import { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
export const useDataProcessingProforma = (data, isActiveUpdateProf) => {
  const [progressBilling, setProgress] = useState(0);

  useEffect(() => {
    if (!isActiveUpdateProf || !data) {
      return;
    }

    let isCancelled = false;

    const processData = async () => {
      for (let n = 0; n < data.length; n++) {
        if (isCancelled) break;

        console.log(data[n]);
        const orderRef = doc(
          db,
          process.env.REACT_APP_COL_ORDERS,
          data[n].BICCIID.toString()
        );
        try {
          await updateDoc(orderRef, {
            billingPaymentValor: data[n].PaymentValor,
            billingPaymentType_service: data[n].PaymentType_service,
            billingPaymentProforma: data[n].PaymentProforma,
            billingCurrencyCode: "CLP",
            billingCurrencySymbol: "$",
          });
        } catch (error) {
          console.log("Manejar nueva solicitud:", data[n].BICCIID.toString());

          let queries = [];

          const collectionOrdersRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );

          queries.push(
            query(collectionOrdersRef, where("orderID", "==", data[n].BICCIID))
          );
          queries.push(
            query(collectionOrdersRef, where("key", "==", data[n].BICCIID))
          );
          queries.push(
            query(collectionOrdersRef, where("int_lpn", "==", data[n].BICCIID))
          );
          queries.push(
            query(collectionOrdersRef, where("soc", "==", data[n].BICCIID))
          );

          let combinedResults = [];

          for (let q of queries) {
            try {
              let ordesBicciexpres = await getDocs(q);
              let dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              console.log(dataOrdesBicciexpres);
              combinedResults = combinedResults.concat(dataOrdesBicciexpres);
            } catch (error) {
              console.error("Error al ejecutar la consulta:", error);
              // Manejar el error o continuar con la siguiente consulta
            }
          }

          const uniqueResults = [
            ...new Map(combinedResults.map((item) => [item.id, item])).values(),
          ];

          try {
            const orderRefq = doc(
              db,
              process.env.REACT_APP_COL_ORDERS,
              uniqueResults[0].id.toString()
            );

            await updateDoc(orderRefq, {
              billingPaymentValor: data[n].PaymentValor,
              billingPaymentType_service: data[n].PaymentType_service,
              billingPaymentProforma: data[n].PaymentProforma,
              billingCurrencyCode: "CLP",
              billingCurrencySymbol: "$",
            });
            console.log("Documento Actualizado");
          } catch (error) {
            console.log(error);
            console.log("No se encuentra ID");
          }
        }

        // Actualizar el progreso cada 10 elementos o al final del array
        if (n % 10 === 9 || n === data.length - 1) {
          const progresoActual = ((n + 1) / data.length) * 100;
          setProgress(progresoActual);
        }

        // Espera corta para evitar bloquear la UI completamente
        await new Promise((resolve) => setTimeout(resolve, 1));
      }
    };

    processData().catch(console.error);

    return () => {
      isCancelled = true;
    };
  }, [data, isActiveUpdateProf]);

  return progressBilling;
};
