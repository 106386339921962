import { db } from "../../firebase"; // Importa correctamente la instancia de Firebase
import React, { useEffect, useState } from "react";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore"; // Importaciones correctas de Firestore
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import { StyledCard, StyledTextField } from "./FromStyles";
import Switch from "@mui/material/Switch";
import {
  Box,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

const FromShippingPaiments = ({
  onChange,
  order,
  companyData,
  colleRef,
  contextOrder,
}) => {
  const [formState, setFormState] = useState({
    id: "",
    whcode: companyData.int_warehouseCode
      ? companyData.int_warehouseCode
      : "default",
  });

  const [methodGenerateId, setMethodGenerateId] = useState(false);

  // useEffect(() => {
  //   console.log(companyData);
  //   console.log(contextOrder);
  //   setFormState({
  //     id: "",
  //     whcode: companyData.int_warehouseCode
  //       ? companyData.int_warehouseCode
  //       : "default",
  //   });
  // }, [companyData && contextOrder]);

  useEffect(() => {
    if (companyData) {
      // Limpiar el ID de la orden al cambiar la empresa
      setFormState((prevState) => ({
        ...prevState,
        id: "", // Resetear el ID de la orden
        whcode: companyData.int_warehouseCode
          ? companyData.int_warehouseCode
          : "default",
      }));
    }
  }, [companyData]);

  // Otro useEffect que actualiza el ID solo si el contexto de la orden cambia
  useEffect(() => {
    if (order && order.id) {
      setFormState((prevState) => ({
        ...prevState,
        id: order.id, // Actualizar el ID con el de la orden
      }));
    }
  }, [order && order.id]);

  useEffect(() => {
    if (
      onChange &&
      (formState.id !== order.id || formState.whcode !== order.whcode)
    ) {
      onChange({ id: formState.id, whcode: formState.whcode });
    }
  }, [formState, onChange, order]);

  // Manejo del cambio del switch para generar ID
  const handleChangeMetod = async (e) => {
    const newMethod = e.target.checked;
    setMethodGenerateId(newMethod); // Actualizar estado del switch

    if (newMethod) {
      const newId = await generateNewOrderId(companyData.prefix);
      const validationIDExist = await checkIfOrderIdExists(newId);
      if (!validationIDExist) {
        setFormState((prevState) => ({
          ...prevState,
          id: newId, // Asignar el nuevo ID
        }));
      } else {
        alert("No se pudo generar un ID único. Intente nuevamente.");
      }
    } else {
      // Cambiar a modo de ingreso manual
      setFormState((prevState) => ({
        ...prevState,
        id: "", // Limpiar el campo de ID para ingreso manual
      }));
    }
  };

  // Generar un nuevo ID
  const generateNewOrderId = async (prefix) => {
    const collTempID = collection(db, "xpress_Accountants");
    const docRef = doc(collTempID, "ordersCounter");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();
      const newId = prefix + (docData.count + 1);
      // Actualizar el contador de IDs
      await updateDoc(docRef, { count: docData.count + 1 });
      return newId;
    } else {
      throw new Error("No se pudo obtener el contador de órdenes.");
    }
  };

  const checkIfOrderIdExists = async (orderId) => {
    const orderRef = doc(colleRef, orderId);
    const orderDoc = await getDoc(orderRef);
    return orderDoc.exists();
  };

  // Manejo de cambio en el campo de ID
  const handleChangeId = (e) => {
    const { value } = e.target;
    // Asegurarse de que el prefijo esté presente
    const prefixedValue = value.startsWith(companyData.prefix)
      ? value
      : `${companyData.prefix}${value}`;
    setFormState((prevState) => ({
      ...prevState,
      id: prefixedValue,
    }));
  };

  // Validar el ID ingresado
  const validateId = async () => {
    const id = formState.id;

    // Verificar que el ID tenga al menos 8 caracteres, incluyendo el prefijo
    if (id.length < 8) {
      alert(
        `El ID debe tener al menos 8 caracteres, incluyendo el prefijo (${companyData.prefix}).`
      );
      return;
    }

    if (id) {
      const exists = await checkIfOrderIdExists(id);
      if (exists) {
        alert("Este ID ya está en uso. Por favor, ingrese otro ID.");
        setFormState((prevState) => ({
          ...prevState,
          id: "", // Limpiar el campo si el ID ya existe
        }));
      } else {
        console.log("ID válido");
      }
    }
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        title={
          <Box display="flex" alignItems="center" width="100%">
            {/* Switch con proporción del 40% */}
            <Box flex={4} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={methodGenerateId} // Usamos methodGenerateId separado del formState
                    onChange={handleChangeMetod}
                    name="seguro"
                    color="primary"
                  />
                }
                label={
                  !methodGenerateId ? (
                    <Typography sx={{ color: "#fff" }}>
                      {"Generar ID"}
                    </Typography>
                  ) : (
                    <Typography sx={{ color: "#fff" }}>
                      {"Ingresar ID"}
                    </Typography>
                  )
                }
              />
            </Box>

            {/* TextField con proporción del 60% */}
            <Box flex={6} ml={2}>
              <StyledTextField
                name="id"
                value={formState.id}
                onChange={handleChangeId}
                onBlur={validateId} // Validar cuando el usuario termine de ingresar el ID
                placeholder="id de orden de envío"
                fullWidth
                margin="normal"
                disabled={methodGenerateId} // Deshabilitar si el ID es generado
                sx={{ marginTop: "1px", marginBottom: "1px" }}
                InputProps={{
                  sx: {
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: "black", // Color negro
                      fontWeight: "bold",
                      opacity: 1, // Texto en negritas
                    },
                  },
                }}
              />
            </Box>
          </Box>
        }
        sx={{
          color: "#fff",
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          paddingBottom: "5px!important",
          color: "#fff",
          borderRadius: "10px 10px 10px 10px",
          background: "#26b1e9",
          backgroundColor: "#5e72b9",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
        }}
      >
        <Typography sx={{ color: "#fff" }}>
          Centro de costo: {formState.whcode}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingPaiments;
