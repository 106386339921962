import React, { useState } from "react";
import { db } from "../../../firebase";
import { Form, Input, Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import { actualizarDatosdeCliente } from "../../../redux/acccionesClientes";
import { actualizarPassPorEnvioEmail } from "../../../redux/acccionesClientes";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FadeInOut from "../../../components/animations/FadeInOut";

const states = [
  {
    value: "santiago",
    label: "Santiago",
  },
  {
    value: "Lobarnechea",
    label: "Lo Barnechea",
  },
  {
    value: "Lascondes",
    label: "Las Condes",
  },
];

const AccountProfileDetails = (props) => {
  //console.log(props)
  const dispatch = useDispatch();
  const userUid = props.userdata.uid;
  const resperror = useSelector((store) => store.usuario.error);

  const [userName, setName] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userPreferenCont, setUserPreferenCont] = React.useState("precencial");
  const [userEmail, setUserEmail] = React.useState("userEmai");
  const [userPhone, setUserPhone] = React.useState("");
  const [userRut, setUserRut] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const [userPass, setUserPass] = React.useState("");
  const [userPassConfirm, setPassConfirm] = React.useState("");
  const [errorPass, setErrorPass] = React.useState(null);
  const [successPass, setSuccessPass] = React.useState(null);
  const [userType, setUserType] = React.useState("empresa");
  const [checked, setChecked] = React.useState(false);

  const [userPassReset, setUserPassReset] = React.useState("");
  //
  // User Typing
  const [rutvalida, setrutvalida] = React.useState("");
  const [validatorAlert, setValidatorAlert] = React.useState("");
  const [validatorSuccess, setValidatorSuccess] = React.useState("");
  //Animaciones
  const [showEdit, setShowEdit] = useState(false);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const [showEdit2, setShowEdit2] = useState(false);
  const toggleShowEdit2 = () => setShowEdit2(!showEdit2);

  React.useEffect(() => {
    setName(props.userdata.userName);
    setUserLastName(props.userdata.userLastName);
    setUserEmail(props.userdata.email);
    setUserPassReset(props.userdata.email);
    if (props.userdata.userPhone) {
      setUserPhone(props.userdata.userPhone);
    }
    if (props.userdata.userRut) {
      //console.log(props.userdata.userRut)
      setUserRut(props.userdata.userRut);
      setrutvalida(props.userdata.userRut);
    }
  }, [props.userdata]);

  const actualizarUsuario = (e) => {
    e.preventDefault();
    if (!userName.trim()) {
      // console.log('El campo nombre es obligatorio')
      setError("El campo nombre es obligatorio");
      return;
    }
    if (userName.length <= 2) {
      // console.log('Indique un nombre de contanto valido')
      setError("Indique un nombre de contanto valido");
      return;
    }
    if (userName.length >= 60) {
      // console.log('Nombre de contacto max 60 Carateres')
      setError("Nombre de contacto max 60 Carateres");
      return;
    }
    if (!userLastName.trim()) {
      // console.log('El campo apellido es obligatorio')
      setError("El campo apellido es obligatorio");
      return;
    }
    if (userLastName.length <= 2) {
      // console.log('El campo apellido no es contanto valido')
      setError("Indique un nombre de contanto valido");
      return;
    }
    if (userLastName.length >= 60) {
      // console.log('El campo apellido no es de máximo 60 Carateres')
      setError("Nombre de contacto max 60 Carateres");
      return;
    }
    if (!userRut.trim()) {
      // console.log('Ingrese Rut')
      setError("Ingrese Rut");
      return;
    }
    // if(!userEmail.trim()){
    //     // console.log('Ingrese Email')
    //     setError('Ingrese Email')
    //     return
    // }
    if (!userPhone.trim()) {
      // console.log('Ingrese un numero telefonico')
      setError("Ingrese un numero telefonico");
      return;
    }
    if (userPhone.length < 12) {
      // console.log('En el campo teléfono faltan numeros')
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (userPhone.length > 12) {
      // console.log('En el campo teléfono existen numeros extra')
      setError("El campo teléfono existen numeros extra");
      return;
    }
    if (!userPreferenCont.trim()) {
      // console.log('Seleccione un medio de contacto')
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      // console.log('Seleccione un tipo de usuario')
      setError("Seleccione un tipo de usuario");
      return;
    }
    setSuccess("Datos Actualizados...");
    const data = {
      uid: userUid,
      username: userName,
      userLastName: userLastName,
      email: userEmail,
      phone: userPhone,
      userPreferenCont: userPreferenCont,
      userType: userType,
      checked: checked,
      userRut: rutvalida,
    };
    dispatch(actualizarDatosdeCliente(data));
    setError(null);
    function delay() {
      dispatch(toggleShowEdit);
      setSuccess(null);
    }
    setTimeout(delay, 1000);
  };

  const actualizarPassPorEmail = (e) => {
    e.preventDefault();
    setSuccessPass("El mensaje fue enviado a tu correo electrónico...");
    const data = {
      email: userEmail,
    };
    dispatch(actualizarPassPorEnvioEmail(data));
    function delay() {
      dispatch(toggleShowEdit2);
      setSuccess(null);
    }
    setTimeout(delay, 1000);
  };

  const actualizarContraseña = (e) => {
    e.preventDefault();

    if (!userPass.trim()) {
      // console.log('Ingrese Contraseña')
      setErrorPass("Ingrese Contraseña");
      return;
    }
    if (userPass.length < 5) {
      // console.log('Contraseña mayor a 6 caracteres')
      setErrorPass("Contraseña mayor a 6 caracteres");
      return;
    }
    if (!userPassConfirm.trim()) {
      // console.log('Ingrese una confirmacion de contraseña')
      setErrorPass("Ingrese una confirmacion de contraseña");
      return;
    }
    if (userPass === userPassConfirm) {
      // console.log('contraseñas iguales')
    } else {
      // console.log('Debe ingresar contraseñas iguales')
      setErrorPass("Debe ingresar contraseñas iguales");
      return;
    }

    if (!resperror === null) {
      // console.log('Error de acceso')
      setErrorPass("Error de acceso");
      return;
    }
    setSuccessPass("Datos Actualizados...");
    setErrorPass(null);
    actualizarPassUsuario();
  };
  const actualizarPassUsuario = React.useCallback(async () => {
    //save Data
    // console.log('is Update Pass')
    setError(null);
    try {
      //dispatch(ingresoUsuarioLogin(email, pass))
    } catch (error) {
      // console.log(error)
    }
  });

  function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      setUserRut(e);
      return;
    }
    valor = clean(e);

    // console.log(e)

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    // console.log(rut)

    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      setUserRut(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado != dv) {
      // console.log('Rut incorrecto')
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
      //  return false;
    } else {
      // console.log('Rut correcto')
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
      //   return true;
    }

    setrutvalida(rut);
    setUserRut(rut);
    var clear = replaceAll(rut, ".", "");
    //setCompanyCodeFolder(clear)

    console.log(clear);
    return;
  }
  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) != -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "" + result;
    }
    return result;
  }
  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  return (
    <Box>
      <Card sx={{ m: 1 }}>
        <CardHeader
          subheader={
            <div>
              <button className="btn btn-ebiex m-1" onClick={toggleShowEdit}>
                {" "}
                <EditOutlinedIcon> </EditOutlinedIcon>
                {showEdit ? "Ocultar" : "Editar"}{" "}
              </button>
              <span> Editar tu informacion</span>
            </div>
          }
          title={<h3>Mi perfil</h3>}
        />
        <Divider />
        <FadeInOut show={showEdit} duration={500}>
          <Container className="formcontent" id="micuentafromIni">
            <hr></hr>

            <Form onSubmit={actualizarUsuario}>
              {success ? (
                <div className="alert alert-success">{success}</div>
              ) : null}
              {error ? <div className="alert alert-danger">{error}</div> : null}
              {resperror ? (
                <div className="alert alert-danger">{resperror}</div>
              ) : null}
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Nombre"
                  placeholder="Nombre"
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={userName}
                  icon="user"
                />

                <Form.Field
                  control={Input}
                  label="Apellido"
                  placeholder="Apellido"
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setUserLastName(e.target.value)}
                  value={userLastName}
                  icon="user"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Rut"
                  placeholder="RUT"
                  iconPosition="left"
                  type="text"
                  onChange={(e) => rutvalidator(e.target.value)} //{e => setUserRut(e.target.value)}
                  value={rutvalida} //Crear Rut  chileno
                />

                <Form.Field
                  control={Input}
                  label="Preferencias de Contacto"
                  placeholder="Preferencias de Contacto"
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setUserPreferenCont(e.target.value)}
                  value={userPreferenCont} //Crear Preferencias
                  icon="user"
                />
              </Form.Group>
              {validatorAlert ? (
                <div className="alert alert-danger">{validatorAlert}</div>
              ) : null}
              <Form.Group widths="equal">
                {/* <Form.Field
              className='field disabled field'
              iconPosition='left' placeholder='Email'
          
              type="email" 
              //label='Email'
              icon='at'
           
              /> */}
                <Form.Field
                  control={Input}
                  //label='Teléfono'
                  placeholder="Teléfono"
                  iconPosition="left"
                  icon="phone"
                  type="text"
                  onChange={(e) => setUserPhone(e.target.value)}
                  value={userPhone}
                />
              </Form.Group>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button color="primary" variant="contained" type="sutmit">
                  Actualizar Datos
                </Button>
              </Box>
            </Form>
          </Container>
        </FadeInOut>
        <Divider />
      </Card>
      <Card sx={{ m: 1 }}>
        <CardHeader
          subheader={
            <div>
              <button className="btn btn-ebiex m-1" onClick={toggleShowEdit2}>
                {" "}
                <EditOutlinedIcon> </EditOutlinedIcon>
                {showEdit2 ? "Ocultar" : "Editar"}{" "}
              </button>
              <span>
                <br></br> Establece una contraseña segura, de al menos 6
                caracteres, Recibirás un correo electrónico para establecer una
                nueva contraseña
              </span>
            </div>
          }
          title={<h3>Cambiar de contraseña de {userEmail}</h3>}
        />
        <Divider />
        <FadeInOut show={showEdit2} duration={500}>
          <Container className="formcontent" id="micuentafromIni">
            <hr></hr>
            <Form onSubmit={actualizarPassPorEmail}>
              {successPass ? (
                <div className="alert alert-success">{successPass}</div>
              ) : null}
              <Form.Group widths="equal">
                <Form.Field
                  className="field disabled field"
                  control={Input}
                  type="email"
                  icon="at"
                  iconPosition="left"
                  label="Recibirás un correo electrónico para establecer una nueva contraseña"
                  value={userPassReset}
                />
              </Form.Group>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button color="primary" variant="contained" type="sutmit">
                  Enviar email
                </Button>
              </Box>
            </Form>
          </Container>
        </FadeInOut>
        <Divider />
      </Card>
    </Box>
  );
};

export default AccountProfileDetails;
