import React from "react";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, query, where, collection, getDocs } from "firebase/firestore";
import { ExitStatus } from "typescript";

function useGetDataOrdersByRiderID(riderId) {
  const [dataResult, setDataResult] = React.useState(null);

  useEffect(() => {
    if (riderId) {
      findOrdersId(riderId);
    } else {
      setDataResult([]);
    }
  }, [riderId]);

  async function findOrdersId(id) {
    const allOrdersRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);

    try {
      const q = query(
        allOrdersRef,
        where("driverID", "==", id),
        where("status", "<=", 7)
      );

      let riderDataOrdersDoc = await getDocs(q);

      const dataOrdes = riderDataOrdersDoc.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDataResult(dataOrdes);
    } catch (error) {
      console.log(error);
      setDataResult([]);
    }
  }

  return { dataResult };
}
export default useGetDataOrdersByRiderID;
