import React from "react";
import { format } from "date-fns";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

const CierreOpsPdf = (props) => {
  const [data, setdata] = React.useState(null);

  const [localMetrcis, setLocalMetrics] = React.useState(null);

  const [totalArrayVehicles, setTotalArrayVehicles] = React.useState([]);

  const [totalArrayComunas, setTotalArrayComunas] = React.useState([]);

  const [rosettaVehicles, setrosettaVehicles] = React.useState([]);

  const [totalHeaders, setTotalHeaders] = React.useState([]);

  const [createBy, setCreateBy] = React.useState([]);

  const [localMetricsOpsStores, setLocalmetricsOpsStores] = React.useState([]);

  const [endDate, setEndDate] = React.useState("");

  const [initDate, setInitDate] = React.useState("");

  React.useEffect(() => {
    console.log(props);
    if (props.metricsOps) {
      callMetrics(props.metricsOps);
      setrosettaVehicles(props.rosettaVehicles);
      setLocalmetricsOpsStores(props.metricsOpsStores);
      setTotalHeaders(props.cant);
      setCreateBy(props.userUid);
      setEndDate(props.endDate);
      setInitDate(props.initDate);
    }
  }, [
    props.rosettaVehicles &&
      props.cant &&
      props.userUid &&
      props.metricsOpsStores,
    props.endDate,
    props.initDate,
  ]);

  React.useEffect(() => {
    console.log(data);
    if (data) {
      callMetrics(data);
    }
  }, [data]);

  React.useEffect(() => {}, [localMetrcis]);

  const callMetrics = (data) => {
    var totalRider = 0;
    var totalVehicles = [];
    var totalAssing = 0;
    var totalDelivery = 0;
    var totalPending = 0;
    var ntsPerformanse = 0;
    var totalZones = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].driverName !== "") {
        totalRider++;
        totalAssing += data[i].asignados;
        totalDelivery += data[i].delivery;
        totalPending += data[i].issures;
      } else {
        totalPending += data[i].issures;
      }

      totalVehicles.push(data[i].vehicleDetailsID);
      totalZones.push(data[i].locality);
    }

    ntsPerformanse = ((totalDelivery * 100) / totalAssing).toFixed(2);

    var allVehiclesNames = [];
    var allVehiclesConter = {};

    for (let a = 0; a < totalVehicles.length; a++) {
      if (!allVehiclesNames.includes(totalVehicles[a])) {
        allVehiclesNames.push(totalVehicles[a]);
        allVehiclesConter[totalVehicles[a]] = { cant: 0 };
      }
    }

    for (let a = 0; a < allVehiclesNames.length; a++) {
      for (let q = 0; q < totalVehicles.length; q++) {
        if (allVehiclesNames[a] === totalVehicles[q]) {
          allVehiclesConter[totalVehicles[q]] = {
            cant: allVehiclesConter[totalVehicles[q]].cant + 1,
          };
        }
      }
    }

    var allZonesNames = [];

    var conterAllZonesGrousp = {};

    for (let x = 0; x < totalZones.length; x++) {
      for (let n = 0; n < totalZones[x].length; n++) {
        var tempData = Object.keys(totalZones[x][n]);

        if (!allZonesNames.includes(tempData[0])) {
          allZonesNames.push(tempData[0]);
          let zoneValue = Object.values(totalZones[x][n]);
          conterAllZonesGrousp[Object.keys(totalZones[x][n])] = parseInt(
            zoneValue[0]
          );
        } else {
          if (conterAllZonesGrousp[tempData[0]]) {
            let subTotalZoneValue = conterAllZonesGrousp[tempData[0]];

            let zoneValue = Object.values(totalZones[x][n]);
            conterAllZonesGrousp[tempData[0]] =
              parseInt(subTotalZoneValue) + parseInt(zoneValue[0]);
          }
        }
      }
    }

    let cantTotalVehicles = Object.values(allVehiclesConter);
    let cantTotalZones = allZonesNames.length;

    let tempNames = Object.keys(allVehiclesConter);
    let tempValues = Object.values(allVehiclesConter);
    let tempArry = [];
    for (let y = 0; y < tempNames.length; y++) {
      let obj = { name: tempNames[y], value: tempValues[y] };
      tempArry.push(obj);
    }

    setTotalArrayVehicles(tempArry);

    let tempNamesComunas = Object.keys(conterAllZonesGrousp);
    let tempValuesComunas = Object.values(conterAllZonesGrousp);
    let tempArrayZones = [];
    for (let y = 0; y < tempNamesComunas.length; y++) {
      let obj = { name: tempNamesComunas[y], value: tempValuesComunas[y] };
      tempArrayZones.push(obj);
    }

    setTotalArrayComunas(tempArrayZones);

    let objeMetric = {
      totalRider: totalRider,
      totalVehicles: tempArry,
      cantTotalVehicles: cantTotalVehicles.length,
      totalAssing: totalAssing,
      totalDelivery: totalDelivery,
      totalPending: totalPending,
      ntsPerformanse: ntsPerformanse,
      totalZones: conterAllZonesGrousp,
      cantTotalZones: cantTotalZones,
    };

    var renderData = Object.entries(objeMetric);
    setLocalMetrics(objeMetric);
  };

  const styles = StyleSheet.create({
    page: { padding: "3%" },
    section: { textAlign: "letf", margin: 10 },
    img: { color: "#050505", width: "120px" },
    imgAvatar: { width: "70px", height: "70px", borderRadius: "100%" },
    text: { fontSize: "20px", marginTop: "5px", marginBottom: "30px" },
    text1: { fontSize: "20px", marginTop: "10px", marginBottom: "5px" },
    text3: { fontSize: "15px", marginTop: "2px", marginBottom: "2px" },
    text12: { fontSize: "12px", marginTop: "2px", marginBottom: "2px" },
    text2: { fontSize: "5px", width: "100%", textAlign: "right" },
    text3Right: { fontSize: "10px", width: "100%", textAlign: "left" },
    space: { marginBottom: "30px" },
    table: {
      fontSize: "10px",
      width: "100%",
      marginTop: 20,
      border: "1px solid black",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableTop: {
      display: "table",
      width: "auto",
      borderWidth: 0,
      marginTop: "10px",
      marginBottom: "0px",
    },
    tableRow: {
      flexDirection: "row",
      backgroundColor: "#f1f1f1",
    },
    tableRowTop: {
      margin: "auto",
      flexDirection: "row",
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
      paddingtOP: "3px",
    },
    tableCol: {
      width: "14%",
      fontWeight: 700,
    },
    tableColTop: {
      width: "14%",
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColTop20: {
      width: "20%",
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },

    tableColCant: {
      width: "4%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColDescipcion: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      fontWeight: 700,
      textAlign: "letf",
    },
    tableCellTop: {
      width: "auto",
      marginTop: 5,
      fontSize: 30,
      color: "blue",
      margin: "auto",
      flexDirection: "row",
      paddingBottom: "5px",
      paddingTop: "5px",
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    bigBicci: {
      fontSize: "28px",
      marginTop: "2px",
      marginBottom: "2px",
      color: "#7d0ee6",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    tableCell: {
      width: "auto",
      marginTop: 0,
      fontSize: 9,
      margin: "auto",
      flexDirection: "row",
      paddingBottom: "3px",
      paddingTop: "3px",
    },
    tableRowItem: {
      flexDirection: "row",
      borderBottom: "1px solid black",
    },
    tableColItem: {
      width: "14%",
      borderStyle: "solid",
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableCellItem: {
      margin: "auto",
      marginTop: "3px",
      marginBottom: "3px",
      fontSize: 8,
      minHeight: "25px",
    },
    tableSmall: {
      fontSize: "10px",
      width: "100%",
      marginTop: 20,
      border: "1px solid black",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableColSmall: {
      display: "table",
      width: "50%",
      borderWidth: 0,
      marginTop: "5px",
      marginBottom: "0px",
    },
    tableSmall2: {
      display: "table",
      width: "50%",
      borderStyle: "solid",
      borderWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      marginTop: "auto",
      marginBottom: "30px",
    },
    tableColSmallTiendas: {
      width: "40%",
      borderStyle: "solid",
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      fontWeight: 700,
      fontSize: "12px",
      color: "blue",
    },
    tableCellTiendas: {
      width: "40%",
      borderStyle: "solid",
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      fontWeight: 700,
      fontSize: "12px",
      color: "red",
    },
    tableStoreSmall2: {
      fontSize: "10px",
      width: "100%",
      marginTop: 20,
      border: "1px solid black",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableRowTopStore: {
      flexDirection: "row",
      backgroundColor: "#f1f1f1",
    },
    tableRowTopStoreBody: {
      flexDirection: "row",
      borderBottom: "1px solid black",
    },
    tableColSmallStore: {
      flex: 1,
      padding: 10,
      width: "30%",
    },
    tableColSmallStoreBody: {
      flex: 1,
      padding: 10,
      width: "30%",
    },
    tableCellStore: {
      fontWeight: "bold",
    },
    tableCellStoreBody: {
      textAlign: "center",
    },
  });

  const typeReport = {
    SD: "SAME DAY",
    HD: "HOME DELIVERY",
    HDF: "HOME DELIVERY GLOBAL",
    geoSort: "GEOSORT",
    BICCI: "BICCI INTEGRACION",
  };

  return (
    <Document fileName="mi-archivo.pdf">
      <Page
        size="A4"
        style={styles.page}
        title={format(Date.now(), "yy/MM/dd")}
        author={"UjajsksiAJSJNSLKAKAHYAls5d46d65s"}
        creator={"BICCIEXPRESS"}
        subject={"Reporte de Operaciónes Bicci"}
      >
        <Text style={styles.text2}>
          {createBy}
          {Date.now().toString()}
        </Text>
        <View style={styles.section}>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>
          <Text style={styles.text1}>
            Reporte de Operaciones ({" "}
            {typeReport[props.typeReport] || "BICCIEXPRESS"})
          </Text>
          <Text style={styles.text3Right}>
            Día-hora de emisión: {format(Date.now(), "dd/MM/yyyy hh:mm:ss")}
          </Text>
          <Text style={styles.text3}>Periodo de consultado:</Text>
          <Text style={styles.text12}>Desde: {initDate}</Text>
          <Text style={styles.text12}>Hasta: {endDate}</Text>
          {localMetrcis ? (
            <View>
              <Text style={styles.text3}>
                Total de Órdenes : {totalHeaders.Total}
              </Text>
              <Text style={styles.bigBicci}>
                Éxito {localMetrcis.ntsPerformanse}%{" "}
              </Text>
              <Text style={styles.text3}>
                Total de Excepciones: {totalHeaders.Incidencias}
              </Text>
              <Text style={styles.text3}>
                Órdenes sin Asignar: {totalHeaders.Creadas}
              </Text>
            </View>
          ) : null}

          <View style={styles.tableTop}>
            <View style={styles.tableRowTop}>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Total Riders</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Excepciones</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Entregados</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Pendientes</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Conductores</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Éxito</Text>
              </View>
              <View style={styles.tableColTop}>
                <Text style={styles.tableCell}>Zonas visitadas</Text>
              </View>
            </View>
          </View>
          {localMetrcis ? (
            <View style={styles.tableTop}>
              <View style={styles.tableRowTop}>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {localMetrcis.totalRider}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {totalHeaders.Incidencias}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {localMetrcis.totalDelivery}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {localMetrcis.totalPending}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {localMetrcis.totalRider}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {Math.floor(localMetrcis.ntsPerformanse).toString()}
                    {" %"}
                  </Text>
                </View>
                <View style={styles.tableColTop}>
                  <Text style={styles.tableCellTop}>
                    {localMetrcis.cantTotalZones}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Riders</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Vehículo</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Asignados</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Finalizados</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Pendientes</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Éxito</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Zonas</Text>
              </View>
            </View>
            {data && rosettaVehicles ? (
              <View>
                {" "}
                {data.map((item, index) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>
                        {item.driverName}
                      </Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>
                        {item.vehicleDetailsID
                          ? item.vehicleDetailsID
                          : "No registrado"}
                      </Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>{item.asignados}</Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>{item.delivery}</Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>{item.issures}</Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>
                        {((item.delivery * 100) / item.asignados).toFixed(2)}%
                      </Text>
                    </View>
                    <View style={styles.tableColItem}>
                      <Text style={styles.tableCellItem}>
                        {item.locality.length}
                      </Text>
                    </View>
                  </View>
                ))}{" "}
              </View>
            ) : null}
          </View>
        </View>
      </Page>
      <Page style={styles.page} break={true}>
        <Text style={styles.text2}>
          {createBy}
          {Date.now().toString()}
        </Text>
        <View style={styles.section}>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>{" "}
          <Text style={styles.text3}>Periodo de consultado: </Text>
          <Text style={styles.text12}>
            Desde: {initDate} Hasta: {endDate}
          </Text>
          <Text style={styles.text12}>Resumen de vehículos</Text>
          <View style={styles.tableSmall}>
            <View style={styles.tableRowTopStore}>
              <View style={styles.tableColSmallStore}>
                <Text style={styles.tableCellStore}>VEHÍCULO</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellStore}>Total Vehículo</Text>
              </View>
            </View>

            {totalArrayVehicles.map((item, index) => (
              <View style={styles.tableRowTopStoreBody} key={index}>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.name ? item.name : "No Registrado"}
                  </Text>
                </View>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.value.cant}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={styles.text12}>Paquetes Por Comuna </Text>
          <View style={styles.tableSmall}>
            <View style={styles.tableRowTopStore}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>COMUNA</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>CANT. PAQUETES</Text>
              </View>
            </View>

            {totalArrayComunas.map((item, index) => (
              <View style={styles.tableRowTop} key={index}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>{item.value}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
      <Page style={styles.page} break={true}>
        <Text style={styles.text2}>
          {createBy}
          {Date.now().toString()}
        </Text>
        <View style={styles.section}>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>
          <Text style={styles.text3}>Periodo de consultado:</Text>
          <Text style={styles.text12}>
            {" "}
            Desde: {initDate} Hasta: {endDate}
          </Text>

          <Text style={styles.text3}>Paquetes Por Tienda </Text>
          <View style={styles.tableStoreSmall2}>
            <View style={styles.tableRowTopStore}>
              <View style={styles.tableColSmallStore}>
                <Text style={styles.tableCellStore}>Tiendas</Text>
              </View>
              <View style={styles.tableColSmallStore}>
                <Text style={styles.tableCellStore}>Excepciones</Text>
              </View>
              <View style={styles.tableColSmallStore}>
                <Text style={styles.tableCellStore}>Entregados</Text>
              </View>
              <View style={styles.tableColSmallStore}>
                <Text style={styles.tableCellStore}>Total tienda</Text>
              </View>
            </View>

            {localMetricsOpsStores.map((item, index) => (
              <View style={styles.tableRowTopStoreBody} key={index}>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.localName}
                  </Text>
                </View>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.withIssues}
                  </Text>
                </View>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.delivered}
                  </Text>
                </View>
                <View style={styles.tableColSmallStoreBody}>
                  <Text style={styles.tableCellStoreBody}>
                    {item.withIssues + item.delivered}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CierreOpsPdf;
