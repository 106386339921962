import React from "react";
import { Card, CardHeader } from "@mui/material";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const ToolsOrdersActions = (props) => {
  if (props.movil === true) {
    return <div>En Aciones de Operación Mantenimiento</div>;
  }
  if (props.movil === false) {
    return (
      <div>
        <Card className="typeMiniformsBarsAccions">
          <CardHeader title="Acciones"></CardHeader>
          <div className="typeMiniformsBarsAccionsItems">
            <div className="m-2">
              <span>Google Maps API: origen y destino.</span>
              <Search
                setSelected={props.setSelected}
                setOrigenGeoCode={props.setOrigenGeoCode}
                setAdressOrigin={props.setAdressOrigin}
                adressOrigin={props.adressOrigin}
                id="imputSearchFromOrigen"
              />
              <SearchDestiny
                setSelecteDestiny={props.setSelecteDestiny}
                setDestinyGeoCode={props.setDestinyGeoCode}
                setAdressDestiny={props.setAdressDestiny}
                adressDestiny={props.adressDestiny}
                id="imputSearchFromDestiny"
              />
            </div>
          </div>
          <div className="typeMiniformsBarsAccionsItems">
            <div className="m-2">
              <h5>Acción a seleccionados</h5>
              <div className="m-2">
                <button
                  onClick={() => props.isShowPopUp("GoogleMaps")}
                  disabled={props.Buttondisable}
                  className={
                    props.adressOrigin && props.adressDestiny
                      ? "btn btn-ebiex extentButton"
                      : "extentButton Buttondisable"
                  }
                >
                  {" "}
                  Ruta Google Maps
                </button>
                <button
                  onClick={() => props.isShowPopUp("ExcelFile")}
                  className="btn btn-ebiex mt-2 extentButton"
                >
                  {" "}
                  Ruta Circuit{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="typeMiniformsBarsAccionsItems">
            <div className="m-2">
              <h5> Acciones de Operación</h5>
              <div className="m-2">
                <button
                  onClick={() => props.isShowPopUp("RutaBicciexpress")}
                  className="btn btn-ebiex extentButton"
                >
                  {" "}
                  Ruta Bicci
                </button>
                <button
                  onClick={() => props.isShowPopUp("Asignacion")}
                  className="btn btn-ebiex  extentButton"
                >
                  {" "}
                  Asignar a conductor{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="typeMiniformsBarsAccionsItems"></div>
        </Card>
      </div>
    );
  }
  return <div>No se reconose el dispositivo</div>;

  function Search({ setSelected, setAdressOrigin, setOrigenGeoCode }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      // console.log(address)
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressOrigin(address);
        //console.log(address)

        const { lat, lng } = await getLatLng(results[0]);

        setOrigenGeoCode({ lat: lat, lng: lng });
        //console.log(lat, lng)

        props.setButtondisable(false);
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Buscar Origen..."
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  function SearchDestiny({
    setSelecteDestiny,
    setAdressDestiny,
    setDestinyGeoCode,
    adressDestiny,
  }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      // console.log(address)
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressDestiny(address);
        //console.log(address)

        const { lat, lng } = await getLatLng(results[0]);

        setDestinyGeoCode({ lat: lat, lng: lng });
        //console.log(lat, lng)

        // setButtondisable(false)
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={"Buscar destino.."}
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
};

export default ToolsOrdersActions;
