import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { ScreenSizeContext } from "../../context/ScreenSizeProvider";
import { IconButton, Card, CardHeader, CardContent } from "@mui/material";
import { db } from "../../firebase";
import CancelIcon from "@mui/icons-material/Cancel";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import FormRouteInfoBicci from "./FormRouteInfoBicci";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import Moment from "moment";
import {
  createDocumentRoute,
  createLocalData,
  defineViewsRouteMapsReducer,
  getDriverConfig,
  obtenerDocumentRoute,
  updateDocumentRoute,
  uptateDriverConfig,
} from "../../redux/routesLiveAccions";
import { doc, getDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getDataDispachstGroupByDriverId } from "../../redux/routesLiveAccions";
import DynamicRouteBicciMapComponent from "./DynamicRouteBicciMapComponent";

//Reload

const libraries = ["places", "geometry"];
const DynamicRouteBicci = (props) => {
  const storePayload = useSelector((store) => store.accionsLiveRoutes);
  const dispatch = useDispatch();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();
  const { widthHeight } = useContext(ScreenSizeContext);
  const { idRider, idRoute } = useParams();

  const [localRiderData, setLocalriderData] = React.useState(null);

  const [welcome, setwelcome] = React.useState(true);

  const [reloadMap, setReloadMap] = React.useState(false);

  const [viewSelectedMarker, setViewSelectedMarker] = React.useState(null);

  const [selectedMarker, setSelectedMarker] = React.useState(null);

  const [reloadMapLoaded, setReloadMapLoaded] = React.useState(false);

  const [apiResponseOrderLabelsMenu, setApiResponseOrderLabelsMenu] =
    React.useState(false);

  const [viewConfig, setviewConfig] = React.useState(null);

  const [currentState, setCurrentState] = React.useState({
    origin: {
      lat: -33.459119,
      lng: -70.598923,
    },
    destination: {
      lat: -33.459119,
      lng: -70.598923,
    },
    originAdress: "Hub Bicci Ñuñoa",
    destinationAdress: "Hub Bicci Ñuñoa",
    responseService: null,
    usedRiderLocations: true,
    requestDir: false,
    polyLine: null,
    hubOrigin: {
      lat: -33.4592446,
      lng: -70.6014753,
    },
    travelMode: "BICYCLING",
    optimizeWaypoints: true,
    wayPoints: [],
    ordersList: null,
    showEdithOptionOrigin: false,
    showEdithOptionDestini: false,
    arrayCurrentList: [],
    responseApi: null,
  });

  const [currentRuteData, setCurrentRuteData] = React.useState(null);
  //const [currentRuteData, setCurrentRuteData] = React.useState(null);

  useEffect(() => {
    const localData = localStorage.getItem("localDataRoute");
    const parsedLocalData = JSON.parse(localData);
    props.setLandScrenMenu(true);
    if (parsedLocalData) {
      initInterfaceByData(parsedLocalData);
      //setwelcome(false);
      // setRenderMap(!renderMap);
    } else {
      dispatch(
        getDataDispachstGroupByDriverId(
          idRider,
          idRoute,
          currentState.origin,
          currentState.destination
        )
      );
      dispatch(obtenerDocumentRoute(idRoute));
    }
  }, []);

  useEffect(() => {
    if (storePayload !== null && currentState) {
      if (
        !storePayload.directionsServiceOptionaAgrupations &&
        storePayload.dataOrdesForRouteGoogleServicesRoutes
      ) {
        let data = storePayload.dataOrdesForRouteGoogleServicesRoutes;
        let confi = currentState;
        let currentOrderList = storePayload.currentOrderList;

        dispatch(
          defineViewsRouteMapsReducer(idRoute, data, confi, currentOrderList)
        );
      } else {
        initInterface(storePayload);
      }
      if (storePayload.viewConfig) {
        setviewConfig(storePayload.viewConfig);
      }
    } else {
    }
  }, [storePayload && storePayload.dataOrdesForRouteGoogleServicesRoutes]);

  useEffect(() => {
    async function getRider(idRider) {
      try {
        // Debería imprimir la fecha actual en formato YYMMDD
        const riderRef = collection(
          db,
          process.env.REACT_APP_COL_USERS_DRIVERS
        );
        let riderData = await getDoc(doc(riderRef, idRider));
        setLocalriderData(riderData.data());
      } catch (error) {
        console.log(error);
      }
    }

    if (!localRiderData && idRider) {
      getRider(idRider);
    }
  }, [idRider]);

  useEffect(() => {
    if (currentState) {
      dispatch(
        getDataDispachstGroupByDriverId(
          idRider,
          idRoute,
          currentState.origin,
          currentState.destination
        )
      );

      dispatch(obtenerDocumentRoute(idRoute));
      dispatch(getDriverConfig(idRider));

      let data = storePayload.dataOrdesForRouteGoogleServicesRoutes;
      let confi = currentState;
      let currentOrderList = storePayload.currentOrderList;
      //let dataObject = "testdevp";

      if (storePayload && storePayload.source !== null) {
        dispatch(
          defineViewsRouteMapsReducer(idRoute, data, confi, currentOrderList)
        );
      }
    }
  }, [currentState]);

  const initInterface = (storePayload) => {
    if (storePayload && storePayload.dataOrdesForRouteGoogleServicesRoutes) {
      let resultadoEsperado = [];
      let ArrayC;
      if (storePayload.documentRoute) {
        let offset = 0;

        for (const key in storePayload.documentRoute.waypoint_order) {
          for (const num of storePayload.documentRoute.waypoint_order[key]) {
            resultadoEsperado.push(num + offset);
          }
          offset += storePayload.documentRoute.waypoint_order[key].length;
        }

        for (let i = 0; i < resultadoEsperado.length; i++) {
          resultadoEsperado[i] = resultadoEsperado[i] + 1;
        }

        ArrayC = storePayload.dataOrdesBicciByRider.sort((a, b) => {
          return (
            resultadoEsperado.indexOf(a.position) -
            resultadoEsperado.indexOf(b.position)
          );
        });

        for (let y = 0; y < ArrayC.length; y++) {
          ArrayC[y].position = y + 1;
        }
      }
      setCurrentRuteData({
        currentOrderList: storePayload.currentOrderList,
        dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
        dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
        dataOrdesBicciByRider: storePayload.documentRoute
          ? ArrayC
          : storePayload.dataOrdesBicciByRider,
        dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
        dataOrdesForRouteGoogleServicesRoutes:
          storePayload.dataOrdesForRouteGoogleServicesRoutes,
        dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
        directionsServiceOptionaAgrupations:
          storePayload.directionsServiceOptionaAgrupations,
        isPointGroups: storePayload.isPointGroups,
        polylines: storePayload.polylines,
        documentRoute: storePayload.documentRoute,
        source: "remote",
        load: true,
      });
      //setwelcome(false);
      setReloadMap(true);
    }
  };

  const initInterfaceByData = (storePayload) => {
    if (storePayload) {
      setCurrentRuteData({
        currentOrderList: storePayload.currentOrderList,
        dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
        dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
        dataOrdesBicciByRider: storePayload.dataOrdesBicciByRider,
        dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
        dataOrdesForRouteGoogleServicesRoutes:
          storePayload.dataOrdesForRouteGoogleServicesRoutes,
        dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
        directionsServiceOptionaAgrupations:
          storePayload.directionsServiceOptionaAgrupations,
        isPointGroups: storePayload.isPointGroups,
        polylines: storePayload.polylines,
        documentRoute: storePayload.documentRoute,
        source: "local",
        load: false,
      });
      setReloadMap(true);
    }
  };

  const updateState = (property, value) => {
    setCurrentState((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const savelocaData = async () => {
    localStorage.removeItem("localDataRoute");
    let SavelocalDataRoute = {
      currentOrderList: storePayload.currentOrderList,
      dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
      dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
      dataOrdesBicciByRider: storePayload.dataOrdesBicciByRider,
      dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
      dataOrdesForRouteGoogleServicesRoutes:
        storePayload.dataOrdesForRouteGoogleServicesRoutes,
      dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
      directionsServiceOptionaAgrupations:
        storePayload.directionsServiceOptionaAgrupations,
      isPointGroups: storePayload.isPointGroups,
      polylines: storePayload.polylines,
      documentRoute: storePayload.documentRoute,
      source: "local",
      load: true,
    };

    dispatch(createLocalData(SavelocalDataRoute));
  };

  const handleMarkerClosed = () => {
    setSelectedMarker(null);
    setViewSelectedMarker(null);
  };

  const handleClosedFroms = () => {
    updateState("showEdithOptionOrigin", false);
    updateState("showEdithOptionDestini", false);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setViewSelectedMarker(marker);
  };

  const handleAceptRoute = async (idRoute) => {
    // DEV 30103230811
    try {
      const planningData = {
        companyId: props.usuarioStorage.company
          ? props.usuarioStorage.company
          : "", // current Document
        createBy: idRider, // current Document
        createDate: Moment(Date.now()).format("DD/MM/YYYY"), // current Document
        currentOrderList: currentRuteData.currentOrderList, // current Document
        date: Date.now(), // current Document
        dateDelivery: Moment(Date.now()).format("YYYYMMDD"), // current Document
        dateDeliveryText: Moment(Date.now())
          .format("YYYY-MM-DD hh.mm.ss")
          .toString(), // current Document
        distanceValue: "", // current Document
        driverID: idRider, // current Document
        driverName: localRiderData.name, // current Document
        id: idRoute, // current Document
        routePoints: currentState.wayPoints, // current Document
        startTimeDelivery: Moment(Date.now()).format("hh:mm:ss").toString(), // current Document
        vehicleID: "", // current Document
        destination: currentState.destination, // currentState Request
        destinationAdress: currentState.destinationAdress, // currentState Request
        optimizeWaypoints: currentState.optimizeWaypoints, // currentState Request
        origin: currentState.origin, // currentState Request
        originAdress: currentState.originAdress, // currentState Request
        travelMode: currentState.travelMode, // currentState Request
        wayPoints: currentState.wayPoints, // currentState Request
        warnings: "isRequire", // responseApi ? responseApi.warnings : "",
        waypoint_order: "isRequire", //responseApi.legs.
        polylines: "isRequire", //responseApi.legs.,
        indications: "isRequire", //responseApi.legs.,
      };
      dispatch(createDocumentRoute(idRoute, planningData));
    } catch (error) {
      console.log(error);
    }
    handleClosedFroms();
    setwelcome(false);
  };

  const setApliConfig = (routeConfig) => {
    dispatch(uptateDriverConfig(idRider, routeConfig));
  };

  async function handleReloadClick() {
    dispatch(
      getDataDispachstGroupByDriverId(
        idRider,
        idRoute,
        currentState.origin,
        currentState.destination
      )
    );
    dispatch(obtenerDocumentRoute(idRoute));
    setReloadMap(false);
  }

  const navigateGoBack = async (idRider) => {
    navigate(`/route/live/${idRider}`);
  };

  const handleReloadPageClick = () => {
    window.location.reload();
  };

  if (loadError) {
    return "Error";
  }

  if (!isLoaded) {
    return "Loading...";
  }

  return (
    <Grid celled="internally" id="expresscuentaContent">
      {/* <h2 className="alternativeColorDhas">Tu ruta de entregas con bicci</h2>{" "} */}
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          {currentRuteData && currentState ? (
            <FormRouteInfoBicci
              dataOrdesBicciByRider={currentRuteData.dataOrdesBicciByRider}
              handleReloadClick={handleReloadClick}
              apiResponseOrderLabelsMenu={apiResponseOrderLabelsMenu}
              dataOrdesBicciByRiderGroups={
                currentRuteData.dataOrdesBicciByRiderGroups
              }
              dataOrderDeliverySusses={currentRuteData.dataOrderDeliverySusses}
              dataordersexcepcions={currentRuteData.dataOrdersExcepcions}
              orderslist={currentState.ordersList}
              originadress={
                currentRuteData.documentoRuote
                  ? currentRuteData.documentoRuote.originAdress
                  : currentState.originAdress
              }
              destinationadress={
                currentRuteData.documentoRuote
                  ? currentRuteData.documentoRuote.destinationAdress
                  : currentState.destinationAdress
              }
              destination={currentState.destination}
              origin={origin}
              viewConfig={viewConfig}
              updateState={updateState}
              setApliConfig={setApliConfig}
            ></FormRouteInfoBicci>
          ) : null}

          {/* <div>
            <button onClick={() => savelocaData()} className="m-2">
              {" "}
              Guardar Ruta en local{" "}
            </button>
            <button onClick={() => console.log("ruta Circuit")} className="m-2">
              {" "}
              Crear ruta Circuit{" "}
            </button>
            <button onClick={() => console.log("Link Maps")} className="m-2">
              {" "}
              Crear Google Maps{" "}
            </button>
            <button onClick={handleReloadClick} className="m-2">
              Recargar Ruta
            </button>
            <button onClick={handleReloadClick} className="m-2">
              Ver Tramos Individualmente
            </button>
            <button onClick={handleReloadClick} className="m-2">
              Actualizar Posiciones
            </button>
          </div> */}
          <div>
            {reloadMap && (
              <div>
                {idRider && currentState && currentRuteData && widthHeight ? (
                  <DynamicRouteBicciMapComponent
                    updateState={updateState}
                    viewConfig={viewConfig}
                    isLoaded={isLoaded}
                    loadError={loadError}
                    idRider={idRider}
                    reloadMapLoaded={reloadMapLoaded}
                    currentState={currentState}
                    currentRuteData={currentRuteData}
                    widthHeight={widthHeight}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                    handleMarkerClick={handleMarkerClick}
                    handleMarkerClosed={handleMarkerClosed}
                    setApiResponseOrderLabelsMenu={
                      setApiResponseOrderLabelsMenu
                    }
                  ></DynamicRouteBicciMapComponent>
                ) : null}
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {currentRuteData &&
        !currentRuteData.documentRoute &&
        currentRuteData.dataOrdesBicciByRider &&
        welcome ? (
          <>
            {currentRuteData.dataOrdesBicciByRider.length > 0 ? (
              <>
                {" "}
                <div className="overlay-OpcionaContainerInfoWindow">
                  <div className="OpcionaContainerInfoWindow">
                    <Card
                      sx={{
                        padding: "10px",
                        paddingRight: "30px",
                        paddingBottom: "20px",
                      }}
                    >
                      <CardHeader
                        title={` Bienvenido, tu ruta de hoy tiene ${currentRuteData.dataOrdesBicciByRider.length} paradas`}
                        sx={{ padding: "1px" }}
                      />

                      <CardContent sx={{ padding: "1px" }}>
                        <div>
                          <>
                            {currentRuteData.dataOrdersExcepcions ? (
                              <div className="alert alert-warning m-3">
                                Posees{" "}
                                {currentRuteData.dataOrdersExcepcions.length}{" "}
                                pendientes por entrega, retornalas a ruta
                                escaneado la orden o retorna el paquete al hub
                              </div>
                            ) : null}
                            <div>
                              Recuerda Fijar una dirección Origen, y un destino
                              luego has click en iniciar, por defecto la ruta
                              actual iniciara en el hub Bicci Ñuñoa{" "}
                            </div>
                            <div>
                              <IconButton
                                sx={{
                                  color: "#21ba45",
                                  padding: "20px",
                                  background: "#f8f8f8",
                                  borderRadius: "3px",
                                }}
                                variant="contained"
                                onClick={() => handleAceptRoute(idRoute)}
                                size="small"
                              >
                                <CheckCircleOutlineIcon />
                                Aceptar e iniciar
                              </IconButton>
                            </div>
                          </>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </>
            ) : (
              <>
                {currentRuteData.dataOrdersExcepcions.length > 0 ? (
                  <>
                    {" "}
                    <div className="overlay-OpcionaContainerInfoWindow">
                      <div className="OpcionaContainerInfoWindow">
                        <Card
                          sx={{
                            padding: "10px",
                            paddingRight: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <CardHeader
                            title={` Bienvenido, tu ruta de hoy hasta el momento tiene ${currentRuteData.dataOrdesBicciByRider.length} paradas`}
                            sx={{ padding: "1px" }}
                          />

                          <CardContent sx={{ padding: "1px" }}>
                            <div>
                              {/* <h3 className="alert alert-danger">
                                Dinamic route bicci , Remover al finalizar
                              </h3> */}
                              Retorna a hojas de rutas
                              <Link to={`/route/live/${idRider}`}>
                                {" "}
                                <button
                                  className="btn-add-guide-small m-2"
                                  onClick={() => navigateGoBack()}
                                >
                                  Retorna a hojas de rutas
                                </button>
                              </Link>
                              {currentRuteData.dataOrdesBicciByRider &&
                              currentRuteData.dataOrdesBicciByRider.length <=
                                23 ? (
                                <>
                                  {currentRuteData.dataOrdersExcepcions ? (
                                    <h3 className="alert alert-warning m-3">
                                      Posees{" "}
                                      {
                                        currentRuteData.dataOrdersExcepcions
                                          .length
                                      }{" "}
                                      pendientes por entregar, retornalas a ruta
                                      escaneado la orden o retorna el paquete al
                                      hub
                                    </h3>
                                  ) : null}
                                  <div>
                                    Recuerda Fijar una dirección Origen, y un
                                    destino luego has click en iniciar, por
                                    defecto la ruta actual iniciara en el hub
                                    Bicci Ñuñoa{" "}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="overlay-OpcionaContainerInfoWindow">
                      <div className="OpcionaContainerInfoWindow">
                        <Card
                          sx={{
                            padding: "10px",
                            paddingRight: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <CardHeader
                            title={` Bienvenido, no tienes entregas pendientes, Si ya escaneaste tus ordenes, solicita apoyo a soporte. Gracias `}
                            sx={{ padding: "1px" }}
                          />

                          <CardContent sx={{ padding: "1px" }}></CardContent>
                        </Card>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : null}

        {viewSelectedMarker && widthHeight.width < 950 ? (
          <div className="OpcionaContainerInfoWindow">
            <Card sx={{ padding: "10px", paddingBottom: "20px" }}>
              <CardHeader
                title={`ID: ${viewSelectedMarker.id}`}
                sx={{ padding: "1px" }}
                action={
                  <IconButton
                    sx={{ color: "#F40606" }}
                    onClick={() => handleMarkerClosed(null)}
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                }
              />

              <CardContent sx={{ padding: "1px" }}>
                <div>
                  <h3>
                    <PersonIcon sx={{ color: "#1806F4", padding: "1px" }} />

                    {viewSelectedMarker.userName}
                  </h3>
                </div>
                <div>
                  <div className="linearButtons">
                    <a
                      className="nonstyle"
                      href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <IconButton
                        sx={{
                          color: "#1806F4",
                          padding: "1px",
                          width: "35px",
                          height: "35px",
                          fontSize: "2.5rem",
                        }}
                        onClick={() => {
                          // Agrega tu lógica de manejo de eventos aquí
                          // Por ejemplo, puedes mostrar un mensaje de confirmación antes de abrir Google Maps
                          // o realizar alguna acción adicional
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`,
                            "_blank"
                          );
                        }}
                      >
                        <AssistantDirectionIcon
                          sx={{
                            color: "#1806F4",
                            padding: "1px",
                            width: "35px",
                            height: "35px",
                            fontSize: "2.5rem",
                          }}
                        />
                      </IconButton>{" "}
                      <div> Google maps</div>
                    </a>

                    <a
                      className="nonstyle"
                      href={constructWazeLink(selectedMarker.toAddress)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img
                        src="/waze.svg"
                        alt="Icon"
                        className="marketr-icon"
                      />
                      <div> Navega con Waze</div>
                    </a>
                  </div>
                  <div> {selectedMarker.toAddress}</div>

                  <div>
                    <IconButton
                      sx={{
                        ml: 1,
                        color: "#1806F4",
                        padding: "1px",
                        fontSize: "1.5em",
                      }}
                      onClick={(e) => console.log(e)}
                      size="small"
                    >
                      <CallIcon />
                    </IconButton>
                    <a
                      className="nonstyle"
                      href={`tel:9${viewSelectedMarker.userPhone}`}
                    >
                      9{viewSelectedMarker.userPhone}
                    </a>
                  </div>
                </div>
                <div>
                  <a
                    className="nonstyle"
                    href={`https://api.whatsapp.com/send?phone=+569${viewSelectedMarker.userPhone}&text=Soporte%20BICCI%20:`}
                  >
                    <IconButton
                      sx={{ ml: 1, color: "#29B834", padding: "1px" }}
                      onClick={props.menuOption}
                      size="small"
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    9{viewSelectedMarker.userPhone}
                  </a>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );

  function constructWazeLink(searchText) {
    const elementos = searchText.split(",");
    const primerElemento = elementos[0];
    const palabras = primerElemento.split(" ");
    let indice = -1;
    for (let i = 0; i < palabras.length; i++) {
      if (!isNaN(parseInt(palabras[i]))) {
        indice = i;
        break;
      }
    }
    let nuevoArray = palabras.slice(0, indice + 1).join(" ");
    nuevoArray += ", ";
    nuevoArray += elementos.slice(1).join(",");
    const encodedSearchText = encodeURIComponent(nuevoArray);
    const wazeLink = `https://waze.com/ul?q=${encodedSearchText}`;
    return wazeLink;
  }
};

export default DynamicRouteBicci;
