import React, { useState } from 'react';
import {Container} from 'semantic-ui-react';
import {
  Card,
  Divider,
  CardHeader,
} from '@mui/material';
import FadeInOut from '../../../components/animations/FadeInOut';
import { Grid } from 'semantic-ui-react';
const Configuraciones = () => {
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  return (  
<Grid celled='internally'  id='expresscuentaContent'>
  <h2>Configuraciones </h2>
  
<Grid.Row>
  <Grid.Column  computer={8} mobile={16} tablet={16} className="dash-container-colmun" >        
  <Card className='typeMiniforms'>
              <CardHeader
                subheader='subheader'
                title='Agregar'

              />
              <Divider />
              <FadeInOut show={showEdit} duration={500}>

              <Container className='formcontent' id="micuentafromIni">
                <hr></hr>
       
             
              
          
              </Container>
              </FadeInOut>

              <Divider />
            
  </Card>
  </Grid.Column>

<Grid.Column  computer={8} mobile={16} tablet={16} className="dash-container-colmun" >
  <Card className='typeMiniforms'>
              <CardHeader
                subheader='Resumenes'
                title='Metrica'

              />
              <Divider />
              <FadeInOut show={showEdit} duration={500}>

              <Container className='formcontent' id="micuentafromIni">
                <hr></hr>

              </Container>
              </FadeInOut>

              <Divider />
            
  </Card>
</Grid.Column>



<Grid.Column  computer={16} mobile={16} tablet={16} className="dash-container-colmun" >
<Card className='typeMiniforms'>
 <CardHeader
  subheader='subheader'
  title='Usuarios Actuales'
  />
  <Divider />
  <Container className='formcontent' id="micuentafromIni">
  <hr></hr>
  </Container>
 
 <Divider />                         
</Card>
<div>

</div>



</Grid.Column>
</Grid.Row>


</Grid>
    ); 
  };

export default Configuraciones;
