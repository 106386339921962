export const retiredPodArray = [
  "F1",
  "F6",
  "F7",
  "F3",
  "F4",
  "F5",
  "8018053",
  "F8018053",
  "F140000000000",
  "F3344222112",
  "F3344222111",
  "F149080992329",
  "F149081024412",
  "F149081002630",
  "F149081018629",
  "F149081021803",
  "F149081483305",
  "F149081495055",
  "F149081450743",
  "F149081400650",
  "F149081470972",
  "F149081491112",
  "F149084645306",
  "F987654321",
  "F55023",
  "F149085351283",
  "F149085278280",
  "F149085268877",
  "F149085266113",
  "F149085257906",
  "F149085235383",
  "F149084692962",
  "F149084686522",
  "F149084678911",
  "F149084676063",
  "F149084670681",
  "F149084670538",
  "F149084669755",
  "F149084669363",
  "F149084668266",
  "F149084660901",
  "F149084660003",
  "F149084659316",
  "F149084657501",
  "F149084651598",
  "F149084649294",
  "F149084647254",
  "F149084640864",
  "F149085326725",
  "F149085239284",
  "F149113494564",
  "F149113761442",
  "F149114073318",
  "F700000000001143274",
  "F100000002551433",
  "F149129398702",
  "F8017784",
  "8017784",
  "F8018288",
  "8018288",
  "F8017987",
  "8017987",
  "F8018005",
  "8018005",
  "F8017863",
  "8017863",
  "F8017886",
  "8017886",
  "F8017972",
  "8017972",
  "F8017903",
  "8017903",
  "F8017982",
  "8017982",
  "F8017870",
  "8017870",
  "F8017885",
  "8017885",
  "F8018319",
  "8018319",
  "F8018391",
  "8018391",
  "F8017888",
  "8017888",
  "F8018433",
  "8018433",
  "F8018137",
  "8018137",
  "F8017981",
  "8017981",
  "F8017993",
  "8017993",
  "F8018016",
  "8018016",
  "F8017878",
  "8017878",
  "F8017890",
  "8017890",
  "F8018009",
  "8018009",
  "F8018010",
  "8018010",
  "F8018130",
  "8018130",
  "F8017776",
  "8017776",
  "F8017781",
  "8017781",
  "F8017782",
  "8017782",
  "F8017786",
  "8017786",
  "F8017822",
  "8017822",
  "F8017852",
  "8017852",
  "F8017853",
  "8017853",
  "F8017854",
  "8017854",
  "F8017869",
  "8017869",
  "F8017692",
  "8017692",
  "F8017838",
  "8017838",
  "F8017839",
  "8017839",
  "F8017947",
  "8017947",
  "F8018278",
  "8018278",
  "F8018376",
  "8018376",
  "F8017721",
  "8017721",
  "F8017859",
  "8017859",
  "F8017860",
  "8017860",
  "F8017882",
  "8017882",
  "F8017916",
  "8017916",
  "F8018276",
  "8018276",
  "F8017953",
  "8017953",
  "F8018031",
  "8018031",
  "F8017817",
  "8017817",
  "F8017821",
  "8017821",
  "F8017835",
  "8017835",
  "F8018346",
  "8018346",
  "F8018337",
  "8018337",
  "F8017877",
  "8017877",
  "F8018437",
  "8018437",
  "F8018138",
  "8018138",
  "F8018215",
  "8018215",
  "F8017819",
  "8017819",
  "F8017871",
  "8017871",
  "F8017688",
  "8017688",
  "F8018310",
  "8018310",
  "F8017924",
  "8017924",
  "F8017928",
  "8017928",
  "F8017936",
  "8017936",
  "F8017992",
  "8017992",
  "F8018003",
  "8018003",
  "F8017865",
  "8017865",
  "F8017921",
  "8017921",
  "F8017939",
  "8017939",
  "F8017976",
  "8017976",
  "F8017999",
  "8017999",
  "F8018017",
  "8018017",
  "F8018145",
  "8018145",
  "F8017775",
  "8017775",
  "F8017777",
  "8017777",
  "F8017816",
  "8017816",
  "F8017818",
  "8017818",
  "F8017868",
  "8017868",
  "F8017884",
  "8017884",
  "F8017834",
  "8017834",
  "F8017948",
  "8017948",
  "F8018051",
  "8018051",
  "F8018293",
  "8018293",
  "F8018327",
  "8018327",
  "F8018336",
  "8018336",
  "F8018411",
  "8018411",
  "F8017722",
  "8017722",
  "F8017848",
  "8017848",
  "F8017855",
  "8017855",
  "F8017861",
  "8017861",
  "F8017922",
  "8017922",
  "F8018126",
  "8018126",
  "F8018331",
  "8018331",
  "F8017930",
  "8017930",
  "F8018000",
  "8018000",
  "F8017879",
  "8017879",
  "F8017893",
  "8017893",
  "F8017780",
  "8017780",
  "F8017785",
  "8017785",
  "F8017756",
  "8017756",
  "F8017832",
  "8017832",
  "F8018284",
  "8018284",
  "F8018353",
  "8018353",
  "F8018357",
  "8018357",
  "F8018378",
  "8018378",
  "F8018332",
  "8018332",
  "F8018104",
  "8018104",
  "F8017941",
  "8017941",
  "F8017944",
  "8017944",
  "F8017967",
  "8017967",
  "F8017926",
  "8017926",
  "F8017932",
  "8017932",
  "F8018022",
  "8018022",
  "F8017773",
  "8017773",
  "F8017837",
  "8017837",
  "F8017841",
  "8017841",
  "F8017946",
  "8017946",
  "F8018275",
  "8018275",
  "F8018297",
  "8018297",
  "F8018370",
  "8018370",
  "F8018375",
  "8018375",
  "F8018334",
  "8018334",
  "F8017717",
  "8017717",
  "F8017881",
  "8017881",
  "F8017883",
  "8017883",
  "F8018014",
  "8018014",
  "F8017783",
  "8017783",
  "F8017867",
  "8017867",
  "F8017830",
  "8017830",
  "F8018403",
  "8018403",
  "F8018425",
  "8018425",
  "F8018436",
  "8018436",
  "F8017977",
  "8017977",
  "F8018011",
  "8018011",
  "F8017889",
  "8017889",
  "F8017895",
  "8017895",
  "F8017917",
  "8017917",
  "F8017787",
  "8017787",
  "F8017815",
  "8017815",
  "F8018420",
  "8018420",
  "F8017846",
  "8017846",
  "F8017880",
  "8017880",
  "F8017907",
  "8017907",
  "F8017991",
  "8017991",
  "F8017772",
  "8017772",
  "F8017779",
  "8017779",
  "F8017864",
  "8017864",
  "F8017683",
  "8017683",
  "F8017723",
  "8017723",
  "F8018385",
  "8018385",
  "F8018124",
  "8018124",
  "F8018435",
  "8018435",
  "F8017923",
  "8017923",
  "F8018008",
  "8018008",
  "F8017961",
  "8017961",
  "F8017778",
  "8017778",
  "F8017724",
  "8017724",
  "F8017840",
  "8017840",
  "F8017887",
  "8017887",
  "F8017996",
  "8017996",
  "F8017965",
  "8017965",
  "F8017814",
  "8017814",
  "F8017831",
  "8017831",
  "F8018049",
  "8018049",
  "F8018306",
  "8018306",
  "F8017971",
  "8017971",
  "F8018036",
  "8018036",
  "F8018146",
  "8018146",
  "F8018075",
  "8018075",
  "F8017874",
  "8017874",
  "F100000005087897",
  "F100000005087893",
  "F100000005084237",
  "F100000005081520",
  "F100000005081393",
  "F100000005081392",
  "F100000005079169",
  "F100000005078754",
  "F100000005074729",
  "F100000005059861",
  "F100000005043778",
  "F100000005029124",
  "F100000005087971",
  "F100000005084435",
  "F100000005084432",
  "F100000005084403",
  "F100000005084249",
  "F100000005083181",
  "F100000005083170",
  "F100000005081292",
  "F100000005078986",
  "F100000005076400",
  "F100000005075019",
  "F100000005073258",
  "F100000005073250",
  "F100000005086852",
  "F100000005084365",
  "F100000005084314",
  "F100000005083163",
  "F100000005083142",
  "F100000005083092",
  "F100000005081422",
  "F100000005081337",
  "F100000005079084",
  "F100000005078078",
  "F100000005075906",
  "F100000005075030",
  "F100000005073992",
  "F100000005073333",
  "F100000005073194",
  "F100000005056520",
  "F100000005054061",
  "F100000005088019",
  "F100000005086858",
  "F100000005086747",
  "F100000005083136",
  "F100000005083060",
  "F100000005083055",
  "F100000005082170",
  "F100000005081513",
  "F100000005074692",
  "F100000005086790",
  "F100000005084471",
  "F100000005076399",
  "F100000005074685",
  "F999100000001153461-2",
  "F907490000050312924-2",
  "F907490000050309698-2",
  "F907490000050308477-2",
  "F379536070000031921-1",
  "F379536070000031668-1",
  "F054162400000005193-1",
  "F054162400000005064-1",
  "F054162400000005048-1",
  "F054162400000005018-1",
  "F054162400000004848-1",
  "F014452400000002050-1",
  "F000702400000003186-1",
  "F999100000001155438-2",
  "F999100000001152679-2",
  "F907490000050315001-2",
  "F907490000050314507-2",
  "F907490000050313969-1",
  "F907490000050312910-2",
  "F907490000050309821-2",
  "F907490000050309499-2",
  "F907490000050308628-2",
  "F379536070000031920-1",
  "F379536070000031919-1",
  "F379536070000031669-1",
  "F307605987838257462-1",
  "F109298700005614131-1",
  "F054162400000005149-1",
  "F054162400000005105-1",
  "F036602400000004487-1",
  "F026172400000003458-1",
  "F999100000001153586-2",
  "F999000000030300239-1",
  "F999000000030296268-1",
  "F907490000050314402-2",
  "F907490000050313506-2",
  "F907490000050311121-1",
  "F907490000050310040-2",
  "F907490000050309967-2",
  "F907490000050309536-2",
  "F907490000050309083-2",
  "F907490000050308931-2",
  "F907490000050308714-2",
  "F907490000050308005-2",
  "F379536070000031906-1",
  "F309687669000246460-1",
  "F072002400000003572-1",
  "F036602400000004869-1",
  "F026192400000002308-1",
  "F026172400000003569-1",
  "F999100000001153932-2",
  "F999000000030296249-1",
  "F907490000050314630-2",
  "F907490000050314040-2",
  "F907490000050313823-2",
  "F907490000050312600-2",
  "F907490000050310240-2",
  "F907490000050309372-2",
  "F309213900001271974-1",
  "F307605987838256399-1",
  "F015012400000003389-1",
  "F999100000001153765-2",
  "F907490000050312021-1",
  "F907490000050311795-1",
  "F907490000050311452-2",
  "F907490000050309084-2",
  "F907490000050308266-2",
  "F054162400000004815-1",
  "F900600000000000353",
  "F700000000001521177",
  "F700000000001519395",
  "F700000000001519394",
  "F700000000001519150",
  "F700000000001519067",
  "F140111000001888071",
  "F140111000001887989",
  "F140111000001884400",
  "F140111000001883897",
  "F140111000001883710",
  "F140111000001883709",
  "F140111000001880915",
  "F900600000000000501",
  "F700000000001520152",
  "F700000000001519405",
  "F700000000001519404",
  "F700000000001518643",
  "F149135479922",
  "F149135479012",
  "F149135477605",
  "F149135474859",
  "F149135458791",
  "F149135455444",
  "F149135442463",
  "F149135438837",
  "F149135435427",
  "F140111000001888417",
  "F140111000001888201",
  "F140111000001886434",
  "F140111000001882397",
  "F140111000001881713",
  "F140111000001881454",
  "F149135515591",
  "F149135496135",
  "F149135495050",
  "F149135490780",
  "F149135482309",
  "F149135476234",
  "F149135475416",
  "F149135470522",
  "F149135468777",
  "F149135468714",
  "F149135468231",
  "F149135465250",
  "F149135459137",
  "F149135456926",
  "F149135444300",
  "F149135441226",
  "F149135441055",
  "F149135436283",
  "F149135407412",
  "F149135407342",
  "F149135393498",
  "F149135242489",
  "F149135512865",
  "F149135503942",
  "F149135475415",
  "F149135468783",
  "F149135461121",
  "F149135450333",
  "F149135435138",
  "F149135419919",
  "F149135409247",
  "F149135403472",
  "F149135402165",
  "F149135398359",
  "F149135320040",
  "F149134833599",
  "F146002669153",
  "F140001983919",
  "F149135510336",
  "F149135481084",
  "F149135472633",
  "F149135465903",
  "F149135456925",
  "F149135451866",
  "F149135450546",
  "F149135432088",
  "F149135359969",
  "F149135341263",
  "F149135273946",
  "F146002669205",
  "F149135481334",
  "F149135465697",
  "F149135453360",
  "F149135396065",
  "F149135376172",
  "F149135298396",
  "F149135132165",
  "F149134884826",
  "F149134905872",
  "F700000000001508000",
  "F140111000001849000",
  "F140111000001848000",
  "F149135144051",
  "F149134433381",
  "F054162400000013071-1",
  "F999000000030678953-1",
  "F999000000030679842",
  "F907490000050488555",
  "F999000000030675484",
  "F026172400000007819",
  "F026172400000007779",
  "F999000000030674788",
  "F907490000050488163",
  "F8289400",
  "F8288329",
  "F054162400000013180",
  "F149136884021",
  "F149137065756",
  "F146002671896",
  "F149137065756",
  "F149137083905",
  "F149136929165",
  "F146002672081",
  "F149138833409",
  "F149138814116",
  "F149138761565",
  "F149138812778",
  "F149138756211",
  "F149138757330",
  "F149138758380",
  "F149138764650",
  "F149138824956",
  "F149138813514",
  "F149138815741",
  "F149138810990",
  "F149138825575",
  "F149138813002",
  "F149138818001",
  "F149138810755",
  "F149138830488",
  "F149138826757",
  "F149138814848",
  "F149138817367",
  "F149138812513",
  "F149138811238",
  "F149138818063",
  "F149138832298",
  "F149138832288",
  "F149138823520",
  "F149138826276",
  "F149138826375",
  "F149138831541",
  "F149138810480",
  "F149138825347",
  "F149138820459",
  "F149138819561",
  "F149138831603",
  "F379536070000139359-1",
  "F907490000050610212-2",
  "F907490000050610300-2",
  "F907490000050610288-2",
  "F907490000050610190-2",
  "F999100000001242622-2",
  "F999100000001242898-2",
  "F015012400000014913-1",
  "F015012400000015066-1",
  "F026172400000013254-1",
  "F017372400000009974-1",
  "F999100000001243164-2",
  "F999100000001242947-2",
  "F999100000001243387-2",
  "F907490000050612057-2",
  "F907490000050612384-2",
  "F014452400000009140-1",
  "F999000000030997835-1",
  "F907490000050610896-2",
  "F900600000000061225-1",
  "F900600000000061298-1",
  "F999100000001243290-2",
  "F907490000050612661-2",
  "F379536070000139209-1",
  "F999000000030990716-1",
  "F026172400000013260-1",
  "F907490000050611651-2",
  "F999100000001243298-2",
  "F907490000050612586-2",
  "F999000000030990998-1",
  "F999000000030991345-1",
  "F999100000001242396-2",
  "F026192400000005229-1",
  "F999000000030996377-1",
  "F907490000050610994-2",
  "F907490000050611252-2",
  "F907490000050612016-2",
  "F999100000001243538-2",
  "F107674615700045330-1",
  "F999000000030991445-1",
  "F907490000050610098-2",
  "F999100000001242282-2",
  "F054162400000019421-1",
  "F907490000050611253-2",
  "F999100000001243384-2",
  "F054162400000019459-1",
  "F054162400000019244-1",
  "F999000000030996599-1",
  "F999100000001242876-2",
  "F107600093504312296-3",
  "F999100000001243008-2",
  "F999100000001243171-2",
  "F907490000050611798-2",
  "F907490000050612502-1",
  "F907490000050611833-2",
  "F017372400000009890-1",
  "F999100000001242358-2",
  "F907490000050610757-2",
  "F015012400000015054-1",
  "F026172400000013134-1",
  "F999000000030997837-1",
  "F999100000001242976-2",
  "F907490000050612514-1",
  "F907490000050610599-2",
  "F999100000001242574-2",
  "F015012400000014980-1",
  "F054162400000019271-1",
  "F999000000030997060-1",
  "F907490000050611349-2",
  "F999000000030998209-1",
  "F907490000050611916-2",
  "F100000005867532",
  "F100000005871013",
  "F100000005876072",
  "F100000005852649",
  "F100000005871003",
  "F100000005866091",
  "F100000005865358",
  "F100000005838075",
  "F100000005864797",
  "F100000005866916",
  "F100000005866038",
  "F100000005865538",
  "F100000005866846",
  "F100000005837201",
  "F100000005870342",
  "F100000005875991",
  "F100000005869032",
  "F100000005850386",
  "F100000005869073",
  "F100000005865709",
  "F100000005867103",
  "F100000005864826",
  "F100000005864495",
  "F100000005866809",
  "F100000005866469",
  "F100000005851339",
  "F100000005867630",
  "F100000005867700",
  "F100000005865904",
  "F100000005867865",
  "F100000005876080",
  "F907490000050482251",
  "F149138514281",
  "F149138511605",
  "149136226621",
  "F8289400",
  "F8288329",
  "F054162400000013180",
  "F149136884021",
  "F149137065756",
  "F146002671896",
  "F149137083905",
  "F149136929165",
  "F146002672081",
  "F149136226621",
  "149136226621",
  "149138153430",
  "F149138153430",
  "F149143381470",
  "F149142712609",
  "F149144028544",
];
