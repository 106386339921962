export const MapVectoriales = {
  type: "FeatureCollection",
  name: "VectorialSatelite",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "CERRILLOS",
        description: "Arturo Aguirre Gacitúa (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.689301, -33.477088, 0.0],
            [-70.68993, -33.47704, 0.0],
            [-70.69082, -33.47707, 0.0],
            [-70.69178, -33.47786, 0.0],
            [-70.69323, -33.47818, 0.0],
            [-70.69492, -33.47862, 0.0],
            [-70.69692, -33.47892, 0.0],
            [-70.70266, -33.48027, 0.0],
            [-70.70428, -33.48056, 0.0],
            [-70.70772, -33.48097, 0.0],
            [-70.70883, -33.4808, 0.0],
            [-70.71041, -33.48093, 0.0],
            [-70.71103, -33.48126, 0.0],
            [-70.71243, -33.48114, 0.0],
            [-70.71376, -33.48005, 0.0],
            [-70.71631, -33.47821, 0.0],
            [-70.71916, -33.4755, 0.0],
            [-70.72203, -33.47332, 0.0],
            [-70.72232, -33.47413, 0.0],
            [-70.7229, -33.47744, 0.0],
            [-70.72347, -33.47994, 0.0],
            [-70.72371, -33.48059, 0.0],
            [-70.72428, -33.48119, 0.0],
            [-70.72447, -33.48179, 0.0],
            [-70.72449, -33.48239, 0.0],
            [-70.72512, -33.48222, 0.0],
            [-70.72612, -33.48186, 0.0],
            [-70.72684, -33.48199, 0.0],
            [-70.72725, -33.48238, 0.0],
            [-70.72786, -33.4828, 0.0],
            [-70.72874, -33.48274, 0.0],
            [-70.72993, -33.48242, 0.0],
            [-70.7313, -33.4825, 0.0],
            [-70.731246, -33.484628, 0.0],
            [-70.731332, -33.486131, 0.0],
            [-70.731268, -33.487796, 0.0],
            [-70.731697, -33.493128, 0.0],
            [-70.73144, -33.494667, 0.0],
            [-70.732555, -33.495777, 0.0],
            [-70.733414, -33.496618, 0.0],
            [-70.734336, -33.49821, 0.0],
            [-70.734916, -33.49957, 0.0],
            [-70.73601, -33.501896, 0.0],
            [-70.736847, -33.503721, 0.0],
            [-70.73734, -33.504866, 0.0],
            [-70.738639, -33.507899, 0.0],
            [-70.739615, -33.510144, 0.0],
            [-70.737147, -33.510127, 0.0],
            [-70.733006, -33.510073, 0.0],
            [-70.726354, -33.509912, 0.0],
            [-70.722942, -33.510055, 0.0],
            [-70.720239, -33.512381, 0.0],
            [-70.718436, -33.514063, 0.0],
            [-70.721827, -33.51832, 0.0],
            [-70.723286, -33.52036, 0.0],
            [-70.724316, -33.521415, 0.0],
            [-70.725431, -33.52281, 0.0],
            [-70.72629, -33.52391, 0.0],
            [-70.72382, -33.52491, 0.0],
            [-70.71755, -33.5272, 0.0],
            [-70.70626, -33.53142, 0.0],
            [-70.70584, -33.52844, 0.0],
            [-70.70563, -33.52494, 0.0],
            [-70.70543, -33.52403, 0.0],
            [-70.7049, -33.52267, 0.0],
            [-70.70329, -33.51878, 0.0],
            [-70.70236, -33.51675, 0.0],
            [-70.69078, -33.49292, 0.0],
            [-70.68896, -33.48818, 0.0],
            [-70.68796, -33.48618, 0.0],
            [-70.68551, -33.47992, 0.0],
            [-70.68533, -33.47895, 0.0],
            [-70.68539, -33.47845, 0.0],
            [-70.68582, -33.4769, 0.0],
            [-70.68626, -33.47622, 0.0],
            [-70.68803, -33.47674, 0.0],
            [-70.68876, -33.47702, 0.0],
            [-70.689301, -33.477088, 0.0],
          ],
          [
            [-70.6894, -33.4771, 0.0],
            [-70.68927, -33.47709, 0.0],
            [-70.689301, -33.477088, 0.0],
            [-70.6894, -33.4771, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "CERRO NAVIA",
        description: "Mauro Tamayo Rozas (ind)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.78212, -33.413722, 0.0],
            [-70.782029, -33.41587, 0.0],
            [-70.78099, -33.415911, 0.0],
            [-70.780912, -33.416151, 0.0],
            [-70.779968, -33.416647, 0.0],
            [-70.77925, -33.417342, 0.0],
            [-70.77901, -33.417636, 0.0],
            [-70.779011, -33.41845, 0.0],
            [-70.778867, -33.418704, 0.0],
            [-70.778163, -33.419546, 0.0],
            [-70.777546, -33.420192, 0.0],
            [-70.77709, -33.421787, 0.0],
            [-70.776749, -33.423064, 0.0],
            [-70.775298, -33.424297, 0.0],
            [-70.773416, -33.424579, 0.0],
            [-70.771705, -33.424884, 0.0],
            [-70.770956, -33.424906, 0.0],
            [-70.769587, -33.425188, 0.0],
            [-70.767625, -33.425342, 0.0],
            [-70.766643, -33.425946, 0.0],
            [-70.765226, -33.426637, 0.0],
            [-70.763218, -33.427585, 0.0],
            [-70.762393, -33.427736, 0.0],
            [-70.761338, -33.427694, 0.0],
            [-70.760176, -33.427372, 0.0],
            [-70.758091, -33.427374, 0.0],
            [-70.757059, -33.427224, 0.0],
            [-70.755485, -33.427419, 0.0],
            [-70.754456, -33.427893, 0.0],
            [-70.753598, -33.428057, 0.0],
            [-70.752265, -33.428296, 0.0],
            [-70.75064, -33.429085, 0.0],
            [-70.749634, -33.429344, 0.0],
            [-70.747328, -33.429806, 0.0],
            [-70.746745, -33.429952, 0.0],
            [-70.74082, -33.42991, 0.0],
            [-70.740852, -33.436367, 0.0],
            [-70.734763, -33.436368, 0.0],
            [-70.730905, -33.436246, 0.0],
            [-70.730201, -33.436266, 0.0],
            [-70.72891, -33.436408, 0.0],
            [-70.728156, -33.436672, 0.0],
            [-70.724246, -33.437743, 0.0],
            [-70.723709, -33.43869, 0.0],
            [-70.722472, -33.438946, 0.0],
            [-70.721501, -33.436691, 0.0],
            [-70.721194, -33.435224, 0.0],
            [-70.721052, -33.434179, 0.0],
            [-70.719985, -33.429647, 0.0],
            [-70.719282, -33.426817, 0.0],
            [-70.718949, -33.425601, 0.0],
            [-70.71777, -33.421139, 0.0],
            [-70.717182, -33.418578, 0.0],
            [-70.716926, -33.417417, 0.0],
            [-70.716695, -33.416374, 0.0],
            [-70.715828, -33.415141, 0.0],
            [-70.717267, -33.414546, 0.0],
            [-70.719371, -33.41321, 0.0],
            [-70.720648, -33.412662, 0.0],
            [-70.721473, -33.412436, 0.0],
            [-70.722635, -33.411676, 0.0],
            [-70.723889, -33.412124, 0.0],
            [-70.724646, -33.412538, 0.0],
            [-70.727973, -33.413659, 0.0],
            [-70.729075, -33.413271, 0.0],
            [-70.729921, -33.412906, 0.0],
            [-70.731857, -33.41253, 0.0],
            [-70.733438, -33.412401, 0.0],
            [-70.734014, -33.412369, 0.0],
            [-70.735737, -33.412153, 0.0],
            [-70.738818, -33.412367, 0.0],
            [-70.741028, -33.412289, 0.0],
            [-70.741948, -33.412213, 0.0],
            [-70.743669, -33.412189, 0.0],
            [-70.744454, -33.412404, 0.0],
            [-70.74556, -33.412457, 0.0],
            [-70.746991, -33.412434, 0.0],
            [-70.74921, -33.412227, 0.0],
            [-70.752052, -33.412311, 0.0],
            [-70.753973, -33.412589, 0.0],
            [-70.755641, -33.412749, 0.0],
            [-70.756825, -33.41264, 0.0],
            [-70.758994, -33.412347, 0.0],
            [-70.760299, -33.41254, 0.0],
            [-70.764162, -33.413379, 0.0],
            [-70.768889, -33.414479, 0.0],
            [-70.771634, -33.414584, 0.0],
            [-70.775873, -33.413716, 0.0],
            [-70.7776, -33.413875, 0.0],
            [-70.778656, -33.413919, 0.0],
            [-70.781431, -33.413912, 0.0],
            [-70.782067, -33.413793, 0.0],
            [-70.78212, -33.413722, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "CONCHALÍ",
        description: "René de la Vega Fuentes (ind)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.70014, -33.366267, 0.0],
            [-70.698304, -33.370684, 0.0],
            [-70.696079, -33.37544, 0.0],
            [-70.693405, -33.38143, 0.0],
            [-70.691235, -33.386455, 0.0],
            [-70.690836, -33.387199, 0.0],
            [-70.689246, -33.390525, 0.0],
            [-70.688249, -33.392358, 0.0],
            [-70.685355, -33.397206, 0.0],
            [-70.681883, -33.402973, 0.0],
            [-70.680942, -33.402769, 0.0],
            [-70.678424, -33.401988, 0.0],
            [-70.675105, -33.40077, 0.0],
            [-70.67212, -33.399668, 0.0],
            [-70.672029, -33.39985, 0.0],
            [-70.667572, -33.399557, 0.0],
            [-70.668088, -33.399088, 0.0],
            [-70.666599, -33.398937, 0.0],
            [-70.66614, -33.398937, 0.0],
            [-70.661047, -33.399365, 0.0],
            [-70.658242, -33.399572, 0.0],
            [-70.657757, -33.397586, 0.0],
            [-70.65753, -33.395831, 0.0],
            [-70.657264, -33.391893, 0.0],
            [-70.657446, -33.388859, 0.0],
            [-70.657315, -33.387526, 0.0],
            [-70.657225, -33.385884, 0.0],
            [-70.657353, -33.384295, 0.0],
            [-70.657612, -33.380667, 0.0],
            [-70.657741, -33.378379, 0.0],
            [-70.656732, -33.376439, 0.0],
            [-70.6641, -33.373635, 0.0],
            [-70.668495, -33.372139, 0.0],
            [-70.675509, -33.369351, 0.0],
            [-70.682794, -33.366753, 0.0],
            [-70.686078, -33.366456, 0.0],
            [-70.689638, -33.366217, 0.0],
            [-70.692697, -33.366077, 0.0],
            [-70.695587, -33.365972, 0.0],
            [-70.696604, -33.365921, 0.0],
            [-70.697205, -33.365938, 0.0],
            [-70.698824, -33.36603, 0.0],
            [-70.700112, -33.366243, 0.0],
            [-70.70014, -33.366267, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "EL BOSQUE",
        description: "Sadi Melo Moya (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.673904, -33.543362, 0.0],
            [-70.678766, -33.541554, 0.0],
            [-70.685389, -33.539143, 0.0],
            [-70.68543, -33.539193, 0.0],
            [-70.686514, -33.540989, 0.0],
            [-70.68624, -33.541229, 0.0],
            [-70.687154, -33.543, 0.0],
            [-70.688207, -33.545363, 0.0],
            [-70.68971, -33.548718, 0.0],
            [-70.693101, -33.556118, 0.0],
            [-70.695483, -33.555899, 0.0],
            [-70.696983, -33.56285, 0.0],
            [-70.697106, -33.563581, 0.0],
            [-70.697055, -33.56369, 0.0],
            [-70.696664, -33.563929, 0.0],
            [-70.697936, -33.566576, 0.0],
            [-70.700824, -33.572953, 0.0],
            [-70.698597, -33.573856, 0.0],
            [-70.692182, -33.576371, 0.0],
            [-70.682887, -33.579998, 0.0],
            [-70.68087, -33.58082, 0.0],
            [-70.676858, -33.58244, 0.0],
            [-70.676706, -33.582469, 0.0],
            [-70.670938, -33.583652, 0.0],
            [-70.663266, -33.585249, 0.0],
            [-70.662227, -33.581609, 0.0],
            [-70.661209, -33.577788, 0.0],
            [-70.660134, -33.573794, 0.0],
            [-70.659828, -33.573264, 0.0],
            [-70.659281, -33.571412, 0.0],
            [-70.656845, -33.562439, 0.0],
            [-70.656515, -33.561123, 0.0],
            [-70.655753, -33.558169, 0.0],
            [-70.654928, -33.554939, 0.0],
            [-70.65434, -33.552087, 0.0],
            [-70.654119, -33.551065, 0.0],
            [-70.6535, -33.548774, 0.0],
            [-70.656834, -33.548225, 0.0],
            [-70.65679, -33.548042, 0.0],
            [-70.666776, -33.546297, 0.0],
            [-70.668075, -33.545674, 0.0],
            [-70.668268, -33.545389, 0.0],
            [-70.671046, -33.54439, 0.0],
            [-70.673904, -33.543362, 0.0],
          ],
          [
            [-70.676177, -33.542544, 0.0],
            [-70.673613, -33.54347, 0.0],
            [-70.673904, -33.543362, 0.0],
            [-70.676177, -33.542544, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "HUECHURABA",
        description: "Carlos Cuadrado Prats (PPD)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.629011, -33.391776, 0.0],
            [-70.628209, -33.392176, 0.0],
            [-70.622657, -33.395534, 0.0],
            [-70.618148, -33.398806, 0.0],
            [-70.615534, -33.400844, 0.0],
            [-70.610169, -33.402837, 0.0],
            [-70.607511, -33.398546, 0.0],
            [-70.606613, -33.394848, 0.0],
            [-70.604993, -33.393393, 0.0],
            [-70.603005, -33.390404, 0.0],
            [-70.602077, -33.386492, 0.0],
            [-70.603865, -33.383319, 0.0],
            [-70.604226, -33.380772, 0.0],
            [-70.603361, -33.377479, 0.0],
            [-70.601318, -33.374632, 0.0],
            [-70.597661, -33.371807, 0.0],
            [-70.597143, -33.369631, 0.0],
            [-70.597095, -33.369352, 0.0],
            [-70.597093, -33.365801, 0.0],
            [-70.600099, -33.362998, 0.0],
            [-70.596426, -33.359664, 0.0],
            [-70.592614, -33.355721, 0.0],
            [-70.586561, -33.353439, 0.0],
            [-70.584546, -33.350639, 0.0],
            [-70.589731, -33.3444, 0.0],
            [-70.591804, -33.337377, 0.0],
            [-70.592987, -33.332945, 0.0],
            [-70.639332, -33.338712, 0.0],
            [-70.660544, -33.327818, 0.0],
            [-70.67955, -33.330189, 0.0],
            [-70.681562, -33.326519, 0.0],
            [-70.695469, -33.320049, 0.0],
            [-70.707183, -33.318417, 0.0],
            [-70.706319, -33.320789, 0.0],
            [-70.704852, -33.324521, 0.0],
            [-70.704046, -33.325534, 0.0],
            [-70.700545, -33.328061, 0.0],
            [-70.696654, -33.331076, 0.0],
            [-70.694079, -33.333469, 0.0],
            [-70.692514, -33.337208, 0.0],
            [-70.69234, -33.338803, 0.0],
            [-70.693709, -33.341291, 0.0],
            [-70.694083, -33.342945, 0.0],
            [-70.693827, -33.347887, 0.0],
            [-70.693599, -33.350161, 0.0],
            [-70.692658, -33.352981, 0.0],
            [-70.691651, -33.356698, 0.0],
            [-70.690366, -33.361314, 0.0],
            [-70.689703, -33.363082, 0.0],
            [-70.689631, -33.365311, 0.0],
            [-70.689631, -33.36621, 0.0],
            [-70.685638, -33.366558, 0.0],
            [-70.682657, -33.366781, 0.0],
            [-70.678879, -33.368201, 0.0],
            [-70.676232, -33.369132, 0.0],
            [-70.664083, -33.373711, 0.0],
            [-70.656602, -33.376431, 0.0],
            [-70.651794, -33.378106, 0.0],
            [-70.643965, -33.381256, 0.0],
            [-70.639429, -33.384089, 0.0],
            [-70.629946, -33.391023, 0.0],
            [-70.629011, -33.391776, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "INDEPENDENCIA",
        description: "Gonzalo Durán Baronti (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.68187, -33.403016, 0.0],
            [-70.680846, -33.404758, 0.0],
            [-70.680434, -33.405678, 0.0],
            [-70.680243, -33.406441, 0.0],
            [-70.679833, -33.409547, 0.0],
            [-70.679698, -33.410648, 0.0],
            [-70.679327, -33.413468, 0.0],
            [-70.679228, -33.414385, 0.0],
            [-70.678891, -33.416605, 0.0],
            [-70.678794, -33.417559, 0.0],
            [-70.678514, -33.418383, 0.0],
            [-70.677989, -33.419198, 0.0],
            [-70.677447, -33.419917, 0.0],
            [-70.676719, -33.420486, 0.0],
            [-70.675632, -33.421116, 0.0],
            [-70.675069, -33.421448, 0.0],
            [-70.674496, -33.421954, 0.0],
            [-70.674113, -33.422406, 0.0],
            [-70.672807, -33.424095, 0.0],
            [-70.671802, -33.425236, 0.0],
            [-70.671228, -33.42595, 0.0],
            [-70.6713804, -33.4262685, 0.0],
            [-70.671482, -33.4266775, 0.0],
            [-70.6666996, -33.4282169, 0.0],
            [-70.664548, -33.4287392, 0.0],
            [-70.6642525, -33.4290824, 0.0],
            [-70.6629551, -33.4295395, 0.0],
            [-70.661027, -33.4300305, 0.0],
            [-70.6599016, -33.4301578, 0.0],
            [-70.6589158, -33.4303585, 0.0],
            [-70.656411, -33.430878, 0.0],
            [-70.6555339, -33.431145, 0.0],
            [-70.651647, -33.431985, 0.0],
            [-70.651701, -33.431165, 0.0],
            [-70.651626, -33.430282, 0.0],
            [-70.651432, -33.427832, 0.0],
            [-70.651304, -33.426214, 0.0],
            [-70.651159, -33.424258, 0.0],
            [-70.651047, -33.423457, 0.0],
            [-70.650735, -33.421252, 0.0],
            [-70.650594, -33.420114, 0.0],
            [-70.650432, -33.419352, 0.0],
            [-70.650149, -33.417836, 0.0],
            [-70.6509, -33.417687, 0.0],
            [-70.651815, -33.417515, 0.0],
            [-70.652701, -33.417504, 0.0],
            [-70.652756, -33.415336, 0.0],
            [-70.652753, -33.414324, 0.0],
            [-70.652773, -33.412481, 0.0],
            [-70.652847, -33.411421, 0.0],
            [-70.65318, -33.4103, 0.0],
            [-70.653164, -33.409399, 0.0],
            [-70.65314, -33.408707, 0.0],
            [-70.653187, -33.407256, 0.0],
            [-70.653258, -33.407177, 0.0],
            [-70.653125, -33.406387, 0.0],
            [-70.65347, -33.406299, 0.0],
            [-70.654976, -33.405926, 0.0],
            [-70.657158, -33.405319, 0.0],
            [-70.657514, -33.403744, 0.0],
            [-70.658125, -33.40089, 0.0],
            [-70.658253, -33.400307, 0.0],
            [-70.658246, -33.39957, 0.0],
            [-70.659083, -33.399516, 0.0],
            [-70.6604, -33.399424, 0.0],
            [-70.662419, -33.399248, 0.0],
            [-70.663929, -33.399111, 0.0],
            [-70.665028, -33.399034, 0.0],
            [-70.666149, -33.398945, 0.0],
            [-70.666602, -33.398933, 0.0],
            [-70.668081, -33.39909, 0.0],
            [-70.667574, -33.399554, 0.0],
            [-70.672024, -33.399857, 0.0],
            [-70.672125, -33.399669, 0.0],
            [-70.673453, -33.400165, 0.0],
            [-70.678424, -33.401982, 0.0],
            [-70.680931, -33.402779, 0.0],
            [-70.68186, -33.40298, 0.0],
            [-70.68187, -33.403016, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LA CISTERNA",
        description: "Santiago Rebolledo Pizarro (PPD)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.678607, -33.526683, 0.0],
            [-70.68161, -33.532125, 0.0],
            [-70.685378, -33.539133, 0.0],
            [-70.680134, -33.541046, 0.0],
            [-70.676188, -33.542501, 0.0],
            [-70.668256, -33.545388, 0.0],
            [-70.668073, -33.545656, 0.0],
            [-70.666753, -33.546292, 0.0],
            [-70.663538, -33.54687, 0.0],
            [-70.660911, -33.54731, 0.0],
            [-70.656782, -33.548053, 0.0],
            [-70.656838, -33.548208, 0.0],
            [-70.65348, -33.548781, 0.0],
            [-70.652391, -33.54484, 0.0],
            [-70.651921, -33.542711, 0.0],
            [-70.651547, -33.541482, 0.0],
            [-70.651038, -33.539821, 0.0],
            [-70.649684, -33.534791, 0.0],
            [-70.648449, -33.530525, 0.0],
            [-70.647938, -33.528538, 0.0],
            [-70.647724, -33.528254, 0.0],
            [-70.647094, -33.526149, 0.0],
            [-70.646518, -33.523896, 0.0],
            [-70.646241, -33.523292, 0.0],
            [-70.644553, -33.517329, 0.0],
            [-70.646226, -33.516994, 0.0],
            [-70.651855, -33.515906, 0.0],
            [-70.65812, -33.514688, 0.0],
            [-70.661705, -33.513559, 0.0],
            [-70.665334, -33.512432, 0.0],
            [-70.670911, -33.510734, 0.0],
            [-70.678607, -33.526683, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LA GRANJA",
        description: "Felipe Delpin Aguilar (PDC)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.636531, -33.519247, 0.0],
            [-70.636339, -33.5212, 0.0],
            [-70.635956, -33.524226, 0.0],
            [-70.63557, -33.5274, 0.0],
            [-70.635508, -33.529016, 0.0],
            [-70.634839, -33.532254, 0.0],
            [-70.634658, -33.534063, 0.0],
            [-70.634572, -33.53693, 0.0],
            [-70.634453, -33.5396, 0.0],
            [-70.634061, -33.542763, 0.0],
            [-70.633631, -33.545877, 0.0],
            [-70.632951, -33.549398, 0.0],
            [-70.632048, -33.553552, 0.0],
            [-70.631481, -33.555858, 0.0],
            [-70.630609, -33.55979, 0.0],
            [-70.627448, -33.559052, 0.0],
            [-70.622265, -33.557879, 0.0],
            [-70.619982, -33.557378, 0.0],
            [-70.6177, -33.556867, 0.0],
            [-70.612341, -33.555636, 0.0],
            [-70.612907, -33.553413, 0.0],
            [-70.614123, -33.549236, 0.0],
            [-70.61491, -33.546358, 0.0],
            [-70.613769, -33.546556, 0.0],
            [-70.613236, -33.546546, 0.0],
            [-70.612298, -33.546556, 0.0],
            [-70.61062, -33.546634, 0.0],
            [-70.610449, -33.541095, 0.0],
            [-70.610352, -33.53949, 0.0],
            [-70.610251, -33.537882, 0.0],
            [-70.610167, -33.537235, 0.0],
            [-70.609889, -33.531151, 0.0],
            [-70.609858, -33.528444, 0.0],
            [-70.609736, -33.526037, 0.0],
            [-70.609935, -33.524523, 0.0],
            [-70.610095, -33.522969, 0.0],
            [-70.610372, -33.520888, 0.0],
            [-70.610911, -33.516678, 0.0],
            [-70.611031, -33.515426, 0.0],
            [-70.611283, -33.513075, 0.0],
            [-70.613789, -33.513096, 0.0],
            [-70.615064, -33.513089, 0.0],
            [-70.616354, -33.513415, 0.0],
            [-70.618072, -33.513833, 0.0],
            [-70.620279, -33.514454, 0.0],
            [-70.62108, -33.514897, 0.0],
            [-70.623834, -33.51623, 0.0],
            [-70.627155, -33.517697, 0.0],
            [-70.627919, -33.517844, 0.0],
            [-70.632238, -33.518322, 0.0],
            [-70.63661, -33.518741, 0.0],
            [-70.636531, -33.519247, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LA PINTANA",
        description: "Claudia Pizarro Peña (PDC)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.656802, -33.562489, 0.0],
            [-70.660058, -33.57392, 0.0],
            [-70.663131, -33.585346, 0.0],
            [-70.665863, -33.593616, 0.0],
            [-70.670668, -33.607378, 0.0],
            [-70.65565, -33.608976, 0.0],
            [-70.643301, -33.610055, 0.0],
            [-70.646749, -33.621741, 0.0],
            [-70.642758, -33.622223, 0.0],
            [-70.644595, -33.627606, 0.0],
            [-70.628738, -33.627958, 0.0],
            [-70.626495, -33.618624, 0.0],
            [-70.626866, -33.604849, 0.0],
            [-70.622348, -33.604342, 0.0],
            [-70.618092, -33.603839, 0.0],
            [-70.606086, -33.602118, 0.0],
            [-70.606763, -33.597486, 0.0],
            [-70.607377, -33.593279, 0.0],
            [-70.608378, -33.587619, 0.0],
            [-70.608589, -33.587229, 0.0],
            [-70.609479, -33.587088, 0.0],
            [-70.610115, -33.58081, 0.0],
            [-70.60948, -33.580775, 0.0],
            [-70.609649, -33.579675, 0.0],
            [-70.610634, -33.57417, 0.0],
            [-70.611425, -33.570189, 0.0],
            [-70.612594, -33.562228, 0.0],
            [-70.61052, -33.561958, 0.0],
            [-70.612315, -33.555638, 0.0],
            [-70.616247, -33.556536, 0.0],
            [-70.623514, -33.558102, 0.0],
            [-70.626367, -33.558853, 0.0],
            [-70.628601, -33.559371, 0.0],
            [-70.63061, -33.559763, 0.0],
            [-70.631066, -33.55813, 0.0],
            [-70.632072, -33.558163, 0.0],
            [-70.634159, -33.557909, 0.0],
            [-70.639539, -33.55718, 0.0],
            [-70.641777, -33.55687, 0.0],
            [-70.645487, -33.556455, 0.0],
            [-70.650558, -33.555604, 0.0],
            [-70.654932, -33.554983, 0.0],
            [-70.656802, -33.562489, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LO BARNECHEA",
        description: "Felipe Guevara Stephens (RN)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.43129, -33.125975, 0.0],
            [-70.43507, -33.128283, 0.0],
            [-70.440318, -33.13576, 0.0],
            [-70.446743, -33.145123, 0.0],
            [-70.452824, -33.158029, 0.0],
            [-70.45799, -33.161959, 0.0],
            [-70.461064, -33.168065, 0.0],
            [-70.464858, -33.174772, 0.0],
            [-70.46542, -33.175917, 0.0],
            [-70.464683, -33.184673, 0.0],
            [-70.468785, -33.188683, 0.0],
            [-70.470491, -33.193063, 0.0],
            [-70.471126, -33.195661, 0.0],
            [-70.470128, -33.201175, 0.0],
            [-70.472505, -33.205279, 0.0],
            [-70.473669, -33.208705, 0.0],
            [-70.476088, -33.213037, 0.0],
            [-70.483688, -33.214487, 0.0],
            [-70.490257, -33.214707, 0.0],
            [-70.49783, -33.214922, 0.0],
            [-70.503153, -33.215845, 0.0],
            [-70.508432, -33.214985, 0.0],
            [-70.515453, -33.218352, 0.0],
            [-70.516091, -33.224127, 0.0],
            [-70.520657, -33.230023, 0.0],
            [-70.526976, -33.234415, 0.0],
            [-70.532961, -33.235263, 0.0],
            [-70.537039, -33.235673, 0.0],
            [-70.54345, -33.234462, 0.0],
            [-70.54923, -33.237328, 0.0],
            [-70.556562, -33.237414, 0.0],
            [-70.562421, -33.241599, 0.0],
            [-70.562773, -33.247899, 0.0],
            [-70.566156, -33.251493, 0.0],
            [-70.569626, -33.255296, 0.0],
            [-70.57231, -33.259928, 0.0],
            [-70.574808, -33.264076, 0.0],
            [-70.575424, -33.266468, 0.0],
            [-70.573148, -33.27256, 0.0],
            [-70.573959, -33.280827, 0.0],
            [-70.577057, -33.286173, 0.0],
            [-70.584547, -33.28982, 0.0],
            [-70.593409, -33.295427, 0.0],
            [-70.59554, -33.301059, 0.0],
            [-70.604016, -33.307609, 0.0],
            [-70.603505, -33.308756, 0.0],
            [-70.592771, -33.332824, 0.0],
            [-70.589588, -33.344179, 0.0],
            [-70.587501, -33.346928, 0.0],
            [-70.584088, -33.350546, 0.0],
            [-70.580572, -33.350902, 0.0],
            [-70.579442, -33.351867, 0.0],
            [-70.57785, -33.352094, 0.0],
            [-70.57541, -33.353174, 0.0],
            [-70.573531, -33.355424, 0.0],
            [-70.57064, -33.357012, 0.0],
            [-70.567503, -33.355457, 0.0],
            [-70.565213, -33.356821, 0.0],
            [-70.56055, -33.358011, 0.0],
            [-70.557075, -33.358834, 0.0],
            [-70.550755, -33.363079, 0.0],
            [-70.540286, -33.370601, 0.0],
            [-70.534707, -33.370272, 0.0],
            [-70.530322, -33.370239, 0.0],
            [-70.526553, -33.369737, 0.0],
            [-70.524925, -33.369209, 0.0],
            [-70.520533, -33.369738, 0.0],
            [-70.519293, -33.370553, 0.0],
            [-70.518335, -33.371562, 0.0],
            [-70.517571, -33.372784, 0.0],
            [-70.515972, -33.372005, 0.0],
            [-70.513172, -33.371181, 0.0],
            [-70.511477, -33.370744, 0.0],
            [-70.509449, -33.370431, 0.0],
            [-70.506039, -33.370027, 0.0],
            [-70.504489, -33.36962, 0.0],
            [-70.502026, -33.368539, 0.0],
            [-70.499331, -33.367246, 0.0],
            [-70.496381, -33.367423, 0.0],
            [-70.495898, -33.367371, 0.0],
            [-70.494908, -33.367048, 0.0],
            [-70.493918, -33.366278, 0.0],
            [-70.492742, -33.364984, 0.0],
            [-70.491596, -33.364951, 0.0],
            [-70.488921, -33.365058, 0.0],
            [-70.483354, -33.365048, 0.0],
            [-70.481436, -33.365103, 0.0],
            [-70.478278, -33.365047, 0.0],
            [-70.474459, -33.36485, 0.0],
            [-70.472554, -33.365559, 0.0],
            [-70.471997, -33.365848, 0.0],
            [-70.469524, -33.366746, 0.0],
            [-70.469203, -33.370953, 0.0],
            [-70.467805, -33.37161, 0.0],
            [-70.465591, -33.372748, 0.0],
            [-70.463661, -33.37265, 0.0],
            [-70.461183, -33.372615, 0.0],
            [-70.456901, -33.372501, 0.0],
            [-70.454211, -33.372404, 0.0],
            [-70.448, -33.372616, 0.0],
            [-70.445303, -33.372657, 0.0],
            [-70.441334, -33.373194, 0.0],
            [-70.439314, -33.372852, 0.0],
            [-70.433826, -33.371768, 0.0],
            [-70.43129, -33.37229, 0.0],
            [-70.430782, -33.372395, 0.0],
            [-70.428645, -33.371657, 0.0],
            [-70.425029, -33.370424, 0.0],
            [-70.423114, -33.369759, 0.0],
            [-70.419571, -33.370435, 0.0],
            [-70.414864, -33.371285, 0.0],
            [-70.411605, -33.371952, 0.0],
            [-70.410516, -33.372209, 0.0],
            [-70.407211, -33.373341, 0.0],
            [-70.406498, -33.373529, 0.0],
            [-70.403568, -33.373857, 0.0],
            [-70.398407, -33.37425, 0.0],
            [-70.395818, -33.374384, 0.0],
            [-70.393156, -33.374528, 0.0],
            [-70.392805, -33.374538, 0.0],
            [-70.387663, -33.377435, 0.0],
            [-70.386003, -33.378401, 0.0],
            [-70.384133, -33.379609, 0.0],
            [-70.382464, -33.38055, 0.0],
            [-70.379325, -33.382481, 0.0],
            [-70.377632, -33.384501, 0.0],
            [-70.375198, -33.387296, 0.0],
            [-70.375297, -33.390315, 0.0],
            [-70.375394, -33.395235, 0.0],
            [-70.378157, -33.398526, 0.0],
            [-70.3762, -33.399608, 0.0],
            [-70.372162, -33.401784, 0.0],
            [-70.368223, -33.403836, 0.0],
            [-70.365732, -33.409505, 0.0],
            [-70.363926, -33.412881, 0.0],
            [-70.363134, -33.414238, 0.0],
            [-70.360418, -33.419111, 0.0],
            [-70.35831, -33.422858, 0.0],
            [-70.356267, -33.426855, 0.0],
            [-70.354929, -33.429339, 0.0],
            [-70.351921, -33.435156, 0.0],
            [-70.349016, -33.440607, 0.0],
            [-70.347788, -33.44303, 0.0],
            [-70.344841, -33.448694, 0.0],
            [-70.342369, -33.450269, 0.0],
            [-70.337608, -33.453295, 0.0],
            [-70.332145, -33.456803, 0.0],
            [-70.330349, -33.457931, 0.0],
            [-70.32814, -33.470075, 0.0],
            [-70.328699, -33.47079, 0.0],
            [-70.327611, -33.470418, 0.0],
            [-70.324978, -33.470434, 0.0],
            [-70.320757, -33.469378, 0.0],
            [-70.317638, -33.467174, 0.0],
            [-70.316031, -33.463881, 0.0],
            [-70.314046, -33.462701, 0.0],
            [-70.313048, -33.462609, 0.0],
            [-70.306507, -33.462464, 0.0],
            [-70.304377, -33.461626, 0.0],
            [-70.302997, -33.460508, 0.0],
            [-70.302997, -33.460327, 0.0],
            [-70.301763, -33.458169, 0.0],
            [-70.301655, -33.457226, 0.0],
            [-70.301432, -33.456613, 0.0],
            [-70.300298, -33.455715, 0.0],
            [-70.298712, -33.454351, 0.0],
            [-70.298016, -33.453495, 0.0],
            [-70.298062, -33.452288, 0.0],
            [-70.298475, -33.451349, 0.0],
            [-70.298185, -33.450337, 0.0],
            [-70.297202, -33.448732, 0.0],
            [-70.29586, -33.448234, 0.0],
            [-70.294352, -33.448163, 0.0],
            [-70.292879, -33.4483, 0.0],
            [-70.288648, -33.449096, 0.0],
            [-70.287567, -33.449404, 0.0],
            [-70.285851, -33.449399, 0.0],
            [-70.284354, -33.449672, 0.0],
            [-70.283284, -33.450679, 0.0],
            [-70.282655, -33.450711, 0.0],
            [-70.282048, -33.451677, 0.0],
            [-70.281852, -33.452191, 0.0],
            [-70.28127, -33.452431, 0.0],
            [-70.280509, -33.452329, 0.0],
            [-70.279547, -33.452362, 0.0],
            [-70.278817, -33.451667, 0.0],
            [-70.278016, -33.451284, 0.0],
            [-70.276185, -33.450832, 0.0],
            [-70.272237, -33.447214, 0.0],
            [-70.26957, -33.444716, 0.0],
            [-70.267808, -33.443319, 0.0],
            [-70.267241, -33.442957, 0.0],
            [-70.265298, -33.442325, 0.0],
            [-70.264373, -33.441797, 0.0],
            [-70.263371, -33.441437, 0.0],
            [-70.261174, -33.441685, 0.0],
            [-70.256095, -33.441163, 0.0],
            [-70.255611, -33.441078, 0.0],
            [-70.255645, -33.440387, 0.0],
            [-70.254247, -33.439657, 0.0],
            [-70.252615, -33.436813, 0.0],
            [-70.251918, -33.43637, 0.0],
            [-70.248994, -33.435785, 0.0],
            [-70.24813, -33.435174, 0.0],
            [-70.247421, -33.434888, 0.0],
            [-70.247356, -33.434464, 0.0],
            [-70.245651, -33.434124, 0.0],
            [-70.245229, -33.433663, 0.0],
            [-70.244023, -33.433639, 0.0],
            [-70.243324, -33.4332, 0.0],
            [-70.243014, -33.433196, 0.0],
            [-70.242886, -33.432003, 0.0],
            [-70.243185, -33.431369, 0.0],
            [-70.242639, -33.431269, 0.0],
            [-70.242121, -33.429499, 0.0],
            [-70.241376, -33.429402, 0.0],
            [-70.239704, -33.42755, 0.0],
            [-70.238778, -33.427481, 0.0],
            [-70.238257, -33.427132, 0.0],
            [-70.235855, -33.424044, 0.0],
            [-70.235865, -33.42292, 0.0],
            [-70.235481, -33.422562, 0.0],
            [-70.235499, -33.421583, 0.0],
            [-70.234058, -33.419455, 0.0],
            [-70.232869, -33.419121, 0.0],
            [-70.229598, -33.417444, 0.0],
            [-70.229123, -33.416965, 0.0],
            [-70.228257, -33.416537, 0.0],
            [-70.227443, -33.415235, 0.0],
            [-70.226202, -33.414668, 0.0],
            [-70.224812, -33.41293, 0.0],
            [-70.223632, -33.411634, 0.0],
            [-70.221968, -33.411554, 0.0],
            [-70.219616, -33.410827, 0.0],
            [-70.218828, -33.409729, 0.0],
            [-70.2177, -33.407867, 0.0],
            [-70.217414, -33.406222, 0.0],
            [-70.216197, -33.404311, 0.0],
            [-70.216767, -33.401911, 0.0],
            [-70.217099, -33.399383, 0.0],
            [-70.215992, -33.395974, 0.0],
            [-70.213157, -33.392941, 0.0],
            [-70.208889, -33.391264, 0.0],
            [-70.206912, -33.391068, 0.0],
            [-70.205287, -33.3904, 0.0],
            [-70.204274, -33.389093, 0.0],
            [-70.202746, -33.388427, 0.0],
            [-70.201612, -33.386772, 0.0],
            [-70.201123, -33.386199, 0.0],
            [-70.201149, -33.385481, 0.0],
            [-70.199767, -33.383375, 0.0],
            [-70.197815, -33.381563, 0.0],
            [-70.197618, -33.379508, 0.0],
            [-70.197263, -33.37852, 0.0],
            [-70.196634, -33.37704, 0.0],
            [-70.196516, -33.376482, 0.0],
            [-70.196127, -33.376047, 0.0],
            [-70.194931, -33.375033, 0.0],
            [-70.194093, -33.373493, 0.0],
            [-70.193365, -33.372163, 0.0],
            [-70.193418, -33.369983, 0.0],
            [-70.192778, -33.367563, 0.0],
            [-70.190997, -33.366174, 0.0],
            [-70.190478, -33.365007, 0.0],
            [-70.190294, -33.363592, 0.0],
            [-70.189736, -33.362832, 0.0],
            [-70.188545, -33.360837, 0.0],
            [-70.186117, -33.360082, 0.0],
            [-70.184346, -33.360096, 0.0],
            [-70.181021, -33.35877, 0.0],
            [-70.179409, -33.356525, 0.0],
            [-70.17841, -33.354563, 0.0],
            [-70.177858, -33.354054, 0.0],
            [-70.176967, -33.353654, 0.0],
            [-70.176465, -33.352096, 0.0],
            [-70.177289, -33.350588, 0.0],
            [-70.178596, -33.349385, 0.0],
            [-70.178316, -33.346664, 0.0],
            [-70.177549, -33.345945, 0.0],
            [-70.176288, -33.345507, 0.0],
            [-70.175045, -33.344182, 0.0],
            [-70.175063, -33.342776, 0.0],
            [-70.175749, -33.342664, 0.0],
            [-70.176319, -33.341753, 0.0],
            [-70.176941, -33.34176, 0.0],
            [-70.176905, -33.341315, 0.0],
            [-70.177728, -33.340945, 0.0],
            [-70.178253, -33.33896, 0.0],
            [-70.179924, -33.336504, 0.0],
            [-70.181087, -33.334945, 0.0],
            [-70.18157, -33.334119, 0.0],
            [-70.180765, -33.331957, 0.0],
            [-70.180751, -33.329223, 0.0],
            [-70.18106, -33.328251, 0.0],
            [-70.181648, -33.327257, 0.0],
            [-70.181964, -33.326356, 0.0],
            [-70.181815, -33.322849, 0.0],
            [-70.181917, -33.320271, 0.0],
            [-70.182157, -33.318569, 0.0],
            [-70.183383, -33.316547, 0.0],
            [-70.183736, -33.314834, 0.0],
            [-70.18441, -33.313282, 0.0],
            [-70.185376, -33.310502, 0.0],
            [-70.186231, -33.30759, 0.0],
            [-70.187408, -33.305004, 0.0],
            [-70.188289, -33.302485, 0.0],
            [-70.189778, -33.299052, 0.0],
            [-70.191031, -33.296743, 0.0],
            [-70.192613, -33.295843, 0.0],
            [-70.193197, -33.294594, 0.0],
            [-70.194441, -33.293894, 0.0],
            [-70.195699, -33.291777, 0.0],
            [-70.197124, -33.289988, 0.0],
            [-70.198, -33.28833, 0.0],
            [-70.198004, -33.286336, 0.0],
            [-70.197611, -33.284459, 0.0],
            [-70.197627, -33.283301, 0.0],
            [-70.198337, -33.278431, 0.0],
            [-70.198664, -33.275181, 0.0],
            [-70.198779, -33.274118, 0.0],
            [-70.198288, -33.272168, 0.0],
            [-70.197837, -33.271181, 0.0],
            [-70.197938, -33.268512, 0.0],
            [-70.198141, -33.267679, 0.0],
            [-70.198519, -33.266797, 0.0],
            [-70.199198, -33.264874, 0.0],
            [-70.20019, -33.26406, 0.0],
            [-70.200862, -33.263042, 0.0],
            [-70.201918, -33.262035, 0.0],
            [-70.202876, -33.260271, 0.0],
            [-70.208691, -33.256516, 0.0],
            [-70.20958, -33.256073, 0.0],
            [-70.209904, -33.254633, 0.0],
            [-70.209535, -33.253937, 0.0],
            [-70.209401, -33.252039, 0.0],
            [-70.208078, -33.24934, 0.0],
            [-70.2076, -33.248067, 0.0],
            [-70.20773, -33.246318, 0.0],
            [-70.209296, -33.244949, 0.0],
            [-70.210032, -33.244509, 0.0],
            [-70.211708, -33.243508, 0.0],
            [-70.213281, -33.242183, 0.0],
            [-70.214708, -33.241464, 0.0],
            [-70.216218, -33.240215, 0.0],
            [-70.216496, -33.23882, 0.0],
            [-70.215444, -33.236393, 0.0],
            [-70.214621, -33.23518, 0.0],
            [-70.213715, -33.234425, 0.0],
            [-70.213133, -33.233557, 0.0],
            [-70.213449, -33.232563, 0.0],
            [-70.215293, -33.232379, 0.0],
            [-70.22013, -33.230522, 0.0],
            [-70.221216, -33.230127, 0.0],
            [-70.22179, -33.229331, 0.0],
            [-70.221785, -33.227788, 0.0],
            [-70.221995, -33.227341, 0.0],
            [-70.220913, -33.224313, 0.0],
            [-70.220077, -33.223531, 0.0],
            [-70.219724, -33.222562, 0.0],
            [-70.218072, -33.219985, 0.0],
            [-70.217357, -33.218811, 0.0],
            [-70.21683, -33.216405, 0.0],
            [-70.216835, -33.215642, 0.0],
            [-70.217533, -33.213859, 0.0],
            [-70.219811, -33.211872, 0.0],
            [-70.220841, -33.21102, 0.0],
            [-70.222992, -33.209894, 0.0],
            [-70.225165, -33.207925, 0.0],
            [-70.226316, -33.206094, 0.0],
            [-70.227137, -33.204476, 0.0],
            [-70.22791, -33.203259, 0.0],
            [-70.228639, -33.201516, 0.0],
            [-70.229357, -33.200741, 0.0],
            [-70.229423, -33.20048, 0.0],
            [-70.232871, -33.20042, 0.0],
            [-70.235252, -33.199506, 0.0],
            [-70.236028, -33.198847, 0.0],
            [-70.237326, -33.198221, 0.0],
            [-70.238656, -33.196454, 0.0],
            [-70.239015, -33.19511, 0.0],
            [-70.240331, -33.191247, 0.0],
            [-70.240764, -33.189663, 0.0],
            [-70.240593, -33.187094, 0.0],
            [-70.241952, -33.184407, 0.0],
            [-70.253913, -33.181595, 0.0],
            [-70.255474, -33.179252, 0.0],
            [-70.256373, -33.177347, 0.0],
            [-70.260328, -33.17048, 0.0],
            [-70.267158, -33.165418, 0.0],
            [-70.276373, -33.156114, 0.0],
            [-70.290182, -33.146345, 0.0],
            [-70.307286, -33.135264, 0.0],
            [-70.33174, -33.119895, 0.0],
            [-70.346331, -33.11255, 0.0],
            [-70.369039, -33.106212, 0.0],
            [-70.379985, -33.102431, 0.0],
            [-70.381356, -33.10331, 0.0],
            [-70.387707, -33.104756, 0.0],
            [-70.392659, -33.111216, 0.0],
            [-70.397673, -33.114612, 0.0],
            [-70.405728, -33.117378, 0.0],
            [-70.422154, -33.119461, 0.0],
            [-70.426333, -33.122949, 0.0],
            [-70.43129, -33.125975, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LO ESPEJO",
        description: "Miguel Bruna Silva (PPD)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.695203, -33.502031, 0.0],
            [-70.696251, -33.504228, 0.0],
            [-70.698202, -33.508212, 0.0],
            [-70.699816, -33.511488, 0.0],
            [-70.701131, -33.514193, 0.0],
            [-70.702732, -33.517641, 0.0],
            [-70.703865, -33.520237, 0.0],
            [-70.704915, -33.522678, 0.0],
            [-70.705446, -33.524017, 0.0],
            [-70.705567, -33.524937, 0.0],
            [-70.705646, -33.525733, 0.0],
            [-70.70581, -33.528198, 0.0],
            [-70.705994, -33.529616, 0.0],
            [-70.7062, -33.53143, 0.0],
            [-70.705358, -33.53175, 0.0],
            [-70.699513, -33.533948, 0.0],
            [-70.696419, -33.535088, 0.0],
            [-70.69078, -33.537209, 0.0],
            [-70.689212, -33.537764, 0.0],
            [-70.685417, -33.539125, 0.0],
            [-70.684438, -33.53738, 0.0],
            [-70.68323, -33.535154, 0.0],
            [-70.682223, -33.533309, 0.0],
            [-70.68145, -33.531888, 0.0],
            [-70.680605, -33.530254, 0.0],
            [-70.6796, -33.528547, 0.0],
            [-70.678594, -33.526588, 0.0],
            [-70.677923, -33.525393, 0.0],
            [-70.676118, -33.521538, 0.0],
            [-70.67407, -33.517237, 0.0],
            [-70.672827, -33.514617, 0.0],
            [-70.670958, -33.51075, 0.0],
            [-70.672719, -33.510074, 0.0],
            [-70.673345, -33.50978, 0.0],
            [-70.675124, -33.509013, 0.0],
            [-70.676805, -33.508321, 0.0],
            [-70.677754, -33.50832, 0.0],
            [-70.678454, -33.508251, 0.0],
            [-70.679361, -33.507768, 0.0],
            [-70.682232, -33.50657, 0.0],
            [-70.683886, -33.505814, 0.0],
            [-70.684339, -33.505835, 0.0],
            [-70.685974, -33.505225, 0.0],
            [-70.688221, -33.504403, 0.0],
            [-70.690341, -33.503686, 0.0],
            [-70.693453, -33.502669, 0.0],
            [-70.695076, -33.502075, 0.0],
            [-70.695203, -33.502031, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LO PRADO",
        description: "Maximiliano Ríos Galleguillos (PPD)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.740855, -33.436389, 0.0],
            [-70.74063, -33.440046, 0.0],
            [-70.740489, -33.441822, 0.0],
            [-70.740552, -33.44409, 0.0],
            [-70.740586, -33.445751, 0.0],
            [-70.739533, -33.446518, 0.0],
            [-70.739472, -33.447331, 0.0],
            [-70.739168, -33.451013, 0.0],
            [-70.738882, -33.453499, 0.0],
            [-70.735709, -33.454136, 0.0],
            [-70.731222, -33.454977, 0.0],
            [-70.729137, -33.455352, 0.0],
            [-70.726812, -33.455789, 0.0],
            [-70.720219, -33.457094, 0.0],
            [-70.718411, -33.457474, 0.0],
            [-70.71258, -33.458574, 0.0],
            [-70.711089, -33.458566, 0.0],
            [-70.708356, -33.458002, 0.0],
            [-70.70557, -33.457268, 0.0],
            [-70.705718, -33.456051, 0.0],
            [-70.706077, -33.453174, 0.0],
            [-70.706263, -33.452232, 0.0],
            [-70.706836, -33.450779, 0.0],
            [-70.706886, -33.450087, 0.0],
            [-70.70709, -33.448789, 0.0],
            [-70.707265, -33.447221, 0.0],
            [-70.707331, -33.446134, 0.0],
            [-70.707351, -33.443973, 0.0],
            [-70.707333, -33.442388, 0.0],
            [-70.707232, -33.441465, 0.0],
            [-70.707232, -33.441021, 0.0],
            [-70.707043, -33.438075, 0.0],
            [-70.708741, -33.438104, 0.0],
            [-70.710365, -33.438181, 0.0],
            [-70.712735, -33.437826, 0.0],
            [-70.715868, -33.437421, 0.0],
            [-70.716997, -33.43725, 0.0],
            [-70.719648, -33.436894, 0.0],
            [-70.721451, -33.436646, 0.0],
            [-70.722468, -33.43892, 0.0],
            [-70.723726, -33.438723, 0.0],
            [-70.724245, -33.437745, 0.0],
            [-70.72477, -33.437617, 0.0],
            [-70.725791, -33.437305, 0.0],
            [-70.728156, -33.436681, 0.0],
            [-70.728922, -33.436406, 0.0],
            [-70.730239, -33.436239, 0.0],
            [-70.731643, -33.436287, 0.0],
            [-70.734172, -33.436349, 0.0],
            [-70.734775, -33.436367, 0.0],
            [-70.740811, -33.436362, 0.0],
            [-70.740855, -33.436389, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "MAIPÚ",
        description: "Catherine Barriga Guerra (ind/UDI)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.928626, -33.490675, 0.0],
            [-70.929445, -33.49452, 0.0],
            [-70.931323, -33.498633, 0.0],
            [-70.934064, -33.501087, 0.0],
            [-70.933052, -33.506296, 0.0],
            [-70.927213, -33.508936, 0.0],
            [-70.924726, -33.510457, 0.0],
            [-70.923013, -33.511361, 0.0],
            [-70.920511, -33.51095, 0.0],
            [-70.91393, -33.508487, 0.0],
            [-70.903788, -33.514168, 0.0],
            [-70.899113, -33.518679, 0.0],
            [-70.895375, -33.519838, 0.0],
            [-70.892003, -33.524679, 0.0],
            [-70.888881, -33.528013, 0.0],
            [-70.875804, -33.532379, 0.0],
            [-70.866477, -33.530818, 0.0],
            [-70.860095, -33.530737, 0.0],
            [-70.854026, -33.537525, 0.0],
            [-70.843376, -33.534171, 0.0],
            [-70.840154, -33.534756, 0.0],
            [-70.838116, -33.53391, 0.0],
            [-70.820479, -33.538001, 0.0],
            [-70.819582, -33.537544, 0.0],
            [-70.812529, -33.530062, 0.0],
            [-70.811995, -33.529616, 0.0],
            [-70.809882, -33.527849, 0.0],
            [-70.805457, -33.530126, 0.0],
            [-70.805704, -33.534677, 0.0],
            [-70.811995, -33.537327, 0.0],
            [-70.813769, -33.538074, 0.0],
            [-70.814688, -33.541007, 0.0],
            [-70.817519, -33.542421, 0.0],
            [-70.811995, -33.54619, 0.0],
            [-70.807659, -33.549148, 0.0],
            [-70.798158, -33.555012, 0.0],
            [-70.797146, -33.556711, 0.0],
            [-70.783708, -33.567736, 0.0],
            [-70.779744, -33.569016, 0.0],
            [-70.76752, -33.571853, 0.0],
            [-70.763043, -33.565978, 0.0],
            [-70.751692, -33.553522, 0.0],
            [-70.744738, -33.545405, 0.0],
            [-70.741363, -33.541674, 0.0],
            [-70.736095, -33.535685, 0.0],
            [-70.729899, -33.528302, 0.0],
            [-70.726396, -33.523922, 0.0],
            [-70.719908, -33.515854, 0.0],
            [-70.71847, -33.513944, 0.0],
            [-70.719143, -33.51338, 0.0],
            [-70.720358, -33.512437, 0.0],
            [-70.721652, -33.511095, 0.0],
            [-70.72295, -33.509869, 0.0],
            [-70.723541, -33.509539, 0.0],
            [-70.72504, -33.509822, 0.0],
            [-70.726851, -33.509915, 0.0],
            [-70.732062, -33.510015, 0.0],
            [-70.735516, -33.510084, 0.0],
            [-70.73954, -33.509963, 0.0],
            [-70.736606, -33.503442, 0.0],
            [-70.735643, -33.501567, 0.0],
            [-70.734929, -33.499886, 0.0],
            [-70.734055, -33.498013, 0.0],
            [-70.732998, -33.496309, 0.0],
            [-70.731745, -33.495078, 0.0],
            [-70.730909, -33.494509, 0.0],
            [-70.731386, -33.493868, 0.0],
            [-70.731346, -33.492055, 0.0],
            [-70.731265, -33.490307, 0.0],
            [-70.731278, -33.489223, 0.0],
            [-70.7313, -33.486335, 0.0],
            [-70.731285, -33.484585, 0.0],
            [-70.73119, -33.482532, 0.0],
            [-70.729852, -33.482342, 0.0],
            [-70.729254, -33.482603, 0.0],
            [-70.728543, -33.482769, 0.0],
            [-70.727832, -33.482817, 0.0],
            [-70.727319, -33.482531, 0.0],
            [-70.726864, -33.482032, 0.0],
            [-70.726068, -33.481865, 0.0],
            [-70.725442, -33.482103, 0.0],
            [-70.724533, -33.482385, 0.0],
            [-70.724561, -33.481723, 0.0],
            [-70.72439, -33.481225, 0.0],
            [-70.723736, -33.480608, 0.0],
            [-70.723565, -33.480134, 0.0],
            [-70.723451, -33.479209, 0.0],
            [-70.723194, -33.478143, 0.0],
            [-70.722738, -33.476578, 0.0],
            [-70.722568, -33.474989, 0.0],
            [-70.722142, -33.473307, 0.0],
            [-70.722682, -33.472881, 0.0],
            [-70.724272, -33.472311, 0.0],
            [-70.726703, -33.471588, 0.0],
            [-70.728955, -33.470946, 0.0],
            [-70.733921, -33.46945, 0.0],
            [-70.737185, -33.471728, 0.0],
            [-70.737411, -33.46857, 0.0],
            [-70.74112, -33.467811, 0.0],
            [-70.74636, -33.466857, 0.0],
            [-70.753623, -33.465518, 0.0],
            [-70.759047, -33.464608, 0.0],
            [-70.760273, -33.464251, 0.0],
            [-70.762072, -33.463892, 0.0],
            [-70.763145, -33.463257, 0.0],
            [-70.764976, -33.458878, 0.0],
            [-70.76948, -33.458751, 0.0],
            [-70.773613, -33.458497, 0.0],
            [-70.777677, -33.458308, 0.0],
            [-70.782776, -33.457931, 0.0],
            [-70.788172, -33.456998, 0.0],
            [-70.790693, -33.461005, 0.0],
            [-70.794393, -33.460568, 0.0],
            [-70.798611, -33.460254, 0.0],
            [-70.802535, -33.460063, 0.0],
            [-70.806314, -33.460243, 0.0],
            [-70.804839, -33.462158, 0.0],
            [-70.810849, -33.46363, 0.0],
            [-70.811995, -33.464393, 0.0],
            [-70.814253, -33.465895, 0.0],
            [-70.817203, -33.466832, 0.0],
            [-70.821385, -33.467628, 0.0],
            [-70.824648, -33.468057, 0.0],
            [-70.829718, -33.465164, 0.0],
            [-70.835862, -33.467052, 0.0],
            [-70.838038, -33.466556, 0.0],
            [-70.84189, -33.466546, 0.0],
            [-70.842376, -33.469165, 0.0],
            [-70.845259, -33.4731, 0.0],
            [-70.847384, -33.478212, 0.0],
            [-70.851612, -33.478031, 0.0],
            [-70.856345, -33.476642, 0.0],
            [-70.858318, -33.476866, 0.0],
            [-70.879753, -33.480381, 0.0],
            [-70.896263, -33.479094, 0.0],
            [-70.903507, -33.48254, 0.0],
            [-70.915813, -33.487835, 0.0],
            [-70.92368, -33.48724, 0.0],
            [-70.928626, -33.490675, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "PADRE HURTADO",
        description: "José Arellano Merino (RN)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.811995, -33.595958, 0.0],
            [-70.809615, -33.592822, 0.0],
            [-70.807998, -33.589501, 0.0],
            [-70.80702, -33.589085, 0.0],
            [-70.807393, -33.588535, 0.0],
            [-70.803367, -33.580788, 0.0],
            [-70.798824, -33.572938, 0.0],
            [-70.789689, -33.577589, 0.0],
            [-70.783704, -33.572481, 0.0],
            [-70.779679, -33.569057, 0.0],
            [-70.78262, -33.568229, 0.0],
            [-70.783673, -33.567747, 0.0],
            [-70.784699, -33.567135, 0.0],
            [-70.797354, -33.556555, 0.0],
            [-70.798195, -33.554946, 0.0],
            [-70.808768, -33.548661, 0.0],
            [-70.811995, -33.546406, 0.0],
            [-70.817611, -33.542481, 0.0],
            [-70.814876, -33.540835, 0.0],
            [-70.813781, -33.538196, 0.0],
            [-70.811995, -33.537393, 0.0],
            [-70.805827, -33.534617, 0.0],
            [-70.805549, -33.530197, 0.0],
            [-70.809737, -33.527999, 0.0],
            [-70.811995, -33.530161, 0.0],
            [-70.820181, -33.537997, 0.0],
            [-70.838081, -33.533922, 0.0],
            [-70.839815, -33.534866, 0.0],
            [-70.843395, -33.534095, 0.0],
            [-70.853926, -33.53752, 0.0],
            [-70.857534, -33.533619, 0.0],
            [-70.860105, -33.53086, 0.0],
            [-70.866506, -33.530808, 0.0],
            [-70.875715, -33.532398, 0.0],
            [-70.881634, -33.530408, 0.0],
            [-70.889039, -33.527963, 0.0],
            [-70.892569, -33.52385, 0.0],
            [-70.895363, -33.519904, 0.0],
            [-70.89913, -33.518742, 0.0],
            [-70.903834, -33.514146, 0.0],
            [-70.909488, -33.511065, 0.0],
            [-70.913875, -33.508568, 0.0],
            [-70.917601, -33.509912, 0.0],
            [-70.920376, -33.510918, 0.0],
            [-70.923463, -33.511567, 0.0],
            [-70.924054, -33.511271, 0.0],
            [-70.92451, -33.51487, 0.0],
            [-70.925922, -33.518589, 0.0],
            [-70.925273, -33.520584, 0.0],
            [-70.925812, -33.52213, 0.0],
            [-70.925866, -33.524124, 0.0],
            [-70.926909, -33.526075, 0.0],
            [-70.927791, -33.529089, 0.0],
            [-70.92816, -33.531381, 0.0],
            [-70.929566, -33.533207, 0.0],
            [-70.929623, -33.534593, 0.0],
            [-70.929405, -33.536392, 0.0],
            [-70.930667, -33.538392, 0.0],
            [-70.930967, -33.539789, 0.0],
            [-70.932863, -33.545618, 0.0],
            [-70.934718, -33.547506, 0.0],
            [-70.935089, -33.548515, 0.0],
            [-70.936604, -33.550757, 0.0],
            [-70.93846, -33.551161, 0.0],
            [-70.940263, -33.551953, 0.0],
            [-70.940749, -33.554669, 0.0],
            [-70.944039, -33.556259, 0.0],
            [-70.945779, -33.557219, 0.0],
            [-70.949485, -33.55942, 0.0],
            [-70.952146, -33.559421, 0.0],
            [-70.952281, -33.56419, 0.0],
            [-70.947961, -33.571351, 0.0],
            [-70.939975, -33.577405, 0.0],
            [-70.940242, -33.57912, 0.0],
            [-70.940763, -33.581184, 0.0],
            [-70.93842, -33.586198, 0.0],
            [-70.939972, -33.593009, 0.0],
            [-70.929167, -33.588466, 0.0],
            [-70.919005, -33.586044, 0.0],
            [-70.912977, -33.587431, 0.0],
            [-70.908473, -33.586406, 0.0],
            [-70.901973, -33.587292, 0.0],
            [-70.895709, -33.588665, 0.0],
            [-70.89272, -33.584939, 0.0],
            [-70.884296, -33.581662, 0.0],
            [-70.88142, -33.585289, 0.0],
            [-70.874221, -33.580721, 0.0],
            [-70.873567, -33.581518, 0.0],
            [-70.875547, -33.585538, 0.0],
            [-70.864964, -33.586392, 0.0],
            [-70.864154, -33.585054, 0.0],
            [-70.863366, -33.584933, 0.0],
            [-70.862314, -33.581705, 0.0],
            [-70.857597, -33.58154, 0.0],
            [-70.851689, -33.581783, 0.0],
            [-70.850418, -33.58036, 0.0],
            [-70.847959, -33.580771, 0.0],
            [-70.844551, -33.581158, 0.0],
            [-70.84212, -33.581465, 0.0],
            [-70.833583, -33.582355, 0.0],
            [-70.83203, -33.583571, 0.0],
            [-70.834594, -33.585539, 0.0],
            [-70.829677, -33.589601, 0.0],
            [-70.820133, -33.597075, 0.0],
            [-70.818561, -33.596365, 0.0],
            [-70.815401, -33.593979, 0.0],
            [-70.812211, -33.596242, 0.0],
            [-70.811995, -33.595958, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "PEDRO AGUIRRE CERDA",
        description: "Juan Rozas Romero (ind)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.685312, -33.47855, 0.0],
            [-70.685352, -33.479227, 0.0],
            [-70.686123, -33.481566, 0.0],
            [-70.687933, -33.486165, 0.0],
            [-70.688849, -33.488207, 0.0],
            [-70.69079, -33.492997, 0.0],
            [-70.691428, -33.494359, 0.0],
            [-70.693489, -33.49857, 0.0],
            [-70.695071, -33.502026, 0.0],
            [-70.694524, -33.502246, 0.0],
            [-70.692792, -33.502874, 0.0],
            [-70.690745, -33.503516, 0.0],
            [-70.688817, -33.504219, 0.0],
            [-70.684207, -33.505775, 0.0],
            [-70.683909, -33.50576, 0.0],
            [-70.68101, -33.507063, 0.0],
            [-70.678384, -33.508212, 0.0],
            [-70.676688, -33.508337, 0.0],
            [-70.675758, -33.508724, 0.0],
            [-70.670941, -33.510716, 0.0],
            [-70.6697, -33.50789, 0.0],
            [-70.66823, -33.504859, 0.0],
            [-70.665737, -33.499591, 0.0],
            [-70.663643, -33.495022, 0.0],
            [-70.661242, -33.489925, 0.0],
            [-70.659951, -33.487302, 0.0],
            [-70.658405, -33.483882, 0.0],
            [-70.656912, -33.480674, 0.0],
            [-70.656048, -33.478902, 0.0],
            [-70.654939, -33.476442, 0.0],
            [-70.655683, -33.476442, 0.0],
            [-70.657657, -33.476177, 0.0],
            [-70.661126, -33.475832, 0.0],
            [-70.662566, -33.475847, 0.0],
            [-70.664752, -33.475914, 0.0],
            [-70.669155, -33.476002, 0.0],
            [-70.671459, -33.476079, 0.0],
            [-70.6715481, -33.4784208, 0.0],
            [-70.6720962, -33.4785238, 0.0],
            [-70.674778, -33.478409, 0.0],
            [-70.676936, -33.47839, 0.0],
            [-70.679226, -33.478265, 0.0],
            [-70.67921, -33.476964, 0.0],
            [-70.680647, -33.476994, 0.0],
            [-70.683169, -33.476915, 0.0],
            [-70.685768, -33.47693, 0.0],
            [-70.685312, -33.47855, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "PUDAHUEL",
        description: "Johnny Carrasco Cerda (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.811995, -33.359676, 0.0],
            [-70.814203, -33.360252, 0.0],
            [-70.816229, -33.361709, 0.0],
            [-70.818166, -33.36263, 0.0],
            [-70.819733, -33.362859, 0.0],
            [-70.821302, -33.364626, 0.0],
            [-70.822595, -33.366087, 0.0],
            [-70.823616, -33.369779, 0.0],
            [-70.825001, -33.371162, 0.0],
            [-70.826107, -33.371776, 0.0],
            [-70.828228, -33.372927, 0.0],
            [-70.828323, -33.374236, 0.0],
            [-70.828968, -33.374619, 0.0],
            [-70.829248, -33.376235, 0.0],
            [-70.831925, -33.377771, 0.0],
            [-70.83285, -33.378924, 0.0],
            [-70.832759, -33.37954, 0.0],
            [-70.832485, -33.380772, 0.0],
            [-70.834424, -33.381923, 0.0],
            [-70.837375, -33.382842, 0.0],
            [-70.837377, -33.383458, 0.0],
            [-70.837563, -33.384382, 0.0],
            [-70.839317, -33.385303, 0.0],
            [-70.839776, -33.384686, 0.0],
            [-70.843553, -33.384065, 0.0],
            [-70.845486, -33.382831, 0.0],
            [-70.849631, -33.38113, 0.0],
            [-70.854512, -33.378504, 0.0],
            [-70.856447, -33.378039, 0.0],
            [-70.860498, -33.37603, 0.0],
            [-70.866391, -33.375157, 0.0],
            [-70.870282, -33.37552, 0.0],
            [-70.873473, -33.374877, 0.0],
            [-70.87517, -33.373501, 0.0],
            [-70.876432, -33.373495, 0.0],
            [-70.877846, -33.374963, 0.0],
            [-70.882096, -33.375236, 0.0],
            [-70.885915, -33.373783, 0.0],
            [-70.887521, -33.37396, 0.0],
            [-70.889662, -33.374506, 0.0],
            [-70.894333, -33.375147, 0.0],
            [-70.901281, -33.368173, 0.0],
            [-70.907283, -33.366423, 0.0],
            [-70.910442, -33.366287, 0.0],
            [-70.932734, -33.37887, 0.0],
            [-70.941686, -33.380824, 0.0],
            [-70.944801, -33.382572, 0.0],
            [-70.951516, -33.382911, 0.0],
            [-70.953379, -33.392392, 0.0],
            [-70.954551, -33.398395, 0.0],
            [-70.952241, -33.404558, 0.0],
            [-70.954891, -33.40952, 0.0],
            [-70.949572, -33.41916, 0.0],
            [-70.95288, -33.433678, 0.0],
            [-70.957727, -33.440043, 0.0],
            [-70.95673, -33.447877, 0.0],
            [-70.952494, -33.452923, 0.0],
            [-70.951908, -33.456599, 0.0],
            [-70.953165, -33.460725, 0.0],
            [-70.9502, -33.468315, 0.0],
            [-70.939307, -33.474189, 0.0],
            [-70.933696, -33.474204, 0.0],
            [-70.923362, -33.486971, 0.0],
            [-70.91549, -33.488009, 0.0],
            [-70.896185, -33.479198, 0.0],
            [-70.880216, -33.480589, 0.0],
            [-70.85649, -33.476639, 0.0],
            [-70.851542, -33.477918, 0.0],
            [-70.847349, -33.478301, 0.0],
            [-70.845655, -33.473297, 0.0],
            [-70.842612, -33.469606, 0.0],
            [-70.84179, -33.466655, 0.0],
            [-70.839824, -33.466468, 0.0],
            [-70.835307, -33.466848, 0.0],
            [-70.82976, -33.465054, 0.0],
            [-70.824657, -33.468139, 0.0],
            [-70.814385, -33.465992, 0.0],
            [-70.811995, -33.464396, 0.0],
            [-70.810816, -33.463608, 0.0],
            [-70.804859, -33.462335, 0.0],
            [-70.806376, -33.460319, 0.0],
            [-70.802862, -33.459978, 0.0],
            [-70.800039, -33.460096, 0.0],
            [-70.796623, -33.460303, 0.0],
            [-70.790599, -33.460888, 0.0],
            [-70.788253, -33.457206, 0.0],
            [-70.782626, -33.45779, 0.0],
            [-70.77567, -33.458239, 0.0],
            [-70.764748, -33.458924, 0.0],
            [-70.764343, -33.460276, 0.0],
            [-70.763322, -33.463299, 0.0],
            [-70.762863, -33.463441, 0.0],
            [-70.762065, -33.463885, 0.0],
            [-70.759912, -33.464292, 0.0],
            [-70.759066, -33.464574, 0.0],
            [-70.748212, -33.466499, 0.0],
            [-70.737367, -33.46857, 0.0],
            [-70.737177, -33.471707, 0.0],
            [-70.731261, -33.467538, 0.0],
            [-70.731107, -33.464052, 0.0],
            [-70.731215, -33.454985, 0.0],
            [-70.732408, -33.45479, 0.0],
            [-70.738902, -33.453518, 0.0],
            [-70.739094, -33.451777, 0.0],
            [-70.739241, -33.450214, 0.0],
            [-70.739544, -33.446523, 0.0],
            [-70.740586, -33.44575, 0.0],
            [-70.740605, -33.445627, 0.0],
            [-70.740562, -33.444638, 0.0],
            [-70.740497, -33.441825, 0.0],
            [-70.7407, -33.439175, 0.0],
            [-70.740839, -33.436374, 0.0],
            [-70.740857, -33.433729, 0.0],
            [-70.740846, -33.43267, 0.0],
            [-70.740842, -33.429951, 0.0],
            [-70.740863, -33.429906, 0.0],
            [-70.742431, -33.429995, 0.0],
            [-70.744611, -33.429971, 0.0],
            [-70.746818, -33.429959, 0.0],
            [-70.748472, -33.429579, 0.0],
            [-70.749516, -33.429351, 0.0],
            [-70.75051, -33.429113, 0.0],
            [-70.75148, -33.428718, 0.0],
            [-70.752279, -33.42829, 0.0],
            [-70.753378, -33.428113, 0.0],
            [-70.754441, -33.427913, 0.0],
            [-70.755502, -33.427432, 0.0],
            [-70.757054, -33.427222, 0.0],
            [-70.757617, -33.427347, 0.0],
            [-70.75838, -33.427398, 0.0],
            [-70.760163, -33.427386, 0.0],
            [-70.761305, -33.427687, 0.0],
            [-70.762415, -33.427728, 0.0],
            [-70.763152, -33.427571, 0.0],
            [-70.764202, -33.42712, 0.0],
            [-70.766631, -33.425986, 0.0],
            [-70.767596, -33.425359, 0.0],
            [-70.76958, -33.425209, 0.0],
            [-70.771007, -33.424875, 0.0],
            [-70.771739, -33.424935, 0.0],
            [-70.773257, -33.424633, 0.0],
            [-70.775258, -33.424306, 0.0],
            [-70.776767, -33.423022, 0.0],
            [-70.777554, -33.420196, 0.0],
            [-70.778257, -33.419447, 0.0],
            [-70.778885, -33.418691, 0.0],
            [-70.778948, -33.41847, 0.0],
            [-70.779024, -33.417632, 0.0],
            [-70.779187, -33.41738, 0.0],
            [-70.779953, -33.416674, 0.0],
            [-70.780899, -33.416189, 0.0],
            [-70.78101, -33.415916, 0.0],
            [-70.782065, -33.415855, 0.0],
            [-70.782375, -33.407902, 0.0],
            [-70.782188, -33.405541, 0.0],
            [-70.781789, -33.404884, 0.0],
            [-70.776698, -33.400113, 0.0],
            [-70.759256, -33.38593, 0.0],
            [-70.808019, -33.355488, 0.0],
            [-70.809314, -33.355951, 0.0],
            [-70.809499, -33.35695, 0.0],
            [-70.810975, -33.357565, 0.0],
            [-70.810977, -33.35941, 0.0],
            [-70.811995, -33.359676, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "PUENTE ALTO",
        description: "Germán Codina Powers (RN)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.4891, -33.541369, 0.0],
            [-70.49485, -33.542626, 0.0],
            [-70.500324, -33.545829, 0.0],
            [-70.51047, -33.542898, 0.0],
            [-70.514304, -33.541719, 0.0],
            [-70.51662, -33.542453, 0.0],
            [-70.51949, -33.542885, 0.0],
            [-70.529928, -33.544835, 0.0],
            [-70.532468, -33.545299, 0.0],
            [-70.541615, -33.546907, 0.0],
            [-70.5539, -33.548095, 0.0],
            [-70.55445, -33.548053, 0.0],
            [-70.568163, -33.547561, 0.0],
            [-70.563353, -33.553325, 0.0],
            [-70.557255, -33.560851, 0.0],
            [-70.563913, -33.562159, 0.0],
            [-70.584576, -33.565691, 0.0],
            [-70.583924, -33.568913, 0.0],
            [-70.592177, -33.569993, 0.0],
            [-70.592489, -33.570223, 0.0],
            [-70.592764, -33.569985, 0.0],
            [-70.597699, -33.567899, 0.0],
            [-70.597982, -33.567734, 0.0],
            [-70.59952, -33.567365, 0.0],
            [-70.600784, -33.567582, 0.0],
            [-70.605147, -33.568834, 0.0],
            [-70.611387, -33.570231, 0.0],
            [-70.609401, -33.580765, 0.0],
            [-70.609992, -33.580896, 0.0],
            [-70.609426, -33.586958, 0.0],
            [-70.608561, -33.587155, 0.0],
            [-70.607004, -33.59595, 0.0],
            [-70.606074, -33.602083, 0.0],
            [-70.618176, -33.603905, 0.0],
            [-70.626838, -33.604897, 0.0],
            [-70.626655, -33.610164, 0.0],
            [-70.626526, -33.618751, 0.0],
            [-70.62762, -33.623164, 0.0],
            [-70.628576, -33.627907, 0.0],
            [-70.629507, -33.632114, 0.0],
            [-70.630706, -33.638522, 0.0],
            [-70.631269, -33.64171, 0.0],
            [-70.631926, -33.644153, 0.0],
            [-70.628409, -33.642539, 0.0],
            [-70.625956, -33.642406, 0.0],
            [-70.622897, -33.643155, 0.0],
            [-70.621265, -33.643103, 0.0],
            [-70.617733, -33.64342, 0.0],
            [-70.614926, -33.643077, 0.0],
            [-70.61087, -33.642066, 0.0],
            [-70.606907, -33.641295, 0.0],
            [-70.601805, -33.640708, 0.0],
            [-70.598229, -33.639695, 0.0],
            [-70.597187, -33.637385, 0.0],
            [-70.595188, -33.637614, 0.0],
            [-70.591538, -33.638169, 0.0],
            [-70.590217, -33.637708, 0.0],
            [-70.589607, -33.635978, 0.0],
            [-70.587793, -33.634874, 0.0],
            [-70.585681, -33.633778, 0.0],
            [-70.583114, -33.634389, 0.0],
            [-70.580945, -33.634247, 0.0],
            [-70.579666, -33.631103, 0.0],
            [-70.57717, -33.627825, 0.0],
            [-70.575301, -33.626431, 0.0],
            [-70.571273, -33.625258, 0.0],
            [-70.567947, -33.625975, 0.0],
            [-70.566847, -33.624317, 0.0],
            [-70.566533, -33.621099, 0.0],
            [-70.564596, -33.620316, 0.0],
            [-70.5611, -33.620481, 0.0],
            [-70.557371, -33.621085, 0.0],
            [-70.554535, -33.619941, 0.0],
            [-70.550862, -33.61844, 0.0],
            [-70.547672, -33.61841, 0.0],
            [-70.544023, -33.61808, 0.0],
            [-70.541084, -33.619982, 0.0],
            [-70.538474, -33.619329, 0.0],
            [-70.534878, -33.617086, 0.0],
            [-70.533032, -33.616271, 0.0],
            [-70.529037, -33.615947, 0.0],
            [-70.525692, -33.616147, 0.0],
            [-70.519577, -33.615768, 0.0],
            [-70.516704, -33.613708, 0.0],
            [-70.51349, -33.61365, 0.0],
            [-70.510178, -33.613424, 0.0],
            [-70.508313, -33.611734, 0.0],
            [-70.508612, -33.608993, 0.0],
            [-70.506668, -33.607769, 0.0],
            [-70.50338, -33.608619, 0.0],
            [-70.500267, -33.608203, 0.0],
            [-70.497954, -33.60509, 0.0],
            [-70.501093, -33.601232, 0.0],
            [-70.497766, -33.600376, 0.0],
            [-70.491109, -33.597782, 0.0],
            [-70.488079, -33.594974, 0.0],
            [-70.484237, -33.594661, 0.0],
            [-70.480181, -33.593906, 0.0],
            [-70.478841, -33.589664, 0.0],
            [-70.477029, -33.589242, 0.0],
            [-70.478266, -33.580507, 0.0],
            [-70.480813, -33.575395, 0.0],
            [-70.485089, -33.566823, 0.0],
            [-70.489112, -33.559717, 0.0],
            [-70.487717, -33.554155, 0.0],
            [-70.485986, -33.548026, 0.0],
            [-70.486758, -33.544439, 0.0],
            [-70.486075, -33.540658, 0.0],
            [-70.4891, -33.541369, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "QUILICURA",
        description: "Juan Carrasco Contreras (ind)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.751881, -33.390348, 0.0],
            [-70.748584, -33.390541, 0.0],
            [-70.745002, -33.389698, 0.0],
            [-70.741989, -33.38918, 0.0],
            [-70.740985, -33.389207, 0.0],
            [-70.739523, -33.390215, 0.0],
            [-70.73878, -33.390674, 0.0],
            [-70.738235, -33.391966, 0.0],
            [-70.737296, -33.392653, 0.0],
            [-70.736716, -33.393254, 0.0],
            [-70.734963, -33.392523, 0.0],
            [-70.732593, -33.392448, 0.0],
            [-70.731143, -33.392326, 0.0],
            [-70.728698, -33.391954, 0.0],
            [-70.7221, -33.39072, 0.0],
            [-70.715318, -33.390482, 0.0],
            [-70.711264, -33.390705, 0.0],
            [-70.702257, -33.383047, 0.0],
            [-70.696208, -33.383446, 0.0],
            [-70.696076, -33.384249, 0.0],
            [-70.693779, -33.383852, 0.0],
            [-70.694654, -33.381729, 0.0],
            [-70.693722, -33.38142, 0.0],
            [-70.693417, -33.381381, 0.0],
            [-70.695148, -33.37759, 0.0],
            [-70.698622, -33.370034, 0.0],
            [-70.70017, -33.366226, 0.0],
            [-70.698807, -33.366014, 0.0],
            [-70.696684, -33.365897, 0.0],
            [-70.689659, -33.366207, 0.0],
            [-70.689766, -33.363249, 0.0],
            [-70.691135, -33.358814, 0.0],
            [-70.691699, -33.35706, 0.0],
            [-70.692306, -33.354504, 0.0],
            [-70.692895, -33.352438, 0.0],
            [-70.69376, -33.350238, 0.0],
            [-70.694235, -33.344991, 0.0],
            [-70.694419, -33.343577, 0.0],
            [-70.694256, -33.342439, 0.0],
            [-70.693578, -33.341137, 0.0],
            [-70.692872, -33.339859, 0.0],
            [-70.692437, -33.338681, 0.0],
            [-70.692659, -33.337073, 0.0],
            [-70.693536, -33.334425, 0.0],
            [-70.694141, -33.333344, 0.0],
            [-70.695265, -33.332248, 0.0],
            [-70.699055, -33.329201, 0.0],
            [-70.70402, -33.325582, 0.0],
            [-70.705098, -33.324253, 0.0],
            [-70.705983, -33.321757, 0.0],
            [-70.706705, -33.319746, 0.0],
            [-70.70699, -33.318566, 0.0],
            [-70.707142, -33.318513, 0.0],
            [-70.707151, -33.318133, 0.0],
            [-70.706964, -33.317627, 0.0],
            [-70.706709, -33.317245, 0.0],
            [-70.706874, -33.31684, 0.0],
            [-70.707222, -33.316689, 0.0],
            [-70.708497, -33.316545, 0.0],
            [-70.709013, -33.316704, 0.0],
            [-70.70999, -33.317147, 0.0],
            [-70.71076, -33.317068, 0.0],
            [-70.711058, -33.316906, 0.0],
            [-70.710984, -33.317217, 0.0],
            [-70.711282, -33.318534, 0.0],
            [-70.711104, -33.319193, 0.0],
            [-70.710881, -33.319392, 0.0],
            [-70.709915, -33.319865, 0.0],
            [-70.708585, -33.321911, 0.0],
            [-70.719493, -33.324469, 0.0],
            [-70.727913, -33.326378, 0.0],
            [-70.735556, -33.328113, 0.0],
            [-70.757429, -33.333238, 0.0],
            [-70.774735, -33.337348, 0.0],
            [-70.792526, -33.341531, 0.0],
            [-70.794642, -33.344815, 0.0],
            [-70.795274, -33.344339, 0.0],
            [-70.796405, -33.34497, 0.0],
            [-70.797431, -33.346978, 0.0],
            [-70.799144, -33.348506, 0.0],
            [-70.799893, -33.348361, 0.0],
            [-70.801898, -33.348985, 0.0],
            [-70.803563, -33.350542, 0.0],
            [-70.804095, -33.351184, 0.0],
            [-70.80472, -33.352148, 0.0],
            [-70.805105, -33.35267, 0.0],
            [-70.806988, -33.353107, 0.0],
            [-70.807516, -33.353991, 0.0],
            [-70.808101, -33.355275, 0.0],
            [-70.751881, -33.390348, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "QUINTA NORMAL",
        description: "Carmen Gloria Fernández (PDC)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.72216, -33.412005, 0.0],
            [-70.721458, -33.412418, 0.0],
            [-70.720655, -33.412677, 0.0],
            [-70.719358, -33.413232, 0.0],
            [-70.717247, -33.414524, 0.0],
            [-70.71582, -33.415147, 0.0],
            [-70.716713, -33.416432, 0.0],
            [-70.717055, -33.417974, 0.0],
            [-70.717704, -33.420953, 0.0],
            [-70.718555, -33.424123, 0.0],
            [-70.719586, -33.428137, 0.0],
            [-70.720755, -33.432848, 0.0],
            [-70.721191, -33.43522, 0.0],
            [-70.721468, -33.436653, 0.0],
            [-70.717049, -33.437256, 0.0],
            [-70.710357, -33.438184, 0.0],
            [-70.70707, -33.438093, 0.0],
            [-70.707225, -33.441505, 0.0],
            [-70.707337, -33.442879, 0.0],
            [-70.707277, -33.446593, 0.0],
            [-70.707132, -33.448764, 0.0],
            [-70.706354, -33.448692, 0.0],
            [-70.698005, -33.447716, 0.0],
            [-70.692389, -33.447968, 0.0],
            [-70.692299, -33.447917, 0.0],
            [-70.69217, -33.446113, 0.0],
            [-70.692087, -33.444488, 0.0],
            [-70.691852, -33.44271, 0.0],
            [-70.691766, -33.440654, 0.0],
            [-70.691777, -33.439336, 0.0],
            [-70.691556, -33.439251, 0.0],
            [-70.687652, -33.438959, 0.0],
            [-70.684079, -33.438696, 0.0],
            [-70.684253, -33.437564, 0.0],
            [-70.683774, -33.43749, 0.0],
            [-70.683691, -33.436518, 0.0],
            [-70.682549, -33.436238, 0.0],
            [-70.681003, -33.436019, 0.0],
            [-70.680861, -33.437245, 0.0],
            [-70.68128, -33.437362, 0.0],
            [-70.681256, -33.438464, 0.0],
            [-70.680075, -33.438406, 0.0],
            [-70.680234, -33.43658, 0.0],
            [-70.680269, -33.43574, 0.0],
            [-70.680515, -33.43301, 0.0],
            [-70.680626, -33.431622, 0.0],
            [-70.680764, -33.429882, 0.0],
            [-70.68066, -33.429024, 0.0],
            [-70.680321, -33.428298, 0.0],
            [-70.679147, -33.427394, 0.0],
            [-70.677514, -33.426871, 0.0],
            [-70.676528, -33.426843, 0.0],
            [-70.676164, -33.4269, 0.0],
            [-70.67191, -33.428253, 0.0],
            [-70.671491, -33.42651, 0.0],
            [-70.67266, -33.426165, 0.0],
            [-70.673614, -33.425775, 0.0],
            [-70.67509, -33.425147, 0.0],
            [-70.676294, -33.424627, 0.0],
            [-70.677746, -33.423982, 0.0],
            [-70.679691, -33.423098, 0.0],
            [-70.681391, -33.422352, 0.0],
            [-70.683786, -33.421342, 0.0],
            [-70.68564, -33.420621, 0.0],
            [-70.686856, -33.420367, 0.0],
            [-70.688176, -33.419855, 0.0],
            [-70.688881, -33.419282, 0.0],
            [-70.690567, -33.418388, 0.0],
            [-70.69155, -33.417553, 0.0],
            [-70.692307, -33.416382, 0.0],
            [-70.692704, -33.41562, 0.0],
            [-70.69309, -33.415333, 0.0],
            [-70.69352, -33.414899, 0.0],
            [-70.694254, -33.414352, 0.0],
            [-70.695012, -33.413753, 0.0],
            [-70.696111, -33.41293, 0.0],
            [-70.69651, -33.412708, 0.0],
            [-70.697301, -33.412268, 0.0],
            [-70.698111, -33.411741, 0.0],
            [-70.699531, -33.410784, 0.0],
            [-70.700163, -33.4101, 0.0],
            [-70.700357, -33.409974, 0.0],
            [-70.701087, -33.40952, 0.0],
            [-70.701881, -33.409133, 0.0],
            [-70.703022, -33.408611, 0.0],
            [-70.704231, -33.408158, 0.0],
            [-70.704846, -33.408046, 0.0],
            [-70.705625, -33.407889, 0.0],
            [-70.708712, -33.407884, 0.0],
            [-70.710234, -33.407964, 0.0],
            [-70.7109, -33.407845, 0.0],
            [-70.713097, -33.408059, 0.0],
            [-70.71376, -33.408373, 0.0],
            [-70.714432, -33.408792, 0.0],
            [-70.714933, -33.409285, 0.0],
            [-70.715685, -33.409635, 0.0],
            [-70.717267, -33.409899, 0.0],
            [-70.718128, -33.409892, 0.0],
            [-70.718786, -33.410071, 0.0],
            [-70.719335, -33.410117, 0.0],
            [-70.719775, -33.410216, 0.0],
            [-70.720394, -33.410307, 0.0],
            [-70.720988, -33.410652, 0.0],
            [-70.721491, -33.410884, 0.0],
            [-70.722373, -33.411537, 0.0],
            [-70.722572, -33.411642, 0.0],
            [-70.72216, -33.412005, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "RECOLETA",
        description: "Daniel Jadue Jadue (PCCh)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.615459, -33.40089, 0.0],
            [-70.618292, -33.398728, 0.0],
            [-70.623477, -33.395125, 0.0],
            [-70.627847, -33.392573, 0.0],
            [-70.631126, -33.390078, 0.0],
            [-70.645174, -33.380848, 0.0],
            [-70.650323, -33.378761, 0.0],
            [-70.651787, -33.378224, 0.0],
            [-70.655071, -33.377094, 0.0],
            [-70.656719, -33.376454, 0.0],
            [-70.657289, -33.377527, 0.0],
            [-70.657728, -33.378386, 0.0],
            [-70.657714, -33.378694, 0.0],
            [-70.657514, -33.381971, 0.0],
            [-70.657349, -33.384344, 0.0],
            [-70.657223, -33.385805, 0.0],
            [-70.657234, -33.386122, 0.0],
            [-70.657302, -33.387438, 0.0],
            [-70.657442, -33.388861, 0.0],
            [-70.657362, -33.390174, 0.0],
            [-70.657258, -33.391894, 0.0],
            [-70.657323, -33.392977, 0.0],
            [-70.657453, -33.394893, 0.0],
            [-70.657527, -33.39568, 0.0],
            [-70.657772, -33.397596, 0.0],
            [-70.658241, -33.399571, 0.0],
            [-70.658258, -33.400327, 0.0],
            [-70.6578, -33.402392, 0.0],
            [-70.657143, -33.405314, 0.0],
            [-70.653119, -33.406389, 0.0],
            [-70.653248, -33.40718, 0.0],
            [-70.653186, -33.407254, 0.0],
            [-70.653155, -33.408051, 0.0],
            [-70.65317, -33.410301, 0.0],
            [-70.652853, -33.411422, 0.0],
            [-70.652765, -33.412467, 0.0],
            [-70.652747, -33.414147, 0.0],
            [-70.6527, -33.417498, 0.0],
            [-70.651798, -33.417511, 0.0],
            [-70.650136, -33.417836, 0.0],
            [-70.650274, -33.418562, 0.0],
            [-70.650592, -33.420142, 0.0],
            [-70.650674, -33.420778, 0.0],
            [-70.651147, -33.424292, 0.0],
            [-70.651228, -33.425142, 0.0],
            [-70.65161, -33.430251, 0.0],
            [-70.651708, -33.431254, 0.0],
            [-70.651637, -33.431987, 0.0],
            [-70.645113, -33.43353, 0.0],
            [-70.637832, -33.435203, 0.0],
            [-70.635362, -33.435785, 0.0],
            [-70.63595, -33.432579, 0.0],
            [-70.636255, -33.430872, 0.0],
            [-70.636292, -33.430588, 0.0],
            [-70.636075, -33.430129, 0.0],
            [-70.635848, -33.429499, 0.0],
            [-70.634328, -33.427183, 0.0],
            [-70.633696, -33.42477, 0.0],
            [-70.632892, -33.422537, 0.0],
            [-70.631726, -33.42119, 0.0],
            [-70.63012, -33.419882, 0.0],
            [-70.627551, -33.418886, 0.0],
            [-70.625403, -33.41748, 0.0],
            [-70.624303, -33.415793, 0.0],
            [-70.622161, -33.414729, 0.0],
            [-70.618485, -33.41242, 0.0],
            [-70.616219, -33.41024, 0.0],
            [-70.61422, -33.408101, 0.0],
            [-70.610227, -33.406046, 0.0],
            [-70.610041, -33.404774, 0.0],
            [-70.610128, -33.402858, 0.0],
            [-70.615459, -33.40089, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "RENCA",
        description: "Claudio Castro Salas (PDC)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.780663, -33.403833, 0.0],
            [-70.782122, -33.405278, 0.0],
            [-70.782481, -33.406558, 0.0],
            [-70.782458, -33.409393, 0.0],
            [-70.78216, -33.413742, 0.0],
            [-70.781471, -33.413891, 0.0],
            [-70.778766, -33.413933, 0.0],
            [-70.775925, -33.413716, 0.0],
            [-70.77343, -33.414251, 0.0],
            [-70.771672, -33.414573, 0.0],
            [-70.768869, -33.41445, 0.0],
            [-70.766011, -33.41373, 0.0],
            [-70.762749, -33.413048, 0.0],
            [-70.760253, -33.412497, 0.0],
            [-70.759003, -33.41235, 0.0],
            [-70.756812, -33.41265, 0.0],
            [-70.755694, -33.41271, 0.0],
            [-70.754101, -33.412581, 0.0],
            [-70.752046, -33.4123, 0.0],
            [-70.750767, -33.41226, 0.0],
            [-70.749244, -33.412242, 0.0],
            [-70.747349, -33.412445, 0.0],
            [-70.746615, -33.412474, 0.0],
            [-70.745588, -33.412465, 0.0],
            [-70.744559, -33.412386, 0.0],
            [-70.743628, -33.412206, 0.0],
            [-70.742026, -33.412227, 0.0],
            [-70.740953, -33.412309, 0.0],
            [-70.738818, -33.41234, 0.0],
            [-70.737468, -33.41222, 0.0],
            [-70.735735, -33.412142, 0.0],
            [-70.733838, -33.412367, 0.0],
            [-70.732977, -33.412409, 0.0],
            [-70.731813, -33.41254, 0.0],
            [-70.729927, -33.412882, 0.0],
            [-70.728905, -33.413345, 0.0],
            [-70.727967, -33.413616, 0.0],
            [-70.72691, -33.413275, 0.0],
            [-70.726129, -33.413025, 0.0],
            [-70.725205, -33.412745, 0.0],
            [-70.724634, -33.412516, 0.0],
            [-70.723965, -33.412137, 0.0],
            [-70.723043, -33.411789, 0.0],
            [-70.722385, -33.411538, 0.0],
            [-70.721475, -33.410859, 0.0],
            [-70.720374, -33.410301, 0.0],
            [-70.719368, -33.410105, 0.0],
            [-70.718808, -33.410056, 0.0],
            [-70.718122, -33.409866, 0.0],
            [-70.717323, -33.409891, 0.0],
            [-70.716637, -33.409809, 0.0],
            [-70.715679, -33.409618, 0.0],
            [-70.714904, -33.40925, 0.0],
            [-70.714428, -33.408804, 0.0],
            [-70.713783, -33.408376, 0.0],
            [-70.713091, -33.408037, 0.0],
            [-70.712279, -33.407948, 0.0],
            [-70.710936, -33.407841, 0.0],
            [-70.710248, -33.407941, 0.0],
            [-70.708665, -33.407862, 0.0],
            [-70.707183, -33.407863, 0.0],
            [-70.705597, -33.407873, 0.0],
            [-70.704264, -33.408142, 0.0],
            [-70.702933, -33.408619, 0.0],
            [-70.70108, -33.409503, 0.0],
            [-70.700192, -33.410078, 0.0],
            [-70.699535, -33.410751, 0.0],
            [-70.698646, -33.411375, 0.0],
            [-70.697329, -33.412248, 0.0],
            [-70.696192, -33.412883, 0.0],
            [-70.694798, -33.413903, 0.0],
            [-70.693571, -33.41486, 0.0],
            [-70.69271, -33.415617, 0.0],
            [-70.69225, -33.416436, 0.0],
            [-70.691533, -33.41755, 0.0],
            [-70.690551, -33.418387, 0.0],
            [-70.688865, -33.419276, 0.0],
            [-70.688171, -33.419846, 0.0],
            [-70.686881, -33.42035, 0.0],
            [-70.685629, -33.420608, 0.0],
            [-70.683248, -33.421548, 0.0],
            [-70.680437, -33.422766, 0.0],
            [-70.678404, -33.423664, 0.0],
            [-70.676341, -33.424598, 0.0],
            [-70.672598, -33.426189, 0.0],
            [-70.671463, -33.426499, 0.0],
            [-70.671268, -33.425967, 0.0],
            [-70.671787, -33.425261, 0.0],
            [-70.672617, -33.424341, 0.0],
            [-70.673775, -33.422851, 0.0],
            [-70.674493, -33.42195, 0.0],
            [-70.675095, -33.421438, 0.0],
            [-70.676717, -33.420488, 0.0],
            [-70.677454, -33.419908, 0.0],
            [-70.677691, -33.419593, 0.0],
            [-70.67812, -33.419039, 0.0],
            [-70.678521, -33.418419, 0.0],
            [-70.678805, -33.417565, 0.0],
            [-70.678901, -33.416638, 0.0],
            [-70.67915, -33.41509, 0.0],
            [-70.679554, -33.411792, 0.0],
            [-70.679981, -33.408521, 0.0],
            [-70.680279, -33.406325, 0.0],
            [-70.680452, -33.405687, 0.0],
            [-70.680857, -33.404754, 0.0],
            [-70.681492, -33.403666, 0.0],
            [-70.682525, -33.401977, 0.0],
            [-70.683752, -33.399866, 0.0],
            [-70.685237, -33.397435, 0.0],
            [-70.686529, -33.395354, 0.0],
            [-70.687686, -33.393349, 0.0],
            [-70.688615, -33.391804, 0.0],
            [-70.68914, -33.390861, 0.0],
            [-70.689683, -33.389737, 0.0],
            [-70.690657, -33.387645, 0.0],
            [-70.691682, -33.38546, 0.0],
            [-70.692051, -33.384472, 0.0],
            [-70.693421, -33.381391, 0.0],
            [-70.693661, -33.381419, 0.0],
            [-70.694612, -33.381743, 0.0],
            [-70.693777, -33.383845, 0.0],
            [-70.696069, -33.384263, 0.0],
            [-70.696238, -33.383454, 0.0],
            [-70.702265, -33.383042, 0.0],
            [-70.702562, -33.383339, 0.0],
            [-70.706518, -33.3867, 0.0],
            [-70.708837, -33.388663, 0.0],
            [-70.711248, -33.390707, 0.0],
            [-70.712966, -33.390627, 0.0],
            [-70.715389, -33.390499, 0.0],
            [-70.717913, -33.390562, 0.0],
            [-70.722064, -33.390709, 0.0],
            [-70.725498, -33.39138, 0.0],
            [-70.729204, -33.392056, 0.0],
            [-70.730776, -33.392272, 0.0],
            [-70.732425, -33.392425, 0.0],
            [-70.734999, -33.392529, 0.0],
            [-70.73669, -33.393263, 0.0],
            [-70.73731, -33.392684, 0.0],
            [-70.738241, -33.391964, 0.0],
            [-70.738792, -33.390661, 0.0],
            [-70.740999, -33.38922, 0.0],
            [-70.741972, -33.389195, 0.0],
            [-70.748717, -33.390571, 0.0],
            [-70.751804, -33.390364, 0.0],
            [-70.759352, -33.385711, 0.0],
            [-70.780663, -33.403833, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "SAN BERNARDO",
        description: "Leonel Cádiz Soto (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.811995, -33.724376, 0.0],
            [-70.813442, -33.72495, 0.0],
            [-70.816784, -33.723541, 0.0],
            [-70.819169, -33.72222, 0.0],
            [-70.82157, -33.72187, 0.0],
            [-70.823968, -33.721993, 0.0],
            [-70.823808, -33.726627, 0.0],
            [-70.822386, -33.728226, 0.0],
            [-70.822735, -33.732283, 0.0],
            [-70.822551, -33.742012, 0.0],
            [-70.83007, -33.746791, 0.0],
            [-70.828649, -33.751945, 0.0],
            [-70.823832, -33.756136, 0.0],
            [-70.811995, -33.748225, 0.0],
            [-70.798472, -33.739184, 0.0],
            [-70.740113, -33.697067, 0.0],
            [-70.72441, -33.690264, 0.0],
            [-70.718941, -33.686256, 0.0],
            [-70.714779, -33.682149, 0.0],
            [-70.714496, -33.676837, 0.0],
            [-70.710603, -33.671069, 0.0],
            [-70.710499, -33.670916, 0.0],
            [-70.705189, -33.663771, 0.0],
            [-70.700333, -33.663665, 0.0],
            [-70.695504, -33.661323, 0.0],
            [-70.689325, -33.661236, 0.0],
            [-70.681762, -33.661497, 0.0],
            [-70.67447, -33.659686, 0.0],
            [-70.667301, -33.658455, 0.0],
            [-70.660602, -33.654745, 0.0],
            [-70.655383, -33.651588, 0.0],
            [-70.648316, -33.649042, 0.0],
            [-70.641647, -33.646737, 0.0],
            [-70.635897, -33.645168, 0.0],
            [-70.632544, -33.645985, 0.0],
            [-70.630469, -33.645649, 0.0],
            [-70.632116, -33.643986, 0.0],
            [-70.631118, -33.64117, 0.0],
            [-70.630162, -33.636238, 0.0],
            [-70.628847, -33.628033, 0.0],
            [-70.644661, -33.627853, 0.0],
            [-70.643292, -33.621946, 0.0],
            [-70.646925, -33.621985, 0.0],
            [-70.643377, -33.610189, 0.0],
            [-70.670645, -33.607336, 0.0],
            [-70.668791, -33.601887, 0.0],
            [-70.66676, -33.596206, 0.0],
            [-70.665029, -33.590772, 0.0],
            [-70.663207, -33.58528, 0.0],
            [-70.66975, -33.583899, 0.0],
            [-70.676704, -33.582485, 0.0],
            [-70.680031, -33.581175, 0.0],
            [-70.684295, -33.579464, 0.0],
            [-70.694382, -33.575535, 0.0],
            [-70.697452, -33.574329, 0.0],
            [-70.70088, -33.572954, 0.0],
            [-70.698145, -33.56701, 0.0],
            [-70.69675, -33.563951, 0.0],
            [-70.697106, -33.563597, 0.0],
            [-70.695442, -33.555881, 0.0],
            [-70.693149, -33.556081, 0.0],
            [-70.69114, -33.551789, 0.0],
            [-70.6874, -33.543565, 0.0],
            [-70.686344, -33.541234, 0.0],
            [-70.68655, -33.541009, 0.0],
            [-70.685385, -33.539141, 0.0],
            [-70.689342, -33.537738, 0.0],
            [-70.693066, -33.53638, 0.0],
            [-70.698066, -33.534534, 0.0],
            [-70.700371, -33.533685, 0.0],
            [-70.707308, -33.531092, 0.0],
            [-70.716589, -33.527824, 0.0],
            [-70.725993, -33.524213, 0.0],
            [-70.734151, -33.533853, 0.0],
            [-70.753551, -33.555345, 0.0],
            [-70.761933, -33.564341, 0.0],
            [-70.767556, -33.571921, 0.0],
            [-70.759861, -33.574826, 0.0],
            [-70.755413, -33.576628, 0.0],
            [-70.753591, -33.581577, 0.0],
            [-70.751264, -33.584117, 0.0],
            [-70.749428, -33.586576, 0.0],
            [-70.745882, -33.5894, 0.0],
            [-70.742352, -33.594499, 0.0],
            [-70.741043, -33.598769, 0.0],
            [-70.741185, -33.604214, 0.0],
            [-70.747119, -33.609347, 0.0],
            [-70.750797, -33.615179, 0.0],
            [-70.747903, -33.622366, 0.0],
            [-70.743984, -33.628856, 0.0],
            [-70.73617, -33.633107, 0.0],
            [-70.737188, -33.63653, 0.0],
            [-70.738616, -33.639784, 0.0],
            [-70.742721, -33.640825, 0.0],
            [-70.747234, -33.643408, 0.0],
            [-70.757064, -33.646877, 0.0],
            [-70.776459, -33.640082, 0.0],
            [-70.788836, -33.640129, 0.0],
            [-70.80106, -33.651699, 0.0],
            [-70.802246, -33.657792, 0.0],
            [-70.806018, -33.65882, 0.0],
            [-70.808157, -33.659322, 0.0],
            [-70.810447, -33.660834, 0.0],
            [-70.809773, -33.66127, 0.0],
            [-70.809163, -33.662419, 0.0],
            [-70.807595, -33.663662, 0.0],
            [-70.806238, -33.664726, 0.0],
            [-70.804973, -33.665836, 0.0],
            [-70.803655, -33.666929, 0.0],
            [-70.802652, -33.667187, 0.0],
            [-70.801276, -33.666797, 0.0],
            [-70.801192, -33.668143, 0.0],
            [-70.800974, -33.668963, 0.0],
            [-70.801619, -33.670657, 0.0],
            [-70.801156, -33.671282, 0.0],
            [-70.803117, -33.67452, 0.0],
            [-70.801557, -33.678471, 0.0],
            [-70.79982, -33.681942, 0.0],
            [-70.797538, -33.684698, 0.0],
            [-70.79751, -33.685071, 0.0],
            [-70.797276, -33.688189, 0.0],
            [-70.795312, -33.69003, 0.0],
            [-70.794775, -33.69424, 0.0],
            [-70.793102, -33.69797, 0.0],
            [-70.796461, -33.70242, 0.0],
            [-70.796732, -33.705204, 0.0],
            [-70.799225, -33.705868, 0.0],
            [-70.801442, -33.710502, 0.0],
            [-70.805035, -33.71301, 0.0],
            [-70.806976, -33.71695, 0.0],
            [-70.807795, -33.721108, 0.0],
            [-70.810332, -33.723717, 0.0],
            [-70.811995, -33.724376, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "SAN JOAQUÍN",
        description: "Sergio Echeverría García (ind/PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.641875, -33.47825, 0.0],
            [-70.64178, -33.480899, 0.0],
            [-70.641597, -33.481993, 0.0],
            [-70.64124, -33.485015, 0.0],
            [-70.64089, -33.487441, 0.0],
            [-70.640408, -33.491337, 0.0],
            [-70.639914, -33.494891, 0.0],
            [-70.639573, -33.497335, 0.0],
            [-70.639268, -33.500988, 0.0],
            [-70.639012, -33.503569, 0.0],
            [-70.638988, -33.50334, 0.0],
            [-70.638634, -33.507204, 0.0],
            [-70.63765, -33.511372, 0.0],
            [-70.637233, -33.513103, 0.0],
            [-70.636848, -33.516589, 0.0],
            [-70.636605, -33.518737, 0.0],
            [-70.628219, -33.517879, 0.0],
            [-70.627149, -33.517691, 0.0],
            [-70.624132, -33.516381, 0.0],
            [-70.620237, -33.514442, 0.0],
            [-70.615078, -33.51307, 0.0],
            [-70.611305, -33.51307, 0.0],
            [-70.611642, -33.510355, 0.0],
            [-70.612014, -33.509984, 0.0],
            [-70.613785, -33.508214, 0.0],
            [-70.614092, -33.507706, 0.0],
            [-70.614304, -33.5063, 0.0],
            [-70.615066, -33.502883, 0.0],
            [-70.616455, -33.496438, 0.0],
            [-70.617477, -33.491844, 0.0],
            [-70.6186, -33.48735, 0.0],
            [-70.618784, -33.487217, 0.0],
            [-70.619737, -33.486233, 0.0],
            [-70.620099, -33.485753, 0.0],
            [-70.620567, -33.484598, 0.0],
            [-70.620821, -33.483144, 0.0],
            [-70.621598, -33.480239, 0.0],
            [-70.622977, -33.474964, 0.0],
            [-70.624007, -33.471499, 0.0],
            [-70.624065, -33.470751, 0.0],
            [-70.624304, -33.470164, 0.0],
            [-70.625148, -33.469544, 0.0],
            [-70.62536, -33.469952, 0.0],
            [-70.626424, -33.471241, 0.0],
            [-70.627992, -33.472064, 0.0],
            [-70.631698, -33.473371, 0.0],
            [-70.635137, -33.474634, 0.0],
            [-70.636894, -33.475284, 0.0],
            [-70.63909, -33.476004, 0.0],
            [-70.640592, -33.476285, 0.0],
            [-70.641966, -33.4764, 0.0],
            [-70.641875, -33.47825, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "SAN MIGUEL",
        description: "Luis Sanhueza Bravo (RN)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.670963, -33.51069, 0.0],
            [-70.662927, -33.513169, 0.0],
            [-70.6581, -33.514681, 0.0],
            [-70.649841, -33.516289, 0.0],
            [-70.641024, -33.51803, 0.0],
            [-70.637346, -33.518765, 0.0],
            [-70.636617, -33.518752, 0.0],
            [-70.636739, -33.517971, 0.0],
            [-70.636781, -33.517023, 0.0],
            [-70.636946, -33.51611, 0.0],
            [-70.637034, -33.51513, 0.0],
            [-70.637273, -33.512999, 0.0],
            [-70.637977, -33.509988, 0.0],
            [-70.638173, -33.509186, 0.0],
            [-70.638627, -33.507185, 0.0],
            [-70.638891, -33.504707, 0.0],
            [-70.639246, -33.501103, 0.0],
            [-70.639553, -33.497712, 0.0],
            [-70.639857, -33.495286, 0.0],
            [-70.640342, -33.491798, 0.0],
            [-70.640812, -33.488032, 0.0],
            [-70.641197, -33.485388, 0.0],
            [-70.641547, -33.482248, 0.0],
            [-70.641753, -33.480934, 0.0],
            [-70.641854, -33.478784, 0.0],
            [-70.641967, -33.476354, 0.0],
            [-70.643597, -33.476439, 0.0],
            [-70.645285, -33.476504, 0.0],
            [-70.647667, -33.476591, 0.0],
            [-70.648562, -33.476736, 0.0],
            [-70.650053, -33.476669, 0.0],
            [-70.651583, -33.47658, 0.0],
            [-70.653607, -33.476588, 0.0],
            [-70.654913, -33.476489, 0.0],
            [-70.670963, -33.51069, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "SAN RAMÓN",
        description: "Miguel Ángel Aguilera (PS)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.644609, -33.517477, 0.0],
            [-70.645143, -33.519406, 0.0],
            [-70.645675, -33.52133, 0.0],
            [-70.646115, -33.522927, 0.0],
            [-70.646212, -33.523295, 0.0],
            [-70.646495, -33.523858, 0.0],
            [-70.646583, -33.524219, 0.0],
            [-70.647107, -33.526175, 0.0],
            [-70.647724, -33.528269, 0.0],
            [-70.647934, -33.528547, 0.0],
            [-70.6484, -33.530386, 0.0],
            [-70.649258, -33.533322, 0.0],
            [-70.649727, -33.53497, 0.0],
            [-70.650475, -33.537742, 0.0],
            [-70.651215, -33.540254, 0.0],
            [-70.65194, -33.542736, 0.0],
            [-70.652347, -33.544685, 0.0],
            [-70.653111, -33.547482, 0.0],
            [-70.65415, -33.551096, 0.0],
            [-70.654494, -33.552879, 0.0],
            [-70.654902, -33.554984, 0.0],
            [-70.653385, -33.555201, 0.0],
            [-70.649123, -33.555849, 0.0],
            [-70.645501, -33.556454, 0.0],
            [-70.635305, -33.557753, 0.0],
            [-70.632096, -33.558149, 0.0],
            [-70.631027, -33.558086, 0.0],
            [-70.631826, -33.554431, 0.0],
            [-70.632057, -33.553465, 0.0],
            [-70.632481, -33.551538, 0.0],
            [-70.632759, -33.550283, 0.0],
            [-70.633348, -33.547382, 0.0],
            [-70.633618, -33.545903, 0.0],
            [-70.633763, -33.544859, 0.0],
            [-70.633958, -33.543434, 0.0],
            [-70.634087, -33.542609, 0.0],
            [-70.634229, -33.541386, 0.0],
            [-70.634462, -33.539619, 0.0],
            [-70.634565, -33.537042, 0.0],
            [-70.634602, -33.535573, 0.0],
            [-70.634669, -33.534129, 0.0],
            [-70.634862, -33.532251, 0.0],
            [-70.635144, -33.530829, 0.0],
            [-70.635519, -33.529039, 0.0],
            [-70.635565, -33.527385, 0.0],
            [-70.635703, -33.526319, 0.0],
            [-70.636388, -33.5208, 0.0],
            [-70.636634, -33.518736, 0.0],
            [-70.637367, -33.518758, 0.0],
            [-70.639268, -33.518384, 0.0],
            [-70.641967, -33.51784, 0.0],
            [-70.64456, -33.517348, 0.0],
            [-70.644609, -33.517477, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "VITACURA",
        description: "Raúl Torrealba del Pedregal (RN)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.584525, -33.350625, 0.0],
            [-70.586558, -33.353431, 0.0],
            [-70.592602, -33.355693, 0.0],
            [-70.596196, -33.359462, 0.0],
            [-70.600058, -33.362974, 0.0],
            [-70.597058, -33.365828, 0.0],
            [-70.597067, -33.369242, 0.0],
            [-70.597638, -33.371813, 0.0],
            [-70.601274, -33.374614, 0.0],
            [-70.603255, -33.377518, 0.0],
            [-70.604137, -33.380787, 0.0],
            [-70.603872, -33.383336, 0.0],
            [-70.602111, -33.386451, 0.0],
            [-70.60302, -33.390455, 0.0],
            [-70.604918, -33.393381, 0.0],
            [-70.606676, -33.394851, 0.0],
            [-70.607529, -33.398628, 0.0],
            [-70.610131, -33.402821, 0.0],
            [-70.61018, -33.406102, 0.0],
            [-70.608032, -33.408324, 0.0],
            [-70.603906, -33.408994, 0.0],
            [-70.602367, -33.408246, 0.0],
            [-70.60138, -33.408108, 0.0],
            [-70.599086, -33.408081, 0.0],
            [-70.596959, -33.407388, 0.0],
            [-70.588409, -33.404461, 0.0],
            [-70.585568, -33.403861, 0.0],
            [-70.581406, -33.402131, 0.0],
            [-70.571115, -33.398039, 0.0],
            [-70.55685, -33.392965, 0.0],
            [-70.548231, -33.389883, 0.0],
            [-70.538098, -33.385736, 0.0],
            [-70.534206, -33.384266, 0.0],
            [-70.531492, -33.38141, 0.0],
            [-70.528193, -33.378038, 0.0],
            [-70.52595, -33.376345, 0.0],
            [-70.52135, -33.374503, 0.0],
            [-70.517704, -33.372692, 0.0],
            [-70.518762, -33.371143, 0.0],
            [-70.520479, -33.369756, 0.0],
            [-70.525022, -33.369114, 0.0],
            [-70.526807, -33.369799, 0.0],
            [-70.530455, -33.370224, 0.0],
            [-70.534918, -33.370367, 0.0],
            [-70.540298, -33.370634, 0.0],
            [-70.557188, -33.358801, 0.0],
            [-70.565151, -33.356769, 0.0],
            [-70.567488, -33.355526, 0.0],
            [-70.570581, -33.356937, 0.0],
            [-70.573585, -33.355411, 0.0],
            [-70.575481, -33.353258, 0.0],
            [-70.578015, -33.352086, 0.0],
            [-70.579375, -33.35197, 0.0],
            [-70.580619, -33.35088, 0.0],
            [-70.584128, -33.350672, 0.0],
            [-70.584525, -33.350625, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Florida",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.589073, -33.510661, 0.0],
            [-70.591496, -33.509921, 0.0],
            [-70.5970304, -33.5094918, 0.0],
            [-70.613785, -33.508214, 0.0],
            [-70.611642, -33.510355, 0.0],
            [-70.6111207, -33.5144806, 0.0],
            [-70.6096956, -33.5261221, 0.0],
            [-70.61062, -33.546634, 0.0],
            [-70.613769, -33.546556, 0.0],
            [-70.61491, -33.546358, 0.0],
            [-70.61052, -33.561958, 0.0],
            [-70.612594, -33.562228, 0.0],
            [-70.611387, -33.570231, 0.0],
            [-70.6050049, -33.568765, 0.0],
            [-70.6002627, -33.5674241, 0.0],
            [-70.59952, -33.567365, 0.0],
            [-70.597982, -33.567734, 0.0],
            [-70.5926024, -33.5700523, 0.0],
            [-70.592489, -33.570223, 0.0],
            [-70.592177, -33.569993, 0.0],
            [-70.583924, -33.568913, 0.0],
            [-70.584576, -33.565691, 0.0],
            [-70.5636587, -33.5620998, 0.0],
            [-70.557255, -33.560851, 0.0],
            [-70.568163, -33.547561, 0.0],
            [-70.5786476, -33.5223294, 0.0],
            [-70.581995, -33.5183044, 0.0],
            [-70.5864378, -33.5126501, 0.0],
            [-70.5880568, -33.5103253, 0.0],
            [-70.589073, -33.510661, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LAS CONDES",
        description: "Joaquín Lavín Infante (UDI)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.43129, -33.479146, 0.0],
            [-70.429378, -33.479767, 0.0],
            [-70.428275, -33.480833, 0.0],
            [-70.426547, -33.480576, 0.0],
            [-70.425312, -33.480025, 0.0],
            [-70.421695, -33.479634, 0.0],
            [-70.417539, -33.479146, 0.0],
            [-70.412893, -33.48145, 0.0],
            [-70.409448, -33.483523, 0.0],
            [-70.40698, -33.483983, 0.0],
            [-70.404326, -33.48513, 0.0],
            [-70.397655, -33.487112, 0.0],
            [-70.393186, -33.487292, 0.0],
            [-70.389438, -33.484181, 0.0],
            [-70.388109, -33.483574, 0.0],
            [-70.383774, -33.483264, 0.0],
            [-70.381294, -33.482964, 0.0],
            [-70.37872, -33.482815, 0.0],
            [-70.378921, -33.483599, 0.0],
            [-70.377972, -33.483794, 0.0],
            [-70.376264, -33.485171, 0.0],
            [-70.374578, -33.485739, 0.0],
            [-70.37278, -33.485741, 0.0],
            [-70.367852, -33.48406, 0.0],
            [-70.367002, -33.483658, 0.0],
            [-70.362212, -33.483412, 0.0],
            [-70.360683, -33.483994, 0.0],
            [-70.356377, -33.484134, 0.0],
            [-70.353888, -33.484225, 0.0],
            [-70.352071, -33.48471, 0.0],
            [-70.351316, -33.486059, 0.0],
            [-70.350637, -33.487763, 0.0],
            [-70.3486, -33.487577, 0.0],
            [-70.345846, -33.486766, 0.0],
            [-70.344183, -33.485322, 0.0],
            [-70.340405, -33.481155, 0.0],
            [-70.337345, -33.477461, 0.0],
            [-70.335442, -33.475954, 0.0],
            [-70.333116, -33.473984, 0.0],
            [-70.329618, -33.472082, 0.0],
            [-70.328255, -33.470057, 0.0],
            [-70.330356, -33.457929, 0.0],
            [-70.344686, -33.448796, 0.0],
            [-70.351986, -33.435038, 0.0],
            [-70.358284, -33.422922, 0.0],
            [-70.365437, -33.410132, 0.0],
            [-70.368248, -33.403823, 0.0],
            [-70.374687, -33.400444, 0.0],
            [-70.378186, -33.398541, 0.0],
            [-70.375395, -33.395254, 0.0],
            [-70.375215, -33.387318, 0.0],
            [-70.379339, -33.382492, 0.0],
            [-70.386733, -33.377978, 0.0],
            [-70.392769, -33.37453, 0.0],
            [-70.400934, -33.374089, 0.0],
            [-70.406525, -33.373539, 0.0],
            [-70.410506, -33.372196, 0.0],
            [-70.416062, -33.371031, 0.0],
            [-70.423145, -33.369747, 0.0],
            [-70.430746, -33.37238, 0.0],
            [-70.43129, -33.372274, 0.0],
            [-70.433891, -33.371764, 0.0],
            [-70.441341, -33.373202, 0.0],
            [-70.445323, -33.372644, 0.0],
            [-70.453761, -33.372402, 0.0],
            [-70.459743, -33.372528, 0.0],
            [-70.46563, -33.372708, 0.0],
            [-70.469226, -33.37092, 0.0],
            [-70.46946, -33.367503, 0.0],
            [-70.469459, -33.366734, 0.0],
            [-70.47454, -33.364821, 0.0],
            [-70.480297, -33.365076, 0.0],
            [-70.486359, -33.365069, 0.0],
            [-70.490102, -33.364997, 0.0],
            [-70.4927, -33.364974, 0.0],
            [-70.493774, -33.36613, 0.0],
            [-70.494886, -33.36708, 0.0],
            [-70.49601, -33.367404, 0.0],
            [-70.496812, -33.367408, 0.0],
            [-70.499311, -33.367242, 0.0],
            [-70.502163, -33.368549, 0.0],
            [-70.504576, -33.369636, 0.0],
            [-70.506059, -33.370034, 0.0],
            [-70.509064, -33.37036, 0.0],
            [-70.512125, -33.370868, 0.0],
            [-70.516021, -33.37202, 0.0],
            [-70.52036, -33.374096, 0.0],
            [-70.522575, -33.375113, 0.0],
            [-70.525109, -33.376074, 0.0],
            [-70.527025, -33.377093, 0.0],
            [-70.528422, -33.378389, 0.0],
            [-70.52984, -33.379897, 0.0],
            [-70.531406, -33.381352, 0.0],
            [-70.53318, -33.38317, 0.0],
            [-70.53608, -33.385048, 0.0],
            [-70.541817, -33.387415, 0.0],
            [-70.545622, -33.388836, 0.0],
            [-70.553087, -33.391753, 0.0],
            [-70.559684, -33.393998, 0.0],
            [-70.565461, -33.395827, 0.0],
            [-70.573489, -33.398877, 0.0],
            [-70.57885, -33.401108, 0.0],
            [-70.582409, -33.4025, 0.0],
            [-70.585265, -33.403819, 0.0],
            [-70.587862, -33.404455, 0.0],
            [-70.5903, -33.404971, 0.0],
            [-70.595735, -33.406935, 0.0],
            [-70.597714, -33.407669, 0.0],
            [-70.599014, -33.408097, 0.0],
            [-70.601197, -33.408122, 0.0],
            [-70.602312, -33.40826, 0.0],
            [-70.603933, -33.408979, 0.0],
            [-70.605368, -33.411991, 0.0],
            [-70.605434, -33.413213, 0.0],
            [-70.606274, -33.415211, 0.0],
            [-70.60671, -33.416169, 0.0],
            [-70.604888, -33.416947, 0.0],
            [-70.604242, -33.417227, 0.0],
            [-70.602424, -33.417573, 0.0],
            [-70.601509, -33.41798, 0.0],
            [-70.600322, -33.419064, 0.0],
            [-70.599059, -33.419962, 0.0],
            [-70.595499, -33.422632, 0.0],
            [-70.591216, -33.425911, 0.0],
            [-70.589684, -33.427117, 0.0],
            [-70.585356, -33.430819, 0.0],
            [-70.584549, -33.431809, 0.0],
            [-70.583103, -33.431655, 0.0],
            [-70.582089, -33.431572, 0.0],
            [-70.580578, -33.431478, 0.0],
            [-70.579238, -33.43142, 0.0],
            [-70.577932, -33.431349, 0.0],
            [-70.575995, -33.431266, 0.0],
            [-70.57166, -33.43116, 0.0],
            [-70.56934, -33.431078, 0.0],
            [-70.565219, -33.430904, 0.0],
            [-70.562854, -33.430816, 0.0],
            [-70.560114, -33.430718, 0.0],
            [-70.558132, -33.430625, 0.0],
            [-70.556691, -33.430553, 0.0],
            [-70.554608, -33.430461, 0.0],
            [-70.5544, -33.430625, 0.0],
            [-70.553599, -33.430554, 0.0],
            [-70.551393, -33.430355, 0.0],
            [-70.550795, -33.430297, 0.0],
            [-70.547429, -33.429883, 0.0],
            [-70.545919, -33.429728, 0.0],
            [-70.540673, -33.429137, 0.0],
            [-70.539181, -33.428976, 0.0],
            [-70.539187, -33.430337, 0.0],
            [-70.539042, -33.430858, 0.0],
            [-70.538864, -33.431413, 0.0],
            [-70.538482, -33.432533, 0.0],
            [-70.53814, -33.433379, 0.0],
            [-70.534206, -33.433017, 0.0],
            [-70.519497, -33.431192, 0.0],
            [-70.516247, -33.431152, 0.0],
            [-70.514578, -33.431132, 0.0],
            [-70.505356, -33.433288, 0.0],
            [-70.50459, -33.436261, 0.0],
            [-70.499771, -33.44188, 0.0],
            [-70.497324, -33.444971, 0.0],
            [-70.496148, -33.446472, 0.0],
            [-70.496058, -33.450271, 0.0],
            [-70.490931, -33.454354, 0.0],
            [-70.489608, -33.455016, 0.0],
            [-70.487744, -33.458722, 0.0],
            [-70.486603, -33.460651, 0.0],
            [-70.486124, -33.463033, 0.0],
            [-70.479327, -33.467998, 0.0],
            [-70.476969, -33.470109, 0.0],
            [-70.475139, -33.473448, 0.0],
            [-70.47403, -33.473765, 0.0],
            [-70.471884, -33.475292, 0.0],
            [-70.466534, -33.474952, 0.0],
            [-70.461485, -33.475928, 0.0],
            [-70.454976, -33.479708, 0.0],
            [-70.448947, -33.483824, 0.0],
            [-70.44072, -33.487997, 0.0],
            [-70.437952, -33.488857, 0.0],
            [-70.438041, -33.486504, 0.0],
            [-70.437907, -33.482884, 0.0],
            [-70.436679, -33.480329, 0.0],
            [-70.434712, -33.479476, 0.0],
            [-70.432187, -33.478855, 0.0],
            [-70.43129, -33.479146, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "MACUL",
        description: "Gonzalo Montoya Riquelme (ind)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.622389, -33.477197, 0.0],
            [-70.62085, -33.483099, 0.0],
            [-70.620571, -33.484584, 0.0],
            [-70.620103, -33.485729, 0.0],
            [-70.61972, -33.486266, 0.0],
            [-70.618818, -33.487188, 0.0],
            [-70.618564, -33.487384, 0.0],
            [-70.617869, -33.490222, 0.0],
            [-70.617564, -33.4915, 0.0],
            [-70.61663, -33.495641, 0.0],
            [-70.616484, -33.49643, 0.0],
            [-70.615535, -33.500758, 0.0],
            [-70.615061, -33.502952, 0.0],
            [-70.614446, -33.505607, 0.0],
            [-70.614056, -33.50768, 0.0],
            [-70.613781, -33.508219, 0.0],
            [-70.607096, -33.508681, 0.0],
            [-70.603976, -33.508959, 0.0],
            [-70.595295, -33.509627, 0.0],
            [-70.591496, -33.509921, 0.0],
            [-70.59024, -33.510267, 0.0],
            [-70.590038, -33.509203, 0.0],
            [-70.58967, -33.507144, 0.0],
            [-70.589616, -33.506455, 0.0],
            [-70.589474, -33.505695, 0.0],
            [-70.589183, -33.504911, 0.0],
            [-70.588848, -33.503958, 0.0],
            [-70.588223, -33.50262, 0.0],
            [-70.587583, -33.501132, 0.0],
            [-70.587178, -33.500212, 0.0],
            [-70.586925, -33.499793, 0.0],
            [-70.58613, -33.498444, 0.0],
            [-70.583539, -33.494223, 0.0],
            [-70.58236, -33.492393, 0.0],
            [-70.581153, -33.490453, 0.0],
            [-70.580849, -33.489877, 0.0],
            [-70.580562, -33.48899, 0.0],
            [-70.580336, -33.487842, 0.0],
            [-70.580013, -33.486581, 0.0],
            [-70.57965, -33.484281, 0.0],
            [-70.579165, -33.482042, 0.0],
            [-70.578209, -33.477494, 0.0],
            [-70.577935, -33.476269, 0.0],
            [-70.577099, -33.472336, 0.0],
            [-70.576655, -33.470263, 0.0],
            [-70.576922, -33.470248, 0.0],
            [-70.5772, -33.47006, 0.0],
            [-70.577364, -33.469821, 0.0],
            [-70.57917, -33.470701, 0.0],
            [-70.580331, -33.4713, 0.0],
            [-70.582019, -33.472178, 0.0],
            [-70.58319, -33.472821, 0.0],
            [-70.584185, -33.473259, 0.0],
            [-70.584642, -33.47331, 0.0],
            [-70.585783, -33.47348, 0.0],
            [-70.586383, -33.473509, 0.0],
            [-70.58688, -33.473469, 0.0],
            [-70.586854, -33.473646, 0.0],
            [-70.586888, -33.473873, 0.0],
            [-70.586983, -33.474041, 0.0],
            [-70.587343, -33.47435, 0.0],
            [-70.587782, -33.474401, 0.0],
            [-70.588274, -33.474355, 0.0],
            [-70.588575, -33.474178, 0.0],
            [-70.588788, -33.473827, 0.0],
            [-70.58947, -33.47396, 0.0],
            [-70.590549, -33.473863, 0.0],
            [-70.592002, -33.473717, 0.0],
            [-70.59247, -33.473643, 0.0],
            [-70.593531, -33.473614, 0.0],
            [-70.594978, -33.473506, 0.0],
            [-70.596397, -33.473474, 0.0],
            [-70.596619, -33.473541, 0.0],
            [-70.596925, -33.473563, 0.0],
            [-70.598728, -33.473693, 0.0],
            [-70.611337, -33.474364, 0.0],
            [-70.615426, -33.474533, 0.0],
            [-70.622974, -33.474966, 0.0],
            [-70.622389, -33.477197, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "LA REINA",
        description: "José Palacios Parra (UDI)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.5840054, -33.4322662, 0.0],
            [-70.5827505, -33.4333822, 0.0],
            [-70.5807585, -33.4346372, 0.0],
            [-70.5788325, -33.4356542, 0.0],
            [-70.5766234, -33.4364062, 0.0],
            [-70.5746174, -33.4371992, 0.0],
            [-70.5733954, -33.4379372, 0.0],
            [-70.5732144, -33.4385092, 0.0],
            [-70.5725584, -33.4420272, 0.0],
            [-70.5715384, -33.4479672, 0.0],
            [-70.5708844, -33.4516722, 0.0],
            [-70.5706915, -33.4532272, 0.0],
            [-70.5708285, -33.4540852, 0.0],
            [-70.5713264, -33.4558512, 0.0],
            [-70.5716554, -33.4572702, 0.0],
            [-70.5721894, -33.4589562, 0.0],
            [-70.5707155, -33.4594342, 0.0],
            [-70.5691475, -33.4600232, 0.0],
            [-70.5671585, -33.4607932, 0.0],
            [-70.5621454, -33.4624182, 0.0],
            [-70.5600544, -33.4625302, 0.0],
            [-70.5572535, -33.4625812, 0.0],
            [-70.5516204, -33.4626262, 0.0],
            [-70.5507414, -33.4626462, 0.0],
            [-70.5444295, -33.4636032, 0.0],
            [-70.5439315, -33.4636232, 0.0],
            [-70.5421675, -33.4628332, 0.0],
            [-70.5401655, -33.4629062, 0.0],
            [-70.5389325, -33.4632502, 0.0],
            [-70.5312275, -33.4654052, 0.0],
            [-70.5307235, -33.4638892, 0.0],
            [-70.5306415, -33.4623062, 0.0],
            [-70.5303055, -33.4616562, 0.0],
            [-70.5294915, -33.4591872, 0.0],
            [-70.5210635, -33.4596122, 0.0],
            [-70.5142475, -33.4597812, 0.0],
            [-70.5090085, -33.4596602, 0.0],
            [-70.5061875, -33.4592832, 0.0],
            [-70.5042365, -33.4571762, 0.0],
            [-70.5056525, -33.4547712, 0.0],
            [-70.4909015, -33.4544292, 0.0],
            [-70.4960555, -33.4502702, 0.0],
            [-70.4959825, -33.4464522, 0.0],
            [-70.5044845, -33.4363272, 0.0],
            [-70.5053265, -33.4332622, 0.0],
            [-70.5145425, -33.4311152, 0.0],
            [-70.5194065, -33.4311502, 0.0],
            [-70.5216095, -33.4314552, 0.0],
            [-70.5239214, -33.4316262, 0.0],
            [-70.5324795, -33.4327082, 0.0],
            [-70.5352614, -33.4330712, 0.0],
            [-70.5380734, -33.4333562, 0.0],
            [-70.5383234, -33.4327602, 0.0],
            [-70.5388805, -33.4312092, 0.0],
            [-70.5391345, -33.4302812, 0.0],
            [-70.5391285, -33.4289292, 0.0],
            [-70.5491304, -33.4300422, 0.0],
            [-70.5514774, -33.4303282, 0.0],
            [-70.5543455, -33.4305802, 0.0],
            [-70.5545635, -33.4304142, 0.0],
            [-70.5596484, -33.4306552, 0.0],
            [-70.5622974, -33.4307462, 0.0],
            [-70.5712004, -33.4311102, 0.0],
            [-70.5746304, -33.4311862, 0.0],
            [-70.5786045, -33.4313362, 0.0],
            [-70.5824465, -33.4315472, 0.0],
            [-70.5844944, -33.4317582, 0.0],
            [-70.5840054, -33.4322662, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "PEÑALOLÉN",
        description: "Carolina Leitao Álvarez-Salamanca (PDC)",
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.572287, -33.459221, 0.0],
            [-70.572604, -33.460055, 0.0],
            [-70.57335, -33.461561, 0.0],
            [-70.573802, -33.462603, 0.0],
            [-70.574521, -33.464498, 0.0],
            [-70.575496, -33.466983, 0.0],
            [-70.575896, -33.467829, 0.0],
            [-70.576105, -33.468798, 0.0],
            [-70.575839, -33.469137, 0.0],
            [-70.575587, -33.469487, 0.0],
            [-70.575735, -33.469995, 0.0],
            [-70.576308, -33.470264, 0.0],
            [-70.576643, -33.470345, 0.0],
            [-70.576823, -33.471126, 0.0],
            [-70.577446, -33.474072, 0.0],
            [-70.578752, -33.480174, 0.0],
            [-70.579354, -33.482998, 0.0],
            [-70.579895, -33.485898, 0.0],
            [-70.580441, -33.488502, 0.0],
            [-70.580903, -33.490131, 0.0],
            [-70.581817, -33.491577, 0.0],
            [-70.584534, -33.495874, 0.0],
            [-70.586802, -33.499638, 0.0],
            [-70.587801, -33.501642, 0.0],
            [-70.589433, -33.505647, 0.0],
            [-70.58967, -33.506728, 0.0],
            [-70.58995, -33.508965, 0.0],
            [-70.590216, -33.510246, 0.0],
            [-70.589073, -33.510661, 0.0],
            [-70.588708, -33.510507, 0.0],
            [-70.58816, -33.510342, 0.0],
            [-70.587655, -33.510155, 0.0],
            [-70.584232, -33.510284, 0.0],
            [-70.57985, -33.510616, 0.0],
            [-70.576448, -33.51088, 0.0],
            [-70.570093, -33.511373, 0.0],
            [-70.56578, -33.511687, 0.0],
            [-70.564317, -33.511241, 0.0],
            [-70.56052, -33.511543, 0.0],
            [-70.557045, -33.511743, 0.0],
            [-70.5507, -33.512273, 0.0],
            [-70.542328, -33.512497, 0.0],
            [-70.53632, -33.51296, 0.0],
            [-70.533262, -33.513195, 0.0],
            [-70.531526, -33.513359, 0.0],
            [-70.529441, -33.513741, 0.0],
            [-70.526798, -33.51467, 0.0],
            [-70.524168, -33.514166, 0.0],
            [-70.522355, -33.513938, 0.0],
            [-70.518263, -33.51325, 0.0],
            [-70.515938, -33.513404, 0.0],
            [-70.483249, -33.514927, 0.0],
            [-70.47533, -33.515241, 0.0],
            [-70.468851, -33.515392, 0.0],
            [-70.467673, -33.515508, 0.0],
            [-70.464731, -33.515935, 0.0],
            [-70.464134, -33.515186, 0.0],
            [-70.464226, -33.514118, 0.0],
            [-70.461983, -33.512891, 0.0],
            [-70.461196, -33.512846, 0.0],
            [-70.460365, -33.51199, 0.0],
            [-70.459104, -33.512076, 0.0],
            [-70.458104, -33.510816, 0.0],
            [-70.456953, -33.509812, 0.0],
            [-70.45588, -33.50917, 0.0],
            [-70.455766, -33.508475, 0.0],
            [-70.454496, -33.507971, 0.0],
            [-70.453414, -33.507587, 0.0],
            [-70.452808, -33.507029, 0.0],
            [-70.45235, -33.506026, 0.0],
            [-70.451369, -33.504673, 0.0],
            [-70.450074, -33.503984, 0.0],
            [-70.448889, -33.503057, 0.0],
            [-70.448456, -33.503008, 0.0],
            [-70.447605, -33.503002, 0.0],
            [-70.446549, -33.502995, 0.0],
            [-70.445813, -33.502716, 0.0],
            [-70.443402, -33.502476, 0.0],
            [-70.443084, -33.501795, 0.0],
            [-70.441355, -33.501021, 0.0],
            [-70.439932, -33.499676, 0.0],
            [-70.438237, -33.49668, 0.0],
            [-70.437283, -33.494441, 0.0],
            [-70.437204, -33.493892, 0.0],
            [-70.437156, -33.491811, 0.0],
            [-70.437177, -33.490325, 0.0],
            [-70.437143, -33.490124, 0.0],
            [-70.437169, -33.489524, 0.0],
            [-70.437671, -33.489088, 0.0],
            [-70.437944, -33.488863, 0.0],
            [-70.438166, -33.488811, 0.0],
            [-70.438485, -33.488686, 0.0],
            [-70.438886, -33.488588, 0.0],
            [-70.439525, -33.488381, 0.0],
            [-70.440326, -33.488126, 0.0],
            [-70.440726, -33.488006, 0.0],
            [-70.440979, -33.487879, 0.0],
            [-70.441606, -33.487558, 0.0],
            [-70.442146, -33.48728, 0.0],
            [-70.442522, -33.487085, 0.0],
            [-70.442923, -33.486884, 0.0],
            [-70.443445, -33.486614, 0.0],
            [-70.444379, -33.486136, 0.0],
            [-70.444616, -33.486029, 0.0],
            [-70.445052, -33.485804, 0.0],
            [-70.445527, -33.485573, 0.0],
            [-70.445831, -33.485404, 0.0],
            [-70.445994, -33.485327, 0.0],
            [-70.446322, -33.485158, 0.0],
            [-70.446506, -33.485061, 0.0],
            [-70.446764, -33.48493, 0.0],
            [-70.447046, -33.484791, 0.0],
            [-70.447353, -33.48463, 0.0],
            [-70.447546, -33.484534, 0.0],
            [-70.447723, -33.484443, 0.0],
            [-70.44787, -33.484375, 0.0],
            [-70.448042, -33.484284, 0.0],
            [-70.448155, -33.484223, 0.0],
            [-70.448344, -33.48414, 0.0],
            [-70.448366, -33.484119, 0.0],
            [-70.448513, -33.484048, 0.0],
            [-70.448681, -33.483962, 0.0],
            [-70.44894, -33.483829, 0.0],
            [-70.449087, -33.48373, 0.0],
            [-70.449217, -33.483634, 0.0],
            [-70.449393, -33.483525, 0.0],
            [-70.449543, -33.483409, 0.0],
            [-70.449727, -33.483292, 0.0],
            [-70.449999, -33.483104, 0.0],
            [-70.450447, -33.482798, 0.0],
            [-70.450869, -33.48251, 0.0],
            [-70.451254, -33.482247, 0.0],
            [-70.451792, -33.481875, 0.0],
            [-70.451936, -33.481781, 0.0],
            [-70.45209, -33.481674, 0.0],
            [-70.452347, -33.481507, 0.0],
            [-70.452581, -33.481343, 0.0],
            [-70.452868, -33.481141, 0.0],
            [-70.453318, -33.480844, 0.0],
            [-70.453622, -33.480635, 0.0],
            [-70.454032, -33.48035, 0.0],
            [-70.454372, -33.48012, 0.0],
            [-70.45476, -33.479854, 0.0],
            [-70.454987, -33.4797, 0.0],
            [-70.455229, -33.479561, 0.0],
            [-70.455587, -33.479352, 0.0],
            [-70.455795, -33.479234, 0.0],
            [-70.456021, -33.479101, 0.0],
            [-70.456211, -33.478985, 0.0],
            [-70.456419, -33.478872, 0.0],
            [-70.456632, -33.478745, 0.0],
            [-70.456878, -33.4786, 0.0],
            [-70.457075, -33.47849, 0.0],
            [-70.457225, -33.478399, 0.0],
            [-70.457466, -33.478262, 0.0],
            [-70.457809, -33.478064, 0.0],
            [-70.458144, -33.477865, 0.0],
            [-70.458275, -33.477788, 0.0],
            [-70.458602, -33.477598, 0.0],
            [-70.458901, -33.477426, 0.0],
            [-70.459258, -33.477219, 0.0],
            [-70.459423, -33.477123, 0.0],
            [-70.459559, -33.477044, 0.0],
            [-70.459802, -33.476906, 0.0],
            [-70.460126, -33.476719, 0.0],
            [-70.460404, -33.476551, 0.0],
            [-70.460747, -33.476355, 0.0],
            [-70.46109, -33.476152, 0.0],
            [-70.461482, -33.475927, 0.0],
            [-70.461771, -33.475871, 0.0],
            [-70.462371, -33.475757, 0.0],
            [-70.462736, -33.475682, 0.0],
            [-70.463423, -33.475557, 0.0],
            [-70.464032, -33.475435, 0.0],
            [-70.464739, -33.4753, 0.0],
            [-70.465398, -33.475156, 0.0],
            [-70.466045, -33.475048, 0.0],
            [-70.46652, -33.474944, 0.0],
            [-70.467161, -33.474996, 0.0],
            [-70.468707, -33.475088, 0.0],
            [-70.46979, -33.475165, 0.0],
            [-70.470782, -33.475226, 0.0],
            [-70.471291, -33.475254, 0.0],
            [-70.471891, -33.475289, 0.0],
            [-70.472675, -33.474712, 0.0],
            [-70.473049, -33.474466, 0.0],
            [-70.473532, -33.474108, 0.0],
            [-70.474036, -33.47376, 0.0],
            [-70.474469, -33.473646, 0.0],
            [-70.475146, -33.473447, 0.0],
            [-70.475461, -33.472826, 0.0],
            [-70.476131, -33.471627, 0.0],
            [-70.476378, -33.47124, 0.0],
            [-70.476713, -33.470574, 0.0],
            [-70.476937, -33.470144, 0.0],
            [-70.477135, -33.469962, 0.0],
            [-70.477568, -33.469583, 0.0],
            [-70.477945, -33.46925, 0.0],
            [-70.478365, -33.468867, 0.0],
            [-70.478785, -33.468444, 0.0],
            [-70.478943, -33.468342, 0.0],
            [-70.479318, -33.468008, 0.0],
            [-70.479724, -33.467702, 0.0],
            [-70.480032, -33.467513, 0.0],
            [-70.480396, -33.467207, 0.0],
            [-70.481065, -33.466723, 0.0],
            [-70.48137, -33.466498, 0.0],
            [-70.481688, -33.466329, 0.0],
            [-70.481953, -33.466053, 0.0],
            [-70.482604, -33.465598, 0.0],
            [-70.483859, -33.46471, 0.0],
            [-70.484269, -33.464358, 0.0],
            [-70.484583, -33.464176, 0.0],
            [-70.485201, -33.463685, 0.0],
            [-70.486127, -33.463034, 0.0],
            [-70.486399, -33.461782, 0.0],
            [-70.486616, -33.460648, 0.0],
            [-70.487431, -33.45935, 0.0],
            [-70.487688, -33.458769, 0.0],
            [-70.489106, -33.456009, 0.0],
            [-70.489579, -33.455048, 0.0],
            [-70.490424, -33.454577, 0.0],
            [-70.490889, -33.454461, 0.0],
            [-70.491973, -33.454534, 0.0],
            [-70.493297, -33.454542, 0.0],
            [-70.494551, -33.454547, 0.0],
            [-70.4962, -33.454609, 0.0],
            [-70.498501, -33.454683, 0.0],
            [-70.503371, -33.45474, 0.0],
            [-70.505676, -33.454802, 0.0],
            [-70.504261, -33.45722, 0.0],
            [-70.506234, -33.459302, 0.0],
            [-70.507518, -33.459501, 0.0],
            [-70.509029, -33.459733, 0.0],
            [-70.511374, -33.459761, 0.0],
            [-70.514155, -33.459832, 0.0],
            [-70.517566, -33.459755, 0.0],
            [-70.521397, -33.459589, 0.0],
            [-70.524931, -33.459415, 0.0],
            [-70.52958, -33.459288, 0.0],
            [-70.529749, -33.460001, 0.0],
            [-70.530233, -33.46123, 0.0],
            [-70.530661, -33.462407, 0.0],
            [-70.530736, -33.463901, 0.0],
            [-70.531273, -33.4654, 0.0],
            [-70.534182, -33.464653, 0.0],
            [-70.538163, -33.463566, 0.0],
            [-70.540158, -33.462978, 0.0],
            [-70.541132, -33.462871, 0.0],
            [-70.542238, -33.46287, 0.0],
            [-70.543926, -33.463641, 0.0],
            [-70.544432, -33.463612, 0.0],
            [-70.546255, -33.46343, 0.0],
            [-70.550837, -33.462633, 0.0],
            [-70.552284, -33.46265, 0.0],
            [-70.560493, -33.462529, 0.0],
            [-70.562188, -33.462512, 0.0],
            [-70.565462, -33.461439, 0.0],
            [-70.567883, -33.460597, 0.0],
            [-70.572131, -33.459029, 0.0],
            [-70.572287, -33.459221, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Florida 2",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.58816, -33.510342, 0.0],
            [-70.5786476, -33.5223294, 0.0],
            [-70.568163, -33.547561, 0.0],
            [-70.5539, -33.548095, 0.0],
            [-70.529928, -33.544835, 0.0],
            [-70.5158539, -33.5421111, 0.0],
            [-70.514304, -33.541719, 0.0],
            [-70.500324, -33.545829, 0.0],
            [-70.4955979, -33.542898, 0.0],
            [-70.486075, -33.540658, 0.0],
            [-70.483249, -33.514927, 0.0],
            [-70.518263, -33.51325, 0.0],
            [-70.526798, -33.51467, 0.0],
            [-70.531526, -33.513359, 0.0],
            [-70.564317, -33.511241, 0.0],
            [-70.56578, -33.511687, 0.0],
            [-70.58816, -33.510342, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Santiago Centro 1",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6609345, -33.4317163, 0.0],
            [-70.6608498, -33.4320732, 0.0],
            [-70.6596174, -33.4389871, 0.0],
            [-70.6605361, -33.4457712, 0.0],
            [-70.6606623, -33.4462367, 0.0],
            [-70.6597368, -33.4463665, 0.0],
            [-70.6500687, -33.4438706, 0.0],
            [-70.646099, -33.4429127, 0.0],
            [-70.6435884, -33.4421517, 0.0],
            [-70.6418219, -33.4413248, 0.0],
            [-70.6397834, -33.4396954, 0.0],
            [-70.635227, -33.4372817, 0.0],
            [-70.635347, -33.4369335, 0.0],
            [-70.6353647, -33.4357693, 0.0],
            [-70.6355777, -33.4357381, 0.0],
            [-70.6515637, -33.4320851, 0.0],
            [-70.6561127, -33.4310107, 0.0],
            [-70.6575477, -33.4306391, 0.0],
            [-70.6589158, -33.4303585, 0.0],
            [-70.6598664, -33.430167, 0.0],
            [-70.6605195, -33.4300999, 0.0],
            [-70.6615682, -33.4299029, 0.0],
            [-70.6618002, -33.4298391, 0.0],
            [-70.661634, -33.4301525, 0.0],
            [-70.6614978, -33.4304616, 0.0],
            [-70.6614415, -33.4305892, 0.0],
            [-70.6611433, -33.4307033, 0.0],
            [-70.6610122, -33.4313712, 0.0],
            [-70.6609345, -33.4317163, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Santiago centro 2",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6606623, -33.4462367, 0.0],
            [-70.6602632, -33.4446094, 0.0],
            [-70.6596174, -33.4389871, 0.0],
            [-70.6605956, -33.4339299, 0.0],
            [-70.6611433, -33.4307033, 0.0],
            [-70.6623661, -33.429604, 0.0],
            [-70.671482, -33.4266775, 0.0],
            [-70.67191, -33.428253, 0.0],
            [-70.6744682, -33.4274908, 0.0],
            [-70.6754982, -33.4270969, 0.0],
            [-70.677514, -33.426871, 0.0],
            [-70.6787597, -33.4274192, 0.0],
            [-70.6799185, -33.4279565, 0.0],
            [-70.68066, -33.429024, 0.0],
            [-70.680075, -33.438406, 0.0],
            [-70.691556, -33.439251, 0.0],
            [-70.692063, -33.444502, 0.0],
            [-70.679563, -33.443957, 0.0],
            [-70.678941, -33.450959, 0.0],
            [-70.67784, -33.450733, 0.0],
            [-70.6766054, -33.4501532, 0.0],
            [-70.6700479, -33.4485484, 0.0],
            [-70.6653702, -33.4474025, 0.0],
            [-70.6610786, -33.4463283, 0.0],
            [-70.6606623, -33.4462367, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Santiago Centro 5",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.67295, -33.4706893, 0.0],
            [-70.672944, -33.474765, 0.0],
            [-70.673116, -33.474855, 0.0],
            [-70.673236, -33.47525, 0.0],
            [-70.673535, -33.475614, 0.0],
            [-70.6738877, -33.475889, 0.0],
            [-70.67452, -33.476009, 0.0],
            [-70.674597, -33.47675, 0.0],
            [-70.675094, -33.476888, 0.0],
            [-70.679199, -33.476935, 0.0],
            [-70.679226, -33.478265, 0.0],
            [-70.6721067, -33.4784127, 0.0],
            [-70.6715481, -33.4784208, 0.0],
            [-70.671459, -33.476079, 0.0],
            [-70.6657767, -33.475889, 0.0],
            [-70.6628155, -33.4757995, 0.0],
            [-70.6590819, -33.4759069, 0.0],
            [-70.654939, -33.476442, 0.0],
            [-70.6547271, -33.4737116, 0.0],
            [-70.6566357, -33.470412, 0.0],
            [-70.6564855, -33.4677629, 0.0],
            [-70.6566142, -33.4586334, 0.0],
            [-70.6568728, -33.4557753, 0.0],
            [-70.6585454, -33.4535134, 0.0],
            [-70.6606623, -33.4462367, 0.0],
            [-70.6735121, -33.4493598, 0.0],
            [-70.6772036, -33.4503711, 0.0],
            [-70.67784, -33.450733, 0.0],
            [-70.67915, -33.469364, 0.0],
            [-70.6793923, -33.4699911, 0.0],
            [-70.680024, -33.470503, 0.0],
            [-70.680133, -33.470693, 0.0],
            [-70.67295, -33.4706893, 0.0],
          ],
          [
            [-70.6728047, -33.4706892, 0.0],
            [-70.67295, -33.470683, 0.0],
            [-70.67295, -33.4706893, 0.0],
            [-70.6728047, -33.4706892, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Ñuñoa 1",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.5987535, -33.4734752, 0.0],
            [-70.5987105, -33.4717211, 0.0],
            [-70.5976806, -33.4549659, 0.0],
            [-70.5986247, -33.4540708, 0.0],
            [-70.5977664, -33.4492369, 0.0],
            [-70.5985818, -33.4485208, 0.0],
            [-70.599136, -33.444872, 0.0],
            [-70.599199, -33.444651, 0.0],
            [-70.6043217, -33.4451996, 0.0],
            [-70.6081626, -33.445683, 0.0],
            [-70.6086, -33.445683, 0.0],
            [-70.6114671, -33.4464887, 0.0],
            [-70.613896, -33.446824, 0.0],
            [-70.613788, -33.447521, 0.0],
            [-70.618407, -33.448376, 0.0],
            [-70.618762, -33.447285, 0.0],
            [-70.631486, -33.44986, 0.0],
            [-70.6262183, -33.4691102, 0.0],
            [-70.625148, -33.469544, 0.0],
            [-70.624065, -33.470751, 0.0],
            [-70.622977, -33.474964, 0.0],
            [-70.5987535, -33.4734752, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Ñuñoa 2",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.597922, -33.444833, 0.0],
            [-70.599199, -33.444651, 0.0],
            [-70.5985818, -33.4485208, 0.0],
            [-70.5977664, -33.4492369, 0.0],
            [-70.598257, -33.4519567, 0.0],
            [-70.5986247, -33.4540708, 0.0],
            [-70.5976806, -33.4549659, 0.0],
            [-70.5987535, -33.4734752, 0.0],
            [-70.5970954, -33.473411, 0.0],
            [-70.5944132, -33.4735184, 0.0],
            [-70.5924927, -33.4735632, 0.0],
            [-70.58947, -33.47396, 0.0],
            [-70.5888556, -33.4737511, 0.0],
            [-70.5884587, -33.4742254, 0.0],
            [-70.5873858, -33.4747534, 0.0],
            [-70.586983, -33.474041, 0.0],
            [-70.5868923, -33.4737958, 0.0],
            [-70.58688, -33.473469, 0.0],
            [-70.586383, -33.473509, 0.0],
            [-70.585783, -33.47348, 0.0],
            [-70.5840062, -33.4731962, 0.0],
            [-70.577364, -33.469821, 0.0],
            [-70.5772, -33.47006, 0.0],
            [-70.576922, -33.470248, 0.0],
            [-70.576409, -33.470243, 0.0],
            [-70.5759297, -33.4700912, 0.0],
            [-70.5757151, -33.4698227, 0.0],
            [-70.575587, -33.469487, 0.0],
            [-70.575839, -33.469137, 0.0],
            [-70.576105, -33.468798, 0.0],
            [-70.575896, -33.467829, 0.0],
            [-70.5753504, -33.4664933, 0.0],
            [-70.573802, -33.462603, 0.0],
            [-70.5721894, -33.4589562, 0.0],
            [-70.5706915, -33.4532272, 0.0],
            [-70.5738054, -33.4375976, 0.0],
            [-70.5778102, -33.4360633, 0.0],
            [-70.582266, -33.433738, 0.0],
            [-70.586819, -33.439008, 0.0],
            [-70.5910289, -33.4455231, 0.0],
            [-70.592776, -33.447745, 0.0],
            [-70.597922, -33.444833, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Providencia 1",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6352243, -33.4372974, 0.0],
            [-70.631486, -33.44986, 0.0],
            [-70.618762, -33.447285, 0.0],
            [-70.618407, -33.448376, 0.0],
            [-70.613788, -33.447521, 0.0],
            [-70.6165458, -33.4347812, 0.0],
            [-70.6202366, -33.4286927, 0.0],
            [-70.6255581, -33.4326682, 0.0],
            [-70.6280472, -33.434817, 0.0],
            [-70.6312229, -33.4362495, 0.0],
            [-70.6332399, -33.4367151, 0.0],
            [-70.6352243, -33.4372974, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Providencia 3",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6283905, -33.4192726, 0.0],
            [-70.623584, -33.4260781, 0.0],
            [-70.6224682, -33.4275108, 0.0],
            [-70.6202366, -33.4286927, 0.0],
            [-70.616975, -33.4274391, 0.0],
            [-70.6141426, -33.4254334, 0.0],
            [-70.6119968, -33.4246454, 0.0],
            [-70.6092502, -33.4223889, 0.0],
            [-70.6081774, -33.4220307, 0.0],
            [-70.6059458, -33.4197024, 0.0],
            [-70.605302, -33.4179114, 0.0],
            [-70.6031563, -33.4173383, 0.0],
            [-70.60671, -33.416169, 0.0],
            [-70.60519, -33.4129316, 0.0],
            [-70.605368, -33.411991, 0.0],
            [-70.603906, -33.408994, 0.0],
            [-70.608032, -33.408324, 0.0],
            [-70.6105115, -33.4060893, 0.0],
            [-70.61422, -33.408101, 0.0],
            [-70.617335, -33.4114271, 0.0],
            [-70.618485, -33.41242, 0.0],
            [-70.624303, -33.415793, 0.0],
            [-70.625403, -33.41748, 0.0],
            [-70.6279351, -33.4192003, 0.0],
            [-70.6283905, -33.4192726, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Providencia 4",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6202366, -33.4286211, 0.0],
            [-70.6165458, -33.4347096, 0.0],
            [-70.614186, -33.446494, 0.0],
            [-70.6119973, -33.446494, 0.0],
            [-70.6086, -33.4456114, 0.0],
            [-70.599199, -33.4445794, 0.0],
            [-70.597922, -33.4447614, 0.0],
            [-70.5928068, -33.4476583, 0.0],
            [-70.5874927, -33.4399408, 0.0],
            [-70.582266, -33.4336664, 0.0],
            [-70.584317, -33.4320262, 0.0],
            [-70.5857761, -33.430522, 0.0],
            [-70.5908401, -33.4262598, 0.0],
            [-70.599981, -33.4194185, 0.0],
            [-70.601509, -33.4179084, 0.0],
            [-70.6031563, -33.4172667, 0.0],
            [-70.605302, -33.4178398, 0.0],
            [-70.6059458, -33.4196308, 0.0],
            [-70.6081774, -33.4219591, 0.0],
            [-70.6092502, -33.4223173, 0.0],
            [-70.6119968, -33.4245738, 0.0],
            [-70.6144168, -33.425576, 0.0],
            [-70.616975, -33.4273675, 0.0],
            [-70.6202366, -33.4286211, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Providencia 2",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6283905, -33.4192726, 0.0],
            [-70.63012, -33.419882, 0.0],
            [-70.632892, -33.422537, 0.0],
            [-70.634328, -33.427183, 0.0],
            [-70.636292, -33.430588, 0.0],
            [-70.6352782, -33.4357163, 0.0],
            [-70.635472, -33.4363598, 0.0],
            [-70.6352243, -33.4372974, 0.0],
            [-70.6312229, -33.4362495, 0.0],
            [-70.6280472, -33.434817, 0.0],
            [-70.6265456, -33.4334948, 0.0],
            [-70.6202366, -33.4286927, 0.0],
            [-70.6224682, -33.4275108, 0.0],
            [-70.6283905, -33.4192726, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Estacion Cebntral 1",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.7069827, -33.4487801, 0.0],
            [-70.7067287, -33.4507701, 0.0],
            [-70.7062269, -33.4516486, 0.0],
            [-70.7053256, -33.4569478, 0.0],
            [-70.7048965, -33.4637862, 0.0],
            [-70.7045191, -33.4707226, 0.0],
            [-70.7013345, -33.4798595, 0.0],
            [-70.6948127, -33.4786111, 0.0],
            [-70.69178, -33.47786, 0.0],
            [-70.6907127, -33.4770611, 0.0],
            [-70.6891937, -33.4770791, 0.0],
            [-70.6861527, -33.4762111, 0.0],
            [-70.68582, -33.4769, 0.0],
            [-70.6763578, -33.4769243, 0.0],
            [-70.6746798, -33.476753, 0.0],
            [-70.67452, -33.476009, 0.0],
            [-70.6738877, -33.475889, 0.0],
            [-70.673236, -33.47525, 0.0],
            [-70.6730718, -33.4747831, 0.0],
            [-70.672944, -33.474765, 0.0],
            [-70.6728373, -33.4705757, 0.0],
            [-70.680133, -33.470693, 0.0],
            [-70.6793923, -33.4699911, 0.0],
            [-70.6790268, -33.4692923, 0.0],
            [-70.67784, -33.450733, 0.0],
            [-70.6784103, -33.4508609, 0.0],
            [-70.678941, -33.450959, 0.0],
            [-70.6794557, -33.4439481, 0.0],
            [-70.6907344, -33.4444512, 0.0],
            [-70.692087, -33.444488, 0.0],
            [-70.6922817, -33.4479591, 0.0],
            [-70.6969572, -33.4479247, 0.0],
            [-70.7069827, -33.4487801, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Estacion Central 2",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.731222, -33.454977, 0.0],
            [-70.7312178, -33.4552814, 0.0],
            [-70.731261, -33.467538, 0.0],
            [-70.7341897, -33.4694412, 0.0],
            [-70.722682, -33.472881, 0.0],
            [-70.72203, -33.47332, 0.0],
            [-70.71243, -33.48114, 0.0],
            [-70.71103, -33.48126, 0.0],
            [-70.7107579, -33.4810393, 0.0],
            [-70.70883, -33.4808, 0.0],
            [-70.70772, -33.48097, 0.0],
            [-70.7050073, -33.4806098, 0.0],
            [-70.7013345, -33.4798595, 0.0],
            [-70.7045191, -33.4707226, 0.0],
            [-70.705136, -33.457376, 0.0],
            [-70.7086551, -33.4583785, 0.0],
            [-70.7123887, -33.4588798, 0.0],
            [-70.731222, -33.454977, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Santiago Centro 3",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6585235, -33.4529217, 0.0],
            [-70.6585454, -33.4535134, 0.0],
            [-70.6568069, -33.4555176, 0.0],
            [-70.6565529, -33.4604645, 0.0],
            [-70.6447906, -33.4588115, 0.0],
            [-70.629899, -33.4557324, 0.0],
            [-70.6314869, -33.4501109, 0.0],
            [-70.6352243, -33.4372974, 0.0],
            [-70.6397834, -33.4396954, 0.0],
            [-70.6427307, -33.4420181, 0.0],
            [-70.6597368, -33.4463665, 0.0],
            [-70.6606623, -33.4462367, 0.0],
            [-70.6585235, -33.4529217, 0.0],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Name: "Santiago Centro 6",
        description: null,
        timestamp: null,
        begin: null,
        end: null,
        altitudeMode: null,
        tessellate: -1,
        extrude: 0,
        visibility: -1,
        drawOrder: null,
        icon: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.6459604, -33.4590593, 0.0],
            [-70.6564993, -33.4605092, 0.0],
            [-70.6564319, -33.4678076, 0.0],
            [-70.6565821, -33.4704567, 0.0],
            [-70.6546735, -33.4737563, 0.0],
            [-70.6549297, -33.4767802, 0.0],
            [-70.6419134, -33.4763987, 0.0],
            [-70.6385789, -33.4758495, 0.0],
            [-70.6267343, -33.4715538, 0.0],
            [-70.6250943, -33.4695887, 0.0],
            [-70.6261647, -33.4691549, 0.0],
            [-70.6298331, -33.4555033, 0.0],
            [-70.6452308, -33.4589161, 0.0],
            [-70.6459604, -33.4590593, 0.0],
          ],
        ],
      },
    },
  ],
};
