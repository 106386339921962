import React from "react";
import { format } from "date-fns";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { minWidth } from "@mui/system";

const PlanerDocPDF = (props) => {
  const styles = StyleSheet.create({
    page: { padding: "2%" },
    section: { textAlign: "letf", margin: 10 },
    img: { color: "#050505", width: "120px" },
    imgAvatar: { width: "70px", height: "70px", borderRadius: "100%" },
    text: { fontSize: "16px", marginTop: "3px" },
    text2: { fontSize: "12px", marginBottom: 1 },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      marginTop: "10px",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableCol: {
      width: "11%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColCant: {
      width: "4%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColDescipcion: {
      width: "23%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
      textAlign: "letf",
    },
    tableCell: {
      marginTop: 5,
      fontSize: 10,
      margin: "auto",
      flexDirection: "row",
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableRowItem: {
      margin: "auto",
      flexDirection: "row",

      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableCellItem: {
      margin: "auto",
      marginTop: 5,
      fontSize: 8,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      minHeight: "25px",
    },
  });

  //manejar Props

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>

          <Text style={styles.text}>Guía de Despachos a Retirar en Bodega</Text>
          <Text style={styles.text}>DOC#{props.documentID} hoja 1 de 2</Text>

          <Image
            //src={{}}
            src={
              props.newRoute.driverAvatar ? props.newRoute.driverAvatar : null
            }
            source={{
              // uri:props.newRoute.driverAvatar,
              //method: 'GET' ,
              headers: { "Access-Control-Allow-Origin": "*" },
            }}
            style={styles.imgAvatar}
            alt=""
          ></Image>
          {props.newRoute.driverName ? (
            <Text>{props.newRoute.driverName}</Text>
          ) : (
            <>Seleccione un conductor</>
          )}
          <Text style={styles.text2}>
            Se ha asignado las siguientes órdenes del día{" "}
            {props.newRoute.dateDeliveryText} , para completar las entregas al
            cliente final, por favor recuerda tomar todas la evidencias
            correctamente, que se solicitan el app BICCI RIDER Retira en{" "}
            {props.newRoute.originAdressName}
          </Text>
          <Text style={styles.text2}>
            Total de Paquetes: {props.newRoute.totalPack}{" "}
          </Text>
          <Text style={styles.text2}>
            Total de Puntos de entrega: {props.waitPointManifest}{" "}
          </Text>

          {props.manifesOrders ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>CÓDIGO</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>TIM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>CLIENTE</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>FECHA</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>SOC</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>SKU</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>CANT</Text>
                </View>
                <View style={styles.tableColDescipcion}>
                  <Text style={styles.tableCell}>DESCRIPCIÓN</Text>
                </View>
              </View>

              {props.manifesOrders.map((element, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.orderID}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.F12}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>
                      {element.CLIENTENOMBRES.toLowerCase()}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>
                      {format(element.timeStamp * 1000, "dd/MM/yy")}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.SOC}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.SKU}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.UNIDADES}</Text>
                  </View>
                  <View style={styles.tableColDescipcion}>
                    <Text style={styles.tableCellItem}>{element.PRODUCTO}</Text>
                  </View>
                </View>
              ))}
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default PlanerDocPDF;
