import React, {
  useState,
  useContext,
  useMemo,
  lazy,
  Suspense,
  useRef,
} from "react";

import {
  Button,
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  Container,
  TextField,
} from "@mui/material";
import { Grid } from "semantic-ui-react";
import { format } from "date-fns";
import Moment from "moment";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import useGlobalConfig from "../../../hooks/globalConfig";
import { PDFViewer } from "@react-pdf/renderer";
import CierreOpsPdf from "./CierreOpsPdf";
import ModalAccionsDispacth from "../../../components/ModalAccionsDispacth/ModalAccionsDispacth";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import * as XLSX from "xlsx";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";

import ToolDashOrdersContainer from "../../../components/Dashboard/dashboard/ToolDashOrdersContainer/ToolDashOrdersContainer";
import { PropaneSharp } from "@mui/icons-material";

import { useLoadScript } from "@react-google-maps/api";
import ToolDashOrdersContainerPics from "../../../components/Dashboard/dashboard/ToolDashOrdersContainerPics/ToolDashOrdersContainerPics";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getDoc } from "firebase/firestore";
import ToolDashAppBar from "../../../components/Dashboard/dashboard/ToolDashAppBar/ToolDashAppBar";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import { ConfirmDialog } from "../../../components/CustomDialog";
import { useBoolean } from "../../../hooks/use-boolean";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";

const libraries = ["places"];
//import ToolDashResumenOps from "../../../components/Dashboard/dashboard/ToolDashResumenOps/ToolDashResumenOps";
const ToolDashResumenOps = lazy(() =>
  import(
    "../../../components/Dashboard/dashboard/ToolDashResumenOps/ToolDashResumenOps"
  )
);

// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
// const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

var ordersForAccions = [];
var dataOrdersForAccions = [];
var listadeItemsNoMach = [];
var popUpAccion = "-";

const GuiasByFalabellaMonitor = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const {
    rosettaVehicles,
    translateStateBicciFalabella,
    styleStateBicciFalabella,
    falabellaExcepcions,
    retiredTestsArray,
  } = useGlobalConfig();
  const confirm = useBoolean();

  const { VehiclesNames } = useGetVehiclesNames();
  const { widthHeight, movilActive } = useContext(ScreenSizeContext);
  const { userdata } = useObtenerDatosdeCliente(props.usuarioStorage.uid);
  const companyId = useMemo(() => {
    if (userdata) {
      if (userdata.companyCollaborator === "falabella") {
        return `${process.env.REACT_APP_BXTOKETN}`;
      }
      return userdata.userCompanyID;
    }
    return "";
  }, [userdata]);

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  const [falabellaOrders, setFalabellaOrders] = useState([]);

  const [BicciOrders, setBicciOrders] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [showEdit, setShowEdit] = useState(true);

  const [userOrders, setUserOrders] = React.useState(null);

  const [progressValue, setProgressValue] = React.useState(0);

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    React.useState(false);

  const [menssajeAdmin, setMenssajeAdmin] = React.useState(null);

  const [containerHistory, setContainerHistory] = React.useState({});

  const [ordersHistoryGetProgres, setOrdersHistoryGetProgres] =
    React.useState(0);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [render, setRender] = useState(true);

  const [path, setPath] = React.useState([]);

  const [realTimeisActive, setRealTimeisActive] = React.useState(false);

  const [metricsOps, setMetricsOps] = React.useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [showPopUp, setShowPopUp] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [origenGeoCode, setOrigenGeoCode] = React.useState(null);
  const [adressOrigin, setAdressOrigin] = React.useState(null);
  const [listIdstoSelct, setListIdstoSelct] = React.useState("");
  const [SelecteDestiny, setSelecteDestiny] = React.useState(null);
  const [destinyGeoCode, setDestinyGeoCode] = React.useState(null);
  const [adressDestiny, setAdressDestiny] = React.useState(null);
  const [CheckedChangeOE, setCheckedChangeOE] = React.useState(false);

  const [typeRequets, setTypeRequets] = React.useState({
    typeRequetsCreate: true,
    typeRequetsUpdate: false,
  });

  const [resetCicle, setResetCicle] = React.useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [Microzaonas, setMicrozonas] = useState([]);

  const [bicciOrdersExcepData, setBicciOrdersExcepData] = useState([]);

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [typeRquest, setTypeRquest] = React.useState(false);

  const [viewEvidences, setViewEvidences] = React.useState(true);

  const isMounted = useRef(true);

  //const [userDataOrders, setDataUserOrders] = React.useState([]);

  const listItems = [
    { id: "Creadas" },
    { id: "Ruta" },
    { id: "Incidencias" },
    { id: "Finalizadas" },
    { id: "Total" },
  ];

  const toggleShowRefres = () => setRender(!render);

  const [cantListItems, setcantListItems] = React.useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const [especialRequest, setSpecialRequest] = React.useState("");

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispachtFalabella(initDate, endDate, typeRequets, companyId);
      findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);
    }
  }, [companyId, initDate, endDate]);

  React.useEffect(() => {}, [itemsDataOrdersForAccions]);

  React.useEffect(() => {
    if (
      BicciOrders.length > 0 ||
      falabellaOrders.length > 0 ||
      bicciOrdersExcepData.length > 0
    ) {
      setMenssajeAdmin(<span className="alert alert-info">Buscando... </span>);

      BiccidataMerge();
    }
  }, [BicciOrders, falabellaOrders, bicciOrdersExcepData]);

  React.useEffect(() => {
    isMounted.current = true;
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando Ordenes... </span>
    );
    findOrdesrDispachtFalabella(initDate, endDate, typeRquest, companyId);
    findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);
    return () => {
      isMounted.current = false;
    };
  }, [initDate, endDate, CheckedChangeOE, typeRequets]);

  React.useEffect(() => {
    if (dataMerge) {
      setMenssajeAdmin(
        <span className="alert alert-info">Buscando Ordenes... </span>
      );
      getResumenOps(dataMerge);
    }
  }, [dataMerge]);

  React.useEffect(() => {
    let interval;
    if (realTimeisActive === true) {
      interval = setInterval(() => {
        resetCicle.currentRequest += 1;
        setCheckedChangeOE(false);
        findOrdesrDispachtFalabella(initDate, endDate, typeRquest, companyId);
        findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);

        if (resetCicle.maxRequest === resetCicle.currentRequest) {
          setRender(!render);
        }
        if (resetCicle.desactiveRealtime === resetCicle.currentRequest) {
          setRealTimeisActive(!realTimeisActive);
          setRender(!render);
        }
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [realTimeisActive]);

  React.useEffect(() => {
    if (listIdstoSelct) {
      selecListItames(listIdstoSelct);
    }
  }, [listIdstoSelct]);

  React.useEffect(() => {}, [render]);

  const findOrdesrDispachtFalabella = async (
    initDate,
    endDate,
    typeRquest,
    companyId
  ) => {
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Falabella </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "updatedAt";
    }
    setProgressValue(55);

    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );
    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }
    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      var res = new Date(fecha2);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    function formatDate(fecha) {
      var res = new Date(fecha);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangInit = restaUno(i);
    var rangFinal = sumarDos(e);
    var rangInitial = formatDate(i);

    const dataOrder = {
      data: "",
      get dataEbiex() {
        return this.data;
      },
      set dataEbiex(data) {
        this.data = data;
      },
    };
    dataOrder.data = [];
    if (isMounted.current) {
      // Actualiza el estado solo si el componente está montado

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
    }
    async function callExceptions() {
      setProgressValue(70);
      try {
        const allOrdersRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
        const querySnapshot = await getDocs(q);

        const dataOrdesHasExcepciones = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dataOrdesHasExcepciones.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );
        let filtrarSolucionados = dataOrdesHasExcepciones.filter(
          (item) =>
            (item.falabella_status !== "1029") &
            (item.falabella_status !== "1046") &
            (item.falabella_status !== "1023") &
            (item.falabella_status !== "1081") &
            (item.falabella_status !== "1007") &
            (item.falabella_status !== "99")
        );

        let dataOrdesfilterHasExcepciones = filtrarSolucionados.filter(
          (item) => !retiredTestsArray.includes("F" + item.id.toString())
        );

        let clearRang = dataOrder.data;
        const arrayId = clearRang.map((obj) => obj.id);

        let clearDataOrdesfilterHasExcepciones =
          dataOrdesfilterHasExcepciones.filter(
            (item) => !arrayId.includes(item.id.toString())
          );

        if (clearDataOrdesfilterHasExcepciones.length > 0) {
          let arryExcepcion = [];
          for (let i = 0; i < clearDataOrdesfilterHasExcepciones.length; i++) {
            arryExcepcion.push("F" + clearDataOrdesfilterHasExcepciones[i].id);
          }

          try {
            const ordersCollectionRef = collection(
              db,
              process.env.REACT_APP_COL_USERS_ORDERS
            );
            const querySnapshot = await getDocs(
              query(ordersCollectionRef, where("id", "in", arryExcepcion))
            );
            const orders = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setBicciOrdersExcepData(orders); // En test
          } catch (error) {}
          if (CheckedChangeOE === false) {
            const resultoFind = dataOrdesfilterHasExcepciones.concat(
              dataOrder.data
            );

            //setResumenTable(resultoFind);
            setFalabellaOrders(resultoFind);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }

          if (CheckedChangeOE !== false) {
            //setResumenTable(dataOrder.data);
            setFalabellaOrders(dataOrder.data);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }
        } else {
          //setResumenTable(dataOrder.data);
          setFalabellaOrders(dataOrder.data);
          setProgressValue(100);
          setDataMerge(dataOrder.data); // QA
        }
        //await BiccidataMerge();
      } catch (error) {}

      toggleShowRefres();
    }

    async function callNormal() {
      try {
        const q = query(
          allOrdersRef,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", endRang)
        );
        const querySnapshot = await getDocs(q);
        var dataOrdes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );

        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredTestsArray.includes("F" + item.id.toString())
        );

        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {}
    }

    async function callIgual() {
      try {
        const dataallOrdersfind5 = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(
          dataallOrdersfind5,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", rangFinal)
        );
        const querySnapshot5 = await getDocs(q);
        var dataOrdes = querySnapshot5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredTestsArray.includes("F" + item.id.toString())
        );
        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {}
    }
  };

  const findOrdesrDispachtBicci = async (
    initDate,
    endDate,
    typeRequets,
    companyId
  ) => {
    setProgressValue(65);
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Bicci </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "date";
    }

    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate() - 5);
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(i);
    var rangFinal = sumarDos(e);

    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    setMenssajeAdmin(
      <span className="alert alert-info">
        Creando resumenes Riders, Vehiculos y Ordenes{" "}
      </span>
    );

    async function callNormal() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", i),
          where(temptypeRquestCall, "<=", endRang)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (bicciOrdersExcepData.length > 0) {
          let mergedate = bicciOrdersExcepData.concat(dataOrdesBicci);
          setBicciOrders(mergedate);
        } else {
          setBicciOrders(dataOrdesBicci);
        }
      } catch (error) {}
    }

    async function callIgual() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", rangInit),
          where(temptypeRquestCall, "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {}
    }
  };

  const BiccidataMerge = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Uniendo data... </span>
    );
    var newdataMere = [];
    var DataOrders = BicciOrders;
    var DataOrdersExcep = bicciOrdersExcepData;

    async function initMerge(newdataMere) {
      const uniqueMap = new Map();

      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (item && item.id && !uniqueMap.has(item.id)) {
            // Asegura que el item y su ID estén definidos
            uniqueMap.set(item.id, item);
          }
        }
        return Array.from(uniqueMap.values());
      }

      var DataMerge = await removeDuplicatesById(falabellaOrders); // Remueve duplicados
      // console.log(DataMerge);
      //  console.log(newdataMere);
      for (let i = 0; i < DataMerge.length; i++) {
        let found = newdataMere.find(
          (element) => "F" + DataMerge[i].id === element.id
        );
        if (found) {
          //       console.log("encontro coincidencias");
          // Si encuentra una coincidencia, procede a fusionar
          DataMerge[i] = {
            // Asigna directamente al objeto, maneja undefined con ?? operador
            ...DataMerge[i], // Conserva propiedades existentes de DataMerge
            placeName: found.placeName ?? "",
            driverName: found.driverName ?? "",
            driverAvatar: found.driverAvatar ?? "",
            driverID: found.driverID ?? "",
            userNameReceptor: found.userNameReceptor ?? "",
            userDniReceptor: found.userDniReceptor ?? "",
            observationReceptor: found.observationReceptor ?? "",
            observationAdmin: found.observationAdmin ?? "",
            pics: found.pics ?? [],
            upDatedAtText: format(
              DataMerge[i].updatedAt.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString(),
            dateCreateText: format(
              DataMerge[i].date.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString(),
          };
        } else {
          //console.log("encontro coincidencias para", DataMerge[i].id);
        }
      }

      setDataMerge(DataMerge);
      setResumenTable(DataMerge);
    }

    // Luego sigue el resto de tu código donde preparas newdataMere y llamas a initMerge

    if (DataOrders.length > 0 || DataOrdersExcep.length > 0) {
      const dataOrdersIdSet = new Set(
        DataOrders.filter((item) => item && item.id).map((item) => item.id)
      );
      const filteredDataOrdersExcep = DataOrdersExcep.filter(
        (item) => item && item.id && !dataOrdersIdSet.has(item.id)
      );
      newdataMere = DataOrders.concat(filteredDataOrdersExcep);
    }

    await initMerge(newdataMere);
  };

  const callFindEspecialRequestData = async () => {
    let falabellaOrdersRequest = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );

    let BicciOrdersRequest = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    let ExpressOrdersRequest = collection(db, process.env.REACT_APP_COL_ORDERS);

    const especialRecuestData = async (especialRequest) => {
      let cantOrderItems = especialRequest.length;
      let arrayRequest = [];
      let arrayRequestMerge = [];

      for (let i = 0; i < cantOrderItems; i++) {
        try {
          let id = especialRequest[i].toString();
          console.log("F" + id.toString());
          const documentReft = doc(BicciOrdersRequest, "F" + id.toString());
          const dataallOrdersfind5 = await getDoc(documentReft);

          if (dataallOrdersfind5.exists()) {
            //  console.log(dataallOrdersfind5.data());
            arrayRequest.push(dataallOrdersfind5.data());
          } else {
            const documentReftExpress = doc(
              ExpressOrdersRequest,
              "F" + id.toString()
            );
            const dataallOrdersfind5Express = await getDoc(documentReftExpress);

            if (dataallOrdersfind5Express.exists()) {
              let newId = dataallOrdersfind5Express.data().int_lpn;

              const newDocumentReftBicci = doc(
                BicciOrdersRequest,
                "F" + newId.toString()
              );
              const newDataDocByLPN = await getDoc(newDocumentReftBicci);
              if (newDataDocByLPN.exists()) {
                //  console.log("encontrado :" + "F" + newId.toString());
                arrayRequest.push(newDataDocByLPN.data());
              } else {
                //  console.log("No se encontro en Bicci SOC : " + id + "LPN " + newId);
              }
            }
            // const alertDocumentReft = doc(ExpressOrdersRequest, "F" + id);
            // const alertAataAllOrdersfind5 = await getDoc(alertDocumentReft);

            // const q = query(ExpressOrdersRequest, where("int_lpn", "==", id));
            // const querySnapshot5 = await getDocs(q);
            // var dataOrdes = querySnapshot5.docs.map((doc) => ({
            //   id: doc.id,
            //   ...doc.data(),
            // }));

            // console.log(dataOrdes);
            // if (dataOrdes.length === 0) {
            //   console.log("No se encontro en express");
            // }

            /*
            if (alertAataAllOrdersfind5.exists()) {
              console.log(alertAataAllOrdersfind5.data());
              console.log(alertAataAllOrdersfind5.data());
              //arrayRequest.push(alertAataAllOrdersfind5.data());
            } else {
            
              // const q = query(
              //   ExpressOrdersRequest,
              //   where("int_lpn", "==", id)
              // );
              // const querySnapshot5 = await getDocs(q);
              // var dataOrdes = querySnapshot5.docs.map((doc) => ({
              //   id: doc.id,
              //   ...doc.data(),
              // }));

              // console.log(dataOrdes);

              // arrayRequest.push({ id: id, comment: "Geosort data" });
            }
            */
          }
        } catch (error) {
          //  console.log(error);
        }
      }
      console.log("especialRecuestData", arrayRequest);
      setBicciOrders(arrayRequest);
    };

    const especialFalabella = async (especialRequest) => {
      let cantOrderItems = especialRequest.length;
      let arrayRequestFalabella = [];
      for (let i = 0; i < cantOrderItems; i++) {
        try {
          let id = especialRequest[i].toString();
          const documentReft = doc(falabellaOrdersRequest, id);
          const dataallOrdersfind5 = await getDoc(documentReft);

          if (dataallOrdersfind5.exists()) {
            // Si el documento existe, añade sus datos al array

            arrayRequestFalabella.push(dataallOrdersfind5.data());
          } else {
            // Si el documento no existe, añade un objeto con solo el id y el comentario
            // arrayRequestFalabella.push({ id: id, comment: "Geosort data" });
            const documentReftExpress = doc(
              ExpressOrdersRequest,
              "F" + id.toString()
            );
            const dataallOrdersfind5Express = await getDoc(documentReftExpress);

            if (dataallOrdersfind5Express.exists()) {
              let newId = dataallOrdersfind5Express.data().int_lpn;

              const newDocumentReftBicci = doc(
                falabellaOrdersRequest,
                newId.toString()
              );
              const newDataDocByLPN = await getDoc(newDocumentReftBicci);
              if (newDataDocByLPN.exists()) {
                // console.log("encontrado :" + "F" + newId.toString());
                arrayRequestFalabella.push(newDataDocByLPN.data());
              } else {
                //                console.log("No se encontro en Bicci SOC : " + id + "LPN " + newId);
              }
            }
            //         console.log("Geosort o integracion externa:", id);
          }
        } catch (error) {}
      }
      //      console.log("arrayRequestFalabella", arrayRequestFalabella);
      setFalabellaOrders(arrayRequestFalabella);
      // setBicciOrders(arrayRequestFalabella);
      confirm.onFalse();
    };

    const arrayDeDatos = especialRequest.split(",").map((id) => id.trim()); // Asegura que cada ID es tratado como texto

    console.log(arrayDeDatos);
    await especialRecuestData(arrayDeDatos);
    await especialFalabella(arrayDeDatos);
  };

  const findHistorials = async () => {
    let cantGet = falabellaOrders.length;

    let dataDocsContainer = {};

    for (let i = 0; i < cantGet; i++) {
      let porcentajeDeAvance = (i / cantGet) * 100;

      setOrdersHistoryGetProgres(porcentajeDeAvance);

      try {
        const clearID = falabellaOrders[i].id; //.replace(/F/g, "");
        const falabellaOrderRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const statusRef = doc(falabellaOrderRef, clearID.toString());

        const statusDocs = collection(statusRef, "status");

        const dataallOrdersfind5 = await getDocs(statusDocs);
        const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        datadocuments.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );

        dataDocsContainer[falabellaOrders[i].id] = datadocuments;
      } catch (error) {}
    }
    setOrdersHistoryGetProgres(100);
    //  console.log(dataDocsContainer);
    setContainerHistory(dataDocsContainer);
  };

  const getResumenOps = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );
    var listRiders = [];
    var comunasDelivery = [];
    var ListStores = [];
    try {
      if (dataMerge && dataMerge.length > 0) {
        for (let n = 0; n < dataMerge.length; n++) {
          if (!listRiders.includes(dataMerge[n].driverID)) {
            listRiders.push(dataMerge[n].driverID);
          }
          if (!comunasDelivery.includes(dataMerge[n].commune2)) {
            comunasDelivery.push(dataMerge[n].commune2);
          }
        }

        const localSet = new Set();

        dataMerge.forEach((order) => {
          localSet.add(order.local_provider);
        });

        const ListStores = Array.from(localSet);

        async function getOrdersByLocal(data, ListStores) {
          setProgressValue(55);
          const orders = data.filter(
            (order) => order.falabella_status === "99" && order.status !== "99"
          );

          const listStoreOriging = {};
          orders.forEach((order) => {
            const local = order.local_provider;
            if (!listStoreOriging[local]) {
              setProgressValue(56);
              listStoreOriging[local] = {
                delivered: 0,
                withIssues: 0,
              };
              if (ListStores.includes(local)) {
                listStoreOriging[local].localName = local;
              }
            }
            if (order.status === "99") {
              setProgressValue(57);
              listStoreOriging[local].withIssues++;
            } else {
              listStoreOriging[local].delivered++;
            }
          });

          let arraylistRidersJsonString = Object.values(listStoreOriging);

          return arraylistRidersJsonString;
        }

        let listStoreOriging = await getOrdersByLocal(dataMerge, ListStores);

        var listRidersJsonString = {};

        var conuters = {};
        var conutersDelivery = {};
        var conutersIssures = {};

        var countersListOrders = {};

        var countersIssuresListOrders = {};

        var conterComunes = {};
        var vehicleDetailsID = {};
        var vehicleDetailsType = {};

        const riderMap = {};
        ridersDataByCompany.forEach((rider) => {
          riderMap[rider.id] = rider.vehicleID;
        });

        const vehicleNameMap = {};
        VehiclesNames.forEach((vehicle) => {
          vehicleNameMap[vehicle.id] = vehicle.name;
        });

        const vehicleRidersMap = {};
        Object.keys(riderMap).forEach((driverID) => {
          const vehicleID = riderMap[driverID];

          const rider = ridersDataByCompany.find(
            (rider) => rider.id === driverID
          );
          if (rider && vehicleNameMap[vehicleID]) {
            vehicleRidersMap[rider.id] = vehicleNameMap[vehicleID];
          }
        });

        if (listRiders.length > 0 && VehiclesNames) {
          for (let n = 0; n < dataMerge.length; n++) {
            for (let m = 0; m < listRiders.length; m++) {
              if (dataMerge[n].driverID === listRiders[m]) {
                if (listRidersJsonString[dataMerge[n].driverID]) {
                  conuters[dataMerge[n].driverID] = {
                    value:
                      listRidersJsonString[dataMerge[n].driverID].asignados + 1,
                  };

                  var countersListOrdersContent = [];

                  if (
                    listRidersJsonString[dataMerge[n].driverID]
                      .countersListOrders
                  ) {
                    countersListOrdersContent =
                      listRidersJsonString[dataMerge[n].driverID]
                        .countersListOrders.data;
                    countersListOrdersContent.push(dataMerge[n].id);

                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  } else {
                    countersListOrdersContent.push(dataMerge[n].id);
                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  }

                  var countersListIssuresOrdersContent = [];
                  if (dataMerge[n].falabella_status === "99") {
                    conutersDelivery[dataMerge[n].driverID] = {
                      value:
                        listRidersJsonString[dataMerge[n].driverID].delivery +
                        1,
                    };
                  } else {
                    conutersIssures[dataMerge[n].driverID] = {
                      value:
                        listRidersJsonString[dataMerge[n].driverID].issures + 1,
                    };

                    if (
                      listRidersJsonString[dataMerge[n].driverID]
                        .countersIssuresListOrders
                    ) {
                      ////console.log('EXISTE countersIssuresListOrders')
                      countersListIssuresOrdersContent =
                        listRidersJsonString[dataMerge[n].driverID]
                          .countersIssuresListOrders.data;
                      countersListIssuresOrdersContent.push(dataMerge[n].id);
                      countersIssuresListOrders[dataMerge[n].driverID] = {
                        data: countersListIssuresOrdersContent,
                      };
                    } else {
                      countersListIssuresOrdersContent.push(dataMerge[n].id);
                      countersIssuresListOrders[dataMerge[n].driverID] = {
                        data: countersListIssuresOrdersContent,
                      };
                    }
                  }
                } else {
                  conuters[dataMerge[n].driverID] = { value: 1 };

                  if (dataMerge[n].falabella_status === "99") {
                    conutersDelivery[dataMerge[n].driverID] = { value: 1 };
                    conutersIssures[dataMerge[n].driverID] = { value: 0 };
                    countersListOrdersContent = [];
                    countersListOrdersContent.push(dataMerge[n].id);
                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  } else {
                    conutersDelivery[dataMerge[n].driverID] = { value: 0 };
                    conutersIssures[dataMerge[n].driverID] = { value: 1 };

                    countersListOrdersContent = [];
                    countersListOrdersContent.push(dataMerge[n].id);
                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };

                    countersListIssuresOrdersContent = [];
                    countersListIssuresOrdersContent.push(dataMerge[n].id);
                    countersIssuresListOrders[dataMerge[n].driverID] = {
                      data: countersListIssuresOrdersContent,
                    };
                  }
                }

                if (listRidersJsonString[dataMerge[n].driverID]) {
                  for (let x = 0; x < comunasDelivery.length; x++) {
                    if (comunasDelivery[x] === dataMerge[n].commune2) {
                      if (
                        listRidersJsonString[dataMerge[n].driverID].locality
                          .length > 0
                      ) {
                        let arrayComunes =
                          listRidersJsonString[dataMerge[n].driverID].locality;
                        let comunaContenidas = [];
                        for (let a = 0; a < arrayComunes.length; a++) {
                          let nombreComuna = Object.keys(arrayComunes[a]);
                          if (!comunaContenidas.includes(nombreComuna[0])) {
                            comunaContenidas.push(nombreComuna[0]);
                          }
                        }
                        if (!comunaContenidas.includes(dataMerge[n].commune2)) {
                          arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                        } else {
                          arrayComunes[
                            comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                          ][dataMerge[n].commune2] =
                            arrayComunes[
                              comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                            ][dataMerge[n].commune2] + 1;
                        }
                        conterComunes[dataMerge[n].driverID] = arrayComunes;
                      } else {
                        let arrayComunes = [];
                        arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                        conterComunes[dataMerge[n].driverID] = arrayComunes;
                      }
                    }
                  }
                } else {
                  for (let x = 0; x < comunasDelivery.length; x++) {
                    if (comunasDelivery[x] === dataMerge[n].commune2) {
                      let arrayComunes = [];
                      arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                      conterComunes[dataMerge[n].driverID] = arrayComunes;
                    }
                  }
                }

                vehicleDetailsID[dataMerge[n].driverID] = {
                  value: vehicleRidersMap[dataMerge[n].driverID]
                    ? vehicleRidersMap[dataMerge[n].driverID]
                    : "No registrado",
                };
                vehicleDetailsType[dataMerge[n].driverID] = {
                  value: vehicleRidersMap[dataMerge[n].driverID]
                    ? vehicleRidersMap[dataMerge[n].driverID]
                    : "No registrado",
                };
                /*
                for (let q = 0; q < VehiclesDataByCompany.length; q++) {
                  if (
                    dataMerge[n].driverID ===
                    VehiclesDataByCompany[q].driverAssing
                  ) {
                    vehicleDetailsID[dataMerge[n].driverID] = {
                      value: VehiclesDataByCompany[q].id,
                    };
                    vehicleDetailsType[dataMerge[n].driverID] = {
                      value: VehiclesDataByCompany[q].vehiclesTypes,
                    };
                  } else {
                    if (!vehicleDetailsID[dataMerge[n].driverID]) {
                      vehicleDetailsID[dataMerge[n].driverID] = {
                        value: "No resgistrado",
                      };
                      vehicleDetailsType[dataMerge[n].driverID] = {
                        value: "No resgistrado",
                      };
                    }
                  }
                }
*/
                listRidersJsonString[dataMerge[n].driverID] = {
                  driverName: dataMerge[n].driverName,
                  driverID: dataMerge[n].driverID,
                  email: dataMerge[n].email,
                  driverAvatar: dataMerge[n].driverAvatar,
                  asignados: conuters[dataMerge[n].driverID].value,
                  //asignadosListId: conutersListId[dataMerge[n].driverID], // Nueva Variable
                  delivery: conutersDelivery[dataMerge[n].driverID].value,
                  issures: conutersIssures[dataMerge[n].driverID].value,
                  vehicleDetailsType:
                    vehicleDetailsType[dataMerge[n].driverID].value,
                  vehicleDetailsID:
                    vehicleDetailsID[dataMerge[n].driverID].value,
                  locality: conterComunes[dataMerge[n].driverID],
                  countersListOrders: countersListOrders[dataMerge[n].driverID],
                  countersIssuresListOrders:
                    countersIssuresListOrders[dataMerge[n].driverID],
                };
              }
            }
          }
          let arraylistRidersJsonString = Object.values(listRidersJsonString);

          setMetricsOps(arraylistRidersJsonString);
          setMetricsOpsStores(listStoreOriging);
          setProgressValue(100);
          setMenssajeAdmin(null);
        }
      } else {
        setProgressValue(100);
        setMetricsOps([]);
        setMetricsOpsStores([]);
        setMenssajeAdmin(
          <span className="alert alert-danger">
            "NO se encuentran envíos para la fecha Solicitada"
          </span>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChageRange = (e) => {
    setProgressValue(20);
    if (e.target.name === "rangoInicio") {
      setInitDate(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDate(e.target.value);
    }
    if (e.target.name === "staticDateBillingFilter") {
      setInitDate(e.target.value);
      setEndDate(e.target.value);
      setStaticDateBillingFilter(e.target.value);
    }
  };

  const setResumenTable = (dataOrdes) => {
    if (dataOrdes && dataOrdes.length === 0) {
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
    } else {
      setMenssajeAdmin(null);
    }

    let Creadas = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1058" || item.falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.falabella_status === "99"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1060" || item.falabella_status === "1061"
    );
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.falabella_status)
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const handleSelecRiders = () => {};

  const changerealTimeOption = (e) => {
    setRealTimeisActive(e.target.checked);
  };

  const generarReporteTurno = () => {
    generarReporteTurnoCierrediario(dataMerge, ridersDataByCompany, initDate);
  };

  // const exporExcelFile = () => {};

  const handleOrdersForAccions = (e) => {
    var fIF = "F" + e.currentTarget.id;
    if (e.currentTarget.checked) {
      ordersForAccions.push(fIF);
      const order = dataMerge.filter(
        (item) => item.id.toString() === e.currentTarget.id
      );

      dataOrdersForAccions.push(order[0]);
    }

    if (!e.currentTarget.checked) {
      let newOrders = ordersForAccions.filter(
        (item) => item !== "F" + e.currentTarget.id
      ); //push(e.target.id)

      ordersForAccions = newOrders;

      let removeForData = dataOrdersForAccions.filter(
        (item) => item.id.toString() !== e.currentTarget.id
      );
      dataOrdersForAccions = removeForData;
    }

    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  async function selecListItames(listIdstoSelct) {
    listadeItemsNoMach = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    listIdstoSelct = listIdstoSelct.trim();
    var listadeItems2 = listIdstoSelct.split(/[,\s]+/);

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems2.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems2[i] === "F" + tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems2.length; index++) {
      if (!ordersForAccions.includes(listadeItems2[index])) {
        listadeItemsNoMach.push(listadeItems2[index]);
      }
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  }

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
  };

  const generarReporteEvidencias = (dataOrdersForAccions) => {
    const matrizReporte = [
      [
        "SOC",
        "Fecha de envío",
        "Zonas",
        "Ultimo Registro",
        "Estatus",
        "Detalle",
        "Conductor",
        "Receptor Rut",
        "Receptor Nombre",
        "Fotos",
        "Fotos",
        "Fotos",
        "Fotos",
      ],
      ...dataOrdersForAccions.map((dato) => [
        dato.soc,
        dato.dateCreateText,
        dato.commune2,
        format(dato.updatedAt.seconds * 1000, "dd/MM/yy hh:mm:ss"),
        dato.falabella_status,
        translateStateBicciFalabella[dato.falabella_status],
        dato.driverName,
        dato.userDniReceptor,
        dato.userNameReceptor,
        dato.pics[0],
        dato.pics[1],
        dato.pics[2],
        dato.pics[3],
        dato.pics[4],
        dato.pics[5],
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `Evidencias entregas ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push("F" + tempuserOrders[n].id);
        }
      }
    }

    let copyData = copyOrdersForAccions;
    navigator.clipboard.writeText(copyData);
    setRender(!render);
  };

  const exportartReportePagos = (userOrders) => {
    console.log(userOrders);
    generarReporteProformas(userOrders);
  };

  const handleCopySelectRiderCurrentListErrors = (e, item) => {
    e.preventDefault();
    navigator.clipboard.writeText(item);
  };

  const handleChangeOE = () => {
    setCheckedChangeOE(!CheckedChangeOE);
  };

  const handleChangetypeRequets = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "typeRequetsCreate" && checked) {
      setTypeRequets({ typeRequetsCreate: true, typeRequetsUpdate: false });
    } else {
      setTypeRequets({ typeRequetsCreate: false, typeRequetsUpdate: true });
    }
  };

  const generarReporte = (data) => {
    const matrizReporte = [
      [
        "Origen",
        "Comuna",
        "Alto",
        "Ancho",
        "Largo",
        "Peso",
        "Tamaño",
        "Rider",
        "Status",
        "Creado",
        "Actulizado",
      ],
      ...data.map((dato) => [
        dato.placeName ? dato.placeName : dato.int_warehouseName,
        dato.commune2,
        dato.length,
        dato.height,
        dato.weight,
        dato.width,
        dato.size,
        dato.driverName,
        dato.falabella_status
          ? dato.falabella_status
          : dato.int_falabella_status,
        dato.dateCreateText,
        dato.upDatedAtText,
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

    XLSX.writeFile(libro, "reportePagoRiders.xlsx");
  };

  const generarReporteEstados = (dataOrdersForAccions) => {
    const matrizReporte = [
      ["Suborden", "Creado", "Estatus", "Descripción", "Última Actualización"],
      ...dataOrdersForAccions.map((dato) => [
        dato.soc,
        dato.dateCreateText,
        dato.falabella_status,
        translateStateBicciFalabella[dato.falabella_status],
        dato.upDatedAtText,
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `ESTADOS ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    // setProgressValue(20);
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  //console.log(dataMerge);

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            {movilActive === true ? (
              <>
                <CardHeader
                  title="Ordenes Falabella"
                  subheader={
                    <ToolDashAppBar
                      movil={widthHeight.width < 950 ? true : false}
                      setSelected={setSelected}
                      setOrigenGeoCode={setOrigenGeoCode}
                      setAdressOrigin={setAdressOrigin}
                      adressOrigin={adressOrigin}
                      setSelecteDestiny={setSelecteDestiny}
                      setDestinyGeoCode={setDestinyGeoCode}
                      setAdressDestiny={setAdressDestiny}
                      adressDestiny={adressDestiny}
                      Buttondisable={Buttondisable}
                      isShowPopUp={isShowPopUp}
                      setButtondisable={setButtondisable}
                      CheckedChangeOE={CheckedChangeOE}
                      handleChangeOE={handleChangeOE}
                      handleChangetypeRequets={handleChangetypeRequets}
                      typeRequets={typeRequets}
                      dataMerge={dataMerge}
                      dataOrdersForAccions={dataOrdersForAccions}
                      generarReporte={generarReporte}
                      generarReporteEstados={generarReporteEstados}
                      generarReporteEvidencias={generarReporteEvidencias}
                      setWiewPrePdf={setWiewPrePdf}
                      findHistorials={findHistorials}
                      wiewPrePdf={wiewPrePdf}
                      initDate={initDate}
                      endDate={endDate}
                      onChageRange={onChageRange}
                      staticDateBillingFilter={staticDateBillingFilter}
                      setListIdstoSelct={setListIdstoSelct}
                      listadeItemsNoMach={listadeItemsNoMach}
                      companyId={companyId}
                      realTimeisActive={realTimeisActive}
                      setRealTimeisActive={setRealTimeisActive}
                      listItems={listItems}
                      cantListItems={cantListItems}
                      handleCopySelectRiderCurrentListErrors={
                        handleCopySelectRiderCurrentListErrors
                      }
                      ToolDashAppBar={ToolDashAppBar}
                      exportartReportePagos={exportartReportePagos}
                      generarReporteTurno={generarReporteTurno}
                    ></ToolDashAppBar>
                    // <div className="Routes-container">
                    //   <div className="Routes-wrapper">
                    //     <label className="switch">
                    //       <input
                    //         type="checkbox"
                    //         id="realTimeisActive"
                    //         name="realTimeisActive"
                    //         checked={realTimeisActive}
                    //         onChange={changerealTimeOption}
                    //       />
                    //       <span className="slider round"></span>
                    //     </label>
                    //     <span className="realtime-label">
                    //       Actualizar en tiempo real:
                    //     </span>
                    //     {realTimeisActive ? (
                    //       <span className="realtime-info">{initDate}</span>
                    //     ) : (
                    //       <div className="realtime-pause">
                    //         <span className="alert alert-info">En Pausa</span>
                    //       </div>
                    //     )}
                    //   </div>
                    //   <div className="searcher-wrapper">
                    //     <SwipeableSearcherDrawer companyId={companyId} />
                    //   </div>
                    // </div>
                  }
                  sx={{
                    padding: "16px",
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "flex-start",
                    "& .Routes-wrapper": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                    "& .realtime-label": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-info": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-pause": {
                      marginTop: "0.5rem",
                      textAlign: "center",
                    },
                    "& .searcher-wrapper": {
                      marginTop: "0.5rem",
                    },
                    "@media (min-width: 950px)": {
                      padding: "16px 24px",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      "& .Routes-wrapper": {
                        flexDirection: "row",
                        alignItems: "center",
                      },
                      "& .realtime-label": {
                        marginTop: 0,
                        marginRight: "1rem",
                      },
                      "& .realtime-pause": {
                        marginTop: 0,
                      },
                      "& .searcher-wrapper": {
                        marginTop: 0,
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                {" "}
                <CardHeader
                  title="Ordenes Falabella"
                  subheader={
                    <div>
                      <ToolDashAppBar
                        movil={widthHeight.width < 950 ? true : false}
                        setSelected={setSelected}
                        setOrigenGeoCode={setOrigenGeoCode}
                        setAdressOrigin={setAdressOrigin}
                        adressOrigin={adressOrigin}
                        setSelecteDestiny={setSelecteDestiny}
                        setDestinyGeoCode={setDestinyGeoCode}
                        setAdressDestiny={setAdressDestiny}
                        adressDestiny={adressDestiny}
                        Buttondisable={Buttondisable}
                        isShowPopUp={isShowPopUp}
                        setButtondisable={setButtondisable}
                        CheckedChangeOE={CheckedChangeOE}
                        handleChangeOE={handleChangeOE}
                        handleChangetypeRequets={handleChangetypeRequets}
                        typeRequets={typeRequets}
                        dataMerge={dataMerge}
                        dataOrdersForAccions={dataOrdersForAccions}
                        generarReporte={generarReporte}
                        generarReporteEstados={generarReporteEstados}
                        generarReporteEvidencias={generarReporteEvidencias}
                        setWiewPrePdf={setWiewPrePdf}
                        findHistorials={findHistorials}
                        wiewPrePdf={wiewPrePdf}
                        initDate={initDate}
                        endDate={endDate}
                        onChageRangePiker={onChageRangePiker}
                        onChageRange={onChageRange}
                        staticDateBillingFilter={staticDateBillingFilter}
                        setListIdstoSelct={setListIdstoSelct}
                        listadeItemsNoMach={listadeItemsNoMach}
                        companyId={companyId}
                        realTimeisActive={realTimeisActive}
                        setRealTimeisActive={setRealTimeisActive}
                        listItems={listItems}
                        cantListItems={cantListItems}
                        handleCopySelectRiderCurrentListErrors={
                          handleCopySelectRiderCurrentListErrors
                        }
                        ToolDashAppBar={ToolDashAppBar}
                        exportartReportePagos={exportartReportePagos}
                        generarReporteTurno={generarReporteTurno}
                      ></ToolDashAppBar>
                      <div className="Routes-container"></div>
                    </div>
                  }
                />
              </>
            )}

            <Divider />

            <Box className="formcontent" id="micuentafromIni">
              {progressValue !== 100 ? (
                <LinearProgress
                  value={progressValue}
                  valueBuffer={100}
                  variant="buffer"
                ></LinearProgress>
              ) : null}
              {/* <div className="DashIconsResumenDispachs-container">
                <Suspense fallback={<div>Cargando...</div>}>
                  <DashIconsResumenDispachs
                    items={listItems}
                    cant={cantListItems}
                  ></DashIconsResumenDispachs>
                </Suspense>
              </div> */}
              <div className="m-2">
                <Suspense fallback={<div>Cargando...</div>}>
                  {menssajeAdmin ? (
                    <>{menssajeAdmin}</>
                  ) : (
                    <>
                      {progressValue !== 100 ? (
                        <LinearProgress></LinearProgress>
                      ) : null}
                    </>
                  )}
                </Suspense>
              </div>
              {/* <div className="frontMetricBar">
                <div className="typeMiniformsBarsMetricsToolBar">
                  <div className="typeMiniformsBarsMetricsTool">
                    <div className="m-2">
                      <h3>Ocultar incidencias activas</h3>
                      <label className="switch">
                        <input
                          type="checkbox"
                          defaultChecked={false}
                          onChange={handleChangeOE}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {typeRequets ? (
                    <>
                      {" "}
                      <div className="typeMiniformsBarsMetricsTool">
                        <div className="m-2">
                          <h3>Por F. Creacion</h3>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={typeRequets.typeRequetsCreate}
                              name={"typeRequetsCreate"}
                              onChange={handleChangetypeRequets}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>

                        <div className="m-2">
                          <h3>Por F. Ultima Act</h3>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={typeRequets.typeRequetsUpdate}
                              name={"typeRequetsUpdate"}
                              onChange={handleChangetypeRequets}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <ToolDashSeachByDaRang
                    initDate={initDate}
                    endDate={endDate}
                    onChageRange={onChageRange}
                  ></ToolDashSeachByDaRang>
                  <ToolDashSeachByDay
                    staticDateBillingFilter={staticDateBillingFilter}
                    onChageRange={onChageRange}
                  ></ToolDashSeachByDay>

                  <ToolDashSeachByIds
                    setListIdstoSelct={setListIdstoSelct}
                    listadeItemsNoMach={listadeItemsNoMach}
                    ordersForAccions={ordersForAccions}
                    dataOrdersForAccions={dataOrdersForAccions}
                    handleCopySelectRiderCurrentListErrors={
                      handleCopySelectRiderCurrentListErrors
                    }
                  ></ToolDashSeachByIds>
                </div>
                <div>
                  <div>
                    <Suspense fallback={<div>Cargando...</div>}>
                      <ToolDashResumenOps
                        movil={widthHeight.width < 950 ? true : false}
                        metricsOps={metricsOps}
                        handleClickSelectRiderCurrentList={
                          handleClickSelectRiderCurrentList
                        }
                        handleCopySelectRiderCurrentList={
                          handleCopySelectRiderCurrentList
                        }
                        rosettaVehicles={rosettaVehicles}
                      ></ToolDashResumenOps>
                    </Suspense>
                  </div>
                </div>
              </div>{" "}
              <div className="button-container">
                {dataMerge.length > 0 ? (
                  <>
                    <button
                      onClick={() => generarReporte(dataMerge)}
                      className="btn btn-ebiex m-2 btn-reporte-excel"
                    >
                      {" "}
                      Reporte Pagos Riders
                    </button>
                  </>
                ) : null}
                {dataMerge.length > 0 ? (
                  <>
                    <button
                      onClick={() =>
                        generarReporteEstados(dataOrdersForAccions)
                      }
                      className="btn btn-ebiex m-2 btn-reporte-excel"
                    >
                      {" "}
                      Reporte Estados
                    </button>
                  </>
                ) : null}
              
                {dataMerge.length > 0 ? (
                  <>
                
                    <button
                      onClick={() =>
                        generarReporteEvidencias(dataOrdersForAccions)
                      }
                      className="btn btn-ebiex m-2 btn-reporte-excel"
                    >
                      {" "}
                      Reporte de Evidencias
                    </button>
                  </>
                ) : null}
                {dataMerge.length > 0 ? (
                  <>
                    <div className="m-2">
                      <button
                        onClick={() => findHistorials()}
                        className="btn btn-ebiex m-2 btn-reporte-excel"
                      >
                        {" "}
                        Obtener Historiales
                      </button>
                    </div>
                  </>
                ) : null}
                
                {ordersHistoryGetProgres && ordersHistoryGetProgres !== 100 ? (
                  <span className="progressValuesNum">
                    {ordersHistoryGetProgres}%
                  </span>
                ) : null}
              </div> */}
              {dataMerge.length > 0 ? (
                <>
                  <div className="m-2">
                    <button
                      onClick={() => setViewEvidences(!viewEvidences)}
                      className="btn btn-ebiex m-2 btn-reporte-excel"
                    >
                      {" "}
                      Evidencias{" "}
                      {viewEvidences ? (
                        <VisibilityIcon></VisibilityIcon>
                      ) : (
                        <VisibilityOffIcon></VisibilityOffIcon>
                      )}
                    </button>

                    <button
                      onClick={confirm.onTrue}
                      className="btn btn-ebiex m-2 btn-reporte-excel"
                    >
                      {" "}
                      Busqueda especial{" "}
                    </button>
                  </div>
                  <div>
                    {ordersHistoryGetProgres <= 0 ||
                    ordersHistoryGetProgres === 100 ? null : (
                      <div>
                        {" "}
                        Cargando{" "}
                        <span className="progressTextPorcentBig ">
                          {ordersHistoryGetProgres.toFixed(2)} %
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ) : null}
              <div></div>
              <Grid.Row>
                <Grid.Column
                  computer={8}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun "
                >
                  <div>
                    {" "}
                    {wiewPrePdf && metricsOps && metricsOpsStores ? (
                      <PDFViewer style={{ width: "100%", height: "90vh" }}>
                        <CierreOpsPdf
                          items={listItems}
                          cant={cantListItems}
                          metricsOps={metricsOps}
                          metricsOpsStores={metricsOpsStores}
                          rosettaVehicles={rosettaVehicles}
                          userUid={PropaneSharp.usuarioStorage.uid}
                          initDate={initDate}
                          endDate={endDate}
                        ></CierreOpsPdf>
                      </PDFViewer>
                    ) : null}
                  </div>
                  <div>
                    {viewEvidences ? (
                      <div>
                        <Suspense fallback={<div>Cargando...</div>}>
                          <ToolDashOrdersContainer
                            movil={widthHeight.width < 950 ? true : false}
                            dataMerge={dataMerge}
                            handleOrdersForAccions={handleOrdersForAccions}
                            styleStateBicciFalabella={styleStateBicciFalabella}
                            translateStateBicciFalabella={
                              translateStateBicciFalabella
                            }
                            containerHistory={containerHistory}
                          ></ToolDashOrdersContainer>
                        </Suspense>
                      </div>
                    ) : (
                      <div>
                        <ToolDashOrdersContainerPics
                          movil={widthHeight.width < 950 ? true : false}
                          dataMerge={dataMerge}
                          handleOrdersForAccions={handleOrdersForAccions}
                          styleStateBicciFalabella={styleStateBicciFalabella}
                          translateStateBicciFalabella={
                            translateStateBicciFalabella
                          }
                          containerHistory={containerHistory}
                        ></ToolDashOrdersContainerPics>
                      </div>
                    )}
                  </div>
                  {/*  */}
                </Grid.Column>

                <Grid.Column
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun"
                >
                  <ConfirmDialog
                    open={confirm.value}
                    onClose={confirm.onFalse}
                    title="Buscar grupos determinados."
                    content={
                      <>
                        ingresa los IDS a <strong>Buscar</strong> Separados por
                        comas
                        <TextField
                          multiline
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="multiline-input"
                          label="Multiline Input"
                          name="multilineInput"
                          onChange={(e) => setSpecialRequest(e.target.value)}
                          autoFocus
                          rows={4}
                          placeholder="149141096575, 149141095462, ...."
                        />
                      </>
                    }
                    action={
                      <Button
                        variant="contained"
                        onClick={() => {
                          callFindEspecialRequestData();
                        }}
                      >
                        Buscar
                      </Button>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Box>

            <Divider />
          </Card>

          <div></div>
          {showPopUp ? (
            <ModalAccionsDispacth
              opened={showPopUp}
              popUpAccion={popUpAccion}
              interfaceOrigen={"liveOrders"}
              ordersForAccions={ordersForAccions}
              dataOrdersForAccions={dataOrdersForAccions}
              companyId={companyId}
              ridersDataByCompany={ridersDataByCompany}
              adressOrigin={adressOrigin}
              origenGeoCode={origenGeoCode}
              adressDestiny={adressDestiny}
              destinyGeoCode={destinyGeoCode}
            >
              {" "}
            </ModalAccionsDispacth>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  function Search({ setSelected, setAdressOrigin, setOrigenGeoCode }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressOrigin(address);

        const { lat, lng } = await getLatLng(results[0]);

        setOrigenGeoCode({ lat: lat, lng: lng });

        setButtondisable(false);
      } catch (error) {
        console.log("😱 Error: ", error);
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Buscar Origen..."
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  function SearchDestiny({
    setSelecteDestiny,
    setAdressDestiny,
    setDestinyGeoCode,
    adressDestiny,
  }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressDestiny(address);

        const { lat, lng } = await getLatLng(results[0]);

        setDestinyGeoCode({ lat: lat, lng: lng });
      } catch (error) {}
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={"Buscar destino.."}
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
};

export default GuiasByFalabellaMonitor;
