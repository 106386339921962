import React, { useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const ReusableFormImportOrdersPregrillaGeosort = ({
  open,
  handleClose,
  handleAddItem,
  setOpen,
  companyData,
  prefix,
  dataOrigin,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [openDropzone, setOpenDropzone] = useState(false);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
  };

  const formFieldsEdit = [
    { variable: "Suborden", label: "Suborden", type: "string", require: true },
    { variable: "Do", label: "Do", type: "string", require: true },
    { variable: "Producto", label: "Producto", type: "string", require: true },
    { variable: "Bu", label: "Bu", type: "string", require: true },
    { variable: "Cantidad", label: "Cantidad", type: "string", require: true },
    { variable: "Sku", label: "Sku", type: "string", require: true },
    {
      variable: "Localidad",
      label: "Localidad",
      type: "string",
      require: true,
    },
    { variable: "Posruta", label: "Posruta", type: "string", require: true },
    {
      variable: "Nombrecliente",
      label: "Nombrecliente",
      type: "string",
      require: true,
    },
    {
      variable: "Rutcliente",
      label: "Rutcliente",
      type: "string",
      require: true,
    },
    { variable: "Idruta", label: "Idruta", type: "string", require: false },
    { variable: "Lpn", label: "Lpn", type: "string", require: false },
  ];

  const handleSaveDropzone = (files) => {
    setErroMensaje(null);
    setFile(files[0]);
    setOpenDropzone(false);
  };
  const handleOpenDropzone = () => {
    setErroMensaje(null);
    setOpenDropzone(true);
  };

  const handleSave = async (formData) => {
    const upsertDocument = async (docData) => {
      let orderkey = prefix + docData.Suborden;

      let order = {
        dataOrigin: "GSDATA",
        key: orderkey,
        id: docData.Suborden.toString(),
        clientRut: docData.Rutcliente,
        routeId: docData.Idruta,
        routePosition: docData.Posruta,
        int_lpn: docData.Lpn,
        userPhone: docData.Telefono ? docData.Telefono : "",
        driverLicensePlate: docData.Patente,
      };
      //console.log("ACTUALIZACION ORDENES GEOSORT METODO :", orderkey);
      const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
      const docRef = doc(collRef, orderkey);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, order);
        //console.log("Documento actualizado con éxito como GDATA.", order);
      } else {
        await setDoc(docRef, docData);
        //console.log("Documento creado desde este documento con éxito.", order);
      }
    };

    if (Array.isArray(formData)) {
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          if (field.require === true) {
            if (
              field.variable !== "id" &&
              (!item.hasOwnProperty(field.label) || item[field.label] === "")
            ) {
              setErroMensaje(
                `Falta un valor para la columna ${field.label} o está vacío en uno de los registros.`
              );
              return false;
            }
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      handleSave(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  const handleSaveData = async (formData) => {
    const upsertDocument = async (docData) => {
      let orderkey = prefix + docData.Suborden;

      let order = {
        dataOrigin: "GSDATA",
        key: orderkey,
        id: docData.Suborden.toString(),
        clientRut: docData.Rutcliente,
        routeId: docData.Idruta,
        routePosition: docData.Posruta,
        int_lpn: docData.Lpn,
        userPhone: docData.Telefono ? docData.Telefono : "",
        driverLicensePlate: docData.Patente,
      };

      const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
      const docRef = doc(collRef, orderkey);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, order);
      } else {
        await setDoc(docRef, docData);
      }
    };

    if (Array.isArray(formData)) {
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Actualizar estados Con documento pregrilla{" "}
      </DialogTitle>
      <DialogContent>
        <Button onClick={handleOpenDropzone} color="primary">
          Subir archivo SDD
        </Button>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>File ready to be saved: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrdersPregrillaGeosort;
