import React, { useState } from "react";
import { Card, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { db } from "../../../../firebase";
import { doc, updateDoc, collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { updateOrderBicciExpressByPregrillaGeosort } from "../../../../redux/accionesOrders";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// Carga de ordernes en sistema para regitros operativos y financieros, las ordenes no son gestionadas desde la aplicacion bicci solo son subidas a tablas de Bicciexpress
// - El Objetivo es crear registos, con dos objetivos registrar envios con app externas para evaluaciones, realizar cuadratura de pagos recibidos y pagos realizados a los conductores.
const ToolDashUpdateDocPreGrilla = (props) => {
  const dispacth = useDispatch();
  const [toolsData, setToolsData] = useState(null);
  const [type, setType] = React.useState("upDate");

  const handleFilePregrilla = async (e) => {
    console.log("Aqui PREGRILLA");

    const files = e.target.files;
    if (files.length > 0) {
      props.setAlertMensajes(null);
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
    }

    const file2 = e.target.files[0];
    const data2 = await file2.arrayBuffer();
    const woskbook = XLSX.read(data2);

    const worksheet = woskbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      const orderID = jsonData[0]["Suborden"];
      const clientRut = jsonData[0]["Rutcliente"];
      const routeId = jsonData[0]["Idruta"];
      const routePosition = jsonData[0]["Posruta"];
      const int_lpn = jsonData[0]["Lpn"];
      const driverLicensePlate = jsonData[0]["Patente"];

      if (
        orderID &&
        clientRut &&
        routeId &&
        routePosition &&
        int_lpn &&
        driverLicensePlate
      ) {
        console.log(jsonData);
        setToolsData(jsonData);
        props.setAlertMensajes(
          <div>
            <div className="alert alert-info m-2">
              Si es un archivo de estimacion puede continuar, pero la ausencuas
              de datos puede afectar la creacion de rutas
            </div>
            <div>{routeId ? "" : "Faltann datos ID RUTA "}</div>
            <div>{driverLicensePlate ? "" : "Faltann datos en VEHICULO "}</div>
          </div>
        );
        props.setDocumentData(jsonData);
        props.setDataType("bicciSdd");
        props.setRender(!props.remder);
      } else {
        props.setAlertMensajes(
          <div>
            <div>
              {` Nonmbres clave requeridos revise el documento y ajustelo para
              procesarlo. campos [Suborden, Rutcliente, Idruta, Parada, Lpn,
              Patente ] deberian estar presentes`}
            </div>
          </div>
        );
      }
    } catch (error) {
      props.setAlertMensajes(<div>No se encuentra la hoja DATA</div>);
    }
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function cancel() {
    setToolsData(null);
    props.setRender(!props.remder);
  }
  async function pause() {
    setToolsData([]);
    props.setRender(!props.remder);
  }
  async function loadOrdesDataSdd(toolsData) {
    if (toolsData) {
      console.log(toolsData);
      dispacth(
        updateOrderBicciExpressByPregrillaGeosort(
          toolsData,
          props.usuarioStorage,
          type
        )
      );
    }
  }

  return (
    <>
      <Card
        className="typeMiniformsBarsAccionsItemsMinimal"
        sx={{ borderRadius: "3px" }}
      >
        <div className="m-2">
          <div className="labelupload">
            <label htmlFor="file-upload1">
              Complemento Documento Planeación Pre grilla - Pasa Ordenes a
              Formato Geosort
            </label>
            <input
              id="file-upload-pre"
              name="file-upload-pre"
              type="file"
              onChange={(e) => handleFilePregrilla(e)}
            />

            <label htmlFor="file-upload-pre" className="choose-file">
              <UploadFileIcon></UploadFileIcon>
            </label>

            <div id="file-upload-message">Ningún archivo seleccionado.</div>
          </div>

          <div>
            {toolsData ? (
              <>
                {/* <FormControl
                fullWidth
                sx={{
                  padding: "1px",
                  margin: "1px",
                }}
              >
                <InputLabel id="demo-simple-select-label">Metodo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Age"
                  onChange={handleChange}
                  sx={{
                    padding: "1px",
                  }}
                >
                  <MenuItem value={"upDate"}>Actualizar</MenuItem>
                  <MenuItem value={"create"}>Crear</MenuItem>
                </Select>
              </FormControl> */}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => loadOrdesDataSdd(toolsData)}
                  sx={{ color: "#591e8f" }}
                >
                  <PlayCircleIcon
                    className="btn-add-guide-small"
                    sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                  />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => pause()}
                  sx={{ color: "#591e8f" }}
                >
                  <PauseIcon
                    className="btn-add-guide-small"
                    sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                  />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => cancel()}
                  sx={{ color: "#591e8f" }}
                >
                  <CancelIcon
                    className="btn-add-guide-small-cancel "
                    sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                  />
                </IconButton>
              </>
            ) : null}
          </div>
        </div>
      </Card>
      {/* 
      <Button
        variant="outline"
        startIcon={<FileUploadIcon />}
        onClick={() => UploadDocumentsByData(true)}
        sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
      >
        Importar documento
      </Button>

      <ReusableFormImportOrders
        open={openImport}
        setOpen={setOpenImport}
        handleClose={() => setOpenImport(false)}
        companyData={props.companyData}
      />
       */}
    </>
  );
};

export default ToolDashUpdateDocPreGrilla;
