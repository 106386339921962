import React from "react";
import { Card } from "@mui/material";
const ToolDashOrdersFilterComunes = (props) => {
  return (
    <Card className="typeMiniformsBars">
      <div className="m-2">
        <div> Seleccione una sucursal </div>
        {props.optionOrigenFilter ? (
          <select
            id="custom-selectFrom-comuna"
            control="select"
            name="currentOrigenFilter"
            onChange={(e) => props.onChageSubFilter(e)}
          >
            <option value={"itemClear"}> Seleccione una Sucursal... </option>
            {props.optionOrigenFilter.map((item, index) => (
              <option key={item + index} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : (
          "No sucursales en su empresa"
        )}

        <div> Seleccione una Comuna </div>
        {props.optionLocalityFilter ? (
          <select
            id="custom-selectFrom-comuna"
            control="select"
            name="currentLocalityFilter"
            onChange={(e) => props.onChageSubFilter(e)}
          >
            <option value={"itemClear"}> Seleccione una Sucursal... </option>
            {props.optionLocalityFilter.map((item, index) => (
              <option key={item + index} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : (
          "No sucursales en su empresa"
        )}
      </div>
    </Card>
  );
};

export default ToolDashOrdersFilterComunes;
