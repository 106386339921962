import React, { useState, useContext, useMemo, useRef } from "react";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { Button, Container } from "semantic-ui-react";
import { Card, Divider, CardHeader, LinearProgress } from "@mui/material";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import Moment from "moment";
import useGlobalConfig from "../../../hooks/globalConfig";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import ToolDashAppBarBilling from "../../../components/Dashboard/dashboard/ToolDashAppBarBilling/ToolDashAppBarBilling";
import ToolDashOrdersContainerDocuments from "../../../components/Dashboard/dashboard/ToolDashOrdersContainerDocuments/ToolDashOrdersContainerDocuments";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import { updateOrdersBicciexpressEstatus } from "../../../redux/accionesOrders";
import { useDispatch } from "react-redux";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import generarArchivoCargaSimpliRoute from "../../../utils/Reports/generarArchivoCargaSimplirouteApp";
import ToolDashOrdersContainerBilling from "../../../components/Dashboard/dashboard/ToolDashOrdersContainerBilling/ToolDashOrdersContainerBilling";
import UserListViewOrders from "../../../components/UserListViewOrders/UserListViewOrders";
import generarReportePicking from "../../../utils/Reports/generarReportePicking";

var listadeItemsNoMach = [];

const GuiasByCompanyBilling = (props) => {
  const dispacth = useDispatch();
  const { translateStateBicciFalabella, styleStateBicciFalabella } =
    useGlobalConfig();

  const { widthHeight, movilActive } = useContext(ScreenSizeContext);

  const companyId = useMemo(() => {
    if (props.usuarioStorage) {
      return props.usuarioStorage.company;
    }
    return "";
  }, [props.usuarioStorage]);

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  const [toolsData, setToolsData] = React.useState(null);

  const [dataType, setDataType] = React.useState("request");

  const [documentData, setDocumentData] = React.useState(null);

  const [progress, setProgres] = React.useState(0);

  const [CheckedChangeOE, setCheckedChangeOE] = React.useState(false);

  const [render, setRender] = React.useState(true);

  const [dataOrdersFilter, setDataOrdersFilter] = React.useState(null);

  const [billingOrdersForAccions, setBillingOrdersForAccions] = useState([]);
  const [billingDataOrdersForAccions, setDataBillingOrdersForAccions] =
    useState([]);

  const [alertMensajes, setAlertMensajes] = React.useState("");

  const [dataCurrentMohstDelivery, setCurrentMohstDelivery] =
    React.useState(null);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  React.useState(0);
  const [typeRequets, setTypeRequets] = React.useState({
    typeRequetsCreate: true,
    typeRequetsUpdate: false,
  });

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [dateMonths, setDateMonths] = React.useState(
    Moment(Date.now()).format("YYYY-MM")
  );

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispacht(companyId, initDate, endDate);
      findOrdesrDispachtMonths(companyId, initDate);
    }
  }, [companyId, initDate, endDate]);

  React.useEffect(() => {
    // console.log("documentData", documentData);
    // console.log("toolsData", toolsData);
    // console.log("dataType", dataType);
    // console.log("dataOrdersFilter", dataOrdersFilter);
  }, [documentData]);

  async function verifiqueOrdesStatus(userOrders) {
    var tempUpdate;

    if (billingDataOrdersForAccions.length > 0) {
      console.log("update by select");
      tempUpdate = billingDataOrdersForAccions;
    } else {
      console.log("update All");
      tempUpdate = userOrders;
    }

    const totalIterations = tempUpdate.length;

    const allOrdersRefBicci = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    const allOrdersRefFalabella = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );

    const ordersStatusBicci = async (orderid) => {
      const q = query(allOrdersRefBicci, where("id", "==", "F" + orderid));
      let ordesBicciexpres = await getDocs(q);
      const dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      var order = dataOrdesBicciexpres[0];

      console.log(order);
      if (order) {
        let bicciStatus = order.status;
        let statusCreate = true;
        let driverName = order.driverName ? order.driverName : "";
        let driverID = order.driverID ? order.driverID : "";
        let userDniReceptor = order.userDniReceptor
          ? order.userDniReceptor
          : "";
        let userNameReceptor = order.userNameReceptor
          ? order.userNameReceptor
          : "";
        let observationReceptor = order.observationReceptor
          ? order.observationReceptor
          : "";
        let observationAdmin = order.observationAdmin
          ? order.observationAdmin
          : "";

        return {
          statusCreate,
          bicciStatus,
          driverName,
          driverID,
          userDniReceptor,
          userNameReceptor,
          observationReceptor,
          observationAdmin,
        };
      } else {
        let bicciStatus = false;
        let statusCreate = false;
        let driverName = "";
        let driverID = "";
        let userDniReceptor = "";
        let userNameReceptor = "";
        let observationReceptor = "";
        let observationAdmin = "";
        return {
          statusCreate,
          bicciStatus,
          driverName,
          driverID,
          userDniReceptor,
          userNameReceptor,
          observationReceptor,
          observationAdmin,
        };
      }
    };

    const ordersStatusFalabella = async (orderid) => {
      const ordesFalabella = query(
        allOrdersRefFalabella,
        where("id", "==", orderid.toString())
      );
      let ordesFalabellaDoc = await getDocs(ordesFalabella);
      const dataOrdesFalabella = ordesFalabellaDoc.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      var orderF = dataOrdesFalabella[0];
      console.log(orderF);
      if (orderF) {
        let createdAtFalabella = orderF.createdAt;
        let falabella_status = orderF.falabella_status
          ? orderF.falabella_status
          : orderF.int_falabella_status;
        let updatedAt = orderF.updatedAt;
        return { createdAtFalabella, falabella_status, updatedAt };
      } else {
        let createdAtFalabella = false;
        let falabella_status = false;
        let updatedAt = false;
        return { createdAtFalabella, falabella_status, updatedAt };
      }
    };

    async function undateMergeDataEbiex(
      id,
      statusCreate,
      status,
      driverName,
      driverID,
      int_client_soc,
      createdAt,
      int_falabella_status,
      observationReceptor,
      observationAdmin,
      userDniReceptor,
      userNameReceptor,
      updatedAt
    ) {
      try {
        let data = {
          id,
          statusCreate,
          status,
          driverName,
          driverID,
          int_client_soc,
          createdAt,
          int_falabella_status,
          observationReceptor,
          observationAdmin,
          userDniReceptor,
          userNameReceptor,
          updatedAt,
        };

        dispacth(updateOrdersBicciexpressEstatus(data));
      } catch (error) {
        // console.log("error no encontrado");
        // console.log(error);
      }
    }

    return new Promise(async (resolve, reject) => {
      let completedIterations = 0;

      for (let index = 0; index < tempUpdate.length; index++) {
        //if ((tempUpdate[index].bicciStatus = 8)) {
        let resp = [];
        let resp2 = [];
        completedIterations++;

        console.log("tempUpdate[index]", tempUpdate[index]);
        console.log("TIPODELIVERY", tempUpdate[index].int_serviceType);
        console.log("LPN", tempUpdate[index].int_lpn);
        console.log("SOC", tempUpdate[index].SOC);
        try {
          resp = await ordersStatusBicci(tempUpdate[index].int_lpn);
        } catch (error) {
          // console.log(error);
        }

        try {
          resp2 = await ordersStatusFalabella(tempUpdate[index].SOC);
        } catch (error) {
          // console.log(error);
        }

        console.log("resp.bicciStatus", resp.bicciStatus);
        if (resp.bicciStatus !== false) {
          tempUpdate[index].statusCreate = resp.statusCreate;
          tempUpdate[index].bicciStatus = resp.bicciStatus;
          tempUpdate[index].driverName = resp.driverName;
          tempUpdate[index].driverID = resp.driverID;
          tempUpdate[index].createdAt = resp2.createdAtFalabella;
          tempUpdate[index].falabella_status = resp2.falabella_status;
          tempUpdate[index].observationReceptor = resp.observationReceptor;
          tempUpdate[index].observationAdmin = resp.observationAdmin;
          tempUpdate[index].userDniReceptor = resp.userDniReceptor;
          tempUpdate[index].userNameReceptor = resp.userNameReceptor;
          tempUpdate[index].updatedAt = resp2.updatedAt;

          try {
            await undateMergeDataEbiex(
              tempUpdate[index].key,
              tempUpdate[index].statusCreate,
              tempUpdate[index].bicciStatus,
              tempUpdate[index].driverName,
              tempUpdate[index].driverID,
              tempUpdate[index].orderID,
              tempUpdate[index].createdAt,
              tempUpdate[index].falabella_status,
              tempUpdate[index].observationReceptor,
              tempUpdate[index].observationAdmin,
              tempUpdate[index].userDniReceptor,
              tempUpdate[index].userNameReceptor,
              tempUpdate[index].updatedAt
            );
          } catch (error) {
            //console.log(error);
          }
        }

        setProgres((completedIterations / totalIterations) * 100);
        // } else {
        //   console.log(
        //     "Nada que actualizar int_bicciStatus >=( " +
        //       tempUpdate[index].orderID
        //   );
        //   console.log(tempUpdate[index].bicciStatus);
        //   console.log(tempUpdate[index].int_bicciStatus);
        //   completedIterations++;
        //   setProgres((completedIterations / totalIterations) * 100);
        // }

        if (
          tempUpdate[index].int_bicciStatus ||
          tempUpdate[index].int_bicciStatus === "isRequire"
        ) {
          let resp = [];
          let resp2 = [];
          completedIterations++;

          console.log("tempUpdate[index]", tempUpdate[index]);
          console.log("TIPODELIVERY", tempUpdate[index].int_serviceType);
          console.log("TIPODELIVERY", tempUpdate[index].int_lpn);

          try {
            resp = await ordersStatusBicci(tempUpdate[index].orderID);
          } catch (error) {
            //console.log(error);
          }

          try {
            resp2 = await ordersStatusFalabella(
              tempUpdate[index].SOC
                ? tempUpdate[index].SOC
                : tempUpdate[index].int_client_soc
            );
          } catch (error) {
            //console.log(error);
          }
          if (resp.bicciStatus !== false) {
            tempUpdate[index].statusCreate = resp.statusCreate;
            tempUpdate[index].int_bicciStatus = resp.bicciStatus;
            tempUpdate[index].driverName = resp.driverName;
            tempUpdate[index].driverID = resp.driverID;
            tempUpdate[index].createdAt = resp2.createdAtFalabella;
            tempUpdate[index].int_falabella_status = resp2.falabella_status;
            tempUpdate[index].userDniReceptor = resp.userDniReceptor;
            tempUpdate[index].userNameReceptor = resp.userNameReceptor;
            tempUpdate[index].observationReceptor = resp.observationReceptor;
            tempUpdate[index].observationAdmin = resp.observationAdmin;
            tempUpdate[index].updatedAt = resp2.updatedAt;
            try {
              await undateMergeDataEbiex(
                tempUpdate[index].key,
                tempUpdate[index].statusCreate,
                tempUpdate[index].int_bicciStatus,
                tempUpdate[index].driverName,
                tempUpdate[index].driverID,
                tempUpdate[index].orderID,
                tempUpdate[index].createdAt,
                tempUpdate[index].int_falabella_status,
                tempUpdate[index].observationReceptor,
                tempUpdate[index].observationAdmin,
                tempUpdate[index].userDniReceptor,
                tempUpdate[index].userNameReceptor,
                tempUpdate[index].updatedAt
              );
            } catch (error) {
              // console.log(error);
            }
          } else {
            console.log(
              "Nada que actualizar Geosort Data " + tempUpdate[index].orderID
            );
          }

          setProgres((completedIterations / totalIterations) * 100);
        } else {
          if (tempUpdate[index].int_bicciStatus < 8) {
            console.log(
              "Nada que actualizar int_bicciStatus no Require " +
                tempUpdate[index].orderID
            );
          }

          completedIterations++;
          setProgres((completedIterations / totalIterations) * 100);
        }
      }

      setRender(!render);
    });
  }

  const findOrdesrDispacht = async (companyId, initDate, endDate) => {
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);
      // console.log(rangInit);
      // console.log(rangFinal);
      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("createdAt", ">=", rangInit),
            where("createdAt", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.orderID]) {
              uniqueObjects[obj.orderID] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
          // console.log(filteredArray);
          setDataOrdersFilter(filteredArray);
        } catch (error) {
          console.log("error 345");
          // console.log(error);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("createdAt", ">=", rangInit),
            where("createdAt", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.SOC]) {
              uniqueObjects[obj.SOC] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          console.log(ordersPropietary);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setDataOrdersFilter(ordersPropietary);
        } catch (error) {
          console.log("error 346");
          //console.log(error);
        }
      }
      setDataType("request");
    } catch (error) {
      //console.log(error);
    }
  };

  const onChageRangeMonths = (e) => {
    setDateMonths(e.target.value);
  };

  const findOrdesrDispachtMonths = async (companyId, initDate) => {
    const selectedDate = new Date(dateMonths);
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      1
    );

    // Obtenemos el último día del mes seleccionado
    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 2,
      0
    );

    // Imprimimos el rango de fechas en la consola

    const ordersDispachtRef = collection(db, process.env.REACT_APP_COL_ORDERS);
    const q = query(
      ordersDispachtRef,
      where("companyId", "==", companyId),
      where("timeStamp", ">=", startOfMonth),
      where("timeStamp", "<=", endOfMonth)
    );

    const querySnapshot = await getDocs(q);

    const ordersPropietary = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log(ordersPropietary);
    const uniqueObjects = {};

    for (const obj of ordersPropietary) {
      if (!uniqueObjects[obj.orderID]) {
        uniqueObjects[obj.orderID] = obj;
      }
    }

    const filteredArray = Object.values(uniqueObjects);

    ordersPropietary.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

    const groupedData = {};

    filteredArray.forEach((item) => {
      if (item.timeStamp && item.timeStamp.seconds) {
        const date = new Date(item.timeStamp.seconds * 1000)
          .toISOString()
          .substring(0, 10);
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(item);
      } else {
        console.warn(
          `Invalid or missing timeStamp for item: ${JSON.stringify(item)}`
        );
        // Handle the item without a valid timeStamp if necessary, e.g., skip it or log it.
      }
    });

    const dates = Object.keys(groupedData);

    const resultCurrentDelivery = [];

    dates.forEach((date) => {
      const orders = groupedData[date];
      const numOrders = orders.length;
      const dateObj = new Date(date);
      const monthNum = dateObj.getMonth() + 1;
      const year = dateObj.getFullYear();
      const month = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(dateObj);
      resultCurrentDelivery.push({
        id: date,
        type: "currentDelivery",
        monthNum: monthNum,
        cant: numOrders,
        year: year,
        month: month,
      });
    });

    setCurrentMohstDelivery(resultCurrentDelivery);
  };

  const generarReporteTurno = (data) => {
    generarReporteTurnoCierrediario(data, ridersDataByCompany, initDate);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    // setProgressValue(20);
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
    // console.log(createDate);
    // console.log(FinalDate);
  };

  const deletedItems = async (data) => {
    for (let index = 0; index < data.length; index++) {
      //  console.log(data[index].key);
      let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data[index].id);
      await deleteDoc(docRef)
        .then(() => {
          console.log("Documento borrado exitosamente." + data[index].key);
        })
        .catch((error) => {
          console.error("Error al borrar el documento:", error);
        });
    }
  };

  const exportartPicking = (userOrders) => {
    console.log("exportartPicking");
    generarReportePicking(userOrders);
  };

  const exportartReportePagos = (userOrders) => {
    //  console.log(userOrders);
    generarReporteProformas(userOrders);
  };

  const UpdateOrderForAcctions = (items) => {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataOrdersFilter;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (
          listadeItems[i] === tempuserOrders[n].id ||
          listadeItems[i] === "F" + tempuserOrders[n].id
        ) {
          console.count("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      // setItemsDataOrdersForAccions(true);
      setDataBillingOrdersForAccions(dataOrdersForAccions);
      setBillingOrdersForAccions(ordersForAccions);
    } else {
      //setItemsDataOrdersForAccions(false);
    }

    // setDataBillingOrdersForAccions(dataOrdersForAccions);
    // setBillingOrdersForAccions(ordersForAccions);

    setRender(!render);
  };

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            {movilActive === true ? (
              <>
                <CardHeader
                  subheader={
                    <ToolDashAppBarBilling
                      movil={widthHeight.width < 950 ? true : false}
                      cantDataOrdersFilter={
                        dataOrdersFilter ? dataOrdersFilter.length : 0
                      }
                      Buttondisable={Buttondisable}
                      setButtondisable={setButtondisable}
                      CheckedChangeOE={CheckedChangeOE}
                      typeRequets={typeRequets}
                      dataOrdersForAccions={billingDataOrdersForAccions}
                      setWiewPrePdf={setWiewPrePdf}
                      wiewPrePdf={wiewPrePdf}
                      listadeItemsNoMach={listadeItemsNoMach}
                      companyId={companyId}
                      staticDateBillingFilter={staticDateBillingFilter}
                      dataCurrentMohstDelivery={dataCurrentMohstDelivery}
                      documentData={documentData}
                      setDocumentData={setDocumentData}
                      dataType={dataType}
                      setDataType={setDataType}
                      render={render}
                      setRender={setRender}
                      dataOrdersFilter={dataOrdersFilter}
                      verifiqueOrdesStatus={verifiqueOrdesStatus}
                      usuarioStorage={props.usuarioStorage}
                      setDataOrdersFilter={setDataOrdersFilter}
                      exportartPicking={exportartPicking}
                    ></ToolDashAppBarBilling>
                  }
                  sx={{
                    padding: "16px",
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "flex-start",
                    "& .Routes-wrapper": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                    "& .realtime-label": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-info": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-pause": {
                      marginTop: "0.5rem",
                      textAlign: "center",
                    },
                    "& .searcher-wrapper": {
                      marginTop: "0.5rem",
                    },
                    "@media (min-width: 950px)": {
                      padding: "16px 24px",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      "& .Routes-wrapper": {
                        flexDirection: "row",
                        alignItems: "center",
                      },
                      "& .realtime-label": {
                        marginTop: 0,
                        marginRight: "1rem",
                      },
                      "& .realtime-pause": {
                        marginTop: 0,
                      },
                      "& .searcher-wrapper": {
                        marginTop: 0,
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                {" "}
                <CardHeader
                  subheader={
                    <div>
                      <ToolDashAppBarBilling
                        movil={widthHeight.width < 950 ? true : false}
                        cantDataOrdersFilter={
                          dataOrdersFilter ? dataOrdersFilter.length : 0
                        }
                        dataOrdersFilter={dataOrdersFilter}
                        dataCurrentMohstDelivery={dataCurrentMohstDelivery}
                        Buttondisable={Buttondisable}
                        setButtondisable={setButtondisable}
                        CheckedChangeOE={CheckedChangeOE}
                        typeRequets={typeRequets}
                        dataOrdersForAccions={billingDataOrdersForAccions}
                        setWiewPrePdf={setWiewPrePdf}
                        wiewPrePdf={wiewPrePdf}
                        listadeItemsNoMach={listadeItemsNoMach}
                        onChageRangeMonths={onChageRangeMonths}
                        dateMonths={dateMonths}
                        companyId={companyId}
                        onChageRangePiker={onChageRangePiker}
                        staticDateBillingFilter={staticDateBillingFilter}
                        setAlertMensajes={setAlertMensajes}
                        toolsData={toolsData}
                        setToolsData={setToolsData}
                        documentData={documentData}
                        generarReporteTurno={generarReporteTurno}
                        dataType={dataType}
                        setDocumentData={setDocumentData}
                        setDataType={setDataType}
                        render={render}
                        setRender={setRender}
                        verifiqueOrdesStatus={verifiqueOrdesStatus}
                        usuarioStorage={props.usuarioStorage}
                        exportartReportePagos={exportartReportePagos}
                        setDataOrdersFilter={setDataOrdersFilter}
                        exportartPicking={exportartPicking}
                      ></ToolDashAppBarBilling>
                    </div>
                  }
                />
              </>
            )}
            {!dataOrdersFilter ? <LinearProgress></LinearProgress> : null}
            <Divider />
            {progress !== 0 && progress < 100 ? (
              <div className="progressTextPorcentBig color-calipso">
                {progress.toFixed(1)}%{" "}
                {progress > 95 ? "Completado..." : "Cargando..."}
              </div>
            ) : null}
            <Container className="formcontent" id="micuentafromIni">
              <div>{alertMensajes}</div>

              <Grid.Row>
                <Grid.Column
                  computer={8}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun "
                >
                  {documentData && dataType !== "request" ? (
                    <div>
                      <ToolDashOrdersContainerDocuments
                        movil={widthHeight.width < 950 ? true : false}
                        styleStateBicciFalabella={styleStateBicciFalabella}
                        translateStateBicciFalabella={
                          translateStateBicciFalabella
                        }
                        documentData={documentData}
                        dataType={dataType}
                      ></ToolDashOrdersContainerDocuments>
                    </div>
                  ) : null}
                  {!dataOrdersFilter ? (
                    <div>no resultados de busqueda</div>
                  ) : null}
                  {dataOrdersFilter && widthHeight.width > 950 ? (
                    <UserListViewOrders
                      dataOrdesRequets={dataOrdersFilter}
                      visibleBilling={true}
                      ordersForAccions={billingOrdersForAccions}
                      UpdateOrderForAcctions={UpdateOrderForAcctions}
                      companyId={companyId}
                    ></UserListViewOrders>
                  ) : null}

                  {dataOrdersFilter && dataType === "request" ? (
                    <div>
                      <ToolDashOrdersContainerBilling
                        movil={widthHeight.width < 950 ? true : false}
                        styleStateBicciFalabella={styleStateBicciFalabella}
                        translateStateBicciFalabella={
                          translateStateBicciFalabella
                        }
                        userOrders={dataOrdersFilter}
                        styleState={props.styleState}
                      ></ToolDashOrdersContainerBilling>
                    </div>
                  ) : null}
                </Grid.Column>

                <Grid.Column
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun"
                ></Grid.Column>
              </Grid.Row>
            </Container>

            <Divider />
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GuiasByCompanyBilling;
