import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { format } from "date-fns";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "10px",
    border: "1px solid #000",
  },
  barcodeSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 0",
  },
  text2: {
    fontSize: 12,
    padding: "2px",
  },
  qrSection: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
  },
  textSection: {
    margin: "1px 0",
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
  },
  infoText: {
    fontSize: 8,
    margin: "1px 0",
  },
  infoTextCenter: {
    fontSize: 8,
    margin: "1px 0",
    textAlign: "center",
  },
  image: {
    width: 60,
    height: 60,
  },
  img: { color: "#050505", width: "120px" },
  imageLogo: {
    width: 120,
    height: 60,
  },
  imageBarcode: {
    width: "100%",
    height: 150,
    textAlign: "center",
  },
  imageBarcodeLpn: {
    width: "100%",
    height: 50,
    textAlign: "center",
  },
  table: {
    fontSize: "10px",
    width: "100%",
    marginTop: 20,
    border: "1px solid black",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableTop: {
    display: "table",
    width: "auto",
    borderWidth: 0,
    marginTop: "10px",
    marginBottom: "0px",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#f1f1f1",
    borderBottom: "1px dotted #000",
  },
  tableRowTop: {
    margin: "auto",
    flexDirection: "row",
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingBottom: "3px",
    paddingtOP: "3px",
  },
  tableCol: {
    width: "15%",
    fontWeight: 400,
    textAlign: "letf",
  },
  tableColBig: {
    width: "25%",
    fontWeight: 400,
    textAlign: "letf",
  },
  tableColTop: {
    width: "20%",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontWeight: 700,
    textAlign: "letf",
  },
  tableColTop20: {
    width: "20%",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontWeight: 700,
    textAlign: "letf",
  },

  tableColCant: {
    width: "4%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontWeight: 700,
  },
  tableColDescipcion: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 700,
    textAlign: "letf",
  },
  tableCellTop: {
    width: "auto",
    marginTop: 5,
    fontSize: 30,
    color: "blue",
    margin: "auto",
    flexDirection: "row",
    paddingBottom: "5px",
    paddingTop: "5px",
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  bigBicci: {
    fontSize: "28px",
    marginTop: "2px",
    marginBottom: "2px",
    color: "#7d0ee6",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  tableCell: {
    width: "auto",
    marginTop: 0,
    fontSize: "8px",
    margin: "auto",
    flexDirection: "row",
    paddingBottom: "1px",
    paddingTop: "1px",
  },
  tableRowItem: {
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableColItem: {
    width: "14%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontWeight: 700,
  },
  tableCellItem: {
    margin: "auto",
    marginTop: "3px",
    marginBottom: "3px",
    marginRight: "3px",
    fontSize: "8px",
    minHeight: "25px",
  },
  tableSmall: {
    fontSize: "8px",
    width: "100%",
    marginTop: 20,
    border: "1px solid black",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableColSmall: {
    display: "table",
    width: "50%",
    borderWidth: 0,
    marginTop: "5px",
    marginBottom: "0px",
  },
  tableSmall2: {
    display: "table",
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginTop: "auto",
    marginBottom: "30px",
  },
  tableColSmallTiendas: {
    width: "40%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 700,
    fontSize: "12px",
    color: "blue",
  },
  tableCellTiendas: {
    width: "40%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 700,
    fontSize: "12px",
    color: "red",
  },
  tableStoreSmall2: {
    fontSize: "10px",
    width: "100%",
    marginTop: 20,
    border: "1px solid black",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableRowTopStore: {
    flexDirection: "row",
    backgroundColor: "#f1f1f1",
  },
  tableRowTopStoreBody: {
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableColSmallStore: {
    flex: 1,
    padding: 10,
    width: "30%",
  },
  tableColSmallStoreBody: {
    flex: 1,
    padding: 10,
    width: "30%",
  },
  tableCellStore: {
    fontWeight: "bold",
  },
  tableCellStoreBody: {
    textAlign: "center",
  },
  inlineView: {
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  midlineView: {
    with: "50%",
  },
  inlineText: {
    margin: 2,
    borderTop: "1px solid #000",
    marginTop: "30px",
  },
});
const CreateManiFestByCurrentListPdf = ({ orders }) => {
  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <View>
          <Text style={styles.header}>BICCI Hoja de Ruta</Text>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>

          <Text style={styles.text}>Guía de Despachos a Retirar en Bodega</Text>
          <Text style={styles.text2}>
            FOLIOS# {orders.length} , día{" "}
            {format(new Date(), "dd/MM/yy hh:mm:ss")}
          </Text>
          <Text style={styles.text2}>
            Se ha asignado las siguientes órdenes del día a _{" "}
            {orders[0].driverName} , para completar las entregas al cliente
            final, por favor recuerda tomar todas la evidencias correctamente,
            que se solicitan el app BICCI RIDER, Retira conforme los{" "}
            {orders.length} del HUB BICCI listados a continuación, para su
            gestión.
          </Text>
          <View style={styles.inlineView}>
            <View style={styles.midlineView}>
              <Text style={styles.inlineText}>OPERACIONES</Text>
            </View>
            <View style={styles.midlineView}>
              <Text style={styles.inlineText}>
                CONDUCTOR:{orders[0].driverName}{" "}
              </Text>
            </View>
          </View>
          {orders ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>CÓDIGO</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>ZONA</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>CLIENTE</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>FECHA</Text>
                </View>
                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>DIRECCIÓN</Text>
                </View>
              </View>

              {orders.map((element, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColBig}>
                    <Text>{element.id} </Text> <Text>LPN:{element.lpn}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>{element.commune2}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>
                      {element.customer_name.toLowerCase()}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellItem}>
                      {format(element.createdAt * 1000, "dd/MM/yy")}
                    </Text>
                  </View>
                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellItem}>{element.address}</Text>
                  </View>
                </View>
              ))}
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default CreateManiFestByCurrentListPdf;
