import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_BICCIXPRESS_DEV_API_KEY}`,
  authDomain: `${process.env.REACT_APP_BICCIXPRESS_DEV_AUTHDOMAIN}`,
  databaseURL: `${process.env.REACT_APP_BICCIXPRESS_DEV_DATABASEURL}`,
  projectId: `${process.env.REACT_APP_BICCIXPRESS_DEV_PROYECID}`,
  storageBucket: `${process.env.REACT_APP_BICCIXPRESS_DEV_STORAGEBUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_BICCIXPRESS_DEV_MESSAGINGSENDERIR}`,
  appId: `${process.env.REACT_APP_BICCIXPRESS_DEV_APPID}`,
  measurementId: `${process.env.REACT_APP_BICCIXPRESS_DEV_MEASUREMENTID}`,
};

const firebaseConfigPro = {
  apiKey: `${process.env.REACT_APP_BICCIXPRESS_API_KEY}`,
  authDomain: `${process.env.REACT_APP_BICCIXPRESS_AUTHDOMAIN}`,
  databaseURL: `${process.env.REACT_APP_BICCIXPRESS_DATABASEURL}`,
  projectId: `${process.env.REACT_APP_BICCIXPRESS_PROYECID}`,
  storageBucket: `${process.env.REACT_APP_BICCIXPRESS_STORAGEBUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_BICCIXPRESS_MESSAGINGSENDERIR}`,
  appId: `${process.env.REACT_APP_BICCIXPRESS_APPID}`,
  measurementId: `${process.env.REACT_APP_BICCIXPRESS_MEASUREMENTID}`,
};

const app = initializeApp(firebaseConfigPro);
const firestore = getFirestore(app);
const bucketName = firebaseConfigPro.projectId + ".appspot.com";
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, firebase, db, storage, bucketName, firestore };
