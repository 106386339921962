import React, { useState } from "react";
import { Card, CardHeader, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const ToolDashOrdersContainerDocuments = (props) => {
  const [localData, setData] = useState([]);
  const [attributeNames, setAttributes] = useState([]);
  const [dataType, setDataType] = useState(null);

  React.useEffect(() => {
    if (props.documentData) {
      setData(props.documentData);
    }

    if (props.dataType) {
      setDataType(props.dataType);
    }
  }, [props]);

  React.useEffect(() => {
    if (localData[0]) {
      console.log(Object.keys(localData[0]));
      let data = Object.keys(localData[0]);
      setAttributes(data);
    }
  }, [localData]);

  // Crear las columnas dinámicamente
  const columns = attributeNames.map((attributeName) => ({
    field: attributeName,
    headerName: attributeName,
    width: 200,
  }));

  const rows = localData.map((item, index) => ({
    id: index,
    ...item, // Copiar todos los atributos del objeto en la fila
  }));

  if (props.movil === true) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader title="No Disponible para movil" />
      </Card>
    );
  }
  if (props.movil === false) {
    return (
      <div>
        {" "}
        <Card className="card-ultimasOrdenes typeMiniforms">
          <CardHeader title="Registros en documento" />

          <Box sx={{ minWidth: 800 }}>
            <div style={{ height: 400, width: "100%" }}>
              {localData && attributeNames ? (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                ></DataGrid>
              ) : (
                ""
              )}
            </div>
            {/*
            <table className="table table-card">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    {" "}
                    <input
                      className="fontsize25"
                      type="checkbox"
                      defaultChecked={false}
                      id={"AllorderID"}
                      name={"selectAllorderID"}
                      onChange={props.handleAllSelectorderID}
                    />{" "}
                  </th>
                  <th scope="col">Código</th>
                  <th scope="col">F12</th>
                  <th scope="col">Conductor</th>
                  <th scope="col">Tienda Origen</th>
                  <th scope="col">Cliente Destino</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Comuna</th>
                  <th scope="col">Fecha Pactada</th>
                  <th scope="col">Estatus</th>
                  <th scope="col">Bicci Estatus</th>
                  <th scope="col">Facturación</th>
                  <th scope="col">Medidas</th>
                  <th scope="col">Pago Estimado</th>
                  <th scope="col">Doc</th>
                </tr>
              </thead>
              <tbody>
                {props.userOrders &&
                  props.userOrders.map((element, index) => (
                    <tr key={element.id}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          className="fontsize25"
                          type="checkbox"
                          defaultChecked={element.setectedItem}
                          id={element.orderID}
                          name={element.dispachtCode}
                          onChange={props.handleOrdersForAccions}
                        />
                      </td>
                      <td>
                        <span className="fontsize18">{element.orderID}</span>
                      </td>
                      <td>{element.F12}</td>
                      <td>
                        {" "}
                        {element.driverName ? (
                          element.driverName
                        ) : (
                          <NewReleasesIcon className="pinEstatusCancel" />
                        )}
                      </td>
                      <td>{element.BODEGANOMBRE}</td>
                      <td>{element.CLIENTENOMBRES.toLowerCase()}</td>
                      <td>
                        {element.DIRECCION1}, {element.DIRECCION2}
                      </td>
                      <td>{element.COMUNA2}</td>

                      <td>
                        {element.timeStamp
                          ? format(element.timeStamp.seconds * 1000, "dd/MM/yy")
                          : "No creado"}
                      </td>
                      <td>
                   
                      </td>
                      <td>
                        {element.bicciStatus < 0 && element.bicciStatus < 8 ? (
                          <span className="pinEstatusCancel">No Info </span>
                        ) : null}
                        {element.bicciStatus > 2 && element.bicciStatus < 8 ? (
                          <span className="pinEstatusInRoute">
                            <DirectionsBike sx={{ fontSize: "12px" }} /> En ruta
                          </span>
                        ) : null}
                        {element.bicciStatus > 7 ? (
                          <span className="pinEstatusDelivery">Entregado </span>
                        ) : null}
                        {element.bicciStatus === null ? (
                          <span className="pinEstatusNoCreate">
                            {" "}
                            Sin Regístro{" "}
                          </span>
                        ) : null}
                      </td>

                      <td>
                        {" "}
                        {element.PaymentProforma ? (
                          <span className="pinEstatusDelivery">OK</span>
                        ) : (
                          "-"
                        )}{" "}
                      </td>
                      <td>
                        {props.userOrders ? (
                          <ValidationsSize
                            id={element.orderID}
                            weight={element.PESO}
                            multibulk={element.MULTIBULTO}
                            length={element.LARGO}
                            height={element.ALTO}
                            width={element.ANCHO}
                            units={element.UNIDADES}
                            volume={element.VOLUMEN}
                            packeSize={element.TAMANOPRODUCTO}
                          ></ValidationsSize>
                        ) : (
                          <>Espere</>
                        )}
                      </td>
                      <td>
                        {props.userOrders && element.PaymentValor ? (
                          <ValidationsPayments
                            id={element.orderID}
                            paymentvalor={element.PaymentValor}
                            weight={element.PESO}
                            multibulk={element.MULTIBULTO}
                            length={element.LARGO}
                            height={element.ALTO}
                            width={element.ANCHO}
                            units={element.UNIDADES}
                            volume={element.VOLUMEN}
                            packeSize={element.TAMANOPRODUCTO}
                          ></ValidationsPayments>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td>
                        {" "}
                        {element.createdAt ? (
                          <>
                            {element.PaymentProforma ? (
                              <strong>{element.PaymentProforma}</strong>
                            ) : (
                              <span className="pinEstatusInRoute">
                                Pendiente
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            <span className="pinEstatusCancel">-- </span>
                          </>
                        )}
                      </td>
                      <td></td>

                     <td ><NavLink to={{
                     pathname: `/dashboard/dispatch/details/${element.id}`}} element={element} color="primary" variant="contained"> VER </NavLink></td>
                     
                    </tr>
                  ))}
              </tbody>
            </table>
            */}
          </Box>
        </Card>
      </div>
    );
  }

  return <div>No se detecto dispositivo</div>;
};

export default ToolDashOrdersContainerDocuments;
