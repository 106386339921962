import React from "react";
import { Grid, Image } from "semantic-ui-react";
import Bannerfooter from "../../../../../components/Bannerfooter/Bannerfooter";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useGlobalConfig from "../../../../../hooks/globalConfig";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
const Pos5 = () => {
  const footermensaje = {
    className: "resaltadoCurvo FloatRignt marging20",
    title: "CIUDADES INTELIGENTES",
    paragramh:
      "La electromovilidad contribuye también al desarrollo de ciudades inteligentes o smart cities (en inglés), concepto que apunta al desarrollo urbano sostenible, dando respuesta a las necesidades de la sociedad, pero también de organizaciones y empresas, buscando la eficiencia energética y la reducción de emisiones de CO2, al mismo tiempo que el desarrollo económico y el cuidado del medioambiente.",
  };

  const {
    img1,
    img2,
    img3,
    img4,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    ecotribicci,
    homeImgUrl,
    biccidashboard,
    ecotribicci2,
    cobertura,
    btnvehicle,
    btnuseradmin,
    btnacuerdo,
    icomshome1,
    icomshome2,
    icomshome3,
    icomshome4,
    icomshome5,
  } = useGlobalConfig();

  //const { totalDelivery, totalCal } = useGetInfo();

  const [totalDelivery, setTotalDelivery] = useState(36000);
  const [totalCal, setTotalCal] = useState(26000);
  const [clientsEstimat, setClientsEstimat] = useState(26000);

  useEffect(() => {
    callUptade();
  }, []);

  async function callUptade() {
    try {
      const ordersDispachtRef = collection(db, "xpress_config");
      const docRefc = doc(ordersDispachtRef, "fromdConfig");
      const querySnapshot = await getDoc(docRefc);
      const totalDocuments = querySnapshot.data().totalDelivery;
      setTotalDelivery(totalDocuments);
      let estimaet = totalDelivery * 0.0005;
      let clientsEstimate = totalDelivery * 0.85;
      setClientsEstimat(clientsEstimate);
      setTotalCal(estimaet + 26000);
    } catch (error) {}
  }

  return (
    <div>
      <Grid celled="internally">
        <Grid.Row className="rowmarging">
          <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph2">
            <div className="text-center">
              <span className="abs-center">
                <h1 className="resaltadoCurvo">BICCI</h1>
              </span>
              <br></br>
              <span className="abs-center">
                <h3 className="resaltadoCurvo">Última milla sustentable.</h3>
              </span>
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo">
                  Somos BICCI, una Startup de última milla sustentable
                  desarrollada para empresas. Ofrecemos un novedoso servicio de
                  entrega a través de nuestros vehículos eléctricos. Brindando
                  un servicio de calidad para nuestros clientes y contribuyendo
                  de esta manera a la reducción de la huella de carbono en el
                  mundo.
                </h2>
              </span>
            </div>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Image src={homeImgUrl} />
          </Grid.Column>
        </Grid.Row>
        <div className="spacedivider"></div>

        <Grid.Row className="bannertiposdeenvio">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="colorWhite font-sizeBig">
              <strong>
                Llega a más clientes con nuestras modalidades de envíos
              </strong>
            </div>
            <div className="cardHomeContent">
              <Card
                className="cardHome"
                sx={{ maxWidth: 250, margin: 2, minWidth: 250 }}
              >
                <CardActionArea>
                  <img src={img1} alt="bicci" className="Home_cardContent" />

                  <CardContent className="justefi-padding01">
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Quick commerce
                    </Typography>
                    <div>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                      >
                        Realizamos entregas de tus productos a tus clientes de
                        manera casi instantánea, en menos de 30 minutos.
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome"
                sx={{ maxWidth: 250, margin: 2, minWidth: 250 }}
              >
                <CardActionArea>
                  <img src={img11} alt="bicci" className="Home_cardContent" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Servicio Express
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body2"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Envía tus ordenes de punto A al punto B, en un tiempo
                      estimado de 3 horas aprox.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {/* <CardActions>
                <Button size="small" color="primary">
                Registrar mi empresa
                </Button>
            </CardActions> */}
              </Card>

              <Card
                className="cardHome"
                sx={{ maxWidth: 250, margin: 2, minWidth: 250 }}
              >
                <CardActionArea>
                  <img src={img2} alt="bicci" className="Home_cardContent" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Same Day
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body2"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Genera tus ordenes de despacho antes de las 12:00 hrs. y
                      tus clientes lo reciben el mismo día. Tiempo aproximado de
                      entrega 12 horas. ciben antes de las 21h.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome"
                sx={{ maxWidth: 250, margin: 2, minWidth: 250 }}
              >
                <CardActionArea>
                  <img src={img3} alt="bicci" className="Home_cardContent" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Next Day
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body2"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Programa envíos para ser entregados al día siguiente
                      mediante rutas consolidadas. Tiempo aproximado de entrega
                      24 horas
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome"
                sx={{ maxWidth: 250, margin: 2, minWidth: 250 }}
              >
                <CardActionArea>
                  <img
                    src={img4}
                    alt="bicci"
                    className="Home_cardContent Home_cardContent160"
                  />

                  <CardContent className="justefi-padding">
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Logística Inversa
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body2"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Brindamos a tus clientes servicio de devoluciones sin
                      problema, fácil y rápido.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <br></br>
          </Grid.Column>
        </Grid.Row>
        <div className="spacedivider"></div>

        <Grid.Row className="rowmargingsmall">
          <Grid.Column computer={8} mobile={16} tablet={6} id="Buscadorletf">
            <NavLink to="/eco-bicci" exact="true">
              <Image id="bannerServicios" src={img1} />
            </NavLink>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={10} id="Buscadorright">
            <NavLink to="/eco-bicci" exact="true">
              <div>
                <span>
                  <h1 id="firstmensaje">
                    "Comprometidos con el desarrollo social y con la reducción
                    de la huella de carbono en el mundo."{" "}
                  </h1>
                </span>
              </div>
            </NavLink>
            <NavLink className="btn btn-ebiex m-1" to="/eco-bicci" exact="true">
              Conoce mas...
            </NavLink>
          </Grid.Column>
        </Grid.Row>

        <div className="spacedivider"></div>

        <Grid.Row className="bannertiposdeservicio fondobicci">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="colorWhite font-sizeBig">
              <h2 className="colorWhite">
                {" "}
                <strong>Herramientas para mejorar tu negocio</strong>{" "}
              </h2>
            </div>
            <div className="cardHomeContent">
              <Card
                className="cardHome3"
                sx={{ maxWidth: 300, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer">
                    <span className="services_badge">Próximamente</span>
                  </div>

                  <div className="services_header">
                    <span className="services_border"></span>PUNTOS DE DESPACHO
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img12} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Genera tus órdenes de con diferentes puntos despacho.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer">
                    <span className="services_badge">Nuevo</span>
                  </div>
                  <div className="services_header">
                    <span className="services_border"></span>INTEGRACIONES
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img13} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Es posible integrarnos a tu plataforma y generar ordenes
                      automaticas en cada compra. previa evaluación.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer">
                    <span className="services_badge">Próximamente</span>
                  </div>
                  <div className="services_header">
                    <span className="services_border"></span>GESTIÓN DE RUTAS
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img14} alt="bicci" className="Home_cardContent3" />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Ordena las fechas y prioridades de entregas con una ruta
                      óptima y eficiente.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <br></br>
          </Grid.Column>
        </Grid.Row>

        <div className="spacedivider"></div>

        <div className="spacedivider"></div>

        <Grid.Row className="rowmarging">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo subtilepage">
                  BENEFICIOS DE LA ELECTROMOVILIDAD.
                </h2>
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="rowmarging">
          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Image src={ecotribicci} />
          </Grid.Column>
          <Grid.Column
            computer={8}
            mobile={16}
            tablet={6}
            id="paragraph2"
            className="columnleft"
          >
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                MEJORAS LA CALIDAD DEL AIRE:
              </h2>
              <p>
                Un vehículo eléctrico es CERO EMISIÓN, es decir, mientras está
                en movimiento no produce emisiones de gases ni partículas, lo
                que impacta directamente en mejorar la calidad de aire en las
                ciudades pues se disminuye la contaminación local. En Chile, 12
                ciudades han sido declaradas como zonas saturadas en material
                particulado, siendo el sector transporte una de las principales
                preocupaciones para las autoridades. Mejorando la calidad del
                aire, se generan beneficios directos en salud y enfermedades
                respiratorias, principalmente en la población infanto-juvenil y
                en adultos mayores.
              </p>
            </div>
            <br></br>
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                {" "}
                JUNTOS CONTRA EL CAMBIO CLIMÁTICO:
              </h2>
              <p>
                La electromovilidad utiliza como fuente energética la
                electricidad, es decir, no ocurre combustión fósil (gasolina,
                diésel, gas) y por tanto no se producen emisiones directas de
                CO2 mientras un vehículo eléctrico se mueve, contribuyendo así a
                combatir el cambio climático. Este beneficio se logra maximizar
                cuando la electricidad es generada también con tecnologías de
                cero emisión, como lo son las energías renovables.
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
        {totalDelivery && totalCal ? (
          <Grid.Row className="metricasenvio">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <div className="cardHomeContent">
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome5}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      {totalDelivery ? (
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          gutterBottom
                          variant="h1"
                          component="div"
                          className="fonZiseBig"
                        >
                          <CountUp start={0} end={totalDelivery} />
                        </Typography>
                      ) : (
                        <></>
                      )}

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Productos entregados.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome3}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      {totalDelivery ? (
                        <>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            gutterBottom
                            variant="h1"
                            component="div"
                            className="fonZiseBig"
                          >
                            {/* <CountUp start={0} end={totalDelivery - 112} /> */}
                            <CountUp start={0} end={clientsEstimat} />
                          </Typography>
                        </>
                      ) : (
                        <div></div>
                      )}

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Clientes felices
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome2}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={7} />
                      </Typography>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Tiendas afiliadas
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome4}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={36} />
                      </Typography>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Tiendas usan nuestro shipping.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome1}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      {totalCal ? (
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          gutterBottom
                          variant="h1"
                          component="div"
                          className="fonZiseBig"
                        >
                          {/* <CountUp start={0} end={totalCal} /> */}
                          <CountUp start={0} end={totalCal} />
                          TH
                        </Typography>
                      ) : (
                        <div></div>
                      )}

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        CO2 Ahorrados.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
              <br></br>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row className="metricasenvio">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <div className="cardHomeContent">
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome5}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={25623} />{" "}
                      </Typography>

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Paquetes entregados.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome3}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={25511} />
                      </Typography>

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Clientes felices
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome2}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={7} />
                      </Typography>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Tiendas afiliadas cada mes
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome4}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={479} />
                      </Typography>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        Tiendas usan nuestro shipping.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card
                  className="cardHome"
                  sx={{
                    maxWidth: 250,
                    margin: 2,
                    minWidth: 250,
                    border: "none",
                  }}
                >
                  <CardActionArea>
                    <img
                      src={icomshome1}
                      alt="bicci"
                      className="Home_cardContent"
                    />

                    <CardContent>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        gutterBottom
                        variant="h1"
                        component="div"
                        className="fonZiseBig"
                      >
                        <CountUp start={0} end={26000} />
                        TH
                      </Typography>

                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        variant="body2"
                        color="text.secondary"
                        className="min-heght"
                      >
                        CO2 Ahorrados.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
              <br></br>
            </Grid.Column>
          </Grid.Row>
        )}

        <div className="spacedivider"></div>

        <Grid.Row className="rowmarging">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo subtilepage">
                  SOLICITAR COBERTURA.
                </h2>
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="rowmarging">
          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Image src={cobertura} />
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo columnright">¿Porque Bicci?</h2>
              <h1 className="resaltado columnright">
                Conviértete en un punto de recolección de paquetes
              </h1>
              <h3 className="columnright">
                Genera ingresos extras desde tu local. Aumenta la circulación de
                potenciales clientes en tu negocio, con la posibilidad de
                aumentar tu ticket promedio de ventas.
              </h3>
              <div className="columnright">
                <NavLink
                  className="btn btn-ebiex m-1"
                  to="/contacto"
                  exact="true"
                >
                  Incluir mi ubicación
                </NavLink>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        <div className="spacedivider"></div>

        <Grid.Row className="bannertiposdeservicio">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <br></br>
            <div className="colorBlack font-sizeBig">
              <h2>
                <strong>¿Por qué elegir a bicci ?</strong>{" "}
              </h2>
            </div>
            <div className="cardHomeContent">
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>ASESORÍA DE
                    EXPERTOS
                  </div>
                  <img src={img16} alt="bicci" className="Home_cardContent3" />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Nos preocupamos por darte la mejor atención. Nuestros
                      asesores dan seguimiento durante todo el recorrido de tu
                      producto hasta llegar a su destino final. Te notificaremos
                      según tus preferencias.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              <Card
                className="cardHome3"
                sx={{ maxWidth: 300, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>SERVICIOS DE
                    ENCOMIENDAS Y MENSAJERÍAS
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img17} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Puedes cotizar el despacho de tu producto de forma simple,
                      también una vez iniciada la ruta podrás verificar su
                      status en tiempo real
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>¡QUEREMOS SER PARTE
                    DE TU ÚLTIMA MILLA!
                  </div>

                  <img src={img15} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Tenemos soluciones adaptadas a tus necesidades, Te damos
                      una experiencia de usuario única. Dale un Valor agregado a
                      tus productos al confiar tus despachos en nosotros.
                      Cuidamos el medio ambiente y reducimos la huella de
                      carbono con nuestros vehículos eco-sustentables
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>SERVICIOS DE
                    CALIDAD
                  </div>

                  <img
                    src={img18}
                    alt="SERVICIO"
                    className="Home_cardContent3"
                  />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Una variedad de servicios que cubran las necesidades de
                      nuestros clientes, dentro de un ambiente amigable,
                      transparente y ecológico, con una atención personalizada e
                      innovadora, logrando con ello poner el mundo a su alcance.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <br></br>
          </Grid.Column>
        </Grid.Row>

        <div className="spacedivider"></div>

        <Grid.Row className="rowmarging">
          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Image src={ecotribicci2} />
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                EVITA LA CONTAMINACIÓN ACÚSTICA:
              </h2>
              <p>
                Los vehículos eléctricos casi no emiten ruido, esto se hace más
                relevante en flotas numerosas de vehículos, en vehículos pesados
                con motores diesel o en vehículos operando a velocidades por
                sobre los 50 (km/h)
              </p>
            </div>
            <br></br>
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                GESTIÓN DE RESIDUOS RESPONSABLE:
              </h2>
              <p>
                Los mecanismos y tecnologías de los vehículos eléctricos no
                requieren de lubricantes y filtros, lo que reduce el manejo de
                residuos peligrosos. Por otra parte, los sistemas de freno
                sufren menor desgaste dado que los vehículos eléctricos emplean
                un sistema de frenos regenerativos. Las baterías eléctricas,
                luego de cumplir su ciclo de vida en el vehículo (8 a 10 años)
                pueden seguir siendo usadas para aplicaciones estacionarias (10
                - 15 años) y posteriormente pueden ser recicladas.{" "}
              </p>
            </div>
            <br></br>
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                {" "}
                BENEFICIOS DE LA ELECTROMOVILIDAD
              </h2>
              <p>
                Cuando logramos transportar una determinada cantidad de
                kilómetros a personas o carga pero utilizando menos energía,
                entonces estamos haciendo eficiencia energética en el
                transporte. Un vehículo eléctrico gasta en torno a un quinto de
                la energía que un vehículo a combustión equivalente,
                principalmente porque las eficiencias de un motor de combustión
                interna no superan el 50% mientras que las de un motor eléctrico
                están por sobre un 95%. Además, los vehículos eléctricos tienen
                la ventaja de recuperar energía gracias a los sistemas de frenos
                regenerativos. Por lo tanto, para una misma distancia y una
                misma condición de carga o pasajeros transportados, esta
                eficiencia energética se traduce en un ahorro económico en la
                operación.
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>

        <div className="spacedivider"></div>

        <Grid.Row className="rowmargingsmall">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo subtilepage">MÁS SOBRE BICCI.</h2>
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
        <br></br>
        <Grid.Row className="rowmarging">
          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Grid>
              <Grid.Row>
                <div className="rowblock">
                  <div className="rowblockl">
                    <Image src={btnvehicle} className="imgbotomsr" />
                  </div>
                  <div className="rowblockr">
                    <h5 className="columnright">
                      Durante los plazos de entrega, usted y sus clientes pueden
                      saber exactamente adónde va el paquete con nuestra
                      tecnología de seguimiento en tiempo real.
                    </h5>
                  </div>
                </div>

                <br></br>
                <div className="rowblock">
                  <div className="rowblockl">
                    <Image src={btnuseradmin} className="imgbotomsl" />
                  </div>
                  <div className="rowblockr">
                    <h5 className="columnleft">
                      El reloj comienza a correr cuando crea la orden en nuestro
                      sistema y uno de nuestros riders se ocupara de poner el
                      pedido en las manos de tu cliente.
                    </h5>
                  </div>

                  <br></br>
                </div>
                <div className="rowblock">
                  <div className="rowblockl">
                    <Image src={btnacuerdo} className="imgbotomsr" />
                  </div>
                  <div className="rowblockr">
                    <h5 className="columnright">
                      Potenciamos nuestros clientes, asegurando soluciones
                      logísticas enfocadas al objetivo correcto, con nuestro
                      excelente servicio y la última tecnología..
                    </h5>
                  </div>
                  <br></br>
                </div>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column computer={8} mobile={16} tablet={6}>
            <Image src={biccidashboard} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Bannerfooter footermensaje={footermensaje} />
    </div>
  );
};

export default Pos5;
