import React, { useState } from "react";
import OpenLayersMaps from "../../../components/OpenLayersMaps/OpenLayersMaps";
import { useEffect } from "react";
import useGetDataDispachstByDate from "../../../hooks/getDataDispachstByDate";
import Moment from "moment";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { LinearProgress } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import { useSelector } from "react-redux";
const EagleEye = (props) => {
  const userUid = useSelector((store) => store.usuario);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [typeRquestReq, setTypeRquestReq] = useState(null);
  const [companyIdReq, setCompanyIdReq] = useState(null);
  const [pines, setPines] = useState(null);
  const [poligonos, setpoligonos] = useState(null);
  const [realTimeisActive, setRealTimeisActive] = React.useState(false);
  const [companyId, setcompanyId] = useState("");

  const [drivers, setDrivers] = useState(null);

  const [viewDrivers, setViewDrivers] = useState(false);
  const [viewDeliverys, setViewDeliverys] = useState(false);
  const [viewPendings, setViewPendings] = useState(false);
  const [viewPoligons, setViewPoligons] = useState(false);

  const { userdata, data } = useObtenerDatosdeCliente(props.usuarioStorage.uid);

  const { dataMerge, metricsOps } = useGetDataDispachstByDate(
    initDate,
    endDate,
    typeRquestReq,
    companyIdReq,
    userdata,
    realTimeisActive,
    setRealTimeisActive
  );

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId, null);

  useEffect(() => {
    //console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [data, userdata]);

  useEffect(() => {
    if (dataMerge) {
      createPinOrders(dataMerge);
    }
  }, [dataMerge]);

  useEffect(() => {
    if (pines && ridersDataByCompany) {
      if (viewDeliverys === true || viewPendings === true) {
        let localPines = [];

        for (let n = 0; n < dataMerge.length; n++) {
          if (viewPendings === true) {
            if (dataMerge[n].status > 0 && dataMerge[n].status < 8) {
              let elementPin = {
                lat: dataMerge[n].toAddressLocation.latitude,
                lon: dataMerge[n].toAddressLocation.longitude,
                iconUrl: "/static/images/casa.png",
                id: dataMerge[n].id,
                toAddress: dataMerge[n].toAddress,
                userPhone: dataMerge[n].userPhone,
                userEmail: dataMerge[n].userEmail,
                userName: dataMerge[n].userName,
              };

              localPines.push(elementPin);
            }
          }
          if (viewDeliverys === true) {
            if (dataMerge[n].status >= 8) {
              let elementPin = {
                lat: dataMerge[n].toAddressLocation.latitude,
                lon: dataMerge[n].toAddressLocation.longitude,
                iconUrl: "/static/images/casaDelivery.png",
                id: dataMerge[n].id,
                toAddress: dataMerge[n].toAddress,
                userPhone: dataMerge[n].userPhone,
                userEmail: dataMerge[n].userEmail,
                userName: dataMerge[n].userName,
              };
              localPines.push(elementPin);
            }
          }
        }

        setPines(localPines);
      } else {
        setPines([]);
      }

      if (viewDrivers === true && ridersDataByCompany) {
        let localPines = [];

        for (let n = 0; n < ridersDataByCompany.length; n++) {
          if (ridersDataByCompany[n].online === true) {
            if (ridersDataByCompany[n].driverLocation) {
              let elementPin = {
                lat: ridersDataByCompany[n].driverLocation.latitude,
                lon: ridersDataByCompany[n].driverLocation.longitude,
                iconUrl: "/static/images/driver.png",
                id: dataMerge[n].id,
                toAddress: dataMerge[n].toAddress,
                userPhone: dataMerge[n].userPhone,
                userEmail: dataMerge[n].userEmail,
                userName: dataMerge[n].userName,
              };
              localPines.push(elementPin);
            } else {
              console.log(ridersDataByCompany[n].driverLocation);
            }
          }
        }
        let data = pines.concat(localPines);
        setPines(data);
      }

      if (viewPoligons === true && ridersDataByCompany) {
        var DataPoligons = JSON.parse(
          localStorage.getItem("BicciToolsClientZones")
        );
        console.log(DataPoligons);
        if (DataPoligons && DataPoligons.polygonType) {
          let arrayPoligos = [];
          for (let n = 0; n < DataPoligons.polygonType.length; n++) {
            const transformedData = {
              coordinates: DataPoligons.polygonType[n].coordinates.map(
                (item) => [item.lng, item.lat]
              ),
            };
            arrayPoligos.push(transformedData);
          }

          setpoligonos(arrayPoligos);
        }
      } else {
        setpoligonos(null);
      }
    }
  }, [viewDrivers, viewDeliverys, viewPendings, viewPoligons]);

  const createPinOrders = async (dataMerge) => {
    let localPines = [];
    for (let n = 0; n < dataMerge.length; n++) {
      let elementPin = {
        lat: dataMerge[n].toAddressLocation.latitude,
        lon: dataMerge[n].toAddressLocation.longitude,
        iconUrl: "/static/images/asset/casa.png",
        id: dataMerge[n].id,
        toAddress: dataMerge[n].toAddress,
        userPhone: dataMerge[n].userPhone,
        userEmail: dataMerge[n].userEmail,
      };
      localPines.push(elementPin);
    }
    setPines(localPines);
  };

  const handleChange = (event) => {
    setDrivers(event.target.checked);
  };

  useEffect(() => {
    console.log(pines);
  }, [pines]);

  return pines ? (
    <div>
      <div>
        <AppBar position="static">
          <Toolbar sx={{ background: "#591e8f" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, background: "#591e8f" }}
            >
              Opciones
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewDrivers}
                    onChange={() => setViewDrivers(!viewDrivers)}
                    aria-label="login switch"
                  />
                }
                label={viewDrivers ? "Riders" : "Riders"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewPendings}
                    onChange={() => setViewPendings(!viewPendings)}
                    aria-label="login switch"
                  />
                }
                label={viewPendings ? "Pendientes" : "Pendientes"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewDeliverys}
                    onChange={() => setViewDeliverys(!viewDeliverys)}
                    aria-label="login switch"
                  />
                }
                label={viewDeliverys ? "Entregados" : "Entregados"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewPoligons}
                    onChange={() => setViewPoligons(!viewPoligons)}
                    aria-label="login switch"
                  />
                }
                label={viewPoligons ? "Poligonos" : "Poligonos"}
              />
            </FormGroup>
            {/*             
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={drivers}
                    onChange={handleChange}
                    aria-label="login switch"
                  />
                }
                label={drivers ? "Rutas" : "Rutas"}
              />
            </FormGroup> 
            */}
          </Toolbar>
        </AppBar>
      </div>
      <div className="map-container">
        <OpenLayersMaps pines={pines} poligonos={poligonos}></OpenLayersMaps>
      </div>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default EagleEye;
