import React, { useState, useMemo, useRef } from "react";
import useGetDataVehiclesByCompany from "./getOrdersDataContextByCompany";
import useGlobalConfig from "./globalConfig";
import useGetDataRiderByCompany from "./getDataRiderByCompany";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { format } from "date-fns";
import { retiredPodArray } from "../utils/objects/retiredPodArray";
import useGetVehiclesNames from "./getVehiclesNames";

function useGetDataDispachstByDate(
  initDate,
  endDate,
  typeRquestReq,
  companyIdReq,
  userdata,
  realTimeisActive,
  setRealTimeisActive
) {
  var ordersForAccions = [];
  var dataOrdersForAccions = [];
  var listadeItemsNoMach = [];
  const { falabellaExcepcions, retiredTestsArray } = useGlobalConfig();

  const companyId = useMemo(() => {
    // console.log("userdata", userdata);
    if (userdata) {
      if (userdata.companyCollaborator.includes("falabella")) {
        return `${process.env.REACT_APP_BXTOKETN}`;
      }
      return userdata.userCompanyID;
    }
    return "";
  }, [userdata]);

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  const { VehiclesNames } = useGetVehiclesNames();

  const [falabellaOrders, setFalabellaOrders] = useState([]);

  const [BicciOrders, setBicciOrders] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [userOrders, setUserOrders] = React.useState(null);

  const [progressValue, setProgressValue] = React.useState(0);

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    React.useState(false);

  const [menssajeAdmin, setMenssajeAdmin] = React.useState(null);

  const [render, setRender] = useState(true);

  const [path, setPath] = React.useState([]);

  const [containerHistory, setContainerHistory] = React.useState({});

  const [metricsOps, setMetricsOps] = React.useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [showPopUp, setShowPopUp] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [origenGeoCode, setOrigenGeoCode] = React.useState(null);
  const [adressOrigin, setAdressOrigin] = React.useState(null);
  const [listIdstoSelct, setListIdstoSelct] = React.useState("");
  const [SelecteDestiny, setSelecteDestiny] = React.useState(null);
  const [destinyGeoCode, setDestinyGeoCode] = React.useState(null);
  const [adressDestiny, setAdressDestiny] = React.useState(null);
  const [CheckedChangeOE, setCheckedChangeOE] = React.useState(false);
  const [ordersHistoryGetProgres, setOrdersHistoryGetProgres] =
    React.useState(0);

  const [typeRequets, setTypeRequets] = React.useState({
    typeRequetsCreate: false,
    typeRequetsUpdate: true,
  });

  const [resetCicle, setResetCicle] = React.useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [bicciOrdersExcepData, setBicciOrdersExcepData] = useState([]);

  const [typeRquest, setTypeRquest] = React.useState(false);

  const isMounted = useRef(true);

  const toggleShowRefres = () => setRender(!render);

  const [cantListItems, setcantListItems] = React.useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispachtFalabella(initDate, endDate, typeRequets, companyId);
      findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);
    }
  }, [companyId, initDate, endDate]);

  React.useEffect(() => {}, [itemsDataOrdersForAccions]);

  React.useEffect(() => {
    if (
      BicciOrders.length > 0 ||
      falabellaOrders.length > 0 ||
      bicciOrdersExcepData.length > 0
    ) {
      setMenssajeAdmin(<span className="alert alert-info">Buscando... </span>);

      BiccidataMerge();
    }
  }, [BicciOrders, falabellaOrders, bicciOrdersExcepData]);

  React.useEffect(() => {
    isMounted.current = true;
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando Ordenes... </span>
    );
    findOrdesrDispachtFalabella(initDate, endDate, typeRquest, companyId);
    findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);
    return () => {
      isMounted.current = false;
    };
  }, [initDate, endDate, CheckedChangeOE, typeRequets]);

  React.useEffect(() => {
    if (dataMerge) {
      setMenssajeAdmin(
        <span className="alert alert-info">Buscando Ordenes... </span>
      );
      getResumenOps();
    }
  }, [dataMerge]);

  React.useEffect(() => {
    let interval;
    if (realTimeisActive === true) {
      //// console.log(" React.useEffect 3 ");
      interval = setInterval(() => {
        resetCicle.currentRequest += 1;
        setCheckedChangeOE(false);
        findOrdesrDispachtFalabella(initDate, endDate, typeRquest, companyId);
        findOrdesrDispachtBicci(initDate, endDate, typeRequets, companyId);

        if (resetCicle.maxRequest === resetCicle.currentRequest) {
          setRender(!render);
        }
        if (resetCicle.desactiveRealtime === resetCicle.currentRequest) {
          setRealTimeisActive(!realTimeisActive);
          setRender(!render);
        }
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [realTimeisActive]);

  React.useEffect(() => {
    if (listIdstoSelct) {
      selecListItames(listIdstoSelct);
    }
  }, [listIdstoSelct]);

  React.useEffect(() => {}, [render]);

  const findOrdesrDispachtFalabella = async (
    initDate,
    endDate,
    typeRquest,
    companyId
  ) => {
    //// console.log("findOrdesrDispacht Falabella");
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Falabella </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "updatedAt";
    }
    setProgressValue(55);

    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );
    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }
    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate());
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    function formatDate(fecha) {
      var res = new Date(fecha);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangFinal = sumarDos(e);
    var rangInitial = formatDate(i);
    const dataOrder = {
      data: "",
      get dataEbiex() {
        return this.data;
      },
      set dataEbiex(data) {
        this.data = data;
      },
    };
    dataOrder.data = [];
    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    async function callExceptions() {
      //// console.log("findOrdesrDispacht Exceptions");
      setProgressValue(45);
      try {
        const allOrdersRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
        const querySnapshot = await getDocs(q);

        const dataOrdesHasExcepciones = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dataOrdesHasExcepciones.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );
        let filtrarSolucionados = dataOrdesHasExcepciones.filter(
          (item) =>
            (item.falabella_status !== "1029") &
            (item.falabella_status !== "1046") &
            (item.falabella_status !== "1023") &
            (item.falabella_status !== "1081") &
            (item.falabella_status !== "1007") &
            (item.falabella_status !== "99")
        );

        let dataOrdesfilterHasExcepciones = filtrarSolucionados.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        let clearRang = dataOrder.data;
        const arrayId = clearRang.map((obj) => obj.id);

        let clearDataOrdesfilterHasExcepciones =
          dataOrdesfilterHasExcepciones.filter(
            (item) => !arrayId.includes(item.id.toString())
          );

        if (clearDataOrdesfilterHasExcepciones.length > 0) {
          //// console.log(clearDataOrdesfilterHasExcepciones);
          let arryExcepcion = [];
          for (let i = 0; i < clearDataOrdesfilterHasExcepciones.length; i++) {
            arryExcepcion.push("F" + clearDataOrdesfilterHasExcepciones[i].id);
          }
          // // console.log(arryExcepcion);
          try {
            const ordersCollectionRef = collection(
              db,
              process.env.REACT_APP_COL_USERS_ORDERS
            );
            const querySnapshot = await getDocs(
              query(ordersCollectionRef, where("id", "in", arryExcepcion))
            );
            const orders = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            //// console.log("orders");
            //// console.log(orders);

            setBicciOrdersExcepData(orders); // En test
          } catch (error) {}
          if (CheckedChangeOE === false) {
            // // console.log("Mostrar incidencias activas");
            const resultoFind = dataOrdesfilterHasExcepciones.concat(
              dataOrder.data
            );

            //setResumenTable(resultoFind);
            setFalabellaOrders(resultoFind);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }

          if (CheckedChangeOE !== false) {
            //// console.log("Ocultar incidencias activas");
            //setResumenTable(dataOrder.data);
            setFalabellaOrders(dataOrder.data);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }
        } else {
          //setResumenTable(dataOrder.data);
          setFalabellaOrders(dataOrder.data);
          setProgressValue(100);
          setDataMerge(dataOrder.data); // QA
        }
        //await BiccidataMerge();
      } catch (error) {}

      toggleShowRefres();
    }

    async function callNormal() {
      try {
        const q = query(
          allOrdersRef,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", endRang)
        );
        const querySnapshot = await getDocs(q);
        var dataOrdes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        //   //////// console.log(dataOrdes)

        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //// console.log(error);
      }
    }

    async function callIgual() {
      try {
        const dataallOrdersfind5 = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(
          dataallOrdersfind5,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", rangFinal)
        );
        const querySnapshot5 = await getDocs(q);
        var dataOrdes = querySnapshot5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        ////// console.log(dataOrdes)
        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );
        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //// console.log(error);
      }
    }
  };

  const findOrdesrDispachtBicci = async (
    initDate,
    endDate,
    typeRequets,
    companyId
  ) => {
    //// console.log("findOrdesrDispacht Bicci");
    setProgressValue(65);
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Bicci </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "createdAt";
    }

    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate() - 5);
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(i);
    var rangFinal = sumarDos(e);

    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    setMenssajeAdmin(
      <span className="alert alert-info">
        Creando resumenes Riders, Vehiculos y Ordenes{" "}
      </span>
    );

    async function callNormal() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", i),
          where(temptypeRquestCall, "<=", endRang)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (bicciOrdersExcepData.length > 0) {
          let mergedate = bicciOrdersExcepData.concat(dataOrdesBicci);
          setBicciOrders(mergedate);
        } else {
          setBicciOrders(dataOrdesBicci);
        }
      } catch (error) {
        //// console.log(error);
      }
    }

    async function callIgual() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", rangInit),
          where(temptypeRquestCall, "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {
        //// console.log(error);
      }
    }
  };

  const BiccidataMerge = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Uniendo data... </span>
    );
    var newdataMere = [];
    var DataOrders = BicciOrders;
    var DataOrdersExcep = bicciOrdersExcepData;

    async function initMerge(newdataMere) {
      const uniqueMap = new Map();

      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        }

        return Array.from(uniqueMap.values());
      }

      var DataMerge = await removeDuplicatesById(falabellaOrders);

      for (let i = 0; i < DataMerge.length; i++) {
        for (let n = 0; n < newdataMere.length; n++) {
          let socf = "F" + DataMerge[i].id;

          if (socf === newdataMere[n].id) {
            // ////// console.log(falabellaOrders[i])

            DataMerge[i].placeName = newdataMere[n].placeName;
            DataMerge[i].driverName = newdataMere[n].driverName;
            DataMerge[i].driverAvatar = newdataMere[n].driverAvatar;
            DataMerge[i].driverID = newdataMere[n].driverID;
            DataMerge[i].userNameReceptor = newdataMere[n].userNameReceptor;
            DataMerge[i].userDniReceptor = newdataMere[n].userDniReceptor;
            DataMerge[i].pics = newdataMere[n].pics;
            DataMerge[i].toAddress = newdataMere[n].toAddress;
            DataMerge[i].toAddressLocation = newdataMere[n].toAddressLocation;
            DataMerge[i].userName = newdataMere[n].userName;
            DataMerge[i].userPhone = newdataMere[n].userPhone;
            DataMerge[i].status = newdataMere[n].status;
            DataMerge[i].upDatedAtText = format(
              DataMerge[i].updatedAt.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
            DataMerge[i].dateCreateText = format(
              DataMerge[i].date.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
          }
        }
      }

      setDataMerge(DataMerge);
      setResumenTable(DataMerge);
    }
    //// console.log("newdataMere");
    //// console.log(falabellaOrders);
    //// console.log(BicciOrders);
    //// console.log(bicciOrdersExcepData);
    if (DataOrders.length > 0 || DataOrdersExcep.length > 0) {
      const dataOrdersIdSet = new Set(DataOrders.map((item) => item.id));
      const filteredDataOrdersExcep = DataOrdersExcep.filter(
        (item) => !dataOrdersIdSet.has(item.id)
      );
      newdataMere = DataOrders.concat(filteredDataOrdersExcep);
    }
    await initMerge(newdataMere);
  };

  const getResumenOps = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );
    let listRiders = [];
    let comunasDelivery = [];
    let listStores = [];
    // console.log("dataMerge", dataMerge);
    // console.log("ridersDataByCompany", ridersDataByCompany);
    try {
      if (dataMerge && dataMerge.length > 0) {
        // Populate listRiders and comunasDelivery arrays
        for (let n = 0; n < dataMerge.length; n++) {
          if (!listRiders.includes(dataMerge[n].driverID)) {
            listRiders.push(dataMerge[n].driverID);
          }
          if (!comunasDelivery.includes(dataMerge[n].commune2)) {
            comunasDelivery.push(dataMerge[n].commune2);
          }
        }

        // Populate listStores array
        const localSet = new Set();
        dataMerge.forEach((order) => {
          localSet.add(order.local_provider);
        });
        listStores = Array.from(localSet);

        // Function to get orders by local provider
        async function getOrdersByLocal(data, listStores) {
          setProgressValue(55);
          const orders = data.filter(
            (order) => order.falabella_status === "99" && order.status !== "99"
          );

          const listStoreOriging = {};
          orders.forEach((order) => {
            const local = order.local_provider;
            if (!listStoreOriging[local]) {
              setProgressValue(56);
              listStoreOriging[local] = {
                delivered: 0,
                withIssues: 0,
                localName: listStores.includes(local) ? local : "",
              };
            }
            if (order.status === "99") {
              setProgressValue(57);
              listStoreOriging[local].withIssues++;
            } else {
              listStoreOriging[local].delivered++;
            }
          });

          return Object.values(listStoreOriging);
        }

        let listStoreOriging = await getOrdersByLocal(dataMerge, listStores);

        let listRidersJsonString = {};

        let conuters = {};
        let conutersDelivery = {};
        let conutersIssures = {};

        let countersListOrders = {};

        let ordersContent = {};
        let listOrderContent = {};

        let countersIssuresListOrders = {};

        let conterComunes = {};
        let vehicleDetailsID = {};
        let vehicleDetailsType = {};

        const riderMap = {};
        ridersDataByCompany.forEach((rider) => {
          riderMap[rider.id] = rider.vehicleID;
        });

        const vehicleNameMap = {};
        VehiclesNames.forEach((vehicle) => {
          vehicleNameMap[vehicle.id] = vehicle.name;
        });

        const vehicleRidersMap = {};
        Object.keys(riderMap).forEach((driverID) => {
          const vehicleID = riderMap[driverID];

          const rider = ridersDataByCompany.find(
            (rider) => rider.id === driverID
          );
          if (rider && vehicleNameMap[vehicleID]) {
            vehicleRidersMap[rider.id] = vehicleNameMap[vehicleID];
          }
        });

        if (listRiders.length > 0 && VehiclesNames) {
          for (let m = 0; m < listRiders.length; m++) {
            ordersContent = [];
            for (let n = 0; n < dataMerge.length; n++) {
              if (dataMerge[n].driverID === listRiders[m]) {
                ordersContent.push(dataMerge[n]);
              }
            }
            listOrderContent[listRiders[m]] = ordersContent;

            conuters[listRiders[m]] = { value: ordersContent.length };

            let deliveryCount = 0;
            let issueCount = 0;
            let countersListOrdersContent = [];
            let countersListIssuresOrdersContent = [];

            ordersContent.forEach((order) => {
              countersListOrdersContent.push(order.id);

              if (order.falabella_status === "99") {
                deliveryCount++;
              } else {
                issueCount++;
                countersListIssuresOrdersContent.push(order.id);
              }

              if (!conterComunes[order.driverID]) {
                conterComunes[order.driverID] = [];
              }

              let comunaFound = false;
              for (let x = 0; x < comunasDelivery.length; x++) {
                if (comunasDelivery[x] === order.commune2) {
                  let comunaExists = conterComunes[order.driverID].some(
                    (comuna) => comuna[order.commune2]
                  );
                  if (!comunaExists) {
                    let comunaObj = {};
                    comunaObj[order.commune2] = 1;
                    conterComunes[order.driverID].push(comunaObj);
                  } else {
                    let comunaObj = conterComunes[order.driverID].find(
                      (comuna) => comuna[order.commune2]
                    );
                    comunaObj[order.commune2]++;
                  }
                  comunaFound = true;
                  break;
                }
              }

              if (!comunaFound) {
                let comunaObj = {};
                comunaObj[order.commune2] = 1;
                conterComunes[order.driverID].push(comunaObj);
              }
            });

            conutersDelivery[listRiders[m]] = { value: deliveryCount };
            conutersIssures[listRiders[m]] = { value: issueCount };

            countersListOrders[listRiders[m]] = {
              data: countersListOrdersContent,
            };
            countersIssuresListOrders[listRiders[m]] = {
              data: countersListIssuresOrdersContent,
            };

            vehicleDetailsID[listRiders[m]] = {
              value: vehicleRidersMap[listRiders[m]] || "No registrado",
            };
            vehicleDetailsType[listRiders[m]] = {
              value: vehicleRidersMap[listRiders[m]] || "No registrado",
            };

            listRidersJsonString[listRiders[m]] = {
              driverName: ridersDataByCompany.find(
                (rider) => rider.id === listRiders[m]
              )?.name,
              driverID: listRiders[m],
              email: ridersDataByCompany.find(
                (rider) => rider.id === listRiders[m]
              )?.email,
              driverAvatar: ridersDataByCompany.find(
                (rider) => rider.id === listRiders[m]
              )?.avatar,
              asignados: conuters[listRiders[m]].value,
              delivery: conutersDelivery[listRiders[m]].value,
              issures: conutersIssures[listRiders[m]].value,
              vehicleDetailsType: vehicleDetailsType[listRiders[m]].value,
              vehicleDetailsID: vehicleDetailsID[listRiders[m]].value,
              locality: conterComunes[listRiders[m]],
              countersListOrders: countersListOrders[listRiders[m]],
              countersIssuresListOrders:
                countersIssuresListOrders[listRiders[m]],
              listOrdersData: {
                data: listOrderContent[listRiders[m]],
              },
            };
          }

          let arraylistRidersJsonString = Object.values(listRidersJsonString);

          setMetricsOps(arraylistRidersJsonString);
          setMetricsOpsStores(listStoreOriging);
          setProgressValue(100);
          setMenssajeAdmin(null);
        }
      } else {
        setProgressValue(100);
        setMetricsOps([]);
        setMetricsOpsStores([]);
        setMenssajeAdmin(
          <span className="alert alert-danger">
            "NO se encuentran envíos para la fecha Solicitada"
          </span>
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const setResumenTable = (dataOrdes) => {
    if (dataOrdes && dataOrdes.length === 0) {
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
    } else {
      setMenssajeAdmin(null);
    }

    let Creadas = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1058" || item.falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.falabella_status === "99"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1060" || item.falabella_status === "1061"
    );
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.falabella_status)
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  async function selecListItames(listIdstoSelct) {
    listadeItemsNoMach = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    listIdstoSelct = listIdstoSelct.trim();
    var listadeItems2 = listIdstoSelct.split(/[,\s]+/);

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems2.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems2[i] === "F" + tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems2.length; index++) {
      if (!ordersForAccions.includes(listadeItems2[index])) {
        listadeItemsNoMach.push(listadeItems2[index]);
      }
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  }

  return {
    falabellaOrders,
    BicciOrders,
    dataMerge,
    userOrders,
    progressValue,
    itemsDataOrdersForAccions,
    menssajeAdmin,
    initDate,
    endDate,
    render,
    path,
    realTimeisActive,
    containerHistory,
    metricsOps,
    metricsOpsStores,
    showPopUp,
    selected,
    origenGeoCode,
    adressOrigin,
    listIdstoSelct,
    SelecteDestiny,
    destinyGeoCode,
    adressDestiny,
    CheckedChangeOE,
    ordersHistoryGetProgres,
  };
}

export default useGetDataDispachstByDate;
