import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const BulkUpdateComponent = ({ columns, handleBulkUpdate }) => {
  const [selectedColumn, setSelectedColumn] = useState(""); // Columna seleccionada
  const [inputValue, setInputValue] = useState(""); // Valor a ingresar
  const [applyToEmptyOnly, setApplyToEmptyOnly] = useState(false); // Aplicar solo a campos vacíos

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value); // Seleccionar la columna
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Guardar el valor del input
  };

  const handleApplyToEmptyChange = (event) => {
    setApplyToEmptyOnly(event.target.checked); // Cambiar la opción de aplicar solo a vacíos
  };

  const handleApplyBulkUpdate = () => {
    if (selectedColumn && inputValue !== "") {
      // Llamar la función de actualización masiva
      handleBulkUpdate(selectedColumn, inputValue, applyToEmptyOnly);
      setSelectedColumn("");
      setInputValue("");
    } else {
      console.log("Seleccione una columna y un valor válido para aplicar.");
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* Selector de columna */}
      <FormControl sx={{ minWidth: 200, mr: 2 }}>
        <InputLabel id="column-select-label">Seleccionar Columna</InputLabel>
        <Select
          labelId="column-select-label"
          id="column-select"
          value={selectedColumn}
          onChange={handleColumnSelect}
        >
          {columns.map((col) => (
            <MenuItem key={col.field} value={col.field}>
              {col.headerName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Input para valor a ingresar */}
      <TextField
        sx={{ mr: 2 }}
        label="Valor a Cargar"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      {/* Check para aplicar solo a campos vacíos */}
      <FormControlLabel
        control={
          <Checkbox
            checked={applyToEmptyOnly}
            onChange={handleApplyToEmptyChange}
          />
        }
        label="Aplicar solo a campos vacíos"
      />
      {/* Botón para realizar relleno automático */}
      {selectedColumn && inputValue ? (
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mr: 2 }}
          onClick={handleApplyBulkUpdate}
        >
          Autorellenar {selectedColumn} con {inputValue}
        </Button>
      ) : null}
    </Box>
  );
};

export default BulkUpdateComponent;
