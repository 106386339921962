import { Box } from "@mui/material";
import React, { useRef, useEffect } from "react";
import Quagga from "quagga";

const Camera = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const photoRef = useRef(null);

  useEffect(() => {
    const constraints = {
      video: {
        facingMode: "environment", // Solicita la cámara trasera
      },
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            startBarcodeReader(); // Comienza a leer códigos de barras
          }
        })
        .catch((error) => {
          console.error("Error accessing the camera: ", error);
        });
    }
  }, []);

  const startBarcodeReader = () => {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: videoRef.current,
        },
        decoder: {
          readers: ["code_128_reader"], // Tipo de códigos de barras que se van a leer (puedes añadir más si lo deseas)
        },
      },
      (err) => {
        if (err) {
          console.error("Error initializing Quagga: ", err);
          return;
        }
        Quagga.start();
      }
    );

    Quagga.onDetected((result) => {
      console.log("Barcode detected: ", result.codeResult.code);
      // Aquí puedes manejar el resultado del código de barras como desees
    });
  };

  const capturePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    context.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    photoRef.current.src = canvasRef.current.toDataURL("image/png");
    console.log(photoRef.current.src);
  };

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline></video>
      <div>
        <button onClick={capturePhoto}>Capture Photo</button>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        {photoRef ? <img ref={photoRef} alt="Captured" /> : null}
      </div>
    </div>
  );
};

export default Camera;
