import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DvrIcon from "@mui/icons-material/Dvr";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography, Paper } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const SwipeableDrawerControlOrders = (props) => {
  // if (props && props.dataordesbiccibyrider && props.orderslist) {
  //   props.dataordesbiccibyrider.sort((a, b) => {
  //     const indexA = props.orderslist.indexOf(a.orderPosition[0]);
  //     const indexB = props.orderslist.indexOf(b.orderPosition[0]);
  //     return indexA - indexB;
  //   });

  //   // console.log(props.dataOrdesBicciByRider);
  //   // for (let i = 0; i < props.dataOrdesBicciByRider.length; i++) {
  //   //   const order = props.dataOrdesBicciByRider[i];
  //   //   order.orderPosition = [i];
  //   // }
  // }

  const [viewDirectiones, setViewDirectiones] = React.useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const toggleVierwDireccionDrawer = () => {
    setViewDirectiones(!viewDirectiones);
  };

  const list = (anchor) => (
    <div>
      <Box
        sx={{ width: 350, zIndex: 99 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className="m-2">
          <div className="title-container-control-ruta-rithg m-2">
            <IconButton
              sx={{ color: "#F40606" }}
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              <CancelIcon />
            </IconButton>
          </div>
          <div className="title-container-control-ruta-letf m-2">
            Pendientes en ruta
          </div>

          <div className="text-container-control-ruta-letf m-2">
            {props.dataordesbiccibyrider ? (
              <>
                <>{props.dataordesbiccibyrider.length} Paradas</>
                <IconButton
                  sx={{ color: "#4267b2", fontSize: "10px" }}
                  onClick={toggleVierwDireccionDrawer}
                  size="small"
                >
                  {viewDirectiones ? (
                    <>
                      <VisibilityOffIcon /> Ocultar direcciones
                    </>
                  ) : (
                    <>
                      <RemoveRedEyeIcon /> Ver direcciones
                    </>
                  )}
                </IconButton>
              </>
            ) : null}
          </div>
          <div>
            <Paper
              elevation={3}
              sx={{
                color: "#f2f2f2",
                backgroundColor: "#00cdf6",
                margin: "1px",
                borderRadius: "14px",
                width: "100%",
                textAlign: "left",
                boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "flex-start",
                padding: "8px",
              }}
            >
              <LocationOnIcon></LocationOnIcon>
              <Typography
                sx={{ fontWeight: 600, marginLeft: "5px" }}
                component="div"
              >
                Origen: {props.originadress}
              </Typography>
              <IconButton
                sx={{ color: "#f2f2f2" }}
                onClick={() => props.setEdithOpcionOrigin(true)}
                size="small"
              ></IconButton>
            </Paper>

            {props.dataordesbiccibyrider ? (
              <>
                {" "}
                {props.dataordesbiccibyrider.map((item, index) => {
                  if (item.falabella_status !== "99") {
                    return (
                      <Paper
                        key={item.id}
                        elevation={3}
                        sx={{
                          color: "#f2f2f2",
                          backgroundColor: "#00cdf6",
                          margin: "1px",
                          borderRadius: "14px",
                          width: "100%",

                          boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",

                          alignItems: "center",
                          display: "inline-flex",
                          justifyContent: "flex-start",
                          padding: "8px",
                        }}
                      >
                        {index + 1}
                        <LocationOnIcon></LocationOnIcon>
                        <Typography
                          sx={{ fontWeight: 600, marginLeft: "5px" }}
                          component="div"
                        >
                          {item.id}
                          <>
                            {viewDirectiones ? (
                              <>
                                <hr></hr>
                                {item.toAddress}
                                <div>
                                  {item.observationReceptor ? (
                                    <div>
                                      <hr></hr>
                                      <div className="color-blue">
                                        Notas de Conducor
                                      </div>
                                      <div>{item.observationReceptor}</div>
                                    </div>
                                  ) : null}
                                  {item.observationAdmin ? (
                                    <div>
                                      <hr></hr>
                                      <div className="color-blue">
                                        Notas de Pedido:
                                      </div>
                                      {item.observationAdmin.map(
                                        (item, index) => (
                                          <div
                                            key={index}
                                            className="color-blue"
                                          >
                                            <div>{item}</div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : null}
                                  <hr></hr>
                                </div>
                              </>
                            ) : null}
                          </>
                        </Typography>

                        {/* <IconButton
                        sx={{ color: "#f2f2f2" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton> */}
                      </Paper>
                    );
                  }
                })}
              </>
            ) : null}

            <Paper
              elevation={3}
              sx={{
                color: "#f2f2f2",
                backgroundColor: "#00cdf6",
                margin: "1px",
                borderRadius: "14px",
                width: "100%",
                textAlign: "left",
                boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "flex-start",
                padding: "8px",
              }}
            >
              <LocationOnIcon></LocationOnIcon>
              <Typography
                sx={{ fontWeight: 600, marginLeft: "5px" }}
                component="div"
              >
                Destino: {props.destinationadress}
              </Typography>
              <IconButton
                sx={{ color: "#f2f2f2" }}
                onClick={() => {
                  props.setEdithOpcionDestini(true);
                }}
                size="small"
              ></IconButton>
            </Paper>
          </div>

          {props.dataordersexcepcions ? (
            <>
              <div className="title-container-control-ruta-letf m-2">
                Ordenes con incidencias
              </div>
              <div>
                Si las tienes en tu poder escanealas para que se agregren a la
                ruta, si no comunacate con soporte{" "}
              </div>
              <div className="text-container-control-ruta-letf m-2">
                {props.dataordesbiccibyrider ? (
                  <>{props.dataordersexcepcions.length} pendientes</>
                ) : null}
              </div>{" "}
              {props.dataordersexcepcions.map((item, index) => {
                let colorMarket;
                var validateStatus = parseInt(item.falabella_status);
                if (validateStatus === 99) {
                  colorMarket = "#2CC04B";
                }
                if (validateStatus !== 99) {
                  colorMarket = "#F12511";
                }
                return (
                  <Paper
                    key={item.id}
                    elevation={3}
                    sx={{
                      color: "#f2f2f2",
                      backgroundColor: colorMarket,
                      margin: "1px",
                      marginBottom: "3px",
                      borderRadius: "14px",
                      width: "100%",
                      boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                      alignItems: "rithg",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                      padding: "8px",
                    }}
                  >
                    {`P${index + 1} - `}
                    <LocationOnIcon></LocationOnIcon>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginLeft: "5px",
                        justifyContent: "flex-start",
                      }}
                      component="div"
                    >
                      {item.id}
                    </Typography>
                    {/* <IconButton
                        sx={{ color: "#f2f2f2" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton> */}
                  </Paper>
                );
              })}
            </>
          ) : null}

          {props.dataOrderDeliverySusses ? (
            <>
              {" "}
              <div className="title-container-control-ruta-letf m-2">
                Ordenes Entregadas
              </div>
              <div className="text-container-control-ruta-letf m-2">
                {props.dataOrderDeliverySusses ? (
                  <>{props.dataOrderDeliverySusses.length} Finalizadas</>
                ) : null}
              </div>{" "}
              {props.dataOrderDeliverySusses.map((item, index) => {
                let colorMarket;
                var validateStatus = parseInt(item.falabella_status);
                if (validateStatus === 99) {
                  colorMarket = "#2CC04B";
                }

                return (
                  <Paper
                    key={item.id}
                    elevation={3}
                    sx={{
                      color: "#f2f2f2",
                      backgroundColor: colorMarket,
                      margin: "1px",
                      marginBottom: "3px",
                      borderRadius: "14px",
                      width: "100%",
                      boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                      alignItems: "rithg",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                      padding: "8px",
                    }}
                  >
                    {`D${index + 1} - `}
                    <LocationOnIcon></LocationOnIcon>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginLeft: "5px",
                        justifyContent: "flex-start",
                      }}
                      component="div"
                    >
                      {item.id}
                    </Typography>
                    {/* <IconButton
                        sx={{ color: "#f2f2f2" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton> */}
                  </Paper>
                );
              })}
            </>
          ) : null}
        </div>
      </Box>
    </div>
  );

  return (
    <div className="btn-control-route-container">
      <React.Fragment key={"right"}>
        {/* <Button
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: 15,
            borderRadius: "15px",
          }}
          className="btn-control"
          onClick={toggleDrawer("right", true)}
        >
          <AssignmentIcon fontSize="large" style={{ color: "#741ab0" }} />
        </Button> */}
        <Button
          className="btn-control-icon-button"
          sx={{
            color: "#00cdf6",
            backgroundColor: "#00cdf6",
          }}
          variant="contained"
          onClick={toggleDrawer("right", true)}
        >
          <AssignmentIcon fontSize="large" style={{ color: "#f8f8f8" }} />
        </Button>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default SwipeableDrawerControlOrders;
