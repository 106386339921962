import { Box } from "@mui/material";
import React from "react";

const PageMantenimiento = () => {
  return (
    <Box sx={{ height: "100vh" }} className="bodycontainerbx">
      <div className="mantenimiento">
        <img id="imgmante" src="/static/images/mantenimiento.jpg" alt=""></img>
      </div>
    </Box>
  );
};

export default PageMantenimiento;
