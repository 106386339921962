import React, { useMemo } from "react";
//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";

import { Image } from "semantic-ui-react";
import { User as UserIcon } from "../../../icons/User";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BusinessIcon from "@mui/icons-material/Business";
import useGlobalConfig from "../../../hooks/globalConfig";
import useTitle from "../../Seo/useTitle";
import DashboardSidebarContact from "./DashboardSidebarContact";

export const DashboardSidebarCliente = (props) => {
  // console.log(props.patuserRol)
  const { img5 } = useGlobalConfig();

  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  var actualUserRoll = props.patuserRol;

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="sidebar-body">
      <div id="brandsLogo">
        <Image className="brands brands-dash" src={img5} />
      </div>
      <h3 className="btn-sidebarLink alignItemscentertitle">
        Bienvenido a Bicci
      </h3>

      <hr></hr>

      <NavLink
        to="/dashboard/tablero"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "tablero"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <ListAltIcon fontSize="medium" />
          <span className="btn-title">Actividad</span>
        </div>
      </NavLink>

      <hr></hr>

      <NavLink
        to="/dashboard/mi-cuenta"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "mi-cuenta"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <UserIcon fontSize="medium" />
          <span className="btn-title">Mi Cuenta</span>
        </div>
      </NavLink>

      <hr></hr>

      <NavLink
        to="/dashboard/companies"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "companies"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <BusinessIcon fontSize="medium" />
          <span className="btn-title">Empresa</span>
        </div>
      </NavLink>

      <hr></hr>

      {/* <NavLink
        to="/dashboard/direcciones"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "direcciones"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <AddLocationAltIcon fontSize="medium" />
          <span className="btn-title">Direcciones</span>
        </div>
      </NavLink> */}
      <hr></hr>
      {/* <hr></hr>


    <NavLink to="/dashboard/envios-express"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "envios-express" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><DeliveryDiningIcon fontSize="medium" /><span className='btn-title'>Envío Express</span></div>
    </NavLink>  */}

      {/* <hr></hr>

    <NavLink to="/dashboard/maps"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "maps" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><MapIcon fontSize="medium" /><span className='btn-title'>Mapas</span></div>
    </NavLink> */}

      {/* <NavLink to="/dashboard/usuarios" className="btn-sidebarLink" >
    <div className={splitLocation[2] === "usuarios" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><UserAddIcon fontSize="medium" /><span className='btn-title'>Agregar Usuario</span></div>
    </NavLink>  */}

      {/* <NavLink to="/dashboard/stores"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "stores" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><StoreIcon fontSize="medium" /><span className='btn-title'>Sucursales</span></div>
    </NavLink> */}

      <hr></hr>

      {/* <NavLink to="/dashboard/administrators"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "administrators" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><UsersIcon fontSize="medium" /><span className='btn-title'>Administradores</span></div>
    </NavLink> */}

      {/* <NavLink to="/dashboard/users"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "users" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><UsersIcon fontSize="medium" /><span className='btn-title'>Usuarios</span></div>
    </NavLink> */}

      {/* 
    <hr className='sidebar-hr'></hr> */}

      {/* <NavLink to="/dashboard/ebiex-drivers"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "ebiex-drivers" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><DirectionsBikeIcon fontSize="medium" /><span className='btn-title'>Conductores</span></div>
    </NavLink> */}

      {/* <hr></hr>


    <NavLink to="/dashboard/dispatch"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "dispatch" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><LocalShippingIcon fontSize="medium" /><span className='btn-title'>Desapachos</span></div>
    </NavLink>

    <hr></hr> */}

      {/* <NavLink to="/dashboard/guides"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "guides" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><ContentPasteGoIcon fontSize="medium" /><span className='btn-title'>Guias</span></div>
    </NavLink> */}

      <hr></hr>

      {/* <NavLink to="/dashboard/routes"  className="btn-sidebarLink"
         
        >
    <div className={splitLocation[2] === "routes" ? "btn btn-lg btn-block btn-sidebar sidebarLink-active" : "btn btn-lg btn-block btn-sidebar"}><ForkLeftIcon fontSize="medium" /><span className='btn-title'>Rutas</span></div>
    </NavLink> */}

      <hr className="sidebar-hr"></hr>

      <NavLink
        to="/dashboard/servicios"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "servicios"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <AppShortcutIcon fontSize="medium" />
          <span className="btn-title">Servicios</span>
        </div>
      </NavLink>

      <DashboardSidebarContact props={props}></DashboardSidebarContact>
    </div>
  );
};
