import React, { useState } from "react";

//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";
import { Box, Button, ButtonBase, Chip, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Image } from "semantic-ui-react";

import { User as UserIcon } from "../../../icons/User";
import { UserAdd as UserAddIcon } from "../../../icons/UserAdd";
import { Users as UsersIcon } from "../../../icons/Users";
import { XCircle as XCircleIcon } from "../../../icons/XCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import PaymentIcon from "@mui/icons-material/Payment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import useGlobalConfig from "../../../hooks/globalConfig";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { GridSearchIcon } from "@mui/x-data-grid";
import FadeInOut from "../../animations/FadeInOut";
import BallotIcon from "@mui/icons-material/Ballot";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InstallPWAButton from "../../../utils/InstallPWAButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MapIcon from "@mui/icons-material/Map";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import MenuGeneralEmpresa from "./IntegratiosMenus/MenuGeneralEmpresa";
import MenuGeneralOperacion from "./IntegratiosMenus/MenuGeneralOperacion";
import MenuGeneralBodega from "./IntegratiosMenus/MenuGeneralBodega";
import useTitle from "../../Seo/useTitle";
import MenuGeneralMyAcount from "./IntegratiosMenus/MenuGeneralMyAcount";
import MenuFalabella from "./IntegratiosMenus/MenuFalabella";

export const DashboardSidebarAdminBicciExpress = (props) => {
  const [showMyAcount, setShowsMyAcount] = useState(false);
  const [showIntF, setShowIntF] = useState(true);
  const { img5 } = useGlobalConfig();

  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  const opctionIntFalabella = [
    "guiasfalabella",
    "guiasfalabella-same-day",
    "guiasfalabella-home-delivery",
    "metricsfalabella",
    "excepcionesfalabella",
    "guias-facturacion",
    "guiasfalabella-geosort",
  ];
  const opctionMyAcconut = ["mi-cuenta", "envios-express", "direcciones"];

  var actualUserRoll = props.patuserRol;

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="sidebar-body">
      <div id="brandsLogo">
        <Image className="brands brands-dash" src={img5} />
      </div>
      <h3 className="btn-sidebarLink alignItemscentertitle">
        Administrador Bicci
      </h3>
      <hr className="sidebar-hr"></hr>

      <MenuGeneralEmpresa onNavigate={props.onNavigate}></MenuGeneralEmpresa>

      <MenuGeneralOperacion
        onNavigate={props.onNavigate}
      ></MenuGeneralOperacion>

      <MenuGeneralBodega onNavigate={props.onNavigate}></MenuGeneralBodega>

      <MenuFalabella onNavigate={props.onNavigate}> </MenuFalabella>

      <MenuGeneralMyAcount
        onNavigate={props.handleCloseDrawer}
      ></MenuGeneralMyAcount>
    </div>
  );
};
