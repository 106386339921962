import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import IconButton from "@mui/material/IconButton";
import Resizer from "react-image-file-resizer";
import {
  Avatar,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MapIcon from "@mui/icons-material/Map";
import RouteIcon from "@mui/icons-material/Route";
import Moment from "moment";
import {
  registerApelations,
  registerPickerActions,
} from "../../redux/accionesOrders";
import { MapVectoriales } from "../../utils/Polygons/VectorialSatelite";
import Modalhome from "../../components/Modalhome/Modalhome";
import ModalHomeLittleEdith from "../../components/Modalhome/Modalhome";
import Camera from "./Camera";

const ScannerApelations = (props) => {
  //console.log(props);
  const dispacth = useDispatch();
  const [botonSeleccionado, setBotonSeleccionado] = useState(null);
  //const [currentUser, setCurrentUser] = useState(props.usuarioStorage.uid);
  const [barcodeDataImput, setBarcodeDataInput] = useState("");
  const [serahrCodeDataImput, setSearchBarcodeDataInput] = useState("");
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [serachResults, setSerachResults] = useState([]);
  const [dataSearch, setDataSearch] = React.useState([]);
  const [inputDataMethod, setInputDataMethod] = React.useState(false);
  const [mute, setMute] = React.useState(true);
  const [muteDriver, setMuteDriver] = React.useState(true);
  const [muteRoute, setMuteRoute] = React.useState(true);
  const [muteZone, setMuteZone] = React.useState(true);
  const synth = window.speechSynthesis;
  const miColeccionRef = collection(db, process.env.REACT_APP_COL_ORDERS);
  const [progressValue, setProgressValue] = React.useState(0);
  const [opened, setOpened] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [currentsTicket, setCurrentsTicket] = useState(null);
  const [localSize, setLocalSize] = useState({
    productSize: 0,
    productHeight: 0,
    productLength: 0,
    productWidth: 0,
    productVolume: 0, // Inicialmente vacío
  });

  const [foto, setFoto] = React.useState([]);
  const [tokensPics, setTokensPics] = React.useState([]);
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const inputRef = useRef(null);

  const miCampo = "key";

  const input1Ref = useRef(null);

  const collectionRef = collection(db, `${process.env.REACT_APP_COL_ORDERS}`);

  useEffect(() => {
    if (serahrCodeDataImput.length > 3) {
      findOrdesrDispachtScanner(serahrCodeDataImput);
    } else {
      setDataSearch([]);
      setFoto([]);
    }
  }, [serahrCodeDataImput]);

  useEffect(() => {
    if (dataSearch) {
      if (!mute) {
        if (!muteDriver) {
          dataSearch.driverName && dataSearch.driverName !== "isRequire"
            ? speakText(dataSearch.driverName)
            : speakText("No Asignado");
        }
        if (!muteRoute) {
          dataSearch.driverLicensePlate &&
          dataSearch.driverLicensePlate !== "isRequire"
            ? speakText(`Vehiculo ${dataSearch.driverLicensePlate}`)
            : speakText("Sin Vehiculo");
        }
        if (!muteZone) {
          dataSearch.routeLocality && dataSearch.routeLocality !== "isRequire"
            ? speakText(`Zona ${dataSearch.routeLocality}`)
            : speakText("Dirección incompleta");
        }
      }
    }
  }, []);

  useEffect(() => {
    // console.log(dataSearch);
    function isPointInPolygon(point, polygon) {
      var x = point[0],
        y = point[1];
      var inside = false;
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }

    function findPolygonForPoint(point, geoJson) {
      for (var feature of geoJson.features) {
        if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
          return feature.properties.Name;
        }
      }
      return null; // No contiene ningún polígono
    }

    var geoJson = MapVectoriales;

    if (dataSearch) {
      setBotonSeleccionado(dataSearch.productSize);
      setLocalSize({
        ...localSize,
        productHeight: parseFloat(dataSearch?.productHeight || 0),
        productLength: parseFloat(dataSearch.productLength || 0),
        productWidth: parseFloat(dataSearch.productWidth || 0),
        productSize: dataSearch.productSize,
        productVolume: parseFloat(dataSearch.productVolume || 0),
      });
      if (props.usuarioStorage) {
        // // console.log("data find", dataSearch);
        dispacth(registerPickerActions(dataSearch, props.usuarioStorage));
      }
    }
    if (dataSearch) {
      if (dataSearch.toAddressLocation) {
        var point = [
          dataSearch.toAddressLocation.longitude
            ? dataSearch.toAddressLocation.longitude
            : 0,
          dataSearch.toAddressLocation.latitude
            ? dataSearch.toAddressLocation.latitude
            : 0,
        ];
        var polygonName = findPolygonForPoint(point, geoJson);
        // console.log(polygonName);
        dataSearch.BicciZone = polygonName;

        if (props.usuarioStorage) {
          let data = { id: serahrCodeDataImput };
        }
      }
      setFoto(dataSearch.picsApelations ? dataSearch.picsApelations : []);
    }
    if (dataSearch && dataSearch.id) {
      findTicket(dataSearch.id);
    }
  }, [dataSearch]);

  useEffect(() => {
    // console.log(localSize);

    const volume =
      parseFloat(localSize.productHeight) *
      parseFloat(localSize.productLength) *
      parseFloat(localSize.productWidth);
    setLocalSize((prevState) => ({
      ...prevState,
      productVolume: parseFloat(volume).toFixed(4),
    }));
  }, [
    localSize.productHeight,
    localSize.productLength,
    localSize.productWidth,
  ]);

  const findTicket = async (id) => {
    try {
      const allTicketsRef = collection(db, process.env.REACT_APP_COL_SUPPORT);

      const q = query(allTicketsRef, where("ticketId", "==", id));
      const dataallTicketfind = await getDocs(q);

      var dataTiket = dataallTicketfind.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(dataTiket);
      setCurrentsTicket(dataTiket);
      setLocalSize({
        productSize: dataTiket[0].dataOrder.size,
        productHeight: dataTiket[0].dataOrder.alto,
        productLength: dataTiket[0].dataOrder.ancho,
        productWidth: dataTiket[0].dataOrder.largo,
        productVolume: dataTiket[0].dataOrder.vol,
      });
    } catch (error) {
      console.log(error);
      setCurrentsTicket(null);
    }
  };
  // useEffect(() => {
  // // Enfocar el campo de entrada al cargar la página
  // setWaitingForScan(true);
  // input1Ref.current.focus();
  // // Agregar un manejador de eventos para hacer clic en el cuerpo
  // document.body.addEventListener("click", handleBodyClick);
  // // Limpia el manejador de eventos al desmontar el componente
  // return () => {
  //   document.body.removeEventListener("click", handleBodyClick);
  // };
  //}, []);

  const handleFocus = () => {
    setWaitingForScan(true);
  };

  const handleBlur = () => {
    setWaitingForScan(false);
  };

  const findOrdesrDispachtScanner = async (serahrCodeDataImput) => {
    const collections = [process.env.REACT_APP_COL_ORDERS];

    let idWithF = "F" + serahrCodeDataImput;

    const tryGetDoc = async (collection, id) => {
      const orderRef = doc(db, collection, id);
      const docSnapshot = await getDoc(orderRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    };

    const tryQueryByAttributes = async (collectionName, attribute, value) => {
      const colRef = collection(db, collectionName);
      const q = query(colRef, where(attribute, "==", value));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    };

    try {
      //149124187276
      //  // console.log("set NULL")
      //setDataSearch(null);

      setProgressValue(10);
      let foundDocumentData = null;

      for (let collection of collections) {
        let documentData;

        documentData = await tryGetDoc(collection, idWithF);
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(20);

        documentData = await tryGetDoc(collection, serahrCodeDataImput);
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(30);

        documentData = await tryQueryByAttributes(
          collection,
          "id",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(40);

        documentData = await tryQueryByAttributes(
          collection,
          "int_client_soc",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        documentData = await tryQueryByAttributes(
          collection,
          "int_f12",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        documentData = await tryQueryByAttributes(
          collection,
          "int_lpn",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(40);

        // ... (rest of your code for other attribute checks)

        if (foundDocumentData) {
          break;
        }
      }

      if (foundDocumentData) {
        setDataSearch(foundDocumentData);
        setProgressValue(100);
      } else {
        // No se encontró el documento en ninguna colección ni por los atributos específicos.
        // setDataSearch([]);
        setProgressValue(100);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const listenscanner = () => {
    input1Ref.current.focus();
  };

  const handleBodyClick = (e) => {
    // Verifica si el clic se realizó fuera del campo de entrada
    if (!input1Ref.current.contains(e.target)) {
      // Si el clic fue fuera del campo, vuelve a enfocar el campo de entrada
      input1Ref.current.focus();
    }
  };

  const handleBarcodeScan = (data) => {
    setBarcodeDataInput(data);
    setSearchBarcodeDataInput(data);
    if (inputDataMethod === true) {
      setTimeout(() => {
        //// console.log("Procesando...");
        setBarcodeDataInput("");
        input1Ref.current.focus();
      }, 500);
    }
  };

  const handleChangeMethod = (event) => {
    setInputDataMethod(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalSize({
      ...localSize,
      [name]: value,
    });

    // Calcular el volumen solo si todos los campos de dimensiones tienen valores
    if (
      localSize.productHeight &&
      localSize.productLength &&
      localSize.productWidth
    ) {
      const volume =
        localSize.productHeight *
        localSize.productLength *
        localSize.productWidth;
      setLocalSize((prevState) => ({
        ...prevState,
        productVolume: volume,
      }));
    }
  };

  const speakText = (text) => {
    if (synth) {
      const utterance = new SpeechSynthesisUtterance(text);
      synth.speak(utterance);
    }
  };

  const sendNewVolumen = (nombreBoton) => {
    // console.log("Call");
    setBotonSeleccionado(nombreBoton);
    switch (nombreBoton) {
      case "XS2":
        setLocalSize({
          ...localSize,

          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "XS2",
          //productVolume: 0.006,
        });
        break;
      case "XS":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "XS",
          // productVolume: 0.01,
        });
        break;
      case "S":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "S",
          //productVolume: 0.033,
        });
        break;
      case "M":
        setLocalSize({
          ...localSize,
          productHeight: 0.275,
          productLength: 0.36,
          productWidth: 0.215,
          productSize: "M",
          productVolume: 0.0213,
        });
        break;
      case "L":
        setLocalSize({
          ...localSize,
          productHeight: 0.31,
          productLength: 0.45,
          productWidth: 0.27,
          productSize: "L",
          productVolume: 0.0377,
        });
        break;
      case "XL":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "XL",
          // productVolume: 0.661,
        });
        break;

      case "XXL":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "XXL",
          //  productVolume: 1.001,
        });
        break;

      case "O":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "O",
          //  productVolume: 1.001,
        });
        break;

      case "otro":
        setLocalSize({
          ...localSize,
          // productHeight: 0,
          // productLength: 0,
          // productWidth: 0,
          productSize: "otro",
          // productVolume: 8.9,
        });
        break;
      default:
        break;
    }
  };

  const estiloBoton = (nombreBoton) => ({
    margin: "1px",
    fontSize: "25px",
    borderRadius: "15px",
    backgroundColor: botonSeleccionado === nombreBoton ? "#59db76" : "#e3e3e3",
  });

  async function savePics(newToken) {
    const orderRef = doc(collectionRef, dataSearch.orderID);
    const orderSnapshot = await getDoc(orderRef);
    const orderData = orderSnapshot.data();
    const tokensPics = orderData.picsApelations || [];
    let arrayPicsApelations = [...tokensPics, newToken];
    await updateDoc(orderRef, {
      picsApelations: arrayPicsApelations,
      picsTaken: true,
    });
    setFoto(arrayPicsApelations);
  }

  function upLoadImage(cropInageResult, newFoto, metadata) {
    try {
      const dataUrl = cropInageResult.split(",")[1];
      uploadString(
        ref(
          storage,
          `xpress_apelations/${dataSearch.orderID}/image${newFoto}.jpg`
        ),
        dataUrl,
        "base64",
        metadata
      )
        .then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          setTokensPics((prevState) => [...prevState, downloadURL]);
          savePics(downloadURL);
        })
        .catch((error) => {
          console.error("Error al cargar la imagen:", error);
        });
    } catch (error) {
      // console.log(error);
    }
  }

  const cropImage = async (imageDataUrl, newFoto, metadata) => {
    try {
      const img = new Image();
      img.src = imageDataUrl; // Carga la imagen en el elemento img

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const targetWidth = 1000;
        const targetHeight = 1500;
        const aspectRatio = targetWidth / targetHeight;

        // Ajuste de alto sin afectar el ancho
        let width = img.width;
        let height = Math.floor(width / aspectRatio);
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);

        // Ajuste de ancho sin afectar el alto
        width = targetWidth;
        height = Math.floor(width / aspectRatio);
        const finalCanvas = document.createElement("canvas");
        const finalContext = finalCanvas.getContext("2d");
        finalCanvas.width = targetWidth;
        finalCanvas.height = targetHeight;
        finalContext.drawImage(
          canvas,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        const stretchedImageDataUrl = finalCanvas.toDataURL("image/jpeg", 1.0);
        upLoadImage(stretchedImageDataUrl, newFoto, metadata);
      };
    } catch (error) {
      // console.log(error);
    }
  };

  const CapturaImagen = () => {
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const inputRef = useRef(null);

    async function handleImageLoad(event) {
      const file = event.target.files[0];
      const metadata = {
        contentType: file.type,
      };
      let newFoto = foto.length + 1;

      Resizer.imageFileResizer(
        file,
        1000, // Aquí puedes definir el nuevo ancho que desees
        1500, // Aquí puedes definir la nueva altura que desees
        "JPEG",
        100,
        0,
        (uri) => {
          cropImage(uri, newFoto, metadata);
        },

        "base64",
        1000, // Nuevo ancho máximo (opcional)
        1500 // Nuevo alto máximo (opcional)
      );
    }

    const handleClick = () => {
      inputRef.current.click();
    };

    return (
      <div
        style={{
          margin: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imagenSeleccionada && (
          <img
            src={imagenSeleccionada}
            alt="Vista Previa"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        )}

        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          capture="environment"
          onChange={handleImageLoad}
          style={{ display: "none" }}
        />

        <Button
          variant="contained"
          sx={{
            minWidth: "64px",
            height: "64px",
            borderRadius: "50%", // Redondea completamente el botón para hacerlo circular
            color: "#5048E5", // Color del texto e icono
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#d5d5d5",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleClick}
          startIcon={<CameraAltIcon sx={{ fontSize: "1.5rem" }} />}
        >
          Subir Foto
        </Button>
      </div>
    );
  };

  const handleSave = () => {
    // console.log(dataSearch.int_lpn);
    // console.log(dataSearch.int_client_soc);
    // console.log(dataSearch.orderID);
    // console.log(foto);
    // console.log(localSize);
    let data = {
      dataAPlecion: true,
      dataOrder: dataSearch,
      foto: foto,
      localSize: localSize,
      usuarioStorage: props.usuarioStorage,
    };
    dispacth(registerApelations(data));
    findOrdesrDispachtScanner(serahrCodeDataImput);
  };

  const handleChangeVolumen = (event) => {
    const { name, value } = event.target;
    setLocalSize({
      ...localSize,
      [name]: parseFloat(value),
    });

    // Calcular el volumen y actualizar el estado
  };
  //140111000002226257
  //100000005915979

  // console.log(dataSearch);
  return (
    <Box
      alt="bicciexpress"
      sx={{
        objectFit: "contain",
        width: "100%",
        height: "100%",
        //backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
        //backgroundSize: "100% auto", // Escala horizontal completa
        //backgroundRepeat: "no-repeat", // No se repite
        // backgroundColor: "#e58888", //"#aef3c3",
        padding: "1em",
        marginBottom: "0px",
      }}
    >
      <Card
        sx={{
          maxWidth: 900,
          height: "90%",
          maxHeight: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2px",
          marginBottom: "5%",

          "@media (max-width:900px)": {
            maxWidth: "100%",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            height: "auto",
            maxHeight: "100%",
          },
        }}
      >
        <CardHeader
          title={
            <Typography variant={"h1"}> Escáner de Apelaciones </Typography>
          }
          subheader={
            <div>
              <Switch
                checked={inputDataMethod}
                onChange={handleChangeMethod}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: waitingForScan ? "#aef3c3" : "#f8f8f8",
                }}
              />
              <Typography
                fontFamily="Lato, Arial, Helvetica, sans-serif"
                color="textPrimary"
                variant="body"
              >
                {inputDataMethod ? (
                  <span>Escaner seleccionado</span>
                ) : (
                  <span>Teclado seleccionado</span>
                )}
              </Typography>
            </div>
          }
          sx={{ padding: "1px", margin: "0px" }}
        />
        {/* <CardMedia
          component="img"
          height="100"
          image="/static/images/asset/entradateclado.png"
          alt="bicciexpress"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "50%",
            backgroundColor: "#aef3c3",
          }}
        /> */}
        <CardContent sx={{ padding: "1px" }}>
          <Box
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "99%",
              //   backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
              //   backgroundSize: "100% auto", // Escala horizontal completa
              //   backgroundRepeat: "no-repeat", // No se repite
              backgroundColor: waitingForScan ? "#aef3c3" : "#f8f8f8", //,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                padding: "1px",
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "30%",
                  },

                  overflowY: "auto",
                  maxHeight: "100vh",
                  padding: "2px",
                  maxWidth: "100%",
                }}
              >
                <AppBar
                  position="static"
                  sx={{
                    color: "#8F8F8",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                    },
                    backgroundColor: "#e3e3e3",
                    overflowY: "auto",
                    maxHeight: "100vh",
                  }}
                >
                  <Toolbar variant="dense" disableGutters>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textPrimary"
                        variant="body"
                        sx={{
                          fontSize: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "1rem",
                            lg: "1rem",
                          },
                          flexGrow: 1,
                          color: "#591e8f",
                        }}
                      >
                        Escáner de distribución
                      </Typography>
                    </Box>
                    {/* Avatar e ícono de estado */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          inputDataMethod
                            ? "/static/images/asset/escanner.png"
                            : "/static/images/asset/teclado.png"
                        }
                        sx={{
                          backgroundColor: waitingForScan
                            ? "#aef3c3"
                            : "#f1eeee",
                          height: 50,
                          width: 50,
                          marginRight: 1,
                        }}
                      />
                      {waitingForScan ? (
                        <ChangeCircleIcon
                          sx={{ color: green[500], marginRight: 1 }}
                        />
                      ) : (
                        <CachedIcon sx={{ color: "#591e8f", marginRight: 1 }} />
                      )}
                      <Typography variant="body2" sx={{ color: "#591e8f" }}>
                        {waitingForScan ? "On" : "Off"}
                      </Typography>
                    </Box>

                    {/* Íconos de sonido y configuración */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => setMute(!mute)}
                        sx={{ color: "#591e8f", marginRight: 1 }}
                      >
                        {!mute ? (
                          <VolumeUpIcon sx={{ color: green[500] }} />
                        ) : (
                          <VolumeOffIcon sx={{ color: "#591e8f" }} />
                        )}
                      </IconButton>

                      <IconButton
                        size="large"
                        aria-label="mute driver"
                        onClick={() => setMuteDriver(!muteDriver)}
                        sx={{ color: "#591e8f", marginRight: 1 }}
                      >
                        <DirectionsBikeIcon
                          sx={{ color: !muteDriver ? green[500] : "#4f4f4f" }}
                        />
                      </IconButton>

                      <IconButton
                        size="large"
                        aria-label="mute zone"
                        onClick={() => setMuteZone(!muteZone)}
                        sx={{ color: "#591e8f", marginRight: 1 }}
                      >
                        <MapIcon
                          sx={{ color: !muteZone ? green[500] : "#4f4f4f" }}
                        />
                      </IconButton>

                      <IconButton
                        size="large"
                        aria-label="mute route"
                        onClick={() => setMuteRoute(!muteRoute)}
                        sx={{ color: "#591e8f" }}
                      >
                        <RouteIcon
                          sx={{ color: !muteRoute ? green[500] : "#4f4f4f" }}
                        />
                      </IconButton>
                    </Box>
                  </Toolbar>
                </AppBar>

                <CapturaImagen></CapturaImagen>
                <Card sx={{ m: 1 }}>
                  <CardHeader title={"Discrepar volmen o tamaño"}></CardHeader>
                  <CardContent>
                    <div
                      style={{
                        margin: "2px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={estiloBoton("XS2")}
                        onClick={() => sendNewVolumen("XS2")}
                      >
                        {" "}
                        XS2{" "}
                      </Button>

                      <Button
                        sx={estiloBoton("XS")}
                        onClick={() => sendNewVolumen("XS")}
                      >
                        {" "}
                        XS{" "}
                      </Button>
                      <Button
                        sx={estiloBoton("S")}
                        onClick={() => sendNewVolumen("S")}
                      >
                        {" "}
                        S{" "}
                      </Button>
                    </div>

                    <div
                      style={{
                        margin: "2px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={estiloBoton("M")}
                        onClick={() => sendNewVolumen("M")}
                      >
                        {" "}
                        M{" "}
                      </Button>
                      <Button
                        sx={estiloBoton("L")}
                        onClick={() => sendNewVolumen("L")}
                      >
                        {" "}
                        L{" "}
                      </Button>
                      <Button
                        sx={estiloBoton("XL")}
                        onClick={() => sendNewVolumen("XL")}
                      >
                        {" "}
                        XL{" "}
                      </Button>
                    </div>

                    <div
                      style={{
                        margin: "2px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={estiloBoton("XXL")}
                        onClick={() => sendNewVolumen("XXL")}
                      >
                        {" "}
                        XXL{" "}
                      </Button>
                      <Button
                        sx={estiloBoton("O")}
                        onClick={() => sendNewVolumen("O")}
                      >
                        {" "}
                        O{" "}
                      </Button>
                    </div>
                  </CardContent>
                </Card>

                <Card sx={{ m: 1 }}>
                  <div
                    style={{
                      margin: "2px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      name="productWidth"
                      label="Ancho"
                      variant="outlined"
                      value={localSize?.productWidth || 0}
                      placeholder="Ancho"
                      style={{ margin: "5px" }}
                      onChange={handleChange}
                    />
                    <TextField
                      name="productLength"
                      label="Largo"
                      variant="outlined"
                      value={localSize?.productLength || 0}
                      placeholder="Largo"
                      style={{ margin: "5px" }}
                      onChange={handleChange}
                    />
                    <TextField
                      name="productHeight"
                      label="Alto"
                      variant="outlined"
                      value={localSize?.productHeight || 0}
                      placeholder="Alto"
                      style={{ margin: "5px" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      margin: "2px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      name="productVolume"
                      label="Vol"
                      variant="outlined"
                      placeholder="Volumen"
                      style={{ margin: "5px" }}
                      value={
                        localSize.productVolume !== ""
                          ? localSize.productVolume
                          : ""
                      } // Mostrar el volumen calculado solo si está disponible
                      disabled // Deshabilitar la edición del campo de volumen
                    />
                  </div>
                </Card>
                <Card sx={{ m: 1 }}>
                  {dataSearch.orderID &&
                  localSize.productVolume > 0 &&
                  foto.length > 0 ? (
                    <Button
                      onClick={() => handleSave()}
                      sx={{ backgroundColor: "#0fd171" }}
                    >
                      Registrar Apelacion
                    </Button>
                  ) : null}
                </Card>
                {/* <Card>
                  <Button onClick={() => setOpen(true)}> Camara</Button>
                </Card> */}
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "70%",
                    padding: "1px",
                  },
                  height: "100%",
                  bottom: "0px",
                }}
              >
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  variant="body"
                >
                  {waitingForScan ? (
                    <div style={{ padding: "5px" }}>
                      Esperando{" "}
                      {inputDataMethod
                        ? "lecturas por escanner..."
                        : "digitación de codigo..."}{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "5px",
                        backgroundColor: "red", //"#e58888",
                        color: "#fff",
                      }}
                    >
                      {inputDataMethod
                        ? "Mantenga la pagina activa para escanear..."
                        : "Mantenga la pagina activa para buscar..."}
                    </div>
                  )}
                </Typography>
                <LinearProgress
                  value={progressValue}
                  variant="determinate"
                ></LinearProgress>
                <div className="searchtiketcontainers">
                  <Card
                    sx={{
                      maxWidth: "450px",
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "1px",
                      textAlign: "center",
                    }}
                  >
                    <div className="inputcode">
                      <input
                        type="text"
                        id="codigoBarras"
                        className={
                          inputDataMethod
                            ? "barcode-input"
                            : "barcode-input-keyboar"
                        }
                        ref={input1Ref}
                        autoFocus
                        placeholder={
                          inputDataMethod
                            ? "|||||||| ||| |||| |||||"
                            : "Digite el codígo abuscar"
                        }
                        value={barcodeDataImput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={(e) => handleBarcodeScan(e.target.value)}

                        //   sx={{
                        //     ...styles.inputBase,
                        //     "::before": {
                        //       ...styles.beforeContent,
                        //     },
                        //   }}
                      />

                      {/* Resto de tu interfaz y lógica aquí */}
                    </div>
                    {serahrCodeDataImput ? (
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textPrimary"
                        variant="h2"
                      >
                        {serahrCodeDataImput}
                      </Typography>
                    ) : null}
                  </Card>

                  {dataSearch ? (
                    <div className="tiketcontainers">
                      <div>
                        {dataSearch && dataSearch.length !== 0 ? (
                          <div className="tiketcontainers">
                            <Card
                              className="m-4 b-1"
                              key={dataSearch.id}
                              sx={{
                                boxShadow: "#F8F8F8",
                              }}
                            >
                              <CardHeader
                                title={
                                  <div>
                                    <div>
                                      {dataSearch.deliveryType
                                        ? dataSearch.deliveryType
                                        : "RESGISTRO DE FACTURACÍON"}
                                    </div>
                                    <div>ID: {dataSearch.id}</div>
                                    <div>KEY: {dataSearch.key}</div>
                                    <div>LPN :{dataSearch.int_lpn}</div>
                                  </div>
                                }
                              />
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  {dataSearch.int_alert ? (
                                    <Typography
                                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                                      color="textPrimary"
                                      variant="h4"
                                      sx={{
                                        padding: "2px",
                                      }}
                                    >
                                      <span className="alertRed bigText">
                                        DISCREPANCIA REGISTRADA
                                      </span>
                                    </Typography>
                                  ) : null}
                                  {/* <Typography>RIDER</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="body"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div>
                                      {!dataSearch.driverName ||
                                      dataSearch.driverName === "isRequire" ||
                                      dataSearch.driverName === "" ? (
                                        <span className="alertRed bigText">
                                          No asignado
                                        </span>
                                      ) : (
                                        <span>{dataSearch.driverName}</span>
                                      )}
                                    </div>
                                  </Typography> */}
                                </CardContent>
                              </Card>
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Grid container direction="row" spacing={3}>
                                    <Grid item xs>
                                      Medida:{" "}
                                      <span>{dataSearch.productSize}</span>
                                      <Typography>TICKETS</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {currentsTicket
                                            ? currentsTicket.map(
                                                (item, index) => (
                                                  <div key={index}>
                                                    {item.id}{" "}
                                                  </div>
                                                )
                                              )
                                            : null}
                                        </div>
                                      </Typography>
                                      {/* <Box>
                                        <Typography>VEHÍCULO</Typography>
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <div>
                                            {!dataSearch.driverLicensePlate ||
                                            dataSearch.driverLicensePlate ===
                                              "isRequire" ||
                                            dataSearch.driverLicensePlate ===
                                              "null" ? (
                                              <span className="alertRed bigText">
                                                -
                                              </span>
                                            ) : (
                                              <span className="alertcolor bigText">
                                                {dataSearch.driverLicensePlate}
                                              </span>
                                            )}
                                          </div>
                                        </Typography>
                                      </Box> */}
                                    </Grid>
                                    <Grid item xs>
                                      <Box>
                                        {/* <Typography>Ruta</Typography>
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <div>
                                            {dataSearch.BicciZone
                                              ? dataSearch.BicciZone
                                              : "No se Encuentra georeferencia"}
                                          </div>
                                        </Typography> */}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Typography>ZONA</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div>
                                      {dataSearch.routeLocality
                                        ? dataSearch.routeLocality
                                        : dataSearch.toAddress}
                                    </div>
                                  </Typography>
                                </CardContent>
                              </Card>
                              {/* <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Typography>PUNTO RUTA</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div> {dataSearch.routePosition}</div>
                                  </Typography>
                                </CardContent>
                              </Card> */}
                              <Card sx={{ padding: "5px" }}>
                                <Typography
                                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                                  color="textPrimary"
                                  gutterBottom
                                  variant="body"
                                >
                                  {foto && foto.length > 0 ? (
                                    <>
                                      {" "}
                                      <hr></hr>
                                      {foto.map((item, index) => (
                                        <img
                                          key={index}
                                          id="imgeApelations"
                                          src={item}
                                          alt={""}
                                        ></img>
                                      ))}
                                      {/* {foto.map((item, index) => (
                                        <div key={index}>
                                          <div>{item}</div>
                                          <hr></hr>
                                        </div>
                                      ))} */}
                                    </>
                                  ) : (
                                    <span className="alert alert-danger">
                                      'Sin Foto'
                                    </span>
                                  )}
                                </Typography>
                              </Card>
                            </Card>
                          </div>
                        ) : (
                          <Card
                            sx={{
                              marginTop: "50px",
                              padding: "20px",
                              marginLeft: "20px",
                              marginRight: "20px",
                            }}
                          >
                            <div>
                              <span className="bigText">
                                Sin resultados, ingrese nuevos datos para
                                iniciar la busqueda.{" "}
                              </span>
                            </div>
                          </Card>
                        )}{" "}
                      </div>
                    </div>
                  ) : (
                    <Card
                      sx={{
                        marginTop: "50px",
                        padding: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <div className="alertcolor bigText"> "buscando..."</div>
                    </Card>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>{" "}
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            position: "absolute",
            width: "100vh",
            height: "100vh",
            margin: "0px",
            padding: "0px",
          },
        }}
      >
        <Button onClick={() => setOpen(false)}> cerrar</Button>
        <Camera></Camera>
      </Dialog>
    </Box>
  );
};

export default ScannerApelations;
