import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import FadeInOut from "../../../animations/FadeInOut";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import MapIcon from "@mui/icons-material/Map";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PaymentIcon from "@mui/icons-material/Payment";

const MenuFalabella = ({ companyCollaborator, onNavigate }) => {
  const [showIntF, setShowIntF] = useState(true);

  const opctionIntFalabella = [
    "guiasfalabella",
    "guiasfalabella-same-day",
    "guiasfalabella-home-delivery",
    "metricsfalabella",
    "excepcionesfalabella",
    "guias-facturacion",
    "guiasfalabella-geosort",
    "facturacion",
  ];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <div>
      {" "}
      {companyCollaborator === "falabella" ? (
        <div>
          <Chip
            icon={<WarehouseIcon />}
            label=" Integracion Falabella"
            variant="outlined"
            color="primary"
            onClick={() => setShowIntF(!showIntF)}
            sx={{
              color: "#F8F8F8",
              width: "100%",
              fontSize: "1em",
              padding: "2px",
              marginBottom: "5px",
              backgroundColor: opctionIntFalabella.includes(splitLocation[2])
                ? "#07b107"
                : "transparent",
            }}
          />

          <FadeInOut show={showIntF} duration={500}>
            <div className="btn-sidebarLink">Opciones:</div>
            <NavLink
              to="/dashboard/view-plannig"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "view-plannig"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <TravelExploreIcon fontSize="medium" />
                <span className="btn-title">Mapa De Distribución</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/view-operations"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "view-operations"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <MapIcon fontSize="medium" />
                <span className="btn-title">Mapa Operación</span>
              </div>
            </NavLink>
            <hr></hr>

            <NavLink
              to="/dashboard/fleet"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "fleet"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <LocalShippingIcon fontSize="medium" />
                <span className="btn-title">Flota</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guiasfalabella"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "guiasfalabella"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <ContentPasteGoIcon fontSize="medium" />
                <span className="btn-title">Ops Integración</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guiasfalabella-same-day"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "guiasfalabella-same-day"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <ContentPasteGoIcon fontSize="medium" />
                <span className="btn-title">Ops Same Day</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guiasfalabella-home-delivery"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "guiasfalabella-home-delivery"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <ContentPasteGoIcon fontSize="medium" />
                <span className="btn-title">Ops Home Delivery</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guiasfalabella-geosort"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "guiasfalabella-geosort"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <ContentPasteGoIcon fontSize="medium" />
                <span className="btn-title">Ops Falabella Geosort</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/metricsfalabella"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "metricsfalabella"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <AssessmentIcon fontSize="medium" />
                <span className="btn-title">Monitor Falabella</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/excepcionesfalabella"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "excepcionesfalabella"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <AssignmentLateIcon fontSize="medium" />
                <span className="btn-title">Alerta OPS</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guias-facturacion"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "guias-facturacion"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <PaymentIcon fontSize="medium" />
                <span className="btn-title">Gestión registos</span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/facturacion"
              className="btn-sidebarLink"
              onClick={onNavigate}
            >
              <div
                className={
                  splitLocation[2] === "facturacion"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <PaymentIcon fontSize="medium" />
                <span className="btn-title">Facturación</span>
              </div>
            </NavLink>
          </FadeInOut>
        </div>
      ) : null}
    </div>
  );
};

export default MenuFalabella;
