import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";

const CreateAPIObject = () => {
  return (
    <div id="CreateAPIObject">
      <h1>Tabla de referencia de API</h1>
      <div id="create-api-variables">
        <p>Tipos de datos esperados y ejemplos de uso.</p>
      </div>

      <div>
        <Box p={3}>
          <Typography variant="body" gutterBottom sx={{ fontWeight: 600 }}>
            API Request Example
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Tipo de Dato</TableCell>
                  <TableCell>Ejemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell colSpan={2}>
                    https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell colSpan={2}>POST</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Authorization</TableCell>
                  <TableCell colSpan={2}>Bearer</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content-Type</TableCell>
                  <TableCell colSpan={2}>application/json</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tiempo de respuesta</TableCell>
                  <TableCell colSpan={2}>aprox: 700 ms</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Parámetros en Body</TableCell>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>fromLocation</TableCell>
                  <TableCell>
                    (2 Double dentro de un JSON) obligatorio
                  </TableCell>
                  <TableCell>
                    {'{"lat": -33.4592491, "lng": -70.6014753}'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>toLocation</TableCell>
                  <TableCell>
                    (2 Double dentro de un JSON) obligatorio
                  </TableCell>
                  <TableCell>
                    {'{"lat": -33.4513036, "lng": -70.5935356}'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>fromAddress</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>
                    Alcalde Eduardo Castillo Velasco 3101, Ñuñoa
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>toAddress</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>farellones norte 50</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>companyID</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>FroY2jsadjhsakdkaJHJA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>userName</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>Jhon Smite</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>userPhone</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>+56987654321</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>userEmail</TableCell>
                  <TableCell>String opcional</TableCell>
                  <TableCell>clientefinal@integracion.cl</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>deliveryDate</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>07/07/2024</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>deliveryType</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>HD ó SD</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>whcode</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>WH123 Codígo de bodega</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>direcction1</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>farellones norte</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>direcction2</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>50</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>direcction3</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>comuna</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>Lo Barnechea</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>region</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>Metropolitana</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>country</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>Chile</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>toAddressComment</TableCell>
                  <TableCell>String opcional</TableCell>
                  <TableCell>dejar en conserjeria</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>product</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>Calzado</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" gutterBottom>
            Ejemplo del Body Tipo de datos: JSON
          </Typography>
          <Paper>
            <Box p={2}>
              <pre>
                {`
{
    "data": {
        "companyID": "FroY2jsadjhsakdkaJHJA",
        "orders": [{
            "id": 509912125620,
            "date": "07/07/2024",
            "deliveryDate": "07/07/2024",
            "deliveryType": "HD",
            "whcode": "WH123",
            "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
            "fromLocation": {"lat":-33.4592491, "lng":-70.6014753},
            "toAddress": "farellones norte 50 1102",
            "direcction1": "farellones norte",
            "direcction2": "50",
            "direcction3": "1102",
            "comuna": "Lo Barnechea",
            "region": "Metropolitana",
            "country": "Chile",
            "toLocation": { "lat":-33.4513036, "lng": -70.5935356},
            "toAddressComment": "dejar en conserjeria",
            "userPhone": "+56987654321",
            "userEmail": "clientefinal@integracion.cl",
            "userName": "Jhon Smite",
            "product": "Calzado",
            "groceries": [{
                "description": "Zapatos postman en test",
                "id": "2czQxEsfDNi1IXqmVBAE_0",
                "name": "Zapatos postman",
                "instructions": "",
                "quantity": 1,
                "price": 10,
                "height": 10,
                "width": 20,
                "length": 30,
                "weight": 2,
                "volume": 6000
            }]
        }]
    }
}
`}
              </pre>
            </Box>
          </Paper>

          <Typography variant="h5" gutterBottom>
            Ejemplo de Respuesta
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Respuesta Correcta</TableCell>
                  <TableCell>
                    200 OK, mensaje: Orden creada con éxito. Código de la orden
                    en la respuesta con la variable <code>orderID</code>.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    400 Bad Request: wrong type, missing data, wrong data,
                    missing groceries.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ejemplo de Respuesta</TableCell>
                  <TableCell colSpan={2}>
                    <pre>
                      {`
                        {
                            "result": {
                                "code": 200,
                                "orderID": "187932351",
                                "message": "Orden creada con éxito"
                            }
                        }
                        `}
                    </pre>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
};

export default CreateAPIObject;
