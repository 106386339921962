import React from "react";
import { Form, Input, Container, textarea } from "semantic-ui-react";
import {
  registrarComentarios,
  enviarEmaildeFormularioCliente,
} from "../../../redux/accionesCRM";
import { useDispatch, useSelector } from "react-redux";
// import BtnAceceder from '../../Login/buttons/BtnAceceder'
// import BtnTebgoCuenta from '../../Login/buttons/BtnTebgoCuenta'

const FrmContacto = () => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);
  // console.log(resperror);
  const [success, setSuccess] = React.useState(null);
  let mensjaEnviado = useSelector((store) => store.mensajes.mjsstatus);
  // console.log(mensjaEnviado);
  const [username, setName] = React.useState("");
  // console.log (username);
  // const[userLastName,setUserLastName] = React.useState("")
  // console.log (userLastName);
  const [companyName, setCompanyName] = React.useState("");
  // console.log (companyName);
  const [email, setEmail] = React.useState("");
  //  console.log (email);
  const [phone, setPhone] = React.useState("+56");
  //  console.log (phone);
  const [userPreferenCont, setUserPreferenCont] = React.useState("Correo");
  //  console.log (userPreferenCont);
  const [textComents, setTextComents] = React.useState("");
  //  console.log (textComents);

  const [error, setError] = React.useState(null);

  const [userType, setUserType] = React.useState("empresa");
  //  console.log (userType);
  const [checked, setChecked] = React.useState(false);

  const registrarComentario = (e) => {
    e.preventDefault();

    if (!username.trim()) {
      //     console.log('El campo nombre es obligatorio')
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!companyName.trim()) {
      //     console.log('El campo nombre de empresa es obligatorio')
      setError("El campo nombre de empresa es obligatorio");
      return;
    }
    if (!email.trim()) {
      //      console.log('Ingrese Email')
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      //          console.log('Ingrese un numero telefonico')
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!textComents.trim()) {
      //       console.log('Ingrese Mensaje')
      setError("Ingrese Mensaje");
      return;
    }
    if (textComents.length < 8) {
      //      console.log('Mensaje es muy corto')
      setError("Mensaje es muy corto");
      return;
    }
    if (!userPreferenCont.trim()) {
      //     console.log('Seleccione un medio de contacto')
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      //       console.log('Seleccione un tipo de usuario')
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (!checked === true) {
      //     console.log('Debe aceptar los terminos y condiciones')
      setError("Debe aceptar los terminos y condiciones");
      return;
    }
    if (!resperror === null) {
      //     console.log('Error de acceso')
      setError("Error de acceso");
      return;
    }
    //   console.log('Validando usuario...')
    setError(null);
    cometar();
  };

  const handleChange = () => {
    setChecked(!checked);
    // console.log (checked)
  };
  if (checked === true) {
    //    console.log ('Acepta terminos y condiciones')
  }

  const setClear = () => {
    setSuccess(null);
    setName("");
    setCompanyName("");
    setEmail("");
    setPhone("+56");
    setUserPreferenCont("Correo");
    setTextComents("");
    setError(null);
    setUserType("empresa");
    setChecked(false);
  };
  const cometar = React.useCallback(async () => {
    //  console.log('Esta Comentando')
    try {
      const userData = {
        userName: username,
        userCompany: companyName,
        userType: userType,
        email: email,
        phone: phone,
        mensaje: textComents,
        userPreferenCont: userPreferenCont,
        checked: checked,
      };
      dispatch(registrarComentarios(userData));
      dispatch(enviarEmaildeFormularioCliente(userData));
      setSuccess("Gracias. Mensaje enviado...");
      function delay() {
        setClear();
        setSuccess(null);
      }
      setTimeout(delay, 5000);
    } catch (error) {
      //   console.log(error)
    }
  });

  return (
    <Container className="formcontent" id="ajustefromIni">
      <hr></hr>

      <Form onSubmit={registrarComentario}>
        {success ? (
          <div>
            <span className="alert alert-success">{success}</span>
          </div>
        ) : null}
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {resperror ? (
          <div className="alert alert-danger">{resperror}</div>
        ) : null}
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Nombre'
            placeholder="Nombre de contacto"
            iconPosition="left"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={username}
            icon="user"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Nombre de Empresa'
            placeholder="Nombre de Empresa"
            iconPosition="left"
            type="text"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
            icon="building"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            iconPosition="left"
            placeholder="Email"
            control={Input}
            type="email"
            //label='Email'
            icon="at"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            label="Tipo de usuario"
            control="select"
            onChange={(e) => setUserType(e.target.value)}
          >
            <option value="empresa">Empresa</option>
            <option value="repartidor">Repartidor</option>
            <option value="integracion">Integracion</option>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            //label='Teléfono'
            placeholder="Teléfono"
            iconPosition="left"
            icon="phone"
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <textarea
            type="TextArea"
            label="Mensaje"
            rows="3"
            placeholder="Como podemos ayudarte?"
            onChange={(e) => setTextComents(e.target.value)}
            value={textComents}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            label="Preferencias de contacto"
            control="select"
            onChange={(e) => setUserPreferenCont(e.target.value)}
          >
            <option value="correo">Correo</option>
            <option value="telefono">Telefono</option>
            <option value="video">Video</option>
            <option value="presencial">Presencial</option>
          </Form.Field>
        </Form.Group>
        <div>
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={handleChange}
          />
          <span> Acepto terminos y condiciones de BICCI EXPRESS.</span>
        </div>
        <hr></hr>
        <button className="btn btn-ebiex " type="sutmit">
          Enviar
        </button>
      </Form>
      <hr></hr>
      {/* <div>
              <BtnAceceder></BtnAceceder> 
              <div >
                   
                  <BtnTebgoCuenta></BtnTebgoCuenta>
              </div>
        </div> */}
    </Container>
  );
};

export default FrmContacto;
