import React, { useState, useRef } from "react";
import { Container } from "semantic-ui-react";
import { Card } from "@mui/material";

const PinpassFrom = (props) => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const handleInputChange = (e, ref) => {
    if (e.target.value.length >= e.target.maxLength) {
      ref.current.focus();
    }

    if (e.target.id === "input1Ref") {
      if (parseInt(e.target.value, 10) > 9) {
        setInput1(e.target.value.charAt(0)); // Establecer el valor a 9 si es mayor a 9
      } else {
        setInput1(e.target.value);
      }
    }
    if (e.target.id === "input2Ref") {
      if (parseInt(e.target.value, 10) > 9) {
        setInput2(e.target.value.charAt(0)); // Establecer el valor a 9 si es mayor a 9
      } else {
        setInput2(e.target.value);
      }
    }
    if (e.target.id === "input3Ref") {
      if (parseInt(e.target.value, 10) > 9) {
        setInput3(e.target.value.charAt(0)); // Establecer el valor a 9 si es mayor a 9
      } else {
        setInput3(e.target.value);
      }
    }
    if (e.target.id === "input4Ref") {
      if (parseInt(e.target.value, 10) > 9) {
        setInput4(e.target.value.charAt(0)); // Establecer el valor a 9 si es mayor a 9
      } else {
        setInput4(e.target.value);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Realizar acciones con los valores de los inputs
    props.setPin(
      input1.toString() +
        input2.toString() +
        input3.toString() +
        input4.toString()
    );
    setInput1("");
    setInput2("");
    setInput3("");
    setInput4("");
  };

  return props.notFound ? (
    <div className="bg-container ">
      <Container className="bodycontent-pinpass">
        {" "}
        <div className="container-car-pin">
          <Card className="card-pin">
            <div className="title-car-pinpass">
              <h2>{props.title}</h2>
              <hr></hr>
            </div>
            <form onSubmit={handleSubmit} className="form-horizontal">
              <div className="from-imputs-pinpass">
                <div className="form-group-imput">
                  <label className="col-sm-2 control-label bigtext">*</label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      min="0"
                      max="9"
                      ref={input1Ref}
                      className="form-control imputcontainer"
                      value={input1}
                      id="input1Ref"
                      onChange={(e) => handleInputChange(e, input2Ref)}
                    />
                  </div>
                </div>
                <div className="form-group-imput">
                  <label className="col-sm-2 control-label bigtext">*</label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      min="0"
                      max="9"
                      ref={input2Ref}
                      className="form-control imputcontainer"
                      value={input2}
                      id="input2Ref"
                      onChange={(e) => handleInputChange(e, input3Ref)}
                    />
                  </div>
                </div>
                <div className="form-group-imput">
                  <label className="col-sm-2 control-label bigtext">*</label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      min="0"
                      max="9"
                      ref={input3Ref}
                      className="form-control imputcontainer"
                      value={input3}
                      id="input3Ref"
                      onChange={(e) => handleInputChange(e, input4Ref)}
                    />
                  </div>
                </div>
                <div className="form-group-imput">
                  <label className="col-sm-2 control-label bigtext">*</label>
                  <div className="col-sm-10">
                    <input
                      type="number"
                      min="0"
                      max="9"
                      ref={input4Ref}
                      className="form-control imputcontainer"
                      value={input4}
                      id="input4Ref"
                      onChange={(e) => handleInputChange(e, input4Ref)}
                    />
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="form-group-button">
                <div className="form-group-button-container">
                  <button
                    type="submit"
                    className="btn btn-ebiex btn-globalhover"
                  >
                    Enviar
                  </button>
                </div>
              </div>
              <div className="alestpinpass text-center">
                {props.info ? (
                  <span className="alert alert-danger">{props.info}</span>
                ) : null}
              </div>
            </form>
          </Card>
        </div>
      </Container>
    </div>
  ) : (
    <div className="bg-container ">
      <Container className="bodycontent-pinpass">
        {" "}
        <div className="container-car-pin">
          <Card className="card-pin">
            <div className="title-car-pinpass">
              <h2>No se encuentra una ruta asociada a esta busqueda</h2>
              <hr></hr>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default PinpassFrom;
