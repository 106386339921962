import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDocs, getDoc, collection } from "firebase/firestore";

function useObtenerDetallesdecuenta(uid) {
  const [accountDetailsHistory, setAccountDetailsHistory] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [availableservices, setAvailableservices] = useState(null);
  const [loadingDetallesdecuenta, setLoading] = useState(false);
  const [errorDetallesdecuenta, setError] = useState(null);

  useEffect(() => {
    if (uid) {
      fetchData(uid);
    }
  }, [uid]);

  const fetchData = async (id) => {
    try {
      const accountants = await getDoc(
        doc(db, `${process.env.REACT_APP_COL_BILLING}`, id)
      );
      var dataResult = accountants.data();
      setAccountDetails(dataResult);
      setAvailableservices(dataResult.availableservices);
      const companiesaccountants = await getDocs(
        collection(db, `${process.env.REACT_APP_COL_BILLING}`, id, "history")
      );
      var dataComp = companiesaccountants.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAccountDetailsHistory(dataComp);
      setError(false);
    } catch (error) {
      //console.log('error')
      setError(true);
    }
  };

  return {
    accountDetails,
    accountDetailsHistory,
    availableservices,
    loadingDetallesdecuenta,
    errorDetallesdecuenta,
  };
}

export default useObtenerDetallesdecuenta;
