import React from "react";
import { Container } from "semantic-ui-react";

const LoadingSpiner = ({ transparent }) => {
  return (
    <div>
      <Container
        id={`${
          !transparent
            ? "smallspinnerloading"
            : "smallspinnerloadingtransparent"
        }`} //"smallspinnerloading"
        className={`row`}
      >
        <div className="loading-container">
          <div className="loading"></div>
          <div className="loadingAbsolute">
            <img
              id="logoSpinnerLoading"
              alt=""
              src={"/static/images/bicciexpress.png"}
            />
          </div>
        </div>
        <div id="loading-text">Cargando..</div>
      </Container>
    </div>
  );
};

export default LoadingSpiner;
