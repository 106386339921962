import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const BulkCreation = () => {
  return (
    <div id="bulk-creation">
      <h1>Creación Masiva</h1>
      <p>Información sobre la creación masiva de registros.</p>

      <div id="bulk-creation-variables">
        <Box p={3}>
          <Typography variant="h4" gutterBottom>
            Creación Masiva
          </Typography>
          <Typography variant="body1" paragraph>
            Esta API permite la creación masiva de órdenes en el sistema de
            Bicci Delivery. Los usuarios pueden subir un archivo que contiene
            múltiples órdenes con detalles como información de productos,
            modificadores, precios, cantidades, y más. Al final del proceso, la
            API retornará un mensaje indicando el éxito o error del
            procesamiento de las órdenes.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Objetivo
          </Typography>
          <Typography variant="body1" paragraph>
            El objetivo principal de esta API es permitir la creación eficiente
            y rápida de múltiples órdenes en el sistema de Bicci Delivery. Esto
            es especialmente útil para grandes volúmenes de datos que necesitan
            ser procesados en una sola operación, asegurando que toda la
            información relevante para la entrega y el procesamiento de las
            órdenes esté correctamente registrada.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Pasos Previos
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Autenticación"
                secondary="Asegúrate de tener las credenciales correctas para acceder a la API, incluyendo un token de autorización."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Preparar los Datos"
                secondary="Reúne toda la información necesaria y asegúrate de que esté correctamente formateada en un archivo, como un archivo Excel."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Formato del JSON"
                secondary="Estructura los datos en el formato adecuado y verifica que el archivo cumple con los requisitos especificados."
              />
            </ListItem>
          </List>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Tipo de Dato</TableCell>
                  <TableCell>Ejemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell colSpan={2}>
                    https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Método</TableCell>
                  <TableCell colSpan={2}>POST</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Headers</TableCell>
                  <TableCell colSpan={2}>
                    <pre>
                      {`
                      headers: { 
                        'companyID': 'FroY2jsadjhsakdkaJHJA', 
                        'Authorization': '••••••', 
                        ...data.getHeaders()
                      },
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Auth Type</TableCell>
                  <TableCell>Bearer Token</TableCell>
                  <TableCell>Token generado vía API</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>companyID</TableCell>
                  <TableCell> FroY2jsadjhsakdkaJHJA</TableCell>
                  <TableCell>
                    CODIGO DE EMPRESA, entregado en el inicio de la integración.{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Body</TableCell>
                  <TableCell>
                    <pre>
                      {`
                    "file": DOCS.xlsx";
                    `}
                    </pre>
                  </TableCell>
                  <TableCell>Documento para creacion masiva,</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Options</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      maxBodyLength: Infinity,
                      `}
                    </pre>
                  </TableCell>
                  <TableCell>
                    Sugerido para cargas superiores a los 300 ordenes
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" gutterBottom>
            Ejemplo de Respuesta
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Respuesta Correcta</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                        "message": "Orders processed successfully",
                        "orders": 3 
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                          "message": "Validation failed",
                          "errors": "Filas Sin ID's en Documentos"
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                        "result": {
                          "code": 400,
                          "message": "Error al subir el archivo"
                        }
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        );
      </div>
    </div>
  );
};

export default BulkCreation;
