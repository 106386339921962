import React, { useState, useEffect } from "react";
import SplitPane from "react-split-pane";
import Introduction from "../pages/Introduction";
import IntegrationEnvironment from "../pages/IntegrationEnvironment";
import ProductionEnvironment from "../pages/ProductionEnvironment";
import LastMileAPI from "../pages/LastMileAPI";
import CreateAPI from "../pages/CreateAPI";
import BulkCreation from "../pages/BulkCreation";
import StateHomologation from "../pages/StateHomologation";
import CodeExample from "./CodeExample";
import CreateAPIObject from "../pages/CreateAPIObject";
import BulkCreationObject from "../pages/BulkCreationObject";

const Content = ({ selectedContent }) => {
  const [selectedExample, setSelectedExample] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getContentComponent = () => {
    switch (selectedContent) {
      case "introduction":
        return <Introduction onSelectExample={setSelectedExample} />;
      case "integration-environment":
        return <IntegrationEnvironment onSelectExample={setSelectedExample} />;
      case "production-environment":
        return <ProductionEnvironment onSelectExample={setSelectedExample} />;
      case "last-mile-api":
        return <LastMileAPI onSelectExample={setSelectedExample} />;
      case "create-api":
        return <CreateAPI onSelectExample={setSelectedExample} />;
      case "bulk-creation":
        return <BulkCreation onSelectExample={setSelectedExample} />;
      case "state-homologation":
        return <StateHomologation onSelectExample={setSelectedExample} />;
      case "create-api-variables":
        return <CreateAPIObject onSelectExample={setSelectedExample} />;
      case "bulk-creation-variables":
        return <BulkCreationObject onSelectExample={setSelectedExample} />;
      default:
        return <Introduction onSelectExample={setSelectedExample} />;
    }
  };

  const examples = {
    introduction: {},
    "integration-environment": {
      EndPoints: `
      https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken
      https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille
      https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload

      `,
    },
    "production-environment": {
      EndPoints: `
      https://us-central1-bicci-7ed2f.cloudfunctions.net/ebiexGenerateCompanyToken
      https://us-central1-bicci-7ed2f.cloudfunctions.net/ebiexCreateOrdersLastMille
      https://us-central1-bicci-7ed2f.cloudfunctions.net/ebiexImportOrdersLastMille/upload
      `,
    },
    "last-mile-api": {
      JavaScript_Fecth: `
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
    "name": "BICCIEXPRESS",
    "companyID": "FroY2jsadjhsakdkaJHJA"
    });

const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
    };

fetch("https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
    `,

      NodeJsAxios: `
const axios = require('axios');
    let data = JSON.stringify({
    "name": "BICCIEXPRESS",
    "companyID": "FroY2jsadjhsakdkaJHJA"
    });

    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken',
    headers: { 
        'Content-Type': 'application/json'
        },
    data : data
            };

    axios.request(config)
        .then((response) => {
        console.log(JSON.stringify(response.data));
        })
    .catch((error) => {
    console.log(error);
    });

            
            
            
            `,

      NodeJs_Native: `

var https = require('follow-redirects').https;
var fs = require('fs');

var options = {
    'method': 'POST',
    'hostname': ' us-central1-bicci-dev-c842f.cloudfunctions.net',
    'path': '/ebiexGenerateCompanyToken',
    'headers': {
        'Content-Type': 'application/json'
    },
    'maxRedirects': 20
};

var req = https.request(options, function (res) {
var chunks = [];

res.on("data", function (chunk) {
    chunks.push(chunk);
});

res.on("end", function (chunk) {
    var body = Buffer.concat(chunks);
    console.log(body.toString());
});

res.on("error", function (error) {
    console.error(error);
});
});

var postData = JSON.stringify({
    "name": "BICCIEXPRESS",
    "companyID": "FroY2jsadjhsakdkaJHJA"
});

req.write(postData);

req.end();
            
            
            
            
            `,

      PHP_cURL: `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
    CURLOPT_URL => 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken',
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => 'POST',
    CURLOPT_POSTFIELDS =>'{
        "name": "BICCIEXPRESS",
        "companyID": "FroY2jsadjhsakdkaJHJA"
}',
CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json'
),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;




            `,

      Python_Request: `
import requests
import json

url = "https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken"

payload = json.dumps({
    "name": "BICCIEXPRESS",
    "companyID": "FroY2jsadjhsakdkaJHJA"
})
headers = {
    'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)






            `,
    },
    "create-api": {
      JavaScript_Fecth: `
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Bearer YOUR_KEY_HERE");

const raw = JSON.stringify({
"data": {
    "companyID": "FroY2jsadjhsakdkaJHJA",
    "orders": [
    {
        "id": 509912125620,
        "date": "07/07/2024",
        "deliveryDate": "07/07/2024",
        "deliveryType": "HD",
        "whcode": "WH123",
        "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
        "comuna": "Lo Barnechea",
        "region": "Metropolitana",
        "country": "Chile",
        "fromLocation": {
        "lat": -33.4592491,
        "lng": -70.6014753
        },
        "toAddress": "farellones norte 50 1102",
        "direcction1": "farellones norte",
        "direcction2": "50",
        "direcction3": "1007",
        "toLocation": {
        "lat": -33.4513036,
        "lng": -70.5935356
        },
        "toAddressComment": "dejar en conserjeria",
        "userPhone": "+56987654321",
        "userEmail": "clientefinal@integracion.cl",
        "userName": "Jhon Smite",
        "product": "Calzado",
        "groceries": [
        {
            "description": "Zapatos postman en test",
            "id": "2czQxEsfDNi1IXqmVBAE_0",
            "name": "Zapatos postman",
            "instructions": "",
            "quantity": 1,
            "price": 10,
            "height": 10,
            "width": 20,
            "length": 30,
            "weight": 2,
            "volume": 6000
        }
        ]
    }
    ]
}
});

const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
};

fetch("https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));



            `,

      NodeJsAxios: `
const axios = require('axios');
let data = JSON.stringify({
"data": {
    "companyID": "FroY2jsadjhsakdkaJHJA",
    "orders": [
    {
        "id": 509912125620,
        "date": "07/07/2024",
        "deliveryDate": "07/07/2024",
        "deliveryType": "HD",
        "whcode": "WH123",
        "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
        "direcction1": "farellones norte",
        "direcction2": "50",
        "direcction3": "",
        "comuna": "Lo Barnechea",
        "region": "Metropolitana",
        "country": "Chile",
        "fromLocation": {
        "lat": -33.4592491,
        "lng": -70.6014753
        },
        "toAddress": "farellones norte 50 1102",
        "toLocation": {
        "lat": -33.4513036,
        "lng": -70.5935356
        },
        "toAddressComment": "dejar en conserjeria",
        "userPhone": "+56987654321",
        "userEmail": "clientefinal@integracion.cl",
        "userName": "Jhon Smite",
        "product": "Calzado",
        "groceries": [
        {
            "description": "Zapatos postman en test",
            "id": "2czQxEsfDNi1IXqmVBAE_0",
            "name": "Zapatos postman",
            "instructions": "",
            "quantity": 1,
            "price": 10,
            "height": 10,
            "width": 20,
            "length": 30,
            "weight": 2,
            "volume": 6000
        }
        ]
    }
    ]
}
});

let config = {
method: 'post',
maxBodyLength: Infinity,
url: 'https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille',
headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer YOUR_KEY_HERE'
},
data : data
};

axios.request(config)
.then((response) => {
console.log(JSON.stringify(response.data));
})
.catch((error) => {
console.log(error);
});



            `,

      NodeJs_Native: `
    var https = require('follow-redirects').https;
    var fs = require('fs');

    var options = {
    'method': 'POST',
    'hostname': ' us-central1-bicci-dev-c842f.cloudfunctions.net',
    'path': '/ebiexCreateOrdersLastMille',
    'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer YOUR_KEY_HERE'
    },
    'maxRedirects': 20
    };

    var req = https.request(options, function (res) {
    var chunks = [];

    res.on("data", function (chunk) {
        chunks.push(chunk);
    });

    res.on("end", function (chunk) {
        var body = Buffer.concat(chunks);
        console.log(body.toString());
    });

    res.on("error", function (error) {
        console.error(error);
    });
    });

    var postData = JSON.stringify({
    "data": {
        "companyID": "FroY2jsadjhsakdkaJHJA",
        "orders": [
        {
            "id": 509912125620,
            "date": "07/07/2024",
            "deliveryDate": "07/07/2024",
            "deliveryType": "HD",
            "whcode": "WH123",
            "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
            "direcction1": "farellones norte",
            "direcction2": "50",
            "direcction3": "",
            "comuna": "Lo Barnechea",
            "region": "Metropolitana",
            "country": "Chile",
            "fromLocation": {
            "lat": -33.4592491,
            "lng": -70.6014753
            },
            "toAddress": "farellones norte 50 1102",
            "toLocation": {
            "lat": -33.4513036,
            "lng": -70.5935356
            },
            "toAddressComment": "dejar en conserjeria",
            "userPhone": "+56987654321",
            "userEmail": "clientefinal@integracion.cl",
            "userName": "Jhon Smite",
            "product": "Calzado",
            "groceries": [
            {
"description": "Zapatos postman en test",
"id": "2czQxEsfDNi1IXqmVBAE_0",
"name": "Zapatos postman",
"instructions": "",
"quantity": 1,
"price": 10,
"height": 10,
"width": 20,
"length": 30,
"weight": 2,
"volume": 6000
            }
            ]
        }
        ]
    }
    });

    req.write(postData);

    req.end();




            `,

      PHP_cURL: `
 <?php

$curl = curl_init();

curl_setopt_array($curl, array(
CURLOPT_URL => 'https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille',
CURLOPT_RETURNTRANSFER => true,
CURLOPT_ENCODING => '',
CURLOPT_MAXREDIRS => 10,
CURLOPT_TIMEOUT => 0,
CURLOPT_FOLLOWLOCATION => true,
CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
CURLOPT_CUSTOMREQUEST => 'POST',
CURLOPT_POSTFIELDS =>'{
    "data": {
        "companyID": "FroY2jsadjhsakdkaJHJA",
        "orders": [{
            "id": 509912125620,
            "date": "07/07/2024",
            "deliveryDate": "07/07/2024",
            "deliveryType": "HD",
            "whcode": "WH123",
            "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
            "direcction1": "farellones norte",
            "direcction2": "50",
            "direcction3": "9564",
            "comuna": "Lo Barnechea",
            "region": "Metropolitana",
            "country": "Chile",
            "fromLocation": {"lat":-33.4592491, "lng":-70.6014753},
            "toAddress": "farellones norte 50 1102",
            "toLocation": { "lat":-33.4513036, "lng": -70.5935356},
            "toAddressComment": "dejar en conserjeria",
            "userPhone": "+56987654321",
            "userEmail": "clientefinal@integracion.cl",
            "userName": "Jhon Smite",
            "product": "Calzado",
            "groceries": [{
"description": "Zapatos postman en test",
"id": "2czQxEsfDNi1IXqmVBAE_0",
"name": "Zapatos postman",
"instructions": "",
"quantity": 1,
"price": 10,
"height": 10,
"width": 20,
"length": 30,
"weight": 2,
"volume": 6000
            }]
        }]
    }
}',
CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization': 'Bearer YOUR_KEY_HERE'
),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;




            `,

      Python_Request: `
import requests
import json

url = "https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexCreateOrdersLastMille"

payload = json.dumps({
"data": {
    "companyID": "FroY2jsadjhsakdkaJHJA",
    "orders": [
    {
        "id": 509912125620,
        "date": "07/07/2024",
        "deliveryDate": "07/07/2024",
        "deliveryType": "HD",
        "whcode": "WH123",
        "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
        "direcction1": "farellones norte",
        "direcction2": "",
        "direcction3": "50",
        "comuna": "Lo Barnechea",
        "region": "Metropolitana",
        "country": "Chile",
        "fromLocation": {
        "lat": -33.4592491,
        "lng": -70.6014753
        },
        "toAddress": "farellones norte 50 1102",
        "toLocation": {
        "lat": -33.4513036,
        "lng": -70.5935356
        },
        "toAddressComment": "dejar en conserjeria",
        "userPhone": "+56987654321",
        "userEmail": "clientefinal@integracion.cl",
        "userName": "Jhon Smite",
        "product": "Calzado",
        "groceries": [
        {
            "description": "Zapatos postman en test",
            "id": "2czQxEsfDNi1IXqmVBAE_0",
            "name": "Zapatos postman",
            "instructions": "",
            "quantity": 1,
            "price": 10,
            "height": 10,
            "width": 20,
            "length": 30,
            "weight": 2,
            "volume": 6000
        }
        ]
    }
    ]
}
})
headers = {
'Content-Type': 'application/json',
'Authorization': 'Bearer YOUR_KEY_HERE'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)

            `,
    },
    "create-api-variables": {
      JSON: `
{
"data": {
    "companyID": "FroY2jsadjhsakdkaJHJA",
    "orders": [{
        "id": 509912125620,
        "date": "07/07/2024",
        "deliveryDate": "07/07/2024",
        "deliveryType": "HD",
        "whcode": "WH123",
        "fromAddress": "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
        "direcction1": "farellones norte",
        "direcction2": "50",
        "direcction3": "859",
        "comuna": "Lo Barnechea",
        "region": "Metropolitana",
        "country": "Chile",
        "fromLocation": {"lat":-33.4592491, "lng":-70.6014753},
        "toAddress": "farellones norte 50 1102",
        "toLocation": { "lat":-33.4513036, "lng": -70.5935356},
        "toAddressComment": "dejar en conserjeria",
        "userPhone": "+56987654321",
        "userEmail": "clientefinal@integracion.cl",
        "userName": "Jhon Smite",
        "product": "Calzado",
        "groceries": [{
            "description": "Zapatos postman en test",
            "id": "2czQxEsfDNi1IXqmVBAE_0",
            "name": "Zapatos postman",
            "instructions": "",
            "quantity": 1,
            "price": 10,
            "height": 10,
            "width": 20,
            "length": 30,
            "weight": 2,
            "volume": 6000
        }]
    }]
}
            }
            `,
    },
    "bulk-creation": {
      JavaScript_Fecth: `
const myHeaders = new Headers();
    myHeaders.append("companyID", "FroY2jsadjhsakdkaJHJA");
    myHeaders.append("Authorization", "••••••");

    const formdata = new FormData();
    formdata.append("file", fileInput.files[0], "/C:/Users/Admin/Documents/DOCS.xlsx");

const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
};

fetch("https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
});
            `,

      NodeJsAxios: `
const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');
let data = new FormData();
data.append('file', fs.createReadStream('/C:/Users/Admin/Documents/DOCS.xlsx'));

let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload',
headers: { 
    'companyID': 'FroY2jsadjhsakdkaJHJA', 
    'Authorization': '••••••', 
    ...data.getHeaders()
},
data : data
};

axios.request(config)
    .then((response) => {
    console.log(JSON.stringify(response.data));
})
.catch((error) => {
    console.log(error);
});
            `,
      NodeJs_Native: `
var https = require('follow-redirects').https;
var fs = require('fs');

var options = {
    'method': 'POST',
    'hostname': ' us-central1-bicci-dev-c842f.cloudfunctions.net',
    'path': '/ebiexImportOrdersLastMille/upload',
    'headers': {
    'companyID': 'FroY2jsadjhsakdkaJHJA',
    'Authorization': '••••••'
            },
            'maxRedirects': 20
            };

var req = https.request(options, function (res) {
var chunks = [];

res.on("data", function (chunk) {
    chunks.push(chunk);
            });

    res.on("end", function (chunk) {
    var body = Buffer.concat(chunks);
    console.log(body.toString());
            });

    res.on("error", function (error) {
    console.error(error);
            });
            });

var postData = "------WebKitFormBoundary7MA4YWxkTrZu0gW Content-Disposition: form-data; name=file; filename=C:/Users/Admin/Documents/DOCS.xlsx \ \ r \n Content-Type: \ {Insert_File_Content_Type}\\r\n\r\n" + fs.readFileSync('/C:/Users/Admin/Documents/DOCS.xlsx') + "\r\n------WebKitFormBoundary7MA4YWxkTrZu0gW--";

req.setHeader('content-type', 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');

req.write(postData);

req.end();




`,
      PHP_cURL: `
<?php
$curl = curl_init();

curl_setopt_array($curl, array(
    CURLOPT_URL => 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload',
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => 'POST',
    CURLOPT_POSTFIELDS => array('file'=> new CURLFILE('/C:/Users/Admin/Documents/DOCS.xlsx')),
    CURLOPT_HTTPHEADER => array(
    'companyID: FroY2jsadjhsakdkaJHJA',
    'Authorization: ••••••'
    ),
));

$response = curl_exec($curl);
curl_close($curl);
echo $response;


            `,
      Python_Request: ` 
import requests

url = "https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload"

payload = {}
files=[
    ('file',('DOCS.xlsx',open('/C:/Users/Admin/Documents/DOCS.xlsx','rb'),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
    ]
headers = {
    'companyID': 'FroY2jsadjhsakdkaJHJA',
    'Authorization': '••••••'
    }
response = requests.request("POST", url, headers=headers, data=payload, files=files)
print(response.text)
            
            `,
    },

    "bulk-creation-variables": {
      JavaScript_Fecth: `
const myHeaders = new Headers();
myHeaders.append("companyID", "FroY2jsadjhsakdkaJHJA");
myHeaders.append("Authorization", "••••••");

const formdata = new FormData();
formdata.append("file", fileInput.files[0], "/C:/Users/Admin/Documents/DOCS.xlsx");

const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
    };

fetch("https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
    });
                        `,

      NodeJsAxios: `
const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');
let data = new FormData();
data.append('file', fs.createReadStream('/C:/Users/Admin/Documents/DOCS.xlsx'));

let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload',
    headers: { 
        'companyID': 'FroY2jsadjhsakdkaJHJA', 
        'Authorization': '••••••', 
        ...data.getHeaders()
        },
    data : data
    };

axios.request(config)
    .then((response) => {
    console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
    console.log(error);
    });
            `,
      NodeJs_Native: `
var https = require('follow-redirects').https;
var fs = require('fs');

var options = {
    'method': 'POST',
    'hostname': ' us-central1-bicci-dev-c842f.cloudfunctions.net',
    'path': '/ebiexImportOrdersLastMille/upload',
    'headers': {
    'companyID': 'FroY2jsadjhsakdkaJHJA',
    'Authorization': '••••••'
    },
    'maxRedirects': 20
    };

var req = https.request(options, function (res) {
var chunks = [];

res.on("data", function (chunk) {
    chunks.push(chunk);
    });

res.on("end", function (chunk) {
    var body = Buffer.concat(chunks);
    console.log(body.toString());
    });

res.on("error", function (error) {
    console.error(error);
    });
    });

var postData = "------WebKitFormBoundary7MA4YWxkTrZu0gW\r\nContent-Disposition: form-data; name=\file\; filename=\/C:/Users/Admin/Documents/DOCS.xlsx\\r\nContent-Type: \{Insert_File_Content_Type}\\r\n\r\n" + fs.readFileSync('/C:/Users/Admin/Documents/DOCS.xlsx') + "\r\n------WebKitFormBoundary7MA4YWxkTrZu0gW--";

req.setHeader('content-type', 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');

req.write(postData);

req.end();




`,
      PHP_cURL: `
<?php
    $curl = curl_init();

    curl_setopt_array($curl, array(
    CURLOPT_URL => 'https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload',
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => 'POST',
    CURLOPT_POSTFIELDS => array('file'=> new CURLFILE('/C:/Users/Admin/Documents/DOCS.xlsx')),
    CURLOPT_HTTPHEADER => array(
            'companyID: FroY2jsadjhsakdkaJHJA',
            'Authorization: ••••••'
    ),
    ));

    $response = curl_exec($curl);
    curl_close($curl);
    echo $response;


            `,
      Python_Request: ` 
    import requests

    url = "https:// us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload"

    payload = {}
      files=[
        ('file',('DOCS.xlsx',open('/C:/Users/Admin/Documents/DOCS.xlsx','rb'),
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
         ]
         headers = {
         'companyID': 'FroY2jsadjhsakdkaJHJA',
         'Authorization': '••••••'
         }
         response = requests.request("POST", url, headers=headers, data=payload, files=files)
         print(response.text)
            
            
            
            `,
    },
    "state-homologation": {
      EJEMPLO_ENTREGA_JSON: `
{
    "data":
    {
        "id":"979465454",
        "status": "8",    //Estado de entrega
        "date": "2024-07-21T10:30:00Z",
        "receiver": {
            "name": "Juan Pérez",
            "rut": "12345678-9"
        },
        "evidence": {
            "photos": [
            "url_to_photo1.jpg",
            "url_to_photo2.jpg"
            ],
            "digitalSignature": "url_to_signature.jpg",
            "geoReference": {
            "latitude": -33.459229,
            "longitude": -70.645348
            }
        },
        "deliveryDetails": {
            "driverName": "Carlos Gómez",
            "driverCode": "DRV12345",
            "deliveryDate": "2024-07-21T10:30:00Z"
        }
    }
}

            `,
      EJEMPLO_ESTADOS_JSON: `
      # Estados enviados al integrador ( La informacíon contenida puede variar segun el requerimiento)
      # Detalles en el documento de Integración

{
"data":
    {
    "id": "979465454",
    "status": "2",
    "date": "2024-07-21T10:30:00Z"
    }
}


{
"data":
   {
    "id":"979465454",
    "status": "6",    //conductor asignado
    "date": "2024-07-21T10:30:00Z",
    "deliveryDetails": {
        "driverName": "Carlos Gómez",
        "driverCode": "DRV12345"
      }
    }
}

{
"data":
    {
        "id":"979465454",
        "status": "10",    //Estado cancelación
        "date": "2024-07-21T10:30:00Z",
        "reasonCode": "1006",
        "reason": "Paquete no autorizado para la entrega"
    }
}


Ejemplo de respuesta esperada:  Respuesta Correcta 200 OK, "confirm": true, 

{
  "result": {
    "code": 200,
    "status": "10",
    "confirm": true,
  }
}



      `,
      EJEMPLO_CONFIGURACION: `
      #Sub Estados enviados al integrador, el estado en nuestro sistema permanece en pendiente hasta un estado terminal  
       {
        { intState: YOURCODE, state: "YOURCODENAME", reasonCode: "reasonCode01", name: "Cliente ausente" },
        { intState: 1202, state: "1202", reasonCode: "reasonCode02", name: "Dirección incorrecta" },
        { intState: 1203, state: "1203", reasonCode: "reasonCode03", name: "Dirección incompleta" },
        { intState: 1204, state: "1204", reasonCode: "reasonCode04", name: "Acceso restringido" },
        { intState: 1205, state: "1205", reasonCode: "reasonCode05", name: "No se pudo contactar al cliente" },
        { intState: 1206, state: "1206", reasonCode: "reasonCode06", name: "Entrega fuera del horario establecido" },
        { intState: 1207, state: "1207", reasonCode: "reasonCode07", name: "Condiciones climáticas adversas" },
        { intState: 1208, state: "1208", reasonCode: "reasonCode08", name: "Vehículo averiado" },
        { intState: 1209, state: "1209", reasonCode: "reasonCode09", name: "Problemas de tráfico" },
        { intState: 1210, state: "1210", reasonCode: "reasonCode10", name: "Problemas con el paquete" },
        { intState: 1211, state: "1211", reasonCode: "reasonCode11", name: "Problemas técnicos con el equipo de entrega" },
        { intState: 1212, state: "1212", reasonCode: "reasonCode12", name: "Cliente solicitó reprogramación" },
        { intState: 1213, state: "1213", reasonCode: "reasonCode13", name: "Cliente en vacaciones" },
        { intState: 1214, state: "1214", reasonCode: "reasonCode14", name: "Cliente en reunión" },
        { intState: 1215, state: "1215", reasonCode: "reasonCode15", name: "Cliente cambió de dirección sin aviso" },
        { intState: 1216, state: "1216", reasonCode: "reasonCode16", name: "Cliente solicitó entrega en otra ubicación" },
        { intState: 1217, state: "1217", reasonCode: "reasonCode17", name: "Dirección no encontrada" },
        { intState: 1218, state: "1218", reasonCode: "reasonCode18", name: "Paquete retenido por seguridad" },
        { intState: 1219, state: "1219", reasonCode: "reasonCode19", name: "Restricciones de acceso en la zona" },
        { intState: 1220, state: "1220", reasonCode: "reasonCode20", name: "Cliente no respondió al timbre" },
        { intState: 1221, state: "1221", reasonCode: "reasonCode21", name: "Mascota suelta en la propiedad" }
        }
      
      `,

      EJEMPLO_CONFIGURACION_SUB_ESTADOS: `
      #Sub Estados enviados al integrador, el estado en nuestro sistema 
      permanece en pendiente hasta un estado terminal  
      
[
  { intState: YOURCODE, state: "YOURCODENAME", reasonCode: "reasonCode01", name: "Cliente ausente" },
  { intState: 1202, state: "1202", reasonCode: "reasonCode02", name: "Dirección incorrecta" },
  { intState: 1203, state: "1203", reasonCode: "reasonCode03", name: "Dirección incompleta" },
  { intState: 1204, state: "1204", reasonCode: "reasonCode04", name: "Acceso restringido" },
  { intState: 1205, state: "1205", reasonCode: "reasonCode05", name: "No se pudo contactar al cliente" },
  { intState: 1206, state: "1206", reasonCode: "reasonCode06", name: "Entrega fuera del horario establecido" },
  { intState: 1207, state: "1207", reasonCode: "reasonCode07", name: "Condiciones climáticas adversas" },
  { intState: 1208, state: "1208", reasonCode: "reasonCode08", name: "Vehículo averiado" },
  { intState: 1209, state: "1209", reasonCode: "reasonCode09", name: "Problemas de tráfico" },
  { intState: 1210, state: "1210", reasonCode: "reasonCode10", name: "Problemas con el paquete" },
  { intState: 1211, state: "1211", reasonCode: "reasonCode11", name: "Problemas técnicos con el equipo de entrega" },
  { intState: 1212, state: "1212", reasonCode: "reasonCode12", name: "Cliente solicitó reprogramación" },
  { intState: 1213, state: "1213", reasonCode: "reasonCode13", name: "Cliente en vacaciones" },
  { intState: 1214, state: "1214", reasonCode: "reasonCode14", name: "Cliente en reunión" },
  { intState: 1215, state: "1215", reasonCode: "reasonCode15", name: "Cliente cambió de dirección sin aviso" },
  { intState: 1216, state: "1216", reasonCode: "reasonCode16", name: "Cliente solicitó entrega en otra ubicación" },
  { intState: 1217, state: "1217", reasonCode: "reasonCode17", name: "Dirección no encontrada" },
  { intState: 1218, state: "1218", reasonCode: "reasonCode18", name: "Paquete retenido por seguridad" },
  { intState: 1219, state: "1219", reasonCode: "reasonCode19", name: "Restricciones de acceso en la zona" },
  { intState: 1220, state: "1220", reasonCode: "reasonCode20", name: "Cliente no respondió al timbre" },
  { intState: 1221, state: "1221", reasonCode: "reasonCode21", name: "Mascota suelta en la propiedad" }
]
#Ejemplo Estados de No Entrega que Permiten la Devolución
[
  { intState: YOURCODE, state: "YOURCODENAME", reasonCode: "reasonCode01", name: "Cliente rechazó la entrega" },
  { intState: 1302, state: "1302", reasonCode: "reasonCode02", name: "Entrega cancelada por el cliente" },
  { intState: 1303, state: "1303", reasonCode: "reasonCode03", name: "Cliente no reconoció la orden" },
  { intState: 1304, state: "1304", reasonCode: "reasonCode04", name: "Cliente no tenía efectivo para pagar" },
  { intState: 1306, state: "1306", reasonCode: "reasonCode05", name: "Paquete devuelto al remitente" },
  { intState: 1405, state: "1405", reasonCode: "reasonCode06", name: "Paquete dañado durante el transporte" },
  { intState: 1406, state: "1406", reasonCode: "reasonCode07", name: "Paquete devuelto al remitente" },
  { intState: 1407, state: "1407", reasonCode: "reasonCode08", name: "Paquete no autorizado para la entrega" },
  { intState: 1407, state: "1407", reasonCode: "reasonCode09", name: "Retener y devolver alerta de fraude" }
]

#Ejemplo de Estados de No Entrega que Pasan a Conciliaciones  
( con cancelación de la orden )
[
  { intState: YOURCODE, state: "YOURCODENAME", reasonCode: "reasonCode01", name: "En proceso de Devolución" },
  { intState: 1407, state: "1407", reasonCode: "reasonCode02", name: "Paquete extraviado" },
  { intState: 1408, state: "1408", reasonCode: "reasonCode03", name: "Paquete robado en ruta" },
  { intState: 1409, state: "1409", reasonCode: "reasonCode04", name: "Paquete accidente con pérdidas" },
  { intState: 1410, state: "1305", reasonCode: "reasonCode05", name: "Paquete dañado durante el transporte" },
  { intState: 1411, state: "1305", reasonCode: "reasonCode06", name: "Paquete faltante en carga" },
  { intState: 1412, state: "1305", reasonCode: "reasonCode07", name: "Producto incompleto" }
]
      
      `,
    },
  };

  return (
    <div className="content">
      {isMobile ? (
        <div className="splitPaneContent">
          <div className="splitPaneContentText">{getContentComponent()}</div>
          <div>
            <CodeExample
              examples={examples[selectedContent] || {}}
              selectedExample={selectedExample}
              onSelectExample={setSelectedExample}
            />
          </div>
        </div>
      ) : (
        <SplitPane
          id="SplitPaneContent"
          split="vertical"
          minSize={200}
          defaultSize={"50%"}
          style={{ marginLeft: "250px" }}
        >
          <div style={{ padding: "20px", height: "90vh", overflowY: "scroll" }}>
            {getContentComponent()}
          </div>
          <div style={{ padding: "20px", height: "90vh", overflowY: "scroll" }}>
            <CodeExample
              examples={examples[selectedContent] || {}}
              selectedExample={selectedExample}
              onSelectExample={setSelectedExample}
            />
          </div>
        </SplitPane>
      )}
    </div>
  );
};

export default Content;
