import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useGetMessagesBySystem (userEmail ){
 // console.log(userEmail)
    const [bicciMessagesBySystem, setMessagesBySystem] = useState([])
    const [loadMessagesBySystem, setLoadMessagesBySystem] = useState(false)
    const [errorMessagesBySystem, setErrorMessagesBySystem] = useState(null)    
  
    useEffect(()=>{
      fetchData(userEmail)
    },[userEmail])
  
    const fetchData = async (userEmail) => {
      if(userEmail){
        try {
          setLoadMessagesBySystem(true)

          const allMsj = await db.collection(`${process.env.REACT_APP_COL_MSJ}`).doc(userEmail).collection('system')
          .get();
          var dataAllMsj = allMsj.docs.map(doc => ({id: doc.id, ...doc.data()}))
          console.log(dataAllMsj)
          setMessagesBySystem(dataAllMsj)

        } catch (error) {
          //console.log(error)
          setErrorMessagesBySystem(true)
          setMessagesBySystem([])
        }
      }
 
    }  


    return {bicciMessagesBySystem, loadMessagesBySystem, errorMessagesBySystem}
}

export default useGetMessagesBySystem