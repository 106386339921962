import React, { useEffect } from "react";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import mapStylesClear from "../../../../components/MapsGoogle/mapStylesClear";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

const libraries = ["places"];
const mapContainerStyle = {
  height: "90vh",
  width: "100%",
};
const options = {
  styles: mapStylesClear,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 11,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};
const rosettaVehicles = {
  BICYCLING: "Bicicleta",
  VEHICLE: "Bicicleta",
  TWO_WHEELER: "Motorizado",
  DRIVING: "Vehículo",
  WALKING: "Caminante",
};

export default function PlannerMapDistribucions(props) {
  //console.log(props)
  const [markersPending, setMarkersPending] = React.useState(props.markerpoint);
  const [markerGroups, setMarkerGroups] = React.useState(props.markerGroups);
  const [packetAnalisys, setpacketAnalisys] = React.useState(
    props.packetAnalisys
  );

  const [selectedMarker, setSelectedMarker] = React.useState("");

  const [polygonsPath, setPolygons] = React.useState(props.dataPoligons);
  const [visiblePoligon, setVisiblePoligon] = React.useState(
    props.dataPoligons
  );

  const colorIndex = {
    0: "blue",
    1: "#2b0487",
    2: "#ba6e09",
    3: "#A326FF",
    4: "#6425F5",
    5: "#FF8A26",
    6: "#26BAFF",
    7: "#6AF525",
    9: "#C5F539",
  };

  useEffect(() => {
    props.setSelectionedMarkerPing(selectedMarker);
  }, [selectedMarker]);

  useEffect(() => {
    setSelectedMarker(props.selectedMarker);
  }, [props.selectedMarker]);

  useEffect(() => {
    //console.log(props.packetAnalisys)
    console.log(props.markerGroups);
    console.log(props.markerpoint);
    setMarkersPending(props.markerpoint);
    setMarkerGroups(props.markerGroups);

    if (packetAnalisys) {
      setpacketAnalisys(props.packetAnalisys);
    }
    var DataPoligons = JSON.parse(
      localStorage.getItem("BicciToolsClientZones")
    );
    //console.log(DataPoligons.polygonType)
    if (DataPoligons) {
      setPolygons(DataPoligons.polygonType);
      setVisiblePoligon(props.visiblePoligon);
    }
  }, [props.markerpoint && props.markerGroups && props.packetAnalisys]);

  console.log(packetAnalisys);

  return (
    <div>
      <div id="mapsControlRoutes">
        {packetAnalisys.map((item, index) => (
          <div key={item.ID + index}>
            {item.TOTALPACK > 0 ? (
              <div className="metricscontainer">
                <div>
                  <span className="metricscontainerSub">
                    Observaciones Ruta:
                  </span>{" "}
                  {item.ID}
                </div>
                <div>
                  <span className="metricscontainerSub">Peso Total:</span>{" "}
                  {item.PESO.toString().substr(0, 5)} Kg{" "}
                  {item.PESO > 25 ? (
                    <span className="alert-analitys m-2"> Exceso de peso</span>
                  ) : null}
                </div>
                <div>
                  <span className="metricscontainerSub">Volumen Total:</span>{" "}
                  {item.VOLUMEN.toString().substr(0, 5)}{" "}
                  {item.VOLUMEN > 0.07 ? (
                    <span className="alert-analitys m-2">Excede 0.07 m3</span>
                  ) : null}
                </div>
                {/* 0.07 */}
                <div>
                  <span className="metricscontainerSub">Total unidades:</span>{" "}
                  {item.TOTALPACK}{" "}
                  {item.TOTALPACK > 35 ? (
                    <span className="alert-analitys m-2">
                      Tiempo de espera{" "}
                    </span>
                  ) : null}
                </div>
                <div>
                  <span className="metricscontainerSub">
                    Contiene Multibulto?:
                  </span>{" "}
                  {item.MULTIBULTOS === true ? <>Si</> : <>No </>}
                </div>
                <div>
                  <span className="metricscontainerSub">Valor de Carga: $</span>{" "}
                  {item.VALORCARGA}{" "}
                  {item.VALORCARGA > 900000 ? (
                    <span className="alert-analitys m-2">Carga valiosa</span>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div>
        <GoogleMap
          id="mapControl"
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          options={options}
        >
          {polygonsPath &&
          polygonsPath.length > 0 &&
          visiblePoligon === true ? (
            <>
              {polygonsPath.map((Poligon) => (
                <div key={Poligon.id}>
                  <Polygon
                    path={Poligon.coordinates}
                    editable={false}
                    draggable={false}
                    // onMouseUp={onEdit}
                    // onDragEnd={onEdit}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}

                    options={{
                      fillColor: Poligon.fill,
                      fillOpacity: 0.2, //item.fillOpacity,
                      strokeColor: Poligon.strokeColor,
                      strokeOpacity: 0.8, //item.strokeOpacity,
                      strokeWeight: 1,
                    }}
                  />
                </div>
              ))}
            </>
          ) : null}
          <Marker
            key={"detinationid"}
            position={props.destination}
            // onClick={() => {setSelectedMarker ({
            //   "id": "Punto BICCI",
            //   "toAddress": "Origen",
            //   "toAddressLocation": props.destination,
            //   "userName": "",
            //   "userPhone": "+56987654321",
            //   "status": '',
            //   "express": "",
            //});}}
            icon={{
              url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(10, 10),
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          />

          <Marker
            key={"origenid"}
            position={props.origin}
            //   onClick={() => {setSelectedMarker ({
            //     "id": "Punto BICCI",
            //     "toAddress": "Destino",
            //     "toAddressLocation": props.destination,
            //     "userName": "",
            //     "userPhone": "+56987654321",
            //     "status": '',
            //     "express": "",
            // });}}
            icon={{
              url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(10, 10),
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          />
          {
            //  console.log(markerGroups)
          }
          {markerGroups.length > 0 ? (
            <>
              {markerGroups.map((marker, index) => (
                <div key={marker + index}>
                  {markersPending[marker].map((marker) => (
                    <Marker
                      key={
                        marker.id +
                        marker.detailsDoc.SKU +
                        marker.detailsDoc.UNIDADES
                      }
                      position={{
                        lat: marker.toAddressLocation.latitude,
                        lng: marker.toAddressLocation.longitude,
                      }}
                      onClick={() => {
                        setSelectedMarker(marker);
                      }}
                      title={marker.id}
                      //animation= {window.google.maps.Animation.DROP}
                      // icon={{
                      //   url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-05.svg?alt=media&token=c9fef7b1-9ea9-424c-b6ab-d32954349719`,
                      //   origin: new window.google.maps.Point(0, 0),
                      //   anchor: new window.google.maps.Point(15, 15),
                      //   scaledSize: new window.google.maps.Size(35, 35),
                      //   fillColor: '#34495e',
                      //   strokeColor: '#000',
                      // }}

                      onLoad={(marker) => {
                        const customIcon = (opts) =>
                          Object.assign(
                            {
                              //path: 'M 40.84679,0.77854062 C 37.001512,1.4840956 34.038179,2.2954846 30.616235,3.6360406 16.39929,9.1040956 5.8159574,21.169097 2.2176244,36.021041 c -3.316112,13.617222 -1.164167,26.387778 6.314722,37.606111 3.1044436,4.621389 7.2319436,8.784167 13.0880546,13.088063 5.820834,4.30389 12.065,10.44222 15.275278,14.993045 4.656671,6.56167 8.149171,15.06362 10.195281,24.69445 l 0.9525,4.58611 0.3175,-1.94028 c 1.37583,-9.27805 4.09222,-16.96861 8.74889,-24.58861 3.35139,-5.503325 9.03111,-11.818055 13.40555,-14.957775 8.07861,-5.75028 10.61861,-7.97278 14.2875,-12.488336 2.25778,-2.786944 3.56306,-4.868333 5.3975,-8.537222 3.38667,-6.773334 4.7625,-12.770556 4.7625,-20.919722 0,-6.067778 -0.52916,-9.454445 -2.29305,-14.9225 C 87.62513,17.182708 74.57235,5.2588186 58.48568,1.4488186 53.86429,0.35520762 45.04485,0.03770762 40.84679,0.77854062 Z M 56.33374,16.159652 c 11.25361,3.351389 19.47333,11.606389 22.61305,22.648334 1.09361,3.739444 1.30528,10.124722 0.52917,14.216944 -1.19945,6.455833 -3.98639,11.782778 -8.60778,16.439445 -3.63361,3.633611 -6.56167,5.679722 -10.54806,7.3025 -4.72722,1.905 -8.96055,2.645833 -14.00527,2.398888 -5.00945,-0.211666 -7.33778,-0.776111 -11.782782,-2.892777 -9.066389,-4.268611 -15.134167,-11.465278 -17.78,-21.096111 -0.635,-2.363612 -0.776111,-3.774723 -0.776111,-8.149167 0.03528,-6.0325 0.635,-8.784167 2.963333,-13.758333 4.1275,-8.643056 12.558889,-15.416389 21.80167,-17.4625 4.26861,-0.9525 11.78278,-0.776112 15.59278,0.352777 z',
                              path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                              //type:'Icon',
                              //icon: pinImage,
                              color: colorIndex[index],
                              fillColor: colorIndex[index],
                              fillOpacity: 1,
                              strokeColor: colorIndex[index],
                              strokeWeight: 0.3,
                              scale: 1.5,
                              anchor: new window.google.maps.Point(5, 10),
                              //     //translate:180
                              // animation: window.google.maps.Animation.DROP,
                            },
                            opts
                          );
                        // path: 'm 322.43335,187.59519 c -0.07,72.31 -59.84,131.05 -133.32,131 -73.48,-0.05 -133.929995,-59.46 -133.519995,-131.54 0.42,-72.6 60.419995,-131.160003 134.069995,-130.940003 73.44,0.28 132.85,59.060003 132.77,131.480003 z',

                        marker.setIcon(
                          customIcon({
                            fillColor: colorIndex[index],
                            strokeColor: "white",
                            color: colorIndex[index],
                          })
                        );
                      }}
                    />
                  ))}
                </div>
              ))}
              {/* {
                markerGroups.map((marker)=>(<>
                
                           {
                            markersPending[marker].map((marker) => (         
                              <InfoWindow
                              position={{ lat: marker.toAddressLocation.latitude, lng: marker.toAddressLocation.longitude }}
                             //onCloseClick={() => {setSelectedMarker (null);}}
                             >
                             <div>
                               ID
                              
                             </div>
                           </InfoWindow>
                              ))
                              }
                
                </>
                ))
              } */}
              {/*               
               {
                markerGroups.map((marker)=>(<>
                
                           {
                            markersPending[marker].map((marker, index) => (         
                              <InfoWindow 
                              key={Math.random().toString()}
                              position={{ lat: marker.toAddressLocation.latitude, lng: marker.toAddressLocation.longitude }}
                              anchor= {window.google.maps.Point(0, 0)}
                              onClick={() => {setSelectedMarker (marker);}}
                              content= {'<div class="mylabel">The label</div>'}
                              shadowStyle= {1}
                              padding= {0}
                              backgroundColor= {'rgb(57,57,57)'}
                              borderRadius= {5}
                              arrowSize= {10}
                              borderWidth= {1}
                              borderColor= {'#2c2c2c'}
                              disableAutoPan= {true}
                              hideCloseButton= {true}
                              arrowPosition= {30}
                              backgroundClassName= {'transparent'}
                              arrowStyle= {2}
                             //onCloseClick={() => {setSelectedMarker (null);}}
                             >
                            
                         
                               <h5>{index}</h5>
                         
                           </InfoWindow >
                              ))
                              }
                
                </>
                ))
              } */}

              {selectedMarker ? (
                <>
                  {
                    <InfoWindow
                      position={{
                        lat: selectedMarker.toAddressLocation.latitude,
                        lng: selectedMarker.toAddressLocation.longitude,
                      }}
                      onCloseClick={() => {
                        setSelectedMarker(null);
                      }}
                    >
                      <div>
                        <span
                          onClick={() => setSelectedMarker(null)}
                          className="posditonCloseInfo"
                        >
                          x
                        </span>
                        <h5>ID: {selectedMarker.id}</h5>
                        <div>
                          <a
                            className="nonstyle"
                            href={`tel:${selectedMarker.userPhone}`}
                          >
                            Teléfono: {selectedMarker.userPhone}
                          </a>
                        </div>
                        <div>
                          <a
                            className="nonstyle"
                            href={`https://api.whatsapp.com/send?phone=${selectedMarker.userPhone}&text=Soporte%20BICCI%20:`}
                          >
                            WhatsApp: {selectedMarker.userPhone}
                          </a>
                        </div>
                        <div>Nombre : {selectedMarker.userName}</div>
                        <div>Destino : {selectedMarker.toAddress}</div>
                      </div>
                    </InfoWindow>
                  }
                </>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </GoogleMap>
      </div>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button
      className="locate btn-primary m-1"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <ShareLocationIcon></ShareLocationIcon>Encuentrame
    </button>
  );
}
