import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { ingresoUsuarioAccion } from '../../../redux/usuarioDucks'
import { Image } from 'semantic-ui-react'

const BtnAceceder = () => {
    const loading = useSelector(store => store.usuario.loading)
    const dispatch = useDispatch()
    return (
    <div>
            <button 
                className="btn "
                onClick={() => dispatch(ingresoUsuarioAccion())}
                disabled={loading}>
                Acceso rapido con:
                <Image className="brands" src='https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png' />
            </button>
    </div>
  )
}

export default BtnAceceder