import React from "react";
import ExpressCreateOreders from "../ExpressCreateOreders/ExpressCreateOreders";

const CreateOrderExpress = ({ userDataContext, companiesData }) => {
  return (
    <ExpressCreateOreders
      userDataContext={userDataContext}
      companiesData={companiesData}
    ></ExpressCreateOreders>
  );
};

export default CreateOrderExpress;
