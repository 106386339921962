import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Box,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Paper,
  TableContainer,
  Grid,
} from "@mui/material";
import { Theme } from "../../../../Theme/Theme";

const ToolDashResumenOps = (props) => {
  const navigate = useNavigate();
  const [viewBy, setViewBy] = useState("drivers");

  const handleViewChange = (view) => {
    setViewBy(view);
  };

  const viewDriverPerfil = (dataRow) => {
    navigate(`/dashboard/drivers/${dataRow.driverID}`);
  };

  const getViewByGridCardsDrivers = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <Typography variant="h6">Resumen de Operación</Typography>
        <Grid container spacing={2}>
          {props.metricsOps.map((item, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardHeader
                  avatar={
                    <Tooltip title="Ver perfil ...">
                      <Avatar
                        src={item.driverAvatar}
                        sx={{
                          bgcolor: "#f2f2f2",
                          cursor: "pointer",
                          "&:hover": {
                            opacity: 0.7,
                          },
                        }}
                        aria-label="recipe"
                        onClick={() => viewDriverPerfil(item)}
                      />
                    </Tooltip>
                  }
                  title={item.driverName ? item.driverName : "SIN ASIGNACIÓN"}
                  subheader={item.driverName ? item.email : ""}
                  action={
                    <Box>
                      <Button
                        onClick={(e) =>
                          props.handleClickSelectRiderCurrentList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Todos Asignados
                      </Button>
                      <Button
                        onClick={(e) =>
                          props.handleClickSelectRiderPendintList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Solo En ruta
                      </Button>
                      <Button
                        onClick={(e) =>
                          props.handleCopySelectRiderCurrentList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="body2">
                    {item.asignados} Asignados
                  </Typography>
                  <Typography variant="body2">
                    {item.delivery} Finalizados
                  </Typography>
                  <Typography variant="body2">
                    {item.issures} Pendientes
                  </Typography>
                  <Typography variant="body2">
                    {((item.delivery * 100) / item.asignados).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2">
                    {item.locality.map((i, index) => (
                      <span key={index}>
                        {i[Object.keys(i)]} {Object.keys(i)}{" "}
                      </span>
                    ))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const getViewByTableDrivers = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <p>Resumen</p>
        <TableContainer component={Paper} sx={{ maxHeight: "750px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Rider</TableCell>
                <TableCell>Vehículo</TableCell>
                <TableCell>Asignados</TableCell>
                <TableCell>Finalizados</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell>% NS</TableCell>
                <TableCell>Comunas</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.metricsOps.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box>
                      <Typography>Seleccionar Lista</Typography>
                      <Grid container spacing={1} sx={{ maxWidth: "300px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              props.handleClickSelectRiderCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Todos Asignados
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              props.handleClickSelectRiderPendintList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Solo pendientes en ruta
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              props.handleCopySelectRiderCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Copiar todos los id
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              props.createManiFestByCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Imprimir hoja de Ruta
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={item.driverAvatar}
                        sx={{ bgcolor: "#f2f2f2", mr: 2 }}
                        onClick={() => viewDriverPerfil(item)}
                        aria-label="recipe"
                      ></Avatar>
                      <Box>
                        {item.driverName ? (
                          <>
                            <Typography variant="body2">
                              {item.driverName}
                            </Typography>
                            <Typography variant="body2">
                              {item.email}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body2" color="error">
                            SIN ASIGNACIÓN
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{item.vehicleDetailsType}</TableCell>
                  <TableCell>{item.asignados}</TableCell>
                  <TableCell>{item.delivery}</TableCell>
                  <TableCell>{item.issures}</TableCell>
                  <TableCell>
                    {((item.delivery * 100) / item.asignados).toFixed(2)}%
                  </TableCell>
                  <TableCell>
                    {item.locality.map((i, index) => (
                      <Typography key={index}>
                        {i[Object.keys(i)]} {Object.keys(i)}
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const getViewByGridCardsRoutes = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <Typography variant="h6">Resumen de Rutas</Typography>
        <Grid container spacing={2}>
          {props.metricsOpsRoutes.map((route, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardHeader
                  title={
                    route.route !== "noRoute"
                      ? `Ruta: ${route.route}`
                      : "Ruta: SIN ASIGNACIÓN"
                  }
                  subheader={
                    <Box>
                      <Button
                        onClick={(e) =>
                          props.handleClickSelectRiderCurrentList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Todos Asignados
                      </Button>
                      <Button
                        onClick={(e) =>
                          props.handleClickSelectRiderPendintList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Solo En ruta
                      </Button>
                      <Button
                        onClick={(e) =>
                          props.handleCopySelectRiderCurrentList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="body2">
                    {route.assigned} Asignados
                  </Typography>
                  <Typography variant="body2">
                    {route.delivered} Finalizados
                  </Typography>
                  <Typography variant="body2">
                    {route.withIssues} Pendientes
                  </Typography>
                  <Typography variant="body2">
                    {((route.delivered * 100) / route.assigned).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2">
                    {Object.entries(route.comunas).map(
                      ([comuna, count], index) => (
                        <span key={index}>
                          {count} {comuna}{" "}
                        </span>
                      )
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const getViewByGridTablesRoutes = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <p>Resumen</p>
        <Table sx={{ maxWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Ruta</TableCell>
              <TableCell>Asignados</TableCell>
              <TableCell>Finalizados</TableCell>
              <TableCell>Pendientes</TableCell>
              <TableCell>% NS</TableCell>
              <TableCell>Comunas</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.metricsOpsRoutes.map((route, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box>
                    <Typography>Seleccionar Lista</Typography>
                    <Grid container spacing={1} sx={{ maxWidth: "300px" }}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            props.handleClickSelectRiderCurrentList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Todos Asignados
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            props.handleClickSelectRiderPendintList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Solo pendientes en ruta
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            props.handleCopySelectRiderCurrentList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Copiar todos los id
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            props.createManiFestByCurrentList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Imprimir hoja de Ruta
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell>
                  {route.route !== "noRoute" ? route.route : "SIN ASIGNACIÓN"}
                </TableCell>
                <TableCell>{route.assigned}</TableCell>
                <TableCell>{route.delivered}</TableCell>
                <TableCell>{route.withIssues}</TableCell>
                <TableCell>
                  {((route.delivered * 100) / route.assigned).toFixed(2)}%
                </TableCell>
                <TableCell>
                  {Object.entries(route.comunas).map(
                    ([comuna, count], index) => (
                      <Typography key={index}>
                        {count} {comuna}
                      </Typography>
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        padding: "0px",
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        <Button
          onClick={() => handleViewChange("drivers")}
          sx={{
            backgroundColor: viewBy === "drivers" ? "#07b107" : "default",
            color: viewBy === "drivers" ? "white" : "default",
            "&:hover": {
              backgroundColor: viewBy === "drivers" ? "darkgreen" : "default",
            },
          }}
        >
          Ver por Conductores
        </Button>
        {props.metricsOpsRoutes ? (
          <Button
            onClick={() => handleViewChange("routes")}
            sx={{
              backgroundColor: viewBy === "routes" ? "#07b107" : "default",
              color: viewBy === "routes" ? "white" : "default",
              "&:hover": {
                backgroundColor: viewBy === "routes" ? "darkgreen" : "default",
              },
            }}
          >
            Ver por Rutas
          </Button>
        ) : null}
      </Box>

      {viewBy === "drivers" ? (
        <>
          {props.movil === true ? getViewByGridCardsDrivers() : null}
          {props.movil === false ? getViewByTableDrivers() : null}
        </>
      ) : (
        <>
          {props.movil === true ? getViewByGridCardsRoutes() : null}
          {props.movil === false ? getViewByGridTablesRoutes() : null}
        </>
      )}
    </Box>
  );
};

export default ToolDashResumenOps;
