import React from 'react';
import {Container} from 'semantic-ui-react';
import useTitle from '../components/Seo/useTitle';
const Page404 = () => {
  useTitle({title:'404 Pagina no encontrada'})
  return  (
 <Container >
  <div>

{/* 
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Page Not Found</title> */}
<div className="ui grid middle aligned segment purple inverted" >
  <div className="ui column center aligned">
    <div className="ui inverted statistic">
      <div className="value">404</div>
      <div className="label">Error</div>
    </div>

    <div className="ui message red inverted">
      <div className="header">Descripción</div>
      <p>Puede que ingreso de manera incorrecta la dirección URL en su navegador.<br></br>
El origen del problema puede ser debido a que el administrador del sitio web ha movido este contenido o trasladado esa URL.</p>
    </div>
  </div>
</div>

 </div>
 </Container> 
 ); 
};

export default Page404;
