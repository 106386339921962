import React from "react";
import { Form, Input, Container } from "semantic-ui-react";
import { crearUsuarioAccion } from "../../redux/usuarioDucks";
import { useDispatch, useSelector } from "react-redux";
import BtnAceceder from "../Login/buttons/BtnAceceder";
import BtnTebgoCuenta from "../Login/buttons/BtnTebgoCuenta";
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

const FormRegistro = () => {
  const resperror = useSelector((store) => store.usuario.error);

  const activo = useSelector((store) => store.usuario.activo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setName] = React.useState("");
  //  console.log (username);
  const [userLastName, setUserLastName] = React.useState("");
  //  console.log (userLastName);
  const [companyName, setCompanyName] = React.useState("");
  //  console.log (companyName);
  const [email, setEmail] = React.useState("");
  //  console.log (email);
  const [phone, setPhone] = React.useState("");
  //  console.log (phone);
  const [userPreferenCont, setUserPreferenCont] = React.useState("precencial");
  //  console.log (userPreferenCont);
  const [pass, setPass] = React.useState("");
  const [userPassConfirm, setPassConfirm] = React.useState("");
  //  console.log (userPassConfirm);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [userType, setUserType] = React.useState("empresa");
  //  console.log (userType);

  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (activo) {
      navigate("/dashboard/mi-cuenta");
    }
  }, [activo]);

  const registrarUsuarios = (e) => {
    e.preventDefault();
    if (!username.trim()) {
      //    // console.log('El campo nombre es obligatorio')
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!userLastName.trim()) {
      //    // console.log('El campo apellido es obligatorio')
      setError("El campo apellido es obligatorio");
      return;
    }
    if (!email.trim()) {
      // console.log('Ingrese Email')
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      // console.log('Ingrese un numero telefonico')
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!pass.trim()) {
      // console.log('Ingrese Contraseña')
      setError("Ingrese Contraseña");
      return;
    }
    if (!userPreferenCont.trim()) {
      // console.log('Seleccione un medio de contacto')
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      // console.log('Seleccione un tipo de usuario')
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (pass.length < 5) {
      // console.log('Contraseña mayor a 6 caracteres')
      setError("Contraseña mayor a 6 caracteres");
      return;
    }
    if (!userPassConfirm.trim()) {
      // console.log('Ingrese una confirmacion de contraseña')
      setError("Ingrese una confirmacion de contraseña");
      return;
    }
    if (pass === userPassConfirm) {
      // console.log('contraseñas iguales')
    } else {
      // console.log('Debe ingresar contraseñas iguales')
      setError("Debe ingresar contraseñas iguales");
      return;
    }

    if (!checked === true) {
      // console.log('Debe aceptar los terminos y condiciones')
      setError("Debe aceptar los terminos y condiciones");
      return;
    }
    if (!resperror === null) {
      // console.log('Error de acceso')
      setError("Error de acceso");
      return;
    }
    // console.log('Validando usuario...')
    setError(null);
    Login();
  };

  const handleChange = () => {
    setChecked(!checked);
    //  console.log(checked);
  };
  if (checked === true) {
    //   console.log("Acepta terminos y condiciones");
  }

  const Login = React.useCallback(async () => {
    // console.log('is login')
    setSuccess(null);
    try {
      const userData = {
        username: username,
        userLastName: userLastName,
        email: email,
        pass: pass,
        userPhone: phone,
        userPreferenCont: userPreferenCont,
        userType: userType,
        checked: checked,
        companyName: companyName,
      };
      dispatch(crearUsuarioAccion(userData));
    } catch (error) {
      // console.log(error)
    }
  });

  return (
    <Container className="formcontent" id="ajustefromIni">
      <hr></hr>

      <Form onSubmit={registrarUsuarios}>
        {success ? <div className="alert alert-success">{success}</div> : null}
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {resperror ? (
          <div className="alert alert-danger topalerts">
            {resperror}
            <br></br>
            <button className="btn btn-nostyle ">
              <NavLink to="/recuperar-cuenta" exact="true">
                Recuperar contraseña
              </NavLink>
            </button>
          </div>
        ) : null}
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Nombre'
            placeholder="Nombre"
            iconPosition="left"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={username}
            icon="user"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Apellido'
            placeholder="Apellido"
            iconPosition="left"
            type="text"
            onChange={(e) => setUserLastName(e.target.value)}
            value={userLastName}
            icon="user"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Nombre de Empresa'
            placeholder="Nombre de Empresa"
            iconPosition="left"
            type="text"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
            icon="building"
          />
        </Form.Group>
        {/* <Form.Group widths='equal'>            
            <Form.Field label='Tipo de usuario' control='select'  onChange={e => setUserType(e.target.value)}>
              <option value='empresa'>Empresa</option>
              <option value='repartidor'>Repartidor</option>
              <option value='integracion'>Integracion</option> 
            </Form.Field>
          </Form.Group> */}
        <Form.Group widths="equal">
          <Form.Field
            iconPosition="left"
            placeholder="Email"
            control={Input}
            type="email"
            //label='Email'
            icon="at"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            //label='Teléfono'
            placeholder="Teléfono"
            iconPosition="left"
            icon="phone"
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </Form.Group>
        {/* <Form.Group widths='equal'>
            <Form.Field  
            label='Preferencias de contacto' 
            control='select'  onChange={e => setUserPreferenCont(e.target.value)}>
              <option value='correo'>Correo</option>
              <option value='telefono'>Telefono</option>
              <option value='video'>Video</option> 
              <option value='presencial'>Presencial</option>
            </Form.Field>

           </Form.Group> */}
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="password"
            label="Contraseña"
            icon="lock"
            iconPosition="left"
            onChange={(e) => setPass(e.target.value)}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="password"
            icon="lock"
            iconPosition="left"
            label="Repita contraseña"
            onChange={(e) => setPassConfirm(e.target.value)}
          />
        </Form.Group>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={handleChange}
          />
          <span className="slider round"></span>
        </label>
        <span> Acepto terminos y condiciones de BICCI.</span>
        <hr></hr>
        <button className="btn btn-ebiex " type="sutmit">
          Registrase y iniciar
        </button>
      </Form>
      <hr></hr>
      <div>
        <BtnAceceder></BtnAceceder>
        <div>
          <BtnTebgoCuenta></BtnTebgoCuenta>
        </div>
      </div>
    </Container>
  );
};

export default FormRegistro;
