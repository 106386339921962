import React from "react";
import { generateBarcodeBase64 } from "../../components/DocumentoPDFShippingLabelPrint/generateBarcodeBase64";
import { generateQRBase64 } from "../../components/DocumentoPDFShippingLabelPrint/generateQRBase64";
import { pdf } from "@react-pdf/renderer";
import DocumentoPDFShippingLabelPrintBicci from "./DocumentoPDFShippingLabelPrintBicci";

const HandlePrintBiccShipping = async (orderData, orderDetailsCliente) => {
  let orderSelect = orderData;

  const barcodeImage = await generateBarcodeBase64(orderSelect.id);

  const barcodeImageLpn = orderSelect.id
    ? await generateBarcodeBase64(orderSelect.id)
    : "";

  const QRImage = await generateQRBase64(orderSelect.id);

  const blob = await pdf(
    <DocumentoPDFShippingLabelPrintBicci
      order={orderSelect}
      orderDetailsCliente={orderDetailsCliente}
      barcodeImage={barcodeImage}
      barcodeImageLpn={barcodeImageLpn}
      qrImage={QRImage}
    />
  ).toBlob();

  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

export default HandlePrintBiccShipping;
