import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import * as XLSX from "xlsx";
import { updateDoc } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";

const useHandleUpdateRoutesAndPositions = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const uploadDocument = async (document) => {
    setLoading(true);
    setError(null);

    try {
      const data = await document.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets["Plan"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let Localprefix = element.PREFIX ? element.PREFIX : "";
          let companyIdOrder = Localprefix + element.BICCIID.toString();
          let driverLicensePlate = element.driverLicensePlate;
          let orderNumber = parseInt(element.orderNumber);
          let lat = parseFloat(element.Latitud);
          let lng = parseFloat(element.Longitud);
          let aplicCorrection = element.toAddressLocation
            ? element.toAddressLocation === true
              ? true
              : false
            : false;

          console.log("actualiza:", companyIdOrder);
          console.log("driverLicensePlate", driverLicensePlate);
          console.log("orderNumber", orderNumber);
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            // Actualiza el documento con el array modificado
            let updateData = {
              driverLicensePlate: driverLicensePlate,
              orderNumber: orderNumber,
            };

            if (aplicCorrection) {
              updateData.toAddressLocation = new GeoPoint(lat, lng);
            }

            // Actualiza el documento con los datos modificados
            await updateDoc(orderRef, updateData);
            console.log("ACTUALIZANDO INFORMACION DE RUTAS");
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }

        // Simula una llamada a una función asincrónica para subir el documento
        //  await new Promise((resolve) => setTimeout(resolve, 5000));

        const result = {
          response: "Update OK",
        };
        console.log(jsonData);

        setData(result);
      } else {
        throw new Error("El archivo no contiene datos válidos.");
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, uploadDocument };
};

export default useHandleUpdateRoutesAndPositions;
