import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";

export const fetcherSingleUserSuportTickets = async (id) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_SUPPORT);

    const documentRef = doc(refscollection, id);

    const document = await getDoc(documentRef);

    let data = document.data();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

export const fetcherUserSuportTickets = async (id) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_SUPPORT);

    const documentRef = query(refscollection, where("companyId", "==", id));

    const documents = await getDocs(documentRef);

    let data = documents.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};
