export const styleState = {
  Terminado: "alert alert-success",
  8: "alert alert-success",
  C0E: "alert alert-primary",
  C01: "alert alert-info",
  C02: "alert alert-info",
  C03: "alert alert-info",
  R01: "alert alert-success",
  R02: "alert alert-success",
  R03: "alert alert-success",
  R04: "alert alert-success",
  R05: "alert alert-success",
  R06: "alert alert-success",
  CA1: "alert alert-danger",
  CA2: "alert alert-danger",
  CA3: "alert alert-danger",
  CA4: "alert alert-danger",
  Devolucion: "alert alert-danger",
  1: "alert alert-danger",
  99: "alert  colorGreen fontf8",
  1058: "alert alert-primary",
  1059: "alert alert-warning",
  1060: "alert alert-info",
  1061: "alert alert-info",
  1041: "alert alert-danger",
  1046: "alert alert-danger",
  1031: "alert alert-danger",
  1002: "alert alert-danger",
  1005: "alert alert-danger",
  1006: "alert alert-danger",
  1007: "alert alert-danger",
  1008: "alert alert-danger",
  1009: "alert alert-danger",
  1017: "alert alert-danger",
  1019: "alert alert-danger",
  1022: "alert alert-danger",
  1023: "alert alert-danger",
  1030: "alert alert-danger",
  1027: "alert alert-danger",
  1032: "alert alert-danger",
  1034: "alert alert-danger",
  1038: "alert alert-danger",
  1036: "alert alert-danger",
};

export const stlyleClassStates = {
  Terminado: "pinEstatusDelivery",
  Pendiente: "pinEstatusNoCreate",
  Devolucion: "alert alert-danger",
  isRequire: "pinEstatusNoCreate",
  0: "pinEstatusNoCreate",
  1: "pinEstatusInRoute",
  2: "pinEstatusInRoute",
  3: "pinEstatusInRoute",
  4: "pinEstatusInRoute",
  5: "pinEstatusInRoute",
  6: "pinEstatusInRoute",
  7: "pinEstatusDelivery",
  8: "pinEstatusDelivery",
  9: "pinEstatusDelivery",
  10: "pinEstatusDelivery",
};
