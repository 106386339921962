import React from "react";
import { Container, Divider } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { Tab } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { db } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { clientAddCartProduct } from "../../../redux/acccionesCart";
var cuenta = "Sin contenido";
var facturacion = "No se muestran datos";
var productos = "No se muestran datos";

//asignar ruta Dashboard en APP.JSX oculto elemento seleccione_plan

const panes = [
  {
    menuItem: "Productos Disponibles",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {productos}
      </Tab.Pane>
    ),
  },
];
var userUid = "noregister"
var  userState = false
const Seleccionepack = () => {
  const dispatch = useDispatch()
  const [productosActivios, setProductosActivios] = React.useState([]);
  const [plantsData, setPlansData] = React.useState([]);
  const [productoWeb, setProductWeb] = React.useState([]);
  React.useEffect(() => {
    obtenerTodoslosservicios();
    findplansdata();
  }, []);
  try {
    const user = useSelector((store) => store.usuario.user.uid);
    userUid = user 
    userState = true
   } catch (error) {
    userState = false
   }
  const obtenerTodoslosservicios = async () => {
    const producServices = await db.collection(`${process.env.REACT_APP_COL_PRODUCTS}`).get();
    const servicios = producServices.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProductosActivios(servicios);
    // console.log(servicios);
  };
  async function findplansdata() {
    try {
      const paymethods = await db.collection(`${process.env.REACT_APP_COL_PRODUCTS}`).get();
      const data4 = paymethods.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //        // console.log(data2)
      setPlansData(data4);
      const plansActive = data4.filter((item) => item.isActive === true); // Activo
      const plansPost = plansActive.filter((item) => item.isPublic === true); // Is Public
      plansPost.sort((a, b) =>
        parseInt(a.position) > parseInt(b.position) ? 1 : -1
      );
      setProductWeb(plansPost);
      // console.log(plansPost);
    } catch (error) {}
  }
  const addToCard = (e) => {
    let obj = e.target.value
    // console.log(obj, userUid)
     dispatch(clientAddCartProduct(obj,userUid));
   // props.history.push(`/checkout/`)
  }
  productos = (
    <Container className='dashcontent'>
      {productosActivios.map((element) => (
        <Grid.Row key={element.id} className="divmap">
          <Grid.Column
            computer={6}
            mobile={6}
            tablet={6}
            className="dash-container-colmun colmunPd30"
          >
            <Grid.Row className="distproductos">
              <div>
                <h3>{element.productName}</h3>
              </div>
            </Grid.Row>
            <Grid.Row className="distproductos">

              <button onClick={addToCard} value={element.id} className="btncambiar">Seleccionar Pack de envios
                                    </button>
              {/* <Modalhome  /> */}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            computer={6}
            mobile={6}
            tablet={6}
            className="dash-container-colmun colmunPd70"
          >

            <Grid.Row className=" distproductosdescrip">
              <Grid.Column className="dash-container-colmun-equal">
                Plan
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal ">
                <h5>{element.description}</h5> cuenta con : 
                <ul>
                  <li>{element.descriptorsWEB0}</li>
                  <li>{element.descriptorsWEB1}</li>
                  <li>{element.descriptorsWEB2}</li>
                  <li>{element.descriptorsWEB3}</li>
                  </ul>
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal textalignend" >
              <div>Precio   ${element.articlePrice}</div>
              <div>Descuento ${element.discount}</div>
               <div>Total a pagar <strong>${element.total}</strong></div>
               

              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="distproductosdescrip">
              <Grid.Column className="dash-container-colmun-equal ">
                Uso
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal ">
              
                    <h3 key={productosActivios.id}>Se agregarán {productosActivios.ChargeShipments} envíos a tu cuenta.</h3>
              
                
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Container>
  );

  return (
    <Tab
      className="tabular menu"
      menu={{ secondary: true }}
      panes={panes}
    ></Tab>
  );
};

export default Seleccionepack;
