import isEqual from "lodash/isEqual";
import { useState, useCallback } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import { useBoolean } from "../../hooks/use-boolean.js";
import Label from "../Label/Label.jsx";
import Iconify from "../Iconify/Iconify.jsx";
import Scrollbar from "../Scrollbar/Scrollbar.jsx";
import { ConfirmDialog } from "../CustomDialog/index.jsx";
import {
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../Table/index.jsx";

//
import OrdersTableRow from "../Dashboard/dashboard/ComponentOrdersContainerFlabella/OrdersTableRow.jsx";
import OrdersTableToolbar from "../Dashboard/dashboard/ComponentOrdersContainerFlabella/OrdersTableToolbar.jsx";
import OrdersTableFiltersResult from "../Dashboard/dashboard/ComponentOrdersContainerFlabella/OrdersTableFiltersResult.jsx";
import {
  translateStatesfalabbelaSelector,
  translateStatesSelectorBasic,
} from "../../utils/objects/translateStates.jsx";
import { useEffect } from "react";
import UseTableOrdersF from "../Table/UseTableOrdersF.jsx";
import { PropaneSharp } from "@mui/icons-material";
import OrderList from "../Orders/OrderList.jsx";

// ----------------------------------------------------------------------

export default function UserListViewOrdersIntFalabella(props) {
  const defaultRowsPerPage = 1000;

  const [configTable, setConfigTable] = useState({
    defaultRowsPerPage: defaultRowsPerPage,
    defaultDense: true,
    ordersForAccions: props.ordersForAccions,
    allData: props.dataOrdesRequets,
  });

  useEffect(() => {
    setConfigTable({
      defaultRowsPerPage: defaultRowsPerPage,
      defaultDense: true,
      ordersForAccions: props.ordersForAccions,
      allData: props.dataOrdesRequets,
    });
  }, [props.ordersForAccions]);

  const table = UseTableOrdersF(configTable);
  //console.log(props);

  const [userList, setUserList] = useState(props.dataOrdesRequets);

  const _roles = [];

  const _service = props.dataOrdesRequets
    .map((obj) => obj.commune2)
    .filter((value, index, self) => self.indexOf(value) === index);

  const _orige = ["Tobalaba", "Domicos", "Egaña"];

  const _rider = props.dataOrdesRequets
    .map((obj) => obj.driverName)
    .filter((value, index, self) => self.indexOf(value) === index);

  const USER_STATUS_OPTIONS = translateStatesSelectorBasic;

  const USER_OPTIONS_OTERS = [
    { value: "entregado", label: "Entregados" },
    { value: "creado", label: "Creados" },
    { value: "enruta", label: "En Ruta" },
    { value: "excepcion", label: "Excepcion" },
    { value: "excepcionSinMoradores", label: "Sin Moradores" },
    { value: "devolucion", label: "Devolucion" },
  ];

  // ----------------------------------------------------------------------

  const STATUS_OPTIONS = [
    { value: "all", label: "All" },
    ...USER_OPTIONS_OTERS,
  ];

  const STATUS_USER_OPTIONS_OTERS = [
    { value: "allOrders", label: "All" },
    ...USER_OPTIONS_OTERS,
  ];

  const TABLE_HEAD = [
    { id: "order", label: "datos de orden", width: "20%" },
    { id: "client", label: "Destino", width: "20%" },
    { id: "conductor", label: "rider", width: "20%" },
    { id: "estatus", label: "estatus", width: "10%" },
    props.visibleBilling
      ? { id: "facturacion", label: "facturacion", width: "20%" }
      : null,
    { id: "acciones", label: "acciones", width: "10%" },
  ];

  const defaultFilters = {
    name: "",
    role: [],
    rider: [],
    status: "all",
    origen: "",
    service: [],
    orderStatus: "allOrders",
    generalSearch: "",
    int_placeName: "",
    id: "",
    userEmail: "",
    deliveryReceptor: "",
    commerce: "",
    direccion1: "",
    deliveryFailureReason: "",
    commune2: "",
    clientNames: "",
    userID: "",
    companyId: "",
    userName: "",
    int_client_soc: "",
    int_lpn: "",
    toAddress: "",
    commune: "",
    key: "",
    driverVehicle: "",
    polygonID: "",
    dataOrigin: "",
    company: "",
    driverLocation: "",
    driverLicensePlate: "",
    origin: "",
    region: "",
    deliveryPictures: "",
    falabella_status: "",
    routeStartDate: "",
    orderID: "",
    int_placeName: "",
    int_travelDate: "",
    driverID: "",
    deliveryMethod: "",
    routeLocality: "",
    paymentRequestId: "",
    billingPaymentType_service: "",
    fromAddress: "",
    int_falabella_status_descriptor: "",
    int_serviceLevel: "",
    driverTruckDescription: "",
    serviceType: "",
    description: "",
    fromAddressLocation: "",
    int_travelNumber: "",
    driverRut: "",
    productName: "",
    driverEmail: "",
    productSku: "",
    driverPhone: "",
    userPhone: "",
    int_warehouseName: "",
    int_bicciStatus: "",
    billingPaymentRequestType: "",
    int_f12: "",
    driverName: "",
    int_clientDescriptor: "",
    transportOrderSize: "",
    clientEmail: "",
    clientRut: "",
    deliveryRut: "",
    deliveryComment: "",
    direccion2: "",
    falabella_status: "all",
  };

  const defaultFilters2 = {
    clientNames: "",
    clientRut: "",
    toAddress: "",
  };

  //const settings = useSettingsContext();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(userList);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setUserList(props.dataOrdesRequets);
    setTableData(props.dataOrdesRequets);
  }, [props.dataOrdesRequets]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter(
      (row) => !table.selected.includes(row.id)
    );
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  useEffect(() => {
    props.UpdateOrderForAcctions(table.selected);
  }, [table.selected]);

  // const handleEditRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.user.edit(id));
  //   },
  //   [router]
  // );
  const handleEditRow = (id) => {
    console.log(id);
  };

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters("falabella_status", newValue);
    },
    [handleFilters]
  );

  const handleFilterOrdersStatus = useCallback(
    (event, newValue) => {
      handleFilters("orderStatus", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleSelectRider = (value) => {
    console.log("Selected rider:", value);
  };

  const handleCopyOrder = (order) => {
    console.log("Copy order:", order);
  };

  const handlePrintRoute = (order) => {
    console.log("Print route:", order);
  };

  if (props.movil === false) {
    return (
      <>
        <Box maxWidth={"100%"} sx={{ maxWidth: "100%", width: "100%" }}>
          <Card sx={{ maxWidth: "100%", width: "100%" }}>
            <Tabs
              value={filters.status}
              onChange={handleFilterStatus}
              sx={{
                px: 0.5,
                boxShadow: (theme) =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab
                  key={tab.value}
                  iconPosition="end"
                  value={tab.value}
                  label={tab.label}
                  icon={
                    <Label
                      variant={
                        ((tab.value === "all" ||
                          tab.value === filters.falabella_status) &&
                          "filled") ||
                        "soft"
                      }
                      color={
                        (tab.value === "entregado" && "success") ||
                        (tab.value === "creado" && "success") ||
                        (tab.value === "pendiente" && "warning") ||
                        (tab.value === "enruta" && "info") ||
                        (tab.value === "excepcion" && "error") ||
                        (tab.value === "excepcionSinMoradores" && "error") ||
                        (tab.value === "terminado" && "success") ||
                        (tab.value === "devolucion" && "error") ||
                        "default"
                      }
                    >
                      {tab.value === "all" && userList.length}
                      {tab.value === "entregado" &&
                        userList.filter(
                          (user) =>
                            user.falabella_status === "Terminado" ||
                            user.falabella_status === "99" ||
                            user.falabella_status === 99
                        ).length}

                      {tab.value === "creado" &&
                        userList.filter(
                          (user) =>
                            user.falabella_status === "1058" ||
                            user.falabella_status === "1059"
                        ).length}
                      {tab.value === "enruta" &&
                        userList.filter(
                          (user) =>
                            user.falabella_status === "1060" ||
                            user.falabella_status === "1061"
                        ).length}
                      {tab.value === "excepcionSinMoradores" &&
                        userList.filter((user) => user.falabella_status === "1")
                          .length}
                      {tab.value === "devolucion" &&
                        userList.filter(
                          (user) =>
                            user.int_falabella_status === "Devolucion" ||
                            user.int_falabella_status === "devolucion" ||
                            user.int_falabella_status === "1029"
                        ).length}
                      {tab.value === "excepcion" &&
                        userList.filter(
                          (user) =>
                            user.falabella_status === "Pendiente" ||
                            user.falabella_status === "1002" ||
                            user.falabella_status === "1005" ||
                            user.falabella_status === "1007" ||
                            user.falabella_status === "1008" ||
                            user.falabella_status === "1009" ||
                            user.falabella_status === "1017" ||
                            user.falabella_status === "1019" ||
                            user.falabella_status === "1022" ||
                            user.falabella_status === "1023" ||
                            user.falabella_status === "1027" ||
                            user.falabella_status === "1031" ||
                            user.falabella_status === "1032" ||
                            user.falabella_status === "1030" ||
                            user.falabella_status === "1034" ||
                            user.falabella_status === "1036" ||
                            user.falabella_status === "1041" ||
                            user.falabella_status === "1081" ||
                            user.falabella_status === "1038" ||
                            user.falabella_status === "1046" ||
                            user.falabella_status === "1"
                        ).length}
                      {tab.value === "update" &&
                        userList.filter(
                          (user) =>
                            user.falabella_status === "isRequire" ||
                            user.falabella_status === null ||
                            user.falabella_status === undefined
                        ).length}
                    </Label>
                  }
                />
              ))}
            </Tabs>

            <OrdersTableToolbar
              filters={filters}
              onFilters={handleFilters}
              //
              roleOptions={_roles}
              serviceOptions={_service}
              ridersOptions={_rider}
            />
            {canReset && (
              <OrdersTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={dataFiltered.length}
                sx={{ p: 2.5, pt: 0 }}
                orderDataFiltered={dataFiltered}
              />
            )}
            <div>
              <TableContainer
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => "F" + row.id)
                    )
                  }
                  action={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar>
                  <Table
                    size={table.dense ? "small" : "medium"}
                    sx={{ minWidth: 960 }}
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          dataFiltered.map((row) => "F" + row.id)
                        )
                      }
                    />

                    <TableBody>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row) => (
                          <OrdersTableRow
                            key={"F" + row.id}
                            row={row}
                            selected={table.selected.includes("F" + row.id)}
                            onSelectRow={() => table.onSelectRow(row)}
                            onDeleteRow={() => handleDeleteRow("F" + row.id)}
                            onEditRow={() => handleEditRow("F" + row.id)}
                            visibleBilling={props.visibleBilling}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          table.page,
                          table.rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </div>
            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Box>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content={
            <>
              Are you sure want to delete{" "}
              <strong> {table.selected.length} </strong> items?
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />
      </>
    );
  }

  if (props.movil === true) {
    return (
      <OrderList
        orders={dataFiltered}
        onSelectRider={handleSelectRider}
        onCopyOrder={handleCopyOrder}
        onPrintRoute={handlePrintRoute}
      />
    );
  }
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const {
    clientNames,
    generalSearch,
    status,
    orderStatus,
    role,
    orige,
    service,
    rider,
    commune2,
    id,
    lpn,
    do: doProperty,
    customer_email,
    falabella_status,
    weight,
    falabella_id,
    max_delivery_date,
    commune1,
    date,
    createdAt,
    length,
    schedule_range,
    number_trip,
    delivery_type,
    customer_name,
    address,
    width,
    quantity,
    local_provider,
    shipper_id,
    height,
    region,
    customer_phone,
    agreed_date,
    size,
    soc,
    price,
    quantity_packages,
    updatedAt,
    placeName,
    driverName,
    pics,
    toAddressLocation,
    upDatedAtText,
    dateCreateText,
  } = filters;

  const valueOfDo = filters["do"];

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (falabella_status !== "all") {
    if (falabella_status === "entregado") {
      inputData = inputData.filter(
        (user) =>
          user.falabella_status === "Terminado" ||
          user.falabella_status === "99" ||
          user.falabella_status === 99
      );
    }

    if (falabella_status === "creado") {
      inputData = inputData.filter(
        (user) =>
          user.falabella_status === "1058" || user.falabella_status === "1059"
      );
    }
    if (falabella_status === "enruta") {
      inputData = inputData.filter(
        (user) =>
          user.falabella_status === "1060" || user.falabella_status === "1061"
      );
    }

    if (falabella_status === "excepcion") {
      inputData = inputData.filter(
        (user) =>
          user.falabella_status === "Pendiente" ||
          user.falabella_status === "1002" ||
          user.falabella_status === "1005" ||
          user.falabella_status === "1007" ||
          user.falabella_status === "1008" ||
          user.falabella_status === "1009" ||
          user.falabella_status === "1017" ||
          user.falabella_status === "1019" ||
          user.falabella_status === "1022" ||
          user.falabella_status === "1023" ||
          user.falabella_status === "1027" ||
          user.falabella_status === "1031" ||
          user.falabella_status === "1032" ||
          user.falabella_status === "1030" ||
          user.falabella_status === "1034" ||
          user.falabella_status === "1036" ||
          user.falabella_status === "1041" ||
          user.falabella_status === "1081" ||
          user.falabella_status === "1038" ||
          user.falabella_status === "1046" ||
          user.falabella_status === "1"
      );
    }
    if (falabella_status === "excepcionSinMoradores") {
      inputData = inputData.filter((user) => user.falabella_status === "1");
    }
    if (falabella_status === "devolucion") {
      inputData = inputData.filter(
        (user) =>
          user.falabella_status === "Devolucion" ||
          user.falabella_status === "1029"
      );
    }
  }
  // if (orderStatus !== "allOrders") {
  //   inputData = inputData.filter((user) => user.orderStatus === orderStatus);
  // }

  // if (falabella_status !== "all") {
  //   inputData = inputData.filter(
  //     (user) => user.falabella_status == falabella_status
  //   );
  // }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  if (orige) {
    inputData = inputData.filter(
      (user) =>
        user.orige &&
        user.orige.toLowerCase().indexOf(orige.toLowerCase()) !== -1
    );
  }

  if (service.length) {
    inputData = inputData.filter((user) => service.includes(user.commune2));
  }
  if (rider.length) {
    inputData = inputData.filter((user) => rider.includes(user.driverName));
  }

  if (generalSearch) {
    const searchValue = generalSearch.toLowerCase();
    inputData = inputData.filter(
      (user) =>
        (user.clientNames &&
          user.clientNames.toLowerCase().includes(searchValue)) ||
        (user.id && user.id.toString().includes(searchValue.toLowerCase())) ||
        (user.valueOfDo &&
          user.valueOfDo.toString().toLowerCase().includes(searchValue)) ||
        (user.lpn && user.lpn.toString().toLowerCase().includes(searchValue)) ||
        (user.customer_email &&
          user.customer_email.toLowerCase().includes(searchValue)) ||
        (user.commune1 && user.commune1.toLowerCase().includes(searchValue)) ||
        (user.customer_name &&
          user.customer_name.toLowerCase().includes(searchValue)) ||
        (user.address && user.address.toLowerCase().includes(searchValue)) ||
        (user.local_provider &&
          user.local_provider.toLowerCase().includes(searchValue)) ||
        (user.region && user.region.toLowerCase().includes(searchValue)) ||
        (user.customer_phone &&
          user.customer_phone.toLowerCase().includes(searchValue)) ||
        (user.placeName &&
          user.placeName.toLowerCase().includes(searchValue)) ||
        (user.driverName && user.driverName.toLowerCase().includes(searchValue))
    );
  }

  return inputData;
}
