import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import * as XLSX from "xlsx";
import { GoogleMap } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";
import { Icon } from "semantic-ui-react";
import { Tooltip, IconButton, LinearProgress } from "@mui/material";
import { CardHeader, Avatar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const style = {
  position: "absolute",
  top: "20%",
  right: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
const btnBicci = {
  paddingtop: 20,
  bgcolor: "#7d0ee6",
  display: "inlineblock",
  padding: 1,
  borderRadius: 4,
};

//const libraries = ["drawing"];
export default function ModalAccionsDispacth(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.opened);
  const [awaitCarge, setAwaitCarge] = React.useState(false);
  const [dataOrdersForAccions, setDataOrdersForAccions] = React.useState([]);
  const [ordersForAccions, setOrdersForAccions] = React.useState(
    props.ordersForAccions
  );
  const [adressOrigin, setadressOrigin] = React.useState(props.adressOrigin);
  const [origenGeoCode, setorigenGeoCode] = React.useState(props.origenGeoCode);

  const [adressDestiny, setadressDestiny] = React.useState(props.adressDestiny);
  const [destinyGeoCode, setDestinyGeoCode] = React.useState(
    props.destinyGeoCode
  );

  const [linkUrlGoogleMaps, setLinkUrlGoogleMaps] = React.useState("");
  const [ridersRows, setRidersRows] = React.useState(props.ridersDataByCompany);
  const [setectedRider, setSetectedRider] = React.useState(
    props.ridersDataByCompany
  );
  const [setectedRiderName, setSetectedRiderName] = React.useState();
  const [setectedRiderAvatar, setSetectedRiderAvatar] = React.useState();

  const [loadingRoute, setLoadingRoute] = React.useState(null);
  const [loadingMensaje, setloadingMensaje] = React.useState(null);
  const google = window.google;

  React.useEffect(() => {}, [setectedRider]);

  React.useEffect(() => {
    findOriginalAdres(props.dataOrdersForAccions, props.ordersForAccions);
  }, [props.dataOrdersForAccions && props.ordersForAccions]);

  const findOriginalAdres = async (data, orders) => {
    setAwaitCarge(true);
    if (data.length > 0) {
      var fechaMenor = null;
      var fechaMayor = null;

      for (var i = 0; i < data.length; i++) {
        try {
          var fecha = new Date(data[i].date.seconds * 1000); // Convertimos la fecha en segundos a milisegundos

          // Comparamos la fecha actual con las fechas menor y mayor
          if (fechaMenor === null || fecha < fechaMenor) {
            fechaMenor = new Date(fecha);
            fechaMenor.setDate(fecha.getDate() - 1);
          }

          if (fechaMayor === null || fecha > fechaMayor) {
            fechaMayor = new Date(fecha);
            fechaMayor.setDate(fecha.getDate() + 1);
          }
        } catch (error) {
          console.log(data[i].id);
        }
      }

      // console.log("Fecha Menor:", fechaMenor);
      // console.log("Fecha Mayor:", fechaMayor);

      try {
        const ordersDispachtRef = collection(
          db,
          process.env.REACT_APP_COL_ORDERS
        );
        const ordersDispachtRefBicci = collection(
          db,
          process.env.REACT_APP_COL_USERS_ORDERS
        );
        const q = query(
          ordersDispachtRef,
          where("timeStamp", ">=", fechaMenor),
          where("timeStamp", "<=", fechaMayor)
        );
        const querySnapshot = await getDocs(q);

        const ordersPropietary = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(ordersPropietary);
        // Fusionar los datos de ordersPropietary en data
        data.forEach((objeto) => {
          var orderID = "F" + objeto.id;
          console.log(orderID);

          // Buscar el objeto correspondiente en ordersPropietary
          // Buscar el objeto correspondiente en ordersPropietary
          var orderData = ordersPropietary.find((order) => {
            // console.log(order.orderID); // Imprimir el objeto order antes de devolverlo
            // console.log(orderID);

            if (order.orderID === orderID) {
              return true;
            }
            return false;
          });
          console.log(orderData);

          if (orderData) {
            // Agregar las propiedades DIRECCION1 y DIRECCION2 a data
            objeto.DIRECCION1 = orderData.DIRECCION1;
            objeto.DIRECCION2 = orderData.DIRECCION2;
            objeto.DIRECCION3 = orderData.DIRECCION3;
            objeto.COMUNA2 = orderData.COMUNA2;
            objeto.adressGeoNumAddressLatLng =
              orderData.adressGeoNumAddressLatLng;
          } else {
            try {
              callOfBicci();
              console.log("No mach CACHO EL ERROR");
            } catch (error) {
              console.log("NO SE RESOLVIO ERROR");
              console.log(error);
            }

            async function callOfBicci() {
              const falabellaOrderData = await getDoc(
                doc(ordersDispachtRefBicci, orderID)
              );
              console.log(falabellaOrderData.data());
              console.log(falabellaOrderData.data().toAddressLocation.latitude);
              console.log(
                falabellaOrderData.data().toAddressLocation.longitude
              );
              if (falabellaOrderData.exists()) {
                objeto.DIRECCION1 = falabellaOrderData.data().toAddress;
                objeto.adressGeoNumAddressLatLng = {
                  lat: falabellaOrderData.data().toAddressLocation.latitude,
                  lng: falabellaOrderData.data().toAddressLocation.longitude,
                };
              }
            }
          }
        });
        console.log(data);
        setDataOrdersForAccions(data);
      } catch (error) {
        console.log(error);
      }
    }
    setAwaitCarge(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const crearLinkGoogle = () => {
    constructorGoogleLink();
  };

  const crearLinkGoogleLive = () => {
    constructorGoogleLinkLive();
  };

  const constructorGoogleLink = async () => {
    // console.log("BOTON 2");
    function espera() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          //console.log("Procesando...");
          resolve();
        }, 1500);
      });
    }
    const apiDireccion = async (address, country) => {
      console.log(address);
      try {
        const results = await getGeocode(address, country);

        for (let i = 0; i < results[0].address_components.length; i++) {
          let elementAdress = results[0].address_components[i];
          ////console.log(elementAdress["types"][0]);
          switch (elementAdress["types"][0]) {
            case "street_number":
              //console.log(elementAdress.long_name);
              break;
            case "route":
              //console.log(elementAdress.long_name);
              break;
            case "locality":
              //console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_3":
              ////console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_2":
              ////console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_1":
              ////console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "country":
              ////console.log(elementAdress.long_name);
              //console.log(elementAdress.long_name);
              break;
            case "postal_code":
              ////console.log(elementAdress.long_name);
              break;
            default:
              break;
          }
        }

        const { lat, lng } = await getLatLng(results[0]);
        //console.log(lat + "  " + lng);
        return { lat, lng };
      } catch (error) {
        return { lat: 0, lng: 0 };
      }
    };

    const getGeocode = (address, country) =>
      new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address: address,
            componentRestrictions: { country: country },
          },
          (results, status) => {
            if (status === "OK") {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

    if (dataOrdersForAccions.length <= 25) {
      setLoadingRoute(true);
      var directionsService = new google.maps.DirectionsService();

      let routeZonesWaitPoints = [];
      for (let n = 0; n < dataOrdersForAccions.length; n++) {
        //console.log(dataOrdersForAccions);

        if (!dataOrdersForAccions[n].adressGeoNumAddressLatLng) {
          var checkAdress =
            dataOrdersForAccions[n].DIRECCION1 +
            ", " +
            dataOrdersForAccions[n].DIRECCION2 +
            ", " +
            dataOrdersForAccions[n].COMUNA2;
          await espera();
          console.log(checkAdress);
          setloadingMensaje(`Validando dirección : ${checkAdress}`);
          var geopoint = await apiDireccion(checkAdress, "CL");
          dataOrdersForAccions[n].adressGeoNumAddressLatLng = geopoint;
        }

        //console.log(dataOrdersForAccions[n].adressGeoNumAddressLatLng);
        routeZonesWaitPoints.push({
          location: dataOrdersForAccions[n].adressGeoNumAddressLatLng,
          stopover: true,
        });
      }

      var startPoint = new google.maps.LatLng(origenGeoCode);
      var endPoint = new google.maps.LatLng(destinyGeoCode);
      var bounds = new google.maps.LatLngBounds();

      await calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        routeZonesWaitPoints,
        bounds
      );

      function calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        wayPointsArray,
        bounds,
        ZoneName
      ) {
        var resps;
        directionsService.route(
          {
            origin: startPoint,
            destination: endPoint,
            travelMode: "BICYCLING",
            optimizeWaypoints: true,
            waypoints: wayPointsArray,
          },
          function (response, status) {
            if (status === "OK") {
              resps = "ok";
              console.log(response);
              var routesLegs = response.routes[0];
              var placesIDs = response.geocoded_waypoints;
              var urlMapsApiRoute;
              var stringPointsGoogleMaps = "";
              var stringWaitPointsGoogleMaps = "";

              var mapsApiData_origin = "";
              var mapsApiData_waypoints = [];
              var mapsApiData_destination = "";
              var mapsApiData_travelmode = "&travelmode=bicycling";
              var mapsApiData_waypoint_place_ids = [];

              // marca
              console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              console.log("Evaluar si es necesario");
              console.log("destination");
              console.log(origin);
              console.log("Evaluar si es necesario ");

              for (let i = 0; i < routesLegs.legs.length; i++) {
                if (i === 0) {
                  mapsApiData_origin = routesLegs.legs[i].start_address;
                  mapsApiData_origin = mapsApiData_origin.replace(/,+/g, "");
                  mapsApiData_origin = mapsApiData_origin.replace(/\s+/g, "+");
                  mapsApiData_origin = "/" + mapsApiData_origin;

                  let waypoints = routesLegs.legs[i].end_address;
                  waypoints = waypoints.replace(/,+/g, "");
                  waypoints = waypoints.replace(/\s+/g, "+");
                  mapsApiData_waypoints.push(waypoints);
                }

                if (i >= 1 && i < routesLegs.legs.length) {
                  let waypoints2 = routesLegs.legs[i].end_address;
                  waypoints2 = waypoints2.replace(/,+/g, "");
                  waypoints2 = waypoints2.replace(/\s+/g, ",");
                  mapsApiData_waypoints.push(waypoints2);
                }

                if (i === routesLegs.legs.length - 1) {
                  mapsApiData_destination = routesLegs.legs[i].end_address;
                  mapsApiData_destination = mapsApiData_destination.replace(
                    /,+/g,
                    ""
                  );
                  mapsApiData_destination = mapsApiData_destination.replace(
                    /\s+/g,
                    "+"
                  );
                  mapsApiData_destination = "/" + mapsApiData_destination;
                }
              }
              stringPointsGoogleMaps = "/" + mapsApiData_waypoints.join("/");

              for (let n = 0; n < placesIDs.length; n++) {
                mapsApiData_waypoint_place_ids.push(placesIDs[n].place_id);
              }
              stringWaitPointsGoogleMaps =
                "&waypoint_place_ids=" +
                mapsApiData_waypoint_place_ids.join("%7C");
              urlMapsApiRoute =
                "https://www.google.com/maps/dir" +
                mapsApiData_origin +
                stringPointsGoogleMaps +
                "/@-33.4363776,-70.6336738,12z";
              setLinkUrlGoogleMaps(urlMapsApiRoute);

              stringPointsGoogleMaps = "";
              stringWaitPointsGoogleMaps = "";

              mapsApiData_origin = "";
              mapsApiData_waypoints = [];
              mapsApiData_destination = "";
              mapsApiData_travelmode = "&travelmode=bicycling";
              mapsApiData_waypoint_place_ids = [];
            } else {
            }
            setLoadingRoute(null);
            setloadingMensaje(null);
          }
        );
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(resps);
          }, 2000);
        });
      }
    }
  };

  const constructorGoogleLinkLive = async () => {
    setLoadingRoute(true);
    console.log("BOTON 1");
    function espera() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log("Procesando...");
          resolve();
        }, 1000);
      });
    }
    const apiDireccionlive = async (address, country) => {
      try {
        const results = await getGeocode(address, country);
        console.log(address);
        if (results) {
          // Loop through the address components and log them to the console
          for (let i = 0; i < results[0].address_components.length; i++) {
            let elementAdress = results[0].address_components[i];

            switch (elementAdress["types"][0]) {
              case "street_number":
                console.log(elementAdress.long_name);
                break;
              case "route":
                console.log(elementAdress.long_name);
                break;
              case "locality":
                console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_3":
                //console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_2":
                //console.log(elementAdress.long_name);
                //console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_1":
                //console.log(elementAdress.long_name);
                //console.log(elementAdress.long_name);
                break;
              case "country":
                console.log(elementAdress.long_name);
                //console.log(elementAdress.long_name);
                break;
              case "postal_code":
                ////console.log(elementAdress.long_name);
                break;
              default:
                break;
            }
          }
        } else {
          console.log("error en la dirección :", address);
        }

        const { lat, lng } = await getLatLng(results[0]);
        console.log(lat + "  " + lng);
        return { lat, lng };
      } catch (error) {
        console.log(error);
        return { lat: 0, lng: 0 };
      }
    };

    const getGeocode = (address, country) =>
      new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address: address,
            componentRestrictions: { country: country },
          },
          (results, status) => {
            console.log(results);
            if (status === "OK") {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

    if (props.dataOrdersForAccions.length <= 25) {
      var directionsService = new google.maps.DirectionsService();

      //console.log(props.dataOrdersForAccions);
      //console.log(dataOrdersForAccions);

      let routeZonesWaitPoints = [];
      let serachData = props.dataOrdersForAccions;
      for (let n = 0; n < serachData.length; n++) {
        //console.log(props.dataOrdersForAccions);

        if (!serachData[n].adressGeoNumAddressLatLng) {
          var checkAdress = `${serachData[n].DIRECCION1}, ${serachData[n].DIRECCION2},${serachData[n].COMUNA2}, Región Metropolitana, CHILE`;
          await espera();
          setloadingMensaje(`Validando dirección : ${checkAdress}`);

          console.log(checkAdress);

          var geopoint = await apiDireccionlive(checkAdress);
          serachData[n].adressGeoNumAddressLatLng = geopoint;
        }

        // console.log(props.dataOrdersForAccions[n].adressGeoNumAddressLatLng);
        routeZonesWaitPoints.push({
          location: serachData[n].adressGeoNumAddressLatLng,
          stopover: true,
        });
      }

      var startPoint = new google.maps.LatLng(origenGeoCode);
      var endPoint = new google.maps.LatLng(destinyGeoCode);
      var bounds = new google.maps.LatLngBounds();

      console.log(routeZonesWaitPoints);
      await calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        routeZonesWaitPoints,
        bounds
      );

      async function calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        wayPointsArray,
        bounds,
        ZoneName
      ) {
        var resps;
        directionsService.route(
          {
            origin: startPoint,
            destination: endPoint,
            travelMode: "BICYCLING",
            optimizeWaypoints: true,
            waypoints: wayPointsArray,
          },
          function (response, status) {
            if (status === "OK") {
              console.log("response ROUTE");
              resps = "ok";
              console.log(response);
              var routesLegs = response.routes[0];
              var placesIDs = response.geocoded_waypoints;
              var urlMapsApiRoute;
              var stringPointsGoogleMaps = "";
              var stringWaitPointsGoogleMaps = "";

              var mapsApiData_origin = "";
              var mapsApiData_waypoints = [];
              var mapsApiData_destination = "";
              var mapsApiData_travelmode = "&travelmode=bicycling";
              var mapsApiData_waypoint_place_ids = [];

              // marca edicion
              console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              console.log("Evaluar si es necesario");
              console.log("destination");
              console.log(origin);
              console.log("Evaluar si es necesario ");

              for (let i = 0; i < routesLegs.legs.length; i++) {
                if (i === 0) {
                  mapsApiData_origin = routesLegs.legs[i].start_address;
                  mapsApiData_origin = mapsApiData_origin.replace(/,+/g, "");
                  mapsApiData_origin = mapsApiData_origin.replace(/\s+/g, "+");
                  mapsApiData_origin = "/" + mapsApiData_origin;

                  let waypoints = routesLegs.legs[i].end_address;
                  waypoints = waypoints.replace(/,+/g, "");
                  waypoints = waypoints.replace(/\s+/g, "+");
                  mapsApiData_waypoints.push(waypoints);
                }

                if (i >= 1 && i < routesLegs.legs.length) {
                  let waypoints2 = routesLegs.legs[i].end_address;
                  waypoints2 = waypoints2.replace(/,+/g, "");
                  waypoints2 = waypoints2.replace(/\s+/g, ",");
                  mapsApiData_waypoints.push(waypoints2);
                }

                if (i === routesLegs.legs.length - 1) {
                  mapsApiData_destination = routesLegs.legs[i].end_address;
                  mapsApiData_destination = mapsApiData_destination.replace(
                    /,+/g,
                    ""
                  );
                  mapsApiData_destination = mapsApiData_destination.replace(
                    /\s+/g,
                    "+"
                  );
                  mapsApiData_destination = "/" + mapsApiData_destination;
                }
              }

              stringPointsGoogleMaps = "/" + mapsApiData_waypoints.join("/");

              for (let n = 0; n < placesIDs.length; n++) {
                mapsApiData_waypoint_place_ids.push(placesIDs[n].place_id);
              }
              stringWaitPointsGoogleMaps =
                "&waypoint_place_ids=" +
                mapsApiData_waypoint_place_ids.join("%7C");
              urlMapsApiRoute =
                "https://www.google.com/maps/dir" +
                mapsApiData_origin +
                stringPointsGoogleMaps +
                "/@-33.4363776,-70.6336738,12z";
              setLinkUrlGoogleMaps(urlMapsApiRoute);

              stringPointsGoogleMaps = "";
              stringWaitPointsGoogleMaps = "";
              mapsApiData_origin = "";
              mapsApiData_waypoints = [];
              mapsApiData_destination = "";
              mapsApiData_travelmode = "&travelmode=bicycling";
              mapsApiData_waypoint_place_ids = [];
            } else {
            }
            setLoadingRoute(null);
            setloadingMensaje(null);
          }
        );
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(resps);
          }, 2000);
        });
      }
    }
  };

  const asignarConductor = () => {
    var dataAsid = {
      data: {
        driverID: setectedRider,
        orderIDs: ordersForAccions,
      },
    };
    //////console.log(dataAsid);
    dispatch(assingMultipleDriver(dataAsid));
    setOpen(false);
  };

  const columnsRiders = [
    {
      field: "selector",
      headerName: "",
      width: 20,
      renderCell: (ridersRows) => {
        // ////console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={ridersRows.id}
                drivername={ridersRows.row.name}
                data={ridersRows.row.avatar}
                onClick={handleGoPropsRidersRows2}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                {/* <ArrowForwardIosIcon   onClick={handleGoPropsRidersRows3} ></ArrowForwardIosIcon> */}

                <Icon name="angle right" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Seleccione un conductor",
      width: 250,
      minHeight: 70,
      height: 70,
      renderCell: (ridersRows) => {
        //      ////console.log(ridersRows)
        return (
          <>
            <CardHeader
              avatar={
                <Avatar
                  src={ridersRows.row.avatar}
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsRidersRows3}
                  aria-label="recipe"
                ></Avatar>
              }
              title={ridersRows.row.name}
              subheader={ridersRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const handleGoPropsRidersRows2 = (e) => {
    setSetectedRider(e.target.parentNode.id);
    setSetectedRiderAvatar(
      e.target.parentNode.attributes.getNamedItem("data").value
    );
    setSetectedRiderName(
      e.target.parentNode.attributes.getNamedItem("drivername").value
    );

    // setRender(!render)
    //  newRoute.driverID = e.target.parentNode.id
    //  newRoute.driverName =  e.target.parentNode.value
  };

  const exporExcelFileCircuit = (data) => {
    for (let n = 0; n < data.length; n++) {
      if (data[n].DIRECCION1 === undefined) {
      }
      if (data[n].DIRECCION1 === undefined) {
        data[n].DIRECCION1 = "";
      }
      if (data[n].DIRECCION2 === undefined) {
        data[n].DIRECCION2 = "";
      }
      if (data[n].COMUNA2 === undefined) {
        let cadena = data[n].DIRECCION1;
        const partes = cadena.split(",");
        const ultimaCadena = partes[partes.length - 2].trim();
        data[n].COMUNA2 = ultimaCadena;
      }
    }

    const matrizReporte = [
      [
        "Unit/Flat/Unit Name",
        "Address Line 1",
        "Address Line 2",
        "State Zip",
        "Country",
        "Earliest Arrival Time",
        "Latest Arrival Time",
        "Notes",
        "Size",
        "Recipient Name",
        "Type of stop",
        "Recipient Email Address",
        "Recipient Phone Number",
        "Id",
        "Package Count",
        "Products",
        "Seller website",
        "Seller Name",
        "Driver (email or phone number)",
      ],
      ...data.map((dato) => [
        "",
        dato.DIRECCION1 + " " + dato.DIRECCION2,
        "",
        dato.COMUNA2,
        "",
        "CL",
        "",
        "",
        dato.CLIENTENOMBRES,
        10,
        "",
        dato.CLIENTETELEFONO,
        dato.orderID,
        "",
        "",
        "",
        "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `${data[0].driverName} Circuit ${Moment(Date.now()).format(
        "YYYY-MM-DD"
      )}.xlsx`
    );
  };

  const exporExcelFileCircuitfromOps = (data) => {
    for (let n = 0; n < data.length; n++) {
      if (data[n].DIRECCION1 === undefined) {
      }
      if (data[n].DIRECCION1 === undefined) {
        data[n].DIRECCION1 = "";
      }
      if (data[n].DIRECCION2 === undefined) {
        data[n].DIRECCION2 = "";
      }
      if (data[n].COMUNA2 === undefined) {
        let cadena = data[n].DIRECCION1;
        const partes = cadena.split(",");
        const ultimaCadena = partes[partes.length - 2].trim();
        data[n].COMUNA2 = ultimaCadena;
      }
    }
    const matrizReporte = [
      [
        "Unit/Flat/Unit Name",
        "Address Line 1",
        "Address Line 2",
        "City",
        "State Zip",
        "Country",
        "Earliest Arrival Time",
        "Latest Arrival Time",
        "Notes",
        "Size",
        "Recipient Name",
        "Type of stop",
        "Recipient Email Address",
        "Recipient Phone Number",
        "Id",
        "Package Count",
        "Products",
        "Seller website",
        "Seller Name",
        "Driver (email or phone number)",
      ],
      ...data.map((dato) => [
        "",
        dato.DIRECCION1 + " " + dato.DIRECCION2,
        dato.DIRECCION3,
        dato.COMUNA2,
        "",
        "CL",
        "",
        "",
        "",
        dato.size,
        dato.customer_name,
        "",
        "",
        `+569${dato.customer_phone}`,
        dato.id,
        dato.quantity,
        "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `${data[0].driverName} Circuit ${Moment(Date.now()).format(
        "YYYY-MM-DD"
      )}.xlsx`
    );
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        id="modalserv"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {!awaitCarge ? (
          <Fade in={open}>
            <Box sx={style}>
              {props.interfaceOrigen === "facturationOrdes" ? (
                <>
                  {" "}
                  {props.popUpAccion === "RutaBicciexpress" ? (
                    <>En desarrollo</>
                  ) : null}
                  {props.popUpAccion === "GoogleMaps" ? (
                    <div>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Link {props.popUpAccion}
                      </Typography>
                      <div id="transition-modal-description" sx={{ mt: 2 }}>
                        <div className="textModalContent">
                          Paradas : {props.dataOrdersForAccions.length}
                        </div>
                        <div className="textModalContent">
                          Origen : {props.adressOrigin}
                        </div>
                        <div className="textModalContent">
                          Destino : {props.adressDestiny}
                        </div>
                        <div>
                          {loadingMensaje === null ? null : (
                            <span>{loadingMensaje}</span>
                          )}
                        </div>
                        <div>
                          {loadingRoute ? null : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                        {/* <div>{linkUrlGoogleMaps}</div>      */}
                        {linkUrlGoogleMaps ? (
                          <>
                            <button
                              className="btn-edit-guide"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  linkUrlGoogleMaps
                                );
                              }}
                            >
                              {" "}
                              Copiar Link de Ruta
                            </button>

                            <a
                              className="btn-edit-guide"
                              href={linkUrlGoogleMaps}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Abrir en navegador
                            </a>
                          </>
                        ) : null}

                        <GoogleMap></GoogleMap>

                        {dataOrdersForAccions.length <= 23 ? (
                          <>
                            {" "}
                            <div></div>
                            <div className="positionSelector"></div>
                            <div>
                              <button
                                className="btn-edit-guide"
                                onClick={crearLinkGoogle}
                              >
                                {" "}
                                Generar link
                              </button>
                            </div>
                            <div></div>
                          </>
                        ) : (
                          <div className="textModalContent">
                            {" "}
                            Excede los 25 Puntos, es un limite de paradas de api
                            de Google Maps
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {props.popUpAccion === "Asignacion" ? (
                    <div>
                      <Box
                        sx={{
                          width: "100%",
                          height: 350,
                          backgroundColor: "#fafafa",
                        }}
                      >
                        {ridersRows ? (
                          <DataGrid
                            className="DataGridStyle"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                            rows={ridersRows}
                            columns={columnsRiders}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                      <div>
                        {setectedRider ? (
                          <>
                            <CardHeader
                              avatar={
                                <Avatar
                                  src={setectedRiderAvatar}
                                  sx={{ bgcolor: "#f2f2f2" }}
                                  aria-label="recipe"
                                ></Avatar>
                              }
                              title={setectedRiderName}
                            />
                          </>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-ebiex extentButton"
                        onClick={asignarConductor}
                      >
                        {" "}
                        Asignar conductor{" "}
                      </button>
                    </div>
                  ) : null}
                  {props.popUpAccion === "ExcelFile" ? (
                    <>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Crear {props.popUpAccion} de ruta para Circuit
                      </Typography>
                      <div className="textModalContent">
                        <div className="textModalContent">
                          {" "}
                          Listo para descargar:
                        </div>
                        <div className="textModalContent">
                          {" "}
                          contenido : {props.dataOrdersForAccions.length}
                        </div>
                        <button
                          onClick={() =>
                            exporExcelFileCircuit(props.dataOrdersForAccions)
                          }
                          className="btn btn-ebiex m-2 btn-reporte-excel"
                        >
                          {" "}
                          Descargar Ruta
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {props.interfaceOrigen === "liveOrders" ? (
                <>
                  {" "}
                  {props.popUpAccion === "RutaBicciexpress" ? (
                    <>En desarrollo</>
                  ) : null}
                  {props.popUpAccion === "GoogleMaps" ? (
                    <div>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Link {props.popUpAccion}
                      </Typography>
                      <div id="transition-modal-description" sx={{ mt: 2 }}>
                        <div className="textModalContent">
                          Paradas : {props.dataOrdersForAccions.length}
                        </div>
                        <div className="textModalContent">
                          Origen : {props.adressOrigin}
                        </div>
                        <div className="textModalContent">
                          Destino : {props.adressDestiny}
                        </div>
                        <div>
                          {loadingMensaje === null ? null : (
                            <span>{loadingMensaje}</span>
                          )}
                        </div>
                        <div>
                          {!loadingRoute ? null : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                        {/* <div>{linkUrlGoogleMaps}</div>      */}
                        {linkUrlGoogleMaps ? (
                          <>
                            <button
                              className="btn-edit-guide"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  linkUrlGoogleMaps
                                );
                              }}
                            >
                              {" "}
                              Copiar Link de Ruta
                            </button>

                            <a
                              className="btn-edit-guide"
                              href={linkUrlGoogleMaps}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Abrir en navegador
                            </a>
                          </>
                        ) : null}

                        <GoogleMap></GoogleMap>

                        {dataOrdersForAccions.length <= 23 ? (
                          <>
                            {" "}
                            <div></div>
                            <div className="positionSelector"></div>
                            <div>
                              <button
                                className="btn-edit-guide"
                                onClick={crearLinkGoogleLive}
                              >
                                {" "}
                                Generar link
                              </button>
                            </div>
                            <div></div>
                          </>
                        ) : (
                          <div className="textModalContent">
                            {" "}
                            Excede los 25 Puntos, es un limite de paradas de api
                            de Google Maps
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {props.popUpAccion === "Asignacion" ? (
                    <div>
                      <Box
                        sx={{
                          width: "100%",
                          height: 350,
                          backgroundColor: "#fafafa",
                        }}
                      >
                        {ridersRows ? (
                          <DataGrid
                            className="DataGridStyle"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                            rows={ridersRows}
                            columns={columnsRiders}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                      <div>
                        {setectedRider ? (
                          <>
                            <CardHeader
                              avatar={
                                <Avatar
                                  src={setectedRiderAvatar}
                                  sx={{ bgcolor: "#f2f2f2" }}
                                  aria-label="recipe"
                                ></Avatar>
                              }
                              title={setectedRiderName}
                            />
                          </>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-ebiex extentButton"
                        onClick={asignarConductor}
                      >
                        {" "}
                        Asignar conductor{" "}
                      </button>
                    </div>
                  ) : null}
                  {props.popUpAccion === "ExcelFile" ? (
                    <>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Crear {props.popUpAccion} de ruta para Circuit
                      </Typography>
                      <div className="textModalContent">
                        <div className="textModalContent">
                          {" "}
                          Listo para descargar:
                        </div>
                        <div className="textModalContent">
                          {" "}
                          contenido : {dataOrdersForAccions.length}
                        </div>
                        <button
                          onClick={() =>
                            exporExcelFileCircuitfromOps(
                              props.dataOrdersForAccions
                            )
                          }
                          className="btn btn-ebiex m-2 btn-reporte-excel"
                        >
                          {" "}
                          Descargar Ruta
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Fade>
        ) : (
          <LinearProgress></LinearProgress>
        )}
      </Modal>
    </div>
  );
}
