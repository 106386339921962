import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

const mensajesDatosFaltantes = {
  id: "Falta un ID válido",
  direcction1: "Falta la calle en el detalle de destino",
  toAddress: "Falta la dirección de destino",
  userPhone: "Falta un número de teléfono",
  userEmail: "Falta un correo electrónico",
  userName: "Falta el nombre del usuario",
  groceries:
    "No se está informando sobre los productos en el contenido de envío. Se agrega el valor NO INFORMA al contenido de su orden",
  fromAddress: "Falta la dirección de origen",

  product: "Falta definir el producto a enviar",
};

const OrderTicket = ({ order, recentCreated }) => {
  const faltantes = [];
  if (!order.id) faltantes.push(mensajesDatosFaltantes.id);
  if (!order.fromAddress) faltantes.push(mensajesDatosFaltantes.fromAddress);
  if (!order.toAddress) faltantes.push(mensajesDatosFaltantes.toAddress);
  if (!order.direcction1) faltantes.push(mensajesDatosFaltantes.direcction1);
  if (!order.userPhone) faltantes.push(mensajesDatosFaltantes.userPhone);
  if (!order.userEmail) faltantes.push(mensajesDatosFaltantes.userEmail);
  if (!order.userName) faltantes.push(mensajesDatosFaltantes.userName);
  if (!order.product) faltantes.push(mensajesDatosFaltantes.product);
  if (!order.groceries || order.groceries.length === 0) {
    faltantes.push(mensajesDatosFaltantes.groceries);
  }

  useEffect(() => {}, [recentCreated]);

  return (
    <>
      {faltantes.map((mensaje, index, recentCreated) =>
        faltantes.length === 1 &&
        mensaje === mensajesDatosFaltantes["groceries"] ? (
          <Box
            sx={{
              backgroundColor: "#f8f8f8",
              m: 1,
              width: "400px",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1),",
              padding: "16px",
              border: "1px solid #ddd",
            }}
          >
            <Typography key={index} sx={{ color: "red" }}>
              <>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "green",
                    marginBottom: "8px",
                  }}
                >
                  {recentCreated !== false
                    ? "Ya puede crear el envío !!!"
                    : "Creado exitosamente !!!"}
                </Typography>
              </>
            </Typography>
          </Box>
        ) : null
      )}

      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          margin: "16px 0",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          color: "#333",
          width: "400px",
          m: 1,
          fontFamily: "'Courier New', Courier, monospace",
          padding: "16px",
          border: "1px solid #ddd",
          borderTop: "dashed 2px #000",
          borderBottom: "dashed 2px #000",
        }}
      >
        {/* Ticket Header */}
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          {order.whcode || "Sin Código"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            marginBottom: "8px",
          }}
        >
          Fecha de Entrega: {order.deliveryDate || "Sin Fecha"}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          ID del Pedido: {order.id || "Sin ID"}
        </Typography>

        <Box
          sx={{
            borderTop: "1px dashed #000",
            marginBottom: "16px",
          }}
        />

        {/* Order Details */}
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            Detalles del Pedido
          </Typography>

          {/* Description and Price */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Descripción</Typography>
            <Typography>Valor</Typography>
          </Box>

          {order.groceries.length > 0 ? (
            order.groceries.map((item, index) => (
              <Box
                key={index}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>{item.name || "Sin nombre"}</Typography>
                <Typography>{item.price ? `$${item.price}` : ""}</Typography>
              </Box>
            ))
          ) : (
            <Typography>No hay productos</Typography>
          )}

          <Box
            sx={{
              borderTop: "1px dashed #000",
              margin: "16px 0",
            }}
          />

          {/* Total */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Total de Artículos
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {order.product}
            </Typography>
          </Box>

          {/* Delivery Type and Insurance */}
          <Box sx={{ marginTop: "16px" }}>
            <Typography>
              Tipo de Entrega: {order.deliveryType || "Sin Tipo"}
            </Typography>
            <Typography>Frágil: {order.fragile ? "Sí" : "No"}</Typography>
            <Typography>
              Multi Bulto: {order.multipack ? "Sí" : "No"}
            </Typography>
            <Typography>
              Activación Seguro: {order.insurance ? "Sí" : "No"}
            </Typography>
          </Box>

          <Box
            sx={{
              borderTop: "1px dashed #000",
              margin: "16px 0",
            }}
          />

          {/* Address Info */}
          <Box>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", marginBottom: "8px" }}
            >
              Destino
            </Typography>
            <Typography>
              Dirección: {order.toAddress || "No especificada"}
            </Typography>
            <Typography>
              Ubicación: {order.toLocation.lat || "N/A"},{" "}
              {order.toLocation.lng || "N/A"}
            </Typography>
            <Typography>Contacto: {order.userName || "Sin nombre"}</Typography>
            <Typography>Correo: {order.userEmail || "Sin correo"}</Typography>
            <Typography>
              Teléfono: {order.userPhone || "Sin teléfono"}
            </Typography>
          </Box>

          <Box
            sx={{
              borderTop: "1px dashed #000",
              margin: "16px 0",
            }}
          />

          {/* Missing Data Section */}
          {faltantes.length > 0 ? (
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "red", marginBottom: "8px" }}
              >
                Faltan los siguientes datos:
              </Typography>
              {faltantes.map((mensaje, index) => (
                <Typography key={index} sx={{ color: "red" }}>
                  {faltantes.length === 1 &&
                  mensaje === mensajesDatosFaltantes["groceries"] ? (
                    <>
                      {mensaje} <br></br>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: "green",
                          marginBottom: "8px",
                        }}
                      >
                        Ya puede crear el envío !!!
                      </Typography>
                    </>
                  ) : (
                    mensaje
                  )}
                </Typography>
              ))}
            </Box>
          ) : (
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "green", marginBottom: "8px" }}
              >
                Todo listo para envíar !!!
              </Typography>
            </Box>
          )}

          {/* Footer */}
          <Typography
            variant="body2"
            sx={{ textAlign: "center", fontWeight: "bold", marginTop: "16px" }}
          >
            ¡GRACIAS POR ENVIAR CON BICCI!
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default OrderTicket;
