import React from "react";
import { useSelector } from "react-redux";
import useGlobalConfig from "../../../hooks/globalConfig";
import { useEffect } from "react";
const Logo = (props) => {
  const { siteName, logoOficialUrl1x4 } = useGlobalConfig();
  const activo = useSelector((store) => store.usuario.activo);
  useEffect(() => {}, [props, props.width]);
  return (
    <div className={activo ? "logo_content_dash" : "logo_content"}>
      {props && props.width ? (
        <>
          {" "}
          <img
            style={{ width: props.width ? `${props.width}px` : "auto" }}
            src={`${logoOficialUrl1x4}`}
            alt={siteName}
          />
        </>
      ) : (
        <>
          {" "}
          <img
            style={{ width: props.width ? `${props.width}px` : "auto" }}
            src={`${logoOficialUrl1x4}`}
            alt={siteName}
          />
        </>
      )}
    </div>
  );
};

export default Logo;
