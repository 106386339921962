import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { Box, Container, Card, CardHeader, Divider } from "@mui/material";
import FrmRidersLastMille from "../../../components/formularios/FrmRidersLastMille/FrmRidersLastMille";
import FrmRidersRetail from "../../../components/formularios/FrmRidersRetail/FrmRidersRetail";
import { useParams } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";

const PostulacionesTypes = (props) => {
  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const { type } = useParams();

  const [fromType, setFromType] = React.useState();

  React.useEffect(() => {
    console.log(type);
    setFromType(type);
  }, [type]);
  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <div className="bodypost">
      <section className="pattern" id="toppattern">
        <section>
          <section className="pattern3">
            <div className="goostyle">
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={5} mobile={16} tablet={5}>
                    <div className="toptitlecontainer">
                      <h2 className="titleflota">
                        {fromType === "last-mille"
                          ? "Únete a la Flota de Última Milla de Bicci Supermercados o Farmacias"
                          : null}
                        {fromType === "retail"
                          ? "Únete a la Flota de Última Milla de Bicci Retail"
                          : null}
                      </h2>
                      <h5>Postula como BICCI-RIDER</h5>
                      {/* <button className="btn btn-ebiex-dark">
                        {" "}
                        ¡Inscríbite ahora!
                      </button> */}
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={11} mobile={16} tablet={11}>
                    {fromType === "last-mille" ? (
                      <img
                        className="imgLogoFlotaML"
                        src="/static/images/asset/006_1.png"
                        alt=""
                      ></img>
                    ) : null}
                    {fromType === "retail" ? (
                      <img
                        className="imgLogoFlotaRetail"
                        src="/static/images/asset/005_1.png"
                        alt=""
                      ></img>
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </section>
        </section>
      </section>

      <div className="bodypostsecionContainer">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={6} mobile={16} tablet={6}>
              <div className="bodytitlecontainer">
                <h2 className="titleflotaBody">
                  Contesta el formulario, es importante para nosotros según tu
                  ubicación y tipo de vehículo, asignarte una zona óptima de
                  trabajo.
                </h2>
                <h5>Postula como BICCI-RIDER</h5>
                <h2> ¡Inscríbite ahora!</h2>
                {/* <button className="btn btn-ebiex-dark">
                  {" "}
                  ¡Inscríbite ahora!
                </button> */}
              </div>
            </Grid.Column>
            <Grid.Column
              computer={10}
              mobile={16}
              tablet={10}
              sx={{ padingBoton: "50px" }}
            >
              <div className="bodypostsecion">
                {fromType === "last-mille" ? (
                  <FrmRidersLastMille></FrmRidersLastMille>
                ) : null}
                <br></br>
                {fromType === "retail" ? (
                  <FrmRidersRetail></FrmRidersRetail>
                ) : null}
                {fromType !== "retail" && fromType !== "last-mille"
                  ? "No encontramos lo que estas buscando..  contactanos"
                  : null}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {/* <div className="bodypostcontentsecion">

      </div> */}
    </div>
  );
};

export default PostulacionesTypes;
