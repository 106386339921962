import React, { useEffect, useCallback } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon,
  DrawingManager,
} from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import mapStylesClear from "../../../../components/MapsGoogle/mapStylesClear";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

const libraries = ["places"];
const mapContainerStyle = {
  height: "90vh",
  width: "90%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 11,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};
const rosettaVehicles = {
  BICYCLING: "Bicicleta",
  VEHICLE: "Bicicleta",
  TWO_WHEELER: "Motorizado",
  DRIVING: "Vehículo",
  WALKING: "Caminante",
};

export default function PlannerMapDistribucionEstimate(props) {
  // console.log(props);
  const [markersPending, setMarkersPending] = React.useState(null);
  const [path, setPath] = React.useState([]);
  const [selectedPolygon, setSelectedPolygon] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = React.useState("");
  const [colorFill, setColor] = React.useState("#591e8f");
  useEffect(() => {
    if (props.markerpoint[0].adressGeoNumAddressLatLng) {
      console.log(props.markerpoint);
      setMarkersPending(props.markerpoint);
    }
  }, [props.markerpoint]);

  // Mira el estado path con useEffect
  React.useEffect(() => {
    console.log("The path state is", path);
    findPoints();
  }, [path]);

  const google = window.google;

  const polygonRef = React.useRef(null);

  const listenersRef = React.useRef([]);

  const onPolygonComplete = (polygon) => {
    polygonRef.current = polygon;
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    setPath(newCoordinates);
    polygon.setDraggable(true);
    polygon.setEditable(true);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("dragend", onEdit);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("onLoad", onLoad);
    polygon.addListener("onUnmount", onUnmount);
    // setSelectedPolygon is not defined in your provided code
    // setSelectedPolygon({
    //   polygon: polygon,
    //   coordinates: newCoordinates,
    // });
  };

  const onEdit = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    } else {
      console.log("NOT CURRENT");
    }
  }, []);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      console.log(path);
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const [polygonOptions, setPolygonOptions] = React.useState({
    editable: true,
    draggable: true,
    // onMouseUp and onDragEnd should be function handlers, not objects
    // onLoad and onUnmount are not valid polygon options
    strokeWeight: 1,
    fillOpacity: 0.3,
    fillColor: "#010101",
    strokeColor: "#010101",
    strokeOpacity: 0.8,
  });

  const onSelectPolygon = (polygon) => {
    polygonRef.current = polygon;
    setSelectedPolygon({
      polygon: polygonRef.current,
      coordinates: polygonRef.path,
    });
  };

  async function findPoints() {
    console.log("markersPending");
    const polygon = new google.maps.Polygon({
      paths: path,
    });
    if (markersPending) {
      const markersInPolygon = markersPending.filter((marker) => {
        const markerPosition = new window.google.maps.LatLng(
          marker.adressGeoNumAddressLatLng.lat,
          marker.adressGeoNumAddressLatLng.lng
        );
        console.log("Validate", markerPosition);

        const isInside = google.maps.geometry.poly.containsLocation(
          markerPosition,
          polygon
        );
        console.log(isInside);
        return isInside;
      });

      console.log(markersInPolygon);

      if (markersInPolygon) {
        markersInPolygon.forEach((marker) => {
          console.log("ID:", marker.SOC);
          console.log("Position:", marker.adressGeoNumAddressLatLng);
          // Imprimir más información del marcador si es necesario
        });
        props.setSeleccionByMapDataWaitPoints(markersInPolygon);
      } else {
        console.log("No detecto nuevas marcas");
      }
    }
  }

  function getdata() {
    //polygon.addPolygon(polygonOptions);
    setPolygonOptions({ ...polygonOptions, fillColor: colorFill.hex });
  }

  return markersPending ? (
    <div>
      <div className="titleDistribucion">
        <h2>Selecciona la distribución mediante poligonos</h2>
      </div>
      <div className="mapControlStyle">
        <GoogleMap
          id="mapControl2"
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          options={options}
        >
          <DrawingManager
            onPolygonComplete={onPolygonComplete}
            defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  // google.maps.drawing.OverlayType.CIRCLE,
                  // google.maps.drawing.OverlayType.RECTANGLE,
                  google.maps.drawing.OverlayType.POLYGON,
                ],
              },
              polygonOptions: polygonOptions,
            }}
          ></DrawingManager>
          {selectedPolygon && (
            <Polygon
              paths={selectedPolygon.coordinates}
              onClick={() => onSelectPolygon(selectedPolygon.polygon)}
              // Resto de las propiedades
              editable={true}
              draggable={true}
              onMouseUp={onEdit}
              onDragEnd={onEdit}
              onLoad={onLoad}
            />
          )}
          {
            //  console.log(markerGroups)
          }
          {markersPending.length > 0 ? (
            <>
              <div>
                {markersPending.map((marker, index) => (
                  <Marker
                    key={index}
                    position={marker.adressGeoNumAddressLatLng}
                    onClick={() => {
                      setSelectedMarker(marker);
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          {selectedMarker ? (
            <>
              {
                <InfoWindow
                  position={selectedMarker.adressGeoNumAddressLatLng}
                  onCloseClick={() => {
                    setSelectedMarker(null);
                  }}
                >
                  <div>
                    <span
                      onClick={() => setSelectedMarker(null)}
                      className="posditonCloseInfo"
                    >
                      x
                    </span>
                    <h5>ID: {selectedMarker.SOC}</h5>
                    <div>
                      <a
                        className="nonstyle"
                        href={`tel:9${selectedMarker.CLIENTETELEFONO}`}
                      >
                        Teléfono: {selectedMarker.CLIENTETELEFONO}
                      </a>
                    </div>
                    <div>
                      <a
                        className="nonstyle"
                        href={`https://api.whatsapp.com/send?phone=${selectedMarker.CLIENTETELEFONO}&text=Soporte%20BICCI%20:`}
                      >
                        WhatsApp: {selectedMarker.CLIENTETELEFONO}
                      </a>
                    </div>
                    <div>Nombre : {selectedMarker.CLIENTENOMBRES}</div>
                    <div>
                      Destino : {selectedMarker.DIRECCION1}{" "}
                      {selectedMarker.DIRECCION2} {selectedMarker.DIRECCION3}{" "}
                      {selectedMarker.OMUNA2}
                    </div>
                  </div>
                </InfoWindow>
              }
            </>
          ) : null}
        </GoogleMap>
      </div>
    </div>
  ) : null;
}
