import React from 'react'
import  { useEffect } from 'react'
import {
    Card,
    Divider,
    CardContent,
    Box,
  } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
const DashIconsResumenVehicles = (props) => {

  useEffect(()=>{

    },[props.cant])


  const COLORS = {
    Disponibles:'dashIcon-green',
    Conectados:'dashIcon-on-line',
    Bloqueados:'dashIcon-grey',
  }
  const CANT = {
    Disponibles:props.cant.Disponibles,
    Conectados:props.cant.Conectados,
    Bloqueados:props.cant.Bloqueados,
  }
  return (
    <>
    {
        props.items.map(item => (     
        <span key={item.id} >
        <Card  
        className='DashIconsResumencard3'
        sx={{ alignItems: 'lef',
        display: 'inline-block',
        flexDirection: 'column',
        margin: '5px',
        border: '1px solid #cbcbcb',
        radius: '8px',
      }}>
<CardContent className="dashIconsresumencardcontent">
<LocalShippingIcon className={`dashIconVehicles ${COLORS[item.id]}`} /> 
<Divider />
  <h3 className="dashicontitle">{item.id}</h3>

    <Box>
      <span className="dashicontspanbigtext">{`${CANT[item.id]}`}</span><span className="dashicontspansmalltext">Uni</span>
    </Box>
  </CardContent>

</Card></span> ))
    }
    </>

  )
}

export default DashIconsResumenVehicles