import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { ScreenSizeContext } from "../../context/ScreenSizeProvider";

import Box from "@mui/material/Box";
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Paper,
  Avatar,
  CardActions,
} from "@mui/material";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import CancelIcon from "@mui/icons-material/Cancel";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import PersonIcon from "@mui/icons-material/Person";
import FormRouteInfoBicci from "./FormRouteInfoBicci";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Moment from "moment";
import {
  createDocumentRoute,
  createLocalData,
  defineViewsRouteMapsReducer,
  getDataDispachstGroupByDriverIdToManifes,
  getDataDispachstGroupByDriverIdToReports,
  getDriverConfig,
  obtenerDocumentRoute,
  updateDocumentRoute,
  uptateDriverConfig,
} from "../../redux/routesLiveAccions";
import { useNavigate } from "react-router-dom";
import DynamicRouteBicciMapComponent from "./DynamicRouteBicciMapComponent";
import Logo from "../header/logo/Logo";
import { format } from "date-fns";
import ModalAccionsDispacthDirectionsResolve from "../ModalAccionsDispacth/ModalAccionsDispacthDirectionsResolve";
import { registrarNotifyArrival } from "../../redux/accionsRegister";
import { PDFViewer } from "@react-pdf/renderer";
import ReporteRutaPDF from "./ReporteRutaPDF";
import useGlobalConfig from "../../hooks/globalConfig";

const libraries = ["places", "geometry"];
var popUpAccion = "-";

const DynamicRouteBicciDistributionByReport = (props) => {
  // console.log(props);
  const storePayload = useSelector((store) => store.accionsLiveRoutes);
  const dispatch = useDispatch();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();
  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);
  const [riderReport, setRiderReport] = useState(null);
  const [riderReportDone, setRiderReportDone] = useState(null);
  const { widthHeight } = useContext(ScreenSizeContext);
  const { idRider, idRoute } = useParams();

  const [welcome, setwelcome] = React.useState(true);

  const [reloadMap, setReloadMap] = React.useState(false);

  const [viewSelectedMarker, setViewSelectedMarker] = React.useState(null);

  const [selectedMarker, setSelectedMarker] = React.useState(null);

  const [reloadMapLoaded, setReloadMapLoaded] = React.useState(false);

  const [apiResponseOrderLabelsMenu, setApiResponseOrderLabelsMenu] =
    React.useState(false);

  const [localRiderData, setLocalriderData] = React.useState(null);

  const [viewRiderDataAsist, setViewRiderDataAsist] = React.useState(null);

  const [viewConfig, setviewConfig] = React.useState(null);

  const [currentState, setCurrentState] = React.useState({
    origin: {
      lat: -33.459119,
      lng: -70.598923,
    },
    destination: {
      lat: -33.459119,
      lng: -70.598923,
    },
    originAdress: "Hub Bicci Ñuñoa",
    destinationAdress: "Hub Bicci Ñuñoa",
    responseService: null,
    usedRiderLocations: true,
    requestDir: false,
    polyLine: null,
    hubOrigin: {
      lat: -33.4592446,
      lng: -70.6014753,
    },
    travelMode: "BICYCLING",
    optimizeWaypoints: true,
    wayPoints: [],
    ordersList: null,
    showEdithOptionOrigin: false,
    showEdithOptionDestini: false,
    arrayCurrentList: [],
    responseApi: null,
  });

  const [currentRuteData, setCurrentRuteData] = React.useState(null);
  //const [currentRuteData, setCurrentRuteData] = React.useState(null);

  const [showPopUp, setShowPopUp] = React.useState(false);

  const { translateStateBicciFalabella } = useGlobalConfig();

  useEffect(() => {
    if (idRider) {
      getvalidationAsist(idRider);
    }
  }, [riderReport, idRider]);

  useEffect(() => {
    const localData = localStorage.getItem("localDataRoute");
    const parsedLocalData = JSON.parse(localData);
    props.setLandScrenMenu(true);
    if (idRoute) {
      if (parsedLocalData) {
        initInterfaceByData(parsedLocalData);
        //setwelcome(false);
        // setRenderMap(!renderMap);
      } else {
        dispatch(
          getDataDispachstGroupByDriverIdToReports(
            idRider,
            idRoute,
            currentState.origin,
            currentState.destination
          )
        );
        dispatch(obtenerDocumentRoute(idRoute));
      }
    }
  }, [idRoute]);

  useEffect(() => {
    async function getRider(idRider) {
      try {
        // Debería imprimir la fecha actual en formato YYMMDD
        const riderRef = collection(
          db,
          process.env.REACT_APP_COL_USERS_DRIVERS
        );
        let riderData = await getDoc(doc(riderRef, idRider));
        // console.log(riderData.data());
        setLocalriderData(riderData.data());
      } catch (error) {
        console.log(error);
      }
    }

    if (!localRiderData && idRider) {
      getRider(idRider);
    }
  }, [idRider]);

  useEffect(() => {
    if (storePayload && currentState) {
      if (
        !storePayload.directionsServiceOptionaAgrupations &&
        storePayload.dataOrdesForRouteGoogleServicesRoutes
      ) {
        let data = storePayload.dataOrdesForRouteGoogleServicesRoutes;
        let confi = currentState;
        let currentOrderList = storePayload.currentOrderList;
        dispatch(
          defineViewsRouteMapsReducer(idRoute, data, confi, currentOrderList)
        );
      } else {
        initInterface(storePayload);
      }
      if (storePayload.viewConfig) {
        setviewConfig(storePayload.viewConfig);
      }
    } else {
    }
  }, [storePayload]);

  useEffect(() => {
    if (currentState) {
      dispatch(
        getDataDispachstGroupByDriverIdToReports(
          idRider,
          idRoute,
          currentState.origin,
          currentState.destination
        )
      );

      dispatch(obtenerDocumentRoute(idRoute));
      dispatch(getDriverConfig(idRider));
      if (storePayload && storePayload.source !== null) {
        let data = storePayload.dataOrdesForRouteGoogleServicesRoutes;
        let confi = currentState;
        let currentOrderList = storePayload.currentOrderList;
        //let dataObject = "testdevp";
        dispatch(
          defineViewsRouteMapsReducer(idRoute, data, confi, currentOrderList)
        );
      }
    }
  }, [currentState]);

  const initInterface = (storePayload) => {
    if (storePayload && storePayload.dataOrdesForRouteGoogleServicesRoutes) {
      let resultadoEsperado = [];
      let ArrayC;
      if (storePayload.documentRoute) {
        let offset = 0;

        for (const key in storePayload.documentRoute.waypoint_order) {
          for (const num of storePayload.documentRoute.waypoint_order[key]) {
            resultadoEsperado.push(num + offset);
          }
          offset += storePayload.documentRoute.waypoint_order[key].length;
        }

        for (let i = 0; i < resultadoEsperado.length; i++) {
          resultadoEsperado[i] = resultadoEsperado[i] + 1;
        }

        ArrayC = storePayload.dataOrdesBicciByRider.sort((a, b) => {
          return (
            resultadoEsperado.indexOf(a.position) -
            resultadoEsperado.indexOf(b.position)
          );
        });

        for (let y = 0; y < ArrayC.length; y++) {
          ArrayC[y].position = y + 1;
        }
      }
      setCurrentRuteData({
        currentOrderList: storePayload.currentOrderList,
        dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
        dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
        dataOrdesBicciByRider: storePayload.documentRoute
          ? ArrayC
          : storePayload.dataOrdesBicciByRider,
        dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
        dataOrdesForRouteGoogleServicesRoutes:
          storePayload.dataOrdesForRouteGoogleServicesRoutes,
        dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
        directionsServiceOptionaAgrupations:
          storePayload.directionsServiceOptionaAgrupations,
        isPointGroups: storePayload.isPointGroups,
        polylines: storePayload.polylines,
        documentRoute: storePayload.documentRoute,
        source: "remote",
        load: true,
      });
      //setwelcome(false);
      setReloadMap(true);
    }
  };

  async function getvalidationAsist(idRider) {
    try {
      // Debería imprimir la fecha actual en formato YYMMDD
      const riderRef = collection(db, "xpress_positionAttendanceRecord");

      let date = new Date();
      function format(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      let dateDay = format(date);

      const q = query(
        riderRef,
        where("driverID", "==", idRider),
        where("date", "<=", dateDay)
      );

      let riderDataAsisDoc = await getDocs(q);

      const riderDataAsist = riderDataAsisDoc.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (riderDataAsist.length > 0) {
        setRiderReport(true);
        if (riderDataAsist.length >= 2) {
          setRiderReportDone(true);
        }
        setViewRiderDataAsist(riderDataAsist);
      } else {
        setRiderReport(false);
      }
    } catch (error) {
      setRiderReport(false);
    }
  }

  const initInterfaceByData = (storePayload) => {
    if (storePayload) {
      setCurrentRuteData({
        currentOrderList: storePayload.currentOrderList,
        dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
        dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
        dataOrdesBicciByRider: storePayload.dataOrdesBicciByRider,
        dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
        dataOrdesForRouteGoogleServicesRoutes:
          storePayload.dataOrdesForRouteGoogleServicesRoutes,
        dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
        directionsServiceOptionaAgrupations:
          storePayload.directionsServiceOptionaAgrupations,
        isPointGroups: storePayload.isPointGroups,
        polylines: storePayload.polylines,
        documentRoute: storePayload.documentRoute,
        source: "local",
        load: false,
      });
      setReloadMap(true);
    }
  };

  const updateState = (property, value) => {
    setCurrentState((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const savelocaData = async () => {
    localStorage.removeItem("localDataRoute");
    let SavelocalDataRoute = {
      currentOrderList: storePayload.currentOrderList,
      dataOrderDeliverySusses: storePayload.dataOrderDeliverySusses,
      dataOrdersExcepcions: storePayload.dataOrdersExcepcions,
      dataOrdesBicciByRider: storePayload.dataOrdesBicciByRider,
      dataOrdesBicciByRiderGroups: storePayload.dataOrdesBicciByRiderGroups,
      dataOrdesForRouteGoogleServicesRoutes:
        storePayload.dataOrdesForRouteGoogleServicesRoutes,
      dataOrdesForRouteIds: storePayload.dataOrdesForRouteIds,
      directionsServiceOptionaAgrupations:
        storePayload.directionsServiceOptionaAgrupations,
      isPointGroups: storePayload.isPointGroups,
      polylines: storePayload.polylines,
      documentRoute: storePayload.documentRoute,
      source: "local",
      load: true,
    };

    dispatch(createLocalData(SavelocalDataRoute));
  };

  const handleMarkerClosed = () => {
    setSelectedMarker(null);
    setViewSelectedMarker(null);
  };

  const handleClosedFroms = () => {
    updateState("showEdithOptionOrigin", false);
    updateState("showEdithOptionDestini", false);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setViewSelectedMarker(marker);
  };

  const handleAceptRoute = async (idRoute, idRider) => {
    if (!idRoute) {
      console.log("error idRoute");
      return;
    }
    try {
      const planningData = {
        companyId: props.usuarioStorage.company
          ? props.usuarioStorage.company
          : "", // current Document
        createBy: idRider, // current Document
        createDate: Moment(Date.now()).format("DD/MM/YYYY"), // current Document
        currentOrderList: currentRuteData.currentOrderList, // current Document
        date: Date.now(), // current Document
        dateDelivery: Moment(Date.now()).format("YYYYMMDD"), // current Document
        dateDeliveryText: Moment(Date.now())
          .format("YYYY-MM-DD hh.mm.ss")
          .toString(), // current Document
        distanceValue: "", // current Document
        driverID: idRider, // current Document
        driverName: localRiderData.name, // current Document
        id: idRoute, // current Document
        routePoints: currentState.wayPoints, // current Document
        startTimeDelivery: Moment(Date.now()).format("hh:mm:ss").toString(), // current Document
        vehicleID: "", // current Document
        destination: currentState.destination, // currentState Request
        destinationAdress: currentState.destinationAdress, // currentState Request
        optimizeWaypoints: currentState.optimizeWaypoints, // currentState Request
        origin: currentState.origin, // currentState Request
        originAdress: currentState.originAdress, // currentState Request
        travelMode: currentState.travelMode, // currentState Request
        wayPoints: currentState.wayPoints, // currentState Request
        warnings: "isRequire", // responseApi ? responseApi.warnings : "",
        waypoint_order: "isRequire", //responseApi.legs.
        polylines: "isRequire", //responseApi.legs.,
        indications: "isRequire", //responseApi.legs.,
      };
      console.log(planningData);
      dispatch(createDocumentRoute(idRoute, planningData));
    } catch (error) {}
    handleClosedFroms();
    setwelcome(false);
  };

  const upDateDocRoute = async (e) => {
    try {
      const planningData = {
        destination: currentState.destination,
        destinationAdress: currentState.destinationAdress,
        optimizeWaypoints: currentState.optimizeWaypoints,
        origin: currentState.origin,
        originAdress: currentState.originAdress,
        travelMode: currentState.travelMode,
        // warnings: responseApi ? responseApi.warnings : "",
        wayPoints: currentState.wayPoints,
        waypoint_order: currentState.ordersList,
      };
      dispatch(updateDocumentRoute(idRoute, planningData));
      handleClosedFroms();
    } catch (error) {
      setwelcome(true);
    }
  };

  const setApliConfig = (routeConfig) => {
    dispatch(uptateDriverConfig(idRider, routeConfig));
  };

  async function handleReloadClick() {
    dispatch(
      getDataDispachstGroupByDriverIdToManifes(
        idRider,
        idRoute,
        currentState.origin,
        currentState.destination
      )
    );
    dispatch(obtenerDocumentRoute(idRoute));
    setReloadMap(false);
  }

  const handleReloadPageClick = () => {
    window.location.reload();
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  const navigateToMapRider = async (e, idRider) => {
    // console.log(e);
    // console.log(item);
    //navigate(`/dashboard/planner/${eID}/edit`);
    let fecha = new Date(); // Fecha actual

    let year = fecha.getFullYear().toString().slice(-2); // Obtiene el año y lo convierte a cadena, luego toma los últimos dos dígitos
    let month = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1. Luego se convierte a cadena, y se le agrega un 0 al inicio si solo tiene un dígito
    let day = fecha.getDate().toString().padStart(2, "0"); // Obtiene el día y lo convierte a cadena, luego se le agrega un 0 al inicio si solo tiene un dígito

    let fechaStr = year + month + day;

    navigate(`/route/live/${idRider}/${localRiderData.bicciNumber}${fechaStr}`);
  };

  const notifyArrival = () => {
    if (riderReport === false) {
      let data = {
        date: new Date(),
        driverID: localRiderData.id,
        name: localRiderData.name,
        position: localRiderData.driverLocation,
        type: "in",
      };
      dispatch(registrarNotifyArrival(data));
      setRiderReport(true);
    }
    if (riderReport === true) {
      let data = {
        date: new Date(),
        driverID: localRiderData.id,
        name: localRiderData.name,
        position: localRiderData.driverLocation,
        type: "out",
      };

      dispatch(registrarNotifyArrival(data));
      setRiderReportDone(true);
    }
  };

  if (loadError) {
    return "Error";
  }

  if (!isLoaded) {
    return "Loading...";
  }
  return (
    <Grid celled="internally" id="expresscuentaContent">
      {/* <h2 className="alternativeColorDhas">Tu ruta de entregas con bicci</h2>{" "} */}
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          {/* <div className="logo-top-container">
            <Logo />
          </div> */}
          {currentRuteData && currentState ? (
            <FormRouteInfoBicci
              handleReloadClick={handleReloadClick}
              dataOrdesBicciByRider={currentRuteData.dataOrdesBicciByRider}
              apiResponseOrderLabelsMenu={apiResponseOrderLabelsMenu}
              dataOrdesBicciByRiderGroups={
                currentRuteData.dataOrdesBicciByRiderGroups
              }
              dataOrderDeliverySusses={currentRuteData.dataOrderDeliverySusses}
              dataordersexcepcions={currentRuteData.dataOrdersExcepcions}
              orderslist={currentState.ordersList}
              originadress={
                currentRuteData.documentoRuote
                  ? currentRuteData.documentoRuote.originAdress
                  : currentState.originAdress
              }
              destinationadress={
                currentRuteData.documentoRuote
                  ? currentRuteData.documentoRuote.destinationAdress
                  : currentState.destinationAdress
              }
              destination={currentState.destination}
              origin={origin}
              viewConfig={viewConfig}
              updateState={updateState}
              setApliConfig={setApliConfig}
            ></FormRouteInfoBicci>
          ) : null}
          <div>
            {localRiderData ? (
              <>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Card
                        className="carnetRider"
                        sx={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              paddingLeft: "10%",
                              paddingRight: "10%",
                              width: "100%",
                            }}
                          >
                            <Avatar
                              src={localRiderData.avatar}
                              sx={{
                                height: 100,
                                mb: 2,
                                width: 100,
                              }}
                            />
                            <div>
                              {" "}
                              Hola <strong>{localRiderData.name}</strong>, En
                              este sitio, te ofrecemos una serie de herramientas
                              y funciones para facilitar tu labor de entrega de
                              paquetes. Queremos asegurarnos de que cuentes con
                              todas las herramientas necesarias para hacer tu
                              trabajo de manera eficiente y efectiva.
                              {/* <strong>{localRiderData.dni}</strong> */}
                            </div>

                            <br></br>
                            {/* <div className="alignItemscenter">
                              <div>Estatus Bicci</div>
                              {localRiderData.active === true ? (
                                <span className="activo">Activo</span>
                              ) : (
                                <span className="inactivo">Inactivo</span>
                              )}{" "}
                            </div> */}
                            <div className="alignItemscenter">
                              <div>Estatus App Online</div>
                              {localRiderData.oline === true ? (
                                <span className="activo">OnLine</span>
                              ) : (
                                <span className="inactivo">OffLine</span>
                              )}{" "}
                            </div>
                            <div className="alignItemscenter">
                              <div>
                                Solo puedes registrar tu asistencia si estas,
                                Online en el App Rider
                              </div>
                              {viewRiderDataAsist ? (
                                <div>
                                  {viewRiderDataAsist.map((item) => (
                                    <div key={item.id}>
                                      {format(
                                        item.date.seconds * 1000,
                                        "dd/MM/yy HH:mm:ss"
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <></>
                              )}
                              {!riderReportDone ? (
                                <IconButton
                                  sx={{
                                    ml: 1, // Margen izquierdo de 1 espacio (el tamaño del espacio depende del theme o sistema de estilos que estés usando)
                                    color: "#030303", // Color del texto
                                    padding: "8px 16px", // Padding interior del botón (8px arriba/abajo, 16px izquierda/derecha)
                                    backgroundColor: "#f0f0f0", // Color de fondo del botón
                                    borderRadius: "4px", // Radio de borde (para dar esquinas redondeadas)
                                    border: "1px solid #ccc", // Borde del botón
                                    fontSize: "16px", // Tamaño del texto
                                    fontWeight: "bold", // Peso de la fuente
                                    outline: "none", // Quita el borde al hacer clic en el botón
                                    ":hover": {
                                      backgroundColor: "#e0e0e0", // Cambia el color de fondo al pasar el ratón por encima del botón
                                      cursor: "pointer", // Cambia el cursor al pasar el ratón por encima del botón
                                    },
                                  }}
                                  onClick={() => notifyArrival()}
                                  size="mediun"
                                >
                                  <WhereToVoteIcon
                                    sx={{
                                      ml: 1,
                                      color: "#29B834",
                                      padding: "1px",
                                    }}
                                  />

                                  {riderReport === true
                                    ? "Notificar Salida de sala"
                                    : "Notificar llegada a sala"}
                                </IconButton>
                              ) : (
                                <></>
                              )}

                              {/* {localRiderData.oline === true ? (
                                <IconButton
                                  sx={{
                                    ml: 1,
                                    color: "#591e8f",
                                    padding: "1px",
                                  }}
                                  onClick={props.menuOption}
                                  size="mediun"
                                >
                                  <WhatsAppIcon
                                    sx={{
                                      ml: 1,
                                      color: "#29B834",
                                      padding: "1px",
                                    }}
                                  />
                                  Registra mi asistencia.
                                </IconButton>
                              ) : (
                                <span className="inactivo">
                                  Activa tu usuario y regresa aqui
                                </span>
                              )}{" "} */}
                            </div>
                          </Box>
                        </CardContent>
                        <Divider />
                        <div className="centercontent">
                          <strong>Telefono: {localRiderData.phone}</strong>
                        </div>
                        <div className="centercontent">
                          <strong>Emai: {localRiderData.email}</strong>
                        </div>
                        <div className="centercontent">
                          <strong>ID: {localRiderData.bicciNumber}</strong>
                        </div>
                        <div className="centercontent">
                          <strong>
                            <div>Estatus App Rider: </div>
                            {localRiderData.appVersion === "1.9.4" ? (
                              <span className="activo">Ultima version</span>
                            ) : (
                              <span className="inactivo">Actualiza tu App</span>
                            )}
                          </strong>
                        </div>
                        <CardActions>
                          <div className="centercontent">
                            <div className="borderCode">
                              {/* <QRCode value={`https://get-bicci.web.app/consulta/${this.props.contenido.data.id}`} size={150} level='H' fgColor='#741ab0'/> */}
                            </div>
                          </div>
                        </CardActions>
                        <div className="centercontent contac-data">
                          <br></br>
                          CONTACTO <br></br>
                          {localRiderData.phone}
                          <br></br>
                        </div>
                      </Card>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions></CardActions>
                </Card>
                <div>
                  <Card
                    sx={{
                      marginBottom: "30px",
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: "20px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                      title={<div>Descargas:</div>}
                      subheader={<h3>Opciones de Ruta</h3>}
                    />

                    <Divider />
                    <CardContent sx={{ padding: "1px" }}>
                      {/* <ModalAccionsDispacthDirectionsResolve></ModalAccionsDispacthDirectionsResolve> */}
                      {showPopUp ? (
                        <ModalAccionsDispacthDirectionsResolve
                          opened={showPopUp}
                          popUpAccion={popUpAccion}
                          interfaceOrigen={"liveOrders"}
                          ordersForAccions={currentRuteData.currentOrderList}
                          dataOrdersForAccions={
                            currentRuteData.dataOrdesBicciByRider
                          }
                          companyId={null}
                          ridersDataByCompany={null}
                          adressOrigin={currentState.originAdress}
                          origenGeoCode={currentState.origin}
                          adressDestiny={currentState.destinationAdress}
                          destinyGeoCode={currentState.destination}
                        >
                          {" "}
                        </ModalAccionsDispacthDirectionsResolve>
                      ) : null}
                      <button
                        onClick={() => isShowPopUp("ExcelFile")}
                        className="btn-add-guide-small m-2"
                      >
                        {" "}
                        Descargar ruta Circuit{" "}
                      </button>
                      <button
                        onClick={() => isShowPopUp("GoogleMaps")}
                        className="btn-add-guide-small m-2"
                      >
                        {" "}
                        Crear Link Google Maps{" "}
                      </button>

                      <button
                        onClick={() => setWiewPrePdf(!wiewPrePdf)}
                        className="btn-add-guide-small m-2"
                      >
                        Descargar Reporte
                      </button>
                      <button
                        onClick={() => handleAceptRoute(idRoute, idRider)}
                        className="btn-add-guide-small m-2"
                      >
                        {" "}
                        Crear Ruta Bicci{" "}
                      </button>
                    </CardContent>
                  </Card>
                </div>

                <div>
                  {wiewPrePdf === true && currentRuteData ? (
                    <PDFViewer style={{ width: "100%", height: "90vh" }}>
                      <ReporteRutaPDF
                        currentRuteData={currentRuteData}
                        localRiderData={localRiderData}
                      ></ReporteRutaPDF>
                    </PDFViewer>
                  ) : null}
                </div>

                <Card>
                  <CardHeader
                    sx={{
                      padding: "20px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                    title={<div>Información importante:</div>}
                    subheader={
                      <div>
                        <h3>Revisa tus paquetes por entregar:</h3>
                        <div>
                          <strong>1- Revisa tus paquetes por entregar:</strong>{" "}
                          Te proporcionamos una lista detallada de todas las
                          órdenes que tienes pendientes, para que puedas
                          mantenerte organizado y planificar tu ruta de manera
                          adecuada.
                        </div>
                        <div>
                          <strong>2- Escanea todas tus órdenes:</strong> Nuestra
                          plataforma cuenta con una función de escaneo que te
                          permitirá llevar un registro preciso de todas tus
                          entregas, evitando errores y asegurando la correcta
                          distribución de los paquetes.
                        </div>
                        <div>
                          <strong>
                            3- Confirma la cantidad de paquetes asignados:
                          </strong>{" "}
                          Te mostramos la cantidad de paquetes asignados junto
                          con su físico. Si detectas alguna diferencia, no dudes
                          en solicitar apoyo a tu supervisor o al equipo de
                          soporte para resolver cualquier inconveniente.
                        </div>
                        <div>
                          <strong>
                            4- Visualiza la distribución en el mapa:
                          </strong>{" "}
                          Mediante nuestro mapa interactivo, podrás ver la
                          ubicación de cada entrega y la trayectoria que debes
                          seguir. Esto te ayudará a detectar posibles problemas
                          de direcciones y a informar rápidamente al equipo de
                          soporte si es necesario.
                        </div>
                        <div>
                          <strong>5- Descarga las opciones de rutas:</strong>
                          Para facilitar aún más tu trabajo, te ofrecemos la
                          posibilidad de descargar las rutas que utilizas o
                          iniciar tu ruta directamente desde nuestra aplicación.
                          Puedes elegir entre diferentes opciones, como Circuit,
                          Google Maps o Ruta Bicci, para encontrar la que mejor
                          se adapte a tus necesidades.
                        </div>
                        <div>
                          <strong>6- Explora diferentes trayectos: </strong>Si
                          necesitas cargar orígenes o destinos diferentes en tu
                          ruta, nuestros botones en la parte superior te
                          permitirán hacerlo de manera sencilla. Flexibilidad y
                          adaptabilidad son parte de nuestro compromiso contigo.
                        </div>
                      </div>
                    }
                  />

                  <Divider />
                  <CardContent sx={{ padding: "1px" }}>
                    <div>
                      <Box
                        sx={{ width: "100%", zIndex: 99 }}
                        role="presentation"
                        // onClick={toggleDrawer(anchor, false)}
                        // onKeyDown={toggleDrawer(anchor, false)}
                      >
                        <div className="m-2">
                          {/* <div className="title-container-control-ruta-rithg m-2">
                        <IconButton
                          sx={{ color: "#F40606" }}
                          // onClick={toggleDrawer(anchor, false)}
                          size="small"
                        >
                          <CancelIcon />
                        </IconButton>
                      </div> */}
                          {/* 
                      <Paper
                        elevation={3}
                        sx={{
                          color: "#f2f2f2",
                          backgroundColor: "#00cdf6",
                          margin: "1px",
                          borderRadius: "14px",
                          width: "100%",
                          textAlign: "left",
                          boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                          alignItems: "center",
                          display: "inline-flex",
                          justifyContent: "flex-start",
                          padding: "8px",
                        }}
                      >
                        <LocationOnIcon></LocationOnIcon>
                        <Typography
                          sx={{ fontWeight: 600, marginLeft: "5px" }}
                          component="div"
                        >
                          {props.originadress}
                        </Typography>
                        <IconButton
                          sx={{ color: "#f2f2f2" }}
                          onClick={() => props.setEdithOpcionOrigin(true)}
                          size="small"
                        ></IconButton>
                      </Paper>

                      <Paper
                        elevation={3}
                        sx={{
                          color: "#f2f2f2",
                          backgroundColor: "#00cdf6",
                          margin: "1px",
                          borderRadius: "14px",
                          width: "100%",
                          textAlign: "left",
                          boxShadow: "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                          alignItems: "center",
                          display: "inline-flex",
                          justifyContent: "flex-start",
                          padding: "8px",
                        }}
                      >
                        <LocationOnIcon></LocationOnIcon>
                        <Typography
                          sx={{ fontWeight: 600, marginLeft: "5px" }}
                          component="div"
                        >
                          {currentRuteData.destinationadress}
                        </Typography>
                        <IconButton
                          sx={{ color: "#f2f2f2" }}
                          onClick={() => {
                            props.setEdithOpcionDestini(true);
                          }}
                          size="small"
                        ></IconButton>
                      </Paper> */}

                          <div className="title-container-control-ruta-letf m-2">
                            Asignadas del dia
                          </div>

                          <div className="text-container-control-ruta-letf m-2">
                            {currentRuteData &&
                            currentRuteData.dataOrdesBicciByRider ? (
                              <>
                                <>
                                  {currentRuteData &&
                                    currentRuteData.dataOrdesBicciByRider
                                      .length}{" "}
                                  Paradas
                                </>
                              </>
                            ) : (
                              <>
                                {" "}
                                No tienes paquetes por entregar en este momento
                              </>
                            )}
                          </div>

                          {currentRuteData &&
                          currentRuteData.dataOrdesBicciByRider ? (
                            <>
                              {" "}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-around", // para dar espacio entre los elementos
                                }}
                              >
                                {currentRuteData.dataOrdesBicciByRider.map(
                                  (item, index) => {
                                    return (
                                      <Box
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={item.id}
                                      >
                                        <Paper
                                          elevation={3}
                                          sx={{
                                            width: "350px",
                                            color: "#f2f2f2",
                                            backgroundColor: "#00cdf6",
                                            margin: "1px",
                                            borderRadius: "14px",
                                            display: "inline-block",
                                            boxShadow:
                                              "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                            alignItems: "center",

                                            justifyContent: "flex-start",
                                            padding: "8px",

                                            // Añade estilos adicionales para pantallas grandes si es necesario
                                            "@media (min-width:1280px)": {
                                              justifyContent: "center", // Por ejemplo, centrar contenido para pantallas grandes
                                            },
                                          }}
                                        >
                                          {index + 1}
                                          <LocationOnIcon></LocationOnIcon>
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              marginLeft: "5px",
                                            }}
                                            component="div"
                                          >
                                            {item.id}{" "}
                                            <Paper
                                              elevation={3}
                                              key={index}
                                              sx={{
                                                color: "#030303",
                                                backgroundColor: "#c6e1c1",
                                                margin: "1px",
                                                borderRadius: "14px",
                                                width: "100%",
                                                textAlign: "left",

                                                alignItems: "center",
                                                display: "inline-flex",
                                                justifyContent: "flex-start",
                                                padding: "8px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  marginLeft: "5px",
                                                }}
                                                component="div"
                                              >
                                                Estado :{" "}
                                                {
                                                  translateStateBicciFalabella[
                                                    item.falabella_status
                                                  ]
                                                }
                                              </Typography>
                                            </Paper>
                                            <>
                                              <hr></hr>
                                              {item.toAddress}
                                              <div>
                                                {item.observationReceptor ? (
                                                  <div>
                                                    <hr></hr>
                                                    <div className="color-blue">
                                                      Notas de Conducor
                                                    </div>
                                                    <div>
                                                      <Paper
                                                        elevation={3}
                                                        key={index}
                                                        sx={{
                                                          color: "#030303",
                                                          backgroundColor:
                                                            "#FFF",
                                                          margin: "1px",
                                                          borderRadius: "14px",
                                                          width: "100%",
                                                          textAlign: "left",
                                                          boxShadow:
                                                            "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                                          alignItems: "center",
                                                          display:
                                                            "inline-flex",
                                                          justifyContent:
                                                            "flex-start",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            fontWeight: 600,
                                                            marginLeft: "5px",
                                                          }}
                                                          component="div"
                                                        >
                                                          {
                                                            item.observationReceptor
                                                          }
                                                        </Typography>
                                                      </Paper>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                {item.observationAdmin ? (
                                                  <div>
                                                    <hr></hr>
                                                    <div className="color-blue">
                                                      Notas de Pedido:
                                                    </div>
                                                    {item.observationAdmin.map(
                                                      (item, index) => (
                                                        <Paper
                                                          elevation={3}
                                                          key={index}
                                                          sx={{
                                                            color: "#030303",
                                                            backgroundColor:
                                                              "#FFF",
                                                            margin: "1px",
                                                            borderRadius:
                                                              "14px",
                                                            width: "100%",
                                                            textAlign: "left",
                                                            boxShadow:
                                                              "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                                            alignItems:
                                                              "center",
                                                            display:
                                                              "inline-flex",
                                                            justifyContent:
                                                              "flex-start",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontWeight: 600,
                                                              marginLeft: "5px",
                                                            }}
                                                            component="div"
                                                          >
                                                            {item}
                                                          </Typography>
                                                        </Paper>
                                                      )
                                                    )}
                                                  </div>
                                                ) : null}
                                                <hr></hr>
                                              </div>
                                            </>
                                          </Typography>

                                          {/* <IconButton
                        sx={{ color: "#f2f2f2" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton> */}
                                        </Paper>
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                            </>
                          ) : null}

                          {currentRuteData &&
                          currentRuteData.dataOrdersExcepcions ? (
                            <>
                              <div className="title-container-control-ruta-letf m-2">
                                Ordenes con incidencias
                              </div>
                              <div>
                                Si las tienes en tu poder escanealas para que se
                                agregren a la ruta, si no comunacate con soporte{" "}
                              </div>
                              <div className="text-container-control-ruta-letf m-2">
                                {currentRuteData &&
                                currentRuteData.dataOrdersExcepcions ? (
                                  <>
                                    {
                                      currentRuteData.dataOrdersExcepcions
                                        .length
                                    }{" "}
                                    pendientes
                                  </>
                                ) : null}
                              </div>{" "}
                              {currentRuteData.dataOrdersExcepcions.map(
                                (item, index) => {
                                  let colorMarket;
                                  var validateStatus = parseInt(
                                    item.falabella_status
                                  );
                                  if (validateStatus === 99) {
                                    colorMarket = "#2CC04B";
                                  }
                                  if (validateStatus !== 99) {
                                    colorMarket = "#F12511";
                                  }
                                  return (
                                    <Paper
                                      key={item.id}
                                      elevation={3}
                                      sx={{
                                        color: "#f2f2f2",
                                        backgroundColor: colorMarket,
                                        margin: "1px",
                                        marginBottom: "3px",
                                        borderRadius: "14px",
                                        width: "100%",
                                        boxShadow:
                                          "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                        alignItems: "rithg",
                                        display: "inline-flex",
                                        justifyContent: "flex-start",
                                        padding: "8px",
                                      }}
                                    >
                                      {`P${index + 1} - `}
                                      <LocationOnIcon></LocationOnIcon>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          marginLeft: "5px",
                                          justifyContent: "flex-start",
                                        }}
                                        component="div"
                                      >
                                        {item.id}
                                      </Typography>

                                      <div>
                                        {item.observationReceptor ? (
                                          <div>
                                            <hr></hr>
                                            <div className="color-blue">
                                              Notas de Conducor
                                            </div>
                                            <div>
                                              <Paper
                                                elevation={3}
                                                key={index}
                                                sx={{
                                                  color: "#f2f2f2",
                                                  backgroundColor: "#00cdf6",
                                                  margin: "1px",
                                                  borderRadius: "14px",
                                                  width: "100%",
                                                  textAlign: "left",
                                                  boxShadow:
                                                    "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                                  alignItems: "center",
                                                  display: "inline-flex",
                                                  justifyContent: "flex-start",
                                                  padding: "8px",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    marginLeft: "5px",
                                                  }}
                                                  component="div"
                                                >
                                                  {item.observationReceptor}
                                                </Typography>
                                              </Paper>
                                            </div>
                                          </div>
                                        ) : null}
                                        {item.observationAdmin ? (
                                          <div>
                                            <hr></hr>
                                            <div className="color-blue">
                                              Notas de Pedido:
                                            </div>
                                            {item.observationAdmin.map(
                                              (item, index) => (
                                                <Paper
                                                  elevation={3}
                                                  key={index}
                                                  sx={{
                                                    color: "#f2f2f2",
                                                    backgroundColor: "#00cdf6",
                                                    margin: "1px",
                                                    borderRadius: "14px",
                                                    width: "100%",
                                                    textAlign: "left",
                                                    boxShadow:
                                                      "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontWeight: 600,
                                                      marginLeft: "5px",
                                                    }}
                                                    component="div"
                                                  >
                                                    {item}
                                                  </Typography>
                                                </Paper>
                                              )
                                            )}
                                          </div>
                                        ) : null}
                                        <hr></hr>
                                      </div>
                                    </Paper>
                                  );
                                }
                              )}
                            </>
                          ) : null}

                          {currentRuteData &&
                          currentRuteData.dataOrderDeliverySusses ? (
                            <>
                              {" "}
                              <div className="title-container-control-ruta-letf m-2">
                                Ordenes Entregadas
                              </div>
                              <div className="text-container-control-ruta-letf m-2">
                                {currentRuteData.dataOrderDeliverySusses ? (
                                  <>
                                    {
                                      currentRuteData.dataOrderDeliverySusses
                                        .length
                                    }{" "}
                                    Finalizadas
                                  </>
                                ) : null}
                              </div>{" "}
                              {currentRuteData.dataOrderDeliverySusses.map(
                                (item, index) => {
                                  let colorMarket;
                                  var validateStatus = parseInt(
                                    item.falabella_status
                                  );
                                  if (validateStatus === 99) {
                                    colorMarket = "#2CC04B";
                                  }

                                  return (
                                    <Paper
                                      key={item.id}
                                      elevation={3}
                                      sx={{
                                        color: "#f2f2f2",
                                        backgroundColor: colorMarket,
                                        margin: "1px",
                                        marginBottom: "3px",
                                        borderRadius: "14px",
                                        width: "100%",
                                        boxShadow:
                                          "-4px 4px 5px -3px rgba(0, 0, 0, 0.75)",
                                        alignItems: "rithg",
                                        display: "inline-flex",
                                        justifyContent: "flex-start",
                                        padding: "8px",
                                      }}
                                    >
                                      {`D${index + 1} - `}
                                      <LocationOnIcon></LocationOnIcon>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          marginLeft: "5px",
                                          justifyContent: "flex-start",
                                        }}
                                        component="div"
                                      >
                                        {item.id}
                                      </Typography>
                                      {/* <IconButton
                        sx={{ color: "#f2f2f2" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton> */}
                                    </Paper>
                                  );
                                }
                              )}
                            </>
                          ) : null}
                        </div>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </>
            ) : null}
          </div>
          {/* <div className="h2-withe">DESARROLLO</div>
          <div className="h2-withe">NO RECALCULAR</div>
          <div className="h2-withe">Enviar Puntos Orogen destino</div>
          <div className="h2-withe">Enviar trayectos</div>
          <div className="h2-withe">Enviar Paradas</div> */}
          <div>
            {reloadMap && (
              <div>
                {idRider && currentState && currentRuteData && widthHeight ? (
                  <DynamicRouteBicciMapComponent
                    updateState={updateState}
                    viewConfig={viewConfig}
                    isLoaded={isLoaded}
                    loadError={loadError}
                    idRider={idRider}
                    reloadMapLoaded={reloadMapLoaded}
                    currentState={currentState}
                    currentRuteData={currentRuteData}
                    widthHeight={widthHeight}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                    handleMarkerClick={handleMarkerClick}
                    handleMarkerClosed={handleMarkerClosed}
                    setApiResponseOrderLabelsMenu={
                      setApiResponseOrderLabelsMenu
                    }
                  ></DynamicRouteBicciMapComponent>
                ) : null}
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {/*  Desarrollo ver mensaje diario nuevo
        {currentRuteData &&
        !currentRuteData.documentRoute &&
        currentRuteData.dataOrdesBicciByRider &&
        welcome ? (
          <>
            {currentRuteData.dataOrdesBicciByRider.length > 0 ? (
              <>
                {" "}
                <div className="overlay-OpcionaContainerInfoWindow">
                  <div className="OpcionaContainerInfoWindow">
                    <Card
                      sx={{
                        padding: "10px",
                        paddingRight: "30px",
                        paddingBottom: "20px",
                      }}
                    >
                      <CardHeader
                        title={` Bienvenido, tu ruta de hoy tiene ${currentRuteData.dataOrdesBicciByRider.length} paradas`}
                        sx={{ padding: "1px" }}
                      />

                      <CardContent sx={{ padding: "1px" }}>
                        <div>
                          <>
                            {currentRuteData.dataOrdersExcepcions ? (
                              <div className="alert alert-warning m-3">
                                Posees{" "}
                                {currentRuteData.dataOrdersExcepcions.length}{" "}
                                pendientes por entrega, retornalas a ruta
                                escaneado la orden o retorna el paquete al hub
                              </div>
                            ) : null}
                            <div>
                              Recuerda Fijar una dirección Origen, y un destino
                              luego has click en iniciar, por defecto la ruta
                              actual iniciara en el hub Bicci Ñuñoa{" "}
                            </div>
                            <div>
                              <IconButton
                                sx={{
                                  color: "#21ba45",
                                  padding: "20px",
                                  background: "#f8f8f8",
                                  borderRadius: "3px",
                                }}
                                variant="contained"
                                onClick={() => handleAceptRoute(idRoute)}
                                size="small"
                              >
                                <CheckCircleOutlineIcon />
                                Aceptar e iniciar
                              </IconButton>
                            </div>
                          </>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </>
            ) : (
              <>
                {currentRuteData.dataOrdersExcepcions.length > 0 ? (
                  <>
                    {" "}
                    <div className="overlay-OpcionaContainerInfoWindow">
                      <div className="OpcionaContainerInfoWindow">
                        <Card
                          sx={{
                            padding: "10px",
                            paddingRight: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <CardHeader
                            title={` Bienvenido, tu ruta de hoy hasta el momento tiene ${currentRuteData.dataOrdesBicciByRider.length} paradas`}
                            sx={{ padding: "1px" }}
                          />

                          <CardContent sx={{ padding: "1px" }}>
                            <div>
                              <h3 className="alert alert-danger">
                                Dinamic route bicci , Remover al finalizar
                              </h3>
                              {currentRuteData.dataOrdesBicciByRider &&
                              currentRuteData.dataOrdesBicciByRider.length <=
                                23 ? (
                                <>
                                  {currentRuteData.dataOrdersExcepcions ? (
                                    <h3 className="alert alert-warning m-3">
                                      Posees{" "}
                                      {
                                        currentRuteData.dataOrdersExcepcions
                                          .length
                                      }{" "}
                                      pendientes por entregar, retornalas a ruta
                                      escaneado la orden o retorna el paquete al
                                      hub
                                    </h3>
                                  ) : null}
                                  <div>
                                    Recuerda Fijar una dirección Origen, y un
                                    destino luego has click en iniciar, por
                                    defecto la ruta actual iniciara en el hub
                                    Bicci Ñuñoa{" "}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="overlay-OpcionaContainerInfoWindow">
                      <div className="OpcionaContainerInfoWindow">
                        <Card
                          sx={{
                            padding: "10px",
                            paddingRight: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <CardHeader
                            title={` Bienvenido, no tienes entregas pendientes, Si ya escaneaste tus órdenes, solicita apoyo a soporte. Gracias `}
                            sx={{ padding: "1px" }}
                          />

                          <CardContent sx={{ padding: "1px" }}></CardContent>
                        </Card>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : null}
       */}
        {viewSelectedMarker && widthHeight.width < 950 ? (
          <div className="OpcionaContainerInfoWindow">
            <Card sx={{ padding: "10px", paddingBottom: "20px" }}>
              <CardHeader
                title={`ID: ${viewSelectedMarker.id}`}
                sx={{ padding: "1px" }}
                action={
                  <IconButton
                    sx={{ color: "#F40606" }}
                    onClick={() => handleMarkerClosed(null)}
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                }
              />

              <CardContent sx={{ padding: "1px" }}>
                <div>
                  <h3>
                    <PersonIcon sx={{ color: "#1806F4", padding: "1px" }} />

                    {viewSelectedMarker.userName}
                  </h3>
                </div>
                <div>
                  <div className="linearButtons">
                    <a
                      className="nonstyle"
                      href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <IconButton
                        sx={{
                          color: "#1806F4",
                          padding: "1px",
                          width: "35px",
                          height: "35px",
                          fontSize: "2.5rem",
                        }}
                        onClick={() => {
                          // Agrega tu lógica de manejo de eventos aquí
                          // Por ejemplo, puedes mostrar un mensaje de confirmación antes de abrir Google Maps
                          // o realizar alguna acción adicional
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`,
                            "_blank"
                          );
                        }}
                      >
                        <AssistantDirectionIcon
                          sx={{
                            color: "#1806F4",
                            padding: "1px",
                            width: "35px",
                            height: "35px",
                            fontSize: "2.5rem",
                          }}
                        />
                      </IconButton>{" "}
                      <div> Google maps</div>
                    </a>

                    <a
                      className="nonstyle"
                      href={constructWazeLink(selectedMarker.toAddress)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img
                        src="/waze.svg"
                        alt="Icon"
                        className="marketr-icon"
                      />
                      <div> Navega con Waze</div>
                    </a>
                  </div>
                  <div> {selectedMarker.toAddress}</div>

                  <div>
                    <IconButton
                      sx={{
                        ml: 1,
                        color: "#1806F4",
                        padding: "1px",
                        fontSize: "1.5em",
                      }}
                      onClick={(e) => console.log(e)}
                      size="small"
                    >
                      <CallIcon />
                    </IconButton>
                    <a
                      className="nonstyle"
                      href={`tel:9${viewSelectedMarker.userPhone}`}
                    >
                      9{viewSelectedMarker.userPhone}
                    </a>
                  </div>
                </div>
                <div>
                  <a
                    className="nonstyle"
                    href={`https://api.whatsapp.com/send?phone=+569${viewSelectedMarker.userPhone}&text=Soporte%20BICCI%20:`}
                  >
                    <IconButton
                      sx={{ ml: 1, color: "#29B834", padding: "1px" }}
                      onClick={props.menuOption}
                      size="small"
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    9{viewSelectedMarker.userPhone}
                  </a>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );

  function constructWazeLink(searchText) {
    const elementos = searchText.split(",");
    const primerElemento = elementos[0];
    const palabras = primerElemento.split(" ");
    let indice = -1;
    for (let i = 0; i < palabras.length; i++) {
      if (!isNaN(parseInt(palabras[i]))) {
        indice = i;
        break;
      }
    }
    let nuevoArray = palabras.slice(0, indice + 1).join(" ");
    nuevoArray += ", ";
    nuevoArray += elementos.slice(1).join(",");
    const encodedSearchText = encodeURIComponent(nuevoArray);
    const wazeLink = `https://waze.com/ul?q=${encodedSearchText}`;
    return wazeLink;
  }
};

export default DynamicRouteBicciDistributionByReport;
