import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux"; // Si usas Redux para el manejo de usuario
import { db } from "../../../firebase";

const UserCompaniesList = () => {
  const [acceptedCompanies, setAcceptedCompanies] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [rejectedRequests, setRejectedRequests] = useState([]);
  const userId = useSelector((state) => state.usuario.user.uid); // Ajusta según tu implementación de Redux

  useEffect(() => {
    const fetchUserCompanies = async () => {
      const userCompaniesRef = collection(db, "xpress_userCompanies");
      const q = query(userCompaniesRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      const accepted = [];
      const pending = [];
      const rejected = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.status === "Aceptado") {
          accepted.push({ ...data });
        } else if (data.status === "Pendiente") {
          pending.push({ ...data });
        } else if (data.status === "Rechazado") {
          rejected.push({ ...data });
        }
      });

      setAcceptedCompanies(accepted);
      setPendingRequests(pending);
      setRejectedRequests(rejected);
    };

    fetchUserCompanies();
  }, [userId]);

  return (
    <div>
      {acceptedCompanies.length > 0 ? (
        <>
          <h3>Empresas Aceptadas</h3>
          <ul>
            {acceptedCompanies.map((company, index) => (
              <li key={index}>
                {company.companyName} - Rol: {company.role}
              </li>
            ))}
          </ul>
        </>
      ) : null}

      {pendingRequests.length > 0 ? (
        <>
          <h3>Solicitudes Pendientes</h3>
          <ul>
            {pendingRequests.map((company, index) => (
              <li key={index}>{company.companyName}</li>
            ))}
          </ul>
        </>
      ) : null}

      {rejectedRequests.length < 0 ? (
        <>
          <h3>Solicitudes Rechazadas</h3>
          <ul>
            {rejectedRequests.map((company, index) => (
              <li key={index}>{company.companyName}</li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
};

export default UserCompaniesList;
