export const CustomDateRangePickerStyle = {
  marginTop: "5px",
  marginBottom: "5px",
  borderRadius: "8px", // Bordes redondeados más claros
  backgroundColor: "white",
  borderColor: "purple",
  color: "#080808",
  border: "1px solid #e1e1e1",
  display: "flex",
  justifyContent: "center",
  minWidth: "200px",
  minHeight: "35px",
  padding: "13px",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#e1e1e1",
  },
};
