import React from "react";
import {
  Box,
  Container,
  CardActions,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import { Grid } from "semantic-ui-react";
import useGlobalConfig from "../../hooks/globalConfig";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getCart } from "../../redux/acccionesCart";
import { useSelector, useDispatch } from "react-redux";
import {
  clientAddCartProduct,
  clientRemoveCartProduct,
} from "../../redux/acccionesCart";
import { useNavigate } from "react-router-dom";
var userUid = "noregister";
const Cart = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { imgPlaceOlderCart } = useGlobalConfig();
  const [lastItem, setlastItem] = React.useState(null);
  const cart = useSelector((store) => store.cartAccion.cartContent);
  const itemsCart = useSelector((store) => store.cartAccion.cart);
  const loading = useSelector((store) => store.cartAccion.loading);
  var cartErr = useSelector((store) => store.cartAccion.cartErr);

  //console.log(cartErr)

  React.useEffect(() => {
    dispatch(getCart());
    LastItemsCart(itemsCart);
  }, [loading, cartErr]);

  React.useEffect(() => {
    LastItemsCart(itemsCart);
    //console.log('SE ACTIVO')
  }, []);

  const LastItemsCart = (itemsCart) => {
    if (itemsCart) {
      let cant = itemsCart.length - 1;
      let ultimo = itemsCart[cant];
      setlastItem(ultimo);
    } else {
      setlastItem(null);
    }
  };

  const handleCarmin = (e) => {
    e.preventDefault();
    //console.log(e.target)
    //console.log('restar')
    //console.log(e.target.value)
    dispatch(clientRemoveCartProduct(e.target.value, userUid));
  };
  const handleCarplus = (e) => {
    e.preventDefault();
    //console.log(e.target)
    //console.log('Sumar')
    //console.log(e.target.value)
    dispatch(clientAddCartProduct(e.target.value, userUid));
  };

  const handlechange = (e) => {
    e.preventDefault();
    //console.log('Revisar inventario o metodos')
    //console.log(lastItem.cartArticleId)
  };
  const handleCarPay = (e) => {
    e.preventDefault();
    //console.log('Push to checkout')
    navigate(`/checkout/`);
  };

  const handleCarView = (e) => {
    e.preventDefault();
    //console.log('Push to paguina carrito')
  };
  const setAutoClosed = () => {
    function delay() {
      props.handleclosedminicart();
    }
    setTimeout(delay, 2000);
  };
  //console.log(cart)
  //console.log(itemsCart)
  //console.log(lastItem)

  return cart ? (
    <>
      {cart.cualitys <= 0 ? (
        <>
          <div>
            <h2>Carrito esta vacio</h2>
            <button onClick={navigate("/productos")}></button>
          </div>
        </>
      ) : (
        <>
          <div className="container container-min-height ">
            <div className="p-2">
              {cartErr ? (
                <span className="alert alert-info m-2">{cartErr}</span>
              ) : (
                <></>
              )}
            </div>
            {cart.article.map((item) => (
              <div key={item.id}>
                <div className="minicartBody m-2">
                  <Container
                    className="cartproduct"
                    id="cartproduct"
                    key={"item.id"}
                  >
                    <CardContent className="CardContentminicard">
                      <Box
                        sx={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid>
                          {/* <div className='btn btn-cart-closed' >
            <span onClick={()=> props.handleclosedminicart()} className="closedBtn"><CloseIcon className='colorRed f-size16'></CloseIcon></span>
            </div> */}
                          <Grid.Row className="cart-Grid-Row">
                            <Grid.Column computer={7} mobile={16} tablet={16}>
                              <div className="boxcart image-information">
                                <div>
                                  {" "}
                                  <img
                                    className="image-minicart"
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                  <CheckCircleIcon
                                    color="success"
                                    id="pinfloatInto"
                                  />
                                </div>
                                <div className="product-cart-info">
                                  <div className="text-h1-minicart">
                                    {item.productName}
                                  </div>
                                  <div className="text-p-minicart">
                                    Los envíos quedarán abonados y disponibles
                                    en tu cuenta, puedes utilizarlos desde tu
                                    panel de control.
                                  </div>
                                </div>
                                <div className="btn-cart-control">
                                  <form className="form-cart-control">
                                    <button
                                      onClick={handleCarmin}
                                      value={item.id}
                                      className="btn-min scrollto bicci-btn-hover"
                                    >
                                      -
                                    </button>
                                    <input
                                      id="cartImputId"
                                      type="text"
                                      value={1}
                                      onChange={handlechange}
                                    />
                                    <button
                                      onClick={handleCarplus}
                                      value={item.id}
                                      className="btn-plus scrollto bicci-btn-hover"
                                    >
                                      +
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </Grid.Column>

                            <Grid.Column
                              computer={5}
                              mobile={16}
                              tablet={16}
                              className="cart-Grid-Column"
                            >
                              <div className="boxcart">
                                <div className="boxcart image-information">
                                  <div className="product-cart-info">
                                    <div className="text-p-minicart-cant">
                                      {item.cartArticlCuality} pack
                                    </div>
                                    <div className="text-p-minicart-amount">
                                      $ {item.totalAmount}
                                    </div>
                                    {item.totalDiscount ? (
                                      <div className="text-p-minicart-notice">
                                        ¡Oferta temporal!
                                      </div>
                                    ) : (
                                      <div className="text-p-minicart-notice">
                                        ¡Mejor precio!
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {item.cualitys === 2 ? (
                                      <>
                                        <img
                                          className="image-minicart floatIn"
                                          src={`${imgPlaceOlderCart}`}
                                          alt={""}
                                        />
                                        <img
                                          className="image-minicart floatIn floatInto "
                                          src={`${imgPlaceOlderCart}`}
                                          alt={""}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.cualitys >= 3 ? (
                                      <>
                                        <img
                                          className="image-minicart floatIn"
                                          src={`${imgPlaceOlderCart}`}
                                          alt={""}
                                        />
                                        <img
                                          className="image-minicart floatIn floatInto "
                                          src={`${imgPlaceOlderCart}`}
                                          alt={""}
                                        />
                                        <img
                                          className="image-minicart floatIn floatInto"
                                          src={`${imgPlaceOlderCart}`}
                                          alt={""}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16} tablet={16}>
                              <div className="boxcart">
                                {/* <Button onClick={handleCarView}className="btn-buy btn-buy-minicart scrollto m-1">Ver carrito</Button>
            <Button onClick={handleCarPay} className="btn-buy btn-buy-minicart scrollto m-1">Comprar carrito</Button>
         */}
                                <div className="btn btn-cart-closed">
                                  <button
                                    onClick={handleCarmin}
                                    value={item.id}
                                    className="closedBtn"
                                  >
                                    Eliminar
                                    <DeleteForeverIcon className="colorRed f-size16"></DeleteForeverIcon>
                                  </button>
                                </div>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Container>
                </div>
              </div>
            ))}

            <div className="aling-rigth">
              <Button
                onClick={handleCarPay}
                className="btn-buy btn-buy-minicart scrollto m-2"
              >
                Pagar carrito
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <>carrito vacio</>
  );
};

export default Cart;
