import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useGetComentsWeb (userRoll ){
 // console.log(userEmail)
    const [bicciComentsWeb, setComentsWeb] = useState(null)
    const [loadComentsWeb, setLoadComentsWeb] = useState(false)
    const [errorComentsWeb, setErrorComentsWeb] = useState(null)    
  
    useEffect(()=>{
      fetchData(userRoll)
    },[userRoll])
  
    const fetchData = async (userRoll) => {
      if(userRoll){
        try {
          setLoadComentsWeb(true)

          const allComentsWeb = await db.collection(`${process.env.REACT_APP_COL_COMENTS}`).get();
          var dataAllComentsWeb = allComentsWeb.docs.map(doc => ({id: doc.id, ...doc.data()}))
          //console.log(dataAllComentsWeb)
          setComentsWeb(dataAllComentsWeb)

        } catch (error) {
          //console.log(error)
          setErrorComentsWeb(true)
          setComentsWeb(null)
        }
      }
 
    }  


    return {bicciComentsWeb, loadComentsWeb, errorComentsWeb}
}

export default useGetComentsWeb