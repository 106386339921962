import React, { useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  polyline,
} from "@react-google-maps/api";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import mapStylesClear from "../MapsGoogle/mapStylesClear";
import CancelIcon from "@mui/icons-material/Cancel";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";

import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
} from "@mui/material";

const libraries = ["places"];

const mapContainerStyle = {
  height: "90vh",
  width: "100%",
};

const options = {
  styles: mapStylesClear,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 14,
};

const rosettaVehicles = {
  BICYCLING: "Bicicleta",
  VEHICLE: "Bicicleta",
  TWO_WHEELER: "Motorizado",
  DRIVING: "Vehículo",
  WALKING: "Caminante",
};

const DynamicRouteBicciMapComponent = (props) => {
  // console.log(props);
  const [dataOrdesBicciByRider, setdataOrdesBicciByRider] = React.useState(
    props.currentRuteData.dataOrdesBicciByRider
  );
  const [userRider, setUserRider] = React.useState(null);
  const [currentState, setCurrentState] = React.useState(props.currentRuteData);
  const [currentRuteData, setCurrentRuteData] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const [animationInterval, setAnimationInterval] = React.useState(null);

  const [polyline, setPolyline] = React.useState(null);

  const [alertPack, setalertPack] = React.useState(false);

  const [mapPosition, setMapPosition] = React.useState({
    lat: -33.459249,
    lng: -70.6037713,
  });

  const [mapLoaded, setMapLoaded] = React.useState(false);

  const mapRef = React.useRef();

  const onMapLoadRute = React.useCallback(
    (map) => {
      mapRef.current = map;
      setMapLoaded(true);
    },
    [dataOrdesBicciByRider]
  );

  useEffect(() => {}, [alertPack, props.viewConfig]);

  useEffect(() => {
    setMapLoaded(false);
  }, [props.reloadMapLoaded]);

  // useEffect(() => {
  //   if (responseApi.length > 0 && currentRuteData.dataOrdesBicciByRiderGroups) {
  //     callReorderLabels(
  //       responseApi,
  //       currentRuteData.dataOrdesBicciByRiderGroups
  //     );
  //   }
  //   if (responseApis.length > 0) {
  //     callReorderLabels(
  //       responseApis,
  //       currentRuteData.dataOrdesBicciByRiderGroups
  //     );
  //   }
  // }, [responseApi, responseApis]);

  useEffect(() => {
    setSelectedMarker(props.selectedMarker);
  }, [props.selectedMarker]);

  useEffect(() => {
    if (
      props.currentRuteData &&
      props.currentRuteData.directionsServiceOptionaAgrupations
    ) {
      setdataOrdesBicciByRider(props.currentRuteData.dataOrdesBicciByRider);
      setCurrentState(props.currentState);
      setCurrentRuteData(props.currentRuteData);
    }
    if (
      props.currentRuteData.documentRoute &&
      props.currentRuteData.documentRoute.overview_path !== "isRequire"
    ) {
      if (props.currentRuteData.documentRoute.overview_path) {
        const jsonString = JSON.stringify(
          props.currentRuteData.documentRoute.overview_path
        );
        let data = props.currentRuteData.documentRoute.overview_path;
        const polylines = [];
        for (let key in data) {
          if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
            const latLngPositions = data[key].map((coordinate) => ({
              lat: coordinate.lat,
              lng: coordinate.lng,
            }));

            const newPolyline = new window.google.maps.Polyline({
              path: latLngPositions,
              scale: 2,
              strokeWeight: 3,
              fillColor: "#591e8f",
              strokeColor: "#4285F4",
              map: mapRef.current,
            });

            polylines.push(newPolyline);
          }
        }

        setPolyline(polylines);
      }
    }
  }, [
    props,
    props.currentRuteData.dataOrdesBicciByRider,
    props.currentRuteData.directionsServiceOptionaAgrupations,
    props.currentRuteData.documentRoute,
  ]);

  useEffect(() => {
    if (userRider && props.viewConfig.alertsEmojis === true) {
      findOrdersInRatio({
        lat: userRider.driverLocation.latitude,
        lng: userRider.driverLocation.longitude,
      });
    }
  }, [userRider]);

  useEffect(() => {
    const fetchData = () => {
      const docRef = doc(
        firestore,
        process.env.REACT_APP_COL_USERS_DRIVERS,
        props.idRider
      );
      getDoc(docRef).then((snapshot) => {
        const tempdata = snapshot.data();
        setUserRider(tempdata);

        if (tempdata && props.viewConfig.upDateByRiderPosition === true) {
          //console.log("Actualizar en nueva posicion del mapa");
          props.updateState("origin", {
            lat: tempdata.driverLocation.latitude,
            lng: tempdata.driverLocation.longitude,
          });
        }
        if (tempdata && props.viewConfig.alertsEmojis === true) {
          findOrdersInRatio({
            lat: tempdata.driverLocation.latitude,
            lng: tempdata.driverLocation.longitude,
          });
        }
      });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000); // 600000 ms son 10 minutos

    // Limpieza del intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (mapRef.current && mapPosition) {
      mapRef.current.setCenter(mapPosition);
    }
  }, [mapPosition]);

  const findOrdersInRatio = async (riderPosition) => {
    async function calcularDistancia(lat1, lon1, lat2, lon2) {
      const radioTierra = 6371000;
      const lat1Rad = toRadians(lat1);
      const lon1Rad = toRadians(lon1);
      const lat2Rad = toRadians(lat2);
      const lon2Rad = toRadians(lon2);
      const deltaLat = lat2Rad - lat1Rad;
      const deltaLon = lon2Rad - lon1Rad;
      const a =
        Math.sin(deltaLat / 2) ** 2 +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distancia = radioTierra * c;

      return distancia;
    }
    function toRadians(grados) {
      return grados * (Math.PI / 180);
    }
    for (let n = 0; n < dataOrdesBicciByRider.length; n++) {
      let chect = await calcularDistancia(
        dataOrdesBicciByRider[n].toAddressLocation.latitude,
        dataOrdesBicciByRider[n].toAddressLocation.longitude,
        riderPosition.lat,
        riderPosition.lng
      );
      if (chect < 500) {
        setalertPack(true);
        return;
      } else {
        setalertPack(false);
      }
    }
  };

  const onMapIdle = () => {
    if (mapRef.current && mapLoaded) {
      const currentPosition = mapRef.current.getCenter();
      setMapPosition(currentPosition);
    }
  };

  function constructWazeLink(searchText) {
    const elementos = searchText.split(",");
    const primerElemento = elementos[0];
    const palabras = primerElemento.split(" ");
    let indice = -1;
    for (let i = 0; i < palabras.length; i++) {
      if (!isNaN(parseInt(palabras[i]))) {
        indice = i;
        break;
      }
    }
    let nuevoArray = palabras.slice(0, indice + 1).join(" ");
    nuevoArray += ", ";
    nuevoArray += elementos.slice(1).join(",");
    const encodedSearchText = encodeURIComponent(nuevoArray);
    const wazeLink = `https://waze.com/ul?q=${encodedSearchText}`;
    return wazeLink;
  }

  async function decodePolyline(encoded) {
    let index = 0;
    let lat = 0;
    let lng = 0;
    let coordinates = [];
    let shift = 0;
    let result = 0;
    let byte = null;
    let latitude_change;
    let longitude_change;
    let precision = Math.pow(10, -5);

    while (index < encoded.length) {
      byte = null;
      shift = 0;
      result = 0;
      do {
        byte = encoded.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);
      latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      shift = result = 0;
      do {
        byte = encoded.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);
      longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      lat += latitude_change;
      lng += longitude_change;
      coordinates.push({ lat: lat * precision, lng: lng * precision });
    }
    return coordinates;
  }

  return dataOrdesBicciByRider && currentState ? (
    <div>
      <GoogleMap
        id="mapControlPublic"
        onIdle={onMapIdle}
        onLoad={onMapLoadRute}
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={mapPosition}
        options={options}
      >
        {userRider ? (
          <Marker
            position={{
              lat: currentRuteData.documentoRuote
                ? currentRuteData.documentoRuote.destination.lat
                : currentState.destination.lat,
              lng: currentRuteData.documentoRuote
                ? currentRuteData.documentoRuote.destination.lng
                : currentState.destination.lng,
            }}
            onClick={() => {}}
            label={{
              text: `Destino`,
              className: "labelMarker",
            }}
            icon={{
              url: `/metaDestino.svg`,
              origin: new window.google.maps.Point(5, 5), // Punto de origen del marcador
              anchor: new window.google.maps.Point(10, 30), // Punto de anclaje del marcador
              scaledSize: new window.google.maps.Size(30, 45),
              fillColor: "#34495e",
              strokeColor: "#000",
            }}
          />
        ) : (
          <></>
        )}

        {userRider ? (
          <>
            {props.viewConfig.upDateByRiderPosition === true ? (
              <>
                <Marker
                  position={{
                    lat: -33.459119,
                    lng: -70.598923,
                  }}
                  onClick={() => {
                    //setSelectedMarker(marker);
                  }}
                  label={{
                    text: `Hub Bicci`,
                    className: "labelMarkerOrigen",
                  }}
                  icon={{
                    url: `/Pines-01.svg`,
                    origin: new window.google.maps.Point(5, 5),
                    anchor: new window.google.maps.Point(10, 30), // Punto de anclaje del marcador
                    scaledSize: new window.google.maps.Size(30, 45),
                  }}
                />
              </>
            ) : (
              <>
                <Marker
                  position={{
                    lat: -33.459119,
                    lng: -70.598923,
                  }}
                  onClick={() => {
                    //setSelectedMarker(marker);
                  }}
                  label={{
                    text: `Hub Bicci`,
                    className: "labelMarkerOrigen",
                  }}
                  icon={{
                    url: `/Pines-01.svg`,
                    origin: new window.google.maps.Point(5, 5),
                    anchor: new window.google.maps.Point(10, 30), // Punto de anclaje del marcador
                    scaledSize: new window.google.maps.Size(30, 45),
                  }}
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}

        {userRider &&
        dataOrdesBicciByRider &&
        dataOrdesBicciByRider.length > 0 ? (
          <>
            <div>
              {dataOrdesBicciByRider.map((marker, index) => {
                // //console.log(dataOrdesBicciByRider.length);
                // //console.log(marker);
                let colorMarket;
                var validateStatus = parseInt(marker.status);
                if (validateStatus >= 8) {
                  colorMarket = "#2CC04B";
                }
                if (validateStatus < 0) {
                  colorMarket = "#C03E2C";
                }
                if (validateStatus >= 0 && validateStatus < 8) {
                  colorMarket = "#591e8f";
                }

                return (
                  <Marker
                    key={index}
                    position={{
                      lat: marker.toAddressLocation.latitude,
                      lng: marker.toAddressLocation.longitude,
                    }}
                    label={
                      props.viewConfig.viewPositionsLabels === true ||
                      props.viewConfig.viewIds === true
                        ? {
                            text:
                              props.viewConfig.viewPositionsLabels === true
                                ? props.viewConfig.viewIds === true
                                  ? `${marker.position} 📦 ${marker.id}`
                                  : `${marker.position}`
                                : props.viewConfig.viewIds === true
                                ? `📦 ${marker.id}`
                                : "🏁",
                            className: "labelMarker-pin",
                          }
                        : null
                    }
                    onClick={() => {
                      props.handleMarkerClick(marker);
                    }}
                    // icon={{
                    //   url: `/static/images/casa.png`,
                    //   origin: new window.google.maps.Point(5, 5),
                    //   anchor: new window.google.maps.Point(10, 30), // Punto de anclaje del marcador
                    //   scaledSize: new window.google.maps.Size(50, 60),
                    // }}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {currentRuteData &&
        currentRuteData.dataOrdersExcepcions &&
        currentRuteData.dataOrdersExcepcions.length > 0 ? (
          <>
            <div>
              {currentRuteData.dataOrdersExcepcions.map((marker, index) => {
                let colorMarket;
                var validateStatus = parseInt(marker.falabella_status);
                if (validateStatus === 99) {
                  colorMarket = "#2CC04B";
                }
                if (validateStatus !== 99) {
                  colorMarket = "#F12511";
                }

                return (
                  <Marker
                    key={index}
                    position={{
                      lat: marker.toAddressLocation.latitude,
                      lng: marker.toAddressLocation.longitude,
                    }}
                    label={
                      props.viewConfig.viewPositionsLabels === true ||
                      props.viewConfig.viewIds === true
                        ? {
                            text:
                              props.viewConfig.viewPositionsLabels === true
                                ? props.viewConfig.viewIds === true
                                  ? `🕐 📦 ${marker.id}`
                                  : `🕐`
                                : props.viewConfig.viewIds === true
                                ? `📦 ${marker.id}`
                                : "🏁",
                            className: "labelMarker-pin",
                          }
                        : null
                    }
                    onClick={() => {
                      props.handleMarkerClick(marker);
                    }}
                    onLoad={(marker) => {
                      const customIcon = (opts) =>
                        Object.assign(
                          {
                            path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                            color: colorMarket,
                            fillColor: colorMarket,
                            fillOpacity: 1,
                            strokeColor: colorMarket,
                            strokeWeight: 0.5,
                            scale: 1.5,
                            anchor: new window.google.maps.Point(5, 10),
                          },
                          opts
                        );
                      marker.setIcon(
                        customIcon({
                          // fillColor: colorIndex[index],
                          strokeColor: "#1222C5",
                          // color: colorIndex[index],
                        })
                      );
                    }}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 
        {currentRuteData &&
        currentRuteData.dataOrderDeliverySusses &&
        currentRuteData.dataOrderDeliverySusses.length > 0 ? (
          <>
            <div>
              {currentRuteData.dataOrderDeliverySusses.map((marker, index) => {
                let colorMarket;
                var validateStatus = parseInt(marker.falabella_status);
                if (validateStatus === 99) {
                  colorMarket = "#2CC04B";
                }

                return (
                  <Marker
                    key={index}
                    position={{
                      lat: marker.toAddressLocation.latitude,
                      lng: marker.toAddressLocation.longitude,
                    }}
                    label={
                      props.viewConfig.viewPositionsLabels === true ||
                      props.viewConfig.viewIds === true
                        ? {
                            text:
                              props.viewConfig.viewPositionsLabels === true
                                ? props.viewConfig.viewIds === true
                                  ? `✅ 📦 ${marker.id}`
                                  : `✅`
                                : props.viewConfig.viewIds === true
                                ? `📦 ${marker.id}`
                                : "🏁",
                            className: "labelMarker-pin",
                          }
                        : null
                    }
                    onClick={() => {
                      props.handleMarkerClick(marker);
                    }}
                    onLoad={(marker) => {
                      const customIcon = (opts) =>
                        Object.assign(
                          {
                            path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                            color: colorMarket,
                            fillColor: colorMarket,
                            fillOpacity: 1,
                            strokeColor: colorMarket,
                            strokeWeight: 0.5,
                            scale: 1.5,
                            anchor: new window.google.maps.Point(5, 10),
                          },
                          opts
                        );
                      marker.setIcon(
                        customIcon({
                          // fillColor: colorIndex[index],
                          strokeColor: "#1222C5",
                          // color: colorIndex[index],
                        })
                      );
                    }}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        */}
        {userRider ? (
          <Marker
            position={{
              lat: userRider.driverLocation.latitude,
              lng: userRider.driverLocation.longitude,
            }}
            onClick={() => {
              //setSelectedMarker(marker);
            }}
            icon={{
              url: `/pinecobicci.svg`,
              origin: new window.google.maps.Point(5, 5), // Punto de origen del marcador
              anchor: new window.google.maps.Point(10, 30), // Punto de anclaje del marcador
              scaledSize: new window.google.maps.Size(45, 65),
              fillColor: "#34495e",
              strokeColor: "#000",
            }}
            label={
              props.viewConfig.alertsEmojis === true && alertPack === true
                ? {
                    text: "⚠️",
                    className: "labelMarker-pin-rider",
                  }
                : null
            }
            // onLoad={(marker) => {
            //   const customIcon = (opts) =>
            //     Object.assign(
            //       {
            //         path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
            //         // color: colorMarket,
            //         // fillColor: colorMarket,
            //         // fillOpacity: 1,
            //         // strokeColor: colorMarket,
            //         // strokeWeight: 0.3,
            //         scale: 1.5,
            //         anchor: new window.google.maps.Point(5, 10),
            //       },
            //       opts
            //     );
            //   marker.setIcon(
            //     customIcon({
            //       // fillColor: colorIndex[index],
            //       strokeColor: "#1222C5",
            //       // color: colorIndex[index],
            //     })
            //   );
            // }}
          />
        ) : (
          <></>
        )}
        {selectedMarker && props.widthHeight.width > 950 ? (
          <>
            {
              <InfoWindow
                position={{
                  lat: selectedMarker.toAddressLocation.latitude,
                  lng: selectedMarker.toAddressLocation.longitude,
                }}
              >
                <Card sx={{ maxWidth: "250px", padding: "3px" }}>
                  <CardHeader
                    title={`ID: ${selectedMarker.id}`}
                    sx={{ padding: "1px" }}
                    action={
                      <>
                        {" "}
                        <IconButton
                          sx={{ color: "#F40606" }}
                          onClick={() => props.handleMarkerClosed(null)}
                          size="small"
                        >
                          <CancelIcon />
                        </IconButton>
                      </>
                    }
                  />
                  <CardContent sx={{ padding: "1px" }}>
                    <div>
                      <IconButton
                        sx={{ color: "#1806F4", padding: "1px" }}
                        onClick={props.menuOption}
                        size="small"
                      >
                        <PersonIcon />
                      </IconButton>{" "}
                      {selectedMarker.userName}
                    </div>
                    <div> {selectedMarker.toAddress}</div>
                    <div className="linearButtons">
                      <a
                        className="nonstyle"
                        href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <IconButton
                          sx={{
                            color: "#1806F4",
                            padding: "1px",
                            width: "35px",
                            height: "35px",
                            fontSize: "2.5rem",
                          }}
                          onClick={() => {
                            // Agrega tu lógica de manejo de eventos aquí
                            // Por ejemplo, puedes mostrar un mensaje de confirmación antes de abrir Google Maps
                            // o realizar alguna acción adicional
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${selectedMarker.toAddressLocation.latitude},${selectedMarker.toAddressLocation.longitude}`,
                              "_blank"
                            );
                          }}
                        >
                          <AssistantDirectionIcon
                            sx={{
                              color: "#1806F4",
                              padding: "1px",
                              width: "35px",
                              height: "35px",
                              fontSize: "2.5rem",
                            }}
                          />
                        </IconButton>{" "}
                        <div> Google maps</div>
                      </a>

                      <a
                        className="nonstyle"
                        href={constructWazeLink(selectedMarker.toAddress)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img
                          src="/waze.svg"
                          alt="Icon"
                          className="marketr-icon"
                        />
                        <div> Navega con Waze</div>
                      </a>
                    </div>

                    <div className="linearButtons">
                      <a
                        className="nonstyle"
                        href={`tel:9${selectedMarker.userPhone}`}
                      >
                        <IconButton
                          sx={{
                            ml: 1,
                            color: "#1806F4",
                            padding: "1px",
                          }}
                          onClick={props.menuOption}
                          size="small"
                        >
                          <CallIcon />
                        </IconButton>
                        9{selectedMarker.userPhone}
                      </a>

                      <a
                        className="nonstyle"
                        href={`https://api.whatsapp.com/send?phone=+569${selectedMarker.userPhone}&text=Soporte%20BICCI%20:`}
                      >
                        <IconButton
                          sx={{
                            ml: 1,
                            color: "#29B834",
                            padding: "1px",
                          }}
                          onClick={props.menuOption}
                          size="small"
                        >
                          <WhatsAppIcon />
                        </IconButton>
                        9{selectedMarker.userPhone}
                      </a>
                    </div>
                  </CardContent>
                </Card>
              </InfoWindow>
            }
          </>
        ) : null}
      </GoogleMap>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default DynamicRouteBicciMapComponent;
