import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
function useGetDataDispachst(companyId, userRoll, OrderID) {
  const [bicciOrdersExpressByID, setBicciOrdersExpressByID] = useState(null);
  const [loadOrdersExpressByID, setLoadOrdersExpressByID] = useState(false);
  const [errorOrdersExpressByID, setErrorOrdersExpressByID] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    fetchData(companyId);
    return () => {
      setIsMounted(false);
    };
  }, [companyId, OrderID, isMounted]);

  const fetchData = async (companyId) => {
    try {
      setLoadOrdersExpressByID(true);
      if (userRoll === process.env.REACT_APP_ADMIN) {
        if (companyId) {
          const docRecf = doc(
            db,
            `${process.env.REACT_APP_COL_USERS_ORDERS}`,
            OrderID
          );
          const docData = await getDoc(docRecf);

          if (docData.exists()) {
            const dataRoute = docData.data();
            //console.log(dataRoute);
            setBicciOrdersExpressByID(dataRoute);
          } else {
            console.log("El documento no existe");
          }
        } else {
          const docRecf = doc(
            db,
            `${process.env.REACT_APP_COL_USERS_ORDERS}`,
            OrderID
          );
          const docData = await getDoc(docRecf);

          if (docData.exists()) {
            const dataRoute = docData.data();
            //console.log(dataRoute);
            setBicciOrdersExpressByID(dataRoute);
          } else {
            console.log("El documento no existe");
          }
        }
      } else {
        if (companyId) {
          const docRecf = doc(
            db,
            `${process.env.REACT_APP_COL_USERS_ORDERS}`,
            OrderID
          );
          const docData = await getDoc(docRecf);

          if (docData.exists()) {
            const dataRoute = docData.data();
            //console.log(dataRoute);
            setBicciOrdersExpressByID(dataRoute);
          } else {
            console.log("El documento no existe");
          }
        } else {
          setBicciOrdersExpressByID(null);
        }
      }

      setLoadOrdersExpressByID(false);
    } catch (error) {
      //console.log(error)
      setErrorOrdersExpressByID(true);
      setBicciOrdersExpressByID(null);
    }
  };
  return {
    bicciOrdersExpressByID,
    loadOrdersExpressByID,
    errorOrdersExpressByID,
  };
}

export default useGetDataDispachst;
