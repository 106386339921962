import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useObtenerResumendeCompras(companyuid){
  
  const [companyBilling,setUserdata] = useState(null)
  const [loadingCompanyBilling, setLoading] = useState(false)
  const [errorCompanyBilling, setError] = useState(null)

  useEffect(()=>{
    fetchData(companyuid)
  },[companyuid])


  const fetchData = async (id) => {
    setLoading(true)
    try { // history
        var table181 = await db.collection(`${process.env.REACT_APP_COL_BILLING}`).doc(companyuid).collection('history')
        .get();
        if(table181.docs.length>0){
        const Ordenes = table181.docs.map(doc => ({id: doc.id, ...doc.data()}))
        setUserdata(Ordenes)
        }
        setLoading(false)
    } catch (error) {
      //  console.log(error)
      setError(error)
      setLoading(false)
          
    }
  }

  return {companyBilling, loadingCompanyBilling, errorCompanyBilling}

}

export default useObtenerResumendeCompras