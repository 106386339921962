import React, { useEffect } from "react";
import { Card, CardHeader, LinearProgress, Box } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { NavLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import { format } from "date-fns";
import RutValidador from "./RutValidador";
import Chip from "@mui/material/Chip";
import ValidaNombre from "./ValidaNomre";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
const ToolDashOrdersContainerPics = (props) => {
  const [isActive, setIsACtive] = React.useState(false);
  const [isActiveBike, setIsACtiveBike] = React.useState(false);
  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);
  const [dataRemenberMergeLocalOrder, setDataRemenberMergeLocalOrder] =
    React.useState([]);
  const [render, setRender] = React.useState(true);

  useEffect(() => {
    console.log("Render");
  }, [render]);

  useEffect(() => {
    newOrder();
  }, [isActive, isActiveBike, props.dataMerge]);

  async function newOrder() {
    if (isActive === true) {
      console.log("Reorder Zones");
      console.log(dataMergeLocalOrder);
      async function ordenarPorDriverName(arr) {
        arr.sort((a, b) => a.commune2.localeCompare(b.commune2));
      }
      await ordenarPorDriverName(dataMergeLocalOrder);
      setDataMergeLocalOrder(dataMergeLocalOrder);
      setRender(!render);
    }
    if (isActiveBike === true) {
      console.log("Reorder Bike");
      console.log(dataMergeLocalOrder);
      async function ordenarPorZoneName(arr) {
        arr.sort((a, b) => a.driverName.localeCompare(b.driverName));
      }
      await ordenarPorZoneName(dataMergeLocalOrder);
      setDataMergeLocalOrder(dataMergeLocalOrder);
      setRender(!render);
    }
    if (isActive === false && isActiveBike === false) {
      setDataMergeLocalOrder(props.dataMerge);

      setRender(!render);
    }
  }

  const handleRowsOrder = (e) => {
    if (e.currentTarget.id === "orderZone") {
      if (isActive === false) {
        setIsACtive(true);
        setIsACtiveBike(false);
      } else {
        setIsACtive(false);
      }
    }
    if (e.currentTarget.id === "orderDrives") {
      if (isActiveBike === false) {
        setIsACtive(false);
        setIsACtiveBike(true);
      } else {
        setIsACtiveBike(false);
      }
    }
  };

  if (props.movil === true) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader title="Evidencias de Entrega no disponible en movil" />

        {dataMergeLocalOrder ? (
          <div className="table-card">
            {dataMergeLocalOrder.map((element, index) => (
              <div key={element.id} className="card">
                <div className="card-header">
                  <input
                    className="fontsize25"
                    type="checkbox"
                    defaultChecked={element.setectedItem}
                    id={element.id.toString()}
                    name={element.dispachtCode}
                    onChange={props.handleOrdersForAccions}
                  />
                  <span className="code">{`F${element.id}`}</span>
                </div>
                <div className="card-body">
                  <div className="card-info">
                    <div>
                      <span>Origen:</span> {element.placeName}
                    </div>
                    <div>
                      <span>Fecha de envío:</span>{" "}
                      {format(element.createdAt.seconds * 1000, "dd/MM/yy")}
                    </div>
                    <div>
                      <span>SOC:</span> {element.soc}
                    </div>
                    <div>
                      <span>Zonas:</span> {element.commune2}
                    </div>
                    <div>
                      <span>F. Creado:</span>{" "}
                      {format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")}
                    </div>
                    <div>
                      <span>Ultimo Registro:</span>{" "}
                      {format(
                        element.updatedAt.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )}
                    </div>
                    <div>
                      <span>Estatus:</span>{" "}
                      {element.falabella_status ? (
                        <>{element.falabella_status}</>
                      ) : (
                        <NewReleasesIcon className="pinEstatusCancel" />
                      )}
                    </div>
                    <div>
                      <span>Detalle:</span>{" "}
                      <NavLink
                        to={{
                          pathname: `/dashboard/dispatch/details/F${element.id}`,
                        }}
                        element={element}
                        color="primary"
                        variant="contained"
                        sx={{
                          backgroundColor: "#030303",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        ver
                      </NavLink>
                    </div>
                  </div>
                  <div className="card-driver">
                    <Avatar
                      src={element.driverAvatar}
                      sx={{ bgcolor: "#f2f2f2" }}
                      aria-label="recipe"
                    />
                    <div>{element.driverName}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <LinearProgress />
        )}
      </Card>
    );
  }
  if (props.movil === false) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader
          title="Revisión de Evidencias de entrega"
          subheader={
            <div>
              {" "}
              {isActive ? (
                <>
                  <Chip
                    label={"Agrupacion Zona"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderZone"}
                    icon={
                      <GroupWorkIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#1ef708",
                    }}
                  />{" "}
                </>
              ) : (
                <>
                  <Chip
                    label={"Agrupacion Zona"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderZone"}
                    icon={
                      <GroupWorkIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#e6e6e6",
                    }}
                  />{" "}
                </>
              )}
              {isActiveBike ? (
                <>
                  <Chip
                    label={"Agrupacion Riders"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderDrives"}
                    icon={
                      <DirectionsBikeIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#1ef708",
                    }}
                  />{" "}
                </>
              ) : (
                <>
                  <Chip
                    label={"Agrupacion Riders"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderDrives"}
                    icon={
                      <DirectionsBikeIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#e6e6e6",
                    }}
                  />{" "}
                </>
              )}
            </div>
          }
        />

        <Box sx={{ minWidth: 800 }}>
          {dataMergeLocalOrder ? (
            <table className="table table-card">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Código</th>

                  <th scope="col">Fecha de envío</th>

                  <th scope="col">Zonas</th>

                  <th scope="col">Ultimo Registro</th>
                  <th scope="col">Estatus</th>
                  <th scope="col">Detalle</th>
                  {props.containerHistory &&
                  Object.keys(props.containerHistory).length !== 0 ? (
                    <th scope="col">Historial</th>
                  ) : null}
                  <th scope="col">Conductor</th>
                  <th scope="col">Datos Entrega</th>
                  <th scope="col">Fotos</th>
                  <th scope="col">Validacion Rut</th>
                  <th scope="col">Validacion Nombre</th>
                </tr>
              </thead>
              <tbody>
                {dataMergeLocalOrder.map((element, index) => (
                  <tr key={element.id}>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        className="fontsize25"
                        type="checkbox"
                        defaultChecked={element.setectedItem}
                        id={element.id.toString()}
                        name={element.dispachtCode}
                        onChange={props.handleOrdersForAccions}
                      />
                    </td>
                    <td>{"F" + element.id.toString()}</td>

                    <td>
                      {format(
                        element.createdAt.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )}
                    </td>

                    <td>{element.commune2}</td>

                    <td>
                      {format(
                        element.updatedAt.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )}
                    </td>

                    <td>
                      {element.falabella_status ? (
                        <>{element.falabella_status}</>
                      ) : (
                        <NewReleasesIcon className="pinEstatusCancel" />
                      )}
                    </td>

                    <td className="minimalTd">
                      {props.containerHistory &&
                      Object.keys(props.containerHistory).length !== 0 &&
                      props.containerHistory[element.soc] ? (
                        <span>
                          {props.containerHistory[element.soc].length <= 4 ? (
                            <>
                              1 Intento{" "}
                              {
                                props.translateStateBicciFalabella[
                                  element.falabella_status
                                ]
                              }
                            </>
                          ) : (
                            <>
                              Con Excepciones{" "}
                              {
                                props.translateStateBicciFalabella[
                                  element.falabella_status
                                ]
                              }
                            </>
                          )}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <span
                            className={
                              element.falabella_status &&
                              props.styleStateBicciFalabella[
                                element.falabella_status
                              ]
                                ? `${
                                    props.styleStateBicciFalabella[
                                      element.falabella_status
                                    ]
                                  }`
                                : "Undefine"
                            }
                          >
                            {
                              props.translateStateBicciFalabella[
                                element.falabella_status
                              ]
                            }
                          </span>
                        </span>
                      )}
                    </td>
                    {props.containerHistory &&
                    Object.keys(props.containerHistory).length !== 0 ? (
                      <td>
                        {props.containerHistory &&
                        Object.keys(props.containerHistory).length !== 0 &&
                        props.containerHistory[element.soc] ? (
                          <span>
                            <ul className="list-dots mb-0">
                              {props.containerHistory[element.soc].map(
                                (item) => (
                                  <li key={`id${item.id}`}>
                                    {
                                      props.translateStateBicciFalabella[
                                        item.status
                                      ]
                                    }
                                  </li>
                                )
                              )}
                            </ul>
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <span
                              className={
                                element.falabella_status &&
                                props.styleStateBicciFalabella[
                                  element.falabella_status
                                ]
                                  ? `${
                                      props.styleStateBicciFalabella[
                                        element.falabella_status
                                      ]
                                    }`
                                  : "Undefine"
                              }
                            >
                              {
                                props.translateStateBicciFalabella[
                                  element.falabella_status
                                ]
                              }
                            </span>
                          </span>
                        )}
                      </td>
                    ) : null}
                    <td>{element.driverName}</td>
                    <td>
                      <div>{element.userNameReceptor}</div>
                      <div>{element.userDniReceptor}</div>
                    </td>
                    <td>
                      {element.pics && element.pics.length > 0 ? (
                        <>
                          {" "}
                          {element.pics.map((item, index) => (
                            <img
                              key={index}
                              className="imgavatar"
                              src={item}
                              alt={""}
                            ></img>
                          ))}
                        </>
                      ) : (
                        <span className="alert alert-danger">'Sin Foto'</span>
                      )}
                    </td>
                    <td>
                      {element.userDniReceptor ? (
                        <RutValidador
                          rut={element.userDniReceptor}
                        ></RutValidador>
                      ) : (
                        "null"
                      )}
                    </td>
                    <td>
                      {element.userNameReceptor ? (
                        <ValidaNombre name={element.userNameReceptor}>
                          {" "}
                        </ValidaNombre>
                      ) : (
                        "null"
                      )}
                    </td>
                    <td>
                      <NavLink
                        to={{
                          pathname: `/dashboard/dispatch/details/F${element.id}`,
                        }}
                        element={element}
                        color="primary"
                        variant="contained"
                        sx={{
                          backgroundColor: "#030303",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        {" "}
                        ver{" "}
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LinearProgress></LinearProgress>
          )}
        </Box>
      </Card>
    );
  }
  return <div>No se reconose el dispositivo</div>;
};

export default ToolDashOrdersContainerPics;
