
import  {  useState, useEffect } from 'react'

function useGetTravelModels (){
   const [travelModels, setTravelModels ] = useState('')
    const [loadtravelModels, setLoadtravelModels ] = useState(null)
    useEffect(()=>{
        getData()
      },[])

      const getData = () =>{
        setLoadtravelModels(true)
        //console.log(travelModels)
        const travelModels = [
            {id:"WALKING"},
            {id:"BICYCLING"},
            {id:"DRIVING"},
            {id:"TWO_WHEELER"}]
            setTravelModels(travelModels)
        setLoadtravelModels(false)
      }

   

    return {travelModels, loadtravelModels}
}

export default useGetTravelModels