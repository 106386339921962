import React, { useEffect, useState, useRef } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import { Avatar, Grid, Switch, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MapIcon from "@mui/icons-material/Map";
import RouteIcon from "@mui/icons-material/Route";
import Moment from "moment";
import {
  registerOrderIssures,
  registerPickerActions,
} from "../../redux/accionesOrders";
const ScannerDistribution = (props) => {
  const dispacth = useDispatch();
  //const [currentUser, setCurrentUser] = useState(props.usuarioStorage.uid);
  const [barcodeDataImput, setBarcodeDataInput] = useState("");
  const [serahrCodeDataImput, setSearchBarcodeDataInput] = useState("");
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [serachResults, setSerachResults] = useState([]);
  const [dataSearch, setDataSearch] = React.useState([]);
  const [inputDataMethod, setInputDataMethod] = React.useState(true);
  const [mute, setMute] = React.useState(true);
  const [muteDriver, setMuteDriver] = React.useState(true);
  const [muteRoute, setMuteRoute] = React.useState(true);
  const [muteZone, setMuteZone] = React.useState(true);
  const [localTempData, setLocalTemData] = React.useState([]);
  const synth = window.speechSynthesis;
  const miColeccionRef = collection(db, process.env.REACT_APP_COL_ORDERS);
  const miCampo = "key";

  const input1Ref = useRef(null);

  //   const handleBluetoothScan = () => {
  //     setWaitingForScan(true); // Mostrar mensaje de espera
  //     navigator.bluetooth
  //       .requestDevice({
  //         filters: [{ services: ["00001234-0000-1000-8000-00805f9b34fb"] }],
  //       })
  //       .then((device) => {
  //         // Conectarse al dispositivo y configurar la lectura de datos
  //         return device.gatt.connect();
  //       })
  //       .then((server) => {
  //         // Obtener el servicio y la característica para recibir datos
  //         return server.getPrimaryService("00001801-0000-1000-8000-00805f9b34fb");
  //       })
  //       .then((service) => {
  //         return service.getCharacteristic("bluetooth_characteristic_uuid");
  //       })
  //       .then((characteristic) => {
  //         // Comenzar a escuchar los datos del escáner
  //         characteristic.addEventListener(
  //           "characteristicvaluechanged",
  //           (event) => {
  //             const barcodeData = new TextDecoder().decode(event.target.value);
  //             // Procesar los datos del código de barras
  //             setBarcodeDataInput(barcodeData);
  //             setSearchBarcodeDataInput(barcodeData);
  //             setTimeout(() => {
  //               setBarcodeDataInput(""); // Limpiar después de un tiempo
  //               setWaitingForScan(false); // Ocultar mensaje de espera
  //             }, 500);
  //           }
  //         );
  //         return characteristic.startNotifications();
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         setWaitingForScan(false); // Manejar el error y ocultar mensaje de espera
  //       });
  //   };
  useEffect(() => {
    findOrdesrDispacht();
  }, []);

  useEffect(() => {
    if (!mute) {
      if (dataSearch.length === 1) {
        dataSearch.map((item) => {
          if (!muteDriver) {
            item.driverName && item.driverName !== "isRequire"
              ? speakText(item.driverName)
              : speakText("No Asignado");
          }

          if (!muteRoute) {
            item.driverLicensePlate && item.driverLicensePlate !== "isRequire"
              ? speakText(`Ruta ${item.driverLicensePlate}`)
              : speakText("Sin Vehiculo");
          }

          if (!muteZone) {
            item.routeLocality && item.routeLocality !== "isRequire"
              ? speakText(`Zona ${item.routeLocality}`)
              : speakText("Dirección incompleta");
          }
        });
      } else {
        speakText(`${dataSearch.length} resultado encontrados`);
      }
    }

    if (dataSearch.length === 1) {
      if (props.usuarioStorage) {
        dispacth(registerPickerActions(dataSearch[0], props.usuarioStorage));
      }
    }
    if (dataSearch.length === 0) {
      if (props.usuarioStorage) {
        let data = { id: serahrCodeDataImput };
        //dispacth(registerOrderIssures(data, props.usuarioStorage));
      }
    }
  }, [serachResults]);

  useEffect(() => {
    const valorBuscado = serahrCodeDataImput;
    findOrders(miCampo, valorBuscado);
    if (serahrCodeDataImput.length > 3) {
      filterData(serahrCodeDataImput);
    }
  }, [serahrCodeDataImput]);

  useEffect(() => {
    // Enfocar el campo de entrada al cargar la página
    setWaitingForScan(true);
    input1Ref.current.focus();
    // Agregar un manejador de eventos para hacer clic en el cuerpo
    document.body.addEventListener("click", handleBodyClick);

    // Limpia el manejador de eventos al desmontar el componente
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      //    console.log("Buscar", obj, searchTerm);
      for (let key in obj) {
        const value = obj[key].toString();
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = localTempData.filter((item) =>
      filterFunction(item, dataSearch)
    );
    // console.log(filteredData);

    setDataSearch(filteredData);
  };

  const handleFocus = () => {
    setWaitingForScan(true);
  };

  const handleBlur = () => {
    setWaitingForScan(false);
  };

  const findOrders = async (miCampo, valorBuscado) => {
    try {
      const q = query(miColeccionRef, where(miCampo, "==", valorBuscado));

      let ordesBicciexpres = await getDocs(q);
      const dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (dataOrdesBicciexpres.length === 0) {
        setSerachResults([]);
      } else {
        setSerachResults(dataOrdesBicciexpres);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findOrdesrDispacht = async () => {
    try {
      const i = Moment(new Date().now);
      const e = Moment(new Date().now);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 6);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      callNormal();

      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            // where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.orderID]) {
              uniqueObjects[obj.orderID] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setLocalTemData(filteredArray);
        } catch (error) {
          console.log("error 345");
          // console.log(error);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleBarcodeScan = (data) => {
    setBarcodeDataInput(data);
    setSearchBarcodeDataInput(data);
    if (inputDataMethod === true) {
      setTimeout(() => {
        //console.log("Procesando...");
        setBarcodeDataInput("");
        input1Ref.current.focus();
      }, 500);
    }
  };

  const listenscanner = () => {
    input1Ref.current.focus();
  };
  const handleBodyClick = (e) => {
    // Verifica si el clic se realizó fuera del campo de entrada
    if (!input1Ref.current.contains(e.target)) {
      // Si el clic fue fuera del campo, vuelve a enfocar el campo de entrada
      input1Ref.current.focus();
    }
  };

  const handleChange = (event) => {
    setInputDataMethod(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const speakText = (text) => {
    if (synth) {
      const utterance = new SpeechSynthesisUtterance(text);
      synth.speak(utterance);
    }
  };

  return (
    <Box
      alt="bicciexpress"
      sx={{
        objectFit: "contain",
        width: "100%",
        height: "100%",
        //backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
        //backgroundSize: "100% auto", // Escala horizontal completa
        //backgroundRepeat: "no-repeat", // No se repite
        // backgroundColor: "#e58888", //"#aef3c3",
        padding: "1em",
        marginBottom: "0px",
      }}
    >
      <Card
        sx={{
          maxWidth: 900,
          height: "90%",
          maxHeight: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2px",
          marginBottom: "5%",
        }}
      >
        <CardHeader
          subheader={
            <div>
              <Switch
                checked={inputDataMethod}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: waitingForScan ? "#aef3c3" : "#e58888",
                }}
              />
              <Typography
                fontFamily="Lato, Arial, Helvetica, sans-serif"
                color="textPrimary"
                variant="body"
              >
                {inputDataMethod ? (
                  <span>Escaner seleccionado</span>
                ) : (
                  <span>Teclado seleccionado</span>
                )}
              </Typography>
            </div>
          }
          sx={{ padding: "1px", margin: "0px" }}
        />
        {/* <CardMedia
          component="img"
          height="100"
          image="/static/images/asset/entradateclado.png"
          alt="bicciexpress"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "50%",
            backgroundColor: "#aef3c3",
          }}
        /> */}
        <CardContent sx={{ padding: "1px" }}>
          <Box
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "99%",
              //   backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
              //   backgroundSize: "100% auto", // Escala horizontal completa
              //   backgroundRepeat: "no-repeat", // No se repite
              backgroundColor: waitingForScan ? "#aef3c3" : "#e58888", //,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                padding: "1px",
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "30%",
                  },

                  overflowY: "auto",
                  maxHeight: "100vh",
                  padding: "2px",
                  maxWidth: "100%",
                }}
              >
                <AppBar
                  position="static"
                  sx={{
                    color: "#8F8F8",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                    },
                    backgroundColor: "#e3e3e3",
                    overflowY: "auto",
                    maxHeight: "100vh",
                  }}
                >
                  <Toolbar variant="dense" disableGutters>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textPrimary"
                      variant="body"
                      sx={{
                        fontSize: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "1rem",
                          lg: "1rem",
                        },
                        flexGrow: 1,
                        color: "#591e8f",
                      }}
                    >
                      Escáner de distribución
                    </Typography>

                    <Avatar
                      alt="Remy Sharp"
                      src={
                        inputDataMethod
                          ? "/static/images/asset/escanner.png"
                          : "/static/images/asset/teclado.png"
                      }
                      sx={{
                        backgroundColor: waitingForScan ? "#aef3c3" : "#e58888",
                        height: 50,
                        width: 50,
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                    />

                    {waitingForScan ? (
                      <ChangeCircleIcon
                        sx={{ color: green[500], marginRight: 1 }}
                      />
                    ) : (
                      <CachedIcon sx={{ color: "#591e8f", marginRight: 1 }} />
                    )}

                    <Typography
                      variant="body2"
                      sx={{ marginRight: 2, color: "#591e8f" }}
                    >
                      {waitingForScan ? "On" : "Off"}
                    </Typography>

                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => setMute(!mute)}
                      sx={{ color: "#591e8f" }}
                    >
                      {!mute ? (
                        <VolumeUpIcon sx={{ color: green[500] }} />
                      ) : (
                        <VolumeOffIcon sx={{ color: "#591e8f" }} />
                      )}
                    </IconButton>

                    <IconButton
                      size="large"
                      aria-label="mute driver"
                      onClick={() => setMuteDriver(!muteDriver)}
                      sx={{ color: "#591e8f" }}
                    >
                      <DirectionsBikeIcon
                        sx={{ color: !muteDriver ? green[500] : "#4f4f4f" }}
                      />
                    </IconButton>

                    <IconButton
                      size="large"
                      aria-label="mute zone"
                      onClick={() => setMuteZone(!muteZone)}
                      sx={{ color: "#591e8f" }}
                    >
                      <MapIcon
                        sx={{ color: !muteZone ? green[500] : "#4f4f4f" }}
                      />
                    </IconButton>

                    <IconButton
                      size="large"
                      aria-label="mute route"
                      onClick={() => setMuteRoute(!muteRoute)}
                      sx={{ color: "#591e8f" }}
                    >
                      <RouteIcon
                        sx={{ color: !muteRoute ? green[500] : "#4f4f4f" }}
                      />
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "70%",
                    padding: "1px",
                  },
                  height: "100%",
                  bottom: "0px",
                }}
              >
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  variant="body"
                >
                  {waitingForScan ? (
                    <div>
                      Esperando{" "}
                      {inputDataMethod
                        ? "lecturas por escanner..."
                        : "digitación de codigo..."}{" "}
                    </div>
                  ) : (
                    <div>
                      {inputDataMethod
                        ? "Mantenga la pagina activa para escanear..."
                        : "Mantenga la pagina activa para buscar..."}
                    </div>
                  )}
                </Typography>

                <div className="searchtiketcontainers">
                  <Card
                    sx={{
                      maxWidth: "450px",
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "1px",
                      textAlign: "center",
                    }}
                  >
                    <div className="inputcode">
                      <input
                        type="text"
                        id="codigoBarras"
                        className={
                          inputDataMethod
                            ? "barcode-input"
                            : "barcode-input-keyboar"
                        }
                        ref={input1Ref}
                        autoFocus
                        placeholder={
                          inputDataMethod
                            ? "|||||||| ||| |||| |||||"
                            : "Digite el codígo abuscar"
                        }
                        value={barcodeDataImput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={(e) => handleBarcodeScan(e.target.value)}
                        //   sx={{
                        //     ...styles.inputBase,
                        //     "::before": {
                        //       ...styles.beforeContent,
                        //     },
                        //   }}
                      />

                      {/* Resto de tu interfaz y lógica aquí */}
                    </div>
                    {serahrCodeDataImput ? (
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textPrimary"
                        variant="h2"
                      >
                        {serahrCodeDataImput}
                      </Typography>
                    ) : null}
                  </Card>

                  {serachResults && serachResults.length !== 0 ? (
                    <div className="tiketcontainers">
                      {serachResults.map((item) => (
                        <Card
                          className="m-4 b-1"
                          key={item.id}
                          sx={{
                            boxShadow: "#F8F8F8",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <CardHeader
                            title={
                              item.dataOrigin === "SDDATA"
                                ? "Same Day"
                                : "Home Delivery"
                            }
                            subheader={<div>{item.id}</div>}
                          />
                          <Card
                            variant="solid"
                            sx={{
                              backgroundColor: "#F8F8F8",
                              padding: "2px",
                              margin: "2px",
                            }}
                          >
                            <CardContent
                              sx={{
                                backgroundColor: "#F8F8F8",
                                padding: "2px",
                              }}
                            >
                              {item.int_alert ? (
                                <Typography
                                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                                  color="textPrimary"
                                  variant="h4"
                                  sx={{
                                    padding: "2px",
                                  }}
                                >
                                  <span className="alertcolor bigText">
                                    DISCREPANCIA REGISTRADA
                                  </span>
                                </Typography>
                              ) : null}
                              <Typography>RIDER</Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                variant="h4"
                                sx={{
                                  padding: "2px",
                                }}
                              >
                                <div>
                                  {!item.driverName ||
                                  item.driverName === "isRequire" ||
                                  item.driverName === "" ? (
                                    <span className="alertRed bigText">
                                      No asignado
                                    </span>
                                  ) : (
                                    <span className="alertcolor bigText">
                                      {item.driverName}
                                    </span>
                                  )}
                                </div>
                              </Typography>
                            </CardContent>
                          </Card>
                          <Card
                            variant="solid"
                            sx={{
                              backgroundColor: "#F8F8F8",
                              padding: "2px",
                              margin: "2px",
                            }}
                          >
                            <CardContent
                              sx={{
                                backgroundColor: "#F8F8F8",
                                padding: "2px",
                              }}
                            >
                              <Typography>RUTA</Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                variant="h4"
                                sx={{
                                  padding: "2px",
                                }}
                              >
                                <div>
                                  {item.driverLicensePlate === "isRequire" ||
                                  item.driverLicensePlate === "null" ? (
                                    <span className="alertRed bigText">
                                      Sin Ruta
                                    </span>
                                  ) : (
                                    <span className="alertcolor bigText">
                                      {item.driverLicensePlate}
                                    </span>
                                  )}
                                </div>
                              </Typography>
                            </CardContent>
                          </Card>
                          <Card
                            variant="solid"
                            sx={{
                              backgroundColor: "#F8F8F8",
                              padding: "2px",
                              margin: "2px",
                            }}
                          >
                            <CardContent
                              sx={{
                                backgroundColor: "#F8F8F8",
                                padding: "2px",
                              }}
                            >
                              <Typography>ZONA</Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                variant="h4"
                                sx={{
                                  padding: "2px",
                                }}
                              >
                                <div>{item.routeLocality}</div>
                              </Typography>
                            </CardContent>
                          </Card>
                          <Card
                            variant="solid"
                            sx={{
                              backgroundColor: "#F8F8F8",
                              padding: "2px",
                              margin: "2px",
                            }}
                          >
                            <CardContent
                              sx={{
                                backgroundColor: "#F8F8F8",
                                padding: "2px",
                              }}
                            >
                              <Typography>PUNTO DE RUTA </Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                variant="h4"
                                sx={{
                                  padding: "2px",
                                }}
                              >
                                <div> {item.routePosition}</div>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {dataSearch && dataSearch.length !== 0 ? (
                        <div className="tiketcontainers">
                          {dataSearch.map((item) => (
                            <Card
                              className="m-4 b-1"
                              key={item.id}
                              sx={{
                                boxShadow: "#F8F8F8",
                              }}
                            >
                              <CardHeader
                                title={
                                  <div>
                                    <div>
                                      {item.dataOrigin === "SDDATA"
                                        ? "Same Day ( SD )"
                                        : "Home Delivery ( HD )"}
                                    </div>
                                    <div>{item.id}</div>
                                  </div>
                                }
                              />
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  {item.int_alert ? (
                                    <Typography
                                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                                      color="textPrimary"
                                      variant="h4"
                                      sx={{
                                        padding: "2px",
                                      }}
                                    >
                                      <span className="alertRed bigText">
                                        DISCREPANCIA REGISTRADA
                                      </span>
                                    </Typography>
                                  ) : null}
                                  <Typography>RIDER</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div>
                                      {!item.driverName ||
                                      item.driverName === "isRequire" ||
                                      item.driverName === "" ? (
                                        <span className="alertRed bigText">
                                          No asignado
                                        </span>
                                      ) : (
                                        <span className="alertcolor bigText">
                                          {item.driverName}
                                        </span>
                                      )}
                                    </div>
                                  </Typography>
                                </CardContent>
                              </Card>
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Typography>RUTA</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div>
                                      {item.driverLicensePlate ===
                                        "isRequire" ||
                                      item.driverLicensePlate === "null" ? (
                                        <span className="alertRed bigText">
                                          Sin Ruta
                                        </span>
                                      ) : (
                                        <span className="alertcolor bigText">
                                          {item.driverLicensePlate}
                                        </span>
                                      )}
                                    </div>
                                  </Typography>
                                </CardContent>
                              </Card>
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Typography>ZONA</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div>{item.routeLocality}</div>
                                  </Typography>
                                </CardContent>
                              </Card>
                              <Card
                                variant="solid"
                                sx={{
                                  backgroundColor: "#F8F8F8",
                                  padding: "2px",
                                  margin: "2px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    backgroundColor: "#F8F8F8",
                                    padding: "2px",
                                  }}
                                >
                                  <Typography>PUNTO RUTA</Typography>
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textPrimary"
                                    variant="h4"
                                    sx={{
                                      padding: "2px",
                                    }}
                                  >
                                    <div> {item.routePosition}</div>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Card>
                          ))}
                        </div>
                      ) : (
                        <div>Sin resultados</div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ScannerDistribution;
