import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase";

export const findOrderByContext = async (args) => {
  let res = { data: {} };
  let dataOrders = [];

  // args[1] contiene los parámetros enviados desde useSWR
  const params = args[1];

  console.log("params:", params);

  const {
    companyIDs,
    state,
    dateField = "createdAt",
    startDate,
    endDate,
  } = params;

  function adjustStartDate(date) {
    const res = new Date(date);
    res.setHours(0, 0, 0, 0);
    return res;
  }

  function adjustEndDate(date) {
    const res = new Date(date);
    res.setHours(23, 59, 59, 999);
    return res;
  }

  const rangInitial = adjustStartDate(startDate);
  const rangFinal = adjustEndDate(endDate);

  console.log("rangInitial:", rangInitial);
  console.log("rangFinal:", rangFinal);

  try {
    const refsCollOrder = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    let queries = [];

    if (companyIDs && companyIDs.length > 0) {
      // Crear una consulta para cada companyID
      companyIDs.forEach((companyID) => {
        let q = query(
          refsCollOrder,
          where(dateField, ">=", rangInitial),
          where(dateField, "<=", rangFinal),
          where("companyID", "==", companyID)
        );
        queries.push(getDocs(q));
      });

      // Ejecutar todas las consultas y combinar los resultados
      const querySnapshots = await Promise.all(queries);

      querySnapshots.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          dataOrders.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      });
    } else {
      // Si no hay companyIDs, hacer la consulta solo con fechas
      let q = query(
        refsCollOrder,
        where(dateField, ">=", rangInitial),
        where(dateField, "<=", rangFinal)
      );

      const querySnapshot = await getDocs(q);

      dataOrders = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    }
    console.log(dataOrders);
    if (state !== undefined && state !== null) {
      dataOrders = dataOrders.filter((order) => order.status === state);
    }

    console.log("dataOrders:", dataOrders);

    res.data.orders = dataOrders;
  } catch (error) {
    console.error("Error fetching orders:", error);
  }

  return res.data;
};
