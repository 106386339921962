import React, { useCallback, useMemo, useRef, useState } from "react";
import OpenLayersMaps from "../../../components/OpenLayersMaps/OpenLayersMaps";
import { useEffect } from "react";
import useGetDataDispachstByDate from "../../../hooks/getDataDispachstByDate";
import Moment from "moment";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { Grid, LinearProgress } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import { Avatar, Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import { Box } from "@mui/material";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "react-bootstrap";
import { db } from "../../../firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament.jsx";
import { differenceInDays } from "date-fns";
import { MapVectoriales } from "../../../utils/Polygons/VectorialSatelite.js";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames.js";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext.jsx";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker/";
import { fDate } from "../../../utils/objects/FormatTime.jsx";
import EventIcon from "@mui/icons-material/Event";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys.jsx";
import { CustomDateRangePickerStyle } from "../../../components/CustomDateRangePicker/CustomDateRangePickerStyle.jsx";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent.jsx";

const libraries = ["places", "drawing", "geometry"];

const DistributionsGoogleMapsCompany = (props) => {
  //console.log(props);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = useState(null);
  const [stateUser, setStateUser] = useState(null);
  const [path, setPath] = useState([]);
  const [viewDrivers, setViewDrivers] = useState(true);
  const [viewDeliverys, setViewDeliverys] = useState(true);
  const [viewExepcions, setViewViewExepcions] = useState(true);
  const [viewPendings, setViewPendings] = useState(true);
  const [viewPoligons, setViewPoligons] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerDriver, setSelectedMarkerDriver] = useState(null);
  const [center, setCenter] = useState({ lat: -33.448891, lng: -70.669266 });
  const [zoom, setZoom] = useState(13); // Asumiendo un zoom inicial
  const [driver, setDriver] = useState("");
  const [driverFilter, setDriverFilter] = useState([]);
  const [ordersByPoligons, setOrdersByPoligons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState("");
  const [polygons, setPolygons] = useState(null);
  const [polygonsType, setPolygonsType] = useState(null);
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);
  const [ordersForAccions, setOrdersForAccions] = useState([]);
  const [vertical, setServiceVertical] = useState("all");
  const [polygonSelectedName, setPolygonSelectedName] = useState("");
  const pines = true;
  let infoWindowMap = {};
  const user = useSelector((store) => store.usuario.user);
  const { userdata } = useObtenerDatosdeCliente(user.uid);

  //-- Nuevas Variables

  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [messajeAdmin, setMenssajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
  } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Asegúrate de que los driverID sean únicos
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];

        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );

          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            // Agregar solo si no está ya presente
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }

        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    // console.log(mergedOrders);
    // console.log(ridersDataByCompany);
    // console.log(VehiclesNames);
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      // Extraer ridersDataByCompany sin duplicados y sin driverID vacío o indefinido
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      // Esta función agrupa las órdenes por tienda y calcula métricas
      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [], // Puedes omitir esto si `commune2` no existe
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);
        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        // Omitir lógica de `commune2` si no existe
        if (order.commune2) {
          const comunaIndex = acc[driverID].locality.findIndex(
            (comuna) => Object.keys(comuna)[0] === order.commune2
          );

          if (comunaIndex >= 0) {
            acc[driverID].locality[comunaIndex][order.commune2]++;
          } else {
            const newComuna = { [order.commune2]: 1 };
            acc[driverID].locality.push(newComuna);
          }
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const memoizedData = useMemo(() => {
    if (viewMergedOrders && metricsOps && ridersDataByCompany) {
      return { dataMerge: viewMergedOrders, metricsOps, ridersDataByCompany };
    }
  }, [viewMergedOrders, metricsOps, ridersDataByCompany]);

  const google = window.google;

  useEffect(() => {
    if (userdata) {
      setStateUser(userdata);
      setCompanyCollaborator(userdata.companyCollaborator);
    }
  }, [userdata]);

  useEffect(() => {}, [setSelectedMarker]);

  useEffect(() => {
    if (path && path.length > 0) {
      findPoints();
    }
  }, [path]);

  // useEffect(() => {
  //   console.log(bicciExpressPolygons);
  //   // Transforma los datos GeoJSON para que se puedan utilizar en el componente Polygon
  //   const pathsFromGeoJson = transformGeoJsonToPaths(bicciExpressPolygons);

  //   // Suponiendo que tu estado de polygons es un array de paths
  //   setPolygons(pathsFromGeoJson);
  // }, [viewPoligons]);

  // const transformGeoJsonToPaths = (geoJson) => {
  //   // Asumiendo que tu GeoJSON es una colección de características de tipo polígono
  //   const paths = geoJson.features.map((feature) =>
  //     feature.geometry.coordinates[0].map((coord) => ({
  //       lat: coord[1],
  //       lng: coord[0],
  //     }))
  //   );

  //   return paths;
  // };

  const setSeleccionByMapDataWaitPoints = () => {};

  const mapRef = useRef();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const donwLoadPolygons = async () => {
    if (!isLoaded) return;

    const allPolygonsRef = collection(db, process.env.REACT_APP_COL_POLYGONS);

    const qa = query(
      allPolygonsRef,
      where("companyId", "==", props.usuarioStorage.company)
    );
    const dataallPolygonsfind2 = await getDocs(qa);
    let dataPolygonsBase = dataallPolygonsfind2.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Transforma los datos de los polígonos para ser utilizados en el mapa
    const googlePolygons = dataPolygonsBase.map((polygonData) => {
      const polygonOptions = {
        paths: polygonData.coordinates,
        editable: false,
        draggable: false,
        strokeWeight: 1,
        fillOpacity: 0.3,
        fillColor: "#591e8f",
        strokeColor: "#591e8f",
        strokeOpacity: 0.8,
      };

      return new google.maps.Polygon(polygonOptions);
    });

    googlePolygons.forEach((googlePolygon) => {
      googlePolygon.setMap(mapRef.current);

      googlePolygon.addListener("click", () => {
        polygonRef.current = googlePolygon;

        const coordinates = googlePolygon
          .getPath()
          .getArray()
          .map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));

        setSelectedPolygon({
          polygon: googlePolygon,
          coordinates: coordinates,
        });

        // Aquí se actualiza correctamente setPath con las coordenadas del polígono
        setPath(coordinates);
        console.log("Polígono seleccionado:", googlePolygon);
      });
    });

    setPolygons(googlePolygons);
  };

  function closeInfoWindow(index) {
    const infoWindow = infoWindowMap[index];
    if (infoWindow) {
      infoWindow.close();
    }
  }

  const clearPolygons = () => {
    if (polygons) {
      // Itera sobre todos los polígonos actuales y elimínalos del mapa.
      polygons.forEach((polygon) => polygon.setMap(null));
      setPolygons(null); // Limpia la referencia en el estado después de quitarlos del mapa.
    }
  };

  const loadStaticPolygons = () => {
    if (!isLoaded) return;

    const googlePolygons = MapVectoriales.features.flatMap((feature, index) => {
      if (feature.geometry.type === "Polygon") {
        const paths = feature.geometry.coordinates[0].map(([lng, lat]) => ({
          lat,
          lng,
        }));
        const polygonOptions = {
          paths: paths,
          editable: false,
          draggable: false,
          strokeWeight: 1,
          fillOpacity: 0.3,
          fillColor: "#591e8f",
          strokeColor: "#591e8f",
          strokeOpacity: 0.8,
        };

        const poly = new google.maps.Polygon(polygonOptions);
        poly.setMap(mapRef.current);

        const bounds = new google.maps.LatLngBounds();
        paths.forEach((p) => bounds.extend(p));
        const center = bounds.getCenter();

        const contentString = `<div id="infoWindowContent"><button id="closeButton${index}" style="background-color: red; color: white; border: none; border-radius: 50%; width: 20px; height: 20px; line-height: 20px; text-align: center; padding: 0; font-size: 12px; cursor: pointer;">x</button>
<strong>${feature.properties.Name}</strong><br/></div>`;

        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
          position: center,
        });
        infoWindow.open(mapRef.current);
        infoWindowMap[index] = infoWindow;
        google.maps.event.addListener(poly, "click", (event) => {
          if (infoWindow.getMap()) {
            infoWindow.close(); // Cierra la ventana si está abierta al hacer clic en el polígono nuevamente
          } else {
            infoWindow.open(mapRef.current, poly); // Abre la ventana si está cerrada
          }
        });

        google.maps.event.addListener(infoWindow, "domready", () => {
          document
            .getElementById(`closeButton${index}`)
            .addEventListener("click", () => closeInfoWindow(index));
        });

        google.maps.event.addListener(poly, "click", (event) => {
          setPolygonSelectedName(feature.properties.Name);
          const coordinates = poly
            .getPath()
            .getArray()
            .map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            }));

          setSelectedPolygon({
            polygon: poly,
            coordinates: coordinates,
          });

          setPath(coordinates);
        });

        return poly;
      } else {
        console.warn("Unhandled geometry type:", feature.geometry.type);
        return [];
      }
    });

    // Opcional: actualiza el estado de polígonos si necesitas acceder a ellos fuera de esta función
    setPolygons(googlePolygons);
  };

  useEffect(() => {
    //if (!polygons) {
    clearPolygons();
    if (viewPoligons) {
      switch (polygonsType) {
        case "redpoligonos":
          loadStaticPolygons();
          break;
        case "comunaspoligonos":
          donwLoadPolygons();
          break;
        default:
          setPolygons(null);
          break;
      }
    }
  }, [viewPoligons && isLoaded, polygonsType]);

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const onPolygonComplete = (polygon) => {
    polygonRef.current = polygon;
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    setPath(newCoordinates);
    polygon.setDraggable(true);
    polygon.setEditable(true);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("dragend", onEdit);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("onLoad", onLoad);
    polygon.addListener("onUnmount", onUnmount);
    // setSelectedPolygon is not defined in your provided code
    setSelectedPolygon({
      polygon: polygon,
      coordinates: newCoordinates,
    });
  };

  const onSelectPolygon = (polygon) => {
    polygonRef.current = polygon;
    const coordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));

    setSelectedPolygon({
      polygon: polygonRef.current,
      coordinates: coordinates,
    });
  };

  async function findPoints() {
    console.log(path);
    const polygon = new google.maps.Polygon({
      paths: path,
    });

    if (memoizedData.dataMerge) {
      const markersInPolygon = memoizedData.dataMerge.filter((marker) => {
        // console.log(marker);
        try {
          const markerPosition = new window.google.maps.LatLng(
            marker.toAddressLocation.latitude,
            marker.toAddressLocation.longitude
          );

          const isInside = google.maps.geometry.poly.containsLocation(
            markerPosition,
            polygon
          );
          return isInside;
        } catch (error) {
          console.log(`${marker.id} no posee geo`);
        }
      });

      if (markersInPolygon) {
        markersInPolygon.forEach((marker) => {
          // Imprimir más información del marcador si es necesario
        });
        setOrdersByPoligons(markersInPolygon);
        const orderIdsWithF = markersInPolygon.map((order) => `F${order.id}`);
        setOrdersForAccions(orderIdsWithF);
      } else {
        console.log("No detecto nuevas marcas");
      }
    } else {
      //      console.log("No detecto nuevas puntos den props");
    }
  }

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    } else {
    }
  }, []);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const [polygonOptions, setPolygonOptions] = useState({
    editable: true,
    draggable: true,
    // onMouseUp and onDragEnd should be function handlers, not objects
    // onLoad and onUnmount are not valid polygon options
    strokeWeight: 1,
    fillOpacity: 0.3,
    fillColor: "#591e8f",
    strokeColor: "#591e8f",
    strokeOpacity: 0.8,
  });

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    let currentCenter = map.getCenter();
    map.addListener("idle", () => {
      let newCenter = map.getCenter();
      // Comprueba si el centro realmente ha cambiado antes de actualizar el estado
      if (
        newCenter.lat() !== currentCenter.lat() ||
        newCenter.lng() !== currentCenter.lng()
      ) {
        setCenter({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
        currentCenter = newCenter; // Actualiza la referencia del centro actual
      }
    });

    // Haz algo similar para el zoom si es necesario
    let currentZoom = map.getZoom();
    map.addListener("zoom_changed", () => {
      let newZoom = map.getZoom();
      if (newZoom !== currentZoom) {
        setZoom(newZoom);
        currentZoom = newZoom; // Actualiza la referencia del zoom actual
      }
    });
  }, []);

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const handleChangeDriver = (e) => {
    console.log(e.target.value);
    setDriver(e.target.value);
    let seleccionRider = memoizedData.dataMerge.filter(
      (item) => item.driverID === e.target.value
    );
    console.log(seleccionRider);
    setDriverFilter(seleccionRider);
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  const handleChangeService = (e) => {
    setServiceVertical(e.target.value);
  };

  const handleTypePoligons = (e) => {
    console.log(e.target.value);
    setPolygonsType(e.target.value);
    // donwLoadPolygons();
    // loadStaticPolygons();
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  return memoizedData ? (
    <div>
      <ToolFiltersComponent
        selectedCompanies={selectedCompanies}
        selectedOrigins={selectedOrigins}
        filteredCompanies={filteredCompanies}
        filteredOrigins={filteredOrigins}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
      ></ToolFiltersComponent>
      <Box>
        {memoizedData.ridersDataByCompany ? (
          <PopUpAsignament
            opemPopUpAsignament={opemPopUpAsignament}
            setOpemPopUpAsignament={setOpemPopUpAsignament}
            ordersForAccions={ordersForAccions}
            ridersDataByCompany={ridersDataByCompany}
          ></PopUpAsignament>
        ) : null}
        <AppBar position="static" sx={{ background: "#fff" }}>
          <Grid item xs={12} md={12} lg={6}>
            {/* Primer elemento al 50% en pantallas grandes, 100% en pequeñas */}
            <Box p={1} sx={{ display: "flex", flexDirection: "row" }}>
              {driverFilter.length ? (
                <Typography type={"span"} sx={{ ml: 2, color: "#030303" }}>
                  Carga de Conductor: {driverFilter.length}{" "}
                </Typography>
              ) : null}
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "#030303", textAlign: "right" }}
              >
                <Typography>
                  Visitas en Vista: {memoizedData?.dataMerge.length}{" "}
                </Typography>
              </Typography>
            </Box>

            <Box p={1}>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "#030303", textAlign: "Left" }}
              >
                MAPA DE DISTRIBUCIÓN:
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              {/* Segundo elemento al 50% en pantallas grandes, 100% en pequeñas */}
              <Box p={1} sx={{ display: "flex" }}>
                <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                  <InputLabel id="select-label" sx={{ color: "#fff" }}>
                    Servicio
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    id="demo-simple-select"
                    value={vertical}
                    label="Servicio"
                    onChange={handleChangeService}
                    sx={{ backgroundColor: "#f8f8f8" }}
                  >
                    <MenuItem key={"all"} value={"all"}>
                      Todos los servicios
                    </MenuItem>
                    <MenuItem key={"sd"} value={"SD"}>
                      SAME DAY
                    </MenuItem>
                    <MenuItem key={"hd"} value={"HD"}>
                      HOME DELIVERY
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                  <InputLabel id="select-label">Poligonos</InputLabel>
                  <Select
                    labelId="select-label"
                    id="demo-simple-select"
                    label="Tipo de poligonos"
                    onChange={handleTypePoligons}
                    sx={{ backgroundColor: "#f8f8f8" }}
                    defaultChecked="redpoligonos"
                    value={polygonsType}
                  >
                    <MenuItem value={"redpoligonos"}>Poligonos Bicci</MenuItem>
                    <MenuItem value={"comunaspoligonos"}>
                      Poligonos por Comunas
                    </MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl sx={{ minWidth: "30%", width: "30%", m: 1 }}>
                  <InputLabel id="select-label">Conductor</InputLabel>
                  <Select
                    labelId="select-label"
                    id="demo-simple-select"
                    value={driver}
                    label="Conductor"
                    onChange={handleChangeDriver}
                    sx={{ backgroundColor: "#f8f8f8" }}
                  >
                    {memoizedData.metricsOps.map((item, index) => (
                      <MenuItem key={index} value={item.driverID}>
                        {item.driverName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography type={"span"}>
                  <Button onClick={() => setDriverFilter([])}>
                    Limpiar filtros
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid p={1} container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              {/* Segundo elemento al 50% en pantallas grandes, 100% en pequeñas */}
              <Box p={1} sx={{ display: "flex" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewDrivers}
                        onChange={() => setViewDrivers(!viewDrivers)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f", // Color del switch cuando está seleccionado
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f", // Color del fondo del switch cuando está seleccionado
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: viewDrivers ? "#591e8f" : "#000", // Cambia a morado cuando se selecciona
                          textTransform: viewDrivers ? "uppercase" : "none", // Mayúsculas cuando está seleccionado
                        }}
                      >
                        {viewDrivers ? "Riders" : "Riders"}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewPendings}
                        onChange={() => setViewPendings(!viewPendings)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f", // Color del switch cuando está seleccionado
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f", // Color del fondo del switch cuando está seleccionado
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: viewPendings ? "#591e8f" : "#000", // Cambia a morado cuando se selecciona
                          textTransform: viewPendings ? "uppercase" : "none", // Mayúsculas cuando está seleccionado
                        }}
                      >
                        {viewPendings ? "Pendientes" : "Pendientes"}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewDeliverys}
                        onChange={() => setViewDeliverys(!viewDeliverys)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f", // Color del switch cuando está seleccionado
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f", // Color del fondo del switch cuando está seleccionado
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: viewDeliverys ? "#591e8f" : "#000", // Cambia a morado cuando se selecciona
                          textTransform: viewDeliverys ? "uppercase" : "none", // Mayúsculas cuando está seleccionado
                        }}
                      >
                        {viewDeliverys ? "Entregados" : "Entregados"}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewExepcions}
                        onChange={() => setViewViewExepcions(!viewExepcions)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f", // Color del switch cuando está seleccionado
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f", // Color del fondo del switch cuando está seleccionado
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: viewExepcions ? "#591e8f" : "#000", // Cambia a morado cuando se selecciona
                          textTransform: viewExepcions ? "uppercase" : "none", // Mayúsculas cuando está seleccionado
                        }}
                      >
                        {viewExepcions ? "Excepciones" : "Excepciones"}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewPoligons}
                        onChange={() => setViewPoligons(!viewPoligons)}
                        aria-label="login switch"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#591e8f", // Color del switch cuando está seleccionado
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#591e8f", // Color del fondo del switch cuando está seleccionado
                            },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: viewPoligons ? "#591e8f" : "#000", // Cambia a morado cuando se selecciona
                          textTransform: viewPoligons ? "uppercase" : "none", // Mayúsculas cuando está seleccionado
                        }}
                      >
                        {viewPoligons ? "Poligonos" : "Poligonos"}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}></Grid>
          </Grid>

          {/* <Toolbar sx={{ background: "#591e8f" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewPoligons}
                    onChange={() => setViewPoligons(!viewPoligons)}
                    aria-label="login switch"
                  />
                }
                label={viewPoligons ? "Poligonos" : "Poligonos"}
              />
            </FormGroup> 
          </Toolbar> */}
        </AppBar>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "450px",
          backgroundColor: "#F8F8F8",
          padding: "0px",
        }}
      >
        {selectedMarkerDriver ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
              borderBottomRightRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#f8f8f8", width: 70, height: 70 }}
              src={
                selectedMarkerDriver.avatar ? selectedMarkerDriver.avatar : ""
              }
              alt=""
            ></Avatar>
            <Typography>{selectedMarkerDriver.name}</Typography>
            <Typography>{selectedMarkerDriver.id}</Typography>
            <Typography>{selectedMarkerDriver.email}</Typography>
            <Typography>{selectedMarkerDriver.dni}</Typography>
            <Typography>{selectedMarkerDriver.bicciNumber}</Typography>
            <Typography>{selectedMarkerDriver.phone}</Typography>
            <Typography>Carga : {driverFilter.length} </Typography>
            <Button onClick={() => setSelectedMarkerDriver(null)}>
              Cerrar
            </Button>
          </Box>
        ) : null}

        {ordersByPoligons && ordersByPoligons.length > 0 ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              maxHeight: "80%",
              overflowY: "scroll",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              {polygonSelectedName}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              Selección en polígono {ordersByPoligons.length} Folios
            </Typography>
            {ordersByPoligons.map((item) => (
              <Stack key={item.id} sx={{ with: "100%" }}>
                <Chip
                  size="small"
                  label={<Typography>ID: {item.id}</Typography>}
                  sx={{ mb: 1 }}
                >
                  {" "}
                </Chip>
              </Stack>
            ))}

            <Button onClick={() => setOrdersByPoligons([])}>Cerrar</Button>
            <Button onClick={() => setOpemPopUpAsignament(true)}>
              Asignar Bicci Riders
            </Button>
            <Button onClick={() => console.log("asignar")}>
              Asignar Bicci Express
            </Button>
          </Box>
        ) : null}

        {memoizedData && driverFilter.length === 0 ? (
          <GoogleMap
            id="mapControl3"
            // mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            onLoad={onMapLoad}
          >
            {/* {viewTools ? (
              <DrawingManager
                onPolygonComplete={onPolygonComplete}
                defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                      // google.maps.drawing.OverlayType.CIRCLE,
                      // google.maps.drawing.OverlayType.RECTANGLE,
                      google.maps.drawing.OverlayType.POLYGON,
                    ],
                  },
                  polygonOptions: polygonOptions,
                }}
              ></DrawingManager>
            ) : null} */}

            {viewPoligons &&
              polygons?.map((polygon) => (
                <Polygon
                  key={polygon.id}
                  paths={polygon.coordinates}
                  //options={polygon.options}
                />
              ))}

            {viewDrivers &&
              memoizedData?.ridersDataByCompany?.map((rider, index) => {
                if (rider.driverLocation && rider.online === true) {
                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: rider.driverLocation.latitude,
                        lng: rider.driverLocation.longitude,
                      }}
                      onClick={() => {
                        setSelectedMarkerDriver(rider);
                      }}
                      onLoad={(markerInstance) => {
                        const customIcon = {
                          url: "/static/images/conductor.png", // URL de la imagen que quieres usar
                          scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                          anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                        };
                        markerInstance.setIcon(customIcon);
                      }}
                      // Aquí puedes agregar más propiedades al marcador si es necesario
                    />
                  );
                } else {
                  return null; // Si no hay datos de localización, retorna null
                }
              })}
            {viewDeliverys &&
              memoizedData?.dataMerge?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.toAddressLocation && order.status === 8) {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casita.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}
            {viewPendings &&
              memoizedData?.dataMerge?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.status >= 0 || order.status < 8) {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casitaAzul.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}

            {viewExepcions &&
              memoizedData?.dataMerge?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.intStatus) {
                    const currentDate = new Date();
                    const createDate = order.createdAt
                      ? new Date(order.createdAt.seconds * 1000)
                      : currentDate;
                    const updateDate = order.updatedAt
                      ? new Date(order.updatedAt.seconds * 1000)
                      : currentDate;
                    const delayDays = differenceInDays(updateDate, createDate);

                    const isDelayed = delayDays > 0;
                    return isDelayed ? (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        label={{
                          text: `Retraso ${delayDays} días`,
                          color: "#F8F8F8",
                          className: "my-custom-label-ping",
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    ) : (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}

            {selectedMarker ? (
              <>
                {
                  <InfoWindow
                    position={{
                      lat: selectedMarker.toAddressLocation._lat,
                      lng: selectedMarker.toAddressLocation._long,
                    }}
                    onCloseClick={() => {
                      setSelectedMarker("");
                    }}
                  >
                    <div>
                      <span
                        onClick={() => setSelectedMarker(null)}
                        className="posditonCloseInfo"
                      >
                        x
                      </span>
                      <a
                        className="btn-bicci-prupura-color"
                        href={`https://bicciexpress.cl/dashboard/dispatch/details/F${selectedMarker.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>ID: {selectedMarker.id}</h5>
                      </a>

                      <div>
                        <a
                          className="nonstyle"
                          href={`tel:9${selectedMarker.customer_phone}`}
                        >
                          Teléfono: {selectedMarker.customer_phone}
                        </a>
                      </div>
                      <div>
                        <a
                          className="nonstyle"
                          href={`https://api.whatsapp.com/send?phone=${selectedMarker.customer_phone}&text=Soporte%20BICCI%20:`}
                        >
                          WhatsApp: {selectedMarker.customer_phone}
                        </a>
                      </div>
                      <div>Nombre : {selectedMarker.customer_name}</div>
                      <div>Teléfono : {selectedMarker.customer_phone}</div>
                      <div>Destino : {selectedMarker.address}</div>
                      <div>Conductor : {selectedMarker.driverName}</div>
                      <div>
                        Observación :{" "}
                        {selectedMarker.observationAdmin?.[0]
                          ? selectedMarker.observationAdmin[0]
                          : "Sin comentario"}
                      </div>
                    </div>
                  </InfoWindow>
                }
              </>
            ) : null}
          </GoogleMap>
        ) : (
          <GoogleMap
            id="mapControl3"
            // mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            onLoad={onMapLoad}
          >
            {viewDrivers &&
              memoizedData?.ridersDataByCompany?.map((rider, index) => {
                if (rider.id === driver) {
                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: rider.driverLocation.latitude,
                        lng: rider.driverLocation.longitude,
                      }}
                      onClick={() => {
                        setSelectedMarkerDriver(rider);
                      }}
                      onLoad={(markerInstance) => {
                        const customIcon = {
                          url: "/static/images/conductor.png", // URL de la imagen que quieres usar
                          scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                          anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                        };
                        markerInstance.setIcon(customIcon);
                      }}
                      // Aquí puedes agregar más propiedades al marcador si es necesario
                    />
                  );
                } else {
                  return null; // Si no hay datos de localización, retorna null
                }
              })}
            {viewDeliverys &&
              driverFilter?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.toAddressLocation && order.status === 8) {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casita.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}
            {viewPendings &&
              driverFilter?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.status >= 0 || order.status < 8) {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casitaAzul.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}

            {viewExepcions &&
              driverFilter?.map((order, index) => {
                if (order.toAddressLocation) {
                  if (order.intStatus) {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: order.toAddressLocation._lat,
                          lng: order.toAddressLocation._long,
                        }}
                        onClick={() => {
                          setSelectedMarker(order);
                        }}
                        onLoad={(markerInstance) => {
                          const customIcon = {
                            url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                            scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                            anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                          };
                          markerInstance.setIcon(customIcon);
                        }}
                      />
                    );
                  } else {
                    return null; // Si no hay datos de localización, retorna null
                  }
                }
              })}

            {selectedMarker ? (
              <>
                {
                  <InfoWindow
                    position={{
                      lat: selectedMarker.toAddressLocation._lat,
                      lng: selectedMarker.toAddressLocation._long,
                    }}
                    onCloseClick={() => {
                      setSelectedMarker("");
                    }}
                  >
                    <div>
                      <span
                        onClick={() => setSelectedMarker(null)}
                        className="posditonCloseInfo"
                      >
                        x
                      </span>
                      <a
                        className="btn-bicci-prupura-color"
                        href={`https://bicciexpress.cl/dashboard/dispatch/details/F${selectedMarker.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>ID: {selectedMarker.id}</h5>
                      </a>

                      <div>
                        <a
                          className="nonstyle"
                          href={`tel:9${selectedMarker.customer_phone}`}
                        >
                          Teléfono: {selectedMarker.customer_phone}
                        </a>
                      </div>
                      <div>
                        <a
                          className="nonstyle"
                          href={`https://api.whatsapp.com/send?phone=${selectedMarker.customer_phone}&text=Soporte%20BICCI%20:`}
                        >
                          WhatsApp: {selectedMarker.customer_phone}
                        </a>
                      </div>
                      <div>Nombre : {selectedMarker.customer_name}</div>
                      <div>Teléfono : {selectedMarker.customer_phone}</div>
                      <div>Destino : {selectedMarker.address}</div>
                      <div>Conductor : {selectedMarker.driverName}</div>
                      <div>
                        Observación :{" "}
                        {selectedMarker.observationAdmin?.[0]
                          ? selectedMarker.observationAdmin[0]
                          : "Sin comentario"}
                      </div>
                    </div>
                  </InfoWindow>
                }
              </>
            ) : null}
          </GoogleMap>
        )}
      </Box>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default DistributionsGoogleMapsCompany;
