import React, { useEffect, useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase.js";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BasicListElements from "../../../../components/BasicListElements/BasicListElements";
import { Box, Typography } from "@mui/material";
import {
  fetcherRoles,
  fetcherServices,
} from "../../../../utils/FectcherData/FectcherUser";
import { DataGrid } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableForm from "../../../../components/ReusableForm/ReusableForm";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import generateReportByData from "../../../../utils/Reports/generateReportByData.jsx";
import DocumentUpDate from "../../../../utils/DocumentUpDate/DocumentUpDate.jsx";
import ReusableFormImport from "../../../../components/ReusableFormImport/ReusableFormImport.jsx";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

const ServicesActivations = () => {
  const [bodyItems, setBodyItems] = useState([]);
  const [render, setRender] = useState(false);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [statusActive, setStatusActive] = useState(false);

  // estados formualrios

  const classes = useStyles();
  useEffect(() => {
    getData();
  }, [render]);

  const getData = async () => {
    const items = await fetcherServices();
    // const sortedItems = items.sort((a, b) => {
    //   return new Date(b.createdAt) - new Date(a.createdAt);
    // });
    console.log(items);
    setBodyItems(items);
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = async (row) => {
    console.log("Ver", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleEdit = (row) => {
    console.log("Editar", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleActivate = async (row) => {
    row.state = true;
    setStatusActive(true);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_SERVICIOS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { state: true });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeactivate = async (row) => {
    row.state = false;
    setStatusActive(false);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_SERVICIOS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { state: false });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        {/* Botón Ver */}
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        {/* Botón Editar */}
        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {/* Botón Activar/Desactivar */}
        {params.row.state ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "name",
        headerName: "Nombre",
        width: 200,
        editable: true,
      },
      {
        field: "path",
        headerName: "path",
        width: 300,
      },
      {
        field: "state",
        headerName: "state",
        width: 100,
      },

      {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
      {
        field: "description",
        headerName: "description",
        width: 500,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < bodyItems.length; i++) {
      rows.push({
        id: bodyItems[i].id,
        name: bodyItems[i].name,
        path: bodyItems[i].path,
        state: bodyItems[i].state,
        description: bodyItems[i].description,
      });
    }
    setRows(rows);
  };

  // Formolario Nuevo Item
  const formFields = [
    { variable: "id", label: "id", type: "string" },
    { variable: "name", label: "name", type: "string" },
    { variable: "servicio_id", label: "servicio_id", type: "string" },
    { variable: "path", label: "path", type: "string" },
    { variable: "state", label: "state", type: "boolean" },
    { variable: "description", label: "description", type: "string" },
  ];

  const handleAddItem = (formData) => {
    const itemIndex = rows.findIndex((item) => item.id === formData.id);

    if (itemIndex > -1) {
      const updatedRows = rows.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            name: formData.name,
            servicio_id: formData.servicio_id,
            path: formData.path,
            state: formData.state,
          };
        }
        return item;
      });
      setRows(updatedRows);
    } else {
      const newItem = {
        id: formData.id || rows.length + 1,
        name: formData.name,
        servicio_id: formData.servicio_id,
        path: formData.path,
        state: formData.state,
      };
      setRows([...rows, newItem]);
    }
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    // Función para crear o actualizar un único documento
    const upsertDocument = async (docData) => {
      let id = docData.id || generarCadenaAleatoria(10);
      if (!docData.id || docData.id === "") {
        docData.id = id;
        docData.servicio_id = id; // Asumiendo que quieres asignar el mismo ID generado a servicio_id
      }

      const collRef = collection(db, process.env.REACT_APP_COL_SERVICIOS);
      const docRef = doc(collRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, docData);
        console.log("Documento actualizado con éxito.");
      } else {
        await setDoc(docRef, docData);
        console.log("Documento creado con éxito.");
      }

      handleAddItem(docData); // Asumiendo que esto actualiza la UI adecuadamente
    };

    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
    setOpenEdit(false);
  };

  // Formolario edit

  // Formolario ver edit
  const formFieldsEdit = [
    { variable: "id", label: "id", type: "string" },
    { variable: "servicio_id", label: "servicio_id", type: "string" },
    { variable: "name", label: "name", type: "string" },
    { variable: "path", label: "path", type: "string" },
    { variable: "state", label: "state", type: "boolean" },
    { variable: "description", label: "description", type: "string" },
  ];

  const handleViewItem = (formData) => {
    const newItem = {
      id: rows.length + 1,
      userName: formData.userName,
      userLastName: formData.userLastName,
    };
    setRows([...rows, newItem]);
  };

  // Formolario ver edit

  //
  const createReportByData = () => {
    console.log("Crear reporte");
    generateReportByData(bodyItems);
  };

  const UploadDocumentsByData = () => {
    console.log("Importador de documentos");
    setOpenImport(true);
  };

  //

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestionar ServicesActivations
      </Typography>
      {/* // Formolario Nuevo Item */}
      <Button
        variant="outline"
        startIcon={<AddCircleIcon />}
        onClick={() => setOpen(true)}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Agregar ítem
      </Button>
      <Button
        variant="outline"
        startIcon={<DownloadIcon />}
        onClick={() => createReportByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Exportar
      </Button>
      <Button
        variant="outline"
        startIcon={<FileUploadIcon />}
        onClick={() => UploadDocumentsByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Actualizar reglas
      </Button>
      <ReusableForm
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        handleSave={handleSave}
      />
      {/* // Formolario Nuevo Item */}
      {/* // Formolario view Item */}
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      {/* // Formolario View Item */}
      {/* // Formolario view Item */}
      <ReusableFormImport
        open={openImport}
        handleClose={() => setOpenImport(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        type={process.env.REACT_APP_COL_SERVICIOS}
        formFieldsEdit={formFieldsEdit}
      />
      {/* // Formolario View Item */}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        className={classes.root}
        groupingModel={["roll"]}
      ></DataGrid>
    </div>
  );
};

export default ServicesActivations;
