import DownloadIcon from "@mui/icons-material/Download";

import React, { useEffect, useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableVewData from "../../../../components/ReusableVewData/ReusableVewData";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";
import { db } from "../../../../firebase";
import generateReportByData from "../../../../utils/Reports/generateReportByData";
import {
  fetcherCompanies,
  fetcherRoles,
  fetcherUsers,
} from "../../../../utils/FectcherData/FectcherUser";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

const Administrators = () => {
  const [bodyItems, setBodyItems] = useState([]);
  const [rolesOptones, setRolesOptones] = useState([]);
  const [companiesOpc, setCompaniesOpc] = useState([]);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const users = await fetcherUsers();
      const filteredUsers = users.filter(
        (user) => user.roll === "company_admin"
      );
      setBodyItems(filteredUsers);

      const roles = await fetcherRoles();
      const options = roles.map((role) => ({ value: role.id, label: role.id }));
      console.log(options);
      setRolesOptones(options);

      const companies = await fetcherCompanies();
      console.log("Fetched companies:", companies);
      const falabellaOption = {
        value: "falabella",
        label: "falabella",
      };
      const BicciAdmin = {
        value: "BicciAdmin",
        label: "BicciAdmin",
      };
      if (companies && companies.length > 0) {
        const companiesOptions = companies.map((company) => ({
          value: company.id,
          label: company.companyName,
        }));

        if (
          !companiesOptions.some(
            (option) => option.value === falabellaOption.value
          )
        ) {
          companiesOptions.push(falabellaOption, BicciAdmin);
        }

        console.log(companiesOptions);

        setCompaniesOpc(companiesOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = (row) => {
    setInitialValues(row);
    setOpen(true);
  };

  const handleEdit = (row) => {
    // Asegúrate de que `initialValues` tenga solo los valores existentes
    const cleanedRow = {
      id: row.id || undefined,
      userName: row.userName || "",
      userLastName: row.userLastName || "",
      email: row.email || "",
      roll: row.roll || "",
      userType: row.userType || "",
      userRut: row.userRut || "",
      companyCollaborator: row.companyCollaborator || [],
      acepTerminos: row.acepTerminos || false,
      avatar: row.avatar || "",
      emailVerified: row.emailVerified || false,
      propietari: row.propietari || "",
      userActive: row.userActive || false,
      userCompany: row.userCompany || "",
      userCompanyID: row.userCompanyID || "",
      userCompanyRoll: row.userCompanyRoll || "",
      userPhone: row.userPhone || "",
      userPreferenCont: row.userPreferenCont || "",
    };

    setInitialValues(cleanedRow);
    setOpenEdit(true);
  };

  const handleActivate = async (row) => {
    row.userActive = true;
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: true });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeactivate = async (row) => {
    row.userActive = false;
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: false });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {params.row.userActive ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "userName",
        headerName: "Nombre",
        width: 150,
        editable: true,
      },
      {
        field: "userLastName",
        headerName: "Apellido",
        width: 100,
      },
      {
        field: "userPhone",
        headerName: "Teléfono",
        width: 120,
        editable: true,
      },
      {
        field: "email",
        headerName: "email",
        width: 200,
        editable: true,
      },
      {
        field: "roll",
        headerName: "roll",
        width: 150,
        editable: true,
      },
      {
        field: "userType",
        headerName: "userType",
        width: 100,
        editable: true,
      },
      {
        field: "userRut",
        headerName: "userRut",
        width: 100,
        editable: true,
      },
      {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
    ];

    setColum(columnsTable);

    const rowsData = bodyItems.map((item) => ({
      id: item.id,
      userName: item.userName,
      userLastName: item.userLastName,
      email: item.email,
      userPhone: item.userPhone,
      roll: item.roll,
      userType: item.userType,
      userRut: item.userRut,
      companyCollaborator: item.companyCollaborator,
      userActive: item.userActive,
    }));

    setRows(rowsData);
  };

  // Formulario Nuevo Item (para visualización)
  const formFields = [
    { variable: "id", label: "ID", type: "string" },
    { variable: "userName", label: "Nombre de Usuario", type: "string" },
    { variable: "userLastName", label: "Apellido de Usuario", type: "string" },
    { variable: "email", label: "Correo Electrónico", type: "string" },
    { variable: "roll", label: "Rol", type: "string" },
    { variable: "userType", label: "Tipo de Usuario", type: "string" },
    { variable: "userRut", label: "RUT del Usuario", type: "string" },
    {
      variable: "companyCollaborator",
      label: "Empresa Colaboradora",
      type: "string",
    },
    {
      variable: "acepTerminos",
      label: "Aceptación de Términos",
      type: "boolean",
    },
    { variable: "avatar", label: "Avatar", type: "string" },
    {
      variable: "createAdd",
      label: "Fecha de Creación (Timestamp)",
      type: "Timestamp",
    },
    { variable: "createDate", label: "Fecha de Creación", type: "string" },
    { variable: "emailVerified", label: "Correo Verificado", type: "boolean" },
    { variable: "propietari", label: "Propietario", type: "string" },
    { variable: "userActive", label: "Usuario Activo", type: "boolean" },
    { variable: "userCompany", label: "Compañía del Usuario", type: "string" },
    { variable: "userCompanyID", label: "ID de la Compañía", type: "string" },
    {
      variable: "userCompanyRoll",
      label: "Rol en la Compañía",
      type: "string",
    },
    { variable: "userPhone", label: "Teléfono del Usuario", type: "string" },
    {
      variable: "userPreferenCont",
      label: "Preferencia de Contacto",
      type: "string",
    },
  ];

  // Formulario Edición
  const formFieldsEdit = [
    { variable: "id", label: "ID", type: "string", disabled: true },
    { variable: "userName", label: "Nombre de Usuario", type: "string" },
    { variable: "userLastName", label: "Apellido de Usuario", type: "string" },
    { variable: "email", label: "Correo Electrónico", type: "string" },
    {
      variable: "roll",
      label: "Rol",
      type: "select",
      options: rolesOptones,
    },
    { variable: "userType", label: "Tipo de Usuario", type: "string" },
    { variable: "userRut", label: "RUT del Usuario", type: "string" },
    {
      variable: "companyCollaborator",
      label: "Empresa Colaboradora",
      type: "multi-select",
      options: companiesOpc,
    },
    {
      variable: "acepTerminos",
      label: "Aceptación de Términos",
      type: "boolean",
    },
    { variable: "avatar", label: "Avatar", type: "string" },
    { variable: "emailVerified", label: "Correo Verificado", type: "boolean" },
    { variable: "propietari", label: "Propietario", type: "string" },
    { variable: "userActive", label: "Usuario Activo", type: "boolean" },
    { variable: "userCompany", label: "Compañía del Usuario", type: "string" },
    { variable: "userCompanyID", label: "ID de la Compañía", type: "string" },
    {
      variable: "userCompanyRoll",
      label: "Rol en la Compañía",
      type: "string",
    },
    { variable: "userPhone", label: "Teléfono del Usuario", type: "string" },
    {
      variable: "userPreferenCont",
      label: "Preferencia de Contacto",
      type: "string",
    },
  ];

  const handleAddItem = (formData) => {
    const itemIndex = rows.findIndex((item) => item.id === formData.id);

    if (itemIndex > -1) {
      const updatedRows = rows.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            ...formData, // Asegúrate de que solo los datos relevantes se actualicen
          };
        }
        return item;
      });
      setRows(updatedRows);
    } else {
      const newItem = {
        ...formData,
        id: formData.id || rows.length + 1,
      };
      setRows([...rows, newItem]);
    }
  };

  const handleSave = async (formData) => {
    console.log(formData);

    // Función para generar una cadena aleatoria
    const generarCadenaAleatoria = (longitud) => {
      const caracteresValidos =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    // Función para crear o actualizar un documento
    const upsertDocument = async (docData) => {
      let id = docData.id || generarCadenaAleatoria(10); // Generar ID solo si no existe

      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, id);

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, docData); // Actualizar si el documento existe
          console.log("Documento actualizado con éxito.");
        } else {
          // await setDoc(docRef, { ...docData, id }); // Crear si el documento no existe
          console.log("Documento creado con éxito.");
        }

        handleAddItem({ ...docData, id }); // Actualizar la UI con el ID usado
      } catch (error) {
        console.error("Error al guardar el documento:", error);
      }
    };

    await upsertDocument(formData);

    setOpen(false);
    setOpenEdit(false);
  };

  const createReportByData = () => {
    console.log("Crear reporte");
    generateReportByData(bodyItems);
  };

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestionar Aministradores
      </Typography>
      <Button
        variant="outline"
        startIcon={<DownloadIcon />}
        onClick={() => createReportByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }}
      >
        Exportar
      </Button>
      <ReusableVewData
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        initialValues={initialValues}
      />
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        className={classes.root}
        groupingModel={["roll"]}
      />
    </div>
  );
};

export default Administrators;
