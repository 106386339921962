function useGlobalConfig() {
  const siteName = "BICCI EXPRESS";
  const phoneSuport = "+56 9 7128 5894";
  const telSuport = "tel:+56971285894";
  const imgPlaceOlderCart =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-03.png?alt=media&token=bdff97b1-1471-4c59-a142-327e59c0b9ff";
  const logoOficialUrl1x4 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00";
  const logoOficialUrl =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Flogo-bicci-express%20(2).png?alt=media&token=40035173-bec8-4a94-a5d0-2b41fdf9cb6b";
  const homeImgUrl =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecobicci-1.png?alt=media&token=25fc24ac-26e1-43a0-8bc0-889c4afec5cf";
  const ecortribicci =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecotribici1500.png?alt=media&token=6ceaa91d-e6fa-41b9-9209-04c5c99fa709";
  const ecortribicciR =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecotribicci1.png?alt=media&token=9bc1bed9-936a-450b-8fb5-ea479c4c01b1";
  const isoTipoUrl =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FbicciFavicon.png?alt=media&token=83d2d86a-682a-42c1-8e7a-07f61674c18a";
  const img1 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-08%20copia.png?alt=media&token=2611dda3-a21f-46c5-928d-b59652b78945";
  const img2 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-02.png?alt=media&token=c053d03f-b255-4472-b829-6c23f724ee17";
  const img3 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FBox750x750.png?alt=media&token=bc5dabbc-4e80-4ec0-9e15-35b9f3c3d088";
  const img4 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-07%20copia.png?alt=media&token=55336a5b-b9ca-4a51-9a17-4eee41e429f8";
  const img5 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(24).svg?alt=media&token=ef6abc30-ba25-4422-bd45-5821ae5dc2cf";
  const img6 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(13).svg?alt=media&token=8d73baab-2c4e-47b9-a908-29bd897b3f2e";
  const img7 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(2).svg?alt=media&token=74a1eda1-27a8-418b-a6d4-67f714a3305f";
  const img8 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(20).svg?alt=media&token=e9872902-6d14-40be-83b1-8cc83d25a66f";
  const img9 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(19).svg?alt=media&token=f2a78b4f-ad4f-4902-b4aa-5eedd1d877a7";
  const img10 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(4).svg?alt=media&token=74ec2648-1dc7-4c66-8c61-9ab5d31d0ddb";
  const img11 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconosVarios-01.svg?alt=media&token=a9ff7f81-9c19-4a9f-8c4a-78c3e20fbb14";
  const img12 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconosVarios-02.svg?alt=media&token=7e1151d9-d0e3-4e85-8ac8-913ce53b2c7e";
  const img13 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconosVarios-03.svg?alt=media&token=ef77e7ec-1bb1-4e49-bfae-49f4817a50ef";
  const img14 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos%20%20Varios-04.png?alt=media&token=8bd1242e-d353-4e25-91e9-69a3fb63a5c7";
  const img15 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FDelivery%201-01.png?alt=media&token=b18c89c0-ead2-4d76-98dc-e7edca1ddc0c";
  const img16 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-03.png?alt=media&token=bdff97b1-1471-4c59-a142-327e59c0b9ff";
  const img17 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fphone%20copia.png?alt=media&token=becb2922-8bf5-4c1c-975a-85b52479ab43";
  const img18 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fmaps-01%20copia.png?alt=media&token=a10a8d7e-dc6b-44de-bfe9-cb573521c943";
  const img19 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-04.png?alt=media&token=6f7fce06-3719-45f8-8e8a-7ed36dfab73d";
  const imgbannerfooter =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fphoto2%20copia.jpg?alt=media&token=f3107f5b-4454-41a5-a338-caa7de73e7c3";
  const boxbicciexpres =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FBox2%20copia.png?alt=media&token=fcc95f2a-8f6e-4be0-84bc-4d4f9e155999";
  const purchaseState = {
    0: "Creado",
    1: "Pendiente de pago",
    2: "En espera",
    3: "Completado",
    4: "Procesando",
    5: "Finalizado",
    6: "Cancelado",
    7: "Reembolsado",
    8: "Fallido",
  };
  const carrusell01 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F001Bannerhome.jpg?alt=media&token=f25440e1-4912-4f84-9a4d-53cad593054d";
  const carrusell02 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F002Bannerhome.jpg?alt=media&token=2b13c8ac-0e12-4ee8-b94e-e77b171d0024";
  const carrusell03 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F003Bannerhome.jpg?alt=media&token=fc15337f-46a7-4fe4-8f8a-ab63b28894ac";
  const carrusell04 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F004Bannerhome.jpg?alt=media&token=125fc93f-0d50-4dc0-8f0c-89d84696545b";
  const ecotribicci2 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecotribici1500.png?alt=media&token=6ceaa91d-e6fa-41b9-9209-04c5c99fa709";
  const ecotribicci =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecotribicci2.png?alt=media&token=5be93892-57d4-4291-8d9b-b38a1c0a846b";
  const cobertura =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-06.png?alt=media&token=b88dafc6-a868-4492-91d4-a7edf2fa61c6";

  const btnvehicle =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(11).svg?alt=media&token=d8bbc32d-e730-4d3c-8e62-5c608da7d399";
  const btnuseradmin =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(6).svg?alt=media&token=3c9a70ed-adbf-43f3-98bc-a5cfe37153e8";
  const btnacuerdo =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(7).svg?alt=media&token=43567005-caed-4244-934a-ebb00deb1ebb";

  const biccidashboard =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fbiccidashborad.png?alt=media&token=93b91af8-2ea3-4e47-9973-7b35d3b90923";
  const icomshome1 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fhome-Icons%20(1).svg?alt=media&token=2ca30627-206b-4b40-8906-f9e721e56e5e";
  const icomshome2 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fhome-Icons%20(2).svg?alt=media&token=ee83592f-d243-4a9f-a023-2207dab5b545";
  const icomshome3 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fhome-Icons%20(3).svg?alt=media&token=872408bf-d691-4179-82be-4aa8a228b55f";
  const icomshome4 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fhome-Icons%20(4).svg?alt=media&token=5bf857af-e7a7-41d9-9541-71555042651e";
  const icomshome5 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fhome-Icons%20(5).svg?alt=media&token=0d99a019-b9c4-459d-ab48-d5d3c5ec7865";
  const ecobicci2 =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fecobicci2.png?alt=media&token=c096419b-8835-4d39-b6d9-dcdb676f83c6";
  const ecobicci2ConLogo =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Fecobicci2ConLogo.png?alt=media&token=f2e48fd5-ac27-4726-a931-e096ff448d3a";
  const rosettaVehicles = {
    BICYCLING: "EcoBicci",
    VEHICLE: "EcoBicci",
    TWO_WHEELER: "Motorizado",
    DRIVING: "Vehículo",
    WALKING: "Caminante",
    MULTI_DRIVING: "Vehículo/Moto",
  };
  const YEARS = [2022, 2023, 2024, 2025, 2026, 2027];

  const MONTHS = {
    Enero: 1,
    Febrero: 2,
    Marzo: 3,
    Abril: 4,
    Mayo: 5,
    Junio: 6,
    Julio: 7,
    Agosto: 8,
    Septiembre: 9,
    Octubre: 10,
    Noviembre: 11,
    Diciembre: 12,
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const typesHistorials = {
    totalDelyvery: "Total Entregados",
    totalCreate: "Total Creados",
    delyveryClear: "Entregados sin incidencias",
    delyveryExcepxions: "Entregados con retraso",
    devolutionsExcepxions: "Devoluciones por Excepciones",
    lostByExceptions: "Pedidas por incidencias",
    lostByLackOfEvidence: "Pedidas por no evidencia",
  };

  const ROUTESTATES = {
    0: "CREADA FALTAN DATOS", //SYSTEMA
    100: "EN PROCESO", //OPERADOR
    101: "ASIGNADO CONDUCTOR", //OPERADOR
    102: "ASIGNADO VEHICULO", //OPERADOR
    103: "ASIGNADA FECHA", //OPERADOR
    104: "ASIGNADO PUNTO DE ENTREGA", //OPERADOR
    105: "RUTA OPTIMA CREADA", //OPERADOR
    106: "EN ESPERA", //OPERADOR
    107: "CAMBIO DE FECHA EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    108: "CAMBIO DE VEHICULO EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    109: "CAMBIO DE RUTA EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    110: "CAMBIO DE CONDUCTOR EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    111: "CAMBIO DE DIRECCION EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    112: "PUNTO AGREGADO EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    113: "PUNTO REMOVIDO EN CURSO", //OPERADOR - se evalua el lapso de tiempo de la ruta
    149: "PAUSADA BODEGA", //OPERADOR - se evalua el lapso de tiempo de la ruta
    150: "RUTA OPTIMA MODIFICADA", //OPERADOR - se evalua el lapso de tiempo de la ruta
    200: "FINALIZADA", //Ecuchar tablas de Ordenes u Operador
    201: "FINALIZADA INICIDENCAS", //Ecuchar tablas de Ordenes u Operador
    202: "FINALIZADA PARCIAL", //Ecuchar tablas de Ordenes u Operador
    400: "ANULADA", //OPERADOR
    401: "ELIMINADA", //OPERADOR
  };

  const comunasSantiago = {
    7630000: "Vitacura",
    7850000: "La Reina",
    7550000: "Las Condes",
    7750000: "Ñuñoa",
    7500000: "Providencia",
    8320000: "Santiago",
    9200000: "Cerrillos",
    9080000: "Cerro Navia",
    8540000: "Conchalí",
    8010000: "El Bosque",
    9160000: "Estación Central",
    8580000: "Huechuraba",
    8380000: "Independencia",
    7970000: "La Cisterna",
    8240000: "La Florida",
    8780000: "La Granja",
    8820000: "La Pintana",
    7690000: "Lo Barnechea",
    9120000: "Lo Espejo",
    8980000: "Lo Prado",
    7810000: "Macul",
    9250000: "Maipú",
    8460000: "Pedro Aguirre Cerda",
    7910000: "Peñalolén",
    9020000: "Pudahuel",
    8700000: "Quilicura",
    8500000: "Quinta Normal",
    8420000: "Recoleta",
    8640000: "Renca",
    8940000: "San Joaquín",
    8900000: "San Miguel",
    8860000: "San Ramón",
  };

  const tablaMedidasTarifas = [
    {
      id: "BICCIXS3",
      transporte: "BICCI",
      m3Menor: 0,
      m3Mayor: 0.005,
      medidaTMS: "XS3",
      pesoKglast: 0,
      pesoKg: 0.5,
      tarifa: 2500,
      maxCm: 40,
      description: "Extra small 3 (3XS), Mini",
      orden: 0,
    },
    {
      id: "BICCIXS2",
      transporte: "BICCI",
      m3Menor: 0.006,
      m3Mayor: 0.009,
      medidaTMS: "XS2",
      pesoKglast: 0.51,
      pesoKg: 1,
      tarifa: 2500,
      maxCm: 40,
      description: "Extra small 2 (2XS), Mini",
      orden: 1,
    },
    {
      id: "BICCIXS",
      transporte: "BICCI",
      m3Menor: 0.01,
      m3Mayor: 0.032,
      medidaTMS: "XS",
      pesoKglast: 1.1,
      pesoKg: 3,
      tarifa: 2500,
      maxCm: 40,
      description: "Extra small (XS), Mini",
      orden: 2,
    },
    {
      id: "BICCIS",
      transporte: "BICCI",
      m3Menor: 0.033,
      m3Mayor: 0.18,
      medidaTMS: "S",
      pesoKglast: 3.1,
      pesoKg: 10,
      tarifa: 2500,
      maxCm: 40,
      description: "Small (S), Medium",
      orden: 3,
    },
    {
      id: "BICCIM",
      transporte: "BICCI",
      m3Menor: 0.181,
      m3Mayor: 0.33,
      medidaTMS: "M",
      pesoKglast: 10.1,
      pesoKg: 20,
      tarifa: 6848,
      maxCm: 100,
      description: "Medium (M), Medium",
      orden: 4,
    },
    {
      id: "BICCILO",
      transporte: "BICCI",
      m3Menor: 1.001,
      m3Mayor: 2.5,
      medidaTMS: "LO",
      pesoKglast: 20.1,
      pesoKg: 20,
      tarifa: 9704,
      maxCm: 650,
      description: "Light Oversized (M), Medium",
      orden: 5,
    },
    {
      id: "BICCIL",
      transporte: "BICCI",
      m3Menor: 0.33,
      m3Mayor: 0.66,
      medidaTMS: "L",
      pesoKglast: 20.1,
      pesoKg: 30,
      tarifa: 9704,
      maxCm: 240,
      description: "Large (L), Big",
      orden: 6,
    },
    // {
    //   id: "BICCIXL",
    //   transporte: "BICCI",
    //   m3Menor: 0.661,
    //   m3Mayor: 1,
    //   medidaTMS: "XL",
    //   pesoKglast: 30.1,
    //   pesoKg: 50,
    //   tarifa: 9704,
    //   maxCm: 240,
    //   description: "Extra Large (XL), Big",
    //   orden: 7,
    // },
    {
      id: "BICCIXXL",
      transporte: "BICCI",
      m3Menor: 0.66,
      m3Mayor: 1,
      medidaTMS: "XXL",
      pesoKglast: 50.1,
      pesoKg: 100,
      tarifa: 11160,
      maxCm: 650,
      description: "Extra Extra Large (XXL), Big",
      orden: 8,
    },
    // {
    //   id: "BICCIXXL",
    //   transporte: "BICCI",
    //   m3Menor: 1.001,
    //   m3Mayor: 2.5,
    //   medidaTMS: "O",
    //   pesoKglast: 100.1,
    //   pesoKg: 300,
    //   tarifa: 12422,
    //   maxCm: 650,
    //   description: "Oversized (O), Super Big",
    //   orden: 9,
    // },
  ];

  const translateStateBicciFalabella = {
    1058: "Creado",
    1059: "Sin Asignar",
    1060: "En Ruta",
    1061: "En Ruta",
    99: "Entregado",
    1: "SIN MORADORES",
    1002: "DOMICILIO NO CORRESPONDE",
    1005: "PROD NO ES LO COMPRADO",
    1006: "PROD INCOMPLETO",
    1007: "PROD FALTANTE",
    1008: "PROD DANADO",
    1009: "FUERA DE HORARIO",
    1017: "NO RETIRA NO CUMPL FORMAT",
    1019: "FUERA DE COBERTURA",
    1022: "CLIENTE REALIZARA N/C",
    1023: "PRODUCTO ROBADO",
    1046: "EXTRAVIADO",
    1027: "NO RETIRADO",
    1031: "PROD N ENTREGADO X TIEMPO",
    1030: "INCIDENCIA EN LA CALLE",
    1032: "FALTA DE INFORMACIÓN",
    1034: "PROD YA FUE RECIBIDO",
    1036: "DIFICULTAD ACCESO DESTINO",
    1041: "CLIENTE RECHAZA ENVIO",
    1018: "NO CONOCEN AL CLIENTE",
    1029: "PROCESO DE LI",
    1081: "RETORNADO A ORIGEN",
    1038: "CLIENTE CAMBIA DIRECCIÓN",
    1046: "EXTRAVIADO EN LA CALLE",
  };

  const styleStateBicciFalabella = {
    1: "alert alert-danger",
    99: "alert  colorGreen fontf8",
    1058: "alert alert-primary",
    1059: "alert alert-warning",
    1060: "alert alert-info",
    1061: "alert alert-info",
    1041: "alert alert-danger",
    1031: "alert alert-danger",
    1002: "alert alert-danger",
    1005: "alert alert-danger",
    1006: "alert alert-danger",
    1007: "alert alert-danger",
    1008: "alert alert-danger",
    1009: "alert alert-danger",
    1017: "alert alert-danger",
    1019: "alert alert-danger",
    1022: "alert alert-danger",
    1023: "alert alert-danger",
    1030: "alert alert-danger",
    1027: "alert alert-danger",
    1032: "alert alert-danger",
    1034: "alert alert-danger",
    1038: "alert alert-danger",
    1036: "alert alert-danger",
    1018: "alert alert-danger",
    1046: "alert alert-danger",
  };

  const falabellaExcepcions = [
    "1",
    "1002",
    "1005",
    "1006",
    "1007",
    "1008",
    "1009",
    "1017",
    "1019",
    "1022",
    "1023",
    "1030",
    "1027",
    "1031",
    "1032",
    "1034",
    "1036",
    "1041",
    "1029",
    "1081",
    "1046",
    "1038",
    "1018", // NO CONOCEN AL CLIENTE
    "1015",
    "Pendiente",
  ];

  const retiredTestsArray = [
    "F1",
    "F6",
    "F7",
    "F3",
    "F4",
    "F5",
    "8018053",
    "F8018053",
    "F140000000000",
    "F3344222112",
    "F3344222111",
    "F149080992329",
    "F149081024412",
    "F149081002630",
    "F149081018629",
    "F149081021803",
    "F149081483305",
    "F149081495055",
    "F149081450743",
    "F149081400650",
    "F149081470972",
    "F149081491112",
    "F149084645306",
    "F987654321",
    "F55023",
    "F149085351283",
    "F149085278280",
    "F149085268877",
    "F149085266113",
    "F149085257906",
    "F149085235383",
    "F149084692962",
    "F149084686522",
    "F149084678911",
    "F149084676063",
    "F149084670681",
    "F149084670538",
    "F149084669755",
    "F149084669363",
    "F149084668266",
    "F149084660901",
    "F149084660003",
    "F149084659316",
    "F149084657501",
    "F149084651598",
    "F149084649294",
    "F149084647254",
    "F149084640864",
    "F149085326725",
    "F149085239284",
    "F149113494564",
    "F149113761442",
    "F149114073318",
    "F700000000001143274",
    "F100000002551433",
    "F149129398702",
    "F8017784",
    "8017784",
    "F8018288",
    "8018288",
    "F8017987",
    "8017987",
    "F8018005",
    "8018005",
    "F8017863",
    "8017863",
    "F8017886",
    "8017886",
    "F8017972",
    "8017972",
    "F8017903",
    "8017903",
    "F8017982",
    "8017982",
    "F8017870",
    "8017870",
    "F8017885",
    "8017885",
    "F8018319",
    "8018319",
    "F8018391",
    "8018391",
    "F8017888",
    "8017888",
    "F8018433",
    "8018433",
    "F8018137",
    "8018137",
    "F8017981",
    "8017981",
    "F8017993",
    "8017993",
    "F8018016",
    "8018016",
    "F8017878",
    "8017878",
    "F8017890",
    "8017890",
    "F8018009",
    "8018009",
    "F8018010",
    "8018010",
    "F8018130",
    "8018130",
    "F8017776",
    "8017776",
    "F8017781",
    "8017781",
    "F8017782",
    "8017782",
    "F8017786",
    "8017786",
    "F8017822",
    "8017822",
    "F8017852",
    "8017852",
    "F8017853",
    "8017853",
    "F8017854",
    "8017854",
    "F8017869",
    "8017869",
    "F8017692",
    "8017692",
    "F8017838",
    "8017838",
    "F8017839",
    "8017839",
    "F8017947",
    "8017947",
    "F8018278",
    "8018278",
    "F8018376",
    "8018376",
    "F8017721",
    "8017721",
    "F8017859",
    "8017859",
    "F8017860",
    "8017860",
    "F8017882",
    "8017882",
    "F8017916",
    "8017916",
    "F8018276",
    "8018276",
    "F8017953",
    "8017953",
    "F8018031",
    "8018031",
    "F8017817",
    "8017817",
    "F8017821",
    "8017821",
    "F8017835",
    "8017835",
    "F8018346",
    "8018346",
    "F8018337",
    "8018337",
    "F8017877",
    "8017877",
    "F8018437",
    "8018437",
    "F8018138",
    "8018138",
    "F8018215",
    "8018215",
    "F8017819",
    "8017819",
    "F8017871",
    "8017871",
    "F8017688",
    "8017688",
    "F8018310",
    "8018310",
    "F8017924",
    "8017924",
    "F8017928",
    "8017928",
    "F8017936",
    "8017936",
    "F8017992",
    "8017992",
    "F8018003",
    "8018003",
    "F8017865",
    "8017865",
    "F8017921",
    "8017921",
    "F8017939",
    "8017939",
    "F8017976",
    "8017976",
    "F8017999",
    "8017999",
    "F8018017",
    "8018017",
    "F8018145",
    "8018145",
    "F8017775",
    "8017775",
    "F8017777",
    "8017777",
    "F8017816",
    "8017816",
    "F8017818",
    "8017818",
    "F8017868",
    "8017868",
    "F8017884",
    "8017884",
    "F8017834",
    "8017834",
    "F8017948",
    "8017948",
    "F8018051",
    "8018051",
    "F8018293",
    "8018293",
    "F8018327",
    "8018327",
    "F8018336",
    "8018336",
    "F8018411",
    "8018411",
    "F8017722",
    "8017722",
    "F8017848",
    "8017848",
    "F8017855",
    "8017855",
    "F8017861",
    "8017861",
    "F8017922",
    "8017922",
    "F8018126",
    "8018126",
    "F8018331",
    "8018331",
    "F8017930",
    "8017930",
    "F8018000",
    "8018000",
    "F8017879",
    "8017879",
    "F8017893",
    "8017893",
    "F8017780",
    "8017780",
    "F8017785",
    "8017785",
    "F8017756",
    "8017756",
    "F8017832",
    "8017832",
    "F8018284",
    "8018284",
    "F8018353",
    "8018353",
    "F8018357",
    "8018357",
    "F8018378",
    "8018378",
    "F8018332",
    "8018332",
    "F8018104",
    "8018104",
    "F8017941",
    "8017941",
    "F8017944",
    "8017944",
    "F8017967",
    "8017967",
    "F8017926",
    "8017926",
    "F8017932",
    "8017932",
    "F8018022",
    "8018022",
    "F8017773",
    "8017773",
    "F8017837",
    "8017837",
    "F8017841",
    "8017841",
    "F8017946",
    "8017946",
    "F8018275",
    "8018275",
    "F8018297",
    "8018297",
    "F8018370",
    "8018370",
    "F8018375",
    "8018375",
    "F8018334",
    "8018334",
    "F8017717",
    "8017717",
    "F8017881",
    "8017881",
    "F8017883",
    "8017883",
    "F8018014",
    "8018014",
    "F8017783",
    "8017783",
    "F8017867",
    "8017867",
    "F8017830",
    "8017830",
    "F8018403",
    "8018403",
    "F8018425",
    "8018425",
    "F8018436",
    "8018436",
    "F8017977",
    "8017977",
    "F8018011",
    "8018011",
    "F8017889",
    "8017889",
    "F8017895",
    "8017895",
    "F8017917",
    "8017917",
    "F8017787",
    "8017787",
    "F8017815",
    "8017815",
    "F8018420",
    "8018420",
    "F8017846",
    "8017846",
    "F8017880",
    "8017880",
    "F8017907",
    "8017907",
    "F8017991",
    "8017991",
    "F8017772",
    "8017772",
    "F8017779",
    "8017779",
    "F8017864",
    "8017864",
    "F8017683",
    "8017683",
    "F8017723",
    "8017723",
    "F8018385",
    "8018385",
    "F8018124",
    "8018124",
    "F8018435",
    "8018435",
    "F8017923",
    "8017923",
    "F8018008",
    "8018008",
    "F8017961",
    "8017961",
    "F8017778",
    "8017778",
    "F8017724",
    "8017724",
    "F8017840",
    "8017840",
    "F8017887",
    "8017887",
    "F8017996",
    "8017996",
    "F8017965",
    "8017965",
    "F8017814",
    "8017814",
    "F8017831",
    "8017831",
    "F8018049",
    "8018049",
    "F8018306",
    "8018306",
    "F8017971",
    "8017971",
    "F8018036",
    "8018036",
    "F8018146",
    "8018146",
    "F8018075",
    "8018075",
    "F8017874",
    "8017874",
    "F100000005087897",
    "F100000005087893",
    "F100000005084237",
    "F100000005081520",
    "F100000005081393",
    "F100000005081392",
    "F100000005079169",
    "F100000005078754",
    "F100000005074729",
    "F100000005059861",
    "F100000005043778",
    "F100000005029124",
    "F100000005087971",
    "F100000005084435",
    "F100000005084432",
    "F100000005084403",
    "F100000005084249",
    "F100000005083181",
    "F100000005083170",
    "F100000005081292",
    "F100000005078986",
    "F100000005076400",
    "F100000005075019",
    "F100000005073258",
    "F100000005073250",
    "F100000005086852",
    "F100000005084365",
    "F100000005084314",
    "F100000005083163",
    "F100000005083142",
    "F100000005083092",
    "F100000005081422",
    "F100000005081337",
    "F100000005079084",
    "F100000005078078",
    "F100000005075906",
    "F100000005075030",
    "F100000005073992",
    "F100000005073333",
    "F100000005073194",
    "F100000005056520",
    "F100000005054061",
    "F100000005088019",
    "F100000005086858",
    "F100000005086747",
    "F100000005083136",
    "F100000005083060",
    "F100000005083055",
    "F100000005082170",
    "F100000005081513",
    "F100000005074692",
    "F100000005086790",
    "F100000005084471",
    "F100000005076399",
    "F100000005074685",
    "F999100000001153461-2",
    "F907490000050312924-2",
    "F907490000050309698-2",
    "F907490000050308477-2",
    "F379536070000031921-1",
    "F379536070000031668-1",
    "F054162400000005193-1",
    "F054162400000005064-1",
    "F054162400000005048-1",
    "F054162400000005018-1",
    "F054162400000004848-1",
    "F014452400000002050-1",
    "F000702400000003186-1",
    "F999100000001155438-2",
    "F999100000001152679-2",
    "F907490000050315001-2",
    "F907490000050314507-2",
    "F907490000050313969-1",
    "F907490000050312910-2",
    "F907490000050309821-2",
    "F907490000050309499-2",
    "F907490000050308628-2",
    "F379536070000031920-1",
    "F379536070000031919-1",
    "F379536070000031669-1",
    "F307605987838257462-1",
    "F109298700005614131-1",
    "F054162400000005149-1",
    "F054162400000005105-1",
    "F036602400000004487-1",
    "F026172400000003458-1",
    "F999100000001153586-2",
    "F999000000030300239-1",
    "F999000000030296268-1",
    "F907490000050314402-2",
    "F907490000050313506-2",
    "F907490000050311121-1",
    "F907490000050310040-2",
    "F907490000050309967-2",
    "F907490000050309536-2",
    "F907490000050309083-2",
    "F907490000050308931-2",
    "F907490000050308714-2",
    "F907490000050308005-2",
    "F379536070000031906-1",
    "F309687669000246460-1",
    "F072002400000003572-1",
    "F036602400000004869-1",
    "F026192400000002308-1",
    "F026172400000003569-1",
    "F999100000001153932-2",
    "F999000000030296249-1",
    "F907490000050314630-2",
    "F907490000050314040-2",
    "F907490000050313823-2",
    "F907490000050312600-2",
    "F907490000050310240-2",
    "F907490000050309372-2",
    "F309213900001271974-1",
    "F307605987838256399-1",
    "F015012400000003389-1",
    "F999100000001153765-2",
    "F907490000050312021-1",
    "F907490000050311795-1",
    "F907490000050311452-2",
    "F907490000050309084-2",
    "F907490000050308266-2",
    "F054162400000004815-1",
    "F900600000000000353",
    "F700000000001521177",
    "F700000000001519395",
    "F700000000001519394",
    "F700000000001519150",
    "F700000000001519067",
    "F140111000001888071",
    "F140111000001887989",
    "F140111000001884400",
    "F140111000001883897",
    "F140111000001883710",
    "F140111000001883709",
    "F140111000001880915",
    "F900600000000000501",
    "F700000000001520152",
    "F700000000001519405",
    "F700000000001519404",
    "F700000000001518643",
    "F149135479922",
    "F149135479012",
    "F149135477605",
    "F149135474859",
    "F149135458791",
    "F149135455444",
    "F149135442463",
    "F149135438837",
    "F149135435427",
    "F140111000001888417",
    "F140111000001888201",
    "F140111000001886434",
    "F140111000001882397",
    "F140111000001881713",
    "F140111000001881454",
    "F149135515591",
    "F149135496135",
    "F149135495050",
    "F149135490780",
    "F149135482309",
    "F149135476234",
    "F149135475416",
    "F149135470522",
    "F149135468777",
    "F149135468714",
    "F149135468231",
    "F149135465250",
    "F149135459137",
    "F149135456926",
    "F149135444300",
    "F149135441226",
    "F149135441055",
    "F149135436283",
    "F149135407412",
    "F149135407342",
    "F149135393498",
    "F149135242489",
    "F149135512865",
    "F149135503942",
    "F149135475415",
    "F149135468783",
    "F149135461121",
    "F149135450333",
    "F149135435138",
    "F149135419919",
    "F149135409247",
    "F149135403472",
    "F149135402165",
    "F149135398359",
    "F149135320040",
    "F149134833599",
    "F146002669153",
    "F140001983919",
    "F149135510336",
    "F149135481084",
    "F149135472633",
    "F149135465903",
    "F149135456925",
    "F149135451866",
    "F149135450546",
    "F149135432088",
    "F149135359969",
    "F149135341263",
    "F149135273946",
    "F146002669205",
    "F149135481334",
    "F149135465697",
    "F149135453360",
    "F149135396065",
    "F149135376172",
    "F149135298396",
    "F149135132165",
    "F149134884826",
    "F149134905872",
    "F700000000001508000",
    "F140111000001849000",
    "F140111000001848000",
    "F149135144051",
    "F149134433381",
    "F054162400000013071-1",
    "F999000000030678953-1",
    "F999000000030679842",
    "F907490000050488555",
    "F999000000030675484",
    "F026172400000007819",
    "F026172400000007779",
    "F999000000030674788",
    "F907490000050488163",
    "F8289400",
    "F8288329",
    "F054162400000013180",
    "F149136884021",
    "F149137065756",
    "F146002671896",
    "F149137083905",
    "F149136929165",
    "F146002672081",
    "F149136226621",
    "149136226621",
  ];

  return {
    siteName,
    phoneSuport,
    telSuport,
    logoOficialUrl1x4,
    imgPlaceOlderCart,
    purchaseState,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    imgbannerfooter,
    carrusell01,
    carrusell02,
    carrusell03,
    carrusell04,
    ecotribicci,
    ecotribicci2,
    homeImgUrl,
    ecortribicci,
    cobertura,
    btnvehicle,
    btnuseradmin,
    btnacuerdo,
    biccidashboard,
    ecortribicciR,
    boxbicciexpres,
    ecobicci2,
    icomshome1,
    icomshome2,
    icomshome3,
    icomshome4,
    icomshome5,
    logoOficialUrl,
    ecobicci2ConLogo,
    isoTipoUrl,
    rosettaVehicles,
    ROUTESTATES,
    comunasSantiago,
    translateStateBicciFalabella,
    styleStateBicciFalabella,
    falabellaExcepcions,
    retiredTestsArray,
    MONTHS,
    YEARS,
    typesHistorials,
    tablaMedidasTarifas,
  };
}

export default useGlobalConfig;
