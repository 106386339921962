import React, { useContext } from "react";
import { UserContext } from "../../../context/CurrentUserProvider";
import TableroCliente from "./TableroCliente";
import TableroEmpresa from "./TableroEmpresa";
import TableroConductor from "./TableroConductor";
import TableroOmbordaing from "./TableroOmbordaing";
import TableroBicciexpress from "./TableroBicciexpress";

const Tablero = () => {
  const { userDataContext } = useContext(UserContext);
  console.log(userDataContext);

  const renderDashboard = () => {
    switch (userDataContext.roll) {
      case "client":
        return <TableroCliente />;
      case "company_driver":
        return <TableroConductor />;
      case "company_ops":
        return <TableroEmpresa />;
      case "company_admin":
        return <TableroEmpresa />;
      case "bicci_admin":
        return <TableroBicciexpress />;
      case "biccixpressAdmin":
        return <TableroBicciexpress />;
      default:
        return <TableroOmbordaing />;
    }
  };

  return <div>{renderDashboard()}</div>;
};

export default Tablero;
