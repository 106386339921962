import React from "react";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import { Form, Input, Container } from "semantic-ui-react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

class ProductWeb extends React.Component {
  constructor(props) {
    super(props);
    //// console.log(props);
    this.state = {
      productId: "",
      id: "",
      productName: "",
      rateService: "",
      description: "",
      descriptorsWEB0: "",
      descriptorsWEB1: "",
      descriptorsWEB2: "",
      descriptorsWEB3: "",
      descriptorsWEB4: "",
      descriptorsWEB5: "",
      descriptorsWEB6: "",
      descriptorsWEB7: "",
      descriptorsWEB8: "",
      descriptorsWEB9: "",
      createBy: this.props.userUid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      isActive: false,
      isDelete: false,
      inPublic: false,
      slug: "",
      date: Date.now(),
      articleType: "packServicExpress", // tipo de articulo pack de Servico Express  ARTYPE
      ServicExpressType: "bagServicExpress", //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,
      articlePrice: "",
      discount: "",
      discountType: "",
      subTotal: "",
      total: "",
      ChargeShipments: "",
      availableservices: this.props.dataRates,
      dataComplete: null,
    };
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validationState = this.validationState.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCall = this.handleCall.bind(this);
    this.handleAccion = this.handleAccion.bind(this);
    this.handleSelectCurrentTask = this.handleSelectCurrentTask.bind(this);
    this.handleChangeItemServ = this.handleChangeItemServ.bind(this);
  }

  // const crearServico=()=>{}
  // const limpiarFormularioa=()=>{}
  render() {
    return (
      <Container className="dashcontent">
        <Grid celled="internally" id="servicioscontent">
          <div>
            <h2>Bolsas de envios o producto publicados en la web</h2>
          </div>
          <Grid.Row>
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo.."
                title={
                  <>
                    <h3>Agregar productos en el sitio</h3>
                  </>
                }
              />
              <Divider />
              {this.state.dataComplete ? (
                <div className="alert alert-danger">
                  {this.state.dataComplete}
                </div>
              ) : (
                <></>
              )}

              <Container className="formcontent" id="micuentafromIni">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <select
                      id="custom-selectFrom"
                      label="Asocia el nuevo plan a un tarifario"
                      name={"rateService"}
                      control="select"
                      onChange={(e) =>
                        this.handleSelectCurrentTask(e.target.value)
                      }
                    >
                      <option> Seleccione un Plan. </option>
                      {this.props.productWeb.map((item) => (
                        <option key={item.id} value={item.id}>
                          {" "}
                          {item.planName}
                        </option>
                      ))}
                    </select>
                  </Form.Group>

                  <Form.Field
                    hidden
                    className="disabled field disabledEstilo"
                    control={Input}
                    type="text"
                    name="productId"
                    onChange={this.handleChange}
                    value={this.state.productId}
                  />
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      name="productName"
                      label="Nombre"
                      onChange={this.handleChange}
                      value={this.state.productName}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Precio"
                      type="number"
                      name="articlePrice"
                      onChange={this.handleChange}
                      value={this.state.articlePrice}
                    />

                    <Form.Field
                      control={Input}
                      type="number"
                      name="discount"
                      label="Descuento"
                      onChange={this.handleChange}
                      value={this.state.discount}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Button
                      sx={{ margin: "2px" }}
                      color="primary"
                      variant="contained"
                      onClick={this.handleAccion}
                    >
                      Calcular
                    </Button>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Sub Total"
                      type="number"
                      name="subTotal"
                      onChange={this.handleChange}
                      value={this.state.subTotal}
                    />

                    <Form.Field
                      control={Input}
                      type="number"
                      name="total"
                      label="Total"
                      onChange={this.handleChange}
                      value={this.state.total}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="number"
                      label="Envios cargados a cuenta"
                      name="ChargeShipments"
                      onChange={this.handleChange}
                      value={this.state.ChargeShipments}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      label="Descripcion"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      label="Link web"
                      name="slug"
                      onChange={this.handleChange}
                      value={this.state.slug}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB0"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB0}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB1"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB1}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB2"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB2}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB3"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB3}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB4"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB4}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB5"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB5}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB6"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB6}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB7"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB7}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB8"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB8}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB9"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB9}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Descriptor WEB "
                      type="text"
                      name="descriptorsWEB10"
                      onChange={this.handleChange}
                      value={this.state.descriptorsWEB10}
                    />
                  </Form.Group>
                  <Card className="typeMiniforms">
                    <CardHeader
                      subheader="Crear nuevo tarifario personalizado"
                      title={
                        <>
                          <h3>Servicios de para este Producto</h3>
                        </>
                      }
                    />
                    <Divider />
                    <Container className="formcontent" id="micuentafromIni">
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col">Código</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.availableservices.map((element) => (
                              <tr key={element.id}>
                                <td>{element.name}</td>
                                <td>{element.id}</td>
                                <td>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      defaultChecked={element.isActive}
                                      id={element.id}
                                      name={element.name}
                                      onChange={this.handleChangeItemServ}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Container>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    ></Box>
                  </Card>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  sx={{ margin: "2px" }}
                  color="primary"
                  variant="contained"
                  onClick={this.handleClear}
                >
                  Limpiar
                </Button>
              </Box>
            </Card>
            {this.props.productWeb
              .filter((item) => item.id === this.state.rateService)
              .map((elements) => (
                <Card className="typeMiniformsserv" key={elements.id}>
                  <CardHeader
                    subheader="Detalla de plan que asociara al producto web"
                    title={
                      <>
                        <h3>Detalles</h3>
                      </>
                    }
                  />
                  <Divider />
                  <Container className="formcontent" id="micuentafromIni">
                    <h3>{elements.planName}</h3>
                    <h4>{this.state.rateService}</h4>
                    <span className="h5"> Nombre : </span>{" "}
                    <span>{elements.ChargeAmount}</span> <br></br>
                    <span className="h5"> Número envios : </span>{" "}
                    <span> {elements.ChargeShipments} </span> <br></br>
                    <span className="h5"> Dia de Facturación : </span>{" "}
                    <span>
                      {" "}
                      {elements.activeBillingDay ? "true" : "false"}{" "}
                    </span>{" "}
                    <br></br>
                    <span className="h5"> Dia de Facturación : </span>{" "}
                    <span> {elements.billingDay} </span> <br></br>
                    <span className="h5"> Periodo de Facturación : </span>{" "}
                    <span>
                      {" "}
                      {elements.activeBilingPeriod ? "true" : "false"}{" "}
                    </span>{" "}
                    <br></br>
                    <span className="h5"> Periodo de Facturación : </span>{" "}
                    <span> {elements.bilingPeriod} </span> <br></br>
                    <span className="h5"> Creado por : </span>{" "}
                    <span> {elements.createBy} </span> <br></br>
                    <span className="h5"> Creado el día : </span>{" "}
                    <span> {elements.createDate} </span> <br></br>
                    <span className="h5"> Descripción : </span>{" "}
                    <span> {elements.description} </span> <br></br>
                    {/* <span className="h5">	Descriptor Web	:	</span>	<span>	{elements.descriptorsWeb}	</span>	<br></br> */}
                    <span className="h5"> Precio: : </span>{" "}
                    <span> {elements.salePrice} </span> <br></br>
                    <span className="h5"> Monto de descuento : </span>{" "}
                    <span> {elements.discountAmount} </span> <br></br>
                    <span className="h5"> Precio en descuento. : </span>{" "}
                    <span> {elements.discountPrice} </span> <br></br>
                    <span className="h5"> Estatus : </span>{" "}
                    <span> {elements.isActive ? "true" : "false"} </span>{" "}
                    <br></br>
                    <span className="h5"> Estatus : </span>{" "}
                    <span> {elements.isPost ? "true" : "false"} </span>{" "}
                    <br></br>
                    <span className="h5"> Estatus WEB : </span>{" "}
                    <span> {elements.isPublic ? "true" : "false"} </span>{" "}
                    <br></br>
                    {/* <span className="h5">	Meta palabras	:	</span>	<span>	{elements.metaWord}	</span>	<br></br> */}
                    <span className="h5"> Tarifario Asociado : </span>{" "}
                    <span> {elements.rateService} </span> <br></br>
                    <span className="h5"> Tarifario Base : </span>{" "}
                    <span> {elements.rateServiceBase} </span> <br></br>
                    <span className="h5"> Splug : </span>{" "}
                    <span> {elements.slug} </span> <br></br>
                    <span className="h5"> Stock : </span>{" "}
                    <span> {elements.stock_quantity} </span> <br></br>
                    <span className="h5"> Tipo : </span>{" "}
                    <span> {elements.type} </span> <br></br>
                  </Container>

                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  ></Box>
                </Card>
              ))}
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Edita productos en el sitio web"
                title={
                  <>
                    <h3>Productos en sitio web</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Status</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.productData.map((element) => (
                        <tr key={element.id}>
                          <td>
                            {element.isActive ? (
                              <CheckCircleIcon color="success"></CheckCircleIcon>
                            ) : (
                              <IndeterminateCheckBoxIcon color="disabled"></IndeterminateCheckBoxIcon>
                            )}
                          </td>
                          <td>{element.productName}</td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItem}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isPublic}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItemPubilc}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>Editar</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  ></Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    // console.log("un clik");
    // console.log(this.state);
    this.validationState();
    try {
      const accountants = await db
        .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
        .doc("xpressProducts")
        .get();
      // console.log(accountants.data().count);
      const documenCont = accountants.data().count + 1;
      const productId = "PRODUCTID" + documenCont;
      this.setState({ productId: productId });
      this.setState({ id: productId });
      await db
        .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
        .doc(productId)
        .set(this.state);
      await db
        .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
        .doc("xpressProducts")
        .set({ count: documenCont });
      //   // console.log("Creando Producto");
      this.handleClear();
    } catch (error) {
      // console.log(error);
    }
  }

  validationState() {
    if (!this.state.rateService.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique un tarifario del producto" });
      return;
    }
    if (!this.state.productName.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el nombre del producto" });
      return;
    }

    if (!this.state.articlePrice > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Revise el calculo de precios" });
      return;
    }
    if (!this.state.total > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Revise el calculo de totales" });
      return;
    }
    if (!this.state.description.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique la descripción del producto" });
      return;
    }
    if (!this.state.slug.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el Link (slug) del producto" });
      return;
    }
    if (!this.state.ChargeShipments > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique cantidad de envios a cargar" });
      return;
    }
    this.setState({ dataComplete: true });
    // console.log('No errors')
  }

  handleAccion(e) {
    e.preventDefault();
    this.handleCall(e);
  }

  handleCall(e) {
    var preciovalue = this.state.articlePrice;
    var descuento = this.state.discount;
    var subtotal = preciovalue - descuento;
    this.setState({ subTotal: subtotal });
    this.setState({ total: subtotal });
    // console.log(subtotal)
  }

  handleChange(e) {
    // console.log("un cambio");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
    // console.log(this.state)
  }

  handleChangeItemServ(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var model = this.props.dataRates;
    for (let i = 0; i < model.length; i++) {
      if (id === model[i].id) {
        // console.log('Update '+ id)
        model[i].isActive = checked;
      }
    }
    this.setState({ availableservices: model });
  }

  handleClear(e) {
    var inicial = {
      productId: "",
      id: "",
      productName: "",
      rateService: "",
      description: "",
      descriptorsWEB0: "",
      descriptorsWEB1: "",
      descriptorsWEB2: "",
      descriptorsWEB3: "",
      descriptorsWEB4: "",
      descriptorsWEB5: "",
      descriptorsWEB6: "",
      descriptorsWEB7: "",
      descriptorsWEB8: "",
      descriptorsWEB9: "",
      createBy: this.props.userUid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      isActive: false,
      isDelete: false,
      inPublic: false,
      slug: "",
      date: Date.now(),
      articleType: "packServicExpress", // tipo de articulo pack de Servico Express  ARTYPE
      ServicExpressType: "bagServicExpress", //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,
      articlePrice: "",
      discount: "",
      discountType: "",
      subTotal: "",
      total: "",
      ChargeShipments: "",
      availableservices: this.props.dataRates,
      dataComplete: null,
    };
    this.setState(inicial);
  }

  async handleChangeItem(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    await db
      .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .doc(id)
      .update({ isActive: checked });
  }
  async handleChangeItemPubilc(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    await db
      .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .doc(id)
      .update({ isPublic: checked });
  }

  handleSelectCurrentTask(e) {
    this.setState({ rateService: e });
  }
}

export default ProductWeb;
