import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";

function useGetDataOrdersListPicking({ listId }) {
  const [listPickingData, setListPickingData] = useState([]);
  const [loadPickingData, setLoadPickingData] = useState(false);
  const [errorPickingData, setErrorPickingData] = useState(null);
  const [internetSpeed, setInternetSpeed] = useState(null); // Estado para la velocidad de internet

  useEffect(() => {
    if (listId) {
      const unsubscribe = subscribeToData(listId);

      return () => {
        unsubscribe();
      };
    }
  }, [listId]);

  useEffect(() => {
    measureInternetSpeed(); // Mide la velocidad de conexión cuando el componente se monta
  }, []);

  const subscribeToData = (listId) => {
    setLoadPickingData(true);
    const allRidersRef = collection(
      db,
      process.env.REACT_APP_COL_PICKER_REGISTER
    );

    const q = query(
      allRidersRef,
      where("listId", "==", listId),
      where("deleted", "==", false)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const allPickingDatasfind = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setListPickingData(allPickingDatasfind);
        setLoadPickingData(false);
        setErrorPickingData(null);
      },
      (error) => {
        console.log(error);
        setErrorPickingData(error);
        setLoadPickingData(false);
      }
    );

    return unsubscribe;
  };

  // Función para medir la velocidad de internet
  const measureInternetSpeed = async () => {
    const image = new Image();
    const startTime = new Date().getTime();
    const imageSizeInBytes = 500000; // Tamaño de la imagen en bytes (500 KB)

    image.src =
      "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"; // Un recurso pequeño de Google

    image.onload = function () {
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000; // Tiempo en segundos
      const bitsLoaded = imageSizeInBytes * 8; // Convertir a bits
      const speedBps = (bitsLoaded / duration).toFixed(2); // Bits por segundo
      const speedKbps = (speedBps / 1024).toFixed(2); // Kilobits por segundo
      const speedMbps = (speedKbps / 1024).toFixed(2); // Megabits por segundo

      setInternetSpeed(speedMbps); // Guardar la velocidad en Mbps
    };

    image.onerror = function () {
      setInternetSpeed("Error al medir la velocidad");
    };
  };

  return { listPickingData, loadPickingData, errorPickingData, internetSpeed }; // Retorna la velocidad de internet
}

export default useGetDataOrdersListPicking;
