import React from "react";

const RutValidador = (props) => {
  const validator = (e) => {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (!e) {
      return "campo vacio";
    }
    if (e.length <= 0) {
      return "campo vacio";
    }
    valor = clean(e);

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    if (cuerpo.length < 7) {
      return "rut < 7";
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (parseInt(dvEsperado) !== parseInt(dv)) {
      return "Rut incorrecto";
    } else {
      return "Ok";
    }
  };

  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }
    return result;
  }

  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  const e = props.rut;
  let respuesta = validator(e);

  return <span>{respuesta}</span>;
};

export default RutValidador;
