import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Switch,
  MenuItem,
  Select,
  Button,
  Typography,
  Box,
  Chip,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledCard, StyledTextField } from "./FromStyles";

const FromShippingDescriptions = ({ onChange, initialOrder }) => {
  const [order, setOrder] = useState(
    initialOrder || {
      groceries: [], // Asegurar que siempre se inicialice como un arreglo vacío
      fragile: false,
      multipack: false,
      insurance: false,
      deliveryType: "HD",
    }
  );

  const [currentProduct, setCurrentProduct] = useState({
    description: "",
    id: Date.now(),
    name: "",
    instructions: "",
    quantity: 1,
    price: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
    volume: 0,
  });

  const [isFragile, setIsFragile] = useState(order.fragile || false);
  const [isMultibulto, setIsMultibulto] = useState(order.multipack || false);
  const [insurance, setInsurance] = useState(order.insurance || false);
  const [deliveryType, setDeliveryType] = useState(order.deliveryType || "HD");

  const [openSelectInventory, setOpenSelectInventory] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [viewContainer, setViewContainer] = useState(false);

  // Save a reference to onChange to avoid recreating the effect every render
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    handleContainer();
  }, []);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // Manejar los cambios en los productos
  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setCurrentProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const { length, width, height } = currentProduct;
    const volume =
      length && width && height
        ? ((((length / 100) * width) / 100) * height) / 100
        : 0;
    setCurrentProduct((prevState) => ({ ...prevState, volume }));
  }, [currentProduct.length, currentProduct.width, currentProduct.height]);

  const validateForm = () => {
    const {
      name,
      description,
      length,
      width,
      height,
      weight,
      price,
      quantity,
    } = currentProduct;
    return (
      name &&
      description &&
      length &&
      width &&
      height &&
      weight &&
      price &&
      quantity
    );
  };

  const addProductToOrder = () => {
    if (!validateForm()) {
      alert(
        "Por favor, completa todos los campos antes de agregar un producto."
      );
      return;
    }

    setOrder((prevOrder) => {
      const updatedGroceries = [...prevOrder.groceries, currentProduct];

      const updatedOrder = {
        ...prevOrder,
        groceries: updatedGroceries,
      };

      onChangeRef.current(updatedOrder);

      setCurrentProduct({
        description: "",
        id: Date.now(),
        name: "",
        instructions: "",
        quantity: 1,
        price: 0,
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        volume: 0,
      });

      return updatedOrder;
    });
  };

  const removeProduct = (productId) => {
    setOrder((prevOrder) => {
      const updatedGroceries = prevOrder.groceries.filter(
        (product) => product.id !== productId
      );

      const updatedOrder = { ...prevOrder, groceries: updatedGroceries };
      onChangeRef.current(updatedOrder);

      return updatedOrder;
    });
  };

  const handleFragileChange = (e) => {
    setIsFragile(e.target.checked);
    const updatedOrder = { ...order, fragile: e.target.checked };
    setOrder(updatedOrder); // Aseguramos que también se guarde en el estado del componente
    onChangeRef.current(updatedOrder);
  };

  const handleMultibultoChange = (e) => {
    setIsMultibulto(e.target.checked);
    const updatedOrder = { ...order, multipack: e.target.checked };
    setOrder(updatedOrder);
    onChangeRef.current(updatedOrder);
  };

  const handleInsuranceChange = (e) => {
    setInsurance(e.target.checked);
    const updatedOrder = { ...order, insurance: e.target.checked };
    setOrder(updatedOrder);
    onChangeRef.current(updatedOrder);
  };

  const handleDeliveryTypeChange = (e) => {
    setDeliveryType(e.target.value);
    const updatedOrder = { ...order, deliveryType: e.target.value };
    setOrder(updatedOrder);
    onChangeRef.current(updatedOrder);
  };

  const handleContainer = () => {
    setViewContainer(!viewContainer);
    setOrder((prevOrder) => {
      const updatedOrder = { ...prevOrder, groceries: [] };
      onChangeRef.current(updatedOrder);
      return updatedOrder;
    });
  };

  const handleOpenSelectInventory = () => {
    setOpenSelectInventory(true);
  };

  const handleCloseSelectInventory = () => {
    setOpenSelectInventory(false);
  };

  const handleOpenAddProduct = () => {
    setOpenAddProduct(true);
  };

  const handleCloseAddProduct = () => {
    setOpenAddProduct(false);
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <Typography sx={{ color: "#fff", padding: "16px" }} variant="h6">
        Detalles de envío
      </Typography>
      <Grid container spacing={2} sx={{ padding: "16px" }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box>
              <Typography sx={{ color: "#fff" }}>Frágil</Typography>
              <Switch checked={isFragile} onChange={handleFragileChange} />
            </Box>
            <Box>
              <Typography sx={{ color: "#fff" }}>Multibulto</Typography>
              <Switch
                checked={isMultibulto}
                onChange={handleMultibultoChange}
              />
            </Box>
            <Box>
              <Typography sx={{ color: "#fff" }}>
                {viewContainer === false
                  ? "Descritores de Contenido"
                  : "Descritores de Contenido"}
              </Typography>
              <Switch checked={viewContainer} onChange={handleContainer} />
            </Box>
          </Box>
        </Grid>

        {viewContainer === true ? (
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Button
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                sx={{ backgroundColor: "#4CAF50", color: "#fff" }}
                onClick={() => setOpenSelectInventory(true)}
              >
                Seleccionar de inventarios
              </Button>

              <Button
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                sx={{ backgroundColor: "#4CAF50", color: "#fff" }}
                onClick={() => setOpenAddProduct(true)}
              >
                Agregar Nuevo
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                padding: "20px",
                color: "#fff",
                backgroundColor: "#db4949",
                borderRadius: "8px",
              }}
            >
              Descritores de Contenido está apagado, no se agregan detalles del
              contenido al envío. Este dato es importante en casos de
              incidencias.
            </Box>
          </Grid>
        )}
        {/* Dialog para seleccionar de inventarios */}
        <Dialog open={openSelectInventory} onClose={handleCloseSelectInventory}>
          <DialogTitle>Seleccionar de Inventarios</DialogTitle>
          <DialogContent sx={{ backgroundColor: "#5e72b9" }}>
            <Box
              sx={{
                padding: "20px",
                color: "#fff",
                backgroundColor: "#db4949",
                borderRadius: "8px",
                m: 2,
              }}
            >
              No hay inventarios configurados
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSelectInventory}>Cerrar</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para agregar nuevo producto */}
        <Dialog open={openAddProduct} onClose={handleCloseAddProduct}>
          <DialogTitle>Agregar Nuevo Producto</DialogTitle>
          <DialogContent sx={{ backgroundColor: "#5e72b9" }}>
            {/* Campos del producto que tienes en el formulario */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#fff" }}>
                  Nombre del Producto
                </InputLabel>
                <StyledTextField
                  fullWidth
                  name="name"
                  placeholder="Ingrese el nombre del producto"
                  value={currentProduct.name}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#fff" }}>
                  Descripción del Producto
                </InputLabel>
                <StyledTextField
                  fullWidth
                  name="description"
                  placeholder="Ingrese la descripción del producto"
                  value={currentProduct.description}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Largo (cm)</InputLabel>
                <StyledTextField
                  fullWidth
                  name="length"
                  placeholder="Largo"
                  value={currentProduct.length}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Ancho (cm)</InputLabel>
                <StyledTextField
                  fullWidth
                  name="width"
                  placeholder="Ancho"
                  value={currentProduct.width}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Alto (cm)</InputLabel>
                <StyledTextField
                  fullWidth
                  name="height"
                  placeholder="Alto"
                  value={currentProduct.height}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Peso (kg)</InputLabel>
                <StyledTextField
                  fullWidth
                  name="weight"
                  placeholder="Peso"
                  value={currentProduct.weight}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>
                  Volumen (auto-calculado)
                </InputLabel>
                <StyledTextField
                  fullWidth
                  name="volume"
                  placeholder="Volumen"
                  value={currentProduct.volume}
                  disabled // Auto-calculated, no manual input allowed
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Precio</InputLabel>
                <StyledTextField
                  fullWidth
                  name="price"
                  placeholder="Precio"
                  value={currentProduct.price}
                  onChange={handleProductChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#fff" }}>Cantidad</InputLabel>
                <StyledTextField
                  fullWidth
                  name="quantity"
                  placeholder="Cantidad"
                  value={currentProduct.quantity}
                  onChange={handleProductChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addProductToOrder}>Guardar</Button>
            <Button onClick={handleCloseAddProduct}>Cancelar</Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12}>
          {order.groceries.map((grocery) => (
            <Chip
              key={grocery.id}
              label={`${grocery.name} - ${grocery.length}x${grocery.width}x${grocery.height}`}
              onDelete={() => removeProduct(grocery.id)}
              deleteIcon={<DeleteIcon sx={{ color: "red" }} />}
              sx={{
                marginRight: "8px",
                marginTop: "8px",
                backgroundColor: "#f8f8f8",
              }}
            />
          ))}
        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ color: "#fff" }}>Activación Seguro</Typography>
          <Switch checked={insurance} onChange={handleInsuranceChange} />
        </Grid>

        <Grid item xs={6}>
          <Select
            fullWidth
            value={initialOrder.deliveryType}
            onChange={handleDeliveryTypeChange}
            sx={{ backgroundColor: "#fff" }}
          >
            <MenuItem value="SD">Same Day</MenuItem>
            <MenuItem value="HD">Next Day</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default FromShippingDescriptions;
