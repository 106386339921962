import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, doc, getDocs } from "firebase/firestore";

function useGetVehiclesNames() {
  const [VehiclesNames, setVehiclesNames] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (companyId) => {
    try {
      const companyVehiclesRef = collection(db, "vehicles"); // process.env.REACT_APP_COL_COMPANIES);

      const querySnapshot = await getDocs(companyVehiclesRef);

      const dataVehiclesNames = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setVehiclesNames(dataVehiclesNames);
    } catch (error) {
      console.log(error);
    }
  };
  return {
    VehiclesNames,
  };
}

export default useGetVehiclesNames;
