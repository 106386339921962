import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";
import { Card, TextField } from "@mui/material";
import {
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const StyledCard = styled(Card)({
  borderRadius: "15px",
  padding: "5px", // Se elimina el padding para usar el del CardContent
  backgroundColor: "#5e72b9", // Ajustado para que coincida con el diseño
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)", // Sombras más suaves como en la imagen
  margin: "10px",
  overflow: "hidden", // Para que el borderRadius se aplique también a los hijos
});

export const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "purple",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "purple",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent", // Quitar borde para simular el de la imagen
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "purple", // Cambiar por el color deseado para el foco
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px", // Bordes redondeados como en la imagen
    backgroundColor: "#fff", // Fondo blanco para los inputs
    padding: "10px", // Espaciado interno como en la imagen

    // Ajustar los siguientes estilos para alinear con el diseño de la imagen:
    fontSize: "1rem", // Tamaño de la fuente
    color: "#333", // Color de la fuente
  },
});

export const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    borderRadius: "10px",
    backgroundColor: "#fff", // Fondo blanco para los inputs
    padding: "10px",
    fontSize: "1rem",
    color: "#333",
  },
  // Selecciona específicamente el InputLabel en su estado normal y shrink
  "& .MuiInputLabel-outlined": {
    color: "#333",
    backgroundColor: "#fff", // Fondo blanco para el InputLabel
    padding: "0 10px", // El mismo padding horizontal que el input para alineación
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    color: "#333",
    transform: "translate(14px, -6px) scale(0.75)",
    backgroundColor: "#fff", // Fondo blanco también cuando está 'shrink'
    overflow: "hidden", // Asegúrate de que el fondo no se desborde
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff", // Borde transparente para el campo
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
  "& .MuiSelect-select": {
    color: "#333", // Color del texto dentro del Select
  },
});
