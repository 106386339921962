import * as XLSX from "xlsx";
import Moment from "moment";
import { differenceInCalendarDays, format, fromUnixTime } from "date-fns";
import { translateStatesBicci } from "../objects/translateStates";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const generarReporteBicciDetalle = async (
  data,
  ridersDataByCompany,
  initDate
) => {
  if (!ridersDataByCompany) {
    console.log("Buscar en base al data que llegan undefine");
    console.log(data);
    console.log(ridersDataByCompany);
    console.log(initDate);
    // Mapear driverID, asignando "noAssign" a los que son null, undefined, o vacíos
    let list = data.map((doc) => {
      let driverID = doc.driverID;
      return driverID ? driverID : "noAssign";
    });

    let dataResult = [];
    try {
      const colRef = collection(
        db,
        `${process.env.REACT_APP_COL_USERS_DRIVERS}`
      );
      const queryDrivers = query(colRef, where("active", "==", true));
      const querySnapshot = await getDocs(queryDrivers);

      querySnapshot.forEach((doc) => {
        let field = doc.data().driverID ? doc.data().driverID : "noAssign";
        if (list.includes(field)) {
          dataResult.push({ id: doc.id, ...doc.data() });
        }
      });

      console.log(dataResult);
      await addDataRidersDataByCompany(data, dataResult);
    } catch (error) {
      console.log(error);
    }

    async function addDataRidersDataByCompany(data, ridersDataByCompany) {
      const newData = JSON.parse(JSON.stringify(data));
      let currentDate = new Date(initDate);
      console.log(newData);

      currentDate.setDate(currentDate.getDate());
      let year = currentDate.getFullYear().toString().slice(-2);
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");
      let formattedDate = year + month + day;

      function formatDate(element) {
        try {
          return element.date
            ? format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : element.createdAt
            ? format(element.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : "No Date";
        } catch (error) {
          console.log("Error al formatear la fecha:", error);
          return "Formato de fecha inválido";
        }
      }

      function getDays(element) {
        try {
          const targetDate = element.date
            ? new Date(element.date.seconds * 1000)
            : element.createdAt
            ? new Date(element.createdAt.seconds * 1000)
            : null;

          if (!targetDate) {
            return 0;
          }

          let endDate;
          if (
            (element.int_falabella_status === "99" ||
              element.int_falabella_status === "Terminado" ||
              element.int_bicciStatus === 8) &&
            element.upDatedAtText
          ) {
            endDate = fromUnixTime(element.upDatedAtText.seconds);
          } else {
            endDate = new Date();
          }

          const delayDays = differenceInCalendarDays(endDate, targetDate);

          return delayDays < 0 ? 0 : delayDays;
        } catch (error) {
          console.log("Error al calcular los días de retraso:", error);
          return "Error al calcular los días";
        }
      }

      function formatDateUpdatedAt(element) {
        try {
          return element.updatedAt
            ? format(element.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : "No date updatedAt";
        } catch (error) {
          return "Formato de fecha inválido";
        }
      }

      const bicciNumberMap = {};
      ridersDataByCompany.forEach((rider) => {
        bicciNumberMap[rider.id] = rider.bicciNumber;
      });

      const matrizReporte = [
        [
          "BICCIID",
          "SUBORDEN",
          "LPN",
          "F12",
          "PPU",
          "RIDER",
          "Origen de Carga",
          "Destino",
          "Comuna",
          "Tipo de Servicio",
          "ID Ruta/Viaje/Shipment",
          "Fecha de recepción",
          "Fecha de Actualización",
          "Fecha de entrega",
          "Estado",
          "Motivo de no entrega",
          "Receptor",
          "RUT Receptor",
          "Observación",
          "Días desde Creación",
          "fotos",
          "Exception GeoRef",
        ],
        ...newData.map((dato) => [
          dato.id,
          dato.soc ? dato.soc : dato.int_soc,
          dato.lpn ? dato.lpn : dato.int_lpn,
          dato.do ? dato.do : dato.int_f12,
          bicciNumberMap[dato.driverID],

          dato.driverName ? dato.driverName : "",
          dato.placeName,
          dato.toAddress ? dato.toAddress : "",
          dato.commune2 ? dato.commune2 : "",
          dato.deliveryType ? dato.deliveryType : "",
          dato.idRoute
            ? dato.idRoute
            : initDate.replace(/-/g, "") + bicciNumberMap[dato.driverID],
          formatDate(dato),
          formatDateUpdatedAt(dato),
          dato.status === 8
            ? dato.deliveryDate
              ? dato.deliveryDate
              : dato.upDatedAtText
              ? dato.upDatedAtText
              : ""
            : "",

          dato.status ? translateStatesBicci[dato.status] : "udefine",

          dato.status === 8 ? "" : dato.intState ? dato.intState : "undefine",

          dato.userNameReceptor ? dato.userNameReceptor : "",
          dato.userDniReceptor ? dato.userDniReceptor : "",
          dato.observationReceptor ||
          dato.observationAdmin ||
          dato.deliveryComment
            ? `${
                dato.observationAdmin
                  ? `- Operador:  ${dato.observationAdmin[0]} `
                  : ""
              }${
                dato.observationReceptor
                  ? `- Conductor: ${dato.observationReceptor}`
                  : ""
              }${dato.deliveryComment ? `- ${dato.deliveryComment}` : ""}`
            : null,
          getDays(dato),
          dato.pics
            ? dato.pics && dato.pics.length > 0
              ? dato.pics.join("\n")
              : ""
            : dato.deliveryPictures
            ? dato.deliveryPictures
            : "",
          dato.status === 8
            ? ""
            : dato.exceptionGeoRef
            ? `${dato.exceptionGeoRef._lat} , ${dato.exceptionGeoRef._long}`
            : "",
        ]),
      ];

      const libro = XLSX.utils.book_new();
      const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
      XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

      XLSX.writeFile(
        libro,
        `Reporte De Turno BICCI ${Moment(Date.now()).format(
          "YYYY-MM-DD:hh:mm:ss"
        )}.xlsx`
      );
    }
  } else {
    async function addDataRidersDataByCompany(data, ridersDataByCompany) {
      const newData = JSON.parse(JSON.stringify(data));
      let currentDate = new Date(initDate);

      currentDate.setDate(currentDate.getDate()); // es Cero - Desarrollo -6
      let year = currentDate.getFullYear().toString().slice(-2); // Extract the last two digits of the year
      let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with leading zero if necessary
      let day = String(currentDate.getDate()).padStart(2, "0"); // Pad with leading zero if necessary
      let formattedDate = year + month + day;

      function formatDate(element) {
        try {
          return element.date
            ? format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : element.createdAt
            ? format(element.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : "No Date";
        } catch (error) {
          console.log("Error al formatear la fecha:", error);
          return "Formato de fecha inválido";
        }
      }

      function getDays(element) {
        try {
          const targetDate = element.date
            ? new Date(element.date.seconds * 1000)
            : element.createdAt
            ? new Date(element.createdAt.seconds * 1000)
            : null;

          if (!targetDate) {
            return 0;
          }

          let endDate;
          if (
            (element.int_falabella_status === "99" ||
              element.int_falabella_status === "Terminado" ||
              element.int_bicciStatus === 8) &&
            element.upDatedAtText
          ) {
            endDate = fromUnixTime(element.upDatedAtText.seconds);
          } else {
            endDate = new Date();
          }

          const delayDays = differenceInCalendarDays(endDate, targetDate);

          return delayDays < 0 ? 0 : delayDays;
        } catch (error) {
          console.log("Error al calcular los días de retraso:", error);
          return "Error al calcular los días";
        }
      }

      function formatDateUpdatedAt(element) {
        try {
          return element.updatedAt
            ? format(element.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
            : "No date updatedAt";
        } catch (error) {
          return "Formato de fecha inválido";
        }
      }

      const bicciNumberMap = {};
      ridersDataByCompany.forEach((rider) => {
        bicciNumberMap[rider.id] = rider.bicciNumber;
      });

      console.log(newData);

      const matrizReporte = [
        [
          "BICCIID",
          "SUBORDEN",
          "LPN",
          "F12",
          "PPU",
          "RIDER",
          "Origen de Carga",
          "Destino",
          "Comuna",
          "Tipo de Servicio",
          "ID Ruta/Viaje/Shipment",
          "Fecha de recepción",
          "Fecha de Actualización",
          "Fecha de entrega",
          "Estado",
          "Descripción",
          "Estado Integración",
          "Motivo de no entrega",
          "Receptor",
          "RUT Receptor",
          "Observación",
          "Días desde creacion",
          "fotos",
          "Exception GeoRef",
          //  "valor",
        ],
        ...newData.map((dato) => [
          dato.id,
          dato.soc ? dato.soc : dato.int_soc,
          dato.lpn ? dato.lpn : dato.int_lpn,
          dato.do ? dato.do : dato.int_f12,
          bicciNumberMap[dato.driverID],
          dato.driverName ? dato.driverName : "",
          dato.local_provider ? dato.local_provider : dato.placeName,
          dato.toAddress ? dato.toAddress : "",
          dato.commune2 ? dato.commune2 : "",
          dato.deliveryType ? dato.deliveryType : "",
          dato.driverLicensePlate
            ? dato.driverLicensePlate
            : initDate.replace(/-/g, "") + bicciNumberMap[dato.driverID],
          formatDate(dato),
          formatDateUpdatedAt(dato),
          dato.status === 8 ? formatDateUpdatedAt(dato) : "",
          dato.status,
          dato.status ? translateStatesBicci[dato.status] : "",
          dato.intState,
          dato.lostDelivery === true ? "Devolucion" : dato.intState,
          dato.userNameReceptor
            ? dato.userNameReceptor
            : dato.deliveryReceptor !== "isRequire"
            ? dato.deliveryReceptor
            : "",
          dato.userDniReceptor ? dato.userDniReceptor : dato.deliveryRut,
          dato.observationReceptor ||
          dato.observationAdmin ||
          dato.deliveryComment
            ? `${
                dato.observationAdmin
                  ? `- Operador:  ${dato.observationAdmin[0]} `
                  : ""
              }${
                dato.observationReceptor
                  ? `- Conductor: ${dato.observationReceptor}`
                  : ""
              }${dato.deliveryComment ? `- ${dato.deliveryComment}` : ""}`
            : null,
          getDays(dato),
          dato.pics
            ? dato.pics && dato.pics.length > 0
              ? dato.pics.join("\n")
              : ""
            : dato.deliveryPictures
            ? dato.deliveryPictures
            : "",
          dato.exceptionGeoRef
            ? `${dato.exceptionGeoRef._lat} , ${dato.exceptionGeoRef._long}`
            : "",
          // dato.price,
        ]),
      ];

      const libro = XLSX.utils.book_new();
      const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
      XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

      XLSX.writeFile(
        libro,
        `Reporte de Operacion Bicci ( CTT BICCI )  ${Moment(Date.now()).format(
          "YYYY-MM-DD hh:mm:ss"
        )}.xlsx`
      );
    }
    await addDataRidersDataByCompany(data, ridersDataByCompany);
  }
};

export default generarReporteBicciDetalle;
