import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { db, firebase } from "../../../firebase";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import { SketchPicker } from "react-color";

import { Form, Input, Grid } from "semantic-ui-react";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { formatRelative } from "date-fns";
import { getPolygonLocalData } from "../../../redux/getUpdatePolygons";
import "@reach/combobox/styles.css";
import mapStyles from "../../../components/MapsGoogle/mapStyles";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import useGlobalConfig from "../../../hooks/globalConfig";
import ListGroup from "react-bootstrap/ListGroup";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

const libraries = ["drawing"];
const mapContainerStyle = {
  height: "70vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};

const markerBytext = {
  lat: -33.448891,
  lng: -70.669266,
  time: new Date(),
};

export default function MapsDrawingManager(props) {
  const dispatch = useDispatch();
  const { comunasSantiago } = useGlobalConfig();
  const [colorFill, setColor] = useState("#591e8f");
  const [polygonName, setPolygonName] = useState("");
  const [listComunas, setListComunas] = useState([]);
  const [polygonLocality, setPolygonLocality] = useState("noseletc");
  const [polygonType, setPolygonType] = useState("noseletc");
  const [polygonId, setPolygonId] = useState("");
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(props.companyId);

  const [polygonsBase, setPolygonsBase] = useState([]);
  const [editList, setEditList] = useState([]);

  const [Microzaonas, setMicrozonas] = useState([]);
  const [editListMicroZonas, setEditListMicroZonas] = useState([]);

  React.useEffect(() => {
    setCompanyId(props.companyId);
    console.log(props.dataPoligons.polygonsbase);
    setPolygonsBase(props.dataPoligons.polygonsbase);
    setEditList(
      props.dataPoligons.polygonsbase.filter((item) => item.isVisible === true)
    );
    setMicrozonas(props.dataPoligons.polygonType);
    setEditListMicroZonas(
      props.dataPoligons.polygonType.filter((item) => item.isVisible === true)
    );
  }, [props]);

  React.useEffect(() => {
    console.log(comunasSantiago);
    if (comunasSantiago) {
      var arr = Object.entries(comunasSantiago);
      setListComunas(arr);
      console.log(arr);
    }
  }, []);

  const google = window.google;

  const mapRef = React.useRef();

  const polygonRef = React.useRef(null);

  const listenersRef = React.useRef([]);

  const [path, setPath] = React.useState([]);

  const [showPicker, setShowPicker] = useState(false);

  const handleColorChangeComplete = (color) => {
    setColor(color.hex);

    setPolygonOptions({ ...polygonOptions, fillColor: color.hex });

    if (polygonRef.current) {
      polygonRef.current.setOptions(polygonOptions);
    }
  };

  const onEdit = React.useCallback(() => {
    console.log("The path state is", path);
    console.log(polygonRef.current);
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      console.log(path);
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const [polygonOptions, setPolygonOptions] = React.useState({
    editable: true,
    draggable: true,
    onMouseUp: { onEdit },
    onDragEnd: { onEdit },
    onLoad: { onLoad },
    onUnmount: { onUnmount },
    strokeWeight: 1,
    fillOpacity: 0.3,
    fillColor: colorFill,
    strokeColor: colorFill,
    strokeOpacity: 0.8,
  });

  console.log("The path state is", path);
  console.log("The state is", polygonOptions);

  const chekPoinyt = async () => {
    const polygonBicci = new google.maps.Polygon({ paths: path });
    if (
      google.maps.geometry.poly.containsLocation(
        { lat: -33.413833, lng: -70.5927821 },
        polygonBicci
      )
    ) {
      console.log(
        google.maps.geometry.poly.containsLocation(
          { lat: -33.413833, lng: -70.5927821 },
          polygonBicci
        )
      );
      console.log("el Geopunto esta dentro del poligono");
    } else {
      console.log(
        google.maps.geometry.poly.containsLocation(
          { lat: -33.413833, lng: -70.5927821 },
          polygonBicci
        )
      );
      console.log("el Geopunto esta fuera de perimetro del poligono");
    }
  };

  const donwLoadPolygons = () => {
    dispatch(
      getPolygonLocalData(companyId, null, polygonLocality, polygonType)
    );
    props.setTriger();
  };

  const onPolygonComplete = (polygon) => {
    polygonRef.current = polygon;
    console.log("polygoncomplete", polygon);
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    console.log(newCoordinates);
    setPath(newCoordinates);
    polygon.setDraggable(true);
    polygon.setEditable(true);
    polygon.addListener("click", getdata);

    // Event used when manipulating and adding points
    polygon.addListener("mouseup", onEdit);
    //           // Event used when dragging the whole Polygon
    polygon.addListener("dragend", onEdit);
    polygon.addListener("onLoad", onLoad);
    polygon.addListener("onUnmount", onUnmount);
  };

  function getdata() {
    //polygon.addPolygon(polygonOptions);
    setPolygonOptions({ ...polygonOptions, fillColor: colorFill.hex });
  }

  const cambiarColor = () => {
    setShowPicker(!showPicker);
  };
  //https://stackoverflow.com/questions/65639499/how-to-change-the-control-position-of-the-drawing-manager-with-the-react-google
  const handleChangeFormControl = (e) => {
    console.log(e);
    setPolygonLocality(e);
  };
  const handleChangeFormControlType = (e) => {
    console.log(e);
    setPolygonType(e);
  };

  const savePolygons = async (e) => {
    e.preventDefault();
    if (!polygonName.trim()) {
      setError("Seleccione Nombre");
      return;
    } else {
      console.log("polygonName");
    }
    if (!colorFill.trim()) {
      setError("Seleccione Color");
      return;
    } else {
      console.log("colorFill");
    }
    if (!polygonLocality.trim()) {
      setError("Seleccione Localidad");
      return;
    } else {
      console.log("polygonLocality");
    }
    if (!polygonType === "noseletc") {
      setError("Seleccione Tipo");
      return;
    } else {
      console.log("polygonType");
    }
    if (path.length <= 0) {
      setError("Dibuje un poligono");
      return;
    } else {
      console.log(path);
    }
    //if(!polygonId.trim()){setError('jejej ID'); return}else{console.log('polygonId') }
    setError(null);
    console.log("Guardar polygono");
    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    var newPolygon = {
      coordinates: path,
      fill: colorFill,
      fillOpacity: polygonRef.current.fillOpacity,
      polygonName: polygonName,
      strokeColor: polygonRef.current.strokeColor,
      strokeOpacity: polygonRef.current.strokeOpacity,
      strokeWidth: polygonRef.current.strokeWeight,
      polygonLocality: polygonLocality,
      polygonType: polygonType,
      companyId: companyId,
      isVisible: true,
      id:
        removeAccents(
          polygonLocality.slice(0, 5).toUpperCase().replace(/ /g, "")
        ) +
        "POLYG" +
        Math.random().toString(16).slice(2, 8).toUpperCase(),
    };
    console.log(newPolygon);
    try {
      await db
        .collection(`${process.env.REACT_APP_COL_POLYGONS}`)
        .doc(newPolygon.id)
        .set(newPolygon);
      dispatch(
        getPolygonLocalData(companyId, null, polygonLocality, polygonType)
      );
      props.setTriger();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeItemViewCapas = async (e) => {
    const BdList = polygonsBase;
    for (let i = 0; i < BdList.length; i++) {
      if (e.currentTarget.id === BdList[i].id) {
        BdList[i].isVisible = e.currentTarget.checked;
      }
    }
    await db
      .collection(`${process.env.REACT_APP_COL_POLYGONS}`)
      .doc(e.currentTarget.id)
      .update({ isVisible: e.currentTarget.checked });
    const visibleList = BdList.filter((item) => item.isVisible === true);
    setEditList(visibleList);
  };

  const handleDeletedtemViewCapas = async (e) => {
    console.log(e.currentTarget.id);
    await db
      .collection(`${process.env.REACT_APP_COL_POLYGONS}`)
      .doc(e.currentTarget.id)
      .delete();
    dispatch(
      getPolygonLocalData(companyId, null, polygonLocality, polygonType)
    );
    props.setTriger();
  };

  const handleChangeItemViewCapasMicroZonas = async (e) => {
    const BdList = Microzaonas;
    for (let i = 0; i < BdList.length; i++) {
      if (e.currentTarget.id === BdList[i].id) {
        BdList[i].isVisible = e.currentTarget.checked;
      }
    }
    await db
      .collection(`${process.env.REACT_APP_COL_POLYGONS}`)
      .doc(e.currentTarget.id)
      .update({ isVisible: e.currentTarget.checked });
    const visibleListMZ = BdList.filter((item) => item.isVisible === true);
    setEditListMicroZonas(visibleListMZ);
  };

  console.log("editListMicroZonas");
  console.log(editListMicroZonas);
  console.log("editList");
  console.log(editList);
  console.log("polygonsBase");
  console.log(polygonsBase);
  console.log("polygonLocality");
  console.log(polygonLocality);
  console.log("Microzaonas");
  console.log(Microzaonas);

  const handleDeletedtemViewCapasMicroZonas = async (e) => {
    console.log(e.currentTarget.id);
    await db
      .collection(`${process.env.REACT_APP_COL_POLYGONS}`)
      .doc(e.currentTarget.id)
      .delete();
    dispatch(
      getPolygonLocalData(companyId, null, polygonLocality, polygonType)
    );
    props.setTriger();
  };
  return (
    <div>
      <Grid celled="internally" id="expresscuentaContent">
        <Grid.Row>
          <Grid.Column
            computer={5}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <div id="mapsControl">
              {/* <button className="m-2 btn btn-primary btn-bicci" onClick={donwLoadPolygons}> Actualizar capas</button> */}

              {error ? (
                <span className="alert alert-danger">{error}</span>
              ) : null}
              <div className="panel-horizontal">
                <Form onSubmit={savePolygons} className="ajusted">
                  <Form.Field
                    control={Input}
                    label="Nombre del Poligono"
                    placeholder="Nombre de micro zona dentro de la comuna"
                    type="text"
                    onChange={(e) => setPolygonName(e.target.value)}
                    value={polygonName}
                  />

                  <Form.Group widths="equal">
                    <div className="input-group ">
                      <span className="select-standar">
                        Seleccione una comuna como macro zona
                      </span>

                      <select
                        id="custom-selectFrom-comuna"
                        control="select"
                        onChange={(e) =>
                          handleChangeFormControl(e.target.value)
                        }
                      >
                        <option> Seleccione una localidad... </option>
                        {listComunas.map((item) => (
                          <option key={item[0]} value={item[1]}>
                            {" "}
                            {item[1]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <div className="input-group ">
                      <span className="select-standar">
                        Seleccione un tipo de poligono
                      </span>
                      <select
                        id="custom-selectFrom-comuna"
                        control="select"
                        onChange={(e) =>
                          handleChangeFormControlType(e.target.value)
                        }
                      >
                        <option> Segun el tipo de poligono... </option>
                        <option
                          key={"PODestinationsLMAS"}
                          value={"PODestinationsLMAS"}
                        >
                          Polygons Last Mile Available Destinations Service
                        </option>
                        <option key={"polygonBase"} value={"PolygonBase"}>
                          Polygon Guía
                        </option>
                        {/* 
                          <option key={'PODestinationsLMRS'} value={'PODestinationsLMRS'}>
                          Polygons Last Mile Restrictions Destinations Service
                          </option>

                          <option key={'PODestinationsFoodAS'} value={'PODestinationsFoodAS'}>
                          Delivery Food Available Destinations Service
                          </option>

                          <option key={'PODestinationsFoodRS'} value={'PODestinationsFoodRS'}>
                          Delivery Food Restrictions Destinations Service
                          </option>

                          <option key={'PODestinationsExpressAS'} value={'PODestinationsExpressAS'}>
                          Delivery Express Available Destinations Service
                          </option>

                          <option key={'PODestinationsExpressRS'} value={'PODestinationsExpressRS'}>
                          Delivery Express Restrictions Destinations Service
                          </option> 
                          */}
                      </select>
                    </div>
                  </Form.Group>
                </Form>
                <button
                  className="m-2 btn btn-primary btn-bicci"
                  onClick={savePolygons}
                >
                  Guardar Poligono
                </button>
                <button
                  className="m-2 btn btn-primary btn-bicci"
                  onClick={cambiarColor}
                >
                  {" "}
                  {showPicker ? "Ocultar Colores" : "Colores"}
                </button>
                {showPicker ? (
                  <span className="float-picker">
                    <SketchPicker
                      color={colorFill}
                      onChangeComplete={handleColorChangeComplete}
                    />
                  </span>
                ) : null}

                <div className="panel-vertical-lateral">
                  <div>Microzonas:</div>

                  {Microzaonas ? (
                    <>
                      {Microzaonas.length > 0 ? (
                        <>
                          <ListGroup>
                            {Microzaonas.map((item) => (
                              <ListGroup.Item
                                className="justifcContent"
                                key={item.id}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    defaultChecked={item.isVisible}
                                    id={item.id}
                                    name={item.id}
                                    onChange={
                                      handleChangeItemViewCapasMicroZonas
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span>{item.polygonName} </span>
                                <Chip
                                  onClick={handleDeletedtemViewCapasMicroZonas}
                                  id={item.id}
                                  value={item.id}
                                  icon={
                                    <DeleteIcon
                                      style={{
                                        color: "#d5182b",
                                        backgroundColor: "#fff",
                                        textAlign: "center",
                                      }}
                                    />
                                  }
                                  style={{
                                    color: "#d5182b",
                                    backgroundColor: "#fff",
                                    textAlign: "center",
                                    float: "right",
                                  }}
                                />
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </>
                      ) : (
                        <div>No Data</div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div></div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={11}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <div className="panel-vertical">
              <div>Capas Guías:</div>

              {polygonsBase ? (
                <>
                  {polygonsBase.length > 0 ? (
                    <>
                      <ListGroup>
                        {polygonsBase.map((item) => (
                          <ListGroup.Item
                            className="justifcContent"
                            key={item.id}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={item.isVisible}
                                id={item.id}
                                name={item.id}
                                onChange={handleChangeItemViewCapas}
                              />
                              <span className="slider round"></span>
                            </label>
                            <span>{item.polygonName} </span>
                            <Chip
                              onClick={handleDeletedtemViewCapas}
                              id={item.id}
                              value={item.id}
                              icon={
                                <DeleteIcon
                                  style={{
                                    color: "#d5182b",
                                    backgroundColor: "#fff",
                                    textAlign: "center",
                                  }}
                                />
                              }
                              style={{
                                color: "#d5182b",
                                backgroundColor: "#fff",
                                textAlign: "center",
                                float: "right",
                              }}
                            />
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </>
                  ) : (
                    <div>No Data</div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            <GoogleMap
              id="mapControl"
              mapContainerStyle={mapContainerStyle}
              zoom={11}
              center={center}
              options={options}
            >
              <DrawingManager
                // onLoad={onLoad}
                defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                onPolygonComplete={onPolygonComplete}
                onOverlayComplete={(...args) =>
                  console.log("=== onOverlayComplete ===", ...args)
                }
                onCircleComplete={(...args) =>
                  console.log("=== onCircleComplete ===", ...args)
                }
                onMarkerComplete={(...args) =>
                  console.log("=== onMarkerComplete ===", ...args)
                }
                onRectangleComplete={(...args) =>
                  console.log("=== onRectangleComplete ===", ...args)
                }
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                      // google.maps.drawing.OverlayType.CIRCLE,
                      // google.maps.drawing.OverlayType.RECTANGLE,
                      google.maps.drawing.OverlayType.POLYGON,
                    ],
                  },
                  polygonOptions: polygonOptions,
                }}

                //https://github.com/tomchentw/react-google-maps/issues/796

                //Colores a Polygonos de adta
              ></DrawingManager>
              {editList ? (
                <>
                  {editList.map((item) => (
                    <div key={item.id}>
                      <Polygon
                        path={item.coordinates}
                        // editable={true}
                        // draggable={true}
                        // onMouseUp={onEdit}
                        // onDragEnd={onEdit}
                        // onLoad={onLoad}
                        // onUnmount={onUnmount}

                        options={{
                          fillColor: item.fill,
                          fillOpacity: 0.2, //item.fillOpacity,
                          strokeColor: item.strokeColor,
                          strokeOpacity: 0.3, //item.strokeOpacity,
                          strokeWeight: 1,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}

              {editListMicroZonas ? (
                <>
                  {editListMicroZonas.map((item) => (
                    <div key={item.id}>
                      <Polygon
                        path={item.coordinates}
                        editable={false}
                        draggable={false}
                        //  onMouseUp={onEdit}
                        //  onDragEnd={onEdit}
                        //  onLoad={onLoad}
                        //  onUnmount={onUnmount}

                        options={{
                          fillColor: item.fill,
                          fillOpacity: 0.4, //item.fillOpacity,
                          strokeColor: item.strokeColor,
                          strokeOpacity: 0.4, //item.strokeOpacity,
                          strokeWeight: 1,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </GoogleMap>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
