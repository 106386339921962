import { db } from "../firebase";
import { collection, where, query, getDocs } from "firebase/firestore";

const dataInicial = {
  polygonsLoading: false,
  polygonsLastUpdate: false,
  bicciPolygons: null,
  bicciPolygonsBase: null,
  bicciPolygonsByLocality: null,
};

const LOADING_POLYGONS = "LOADING_POLYGONS";
const UPDATE_POLYGONS = "UPDATE_POLYGONS";
const ROMEVE_POLYGONS = "ROMEVE_POLYGONS";
const ERROR_UPDATE_POLYGONS = "ERROR_UPDATE_POLYGONS";

export default function updatePolygonLocalData(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_POLYGONS:
      return { ...state, polygonsLoading: true };
    case UPDATE_POLYGONS:
      return { ...state, polygonsLoading: false };
    case ROMEVE_POLYGONS:
      return { ...state, polygonsLoading: false };
    case ERROR_UPDATE_POLYGONS:
      return { ...state, polygonsLoading: false };
    default:
      return state;
  }
}

export const getPolygonLocalData =
  (companyId, userRoll, polygonsLocate, polygonType) => async (dispatch) => {
    //  console.log(companyId);
    //  console.log(userRoll);
    //  console.log(polygonsLocate);
    //  console.log(polygonType);

    dispatch({
      type: LOADING_POLYGONS,
    });

    try {
      // ERROR AL CARGA VARIABLES VACIAS

      if (companyId && polygonsLocate && polygonType) {
        const allPolygonsRef = collection(
          db,
          process.env.REACT_APP_COL_POLYGONS
        );

        const q = query(
          allPolygonsRef,
          where("companyId", "==", companyId),
          where("polygonType", "!=", "PolygonBase")
        );
        const dataallPolygonsfind = await getDocs(q);
        var dataPolygons = dataallPolygonsfind.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const qa = query(
          allPolygonsRef,
          where("companyId", "==", companyId),
          where("polygonType", "==", "PolygonBase")
        );
        const dataallPolygonsfind2 = await getDocs(qa);
        var dataPolygonsBase = dataallPolygonsfind2.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        try {
          const qac = query(
            allPolygonsRef,
            where("companyId", "==", companyId),
            where("polygonsLocate", "==", polygonsLocate)
          );
          const dataallPolygonsfind3 = await getDocs(qac);
          var dataPolygonsLocate = dataallPolygonsfind3.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log('dataPolygonsLocate')
          // console.log(dataPolygonsLocate)
        } catch (error) {
          console.log(null);
        }
      } else {
        //console.log('null: dispacht null')
        //console.log(dataPolygons)
      }
      //console.log('UPDATE_POLYGONS')
      dispatch({
        type: UPDATE_POLYGONS,
        accion: {
          polygonType: dataPolygons,
          polygonsbase: dataPolygonsBase,
          polygonslocate: dataPolygonsLocate,
        },
      });
      localStorage.setItem(
        "BicciToolsClientZones",
        JSON.stringify({
          polygonType: dataPolygons,
          polygonsbase: dataPolygonsBase,
          polygonslocate: dataPolygonsLocate,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_UPDATE_POLYGONS,
      });
    }
  };

export const RemovePolygonLocalData = () => async (dispatch) => {
  localStorage.removeItem("BicciToolsClientZones");
  dispatch({
    type: ROMEVE_POLYGONS,
  });
};
