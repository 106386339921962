import React, { useEffect } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import mapStyles from "../../../../components/MapsGoogle/mapStyles";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

const libraries = ["places"];
const mapContainerStyle = {
  height: "50vh",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};

const center = {
  lat: -33.448891,
  lng: -70.669266,
};
const rosettaVehicles = {
  BICYCLING: "Bicicleta",
  VEHICLE: "Bicicleta",
  TWO_WHEELER: "Motorizado",
  DRIVING: "Vehículo",
  WALKING: "Caminante",
};

export default function DashRoutesAddMap(props) {
  const handleresposeapi = props.handleresposeapi;

  const [renderPoint, setRenderPoint] = React.useState(true);
  const toggleShowEdit = () => setRenderPoint(!renderPoint);
  const [responseService, setResponse] = React.useState(null);
  const [distancia, setDistancia] = React.useState(null);
  const [duracion, setdDuracion] = React.useState(null);
  const [routeOrder, setRouteOrder] = React.useState(null);
  const [routeItems, setRouteItems] = React.useState(null);
  const [travelMode, setTravelMode] = React.useState(null);
  const [requestDir, setRequestDir] = React.useState(false);

  const [markers, setMarkers] = React.useState("");
  const [markersPending, setMarkersPending] = React.useState(
    props.targetedordersrows
  );
  const [markersCancels, setMarkersCancels] = React.useState(
    props.targetedorderscancel
  );
  const [markersDelivery, setMarkersDelivery] = React.useState(
    props.targetedordersdelivered
  );
  const [selectedMarker, setSelectedMarker] = React.useState("");

  const mapRef = React.useRef();

  useEffect(() => {
    setSelectedMarker(props.markerpoint);
    setRequestDir(false);
  }, [props.waypoints]);

  useEffect(() => {
    //console.log(responseService)
  }, [props.handleresposeapi]);

  const getDataRoute = (routes, travelMode, response) => {
    //console.log(routes)
    var n = routes[0].legs.length;
    var distanceMtrsValue = 0;
    var distanceSegDuration = 0;
    for (let i = 0; i < n; i++) {
      distanceMtrsValue += routes[0].legs[i].distance.value;
      distanceSegDuration += routes[0].legs[i].duration.value;
    }
    setDistancia(distanceMtrsValue / 1000);
    setdDuracion(distanceSegDuration / 60);
    setRouteOrder(routes[0].waypoint_order);
    setRouteItems(routes[0].waypoint_order.length);
    setTravelMode(travelMode);
    handleresposeapi(response);
  };

  const directionsCallback = (response) => {
    //console.log(response)
    setResponse(response);
    if (requestDir !== true) {
      if (response) {
        setRequestDir(true);
        getDataRoute(response.routes, response.request.travelMode, response);
      }
    }
  };

  const onMapLoadRute = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const DirectionsServiceOption = (props = {
    origin: props.origin,
    destination: props.destination,
    travelMode: props.travelmodel,
    optimizeWaypoints: props.optimizewaypoints,
    waypoints: props.waypoints,
  });

  //console.log(DirectionsServiceOption)
  const Addrutes = () => {
    if (requestDir !== true) {
      return (
        <DirectionsService
          options={DirectionsServiceOption}
          callback={directionsCallback}
        />
      );
    } else return <></>;
  };

  return (
    <div>
      <div id="mapsControlRoutes">
        <div className="metricscontainer">
          <div className="boxmetrics">
            {travelMode ? (
              <div>
                Tipo de vehículo: <span>{rosettaVehicles[travelMode]}</span>
              </div>
            ) : (
              <></>
            )}

            {distancia ? <div>{distancia} KM</div> : <></>}
            {duracion ? <div>{duracion.toFixed(2)} Minutos</div> : <></>}

            {routeItems ? <div>{routeItems} Paquetes</div> : <></>}
          </div>
        </div>
      </div>
      <div>
        <GoogleMap
          id="mapControl"
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          options={options}
          onLoad={onMapLoadRute}
        >
          <Marker
            key={Math.random().toString()}
            position={props.destination}
            // onClick={() => {setSelectedMarker ({
            //   "id": "Punto BICCI",
            //   "toAddress": "Origen",
            //   "toAddressLocation": props.destination,
            //   "userName": "",
            //   "userPhone": "+56987654321",
            //   "status": '',
            //   "express": "",
            //});}}
            icon={{
              url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            key={Math.random().toString()}
            position={props.origin}
            //   onClick={() => {setSelectedMarker ({
            //     "id": "Punto BICCI",
            //     "toAddress": "Destino",
            //     "toAddressLocation": props.destination,
            //     "userName": "",
            //     "userPhone": "+56987654321",
            //     "status": '',
            //     "express": "",
            // });}}
            icon={{
              url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          {markersPending.length > 0 ? (
            <>
              {markersPending.map((marker) => (
                <Marker
                  key={Math.random().toString()}
                  position={{
                    lat: marker.toAddressLocation.latitude,
                    lng: marker.toAddressLocation.longitude,
                  }}
                  onClick={() => {
                    setSelectedMarker(marker);
                  }}
                  icon={{
                    url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-05.svg?alt=media&token=c9fef7b1-9ea9-424c-b6ab-d32954349719`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(50, 50),
                  }}
                />
              ))}
              {markersDelivery.map((marker) => (
                <Marker
                  key={Math.random().toString()}
                  position={{
                    lat: marker.toAddressLocation.latitude,
                    lng: marker.toAddressLocation.longitude,
                  }}
                  onClick={() => {
                    setSelectedMarker(marker);
                  }}
                  icon={{
                    url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-04.svg?alt=media&token=74c3e98e-8074-4ae9-80e1-df039f641149`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(50, 50),
                  }}
                />
              ))}
              {markersCancels.map((marker) => (
                <Marker
                  key={Math.random().toString()}
                  position={{
                    lat: marker.toAddressLocation.latitude,
                    lng: marker.toAddressLocation.longitude,
                  }}
                  onClick={() => {
                    setSelectedMarker(marker);
                  }}
                  icon={{
                    url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-03.svg?alt=media&token=33234a02-2bc7-44ed-be83-46d14ebbf910`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(50, 50),
                  }}
                />
              ))}
              {selectedMarker ? (
                <>
                  {
                    <InfoWindow
                      position={{
                        lat: selectedMarker.toAddressLocation.latitude,
                        lng: selectedMarker.toAddressLocation.longitude,
                      }}
                      onCloseClick={() => {
                        setSelectedMarker(null);
                      }}
                    >
                      <div>
                        <h5>ID: {selectedMarker.id}</h5>
                        <div>
                          <a
                            className="nonstyle"
                            href={`tel:${selectedMarker.userPhone}`}
                          >
                            Teléfono: {selectedMarker.userPhone}
                          </a>
                        </div>
                        <div>
                          <a
                            className="nonstyle"
                            href={`https://api.whatsapp.com/send?phone=${selectedMarker.userPhone}&text=Soporte%20BICCI%20:`}
                          >
                            WhatsApp: {selectedMarker.userPhone}
                          </a>
                        </div>
                        <div>Nombre : {selectedMarker.userName}</div>
                        <div>Destino : {selectedMarker.toAddress}</div>
                      </div>
                    </InfoWindow>
                  }
                </>
              ) : null}
            </>
          ) : (
            <></>
          )}

          {responseService !== null && requestDir === true ? (
            <DirectionsRenderer
              options={{
                directions: responseService,
                suppressMarkers: true,
                polylineOptions: {
                  strokeOpacity: 0.4,
                  strokeWeight: 4,
                  strokeColor: "#8c0af0",
                },
              }}
            ></DirectionsRenderer>
          ) : (
            <></>
          )}
          <Addrutes />
        </GoogleMap>
      </div>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button
      className="locate btn-primary m-1"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <ShareLocationIcon></ShareLocationIcon>Encuentrame
    </button>
  );
}
