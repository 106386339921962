import QRCode from "qrcode";

export const generateQRBase64 = async (text) => {
  try {
    const url = await QRCode.toDataURL(text, {
      width: 128,
      margin: 2,
    });
    return url;
  } catch (err) {
    console.error("Error generando código QR:", err);
    return null;
  }
};
