import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import useGetRoutesExpressById from "../../../../../hooks/getRoutesExpressById";
import useGetOrderIDExpress from "../../../../../hooks/getDataDispachst";
import { useEffect } from "react";
import useGetDataDispachst from "../../../../../hooks/getDataDispachstById";

export default function SwipeableTemporaryDrawer(props) {
  const [orderID, setOrderID] = React.useState("F149085070851");
  const [orderIdExist, setOrderIdExist] = React.useState(false);

  const {
    bicciOrdersExpressByID,
    loadOrdersExpressByID,
    errorOrdersExpressByID,
  } = useGetDataDispachst(props.companyId, props.LocalRol, orderID);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (bicciOrdersExpressByID) {
      //setOrderIdExist(props.orderIdExist);
    } else {
      setOrderIdExist(false);
    }
  }, [bicciOrdersExpressByID]);

  useEffect(() => {
    if (props && props.orderIdExist) {
      setOrderIdExist(props.orderIdExist);
    }
  }, [props && props.orderIdExist]);

  const list = (anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="m-2">
        <h2>Ingresa el ID y agrega el item</h2>
      </div>
      <Divider />
      <input
        className="m-2"
        onChange={(e) => setOrderID(e.target.value)}
        value={orderID}
      ></input>
      {bicciOrdersExpressByID ? (
        <>
          {" "}
          <button
            disabled={false}
            className="btn btn-ebiex m-2"
            onClick={() => props.selectAddOrders(bicciOrdersExpressByID)}
          >
            {" "}
            Agregar despacho{" "}
          </button>
        </>
      ) : (
        <>
          {" "}
          <button disabled={true} className=" m-2 Buttondisable">
            {" "}
            Agregar despacho{" "}
          </button>
        </>
      )}

      <div className="m-2">
        {bicciOrdersExpressByID ? (
          <>Encontrado {bicciOrdersExpressByID.id}</>
        ) : (
          "Sin resultados"
        )}
        {orderIdExist === true ? " Orden ya esta agregada" : null}
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button
          sx={{ color: "white", fontWeight: "600", fontSize: 15 }}
          className="btn btn-ebiex m-2"
          onClick={toggleDrawer("right", true)}
        >
          {"Agregar Paquete"}
        </Button>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
