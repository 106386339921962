import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import Bannerfooter from '../../../../../components/Bannerfooter/Bannerfooter'
import useGlobalConfig from '../../../../../hooks/globalConfig';
const Pos2 = () => {
  const {cobertura} = useGlobalConfig()
  const footermensaje = {
    className: '',
    title:  '',
    paragramh:''}
  return (
    <div>
    <Grid celled='internally'>
    <Grid.Row>
      <Grid.Column computer={16} mobile={16} tablet={16}>
        <div id="pagetitle"><h2>Acerca de nosotros</h2></div>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>

      <Grid.Column computer={8} mobile={16} tablet={6}>
        <Image src={cobertura} />
      </Grid.Column>
      <Grid.Column computer={8} mobile={16} tablet={10} id="paragraph1">
        <div><h2>¿QUIÉNES SOMOS?</h2>
        <span>Somos una empresa que nace desde la necesidad de ayudar a la comunidad y al medio ambiente. Promovemos la electromovilidad con nuestros vehículos. 
        Para el año 2020 formamos Bicci Delivery para dar un valor adicional al repartidor y al comercio, haciendo más eco-sustentable el despacho. 
        Esa experiencia y nuestra constante innovación nos hicieron dar un paso más y llegamos con BICCI EXPRESS, donde con tecnología innovadora y  más vehículos eco-sustentables vamos a forjar esta nueva vertical de negocio como una experiencia única y reconfortante para ustedes.
        </span>
        </div>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row>
    <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph2">
    <div><h2>NUESTRO EQUIPO</h2>
        <span>Nuestro equipo de reparto está conformado con personal calificado y capacitado para realizar el despacho de tus productos, con un alto nivel de compromiso y profesionalismo.  </span>
        </div>
      </Grid.Column>
      <Grid.Column computer={8} mobile={16} tablet={6}>
        <Image src='https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fphoto5%20copia.png?alt=media&token=1b557802-4dea-42b9-85af-97a58e3a0f2e' />
      </Grid.Column>
    </Grid.Row>

    
  </Grid> 
  <Bannerfooter footermensaje={footermensaje}/>
  </div>
  );
};

export default Pos2;
