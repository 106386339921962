import * as XLSX from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

const generateReportByData = async (data) => {
  if (data.length === 0) return;
  const headers = Object.keys(data[0]);
  const matrizReporte = [
    headers,
    ...data.map((item) =>
      headers.map((header) => {
        // Aquí puedes agregar cualquier lógica de transformación/formato
        let value = item[header];

        // Ejemplo de formato condicional para fechas o cualquier otro campo
        if (header === "timeStamp" && item[header]) {
          value = format(item[header].seconds * 1000, "dd/MM/yyyy");
        }

        // Retorna el valor transformado o directo
        return value ?? ""; // Usamos el operador nullish coalescing para manejar valores nulos o indefinidos
      })
    ),
  ];

  // Creación y descarga del libro
  const libro = XLSX.utils.book_new();
  const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
  XLSX.utils.book_append_sheet(libro, hoja, "DATA");

  XLSX.writeFile(
    libro,
    `Bicciexpress Reporte ${Moment(Date.now()).format(
      "YYYY-MM-DD hh:mm:ss"
    )}.xlsx`
  );
};

export default generateReportByData;
