import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useObtenerServiciosAcivos(array){

  const [servicios, setservicios] = useState(null)
  const [serviciosActivos, setServiciosActivos] = useState(null)
  const [serviciosContratados,setServiciosContratados] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(()=>{
    fetchData(array)
  },[array])


  const fetchData = async (value) => {
    try { //servicios Activos
        setLoading(true)
      const accountants =  await db.collection(`${process.env.REACT_APP_COL_AVIABLE_SERV}`)
      .get();
      const dataResult = accountants.docs.map(doc => ({id: doc.id, ...doc.data()}))
      setservicios(dataResult)
      
      //Macht Data filter(item => item.originPropietary === userUid)

      const dataActivos = dataResult.filter(item => item.isActive === true)
      setServiciosActivos(dataActivos)
      

      const ObjectdataContratados = Object.values(value)
      const dataContratados = ObjectdataContratados.filter(item => item.active === true)
        let detalleConntratados=[]


                for (let n = 0; n < dataContratados.length; n++) {

                    if ((dataActivos[n].isActive) && (dataContratados[n].active)) {
                       // console.log(dataActivos[n].name)
                        detalleConntratados.push(dataActivos[n])
                    }
                    
                }

      setServiciosContratados(detalleConntratados)
      setLoading(false)
    } catch (error) {
    //  console.log(error)
      setError(true)
      setLoading(false)
    }
  }

  return {serviciosActivos, serviciosContratados, servicios, loading, error}

}

export default useObtenerServiciosAcivos