import React from 'react';
import { Container } from 'semantic-ui-react';
import useTitle from '../../../components/Seo/useTitle';
import useSeo from '../../../components/Seo/useSeo';
import useGlobalConfig from '../../../hooks/globalConfig';
const Info = () => {
  const {siteName} = useGlobalConfig()
  useTitle({title:`${siteName} información`})
  useSeo({description:'Nuestro equipo de reparto está conformado con personal calificado y capacitado para realizar el despacho de tus productos, con un alto nivel de compromiso y profesionalismo.'})

  return (  
    <Container className='bodycontent'>
     <div>
    Info
    </div>
    </Container> 
    ); 
  };

export default Info;
