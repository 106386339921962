import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useGlobalConfig from "../hooks/globalConfig";
import { VolumeMute } from "@mui/icons-material";
import { ProgressBar } from "react-bootstrap";

const ValidationsSize = (props) => {
  const { tablaMedidasTarifas } = useGlobalConfig();
  let validationAlert = null;
  let alertSize = null;
  let alertWeight = null;

  // console.log(props);
  //console.log(tablaMedidasTarifas);
  let volumen = (props.width * props.height * props.length).toFixed(3);
  //console.log(parseFloat(volumen));
  let totalVolumen = parseFloat(volumen) * props.units;
  //console.log(parseFloat(totalVolumen));
  // if (parseFloat(totalVolumen) !== parseFloat(volumen)) {
  //   console.log(props.id);
  //   console.log("Alerta de volumen en grilla no es correcto");
  // }

  //validar si su  medida es correcta volumen y pertenece al tamaño del paquete en tabla

  //validar si su  peso es correcto y pertenece al tamaño del paquete en tabla

  let peso = props.weight;
  let totalPeso = parseFloat(peso) * props.units;
  // if (parseFloat(totalPeso) !== parseFloat(peso)) {
  //   console.log(props.id);
  //   console.log("Alerta de Peso en grilla no es correcto");
  // }

  //validar si alguno de sus lados sobresale del tamaño paquete en tabla

  //validar si su volumen final pertenece al tamaño del paquete en tabla

  //validar si su peso final pertenece al tamaño del paquete en tabla

  let resultMedidaTMS = null; // Variable para almacenar la medidaTMS correspondiente
  let pagoEsperadoTarifa = null; // Variable para almacenar la medidaTMS correspondiente

  let resultPesoTMS = null; // Variable para almacenar la medidaTMS correspondiente
  let pagoEsperadoPorPesoTarifa = null; // Variable para almacenar la medidaTMS correspondiente
  let positionTMS = null;
  let positionSDD = null;

  for (let i = 0; i < tablaMedidasTarifas.length; i++) {
    const objeto = tablaMedidasTarifas[i];
    const { m3Menor, m3Mayor, medidaTMS, tarifa } = objeto;

    if (
      parseFloat(totalVolumen) >= m3Menor &&
      parseFloat(totalVolumen) <= m3Mayor
    ) {
      resultMedidaTMS = medidaTMS;
      pagoEsperadoTarifa = tarifa;
      break; // Salir del bucle una vez que se encuentre la medidaTMS correspondiente
    }
  }

  for (let i = 0; i < tablaMedidasTarifas.length; i++) {
    const objeto = tablaMedidasTarifas[i];
    const { medidaTMS, tarifa, pesoKg, pesoKglast, orden } = objeto;
    if (parseFloat(totalPeso * 1000) <= pesoKg * 1000) {
      if (
        parseFloat(totalPeso * 1000) >= pesoKglast * 1000 &&
        parseFloat(totalPeso * 1000) <= pesoKg * 1000
      ) {
        // console.log("ORDEN :", props.id);
        // console.log("Peso PAQUETE", totalPeso * 1000);
        // console.log("Peso minimon", pesoKglast * 1000);
        // console.log("Peso maximo", pesoKg * 1000);
        resultPesoTMS = medidaTMS;
        pagoEsperadoPorPesoTarifa = tarifa;
        positionTMS = orden;
      }
    }
    if (medidaTMS === props.packeSize) {
      positionSDD = orden; // Guardar la posición del objeto encontrado
    }
  }

  if (resultMedidaTMS !== props.packeSize) {
    // console.log("----- VOL -----");
    // console.log("ORDEN :", props.id);
    // console.log("volumen Incorrecto");
    // console.log("Tamaño en Grilla: ", props.packeSize);
    // console.log("Tamaño en Tabla TMS corresponde: ", resultMedidaTMS);
    // console.log("Tarifa esperada", resultMedidaTMS);
    // console.log("Pago esperado en tarifa", pagoEsperadoTarifa);
    // setValidationAlert(true);
    validationAlert = true;
    alertSize = `${resultMedidaTMS} Vol es: ${totalVolumen}`;
  }
  if (positionTMS > positionSDD) {
    if (resultPesoTMS !== props.packeSize) {
      // console.log("---- PESO ----");
      // console.log("ORDEN :", props.id);
      // console.log("Peso Incorrecto");
      // console.log("Peso en Grilla: ", props.packeSize);
      // console.log("Peso en positionSDD: ", positionSDD);
      // console.log("Peso en Tabla TMS corresponde: ", resultPesoTMS);
      // console.log("Peso en positionTMS: ", positionTMS);
      // console.log("Tarifa esperada", resultPesoTMS);
      // console.log("Pago esperado en tarifa", pagoEsperadoPorPesoTarifa);
      // setValidationAlert(true);
      validationAlert = true;
      alertWeight = `Peso es: ${resultPesoTMS}`;
    }
  }
  return validationAlert ? (
    <div>
      {validationAlert === false ? (
        <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon>
      ) : (
        <>
          <ul className="padding-left-0">
            {alertSize ? (
              <li className="listNone">
                <ReportIcon style={{ color: "red" }}></ReportIcon>
                <span>{alertSize}</span>
              </li>
            ) : null}
            {/* {alertWeight ? (
              <li className="listNone">
                <ReportIcon style={{ color: "red" }}></ReportIcon>
                <span>{alertWeight}</span>
              </li>
            ) : null} */}
          </ul>
        </>
      )}
    </div>
  ) : (
    <>
      <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon>{" "}
      <span>Vol Ok {resultMedidaTMS}</span>
    </>
  );
};

export default ValidationsSize;
