import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  TextField,
  Switch,
  IconButton,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import LinearProgress from "@mui/material/LinearProgress";
import { fetcherRoles } from "../../../../utils/FectcherData/FectcherUser";

const AdminsCompaniesEdith = () => {
  const { id } = useParams();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openNewPasswordDialog, setOpenNewPasswordDialog] = useState(false);
  const [user, setUser] = useState(null);
  const [rolesOptions, setRolesOptiones] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [mensajesWeb, setMensajesWeb] = useState(
    user?.mensajesWeb ? user.mensajesWeb : false
  );
  const [userActive, setUserActive] = useState(
    user?.userActive ? user.userActive : false
  );

  useEffect(() => {
    if (id) {
      const fetchUser = async () => {
        try {
          const userData = await getUserById(id);
          console.log(user);
          setUser(userData);
        } catch (error) {
          console.error("Error al obtener el usuario:", error);
        }
      };
      fetchUser();
      getData();
    }
  }, [id]);

  const getData = async () => {
    const roles = await fetcherRoles();
    const options = roles.map((role) => {
      return { value: role.id, label: role.id };
    });
    setRolesOptiones(options);
  };

  const getUserById = async (id) => {
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, id);
      let documentUser = await getDoc(docRef);
      return documentUser.data();
    } catch (error) {
      console.log(error);
      return "userNotFound";
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setUser({ ...user, [name]: checked });
  };

  const handleSave = () => {
    // Aquí iría la lógica para guardar los cambios
    console.log("Datos guardados:", user);
    setIsEditing(false);
  };

  const handleMensajesWebChange = (event) => {
    setMensajesWeb(event.target.checked);
  };

  const handleUserActiveChange = (event) => {
    setUserActive(event.target.checked);
  };

  const handleResetPassword = async (email) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Correo de restablecimiento enviado.");
      setOpenResetDialog(false);
      // Opcional: muestra una notificación de éxito al usuario
    } catch (error) {
      console.error("Error al enviar el correo de restablecimiento:", error);
      // Opcional: maneja errores, como usuario no encontrado o problemas de red
    }
  };

  const handleNewPassword = async (uid, newPassword) => {
    const auth = getAuth();
    try {
      await auth.updateUser(uid, {
        password: newPassword,
      });
      console.log("Contraseña actualizada con éxito.");
      setOpenNewPasswordDialog(false);
    } catch (error) {
      console.error("Error al actualizar la contraseña:", error);
    }
  };

  console.log(rolesOptions);

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      {user ? (
        <Box sx={{ backgroundColor: "#f5f5f5", padding: "20px" }}>
          <Grid container spacing={2}>
            {/* Avatar y detalles del usuario */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar
                        src={user.avatar || ""}
                        alt={user.displayName || ""}
                        sx={{ width: 64, height: 64 }}
                      />
                    </Grid>
                    <Grid item>
                      {isEditing ? (
                        <>
                          <TextField
                            label="Nombre"
                            name="displayName"
                            value={user.displayName || ""}
                            onChange={handleChange}
                            fullWidth
                            sx={{ m: 1 }}
                          />
                          <TextField
                            label="Correo Electrónico"
                            name="email"
                            value={user.email || ""}
                            onChange={handleChange}
                            fullWidth
                            sx={{ m: 1 }}
                          />
                          <TextField
                            label="Teléfono"
                            name="userPhone"
                            value={user.userPhone || ""}
                            onChange={handleChange}
                            fullWidth
                            sx={{ m: 1 }}
                          />
                        </>
                      ) : (
                        <>
                          <Typography variant="h6">
                            {user.displayName || ""}
                          </Typography>
                          <Typography variant="body2">
                            {user.email || ""}
                          </Typography>
                          <Typography variant="body2">
                            Teléfono: {user.userPhone || ""}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Información de la Empresa */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    Información de la Empresa
                  </Typography>
                  {isEditing ? (
                    <>
                      <TextField
                        label="Compañía"
                        name="userCompany"
                        value={user.userCompany || ""}
                        onChange={handleChange}
                        fullWidth
                        sx={{ m: 1 }}
                      />
                      <TextField
                        label="RUT"
                        name="userCompanyID"
                        value={user.userCompanyID || ""}
                        onChange={handleChange}
                        fullWidth
                        sx={{ m: 1 }}
                      />
                      <TextField
                        label="Rol"
                        name="userCompanyRoll"
                        value={user.userCompanyRoll || ""}
                        onChange={handleChange}
                        fullWidth
                        sx={{ m: 1 }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body2">
                        Compañía: {user.userCompany || ""}
                      </Typography>
                      <Typography variant="body2">
                        RUT: {user.userCompanyID || ""}
                      </Typography>
                      <Typography variant="body2">
                        Rol: {user.userCompanyRoll || ""}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Preferencias */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Preferencias</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2">Mensajes Web</Typography>
                      <Switch
                        checked={user.mensajesWeb || false}
                        onChange={handleSwitchChange}
                        name="mensajesWeb"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Usuario Activo</Typography>
                      <Switch
                        checked={user.userActive || false}
                        onChange={handleSwitchChange}
                        name="userActive"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Rol del usuario */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Rol del Usuario</Typography>
                  {isEditing ? (
                    <Select
                      label="Rol"
                      name="userType"
                      value={user.userType || ""}
                      onChange={handleChange}
                      fullWidth
                      sx={{ m: 1 }}
                    >
                      {rolesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography variant="body2">
                      Rol: {user.userType || ""}
                    </Typography>
                  )}
                  <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenResetDialog(true)}
                      sx={{ marginRight: 1 }}
                    >
                      Restablecer contraseña (Email)
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenNewPasswordDialog(true)}
                    >
                      Crear nueva contraseña
                    </Button>
                  </Box>
                </CardContent>
                {/* Diálogo para restablecer contraseña */}
                <Dialog
                  open={openResetDialog}
                  onClose={() => setOpenResetDialog(false)}
                >
                  <DialogTitle>Restablecer contraseña</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      ¿Seguro que deseas enviar un correo de restablecimiento de
                      contraseña?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenResetDialog(false)}>
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => handleResetPassword(user.email)}
                      color="primary"
                    >
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Diálogo para crear nueva contraseña */}
                <Dialog
                  open={openNewPasswordDialog}
                  onClose={() => setOpenNewPasswordDialog(false)}
                >
                  <DialogTitle>Establecer nueva contraseña</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Nueva Contraseña"
                      type="password"
                      fullWidth
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenNewPasswordDialog(false)}>
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => handleNewPassword(user.uid, newPassword)}
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Card>
            </Grid>

            {/* Datos personales */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Datos Personales</Typography>
                  {isEditing ? (
                    <>
                      <TextField
                        label="Nombre Completo"
                        name="username"
                        value={user.username || ""}
                        onChange={handleChange}
                        fullWidth
                        sx={{ m: 1 }}
                      />
                      <TextField
                        label="RUT"
                        name="userRut"
                        value={user.userRut || ""}
                        onChange={handleChange}
                        fullWidth
                        sx={{ m: 1 }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body2">
                        Nombre Completo: {user.username || ""}
                      </Typography>
                      <Typography variant="body2">
                        RUT: {user.userRut || ""}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Botón de editar o guardar */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Opciones</Typography>
                  <IconButton
                    aria-label="editar"
                    onClick={handleEditToggle}
                    sx={{ color: "#1e2158" }}
                  >
                    {isEditing ? (
                      <SaveIcon />
                    ) : (
                      <>
                        <EditIcon />
                        <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                          Editar
                        </Typography>
                      </>
                    )}
                  </IconButton>
                  {isEditing && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginLeft: "10px" }}
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setIsEditing(false)}
                        sx={{ backgroundColor: "red", marginLeft: "10px" }}
                      >
                        Cancelar
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default AdminsCompaniesEdith;
