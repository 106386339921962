import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const GraficasChart = (props) => {
  // console.log(props);
  let colors = [
    "#28cd71",
    "#41c5ed",
    "#591e8f",
    "#0219EB",
    "#02F5B8",
    "#87DE0D",
    "#F5A802",
    "#EB867A",
    "#BC5DEB",
    "#E302EB",
  ];
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const labels = [];
  const dataValues = [];
  const borderColor = [];
  const backgroundColor = [];

  for (let i = 0; i < props.dataHistorial.length; i++) {
    const mesNombre = props.dataHistorial[i].month;
    const cant = props.dataHistorial[i].cant;

    labels.push(mesNombre);
    dataValues.push(cant);
    borderColor.push(`255,255,255`);
    backgroundColor.push(colors[getRandomInt(0, 9)]);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: props.name,
        data: dataValues,
        fill: false,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        tension: 0.1,
      },
    ],
  };

  return <Doughnut data={data} />;
};

export default GraficasChart;
