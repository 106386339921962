import React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const LastMileAPI = () => {
  return (
    <div id="last-mile-api">
      <h1>API Ultima Milla</h1>

      <div>
        <h2>Descripción General</h2>
        <p>
          Este diagrama de flujo ilustra el proceso completo desde la generación
          del token hasta la entrega del pedido, incluyendo los estados
          intermedios y los posibles subestados en caso de retraso o cancelación
          de la entrega.
        </p>

        <img
          src={`https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fdiagrama%20de%20flujo%20ultima%20milla.png?alt=media&token=03c8a86c-ef47-457e-bcf1-8bca16ef2cd4`}
          alt={"diagrama ultima milla"}
          loading="lazy"
          style={{ maxWidth: "100%" }}
        />

        <h3>Pasos del Proceso:</h3>
        <h4>Generación de Token:</h4>
        <p>
          <strong>Descripción:</strong> Se solicita un token de seguridad a
          través del endpoint <code>ebiexGenerateCompanyToken</code>.<br />
          <strong>Propósito:</strong> Este token se utilizará para autenticar
          las solicitudes API subsecuentes.
        </p>

        <Box p={1}>
          <Typography variant="body" gutterBottom sx={{ fontWeight: 600 }}>
            API Request Example
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Tipo de Dato</TableCell>
                  <TableCell>Ejemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell colSpan={2}>
                    https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexGenerateCompanyToken
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell colSpan={2}>POST</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content-Type</TableCell>
                  <TableCell colSpan={2}>application/json</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Parámetros en Body</TableCell>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>"BICCIEXPRESS"</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>companyID</TableCell>
                  <TableCell>String obligatorio</TableCell>
                  <TableCell>"FroY2jsadjhsakdkaJHJA"</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" gutterBottom>
            Ejemplo del Body Tipo de datos: JSON
          </Typography>
          <Paper>
            <Box p={2}>
              <pre>
                {`
{
  "name": "BICCIEXPRESS",
  "companyID": "FroY2jsadjhsakdkaJHJA"
}
`}
              </pre>
            </Box>
          </Paper>

          <Typography variant="h5" gutterBottom>
            Ejemplo de Respuesta
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Respuesta Correcta</TableCell>
                  <TableCell>
                    200 OK, mensaje: Token generado con éxito. El token estará
                    en la variable <code>token</code>.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    400 Bad Request: datos incorrectos o faltantes.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ejemplo de Respuesta</TableCell>
                  <TableCell colSpan={2}>
                    <pre>
                      {`
{
  "result": {
    "code": 200,
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    "message": "Token generado con éxito"
  }
}
`}
                    </pre>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <div></div>

        <Box mt={3}>
          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Solicitud de Orden:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Se crea una orden utilizando el
            endpoint <code>ebiexCreateOrdersLastMille</code> o{" "}
            <code>ebiexImportOrdersLastMille</code> para importaciones masivas.
            <br />
            <strong>Estado Inicial:</strong> BICCI estado (0)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Orden Creada:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Una vez que la orden es creada, se
            asigna un estado inicial en el sistema BICCI.
            <br />
            <strong>Estado:</strong> BICCI estado (1)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Orden Confirmada:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> La orden ha sido confirmada en el
            sistema BICCI.
            <br />
            <strong>Estado:</strong> BICCI estado (2)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Espera de asignación (No utilizados en última milla):
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Orden en proceso de ser aceptada por
            un rider.
            <br />
            <strong>Estado:</strong> BICCI estado (3)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Repartidor Asignado:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Se asigna un repartidor para la orden
            creada.
            <br />
            <strong>Estado:</strong> BICCI estado (4)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Rider Camino a Punto A (No utilizados en última milla):
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> El repartidor se dirige al punto A
            para recoger el pedido.
            <br />
            <strong>Estados Opcionales:</strong> BICCI estados (5), estos
            estados son configurables según el perfil de la empresa durante la
            integración.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Rider Camino a Punto B:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> El repartidor se dirige al punto B,
            que es el destino de la entrega.
            <br />
            <strong>Estado Transitorio:</strong> BICCI estado (6)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Llegada a Destino:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> El repartidor llega al destino e
            inicia el proceso de entrega.
            <br />
            <strong>Estado:</strong> BICCI estado (7), este es un estado
            transitorio que indica la llegada e inicio del protocolo de entrega.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Pedido Entregado:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> El pedido ha sido entregado
            exitosamente.
            <br />
            <strong>Estado Final:</strong> BICCI estado (8)
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Subestados y Casos Especiales:
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Retraso en la Entrega:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Si hay un retraso en la entrega, se
            utilizan subestados configurados en el perfil de la empresa.
            <br />
            <strong>Estado:</strong> BICCI estado (7)
            <br />
            <strong>Subestados de Integración:</strong> Configurados durante la
            integración para manejar los motivos de retraso.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Motivos de No Entrega:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Si el pedido no puede ser entregado,
            se especifican los motivos.
            <br />
            <strong>Estado:</strong> Dependiendo de la causa de la no entrega,
            se asignan subestados específicos.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Cancelación de Entrega:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Si la entrega es cancelada, se
            utilizan subestados configurados en el perfil de la empresa.
            <br />
            <strong>Estado:</strong> BICCI estado (-1)
            <br />
            <strong>Subestados de Integración:</strong> Configurados durante la
            integración para manejar las cancelaciones.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Devolución:
          </Typography>
          <Typography paragraph>
            <strong>Descripción:</strong> Si el pedido no se entrega y necesita
            ser devuelto, se activa un estado de devolución.
            <br />
            <strong>Subestados:</strong> Configurados durante la integración
            para manejar devoluciones.
          </Typography>

          <Typography variant="body" sx={{ fontSize: "18px" }} gutterBottom>
            Endpoint Cliente Integración:
          </Typography>
          <Typography paragraph>
            El sistema está desarrollado para enviar actualizaciones de estado a
            un endpoint del cliente, según la configuración realizada durante la
            integración.
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default LastMileAPI;
