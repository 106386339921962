import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// hooks
import { useBoolean } from "../../../../hooks/use-boolean";
// components
import Label from "../../../../components/Label/Label";
import Iconify from "../../../../components/Iconify/Iconify";
import CustomPopover, {
  usePopover,
} from "../../../../components/CustomPopover";
import { ConfirmDialog } from "../../../../components/CustomDialog";
import UserDriversQuickEditForm from "./OrderQuickEditForm";
import { NavLink, useNavigate } from "react-router-dom";
import {
  translateStatesSelectorOptionesBasic,
  translateStatesfalabbela,
} from "../../../../utils/objects/translateStates";
import { styleState } from "../../../../utils/objects/stylesStates";
import { format } from "date-fns";
import { grey } from "@mui/material/colors";
import { Box, Chip, OutlinedInput, Select, TextField } from "@mui/material";
import {
  createBicciexpressOrderIssures,
  updateSateBicciexpressOrdes,
} from "../../../../redux/accionesOrders";
import ValidationsSize from "../../../../utils/ValidationsSize";
import { collection, updateDoc, getDoc, doc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ValidationDirections from "../../../../utils/ValidationDirections";
//import UserDriversQuickEditForm from "./UserDriversQuickEditForm";

// ----------------------------------------------------------------------

export default function OrdersTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  visibleBilling,
}) {
  // console.log(row);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userUid = useSelector((store) => store.usuario.user.uid);
  const companyId = useSelector((store) => store.usuario.user.company);
  const [selectedEstatus, setSelectedEstatus] = React.useState([]);
  const [comment, setComment] = React.useState("");

  const handleSelectedEstatus = (event) => {
    setSelectedEstatus(event.target.value);
  };

  const handleNavigateRows = (dataRow) => {
    navigate(`/dashboard/dispatch/details/F${dataRow.id}`);
  };

  const handleNavigateEd = (dataRow) => {
    navigate(`/dashboard/dispatch/edithDetails/F${dataRow.id}`);
  };

  const viewDriverPerfil = (dataRow) => {
    navigate(`/dashboard/drivers/${dataRow.driverID}`);
  };

  const handleSaveState = () => {
    dispatch(updateSateBicciexpressOrdes({ id, selectedEstatus }));
    popover.onClose();
  };

  const {
    id,
    lpn,
    customer_email,
    falabella_status,
    weight,
    falabella_id,
    max_delivery_date,
    commune1,
    date,
    createdAt,

    schedule_range,
    number_trip,
    delivery_type,
    customer_name,
    address,

    quantity,
    commune2,
    local_provider,
    shipper_id,

    status,
    region,
    customer_phone,
    agreed_date,
    size,
    soc,
    price,
    quantity_packages,
    updatedAt,
    placeName,
    driverName,
    pics,
    do: doProperty,
    toAddressLocation,
    toAddress,
    upDatedAtText,
    dateCreateText,
    driverAvatar,
    driverID,
    observationAdmin,
    userDniReceptor,
    userNameReceptor,
    isPickedUp,
    length,
    productHeight,
    height,
    productLength,
    productWeight,
    width,
    productWidth,
    units,
    productUnits,
    productSize,
    driverLicensePlate,
    orderNumber,
  } = row;

  const handleSaveTicket = (row, userUid, type) => {
    // console.log(row);
    // console.log(userUid);
    // console.log(type);
    dispatch(createBicciexpressOrderIssures({ row, userUid, type, companyId }));
    popover.onClose();
  };

  // Cambio en el TextField para actualizar el estado
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const HandleAddComment = async () => {
    if (!comment.trim()) return; // Verifica si el comentario está vacío para no agregar entradas vacías.

    try {
      const collRef = collection(db, process.env.REACT_APP_COL_SUPPORT);
      const docRef = doc(collRef, row.id); // Asegúrate de que 'row.id' tenga el ID correcto del documento/ticket.
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Obtiene el array actual de comentarios y agrega el nuevo comentario.
        const existingComments = docSnap.data().observationAdmin || [];
        const updatedComments = [...existingComments, comment];

        await updateDoc(docRef, { observationAdmin: updatedComments });
        console.log("Comentario agregado con éxito.");
        setComment(""); // Limpia el campo de comentario después de guardar.
      } else {
        console.log("El documento no existe y no se pudo actualizar.");
      }
    } catch (error) {
      console.error("Error al agregar el comentario:", error);
    }
  };

  const valueOfDo = row["do"];

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  function formatDate(dateSeconds) {
    try {
      return format(dateSeconds * 1000, "dd/MM/yy").toString();
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return null;
    }
  }

  return (
    <>
      <TableRow hover selected={selected} sx={{ borderBottom: "grey" }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
          <ValidationsSize
            id={id}
            weight={productWeight ? productWeight : weight}
            // multibulk={element.MULTIBULTO}
            length={productHeight ? productHeight : height}
            height={productLength ? productLength : length}
            width={productWidth ? productWidth : width}
            units={productUnits ? productUnits : quantity_packages}
            // volume={element.VOLUMEN}
            packeSize={size ? size : productSize}
          ></ValidationsSize>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {" "}
          <ListItemText
            primary={id}
            secondary={
              <div>
                <div>F12: {valueOfDo}</div>
                <div>LPN: {lpn}</div>
                <div>Origen: {placeName}</div>
                <div style={{ position: "relative" }}>
                  {price > 200000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "0",
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}

                  {price > 400000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "1em", // Establece la distancia horizontal entre las monedas
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}

                  {price > 900000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "2em", // Establece la distancia horizontal entre las monedas
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}
                </div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <ListItemText
            primary={`Cliente: ${customer_name}`}
            secondary={
              <div>
                <div>Telefono: {customer_phone}</div>
                <div>Email: {customer_email}</div>
                <div>
                  Destino:{" "}
                  {
                    <ValidationDirections
                      address={address}
                    ></ValidationDirections>
                  }
                </div>
                <div>{commune2}</div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Avatar
            alt={""}
            src={driverAvatar}
            sx={{ mr: 2 }}
            onClick={() => viewDriverPerfil(row)}
          />

          <ListItemText
            primary={`Contuctor: ${driverName}`}
            secondary={
              <div>
                <div>Ruta: {driverLicensePlate ? driverLicensePlate : ""}</div>
                <div>position: {orderNumber ? orderNumber : ""}</div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>
        <TableCell>
          <div>
            <span className={styleState[falabella_status]}>
              {translateStatesfalabbela[falabella_status]}
            </span>
          </div>
          <div>
            <div>Creado:</div>
            <div>{format(createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}</div>
          </div>
          <div>
            <div>Actualizado:</div>
            <div>{format(updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}</div>
          </div>
          <div>
            {isPickedUp ? (
              <Chip
                sx={{
                  backgroundColor: "green",
                  color: "#F8F8F8",
                  height: "15px",
                  fontSize: "8px",
                  padding: "0 2px",
                }}
                label="Picking OK"
                size="small"
              />
            ) : (
              <Chip
                sx={{
                  backgroundColor: "grey",
                  color: "#F8F8F8",
                  height: "15px",
                  fontSize: "8px",
                  padding: "0 2px",
                }}
                label="No Picking"
                size="small"
              />
            )}
          </div>

          {/* <div>
            <Label
              variant="soft"
              color={isPickedUp || isPickedUp == true ? "success" : "error"}
            >
              {isPickedUp ? "OK Picking" : "No Picking"}
            </Label>
          </div> */}
        </TableCell>
        {visibleBilling ? (
          <TableCell sx={{ whiteSpace: "nowrap" }}>
            <ListItemText
              primary={
                <div>
                  <div></div>
                  <div>
                    {falabella_status && falabella_status !== "99" ? (
                      <div>{falabella_status}</div>
                    ) : null}
                  </div>
                  <div>
                    {falabella_status && falabella_status !== "99" ? (
                      <div>{falabella_status}</div>
                    ) : null}
                  </div>
                </div>
              }
              secondary={
                <div>
                  <div>Doc: {"billingPaymentProforma"}</div>
                  <div>Servicio: {"billingPaymentType_service"}</div>
                  <div>Monto: {"billingPaymentValor"}</div>
                </div>
              }
              primaryTypographyProps={{ typography: "body2" }}
              secondaryTypographyProps={{
                component: "span",
                color: grey[900],
              }}
            />
          </TableCell>
        ) : null}

        <TableCell align="right" sx={{ px: 1, whiteSpace: "nowrap" }}>
          <Tooltip title="Ver detalle" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? "inherit" : "default"}
              // onClick={quickEdit.onTrue}
              onClick={() => handleNavigateRows(row)}
            >
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>

          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>

          <NavLink
            to={{
              pathname: `/dashboard/dispatch/details/F${id}`,
            }}
            element={row}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#030303", width: "20px", height: "20px" }}
          >
            {" "}
            ver{" "}
          </NavLink>
        </TableCell>
      </TableRow>

      <UserDriversQuickEditForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {/* <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Crear ticket
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleNavigateEd(row);
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Corregir Datos
        </MenuItem>
      </CustomPopover>

      {/* <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      /> */}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={`TICKET # ${id}`}
        content={
          <div>
            <Button
              sx={{ marginLeft: "3px", marginRight: "3px" }}
              color="secondary"
              onClick={() => navigate(`/dashboard/support-ticket/`)}
            >
              Ver todos los Tickets
            </Button>
            <div>
              <strong>Conductor: {driverName} </strong>
              <div>
                <strong>Estado de la Orden:</strong>{" "}
                <span className={styleState[falabella_status]}>
                  {translateStatesfalabbela[falabella_status]}
                </span>
              </div>
              <div>
                <strong>Comentario de Estado:</strong>{" "}
                <span>
                  {observationAdmin
                    ? observationAdmin.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))
                    : null}
                </span>
              </div>
              <div>
                {/* <div>
                  Creado:{" "}
                  {format(createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}
                </div> */}
              </div>
              <div>
                <div>
                  Ultimo cambio de estado:{" "}
                  {format(updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}
                </div>
              </div>
              <div>COD: {id}</div>
              <ListItemText
                primary={`Cliente: ${customer_name}`}
                secondary={
                  <div>
                    <div>Teléfono: {customer_phone}</div>
                    <div>Destino: {address}</div>
                    <div>{commune2}</div>
                  </div>
                }
                primaryTypographyProps={{ typography: "body2" }}
                secondaryTypographyProps={{
                  component: "span",
                  color: grey[900],
                }}
              />
            </div>
            <div>
              <strong>AGREGA COMENTARIOS AL TICKET</strong>
            </div>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: "16px", m: 1 }}
            >
              <Select
                value={selectedEstatus}
                onChange={handleSelectedEstatus}
                input={<OutlinedInput label="Estado" />}
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: 240 },
                  },
                }}
                renderValue={(selected) => `Estatus: ${selected}`}
                sx={{ width: "240px" }}
              >
                {translateStatesSelectorOptionesBasic.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="IMPORTANTE: No son estados de integraciones">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => handleSaveState()}
                >
                  Guardar estado para BICCIEXPRESS
                </Button>
              </Tooltip>
            </Box>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: "16px", m: 1 }}
            >
              <div>
                <Button
                  sx={{ marginLeft: "3px", marginRight: "3px" }}
                  variant="contained"
                  color="success"
                  onClick={() =>
                    handleSaveTicket(row, userUid, "supportTicket")
                  }
                >
                  Crear Nuevo Seguimiento
                </Button>
              </div>
            </Box>
            <Box sx={{ alignItems: "center", gap: "16px" }}>
              <TextField
                label="Escribe actualización aquí..."
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={comment}
                onChange={handleCommentChange} // Esto actualizará el estado 'comment' con cada cambio en el campo de texto
                sx={{ m: 1 }}
              />
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => HandleAddComment()}
                sx={{ m: 1 }}
              >
                Agregar Comentario
              </Button>
            </Box>
          </div>
        }
      />
    </>
  );
}

OrdersTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  visibleBilling: PropTypes.bool,
  companyId: PropTypes.string,
};
