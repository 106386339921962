import * as XLSX from "xlsx";
import Moment from "moment";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarReporte = (data) => {
  console.log(data);
  const matrizReporte = [
    [
      "Origen",
      "Codígo",
      "Comuna",
      "Alto",
      "Ancho",
      "Largo",
      "Peso",
      "Tamaño",
      "Rider",
      "Status",
      "StatusDescription",
      "Creado",
      "Actualizado",
      "Creado2",
    ],
    ...data.map((dato) => [
      dato.local_provider && dato.local_provider !== ""
        ? dato.local_provider
        : dato.int_warehouseName,
      dato.id,
      dato.commune2,
      dato.length ? dato.length : dato.productHeight,
      dato.height ? dato.height : dato.productLength,
      dato.weight ? dato.weight : dato.productWeight,
      dato.width ? dato.width : dato.productWidth,
      dato.size ? dato.size : dato.productSize,
      dato.driverName,
      dato.falabella_status ? dato.falabella_status : dato.int_falabella_status,
      dato.falabella_status
        ? translateStatesfalabbela[dato.falabella_status]
        : translateStatesfalabbela[dato.int_falabella_status],
      dato.dateCreateText
        ? dato.createdAt
          ? dato.createdAt
          : dato.dateCreateText
        : data.int_travelDate,
      dato.upDatedAtText ? dato.upDatedAtText : dato.deliveryRealDeliveryDate,
      dato.deliveryDate ? dato.deliveryDate : null,
    ]),
  ];

  const libro = XLSX.utils.book_new();
  const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
  XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

  XLSX.writeFile(
    libro,
    `reportePagoRiders ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
  );
};

export default generarReporte;
