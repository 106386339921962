import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import { format } from "date-fns";
import { translateStatesfalabbela } from "../../utils/objects/translateStates";
import { styleState } from "../../utils/objects/stylesStates";
import { NavLink } from "react-router-dom";
import { Theme } from "../../Theme/Theme";

const OrderCard = ({ order, onSelectRider, onCopyOrder, onPrintRoute }) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            src={order.driverAvatar}
            sx={{
              bgcolor: "#f2f2f2",
              cursor: "pointer",
              "&:hover": { opacity: 0.7 },
            }}
            aria-label="recipe"
          />
        }
        title={order.driverName ? order.driverName : "SIN ASIGNACIÓN"}
        subheader={order.email}
      />
      <CardContent>
        <Grid container spacing={3}>
          {/* Sección Origen */}
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="subtitle1">Origen</Typography>
            <Typography variant="body2">{order.id}</Typography>
            <Typography variant="body2">{order.soc}</Typography>
            <Typography variant="body2">{order.bu}</Typography>
            <Typography variant="body2">{order.placeName}</Typography>
          </Grid>
          {/* Sección Destino */}
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="subtitle1">Destino</Typography>
            <Typography variant="body2">{order.address}</Typography>
            <Typography variant="body2">{order.commune2}</Typography>
          </Grid>
          {/* Sección Rider */}
          <Grid item xs={12} sm={2} md={2}>
            <Typography variant="subtitle1">Ruta</Typography>
            <Typography variant="body2">{order.driverLicensePlate}</Typography>
            <Typography variant="body2">{order.orderNumber}</Typography>
            <div>
              {order.isPickedUp ? (
                <Chip
                  sx={{
                    backgroundColor: "green",
                    color: "#F8F8F8",
                    height: "15px",
                    fontSize: "8px",
                    padding: "0 2px",
                  }}
                  label="Picking OK"
                  size="small"
                />
              ) : (
                <Chip
                  sx={{
                    backgroundColor: "grey",
                    color: "#F8F8F8",
                    height: "15px",
                    fontSize: "8px",
                    padding: "0 2px",
                  }}
                  label="No Picking"
                  size="small"
                />
              )}
            </div>
          </Grid>
          {/* Sección Estado */}
          <Grid item xs={12} sm={2} md={2}>
            <Typography variant="subtitle1">Estado</Typography>
            <Typography variant="body2">
              <div>Creado:</div>
              <div>
                {format(order.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}
              </div>
            </Typography>
            <Typography variant="body2">
              <div>Actualizado:</div>
              <div>
                {format(order.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")}
              </div>
            </Typography>
            <Typography variant="body2">
              <span className={styleState[order.falabella_status]}>
                {translateStatesfalabbela[order.falabella_status]}
              </span>
            </Typography>
          </Grid>
          {/* Sección Acciones */}
          <Grid item xs={12} sm={2} md={2}>
            <Typography variant="subtitle1">Acciones</Typography>
            {/* <Button
              size="small"
              variant="contained"
              onClick={() => onCopyOrder(order)}
              sx={{
                marginBottom: "8px",
                background: "#591e8f",
                borderRadius: "4px",
                color: "#f8f8f8",
                fontSize: "12px",
                fontWeight: "300",
                margin: "1px",
                lineHeight: "1",
                "&:hover": {
                  background: "#4e1778",
                },
              }}
            >
              Copiar
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => onPrintRoute(order)}
              sx={{
                background: "#591e8f",
                borderRadius: "4px",
                color: "#f8f8f8",
                fontSize: "12px",
                fontWeight: "300",
                margin: "1px",
                lineHeight: "1",
                "&:hover": {
                  background: "#4e1778",
                },
              }}
            >
              Imprimir hoja de Ruta
            </Button> */}
            <NavLink to={`/dashboard/dispatch/details/F${order.id}`}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  marginBottom: "8px",
                  background: "#591e8f",
                  borderRadius: "4px",
                  color: "#f8f8f8",
                  fontSize: "12px",
                  fontWeight: "300",
                  margin: "1px",
                  lineHeight: "1",
                  "&:hover": {
                    background: "#4e1778",
                  },
                }}
              >
                Ver
              </Button>
            </NavLink>
            <NavLink to={`/dashboard/dispatch/edithDetails/F${order.id}`}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  marginBottom: "8px",
                  background: "#591e8f",
                  borderRadius: "4px",
                  color: "#f8f8f8",
                  fontSize: "12px",
                  fontWeight: "300",
                  margin: "1px",
                  lineHeight: "1",
                  "&:hover": {
                    background: "#4e1778",
                  },
                }}
              >
                Editar
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </CardContent>
      {/* <CardActions>
        <Button size="small" onClick={() => onCopyOrder(order)}>
          Copy
        </Button>
        <Button size="small" onClick={() => onPrintRoute(order)}>
          Imprimir hoja de Ruta
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default OrderCard;
