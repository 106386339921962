import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import OrderCard from "./OrderCard";

const OrderList = ({ orders, onSelectRider, onCopyOrder, onPrintRoute }) => {
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Typography variant="subtitle1">
        {" "}
        Vista pantallas pequeñas activada
      </Typography>
      <Grid container spacing={2}>
        {orders.map((order, index) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <OrderCard
              order={order}
              onSelectRider={onSelectRider}
              onCopyOrder={onCopyOrder}
              onPrintRoute={onPrintRoute}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OrderList;
