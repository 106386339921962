import React from "react";
import { db } from "../../../../firebase";
import { useEffect } from "react";
import { Container, Form, Input, Card, CardContent } from "semantic-ui-react";
import { Tooltip, Typography, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Moment from "moment";
import useGetDataVehiclesByCompany from "../../../../hooks/getOrdersDataContextByCompany";
import useGetDataRiderByCompany from "../../../../hooks/getDataRiderByCompany";
import useObtenerDatosdeCliente from "../../../../hooks/obtenerDatosdeCliente";
import CellTowerIcon from "@mui/icons-material/CellTower";
import Chip from "@mui/material/Chip";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const DashRoutesNew = (props) => {
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const usuarioLocal = usuarioStorage.uid;
  const LocalRol = usuarioStorage.roll;
  const [companyId, setcompanyId] = React.useState("");
  var userRoll = null;
  const [userRider, setUserRiderr] = React.useState("");
  const [userVehicle, setUserVehicle] = React.useState("");
  const [routeDate, setRouteDate] = React.useState("");
  const [routeStartTimeDelivery, setRouteStartTimeDelivery] =
    React.useState("08:30");
  const [routeStayTimeDelivery, setRouteStayTimeDelivery] =
    React.useState("00:15");
  const [routeEndTimeDelivery, setRouteEndTimeDelivery] =
    React.useState("20:30");

  const { userdata, data } = useObtenerDatosdeCliente(usuarioLocal);

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(
    companyId,
    userRider
  );
  const { ridersDataByCompany } = useGetDataRiderByCompany(
    companyId,
    userVehicle
  );

  const [routeRows, setRouteRows] = React.useState("");
  const [ridersRows, setRidersRows] = React.useState("");
  const [vehiclesRows, setVehiclesRows] = React.useState("");

  const [render, setRender] = React.useState(false);
  const [errorData, setError] = React.useState(null);
  const [succesCreate, setSuccesCreate] = React.useState(null);
  const [lastCreate, setLastCreate] = React.useState(null);

  const columnsRiders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: "200",
      renderCell: (ridersRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            <Chip
              label="Add"
              onClick={handleGoPropsRidersRows}
              id={ridersRows.row.id}
              value={ridersRows.row.id}
              variant="outlined"
              icon={
                <DirectionsBikeIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#fff",
                backgroundColor: "blue",
              }}
            />
            <Chip
              label={
                ridersRows.row.online === true ? "OnLine   " : "Desconectado"
              }
              icon={
                <CellTowerIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor:
                  ridersRows.row.online === true ? "#1ef708" : "#f7e908",
              }}
            />
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "phone",
      headerName: "Teléfono",
      width: 120,
      editable: true,
    },
    {
      field: "bicciNumber",
      headerName: "Bicci Number",
      sortable: false,
      width: 100,
    },
  ];

  const columnsVehicle = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,
      renderCell: (vehicleRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Chip
              label="Add"
              onClick={handleGoPropsVehicleRows}
              id={vehicleRows.row.id}
              value={vehicleRows.row.id}
              variant="outlined"
              icon={
                <LocalShippingIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#fff",
                backgroundColor: "blue",
              }}
            />
            <Chip
              label={
                vehicleRows.row.online === true ? "OnLine   " : "Desconectado"
              }
              icon={
                <CellTowerIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor:
                  vehicleRows.row.online === true ? "#1ef708" : "#f7e908",
              }}
            />
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 120,
      editable: true,
    },
    {
      field: "VehiclesTypes",
      headerName: "Veículo",
      width: 120,
      editable: true,
    },
    {
      field: "travelMode",
      headerName: "Tipo de ruta",
      width: 120,
      editable: true,
    },
  ];

  var [newRoute, setNewRoute] = React.useState({
    createBy: usuarioLocal,
    createDate: Moment(Date.now()).format("DD-MM-YYYY"),
    date: Date.now(),
    id: "",
    vehicleID: "",
    driverID: "",
    driverName: "",
    companyId: "",
    travelMode: "",
    currentOrderList: {},
    origin: { lat: -33.41856676168833, lng: -70.6027176809204 },
    destination: { lat: -33.41856676168833, lng: -70.6027176809204 },
    optimizeWaypoints: true, // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    wayPoints: [],
    waypoint_order: [],
    maxTimeDelivery: routeStayTimeDelivery,
    dateDeliveryText: "",
    dateDelivery: "",
    startTimeDelivery: routeStartTimeDelivery,
    endTimeDelivery: routeEndTimeDelivery,
    distanceValue: 0,
    durationValue: 0,
    routePoints: 0,
    dispacthDelyvery: 0,
    dispacthPartials: 0,
    dispacthNotDelyvery: 0,
    fullCompliance: 0,
    warnings: "",
    isDelete: false,
    isActive: true,
    routeStates: 0,
  });

  useEffect(() => {
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [data, userdata]);

  useEffect(() => {
    getBicciDriversData();
    getBicciVehiclesData();
    getBicciUserData();
    //  // console.log(newRoute)
    //  // console.log(routeDate)
    //  // console.log(routeStartTimeDelivery)
    //  // console.log(routeEndTimeDelivery)
    //  // console.log(routeStayTimeDelivery)
    //  // console.log(riderData)
    //  // console.log(vehiclesData)
    //  // console.log(userRider)
  }, [VehiclesDataByCompany && ridersDataByCompany]);

  useEffect(() => {}, [render]);

  const getBicciUserData = () => {
    if (userdata) {
      newRoute.companyId = userdata.userCompanyID;
    }
  };

  const getBicciDriversData = () => {
    if (ridersDataByCompany) {
      //console.log(ridersDataByCompany)
      var ridersRows = [];
      for (let i = 0; i < ridersDataByCompany.length; i++) {
        ridersRows.push({
          id: ridersDataByCompany[i].id,
          name: ridersDataByCompany[i].name,
          bicciNumber: ridersDataByCompany[i].bicciNumber,
          online: ridersDataByCompany[i].online,
          phone: ridersDataByCompany[i].phone,
        });
      }

      setRidersRows(ridersRows);
    } else {
    }
  };

  const getBicciVehiclesData = () => {
    if (VehiclesDataByCompany) {
      console.log(VehiclesDataByCompany);
      var vehicleRows = [];
      for (let i = 0; i < VehiclesDataByCompany.length; i++) {
        vehicleRows.push({
          id: VehiclesDataByCompany[i].id,
          travelMode: VehiclesDataByCompany[i].travelMode,
          vehiclesTypes: VehiclesDataByCompany[i].vehiclesTypes,
          origen: VehiclesDataByCompany[i].origen,
          destiny: VehiclesDataByCompany[i].destiny,
        });
      }
      setVehiclesRows(vehicleRows);
    } else {
      // // console.log('Sin data')
    }
  };

  const clearObjRoute = () => {
    newRoute.createBy = usuarioLocal;
    newRoute.createDate = Moment(Date.now()).format("DD-MM-YYYY");
    newRoute.date = Date.now();

    newRoute.vehicleID = "";
    newRoute.driverID = "";
    newRoute.driverName = "";
    newRoute.companyId = "";
    newRoute.travelMode = "";
    newRoute.currentOrderList = {};
    newRoute.origin = { lat: -33.41856676168833, lng: -70.6027176809204 };
    newRoute.destination = { lat: -33.41856676168833, lng: -70.6027176809204 };
    newRoute.optimizeWaypoints = true; // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    newRoute.wayPoints = [];
    newRoute.waypoint_order = [];
    newRoute.maxTimeDelivery = routeStayTimeDelivery;
    newRoute.dateDeliveryText = "";
    newRoute.dateDelivery = "";
    newRoute.startTimeDelivery = routeStartTimeDelivery;
    newRoute.endTimeDelivery = routeEndTimeDelivery;
    newRoute.distanceValue = 0;
    newRoute.durationValue = 0;
    newRoute.routePoints = newRoute.wayPoints.length;
    newRoute.dispacthDelyvery = 0;
    newRoute.dispacthPartials = 0;
    newRoute.dispacthNotDelyvery = 0;
    newRoute.fullCompliance = 0;
    newRoute.warnings = "";
    newRoute.routeStates = 0;

    setRender(!render);
  };

  const handleGoedith = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/${e.target.id}/edit`);
  };

  const handleChange = (e) => {
    // console.log(e.target.name)
    if (e.target.name === "endHour") {
      setRouteEndTimeDelivery(e.target.value);
      newRoute.endTimeDelivery = e.target.value;
    }
    if (e.target.name === "awaitTime") {
      setRouteStayTimeDelivery(e.target.value);
      newRoute.maxTimeDelivery = e.target.value;
    }
    if (e.target.name === "initHour") {
      setRouteStartTimeDelivery(e.target.value);
      newRoute.startTimeDelivery = e.target.value;
    }
    if (e.target.name === "dispchDate") {
      setRouteDate(e.target.value);
      newRoute.dateDelivery = e.target.value;
      newRoute.dateDeliveryText = e.target.value;
    }
    setRender(!render);
  };

  const handleGoProps = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/${e.target.id}/edit`);
  };
  const handleGoPropsRidersRows = (e) => {
    e.preventDefault();
    var search = ridersRows.find((item) => item.id === e.currentTarget.id);
    //console.log(search)
    newRoute.driverID = e.currentTarget.id;
    newRoute.driverName = search.name;

    setRender(!render);
  };

  const handleGoPropsVehicleRows = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    var searchv = vehiclesRows.find((item) => item.id === e.currentTarget.id);
    //console.log(searchv)
    newRoute.vehicleID = e.currentTarget.id;
    newRoute.travelMode = searchv.travelMode;
    newRoute.origin = searchv.origen;
    newRoute.destination = searchv.destiny;

    setRender(!render);
  };
  const handleGoDispatches = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/details/${e.target.id}`);
  };
  const handleChangeDate = (event, data) => {
    // console.log(data)
    setRouteDate(data.value);
    newRoute.dateDelivery = Moment(data.value).format(
      "DD MMMM YYYY, h:mm:ss a"
    );
    newRoute.dateDeliveryText = Moment(data.value).format(
      "DD MMMM YYYY, h:mm:ss a"
    );
    setRender(!render);
  };

  const createrRoute = (e) => {
    e.preventDefault();
    // console.log('createrRoute')
  };

  function checkboxSelection(e) {
    // console.log(e.targat)
  }

  async function handleClickSave(e) {
    e.preventDefault();
    console.log(newRoute);
    // console.log(newRoute.currentOrderList)
    if (!newRoute.companyId.trim()) {
      setError("Error companyId");
      return;
    }
    if (!newRoute.createBy.trim()) {
      setError("Error createBy");
      return;
    }
    // if(!newRoute.createDate.length()>0){setError('Error createDate')}
    // if(!newRoute.date.length()>0){setError('Error date')}
    //if(!newRoute.id.trim()){setError('Error ID');return}
    if (!newRoute.driverID.trim()) {
      setError("Error driverID");
      return;
    }
    if (!newRoute.vehicleID.trim()) {
      setError("Error vehicleID");
      return;
    }
    if (!newRoute.driverName.trim()) {
      setError("Error driverName");
      return;
    }
    if (!newRoute.travelMode.trim()) {
      setError("Error travelMode");
      return;
    }
    if (!Object.entries(newRoute.currentOrderList).length !== true) {
      setError("Error currentOrderList");
      return;
    }
    if (!Object.entries(newRoute.origin).length === true) {
      setError("Error origin");
      return;
    }
    if (!Object.entries(newRoute.destination).length === true) {
      setError("Error destination");
      return;
    }
    //if(!Object.entries(newRoute.optimizeWaypoints).length === true){setError('Error optimizeWaypoints');return}
    if (!Object.entries(newRoute.wayPoints).length !== true) {
      setError("Error wayPoints");
      return;
    }
    if (!Object.entries(newRoute.waypoint_order).length !== true) {
      setError("Error wayPoints");
      return;
    }
    if (!newRoute.maxTimeDelivery.trim()) {
      setError("Error maxTimeDelivery");
      return;
    }
    if (!newRoute.dateDelivery.trim()) {
      setError("Error dateDelivery");
      return;
    } else {
      newRoute.routeStates = 103;
    }
    if (!newRoute.dateDeliveryText.trim()) {
      setError("Error dateDeliveryText");
      return;
    }
    if (!newRoute.startTimeDelivery.trim()) {
      setError("Error startTimeDelivery");
      return;
    }
    if (!newRoute.endTimeDelivery.trim()) {
      setError("Error endTimeDelivery");
      return;
    }
    if (!newRoute.distanceValue === 0) {
      setError("Error distanceValue");
      return;
    }
    if (!newRoute.durationValue === 0) {
      setError("Error durationValue");
      return;
    }
    if (!newRoute.routePoints === 0) {
      setError("Error routePoints");
      return;
    }
    if (!newRoute.dispacthDelyvery === 0) {
      setError("Error dispacthDelyvery");
      return;
    }
    if (!newRoute.dispacthPartials === 0) {
      setError("Error dispacthPartials");
      return;
    }
    if (!newRoute.dispacthNotDelyvery === 0) {
      setError("Error dispacthNotDelyvery");
      return;
    }
    if (!newRoute.fullCompliance === 0) {
      setError("Error fullCompliance");
      return;
    }
    //if(!newRoute.warnings.trim()){setError('Error warnings')}
    setError(null);
    // console.log('Crear ruta')
    props.history.push(`/dashboard/routes/`);

    try {
      const accountants = await db
        .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
        .doc("routesCounter")
        .get();
      // console.log(accountants.data().count);
      const documenCont = accountants.data().count + 1;
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(documenCont.toString())
        .set(newRoute);
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(documenCont.toString())
        .collection("history")
        .doc(Date.now().toString())
        .set(newRoute);
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(documenCont.toString())
        .update({ id: documenCont.toString() });

      await db
        .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
        .doc("routesCounter")
        .set({ count: parseInt(documenCont) });
      setSuccesCreate("true");
      clearObjRoute();
    } catch (error) {
      // console.log(error)
    }
  }

  function handleCancel() {
    clearObjRoute();
    props.history.push(`/dashboard/routes/`);
  }

  return (
    <div>
      {succesCreate ? (
        <div>
          <div className="alert alert-success"> Nueva ruta creada </div>
        </div>
      ) : (
        <></>
      )}
      <Container className="containerroutes">
        <div className="containerroutesdiv">
          <Box
            sx={{
              width: 300,
              height: 50,
              backgroundColor: "#e0e0e0",
              textAlign: "center",
              p: 1,
            }}
          >
            <h3> Información del Usuario</h3>
          </Box>
          <Box
            sx={{
              width: 300,
              height: "100%",
              backgroundColor: "#eeeeee",
              p: 1,
              "&:hover": {
                backgroundColor: "#eeeeee",
                opacity: [0.9, 0.8, 0.7],
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eeeeee",
                p: 2,
              }}
            >
              <form onSubmit={createrRoute}>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    type="text"
                    label="Usuario Rider"
                    placeholder="ID Rider o Nombre"
                    iconPosition="left"
                    icon="user"
                    name="usuarioRider"
                    onChange={(e) => setUserRiderr(e.target.value)}
                    value={userRider}
                  />
                </Form.Group>
                <Form.Field
                  control={Input}
                  type="text"
                  label="Vehículo"
                  placeholder="Patente/Placa"
                  iconPosition="left"
                  icon="truck"
                  name="vehicle"
                  onChange={(e) => setUserVehicle(e.target.value)}
                  value={userVehicle}
                />
                <hr></hr>
                <h3> Información de la ruta de despacho</h3>
                <hr></hr>

                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    type="date"
                    label="Fecha de ruta"
                    iconPosition="left"
                    min={Moment(Date.now()).format("YYYY-MM-DD")}
                    // max="2022-08-10"
                    name="dispchDate"
                    onChange={(e) => handleChange(e)}
                    value={routeDate}
                    // blur={// console.log('blur accion')}
                    onClick={(e) => console.log(e.view)}

                    //locale={'firstDayOfWeek': 1}
                  />
                </Form.Group>
                <hr></hr>

                <hr></hr>
                <Form.Group widths="equal">
                  <span>Hora de inicio</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="initHour"
                    onChange={(e) => handleChange(e)}
                    value={routeStartTimeDelivery}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <hr></hr>
                  <span>Hora de final</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="endHour"
                    onChange={(e) => handleChange(e)}
                    value={routeEndTimeDelivery}
                  />
                </Form.Group>
                <hr></hr>
                <Form.Group widths="equal">
                  <span>Tiempo en punto de entrega</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="awaitTime"
                    onChange={(e) => handleChange(e)}
                    value={routeStayTimeDelivery}
                  />
                </Form.Group>
                <hr></hr>

                {/* <button>Buscar</button> */}
              </form>
            </Box>
          </Box>
        </div>
        <div className="containerroutesdivleft">
          <div>
            {" "}
            <h3 className="m-1">Seleccionar Un Conductor</h3>
          </div>
          <Box
            sx={{
              height: 400,
              width: 550,
              backgroundColor: "#fafafa",
            }}
          >
            {ridersRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={ridersRows}
                columns={columnsRiders}
                pageSize={5}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                onClick={checkboxSelection}
              />
            ) : (
              <></>
            )}
          </Box>
          <div>
            {" "}
            <h3 className="m-1">Selecciona Un Vehículo</h3>
          </div>
          <Box
            sx={{
              height: 400,
              width: 550,
              backgroundColor: "#fafafa",
            }}
          >
            {vehiclesRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={vehiclesRows}
                columns={columnsVehicle}
                pageSize={5}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                onClick={checkboxSelection}
              />
            ) : (
              <></>
            )}
          </Box>
        </div>

        <div className="containerroutesdivleft">
          <Box
            sx={{
              height: "33%",
              width: "auto",
              backgroundColor: "fdfdfd",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: 50,
                backgroundColor: "#e0e0e0",
                textAlign: "center",
                p: 1,
                "&:hover": {
                  backgroundColor: "#e0f7fa",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <h3> Resumen de ruta </h3>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "#f8f8f8",
                textAlign: "left",
                p: 3,
                border: 1,
                borderTop: 1,
                borderRight: 1,
                borderBottom: 1,
                borderLeft: 1,
                borderColor: "grey.500",
                borderRadius: 1,
              }}
            >
              <Box
                className="containerroutesdivbox"
                sx={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#fafafa",

                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    textAlign: "left",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    sx={{ minWidth: 100 }}
                  >
                    {" "}
                  </Typography>

                  <Tooltip title="Reasignar ruta">
                    <IconButton
                      onClick={handleCancel}
                      size="small"
                      sx={{
                        ml: 2,
                        color: "#4a148c",
                        "&:focus": {
                          outline: "none!important",
                        },
                      }}
                    >
                      {`x Cancelar`}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reasignar ruta">
                    <IconButton
                      onClick={clearObjRoute}
                      size="small"
                      sx={{
                        ml: 2,
                        color: "#4a148c",
                        "&:focus": {
                          outline: "none!important",
                        },
                      }}
                    >
                      <CleaningServicesIcon
                        sx={{ width: 15, height: 15 }}
                      ></CleaningServicesIcon>
                      Limpiar
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Reasignar ruta">
                    <IconButton
                      onClick={handleClickSave}
                      size="small"
                      sx={{ ml: 2, color: "#4a148c" }}
                    >
                      <AddCircleIcon
                        sx={{ width: 15, height: 15 }}
                      ></AddCircleIcon>
                      Guardar
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Card>
                <CardContent>
                  {newRoute.driverName ? (
                    <div className="cardcontentrpute">
                      Usuario conductor:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.driverName}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Selecciona un Conductor{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {newRoute.vehicleID ? (
                    <div className="cardcontentrpute">
                      Vehículo:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.vehicleID}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Selecciona un vehículo{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {newRoute.dateDeliveryText ? (
                    <div className="cardcontentrpute">
                      Fecha de despacho:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.dateDeliveryText}
                      </span>{" "}
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica una fecha de ruta
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeStartTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Hora de inicio:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeStartTimeDelivery}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        Indica Hora de inicio{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeEndTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Hora de final:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeEndTimeDelivery}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica Hora de finalizacion{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeStayTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Tiempo de espera:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeStayTimeDelivery}
                      </span>{" "}
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica Tiempo de espera en cada punto
                      </span>
                    </div>
                  )}
                </CardContent>
                {errorData ? (
                  <div className="p-1 m-3">
                    <span className="alert alert-danger">{errorData}</span>
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>
            </Box>
          </Box>
        </div>

        <div></div>
      </Container>
    </div>
  );
};

export default DashRoutesNew;
