import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LoginIcon from "@mui/icons-material/Login";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../logo/Logo";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cerrarSesionAccion } from "../../../redux/usuarioDucks";
import { makeStyles } from "@mui/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  customMenu: {
    backgroundColor: "#FFFFFF",
    color: "#121828",
    borderRadius: "3px",
    boxShadow:
      "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    maxHeight: "50vh",
    overflowY: "auto",
    overflowX: "hidden",
    minWidth: "16px",
    minHeight: "16px",
    maxWidth: "calc(100% - 50px)",
    outline: "0",
    position: "fixed", // Para asegurarte de que ocupa toda la pantalla
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

function ResponsiveAppBar({ open, handleClick, props }) {
  // console.log(props);
  const dispatch = useDispatch();
  const activo = useSelector((store) => store.usuario.activo);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const cerrarSesion = (props) => {
    //console.log(props)
    //    props.history.push('/')
    dispatch(cerrarSesionAccion());
  };

  const classes = useStyles();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        paddingBottom: "5px",
      }}
    >
      <Container maxWidth="xl" sx={{ maxHeight: "65px" }}>
        <Toolbar disableGutters sx={{ maxHeight: "65px" }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Logo></Logo>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#591e8f" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom", // Cambia "bottom" a "top"
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{ className: classes.customMenu }} // Aplicar la clase personalizada
            >
              <MenuItem>
                <div className="navlinkbar">
                  <NavLink
                    className="btns-AppBar"
                    onClick={handleClick}
                    to="/inicio"
                    exact="true"
                  >
                    Home
                  </NavLink>
                </div>
                <hr></hr>
              </MenuItem>
              <MenuItem>
                <div className="navlinkbar">
                  <NavLink
                    className="btns-AppBar"
                    onClick={handleClick}
                    to="/nosotros"
                    exact="true"
                  >
                    Nosotros
                  </NavLink>
                </div>
                <hr></hr>
              </MenuItem>
              <MenuItem>
                <div className="navlinkbar">
                  <NavLink
                    className="btns-AppBar"
                    onClick={handleClick}
                    to="/flota"
                    exact="true"
                  >
                    Flota
                  </NavLink>
                </div>
                <hr></hr>
              </MenuItem>
              <MenuItem>
                <div className="navlinkbar">
                  <NavLink
                    className="btns-AppBar"
                    onClick={handleClick}
                    to="/servicios"
                    exact="true"
                  >
                    Servicios
                  </NavLink>
                </div>
                <hr></hr>
              </MenuItem>
              <MenuItem>
                <div className="navlinkbar">
                  <NavLink
                    className="btns-AppBar"
                    onClick={handleClick}
                    to="/contacto"
                    exact="true"
                  >
                    Contacto
                  </NavLink>
                </div>
                <hr></hr>
              </MenuItem>
              {activo ? (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns-AppBar mr-2"
                      onClick={handleClick}
                      to="/dashboard/mi-cuenta"
                      exact="true"
                    >
                      <i className="user circle icon"></i>Perfil
                    </NavLink>
                  </div>
                </MenuItem>
              ) : null}
              <hr></hr>
              {activo ? (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns.AppBarmr-2 "
                      onClick={() => cerrarSesion(props)}
                      to="/login"
                    >
                      <i className="sign out alternate icon koFoOQ"></i>Salir
                    </NavLink>
                  </div>
                </MenuItem>
              ) : (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns-AppBar"
                      onClick={handleClick}
                      to="/login"
                      exact="true"
                    >
                      Login
                    </NavLink>
                  </div>
                </MenuItem>
              )}
              <Box sx={{ vertical: "bottom", horizontal: "right" }}>
                <Button onClick={handleCloseNavMenu}>Cerrar</Button>
              </Box>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Logo></Logo>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center", // Centra los elementos horizontalmente
              alignItems: "center",
            }}
          >
            <div className="navlinkbar">
              <NavLink
                className="btns-AppBar"
                onClick={handleClick}
                to="/inicio"
                exact="true"
              >
                Home
              </NavLink>
            </div>
            <div className="navlinkbar">
              <NavLink
                className="btns-AppBar"
                onClick={handleClick}
                to="/nosotros"
                exact="true"
              >
                Nosotros
              </NavLink>
            </div>

            <div className="navlinkbar">
              <NavLink
                className="btns-AppBar"
                onClick={handleClick}
                to="/flota"
                exact="true"
              >
                Flota
              </NavLink>
            </div>

            <div className="navlinkbar">
              <NavLink
                className="btns-AppBar"
                onClick={handleClick}
                to="/servicios"
                exact="true"
              >
                Servicios
              </NavLink>
            </div>
            <div className="navlinkbar">
              <NavLink
                className="btns-AppBar"
                onClick={handleClick}
                to="/contacto"
                exact="true"
              >
                Contacto
              </NavLink>
            </div>

            {!activo ? (
              <div className="navlinkbar">
                <NavLink
                  className="btns-AppBar"
                  onClick={handleClick}
                  to="/login"
                  exact="true"
                >
                  Entrar
                </NavLink>
              </div>
            ) : null}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {!activo ? (
              <div id="socialcontainer">
                {/* <Tooltip title="Carrito">
                  <IconButton
                    sx={{ ml: 1, color: "#591E8F" }}
                    //onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => history.push("/cart")}
                  >
                    <Badge badgeContent={cartcontent} color="secondary">
                      <ShoppingBag fontSize="mediun" />
                    </Badge>
                  </IconButton> 
                </Tooltip>*/}
                <Link
                  className="btn-bicci-prupura-color"
                  to="https://www.facebook.com/bicciexpress"
                >
                  <span>
                    <FacebookIcon></FacebookIcon>
                  </span>
                </Link>
                <Link
                  className="btn-bicci-prupura-color"
                  to="https://www.instagram.com/bicciexpress.cl/"
                >
                  <span>
                    <InstagramIcon></InstagramIcon>
                  </span>
                </Link>

                <Tooltip title="Login">
                  <LoginIcon
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, color: "#591e8f" }}
                  ></LoginIcon>
                </Tooltip>
              </div>
            ) : (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src={props.user.photoURL} />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
            >
              {activo ? (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns-AppBar mr-2"
                      onClick={handleClick}
                      to="/dashboard/mi-cuenta"
                      exact="true"
                    >
                      <i className="user circle icon"></i>Perfil
                    </NavLink>
                  </div>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns-AppBar mr-2"
                      onClick={handleClick}
                      to="/dashboard"
                      exact="true"
                    >
                      <ListAltIcon fontSize="medium" />
                      Dashboard
                    </NavLink>
                  </div>
                </MenuItem>
              ) : null}
              {activo ? (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns.AppBarmr-2 "
                      onClick={() => cerrarSesion(props)}
                      to="/login"
                    >
                      <i className="sign out alternate icon koFoOQ"></i>cerrar
                      sesion
                    </NavLink>
                  </div>
                </MenuItem>
              ) : (
                <MenuItem>
                  <div className="navlinkbar">
                    <NavLink
                      className="btns-AppBar"
                      onClick={handleClick}
                      to="/login"
                      exact="true"
                    >
                      Login
                    </NavLink>
                  </div>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
