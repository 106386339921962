import { Box, Typography, Card, CardContent } from "@mui/material";
import React from "react";
import {
  emptyOrder,
  orderValidationSchema,
  emptyReq,
} from "../../utils/objects/odersBody";

const DebugObjectChanges = ({ objectValidate, contextOrder }) => {
  const extraFields = Object.keys(objectValidate).filter(
    (key) => !(key in emptyOrder)
  );
  const initialFields = Object.keys(emptyOrder);
  const resultFields = Object.keys(objectValidate);

  // Filtrar los campos de emptyOrder que están presentes y tienen un valor en objectValidate
  const isLocationValid = (location) => {
    return (
      location?.lat !== null &&
      location?.lat !== undefined &&
      location?.lng !== null &&
      location?.lng !== undefined
    );
  };

  const isGroceryValid = (grocery) => {
    return (
      grocery.description !== "" ||
      grocery.id !== "" ||
      grocery.name !== "" ||
      grocery.quantity > 0 ||
      grocery.price > 0 ||
      grocery.height > 0 ||
      grocery.width > 0 ||
      grocery.length > 0 ||
      grocery.weight > 0 ||
      grocery.volume > 0
    );
  };

  const isGroceriesArrayValid = (groceries) => {
    return (
      groceries?.length > 0 &&
      groceries.some((grocery) => isGroceryValid(grocery))
    );
  };

  const validFields = initialFields.filter((key) => {
    if (key === "fromLocation" || key === "toLocation") {
      return isLocationValid(objectValidate[key]);
    }
    if (key === "groceries") {
      return isGroceriesArrayValid(objectValidate[key]);
    }
    return (
      objectValidate[key] !== null &&
      objectValidate[key] !== undefined &&
      objectValidate[key] !== ""
    );
  });

  const pendingFields = initialFields.filter(
    (key) => !validFields.includes(key)
  );
  const pendingFieldsCount = pendingFields.length;

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "#080808",
        color: "blue",
        padding: "10px",
        maxHeight: "80%",
        maxWidth: "300px",
        overflowY: "auto",
        overflowX: "auto",
        zIndex: 999,
        marginBottom: "50px",
        borderBottom: "5px solid #FFF",
        m: 2,
        p: 1,
      }}
    >
      <Card>
        <CardContent sx={{ backgroundColor: "#080808" }}>
          <Typography variant="h6" sx={{ color: "lightblue" }}>
            Object Debug Info : {initialFields.length}
          </Typography>
          <Typography variant="h6" sx={{ color: "lightblue" }}>
            Pendientes : {pendingFieldsCount > 0 ? pendingFieldsCount : 0}
          </Typography>
          <Typography variant="h6" sx={{ color: "green", marginTop: "20px" }}>
            Original Fields with Values: {validFields.length}
          </Typography>
          <div>
            {validFields.map((field) => (
              <Typography key={field} sx={{ color: "green" }}>
                {field}
              </Typography>
            ))}
          </div>

          {/* Mostrar los campos pendientes en rojo */}
          <Typography variant="h6" sx={{ color: "red", marginTop: "20px" }}>
            Pendientes:
          </Typography>
          <div>
            {pendingFields.map((field) => (
              <Typography key={field} sx={{ color: "red" }}>
                {field}
              </Typography>
            ))}
          </div>

          <hr style={{ backgroundColor: "#f8f8f8" }}></hr>
          <pre style={{ color: "lightblue" }}>
            {JSON.stringify(contextOrder, null, 2)}
          </pre>
          <hr style={{ backgroundColor: "#f8f8f8" }}></hr>
          <pre style={{ color: "lightblue" }}>
            {JSON.stringify(objectValidate, null, 2)}
          </pre>
          {extraFields.length > 0 && (
            <>
              <Typography sx={{ color: "red" }}>
                Total Fields : {resultFields.length}
              </Typography>

              <Typography sx={{ color: "red" }}>
                Extra Fields : {extraFields.length}
              </Typography>

              <ul style={{ color: "yellow" }}>
                {extraFields.map((field) => (
                  <li key={field}>{field}</li>
                ))}
              </ul>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DebugObjectChanges;
