import PropTypes from "prop-types";
// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
//
import EmptyContent from "../EmptyContent/EmptyContent";

// ----------------------------------------------------------------------

export default function TableNoData({
  notFound,
  sx,
  mensajeBanner,
  bannerActive,
}) {
  return (
    <TableRow>
      {notFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            filled
            title={
              mensajeBanner
                ? mensajeBanner
                : "Esperando resultados, confirme si el rango de fecha es válido."
            }
            sx={{
              py: 10,
              ...sx,
            }}
            bannerActive={bannerActive}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

TableNoData.propTypes = {
  notFound: PropTypes.bool,
  sx: PropTypes.object,
  bannerActive: PropTypes.bool,
  mensajeBanner: PropTypes.string,
};
