import React from "react";
import AccountProfileDetails from "./Form_micuenta";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { OpcionesLaterales } from "./OpcionesLaterales";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
  Grid,
} from "@mui/material";
import useObtenerDetallesdecuenta from "../../../hooks/obtenerDetallesdecuenta";
import useObtenerBalance from "../../../hooks/obtenerBalance";
import { useLoadScript } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const libraries = ["places"];
const user = {
  avatar: "/static/images/avatars/avatar_12.png",
  city: "Region Metrpolitana",
  country: "CHILE",
};

const Micuenta = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();
  const userUid = useSelector((store) => store.usuario.user.uid);

  const [userData, setUserData] = React.useState("");
  const [userDisplayName, setDisplayName] = React.useState();
  const [avatar, setAvatar] = React.useState("");

  const [dataClientCompany, setDataClienCompany] = React.useState("");

  //const { data } = useObtenerDatosdeCliente(userUid)//(userUid)
  const {
    accountDetails,
    accountDetailsHistory,
    availableservices,
    loadingDetallesdecuenta,
    errorDetallesdecuenta,
  } = useObtenerDetallesdecuenta(userData.userCompanyID); //(userUid)

  const { userBalance, loadingBalance, errorBalance } = useObtenerBalance(
    userData.userCompanyID
  ); //(userUid)

  React.useEffect(() => {
    if (userUid) {
      obtenerDatosdeCliente(userUid);
    }
  }, []);

  const obtenerDatosdeCliente = async (userUid) => {
    try {
      const accountants = await getDoc(
        doc(db, `${process.env.REACT_APP_COL_USERS}/${userUid}`)
      );
      const data = accountants.data();
      setUserData(data);
      setAvatar(data.avatar);
      setDisplayName(data.userName);
    } catch (error) {}
  };

  // React.useEffect(() => {
  //   setDataClienCompany(data)
  // }, [data ])

  React.useEffect(() => {}, [
    accountDetails &&
      accountDetailsHistory &&
      availableservices &&
      loadingDetallesdecuenta &&
      errorDetallesdecuenta,
  ]);

  React.useEffect(() => {}, [userBalance, loadingBalance, errorBalance]);

  const createnewcompany = () => {
    props.history.push(`/dashboard/companies`);
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }
  return (
    <Grid container sx={{ m: 1 }}>
      <Grid item xs={12}>
        <h2 className="h2-withe">Mi cuenta</h2>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={avatar}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {userDisplayName}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body2"
                >
                  {`${user.city} ${user.country}`}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
          </Card>
          <br />
          <Card sx={{ m: 1 }}>
            <CardContent>
              <h3>Empresa</h3>
              {dataClientCompany ? (
                <>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      src={dataClientCompany.companyImgLogo}
                      sx={{
                        height: 64,
                        mb: 2,
                        width: 64,
                      }}
                    />
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textPrimary"
                      gutterBottom
                    >
                      {/* {data.companyName} */}
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textSecondary"
                      variant="body2"
                    >
                      {`${dataClientCompany.companyRegion} ${dataClientCompany.companyCity}`}
                    </Typography>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textSecondary"
                      variant="body2"
                    >
                      {dataClientCompany.companyRut}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    onClick={() => navigate("/dashboard/companies")}
                  >
                    Crear
                  </Button>
                </>
              )}
            </CardContent>
            <Divider />
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* <Card sx={{ m: 1 }}>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CarouselItemsMenuMovilApp />
              </Box>
            </CardContent>
            <Divider />
          </Card> */}

          <Card sx={{ m: 1 }}>
            {/* <CardContent>
              <h3>Mis planes y bolsas de envíos adquiridos</h3>

              <Card
                sx={{
                  alignItems: "left",
                  display: "inline-block",
                  flexDirection: "column",
                  margin: "5px",
                  border: "1px solid #cbcbcb",
                  padding: "10px",
                  radius: "8px",
                }}
              >
                <CardContent>
                  <h3>Detallates de cuenta:</h3>
                  <Box>
                    {accountDetails ? (
                      <>
                        {`Último Pago: ${accountDetails.lastRecharge}`}
                        <br />
                        {`Envíos disponibles: ${accountDetails.shipmentsAvailable}`}
                        <br />
                        {`Método de pago: ${accountDetails.payMethod}`}
                        <br />
                        {`Opción de pago: ${accountDetails.payOptions}`}
                        <br />
                      </>
                    ) : (
                      <>
                        {loadingDetallesdecuenta ? (
                          <CircularProgress />
                        ) : (
                          <>Sin registros COD252</>
                        )}
                      </>
                    )}
                  </Box>
                </CardContent>
                <Divider />
              </Card>
              <Card
                sx={{
                  alignItems: "left",
                  display: "inline-block",
                  flexDirection: "column",
                  margin: "5px",
                  border: "1px solid #cbcbcb",
                  padding: "10px",
                  radius: "8px",
                }}
              >
                <CardContent>
                  <h3>Balance de Cuenta:</h3>

                  <Box>
                    {userBalance ? (
                      <>
                        {`Estatus una cuenta : ${userBalance.isActiveWallet}`}
                        <br />
                        {`Pagos pendientes: $ ${userBalance.pendingPayment}`}
                        <br />
                        {`Envios disponibles: ${userBalance.balance}`}
                        <br />
                        {`Cobertura de envios: ${userBalance.maxCoverage} Km`}
                        <br />
                      </>
                    ) : (
                      <>
                        {loadingBalance ? (
                          <>Sin registros</>
                        ) : (
                          <>
                            {errorBalance ? (
                              <>No existe balance</>
                            ) : (
                              <>Sin registros COD314</>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </CardContent>
                <Divider />
              </Card>
            </CardContent> */}
            <Divider />
          </Card>

          {userData ? (
            <AccountProfileDetails props={props} userdata={userData} />
          ) : (
            <>{accountDetails ? <LinearProgress /> : <>Sin registros</>}</>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {availableservices ? (
            <OpcionesLaterales serviciosAct={availableservices} props={props} />
          ) : (
            <>
              {accountDetails ? (
                <LinearProgress />
              ) : (
                <span>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      border: "1px solid #f8f8f8 !important",
                      backgroundColor: "#8f130c",
                      borderRadius: "15px",
                      textAlign: "center",
                      width: "75%",
                      color: "#ffffff !important",
                      padding: "2px !important",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Requiere registro de su empresa.
                  </Typography>
                </span>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Micuenta;
