import { getGeocode, getLatLng } from "use-places-autocomplete";

async function getValidationAdressGeoRef(adressDirection) {
  // console.log("getValidationAdressGeoRef getGeocode", adressDirection);
  let lat1;
  let lon1;
  let dirValidation0, dirValidation1;
  dirValidation0 = adressDirection;
  const Validarobjeto = {
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_3: "",
    administrative_area_level_2: "",
    administrative_area_level_1: "",
    country: "",
    postal_code: "",
    lngLat: "",
    formatted_address: "",
    place_id: "",
  };

  const HandleGetDirectiones = async (address) => {
    try {
      const results = await getGeocode({ address });
      // //console.log(results);
      return results[0];
    } catch (error) {
      // //console.log("😱 Error: ", error);
      return error;
    }
  };

  const buscarDireccionPLlaceApi = async (responsePlaceApi) => {
    // //console.log("buscarDireccionPlaceApi responsePlaceApi", responsePlaceApi);
    // //console.log("--------------------");

    try {
      for (let i = 0; i < responsePlaceApi.address_components.length; i++) {
        let elementAdress = responsePlaceApi.address_components[i];
        switch (elementAdress["types"][0]) {
          case "street_number":
            Validarobjeto.street_number = elementAdress.long_name;
            break;
          case "route":
            Validarobjeto.route = elementAdress.long_name;
            break;
          case "locality":
            Validarobjeto.locality = elementAdress.long_name;
            break;
          case "administrative_area_level_3":
            Validarobjeto.administrative_area_level_3 = elementAdress.long_name;
            break;
          case "administrative_area_level_2":
            Validarobjeto.administrative_area_level_2 = elementAdress.long_name;
            break;
          case "administrative_area_level_1":
            Validarobjeto.administrative_area_level_1 = elementAdress.long_name;
            break;
          case "country":
            Validarobjeto.country = elementAdress.long_name;
            break;
          case "postal_code":
            Validarobjeto.postal_code = elementAdress.long_name;
            break;

          default:
            break;
        }
      }

      const { lat, lng } = getLatLng(responsePlaceApi);

      Validarobjeto.lngLat = `${lat},${lng}`;
      lat1 = lat;
      lon1 = lng;
      Validarobjeto.formatted_address = responsePlaceApi.formatted_address;
      dirValidation1 = responsePlaceApi.formatted_address;
      // //console.log(Validarobjeto);
      return Validarobjeto;
    } catch (error) {
      //console.log(error);
    }
  };

  /*
  const buscarDireccionesEnRuta = (responsePlaceApi) => {
    // //console.log("buscarDirecciones.Route Validarobjeto", Validarobjeto);
    // //console.log("buscarDirecciones.Route Validarobjeto", responsePlaceApi);
    // //console.log("---------------------");

    let wayPointsTest = [
      {
        stopover: true,
        location: Validarobjeto.lngLat,
      },
    ];

    const origin = {
      lat: -33.4592446,
      lng: -70.6014753,
    };

    const destination = {
      lat: -33.4592446,
      lng: -70.6014753,
    };

    const DirectionsServiceOption = {
      origin: origin,
      destination: destination,
      travelMode: "BICYCLING",
      optimizeWaypoints: true,
      waypoints: wayPointsTest,
    };
    // //console.log(DirectionsServiceOption);

    var directionsService = new window.google.maps.DirectionsService();

    // //console.log("DirectionsServiceOption", DirectionsServiceOption);

    return new Promise((resolve, reject) => {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: DirectionsServiceOption.travelMode,
          optimizeWaypoints: DirectionsServiceOption.optimizeWaypoints,
          waypoints: DirectionsServiceOption.waypoints,
        },
        function (response, status) {
          if (status === window.google.maps.DirectionsStatus.OK) {
            // //console.log("response route", response);
            console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
            console.log("Evaluar si es necesario");
            console.log(destination);
            console.log(origin);
            console.log("Evaluar si es necesario ");
            try {
              if (response.routes[0].waypoint_order.length > 0) {
                dirValidation2 = response.routes[0].legs[0].end_address;
                lat2 = response.routes[0].legs[0].end_location.lat();
                lon2 = response.routes[0].legs[0].end_location.lng();
                resolve(response.routes[0].legs[0].end_address);
              } else {
                // //console.log("precondicion error");
                //reject(new Error("Error de precondición"));
                resolve("Error");
              }
            } catch (error) {
              // //console.log(error);
              //reject(error);
              resolve("Error");
            }
          } else {
            //reject(new Error("Error en la solicitud de ruta"));
            resolve("Error");
          }
        }
      );
    });
  };
 */
  /*
  const validarDirecciones = async (validation0, validation1, validation2) => {
    // //console.log(validation0, validation1, validation2);
    if (validation0 && validation1 && validation2) {
      const dir0Elements = validation0.split(",");
      const dir1Elements = validation1.split(",");
      const dir2Elements = validation2.split(",");
      let coincidencias = 0;

      for (let i = 0; i < dir0Elements.length; i++) {
        const dir0Words = dir0Elements[i].trim().split(" ");

        for (let j = 0; j < dir1Elements.length; j++) {
          const dir1Words = dir1Elements[j].trim().split(" ");

          for (let k = 0; k < dir2Elements.length; k++) {
            const dir2Words = dir2Elements[k].trim().split(" ");

            for (let x = 0; x < dir0Words.length; x++) {
              if (
                dir1Words.includes(dir0Words[x]) &&
                dir2Words.includes(dir0Words[x])
              ) {
                coincidencias++;
                break;
              }
            }
          }
        }
      }

      if (coincidencias >= 2) {
        return "OK";
      } else {
        return "Error";
      }
    }
  };
*/
  const validarDirecciones = async (validation0, validation1) => {
    if (validation0 && validation1) {
      const dir0Elements = validation0.split(",");
      const dir1Elements = validation1.split(",");
      let coincidencias = 0;

      for (let i = 0; i < dir0Elements.length; i++) {
        const dir0Words = dir0Elements[i].trim().split(" ");

        for (let j = 0; j < dir1Elements.length; j++) {
          const dir1Words = dir1Elements[j].trim().split(" ");

          for (let x = 0; x < dir0Words.length; x++) {
            if (dir1Words.includes(dir0Words[x])) {
              coincidencias++;
              break;
            }
          }
        }
      }

      if (coincidencias >= 2) {
        return "OK";
      } else {
        return "Error";
      }
    }
  };

  function calcularDistancia(lat1, lon1, lat2, lon2) {
    //console.log(lat1, lon1, lat2, lon2);
    // Radio de la Tierra en metros
    const radioTierra = 6371000;

    // Convertir las coordenadas a radianes
    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    // Diferencia de latitudes y longitudes
    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = lon2Rad - lon1Rad;

    // Calcular la distancia utilizando la fórmula de Haversine
    const a =
      Math.sin(deltaLat / 2) ** 2 +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distancia = radioTierra * c;

    return distancia;
  }

  function toRadians(grados) {
    return grados * (Math.PI / 180);
  }

  let apiResultsGeocode = await HandleGetDirectiones(adressDirection);

  if (!apiResultsGeocode) {
    // //console.log("error apiResultsGeocode");
  }

  let responsePlaceApi = await buscarDireccionPLlaceApi(apiResultsGeocode);

  if (!responsePlaceApi) {
    // //console.log("error responsePlaceApi");
  }

  //let responseRouteApi = await buscarDireccionesEnRuta(responsePlaceApi);

  if (responsePlaceApi) {
    let respomseValidationes = await validarDirecciones(
      dirValidation0,
      dirValidation1
    );

    if (respomseValidationes === "OK") {
      return {
        response: "OK",
        newDir: dirValidation0,
        newGeoRef: { lat: lat1, lng: lon1 },
        Validarobjeto: Validarobjeto,
      };
    }
    if (respomseValidationes === "Error") {
      const distancia = calcularDistancia(lat1, lon1);
      if (distancia <= 50) {
        return {
          response: "OK",
          newDir: dirValidation0,
          newGeoRef: { lat: lat1, lng: lon1 },
          Validarobjeto: Validarobjeto,
        };
      } else {
        return { response: "Error" };
      }
    }
  }
  return { response: "null" };
}

export default getValidationAdressGeoRef;
