import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const getOrdesrDispachtByAny = async (serahrCodeDataImput) => {
  const collections = [
    process.env.REACT_APP_COL_USERS_ORDERS,
    process.env.REACT_APP_COL_ORDERS,
    process.env.REACT_APP_COL_FALABELLA_ORDERS,
  ];

  const attributes = [
    "id",
    "do",
    "lpn",
    "int_f12",
    "key",
    "int_lpn",
    "orderID",
  ];

  const tryGetDoc = async (collection, id) => {
    const orderRef = doc(db, collection, id);
    const docSnapshot = await getDoc(orderRef);
    return docSnapshot.exists() ? docSnapshot.data() : null;
  };

  const tryQueryByAttributes = async (collectionName, attribute, value) => {
    console.log(collectionName, attribute, value);
    const colRef = collection(db, collectionName);
    const q = query(colRef, where(attribute, "==", value));
    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        console.log("result :", querySnapshot[0]);
        return querySnapshot.docs[0].data();
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  try {
    for (let collection of collections) {
      let documentData;

      // Intenta obtener el documento por ID
      documentData = await tryGetDoc(collection, "F" + serahrCodeDataImput);
      if (!documentData) {
        documentData = await tryGetDoc(collection, serahrCodeDataImput);
      }

      if (documentData) {
        console.log(
          `Documento existe en colección ${collection} con ID ${serahrCodeDataImput}`
        );
        return documentData;
      }

      // Intenta obtener el documento por atributo
      for (let attribute of attributes) {
        documentData = await tryQueryByAttributes(
          collection,
          attribute,
          serahrCodeDataImput
        );
        if (documentData) {
          console.log(
            `Documento encontrado en colección ${collection} con atributo ${attribute}: ${serahrCodeDataImput}`
          );
          return documentData;
        }
      }
    }
  } catch (error) {
    console.error("Error buscando el documento:", error);
    return null;
  }

  console.log("No se encuentra el documento en ninguna colección.");
  return null;
};

export default getOrdesrDispachtByAny;
