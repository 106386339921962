import React from "react";

import { Form, Container } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FadeInOut from "../animations/FadeInOut";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import SwipeableDrawerControlOrders from "../SwipeableDrawerControlOrders/SwipeableDrawerControlOrders";
import { useEffect } from "react";

const FormRouteInfoBicci = (props) => {
  const dispatch = useDispatch();
  const mapRef = React.useRef();

  const [sortedArray, setSortedArray] = React.useState(null);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const [showEditO, setShowEditO] = React.useState(false);
  const toggleShowEditO = () => setShowEditO(!showEditO);

  const [reiniciandoStateServicie, setReiniciandoStateServicie] =
    React.useState(false);

  const [showEditD, setShowEditD] = React.useState(false);
  const toggleShowEditD = () => setShowEditD(!showEditD);

  const [showEditOpcions, setShowEditOpcions] = React.useState(false);
  const toggleShowEditOpctions = () => setShowEditOpcions(!showEditOpcions);

  const [realTimeisActive, setRealTimeisActive] = React.useState(false);

  const [originAdress, setoriginadress] = React.useState(false);

  const [origin, setorigin] = React.useState(false);

  const [destination, setDestination] = React.useState(false);

  const [destinationAdress, setDestinationAdress] = React.useState(false);

  const [localRouteConfig, setRouteConfig] = React.useState({
    upDateByRiderPosition: props.viewConfig.upDateByRiderPosition,
    viewPositionsLabels: props.viewConfig.viewPositionsLabels,
    viewIds: props.viewConfig.viewIds,
    nextStops: props.viewConfig.nextStops,
    alertsEmojis: props.viewConfig.alertsEmojis,
    alertProxmitiPoints: props.viewConfig.alertProxmitiPoints,
    maspStyleUserPreferent: props.viewConfig.maspStyleUserPreferent,
  });

  useEffect(() => {
    if (localRouteConfig) {
      if (localRouteConfig.upDateByRiderPosition === false) {
        props.updateState("origin", { lat: -33.459119, lng: -70.598923 });
      }
      props.setApliConfig(localRouteConfig);
    }
  }, [localRouteConfig]);

  useEffect(() => {
    setSortedArray(props.apiResponseOrderLabelsMenu);
  }, [props && props.apiResponseOrderLabelsMenu]);

  useEffect(() => {}, [localRouteConfig]);

  const handleSubmitFrom = () => {
    props.updateState("origin", origin);
    props.updateState("originAdress", originAdress);
  };
  const handleSubmitFromD = () => {
    props.updateState("destination", destination);
    props.updateState("destinationAdress", destinationAdress);
  };

  // const callReorderLabels = async (
  //   apiResponseOrderLabels,
  //   currentRuteDataDataOrdesBicciByRiderGroups
  // ) => {
  //   let newDataOrdesBicciByRiderGroups = [];
  //   for (let n = 0; n < apiResponseOrderLabels.length; n++) {
  //     let elementsNewOrder =
  //       apiResponseOrderLabels[n].routes[0].waypoint_order;
  //     let DataChange = currentRuteDataDataOrdesBicciByRiderGroups[n];

  //     for (let i = 0; i < elementsNewOrder.length; i++) {
  //       let value = elementsNewOrder.indexOf(i);
  //       DataChange[i].position = value;
  //     }

  //     DataChange.sort((a, b) => a.position - b.position);

  //     newDataOrdesBicciByRiderGroups.push(DataChange);
  //   }

  //   let newDataOrdesBicciByRider = [];
  //   let cont = 1;

  //   for (let x = 0; x < newDataOrdesBicciByRiderGroups.length; x++) {
  //     let grupsElement = newDataOrdesBicciByRiderGroups[x];

  //     for (let y = 0; y < grupsElement.length; y++) {
  //       grupsElement[y].position = cont++;
  //       newDataOrdesBicciByRider.push(grupsElement[y]);
  //     }
  //   }

  //   newDataOrdesBicciByRider.sort((a, b) => a.position - b.position);

  //   setdataOrdesBicciByRider(newDataOrdesBicciByRider);
  //   setApiResponseOrderLabelsMenu(newDataOrdesBicciByRider);
  // };

  const handleSubmitFromOptiosn = () => {
    //console.log("handleSubmitFromOptiosn");
  };

  const Cargando = () => {
    return (
      <div>
        {/* <CircularProgress /> */}

        <Container id="smallspiner" className="row">
          <div className="center-smallspiner">
            <img
              alt=""
              src="/ebiexlogosvg_animated.svg"
              className="smallspiner rotating"
            />
            <h3>Enviando...</h3>
          </div>
        </Container>
      </div>
    );
  };

  const changeOption = (e) => {
    // e.preventDefault();
    console.log(e.target.name);

    switch (e.target.name) {
      case "upDateByRiderPosition":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "viewPositionsLabels":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "viewIds":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "nextStops":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "alertsEmojis":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "alertProxmitiPoints":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "maspStyleUserPreferent":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      default:
        break;
    }
  };

  // console.log(localRouteConfig);
  return reiniciandoStateServicie !== true ? (
    <Container id="Container-top" className="Container-top-routes">
      <Card
        className="typeMiniforms-routes"
        sx={{
          margintop: "5px",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          backgroundColor: "transparent",
          background: "transparent",
        }}
      >
        <Form>
          <div id="topControlRoutes">
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"} //success
              variant="contained"
              onClick={props.handleReloadClick}
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {<SyncIcon />}
            </Button>
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"} //success
              variant="contained"
              onClick={toggleShowEditOpctions}
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.originadress ? <SettingsIcon /> : <ModeEditIcon />}
            </Button>
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"} //success
              variant="contained"
              onClick={toggleShowEditO}
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.originadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Origen
            </Button>
            <Button
              className="btn-control-route"
              color={props.destinationadress ? "success" : "secondary"}
              onClick={toggleShowEditD}
              variant="contained"
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.destinationadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Destino
            </Button>
            <SwipeableDrawerControlOrders
              dataordesbiccibyrider={props.dataOrdesBicciByRider}
              // orderslist={props.orderslist}
              originadress={props.originadress}
              destinationadress={props.destinationadress}
              dataordersexcepcions={props.dataordersexcepcions}
              dataOrderDeliverySusses={props.dataOrderDeliverySusses}
            />
          </div>
        </Form>
        <FadeInOut show={showEditOpcions} duration={500}>
          <Card>
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Seleccione configuraciones de vistas.</>}
              title={<div>Ajustes personales</div>}
            />
            <Divider />

            <Container
              className="formcontent routesfromIni"
              id="containerroutesfromIni"
            >
              <Form onSubmit={handleSubmitFromOptiosn}>
                <Form.Group
                  sx={{
                    display: "flex",
                    justifycontent: "space-between",
                    alignitems: "flex-end",
                    padding: "5px",
                  }}
                >
                  {localRouteConfig ? (
                    <>
                      <table className="Options-table m-2">
                        <tbody>
                          <tr>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="upDateByRiderPosition"
                                  name="upDateByRiderPosition"
                                  checked={
                                    localRouteConfig.upDateByRiderPosition
                                  }
                                  onChange={changeOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="option-label">
                                Actualizar desde mi posición
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="viewPositionsLabels"
                                  name="viewPositionsLabels"
                                  checked={localRouteConfig.viewPositionsLabels}
                                  onChange={changeOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="option-label">
                                Ver Posiciones
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="viewIds"
                                  name="viewIds"
                                  checked={localRouteConfig.viewIds}
                                  onChange={changeOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="option-label">
                                Ver Id de paquete
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="Options-table">
                        <tbody>
                          <tr>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="nextStops"
                                  name="nextStops"
                                  checked={localRouteConfig.nextStops}
                                  onChange={changeOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="option-label">
                                Ver sugerencias de próxima parada
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="alertsEmojis"
                                  name="alertsEmojis"
                                  checked={localRouteConfig.alertsEmojis}
                                  onChange={changeOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="option-label">
                                Habilitar alertas, proximidad a punto de entrega
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <div>
                                <Button
                                  sx={{
                                    margin: "2px",
                                    borderRadius: "3px",
                                    backgroundColor: "#591e8f",
                                    color: "#fff",
                                    height: "30px",
                                  }}
                                  type="sutmit"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => setShowEditOpcions(false)}
                                >
                                  Guardar!
                                </Button>
                                <Button
                                  sx={{
                                    margin: "2px",
                                    color: "#F33107",
                                    height: "30px",
                                  }}
                                  onClick={() => setShowEditOpcions(false)}
                                >
                                  Cancelar
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* <div className="Routes-wrapper">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="alertProxmitiPoints"
                            name="alertProxmitiPoints"
                            checked={localRouteConfig.alertProxmitiPoints}
                            onChange={changeOption}
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="realtime-label h2-withe"></span>
                        {localRouteConfig.alertProxmitiPoints === true ? (
                          <div>
                            <span>Avisarme si existe un próximo cercano</span>
                          </div>
                        ) : (
                          <div>
                            <span>Avisarme si existe un próximo cercano</span>
                          </div>
                        )}
                      </div> */}
                      {/* <div className="Routes-wrapper">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="maspStyleUserPreferent"
                            name="maspStyleUserPreferent"
                            checked={localRouteConfig.maspStyleUserPreferent}
                            onChange={changeOption}
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="realtime-label h2-withe"></span>
                        {localRouteConfig.maspStyleUserPreferent === true ? (
                          <div>
                            <span>Cambiar color del mapa</span>
                          </div>
                        ) : (
                          <div>
                            <span>Cambiar color del mapa</span>
                          </div>
                        )}
                      </div> */}
                    </>
                  ) : null}
                </Form.Group>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                ></Box>
              </Form>
            </Container>

            <Divider />
          </Card>
        </FadeInOut>

        <FadeInOut show={showEditO} duration={500}>
          <Card>
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Origen actual.</>}
              title={<div>{props.originadress} </div>}
            />
            <Divider />

            <Container
              className="formcontent routesfromIni"
              id="containerroutesfromIni"
            >
              <hr></hr>

              <Form onSubmit={handleSubmitFrom}>
                <Form.Group widths="equal" sx={{ padding: "5px" }}>
                  Usar mi posicion actual
                  <SearchOrigin
                    panTo={panTo}
                    setoriginadress={setoriginadress}
                    setorigin={setorigin}
                    dispatch={dispatch}
                    props={props}
                    // toUserName={toUserName}
                    // setToAddress={setToAddress}
                    // setToAdressPhone={setToAdressPhone}
                    // setToUserEmail={setToUserEmail}
                    // setToAdressName={setToAdressName}
                    // setToAdressNumAddress={setToAdressNumAddress}
                    // setToAdressCity={setToAdressCity}
                    // setToAdressCountry={setToAdressCountry}
                    // setToAdressComuna={setToAdressComuna}
                    // setToAdressRegion={setToAdressRegion}
                    // setToCompanyAsignament={setToCompanyAsignament}
                    // setToPropietary={setToPropietary}
                    // setToAdressComent={setToAdressComent}
                    // setToGeoNumAddressLat={setToGeoNumAddressLat}
                    // setToGeoNumAddressLng={setToGeoNumAddressLng}
                    // setToAdressNumAddressTemp={setToAdressNumAddressTemp}
                    id="imputSearchFromOrigen"
                  />
                  <Button
                    sx={{
                      margin: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#591e8f",
                      color: "#fff",
                    }}
                    type="sutmit"
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditO(false)}
                  >
                    Confirmar Origen!
                  </Button>
                  <Button
                    sx={{ margin: "2px", color: "#F33107" }}
                    onClick={() => setShowEditO(false)}
                  >
                    Cancelar
                  </Button>
                </Form.Group>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                ></Box>
              </Form>
            </Container>

            <Divider />
          </Card>
        </FadeInOut>

        <FadeInOut show={showEditD} duration={500}>
          <Card
            className="typeMiniforms-routes"
            sx={{
              margintop: "5px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Destino actual.</>}
              title={<div>{props.destinationadress} </div>}
            />
            <Divider />

            <Container
              className="formcontent  routesfromIni"
              id="containerroutesfromIorig"
            >
              <hr></hr>

              <Form onSubmit={handleSubmitFromD}>
                <Form.Group widths="equal" sx={{ padding: "5px" }}>
                  <Search
                    panTo={panTo}
                    dispatch={dispatch}
                    props={props}
                    setDestination={setDestination}
                    setDestinationAdress={setDestinationAdress}
                    id="handleSubmitFromD"
                  />
                  <Button
                    sx={{
                      margin: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#591e8f",
                      color: "#fff",
                    }}
                    type="sutmit"
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditD(false)}
                  >
                    Confirmar destino!
                  </Button>
                  <Button
                    sx={{ margin: "2px", color: "#F33107" }}
                    onClick={() => setShowEditD(false)}
                  >
                    Cancelar
                  </Button>
                </Form.Group>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                ></Box>
              </Form>
            </Container>

            <Divider />
          </Card>
        </FadeInOut>

        <Card
          className="typeMiniforms-routes"
          sx={{
            margintop: "5px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          {localRouteConfig.nextStops === true ? (
            <>
              <CardHeader
                sx={{
                  padding: "5px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
                subheader={<>Sugerencia de proxima parada.</>}
                title={
                  <div>
                    {" "}
                    {sortedArray && sortedArray.length > 0 ? (
                      <div>
                        <div>{sortedArray[0].id}</div>
                        <div>{sortedArray[0].toAddress}</div>
                      </div>
                    ) : null}
                  </div>
                }
              />
              <Divider />

              <Container sx={{ maxHeight: "50px" }}>
                <hr></hr>
              </Container>

              <Divider />
            </>
          ) : null}
        </Card>
      </Card>
    </Container>
  ) : (
    <div>
      <Cargando />
    </div>
  );
};

function Search({
  panTo,
  dispatch,
  props,
  setDestination,
  setDestinationAdress,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      setDestinationAdress(address);

      const { lat, lng } = await getLatLng(results[0]);
      setDestination({ lat: lat, lng: lng });
      //panTo({ lat: lat, lng: lng });
    } catch (error) {
      console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de destino"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
function SearchOrigin({ panTo, dispatch, props, setorigin, setoriginadress }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      setoriginadress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setorigin({ lat: lat, lng: lng });
      //panTo({ lat: lat, lng: lng });
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de origen"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default FormRouteInfoBicci;
