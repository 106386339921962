
import React, { useEffect, useRef } from 'react'

export default function useSeo  (description) {
    
const prevDescripcion =useRef(document.querySelector('meta[name="description"]').getAttribute('content'))

useEffect(() => {

    const metaDescripcion = document.querySelector('meta[name="description"]')
    const previusDescripcion = prevDescripcion.current
    
    if(description){
        metaDescripcion.setAttribute('content',description.description)
     
    }

    return () =>  metaDescripcion.setAttribute('content',previusDescripcion)

}, [description])



}

