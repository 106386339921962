import React, { useState, useContext, useMemo } from "react";
import { Container } from "semantic-ui-react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";
import { Card, Divider, CardHeader, LinearProgress } from "@mui/material";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import Moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Form, Input } from "semantic-ui-react";

import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import * as GuiasReportePDF from "../Guias/GuiasReportePDF";
//import ExportExcel from "react-export-excel";
import ModalAccionsDispacth from "../../../components/ModalAccionsDispacth/ModalAccionsDispacth";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import ToolDashSeachByDaRang from "../../../components/Dashboard/dashboard/ToolDashSeachByRang/ToolDashSeachByDaRang";
import ToolDashOrdersContainerBilling from "../../../components/Dashboard/dashboard/ToolDashOrdersContainerBilling/ToolDashOrdersContainerBilling";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import ToolsOrdersActions from "../../../components/Dashboard/dashboard/ToolsOrdersActions/ToolsOrdersActions";
import ToolDashUpdatePayments from "../../../components/Dashboard/dashboard/ToolDashUpdatePayments/ToolDashUpdatePayments";

import ToolDashOrdersFilterState from "../../../components/Dashboard/dashboard/ToolDashOrdersFilterState/ToolDashOrdersFilterState";
import ToolDashOrdersFilterDriver from "../../../components/Dashboard/dashboard/ToolDashOrdersFilterDriver/ToolDashOrdersFilterDriver";
import ToolDashOrdersFilterComunes from "../../../components/Dashboard/dashboard/ToolDashOrdersFilterComunes/ToolDashOrdersFilterComunes";

import GraficasChart from "../../../components/GraficasChart/GraficasChart";
import GraficasChartLine from "../../../components/GraficasChartLine/GraficasChartLine";
import GraficasChartLineDaysMonths from "../../../components/GraficasChartLineDaysMonths/GraficasChartLineDaysMonths";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import useGetDataCollaboratorByCompany from "../../../hooks/useGetDataCollaboratorByCompany";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import DashIconsResumenDash from "../../../components/Dashboard/DashIconsResumenDash";
import useGlobalConfig from "../../../hooks/globalConfig";
import ToolDashAddHistory from "../../../components/Dashboard/dashboard/ToolDashAddHistory/ToolDashAddHistory";

var ordersForAccions = [];
var dataOrdersForAccions = [];
var listadeItemsNoMach = [];
var popUpAccion = "-";

const TableroEmpresa = (props) => {
  const { MONTHS, YEARS, typesHistorials } = useGlobalConfig();

  const { widthHeight } = useContext(ScreenSizeContext);

  const userUid = useSelector((store) => store.usuario.user.uid);

  const [companyId, setcompanyId] = useState("");

  const [userOrders, setUserOrders] = React.useState(null);

  const [userDataOrders, setDataUserOrders] = React.useState([]);

  const [restoreUserOrders, setRestoreUserOrdes] = React.useState([]);

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [optionDriverFilter, setOptionDriverFilter] = React.useState("");
  const [optionLocalityFilter, setOptionLocalityFilter] = React.useState("");
  const [optionStatusBillingFilter, setOptionStatusBillingFilter] =
    React.useState("");
  const [optionOrderStatusFilter, setOptionOrderStatusFilter] =
    React.useState("");
  const [optionOrigenFilter, setOptionOrigenFilter] = React.useState("");

  const [initDateBillingFilter, setInitDateStatusBillingFilter] =
    React.useState(Moment(Date.now()).format("YYYY-MM-DD"));

  const [endtDateBillingFilter, setEndDateStatusBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );
  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [locaDataOrdesFalabella, setLocalDataOrdesFalabella] =
    React.useState(null);
  const [bicciOrders, setBicciOrders] = React.useState(null);

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    React.useState(false);

  const [showPopUp, setShowPopUp] = React.useState(false);

  const [selected, setSelected] = React.useState(null);
  const [origenGeoCode, setOrigenGeoCode] = React.useState(null);
  const [adressOrigin, setAdressOrigin] = React.useState(null);

  const [SelecteDestiny, setSelecteDestiny] = React.useState(null);
  const [destinyGeoCode, setDestinyGeoCode] = React.useState(null);
  const [adressDestiny, setAdressDestiny] = React.useState(null);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [alertMensajes, setAlertMensajes] = React.useState(null);

  const [progressValue, setProgressValue] = React.useState(null);

  const [dataDocsMetricsTotalDelivery, setDataDocsMetricsTotalDelivery] =
    React.useState(null);

  const [dataDocsMetricsTotalCreate, setDataDocsMetricsTotalCreate] =
    React.useState(null);

  const [
    dataDocsMetricsTotalDeliveryClear,
    setDataDocsMetricsTotalDeliveryClear,
  ] = React.useState(null);

  const [
    dataDocsMetricsTotalDeliveryExcepxions,
    setDataDocsMetricsTotalDeliveryExcepxions,
  ] = React.useState(null);

  const [
    dataDocsMetricsTotalDevolutionsExcepxions,
    setDataDocsMetricsTotalDevolutionsExcepxions,
  ] = React.useState(null);

  const [
    dataDocsMetricsTotalLostByExceptions,
    setDataDocsMetricsTotalLostByExceptions,
  ] = React.useState(null);

  const [
    dataDocsMetricsTotalLostByLackOfEvidence,
    setDataDocsMetricsTotalLostByLackOfEvidence,
  ] = React.useState(null);

  const [dataCurrentMohstDelivery, setCurrentMohstDelivery] =
    React.useState(null);

  const [dateMonths, setDateMonths] = React.useState(
    Moment(Date.now()).format("YYYY-MM")
  );

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  const { userdata } = useObtenerDatosdeCliente(userUid);

  const { riderCantDataRider } = useGetDataRiderByCompany(companyId, null);
  const { listCollaboratorDataByCompany } =
    useGetDataCollaboratorByCompany(companyId);

  const { listVehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  console.log(listVehiclesDataByCompany);

  const styleState = {
    C0E: "alert alert-primary",
    C01: "alert alert-info",
    C02: "alert alert-info",
    C03: "alert alert-info",
    R01: "alert alert-success",
    R02: "alert alert-success",
    R03: "alert alert-success",
    R04: "alert alert-success",
    R05: "alert alert-success",
    R06: "alert alert-success",
    CA1: "alert alert-danger",
    CA2: "alert alert-danger",
    CA3: "alert alert-danger",
    CA4: "alert alert-danger",
  };

  const [cantListItemsUsers, setcantListItemsUsers] = React.useState({
    Disponibles: 0,
    Invitados: 0,
    Bloqueados: 0,
  });

  const [cantListItemsVehicles, setcantListItemsVehicles] = React.useState({
    Conectados: 0,
    Disponibles: 0,
    Bloqueados: 0,
  });

  const [cantListItemsDrivers, setcantListItemsDrivers] = React.useState({
    Activos: 0,
    Conectados: 0,
    Invitados: 0,
    Inactivos: 0,
  });

  const [cantListItems, setcantListItems] = React.useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const listItemsDrivers = [
    { id: "Activos" },
    { id: "Conectados" },
    { id: "Invitados" },
    { id: "Inactivos" },
  ];

  const listItemsVehicles = [
    { id: "Conectados" },
    { id: "Bloqueados" },
    { id: "Disponibles" },
  ];

  const listItemsUsers = [
    { id: "Disponibles" },
    { id: "Invitados" },
    { id: "Bloqueados" },
  ];

  const dataallOrdersfind3 = collection(
    db,
    process.env.REACT_APP_COL_USERS_ORDERS
  );

  React.useEffect(() => {
    if (companyId && initDateBillingFilter && endtDateBillingFilter) {
      findOrdesrDispacht(
        companyId,
        initDateBillingFilter,
        endtDateBillingFilter
      );

      findOrdesrDispachtFalabella(
        companyId,
        initDateBillingFilter,
        endtDateBillingFilter
      );

      findMetricDataHistorial(
        companyId,
        initDateBillingFilter,
        endtDateBillingFilter
      );

      // findOrdesrDispachtStatusBicci(
      //   companyId,
      //   initDateBillingFilter,
      //   endtDateBillingFilter
      // );
    }
  }, [companyId, initDateBillingFilter, endtDateBillingFilter]);

  React.useEffect(() => {
    findOrdesrDispachtFalabellaMonths(
      companyId,
      initDateBillingFilter,
      endtDateBillingFilter
    );

    findOrdesrDispachtMonths(
      companyId,
      initDateBillingFilter,
      endtDateBillingFilter
    );
  }, [companyId && initDateBillingFilter && endtDateBillingFilter, dateMonths]);

  React.useEffect(() => {
    if (userdata && userdata.userCompanyID) {
      setcompanyId(userdata.userCompanyID);
      if (userdata.companyCollaborator === "falabella") {
        setcompanyId(`${process.env.REACT_APP_BXTOKETN}`);
      }
    }
  }, [userdata]);

  React.useEffect(() => {
    if (userdata) {
      if (listCollaboratorDataByCompany) {
        setcantListItemsUsers(listCollaboratorDataByCompany);
      }
      if (listVehiclesDataByCompany) {
        setcantListItemsVehicles(listVehiclesDataByCompany);
      }
      if (riderCantDataRider) {
        setcantListItemsDrivers(riderCantDataRider);
      }
    }
  }, [
    riderCantDataRider &&
      listCollaboratorDataByCompany &&
      listVehiclesDataByCompany,
  ]);

  // filterFordataRecuest estatus  =>   findOrdesrDispachtCompanyByDate(); , Accions selecListItames(listIdstoSelct)

  function getOpcionsFilters(ordersPropietary) {
    setProgressValue(75);
    let driversGroupingNames = [];
    let selectDriversGroup = [];

    let comunasGroupingNames = [];
    let selectComunasGroup = [];

    let billingStatesNames = [];
    let selectbillingStatesGroup = [];

    let orderStatesNames = [];
    let selectOrderStatesGroup = [];

    let origenStatesNames = [];
    let selectOrigenStatesGroup = [];

    for (let i = 0; i < ordersPropietary.length; i++) {
      driversGroupingNames.push(ordersPropietary[i].driverName);
      comunasGroupingNames.push(
        formateValue(ordersPropietary[i].COMUNA2.toUpperCase())
      );
      if (ordersPropietary[i].PaymentProforma) {
        billingStatesNames.push(ordersPropietary[i].PaymentProforma);
      } else {
        billingStatesNames.push("Pendiente por pago");
      }
      orderStatesNames.push(ordersPropietary[i].bicciStatus);
      origenStatesNames.push(ordersPropietary[i].BODEGANOMBRE);
    }

    for (let i = 0; i < driversGroupingNames.length; i++) {
      const item = driversGroupingNames[i];
      if (
        !selectDriversGroup.includes(driversGroupingNames[i]) &&
        driversGroupingNames[i] !== "" &&
        driversGroupingNames[i] !== null
      ) {
        selectDriversGroup.push(item);
      }
    }
    for (let i = 0; i < comunasGroupingNames.length; i++) {
      if (
        !selectComunasGroup.includes(
          formateValue(comunasGroupingNames[i].toUpperCase())
        ) &&
        comunasGroupingNames[i] !== "" &&
        comunasGroupingNames[i] !== null
      ) {
        selectComunasGroup.push(comunasGroupingNames[i]);
      }
    }

    for (let i = 0; i < billingStatesNames.length; i++) {
      if (!selectbillingStatesGroup.includes(billingStatesNames[i])) {
        selectbillingStatesGroup.push(billingStatesNames[i]);
      }
    }
    for (let i = 0; i < orderStatesNames.length; i++) {
      if (
        !selectOrderStatesGroup.includes(orderStatesNames[i]) &&
        orderStatesNames[i] !== "" &&
        orderStatesNames[i] !== null
      ) {
        selectOrderStatesGroup.push(orderStatesNames[i]);
      }
    }
    for (let i = 0; i < origenStatesNames.length; i++) {
      if (
        !selectOrigenStatesGroup.includes(origenStatesNames[i]) &&
        origenStatesNames[i] !== "" &&
        origenStatesNames[i] !== null
      ) {
        selectOrigenStatesGroup.push(origenStatesNames[i]);
      }
    }

    setOptionDriverFilter(selectDriversGroup);
    setOptionLocalityFilter(selectComunasGroup);
    setOptionStatusBillingFilter(selectbillingStatesGroup);
    setOptionOrderStatusFilter(selectOrderStatesGroup);
    setOptionOrigenFilter(selectOrigenStatesGroup);
  }

  const findOrdesrDispacht = async (
    companyId,
    initDateBillingFilter,
    endtDateBillingFilter
  ) => {
    setProgressValue(50);
    try {
      const initDateBillingFilterObj = new Date(initDateBillingFilter);
      const endtDateBillingFilterObj = new Date(endtDateBillingFilter);

      function restaUno(fecha2) {
        fecha2.setDate(fecha2.getDate());
        return fecha2;
      }

      function sumarUno(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        return res;
      }
      function sumarDos(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 2);
        return res;
      }

      var rangInit = sumarUno(initDateBillingFilterObj);

      var rangFinal = sumarDos(endtDateBillingFilterObj);

      if (initDateBillingFilter !== endtDateBillingFilter) {
        callNormal();
      }
      if (initDateBillingFilter === endtDateBillingFilter) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.orderID]) {
              uniqueObjects[obj.orderID] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          ordersPropietary.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setProgressValue(100);
          setRestoreUserOrdes(filteredArray);
          setUserOrders(ordersPropietary);
          setDataUserOrders(ordersPropietary);
          setResumenTable(ordersPropietary);
          getOpcionsFilters(ordersPropietary);
          setProgressValue(100);
        } catch (error) {
          console.log("error 345");
          console.log(error);
        }
      }

      async function callIgual() {
        try {
          /*
          const ordesrDispachtBll = await ordesrDispachtRef
            .where("companyId", "==", companyId)
            .where("timeStamp", ">=", rangInit)
            .where("timeStamp", "<=", rangFinal)
            .get();
          const ordersPropietary = ordesrDispachtBll.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
*/
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.SOC]) {
              uniqueObjects[obj.SOC] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          ordersPropietary.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setUserOrders(ordersPropietary);
          setRestoreUserOrdes(filteredArray);
          setDataUserOrders(ordersPropietary);
          setResumenTable(ordersPropietary);
          getOpcionsFilters(ordersPropietary);
          setProgressValue(100);
        } catch (error) {
          console.log("error 346");
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findMetricDataHistorial = async () => {
    const metricsCollections = collection(
      db,
      process.env.REACT_APP_COL_METRICS_HISTORY
    );
    const documentHistorial = await getDocs(metricsCollections);
    const docHistorial = documentHistorial.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    function compararFechas(a, b) {
      if (a.year < b.year) {
        return -1;
      } else if (a.year > b.year) {
        return 1;
      } else {
        if (a.monthNum < b.monthNum) {
          return -1;
        } else if (a.monthNum > b.monthNum) {
          return 1;
        } else {
          return 0;
        }
      }
    }

    let docsMetricsTotalDelivery = docHistorial.filter(
      (item) => item.type === "totalDelyvery"
    );
    docsMetricsTotalDelivery.sort(compararFechas);
    setDataDocsMetricsTotalDelivery(docsMetricsTotalDelivery);

    let docsMetricsTotalCreate = docHistorial.filter(
      (item) => item.type === "totalCreate"
    );
    docsMetricsTotalCreate.sort(compararFechas);
    setDataDocsMetricsTotalCreate(docsMetricsTotalCreate);

    let docsMetricsTotalDeliveryClear = docHistorial.filter(
      (item) => item.type === "delyveryClear"
    );
    docsMetricsTotalDeliveryClear.sort(compararFechas);
    setDataDocsMetricsTotalDeliveryClear(docsMetricsTotalDeliveryClear);

    let docsMetricsTotalDeliveryExcepxions = docHistorial.filter(
      (item) => item.type === "delyveryExcepxions"
    );
    docsMetricsTotalDeliveryExcepxions.sort(compararFechas);
    setDataDocsMetricsTotalDeliveryExcepxions(
      docsMetricsTotalDeliveryExcepxions
    );

    let docsMetricsTotalDevolutionsExcepxions = docHistorial.filter(
      (item) => item.type === "devolutionsExcepxions"
    );
    docsMetricsTotalDevolutionsExcepxions.sort(compararFechas);
    setDataDocsMetricsTotalDevolutionsExcepxions(
      docsMetricsTotalDevolutionsExcepxions
    );

    let docsMetricsLostByExceptions = docHistorial.filter(
      (item) => item.type === "lostByExceptions"
    );
    docsMetricsLostByExceptions.sort(compararFechas);
    setDataDocsMetricsTotalLostByExceptions(docsMetricsLostByExceptions);

    let docsMetricsTotalLostByLackOfEvidence = docHistorial.filter(
      (item) => item.type === "lostByLackOfEvidence"
    );
    docsMetricsTotalLostByLackOfEvidence.sort(compararFechas);
    setDataDocsMetricsTotalLostByLackOfEvidence(
      docsMetricsTotalLostByLackOfEvidence
    );
  };

  const findOrdesrDispachtFalabella = async (
    companyId,
    initDateBillingFilter,
    endtDateBillingFilter
  ) => {
    setProgressValue(65);
    const initDateBillingFilterObj = new Date(initDateBillingFilter);
    const endtDateBillingFilterObj = new Date(endtDateBillingFilter);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate());
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(initDateBillingFilterObj);
    var rangFinal = sumarDos(endtDateBillingFilterObj);

    if (initDateBillingFilter !== endtDateBillingFilter) {
      callNormal();
    }
    if (initDateBillingFilter === endtDateBillingFilter) {
      callIgual();
    }

    async function callNormal() {
      try {
        /*
        const ordesrDispachtFb = await allOrdersRefFalabella
          .where("createdAt", ">=", rangInit)
          .where("createdAt", "<=", rangFinal)
          .get();

        var dataOrdesFalabella = ordesrDispachtFb.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
*/
        const allOrdersRefFalabella = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );
        const q = query(
          allOrdersRefFalabella,
          where("createdAt", ">=", rangInit),
          where("createdAt", "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesFalabella = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdesFalabella.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );

        setLocalDataOrdesFalabella(dataOrdesFalabella);
      } catch (error) {
        console.log(error);
      }
    }
    async function callIgual() {
      try {
        /*
        const ordesrDispachtFb = await allOrdersRefFalabella
          .where("createdAt", ">=", rangInit)
          .where("createdAt", "<=", rangFinal)
          .get();

        var dataOrdesFalabella = ordesrDispachtFb.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        */
        const allOrdersRefFalabella = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );
        const q = query(
          allOrdersRefFalabella,
          where("createdAt", ">=", rangInit),
          where("createdAt", "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesFalabella = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdesFalabella.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );

        setLocalDataOrdesFalabella(dataOrdesFalabella);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const findOrdesrDispachtStatusBicci = async (
    companyId,
    initDateBillingFilter,
    endtDateBillingFilter
  ) => {
    const initDateBillingFilterObj = new Date(initDateBillingFilter);
    const endtDateBillingFilterObj = new Date(endtDateBillingFilter);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate());
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(initDateBillingFilterObj);
    var rangFinal = sumarDos(endtDateBillingFilterObj);

    if (initDateBillingFilter !== endtDateBillingFilter) {
      callNormal();
    }
    if (initDateBillingFilter === endtDateBillingFilter) {
      callIgual();
    }

    async function callNormal() {
      try {
        const ordesrDispachtBi = await dataallOrdersfind3
          .where("createdAt", ">=", rangInit)
          .where("createdAt", "<=", rangFinal)
          .get();

        var dataOrdesBicci = ordesrDispachtBi.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {
        console.log(error);
      }
    }
    async function callIgual() {
      try {
        const ordesrDispachtBi = await dataallOrdersfind3
          .where("createdAt", ">=", rangInit)
          .where("createdAt", "<=", rangFinal)
          .get();

        var dataOrdesBicci = ordesrDispachtBi.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const findOrdesrDispachtMonths = async (
    companyId,
    initDateBillingFilter,
    endtDateBillingFilter
  ) => {
    if (companyId) {
      // Creamos un objeto Date con la fecha seleccionada
      const selectedDate = new Date(dateMonths);

      // Obtenemos el primer día del mes seleccionado
      const startOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        1
      );

      // Obtenemos el último día del mes seleccionado
      const endOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 2,
        0
      );

      // Imprimimos el rango de fechas en la consola

      const ordersDispachtRef = collection(
        db,
        process.env.REACT_APP_COL_ORDERS
      );
      const q = query(
        ordersDispachtRef,
        where("companyId", "==", companyId),
        where("timeStamp", ">=", startOfMonth),
        where("timeStamp", "<=", endOfMonth)
      );

      const querySnapshot = await getDocs(q);

      const ordersPropietary = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const uniqueObjects = {};

      for (const obj of ordersPropietary) {
        if (!uniqueObjects[obj.orderID]) {
          uniqueObjects[obj.orderID] = obj;
        }
      }

      const filteredArray = Object.values(uniqueObjects);

      ordersPropietary.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

      const groupedData = {};

      filteredArray.forEach((item) => {
        const date = new Date(item.timeStamp.seconds * 1000)
          .toISOString()
          .substring(0, 10);
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(item);
      });

      const dates = Object.keys(groupedData);

      const resultCurrentDelivery = [];

      dates.forEach((date) => {
        const orders = groupedData[date];
        const numOrders = orders.length;
        const dateObj = new Date(date);
        const monthNum = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(dateObj);
        resultCurrentDelivery.push({
          id: date,
          type: "currentDelivery",
          monthNum: monthNum,
          cant: numOrders,
          year: year,
          month: month,
        });
      });
      setCurrentMohstDelivery(resultCurrentDelivery);
    }
  };

  const onChageRangeMonths = (e) => {
    setDateMonths(e.target.value);
  };

  const findOrdesrDispachtFalabellaMonths = async (
    companyId,
    initDateBillingFilter,
    endtDateBillingFilter
  ) => {
    // Creamos un objeto Date con la fecha seleccionada
    const selectedDate = new Date(dateMonths);

    // Obtenemos el primer día del mes seleccionado
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      1
    );

    // Obtenemos el último día del mes seleccionado
    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 2,
      0
    );

    try {
      const allOrdersRefFalabella = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const q = query(
        allOrdersRefFalabella,
        where("createdAt", ">=", startOfMonth),
        where("createdAt", "<=", endOfMonth)
      );

      const querySnapshot = await getDocs(q);

      const dataOrdesFalabella = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dataOrdesFalabella.sort((a, b) =>
        a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
      );
    } catch (error) {
      console.log(error);
    }
  };

  function formateValue(value) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const setResumenTable = (dataOrdes) => {
    let Creadas = dataOrdes.filter(
      (item) => parseInt(item.dispachtCurrentStatus) === 0
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => parseInt(item.dispachtCurrentStatus) > 7
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        parseInt(item.dispachtCurrentStatus) > 1 ||
        parseInt(item.dispachtCurrentStatus) < 8
    );
    // cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter(
      (item) => parseInt(item.dispachtCurrentStatus) > 0
    );
    //cantListItems.Incidencias = Incidencias.length
    // cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const onChageRange = async (e) => {
    if (e.target.name === "rangoInicio") {
      setInitDateStatusBillingFilter(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDateStatusBillingFilter(e.target.value);
    }
    if (e.target.name === "staticDateBillingFilter") {
      setInitDateStatusBillingFilter(e.target.value);
      setEndDateStatusBillingFilter(e.target.value);
      setStaticDateBillingFilter(e.target.value);
    }
  };

  const onChageSubFilter = async (e) => {
    setProgressValue(75);
    let dataUniverse = restoreUserOrders;
    let param = e.target.value;

    if (e.target.name === "currentStatusBillingFilter") {
      if (param === "Pendiente por pago") {
        let dataFiltere = dataUniverse.filter((item) => !item.PaymentProforma);

        setUserOrders(dataFiltere);
      } else {
        let dataFiltere = dataUniverse.filter(
          (item) => item.PaymentProforma === param
        );

        setUserOrders(dataFiltere);
      }
    }

    if (e.target.name === "currentOrderStatusFilter") {
      let dataFiltere = dataUniverse.filter(
        (item) => item.bicciStatus === parseInt(param)
      );

      setUserOrders(dataFiltere);
    }

    if (e.target.name === "currentDriverFilter") {
      let dataFiltere = dataUniverse.filter(
        (item) => item.driverName === param
      );

      setUserOrders(dataFiltere);
    }

    if (e.target.name === "currentOrigenFilter") {
      let dataFiltere = dataUniverse.filter(
        (item) => item.BODEGANOMBRE === param
      );

      setUserOrders(dataFiltere);
    }

    if (e.target.name === "currentLocalityFilter") {
      let dataFiltere = dataUniverse.filter(
        (item) => formateValue(item.COMUNA2.toUpperCase()) === param
      );

      setUserOrders(dataFiltere);
    }
    setProgressValue(100);
  };

  const claerFilter = async () => {
    setUserOrders(restoreUserOrders);
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  const handleAllSelectorderID = () => {};

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <CardHeader title="Estatus Guias y Despachos Compañía" />
            {/* <button className='m-2 btn btn-primary'> Buscar Cambios</button> */}

            {progressValue !== 100 ? (
              <LinearProgress
                value={progressValue}
                valueBuffer={100}
                variant="buffer"
              ></LinearProgress>
            ) : null}
            <Divider />

            <div>
              {itemsDataOrdersForAccions ? (
                <ToolsOrdersActions
                  movil={widthHeight.width < 950 ? true : false}
                  setSelected={setSelected}
                  setOrigenGeoCode={setOrigenGeoCode}
                  setAdressOrigin={setAdressOrigin}
                  adressOrigin={adressOrigin}
                  setSelecteDestiny={setSelecteDestiny}
                  setDestinyGeoCode={setDestinyGeoCode}
                  setAdressDestiny={setAdressDestiny}
                  adressDestiny={adressDestiny}
                  Buttondisable={Buttondisable}
                  isShowPopUp={isShowPopUp}
                  setButtondisable={setButtondisable}
                ></ToolsOrdersActions>
              ) : null}
            </div>

            <Container className="formcontent" id="micuentafromIni">
              <div className="headerContent">
                <div className="dashcontentDashIconsResumen">
                  <Grid.Column computer={16} mobile={16} tablet={16}>
                    <DashIconsResumenDash
                      itemsD={listItemsDrivers}
                      cantD={cantListItemsDrivers}
                      itemsV={listItemsVehicles}
                      cantV={cantListItemsVehicles}
                      itemsU={listItemsUsers}
                      cantU={cantListItemsUsers}
                    ></DashIconsResumenDash>
                  </Grid.Column>
                </div>
                <div>
                  {/* <Card className="formcontenttools">
                    <CardHeader subheader="Herramientas"></CardHeader>
                    <Container className="formcontent" id="micuentafromIni">
                      <ToolDashUpdatePayments
                        setAlertMensajes={setAlertMensajes}
                        setDataPayment={setDataPayment}
                        locaDataOrdesFalabella={locaDataOrdesFalabella}
                        userOrders={userOrders}
                        dataPayment={dataPayment}
                        LoadDataEstatus={LoadDataEstatus}
                        setLoadDataEstatus={setLoadDataEstatus}
                        bicciOrders={bicciOrders}
                        setRender={setRender}
                        render={render}
                      ></ToolDashUpdatePayments>
                      <ToolDashSeachByDaRang
                        initDate={initDateBillingFilter}
                        endDate={endtDateBillingFilter}
                        onChageRange={onChageRange}
                      ></ToolDashSeachByDaRang>
                    </Container>
                  </Card>{" "} */}
                  <Card className="formcontenttools">
                    <CardHeader
                      subheader={
                        <div>
                          "Filtros sobre periodo de tiempo seleccionado"{" "}
                          <button
                            onClick={() => claerFilter()}
                            className="btn-add-guide-small m-2"
                          >
                            {" "}
                            Limpiar filtro{" "}
                          </button>
                        </div>
                      }
                    ></CardHeader>
                    <Container className="formcontent" id="micuentafromIni">
                      <ToolDashOrdersFilterState
                        onChageSubFilter={onChageSubFilter}
                        optionOrderStatusFilter={optionOrderStatusFilter}
                        optionStatusBillingFilter={optionStatusBillingFilter}
                      ></ToolDashOrdersFilterState>
                      <ToolDashOrdersFilterDriver
                        onChageSubFilter={onChageSubFilter}
                        optionDriverFilter={optionDriverFilter}
                      ></ToolDashOrdersFilterDriver>

                      <ToolDashOrdersFilterComunes
                        onChageSubFilter={onChageSubFilter}
                        optionOrigenFilter={optionOrigenFilter}
                        optionLocalityFilter={optionLocalityFilter}
                      ></ToolDashOrdersFilterComunes>
                      {/* 
                      <ToolDashAddHistory
                        months={MONTHS}
                        years={YEARS}
                        typesHistorials={typesHistorials}
                      ></ToolDashAddHistory>
                       */}
                      {/* <ToolDashOpsReportBilling
                        setWiewPrePdf={setWiewPrePdf}
                        wiewPrePdf={wiewPrePdf}
                        userOrders={userOrders}
                        exporExcelFile={exporExcelFile}
                        Moment={Moment}
                      ></ToolDashOpsReportBilling> */}
                      {/* 
                  <Card className="typeMiniformsBars">
                    <div className="m-2">
                      <h3>Reportes</h3>
                      <div className="m-2">
                        <button
                          onClick={(e) => setWiewPrePdf(!wiewPrePdf)}
                          className="btn btn-ebiex m-2"
                        >
                          {wiewPrePdf === true
                            ? "Regresar a vista Web"
                            : "Crear reporte PDF"}
                        </button>
                      </div>
                      <div className="m-2">
                        {userOrders && userOrders.length > 0 ? (
                          <ExcelFile
                            element={
                              <button
                                onClick={(e) => exporExcelFile()}
                                className="btn btn-ebiex m-2"
                              >
                                {" "}
                                Extracion especial Excel
                              </button>
                            }
                            filename={
                              "Pendientes por pago Bicci Ordenes" +
                              Moment(Date.now()).format("YYYY-MM-DD")
                            }
                          >
                            <ExcelSheet data={userOrders} name="DATA">
                              <ExcelColumn
                                label="SOC"
                                value="SOC"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="F12"
                                value="F12"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="ESTATUS"
                                value="bicciStatus"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="ESTATUS FALABELLA"
                                value="falabella_status"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="REGION"
                                value="COMUNA2"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="FECHA RETIRO"
                                value={"dateText"}
                              ></ExcelColumn>
                              <ExcelColumn
                                label="Tipo de servicio"
                                value="PaymentType_service"
                              ></ExcelColumn>

                              <ExcelColumn
                                label="TAMAÑO PRODUCTO"
                                value="TAMANOPRODUCTO"
                              ></ExcelColumn>
                              <ExcelColumn
                                label="COSTO"
                                value="PaymentValor"
                              ></ExcelColumn>
                            </ExcelSheet>
                          </ExcelFile>
                        ) : null}
                      </div>
                    </div>
                  </Card>
                   */}
                    </Container>
                  </Card>
                </div>

                {companyId ? (
                  <div>
                    <div>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Historial de despachos</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Productos entregados"}
                              dataHistorial={dataDocsMetricsTotalDelivery}
                              color={"rgb(118, 27, 204)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Entregas limpias</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Entregados limpias"}
                              dataHistorial={dataDocsMetricsTotalDeliveryClear}
                              color={"rgb(7, 163, 25)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Historial de Entregados Con Retraso</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Entregados Con retraso"}
                              dataHistorial={
                                dataDocsMetricsTotalDeliveryExcepxions
                              }
                              color={"rgb(0, 255, 30)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Historial de Devoluciones</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Devoluciones por Cliente"}
                              dataHistorial={
                                dataDocsMetricsTotalDevolutionsExcepxions
                              }
                              color={"rgb(255, 157, 0)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Historial de Perdidas</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Perdidas"}
                              dataHistorial={
                                dataDocsMetricsTotalLostByExceptions
                              }
                              color={"rgb(255, 81, 0)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                      <Card className="typeMiniformsGrafsline">
                        <div className="m-2">
                          <div>Perdias no envidencia</div>
                          {dataDocsMetricsTotalDelivery ? (
                            <GraficasChartLine
                              props={props}
                              name={"Perdidas"}
                              dataHistorial={
                                dataDocsMetricsTotalLostByLackOfEvidence
                              }
                              color={"rgb(255, 81, 0)"}
                            ></GraficasChartLine>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                    </div>
                    <div>
                      <Card className="GraficasChartLineDaysMonths">
                        <div className="m-2">
                          <div>
                            <span>Ver paquetes mensuales</span>{" "}
                            <Form.Field
                              id="FinalDate"
                              max={Moment(Date.now()).format("YYYY-MM")}
                              control={Input}
                              format={"YYYY-MM"}
                              type="month"
                              name="rangoFinal"
                              onChange={(e) => onChageRangeMonths(e)}
                              value={dateMonths}
                            />
                          </div>
                          {dataCurrentMohstDelivery ? (
                            <GraficasChartLineDaysMonths
                              props={props}
                              name={"Paquetes por día"}
                              dataHistorial={dataCurrentMohstDelivery}
                              color={"rgb(118, 27, 204)"}
                            ></GraficasChartLineDaysMonths>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>

                      <Card className="GraficasChartLineDaysMonthsDount">
                        <div className="m-2">
                          <div> Entregadas </div>
                          {dataDocsMetricsTotalDeliveryClear ? (
                            <GraficasChart
                              dataHistorial={dataDocsMetricsTotalDeliveryClear}
                              name={"Mes"}
                              props={props}
                              labels={"create"}
                            ></GraficasChart>
                          ) : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                ) : (
                  <>No posee metricas en este momento , Mostrar vista pervia</>
                )}
              </div>
              {wiewPrePdf === true ? (
                <PDFViewer style={{ width: "100%", height: "90vh" }}>
                  {" "}
                  <GuiasReportePDF
                    userOrders={userOrders}
                    initDateBillingFilter={initDateBillingFilter}
                    endtDateBillingFilter={endtDateBillingFilter}
                  ></GuiasReportePDF>
                </PDFViewer>
              ) : (
                <Grid.Row>
                  {userOrders && locaDataOrdesFalabella && bicciOrders ? (
                    <></>
                  ) : (
                    <LinearProgress></LinearProgress>
                  )}
                  <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={16}
                    className="dash-container-colmun "
                  >
                    {locaDataOrdesFalabella && userOrders ? (
                      <ToolDashOrdersContainerBilling
                        setAlertMensajes={setAlertMensajes}
                        movil={widthHeight.width < 950 ? true : false}
                        userOrders={userOrders}
                        handleAllSelectorderID={handleAllSelectorderID}
                        styleState={styleState}
                      ></ToolDashOrdersContainerBilling>
                    ) : (
                      <>No hay ordenes que mostrar</>
                    )}
                  </Grid.Column>

                  <Grid.Column
                    computer={6}
                    mobile={16}
                    tablet={16}
                    className="dash-container-colmun"
                  >
                    {/* <LatestProducts  />  */}
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* <DashIconsResumenDispachs items={listItems} cant={cantListItems} ></DashIconsResumenDispachs>   */}
            </Container>

            <Divider />
          </Card>

          <div></div>
        </Grid.Column>
      </Grid.Row>

      <div>
        {alertMensajes ? (
          <div className="alert alert-danger">{alertMensajes}</div>
        ) : null}
      </div>
      {showPopUp ? (
        <ModalAccionsDispacth
          opened={showPopUp}
          popUpAccion={popUpAccion}
          interfaceOrigen={"facturationOrdes"}
          ordersForAccions={ordersForAccions}
          companyId={companyId}
          ridersDataByCompany={ridersDataByCompany}
          dataOrdersForAccions={dataOrdersForAccions}
          adressOrigin={adressOrigin}
          origenGeoCode={origenGeoCode}
          adressDestiny={adressDestiny}
          destinyGeoCode={destinyGeoCode}
        >
          {" "}
        </ModalAccionsDispacth>
      ) : null}
    </Grid>
  );

  function Search({ setSelected, setAdressOrigin, setOrigenGeoCode }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressOrigin(address);

        const { lat, lng } = await getLatLng(results[0]);

        setOrigenGeoCode({ lat: lat, lng: lng });

        setButtondisable(false);
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Buscar Origen..."
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  function SearchDestiny({
    setSelecteDestiny,
    setAdressDestiny,
    setDestinyGeoCode,
    adressDestiny,
  }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      let element = [];
      try {
        const results = await getGeocode({ address });

        setAdressDestiny(address);
        const { lat, lng } = await getLatLng(results[0]);

        setDestinyGeoCode({ lat: lat, lng: lng });
      } catch (error) {
        //console.log("😱 Error: ", error);
        //console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-3 imput-modal-routeMaps"
            id="imputSearchFromOrigenRoutes"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={"Buscar destino.."}
          />
          <ComboboxPopover className="comboboxList-ebiex-modal">
            <ComboboxList className="comboboxList-ebiex-modal-items">
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
};

export default TableroEmpresa;
