import PropTypes from "prop-types";
// @mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
// hooks
import { useResponsive } from "../../hooks/useResponsive.js";
import LocalizationProviderContext from "../../context/LocalizationProviderContext.jsx";
import { useEffect, useState } from "react";
import { tr } from "date-fns/locale";

// ----------------------------------------------------------------------

export default function CustomDateRangePicker({
  title = "Seleccione la fecha o rango de su busqueda",
  variant,
  //
  startDate,
  endDate,
  //
  onChangeStartDate,
  onChangeEndDate,
  //
  open,
  onClose,
  //
  error,
  setNewDate,
}) {
  const mdUp = useResponsive("up", "md");
  const isCalendarView = variant === "calendar" ? true : false;

  return (
    <LocalizationProviderContext>
      <Dialog
        fullWidth
        maxWidth={isCalendarView ? false : "xs"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            ...(isCalendarView && {
              maxWidth: 720,
            }),
          },
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        <DialogContent
          sx={{
            ...(isCalendarView &&
              mdUp && {
                overflow: "unset",
              }),
          }}
        >
          <Stack
            justifyContent="center"
            spacing={isCalendarView ? 3 : 2}
            direction={isCalendarView && mdUp ? "row" : "column"}
            sx={{ pt: 1 }}
          >
            {isCalendarView ? (
              <>
                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    borderColor: "divider",
                    borderStyle: "dashed",
                  }}
                >
                  <DateCalendar
                    value={startDate}
                    onChange={onChangeStartDate}
                  />
                </Paper>

                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    borderColor: "divider",
                    borderStyle: "dashed",
                  }}
                >
                  <DateCalendar value={endDate} onChange={onChangeEndDate} />
                </Paper>
              </>
            ) : (
              <>
                <DatePicker
                  label="Start date"
                  value={startDate}
                  onChange={onChangeStartDate}
                />

                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={onChangeEndDate}
                />
              </>
            )}
          </Stack>

          {error && (
            <FormHelperText error sx={{ px: 2 }}>
              End date must be later than start date
            </FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>

          <Button disabled={error} variant="contained" onClick={setNewDate}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProviderContext>
  );
}

CustomDateRangePicker.propTypes = {
  error: PropTypes.bool,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  // variant: PropTypes.oneOf(["input", "calendar"]),
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  setNewDate: PropTypes.func,
};
