import {auth , db} from '../firebase'

// data inicial
const dataInicial = {
    loading: false,
    activo: false,
}

const LOADING_MOMENT = 'LOADING_MOMENT'
const EDIT_SERV_EBIEX = 'EDIT_SERV_EBIEX'
const EDIT_SERV_ERROR = 'EDIT_SERV_ERROR'
const EDIT_SERV_EBIEX_EXITO = 'EDIT_SERV_EBIEX_EXITO'
const FIND_SERV_ERROR = 'FIND_SERV_ERROR'

export default function adminServicesEbiex (state = dataInicial, action) {
    switch(action.type){
        case LOADING_MOMENT:
            return {...state, loading: true}
        case EDIT_SERV_EBIEX:
             return {...state, user: action.payload }
        case EDIT_SERV_EBIEX_EXITO:
                return {...state }
         case EDIT_SERV_ERROR:
                return { loading: false }
        // case SUPPRESS_USERUSER_EBIEX:
        //     return {...state, error:action.error}
        // case CREATE_COMPANYS_BY_USER:
        //     return {...state, loading: false, company: action.payload, levelUp: true}
        case FIND_SERV_ERROR:
            return {error: action.mjsstatus}
        default:
            return state
        }
    }


export const createNewService = (data) => async(dispatch) => {
        
      //  console.log(data.uid)
        dispatch({
            type: LOADING_MOMENT
        })
        try {   
            // console.log(data.username)
           
            // await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(data.uid).update({
            //  username:data.username,
            //  userLastName:data.userLastName,
            //  userPhone:data.phone,
            //  userPreferenCont:data.userPreferenCont,
            //  userRut:data.userRut,})
             //console.log('Datos Actualizados')
             //userType:data.userType,
             //     email:data.email,
             //     checked:data.checked,
        } catch (error) {
             console.log(error)
             dispatch({
             type: EDIT_SERV_ERROR 
             })
        }
    
}
 
