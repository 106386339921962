import React from "react";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import { Form, Input, Container } from "semantic-ui-react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import moment from "moment";

class CrearServico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      createBy: props.props.userUid,
      createDate: "",
      isActive: true,
      name: "",
      description: "",
      position: 0,
      imagenes: "",
      type: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
  }

  // const crearServico=()=>{}
  // const limpiarFormularioa=()=>{}
  render() {
    return (
      <Container className="dashcontent">
        <Grid celled="internally" id="servicioscontent">
          <div>
            <h2> Servicios</h2>
          </div>
          <Grid.Row>
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo..."
                title={
                  <>
                    <h3>Agregar nuevo servicio</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="id del Servicio"
                      type="text"
                      name="productId"
                      onChange={this.handleChange}
                      value={this.state.productId}
                    />

                    <Form.Field
                      control={Input}
                      type="text"
                      name="name"
                      label="Nombre del Servicio"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      label="Descripción del Servicio"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                    <Form.Field
                      control={Input}
                      type="select"
                      label="Crear activo"
                      name="type"
                      onChange={this.handleChange}
                      value={this.state.isActive}
                    />
                  </Form.Group>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>

            <Card className="typeMiniforms">
              <CardHeader
                subheader="Crear nuevo tarifario personalizado"
                title={
                  <>
                    <h3>Servicios de entregas</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form onSubmit={this.handleSubmit}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Código</th>
                        <th scope="col">Status</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.dataRates.map((element) => (
                        <tr key={element.id}>
                          <td>{element.name}</td>
                          <td>{element.id}</td>
                          {/* <td >
                          {
                          element.isActive?('true'):('False')
                          }</td> */}
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItem}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>Editar</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.state.productId.length === 0) {
      return;
    }
    try {
      await db
        .collection(`${process.env.REACT_APP_COL_AVIABLE_ARTYPE}`)
        .doc(this.state.productId)
        .set({
          productId: this.state.productId,
          createBy: this.state.createBy,
          createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
          date: Date.now(),
          isActive: this.state.isActive,
          name: this.state.name,
          description: this.state.description,
          imagenes: this.state.imagenes,
          position: parseInt(this.state.position),
        });
    } catch (error) {
      // console.log(error)
    }
  }
  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  async handleChangeItem(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const id = target.id;
    const checked = target.checked;
    // console.log(checked)
    // console.log(name)
    // console.log(value)
    // console.log(id)
    // console.log(e)
    await db
      .collection(`${process.env.REACT_APP_COL_AVIABLE_SERV}`)
      .doc(id)
      .update({ isActive: checked });
  }
}

export default CrearServico;
