import * as XLSX from "xlsx";
import Moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const generarReportePickingUtils = async (initDate, endDate) => {
  if (initDate && endDate) {
    // console.log(
    //   Moment(Date(initDate)).format("YYYY-MM-DD"),
    //   Moment(Date(endDate)).format("YYYY-MM-DD")
    // );

    const i = new Date(initDate);
    const e = new Date(endDate);

    const sumarDos = (fecha) =>
      new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate() + 1,
        23,
        59,
        59,
        999
      );
    const formatDate = (fecha) =>
      new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate(),
        23,
        59,
        59,
        999
      );

    const rangFinal = sumarDos(i);
    const rangInitial = formatDate(e);

    const collRef = collection(db, process.env.REACT_APP_COL_PICKER_REGISTER);

    try {
      const q = query(
        collRef,
        where("date", ">=", rangInitial),
        where("date", "<=", rangFinal)
      );
      const querySnapshot = await getDocs(q);
      let dataOrdes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dataOrdes.sort((a, b) => (a.date.seconds < b.date.seconds ? 1 : -1));

      // Unir los objetos con el mismo orderID
      let combinedOrders = {};

      dataOrdes.forEach((order) => {
        const orderId = order.orderID;

        if (!combinedOrders[orderId]) {
          // Si no existe este orderID en el objeto combinado, crearlo
          combinedOrders[orderId] = { ...order };
        } else {
          // Combinar propiedades de ambos tipos de objetos
          combinedOrders[orderId] = {
            ...combinedOrders[orderId],
            ...order, // Agregar o sobrescribir propiedades
          };
        }
      });

      const combinedArray = Object.values(combinedOrders); // Convertir el objeto a un array

      // Generar la matriz para el reporte
      const matrizReporte = [
        [
          "listId",
          "Order ID",
          "Date",
          "BICCI Zone",
          "Company ID",
          "Driver License Plate",
          "Is Picked Up",
          "sobrante",
          "Eliminado",
          "User",
        ],
        ...combinedArray.map((order) => [
          order.listId || "N/A",
          order.orderID || "N/A",
          Moment(order.date.seconds * 1000).format("YYYY-MM-DD HH:mm:ss"),
          order.BicciZone || "N/A",
          order.companyId || "N/A",
          order.driverLicensePlate || "N/A",
          order.isPickedUp ? "Yes" : "No",
          order.sobrante === true ? true : false,
          order.deleted === true ? true : "",
          order.user || "N/A",
        ]),
      ];

      // Crear el libro y la hoja en Excel
      const libro = XLSX.utils.book_new();
      const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
      XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

      XLSX.writeFile(
        libro,
        `Reporte De Picking BICCI ${Moment(initDate).format(
          "YYYY-MM-DD"
        )} al ${Moment(endDate).format("YYYY-MM-DD")}.xlsx`
      );
    } catch (error) {
      console.log(error);
    }
  }
};

export default generarReportePickingUtils;
