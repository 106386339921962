

  import React from 'react'
import { format } from 'date-fns';
import { Document , Page, Text, View, Image, StyleSheet  } from '@react-pdf/renderer';
import { minWidth } from '@mui/system';

const GuiasReportePDF = ( props) => {
    console.log( props)
    const [ totalUnidades , setTotalUnidades] = React.useState(0)
    const COLORS = {
      Creadas:'dashIcon-blue',
      Ruta:'dashIcon-grey',
      Incidencias:'dashIcon-red',
      Finalizadas:'dashIcon-on-line',
      Total:'dashIcon-blue',
    }
    const CANT = {
      Creadas:props.cant.Creadas,
      Ruta:props.cant.Ruta,
      Incidencias:props.cant.Incidencias,
      Finalizadas:props.cant.Finalizadas,
      Total:props.cant.Total,
    }
    const styles = StyleSheet.create({
        page: {  padding:'3%' },
        section: { textAlign: 'letf', margin: 10 },
        img:{ color:'#050505', width:'120px' },
        imgAvatar:{  width:'70px',height:'70px' , borderRadius:'100%'},
        text:{fontSize:'16px', marginTop:'3px'},
        text2:{fontSize:'12px', marginBottom:1},
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 0, 
            marginTop:'10px',
            marginBottom:'30px'
         
          }, 
          tableRow: { 
            margin: "auto", 
            flexDirection: "row" ,
            borderTopWidth:0,
            borderLeftWidth: 0,
            borderRightWidth:0, 
            paddingBottom:'3px',
          }, 
          tableCol: { 
            width: "14%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0,
            borderRightWidth:0, 
            fontWeight:700,
          }, 
          tableColCant: { 
            width: "4%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0,
            borderRightWidth:0, 
            fontWeight:700,
          }, 
          tableColDescipcion: { 
            width: "23%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0,
            borderRightWidth:0, 
            fontWeight:700,
            textAlign:'letf'
          }, 
          tableCell: { 
            marginTop: 5, 
            fontSize: 10,
            margin: "auto", 
            flexDirection: "row" ,
            borderLeftWidth: 0,
            borderRightWidth:0, 
            paddingBottom:'3px',
          },
          tableRowItem: { 
            margin: "auto", 
            flexDirection: "row" ,
           
            borderLeftWidth: 0,
            borderRightWidth:0, 
            paddingBottom:'3px',
          }, 
          tableCellItem: { 
            margin: "auto", 
            marginTop: 5, 
            fontSize: 8, 
            borderLeftWidth: 0,
            borderRightWidth:0, 
            minHeight:'25px'
          },
        
        });

//manejar Props

function getTotalUnidades(data){
  var total = 0
  for (let i = 0; i < data.length; i++) {
    total +=  data[i].UNIDADES
    
  }
  return total 

}

  return (
    <Document>
        <Page size="A4" style={styles.page}>
   
    <View style={styles.section}>
    <Image  
    src={'/static/images/bicciexpress.png'} alt='' 
    style={styles.img} ></Image>
    
  
    <Text  style={styles.text}>Reporte de despachos</Text>
    <Text style={styles.text}> Total de registros: {props.userOrders.length}</Text>
    <Text style={styles.text}> Total de Bultos: {getTotalUnidades(props.userOrders)}</Text> 
    
      <Text  style={styles.text2}> 
       informe con rango de fecha {props.initDateBillingFilter} al {props.endtDateBillingFilter}. 
       </Text>
     
      
        {
          props.userOrders?(
        <View style={styles.table} >
       <View style={styles.tableRow}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>CÓDIGO</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>ORIGEN</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>COMUNA</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>FECHA</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>ESTATUS</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>CONDUCTOR</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>UNIDADES</Text> 
          </View> 
        </View>
   
     

        {props.userOrders.map((element, index) => (
            <View key={index} style={styles.tableRow}>
            
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{element.orderID}</Text> 
                </View>
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{element.BODEGANOMBRE}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{element.CLIENTENOMBRES.toLowerCase()}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{format(element.timeStamp * 1000, 'dd/MM/yy')}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>
                  {element.bicciStatus >= 8?('Finalizado'):null}
                  {element.bicciStatus < 0?('Cancelado'):null}
                  {element.bicciStatus > 0 && element.bicciStatus > 8?('En Ruta'):null}</Text> 
                </View>  
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{element.driverName}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                <Text style={styles.tableCellItem}>{element.UNIDADES}</Text> 
                </View> 
                
            </View> 
            ))                          
        }
       
        
      </View>
        ):null
}


</View>

</Page>
</Document>
  )
}

export default GuiasReportePDF