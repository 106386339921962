import React, { useState } from "react";

//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import FadeInOut from "../../../animations/FadeInOut";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import StoreIcon from "@mui/icons-material/Store";

const MenuGeneralBodega = ({ onNavigate }) => {
  const [showBodegas, setShowsBodegas] = useState(false);

  const opctionWarehouse = [
    "scannerdistribution-v2",
    "incidencia-apelacion",
    "scanner-asignacion",
  ];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <>
      <Chip
        icon={<WarehouseIcon />}
        label=" Gestión de Bodegas"
        variant="outlined"
        clickable
        color="primary"
        onClick={() => setShowsBodegas(!showBodegas)}
        sx={{
          color: "#F8F8F8",
          width: "100%",
          fontSize: "1em",
          padding: "2px",
          marginBottom: "5px",
          backgroundColor: opctionWarehouse.includes(splitLocation[2])
            ? "#07b107"
            : "transparent",
        }}
      />
      <FadeInOut show={showBodegas} duration={500}>
        {/* <hr className="sidebar-hr"></hr> */}
        {/* <NavLink to="/scannerdistribution/app" className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "app"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <QrCodeScannerIcon fontSize="medium" />
            <span className="btn-title">Scanner Distribution</span>
          </div>
        </NavLink> */}
        <NavLink
          to="/dashboard/incidencia-apelacion"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "incidencia-apelacion"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <QrCodeScannerIcon fontSize="medium" />
            <span className="btn-title">Registrar apelaciones</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/scannerdistribution-v2"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "scannerdistribution-v2"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <QrCodeScannerIcon fontSize="medium" />
            <span className="btn-title">Scanner Distribution v2</span>
          </div>
        </NavLink>
        <NavLink to="/dashboard/scanner-asignacion" className="btn-sidebarLink">
          <div
            className={
              splitLocation[2] === "scanner-asignacion"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <QrCodeScannerIcon fontSize="medium" />
            <span className="btn-title">Asignador QR/Bar</span>
          </div>
        </NavLink>
        <hr></hr>
        {/* <NavLink
          to="/dashboard/stores"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "stores"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <StoreIcon fontSize="medium" />
            <span className="btn-title">Sucursales</span>
          </div>
        </NavLink> */}
      </FadeInOut>
    </>
  );
};

export default MenuGeneralBodega;
