async function getLowConcentrations(
  manifesOrders,
  origin,
  endPlaceDestination,
  optimizeWaypointsNodos
) {
  const google = window.google;
  console.log(origin);
  console.log(endPlaceDestination);
  let startPoint = origin;
  let endPoint = endPlaceDestination; //{ lat: -33.4546334, lng: -70.700989 };
  let manifestMatrix;
  const directionsService = new google.maps.DirectionsService();
  manifestMatrix = manifesOrders;
  class dataForCallBacks {
    constructor(
      response,
      type,
      startPointalgorithm,
      endPointalgorithm,
      reOrder_comunasWaintPoint,
      reOrder_ComunasGroupingNames,
      reOrder_responseHOOCKGrupos,
      reOrder_responseHOOCKManifest,
      boundsOne
    ) {
      this._response = response;
      this._type = type;
      this._startPointalgorithm = startPoint;
      this._endPointalgorithm = endPoint;
      this._reOrder_comunasWaintPoint = reOrder_comunasWaintPoint;
      this._reOrder_ComunasGroupingNames = reOrder_ComunasGroupingNames;
      this._reOrder_responseHOOCKGrupos = reOrder_responseHOOCKGrupos;
      this._reOrder_responseHOOCKManifest = reOrder_responseHOOCKManifest;
      this._boundsOne = boundsOne;
    }

    get response() {
      return this._response;
    }

    set response(newResponse) {
      this._response = newResponse;
    }

    get type() {
      return this._type;
    }

    set type(newType) {
      this._type = newType;
    }

    get startPointalgorithm() {
      return this._startPointalgorithm;
    }

    set startPointalgorithm(newStartPointalgorithm) {
      this._startPointalgorithm = newStartPointalgorithm;
    }

    get endPointalgorithm() {
      return this._endPointalgorithm;
    }

    set endPointalgorithm(newEndPointalgorithm) {
      this._endPointalgorithm = newEndPointalgorithm;
    }
    get reOrder_comunasWaintPoint() {
      return this._reOrder_comunasWaintPoint;
    }

    set reOrder_comunasWaintPoint(newReOrder_comunasWaintPoint) {
      this._reOrder_comunasWaintPoint = newReOrder_comunasWaintPoint;
    }
    get reOrder_ComunasGroupingNames() {
      return this._reOrder_ComunasGroupingNames;
    }

    set reOrder_ComunasGroupingNames(newReOrder_ComunasGroupingNames) {
      this._reOrder_ComunasGroupingNames = newReOrder_ComunasGroupingNames;
    }
    get reOrder_responseHOOCKGrupos() {
      return this._reOrder_responseHOOCKGrupos;
    }

    set reOrder_responseHOOCKGrupos(newReOrder_responseHOOCKGrupos) {
      this._reOrder_responseHOOCKGrupos = newReOrder_responseHOOCKGrupos;
    }
    get reOrder_responseHOOCKManifest() {
      return this._reOrder_responseHOOCKManifest;
    }

    set reOrder_responseHOOCKManifest(newReOrder_responseHOOCKManifest) {
      this._reOrder_responseHOOCKManifest = newReOrder_responseHOOCKManifest;
    }
  }

  async function createLocalPonitGroups(manifesOrders) {
    let localManifesOrders = manifesOrders;
    let comunasContenidas = [];
    for (const index in localManifesOrders) {
      comunasContenidas.push(localManifesOrders[index].COMUNA2);
    }

    const pointGroups = {};
    const lowDensityWaipoints = [];

    const uniqueArray = comunasContenidas.filter((element, index) => {
      return comunasContenidas.indexOf(element) === index;
    });

    console.log(uniqueArray);

    const cleanArray = uniqueArray.map((element) => {
      const cleanedElement2 = element.replace(/\s/g, "%").trim().toLowerCase();
      return cleanedElement2;
    });
    console.log(cleanArray);

    for (let n = 0; n < cleanArray.length; n++) {
      let obj = {
        // geoPonitRef: {
        //   lat: 0,
        //   lng: 0,
        // },
        orderIds: [],
        position: 0,
      };
      pointGroups[cleanArray[n]] = obj;
    }

    console.log(pointGroups);

    var countPosition = 0;
    for (const index in localManifesOrders) {
      for (let n = 0; n < cleanArray.length; n++) {
        if (
          cleanArray[n] ===
          localManifesOrders[index].COMUNA2.replace(/\s/g, "%")
            .trim()
            .toLowerCase()
        ) {
          if (!pointGroups[cleanArray[n]].geoPonitRef) {
            let value = countPosition;
            pointGroups[cleanArray[n]].geoPonitRef = {
              lat: localManifesOrders[index].adressGeoNumAddressLatLng.lat,
              lng: localManifesOrders[index].adressGeoNumAddressLatLng.lng,
            };
            pointGroups[cleanArray[n]].position = value;

            let objWaitPoint = {
              stopover: true,
              location: {
                lat: localManifesOrders[index].adressGeoNumAddressLatLng.lat,
                lng: localManifesOrders[index].adressGeoNumAddressLatLng.lng,
              },
              position: value,
            };
            lowDensityWaipoints.push(objWaitPoint);

            countPosition++;
          }
          let ordenesContenidas = pointGroups[cleanArray[n]].orderIds;

          ordenesContenidas.push(localManifesOrders[index].orderID);

          pointGroups[cleanArray[n]].orderIds = ordenesContenidas;
        }
      }
    }
    return pointGroups;
  }
  async function procesarResponseHOOCKGrupos(responseHOOCKGrupos) {
    const arrayOrdenado = Object.entries(responseHOOCKGrupos);
    console.log("ver arrayOrdenado");
    console.log(arrayOrdenado);
    var goupsWaitPoints = [];
    var OrderComunasGroupingNames = [];
    if (responseHOOCKGrupos) {
      for (let i = 0; i < arrayOrdenado.length; i++) {
        for (let n = 0; n < arrayOrdenado.length; n++) {
          if (i === arrayOrdenado[n][1].position) {
            goupsWaitPoints.push({
              stopover: true,
              location: arrayOrdenado[n][1].geoPonitRef,
            });
            console.log(arrayOrdenado[n][0]);
            console.log(arrayOrdenado[n][1]);
            OrderComunasGroupingNames.push(arrayOrdenado[n][0]);
          }
        }
      }
    }
    console.log("result arrayOrdenado");
    console.log(goupsWaitPoints, OrderComunasGroupingNames);
    return { goupsWaitPoints, OrderComunasGroupingNames };
  }
  let pointGroups = await createLocalPonitGroups(manifesOrders);
  console.log(pointGroups);
  const { goupsWaitPoints, OrderComunasGroupingNames } =
    await procesarResponseHOOCKGrupos(pointGroups);

  await directionsService.route(
    {
      origin: startPoint,
      destination: endPoint,
      travelMode: "BICYCLING",
      optimizeWaypoints: true,
      waypoints: goupsWaitPoints,
    },
    function (response, status) {
      if (status === "OK") {
        // directionsDisplay.setDirections(response);

        let re_OrderComunasGroupingNames = [];
        let re_pointGroups = pointGroups;
        let newOrder = response.routes[0].waypoint_order;
        console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
        console.log("Evaluar si es necesario");
        console.log("destination");
        console.log(origin);
        console.log("Evaluar si es necesario ");
        for (let index = 0; index < newOrder.length; index++) {
          re_OrderComunasGroupingNames.push(
            OrderComunasGroupingNames[newOrder[index]]
          );
        }

        for (let index = 0; index < newOrder.length; index++) {
          re_pointGroups[re_OrderComunasGroupingNames[index]].position = index;
        }

        let entries = Object.entries(re_pointGroups).sort(
          (a, b) => a[1].position - b[1].position
        );
        let reOrder_comunasWaintPoint = [];
        console.log(entries);
        for (const [value] of entries) {
          reOrder_comunasWaintPoint.push({
            stopover: true,
            location: {
              lat: value.geoPonitRef.lat,
              lng: value.geoPonitRef.lng,
            },
          });
        }

        if (re_OrderComunasGroupingNames.length < 23) {
          dataForCallBacks.response = response;
          dataForCallBacks.type = "calculateByMultizonesLowDensity";
          dataForCallBacks.startPointalgorithm = startPoint;
          dataForCallBacks.endPointalgorithm = endPoint;
          dataForCallBacks.reOrder_comunasWaintPoint =
            reOrder_comunasWaintPoint;
          dataForCallBacks.reOrder_ComunasGroupingNames =
            re_OrderComunasGroupingNames;
          dataForCallBacks.reOrder_responseHOOCKGrupos = re_pointGroups;
          dataForCallBacks.reOrder_responseHOOCKManifest = manifesOrders;
        } else {
          console.log("error EXCEDE NUMERO DE MICROZONAS");
        }
      } else {
        console.log("error api");
        console.log(status);
      }
    }
  );

  return {
    response: dataForCallBacks.response,
    type: dataForCallBacks.type,
    startPointalgorithm: dataForCallBacks.startPointalgorithm,
    endPointalgorithm: dataForCallBacks.endPointalgorithm,
    reOrder_comunasWaintPoint: dataForCallBacks.reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames: dataForCallBacks.reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos: dataForCallBacks.reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest:
      dataForCallBacks.reOrder_responseHOOCKManifest,
  };
}

export default getLowConcentrations;
