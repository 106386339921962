import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const ReusableFormImportOrdersByDataSDD = ({
  open,
  handleClose,
  setOpen,
  returnDocumentData,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]); // Almacena los errores de validación
  const [dataDocument, setDataDocument] = useState([]);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]); // Almacena el archivo seleccionado
  };

  const fieldConfig = {
    ID: { required: true },
    FECHACARGA: { required: true },
    IDEINTERNAL: { required: true },
    FECHAENTREGA: { required: true },
    TIPODELIVERY: { required: true },
    CODIGOSUCRUSAL: { required: false },
    ORIGEN: { required: true },
    ORIGENLAT: { required: false },
    ORIGENLOG: { required: false },
    DESTINO: { required: true },
    DIR1: { required: true },
    DIR2: { required: false },
    DI2: { required: false },
    COMUNA: { required: true },
    REGION: { required: true },
    PAIS: { required: true },
    LAT: { required: false },
    LOG: { required: false },
    COMENTARIO: { required: false },
    TELEFONO: { required: true },
    EMAIL: { required: true },
    NOMBRECLIENTE: { required: true },
    TIPOPRODUCTO: { required: false },
    CONTENIDO: { required: true },
    SKU: { required: false },
    DESCRPCION: { required: false },
    INSTRUCCIONES: { required: false },
    UNIDADES: { required: true },
    VALOR: { required: true },
    ALTO: { required: true },
    ANCHO: { required: true },
    LARGO: { required: true },
    PESO: { required: true },
    VOLUMEN: { required: true },
  };

  const validateFields = (data) => {
    let errors = [];

    const transformedData = data.map((item) => {
      // Convertimos TELEFONO a string si no lo es
      if (item.TELEFONO && typeof item.TELEFONO !== "string") {
        item.TELEFONO = String(item.TELEFONO);
      }
      return item;
    });

    transformedData.forEach((item, index) => {
      // Recorremos cada campo de la configuración
      Object.keys(fieldConfig).forEach((field) => {
        const isRequired = fieldConfig[field].required;
        const fieldValue = item[field];

        // Si el campo es obligatorio y está vacío o no existe
        if (isRequired && (!fieldValue || fieldValue === "")) {
          errors.push({
            index,
            field,
            message: `Falta el valor de ${field}`,
          });
        }
      });
    });

    setValidationErrors(errors); // Almacena los errores
    return { isValid: errors.length === 0, errors, transformedData }; // Retorna un objeto con la validación, los errores y los datos transformados
  };

  const handleFileSave = async () => {
    if (!file) return;

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const validation = validateFields(jsonData);

    if (validation.isValid) {
      returnDocumentData(
        validation.transformedData,
        validation.errors,
        "ready"
      );
      handleClose();
    } else {
      setDataDocument(validation.transformedData);
      setErroMensaje(validation.errors);
    }
  };

  const hadleEdith = async (data, validation) => {
    returnDocumentData(data, validation, "ready");
    setErroMensaje(null);
    setFile(null);
    setValidationErrors([]); // Almacena los errores de validación
    setDataDocument([]);
    handleClose();
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileSelector,
  } = useDropzone({
    onDrop,
    accept: ".xlsx",
    maxFiles: 1,
    maxSize: 5000000,
    noClick: true,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "90%",
          height: "90vh",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">
          Crear envíos con documento importación
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Button onClick={openFileSelector} color="primary">
          Seleccionar archivo SDD
        </Button>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box
            {...getRootProps()}
            sx={{ border: "2px dashed #ccc", p: 2, minHeight: "450px" }}
          >
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>Archivo listo para guardar: {file.name}</p>}
        {erroMensaje
          ? erroMensaje.map((error, index) => (
              <div key={index}>
                <Typography variant="body2" color="error">
                  {`LINEA ${error.index + 1},  Error en el campo ${
                    error.field
                  }: ${error.message}`}
                </Typography>
              </div>
            ))
          : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        {erroMensaje ? (
          <Button
            onClick={() => hadleEdith(dataDocument, erroMensaje)}
            color="primary"
            disabled={!file}
          >
            Editar
          </Button>
        ) : null}
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrdersByDataSDD;
