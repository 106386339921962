import React from "react";
import { updateDoc, GeoPoint } from "firebase/firestore";
import { doc } from "firebase/firestore";
import { db } from "../firebase";

const activateDevolutionUser = async () => {
  const userRef = doc(
    db,
    process.env.REACT_APP_COL_USERS_DRIVERS,
    process.env.REACT_APP_INT_USER_BICCI_DEVOLUTION
  );
  let latitud = -33.459179118760936;
  let longitud = -70.5988997353241;
  try {
    await updateDoc(userRef, {
      online: true,
      driverLocation: new GeoPoint(latitud, longitud),
    });
  } catch (error) {
    console.log(error);
  }

  return;
};

export default activateDevolutionUser;
