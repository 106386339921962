import { doc } from "firebase/firestore";
import { db } from "../../firebase";
import { updateDoc } from "firebase/firestore";

const updateOrdersBicciexpressEstatusBilling = async (dataUpdate, id) => {
  const orderRef = doc(
    db,
    process.env.REACT_APP_COL_ORDERS,
    "F" + id.toString()
  );

  try {
    await updateDoc(orderRef, {
      statusCreate: dataUpdate.statusCreate,
      int_bicciStatus: dataUpdate.bicciStatus,
      status: dataUpdate.bicciStatus,
      driverName: dataUpdate.driverName,
      driverID: dataUpdate.driverID,
      createdAt: dataUpdate.createdAt,
      int_falabella_status: dataUpdate.falabella_status,
      userDniReceptor: dataUpdate.userDniReceptor,
      userNameReceptor: dataUpdate.userNameReceptor,
      observationReceptor: dataUpdate.observationReceptor,
      observationAdmin: dataUpdate.observationAdmin,
      updatedAt: dataUpdate.updatedAt,
    });
  } catch (error) {
    console.log("error al actualizar el documento :", id);
    console.log(error);
  }
};

export default updateOrdersBicciexpressEstatusBilling;
