import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

// components
import Iconify from "../../../Iconify/Iconify";

// ----------------------------------------------------------------------
// dashboard/guiasfalabella
export default function OrdersTableFiltersResult({
  filters,
  onFilters,
  onResetFilters,
  results,
  orderDataFiltered,
  ...other
}) {
  const handleRemoveStatus = () => {
    onFilters("status", "all");
  };

  const handleRemoveRole = (inputValue) => {
    const newValue = filters.role.filter((item) => item !== inputValue);
    onFilters("role", newValue);
  };
  const handleRemoveService = (inputValue) => {
    const newValue = filters.service.filter((item) => item !== inputValue);
    onFilters("service", newValue);
  };
  const handleRemoveRider = (inputValue) => {
    const newValue = filters.service.filter((item) => item !== inputValue);
    onFilters("rider", newValue);
  };

  const getOrderStatuses = (driverName) => {
    // Filtra las órdenes que pertenecen al conductor específico
    const driverOrders = orderDataFiltered.filter(
      (order) => order.driverName === driverName
    );

    // Agrupa y cuenta las órdenes por estado
    const statuses = driverOrders.reduce((acc, order) => {
      if (!acc[order.int_falabella_status]) {
        acc[order.int_falabella_status] = {
          name: order.int_falabella_status,
          count: 0,
        };
      }
      acc[order.int_falabella_status].count++;
      return acc;
    }, {});

    // Convierte el objeto a un array
    return Object.values(statuses);
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: "body2" }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: "text.secondary", ml: 0.25 }}>
          Encontrados
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {filters.status !== "all" && (
          <Block label="Status:">
            <Chip
              size="small"
              label={filters.status}
              onDelete={handleRemoveStatus}
            />
          </Block>
        )}

        {filters.orderStatus !== "allOrdes" && (
          <Block label="Status:">
            <Chip
              size="small"
              label={filters.orderStatus}
              onDelete={handleRemoveStatus}
            />
          </Block>
        )}

        {!!filters.role.length && (
          <Block label="Role:">
            {filters.role.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveRole(item)}
              />
            ))}
          </Block>
        )}
        {filters.service.length && (
          <Block label="Service:">
            {filters.service.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveService(item)}
              />
            ))}
          </Block>
        )}
        {filters.rider.length && (
          <Block label="Rider:">
            {filters.rider.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveRider(item)}
              />
            ))}
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button>
      </Stack>
      {filters.rider.length && (
        <Block label="Rider:">
          {filters.rider.map((item) => (
            <div key={item}>
              <Chip
                label={item}
                size="small"
                onDelete={() => handleRemoveRider(item)}
              />
              <Stack spacing={1.5} direction="column">
                <Box sx={{ typography: "body" }}></Box>
                {getOrderStatuses(item).map((status, index) => (
                  <Chip
                    key={`${status.name}${status.count}`}
                    label={`${status.name}: ${status.count}`}
                    size="small"
                  ></Chip>
                ))}
              </Stack>
            </div>
          ))}
        </Block>
      )}
    </Stack>
  );
}

OrdersTableFiltersResult.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  results: PropTypes.number,
  orderDataFiltered: PropTypes.array,
};

// ----------------------------------------------------------------------

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: "hidden",
        borderStyle: "dashed",
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: "subtitle2" }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
