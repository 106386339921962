import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import React, { useEffect, useLayoutEffect, useState } from "react";

const useFindOrderBicciexpress = (companyId, initDate, endDate) => {
  const [dataOrders, setDataOrdersFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if ((companyId, initDate, endDate)) {
      findOrderBicciexpress(companyId, initDate, endDate);
    }
  }, [companyId, initDate, endDate]);

  useEffect(() => {}, [loading]);

  const findOrderBicciexpress = async (companyId, initDate, endDate) => {
    setLoading(true);
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 1);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const ordersPropietaryDevolution = ordersPropietary.filter(
            (item) => item.status !== "Devolucion"
          );

          const uniqueObjects = {};

          // for (const obj of ordersPropietaryDevolution) {
          //   if (!uniqueObjects[obj.SOC]) {
          //     uniqueObjects[obj.SOC] = obj;
          //   }
          // }

          const filteredArray = Object.values(ordersPropietaryDevolution);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setDataOrdersFilter(filteredArray);
          setLoading(false);
        } catch (error) {
          console.log("error 345");
          setLoading(false);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const ordersPropietaryDevolution = ordersPropietary.filter(
            (item) => item.status !== "Devolucion"
          );

          // const uniqueObjects = {};

          // for (const obj of ordersPropietaryDevolution) {
          //   if (!uniqueObjects[obj.SOC]) {
          //     uniqueObjects[obj.SOC] = obj;
          //   }
          // }

          const filteredArray = Object.values(ordersPropietaryDevolution);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setDataOrdersFilter(filteredArray);
          setLoading(false);
        } catch (error) {
          console.log("error 346");
          //console.log(error);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return { dataOrders, loading };
};

export default useFindOrderBicciexpress;
