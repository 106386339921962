import { ConstructionOutlined } from "@mui/icons-material";
import { auth, db } from "../firebase";
import {
  updateDoc,
  setDoc,
  doc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth";
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
  itemid: "",
};

const LOADING_MOMENT = "LOADING_MOMENT";
const EDIT_USER_EBIEX = "EDIT_USER_EBIEX";
const EDIT_USER_ERROR = "EDIT_USER_ERROR";
const EDIT_USER_EBIEX_EXITO = "EDIT_USER_EBIEX_EXITO";
const ADDCARD_USER_EBIEX = "ADDCARD_USER_EBIEX";
const USUARIO_RESET_LOGIN_EMAIL = "USUARIO_RESET_LOGIN_EMAIL";
// const SUPPRESS_USERUSER_EBIEX = 'SUPPRESS_USERUSER_EBIEX'
// const CREATE_COMPANYS_BY_USER =  'CREATE_COMPANYS_BY_USER'
const FIND_INFORMATION_ERROR = "FIND_INFORMATION_ERROR";

export default function usuarioClienteEbiex(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_MOMENT:
      return { ...state, loading: true };
    case EDIT_USER_EBIEX:
      return { ...state, user: action.payload };
    case EDIT_USER_EBIEX_EXITO:
      return { ...state };
    case EDIT_USER_ERROR:
      return { loading: false };
    case ADDCARD_USER_EBIEX:
      return { itemid: action.itemid };
    case USUARIO_RESET_LOGIN_EMAIL:
      return { ...dataInicial, error: action.error };
    // case CREATE_COMPANYS_BY_USER:
    //     return {...state, loading: false, company: action.payload, levelUp: true}
    case FIND_INFORMATION_ERROR:
      return { error: action.mjsstatus };
    default:
      return state;
  }
}
export const actualizarDatosdeCliente = (data) => async (dispatch) => {
  //  // console.log(data.uid)
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    // console.log(data.username);

    await updateDoc(doc(db, `${process.env.REACT_APP_COL_USERS}/${data.uid}`), {
      username: data.username,
      userLastName: data.userLastName,
      userPhone: data.phone,
      userPreferenCont: data.userPreferenCont,
      userRut: data.userRut,
    });
    // console.log("Datos Actualizados");
    //userType:data.userType,
    //     email:data.email,
    //     checked:data.checked,
  } catch (error) {
    // console.log(error);
    dispatch({
      type: EDIT_USER_ERROR,
    });
  }
};

export const actualizarPassPorEnvioEmail = (data) => async (dispatch) => {
  //// console.log(data.email)
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    // console.log(data.email);

    await sendPasswordResetEmail(auth, data.email);
    // console.log("Correo enviado...");
    dispatch({
      type: USUARIO_RESET_LOGIN_EMAIL,
      error: null,
    });
  } catch (error) {
    // console.log(error);
    dispatch({
      type: EDIT_USER_ERROR,
    });
  }
};

export const crearPointADireccionByUser = (data) => async (dispatch) => {
  // console.log("its Fine");
  try {
    const respon = await setDoc(
      doc(
        collection(
          db,
          `${process.env.REACT_APP_COL_USERS}`,
          data.uid,
          "direcciones"
        )
      ),
      {
        companyPropietary: "",
        companyAsignament: "",
        propietary: data.uid,
        userName: data.userName,
        adressName: data.adressName,
        adressPhone: data.adressPhone,
        adressAddress: data.adressAddress,
        adressComent: data.adressComent,
        adressRegion: data.adressRegion,
        adressNumAddress: data.adressNumAddress,
        adressCity: data.adressCity,
        adressComuna: data.adressComuna,
        adressGeoNumAddressLat: data.adressGeoNumAddressLat,
        adressGeoNumAddressLng: data.adressGeoNumAddressLng,
        delete: false,
      }
    );

    //  const messageRef = await db.collection('user').doc('idUsuarios').collection('direcciones').doc('nuevadireccion3').get()

    //  // console.log(messageRef.data())
  } catch (error) {
    // console.log(error);
  }
};

export const deletePointADireccionByUser = (data) => async (dispatch) => {
  // console.log(data);
  try {
    await deleteDoc(
      doc(
        collection(db, process.env.REACT_APP_COL_USERS),
        data.propietary,
        "direcciones",
        data.id
      )
    );

    // console.log("it delete");
  } catch (error) {
    // console.log(error);
  }
};

export const updatePointADireccionByUser = (data) => async (dispatch) => {
  // console.log(data);
  // console.log(data.uid);
  try {
    await updateDoc(
      doc(
        db,
        process.env.REACT_APP_COL_USERS,
        data.uid,
        "direcciones",
        data.documentDir
      ),
      {
        companyPropietary: "",
        companyAsignament: "",
        propietary: data.uid,
        userName: data.userName,
        adressName: data.adressName,
        adressPhone: data.adressPhone,
        adressAddress: data.adressAddress,
        adressComent: data.adressComent,
        adressRegion: data.adressRegion,
        adressNumAddress: data.adressNumAddress,
        adressCity: data.adressCity,
        adressComuna: data.adressComuna,
        adressGeoNumAddressLat: data.adressGeoNumAddressLat,
        adressGeoNumAddressLng: data.adressGeoNumAddressLng,
        delete: false,
      }
    );

    // console.log("it UpDate");
  } catch (error) {
    // console.log(error);
  }
};

// export const clientAddCartProduct = (product, uid) => async (dispatch) => {
//   let itemCard = {
//       product:'',
//       article:[],
//   }
//   let car = []
//   itemCard.product = product
//   // console.log(product);
//   // console.log(uid);

//   dispatch({
//     type: LOADING_MOMENT,
//   });

//   try {
//     const productos = await db.collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`).doc(product).get();
//     if(productos.data()){
//       itemCard.article.push(productos.data())
//     }
//   } catch (error) { // console.log(error)  }
//   try {
//     const bolsas =await db.collection(`${process.env.REACT_APP_COL_PRODUCTS}`).doc(product).get();
//     if(bolsas.data()){
//      itemCard.article.push(bolsas.data())
//     }
//   } catch (error) { // console.log(error) }
//    // console.log(itemCard)
//    // console.log(itemCard.article)
//    //// console.log(localStorage['itemCard'])
//    car = JSON.parse(localStorage.getItem("itemCard"));
//    //-- Pausa
//    if(car){
//      if(car.length>0){
//        let n = 1
//        for (let i = 0; i < car.length; i++) {
//          if(car[i][0].id === product ){
//            // console.log(car[i][0].id +' cantidad '+ n++);
//          }
//        }
//      }
//     }
//    //
//    //descomentar
//     //  if(!(car instanceof Array))
//     //  car = []; // if not, create one
//     //  car.push(itemCard.article); // push a new student inside of it
//     //  localStorage.setItem("itemCard", JSON.stringify(car));

//    if (uid === "noregister") {
//      try {
//   //       const rateEbiex = await db
//   //         .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
//   //         .doc(product.rateEbiex)
//   //         .get();
//   //       // console.log(rateEbiex.data());
//   //       itemCard.plan = (rateEbiex.data())
//   //       dispatch({
//   //           type: ADDCARD_USER_EBIEX,
//   //           itemid:itemCard,
//   //       })
//   //       // console.log(itemCard);
//   //       localStorage.setItem('itemCard', JSON.stringify({itemCard}))
//        } catch (error) {
//          // console.log(error);
//        }

//    } else {
//      try {
//   //     const rateEbiex = await db
//   //       .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
//   //       .doc(product)
//   //       .get();
//   //     // console.log(rateEbiex.data());
//   //     itemCard.plan = (rateEbiex.data())
//   //     dispatch({
//   //         type: ADDCARD_USER_EBIEX,
//   //         itemid:itemCard,
//   //     })
//   //     // console.log(itemCard);
//   //     localStorage.setItem('itemCard', JSON.stringify({itemCard}))
//      } catch (error) {  // console.log(error);
//      }

//   //   try {
//   //     const user = await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(uid).get();
//   //     // console.log(user.data());
//   //   } catch (error) {
//   //     // console.log(error);
//   //   }

//   //   try {
//   //     const rateEbiex = await db
//   //       .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
//   //       .doc(product.rateEbiex)
//   //       .get();
//   //     // console.log(rateEbiex.data());
//   //   } catch (error) {
//   //     // console.log(error);
//   //   }
//   // }

//   // localStorage.setItem('usuario', JSON.stringify({
//   //     uid: res.user.uid,
//   //     email: res.user.email.at,
//   //     roll:res.user.roll
//   // }))

//   //  } catch (error) {
//   //     // console.log(error.code)

//   // dispatch({
//   //     type: USUARIO_ERROR_LOGIN_EMAIL,
//   //     error:'Usuario o contraseña incorrectas'
//   // })
//      }
// };
