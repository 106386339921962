import React from "react";
import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";

const CreateAPI = () => {
  return (
    <div id="create-api">
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Esta API permite crear una orden en el sistema de Bicci Delivery.
        </Typography>
        <Typography variant="body1" paragraph>
          Con esta API, los usuarios pueden agregar órdenes detalladas que
          incluyen información sobre los productos a enviar, modificadores,
          precios, cantidad, y más. Al final del proceso, la API retornará un
          código <code>orderID</code> que puede ser utilizado para realizar un
          seguimiento de la orden creada.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Objetivo
        </Typography>
        <Typography variant="body1" paragraph>
          El objetivo principal de esta API es facilitar la creación de órdenes
          en el sistema de Bicci Delivery, asegurando que toda la información
          relevante para la entrega y el procesamiento de la orden esté
          correctamente registrada. Esto incluye detalles sobre la dirección de
          origen y destino, información del usuario, y especificaciones del
          producto.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Pasos Previos
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Autenticación"
              secondary="Asegúrate de tener las credenciales correctas para acceder a la API, incluyendo un token de autorización."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Preparar los Datos"
              secondary="Reúne toda la información necesaria sobre la orden, incluyendo ubicaciones, direcciones, detalles del producto, y cualquier otro dato requerido."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Formato del JSON"
              secondary="Estructura los datos en el formato JSON adecuado como se describe en los ejemplos proporcionados."
            />
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default CreateAPI;
