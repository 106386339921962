import { useEffect, useState, useCallback } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

function useGetDataCollaboratorByCompany(companyId) {
  const [collaboratorDataByCompany, setCollaboratorDataCollaborator] =
    useState(null);
  const [loadCollaboratorData, setLoadDataCollaborator] = useState(false);
  const [errorCollaboratorData, setErrorDataCollaborator] = useState(null);
  const [listCollaboratorDataByCompany, setListcollaboratorDataByCompany] =
    useState(null);

  const fetchData = useCallback(async (companyId) => {
    setLoadDataCollaborator(true);

    try {
      const docsCollaboratorCompany = await getDocs(
        collection(
          db,
          process.env.REACT_APP_COL_COMPANIES,
          companyId,
          "companyCollaborator"
        )
      );

      const dataCollaboratorCompany = docsCollaboratorCompany.docs.map(
        (doc) => ({
          id: doc.id,
          ...doc.data(),
        })
      );

      const list = docsCollaboratorCompany.docs.map((doc) => doc.data().email);

      const dataResult = [];
      const querySnapshot = await getDocs(
        collection(db, `${process.env.REACT_APP_COL_USERS}`)
      );

      querySnapshot.docs.forEach((doc) => {
        const field = doc.data().email;
        const id = doc.id;
        if (list.includes(field)) {
          dataResult.push({ id, ...doc.data() });
        }
      });

      const dataRowsBeforeDelete = dataCollaboratorCompany.filter(
        (item) => item.isDeleted === false
      );
      const usersActive = dataCollaboratorCompany.filter(
        (item) => item.isblockedAgreement === true
      );
      const userImvite = dataCollaboratorCompany.filter(
        (item) => item.isInvited === true
      );
      const userNoBloq = dataCollaboratorCompany.filter(
        (item) => item.isblockedAgreement === false
      );

      setListcollaboratorDataByCompany({
        Disponibles: userNoBloq.length,
        Invitados: userImvite.length,
        Bloqueados: usersActive.length,
      });

      setCollaboratorDataCollaborator(dataRowsBeforeDelete);

      setLoadDataCollaborator(false);
    } catch (error) {
      setErrorDataCollaborator(true);
      setLoadDataCollaborator(false);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchData(companyId);
    }
  }, [companyId, fetchData]);

  return {
    collaboratorDataByCompany,
    listCollaboratorDataByCompany,
    loadCollaboratorData,
    errorCollaboratorData,
    refreshData: () => fetchData(companyId), // Exponemos la función para refrescar los datos
  };
}

export default useGetDataCollaboratorByCompany;
