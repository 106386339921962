import React, { useState } from "react";

const GetRouteGoogleMaps = ({ origin, destination, waypoints }) => {
  const [responseRoutes, setResponseRoutes] = useState(null);

  const API_KEY = process.env.REACT_APP_APIGOOGLEMAPS_MATRIX; // Reemplaza con tu clave de API

  async function getOptimizedRoute(
    origin,
    destination,
    waypoints,
    travelMode = "DRIVE"
  ) {
    const endpoint = `https://routes.googleapis.com/directions/v2:computeRoutes`;

    const headers = {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": API_KEY,
      "X-Goog-FieldMask":
        "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
    };

    const body = {
      origin: {
        location: {
          latLng: {
            latitude: origin.lat,
            longitude: origin.lng,
          },
        },
      },
      destination: {
        location: {
          latLng: {
            latitude: destination.lat,
            longitude: destination.lng,
          },
        },
      },
      travelMode: travelMode,
      intermediates: waypoints.map((waypoint) => ({
        location: {
          latLng: {
            latitude: waypoint.lat,
            longitude: waypoint.lng,
          },
        },
      })),
      routeModifiers: {
        avoidTolls: false,
        avoidHighways: false,
        avoidFerries: false,
      },
      computeAlternativeRoutes: false,
      optimizeWaypoints: true, // Optimiza las paradas intermedias
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponseRoutes(data.routes); // Actualiza el estado con las rutas obtenidas
    } catch (error) {
      console.error("Error fetching routes:", error);
    }
  }

  React.useEffect(() => {
    getOptimizedRoute(origin, destination, waypoints);
  }, [origin, destination, waypoints]);

  return (
    <div>
      {responseRoutes ? (
        <pre>{JSON.stringify(responseRoutes, null, 2)}</pre>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GetRouteGoogleMaps;
