import React, { Component, useState } from "react";
import { db } from "../../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "semantic-ui-react";
import { Tab } from "semantic-ui-react";
import { Icon, Label, Menu } from "semantic-ui-react";
import useGetMessagesByCompanyId from "../../../hooks/getMessagesByCompanyId";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetMessagesBySystem from "../../../hooks/getMessagesByUserSystem";
import useGetComentsWeb from "../../../hooks/getComentsWeb";
import { NavLink } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import StarIcon from "@mui/icons-material/Star";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import { Button } from "@mui/material";
import { Grid } from "semantic-ui-react";

const Mensajes = (props) => {
  const dispatch = useDispatch();

  const userUid = useSelector((store) => store.usuario);

  const [userStateData, setStateData] = useState({
    email: "",
    userCompanyID: "",
    userCompanyRoll: "",
    roll: "",
  });

  const [msjMensajes, setMensajes] = React.useState("");
  const [msjMensajesBySistem, setMensajesBySistem] = React.useState("");
  const [msjBicciContactsByTeam, setMsjBicciContactsByTeam] =
    React.useState("");
  const [msjNotificasiones, setMsjNotificasiones] = React.useState("");
  const [msjSoporte, setSoporte] = React.useState("");
  const [msjMensajesWeb, setMensajesWeb] = React.useState("");

  const [currentMsjList, setCurrentMsjList] = React.useState([]);

  const [initLoad, setInitLoad] = React.useState(false);

  const [reLoad, setReLoad] = React.useState(false);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const [showPopup, setShowPopup] = React.useState(false);

  const [objUser, setobjUser] = React.useState("");

  const [typePop, setTypePop] = React.useState("");

  var clikeables;

  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);
  var notificasiones = "Lista de mensajes de Notificasiones";
  var mensajes = "Lista de mensajes de usuario";
  var soporte = "Lista de mensajes de Sistema y soporte";
  var mensajesWeb = "lista de mensajes web";
  var notificasionesSistema = "lisata de mensaje Ny system";

  const { bicciMessagesByCompanyId } = useGetMessagesByCompanyId(
    userStateData.userCompanyID,
    userStateData.userCompanyRoll
  );
  const { bicciContactsByTeam } = useGetComentsWeb(userStateData.email);
  const { bicciMessagesBySystem } = useGetMessagesBySystem(userStateData.email);
  const { bicciMessagesSupport } = useGetComentsWeb(userStateData.email);
  const { bicciComentsWeb } = useGetComentsWeb(userStateData.roll);

  const panes = [
    // {
    //   menuItem: 'MENSAJES',
    //   render: () => <Tab.Pane  className="item" attached={false}>{mensajes}</Tab.Pane>,
    // },
    {
      menuItem: {
        content: `NOTIFICASIONES DE EMPRESA`,
        key: "notificasiones",
        icon: "bell",
      },
      render: () => (
        <Tab.Pane className="item" id="notificasiones" attached={false}>
          {notificasiones}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: "SOPORTE",
        key: "soporte",
        icon: "life ring outline",
      },
      render: () => (
        <Tab.Pane className="item" id="soporte" attached={false}>
          {soporte}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: `SISTEMA`,
        key: "notificasionesSistema",
        icon: "bell",
      },
      render: () => (
        <Tab.Pane className="item" id="notificasionesSistema" attached={false}>
          {notificasionesSistema}
        </Tab.Pane>
      ),
    },
    userdata && userdata.mensajesWeb === true
      ? {
          menuItem: {
            content: "MENSAJES WEB",
            key: "mensajesweb",
            icon: "comment alternate outline",
          },
          render: () => (
            <Tab.Pane className="item" id="mensajesWeb" attached={false}>
              {mensajesWeb}
            </Tab.Pane>
          ),
        }
      : null,
  ];

  React.useEffect(() => {
    if (userdata) {
      setStateData({
        email: userdata.email,
        userCompanyID: userdata.userCompanyID,
        userCompanyRoll: userdata.userCompanyRoll,
        roll: userdata.roll,
      });
    }
  }, [userdata]);

  React.useEffect(() => {}, [currentMsjList]);

  React.useEffect(() => {
    if (bicciMessagesByCompanyId) {
      bicciMessagesByCompanyId.sort((a, b) => (a.id < b.id ? 1 : -1));
      setCurrentMsjList(bicciMessagesByCompanyId);
    }
  }, [initLoad]);

  React.useEffect(() => {
    if (bicciMessagesBySystem && bicciComentsWeb) {
      setMensajes(bicciMessagesByCompanyId);
      setMensajesBySistem(bicciMessagesBySystem);
      setMsjNotificasiones(bicciMessagesBySystem);
      //setMsjBicciContactsByTeam(bicciContactsByTeam)
      setSoporte([]);
      setMensajesWeb(bicciComentsWeb);
      setSoporte(bicciMessagesSupport);
      setInitLoad(!initLoad);
    }
  }, [
    bicciMessagesBySystem &&
      bicciMessagesByCompanyId &&
      bicciComentsWeb &&
      bicciContactsByTeam &&
      bicciMessagesSupport,
  ]);

  React.useEffect(() => {}, [msjNotificasiones]);

  const loadInitData = async (id) => {
    if (id === 0) {
      msjMensajes.sort((a, b) =>
        new Date(a.date) < new Date(b.date) ? 1 : -1
      );
      setCurrentMsjList(msjMensajes);
    }
    if (id === 1) {
      setCurrentMsjList([]);
    }
    if (id === 2) {
      bicciMessagesBySystem.sort((a, b) =>
        new Date(a.date) < new Date(b.date) ? 1 : -1
      );
      setCurrentMsjList(bicciMessagesBySystem);
    }
    if (id === 3) {
      bicciComentsWeb.sort((a, b) =>
        new Date(a.date) < new Date(b.date) ? 1 : -1
      );
      setCurrentMsjList(bicciComentsWeb);
    }
  };

  function counterMsj(data, fill) {
    //console.log(msjMensajes)
    let unread = data.filter((item) => item.read === false);
    let CANT = {
      inbox: data.length,
      unread: unread.length,
      outstanding: data.filter((item) => item.outstanding === true).length,
      bookmark: data.filter((item) => item.bookmark === true).length,
      send: data.filter((item) => item.send === true).length,
    };
    return CANT[fill];
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const viewNotifications = async (e) => {
    var searchData = currentMsjList.find(
      (item) => item.id === e.currentTarget.id
    );
    console.log(searchData);
    setTypePop("notifications");
    setobjUser({
      title: "Notificasión De Empresa",
      texto: searchData.subject,
      data: searchData,
    });
    const notificationUser = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userStateData.userCompanyID)
      .collection("system");
    await notificationUser.doc(searchData.id).update({ read: true });
    togglePopup();
  };

  const viewMessagge = async (e) => {
    var searchData = currentMsjList.find(
      (item) => item.id === e.currentTarget.id
    );

    setTypePop("sistema");
    setobjUser({
      title: "Notificasión de sistema",
      texto: "",
      data: searchData,
    });
    const notificationUserSystem = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(searchData.to)
      .collection("system");
    await notificationUserSystem.doc(searchData.id).update({ read: true });
    togglePopup();
  };

  const viewMessaggeMW = (e) => {
    var searchData = currentMsjList.find(
      (item) => item.id === e.currentTarget.id
    );
    setTypePop("mensajeweB");
    setobjUser({
      title: searchData.id,
      texto: "",
      data: searchData,
    });
    togglePopup();
  };

  const marcaDestacadoNotificasions = async (e) => {
    e.preventDefault();

    /*
  var searchData = currentMsjList.find (item => item.id === e.currentTarget.id )
  let update = !e.tatget.value
  console.log(searchData)
  const notificationUser =  db.collection(`${process.env.REACT_APP_COL_MSJ}`).doc(userStateData.userCompanyID).collection('system')
  await notificationUser.doc(searchData.id).update({outstanding:update})
  searchData.outstanding = !e.currentTarget.value
  */
    //let updateStateList = currentMsjList.map(item=> item.id === searchData.id?searchData:item  )
    //setCurrentMsjList(updateStateList)
    //console.log(updateStateList) // funciona para marcar - no desmarca
    setReLoad(!reLoad);
  };

  const marcaImportanteNotificasions = async (e) => {
    e.preventDefault();

    var searchData = currentMsjList.find(
      (item) => item.id === e.currentTarget.id
    );
    let update = !e.tatget.value;

    const notificationUser = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userStateData.userCompanyID)
      .collection("system");
    await notificationUser.doc(searchData.id).update({ bookmark: update });
    setReLoad(!reLoad);
  };

  notificasiones = (
    <Container className="dashcontent imboxmessages">
      <MenuExampleVertical
        inboxCant={msjMensajes ? msjMensajes.length : 0}
        send={msjMensajes ? counterMsj(msjMensajes, "send") : 0}
        unread={msjMensajes ? counterMsj(msjMensajes, "unread") : 0}
        outstanding={msjMensajes ? counterMsj(msjMensajes, "outstanding") : 0}
        bookmark={msjMensajes ? counterMsj(msjMensajes, "bookmark") : 0}
        data={msjMensajes}
        setCurrentMsjList={setCurrentMsjList}
      ></MenuExampleVertical>
      <div className="listmessagescontainer">
        <div></div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">De:</th>
              <th scope="col">Asunto</th>
              <th scope="col">Fecha</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {msjMensajes ? (
              <>
                {currentMsjList.map((element) => (
                  <tr
                    key={element.id}
                    onClick={() => console.log("console notificasiones")}
                    className="Btn-rowCell"
                  >
                    <td>
                      <span className="displayFlex">
                        <button
                          value={element.outstanding}
                          id={element.id}
                          onClick={(e) => marcaDestacadoNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.outstanding === true ? (
                            <StarIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <StarOutlineIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>

                        <button
                          value={element.bookmark}
                          id={element.id}
                          onClick={(e) => marcaImportanteNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.bookmark === true ? (
                            <BookmarkIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <BookmarkBorderIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>
                      </span>
                    </td>
                    <td>{element.from}</td>
                    <td>{element.subject}</td>
                    <td>{element.sendDateText}</td>
                    <td>
                      <DeleteForeverIcon
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                        }}
                        color="error"
                        variant="contained"
                        onClick={marcaImportanteNotificasions}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>

        {msjMensajes && msjMensajes.length > 0 ? (
          <></>
        ) : (
          <>No existen nuevas notificasiones</>
        )}
      </div>
    </Container>
  );

  soporte = (
    <Container className="dashcontent imboxmessages">
      <MenuExampleVertical
        inboxCant={msjSoporte ? msjSoporte.length : 0}
        send={msjSoporte ? counterMsj(msjSoporte, "send") : 0}
        unread={msjSoporte ? counterMsj(msjSoporte, "unread") : 0}
        outstanding={msjSoporte ? counterMsj(msjSoporte, "outstanding") : 0}
        bookmark={msjSoporte ? counterMsj(msjSoporte, "bookmark") : 0}
        data={msjSoporte}
        setCurrentMsjList={setCurrentMsjList}
      ></MenuExampleVertical>
      <div className="listmessagescontainer">
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">De:</th>
              <th scope="col">Asunto</th>
              <th scope="col">Fecha</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {msjSoporte ? (
              <>
                {currentMsjList.map((element) => (
                  <tr
                    key={element.id}
                    onClick={() => console.log("console soporte")}
                    className="Btn-rowCell"
                  >
                    <td>
                      <span className="displayFlex">
                        <button
                          value={element.outstanding}
                          id={element.id}
                          onClick={(e) => marcaDestacadoNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.outstanding === true ? (
                            <StarIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <StarOutlineIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>

                        <button
                          value={element.bookmark}
                          id={element.id}
                          onClick={(e) => marcaImportanteNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.bookmark === true ? (
                            <BookmarkIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <BookmarkBorderIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>
                      </span>
                    </td>
                    <td>{element.email}</td>
                    <td>{element.mensaje.substr(0, 36) + "..."}</td>
                    <td>{element.date.substr(0, 20) + "..."}</td>
                    <td>
                      <DeleteForeverIcon
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                        }}
                        color="error"
                        variant="contained"
                        onClick={() => console.log("delete")}
                      />
                    </td>

                    <td>
                      <NavLink
                        to={{
                          pathname: `/dashboard/mensajes/details/${element.id}`,
                        }}
                        element={element}
                        color="primary"
                        variant="contained"
                      >
                        {" "}
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>

        {msjSoporte && msjSoporte.length > 0 ? (
          <></>
        ) : (
          <>No hay tickets nuevos </>
        )}
      </div>
    </Container>
  );

  notificasionesSistema = (
    <Container className="dashcontent imboxmessages">
      <MenuExampleVertical
        inboxCant={
          msjMensajesBySistem || bicciMessagesBySystem
            ? bicciMessagesBySystem.concat(msjMensajesBySistem).length
            : 0
        }
        send={
          msjMensajesBySistem
            ? counterMsj(
                bicciMessagesBySystem.concat(msjMensajesBySistem),
                "send"
              )
            : 0
        }
        unread={
          msjMensajesBySistem
            ? counterMsj(
                bicciMessagesBySystem.concat(msjMensajesBySistem),
                "unread"
              )
            : 0
        }
        outstanding={
          msjMensajesBySistem
            ? counterMsj(
                bicciMessagesBySystem.concat(msjMensajesBySistem),
                "outstanding"
              )
            : 0
        }
        bookmark={
          msjMensajesBySistem
            ? counterMsj(
                bicciMessagesBySystem.concat(msjMensajesBySistem),
                "bookmark"
              )
            : 0
        }
        data={bicciMessagesBySystem.concat(msjMensajesBySistem)}
        setCurrentMsjList={setCurrentMsjList}
      ></MenuExampleVertical>
      <div className="listmessagescontainer">
        <div></div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">De:</th>
              <th scope="col">Asunto</th>
              <th scope="col">Fecha</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {msjMensajesBySistem ? (
              <>
                {currentMsjList.map((element) => (
                  <tr
                    key={element.id}
                    onClick={() => console.log("console notificasionesSistema")}
                    className="Btn-rowCell"
                  >
                    <td>
                      <span className="displayFlex">
                        <button
                          value={element.outstanding}
                          id={element.id}
                          onClick={(e) => marcaDestacadoNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.outstanding === true ? (
                            <StarIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <StarOutlineIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>

                        <button
                          value={element.bookmark}
                          id={element.id}
                          onClick={(e) => marcaImportanteNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.bookmark === true ? (
                            <BookmarkIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <BookmarkBorderIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>
                      </span>
                    </td>
                    <td>{element.from}</td>
                    <td>{element.subject}</td>
                    <td>{element.sendDateText}</td>
                    <td>
                      <DeleteForeverIcon
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                        }}
                        color="error"
                        variant="contained"
                        onClick={() => console.log("delete")}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>

        {msjMensajesBySistem && msjMensajesBySistem.length > 0 ? (
          <></>
        ) : (
          <>No existen nuevas notificasiones</>
        )}
      </div>
    </Container>
  );

  mensajesWeb = (
    <Container className="dashcontent imboxmessages">
      <MenuExampleVertical
        inboxCant={msjMensajesWeb ? msjMensajesWeb.length : 0}
        send={msjMensajesWeb ? counterMsj(msjMensajesWeb, "send") : 0}
        unread={msjMensajesWeb ? counterMsj(msjMensajesWeb, "unread") : 0}
        outstanding={
          msjMensajesWeb ? counterMsj(msjMensajesWeb, "outstanding") : 0
        }
        bookmark={msjMensajesWeb ? counterMsj(msjMensajesWeb, "bookmark") : 0}
        data={msjMensajesWeb}
        setCurrentMsjList={setCurrentMsjList}
      ></MenuExampleVertical>
      <div className="listmessagescontainer">
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">De:</th>
              <th scope="col">Asunto</th>
              <th scope="col">Fecha</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {msjMensajesWeb ? (
              <>
                {currentMsjList.map((element, index) => (
                  <tr
                    key={element.id}
                    onClick={() => viewMessaggeMW}
                    className="Btn-rowCell"
                  >
                    <td>
                      <span className="displayFlex">
                        <button
                          value={element.outstanding}
                          id={element.id}
                          onClick={(e) => marcaDestacadoNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.outstanding === true ? (
                            <StarIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <StarOutlineIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>

                        <button
                          value={element.bookmark}
                          id={element.id}
                          onClick={(e) => marcaImportanteNotificasions(e)}
                          className="buttonRowMjs"
                        >
                          {element.bookmark === true ? (
                            <BookmarkIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          ) : (
                            <BookmarkBorderIcon
                              sx={{
                                display: "flex",
                                justifyContent: "flex-center",
                                m: 1,
                                padding: 0,
                                background: "unuset",
                              }}
                              color="primary"
                              variant="contained"
                            />
                          )}
                        </button>
                      </span>
                    </td>
                    <td>
                      {element.userName}
                      <br></br>
                      {element.email}
                      <br></br>
                      {element.userPhone}
                    </td>
                    <td>{element.mensaje ? element.mensaje : <></>}</td>
                    {/* .substr( 0,36) */}
                    <td>{element.date ? element.date : <></>}</td>
                    <td>
                      <DeleteForeverIcon
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                        }}
                        color="error"
                        variant="contained"
                        onClick={() => console.log("delete")}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>

        {msjMensajesWeb && msjMensajesWeb.length > 0 ? (
          <></>
        ) : (
          <>No hay mensajes nuevos</>
        )}
      </div>
    </Container>
  );

  return (
    <div>
      <Tab
        className="tabular menu"
        menu={{ pointing: true }}
        // onClick={(e)=>{ console.log (e.currentTarget)}}

        panes={panes}
        activeIndex={activeIndex}
        onTabChange={(e, { activeIndex }) => {
          setActiveIndex(activeIndex);
          loadInitData(activeIndex);
        }}
      />
      {showPopup ? (
        <PopupMessage
          contenido={objUser}
          togglePopup={togglePopup}
          type={typePop}
        ></PopupMessage>
      ) : null}
    </div>
  );
};

class MenuExampleVertical extends Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: "inbox" };
  }

  handleItemClick = (e, { name }) => {
    if (this.props.data) {
      // console.log('update filter:'+ name)
      // console.log(this.props.data)

      const PAYLOAD = {
        inbox: this.props.data,
        unread: this.props.data.filter((item) => item.read !== true),
        send: this.props.data.filter((item) => item.send === true),
        outstanding: this.props.data.filter(
          (item) => item.outstanding === true
        ),
        bookmark: this.props.data.filter((item) => item.bookmark === true),
      };

      //console.log(PAYLOAD[name])
      this.props.setCurrentMsjList(PAYLOAD[name]);
    }
    return this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Menu vertical>
        <Menu.Item
          name="inbox"
          active={activeItem === "inbox"}
          onClick={this.handleItemClick}
        >
          <Label color="teal">{this.props.inboxCant}</Label>
          <Icon name="inbox" />
          Recibidos
        </Menu.Item>

        <Menu.Item
          name="unread"
          active={activeItem === "unread"}
          onClick={this.handleItemClick}
        >
          <Label>{this.props.unread}</Label>
          <Icon name="envelope outline" />
          Sin leer
        </Menu.Item>
        <Menu.Item
          name="send"
          active={activeItem === "send"}
          onClick={this.handleItemClick}
        >
          <Label>{this.props.send}</Label>
          <Icon name="paper plane outline" />
          Enviados
        </Menu.Item>

        <Menu.Item
          name="outstanding"
          active={activeItem === "outstanding"}
          onClick={this.handleItemClick}
        >
          <Label>{this.props.outstanding}</Label>
          <Icon name="star" />
          Importantes
        </Menu.Item>
        <Menu.Item
          name="bookmark"
          active={activeItem === "bookmark"}
          onClick={this.handleItemClick}
        >
          <Label>{this.props.bookmark}</Label>
          <Icon name="bookmark" />
          Destacados
        </Menu.Item>

        {/* <Menu.Item>
            <Input icon='search' placeholder='Search mail...' />
          </Menu.Item>  */}
      </Menu>
    );
  }
}

export default Mensajes;
