import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import mapStyleMinimap from "../../components/MapsGoogle/mapStyleMinimap";
import { useLoadScript } from "@react-google-maps/api";
import { useEffect } from "react";
const libraries = ["places", "geometry"];

const options = {
  styles: mapStyleMinimap,
  fullscreenControl: true,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 18,
};

const mapContainerStyle = {
  height: "90vh",
  width: "100vh",
};

export default function ExternalMapsTraking(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [markersPending, setMarkersPending] = React.useState(null);
  const [markerDelivery, setMarkerDelivery] = React.useState(null);
  const [center, setCenter] = React.useState(null);

  const mapRef = React.useRef();
  const google = window.google;

  useEffect(() => {
    if (props && props.markersPending) {
      setMarkersPending({
        lat: props.markersPending.latitude,
        lng: props.markersPending.longitude,
      });
    }
    if (props && props.driverLocation) {
      setMarkerDelivery({
        lat: props.driverLocation.latitude,
        lng: props.driverLocation.longitude,
      });
    }
  }, [props, props.markersPending, props.driverLocation]);

  const onMapLoad = React.useCallback(
    (map) => {
      mapRef.current = map;

      if (google && markerDelivery) {
        var circle = new google.maps.Circle({
          strokeColor: "#0af079",
          strokeOpacity: 0.3,
          strokeWeight: 0.3,
          fillColor: "#940bd9",
          fillOpacity: 0.12,
          map: mapRef.current,
          center: {
            lat: parseFloat(markerDelivery.lat),
            lng: parseFloat(markerDelivery.lng),
          },
          radius: 3000,
        });
      }
    },
    [google, markerDelivery && markersPending]
  );

  useEffect(() => {
    if (markersPending) {
      setCenter({
        lat: markersPending.lat,
        lng: markersPending.lng,
      });
    }
  }, [markersPending && markerDelivery]);

  useEffect(() => {}, [center]);

  const getMarkey = () => {
    return (
      <Marker
        key={"idkey"}
        position={{
          lng: parseFloat(markerDelivery.lng),
          lat: parseFloat(markerDelivery.lat),
        }}
      />
    );
  };

  if (loadError) {
    return "Error";
  }

  if (!isLoaded) {
    return "Loading...";
  }

  return center ? (
    <div className="mapControlStyle">
      <GoogleMap
        id="mapControl3"
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {markersPending ? (
          <>
            <Marker
              key={"idkey"}
              label={{
                text: `Tu ubicación`,
                className: "labelMarker",
              }}
              position={{
                lng: parseFloat(markersPending.lng),
                lat: parseFloat(markersPending.lat),
              }}
            />
          </>
        ) : null}
      </GoogleMap>
    </div>
  ) : null;
}
