const Sidebar = ({ onSelect }) => {
  return (
    <div className="sidebar">
      <ul>
        <li>
          <button onClick={() => onSelect("introduction")}>Introduction</button>
        </li>
        <li>
          <button onClick={() => onSelect("integration-environment")}>
            Integration Environment
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("production-environment")}>
            Production Environment
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("last-mile-api")}>
            Last Mile API
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("create-api")}>Create API</button>
        </li>
        <li>
          <button onClick={() => onSelect("bulk-creation")}>
            Bulk Creation
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("state-homologation")}>
            State Homologation
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("create-api-variables")}>
            Create API Variables
          </button>
        </li>
        <li>
          <button onClick={() => onSelect("bulk-creation-variables")}>
            Bulk Creation Variables
          </button>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
