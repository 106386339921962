import React from "react";
import Pos5 from "../postcontent/inicio/Pos5/Pos5";
import useTitle from "../../../components/Seo/useTitle";
import useSeo from "../../../components/Seo/useSeo";
import Carouselhomeauto from "../../../components/Carouselhome/Carouselhomeauto.jsx";
import useGlobalConfig from "../../../hooks/globalConfig";
const Inicio = () => {
  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName}` });
  useSeo({
    description: `En ${siteName} nos preocupamos por darte la mejor atención. Nuestros asesores dan seguimiento durante todo el recorrido de tu producto hasta llegar a su destino final. Te notificaremos según tus preferencias.`,
  });

  return (
    <div className="bodycontainerbx">
      <Carouselhomeauto></Carouselhomeauto>
      <div>
        <Pos5 />
      </div>
    </div>
  );
};

export default Inicio;
