import React, { useState } from "react";

//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BusinessIcon from "@mui/icons-material/Business";
import FadeInOut from "../../../animations/FadeInOut";
import { Users as UsersIcon } from "../../../../icons/Users";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export const MenuGeneralEmpresa = ({ onNavigate }) => {
  const [showGestionCompany, setShowGestionCompany] = useState(false);
  const opctionCompany = [
    "tablero",
    "companies",
    "users",
    "drivers",
    "vehicles",
  ];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <>
      <Chip
        icon={<BusinessIcon />}
        label=" Gestión de Empresa"
        variant="outlined"
        clickable
        color="primary"
        onClick={() => setShowGestionCompany(!showGestionCompany)}
        sx={{
          color: "#F8F8F8",
          width: "100%",
          fontSize: "1em",
          padding: "2px",
          marginBottom: "5px",
          backgroundColor: opctionCompany.includes(splitLocation[2])
            ? "#07b107"
            : "transparent",
        }}
      />
      <FadeInOut show={showGestionCompany} duration={500}>
        <NavLink
          to="/dashboard/tablero"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "tablero"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ListAltIcon fontSize="medium" />
            <span className="btn-title">Dashboard</span>
          </div>
        </NavLink>
        <hr></hr>
        <NavLink
          to="/dashboard/companies"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "companies"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <BusinessIcon fontSize="medium" />
            <span className="btn-title">Empresa</span>
          </div>
        </NavLink>

        <hr></hr>
        <NavLink
          to="/dashboard/usuarios"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "users"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <UsersIcon fontSize="medium" />
            <span className="btn-title">Usuarios</span>
          </div>
        </NavLink>
        <hr></hr>
        <NavLink
          to="/dashboard/drivers"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "drivers"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <DirectionsBikeIcon fontSize="medium" />
            <span className="btn-title">Conductores</span>
          </div>
        </NavLink>
        <hr></hr>
        <NavLink
          to="/dashboard/vehicles"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "vehicles"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <AirportShuttleIcon fontSize="medium" />
            <span className="btn-title">Vehículos</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/support-ticket"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div
            className={
              splitLocation[2] === "support-ticket"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ConfirmationNumberIcon fontSize="medium" />
            <span className="btn-title">Support tickets</span>
          </div>
        </NavLink>
      </FadeInOut>
    </>
  );
};

export default MenuGeneralEmpresa;
