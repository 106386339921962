import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import "semantic-ui-css/semantic.min.css";
import "./scss/global.scss";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import generateStore from "./redux/store";
import { Theme } from "./Theme/Theme";
//import UsuarioProvider from "./context/UsuarioProvider";
import UserContext from "./context/CurrentUserProvider";

const store = generateStore();
const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserContext>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </UserContext>
    </Provider>
  </React.StrictMode>
);
