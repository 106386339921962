import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
} from "@mui/material";
import FromShippingOrigin from "./FromShippingOrigin";
import FromShippingTo from "./FromShippingTo";
import FromShippingPaiments from "./FromShippingPaiments";
import FromShippingAccions from "./FromShippingAccions";
import FromShippingDescriptions from "./FromShippingDescriptions";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import { collection } from "firebase/firestore";
import { db } from "../../firebase";
import { emptyOrder } from "../../utils/objects/odersBody";
import DebugObjectChanges from "../../utils/DebugObjectChanges/DebugObjectChanges";
import OrderTicket from "./OrderTicket";
import OrderList from "./OrderList";
import axios from "axios";

const FromShippingNewExpress = ({ userDataContext, prefix, companyData }) => {
  const [recentCreated, setRecentCreated] = useState(false);
  const [order, setOrder] = useState(emptyOrder);
  const [contextOrder, setContextOrder] = useState({
    companyID: "",
    token: "",
  });
  const [tabIndex, setTabIndex] = useState(0); // Estado para manejar la pestaña activa
  const [costCenter, setCostCenter] = useState([]);
  const [companyDirectoy, setCompanyDirectoy] = useState([]);
  const [dataDirectory, setDataDirectory] = useState([]);

  const colleRef = collection(db, "orders");
  const validateFaltantes = [];

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Verificamos los campos obligatorios y añadimos mensajes de error si están vacíos.
  if (!order.fromAddress) validateFaltantes.push({ index: 0, status: false });
  if (!order.toAddress) validateFaltantes.push({ index: 1, status: false });
  if (!order.userPhone) validateFaltantes.push({ index: 1, status: false });
  if (!order.userEmail) validateFaltantes.push({ index: 1, status: false });
  if (!order.userName) validateFaltantes.push({ index: 1, status: false });
  if (!order.direcction1) validateFaltantes.push({ index: 1, status: false });
  if (!order.product) validateFaltantes.push({ index: 2, status: false });
  if (!order.id) validateFaltantes.push(false);

  useEffect(() => {
    if (companyData) {
      updateContextOrder(companyData);
      setOrder(emptyOrder);
    }
  }, [companyData]);

  useEffect(() => {
    setDefaultTab(validateFaltantes);
  }, [validateFaltantes]);

  const updateContextOrder = (data) => {
    setContextOrder((prevState) => ({
      ...prevState,
      companyID: data.integrationServicesBicciID,
      token: data.token,
    }));
    setOrder((prevState) => ({
      ...prevState,
      date: format(new Date(), "dd/mm/yy").toString(),
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const setDefaultTab = (validateFaltantes) => {
    for (let i = 0; i < validateFaltantes.length; i++) {
      const element = validateFaltantes[i];
      if (element.status === false) {
        const firstErrorTab = element.index;

        setTabIndex(firstErrorTab);
        break;
      }
    }
  };

  const handleChangeAccions = async (accion) => {
    if (accion === "create") {
      setLoading(true);
      setErrorMessage("");
      const orderIdWithoutPrefix = order.id.replace(prefix, "");
      try {
        const orderData = {
          data: {
            companyID: contextOrder.companyID,
            orders: [
              {
                id: orderIdWithoutPrefix,
                date: order.date ? new Date(order.date) : new Date(),
                deliveryDate: new Date(order.deliveryDate),
                deliveryType: order.deliveryType,
                whcode: order.whcode,
                fromAddress: order.fromAddress,
                direcction1: order.direcction1,
                direcction2: order.direcction2,
                direcction3: order.direcction3 || "", // Este campo puede no ser obligatorio
                comuna: order.comuna,
                region: order.region,
                country: order.country,
                fromLocation: {
                  lat: parseFloat(order.fromLocation.lat),
                  lng: parseFloat(order.fromLocation.lng),
                },
                toAddress: order.toAddress,
                toLocation: {
                  lat: parseFloat(order.toLocation.lat),
                  lng: parseFloat(order.toLocation.lng),
                },
                toAddressComment: order.toAddressComment || "", // Este campo puede no ser obligatorio
                userPhone: order.userPhone,
                userEmail: order.userEmail,
                userName: order.userName,
                product: order.product,
                groceries: order.groceries?.length
                  ? order.groceries.map((grocery) => ({
                      description: grocery.description || "",
                      id: grocery.id || "",
                      name: grocery.name || "",
                      instructions: grocery.instructions || "",
                      quantity: grocery.quantity || 1,
                      price: grocery.price || 1,
                      height: grocery.height || 1,
                      width: grocery.width || 1,
                      length: grocery.length || 1,
                      weight: grocery.weight || 1,
                      volume: grocery.volume || 0.01,
                    }))
                  : [],
              },
            ],
          },
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_BICCIXPRESS_CREATE_ORDERS,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${contextOrder.token}`,
          },
          data: orderData,
        };

        const response = await axios.request(config);
        if (response.status === 200) {
          setLoading(false);
          setRecentCreated(true);
          // Aquí puedes mostrar una notificación de éxito o redirigir a otra página
        } else {
          console.error("Error al crear la orden", response.status);
          setLoading(false);
          if (response.status >= 400) {
            // Manejo de diferentes códigos de error
            console.error(
              `Error: ${response.status}, Detalles: ${response.data}`
            );
            setErrorMessage(
              `Error: ${response.status}, Detalles: ${response.data}`
            );
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error en la API:", error);
        setErrorMessage(`Error en la API`);
      }
    }

    if (accion === "clear") {
      // Lógica para limpiar todos los campos

      setOrder({
        deliveryType: "HD",
        fromAddress: "",
        direcction1: "",
        direcction2: "",
        direcction3: "",
        comuna: "",
        region: "",
        country: "",
        fromLocation: { lat: null, lng: null },
        toAddress: "",
        toLocation: { lat: null, lng: null },
        toAddressComment: "",
        userPhone: "",
        userEmail: "",
        userName: "",
        product: "",
        fragile: false,
        multipack: false,
        insurance: false,
        groceries: [], // Limpia la lista de productos
      });
    }

    if (accion === "canceled") {
      // Lógica para limpiar todos los campos
      setOrder(emptyOrder);

      // Simula una navegación a la página "Mis Envíos"
      window.location.href = "/dashboard/guias/view"; // Redirige a la página 'Mis Envíos'
    }
  };

  const handleNew = async () => {
    handleChangeAccions("clear");
    setRecentCreated(false);
  };

  const handleOriginChange = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const navigateOredes = () => {
    console.log("navigateOredes");
  };

  return (
    <Box
      sx={{
        padding: 0,
        width: "100%",
        height: "100%",
        background:
          "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
        color: "#f8f8f8",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {!recentCreated ? (
        <>
          <Box
            sx={{
              padding: 0,
              width: "450px",
              height: "100%",
              background:
                "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
              color: "#f8f8f8",
            }}
          >
            {/* <DebugObjectChanges
              objectValidate={order}
              contextOrder={contextOrder}
            ></DebugObjectChanges> */}

            <FromShippingAccions
              handleChangeAccions={handleChangeAccions}
              validateFaltantes={validateFaltantes}
              order={order}
              contextOrder={contextOrder}
              onChange={handleChangeAccions}
              handleChangeDate={(newDate) => {
                setOrder((prevOrder) => ({
                  ...prevOrder,
                  deliveryDate: newDate,
                }));
              }}
            />
            <FromShippingPaiments
              contextOrder={contextOrder}
              order={order}
              onChange={(data) => {
                setOrder((prevState) => ({ ...prevState, ...data }));
              }}
              companyData={companyData}
              costCenter={costCenter}
              colleRef={colleRef}
            />
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="form tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#00e500",
                },
              }}
            >
              <Tab
                label="Origen"
                sx={{
                  color: tabIndex === 0 ? "#00e500" : "#fff",
                  fontSize: "1.5em",
                  "&.Mui-selected": {
                    color: "#00e500",
                  },
                }}
              />
              <Tab
                label="Destino"
                sx={{
                  color: tabIndex === 1 ? "#00e500" : "#fff",
                  fontSize: "1.5em",
                  "&.Mui-selected": {
                    color: "#00e500",
                  },
                }}
              />
              <Tab
                label="Descripción"
                sx={{
                  color: tabIndex === 2 ? "#00e500" : "#fff",
                  fontSize: "1.5em",
                  "&.Mui-selected": {
                    color: "#00e500",
                  },
                }}
              />
            </Tabs>

            <Box sx={{ padding: 1 }}>
              {tabIndex === 0 && (
                <FromShippingOrigin
                  order={order}
                  onChange={(data) => {
                    setOrder((prevState) => ({ ...prevState, ...data }));
                  }}
                  companyDirectoy={companyDirectoy}
                  dataDirectory={dataDirectory}
                  companyData={companyData}
                />
              )}
              {tabIndex === 1 && (
                <FromShippingTo
                  order={order}
                  onChange={(data) => {
                    setOrder((prevState) => ({ ...prevState, ...data }));
                  }}
                  dataDirectory={dataDirectory}
                  companyData={companyData}
                />
              )}
              {tabIndex === 2 && (
                <FromShippingDescriptions
                  initialOrder={order}
                  onChange={(data) => {
                    setOrder((prevState) => {
                      const updatedOrder = { ...prevState, ...data };

                      // Ajustar el campo 'product' basado en la cantidad de 'groceries'
                      const bultosCount = updatedOrder.groceries.length;
                      updatedOrder.product =
                        bultosCount > 0
                          ? `${bultosCount} ${
                              bultosCount > 1 ? "bultos" : "bulto"
                            }`
                          : "NO INFORMA";

                      return updatedOrder;
                    });
                  }}
                  dataDirectory={dataDirectory}
                  companyData={companyData}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Card>
          <CardContent>
            <Typography variant="h5">
              Has creado un envío exitosamente, ¿deseas crear otro?
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button
                sx={{
                  p: 2,
                  m: 1,
                  border: "1px solid blue",
                  backgroundColor: "green",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
                onClick={handleNew}
              >
                Nuevo Envío
              </Button>
              <Button
                sx={{
                  p: 2,
                  m: 1,
                  border: "1px solid blue",
                  backgroundColor: "red",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
                onClick={navigateOredes}
              >
                Ir a mis envíos
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Box
        sx={{
          padding: 0,
          width: "450px",
          height: "100%",
          background:
            "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
          color: "#f8f8f8",
        }}
      >
        <OrderTicket
          order={order}
          contextOrder={contextOrder}
          recentCreated={recentCreated}
          validateFaltantes={validateFaltantes}
        ></OrderTicket>
      </Box>
      <Box
        sx={{
          padding: 0,
          width: "450px",
          height: "100%",
          background:
            "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
        }}
      >
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            position="relative"
          >
            {/* Circular Progress */}
            <CircularProgress />

            <Box
              sx={{
                position: "absolute",
                top: "0%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {/* Aquí puedes usar una imagen o un ícono */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00"
                alt="Loading icon"
                width={100}
                height={50}
              />
              {/* O usar un ícono de MUI */}
              {/* <CheckCircleIcon color="primary" fontSize="large" /> */}
            </Box>

            {/* Mensaje de texto */}
            <Typography variant="body1" sx={{ ml: 2 }}>
              Cargando, por favor espera...
            </Typography>
          </Box>
        )}

        {/* Mostrar mensaje de error si existe */}
        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FromShippingNewExpress;
