import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
class Fromsession extends React.Component{
constructor(props) {
super(props);
//console.log(this.props)
this.state = {
  companyRut:"",
  companyName:"",
  companyAddress:"",
  companyNumAddress:"",
  companyRegion:"",
  companyCity:"",
  companyComuna:"",
  companyCountry:"",
  companyComent:"",
  companyContacName:"",
  companyAdressPhone:"",
  companyImgRut:"",
  companyImgDocumentos:"",
  companyImgPatente:"",
  companyImgDocSii:"",
  success:"",
  error:"",
  resperror:"",}
  // this.procesarDatos = this.procesarDatos.bind(this); Aqui
  // this.hadlechage = this.hadlechage.bind(this) 
}
//const Fromloging = (props) => {
//console.log(props)
render(){
  return (<>
    {this.props.isAssignCompany? (
      <div> 
           {
              this.props.session.userCompanyRoll === "company_admin"?(<> </> ):(<>      
              <div className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
              <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                  <h3 className="title">
                  Datos de Facturación<br></br>    </h3>
                  Empresa: {this.props.Company.companyName}<br></br>
                  Rut: {this.props.Company.companyRut}<br></br>
                  Tel: {this.props.Company.companyAdressPhone}<br></br>
                  <div className="ui-seller-info"></div>
                  </div>
                  <div>
                  <button className="btn-ebiex-froms" onClick={this.props.accion}> Continuar</button>
                  <br></br>
                  <InfoIcon></InfoIcon>Solo el administrador puede cambiar datos de la empresa</div>
             
              </div>
            </div></>)          
           }
      </div>
      ) : (<></>)}</>)
    }
}

export default Fromsession