import * as XLSX from "xlsx";
import Moment from "moment";
import { format } from "date-fns";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarReportePicking = async (userOrders) => {
  const matrizReporte = [
    [
      "Código",
      "F12",
      "Conductor",
      "Tienda Origen",
      "Cliente Destino",
      "Dirección",
      "Comuna",
      "Fecha Pactada",
      "Bicci Estatus",
      "Falabella Estatus",
      "Tamaño producto",
      "Picking",
    ],
    ...userOrders.map((dato) => [
      dato.orderID,
      dato.F12 ? dato.F12 : dato.int_f12,
      dato.driverName && dato.driverName !== "isRequire"
        ? dato.driverName
        : "No registra",
      dato.BODEGANOMBRE ? dato.BODEGANOMBRE : dato.int_placeName,
      dato.CLIENTENOMBRES ? dato.CLIENTENOMBRES : dato.clientNames,
      dato.toAddress
        ? dato.toAddress
        : `${dato.DIRECCION1}, ${dato.DIRECCION2}`,
      dato.COMUNA2 ? dato.COMUNA2 : dato.commune2,
      dato.routeEstimatedDate
        ? dato.routeEstimatedDate
        : dato.timeStamp
        ? format(dato.timeStamp.seconds * 1000, "dd/MM/yy")
        : "err",
      dato.int_bicciStatus
        ? dato.int_bicciStatus !== "isRequire"
          ? dato.int_bicciStatus
          : ""
        : dato.int_bicciStatus,
      dato.int_falabella_status
        ? dato.int_falabella_status === "isRequire"
          ? ""
          : dato.int_falabella_status
        : dato.falabella_status === "isRequire"
        ? ""
        : dato.falabella_status,
      dato.TAMANOPRODUCTO ? dato.TAMANOPRODUCTO : dato.transportOrderSize,
      dato.isPickedUp
        ? dato.isPickedUp
        : dato.isPickedUp !== "isRequire"
        ? "No Register"
        : "No Picking",
    ]),
  ];
  const libro = XLSX.utils.book_new();
  const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
  XLSX.utils.book_append_sheet(libro, hoja, "DATA");

  XLSX.writeFile(
    libro,
    `Picking ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
  );
};

export default generarReportePicking;
