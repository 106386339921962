import React from "react";
import { NavLink } from "react-router-dom";

const BtnTebgoCuenta = () => {
  return (
    <NavLink to="/login" exact="true">
      <button id="exact001" className="btn btn-success">
        Iniciar con mi cuenta
      </button>
    </NavLink>
  );
};

export default BtnTebgoCuenta;
