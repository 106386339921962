import { db, auth } from "../firebase";

import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import moment from "moment";
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
  permisos: "",
};

// types
const LOADING = "LOADING";
const USUARIO_ERROR = "USUARIO_ERROR";
const USUARIO_ERROR_LOGIN_EMAIL = "USUARIO_ERROR_LOGIN_EMAIL";
const USUARIO_EXITO = "USUARIO_EXITO";
const USUARIO_ROL = "USUARIO_ROL";
const USUARIO_ERROR_ROL = "USUARIO_ERROR_ROL";
const CERRAR_SESION = "CERRAR_SESION";
const USUARIO_ADMINISTRADOR_EXITO = "USUARIO_ADMINISTRADOR_EXITO";
const USUARIO_RESET_LOGIN_EMAIL = "USUARIO_RESET_LOGIN_EMAIL";

// reducer
export default function usuarioReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case USUARIO_ERROR:
      return { ...dataInicial, error: action.error };
    case USUARIO_ERROR_LOGIN_EMAIL:
      return { ...dataInicial, error: action.error };
    case USUARIO_EXITO:
      return { ...state, loading: false, user: action.payload, activo: true };
    case USUARIO_ADMINISTRADOR_EXITO:
      return { ...state, loading: false, user: action.payload, activo: true };
    case USUARIO_RESET_LOGIN_EMAIL:
      return { ...dataInicial, error: action.error };
    case CERRAR_SESION:
      return { ...dataInicial };
    default:
      return { ...state };
  }
}

// action
export const ingresoUsuarioAccion = () => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  console.log("ingresoUsuarioAccion");
  try {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, provider);
    //    console.log(res);
    //    console.log(res.user.uid);
    const uid = res.user.uid;
    try {
      // SI EXISTE EN BICCI  - PERO NO EN BICCIEXPRESS
      let userCollectionRef = collection(db, process.env.REACT_APP_COL_USERS);
      let userDocRef = doc(userCollectionRef, uid);
      let userRoll = await getDoc(userDocRef);

      //     console.log(userRoll.data());
      // Si el usuario no existe en bicci Express

      if (!userRoll.data()) {
        // console.log("CREAR usuario bicciexpress");
        dispatch(createNewUser(null, res));
      }
      /*
      if (!userRoll.data().uid) {
        console.log("CREAR usuario bicciexpress uid");
        dispatch(createNewUser(null, res));
      }
      if (!userRoll.data().email) {
        console.log("CREAR usuario bicciexpress email");
        dispatch(createNewUser(null, res));
      }
      if (!userRoll.data().roll) {
        console.log("CREAR usuario bicciexpress roll");
        dispatch(createNewUser(null, res));
      }
*/
      //   console.log("ENTRAR usuario bicciexpress");
      if (userRoll && userRoll.data()) {
        dispatch(loginInSite(userRoll));
      }
      /*
      if (userRoll.data()) {
        // console.log('ENTRAR usuario bicciexpress')
        // console.log(userRoll.data())
        dispatch({
          type: USUARIO_ADMINISTRADOR_EXITO,
          payload: {
            uid: userRoll.data().uid,
            email: userRoll.data().email,
            roll: userRoll.data().roll,
            company: userRoll.data().userCompanyID
              ? userRoll.data().userCompanyID
              : "",
          },
        });

        localStorage.setItem(
          "usuario",
          JSON.stringify({
            uid: userRoll.data().uid,
            email: userRoll.data().email,
            roll: userRoll.data().roll,
            company: userRoll.data().userCompanyID
              ? userRoll.data().userCompanyID
              : "",
          })
        );
      }
      */
    } catch (error) {
      // console.log("OTRO ERROR 1");
      // console.log(error);
      // SI EXISTE EN BICCI  - PERO NO EN BICCIEXPRESS
    }
  } catch (error) {
    // console.log("OTRO ERROR 103");
    // console.log(error);
    dispatch({
      type: USUARIO_ERROR,
      error: "Usuario no posee una cuenta, registre una nueva",
    });
  }
};

//FormLogig
export const ingresoUsuarioLogin = (email, pass) => async (dispatch) => {
  console.log("ingresoUsuarioLogin");

  dispatch({
    type: LOADING,
  });
  try {
    // Login con password
    const res = await signInWithEmailAndPassword(auth, email, pass);

    try {
      // SI EXISTE EN BICCI  - PERO NO EN BICCIEXPRESS
      const userDocRef = doc(db, process.env.REACT_APP_COL_USERS, res.user.uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const data = userSnapshot.data();
        var userRoll = data;
      } else {
        console.log("El documento no existe");
      }
      var data = userSnapshot.data();
      // Si el usuario no existe en bicci Express
      var isOk = true;
      if (!userSnapshot.data()) {
        console.log("CREAR usuario bicciexpress forze");
        isOk = false;
        dispatch(createNewUser(data, res));
      }
      if (!userSnapshot.data().uid) {
        console.log("CREAR usuario bicciexpress uid");
        isOk = false;
        dispatch(createNewUser(data, res));
      }
      if (!userSnapshot.data().email) {
        console.log("CREAR usuario bicciexpress email");
        isOk = false;
        dispatch(createNewUser(data, res));
      }
      if (!userSnapshot.data().roll) {
        // console.log("CREAR usuario bicciexpress roll");
        isOk = false;
        dispatch(createNewUser(data, res));
      }

      if (userSnapshot.data() && isOk === true) {
        //  console.log("ENTRAR usuario bicciexpress");
        if (userSnapshot && userSnapshot.data()) {
          dispatch(loginInSite(userSnapshot));
        }
      }
    } catch (error) {
      // console.log("OTRO ERROR 1");
      // console.log(error);
      // SI EXISTE EN BICCI  - PERO NO EN BICCIEXPRESS
    }
  } catch (error) {
    // console.log('OTRO ERROR 2')
    //  console.log(error.code)
    let errormensaje = "Usuario o contraseña incorrectas";
    if (error.code === "auth/user-not-found") {
      errormensaje = "Debe registrar, una cuenta";
    }
    if (error.code === "auth/wrong-password") {
      errormensaje = "Contraseña incorrecta";
    }
    if (error.code === "auth/too-many-requests") {
      errormensaje =
        "Su cuenta se bloqueará por seguridad, la información será enviada al equipo técnico.";
    }

    dispatch({
      type: USUARIO_ERROR,
      error: errormensaje,
    });
  }
};

//FormRegistro
export const crearUsuarioAccion = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    // Crea un nuevo usuario con correo electrónico y contraseña
    const res = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.pass
    );
    const user = res.user;

    // console.log(user);
    await dispatch(createNewUser(data, res));
    //console.log(res.user.uid);

    try {
      const uid = res.user.uid;
      // SI EXISTE EN BICCI  - PERO NO EN BICCIEXPRESS
      let userCollectionRef = collection(db, process.env.REACT_APP_COL_USERS);
      let userDocRef = doc(userCollectionRef, uid);
      let userRoll = await getDoc(userDocRef);

      //console.log(userRoll.data());

      if (userRoll && userRoll.data()) {
        dispatch(loginInSite(userRoll));
      }
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log(error);
    let errormensaje = "error de acceso 410";
    if (error.code === "auth/email-already-in-use") {
      errormensaje =
        "Usuario registrado en el entorno Bicci, puede ser como cliente o rider. Establezca su contraseña en el menú en el siguiente link";
    }
    dispatch({
      type: USUARIO_ERROR,
      error: errormensaje,
    });
  }
};

export const actualizarPassPorEnvioEmailUdk = (data) => async (dispatch) => {
  //// console.log(data.email)
  dispatch({
    type: LOADING,
  });
  try {
    // console.log(data.email);

    await auth.sendPasswordResetEmail(data.email);
    // console.log("Correo enviado...");
    dispatch({
      type: USUARIO_RESET_LOGIN_EMAIL,
      error: null,
    });
  } catch (error) {
    //console.log(error);
    dispatch({
      type: USUARIO_ERROR,
      error: "Error 292: No existe usuario",
    });
  }
};

export const leerUsuarioActivoAccion = () => (dispatch) => {
  if (localStorage.getItem("usuario")) {
    dispatch({
      type: USUARIO_EXITO,
      payload: JSON.parse(localStorage.getItem("usuario")),
    });
  }
};
// Expot Roll de usuario
//FormLogig

export const cerrarSesionAccion = () => (dispatch) => {
  auth.signOut();

  localStorage.removeItem("usuario");

  dispatch({
    type: CERRAR_SESION,
  });
};

export const createNewUser = (data, res) => async (dispatch) => {
  // Validar res
  // console.log('createNewUser')
  // console.log(data)
  // console.log(res)
  function nombrePropio(name) {
    let nombre = name;
    let cadena = nombre.toLowerCase().split(" ");
    for (var i = 0; i < cadena.length; i++) {
      cadena[i] = cadena[i].charAt(0).toUpperCase() + cadena[i].substring(1);
    }
    nombre = cadena.join(" ");
    return nombre;
  }

  function getacepTerminos() {
    if (data) {
      return true;
    } else {
      return true;
    }
  }
  function getEmail() {
    if (data) {
      return data.email.toLowerCase();
    } else {
      return res.user.email;
    }
  }
  function getuserLastName() {
    if (data) {
      return nombrePropio(data.userLastName);
    } else {
      return "";
    }
  }
  function getusername() {
    if (data) {
      return data.username;
    } else {
      if (res.user && res.user.displayName) {
        return res.user.displayName;
      } else {
        return "";
      }
    }
  }
  function getdisplayName() {
    if (res) {
      if (res.user.displayName) {
        return res.user.displayName;
      }
    }
    if (data) {
      let nom1 = nombrePropio(data.username);
      let nom2 = nombrePropio(data.userLastName);
      let nombre = nom1 + " " + nom2;
      return nombre.trim();
    }
  }

  /*
        username:username,
        userLastName:userLastName,
        email:email,
        pass:pass,
        userPhone:phone,
        userPreferenCont:userPreferenCont,
        userType:userType,
        checked:checked,
        companyName: companyName,
        */

  function getuserPhone() {
    if (data) {
      return data.userPhone;
    } else {
      return res.user.phoneNumber;
    }
  }
  function getavatar() {
    if (data) {
      return "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fuser-profile-icon.jpg?alt=media&token=e22f14e3-7b51-425b-b57c-072dda009ea8";
    } else {
      if (res.user.photoURL) {
        return res.user.photoURL;
      } else {
        return res.user.providerData[0].photoURL;
      }
    }
  }
  function getpropietari() {
    if (data) {
      return "Bicci";
    } else {
      return "Bicci";
    }
  }
  function getemailVerified() {
    if (data) {
      return false;
    } else {
      return res.user.emailVerified;
    }
  }
  function getuserPreferenCont() {
    if (data) {
      if (data.userPreferenCont) {
        return data.userPreferenCont;
      } else {
        return "";
      }
    } else {
      return "email";
    }
  }
  function getuserType() {
    if (data) {
      if (data.userType) {
        return data.userType;
      } else {
        return "empresa";
      }
    } else {
      return "empresa";
    }
  }
  function getcompanyName() {
    if (data.companyName) {
      return data.companyName;
    } else {
      return "";
    }
  }

  const user = {
    createAdd: Date.now(),
    createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    acepTerminos: getacepTerminos(),
    email: getEmail(),
    roll: "client",
    uid: res.user.uid,
    userActive: true,
    userCompany: data ? getcompanyName() : "",
    userLastName: getuserLastName(),
    userName: getusername(),
    displayName: getdisplayName(),
    userPhone: getuserPhone(),
    avatar: getavatar(),
    propietari: getpropietari(),
    emailVerified: getemailVerified(),
    userPreferenCont: getuserPreferenCont(),
    userType: getuserType(),
  };

  // console.log(user)

  function getNewUser() {
    return user;
  }

  try {
    let user = getNewUser();
    //console.log('exito')
    const userRef = doc(db, process.env.REACT_APP_COL_USERS, user.uid);
    await setDoc(userRef, user);
    const contactRef = doc(
      db,
      process.env.REACT_APP_COL_CONTACTS,
      user.email.toLowerCase()
    );
    await setDoc(contactRef, user);
    dispatch({
      type: USUARIO_EXITO,
      payload: {
        uid: user.uid,
        email: user.email,
        roll: "client",
      },
    });
    localStorage.setItem(
      "usuario",
      JSON.stringify({
        uid: user.uid,
        email: user.email,
        roll: "client",
      })
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: USUARIO_ERROR,
      error: "Error 416 createNewUser ",
    });
  }
};

export const createNewUserByFrom = (data, res) => async (dispatch) => {
  // console.log('createNewUser')
  // console.log(data)
  // console.log(res)
  function nombrePropio(name) {
    let nombre = name;
    let cadena = nombre.toLowerCase().split(" ");
    for (var i = 0; i < cadena.length; i++) {
      cadena[i] = cadena[i].charAt(0).toUpperCase() + cadena[i].substring(1);
    }
    nombre = cadena.join(" ");
    return nombre;
  }

  function getacepTerminos() {
    if (data) {
      return true;
    } else {
      return true;
    }
  }
  function getEmail() {
    if (data) {
      return data.email.toLowerCase();
    } else {
      return res.user.email;
    }
  }
  function getuserLastName() {
    if (data) {
      return nombrePropio(data.userLastName);
    } else {
      if (res.additionalUserInfo.profile) {
        return res.additionalUserInfo.profile.family_name;
      } else {
        return "";
      }
    }
  }
  function getusername() {
    if (data) {
      return data.userName;
    } else {
      if (res.additionalUserInfo.profile) {
        return res.additionalUserInfo.profile.given_name;
      } else {
        return "";
      }
    }
  }
  function getdisplayName() {
    if (res) {
      if (res.user.displayName) {
        return res.user.displayName;
      }
    }
    if (data) {
      let nom1 = nombrePropio(data.userName);
      let nom2 = nombrePropio(data.userLastName);
      let nombre = nom1 + " " + nom2;
      return nombre.trim();
    }
  }

  function getuserPhone() {
    if (data) {
      return data.userPhone;
    } else {
      return res.user.phoneNumber;
    }
  }
  function getavatar() {
    if (data) {
      return "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fuser-profile-icon.jpg?alt=media&token=e22f14e3-7b51-425b-b57c-072dda009ea8";
    } else {
      if (res.additionalUserInfo.profile) {
        return res.additionalUserInfo.profile.picture;
      } else {
        return res.user.photoURL;
      }
    }
  }
  function getpropietari() {
    if (data) {
      return "Bicci";
    } else {
      return "Bicci";
    }
  }
  function getemailVerified() {
    if (data) {
      return false;
    } else {
      return res.user.emailVerified;
    }
  }
  function getuserPreferenCont() {
    if (data) {
      if (data.userPreferenCont) {
        return data.userPreferenCont;
      } else {
        return "";
      }
    } else {
      return "email";
    }
  }
  function getuserType() {
    if (data) {
      if (data.userType) {
        return data.userType;
      } else {
        return "empresa";
      }
    } else {
      return "empresa";
    }
  }
  function getcompanyName() {
    if (data.companyName) {
      return data.companyName;
    } else {
      return "";
    }
  }

  const user = {
    createAdd: Date.now(),
    createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    acepTerminos: getacepTerminos(),
    email: getEmail(),
    roll: "client",
    uid: res.user.uid,
    userActive: true,
    userCompany: data ? getcompanyName() : "",
    userLastName: getuserLastName(),
    userName: getusername(),
    displayName: getdisplayName(),
    userPhone: getuserPhone(),
    avatar: getavatar(),
    propietari: getpropietari(),
    emailVerified: getemailVerified(),
    userPreferenCont: getuserPreferenCont(),
    userType: getuserType(),
  };

  // console.log(user)

  function getNewUser() {
    return user;
  }

  try {
    let user = getNewUser();
    //  console.log(user)
    const userRef = doc(db, process.env.REACT_APP_COL_USERS, user.uid);
    await setDoc(userRef, user);
    const contactRef = doc(
      db,
      process.env.REACT_APP_COL_CONTACTS,
      user.email.toLowerCase()
    );
    await setDoc(contactRef, user);
    dispatch({
      type: USUARIO_EXITO,
      payload: {
        uid: user.uid,
        email: user.email,
        roll: "client",
      },
    });
    localStorage.setItem(
      "usuario",
      JSON.stringify({
        uid: user.uid,
        email: user.email,
        roll: "client",
      })
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: USUARIO_ERROR,
      error: "Error 416 createNewUser ",
    });
  }
};

export const loginInSite = (userRoll) => async (dispatch) => {
  //console.log(userRoll);
  if (userRoll.data()) {
    dispatch({
      type: USUARIO_ADMINISTRADOR_EXITO,
      payload: {
        uid: userRoll.data().uid,
        email: userRoll.data().email,
        roll: userRoll.data().roll ? userRoll.data().roll : "client",
        company: userRoll.data().userCompanyID
          ? userRoll.data().userCompanyID
          : "",
      },
    });

    localStorage.setItem(
      "usuario",
      JSON.stringify({
        uid: userRoll.data().uid,
        email: userRoll.data().email,
        roll: userRoll.data().roll ? userRoll.data().roll : "client",
        company: userRoll.data().userCompanyID
          ? userRoll.data().userCompanyID
          : "",
      })
    );
  }
};
