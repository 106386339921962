import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  LinearProgress,
  Card,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import GraficasChartLine from "../../../components/GraficasChartLine/GraficasChartLine";

// Registrar componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TableroCliente = () => {
  const today = new Date();
  const last30Days = Array.from({ length: 30 }, (_, i) => {
    const date = new Date();
    date.setDate(today.getDate() - i);
    return date.toLocaleDateString();
  }).reverse(); // Revertimos para que las fechas estén en orden ascendente

  const generateRandomData = (base, variation) => {
    return last30Days.map(() => Math.floor(base + Math.random() * variation));
  };

  const dataLineChart = {
    labels: last30Days,
    datasets: [
      {
        label: "Ordenes Creadas",
        data: generateRandomData(500, 5), // Promedio de 500, con variación de ±50
        borderColor: "#3f51b5", // Azul
        fill: false,
      },
      {
        label: "Canceladas",
        data: generateRandomData(2, 3), // Promedio de 2 a 5 canceladas
        borderColor: "#f44336", // Rojo
        fill: false,
      },
      {
        label: "Entregadas",
        data: generateRandomData(490, 10), // Promedio de 2 a 5 entregadas
        borderColor: "#4caf50", // Verde
        fill: false,
      },
    ],
  };

  const dataBarChart = (label, color) => ({
    labels: ["Santiago centro", "Providencia", "Ñuñoa", "Macul", "La Reina"],
    datasets: [
      {
        label,
        data: [12, 19, 3, 5, 2],
        backgroundColor: color,
      },
    ],
  });

  const dataDocsMetricsTotalDelivery = [
    { month: "Jan", cant: 1000 },
    { month: "Feb", cant: 1200 },
    { month: "Mar", cant: 1100 },
    { month: "Apr", cant: 900 },
    { month: "May", cant: 1300 },
    { month: "Jun", cant: 1400 },
    { month: "Jul", cant: 1500 },
    { month: "Aug", cant: 1600 },
    { month: "Sep", cant: 1700 },
    { month: "Oct", cant: 1800 },
    { month: "Nov", cant: 1900 },
    { month: "Dec", cant: 2000 },
  ];

  const dataDocsMetricsTotalDeliveryClear = [
    { month: "Jan", cant: 500 },
    { month: "Feb", cant: 600 },
    { month: "Mar", cant: 700 },
    { month: "Apr", cant: 550 },
    { month: "May", cant: 650 },
    { month: "Jun", cant: 700 },
    { month: "Jul", cant: 750 },
    { month: "Aug", cant: 800 },
    { month: "Sep", cant: 850 },
    { month: "Oct", cant: 900 },
    { month: "Nov", cant: 950 },
    { month: "Dec", cant: 1000 },
  ];

  const dataDocsMetricsTotalDeliveryExcepxions = [
    { month: "Jan", cant: 50 },
    { month: "Feb", cant: 60 },
    { month: "Mar", cant: 70 },
    { month: "Apr", cant: 65 },
    { month: "May", cant: 80 },
    { month: "Jun", cant: 75 },
    { month: "Jul", cant: 85 },
    { month: "Aug", cant: 90 },
    { month: "Sep", cant: 100 },
    { month: "Oct", cant: 105 },
    { month: "Nov", cant: 110 },
    { month: "Dec", cant: 120 },
  ];

  const dataDocsMetricsTotalDevolutionsExcepxions = [
    { month: "Jan", cant: 30 },
    { month: "Feb", cant: 40 },
    { month: "Mar", cant: 45 },
    { month: "Apr", cant: 35 },
    { month: "May", cant: 25 },
    { month: "Jun", cant: 24 },
    { month: "Jul", cant: 36 },
    { month: "Aug", cant: 15 },
    { month: "Sep", cant: 10 },
    { month: "Oct", cant: 9 },
    { month: "Nov", cant: 8 },
    { month: "Dec", cant: 5 },
  ];

  const dataDocsMetricsTotalLostByExceptions = [
    { month: "Jan", cant: 20 },
    { month: "Feb", cant: 19 },
    { month: "Mar", cant: 18 },
    { month: "Apr", cant: 13 },
    { month: "May", cant: 10 },
    { month: "Jun", cant: 9 },
    { month: "Jul", cant: 5 },
    { month: "Aug", cant: 1 },
    { month: "Sep", cant: 2 },
    { month: "Oct", cant: 0 },
    { month: "Nov", cant: 0 },
    { month: "Dec", cant: 0 },
  ];

  const dataDocsMetricsTotalLostByLackOfEvidence = [
    { month: "Jan", cant: 1 },
    { month: "Feb", cant: 5 },
    { month: "Mar", cant: 2 },
    { month: "Apr", cant: 1 },
    { month: "May", cant: 3 },
    { month: "Jun", cant: 0 },
    { month: "Jul", cant: 0 },
    { month: "Aug", cant: 0 },
    { month: "Sep", cant: 0 },
    { month: "Oct", cant: 0 },
    { month: "Nov", cant: 0 },
    { month: "Dec", cant: 0 },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottoms sx={{ color: "#F8F8F8" }}>
        ( Ejemplo de vista ) Ordenes durante los últimos 30 días
      </Typography>

      <Box sx={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Historial de despachos</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Productos entregados"}
                    dataHistorial={dataDocsMetricsTotalDelivery}
                    color={"rgb(118, 27, 204)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Entregas limpias</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Entregados limpias"}
                    dataHistorial={dataDocsMetricsTotalDeliveryClear}
                    color={"rgb(7, 163, 25)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Historial de Entregados Con Retraso</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Entregados Con retraso"}
                    dataHistorial={dataDocsMetricsTotalDeliveryExcepxions}
                    color={"rgb(0, 255, 30)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Historial de Devoluciones</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Devoluciones por Cliente"}
                    dataHistorial={dataDocsMetricsTotalDevolutionsExcepxions}
                    color={"rgb(255, 157, 0)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Historial de Perdidas</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Perdidas"}
                    dataHistorial={dataDocsMetricsTotalLostByExceptions}
                    color={"rgb(255, 81, 0)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="typeMiniformsGrafsline">
              <div className="m-2">
                <div>Perdidas no envidencia</div>
                {dataDocsMetricsTotalDelivery ? (
                  <GraficasChartLine
                    name={"Perdidas"}
                    dataHistorial={dataDocsMetricsTotalLostByLackOfEvidence}
                    color={"rgb(255, 81, 0)"}
                  />
                ) : (
                  <LinearProgress />
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{ padding: "20px", marginBottom: "20px" }}>
        <Line data={dataLineChart} />
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: "20px" }}>
            <Typography variant="h6">Ordenes por Comuna Creadas</Typography>
            <Line data={dataBarChart("Creadas", "#3f51b5")} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: "20px" }}>
            <Typography variant="h6">Ordenes por Comuna Entregadas</Typography>
            <Line data={dataBarChart("Entregadas", "#4caf50")} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: "20px" }}>
            <Typography variant="h6">Ordenes por Comuna Canceladas</Typography>
            <Line data={dataBarChart("Canceladas", "#f44336")} />
          </Paper>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button variant="contained" color="primary">
          Listar Ordenes
        </Button>
        <Button variant="contained" color="secondary">
          Ver Mapa de Distribución
        </Button>
      </Box>
    </Box>
  );
};

export default TableroCliente;
