import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetcherSingleUserSuportTickets } from "../../../../utils/FectcherData/FectcherData";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

import { Block, Padding } from "@mui/icons-material";
import { translateStatesSupport } from "../../../../utils/objects/translateStates";

import {
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CardContent } from "semantic-ui-react";
import { updateDoc } from "firebase/firestore";

const SupportTicketDetalis = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ticketData, setTicketData] = useState({});
  const [stateTicket, setStateTicket] = useState(
    Object.keys(translateStatesSupport)[0]
  );
  const statesArray = Object.keys(translateStatesSupport);

  const collectionSuport = collection(
    db,
    `${process.env.REACT_APP_COL_SUPPORT}`
  );

  useEffect(() => {
    if (id) {
      ordersData(id);
    }
  }, [id]);

  const ordersData = async (id) => {
    console.log(id);
    try {
      const documentRef = doc(collectionSuport, id.toString());

      const document = await getDoc(documentRef);

      let data = document.data();
      setTicketData(data);
    } catch (error) {
      console.error("Error fetching :", error);
      setTicketData([]);
    }
  };

  function handleClick() {
    navigate(-1);
  }

  const handleNewState = (event) => {
    console.log(event.target.value);
    setStateTicket(event.target.value);
  };

  const chageStateTicket = (e) => {
    const documentRef = doc(collectionSuport, ticketData.id);
    try {
      updateDoc(documentRef, {
        ticketStatus: stateTicket,
        ticketStatusDescriptor: translateStatesSupport[stateTicket],
      });
      setTicketData((prevData) => ({
        ...prevData,
        ticketStatus: stateTicket,
        ticketStatusDescriptor: translateStatesSupport[stateTicket],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  console.log(ticketData);

  return (
    <div id="containerDetails">
      <div>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <h2 className="h2-withe">
              <Button onClick={() => handleClick()}>{`<- Regresar`}</Button>
              Detalles del Ticket de Soporte
            </h2>
          </Grid>
        </Grid>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={6} sx={{ m: 1 }}>
            <Card
              sx={{
                width: "100%",
                minHeight: "50px",
                backgroundColor: "#fff",
                padding: 1,
              }}
            >
              <CardHeader title={`ID: ${ticketData.id}`}></CardHeader>
              <Divider />
              <CardContent sx={{ padding: 1 }}>
                <Typography sx={{}}>
                  Estado del Folio: {ticketData.status}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Estado del Ticket :{" "}
                  {translateStatesSupport[ticketData.ticketStatus]}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Emmpresa: {ticketData.companyId}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Tipo de ticket: {ticketData.type}
                </Typography>

                <Typography sx={{}}>
                  Alto: {ticketData.dataOrder?.alto || 0}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  {" "}
                  Ancho: {ticketData.dataOrder?.ancho || 0}
                </Typography>
                <Divider />
                <Divider />
                <Typography sx={{}}>
                  largo: {ticketData.dataOrder?.largo || 0}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Size: {ticketData.dataOrder?.size || 0}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Vol: {ticketData.dataOrder?.vol || 0}
                </Typography>
                <Divider />
                <Typography sx={{}}>
                  Fotos: {ticketData?.picsApelations?.length}
                </Typography>
                {ticketData.picsApelations &&
                  ticketData.picsApelations.map((item, index) => (
                    <img
                      key={index}
                      id="imgeApelations"
                      src={item}
                      alt={""}
                    ></img>
                  ))}
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} sx={{ m: 1 }}>
            <Card
              sx={{
                width: "100%",
                minHeight: "50px",
                backgroundColor: "#fff",
                padding: 1,
              }}
            >
              <CardHeader subtitle={`$in total`} title="Cambio de estado" />
              Estado Actual: {translateStatesSupport[ticketData.ticketStatus]}
              <Divider sx={{ mb: 2 }} />
              <FormControl fullWidth>
                <InputLabel id="select-label">Nuevo Estado</InputLabel>
                <Select
                  labelId="select-label"
                  label="Nuevo Estado"
                  onChange={handleNewState}
                  sx={{ color: "black", margin: 1 }} // Cambiado a negro para visualización
                  value={stateTicket}
                >
                  {statesArray.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {translateStatesSupport[item]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider />
              <Button onClick={() => chageStateTicket()}>
                {" "}
                Enviar Nuevo estado{" "}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SupportTicketDetalis;
