import React from "react";

const ValidaNombre = (props) => {
  // Eliminar espacios en blanco al inicio y final del texto
  let texto = props.name.trim();

  // Verificar si el texto contiene algún espacio en blanco
  if (texto.indexOf(" ") === -1) {
    return "Nombre incorrecto"; // El texto tiene solo una palabra
  } else {
    return "Ok"; // El texto tiene más de una palabra
  }
};

export default ValidaNombre;
