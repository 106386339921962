import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Map, View, Overlay } from "ol";
import { Fill, Stroke, Style } from "ol/style";
import { Polygon } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Icon from "ol/style/Icon";

const OpenLayersMaps = (props) => {
  const mapRef = useRef();
  const map = useRef(); // Guardar la referencia del mapa aquí
  const vectorLayerRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null); // Estado para la información de la burbuja emergente
  const popupRef = useRef(); // Referencia para el div de la burbuja emergente
  const colors = [
    "rgba(255, 0, 0, 0.5)",
    "rgba(0, 255, 0, 0.5)",
    "rgba(0, 0, 255, 0.5)",
    "rgba(0, 128, 128, 0.5)",
    "rgba(128, 0, 255, 0.5)",
    "rgba(128, 128, 0, 0.5)",
    "rgba(192, 182, 192, 0.5)",
    "rgba(255, 0, 255, 0.5)",
    "rgba(182, 255, 255, 0.5)",
    "rgba(0, 128, 255, 0.5)",
    "rgba(150, 0, 255, 0.5)",
    "rgba(0, 90, 255, 0.5)",
    // ... añade más colores aquí
  ];
  useEffect(() => {
    map.current = new Map({
      target: null,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-70.6025063, -33.4590365]),
        zoom: 12,
      }),
    });

    vectorLayerRef.current = new VectorLayer(); // Inicializa la capa sin fuente
    map.current.addLayer(vectorLayerRef.current); // Agrega la capa al mapa

    const popup = new Overlay({
      element: popupRef.current,
      positioning: "bottom-center",
      stopEvent: true,
      offset: [0, -10],
    });
    map.current.addOverlay(popup);

    map.current.on("click", function (event) {
      map.current.forEachFeatureAtPixel(event.pixel, function (feature) {
        const info = feature.get("info");
        if (info) {
          console.log("click");
          setPopupInfo(info);
          popup.setPosition(event.coordinate);
        }
      });
    });
  }, []);

  useEffect(() => {
    // Crear capa de pines

    const createPolygonLayer = (poligonos) => {
      const features = poligonos.map((poligono, index) => {
        const polygon = new Polygon([
          poligono.coordinates.map((coordinate) => fromLonLat(coordinate)),
        ]);
        const color = colors[index % colors.length]; // Esto seleccionará el color basado en el índice, reiniciando si hay más de 10 polígonos

        const feature = new Feature({
          geometry: polygon,
        });

        feature.setStyle(
          new Style({
            fill: new Fill({
              color: color, // Aplica el color seleccionado
            }),
            stroke: new Stroke({
              color: color, // Color del borde
              width: 0.5, // Ancho del borde
            }),
          })
        );

        return feature;
      });

      const vectorSource = new VectorSource({
        features,
      });

      return new VectorLayer({
        source: vectorSource,
      });
    };

    const createPinLayer = (pins) => {
      const features = pins.map((pin) => {
        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.3, 0.3],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: pin.iconUrl,
            scale: 0.9,
          }),
        });

        const feature = new Feature({
          geometry: new Point(fromLonLat([pin.lon, pin.lat])),
          style: iconStyle,
        });

        feature.set("info", pin);

        return feature;
      });

      const vectorSource = new VectorSource({
        features,
      });

      return new VectorLayer({
        source: vectorSource,
        style: (feature) => feature.get("style"),
      });
    };

    // Eliminar la capa existente si ya existe
    if (map.current.getLayers().getLength() > 1) {
      map.current.removeLayer(map.current.getLayers().item(1));
    }
    if (props && props.poligonos && props.poligonos.length > 0) {
      const polygonLayer = createPolygonLayer(props.poligonos);
      map.current.addLayer(polygonLayer);
    }
    // Agregar una nueva capa si hay pines
    if (props && props.pines && props.pines.length > 0) {
      const vectorLayer = createPinLayer(props.pines);
      map.current.addLayer(vectorLayer);
    }
  }, [props, props.pines, props.poligonos]);

  useEffect(() => {
    if (mapRef.current) {
      map.current.setTarget(mapRef.current); // Asignar el target aquí
    }
    return () => {
      map.current.setTarget(undefined); // Limpiar en desmontaje
    };
  }, []); // Dependencia vacía significa que esto se ejecutará solo en el montaje y desmontaje

  const mapStyle = {
    width: "100%",
    height: "90vh",
    filter: "grayscale(30%)",
  };

  return (
    <div>
      <div ref={mapRef} style={mapStyle} />
      <div ref={popupRef} style={{ display: popupInfo ? "" : "none" }}>
        {/* Renderizar el contenido de la burbuja emergente */}
        {popupInfo && (
          <div className="popInfo">
            <button
              className="closeButton"
              onClick={() => {
                setPopupInfo(null);
              }}
            >
              X
            </button>
            <div className="popInfoTile">{popupInfo.id}</div>
            <div className="popInfotext">+569{popupInfo.userPhone}</div>
            <div className="popInfotext">{popupInfo.userEmail}</div>
            <div className="popInfotext">{popupInfo.userName}</div>
            <div className="popInfotext">{popupInfo.toAddress}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenLayersMaps;
