import React from "react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import { Container } from "semantic-ui-react";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

const Boxdireccion = () => {
  const userUid = useSelector((store) => store.usuario.user.uid);
  const [directions, setDirections] = React.useState([]);

  React.useEffect(() => {
    const renderAdresses = async (userUid) => {
      // console.log(userUid)
      try {
        const adressses = await db
          .collection(`${process.env.REACT_APP_COL_USERS}`)
          .doc(userUid)
          .collection("direcciones")
          .get();

        const directions = adressses.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDirections(directions);
      } catch (error) {
        // console.log(error)
      }
    };
    renderAdresses(userUid);
  }, []);

  return (
    <Card className="typeMiniforms">
      <CardHeader subheader="Edita tus direcciones" title="Direcciones" />
      <Divider />
      <Container className="formcontentmin" id="micuentafrominidash2">
        {directions ? (
          <div className="adressminicontainer">
            {directions.map((item) => (
              <div className="typecardminadress" key={item.id}>
                <Card>
                  <CardHeader
                    subheader={item.adressAddress}
                    title={item.adressName}
                  />
                  <Divider />
                  <Divider />
                </Card>
              </div>
            ))}
          </div>
        ) : (
          "No userOrders"
        )}
      </Container>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <NavLink to="/dashboard/direcciones" className="btn-sidebarLink">
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            variant="contained"
          >
            <AddLocationAltIcon fontSize="medium" />
            Agregar
          </Button>
        </NavLink>
      </Box>
    </Card>
  );
};

export default Boxdireccion;
