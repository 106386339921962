import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { format } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import useGetDataDispachst from "../../../hooks/getDataDispachstById";
import {
  doc,
  query,
  where,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
  Toolbar,
} from "@mui/material";
import { TableNoData } from "../../../components/Table";
import getOrdesrDispachtByAny from "../../../utils/getOrdesrDispachtByAny";
import {
  stlyleClassStates,
  styleState,
} from "../../../utils/objects/stylesStates";
import { translateStatesfalabbela } from "../../../utils/objects/translateStates";

export default function SwipeableSearcherDrawer(props) {
  const [dataSearchOrder, setDataSearch] = React.useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [orderIdExist, setOrderIdExist] = React.useState(false);
  const [orderDetailsCliente, setOrderDetailsClient] = useState(null);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    let timer;
    if (isActive && progress < 100) {
      // Asegurarnos de que el progreso no supere el 100%
      timer = setTimeout(() => {
        setProgress((prevProgress) => Math.min(prevProgress + 10, 100)); // Aumentamos el progreso en 10% cada segundo
      }, 1000);
    }

    return () => {
      clearTimeout(timer); // Limpia el timer al desmontar el componente o al detener el progreso
    };
  }, [isActive, progress]);

  const handleSeach = async () => {
    setProgress(0); // Restablecer el progreso
    setIsActive(true);
    if (dataSearchOrder !== null && dataSearchOrder !== "" && dataSearchOrder) {
      const result = await getOrdesrDispachtByAny(dataSearchOrder);
      setOrderDetails(result);
      findOrdesrDispacht(result.id);
    } else {
      console.log("parametro invalido");
    }
  };

  const findOrdesrDispacht = async (id) => {
    try {
      const ordesBicciexpres = await getDocs(
        query(
          collection(db, process.env.REACT_APP_COL_ORDERS),
          where("orderID", "==", id)
        )
      );
      var dataOrdes = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const dataOrdesBicciExpress = dataOrdes;
      setDataOrdesBicciExpress(dataOrdesBicciExpress);
      setIsActive(false);
    } catch (error) {
      setDataOrdesBicciExpress({ error: "no found" });
      setIsActive(false);
    }

    try {
      const clearID = id.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const falabellaOrderData = await getDoc(doc(falabellaOrderRef, clearID));

      if (falabellaOrderData.exists()) {
        const orderDataSoc = falabellaOrderData.data();
        setOrderDetailsClient(orderDataSoc);
        setIsActive(false);
      } else {
        setOrderDetailsClient({ error: "Pedido no encontrado" });
        setIsActive(false);
      }
    } catch (error) {
      setOrderDetailsClient({
        error: "Ocurrió un error al buscar el pedido",
      });
    }

    try {
      const clearID = id.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );

      const statusRef = doc(falabellaOrderRef, clearID.toString());

      const statusDocs = collection(statusRef, "status");

      const dataallOrdersfind5 = await getDocs(statusDocs);
      const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      datadocuments.sort((a, b) =>
        a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
      );
      setDispachtHistory(datadocuments);
      setIsActive(false);
    } catch (error) {
      setDispachtHistory([]);
      setIsActive(false);
    }
  };

  function formatDate(dateSeconds) {
    try {
      return format(dateSeconds * 1000, "dd/MM/yy").toString();
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return null;
    }
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="m-2">
        <h2>Busca rápida de paquetes</h2>
      </div>
      <Divider />
      <Grid>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              bgcolor: "#f2f2f2",
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  onClick={handleSeach}
                  startIcon={<SearchIcon />}
                  sx={{
                    width: "150px",
                    padding: "10px",
                    background: "#591e8f",
                    borderRadius: "4px",
                    color: "#f8f8f8",
                    fontSize: "12px",
                    fontWeight: "300",
                    margin: "1px",
                    lineHeight: 1,
                    "&:hover": {
                      backgroundColor: "#4a1468",
                    },
                  }}
                >
                  Buscar
                </Button>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Código del envío..."
                  value={dataSearchOrder}
                  onChange={(e) => setDataSearch(e.target.value)}
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "inherit",
                    paddingInlineEnd: "44px",
                    textAlign: "left",
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#591e8f",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#591e8f",
                      },
                    },
                  }}
                />
              </Box>
            </Toolbar>
          </AppBar>
          {isActive ? (
            <LinearProgress value={progress} variant="determinate" />
          ) : null}

          <>
            {orderDetails ? (
              <>
                {" "}
                <div id="containerDetails">
                  <div>
                    <div>Resultados de busqueda</div>
                  </div>
                  <Grid container spacing={2} columns={12}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader
                          title={
                            <div>
                              <h1>{orderDetails.id}</h1>
                              {orderDetailsCliente ? (
                                <span
                                  className={
                                    styleState[
                                      orderDetailsCliente.falabella_status
                                    ]
                                  }
                                >
                                  {
                                    translateStatesfalabbela[
                                      orderDetailsCliente.falabella_status
                                    ]
                                  }
                                </span>
                              ) : null}

                              {orderDetails.int_falabella_status ? (
                                <div>
                                  <span>Orden Estatus :</span>
                                  <span
                                    className={
                                      stlyleClassStates[
                                        orderDetails.int_falabella_status
                                      ]
                                    }
                                  >
                                    {orderDetails.int_falabella_status !==
                                    "isRequire"
                                      ? orderDetails.int_falabella_status
                                      : "Requiere Actualizacion "}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          }
                        />

                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "350",
                            }}
                          >
                            <table style={{ width: "100%" }}>
                              <td>
                                <NavLink
                                  to={{
                                    pathname:
                                      orderDetails && orderDetails.key
                                        ? `/dashboard/dispatch/details/${orderDetails.key}`
                                        : `/dashboard/dispatch/details/${orderDetails.id}`,
                                  }}
                                  element={orderDetails}
                                  color="primary"
                                  variant="contained"
                                >
                                  {" "}
                                  ver detalles{" "}
                                </NavLink>
                              </td>
                              <tbody>
                                <tr>
                                  <td>Nombre</td>
                                  <td>Valor</td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>ID</td>
                                  <td>{orderDetails.id}</td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Creado en</td>
                                  <td>
                                    {orderDetails.createdAt
                                      ? formatDate(
                                          orderDetails.createdAt.seconds
                                        )
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Fecha viaje</td>
                                  <td>
                                    {orderDetails.date
                                      ? formatDate(orderDetails.date.seconds)
                                      : null}
                                    {orderDetails.timeStamp
                                      ? formatDate(
                                          orderDetails.timeStamp.seconds
                                        )
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Express</td>
                                  <td>
                                    {orderDetails.express
                                      ? orderDetails.express
                                      : orderDetails.dataOrigin}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Dirección origen</td>
                                  <td>
                                    {orderDetails.fromAddress &&
                                    orderDetails.fromAddress !== "isRequire"
                                      ? orderDetails.fromAddress
                                      : orderDetails.int_warehouseName}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Ubicación origen</td>

                                  <td>
                                    {orderDetails.fromAddressLocation ? (
                                      <a
                                        target="blank"
                                        href={`https://www.google.com/maps/search/?api=1&query=${orderDetails.fromAddressLocation.latitude},${orderDetails.fromAddressLocation.longitude}`}
                                      >
                                        Ver en mapa
                                      </a>
                                    ) : null}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Última milla</td>
                                  <td>{orderDetails.lastMile}</td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Lugar</td>
                                  <td>
                                    {orderDetails.placeName
                                      ? orderDetails.placeName
                                      : orderDetails.int_placeName}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Monto del servicio</td>
                                  <td>
                                    {orderDetails.serviceAmount
                                      ? orderDetails.serviceAmount
                                      : 0}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Estatus</td>
                                  <td>
                                    <div>
                                      <div>
                                        Estatus Sistema:{" "}
                                        {orderDetails.status &&
                                        orderDetails.status !== "isRequire"
                                          ? orderDetails.status
                                          : null}
                                      </div>
                                      <div>
                                        Estatus Int B:{" "}
                                        {orderDetails.int_bicciStatus &&
                                        orderDetails.int_bicciStatus !==
                                          "isRequire"
                                          ? orderDetails.int_bicciStatus
                                          : null}
                                      </div>
                                      <div>
                                        Estatus Int F:{" "}
                                        {orderDetails.int_falabella_status &&
                                        orderDetails.int_falabella_status !==
                                          "isRequire"
                                          ? orderDetails.int_falabella_status
                                          : null}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Dirección destino</td>
                                  <td>{orderDetails.toAddress}</td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Ubicación destino</td>
                                  <td>
                                    {orderDetails.toAddressLocation ? (
                                      <a
                                        target="blank"
                                        href={`https://www.google.com/maps/search/?api=1&query=${orderDetails.toAddressLocation.latitude},${orderDetails.toAddressLocation.longitude}`}
                                      >
                                        Ver en mapa
                                      </a>
                                    ) : null}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Correo electrónico del usuario</td>
                                  <td>
                                    {orderDetails.userEmail &&
                                    orderDetails.userEmail !== "isRequire"
                                      ? orderDetails.userEmail
                                      : orderDetails.clientEmail}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Nombre del usuario</td>
                                  <td>
                                    {orderDetails.userName &&
                                    orderDetails.userName !== "isRequire"
                                      ? orderDetails.userName
                                      : orderDetails.clientNames}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #ccc" }}>
                                  <td>Teléfono del usuario</td>
                                  <td>{orderDetails.userPhone}</td>
                                </tr>
                              </tbody>
                            </table>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"></h5>
                    <p className="card-text">
                      <TableNoData
                        notFound={true}
                        bannerActive={false}
                        mensajeBanner={
                          "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                        }
                      ></TableNoData>
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        </Box>
      </Grid>
      <Divider />
    </Box>
  );

  // console.log(dataSearchOrder);

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button
          sx={{ color: "white", fontWeight: "600", fontSize: 15 }}
          className={
            props.styleClassName
              ? props.styleClassName
              : "btn-find m-2 btnsearchd"
          }
          onClick={toggleDrawer("right", true)}
        >
          <SearchIcon fontSize="large" style={{ color: props.iconColor }} />
        </Button>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
