import React from 'react';  
import {db} from '../../firebase'
import { Box, Card, CardContent, Divider} from '@mui/material';
import moment from 'moment';

class PopupMessage extends React.Component {  
    constructor(props){  
        console.log(props)
        super(props);  
        this.state = { 
            showPopup: false 
        };  
        this.togglePopup = this.togglePopup.bind(this);
        this.UpdateMensajeRead = this.UpdateMensajeRead.bind(this);
        this.UpdateUserAcept = this.UpdateUserAcept.bind(this);
        this.UpdateUserDecline = this.UpdateUserDecline.bind(this);
        this.dispatch = this.props.dispatch.bind(this);
        }
     

     async UpdateMensajeRead (){
      console.log('Is Read')


     }

     async UpdateUserAcept(){
      console.log('Acepted')
      
      const docRefCompany =  db.collection(`${process.env.REACT_APP_COL_COMPANIES}`).doc(this.props.contenido.data.companyId).collection('companyCollaborator')
       await docRefCompany.doc(this.props.userdata.email).update({
         uid:this.props.userdata.uid, //response
         acepteDate:moment(Date.now()).format('DD MMMM YYYY, h:mm:ss a'),   //response
         acepteAdd:Date.now(),    //response
         wasAcceptedAgreement:true,  //response
         isAcepted:true,
         userActive:true,
       })
  

       await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(this.props.userdata.uid).update({
        userCompany: this.props.contenido.data.companyName,
        userCompanyID: this.props.contenido.data.companyId,
        roll: process.env.REACT_APP_ADMIN_COMPANY,
        userCompanyRoll: process.env.REACT_APP_ADMIN_COMPANY,
      }); 

      this.props.togglePopup()
     }




  
    render() {  

        return (  
          <>


        {
        this.props.type?(<>
        {
          this.props.type === 'sistema'?(<>

        <div className='popup'>  
        <div className='popup_open'>  
        <h2 className='popup_open_title'>{this.props.contenido.title}</h2>  
        <button className='closePopup' onClick={this.props.togglePopup}>X</button>  
        <p>{this.props.contenido.texto}</p>
        <div>
        <div>

          <Card >
              <CardContent>
              <Box
                sx={{
                alignItems: 'left',
                display: 'flex',
                flexDirection: 'column'
                }}
                >
                <div className="popcontainer"><strong>De:</strong>  {this.props.contenido.data.companyName} </div>    
                <div className="popcontainer"><strong>Asunto:</strong>  {this.props.contenido.data.subject} </div>    
                </Box>
                </CardContent>
                <Divider />
                  <div className="centercontent contac-data">
                  <hr></hr>
                  <div className="popcontainer"> Fecha  : {this.props.contenido.data.sendDateText}</div> 
                  <div className="popcontainer"><p>{this.props.contenido.data.text}</p> </div> 
                  <hr></hr>
                  {
                    this.props.contenido.data.isInvitation=== true ?(<>
                    <button onClick={this.UpdateUserAcept} className="m-2 btn btn-primary">Acepto</button>
                    <button onClick={this.UpdateUserDecline} className="m-2 btn dashIcon-red">No acepto</button>
                    </>):(<></>)
                  }

                  </div> 
            <Divider />
          </Card>

        </div>
        </div>
        </div>  
        </div>  


          
          </>):(<></>)
        }
        {
          this.props.type === 'mensajeweB'?(<>

        <div className='popup'>  
        <div className='popup_open'>  
        <h2 className='popup_open_title'>{'mensajeweB'}</h2>  
        <button className='closePopup' onClick={this.props.togglePopup}>X</button>  
        <p>{this.props.contenido.texto}</p>
        <div>
        <div>

          <Card >
              <CardContent>
              <Box
                sx={{
                alignItems: 'left',
                display: 'flex',
                flexDirection: 'column'
                }}
                >
                  
                <strong>Detalles de Usuario.</strong>     
                <div className='alignItemscenter'>{this.props.contenido.data.isActive===true?(<span className="activo">ACTIVO EN PLATAFORMA</span>):(<span className="inactivo">NO ACTIVO EN PLATAFORMA</span>)} </div> 
                </Box>
                </CardContent>
                <Divider />
                  <div className="centercontent contac-data">

                  <div className="popcontainer"> Empresa : {this.props.contenido.data.companyName} </div> 
                  <div className="popcontainer"> Fecha de registro : {this.props.contenido.data.createdateAdd}</div> 
                  <div className="popcontainer"> Email : {this.props.contenido.data.email} </div> 
                  <div className="popcontainer"> Telefono : {this.props.contenido.data.phone}</div> 
                  <div className="popcontainer"> Nombre : {this.props.contenido.data.username}</div> 
                  <div className="popcontainer"> Apellido : {this.props.contenido.data.userLastName}</div> 
                  <div className="popcontainer"> Agregado como operador: {this.props.contenido.data.isblockedAgreement===true?(<span className="activo-small">Habilitado por la compañia</span>):(<span className="inactivo-small">Inactivo por la compañia</span>)} </div> 
                  <div className="popcontainer"> Rol de usuario: {this.props.contenido.data.company_roll} </div> 

                  </div> 
            <Divider />
          </Card>

        </div>
        </div>
        </div>  
        </div>  


          
          </>):(<></>)
        }
                {
          this.props.type === 'notifications'?(<>

        <div className='popup'>  
        <div className='popup_open'>  
        <h2 className='popup_open_title'>{this.props.contenido.title}</h2>  
        <button className='closePopup' onClick={this.props.togglePopup}>X</button>  
        <p>{this.props.contenido.texto}</p>
        <div>
        <div>

          <Card >
              <CardContent>
              <Box
                sx={{
                alignItems: 'left',
                display: 'flex',
                flexDirection: 'column'
                }}
                >
                <div className="popcontainer"> Fecha de registro : {this.props.contenido.data.sendDateText}</div> 
                <strong>{this.props.contenido.data.text}</strong>     
                </Box>
                </CardContent>
                <Divider />
                  <div className="centercontent contac-data">


                  </div> 
            <Divider />
          </Card>

        </div>
        </div>
        </div>  
        </div>  


          
          </>):(<></>)
        }
        </>):null
        }  



        </>
        );  

    }  

    togglePopup(e) {  
      //this.props.setshowPopup(false)
       this.setState({  
                showPopup: !this.state.showPopup  
           });  
      }  
    
      UpdateUserDecline(e){
        e.preventDefault();
        console.log('Decline')
        this.props.togglePopup()
      }

}  



export default PopupMessage;