import React from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Stack,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import { CustomDateRangePickerStyle } from "../../../CustomDateRangePicker/CustomDateRangePickerStyle";
import { fDate } from "../../../../utils/objects/FormatTime";
// Assuming you have styles defined for date picker

const ToolFiltersComponent = (props) => {
  const {
    selectedCompanies,
    selectedOrigins,
    filteredCompanies,
    filteredOrigins,
    handleChange,
    handleOrigins,
    rangeInputPicker,
    setNewDate,
    excludePendings,
    handleExcludePendings,
  } = props;

  return (
    <Grid
      container
      spacing={3}
      sx={{
        backgroundColor: "#F9FAFC",
      }}
      id="filtrosEmpresas"
    >
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            width: "100%",
            p: 1,
          }}
        >
          <Typography variant="body" sx={{ margin: "10px" }}>
            Búsqueda de empresas
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "5px",
            }}
          >
            <FormControl
              fullWidth
              sx={{
                minWidth: "200px",
                maxWidth: "49%",
                margin: "3px",
                backgroundColor: "#FFF",
              }}
            >
              <InputLabel
                id="company-select-label"
                sx={{ backgroundColor: "#FFF", padding: "0px" }}
              >
                Empresas
              </InputLabel>
              <Select
                labelId="company-select-label"
                id="company-select"
                multiple
                value={selectedCompanies}
                onChange={handleChange}
                renderValue={(selected) => (
                  <Chip
                    label={
                      selected.length === 1
                        ? `${selected.length} empresa seleccionada`
                        : `${selected.length} empresas seleccionadas`
                    }
                  />
                )}
              >
                {filteredCompanies.map((company) => (
                  <MenuItem
                    key={company.integrationServicesBicciID}
                    value={company.integrationServicesBicciID}
                  >
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {filteredOrigins.length > 0 ? (
              <FormControl
                fullWidth
                sx={{
                  minWidth: "200px",
                  maxWidth: "49%",
                  margin: "2px",
                  backgroundColor: "#FFF",
                }}
              >
                <InputLabel
                  id="origin-select-label"
                  sx={{ backgroundColor: "#FFF", padding: "0px" }}
                >
                  Origen
                </InputLabel>
                <Select
                  labelId="origin-select-label"
                  id="origin-select"
                  multiple
                  value={selectedOrigins}
                  onChange={handleOrigins}
                  renderValue={(selected) => (
                    <Chip
                      label={
                        selected.length === 1
                          ? `${selected.length} Origen seleccionado`
                          : `${selected.length} Origen seleccionados`
                      }
                    />
                  )}
                >
                  {filteredOrigins.map((origin) => (
                    <MenuItem key={origin.placeName} value={origin.placeName}>
                      {origin.placeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        {rangeInputPicker ? (
          <Box
            sx={{
              width: "100%",
              p: 1,
            }}
          >
            <Typography variant="body">Seleccione fecha</Typography>
            <Button
              variant="contained"
              onClick={rangeInputPicker.onOpen}
              sx={CustomDateRangePickerStyle}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body">
                  <strong>Desde:</strong> {fDate(rangeInputPicker.startDate)}
                  <EventIcon color="primary" />
                </Typography>
                <Typography variant="body">
                  <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
                  <EventIcon color="primary" />
                </Typography>
              </Stack>
            </Button>

            <CustomDateRangePicker
              variant="calendar"
              open={rangeInputPicker.open}
              startDate={rangeInputPicker.startDate}
              endDate={rangeInputPicker.endDate}
              onChangeStartDate={rangeInputPicker.onChangeStartDate}
              onChangeEndDate={rangeInputPicker.onChangeEndDate}
              onClose={rangeInputPicker.onClose}
              error={rangeInputPicker.error}
              setNewDate={setNewDate}
            />
          </Box>
        ) : null}
      </Grid>
      {handleExcludePendings ? (
        <Grid item xs={3} md={3}>
          <Box
            sx={{
              width: "100%",
              p: 1,
            }}
          >
            <Typography variant="body">Seleccione vista</Typography>
            <FormGroup
              sx={{
                backgroundColor: "#F8F8F8",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={excludePendings}
                    onChange={() => handleExcludePendings(!excludePendings)}
                    aria-label="login switch"
                  />
                }
                label={
                  excludePendings
                    ? "Vista Sin Pendientes"
                    : "Vista Con Pendientes"
                }
              />
            </FormGroup>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ToolFiltersComponent;
