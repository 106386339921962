import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

// ----------------------------------------------------------------------

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  items,
  sx,
}) {
  let active = true;
  return (
    <TableHead sx={sx}>
      {active ? (
        <TableRow>
          {onSelectAllRows && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={!!numSelected && numSelected < rowCount}
                checked={!!rowCount && numSelected === rowCount}
                onChange={(event) => onSelectAllRows(event.target.checked)}
              />
            </TableCell>
          )}

          {headLabel.map((headCell) => {
            if (!headCell) return null;

            return (
              <TableCell
                key={headCell.id}
                align={headCell.align || "left"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                  xs: "none",
                }}
              >
                {onSort ? (
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => onSort(headCell.id)}
                  >
                    {headCell.label}

                    {orderBy === headCell.id && (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    )}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ) : null}
    </TableHead>
  );
}

TableHeadCustom.propTypes = {
  sx: PropTypes.object,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
};
