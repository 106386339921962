import React from "react";
import { useRef } from "react";
//import ExportExcel from "react-export-excel";
import Moment from "moment";
import { LinearProgress } from "@mui/material";
import { Divider } from "semantic-ui-react";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Tooltip, IconButton } from "@mui/material";
import SwipeableTemporaryDrawer from "./DashPlannerEditMapRouteComponents/SwipeableTemporaryDrawer";
// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExcelFile.ExcelSheet;
// const ExcelColumn = ExcelFile.ExcelColumn;

//const exporExcelFile = (props) => {};
const pais = "CHILE";
const stopTime = 10;

const campoVacio = "";

const PlanerDocExcell = (props) => {
  const handleDeleted = (orderID) => {
    console.log("Deleted Order of manifets");
    props.handleDeleted(orderID);
  };

  const selectAddOrders = (bicciOrdersExpressByID) => {
    console.log("selectAddOrders");
    console.log(bicciOrdersExpressByID);
    props.selectAddOrders(bicciOrdersExpressByID);
  };

  const dragStartItem = (e, position) => {
    props.dragRow.current = position;
  };

  const dragEnterItem = (e, position) => {
    props.dragOverRow.current = position;
  };

  const dropItem = (e, position) => {
    e.preventDefault();
    const copyListItems = props.currentOrderList;
    const dragItemContent = props.currentOrderList[props.dragRow.current];
    copyListItems.splice(props.dragRow.current, 1);
    copyListItems.splice(props.dragOverRow.current, 0, dragItemContent);

    props.dragRow.current = null;
    props.dragOverRow.current = null;
    // setCurrentOrderList(copyListItems);
    props.updateDataRouteOrderPiont(copyListItems);
    props.offOptimize();
  };

  return props && props.manifesOrders ? (
    <div>
      <div className="m-2">
        {/* <ExcelFile
          element={
            <button
              onClick={(e) => exporExcelFile()}
              className="btn btn-ebiex m-2"
            >
              {" "}
              Exportar ruta circuit
            </button>
          }
          filename={
            "hoja de ruta circuit " + Moment(Date.now()).format("YYYY-MM-DD")
          }
        >
          <ExcelSheet data={props.manifesOrders} name="Sheet1">
            <ExcelColumn
              label="Unit/Flat/Unit Name"
              value={campoVacio}
            ></ExcelColumn>
            <ExcelColumn
              label="Address Line 1"
              value="DIRECCION1"
            ></ExcelColumn>
            <ExcelColumn
              label="Address Line 2"
              value="DIRECCION2"
            ></ExcelColumn>
            <ExcelColumn label="City" value="COMUNA2"></ExcelColumn>
            <ExcelColumn label="State	Zip" value={campoVacio}></ExcelColumn>
            <ExcelColumn label="Country" value={pais}></ExcelColumn>
            <ExcelColumn
              label="Earliest Arrival Time"
              value={campoVacio}
            ></ExcelColumn>
            <ExcelColumn
              label="Latest Arrival Time"
              value={campoVacio}
            ></ExcelColumn>
            <ExcelColumn label="Notes" value={campoVacio}></ExcelColumn>
            <ExcelColumn label="Size" value={campoVacio}></ExcelColumn>
            <ExcelColumn
              label="Recipient Name"
              value="CLIENTENOMBRES"
            ></ExcelColumn>
            <ExcelColumn label="Type of stop" value={stopTime}></ExcelColumn>
            <ExcelColumn
              label="Recipient Email Address"
              value={campoVacio}
            ></ExcelColumn>
            <ExcelColumn
              label="Recipient Phone Number"
              value="CLIENTETELEFONO"
            ></ExcelColumn>
            <ExcelColumn label="Id" value="orderID"></ExcelColumn>
            <ExcelColumn label="Package Count" value="UNIDADES"></ExcelColumn>
            <ExcelColumn label="Products" value={campoVacio}></ExcelColumn>
            <ExcelColumn
              label="Seller website"
              value={campoVacio}
            ></ExcelColumn>
            <ExcelColumn label="Seller Name" value={campoVacio}></ExcelColumn>
            <ExcelColumn
              label="Driver (email or phone number)"
              value={campoVacio}
            ></ExcelColumn>
          </ExcelSheet>
        </ExcelFile> */}
        <>ExcelFile</>
        {/* 
        <button className="btn btn-ebiex m-2">
          Anexar listado activo del conductor
        </button> */}
        <SwipeableTemporaryDrawer
          selectAddOrders={selectAddOrders}
          orderIdExist={props.orderIdExist}
          LocalRol={props.LocalRol}
          companyId={props.companyId}
        ></SwipeableTemporaryDrawer>
      </div>
      <Divider></Divider>
      <Box sx={{ width: "100%" }}>
        {props.manifesOrders ? (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Código</th>
                <th scope="col">TIM</th>
                <th scope="col">Cliente Destino</th>
                <th scope="col">Fecha de envío</th>
                <th scope="col">Tamaño</th>
                <th scope="col">SKU</th>
                <th scope="col">CANT</th>
                <th scope="col">Descripción</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {props.manifesOrders.map((element, index) => (
                <tr
                  key={index}
                  onDragStart={(e) => dragStartItem(e, index)}
                  onDragEnter={(e) => dragEnterItem(element, index)}
                  onDragEnd={dropItem}
                  draggable
                >
                  <td>{index + 1}</td>
                  <td>{element.orderID}</td>
                  <td>{element.F12}</td>
                  <td>{element.CLIENTENOMBRES}</td>
                  <td>{format(element.timeStamp * 1000, "dd/MM/yy")}</td>
                  <td>{element.TAMANOPRODUCTO}</td>
                  <td>{element.SKU}</td>
                  <td>{element.UNIDADES}</td>
                  <td>{element.PRODUCTO}</td>
                  <td>
                    <Tooltip title="Cancelar edición">
                      <IconButton
                        onClick={() => handleDeleted(element.orderID)}
                        size="small"
                        sx={{
                          ml: 2,
                          color: "#f8f8f8",
                          background: "#ff0000",
                          borderRadius: "3px",
                          margintop: "5px",
                          marginLeft: "0px",
                          "&:focus": {
                            outline: "none!important",
                            margintop: "5px",
                            marginLeft: "0px",
                          },
                          "&:hover": {
                            opacity: 0.4,
                            ml: 2,
                            color: "#f8f8f8",
                            background: "#ff0000",
                            borderRadius: "3px",
                            margintop: "5px",
                            marginLeft: "0px",
                          },
                        }}
                      >
                        {`x`}
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LinearProgress></LinearProgress>
        )}
      </Box>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default PlanerDocExcell;
