import React, { useState } from "react";
import { db } from "../../../firebase";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { Tab } from "semantic-ui-react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import * as XLSX from "xlsx";
import { LinearProgress } from "@mui/material";
import { Card, Divider, CardHeader, Box } from "@mui/material";
import { Container } from "semantic-ui-react";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { getPolygonLocalData } from "../../../redux/getUpdatePolygons";
import PlannerManagerMap from "./PlannerTools/PlannerManagerMap";
import PlannerManagerRoutes from "./PlannerTools/PlannerManagerRoutes";
import { useLoadScript } from "@react-google-maps/api";
import PlannerMapDistribucionEstimate from "./PlannerTools/PlannerMapDistribucionEstimate";
import Chip from "@mui/material/Chip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Moment from "moment";
var mapa = "vista de rutas";
var documentoGrilla = "Lista de rutas";
var planingList = "Editor Listado de rutas no activas";
const libraries = ["places", "drawing", "geometry"];

const panes = [
  {
    menuItem: "SUBIR DOCUMENTOS",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {documentoGrilla}
      </Tab.Pane>
    ),
  },
  {
    menuItem: "DISTRIBUCION",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {mapa}
      </Tab.Pane>
    ),
  },
  {
    menuItem: "VER RUTAS PLANIFICADAS",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {planingList}
      </Tab.Pane>
    ),
  },
];

export default function Planner(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userUid = useSelector((store) => store.usuario);

  const [companyId, setcompanyId] = useState("");

  const [documenIDBICCI, setdocumenIDBICCI] = useState(null);

  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);

  const [render, setRender] = useState(false);

  const [dataPoligons, setDataPoligons] = useState(null);

  const [polygonLocality, setPolygonLocality] = useState("noseletc");

  const [polygonType, setPolygonType] = useState("noseletc");

  const [planningData, setPlannigData] = useState(false);

  const [planningDataNormalize, setplanningDataNormalize] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [completeProsesing, setCompleteProseeing] = useState(false);

  const [saveNewDocument, setSaveNewDocument] = useState(null);

  const [saveError, setSaveError] = useState(null);

  const [activeIndex, setActiveIndex] = useState(1);

  const [observerData, setObserverData] = useState(null);

  const [visibleLits, setVisibleLits] = useState(true);

  const [observerDataWaitPoints, setObserverDataWaitPoints] = useState([]);

  const [seleccionByMapDataWaitPoints, setSeleccionByMapDataWaitPoints] =
    useState([]);

  const [resumenObserverOps, setResumenObserverOps] = useState(null);

  const [resumenObserverOpsBodegas, setResumenObserverOpsBodegas] =
    useState(null);

  const [resumenObserverOpsComunas, setResumenObserverOpsComunas] =
    useState(null);

  const [resumenObserverOpsSize, setResumenObserverOpsSize] = useState(null);

  var DataPoligons = JSON.parse(localStorage.getItem("BicciToolsClientZones"));

  const google = window.google;

  React.useEffect(() => {
    //console.log(processing)
  }, [processing]);

  React.useEffect(() => {}, [resumenObserverOpsComunas]);

  React.useEffect(() => {}, [render]);

  React.useEffect(() => {}, [companyId]);

  React.useEffect(() => {
    donwLoadPolygons();
  }, [companyId]);

  React.useEffect(() => {
    console.log(DataPoligons);

    setDataPoligons(DataPoligons);
  }, []);

  React.useEffect(() => {
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [data, userdata]);

  React.useEffect(() => {
    if (observerData) {
      getResumenEstimateOps(observerData);
    }
  }, [observerData]);

  const setTriger = () => {
    function delay2() {
      setRender(!render);
    }
    setTimeout(delay2, 3000);
  };

  function espera(time) {
    return new Promise((resolve, reject) => {
      //here our function should be implemented
      setTimeout(() => {
        console.log("Procesando...");
        resolve();
      }, time);
    });
  }

  async function addGeopoint() {
    // console.log('1 >'+processing)
    const apiDireccion = async (address) => {
      console.log(address);
      setdocumenIDBICCI("Agregando Geopunto..." + address);
      try {
        const results = await getGeocode({ address });

        for (let i = 0; i < results[0].address_components.length; i++) {
          let elementAdress = results[0].address_components[i];
          // console.log(elementAdress["types"][0]);
          switch (elementAdress["types"][0]) {
            case "street_number":
              //console.log(elementAdress.long_name);
              break;
            case "route":
              //console.log(elementAdress.long_name);
              break;
            case "locality":
              console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_3":
              // console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_2":
              // console.log(elementAdress.long_name);
              console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_1":
              // console.log(elementAdress.long_name);
              console.log(elementAdress.long_name);
              break;
            case "country":
              // console.log(elementAdress.long_name);
              console.log(elementAdress.long_name);
              break;
            case "postal_code":
              // console.log(elementAdress.long_name);
              break;
            default:
              break;
          }
        }

        const { lat, lng } = await getLatLng(results[0]);
        console.log(lat + "  " + lng);
        return { lat, lng };
      } catch (error) {
        console.log(error);
        return { lat: 0, lng: 0 };
      }
    };
    setProcessing(true);
    return new Promise(async (resolve, reject) => {
      let proceso1 = "proceso 1";
      for (let index = 0; index < planningData.length; index++) {
        setdocumenIDBICCI(planningData[index].SOC);
        let address = `${planningData[index].DIRECCION1} ${planningData[index].DIRECCION2} , ${planningData[index].COMUNA2}, Región Metropolitana}`;
        if (index > 0 && (index + 1) % 10 === 0) {
          console.log("MODULO 10");
          await espera(2000);
          console.log("CONTER :" + (index + 1));
          let newPoint = await apiDireccion(address);
          console.log(newPoint);
          planningData[index].adressGeoNumAddressLatLng = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
        } else {
          console.log("CONTER :" + (index + 1));
          //console.log(address)
          await espera(500);
          let newPoint = await apiDireccion(address);
          console.log(newPoint);
          planningData[index].adressGeoNumAddressLatLng = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
        }
      }
      resolve(proceso1);
    });
  }

  async function addZonedelivery() {
    setdocumenIDBICCI("Zonas De Servicio Activas...");
    const chekPoinyt = async (adressGeoNumAddressLatLng) => {
      //console.log(polygonBicci)
      var isInZoneService;
      var zonePoligon;
      var id;

      const chectContainsLocation = async (
        polygonBicci,
        polygonName,
        polygonID
      ) => {
        if (
          google.maps.geometry.poly.containsLocation(
            adressGeoNumAddressLatLng,
            polygonBicci
          )
        ) {
          console.log(
            google.maps.geometry.poly.containsLocation(
              adressGeoNumAddressLatLng,
              polygonBicci
            )
          );
          console.log("el Geopunto esta DENTRO del poligono");
          isInZoneService = true;
          zonePoligon = polygonName;
          id = polygonID;
          return { zonePoligon, isInZoneService, id };
        } else {
          isInZoneService = false;
          zonePoligon = "outZones";
          id = "outZones";
          return { zonePoligon, isInZoneService, id };
        }
      };

      for (let i = 0; i < DataPoligons.polygonType.length; i++) {
        console.log(DataPoligons.polygonType[i]);
        const polygonBicci = new google.maps.Polygon({
          paths: DataPoligons.polygonType[i].coordinates,
        });
        console.log(polygonBicci);
        let result = await chectContainsLocation(
          polygonBicci,
          DataPoligons.polygonType[i].polygonName,
          DataPoligons.polygonType[i].id
        );
        console.log(result);
        if (result.isInZoneService === true) {
          console.log("ENCOTRADO UNA COICIDENCIA");
          setdocumenIDBICCI(
            "ENCOTRADO UNA COINCIDENCIA " +
              DataPoligons.polygonType[i].polygonName
          );
          return { zonePoligon, isInZoneService, id };
        }
        if (
          result.isInZoneService === false &&
          i + 1 === DataPoligons.polygonType.length
        ) {
          console.log("FUERA DE PERIMETRO");
          return { zonePoligon, isInZoneService, id };
        }
      }
    };
    return new Promise(async (resolve, reject) => {
      let proceso2 = "proceso 2";
      for (let index = 0; index < planningData.length; index++) {
        await espera(1000);
        let resp = await chekPoinyt(
          planningData[index].adressGeoNumAddressLatLng
        );
        planningData[index].zonePoligon = resp.zonePoligon;
        planningData[index].isInZoneService = resp.isInZoneService;
        planningData[index].polygonID = resp.id;
      }
      resolve(proceso2);
    });
  }

  async function addBicciCode() {
    return new Promise((resolve, reject) => {
      for (let index = 0; index < planningData.length; index++) {
        planningData[index].orderID = "F" + planningData[index].SOC;
        planningData[index].key =
          "F" +
          planningData[index].SOC +
          "SKU" +
          planningData[index].SKU +
          "F" +
          planningData[index].F12;
        planningData[index].companyId = companyId;
      }
      setTimeout(() => {
        let proceso2 = "proceso 3";

        resolve(proceso2);
      }, 3500);
    });
  }

  async function verifiqueOrdesStatus() {
    const ordersStatus = async (orderid) => {
      setdocumenIDBICCI("Verificando Estatus en Bicci... " + orderid);
      try {
        const orderRef = doc(
          db,
          process.env.REACT_APP_COL_USERS_ORDERS,
          orderid
        );
        const order = await getDoc(orderRef);
        if (order) {
          let status = order.data().status; // if > 2 Asignado
          let statusCreate = true;
          return { statusCreate, status };
        }
      } catch (error) {
        console.log(error);
        let status = null;
        let statusCreate = false;
        return { statusCreate, status };
      }
    };

    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < planningData.length; index++) {
        let resp = await ordersStatus("F" + planningData[index].SOC);
        planningData[index].statusCreate = resp.statusCreate;
        planningData[index].bicciStatus = resp.status;
        function ExcelDateToJSDate(serial) {
          var utc_days = Math.floor(serial - 25569);
          var utc_value = utc_days * 86400;
          var date_info = new Date(utc_value * 1000);

          var fractional_day = serial - Math.floor(serial) + 0.0000001;

          var total_seconds = Math.floor(86400 * fractional_day);

          var seconds = total_seconds % 60;

          total_seconds -= seconds;

          var hours = Math.floor(total_seconds / (60 * 60));
          var minutes = Math.floor(total_seconds / 60) % 60;

          //let fecha  = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
          let fechaMasUno = new Date(
            date_info.getFullYear(),
            date_info.getMonth(),
            date_info.getDate(),
            hours,
            minutes,
            seconds
          );
          fechaMasUno.setDate(fechaMasUno.getDate() + 1);
          console.log(fechaMasUno);
          let fechaMasUnoFormat = format(fechaMasUno, "dd/MM/yy HH:mm:ss", {
            locale: es,
          });
          return { fechaMasUnoFormat, fechaMasUno };
        }
        let time = ExcelDateToJSDate(planningData[index].RANGOFECHAPACTADA);
        planningData[index].estiatedDate = time.fechaMasUnoFormat;
        planningData[index].timeStamp = time.fechaMasUno;
        //console.log(new Date(convertToMs(planningData[index].RANGOFECHAPACTADA*1000)).toUTCString());
      }
      setTimeout(() => {
        let proceso2 = "proceso 3";

        resolve(proceso2);
      }, 3500);
    });
  }

  const getResumenEstimateOps = async (observerData) => {
    var resumenObserverOps = {
      total_Bodegas_Nombre: {},
      total_Distribucion_comuna: {},
      total_tamaños: {},
    };
    setProcessing(true);
    for (var i = 0; i < observerData.length; i++) {
      var obj = observerData[i];

      // Agrupar por BODEGANOMBRE
      var bodegaNombre = obj.BODEGANOMBRE;
      if (
        resumenObserverOps.total_Bodegas_Nombre.hasOwnProperty(bodegaNombre)
      ) {
        resumenObserverOps.total_Bodegas_Nombre[bodegaNombre]++;
      } else {
        resumenObserverOps.total_Bodegas_Nombre[bodegaNombre] = 1;
      }

      // Agrupar por comuna
      var comuna = obj.comuna;
      if (resumenObserverOps.total_Distribucion_comuna.hasOwnProperty(comuna)) {
        resumenObserverOps.total_Distribucion_comuna[comuna]++;
      } else {
        resumenObserverOps.total_Distribucion_comuna[comuna] = 1;
      }

      // Agrupar por TAMANOPRODUCTO
      var tamanoProducto = obj.TAMANOPRODUCTO;
      if (resumenObserverOps.total_tamaños.hasOwnProperty(tamanoProducto)) {
        resumenObserverOps.total_tamaños[tamanoProducto]++;
      } else {
        resumenObserverOps.total_tamaños[tamanoProducto] = 1;
      }
    }

    console.log(resumenObserverOps);

    var arrayBodegas = Object.entries(
      resumenObserverOps.total_Bodegas_Nombre
    ).map(([key, value]) => ({
      bodegaNombre: key,
      cantidad: value,
    }));
    setResumenObserverOpsBodegas(arrayBodegas);

    var arrayComunas = Object.entries(
      resumenObserverOps.total_Distribucion_comuna
    ).map(([key, value]) => ({
      comuna: key,
      cantidad: value,
    }));
    setResumenObserverOpsComunas(arrayComunas);

    var arraySize = Object.entries(resumenObserverOps.total_tamaños).map(
      ([key, value]) => ({
        size: key,
        cantidad: value,
      })
    );
    setResumenObserverOpsSize(arraySize);
    setResumenObserverOps(resumenObserverOps);
    setProcessing(false);
  };

  const normalizedPlaninData = async (planningData) => {
    //console.log(processing)
    let reespuesta1 = "";
    let reespuesta2 = "";
    let reespuesta3 = "";
    let reespuesta4 = "";
    if (!planningData) {
      return console.log("error no data");
    } else {
      console.log(planningData.length);
      setProcessing(true);

      reespuesta1 = await addGeopoint();

      reespuesta2 = await addZonedelivery();

      reespuesta3 = await addBicciCode();

      reespuesta4 = await verifiqueOrdesStatus();
      setdocumenIDBICCI("Limpiando duplicados...");
      setCompleteProseeing(true);

      function eliminarObjetosRepetidos(array) {
        let objetosUnicos = {};

        array.forEach((objeto) => {
          objetosUnicos[objeto.key] = objeto;
        });

        let arrayUnico = Object.values(objetosUnicos);

        return arrayUnico;
      }

      let planningDataClear = eliminarObjetosRepetidos(planningData);

      planningDataClear.sort((a, b) =>
        a.zonePoligon < b.zonePoligon ? 1 : -1
      );

      setplanningDataNormalize(planningDataClear);
      setPlannigData(planningDataClear);
      console.log("final_>" + reespuesta1);
      console.log("final_>" + reespuesta2);
      console.log("final_>" + reespuesta3);
      console.log("final_>" + reespuesta4);

      console.log(planningDataClear); // TODOS LOS REGISTROS
    }

    if (reespuesta3) {
      console.log("result_>" + reespuesta3);
      setCompleteProseeing(null);
      setProcessing(null);
    }
    //console.log(processing)
  };

  const checkPlaninData = async (observerData) => {
    setProcessing(true);
    setCompleteProseeing(false);
    // console.log('1 >'+processing)
    const apiDireccionFind = async (address) => {
      setdocumenIDBICCI("Agregando Geopunto..." + address);
      try {
        const results = await getGeocode({ address });
        let locality = "";
        for (let i = 0; i < results[0].address_components.length; i++) {
          let elementAdress = results[0].address_components[i];
          // console.log(elementAdress["types"][0]);
          switch (elementAdress["types"][0]) {
            case "street_number":
              //console.log(elementAdress.long_name);
              break;
            case "route":
              //console.log(elementAdress.long_name);
              break;
            case "locality":
              locality = elementAdress.long_name;

              break;
            case "administrative_area_level_3":
              // console.log(elementAdress.long_name);
              break;
            case "administrative_area_level_2":
              // console.log(elementAdress.long_name);

              break;
            case "administrative_area_level_1":
              // console.log(elementAdress.long_name);

              break;
            case "country":
              // console.log(elementAdress.long_name);

              break;
            case "postal_code":
              // console.log(elementAdress.long_name);
              break;
            default:
              break;
          }
        }

        const { lat, lng } = await getLatLng(results[0]);
        console.log(lat + "  " + lng);
        return { lat, lng, locality };
      } catch (error) {
        console.log(error);
        return { lat: 0, lng: 0, locality: "Error" };
      }
    };

    return new Promise(async (resolve, reject) => {
      let proceso1 = "proceso 1";
      let observerDataWaitPointsTemp = [];
      for (let index = 0; index < observerData.length; index++) {
        setdocumenIDBICCI(observerData[index].SOC);
        let address = `${observerData[index].DIRECCION1} ${observerData[index].DIRECCION2},${observerData[index].COMUNA2}, Región Metropolitana, CHILE`;
        if (index > 0 && (index + 1) % 10 === 0) {
          console.log("MODULO 10");
          await espera(1000);
          console.log("CONTER :" + (index + 1));
          let newPoint = await apiDireccionFind(address);
          console.log(newPoint);
          observerData[index].adressGeoNumAddressLatLng = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
          let position = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
          observerDataWaitPointsTemp.push({ position });
          observerData[index].comuna = newPoint.locality;
        } else {
          console.log("CONTER :" + (index + 1));
          await espera(500);
          //console.log(address)

          let newPoint = await apiDireccionFind(address);
          console.log(newPoint);
          observerData[index].adressGeoNumAddressLatLng = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
          let position = {
            lat: newPoint.lat,
            lng: newPoint.lng,
          };
          observerDataWaitPointsTemp.push({ position });
          observerData[index].comuna = newPoint.locality;
        }
      }
      console.log(observerData);
      console.log(observerDataWaitPoints);
      setObserverData(observerData);
      setObserverDataWaitPoints(observerDataWaitPointsTemp);
      getResumenEstimateOps(observerData);
      setProcessing(false);
      setCompleteProseeing(true);
      resolve(proceso1);
    });
  };

  const donwLoadPolygons = () => {
    dispatch(
      getPolygonLocalData(companyId, null, polygonLocality, polygonType)
    );
    //props.setTriger()
  };

  const handleFile = async (e) => {
    setSaveNewDocument(null);
    setSaveError(null);
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets[woskbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);
    let arrayjsonData = Object.values(jsonData);
    setPlannigData(arrayjsonData);
  };

  const handleFileDirections = async (e) => {
    setSaveNewDocument(null);
    setSaveError(null);
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);
    const worksheet = woskbook.Sheets[woskbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);
    let arrayjsonData = Object.values(jsonData);
    setObserverData(arrayjsonData);
  };

  const handleSelecOrder = async (e) => {
    console.log(e.currentTarget.id);
    console.log(e.currentTarget.checked);
  };

  const saveDocumentsEstimatedOrders = async () => {
    // extraer data documento name sucursarl - location - address - geopunto Origen
    // geopunto Origen esde documento - Opcion 2 desde Sucursales
    // tomar el dato  BODEGANOMBRE => BUSCAR GEOPUNTO EN falabellaLocals
    setProcessing(true);
    setCompleteProseeing(false);
    try {
      const ordersEstimateCol = collection(
        db,
        process.env.REACT_APP_COL_ORDERS_ESTIMATE
      );
      const newOrderDoc = doc(
        ordersEstimateCol,
        "SDDBICCI" + Date.now().toString()
      );

      setDoc(newOrderDoc, {
        id: "SDDBICCI" + Date.now().toString(),
        companyId: companyId,
        client: "FALABELLA RETAIL S.A.",
        sucursal: "(Plaza Egaña) FALABELLA RETAIL S.A.",
        createBy: userUid.user.uid,
        crearteAdd: Date.now(),
        Data: planningData,
        isDelete: false,
      });
      setSaveNewDocument(true);
    } catch (error) {
      setSaveError(true);
    }

    for (let i = 0; i < planningData.length; i++) {
      try {
        const docRef = doc(
          db,
          process.env.REACT_APP_COL_ORDERS,
          planningData[i].key
        );
        await setDoc(docRef, planningData[i]);
        setdocumenIDBICCI(`Guardando... ${planningData[i].SOC}`);
        planningData[i].save = true;
      } catch (error) {
        console.log(error);
        planningData[i].save = true;
      }
      setdocumenIDBICCI(null);
      setSaveError(null);
    }
    setProcessing(false);
    setCompleteProseeing(true);
  };

  function handleClickNew(e) {
    navigate(`/dashboard/routes/new`);
  }

  const handleGoProps = (eID) => {
    navigate(`/dashboard/planner/${eID}/edit`);
  };
  const handleGoDispatches = (eID) => {
    navigate(`/dashboard/planner/${eID}/dispatches`); // no en uso
  };

  const deleteSelection = (e) => {
    console.log(parseInt(e.currentTarget.id));
    const seleccionByMap = seleccionByMapDataWaitPoints.filter(
      (item) => item.SOC !== parseInt(e.currentTarget.id)
    );
    setSeleccionByMapDataWaitPoints(seleccionByMap);
  };

  const showList = () => {
    setVisibleLits(!visibleLits);
  };

  const exportarSeleccion = (data) => {
    //console.log(data);
    const matrizReporte = [
      ["F14", "F12", "Destino", "Comuna", "Tamaño", "Cantidad"],
      ...data.map((dato) => [
        dato.SOC,
        dato.F12,
        `${dato.DIRECCION1}, ${dato.DIRECCION2}, ${dato.COMUNA2}`,
        dato.COMUNA2,
        dato.TAMANOPRODUCTO,
        dato.UNIDADES,
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

    XLSX.writeFile(
      libro,
      `Seleccion Manual Paradas ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };
  documentoGrilla = (
    <div>
      {processing && !completeProsesing ? (
        <div>
          <div>validando: {documenIDBICCI}</div>
          <LinearProgress />
        </div>
      ) : (
        <></>
      )}

      <div>Planificador Lista de rutas con archivos SDD Falabella</div>

      {saveNewDocument ? (
        <div className="m-3">
          <span className="alert alert-success m-2">
            {" "}
            Se a guardado un nuevo archivo
          </span>
          <button
            onClick={(e) => {
              setActiveIndex(1);
            }}
            className="btn btn-ebiex m-1"
          >
            Continuar...
          </button>
        </div>
      ) : null}
      {saveError ? (
        <div className="m-3">
          <span className="alert alert-danger m-2">
            {" "}
            Error al guardar el nuevo archivo
          </span>
        </div>
      ) : null}
      <input type="file" onChange={(e) => handleFile(e)} />
      <div>
        {planningData ? (
          <button
            onClick={(e) => normalizedPlaninData(planningData)}
            className="btn btn-ebiex m-1"
          >
            {" "}
            Procesar archivo
          </button>
        ) : null}
        {planningDataNormalize ? (
          <button
            onClick={(e) => saveDocumentsEstimatedOrders()}
            className="btn btn-ebiex m-1"
          >
            {" "}
            Guardar Envios estimadas
          </button>
        ) : null}
      </div>
      <hr></hr>
      <div>
        <div>Consultar Grupo de Direcciónes</div>
        <input type="file" onChange={(e) => handleFileDirections(e)} />
        <div>
          {observerData ? (
            <button
              onClick={(e) => checkPlaninData(observerData)}
              className="btn btn-ebiex m-1"
            >
              {" "}
              Chek All
            </button>
          ) : null}
        </div>
      </div>

      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader title="Despachos estimados" />

        <Box sx={{ minWidth: 800 }}>
          {
            planningData ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">ID</th>
                    <th scope="col">F12</th>
                    <th scope="col">Origen</th>
                    <th scope="col">Dirección Destino</th>
                    <th scope="col">Fecha de envío</th>
                    <th scope="col">ZonaAct</th>
                    <th scope="col">Poligonos</th>
                    <th scope="col">Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {planningData.map((element, index) => (
                    <tr key={index}>
                      <td>
                        {element.save === true ? (
                          <WhereToVoteIcon
                            style={{
                              color: "#028600",
                              backgroundColor: "#dadbda00",
                            }}
                          ></WhereToVoteIcon>
                        ) : (
                          <WhereToVoteIcon
                            style={{
                              color: "#ff0000",
                              backgroundColor: "#dadbda00",
                            }}
                          ></WhereToVoteIcon>
                        )}
                      </td>
                      <td>{element.orderID}</td>
                      <td>{element.F12}</td>
                      <td>{element.BODEGANOMBRE}</td>
                      <td>
                        {` ${element.DIRECCION1} ${element.DIRECCION2}, ${element.COMUNA2}`}
                      </td>
                      <td>{element.estiatedDate}</td>
                      <td>
                        {element.isInZoneService === true ? (
                          <WhereToVoteIcon
                            style={{
                              color: "#028600",
                              backgroundColor: "#dadbda00",
                            }}
                          ></WhereToVoteIcon>
                        ) : (
                          <WhereToVoteIcon
                            style={{
                              color: "#ff0000",
                              backgroundColor: "#dadbda00",
                            }}
                          ></WhereToVoteIcon>
                        )}
                      </td>
                      <td>{element.zonePoligon}</td>
                      <td>
                        {element.statusCreate === true ? "Creado" : "No Creado"}
                        {/*                         
                      <NavLink to={{
                      pathname: `/dashboard/planner/details/${element.key}`}} 
                      element={element} 
                      color="primary" variant="contained"> ver</NavLink> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null
            // <div className="m-3 p-3">
            //   <span className="alert alert-info m-2">
            //     Cree nuevos registros desde un archivo SDD
            //   </span>
            // </div>
          }
          {observerData ? (
            <Card>
              <CardHeader title="Reporte Operativo Same Day Tiendas (Carriers BICCIPM Y BICCIEX)"></CardHeader>
              <Divider></Divider>
              <Box>
                <>
                  {resumenObserverOpsBodegas ? (
                    <Box
                      sx={{
                        maxWidth: "450px",
                        width: "450px",
                        margin: "5px",
                        border: "1px solid",
                        display: "inline-flex",
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Tienda Origen</th>
                            <th scope="col">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resumenObserverOpsBodegas.map((element, index) => (
                            <tr key={index}>
                              <td>{element.bodegaNombre}</td>
                              <td>{element.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {resumenObserverOpsComunas &&
                  resumenObserverOpsComunas[0] &&
                  resumenObserverOpsComunas[0].comuna !== "undefined" ? (
                    <Box
                      sx={{
                        maxWidth: "450px",
                        width: "450px",
                        margin: "5px",
                        border: "1px solid",
                        display: "inline-flex",
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Comuna</th>
                            <th scope="col">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resumenObserverOpsComunas.map((element, index) => (
                            <tr key={index}>
                              <td>{element.comuna}</td>
                              <td>{element.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {resumenObserverOps && resumenObserverOpsSize ? (
                    <Box
                      sx={{
                        maxWidth: "450px",
                        width: "450px",
                        margin: "5px",
                        border: "1px solid",
                        display: "inline-flex",
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Tamaño</th>
                            <th scope="col">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resumenObserverOpsSize.map((element, index) => (
                            <tr key={index}>
                              <td>{element.size}</td>
                              <td>{element.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              </Box>
              <Divider></Divider>
              <Box>
                {observerDataWaitPoints.length > 0 ? (
                  <PlannerMapDistribucionEstimate
                    markerpoint={observerData}
                    setSeleccionByMapDataWaitPoints={
                      setSeleccionByMapDataWaitPoints
                    }
                  ></PlannerMapDistribucionEstimate>
                ) : null}
                {seleccionByMapDataWaitPoints.length > 0 ? (
                  <div className="contenOrdersforMap">
                    <div>
                      <button
                        className="btn btn-ebiex m-1"
                        onClick={() =>
                          exportarSeleccion(seleccionByMapDataWaitPoints)
                        }
                      >
                        {" "}
                        Exportar
                      </button>
                      {visibleLits ? (
                        <>
                          {" "}
                          <Chip
                            onClick={showList}
                            clickable
                            icon={
                              <VisibilityIcon
                                style={{
                                  color: "#591e8f",
                                }}
                              />
                            }
                            style={{
                              color: "#591e8f",
                              backgroundColor: "#ff000000",
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <Chip
                            onClick={showList}
                            clickable
                            icon={
                              <VisibilityOffIcon
                                style={{
                                  color: "#007bff",
                                }}
                              />
                            }
                            style={{
                              color: "red",
                              backgroundColor: "#ff000000",
                            }}
                          />{" "}
                        </>
                      )}
                    </div>
                    {visibleLits ? (
                      <>
                        {seleccionByMapDataWaitPoints.map((item, index) => (
                          <div key={index}>
                            <hr></hr>
                            <span>{item.SOC}</span>
                            {"  "}
                            <Chip
                              onClick={deleteSelection}
                              clickable
                              id={item.SOC}
                              icon={
                                <DeleteOutlineIcon
                                  style={{
                                    color: "red",
                                  }}
                                />
                              }
                              style={{
                                color: "red",
                                backgroundColor: "#ff000000",
                              }}
                            />{" "}
                            <span>{item.TAMANOPRODUCTO}</span>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="contenOrdersforMap">
                    No a seleccionado ningun item
                  </div>
                )}
                {observerData ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Tienda</th>
                        <th scope="col">SOC</th>
                        <th scope="col">Destino</th>
                        <th scope="col">Comuna</th>
                        <th scope="col">Tamaño</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {observerData.map((element, index) => (
                        <tr key={index}>
                          <td>{element.BODEGANOMBRE}</td>
                          <td>{element.SOC}</td>
                          <td>
                            {element.DIRECCION1} {element.DIRECCION2}{" "}
                            {element.DIRECCION3}, {element.COMUNA}{" "}
                            {element.COMUNA2}
                          </td>
                          <td>{element.comuna}</td>
                          <td>{element.TAMANOPRODUCTO}</td>
                          <td>{element.UNIDADES}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
              </Box>
              <Container></Container>
            </Card>
          ) : null}
        </Box>
      </Card>
    </div>
  );

  mapa = (
    <div>
      {dataPoligons ? (
        <>
          <PlannerManagerMap
            dataPoligons={dataPoligons}
            setTriger={setTriger}
            companyId={companyId}
            userUid={userUid}
            setActiveIndex={setActiveIndex}
          ></PlannerManagerMap>
        </>
      ) : (
        "CARGANDO"
      )}
    </div>
  );

  planingList = (
    <div>
      <PlannerManagerRoutes
        handleClickNew={handleClickNew}
        handleGoProps={handleGoProps}
        handleGoDispatches={handleGoDispatches} // No en uso
      ></PlannerManagerRoutes>
    </div>
  );

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }

  console.log(seleccionByMapDataWaitPoints);
  return (
    <>
      <Tab
        className="tabular menu"
        menu={{ pointing: true }}
        panes={panes}
        activeIndex={activeIndex}
        onTabChange={(e, { activeIndex }) => {
          setActiveIndex(activeIndex);
        }}
      ></Tab>
    </>
  );
}
