import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";

import { db } from "../../../../firebase";
import { query, where, getDocs, collection } from "firebase/firestore";
import UserCompaniesList from "../../../../components/Dashboard/UserCompaniesList/UserCompaniesList";
import { requestAccessToCompany } from "../../../../utils/requestAccessToCompany";

const FromFindCompany = ({ userId, userName, email }) => {
  const [rut, setRut] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [messageSent, setMessageSent] = useState(false);
  const [requests, setRequests] = useState([]);

  const handleRutChange = async (e) => {
    setRut(e.target.value);

    if (e.target.value) {
      const companiesRef = collection(db, "companies");
      const q = query(companiesRef, where("rut", "==", e.target.value));
      const querySnapshot = await getDocs(q);

      const matchedCompanies = [];
      querySnapshot.forEach((doc) => {
        matchedCompanies.push({ id: doc.id, ...doc.data() });
      });

      setCompanies(matchedCompanies);
    } else {
      setCompanies([]);
    }
  };

  const handleCompanySelect = (e) => {
    const selectedCompanyId = e.target.value;
    const company = companies.find(
      (company) => company.id === selectedCompanyId
    );
    setSelectedCompany(company); // Almacena todo el objeto de la empresa seleccionada
  };

  const handleSubmit = async () => {
    console.log(selectedCompany); // Asegúrate de que selectedCompany tiene el atributo `rut`
    try {
      if (selectedCompany) {
        await requestAccessToCompany(
          userId,
          userName,
          email,
          selectedCompany.id, // Enviar el ID de la empresa
          selectedCompany.name, // Enviar el nombre de la empresa
          selectedCompany.rut // Enviar el RUT de la empresa
        );

        const newRequest = {
          companyName: selectedCompany.name,
          companyRut: selectedCompany.rut, // Guardar también el RUT en la solicitud
          status: "Pendiente",
        };
        setRequests([...requests, newRequest]);
        setMessageSent(true);
        setSelectedCompany(null); // Clear the selection
      }
    } catch (error) {
      console.error("Error al enviar la solicitud: ", error);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 4,
        backgroundColor: "#f8F8F8",
        p: 1,
        borderRadius: "3px",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Buscar Empresa por Rut
      </Typography>

      <TextField
        label="Rut"
        variant="outlined"
        fullWidth
        value={rut}
        onChange={handleRutChange}
        sx={{ mb: 2 }}
      />

      {companies.length > 0 ? (
        <TextField
          select
          label="Seleccionar Empresa"
          variant="outlined"
          fullWidth
          value={selectedCompany?.id || ""}
          onChange={handleCompanySelect}
          sx={{ mb: 2 }}
        >
          {companies.map((company, index) => (
            <MenuItem key={index} value={company.id}>
              {company.name} - {company.rut}
            </MenuItem>
          ))}
        </TextField>
      ) : null}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        disabled={!selectedCompany}
      >
        Enviar Solicitud
      </Button>

      {messageSent && (
        <Typography
          variant="body1"
          color="success.main"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Solicitud enviada.
        </Typography>
      )}

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Últimas Solicitudes
      </Typography>

      <List>
        {requests.map((request, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${request.companyName} - ${request.companyRut}`}
            />
            <Chip
              label={request.status}
              color={
                request.status === "Pendiente"
                  ? "warning"
                  : request.status === "Aceptado"
                  ? "success"
                  : "error"
              }
            />
          </ListItem>
        ))}
      </List>
      <UserCompaniesList />
    </Box>
  );
};

export default FromFindCompany;
