import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useGetMessagesByCompanyId (CompanyId, userRoll ){
    const [bicciMessagesByCompanyId, setMessagesByID] = useState([])
    const [loadMessagesByCompanyId, setLoadMessagesByID] = useState(false)
    const [errorMessagesByCompanyId, setErrorMessagesByID] = useState(null)    
  
    useEffect(()=>{
      fetchData(userRoll)
    },[CompanyId && userRoll])
  
  
    const fetchData = async (userEmail) => {
      if(userEmail){
        try {
          setLoadMessagesByID(true)

          const allMsj = await db.collection(`${process.env.REACT_APP_COL_MSJ}`).doc(CompanyId).collection('system')
          .get();
          var dataAllMsj = allMsj.docs.map(doc => ({id: doc.id, ...doc.data()}))
          //console.log(dataAllMsj)
          setMessagesByID(dataAllMsj)
          setLoadMessagesByID(false)
        } catch (error) {
          //console.log(error)
          setErrorMessagesByID(true)
          setMessagesByID([])
        }
      }
 
    }  


    return {bicciMessagesByCompanyId, loadMessagesByCompanyId, errorMessagesByCompanyId}
}

export default useGetMessagesByCompanyId