import React from "react";
import { Container } from "semantic-ui-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Boxinfoservices from "../../../components/Micuentacardboxs/Boxinfoservices";
import Boxdireccion from "../../../components/Micuentacardboxs/Boxdireccion";
import Boxdispach from "../../../components/Micuentacardboxs/Boxdispach";
import { useEffect } from "react";

export const OpcionesLaterales = (props) => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    if (props) {
      setUser(props.useStorage);
    }
  }, [props]);

  return (
    <Container>
      <Boxinfoservices
        serviciosAct={props.serviciosAct}
        props={props}
      ></Boxinfoservices>

      <Boxdireccion props={props}></Boxdireccion>

      <Boxdispach props={props} usuarioStorage={user}></Boxdispach>
    </Container>
  );
};
