import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  FormControl,
  LinearProgress,
  InputLabel,
  Select,
  Stack,
  Chip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { grey, green } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LaunchIcon from "@mui/icons-material/Launch";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Card } from "@mui/material";
import { useEffect } from "react";
import SwipeableSearcherDrawer from "../../../../pages/Dashboard/Guias/SwipeableSearcherDrawer";
import Grid from "@mui/material/Grid";
import GraficasChartLineDaysMonths from "../../../GraficasChartLineDaysMonths/GraficasChartLineDaysMonths";
import generarReporte from "../../../../utils/Reports/generarReporte";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../CustomDateRangePicker";
import { fDate } from "../../../../utils/objects/FormatTime";
import EventIcon from "@mui/icons-material/Event";
import ReusableFormImportOrders from "../../../ReusableFormImportOrders/ReusableFormImportOrders";
import DashIconsResumenDispachsMinimal from "../../DashIconsResumenDispachs/DashIconsResumenDispachsMinimal";
import DashIconsResumenIncidencias from "../../DashIconsResumenDispachs/DashIconsResumenIncidencias";
import { CustomDateRangePickerStyle } from "../../../CustomDateRangePicker/CustomDateRangePickerStyle";
import ToolFiltersComponent from "../ToolFiltersComponent/ToolFiltersComponent";

export default function ToolDashAppBarCompanyOps(props) {
  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);

  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const [openImport, setOpenImport] = React.useState(false);

  useEffect(() => {
    if (props.dataOrdersFilter) {
      setDataMergeLocalOrder(props.dataOrdersFilter);
    }
  }, [props, viewFinFindOrdes, props.dataOrdersFilter]);

  useEffect(() => {
    if (props.companiesData) {
      // console.log("Selecctor", props.companiesData);
      // const validCompanies = props.companiesData.filter(
      //   (company) =>
      //     company.active === true &&
      //     company.integrationsUpdateStatus === true &&
      //     typeof company.integrationServicesBicciID === "string"
      // );
      props.setFilteredCompanies(props.companiesData);
    }
  }, [props && props.companiesData]);

  useEffect(() => {
    if (props.companiesOrigin.length > 0) {
      props.setFilteredOrigins(props.companiesOrigin);
    }
  }, [props && props.companiesOrigin]);

  // useEffect(() => {
  //   console.log(selectedCompanies);
  //   props.setViewFilterMergedOrders(selectedCompanies);
  // }, [selectedCompanies]);

  // useEffect(() => {
  //   console.log(selectedOrigins);
  //   props.setViewFilterMergedOrdersOrigins(selectedOrigins);
  // }, [selectedOrigins]);

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const handleFilter = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleChange = (event) => {
    props.setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    props.setSelectedOrigins(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ToolFiltersComponent
        selectedCompanies={props.selectedCompanies}
        selectedOrigins={props.selectedOrigins}
        filteredCompanies={props.filteredCompanies}
        filteredOrigins={props.filteredOrigins}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
      ></ToolFiltersComponent>

      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          borderBlockColor: grey[200],
          padding: "20px",
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "70%",
            },
          }}
        >
          <Box sx={{ display: "flex", direction: "row" }}>
            {/* <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                },
              }}
            >
              <Box sx={{ display: "flex", direction: "row" }}>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuReport}
                    sx={{ color: "#4267b2" }}
                  >
                    <SaveAltIcon sx={{ color: "#4267b2" }} />
                  </IconButton>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElReport}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElReport)}
                    onClose={handleCloseMenuReport}
                  >
                    <MenuItem
                      onClick={handleCloseMenuReport}
                      sx={{ color: "#4267b2" }}
                    >
                      {props.dataOrdersFilter &&
                      props.dataOrdersFilter.length > 0 ? (
                        <>
                          <button
                            onClick={() =>
                              generarReporte(props.dataOrdersFilter)
                            }
                            className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                          >
                            {" "}
                            Reporte Pagos Riders
                          </button>
                        </>
                      ) : null}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenuReport}
                      sx={{ color: "#4267b2" }}
                    >
                      {props.dataOrdersFilter &&
                      props.dataOrdersFilter.length > 0 ? (
                        <>
                          <button
                            onClick={() =>
                              props.generarReporteTurno(props.dataOrdersFilter)
                            }
                            className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                          >
                            {" "}
                            Cierre Turno Salida directas
                          </button>
                        </>
                      ) : null}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenuReport}
                      sx={{ color: "#4267b2" }}
                    >
                      {props.dataOrdersFilter &&
                      props.dataOrdersFilter.length &&
                      props.dataOrdersForAccions &&
                      props.dataOrdersForAccions.length > 0 ? (
                        <>
                          <button
                            onClick={() =>
                              props.generarReporteEstados(
                                props.dataOrdersForAccions
                              )
                            }
                            className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                          >
                            {" "}
                            Reporte Estados
                          </button>
                        </>
                      ) : (
                        <div>
                          Seleccione Órdenes para, exportar un reporte de
                          Estados
                        </div>
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenuReport}
                      sx={{ color: "#4267b2" }}
                    >
                      {props.dataOrdersFilte &&
                      props.dataOrdersFilter.length > 0 ? (
                        <button
                          onClick={() => props.findHistorials()}
                          className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                        >
                          {" "}
                          Ver Historiales de Órdenes
                        </button>
                      ) : null}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenuReport}
                      sx={{ color: "#4267b2" }}
                    >
                      {props.dataMerge && props.dataMerge.length > 0 ? (
                        <button
                          onClick={() => props.setWiewPrePdf(!props.wiewPrePdf)}
                          className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                        >
                          {props.wiewPrePdf === true
                            ? "Cerrar vista previa de reporte"
                            : "Crear reporte Cierre Diario"}
                        </button>
                      ) : null}
                    </MenuItem>
                  </Menu>
                </div>
                <div>
                  
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => props.handleModeChange("express")}
                    sx={{ color: "#4267b2" }}
                  >
                    <LaunchIcon sx={{ color: "#4267b2" }} />
                  </IconButton>
                </div>
                <div>
                  
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => props.handleModeChange("masive")}
                    sx={{ color: "#4267b2" }}
                  >
                    <FileUploadIcon sx={{ color: "#4267b2" }} />
                  </IconButton>

                  <ReusableFormImportOrders
                    open={openImport}
                    setOpen={setOpenImport}
                    handleClose={() => setOpenImport(false)}
                    companyData={props.companyData}
                  />
                </div>
              </Box>
            </Grid> */}
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "30%",
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                textAlign: "right",
              }}
            >
              {props.dataOrdersFilter.length} Items en la consulta actual
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
              },
              textAlign: "right",
            }}
          >
            <div className="">
              <DashIconsResumenDispachsMinimal
                items={props.listItems}
                cant={props.cantListItems}
              ></DashIconsResumenDispachsMinimal>
              {props && props.datailInsures ? (
                <DashIconsResumenIncidencias
                  datailInsures={props.datailInsures}
                ></DashIconsResumenIncidencias>
              ) : null}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
