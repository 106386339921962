import React, { useEffect, useRef, useCallback } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Alert,
  Card,
  LinearProgress,
} from "@mui/material";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { format } from "date-fns";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import mapStyles from "../../../components/MapsGoogle/mapStyles";

const libraries = ["places"];
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};

export default function Traking() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const [condeDispacht, setCondeDispacht] = React.useState("");
  const [errortrack, setErrortrack] = React.useState(null);
  const [sussestrack, setSussestrack] = React.useState(null);
  const [errorSinDato, setErrorSinDato] = React.useState(null);
  const [orderInfo, setOrderInfo] = React.useState(null);
  const [orderHistoy, setOrderHistory] = React.useState(null);
  const [riderLocation, setRiderLocation] = React.useState(null);

  const mapRef = useRef();

  useEffect(() => {
    console.log("Order Info:", orderInfo);
    if (orderInfo) {
      console.log("Order Info toAddressLocation:", orderInfo.toAddressLocation);
    }
  }, [orderInfo]);

  const findOrderTraking = async () => {
    setErrortrack(null);
    setSussestrack(null);

    if (!condeDispacht.trim()) {
      setErrortrack("Debe ingresar un código");
      return;
    }
    await encontrarOrden(condeDispacht);
  };

  const encontrarOrden = async (data) => {
    try {
      const findOrdesrDispacht = async (id) => {
        try {
          const colletioneReft = collection(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const orderReft = doc(colletioneReft, id);
          const ordesDispacht = await getDoc(orderReft);
          return ordesDispacht.data();
        } catch (error) {
          console.error("Error fetching order:", error);
        }
      };

      const response = await findOrdesrDispacht(data);

      if (response) {
        console.log("Order found:", response);
        setOrderInfo(response);
        setSussestrack("Encontrado !");
        setErrorSinDato(null);
        // Simulating fetching rider location
        setRiderLocation({
          latitude: -33.447487,
          longitude: -70.673676,
        });
      } else {
        setErrortrack("No encontramos este ID, verifique el código");
        setErrorSinDato("Ingrese un código de búsqueda");
        setOrderInfo(null);
        setRiderLocation(null);
      }
    } catch (error) {
      console.error("Error finding order:", error);
    }
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const markerLoadHandler = (markerInstance) => {
    const customIcon = {
      url: "/static/images/casita.png",
      scaledSize: new window.google.maps.Size(50, 50),
      anchor: new window.google.maps.Point(25, 50),
    };
    markerInstance.setIcon(customIcon);
  };

  const MarkerDestini = ({ orderInfo }) => {
    if (orderInfo && orderInfo.toAddressLocation) {
      return (
        <Marker
          key="orderInfoId"
          position={{
            lat: orderInfo.toAddressLocation.latitude,
            lng: orderInfo.toAddressLocation.longitude,
          }}
          onLoad={markerLoadHandler}
        />
      );
    }
    return null;
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card id="mapsTracking" sx={{ padding: 2 }}>
          <Typography variant="h5" component="h3">
            Sigue tus envíos con el ID de despacho.
          </Typography>

          {errortrack && (
            <Alert severity="warning" className="m-2">
              {errortrack}
            </Alert>
          )}

          {sussestrack && (
            <Alert severity="success" className="m-2">
              {sussestrack}
            </Alert>
          )}
          <br />

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                label="Ingrese su código de seguimiento"
                onChange={(e) => setCondeDispacht(e.target.value)}
                value={condeDispacht}
                InputProps={{
                  startAdornment: <TravelExploreIcon position="start" />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={findOrderTraking}
                fullWidth
                startIcon={<TravelExploreIcon />}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>

          {sussestrack && orderInfo && (
            <div className="m-2">
              <div className="alert alert-success">
                <Typography variant="h6" component="h4">
                  Resumen:
                </Typography>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Código</th>
                      <th scope="col">{orderInfo.id}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Envia</th>
                      <td>{orderInfo.placeName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Recibe</th>
                      <td>{orderInfo.userName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="alert alert-info">
                <Typography variant="h6" component="h4">
                  Historial
                </Typography>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderHistoy &&
                      orderHistoy.map((historyMk) => (
                        <tr key={historyMk.id}>
                          <th scope="row">
                            {format(
                              historyMk.dispachtStatusChangeDate,
                              "dd/MM/yy"
                            )}
                          </th>
                          <td>{historyMk.issues}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Card>
      </Grid>
      {errorSinDato && (
        <Grid item xs={12}>
          <Alert severity="info" className="m-2">
            {errorSinDato}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {orderInfo && orderInfo.toAddressLocation ? (
          <GoogleMap
            id="mapControl2"
            mapContainerStyle={mapContainerStyle}
            zoom={13}
            center={{
              lat: orderInfo.toAddressLocation.latitude,
              lng: orderInfo.toAddressLocation.longitude,
            }}
            options={options}
            onLoad={onMapLoad}
          >
            <MarkerDestini orderInfo={orderInfo} />
            {riderLocation && (
              <Circle
                key="orderDriver"
                center={{
                  lat: riderLocation.latitude,
                  lng: riderLocation.longitude,
                }}
                radius={3000} // Puedes ajustar el radio según tus necesidades
                options={{
                  strokeColor: "#591e8f",
                  strokeOpacity: 0.7,
                  strokeWeight: 1,
                  fillColor: "#591e8f",
                  fillOpacity: 0.35,
                }}
              />
            )}
          </GoogleMap>
        ) : (
          <LinearProgress />
        )}
      </Grid>
    </Grid>
  );
}
