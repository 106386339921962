import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Input, Container } from "semantic-ui-react";
import { ingresoUsuarioLogin } from "../../redux/usuarioDucks";
import { useDispatch, useSelector } from "react-redux";
import BtnAceceder from "../Login/buttons/BtnAceceder";
import BtnRegistrarme from "../Login/buttons/BtnRegistrarme";
const FormLogin = (props) => {
  const dispatch = useDispatch();

  const resperror = useSelector((store) => store.usuario.error);
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");

  const [error, setError] = React.useState(null);

  const [success, setSuccess] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      //    console.log('Ingrese Email')
      setError("Ingrese Email");
      return;
    }
    if (!pass.trim()) {
      //    console.log('Ingrese Contraseña')
      setError("Ingrese Contraseña");
      return;
    }
    if (pass.length < 5) {
      //     console.log('Contraseña mayor a 6 caracteres')
      setError("Contraseña mayor a 6 caracteres");
      return;
    }

    if (!resperror === null) {
      //   console.log('Error de acceso')
      setError("Error de acceso");
      return;
    }

    //   console.log('Validando usuario...')
    setError(null);
    Login();
  };

  const Login = React.useCallback(async () => {
    function delay() {
      setSuccess(null);
      setError(null);
    }
    setTimeout(delay, 1000);
    try {
      dispatch(ingresoUsuarioLogin(email, pass));
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Container className="formcontent " id="ajustefromIni">
      <Form onSubmit={procesarDatos}>
        {success ? (
          <div className="alert alert-success topalerts">
            <span>{success}</span>
          </div>
        ) : null}
        {error ? (
          <div className="alert alert-danger topalerts">
            <span>{error}</span>
          </div>
        ) : null}
        {resperror ? (
          <div className="alert alert-danger topalerts">
            <span>{resperror}</span>
          </div>
        ) : null}
        <Form.Group widths="equal">
          <Form.Field
            iconPosition="left"
            placeholder="Email"
            control={Input}
            type="email"
            label="Email"
            icon="at"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="password"
            label="Contraseña"
            placeholder="Contraseña"
            iconPosition="left"
            icon="lock"
            onChange={(e) => setPass(e.target.value)}
            value={pass}
          />
        </Form.Group>
        <Form.Group widths="equal"></Form.Group>
        <button className="btn btn-ebiex " type="sutmit">
          Entrar
        </button>
      </Form>
      <div>
        <BtnAceceder></BtnAceceder>
        <div>
          <button className="btn btn-nostyle ">
            <NavLink to="/recuperar-cuenta" exact="true">
              Recuperar contraseña
            </NavLink>
          </button>
          <BtnRegistrarme></BtnRegistrarme>
        </div>
      </div>
    </Container>
  );
};

export default FormLogin;
