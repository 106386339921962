export const BicciExpressOrderStatuses = {
  100: {
    status: 100,
    descriptionStatus: "created",
    timestamp: new Date(),
  },
  150: {
    status: 150,
    descriptionStatus: "confirmed",
    timestamp: null, // Se establecerá cuando la orden sea confirmada
  },
  200: {
    status: 200,
    descriptionStatus: "store",
    timestamp: null, // Se establecerá cuando la orden esté almacenada
  },
  250: {
    status: 250,
    descriptionStatus: "preparing",
    timestamp: null, // Se establecerá cuando la orden esté siendo preparada
  },
  300: {
    status: 300,
    descriptionStatus: "readyRetirement",
    timestamp: null, // Se establecerá cuando la orden esté lista para ser retirada
  },
  350: {
    status: 350,
    descriptionStatus: "pickedUp",
    timestamp: null, // Se establecerá cuando la orden haya sido recogida
  },
  400: {
    status: 400,
    descriptionStatus: "transit",
    timestamp: null, // Se establecerá cuando la orden esté en tránsito
  },
  450: {
    status: 450,
    descriptionStatus: "deliveryPoint",
    timestamp: null, // Se establecerá cuando la orden llegue al punto de entrega
  },
  500: {
    status: 500,
    descriptionStatus: "delivered",
    timestamp: null, // Se establecerá cuando la orden haya sido entregada
  },
  550: {
    status: 550,
    descriptionStatus: "pending",
    timestamp: null, // Se establecerá cuando la orden esté pendiente
  },
  600: {
    status: 600,
    descriptionStatus: "cancelled",
    timestamp: null, // Se establecerá si la orden es cancelada
  },
  700: {
    status: 700,
    descriptionStatus: "returned",
    timestamp: null, // Se establecerá si la orden es devuelta
  },
};

export const listItems = [
  { id: "Creadas" },
  { id: "Ruta" },
  { id: "Incidencias" },
  { id: "Finalizadas" },
  { id: "Total" },
];
