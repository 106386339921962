import  { useEffect, useState } from 'react'
import {db} from '../firebase'

function useGetDataVehicles (companyId, userVehicle){
// console.log(companyId)
// console.log(routeDate)
// console.log(userVehicles)
// console.log(userVehicle)
    const [vehiclesData, setVehiclesDataVehicles ] = useState(null)
    const [loadVehiclesData, setLoadDataVehicles] = useState(false)
    const [errorVehiclesData, setErrorDataVehicles] = useState(null)    
  
    useEffect(()=>{
      fetchData(companyId)
    },[companyId,userVehicle])
  
    const fetchData = async (companyId) => {
      try {
        if(companyId){
        setLoadDataVehicles(true)
        const allVehicles = await db.collection(`${process.env.REACT_APP_COL_USERS_VEHICLES}`)
        .get();
        var datatAllVehicles = allVehicles.docs.map(doc => ({id: doc.id, ...doc.data()}))
        if(userVehicle){
          let userVehicleClear = userVehicle.trim().toLowerCase() 
          datatAllVehicles = datatAllVehicles.filter(userVehicles => userVehicles.id.toLowerCase().includes(userVehicleClear));
          }
          setVehiclesDataVehicles(datatAllVehicles)
          //console.log(datatAllVehicles)
        setLoadDataVehicles(false)
        setErrorDataVehicles(false)
        }else{
          setLoadDataVehicles(true)
            const allVehicles = await db.collection(`${process.env.REACT_APP_COL_USERS_VEHICLES}`)
            .get();
            var datatAllVehicles = allVehicles.docs.map(doc => ({id: doc.id, ...doc.data()}))
            if(userVehicle){
              let userVehicleClear = userVehicle.trim().toLowerCase() 
              datatAllVehicles = datatAllVehicles.filter(userVehicles => userVehicles.id.toLowerCase().includes(userVehicleClear));
              }
              setVehiclesDataVehicles(datatAllVehicles)
            }
            //console.log(datatAllVehicles)
            setLoadDataVehicles(false)
            setErrorDataVehicles(false)
          } catch (error) {
            //console.log(error)
            setErrorDataVehicles(true)
          }
        }
  
    return {vehiclesData, loadVehiclesData, errorVehiclesData}
}

export default useGetDataVehicles