import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserContext } from "../context/CurrentUserProvider";
import Moment from "moment";

const cacheKey = "bexpress_companyDataCache";
const cacheExpiryKey = "bexpress_companyDataCacheExpiry";
const cacheDuration = 3600 * 1000; // 1 hora en milisegundos

async function generateKey(password) {
  const encodedPassword = new TextEncoder().encode(password);
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encodedPassword,
    "PBKDF2",
    false,
    ["deriveKey"]
  );

  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: new TextEncoder().encode("unSaltUnico"), // Deberías generar un salt único para cada usuario
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}

// Función para encriptar datos
async function encryptData(data, password) {
  const encodedData = new TextEncoder().encode(data);
  const key = await generateKey(password);
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedData = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    encodedData
  );

  return {
    encryptedData: Array.from(new Uint8Array(encryptedData)),
    iv: Array.from(iv),
  };
}

// Función para desencriptar datos
async function decryptData(encryptedData, password, iv) {
  const key = await generateKey(password);
  try {
    const decryptedData = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: new Uint8Array(iv) },
      key,
      new Uint8Array(encryptedData)
    );
    return new TextDecoder().decode(decryptedData);
  } catch (error) {
    throw new Error("Error during decryption: " + error.message);
  }
}

// Función para almacenar datos en la caché con encriptación
const storeDataInCache = async (data, password) => {
  try {
    const { encryptedData, iv } = await encryptData(
      JSON.stringify(data),
      password
    );
    localStorage.setItem(cacheKey, JSON.stringify({ encryptedData, iv }));
    localStorage.setItem(cacheExpiryKey, Date.now() + cacheDuration);
  } catch (error) {
    console.error("Error al almacenar datos en la caché:", error);
  }
};

// Función para recuperar datos desde la caché con desencriptación
const getDataFromCache = async (password) => {
  try {
    const expiry = localStorage.getItem(cacheExpiryKey);
    if (expiry && Date.now() > expiry) {
      // La caché ha expirado
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheExpiryKey);
      return null;
    }

    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      console.log("Cargando desde caché encriptada Consulta en Linea");
      const { encryptedData, iv } = JSON.parse(cachedData);
      const decryptedData = await decryptData(encryptedData, password, iv);
      return JSON.parse(decryptedData);
    }
    return null;
  } catch (error) {
    console.error("Error al recuperar datos de la caché:", error);
    return null;
  }
};

function useGetOrdersDataByCompany(initDateInitial, endDateInitial) {
  const { userDataContext } = useContext(UserContext);

  const days = 1;
  const daysfinal = 1;
  const [consoleIntegrations, setConsoleIntegrations] = useState([]);
  const [initDate, setInitDate] = useState(
    initDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    endDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );
  const [mergedOrders, setMergedOrders] = useState([]);
  const [loadingContext, setLoading] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [userAccess, setUserAcess] = useState([]);

  const companyIDs_Int = useMemo(() => {
    if (userDataContext && userDataContext.userData) {
      const companyCollaborator =
        userDataContext.userData.companyCollaborator || [];
      const userCompanyID = userDataContext.userData.companyID;

      // Asegúrate de que userCompanyID no esté ya en companyCollaborator
      if (userCompanyID && !companyCollaborator.includes(userCompanyID)) {
        companyCollaborator.push(userCompanyID);
      }
      // console.log("1", companyCollaborator);

      // Actualiza el estado de userAccess con el array actualizado
      setUserAcess(companyCollaborator);

      return companyCollaborator;
    }
    return [];
  }, [userDataContext]);

  const isAdmin = useMemo(() => {
    return companyIDs_Int.includes("BicciAdmin");
  }, [companyIDs_Int]);

  useEffect(() => {
    const loadAndMergeOrders = async () => {
      setLoading(true);

      if (isAdmin) {
        // El usuario es admin, omitir fetchConsoleIntegrations y getConsoleIntegrations
        let integrations = await getDataFromCache(
          process.env.REACT_APP_SECRETENCRIPT_WEB
        ); // Intentar obtener datos desde la caché

        if (!integrations && companyIDs_Int.length > 0) {
          integrations = await getAllConsoleIntegrations(companyIDs_Int);
          storeDataInCache(
            integrations,
            process.env.REACT_APP_SECRETENCRIPT_WEB
          ); // Almacenar los datos en la caché para futuras consultas
        }
        setCompaniesData(integrations);

        const ordersToday = await findOrderBicci(
          [],
          initDate,
          endDate,
          isAdmin
        );

        const ordersPending = await findOrderBicciPending([], isAdmin);

        // Merge de órdenes con eliminación de duplicados
        const allOrdersMap = {};
        [...ordersToday, ...ordersPending].forEach((order) => {
          allOrdersMap[order.id] = order;
        });

        // Convertir el mapa de vuelta a un array de órdenes únicas
        const mergedArray = Object.values(allOrdersMap);

        setMergedOrders(mergedArray);
      } else {
        // El usuario no es admin, proceder con fetchConsoleIntegrations y getConsoleIntegrations
        let integrations = await getDataFromCache(
          process.env.REACT_APP_SECRETENCRIPT_WEB
        ); // Intentar obtener datos desde la caché

        if (!integrations && companyIDs_Int.length > 0) {
          integrations = await getConsoleIntegrations(companyIDs_Int);
          storeDataInCache(
            integrations,
            process.env.REACT_APP_SECRETENCRIPT_WEB
          ); // Almacenar los datos en la caché para futuras consultas
        }
        setCompaniesData(integrations);
        setConsoleIntegrations(integrations);
      }

      setLoading(false);
    };

    loadAndMergeOrders();
  }, [initDate, endDate, companyIDs_Int, isAdmin]);

  const getAllConsoleIntegrations = async (userAccess) => {
    try {
      if (userAccess.length > 0) {
        const refsCompanys = collection(
          db,
          process.env.REACT_APP_COL_COMPANIES
        );
        const companiesDocs = await getDocs(refsCompanys);

        if (companiesDocs.docs.length > 0) {
          const documentComanies = await Promise.all(
            companiesDocs.docs.map(async (docSnapshot) => {
              let companyData = {
                id: docSnapshot.id,
                ...docSnapshot.data(),
              };

              // Verificar si tiene integración con Bicci y está activa
              if (
                companyData.integrationServicesBicciID &&
                companyData.integrationServicesBicciIsActive
              ) {
                const collRefBicci = collection(
                  db,
                  process.env.REACT_APP_COL_BICCI_COMPANIES
                );
                const docRefBicci = doc(
                  collRefBicci,
                  companyData.integrationServicesBicciID
                );
                let documenCompanBicci = await getDoc(docRefBicci);

                if (documenCompanBicci.exists()) {
                  let companiBicci = documenCompanBicci.data();
                  // Fusionar datos de la empresa con los datos de Bicci
                  companyData = {
                    ...companyData,
                    ...companiBicci, // Datos de Bicci sobrescribirán los existentes en caso de conflicto
                  };
                } else {
                  console.log(
                    `No se encontraron datos en Bicci para la empresa con ID: ${companyData.integrationServicesBicciID}`
                  );
                }
              }

              return companyData;
            })
          );

          return documentComanies;
        } else {
          console.log("Sin resultados");
          return [];
        }
      } else {
        console.log("No posee ninguna colaboración");
        return [];
      }
    } catch (error) {
      console.log("Error al obtener integraciones:", error);
      return [];
    }
  };

  const getConsoleIntegrations = async (userAccess) => {
    try {
      if (userAccess.length > 0 && userAccess.length < 10) {
        const refsCompanys = collection(
          db,
          process.env.REACT_APP_COL_COMPANIES
        );
        const q = query(refsCompanys, where("companyRut", "in", userAccess));
        const companiesDocs = await getDocs(q);

        if (companiesDocs.docs.length > 0) {
          const documentComanies = await Promise.all(
            companiesDocs.docs.map(async (doc) => {
              let companyData = {
                id: doc.id,
                ...doc.data(),
              };

              // Verificar si tiene integración con Bicci y está activa
              if (
                companyData.integrationServicesBicciID &&
                companyData.integrationServicesBicciIsActive
              ) {
                const collRefBicci = collection(
                  db,
                  process.env.REACT_APP_COL_BICCI_COMPANIES
                );
                const docRefBicci = doc(
                  collRefBicci,
                  companyData.integrationServicesBicciID
                );
                let documenCompanBicci = await getDoc(docRefBicci);

                if (documenCompanBicci.exists()) {
                  let companiBicci = documenCompanBicci.data();
                  // Fusionar datos de la empresa con los datos de Bicci
                  companyData = {
                    ...companyData,
                    ...companiBicci, // Datos de Bicci sobrescribirán los existentes en caso de conflicto
                  };
                } else {
                  console.log(
                    `No se encontraron datos en Bicci para la empresa con ID: ${companyData.integrationServicesBicciID}`
                  );
                }
              }

              return companyData;
            })
          );

          return documentComanies;
        } else {
          console.log("Sin resultados");
          return [];
        }
      } else {
        console.log(
          "Excede el límite de empresas o no posee ninguna colaboración"
        );
        return [];
      }
    } catch (error) {
      console.log("Error al obtener integraciones:", error);
      return [];
    }
  };

  //Bicci
  const findOrderBicci = async (arrayCompanys, initDate, endDate, isAdmin) => {
    const allOrders = [];
    const i = new Date(initDate);
    const e = new Date(endDate);

    function initformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + days);
      res.setHours(0);
      res.setMinutes(0);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }
    function finalformatDate(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + daysfinal);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangInit = initformatDate(i);
    var rangFinal = finalformatDate(e);

    try {
      const ordersDispachtRef1 = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      if (isAdmin) {
        // Si es admin, no filtramos por companyID, pero sí por fechas
        const q = query(
          ordersDispachtRef1,
          where("createdAt", ">=", rangInit),
          where("createdAt", "<=", rangFinal)
        );
        const querySnapshot1 = await getDocs(q);
        const orders = querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        allOrders.push(...orders);
      } else {
        for (let i = 0; i < arrayCompanys.length; i++) {
          const q = query(
            ordersDispachtRef1,
            where(
              "companyID",
              "==",
              arrayCompanys[i].integrationServicesBicciID
            ),
            where("createdAt", ">=", rangInit),
            where("createdAt", "<=", rangFinal)
          );
          const querySnapshot1 = await getDocs(q);
          const orders = querySnapshot1.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          allOrders.push(...orders);
        }
      }
    } catch (error) {
      console.log(`Error fetching orders:`, error);
    }

    return allOrders;
  };

  const findOrderBicciPending = async (arrayCompanys, isAdmin) => {
    const allPendingOrders = [];

    try {
      const ordersDispachtRef1 = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      if (isAdmin) {
        // Si es admin, no filtramos por companyID, solo por estado
        const q = query(
          ordersDispachtRef1,
          where("status", ">=", 0),
          where("status", "<", 8)
          // where("lostDelivery", "==", false)
        );
        const querySnapshot1 = await getDocs(q);
        const pendingOrders = querySnapshot1.docs
          .filter((doc) => doc.data().lostDelivery !== true) // Filtrar nuevamente por lostDelivery
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        allPendingOrders.push(...pendingOrders);
      } else {
        // Si no es admin, filtramos por cada companyID
        for (let i = 0; i < arrayCompanys.length; i++) {
          const q = query(
            ordersDispachtRef1,
            where(
              "companyID",
              "==",
              arrayCompanys[i].integrationServicesBicciID
            ),
            where("status", ">=", 0),
            where("status", "<", 8)
            // where("lostDelivery", "==", false)
          );
          const querySnapshot1 = await getDocs(q);
          const pendingOrders = querySnapshot1.docs
            .filter((doc) => doc.data().lostDelivery !== true) // Filtrar nuevamente por lostDelivery
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          allPendingOrders.push(...pendingOrders);
        }
      }
    } catch (error) {
      console.log(`Error fetching pending orders:`, error);
    }

    return allPendingOrders;
  };

  return {
    mergedOrders,
    loadingContext,
    companiesData,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
  };
}

export default useGetOrdersDataByCompany;
