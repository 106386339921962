import React from "react";
import { useEffect } from "react";
import { Container, Form, Input, Card, CardContent } from "semantic-ui-react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip, Typography, IconButton } from "@mui/material";
import Moment from "moment";
import useGetDataRider from "../../../hooks/getDataRider";
import useGetDataVehicles from "../../../hooks/getDataVehicles";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { db } from "../../../firebase";
import useGetRoutesExpressById from "../../../hooks/getRoutesExpressById";
import useGetOrderIDExpress from "../../../hooks/getDataDispachst";
import { useParams } from "react-router-dom";

const DashRoutesEdit = (props) => {
  const { id: routeId } = useParams();
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const usuarioLocal = usuarioStorage.uid;
  const LocalRol = usuarioStorage.roll;
  var companyId = null;
  const [userRider, setUserRiderr] = React.useState("");
  const [userVehicle, setUserVehicle] = React.useState("");
  const [OrderID, setOrderID] = React.useState("");
  const [ordersStatus, setOrdersStatus] = React.useState([0, 1, 2, 3, 4, 5, 7]);
  const [currentOrderList, setCurrentOrderList] = React.useState([]);
  const [routeDate, setRouteDate] = React.useState("");
  const [routeStartTimeDelivery, setRouteStartTimeDelivery] =
    React.useState("08:30");
  const [routeStayTimeDelivery, setRouteStayTimeDelivery] =
    React.useState("00:15");
  const [routeEndTimeDelivery, setRouteEndTimeDelivery] =
    React.useState("20:30");
  //console.log(usuarioLocal)
  const { userdata } = useObtenerDatosdeCliente(usuarioLocal);

  const { riderData } = useGetDataRider(companyId, userRider);
  const { vehiclesData } = useGetDataVehicles(companyId, userVehicle);
  const { bicciRoutesExpressByID } = useGetRoutesExpressById(
    companyId,
    LocalRol,
    routeId
  );
  const { bicciOrderIDExpress } = useGetOrderIDExpress(
    companyId,
    LocalRol,
    ordersStatus,
    OrderID
  );
  const [ridersRows, setRidersRows] = React.useState("");
  const [vehiclesRows, setVehiclesRows] = React.useState("");
  const [OrdersRows, setOrdersRows] = React.useState("");
  const [targetedOrdersRows, setTargetedOrdersRows] = React.useState("");
  const [render, setRender] = React.useState(false);
  const [errorData, setError] = React.useState(null);
  const [succesCreate, setSuccesCreate] = React.useState(null);

  const columnsRiders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (ridersRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={ridersRows.id}
              value={ridersRows.row.name}
              style={{ marginLeft: 5 }}
              onClick={handleGoPropsRidersRows}
            >
              Seleccionar
            </button>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "id",
      headerName: "Rider Id",
      width: 100,
      editable: true,
    },
    {
      field: "bicciNumber",
      headerName: "Bicci Number",
      sortable: false,
      width: 100,
    },
  ];

  const columnsVehicle = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (vehicleRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={vehicleRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleGoPropsVehicleRows}
            >
              Seleccionar
            </button>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 120,
      editable: true,
    },
    {
      field: "VehiclesTypes",
      headerName: "Veículo",
      width: 120,
      editable: true,
    },
    {
      field: "travelMode",
      headerName: "Tipo de ruta",
      width: 120,
      editable: true,
    },
  ];

  const columsOrders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (OrdersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={OrdersRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleGoPropsOrdersRows}
            >
              Agregar
            </button>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: true,
    },
    {
      field: "toAddress",
      headerName: "Destino",
      width: 120,
      editable: true,
    },
    {
      field: "userName",
      headerName: "Destinatario",
      width: 120,
      editable: true,
    },
  ];

  const targetColumsOrders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (targetedOrdersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={targetedOrdersRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleRemoveItem}
            >
              Remover
            </button>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: true,
    },
    {
      field: "toAddress",
      headerName: "Destino",
      width: 120,
      editable: true,
    },
    {
      field: "userName",
      headerName: "Destinatario",
      width: 120,
      editable: true,
    },
  ];

  var [newRoute, setNewRoute] = React.useState({
    createBy: usuarioLocal,
    createDate: Moment(Date.now()).format("DD-MM-YYYY"),
    date: Date.now(),
    id: "",
    vehicleID: "",
    driverID: "",
    driverName: "",
    companyId: "",
    travelMode: "",
    currentOrderList: {},
    origin: { lat: -33.41856676168833, lng: -70.6027176809204 },
    destination: { lat: -33.41856676168833, lng: -70.6027176809204 },
    optimizeWaypoints: true, // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    wayPoints: [],
    waypoint_order: [],
    maxTimeDelivery: routeStayTimeDelivery,
    dateDeliveryText: "",
    dateDelivery: "",
    startTimeDelivery: routeStartTimeDelivery,
    endTimeDelivery: routeEndTimeDelivery,
    distanceValue: 0,
    durationValue: 0,
    setRoutePoints: 0,
    dispacthDelyvery: 0,
    dispacthPartials: 0,
    dispacthNotDelyvery: 0,
    fullCompliance: 0,
    warnings: "",
  });

  useEffect(() => {
    getBicciDriversData();
    getBicciVehiclesData();
    getBicciUserData();
    getAviableOrders();
  }, [vehiclesData, riderData, userdata, bicciOrderIDExpress]); //, targetedOrdersRows

  useEffect(() => {}, [render]);

  useEffect(() => {
    getAviableOrders();
  }, [OrderID]);

  useEffect(() => {
    if (bicciRoutesExpressByID) {
      setObjRoute(bicciRoutesExpressByID);
    }
  }, [bicciRoutesExpressByID]);

  const setObjRoute = (bicciRoutesExpressByID) => {
    newRoute.id = bicciRoutesExpressByID.id;
    newRoute.createBy = usuarioLocal;
    newRoute.createDate = bicciRoutesExpressByID.createDate;
    newRoute.date = bicciRoutesExpressByID.date;
    newRoute.vehicleID = bicciRoutesExpressByID.vehicleID;
    newRoute.driverID = bicciRoutesExpressByID.driverID;
    newRoute.driverName = bicciRoutesExpressByID.driverName;
    newRoute.companyId = bicciRoutesExpressByID.companyId;
    newRoute.travelMode = bicciRoutesExpressByID.travelMode;
    newRoute.currentOrderList = bicciRoutesExpressByID.currentOrderList;
    newRoute.origin = bicciRoutesExpressByID.origin;
    newRoute.destination = bicciRoutesExpressByID.destination;
    newRoute.optimizeWaypoints = bicciRoutesExpressByID.optimizeWaypoints; // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    newRoute.wayPoints = bicciRoutesExpressByID.wayPoints;
    newRoute.waypoint_order = bicciRoutesExpressByID.waypoint_order;
    newRoute.maxTimeDelivery = bicciRoutesExpressByID.maxTimeDelivery;
    newRoute.dateDeliveryText = bicciRoutesExpressByID.dateDeliveryText;
    newRoute.dateDelivery = bicciRoutesExpressByID.dateDelivery;
    newRoute.startTimeDelivery = bicciRoutesExpressByID.startTimeDelivery;
    newRoute.endTimeDelivery = bicciRoutesExpressByID.endTimeDelivery;
    newRoute.distanceValue = bicciRoutesExpressByID.distanceValue;
    newRoute.durationValue = bicciRoutesExpressByID.durationValue;
    newRoute.setRoutePoints = bicciRoutesExpressByID.wayPoints.length;
    newRoute.dispacthDelyvery = bicciRoutesExpressByID.dispacthDelyvery;
    newRoute.dispacthPartials = bicciRoutesExpressByID.dispacthPartials;
    newRoute.dispacthNotDelyvery = bicciRoutesExpressByID.dispacthNotDelyvery;
    newRoute.fullCompliance = bicciRoutesExpressByID.fullCompliance;
    newRoute.warnings = bicciRoutesExpressByID.warnings;
    setCurrentOrderList(newRoute.currentOrderList);
    setRender(!render);
  };

  const getBicciUserData = () => {
    if (userdata) {
      newRoute.companyId = userdata.userCompanyID;
    }
  };

  const getAviableOrders = () => {
    if (bicciOrderIDExpress) {
      var OrdersRowsTemp = [];
      var targetedOrdersRowsTemp = [];
      for (let i = 0; i < bicciOrderIDExpress.length; i++) {
        let opsList = Object.values(currentOrderList);
        if (opsList.includes(bicciOrderIDExpress[i].id)) {
          // console.log('encontrando ya asignados: ' + bicciOrderIDExpress[i].id)
          targetedOrdersRowsTemp.push({
            id: bicciOrderIDExpress[i].id,
            toAddress: bicciOrderIDExpress[i].toAddress,
            userName: bicciOrderIDExpress[i].userName,
          });
        } else {
          OrdersRowsTemp.push({
            id: bicciOrderIDExpress[i].id,
            toAddress: bicciOrderIDExpress[i].toAddress,
            userName: bicciOrderIDExpress[i].userName,
          });
        }
      }
      // console.log(OrdersRowsTemp)
      setOrdersRows(OrdersRowsTemp);
      setTargetedOrdersRows(targetedOrdersRowsTemp);
    }
  };

  const UpdateAviableOrders = () => {
    // console.log(newRoute.currentOrderList)
    if (bicciOrderIDExpress) {
      var OrdersRowsTemp = [];
      var targetedOrdersRowsTemp = [];
      for (let i = 0; i < bicciOrderIDExpress.length; i++) {
        let opsList = Object.values(newRoute.currentOrderList);
        if (opsList.includes(bicciOrderIDExpress[i].id)) {
          // console.log('encontrando ya asignados: ' + bicciOrderIDExpress[i].id)
          targetedOrdersRowsTemp.push({
            id: bicciOrderIDExpress[i].id,
            toAddress: bicciOrderIDExpress[i].toAddress,
            userName: bicciOrderIDExpress[i].userName,
          });
        } else {
          OrdersRowsTemp.push({
            id: bicciOrderIDExpress[i].id,
            toAddress: bicciOrderIDExpress[i].toAddress,
            userName: bicciOrderIDExpress[i].userName,
          });
        }
      }
      newRoute.setRoutePoints = targetedOrdersRowsTemp.length;
      setOrdersRows(OrdersRowsTemp);
      setTargetedOrdersRows(targetedOrdersRowsTemp);
    }
  };

  const getBicciDriversData = () => {
    if (riderData) {
      var ridersRows = [];
      for (let i = 0; i < riderData.length; i++) {
        ridersRows.push({
          id: riderData[i].id,
          name: riderData[i].name,
          bicciNumber: riderData[i].bicciNumber,
        });
      }
      setRidersRows(ridersRows);
    } else {
    }
  };

  const getBicciVehiclesData = () => {
    if (vehiclesData) {
      var vehicleRows = [];
      for (let i = 0; i < vehiclesData.length; i++) {
        vehicleRows.push({
          id: vehiclesData[i].id,
          travelMode: vehiclesData[i].travelMode,
          VehiclesTypes: vehiclesData[i].VehiclesTypes,
        });
      }
      setVehiclesRows(vehicleRows);
    } else {
      // // console.log('Sin data')
    }
  };

  const clearObjRoute = () => {
    if (newRoute.createBy !== bicciRoutesExpressByID.createBy) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.createDate !== bicciRoutesExpressByID.createDate) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.date !== bicciRoutesExpressByID.date) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.vehicleID !== bicciRoutesExpressByID.vehicleID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverID !== bicciRoutesExpressByID.driverID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverName !== bicciRoutesExpressByID.driverName) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.companyId !== bicciRoutesExpressByID.companyId) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.travelMode !== bicciRoutesExpressByID.travelMode) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.currentOrderList !== bicciRoutesExpressByID.currentOrderList) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.origin !== bicciRoutesExpressByID.origin) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.destination !== bicciRoutesExpressByID.destination) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.optimizeWaypoints !== bicciRoutesExpressByID.optimizeWaypoints
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.wayPoints !== bicciRoutesExpressByID.wayPoints) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.waypoint_order !== bicciRoutesExpressByID.waypoint_order) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.maxTimeDelivery !== bicciRoutesExpressByID.maxTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDeliveryText !== bicciRoutesExpressByID.dateDeliveryText) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDelivery !== bicciRoutesExpressByID.dateDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.startTimeDelivery !== bicciRoutesExpressByID.startTimeDelivery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.endTimeDelivery !== bicciRoutesExpressByID.endTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.distanceValue !== bicciRoutesExpressByID.distanceValue) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.durationValue !== bicciRoutesExpressByID.durationValue) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.setRoutePoints !== bicciRoutesExpressByID.setRoutePoints) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dispacthDelyvery !== bicciRoutesExpressByID.dispacthDelyvery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dispacthPartials !== bicciRoutesExpressByID.dispacthPartials) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.dispacthNotDelyvery !==
      bicciRoutesExpressByID.dispacthNotDelyvery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.fullCompliance !== bicciRoutesExpressByID.fullCompliance) {
      setError("Faltan cambios por guardar");
      return;
    }
    props.history.push(`/dashboard/routes/`);
    setRender(!render);
  };

  const handleGoedith = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/${e.target.id}/edit`);
  };

  const handleChange = (e) => {
    // console.log(e.target.name)
    if (e.target.name === "endHour") {
      setRouteEndTimeDelivery(e.target.value);
      newRoute.endTimeDelivery = e.target.value;
    }
    if (e.target.name === "awaitTime") {
      setRouteStayTimeDelivery(e.target.value);
      newRoute.maxTimeDelivery = e.target.value;
    }
    if (e.target.name === "initHour") {
      setRouteStartTimeDelivery(e.target.value);
      newRoute.startTimeDelivery = e.target.value;
    }
    if (e.target.name === "dispchDate") {
      setRouteDate(e.target.value);
      newRoute.dateDelivery = e.target.value;
      newRoute.dateDeliveryText = e.target.value;
    }
    setRender(!render);
  };

  const handleGoPropsOrdersRows = (e) => {
    // console.log('un click')
    e.preventDefault();
    // console.log(e.target.id)
    setCurrentOrderList(newRoute.currentOrderList);
    let opsList = Object.values(currentOrderList);
    if (!opsList.includes(e.target.id)) {
      opsList.push(e.target.id);
    }
    newRoute.currentOrderList = Object.assign({}, opsList);
    setCurrentOrderList(newRoute.currentOrderList);
    UpdateAviableOrders();
    // console.log(newRoute)
    setRender(!render);
  };

  const handleRemoveItem = (e) => {
    // console.log('accion remove')
    e.preventDefault();
    // console.log(e.target.id)
    setCurrentOrderList(newRoute.currentOrderList);
    let removeItem = Object.values(currentOrderList);

    var newArray = removeItem.filter((item) => item !== e.target.id);
    // console.log(newArray);

    newRoute.currentOrderList = Object.assign({}, newArray);
    setCurrentOrderList(newRoute.currentOrderList);
    UpdateAviableOrders();
    // console.log(newRoute)
    setRender(!render);
  };

  const handleGoProps = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/${e.target.id}/edit`);
  };

  const handleGoPropsRidersRows = (e) => {
    e.preventDefault();
    // console.log('un click')
    // console.log(e.target.value)
    // console.log(e.target.id)
    newRoute.driverID = e.target.id;
    newRoute.driverName = e.target.value;

    setRender(!render);
  };

  const handleGoPropsVehicleRows = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    newRoute.vehicleID = e.target.id;
    let tempData = vehiclesData.filter((item) => item.id === e.target.id);
    // console.log(tempData)
    newRoute.travelMode = tempData[0].travelMode;
    newRoute.origin = tempData[0].origen;
    newRoute.destination = tempData[0].destiny;
    setRender(!render);
  };

  const handleGoDispatches = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    props.history.push(`/dashboard/routes/details/${e.target.id}`);
  };
  const handleChangeDate = (event, data) => {
    // console.log(data)
    setRouteDate(data.value);
    newRoute.dateDelivery = Moment(data.value).format(
      "DD MMMM YYYY, h:mm:ss a"
    );
    newRoute.dateDeliveryText = Moment(data.value).format(
      "DD MMMM YYYY, h:mm:ss a"
    );
    setRender(!render);
  };

  const createrRoute = (e) => {
    e.preventDefault();
    // console.log('createrRoute')
  };

  function checkboxSelection(e) {
    // console.log(e.targat)
  }

  async function handleClickUpdate(e) {
    e.preventDefault();
    // console.log(newRoute)
    // console.log(newRoute.currentOrderList)
    if (!newRoute.companyId.trim()) {
      setError("Error companyId");
      return;
    }
    if (!newRoute.createBy.trim()) {
      setError("Error createBy");
      return;
    }
    // if(!newRoute.createDate.length()>0){setError('Error createDate')}
    // if(!newRoute.date.length()>0){setError('Error date')}
    //if(!newRoute.id.trim()){setError('Error ID');return}
    if (!newRoute.vehicleID.trim()) {
      setError("Error vehicleID");
      return;
    }
    if (!newRoute.driverID.trim()) {
      setError("Error driverID");
      return;
    }
    if (!newRoute.driverName.trim()) {
      setError("Error driverName");
      return;
    }
    if (!newRoute.travelMode.trim()) {
      setError("Error travelMode");
      return;
    }
    //  if(!Object.entries(newRoute.currentOrderList).length !== true){setError('Error currentOrderList');return}
    if (!Object.entries(newRoute.origin).length === true) {
      setError("Error origin");
      return;
    }
    if (!Object.entries(newRoute.destination).length === true) {
      setError("Error destination");
      return;
    }
    //if(!Object.entries(newRoute.optimizeWaypoints).length === true){setError('Error optimizeWaypoints');return}
    //if(!Object.entries(newRoute.wayPoints).length !== true){setError('Error wayPoints');return}
    //if(!Object.entries(newRoute.waypoint_order).length !== true){setError('Error wayPoints');return}
    // if(!newRoute.maxTimeDelivery.trim()){setError('Error maxTimeDelivery');return}
    // if(!newRoute.dateDeliveryText.trim()){setError('Error dateDeliveryText');return}
    // if(!newRoute.dateDelivery.trim()){setError('Error dateDelivery');return}
    // if(!newRoute.startTimeDelivery.trim()){setError('Error startTimeDelivery');return}
    if (!newRoute.distanceValue === 0) {
      setError("Error distanceValue");
      return;
    }
    if (!newRoute.durationValue === 0) {
      setError("Error durationValue");
      return;
    }
    if (!newRoute.setRoutePoints === 0) {
      setError("Error setRoutePoints");
      return;
    }
    if (!newRoute.dispacthDelyvery === 0) {
      setError("Error dispacthDelyvery");
      return;
    }
    if (!newRoute.dispacthPartials === 0) {
      setError("Error dispacthPartials");
      return;
    }
    if (!newRoute.dispacthNotDelyvery === 0) {
      setError("Error dispacthNotDelyvery");
      return;
    }
    if (!newRoute.fullCompliance === 0) {
      setError("Error fullCompliance");
      return;
    }
    //if(!newRoute.warnings.trim()){setError('Error warnings')}

    setError(null);
    // console.log('Update ruta')

    try {
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .collection("history")
        .doc(Date.now().toString())
        .set(newRoute);
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .update(newRoute);
      // setSuccesCreate('true')
      // clearObjRoute()
      props.history.push(`/dashboard/routes/`);
    } catch (error) {
      // console.log(error)
    }
  }

  function handleCancel() {
    clearObjRoute();
  }

  const confirmCancel = (e) => {
    e.preventDefault();
    props.history.push(`/dashboard/routes/`);
  };
  return (
    <div>
      {succesCreate ? (
        <div>
          <div className="alert alert-success m-3"> Nueva ruta creada </div>
        </div>
      ) : (
        <></>
      )}
      <Container className="containerroutes">
        <div className="containerroutesdiv">
          <Box
            sx={{
              width: 300,
              height: 50,
              backgroundColor: "#e0e0e0",
              textAlign: "center",
              p: 1,
            }}
          >
            <h3> Información de la Ruta #{routeId}</h3>
          </Box>
          <Box
            sx={{
              width: 300,
              height: 300,
              backgroundColor: "#fff",
              textAlign: "center",
              p: 1,
            }}
          >
            {" "}
            <br></br>
            {bicciRoutesExpressByID ? (
              <>
                <div className="m-3">
                  <h5>RIDER: {bicciRoutesExpressByID.driverName}</h5>{" "}
                </div>
                <div className="m-3">
                  <h5>VEHÍCULO: {bicciRoutesExpressByID.vehicleID}</h5>
                </div>
                <div className="m-3">
                  <h5>FECHA: {bicciRoutesExpressByID.dateDeliveryText}</h5>{" "}
                </div>
                <div className="m-3">
                  <h5>INICIO: {routeStartTimeDelivery} Hrs</h5>
                </div>
                <div className="m-3">
                  <h5>CIERRE: {routeEndTimeDelivery} Hrs</h5>
                </div>
                <div className="m-3">
                  <h5>TIEMPO ESPERA: {routeStayTimeDelivery} Hrs</h5>{" "}
                </div>
              </>
            ) : (
              <div className="m-3"></div>
            )}
            <br></br>
            <br></br>
          </Box>

          <br></br>
          <Box
            sx={{
              width: 300,
              height: "100%",
              backgroundColor: "#e0e0e0",
              p: 1,
              "&:hover": {
                backgroundColor: "#eeeeee",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eeeeee",
                p: 2,
              }}
            >
              <form onSubmit={createrRoute}>
                <hr></hr>
                <h3> Cambiar datos de ruta de despacho</h3>
                <hr></hr>

                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    type="date"
                    label="Fecha de ruta"
                    iconPosition="left"
                    min={Moment(Date.now()).format("DD-MM-YYYY")}
                    // max="2022-08-10"
                    name="dispchDate"
                    onChange={(e) => handleChange(e)}
                    value={routeDate}
                    // blur={ console.log('blur accion')}
                    onClick={(e) => console.log(e.view)}

                    //locale={'firstDayOfWeek': 1}
                  />
                </Form.Group>
                <hr></hr>

                <hr></hr>
                <Form.Group widths="equal">
                  <span>Hora de inicio</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="initHour"
                    onChange={(e) => handleChange(e)}
                    value={routeStartTimeDelivery}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <hr></hr>
                  <span>Hora de final</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="endHour"
                    onChange={(e) => handleChange(e)}
                    value={routeEndTimeDelivery}
                  />
                </Form.Group>
                <hr></hr>
                <Form.Group widths="equal">
                  <span>Tiempo en punto de entrega</span>
                  <Form.Field
                    control={Input}
                    type="time"
                    iconPosition="left"
                    name="awaitTime"
                    onChange={(e) => handleChange(e)}
                    value={routeStayTimeDelivery}
                  />
                </Form.Group>
                <hr></hr>

                {/* <button>Buscar</button> */}
              </form>
            </Box>
          </Box>
        </div>
        <div className="containerroutesdivleft">
          <Box
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "#e0e0e0",
              textAlign: "center",
              p: 1,
            }}
          >
            <div>
              {" "}
              <h3 className="m-1">Buscar Nuevo Conductor</h3>
            </div>
          </Box>
          <Form.Group widths="equal" className="FormGroupRiders">
            <Form.Field
              control={Input}
              type="text"
              placeholder="ID Rider o Nombre"
              iconPosition="left"
              icon="user"
              name="usuarioRider"
              onChange={(e) => setUserRiderr(e.target.value)}
              value={userRider}
            />
          </Form.Group>

          <Box
            sx={{
              width: "100%",
              height: 276,
              backgroundColor: "#fafafa",
            }}
          >
            {ridersRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={ridersRows}
                columns={columnsRiders}
                pageSize={5}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                onClick={checkboxSelection}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "#e0e0e0",
              textAlign: "center",
              p: 1,
            }}
          >
            <div>
              {" "}
              <h3 className="m-1">Buscar Nuevo Vehículo</h3>
            </div>
          </Box>
          <Form.Group widths="equal" className="FormGroupVehicle">
            <Form.Field
              control={Input}
              type="text"
              placeholder="Patente/Placa"
              iconPosition="left"
              icon="truck"
              name="vehicle"
              onChange={(e) => setUserVehicle(e.target.value)}
              value={userVehicle}
            />{" "}
          </Form.Group>
          <Box
            sx={{
              height: 400,
              width: 550,
              backgroundColor: "#fafafa",
            }}
          >
            {vehiclesRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={vehiclesRows}
                columns={columnsVehicle}
                pageSize={5}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                onClick={checkboxSelection}
              />
            ) : (
              <></>
            )}
          </Box>
          <hr></hr>
          <Box
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "#e0e0e0",
              textAlign: "center",
              p: 1,
            }}
          >
            <div>
              {" "}
              <h3 className="m-1">Asignar Guias a Ruta</h3>
            </div>
          </Box>
          <Form.Group widths="equal" className="FormGroupVehicle">
            <Form.Field
              control={Input}
              type="text"
              placeholder="#Código ID"
              iconPosition="left"
              icon="truck"
              name="orderid"
              onChange={(e) => setOrderID(e.target.value)}
              value={OrderID}
            />{" "}
          </Form.Group>
          <Box
            sx={{
              height: 400,
              width: 550,
              backgroundColor: "#fafafa",
            }}
          >
            {OrdersRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={OrdersRows}
                columns={columsOrders}
                pageSize={5}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
            ) : (
              <></>
            )}
          </Box>
        </div>

        <div className="containerroutesdivleft">
          <Box
            sx={{
              height: "33%",
              width: "auto",
              backgroundColor: "fdfdfd",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: 50,
                backgroundColor: "#e0e0e0",
                textAlign: "center",
                p: 1,
                "&:hover": {
                  backgroundColor: "#e0f7fa",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <h3> Resumen de ruta </h3>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "#f8f8f8",
                textAlign: "left",
                p: 3,
                border: 1,
                borderTop: 1,
                borderRight: 1,
                borderBottom: 1,
                borderLeft: 1,
                borderColor: "grey.500",
                borderRadius: 1,
              }}
            >
              <Box
                className="containerroutesdivbox"
                sx={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#fafafa",

                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    textAlign: "left",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    sx={{ minWidth: 100 }}
                  >
                    {" "}
                  </Typography>

                  <Tooltip title="Cancelar edición">
                    <IconButton
                      onClick={handleCancel}
                      size="small"
                      sx={{
                        ml: 2,
                        color: "#4a148c",
                        "&:focus": {
                          outline: "none!important",
                        },
                      }}
                    >
                      {`x Cancelar`}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Reasignar ruta">
                    <IconButton
                      onClick={handleClickUpdate}
                      size="small"
                      sx={{ ml: 2, color: "#4a148c" }}
                    >
                      <AddCircleIcon
                        sx={{ width: 15, height: 15 }}
                      ></AddCircleIcon>
                      Guardar
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Card className="routeResumen">
                <CardContent className="routeResumen">
                  {newRoute ? (
                    <div className="cardcontentrpute">
                      Usuario conductor:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.driverName}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Selecciona un Conductor{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {newRoute ? (
                    <div className="cardcontentrpute">
                      Vehículo:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.vehicleID}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Selecciona un vehículo{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {newRoute ? (
                    <div className="cardcontentrpute">
                      Fecha de despacho:
                      <span className="alert alert-success m-1">
                        {" "}
                        {newRoute.dateDeliveryText}
                      </span>{" "}
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica una fecha de ruta
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeStartTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Hora de inicio:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeStartTimeDelivery}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        Indica Hora de inicio{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeEndTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Hora de final:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeEndTimeDelivery}
                      </span>
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica Hora de finalizacion{" "}
                      </span>
                    </div>
                  )}
                  <br></br>
                  {routeStayTimeDelivery ? (
                    <div className="cardcontentrpute">
                      Tiempo de espera:
                      <span className="alert alert-success m-1">
                        {" "}
                        {routeStayTimeDelivery}
                      </span>{" "}
                    </div>
                  ) : (
                    <div className="m-1 cardcontentrpute">
                      <span className="alert alert-warning">
                        {" "}
                        Indica Tiempo de espera en cada punto
                      </span>
                    </div>
                  )}
                </CardContent>
                {errorData ? (
                  <div>
                    <div className="alert danger-alert">{errorData}</div>
                    <div>
                      {" "}
                      <button
                        className="resimanconfirmCancel"
                        onClick={confirmCancel}
                      >
                        {" "}
                        Salir sin guardar{" "}
                      </button>
                    </div>{" "}
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>
            </Box>
            <div className="containerroutesdivleft">
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#f8f8f8",
                  textAlign: "left",
                  p: 3,
                  border: 1,
                  borderTop: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  borderColor: "grey.500",
                  borderRadius: 1,
                }}
              >
                <Card>
                  <Box
                    sx={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#e0e0e0",
                      textAlign: "center",
                      p: 1,
                    }}
                  >
                    <div>
                      {" "}
                      <h3 className="m-1">Guias Asociadas</h3>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: 400,
                      width: 550,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {targetedOrdersRows ? (
                      <DataGrid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={targetedOrdersRows}
                        columns={targetColumsOrders}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Card>
              </Box>
            </div>
          </Box>
        </div>

        <div></div>
      </Container>
    </div>
  );
};

export default DashRoutesEdit;
