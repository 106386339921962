import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const ReusableFormImportOrdersByDataSDDFalabella = ({
  open,
  handleClose,
  setOpen,
  returnDocumentDataFalabella,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]); // Almacena los errores de validación
  const [dataDocument, setDataDocument] = useState([]);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]); // Almacena el archivo seleccionado
  };

  // Configuración de campos
  const fieldConfig = {
    SOC: { required: true },
    TIPODELIVERY: { required: true },
    BU: { required: true },
    UNIDADES: { required: true },
    PO: { required: true },
    CAMION: { required: false },
    CLIENTERUT: { required: true },
    CLIENTENOMBRES: { required: true },
    CLIENTETELEFONO: { required: true },
    CLIENTECORREO: { required: true },
    DIRECCION1: { required: true },
    DIRECCION2: { required: true },
    DIRECCION3: { required: true },
    COMUNA: { required: true },
    COMUNA2: { required: true },
    LATITUD: { required: false },
    LONGITUD: { required: false },
    RANGOFECHAPACTADA: { required: true }, // Manejo de fecha y hora
    SERVICELEVEL: { required: true },
    VENTANAINICIO: { required: true }, // Manejo de fecha y hora
    VENTANATERMINO: { required: true }, // Manejo de fecha y hora
    RECOGIDO: { required: true },
    F12: { required: true },
    MULTIBULTO: { required: true },
    UNIDADES: { required: true },
    ALTO: { required: true },
    LARGO: { required: true },
    ANCHO: { required: true },
    VOLUMEN: { required: true },
    PESO: { required: true },
    VALOR: { required: true },
    TAMANOPRODUCTO: { required: true },
    LPN: { required: true },
    BODEGACODIGO: { required: true },
    BODEGANOMBRE: { required: true },
    PAPERLESS: { required: true },
    NUMEROVIAJE: { required: true },
    FECHAVIAJE: { required: true }, // Manejo de fecha y hora
  };

  const validateFields = (data) => {
    let errors = [];

    data.forEach((item, index) => {
      // Recorremos cada campo de la configuración
      Object.keys(fieldConfig).forEach((field) => {
        const isRequired = fieldConfig[field].required;
        const fieldValue = item[field];

        // Si el campo es obligatorio y está vacío o no existe
        if (isRequired && (!fieldValue || fieldValue === "")) {
          errors.push({
            index,
            field,
            message: `Falta el valor de ${field}`,
          });
        }
      });
    });

    setValidationErrors(errors); // Almacena los errores
    return { isValid: errors.length === 0, errors }; // Retorna un objeto con la validación y los errores
  };

  const handleFileSave = async () => {
    if (!file) return;

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const validation = validateFields(jsonData);

    if (validation.isValid) {
      returnDocumentDataFalabella(jsonData, validation.errors, "ready");
      handleClose();
    } else {
      setDataDocument(jsonData);
      setErroMensaje(validation.errors);
    }
  };

  const hadleEdith = async (data, validation) => {
    returnDocumentDataFalabella(data, validation, "ready");
    setErroMensaje(null);
    setFile(null);
    setValidationErrors([]); // Almacena los errores de validación
    setDataDocument([]);
    handleClose();
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileSelector,
  } = useDropzone({
    onDrop,
    accept: ".xlsx",
    maxFiles: 1,
    maxSize: 5000000,
    noClick: true,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "90%",
          height: "90vh",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">
          Crear envíos con documento importación
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Button onClick={openFileSelector} color="primary">
          Seleccionar archivo
        </Button>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box
            {...getRootProps()}
            sx={{ border: "2px dashed #ccc", p: 2, minHeight: "450px" }}
          >
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>Archivo listo para guardar: {file.name}</p>}
        {erroMensaje
          ? erroMensaje.map((error, index) => (
              <div key={index}>
                <Typography variant="body2" color="error">
                  {`LINEA ${error.index + 1},  Error en el campo ${
                    error.field
                  }: ${error.message}`}
                </Typography>
              </div>
            ))
          : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        {erroMensaje ? (
          <Button
            onClick={() => hadleEdith(dataDocument, erroMensaje)}
            color="primary"
            disabled={!file}
          >
            Editar
          </Button>
        ) : null}
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrdersByDataSDDFalabella;
