import  { useEffect, useState } from 'react'
import { Item } from 'semantic-ui-react'
import {db} from '../firebase'

function useObtenerProductos(){
  const [plans, setpublicplanes] = useState(null)
  const [products, setproducts] = useState(null)
  const [productosplanes,setProductosplanes] = useState(null)
  const [productosBolsas,setProductosBolsas] = useState(null)
  const [loadingProductos, setLoading] = useState(false)
  const [errorProductos, setError] = useState(null)

  useEffect(()=>{
    fetchData()
  },[])


  const fetchData = async () => {
    try {
      setLoading(true)

       const productos = await db.collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .get();
      var dataResult = productos.docs.map(doc => ({id: doc.id, ...doc.data()}))
      var datafilter1 = dataResult.filter((item) => item.isDelete === false);
      var datafilter2 = datafilter1.filter((item) => item.isActive === true);
      var datafilter3 = datafilter2.filter((item) => item.type !== 'custom');
      setProductosplanes(datafilter2)
      setpublicplanes(datafilter3)


      const bolsas =await db.collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .get();
      var dataBolsas = bolsas.docs.map(doc => ({id: doc.id, ...doc.data()}))
      var dataBolsas1 = dataBolsas.filter((item) => item.isDelete === false);
      var dataBolsas2 = dataBolsas1.filter((item) => item.isActive === true);
      var dataBolsas3 = dataBolsas2.filter((item) => item.isPublic === true);
      setProductosBolsas(dataBolsas2)
      setproducts(dataBolsas3)
      setLoading(false)
    } catch (error) {
      console.log('error')
      setError(true)
      setLoading(false)
    }
  }

  return {productosBolsas, productosplanes, plans, products, loadingProductos, errorProductos}

}

export default useObtenerProductos