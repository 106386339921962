import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export const requestOrdersStatusFalabellaCollection = async (orderid) => {
  console.log("requestOrdersStatusFalabellaCollection");
  const allOrdersRefFalabella = collection(
    db,
    process.env.REACT_APP_COL_FALABELLA_ORDERS
  );

  const ordesFalabella = query(
    allOrdersRefFalabella,
    where("id", "==", orderid.toString())
  );
  let ordesFalabellaDoc = await getDocs(ordesFalabella);
  const dataOrdesFalabella = ordesFalabellaDoc.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  var orderF = dataOrdesFalabella[0];
  console.log(orderF);
  if (orderF) {
    console.log("encontredo orderF", orderid);
    let createdAtFalabella = orderF.createdAt;
    let falabella_status = orderF.falabella_status
      ? orderF.falabella_status
      : orderF.int_falabella_status;
    let updatedAt = orderF.updatedAt;
    return { createdAtFalabella, falabella_status, updatedAt };
  } else {
    console.log("No encontredo", orderid);
    let createdAtFalabella = false;
    let falabella_status = false;
    let updatedAt = false;
    return { createdAtFalabella, falabella_status, updatedAt };
  }
};
