import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Importa los iconos que necesites
import { StyledCard, StyledTextField } from "./FromStyles";
import IconButton from "@mui/material/IconButton";
import PaidIcon from "@mui/icons-material/Paid";
import { Typography } from "@mui/material";
const FromShippingPaiments = ({ onChange, data }) => {
  const [formState, setFormState] = useState({
    address: "",
    city: "",
    postalCode: "",
  });

  useEffect(() => {
    if (data) {
      setFormState(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...formState, [name]: value };
    setFormState(newState);
    onChange(newState);
  };

  return (
    <StyledCard
      elevation={0}
      variant="outlined"
      sx={{ background: "transparent", border: "none", boxShadow: "none" }}
    >
      <CardHeader
        title="Pago"
        sx={{
          color: "#fff", // Texto blanco
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent
        sx={{
          display: "flex", // Habilita Flexbox
          flexDirection: "column", // Organiza los hijos en columna
          justifyContent: "center", // Centra los hijos verticalmente
          alignItems: "center", // Centra los hijos horizontalmente
          padding: "5px",
          paddingBottom: "5px!important",
          color: "#fff",
          borderRadius: "10px 10px 10px 10px",
          background: "#26b1e9", // Fondo azul
          backgroundColor: "#5e72b9", // Fondo azul oscuro
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
        }}
      >
        <IconButton
          sx={{
            padding: "10px",
            backgroundColor: "#fff",
            color: "primary.main", // Color del ícono
            "&:hover": {
              backgroundColor: "primary.light", // Color en hover
            },
            marginBottom: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <PaidIcon fontSize="large" />
        </IconButton>
        <Typography variant="button" display="block" gutterBottom>
          conciliación
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingPaiments;
