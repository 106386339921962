import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { OrdersProvider } from "../../hooks/useOrdersProviders/OrdersContext";

const TvScrems = (props) => {
  useEffect(() => {
    if (props && props.setLandScrenMenu) {
      props.setLandScrenMenu(true);
    }
  }, [props]);
  return (
    <OrdersProvider>
      <Grid
        item
        xs={12}
        className="dash-container-colmun"
        sx={{
          position: "fixe",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 0,
          zIndex: 9000,
        }}
      >
        {/* Contenido de rutas anidadas */}
        <Outlet props={props} />
      </Grid>
    </OrdersProvider>
  );
};

export default TvScrems;
