import React, { useState, useEffect } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import BulkUpdateComponent from "./BulkUpdateComponent";

// Configuración de campos

const EditableOrderGridFalabella = ({ data, errors, mode, onSave }) => {
  const fieldConfig = {
    SOC: { required: true },
    TIPODELIVERY: { required: true },
    BU: { required: true },
    UNIDADES: { required: true },
    PO: { required: true },
    CAMION: { required: false },
    CLIENTERUT: { required: true },
    CLIENTENOMBRES: { required: true },
    CLIENTETELEFONO: { required: true },
    CLIENTECORREO: { required: true },
    DIRECCION1: { required: true },
    DIRECCION2: { required: true },
    DIRECCION3: { required: false },
    COMUNA: { required: true },
    COMUNA2: { required: true },
    LATITUD: { required: false },
    LONGITUD: { required: false },
    RANGOFECHAPACTADA: { required: true }, // Manejo de fecha y hora
    SERVICELEVEL: { required: true },
    VENTANAINICIO: { required: true }, // Manejo de fecha y hora
    VENTANATERMINO: { required: true }, // Manejo de fecha y hora
    RECOGIDO: { required: true },
    F12: { required: true },
    MULTIBULTO: { required: true },
    UNIDADES: { required: true },
    ALTO: { required: true },
    LARGO: { required: true },
    ANCHO: { required: true },
    VOLUMEN: { required: true },
    PESO: { required: true },
    VALOR: { required: true },
    TAMANOPRODUCTO: { required: true },
    LPN: { required: true },
    BODEGACODIGO: { required: true },
    BODEGANOMBRE: { required: true },
    PAPERLESS: { required: true },
    NUMEROVIAJE: { required: true },
    FECHAVIAJE: { required: true }, // Manejo de fecha y hora
  };

  const [rows, setRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState(errors || []);
  const [applyToEmptyOnly, setApplyToEmptyOnly] = useState(false); // Estado para aplicar solo a vacíos

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (data && data.length > 0) {
      const initializedRows = data.map((row) => {
        let newRow = { ...row };
        // Convertir fechas
        newRow.RANGOFECHAPACTADA = newRow.RANGOFECHAPACTADA
          ? excelSerialDateToJSDateTime(newRow.RANGOFECHAPACTADA)
          : "";
        newRow.FECHAVIAJE = newRow.FECHAVIAJE
          ? excelSerialDateToJSDateTime(newRow.FECHAVIAJE)
          : "";

        Object.keys(fieldConfig).forEach((field) => {
          if (newRow[field] === undefined) {
            newRow[field] = ""; // Si el campo no existe, inicializar como cadena vacía
          }
        });
        return newRow;
      });
      setRows(initializedRows);
    }
  }, [data]);

  useEffect(() => {
    handleValidate();
  }, [rows]);

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRows = rows.map((row) =>
      row.SOC === newRow.SOC ? newRow : row
    );
    setRows(updatedRows); // Actualizamos las filas en el estado

    return newRow; // Retornamos el nuevo valor actualizado
  };

  const handleBulkUpdate = (selectedColumn, value, applyToEmptyOnly) => {
    const updatedRows = rows.map((row) => {
      const columnValue = row[selectedColumn];

      if (
        applyToEmptyOnly &&
        (columnValue === undefined || columnValue === "")
      ) {
        return { ...row, [selectedColumn]: value }; // Aplicar solo a los vacíos
      } else if (!applyToEmptyOnly) {
        return { ...row, [selectedColumn]: value }; // Aplicar a todos
      }
      return row;
    });

    setRows(updatedRows); // Actualizar las filas
  };

  const handleSave = () => {
    const rowsToSave = rows.map((row) => {
      let newRow = { ...row };
      // Convertir fechas legibles de vuelta a serial de Excel
      if (newRow.RANGOFECHAPACTADA) {
        newRow.RANGOFECHAPACTADA = jsDateToExcelSerial(
          newRow.RANGOFECHAPACTADA
        );
      }
      if (newRow.FECHAVIAJE) {
        newRow.FECHAVIAJE = jsDateToExcelSerial(newRow.FECHAVIAJE);
      }
      return newRow;
    });

    onSave(rowsToSave, validationErrors); // Envía los datos procesados
  };

  const ValidarDatos = (rows) => {
    let validationErrors = [];
    rows.forEach((row, rowIndex) => {
      Object.keys(fieldConfig).forEach((field) => {
        const isRequired = fieldConfig[field].required;
        const fieldValue = row[field];

        if (isRequired && (!fieldValue || fieldValue === "")) {
          validationErrors.push({
            index: rowIndex + 1,
            field,
            message: `El campo ${field} es obligatorio.`,
          });
        }
      });
    });

    return {
      isValid: validationErrors.length === 0,
      errors: validationErrors,
    };
  };

  const handleValidate = () => {
    const validation = ValidarDatos(rows);
    if (validation.isValid) {
      setValidationErrors([]);
    } else {
      setValidationErrors(validation.errors);
    }
  };

  // Convierte serial de Excel a fecha legible
  const excelSerialDateToJSDate = (serial) => {
    const days = Math.floor(serial);
    const fraction = serial - days;
    const excelEpoch = new Date(1899, 11, 30);
    const date = new Date(excelEpoch.getTime() + days * 86400 * 1000);
    const millisecondsInDay = 86400 * 1000;
    const milliseconds = Math.round(fraction * millisecondsInDay);
    date.setMilliseconds(date.getMilliseconds() + milliseconds);
    return date.toISOString().slice(0, 10); // Formato legible YYYY-MM-DD
  };

  // Convierte serial de Excel a fecha con hora legible
  const excelSerialDateToJSDateTime = (serial) => {
    const days = Math.floor(serial);
    const fraction = serial - days;
    const excelEpoch = new Date(1899, 11, 30);
    const date = new Date(excelEpoch.getTime() + days * 86400 * 1000);
    const millisecondsInDay = 86400 * 1000;
    const milliseconds = Math.round(fraction * millisecondsInDay);
    date.setMilliseconds(date.getMilliseconds() + milliseconds);
    return date.toISOString().slice(0, 19).replace("T", " "); // Formato legible YYYY-MM-DD HH:mm:ss
  };

  // Convierte fecha legible a serial de Excel
  const jsDateToExcelSerial = (dateStr) => {
    const date = new Date(dateStr);
    const excelEpoch = new Date(1899, 11, 30);
    const diff = date.getTime() - excelEpoch.getTime();
    return diff / (86400 * 1000);
  };

  const splitAddressSDDGS = (address, comuna) => {
    const regex = /^(.*?)(\d+)\s*(.*)?$/;
    const match = address.match(regex);

    if (!match) {
      return {
        DIRECCION1: cleanString(address),
        DIRECCION2: "",
        DIRECCION3: "",
      }; // Si no coincide, retorna la dirección completa en DIRECCION1
    }

    let DIRECCION1 = cleanString(match[1].trim());
    let DIRECCION2 = cleanString(match[2].trim());
    let DIRECCION3 = match[3] ? cleanString(match[3].trim()) : "";

    // Nueva regla: Si DIRECCION3 termina con la comuna, quitarla y eliminar la coma antes de la comuna
    const comunaLower = comuna.toLowerCase().trim();
    const direccion3Lower = DIRECCION3.toLowerCase().trim();

    if (direccion3Lower.endsWith(comunaLower)) {
      DIRECCION3 = DIRECCION3.replace(
        new RegExp(`,?\\s*${comuna}$`, "i"),
        ""
      ).trim();
    }

    return { DIRECCION1, DIRECCION2, DIRECCION3 };
  };

  // const splitAddressSDDGS = (address, comuna, currentDir2, currentDir3) => {
  //   // Si DIRECCION3 está vacía pero ya tenemos valores en DIRECCION1 y DIRECCION2, no procesar
  //   if (currentDir2 && !currentDir3) {
  //     return {
  //       DIRECCION1: address, // Mantener la dirección tal cual está si ya está procesada
  //       DIRECCION2: currentDir2,
  //       DIRECCION3: currentDir3,
  //     };
  //   }

  //   const regex = /^(.*?)(\d+)\s*(.*)?$/;
  //   const match = address.match(regex);

  //   if (!match) {
  //     return {
  //       DIRECCION1: cleanString(address),
  //       DIRECCION2: "",
  //       DIRECCION3: "",
  //     }; // Si no coincide, retorna la dirección completa en DIRECCION1
  //   }

  //   let DIRECCION1 = cleanString(match[1].trim());
  //   let DIRECCION2 = cleanString(match[2].trim());
  //   let DIRECCION3 = match[3] ? cleanString(match[3].trim()) : "";

  //   // Nueva regla: Si DIRECCION3 termina con la comuna, quitarla y eliminar la coma antes de la comuna
  //   const comunaLower = comuna.toLowerCase().trim();
  //   const direccion3Lower = DIRECCION3.toLowerCase().trim();

  //   if (direccion3Lower.endsWith(comunaLower)) {
  //     DIRECCION3 = DIRECCION3.replace(
  //       new RegExp(`,?\\s*${comuna}$`, "i"),
  //       ""
  //     ).trim();
  //   }

  //   // Validación: Si DIRECCION2 ya está correcta y DIRECCION3 está vacía, no sobreescribir
  //   if (currentDir2 && !DIRECCION3) {
  //     return {
  //       DIRECCION1, // Mantener la dirección como está
  //       DIRECCION2: currentDir2, // No borrar o modificar DIRECCION2 si ya está correcta
  //       DIRECCION3: currentDir3 || "", // Mantener DIRECCION3 vacía si ya lo está
  //     };
  //   }

  //   // Limpiar DIRECCION1 de cualquier dato que ya se ha extraído en DIRECCION2 y DIRECCION3
  //   if (DIRECCION2 && DIRECCION1.includes(DIRECCION2)) {
  //     DIRECCION1 = DIRECCION1.replace(DIRECCION2, "").trim();
  //   }

  //   if (DIRECCION3 && DIRECCION1.includes(DIRECCION3)) {
  //     DIRECCION1 = DIRECCION1.replace(DIRECCION3, "").trim();
  //   }

  //   return { DIRECCION1, DIRECCION2, DIRECCION3 };
  // };

  // Función para limpiar caracteres especiales
  const cleanString = (str) => {
    // Eliminar caracteres especiales al principio y final (# , ; .)
    return str.replace(/^(\s|[#.,;])+/g, "").replace(/(\s|[#.,;])+$/g, "");
  };

  const handleApplyAddressTransformation = (applyToEmptyOnly) => {
    const updatedRows = rows.map((row) => {
      let adress = `${row.DIRECCION1}, ${row.DIRECCION2}, ${row.DIRECCION3}`;
      const { DIRECCION1, DIRECCION2, DIRECCION3 } = splitAddressSDDGS(
        adress,
        row.COMUNA
      );

      if (applyToEmptyOnly) {
        if (!row.DIRECCION1 && !row.DIRECCION2 && !row.DIRECCION3) {
          return { ...row, DIRECCION1, DIRECCION2, DIRECCION3 };
        }
        return row;
      } else {
        return { ...row, DIRECCION1, DIRECCION2, DIRECCION3 };
      }
    });

    setRows(updatedRows);
  };

  const columns = [
    { field: "SOC", headerName: "SOC", width: 150, editable: true },
    {
      field: "TIPODELIVERY",
      headerName: "Tipo Delivery",
      width: 150,
      editable: true,
    },
    { field: "BU", headerName: "BU", width: 150, editable: true },
    { field: "UNIDADES", headerName: "Unidades", width: 150, editable: true },
    { field: "PO", headerName: "PO", width: 150, editable: true },
    { field: "CAMION", headerName: "Camión", width: 150, editable: true },
    {
      field: "CLIENTERUT",
      headerName: "RUT Cliente",
      width: 150,
      editable: true,
    },
    {
      field: "CLIENTENOMBRES",
      headerName: "NOMBRECLIENTE",
      width: 150,
      editable: true,
    },
    {
      field: "CLIENTETELEFONO",
      headerName: "Teléfono Cliente",
      width: 150,
      editable: true,
    },
    {
      field: "CLIENTECORREO",
      headerName: "Correo Cliente",
      width: 150,
      editable: true,
    },
    {
      field: "DIRECCION1",
      headerName: "Dirección 1",
      width: 150,
      editable: true,
    },
    {
      field: "DIRECCION2",
      headerName: "Dirección 2",
      width: 150,
      editable: true,
    },
    {
      field: "DIRECCION3",
      headerName: "Dirección 3",
      width: 150,
      editable: true,
    },
    { field: "COMUNA", headerName: "Comuna", width: 150, editable: true },
    { field: "COMUNA2", headerName: "Regíon", width: 150, editable: true },
    { field: "LATITUD", headerName: "Latitud", width: 150, editable: true },
    { field: "LONGITUD", headerName: "Longitud", width: 150, editable: true },
    {
      field: "RANGOFECHAPACTADA",
      headerName: "Fecha Pactada",
      width: 150,
      editable: true,
    },
    {
      field: "VENTANAINICIO",
      headerName: "Ventana Inicio",
      width: 150,
      editable: true,
    },
    {
      field: "VENTANATERMINO",
      headerName: "Ventana Término",
      width: 150,
      editable: true,
    },
    {
      field: "FECHAVIAJE",
      headerName: "Fecha Viaje",
      width: 150,
      editable: true,
    },
    { field: "UNIDADES", headerName: "Unidades", width: 150, editable: true },
    { field: "VALOR", headerName: "Valor", width: 150, editable: true },
    { field: "ALTO", headerName: "Alto", width: 150, editable: true },
    { field: "LARGO", headerName: "Largo", width: 150, editable: true },
    { field: "ANCHO", headerName: "Ancho", width: 150, editable: true },
    { field: "VOLUMEN", headerName: "Volumen", width: 150, editable: true },
    { field: "PESO", headerName: "Peso", width: 150, editable: true },
    {
      field: "NUMEROVIAJE",
      headerName: "N de viaje",
      width: 150,
      editable: true,
    },
    ,
  ];

  const columnNames = {
    SOC: "SOC",
    TIPODELIVERY: "Tipo Delivery",
    BU: "BU",
    UNIDADES: "Unidades",
    PO: "PO",
    CAMION: "Camión",
    CLIENTERUT: "RUT Cliente",
    CLIENTENOMBRES: "NOMBRECLIENTE",
    CLIENTETELEFONO: "Teléfono Cliente",
    CLIENTECORREO: "Correo Cliente",
    DIRECCION1: "Dirección 1",
    DIRECCION2: "Dirección 2",
    DIRECCION3: "Dirección 3",
    COMUNA: "Comuna",
    LATITUD: "Latitud",
    LONGITUD: "Longitud",
    RANGOFECHAPACTADA: "Fecha Pactada",
    VENTANAINICIO: "Ventana Inicio",
    VENTANATERMINO: "Ventana Término",
    FECHAVIAJE: "Fecha Viaje",
    VALOR: "Valor",
    ALTO: "Alto",
    ANCHO: "Ancho",
    LARGO: "Largo",
    VOLUMEN: "Volumen",
    PESO: "Peso",
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Editar Data de Órdenes
      </Typography>

      <BulkUpdateComponent
        columns={columns}
        handleBulkUpdate={handleBulkUpdate}
      ></BulkUpdateComponent>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={handleSave}
      >
        Guardar cambios
      </Button>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={handleValidate}
      >
        Revisar datos
      </Button>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={() => handleApplyAddressTransformation(applyToEmptyOnly)}
      >
        Aplicar reglas de dirección
      </Button>

      <FormControlLabel
        control={
          <Checkbox
            checked={applyToEmptyOnly}
            onChange={(e) => setApplyToEmptyOnly(e.target.checked)} // Cambiar el estado de la opción
          />
        }
        label="Aplicar reglas de dirección solo a campos vacíos"
      />

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={1500}
        getRowId={(row) => row.SOC}
        processRowUpdate={processRowUpdate}
        apiRef={apiRef}
      />

      {validationErrors.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" color="error">
            Errores encontrados: <strong>{validationErrors.length}</strong>
          </Typography>
          <Box
            sx={{ maxWidth: "350px", maxHeight: "230px", overflow: "scroll" }}
          >
            {validationErrors.map((error, index) => (
              <Typography key={index} variant="body2" color="error">
                {`Línea ${error.index} - Campo: ${
                  columnNames[error.field] || error.field
                } - ${error.message}`}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditableOrderGridFalabella;
