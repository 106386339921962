import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export const requestOrdersStatusBicciCollection = async (element) => {
  const allOrdersRefBicci = collection(
    db,
    process.env.REACT_APP_COL_USERS_ORDERS
  );

  async function buscarOrdenPorParametros(element, allOrdersRefBicci) {
    let q;

    async function removeF(orderId) {
      try {
        if (orderId && orderId.startsWith("F")) {
          return orderId.substring(1);
        } else {
          return orderId;
        }
      } catch (error) {
        console.log(orderId, error);
        return orderId;
      }
    }

    if (element.int_serviceType === "SD" || element.int_serviceType === "HDF") {
      const orderid = await removeF(element.id);

      q = query(allOrdersRefBicci, where("id", "==", "F" + orderid));
    }
    if (element.int_serviceType === "HD") {
      const orderid = await removeF(
        element.dataOrigin === "GSDATA" ? element.key : element.int_lpn
      );
      //  console.log(element.int_lpn);
      q = query(allOrdersRefBicci, where("id", "==", "F" + orderid));
    }

    if (!q) {
      console.error(
        "Consulta no definida debido a un tipo de servicio no manejado.",
        element.id
      );
      return null; // O manejar de otra manera
    }

    try {
      let ordesBicciexpres = await getDocs(q);
      const dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (dataOrdesBicciexpres.length === 0) {
        //    console.log(dataOrdesBicciexpres.length === 0? "Documento No Se gestiono en Bicci": null);
        return null;
      }

      // console.log(dataOrdesBicciexpres.legnth);

      return dataOrdesBicciexpres[0]; // Devuelve el primer resultado, si existe
    } catch (error) {
      //   console.error("Error al buscar ordenes:", error);
      return null; // Manejar el error adecuadamente
    }
  }

  var order = await buscarOrdenPorParametros(element, allOrdersRefBicci);
  // console.log(order);

  let newIdReq;

  if (order && order.id.startsWith("F")) {
    newIdReq = order.id.substring(1);
  } else {
    //newIdReq = order.id;
    // console.log("error", order);
  }

  if (order && order.id) {
    let bicciStatus = order.status;
    let statusCreate = true;
    let driverName = order.driverName ? order.driverName : "";
    let driverID = order.driverID ? order.driverID : "";
    let userDniReceptor = order.userDniReceptor ? order.userDniReceptor : "";
    let userNameReceptor = order.userNameReceptor ? order.userNameReceptor : "";
    let observationReceptor = order.observationReceptor
      ? order.observationReceptor
      : "";
    let observationAdmin = order.observationAdmin ? order.observationAdmin : "";
    let newReq = newIdReq;

    return {
      statusCreate,
      bicciStatus,
      driverName,
      driverID,
      userDniReceptor,
      userNameReceptor,
      observationReceptor,
      observationAdmin,
      newReq,
    };
  } else {
    let bicciStatus = false;
    let statusCreate = false;
    let driverName = "";
    let driverID = "";
    let userDniReceptor = "";
    let userNameReceptor = "";
    let observationReceptor = "";
    let observationAdmin = "";
    let newReq = "";
    return {
      statusCreate,
      bicciStatus,
      driverName,
      driverID,
      userDniReceptor,
      userNameReceptor,
      observationReceptor,
      observationAdmin,
      newReq,
    };
  }
};
