import React, { useState } from "react";
import { Container, Input } from "semantic-ui-react";
import {
  Card,
  Divider,
  CardHeader,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import FadeInOut from "../../../components/animations/FadeInOut";
import { Grid } from "@mui/material";
import { useGetOrders } from "../../../hooks/migration/useGetOrders";
import SplitPane from "react-split-pane";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const Dispatchs = () => {
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);

  const [searchParams, setSearchParams] = useState({
    companyIDs: ["FroY2JbeirD7B1MRYEXo"], //, null] Incluye `null` para buscar sin `companyID`
    state: 8,
    dateField: "createdAt", // O "updatedAt"
    startDate: new Date("2024-07-14"),
    endDate: new Date("2024-07-17"),
  });

  const { orders, ordersLoading, ordersError, ordersEmpty } =
    useGetOrders(searchParams);

  const handleLoadMore = () => {
    if (orders.length > 0) {
      setLastVisible(orders[orders.length - 1].id);
    }
  };

  const elementsMenu = (
    <>
      <Input type="checkbox"></Input>
      <Tooltip title="ocultar menu">
        <IconButton
          sx={{ ml: 1, color: "#1e2158" }}
          onClick={() => console.log("icon")}
        >
          <MenuOpenIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );

  const acctionsList = (
    <>
      <Input type="checkbox"></Input>
    </>
  );

  const buscador = (
    <>
      <Input type="text" placeholder={"Buscar..."}></Input>
    </>
  );

  if (ordersLoading) return <p>Loading...</p>;
  if (ordersError) return <p>Error loading orders.</p>;
  if (ordersEmpty) return <p>No orders found.</p>;

  return (
    <Box style={{ backgroundColor: "#8F8F8", height: "100vh" }}>
      <Grid
        celled="internally"
        sx={{ backgroundColor: "#F8F8F8", height: "90%" }}
      >
        <Grid>
          <Card>
            <CardHeader subheader={buscador} action={elementsMenu} />

            <FadeInOut show={showEdit} duration={500}>
              <Container className="formcontent" id="micuentafromIni">
                <hr></hr>
              </Container>
            </FadeInOut>
          </Card>
        </Grid>
        <Grid id="gridContainer">
          <Divider />
          <SplitPane split="vertical" minSize={200} defaultSize="50%">
            <div style={{ padding: "5px", height: "100%" }}>
              <Grid>
                <Card>
                  <CardHeader
                    subheader={acctionsList}
                    title="Todos los envíos"
                  />
                  <FadeInOut show={showEdit} duration={500}>
                    <Container className="formcontent" id="micuentafromIni">
                      <hr />
                    </Container>
                  </FadeInOut>
                </Card>
              </Grid>
            </div>
            <div style={{ padding: "5px", height: "100%" }}>
              <Grid>
                <Card>
                  <CardHeader
                    subheader={acctionsList}
                    title="Envíos por empresa"
                  />
                  <Container className="formcontent" id="micuentafromIni">
                    <hr />
                  </Container>
                </Card>
              </Grid>
            </div>
          </SplitPane>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dispatchs;
