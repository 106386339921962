import React, { useRef } from "react";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import { Form, Input, Container, Checkbox } from "semantic-ui-react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

var estados;
class TypesPackage extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props)
    this.state = {
      productId: "",
      createBy: props.props.userUid,
      createDate: "",
      isActive: true,
      name: "",
      description: "",
      position: 0,
      imagenes: "",
      type: "",
      packageMinWeight: "",
      packageMaxWeight: "",
      packageLength: "",
      packageHeight: "",
      packageWidth: "",
    };
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  // const crearServico=()=>{}
  // const limpiarFormularioa=()=>{}
  render() {
    return (
      <Container className="dashcontent">
        <Grid celled="internally" id="servicioscontent">
          <div>
            <h2> Tipos de paquetes </h2>
          </div>
          <Grid.Row>
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo..."
                title={
                  <>
                    <h3>Tipos de paquetes</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <select
                      id="custom-selectFrom"
                      label="Tipo de usuario"
                      name={"type"}
                      control="select"
                      onChange={(e) => this.handleSelect(e.target.value)}
                    >
                      <option> Seleccione... </option>
                      {this.props.dataRates.map((item) => (
                        <option key={item.id} value={item.id}>
                          {" "}
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Código in APP"
                      type="text"
                      name="productId"
                      onChange={this.handleChange}
                      value={this.state.productId}
                    />

                    <Form.Field
                      control={Input}
                      type="text"
                      name="name"
                      label="Nombre de tipo de paquete"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      label="Descripcion"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                    <Form.Field
                      control={Input}
                      type="select"
                      label="Activo"
                      name="isActive"
                      onChange={this.handleChange}
                      value={this.state.isActive}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Peso minimo"
                      placeholder="Kgs"
                      iconPosition="left"
                      type="number"
                      name="packageMinWeight"
                      icon="weight"
                      onChange={this.handleChange}
                      value={this.state.packageMinWeight}
                    />
                    <Form.Field
                      control={Input}
                      label="Peso maximo"
                      placeholder="Kgs"
                      iconPosition="left"
                      type="number"
                      name="packageMaxWeight"
                      icon="weight"
                      onChange={this.handleChange}
                      value={this.state.packageMaxWeight}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Largo"
                      placeholder="cm"
                      iconPosition="left"
                      type="number"
                      name="packageLength"
                      icon="angle right"
                      onChange={this.handleChange}
                      value={this.state.packageLength}
                    />

                    <Form.Field
                      control={Input}
                      label="Alto"
                      placeholder="cm"
                      iconPosition="left"
                      type="number"
                      name="packageHeight"
                      icon="angle right"
                      onChange={this.handleChange}
                      value={this.state.packageHeight}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Ancho"
                      placeholder="cm"
                      iconPosition="left"
                      type="number"
                      name="packageWidth"
                      icon="angle right"
                      onChange={this.handleChange}
                      value={this.state.packageWidth}
                    />
                  </Form.Group>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  sx={{ margin: "2px" }}
                  color="primary"
                  variant="contained"
                  onClick={this.handleClear}
                >
                  Limpiar
                </Button>
              </Box>
            </Card>
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Crear nuevo tarifario personalizado"
                title={
                  <>
                    <h3>Activa o desactiva tipos paquetes</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Nombre de tipo de paquete</th>
                        <th scope="col">Código in APP</th>
                        <th scope="col">Status</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.paqRates.map((element) => (
                        <tr key={element.id}>
                          <td>
                            {(estados = element.isActive)}
                            {element.isActive ? (
                              <CheckCircleIcon color="success"></CheckCircleIcon>
                            ) : (
                              <IndeterminateCheckBoxIcon color="disabled"></IndeterminateCheckBoxIcon>
                            )}
                          </td>
                          <td>{element.name}</td>
                          <td>{element.id}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItem}
                              />
                              <span className="slider round"></span>
                            </label>
                            {/* <input onChange={this.handleChangeTarifario} value={this.state[element.id]} className="maxw" type="number" name={element.id} /> */}
                          </td>
                          <td>Editar</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  ></Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.state.productId.length === 0) {
      return;
    }

    try {
      await db
        .collection(`${process.env.REACT_APP_COL_TYPEPACKS}`)
        .doc(this.state.productId)
        .set({
          productId: this.state.productId,
          createBy: this.state.createBy,
          createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
          date: Date.now(),
          isActive: true,
          name: this.state.name,
          description: this.state.description,
          imagenes: this.state.imagenes,
          position: parseInt(this.state.position),
          packageMinWeight: parseFloat(this.state.packageMinWeight),
          packageMaxWeight: parseFloat(this.state.packageMaxWeight),
          packageLength: parseInt(this.state.packageLength),
          packageHeight: parseInt(this.state.packageHeight),
          packageWidth: parseInt(this.state.packageWidth),
          xpressAvailableservices: this.state.type,
        });
    } catch (error) {
      console.log(error);
    }
  }
  handleSelect(e) {
    this.setState({ type: e });
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  async handleChangeItem(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const id = target.id;
    const checked = target.checked;

    await db
      .collection(`${process.env.REACT_APP_COL_TYPEPACKS}`)
      .doc(id)
      .update({ isActive: checked });
  }

  handleClear(e) {}
}

export default TypesPackage;
