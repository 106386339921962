import React from 'react'
import  { useEffect } from 'react'
import {
    Card,
    Divider,
    CardContent,
    Box,
  } from '@mui/material';
  import GroupsIcon from '@mui/icons-material/Groups';
import { PowerInputSharp } from '@mui/icons-material';
const DashIconsResumenUser = (props) => {
  
  useEffect(()=>{

    },[props.cant])


  const COLORS = {
    Disponibles:'dashIcon-blue',
    Bloqueados:'dashIcon-grey',
    Invitados:'dashIcon-green',
  }
  const CANT = {
    Disponibles:props.cant.Disponibles,
    Bloqueados:props.cant.Bloqueados,
    Invitados:props.cant.Invitados,
  }
  return (
    <>
    {
        props.items.map(item => (     
        <span key={item.id} >
        <Card  
        className='DashIconsResumencard3'
        sx={{ alignItems: 'lef',
        display: 'inline-block',
        flexDirection: 'column',
        margin: '5px',
        border: '1px solid #cbcbcb',
        radius: '8px',
      }}>
<CardContent className="dashIconsresumencardcontent">
<GroupsIcon className={`dashIconUsers ${COLORS[item.id]}`} /> 
<Divider />
  <h3 className="dashicontitle">{item.id}</h3>

    <Box>
      <span className="dashicontspanbigtext">{`${CANT[item.id]}`}</span><span className="dashicontspansmalltext">Uni</span>
    </Box>
  </CardContent>

</Card></span> ))
    }
    </>

  )
}

export default DashIconsResumenUser