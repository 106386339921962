import React, { useContext, useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/material";
import { UserContext } from "../../../context/CurrentUserProvider";
import GuiasByCompanyForPrint from "../Guias/GuiasByCompanyForPrint";
import useGetOrdersDataByCompany from "../../../hooks/getOrdersDataContextByCompany";
import Moment from "moment";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext";

const ShippingPrint = (props) => {
  const { userDataContext } = useContext(UserContext);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
  } = useOrders({ initDateInitial, endDateInitial });

  console.log(mergedOrders);
  useEffect(() => {
    setShouldSubscribe(true);
  }, []);

  const userRoll = userDataContext.roll;
  const userdata = userDataContext.userData;

  return (
    <>
      {userRoll && userdata ? (
        <Box sx={{ backgroundColor: "#f8f8f8" }}>
          <GuiasByCompanyForPrint
            userdata={userdata}
            firebaseUser={props.firebaseUser}
            usuarioStorage={props.usuarioStorage}
            userDataContext={userDataContext}
            mergedOrders={mergedOrders}
            companiesData={companiesData}
            loadingContext={loadingContext}
            setInitDate={setInitDate}
            setEndDate={setEndDate}
            initDate={initDate}
            endDate={endDate}
          ></GuiasByCompanyForPrint>{" "}
        </Box>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ShippingPrint;
