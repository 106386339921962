import React, { useState } from "react";
import { db } from "../../../firebase";
import { crearPointADireccionByUser } from "../../../redux/acccionesClientes";
import { updatePointADireccionByUser } from "../../../redux/acccionesClientes";
import { deletePointADireccionByUser } from "../../../redux/acccionesClientes";
import { Box, Button, Card, Divider, CardHeader } from "@mui/material";

// import Map_marks from './Map_marks';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
//Importeciones de Formularios

import { Form, Input, Container } from "semantic-ui-react";

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import FadeInOut from "../../animations/FadeInOut";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import mapStyles from "../../MapsGoogle/mapStyles";
import { formatRelative } from "date-fns";
import useGlobalConfig from "../../../hooks/globalConfig";
const libraries = ["places"];

const markerBytext = {
  lat: -33.444307504592445,
  lng: -70.64900995751954,
  time: new Date(),
};

const mapContainerStyle = {
  height: "50vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};
const center = {
  lat: -33.44488045476359,
  lng: -70.65930964013673,
};

const Newaddressbilling = (props) => {
  const { siteName } = useGlobalConfig();

  const dispatch = useDispatch();
  const userUid = useSelector((store) => store.usuario.user.uid);
  const [directions, setDirections] = React.useState([]);
  const [modoEdicion, setModoEdicion] = React.useState(false);
  // const [id, setId] = React.useState("");
  const [itemediht, setItemEdith] = React.useState([]);

  //Variavles de formularios

  // States
  const resperror = useSelector((store) => store.usuario.error);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [warning, setWarning] = React.useState("");

  // User Typing
  const [adressName, setAdressName] = React.useState("Dirección#1");
  const [userName, setName] = React.useState("");
  const [adressPhone, setAdressPhone] = React.useState("+56");
  const [adressAddress, setAdressAddress] = React.useState("");
  const [adressNumAddress, setAdressNumAddress] = React.useState("");

  const [adressRegion, setAdressRegion] = React.useState("");
  const [adressCity, setAdressCity] = React.useState("");
  const [adressComuna, setAdressComuna] = React.useState("");
  const [adressCountry, setAdressCountry] = React.useState("CHILE");
  const [adressComent, setAdressComent] = React.useState("");

  //Maps
  const [adressGeoNumAddressLat, setAdressGeoNumAddressLat] =
    React.useState("");
  const [adressGeoNumAddressLng, setAdressGeoNumAddressLng] =
    React.useState("");

  //Data Base
  //  const [origenEmail, setOrigenEmail] = React.useState("");
  //  const [servicesType, setServicesType] = React.useState("Client BICCI EXPRESS");

  //Animaciones
  const [showEdit, setShowEdit] = useState(false);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const [isEditNew, setEditNew] = useState(false);

  const [currentItemEdith, setCurrentItemEdith] = useState(false);

  const [markers, setMarkers] = React.useState([]);

  const [selected, setSelected] = React.useState(null);

  const [draggable, setDraggable] = React.useState(false);

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  React.useEffect(() => {
    const findAdresses = async (userUid) => {
      // console.log(userUid);
      try {
        const adressses = await db
          .collection(`${process.env.REACT_APP_COL_USERS}`)
          .doc(userUid)
          .collection("direcciones")
          .get();
        // console.log(adressses.docs);
        const directions = adressses.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(directions);
        setDirections(directions);
      } catch (error) {
        // console.log(error);
      }
    };

    findAdresses(userUid);
  }, []);

  const mapRef = React.useRef();

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const eliminarDireccion = (id) => {
    //console.log("eliminar");
    const dirDelete = directions.filter((item) => item.id === id);
    const dirRest = directions.filter((item) => item.id !== id);
    setDirections(dirRest);
    dispatch(deletePointADireccionByUser(dirDelete[0]));
  };

  const cacelarEdicion = () => {
    dispatch(toggleShowEdit);
    setEditNew(false);
    setModoEdicion(false);
  };

  const toggleShowEditNew = () => {
    toggleShowEdit(true);
    setEditNew(true);
    setModoEdicion(true);
    setName("");
    setAdressPhone("+56");
    setAdressAddress("");
    setAdressComent("");
    setAdressRegion("");
    setAdressNumAddress("");
    setAdressCity("");
    setAdressComuna("");
    setAdressComent("");
    setError(null);
    setSuccess(null);
    setWarning(null);
  };

  const onMapClick = React.useCallback((e) => {
    // console.log(e.latLng.lat());
    // console.log(e.latLng.lng());
    markerBytext.lat = e.latLng.lat();
    markerBytext.lng = e.latLng.lng();
    setSelected(markerBytext);

    //  setMarkers((current) => [

    //    {
    //      lat: e.latLng.lat(),
    //      lng: e.latLng.lng(),
    //     time: new Date(),
    //    },
    //  ]);
  }, []);

  const editarDireccion = async (item) => {
    // console.log(item);
    // console.log(userUid);

    try {
      const adressses = await db
        .collection(`${process.env.REACT_APP_COL_USERS}`)
        .doc(userUid)
        .collection("direcciones")
        .doc(item.id)
        .get();
      // console.log(adressses.data());
      setAdressGeoNumAddressLat(adressses.data().adressGeoNumAddressLat);
      setAdressGeoNumAddressLng(adressses.data().adressGeoNumAddressLng);
      setCurrentItemEdith(item.id);
      setEditNew(false);
      setModoEdicion(true); // Alternar formulario  modoEdicion =   <Form_direcciones/> :  <Edit_direcciones itemData={data}/>
      setItemEdith(item.id);
      setName(adressses.data().userName);
      setAdressName(adressses.data().adressName);
      setAdressPhone(adressses.data().adressPhone);
      setAdressAddress(adressses.data().adressAddress);
      setAdressComent(adressses.data().adressComent);
      setAdressRegion(adressses.data().adressRegion);
      setAdressNumAddress(adressses.data().adressNumAddress);
      setAdressCity(adressses.data().adressCity);
      setAdressComuna(adressses.data().adressComuna);
      setAdressComent(adressses.data().adressComent);
    } catch (error) {
      // console.log(error);
    }
    //console.log(item)

    dispatch(toggleShowEdit);
    setError(null);
    setSuccess(null);
    setWarning(null);
  };
  // const verDireccion = (iten) => {
  //   setModoEdicion(false);
  // };
  const [adressConut, setAdressConut] = useState(1);

  const dataFind = {
    count: 2,
    name: "Mi direccion1",
    geopointa: 11122.2233,
  };

  const guardarDireccion = (e) => {
    e.preventDefault();
    setAdressGeoNumAddressLat(markerBytext.lat);
    setAdressGeoNumAddressLng(markerBytext.lng);
    if (!userName.trim()) {
      // console.log("El campo contacto es obligatorio");
      setError("El campo contacto es obligatorio");
      return;
    }
    if (userName.length <= 2) {
      // console.log("Indique un nombre de contanto valido");
      setError("Indique un nombre de contanto valido");
      return;
    }
    if (userName.length >= 60) {
      // console.log("Nombre de contacto max 60 Carateres");
      setError("Nombre de contacto max 60 Carateres");
      return;
    }
    if (!adressPhone.trim()) {
      // console.log("El campo teléfono es obligatorio");
      setError("El campo teléfono es obligatorio");
      return;
    }
    if (adressPhone.length < 12) {
      // console.log("En el campo teléfono faltan numeros");
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (adressPhone.length > 12) {
      // console.log("En el campo teléfono existen numeros extra");
      setError("El campo teléfono existen numeros extra");
      return;
    }
    if (!adressAddress.trim()) {
      // console.log("El campo dirección es obligatorio");
      setError("El campo dirección es obligatorio");
      return;
    }
    if (!adressRegion.trim()) {
      // console.log("El campo Regíon es obligatorio");
      setError("El campo Regíon es obligatorio");
      return;
    }
    if (!adressCity.trim()) {
      // console.log("El campo ciudad es obligatorio");
      setError("El campo ciudad es obligatorio");
      return;
    }
    if (!adressComuna.trim()) {
      // console.log("El campo comuna es obligatorio");
      setError("El campo comuna es obligatorio");
      return;
    }
    if (!adressCountry.trim()) {
      // console.log("El campo pais es obligatorio");
      setError("El campo pais es obligatorio");
      return;
    }
    if (!adressNumAddress.trim()) {
      // console.log("Indica numero de dirección , si no la requiere escriba NA");
      setWarning("Indica numero de dirección , si no la requiere escriba NA");
      setError("");
      return;
    }
    if (!adressName.trim()) {
      // console.log("Indica nombre para dirección");
      setWarning("Indica nombre para dirección");
      setError("");
      return;
    }

    if (adressNumAddress.length > 0) {
      setWarning(null);
    }
    setSuccess("Dirección Guardada.");

    if (showEdit === true && isEditNew === true) {
      guardarDireccionUsuario();
    }

    if (showEdit === true && isEditNew === false) {
      guardarEdicionDireccionUsuario();
    }
  };

  const guardarEdicionDireccionUsuario = React.useCallback(async () => {
    //  console.log(findEdithAdress.data())

    const data = {
      documentDir: currentItemEdith,
      uid: userUid,
      propietary: userUid,
      adressName: adressName,
      userName: userName,
      adressPhone: adressPhone,
      adressAddress: adressAddress,
      adressComent: adressComent,
      adressRegion: adressRegion,
      adressNumAddress: adressNumAddress,
      adressCity: adressCity,
      adressComuna: adressComuna,
      adressGeoNumAddressLat: adressGeoNumAddressLat,
      adressGeoNumAddressLng: adressGeoNumAddressLng,
    };
    dispatch(updatePointADireccionByUser(data));
    setName("");
    setAdressPhone("+56");
    setAdressAddress("");
    setAdressComent("");
    setAdressRegion("");
    setAdressNumAddress("");
    setAdressCity("");
    setAdressComuna("");
    setAdressComent("");
    setError(null);
    setSuccess(null);
    setWarning(null);
    setShowEdit(false);
    const renderAdresses = async (userUid) => {
      // console.log(userUid);
      try {
        const adressses = await db
          .collection(`${process.env.REACT_APP_COL_USERS}`)
          .doc(userUid)
          .collection("direcciones")
          .get();
        // console.log(adressses.docs);
        const directions = adressses.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(directions);
        setDirections(directions);
      } catch (error) {
        // console.log(error);
      }
    };
    renderAdresses(userUid);
    setEditNew(false);
    setModoEdicion(false);
  });

  const guardarDireccionUsuario = React.useCallback(async () => {
    //console.log("is Guardar");
    const data = {
      uid: userUid,
      adressName: adressName,
      userName: userName,
      adressPhone: adressPhone,
      adressAddress: adressAddress,
      adressComent: adressComent,
      adressRegion: adressRegion,
      adressNumAddress: adressNumAddress,
      adressCity: adressCity,
      adressComuna: adressComuna,
      adressGeoNumAddressLat: adressGeoNumAddressLat,
      adressGeoNumAddressLng: adressGeoNumAddressLng,
    };
    setError(null);
    setName("");
    setAdressPhone("+56");
    setAdressAddress("");
    setAdressComent("");
    setAdressRegion("");
    setAdressNumAddress("");
    setAdressCity("");
    setAdressComuna("");
    setAdressComent("");
    setError(null);
    setSuccess(null);
    setWarning(null);
    try {
      dispatch(crearPointADireccionByUser(data));
      // console.log(data);
      function delay() {
        dispatch(toggleShowEdit);
        setSuccess(null);
      }
      setTimeout(delay, 1000);
      const renderAdresses = async (userUid) => {
        // console.log(userUid);
        try {
          const adressses = await db
            .collection(`${process.env.REACT_APP_COL_USERS}`)
            .doc(userUid)
            .collection("direcciones")
            .get();
          // console.log(adressses.docs);
          const directions = adressses.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log(directions);
          setDirections(directions);
        } catch (error) {
          // console.log(error);
        }
      };
      renderAdresses(userUid);
      setEditNew(false);
      setModoEdicion(false);
    } catch (error) {
      // console.log(error);
    }
  });

  const limpiarFormulario = (e) => {
    e.preventDefault();
    setName("");
    setAdressPhone("+56");
    setAdressAddress("");
    setAdressComent("");
    setAdressRegion("");
    setAdressNumAddress("");
    setAdressCity("");
    setAdressComuna("");
    setAdressComent("");
    setError(null);
    setSuccess(null);
    setWarning(null);
    return;
  };

  return (
    <div celled="internally" id="expresscuentaContent">
      <h2>¿Dirección fisica?</h2>
      <div className="usa-radio">
        <input
          className="usa-radio__input usa-radio__input--tile"
          type="radio"
          id="equal"
          name="addressBilling"
          value="equalAddressBilling"
          onClick={props.handleSelectBillimgAdress}
        />
        <label className="usa-radio__label" htmlFor="equal">
          <h3>Usar misma dirección de facturación?</h3>
        </label>
      </div>
      <div>
        {directions.map((item) => (
          // <Myaddresses data={item} key={item.id} />
          <div key={item.id}>
            <div className="usa-radio">
              <input
                className="usa-radio__input usa-radio__input--tile"
                type="radio"
                id={item.id}
                name="addressBilling"
                value={item.id}
                onClick={props.handleSelectBillimgAdress}
              />
              <label className="usa-radio__label" htmlFor={item.id}>
                <div className="direccionsrows">
                  <div className="direccionsrows-col-50">
                    <h3>{item.adressName},</h3>
                    {item.adressAddress}
                  </div>
                  <div className="direccionsrows-col-btn direccionsrows-col-50">
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "flex-center",
                        float: "right",
                        m: 1,
                      }}
                      color="error"
                      variant="contained"
                      onClick={() => eliminarDireccion(item.id)}
                    >
                      <DeleteForeverIcon></DeleteForeverIcon>
                    </Button>
                    {modoEdicion ? (
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        disabled
                        variant="contained"
                        //onClick={() => editarDireccion(item.id)}
                      >
                        Editando
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        variant="contained"
                        onClick={() => editarDireccion(item)}
                      >
                        <EditIcon> </EditIcon>
                      </Button>
                    )}
                  </div>
                </div>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="usa-fieldset"></div>
      <div className="usa-radio usa-radio-add">
        <div>
          <div>
            <Card>
              <CardHeader
                subheader={
                  modoEdicion ? (
                    <div>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                        }}
                        color="error"
                        variant="contained"
                        onClick={cacelarEdicion}
                      >
                        <CancelIcon></CancelIcon>
                        Cancelar
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        variant="contained"
                        onClick={toggleShowEditNew}
                      >
                        <AddCircleIcon />
                        Agregar dirección
                      </Button>
                    </div>
                  )
                }
                title={
                  modoEdicion ? (
                    <h3>Editar Dirección {itemediht.adressName}</h3>
                  ) : (
                    <h3> Nueva Dirección</h3>
                  )
                }
              />
              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  <hr></hr>
                  {/* editarDireccion */}
                  <Form
                    onSubmit={guardarDireccion}
                    // {guardarDireccion}
                  >
                    {success ? (
                      <div className="alert alert-success">{success}</div>
                    ) : null}
                    {error ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                    {warning ? (
                      <div className="alert alert-warning">{warning}</div>
                    ) : null}
                    {resperror ? (
                      <div className="alert alert-danger">{resperror}</div>
                    ) : null}
                    <Form.Group widths="equal">
                      <Form.Field
                        className="formGroup2"
                        control={Input}
                        label="Identificador"
                        placeholder="Indentificador(Alias) "
                        type="text"
                        onChange={(e) => setAdressName(e.target.value)}
                        value={adressName}
                      />
                    </Form.Group>
                    <Divider />
                    <Form.Group widths="equal">
                      <Form.Field
                        control={Input}
                        label="Nombre"
                        placeholder="Nombre de Contacto"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={userName}
                        icon="user"
                      />
                      <Form.Field
                        control={Input}
                        label="Teléfono"
                        placeholder="Teléfono"
                        iconPosition="left"
                        icon="phone"
                        type="text"
                        onChange={(e) => setAdressPhone(e.target.value)}
                        value={adressPhone}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Search
                        panTo={panTo}
                        setSelected={setSelected}
                        setAdressAddress={setAdressAddress}
                        setAdressComuna={setAdressComuna}
                        setAdressCity={setAdressCity}
                        setAdressRegion={setAdressRegion}
                        setAdressCountry={setAdressCountry}
                        setAdressGeoNumAddressLat={setAdressGeoNumAddressLat}
                        setAdressGeoNumAddressLng={setAdressGeoNumAddressLng}
                        id="imputSearchFromOrigen"
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Field
                        className="formGroup2"
                        control={Input}
                        placeholder="Número dpto / casa"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setAdressNumAddress(e.target.value)}
                        value={adressNumAddress}
                        icon="angle right"
                      />
                      <Form.Field
                        className="formGroup2"
                        control={Input}
                        placeholder="Comuna"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setAdressComuna(e.target.value)}
                        value={adressComuna}
                        icon="angle right"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        control={Input}
                        className="formGroup2"
                        placeholder="Regíon"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setAdressRegion(e.target.value)}
                        value={adressRegion}
                        icon="angle right"
                      />
                      <Form.Field
                        className="formGroup2"
                        control={Input}
                        placeholder="Ciudad"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setAdressCity(e.target.value)}
                        value={adressCity}
                        icon="angle right"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        className="disabled field disabledEstilo formGroup4"
                        control={Input}
                        placeholder="Pais"
                        iconPosition="left"
                        type="text"
                        onChange={(e) => setAdressCountry(e.target.value)}
                        value={adressCountry}
                        icon="globe"
                      />
                      <Form.Field
                        control={Input}
                        placeholder="Comentario breve o referencia"
                        type="TextArea"
                        onChange={(e) => setAdressComent(e.target.value)}
                        value={adressComent}
                      />
                    </Form.Group>
                    <Form.Field
                      control={Input}
                      id="ocultarImput"
                      placeholder="Direccíon de Origen"
                      type="text"
                      onChange={(e) => setAdressAddress(e.target.value)}
                      value={adressAddress}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        variant="contained"
                        type="sutmit"
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Form>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={limpiarFormulario}
                  >
                    <CleaningServicesIcon></CleaningServicesIcon>
                    Limpiar
                  </Button>
                </Container>
                <div>
                  {/* Maps Mark */}
                  <div>
                    <GoogleMap
                      id="mapControl"
                      mapContainerStyle={mapContainerStyle}
                      zoom={12}
                      center={center}
                      options={options}
                      onClick={onMapClick}
                      onLoad={onMapLoad}
                    >
                      {LoadMarket(
                        selected,
                        setSelected,
                        draggable,
                        setMarkers,
                        setAdressGeoNumAddressLat,
                        setAdressGeoNumAddressLng
                      )}

                      {markers.map((marker) => (
                        <Marker
                          onDragEnd={(e) => {
                            // console.log(e.latLng);
                            // console.log(e.latLng.lat());
                            // console.log(e.latLng.lng());
                            marker.lat = e.latLng.lat();
                            marker.lng = e.latLng.lng();
                          }}
                          draggable={true}
                          key={`${marker.lat}-${marker.lng}`}
                          position={{ lat: marker.lat, lng: marker.lng }}
                          onClick={() => {
                            setSelected(marker);
                          }}
                          icon={{
                            url: `/PointMark.svg`,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 15),
                            scaledSize: new window.google.maps.Size(50, 50),
                          }}
                        />
                      ))}

                      {selected ? (
                        <InfoWindow
                          position={{ lat: selected.lat, lng: selected.lng }}
                          onCloseClick={() => {
                            setSelected(null);
                          }}
                        >
                          <div>
                            <h2>
                              <span role="img" aria-label={`${siteName}`}>
                                <img src="/PointMark.svg" />
                              </span>{" "}
                              Punto de Retiro
                            </h2>
                            <p>
                              Marcado{" "}
                              {formatRelative(selected.time, new Date())}
                            </p>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </GoogleMap>
                  </div>
                </div>
              </FadeInOut>

              <Divider />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

function Search({
  panTo,
  setSelected,
  setAdressAddress,
  setAdressComuna,
  setAdressCity,
  setAdressRegion,
  setAdressCountry,
  setAdressGeoNumAddressLat,
  setAdressGeoNumAddressLng,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    //console.log(address);
    setValue(address, false);
    clearSuggestions();
    let element = [];
    try {
      const results = await getGeocode({ address });
      // console.log(results[0].address_components[0].long_name);
      // console.log(results[0].address_components[1].long_name);
      // console.log(results[0].address_components[2].long_name);
      // console.log(results[0].address_components[3].long_name);
      // console.log(results[0].address_components[4].long_name);
      // console.log(results[0].address_components[5].long_name);
      // console.log(results[0].address_components[6].long_name);
      // console.log(results[0]);
      // console.log(results[0].address_components.length);
      // console.log(results[0].address_components);
      setAdressAddress(address);

      for (let i = 0; i < results[0].address_components.length; i++) {
        let elementAdress = results[0].address_components[i];
        // console.log(elementAdress["types"][0]);
        switch (elementAdress["types"][0]) {
          case "street_number":
            // console.log(elementAdress.long_name);
            break;
          case "route":
            // console.log(elementAdress.long_name);
            break;
          case "locality":
            // console.log(elementAdress.long_name);
            setAdressComuna(elementAdress.long_name);
            break;
          case "administrative_area_level_3":
            // console.log(elementAdress.long_name);
            break;
          case "administrative_area_level_2":
            // console.log(elementAdress.long_name);
            setAdressCity(elementAdress.long_name);
            break;
          case "administrative_area_level_1":
            // console.log(elementAdress.long_name);
            setAdressRegion(elementAdress.long_name);
            break;
          case "country":
            // console.log(elementAdress.long_name);
            setAdressCountry(elementAdress.long_name);

            break;
          case "postal_code":
            // console.log(elementAdress.long_name);
            break;

          default:
            break;
        }
      }

      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      //  Sin funciones
      markerBytext.lat = lat;
      markerBytext.lng = lng;

      // console.log(lat);
      // console.log(lng);
      setAdressGeoNumAddressLat(lat);
      setAdressGeoNumAddressLng(lng);
      setSelected(markerBytext);
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-3"
          id="imputSearchFromTo2"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Busca tu ubicación"
        />
        <ComboboxPopover className="comboboxList-ebiex">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={id}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

function LoadMarket(selected, setSelected, setMarkers) {
  const { siteName } = useGlobalConfig();
  return (
    <>
      <Marker
        onDragEnd={(e) => {
          markerBytext.lat = e.latLng.lat();
          markerBytext.lng = e.latLng.lng();
          var lat = e.latLng.lat();
          var lng = e.latLng.lng();
          // console.log(e.latLng);
          // console.log(e.latLng.lat());
          // console.log(e.latLng.lng());
        }}
        draggable={true}
        key={`${markerBytext.lat}-${markerBytext.lng}`}
        position={{ lat: markerBytext.lat, lng: markerBytext.lng }}
        icon={{
          url: `/PointMark.svg`,
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(15, 15),
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        onClick={() => {
          setSelected(markerBytext);
        }}
        onChange={() => {
          setSelected({ lat: markerBytext.lat, lng: markerBytext.lng });
          // setMarkers({ lat: markerBytext.lat, lng: markerBytext.lng })
        }}
      />
      // console.log(selected)
      {selected ? (
        <InfoWindow
          position={{ lat: selected.lat, lng: selected.lng }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div>
            <h2>
              <span role="img" aria-label={siteName}>
                <img src="/PointMark.svg" />
              </span>{" "}
              Punto de Retiro
            </h2>
            <p>Marcado {formatRelative(selected.time, new Date())}</p>
          </div>
        </InfoWindow>
      ) : null}
    </>
  );
}

export default Newaddressbilling;
