import * as XLSX from "xlsx";
import moment from "moment";
import { differenceInCalendarDays, format, fromUnixTime } from "date-fns";
import { translateStatesBicci } from "../objects/translateStates";

const generarReporteTurnoIncidenciasUtils = async (
  metricsOps,
  ridersDataByCompany
) => {
  console.log(metricsOps);
  if (metricsOps) {
    const bicciNumberMap = {};
    ridersDataByCompany.forEach((rider) => {
      bicciNumberMap[rider.id] = rider.bicciNumber;
    });

    function formatDate(element) {
      try {
        return element.date
          ? format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : element.createdAt
          ? format(element.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : "No Date";
      } catch (error) {
        console.log("Error al formatear la fecha:", error);
        return "Formato de fecha inválido";
      }
    }

    function getDays(element) {
      try {
        const targetDate = element.date
          ? new Date(element.date.seconds * 1000)
          : element.createdAt
          ? new Date(element.createdAt.seconds * 1000)
          : null;

        if (!targetDate) {
          return 0;
        }

        let endDate;
        if (
          (element.int_falabella_status === "99" ||
            element.int_falabella_status === "Terminado" ||
            element.int_bicciStatus === 8) &&
          element.upDatedAtText
        ) {
          endDate = fromUnixTime(element.upDatedAtText.seconds);
        } else {
          endDate = new Date();
        }

        const delayDays = differenceInCalendarDays(endDate, targetDate);

        return delayDays < 0 ? 0 : delayDays;
      } catch (error) {
        console.log("Error al calcular los días de retraso:", error);
        return "Error al calcular los días";
      }
    }

    function formatDateUpdatedAt(element) {
      try {
        return element.updatedAt
          ? format(element.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : "No date updatedAt";
      } catch (error) {
        return "Formato de fecha inválido";
      }
    }

    const updateTableDocuments = async (metricsOps) => {
      // Matriz para las incidencias
      const matrizIncidencias = [
        [
          "BICCIID",
          "SUBORDEN",
          "LPN",
          "F12",
          "PPU",
          "RIDER",
          "Origen de Carga",
          "Destino",
          "Comuna",
          "Tipo de Servicio",
          "ID Ruta/Viaje/Shipment",
          "Fecha de recepción",
          "Fecha de Actualización",
          "Fecha de entrega",
          "Estado",
          "Descripción",
          "Estado Integración",
          "Motivo de no entrega",
          "Receptor",
          "RUT Receptor",
          "Observación",
          "Días desde creacion",
          "fotos",
          "Exception GeoRef",
        ],
      ];

      metricsOps.forEach((op) => {
        if (op.listOrdersIssuresData && op.listOrdersIssuresData.data) {
          op.listOrdersIssuresData.data.forEach((issue) => {
            matrizIncidencias.push([
              issue.id || "N/A",
              issue.soc || issue.int_soc || "N/A",
              issue.lpn || issue.int_lpn || "N/A",
              issue.do || issue.int_f12 || "N/A",
              bicciNumberMap[issue.driverID] || "N/A",
              issue.driverName || "",
              issue.local_provider || issue.placeName || "N/A",
              issue.toAddress || "N/A",
              issue.commune2 || "N/A",
              issue.deliveryType || "N/A",
              issue.driverLicensePlate || "N/A",
              formatDate(issue),
              formatDateUpdatedAt(issue),
              issue.status === 8 ? formatDateUpdatedAt(issue) : "",
              issue.status || "N/A",
              issue.status
                ? translateStatesBicci[issue.status] || "N/A"
                : "N/A",
              issue.intState || "N/A",
              issue.lostDelivery === true
                ? "Devolucion"
                : issue.intState || "N/A",
              issue.userNameReceptor || issue.deliveryReceptor || "",
              issue.userDniReceptor || issue.deliveryRut || "N/A",
              issue.observationReceptor ||
              issue.observationAdmin ||
              issue.deliveryComment
                ? `${
                    issue.observationAdmin
                      ? `- Operador:  ${issue.observationAdmin[0]} `
                      : ""
                  }${
                    issue.observationReceptor
                      ? `- Conductor: ${issue.observationReceptor}`
                      : ""
                  }${issue.deliveryComment ? `- ${issue.deliveryComment}` : ""}`
                : "N/A",
              getDays(issue),
              issue.pics && issue.pics.length > 0
                ? issue.pics.join("\n")
                : issue.deliveryPictures || "N/A",
              issue.exceptionGeoRef
                ? `${issue.exceptionGeoRef._lat} , ${issue.exceptionGeoRef._long}`
                : "N/A",
            ]);
          });
        }
      });

      const libro = XLSX.utils.book_new();

      // Crear la segunda hoja con las incidencias (si tiene más de un dato)
      if (matrizIncidencias.length > 1) {
        const hoja2 = XLSX.utils.aoa_to_sheet(matrizIncidencias);
        XLSX.utils.book_append_sheet(libro, hoja2, "PENDIENTES");
      }

      XLSX.writeFile(
        libro,
        `Reporte de Pendientes ( BICCI )  ${moment(Date.now()).format(
          "YYYY-MM-DD hh:mm:ss"
        )}.xlsx`
      );
    };

    await updateTableDocuments(metricsOps);
  }
};

export default generarReporteTurnoIncidenciasUtils;
