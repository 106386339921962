import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
} from "@mui/material";
import { collection, doc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
const EditRoutePosition = ({ orderID }) => {
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(""); // Estado para la ruta
  const [position, setPosition] = useState(""); // Estado para la posición

  // Función para abrir el popup
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Función para cerrar el popup
  const handleClose = () => {
    setOpen(false);
  };

  // Función para manejar el envío de la edición
  const handleEdit = async () => {
    console.log("Ruta:", route);
    console.log("Posición:", parseInt(position));
    const colecctionRef = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );
    const orderRef = doc(colecctionRef, orderID);

    await updateDoc(orderRef, {
      driverLicensePlate: route,
      orderNumber: parseInt(position),
    });

    handleClose(); // Cerrar el popup después de enviar
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#591E8F",
          border: "none",
          borderRadius: "3px",
          color: "#fff",
          cursor: "pointer",
          fontSize: "14px",
          marginTop: "30px",
          outline: "none",
          padding: "5px",
          minWidth: "50px",
          fontWeight: 400,
          margin: "0em 1em 0em 0em",
          ":hover": {
            backgroundColor: "#25b2f4 !important",
            color: "#591e8f",
          },
        }}
        onClick={handleClickOpen}
        color="primary"
        variant="contained"
        type="button"
      >
        Editar ruta o posición
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Ruta o Posición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, ingrese la nueva ruta y posición para la edición.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="route"
            label="Ruta"
            type="text"
            fullWidth
            variant="outlined"
            value={route}
            onChange={(e) => setRoute(e.target.value)} // Actualizar el estado de la ruta
          />
          <TextField
            margin="dense"
            id="position"
            label="Posición"
            type="text"
            fullWidth
            variant="outlined"
            value={position}
            onChange={(e) => setPosition(e.target.value)} // Actualizar el estado de la posición
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEdit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditRoutePosition;
