import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import {
  fetcherOrdersSingleCompany,
  fetcherSingleCompany,
} from "../../../../utils/FectcherData/FectcherUser";

const CompaniesSingleView = (props) => {
  const { id } = useParams();
  const [user, setcompanyData] = useState(null);
  const [driverOperationData, setDriverOperationData] = useState(null);
  const [driverOperationDataAgroup, setDriverOperationDataAgroup] =
    useState(null);

  useEffect(() => {
    if (id) {
      buscarConductor(id);
    }
  }, [id]);

  useEffect(() => {
    if (driverOperationData) {
      const groupedOrders = groupOrdersByDate(driverOperationData);
      setDriverOperationDataAgroup(groupedOrders);
    }
  }, [driverOperationData]);

  const buscarConductor = async (id) => {
    let companyData = await fetcherSingleCompany(id);
    let Orders = await fetcherOrdersSingleCompany(id);
    console.log(companyData);
    console.log(Orders);
    setcompanyData(companyData);
    setDriverOperationData(Orders);
  };

  function timestampToDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function groupOrdersByDate(orders) {
    return orders.reduce((acc, order) => {
      const date = timestampToDate(order.createdAt);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {});
  }

  function printOrdersCountByDate(groupedOrders) {
    const elements = [];
    for (const [date, orders] of Object.entries(groupedOrders)) {
      elements.push(
        <div
          key={date}
        >{`${date}        =>   Ordenes asignadas : ${orders.length} `}</div>
      );
    }
    return elements;
  }

  return user ? (
    <Grid sx={{ m: 1 }}>
      <Grid>
        <h2 className="h2-withe">Detalles de : {id}</h2>
      </Grid>
      <Grid container spacing={3} sx={{ m: 1 }}>
        <Grid xs={12} md={2}>
          {/* id="mi-cuentaContainer"> */}
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={user.avatar}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.companyName}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.companyRut}
                </Typography>
                Create By:
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.createByUid}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.companyAdressPhone}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {`${user.companyContacEmail}`}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {user.companyContacName}
                </Typography>
                COMPANY ID:
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.companyID}
                </Typography>
                COMPANY FOLDER:
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.companyCodeFolder}
                </Typography>
                COMPANY RUT:
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.companyRut}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
              <Card>
                <h3>Datos de facturación</h3>
                {user ? (
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.isActive === true ? (
                          <>Activo</>
                        ) : (
                          <>
                            <i className="exclamation circle icon alert-danger">
                              {" "}
                            </i>
                            Activación en espera.. Puede solicitar revisión en
                            soporte{" "}
                          </>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.statusDocsRut === true ? (
                          <span>
                            <i className="check circle icon alert-success"></i>{" "}
                            RUT
                          </span>
                        ) : (
                          <span>
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            RUT
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.statusDocsComercio ? (
                          <span>
                            <i className="check circle icon alert-success"></i>{" "}
                            Documento Constitutivo
                          </span>
                        ) : (
                          <span>
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            Si es necesario Documento Constitutivo
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.statusDocsDocsii ? (
                          <span>
                            <i className="check circle icon alert-success"></i>{" "}
                            Documento SII
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            Documento SII
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {" "}
                        Acuerdo comercial
                        {user.statusDocsAcuerdo ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>{" "}
                            Acuerdo subido{" "}
                          </span>
                        ) : (
                          <span>
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            En la espera{" "}
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.statusDocsPatente ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>{" "}
                            Pantente Subido
                          </span>
                        ) : (
                          <span>
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            En la espera{" "}
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {" "}
                        Logo
                        {user.statusDocsLogo ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>{" "}
                            Pantente Subido
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            En la espera
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        Metodo de pago
                        {user.currentPayMethods.length <= 0 ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>{" "}
                            Seleccionado
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>{" "}
                            No seleccionado
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.payPendings <= 0 ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>
                            No posee pagos pendientes
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>
                            $ {user.payPendings}
                          </span>
                        )}
                      </Typography>
                      <Divider />
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        color="textSecondary"
                        variant="body2"
                      >
                        {user.suportTikets.length <= 0 ? (
                          <span>
                            <br></br>
                            <i className="check circle icon alert-success"></i>
                            Todos los tickets de soporte estan cerrados
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <br></br>{" "}
                            <i className="exclamation circle icon alert-warning"></i>
                            <button>ver tickets</button>
                          </span>
                        )}
                      </Typography>
                      <Divider />
                    </Box>
                  </CardContent>
                ) : (
                  <span>
                    <i className="exclamation circle icon alert-warning"></i>
                    Agrege los datos de su empresa
                  </span>
                )}
                <Divider />
                <CardActions></CardActions>
              </Card>
            </CardActions>
          </Card>
          <br></br>
        </Grid>

        <Grid xs={12} md={6}>
          <Card sx={{ m: 1 }}>
            {/* className="typeMiniforms"> */}
            <CardContent>
              <h3>
                Detalles de actividad últimos 30 días, se considera la fecha de
                creación
              </h3>
            </CardContent>
            <Divider />
            <CardActions></CardActions>
          </Card>
          <Card sx={{ m: 1 }}>
            {/* className="typeMiniforms"> */}
            <CardContent>
              {driverOperationDataAgroup ? (
                <>{printOrdersCountByDate(driverOperationDataAgroup)}</>
              ) : null}
            </CardContent>
            <Divider />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default CompaniesSingleView;
