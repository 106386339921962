import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

const RutaPrivada = ({
  component: Component,
  usuarioStorage,
  firebaseUser,
  ...rest
}) => {
  // console.log(usuarioStorage);
  // console.log(firebaseUser);
  // Si el usuario no está autenticado, redirigir al usuario al login

  if (!firebaseUser) {
    return <Navigate to="/login" />;
  }

  // Si el usuario está autenticado pero el usuarioStorage no es válido, cerrar sesión y redirigir al usuario al login
  if (!usuarioStorage) {
    // Aquí deberías implementar la lógica para cerrar sesión del usuario y eliminar cualquier información de usuario almacenada en el almacenamiento local (localStorage)
    return <Navigate to="/login" />;
  }

  // Si el usuario está autenticado y el usuarioStorage es válido, permitir el acceso a la ruta privada
  if (firebaseUser.uid === usuarioStorage.uid) {
    return <Outlet />;
  }

  // Si el usuario está autenticado pero el usuarioStorage es de otro usuario, cerrar sesión y redirigir al usuario al login
  // Aquí deberías implementar la misma lógica que en el caso anterior (usuarioStorage no es válido)
  return <Navigate to="/login" />;
};

export default RutaPrivada;
