import React from "react";
import { Image, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SecurityIcon from "@mui/icons-material/Security";
import { db } from "../../firebase";
import { NavLink } from "react-router-dom";
import { clientAddCartProduct } from "../../redux/acccionesCart";
import useGlobalConfig from "../../hooks/globalConfig";
import { CircularProgress, LinearProgress } from "@mui/material";
//https://www.mercadolibre.cl/gz/cart
//http://localhost:3000/
//http://localhost:3000/productos/pack-15
//http://localhost:3000/productos/

var userUid = "noregister";
var userState = false;

const Productdetails = (props) => {
  const { siteName, phoneSuport, telSuport } = useGlobalConfig();
  const [productoUrl, setUrl] = React.useState(props.match.params.slugs);
  const [productoDetails, setProductoDetails] = React.useState([]);
  const [taxesAsoc, setTaxesAsoc] = React.useState([]);
  const dispatch = useDispatch();
  const [reload, setReload] = React.useState(true);

  try {
    const user = useSelector((store) => store.usuario.user.uid);
    userUid = user;
    userState = true;
  } catch (error) {
    userState = false;
  }

  React.useEffect(() => {
    // console.log(productoUrl)
    findProductoSlug(productoUrl);
    findPlanSlug(productoUrl);
  }, [productoUrl]);

  React.useEffect(() => {
    //  handleReload()
  }, [reload]);

  const findProductoSlug = async (slugs) => {
    var id;
    const resultsProduct = await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .get();
    resultsProduct.forEach((snapshot) => {
      let field = snapshot.get("slug");
      id = snapshot.get("id");
      if (field === slugs) {
        // console.log(`Find Product : ${field}`);
        // console.log(`Find Product en : ${id}`);
        let type = "plan";
        getDetails(id, type);
      }
    });
  };

  const findPlanSlug = async (slugs) => {
    var id;
    const resultsProduct = await db
      .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .get();
    resultsProduct.forEach((snapshot) => {
      let field = snapshot.get("slug");
      id = snapshot.get("productId");
      if (field === slugs) {
        // console.log(`Find Product : ${field}`);
        // console.log(`Find Product en : ${id}`);
        let type = "bags";
        getDetails(id, type);
      }
    });
  };

  const getDetails = async (id, type) => {
    if (type === "plan") {
      const getResultsProduct = await db
        .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
        .doc(id)
        .get();
      setProductoDetails(getResultsProduct.data());
      const getPlans = await db
        .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
        .get();
      const clearSelft = getPlans.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const similar = clearSelft.filter((item) => item.id !== id);
      const simlarStatus = similar.filter((item) => item.isDelete === false);
      const simlarActive = simlarStatus.filter(
        (item) => item.isActive === true
      );
      const simlarPublic = simlarActive.filter(
        (item) => item.isPublic === true
      );
      const simlarisPost = simlarPublic.filter((item) => item.isPost === true);
      simlarisPost.sort((a, b) => (a.dispachtDate < b.dispachtDate ? 1 : -1));
      let getTree = simlarisPost.slice(0, 2);
      // console.log(getTree)
      if (getTree.length === 0) {
        setTaxesAsoc(null);
      } else {
        setTaxesAsoc(getTree);
      }
      // console.log(getResultsProduct.data())
    }
    if (type === "bags") {
      const getResultsProduct = await db
        .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
        .doc(id)
        .get();
      setProductoDetails(getResultsProduct.data());
      const getProducts = await db
        .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
        .get();
      const clearSelft = getProducts.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const similar = clearSelft.filter((item) => item.id !== id);
      const simlarStatus = similar.filter((item) => item.isDelete === false);
      const simlarActive = simlarStatus.filter(
        (item) => item.isActive === true
      );
      const simlarPublic = simlarActive.filter(
        (item) => item.isPublic === true
      );
      //  const simlarisPost = simlarPublic.filter(item => item.isPost === true)
      simlarPublic.sort((a, b) => (a.dispachtDate < b.dispachtDate ? 1 : -1));
      let getTree = simlarPublic.slice(0, 2);
      // console.log(getTree)
      if (getTree.length === 0) {
        setTaxesAsoc(null);
      } else {
        setTaxesAsoc(getTree);
      }
      // console.log(getResultsProduct.data())
    }
  };

  const addToCard = (e) => {
    let obj = e.target.value;
    // console.log(obj, userUid)
    dispatch(clientAddCartProduct(obj, userUid));
    // props.history.push(`/checkout/`)
  };
  const handleSelect = async (e) => {
    // console.log(e.metaKey)
    // console.log(e.target)
    let element = e.target;
    let vaule = element.value;
    // setTaxesAsoc([])

    // console.log(vaule)
    setUrl(e.target.value);
    props.history.push(`/productos/${e.target.value}`);
    //   setIsFind(true)
  };

  return (
    <div className="bg-container">
      <div className="ui-vpp-store__logo-container">
        <div className="ui-vpp-store__bg-image productHedadre"></div>
      </div>
      <div>
        {productoDetails ? (
          <div className="bg-containercolor">
            <div id="bodycontent-prodtucto">
              <div className="ui-vpp-store__logo-container"></div>
              <div>
                <div className="breadcrumbebiex">
                  <div className="breadcrumbebiex-container">
                    <NavLink to="/productos/">Volver a productos</NavLink>
                  </div>
                  <div className="bodycontent-prodtucto">
                    <div className="container-producto">
                      <section className="section-content padding-y bg">
                        <div className="container">
                          <article className="card">
                            <div className="card-body">
                              <div className="row">
                                <aside className="col-md-7">
                                  <article className="gallery-wrap">
                                    <div className="card Image-big-wrap">
                                      <NavLink to="#">
                                        {" "}
                                        <Image src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-03.png?alt=media&token=bdff97b1-1471-4c59-a142-327e59c0b9ff" />
                                      </NavLink>
                                    </div>
                                  </article>
                                </aside>

                                <main className="col-md-5">
                                  <div className="ui-pdp--sticky-wrapper">
                                    <article className="cardarticle">
                                      {userState === false ? (
                                        <div className="section-title-non">
                                          <h2>Inicie sesion</h2>
                                        </div>
                                      ) : (
                                        <div className="section-title">
                                          {/* <h2>Bienvenido</h2> */}
                                        </div>
                                      )}
                                      <NavLink
                                        to="#"
                                        className="text-primary btn-link"
                                      >
                                        Bolsas de envíos
                                      </NavLink>
                                      <h3 className="title">
                                        {productoDetails.productName}
                                        {productoDetails.planName}
                                      </h3>
                                      <span className="title">
                                        {productoDetails.description}
                                      </span>

                                      <hr />
                                      {productoDetails.descriptorsWeb ? (
                                        <>
                                          {productoDetails.descriptorsWeb
                                            .length > 0 ? (
                                            <>
                                              <div className="mb-3">
                                                <h6>Descripción corta</h6>
                                                <ul className="list-dots mb-0">
                                                  {productoDetails.descriptorsWeb.map(
                                                    (item) => (
                                                      <li
                                                        key={Math.random().toString()}
                                                      >
                                                        {item}
                                                      </li>
                                                    )
                                                  )}{" "}
                                                </ul>
                                              </div>
                                            </>
                                          ) : (
                                            <>{/* es cero */}</>
                                          )}
                                        </>
                                      ) : (
                                        <>{/* buscando... */}</>
                                      )}
                                      {productoDetails.descriptorsWEB0 ? (
                                        <>
                                          <div className="mb-3">
                                            <h6>Descripción corta</h6>
                                            <ul className="list-dots mb-0">
                                              {productoDetails.descriptorsWEB0 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB0
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB1 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB1
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB2 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB2
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB3 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB3
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB4 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB4
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB5 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB5
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB6 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB6
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                              {productoDetails.descriptorsWEB7 ? (
                                                <li>
                                                  {
                                                    productoDetails.descriptorsWEB7
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                            </ul>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="mb-3">
                                        {productoDetails.discountAmount > 0 ? (
                                          <>
                                            <var className="price h4">
                                              ${productoDetails.totalAmount}
                                            </var>{" "}
                                            <br />
                                            <span className="monthly">
                                              Precio Normal $
                                              {productoDetails.ChargeAmount} /{" "}
                                              {productoDetails.ChargeShipments}{" "}
                                              envíos
                                            </span>
                                            <br></br>
                                            <span className="monthly">
                                              Ahorras $
                                              {productoDetails.discountAmount} /
                                              en esta compra.
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <var className="price h4">
                                              ${productoDetails.totalAmount}{" "}
                                              {productoDetails.articlePrice}
                                            </var>{" "}
                                            <br />
                                            <span className="monthly">
                                              Precio: $
                                              {productoDetails.ChargeAmount}{" "}
                                              {productoDetails.articlePrice}/{" "}
                                              {productoDetails.ChargeShipments}{" "}
                                              envíos
                                            </span>
                                            <br></br>
                                          </>
                                        )}

                                        <br></br>

                                        <a
                                          className="nonstyle"
                                          href={telSuport}
                                        >
                                          <Icon color="purple" name="phone" />
                                          {phoneSuport} Asistencia
                                        </a>
                                      </div>

                                      <div className="mb-4">
                                        {productoDetails.id ? (
                                          <>
                                            <button
                                              onClick={addToCard}
                                              value={productoDetails.id}
                                              className="ui positive button"
                                            >
                                              COMPRAR AHORA
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        <NavLink
                                          to="/soporte/"
                                          className="btn btn-light"
                                        >
                                          SOPORTE
                                        </NavLink>
                                      </div>
                                      {taxesAsoc ? (
                                        <>
                                          {taxesAsoc.length > 0 ? (
                                            <>
                                              <label className="text-muted">
                                                Similares
                                              </label>
                                            </>
                                          ) : (
                                            <>
                                              <LinearProgress />
                                            </>
                                          )}

                                          <div className="thumbs-wrap">
                                            {taxesAsoc.map((item) => (
                                              <button
                                                key={item.id}
                                                onClick={handleSelect}
                                                value={item.slug}
                                                className="ui purple basic button"
                                              >
                                                <Image
                                                  className="pointereventsnone"
                                                  src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-03.png?alt=media&token=bdff97b1-1471-4c59-a142-327e59c0b9ff"
                                                />
                                                {item.productName}
                                                {item.planName}
                                                <br></br>
                                              </button>
                                            ))}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </article>

                                    <div className="ui-pdp-container-ebiex mt-16 ">
                                      <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                          <h3 className="title">
                                            Información de la tienda
                                          </h3>
                                          <div className="ui-seller-info">
                                            <div className="seller_header">
                                              <div className="ui-pdp-seller__header__image-container">
                                                <Image
                                                  className="logo-infoStore"
                                                  decoding="async"
                                                  src="https://firebasestorage.googleapis.com/v0/b/e-biex-265f6.appspot.com/o/assent%2F10022022%2F10022022%20(12).png?alt=media&token=e5f88a95-6b92-4c1b-9018-4f88bb2e5d22"
                                                  alt="ebiex"
                                                ></Image>
                                              </div>
                                              <div className="ui-pdp-seller__header__info-container">
                                                <div className="ui-pdp-seller__header__title">
                                                  {siteName}
                                                </div>
                                                <p className="ui-pdp-color--GRAY ui-pdp-size--XSMALL ui-pdp-family--REGULAR ui-pdp-seller__header__subtitle">
                                                  Tienda oficial.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ui-pdp-container-ebiex mt-16 ">
                                      <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                          <h3 className="title">
                                            Medios de pago
                                          </h3>
                                          <div className="ui-seller-info">
                                            <div className="seller_header">
                                              <div className="ui-pdp-seller__header__image-container"></div>
                                              <div className="ui-pdp-seller__header__info-container">
                                                <div className="ui-pdp-seller__header__title">
                                                  Transferencia
                                                </div>
                                                <p className="ui-pdp-color--GRAY ui-pdp-size--XSMALL ui-pdp-family--REGULAR ui-pdp-seller__header__subtitle">
                                                  <SecurityIcon color="success"></SecurityIcon>
                                                  Pagos seguros transferencias:
                                                  <br></br>
                                                  todos los pagos por
                                                  transferencias son recibidos a
                                                  nuestra cuenta empresa, no
                                                  utilizamos cuentas de
                                                  terceros.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </main>
                              </div>
                            </div>
                          </article>
                          <article className="card mt-5">
                            <div className="card-body">
                              <div className="row">
                                <aside className="col-md-6">
                                  <h5>Características</h5>
                                  <dl className="row">
                                    <dt className="col-sm-3">#1</dt>
                                    <dd className="col-sm-9">
                                      Lorem ipsum dolor sit amet
                                    </dd>

                                    <dt className="col-sm-3">#2</dt>
                                    <dd className="col-sm-9">
                                      Lorem ipsum dolor sit amet
                                    </dd>

                                    <dt className="col-sm-3">#3</dt>
                                    <dd className="col-sm-9">
                                      Lorem ipsum dolor sit amet
                                    </dd>

                                    <dt className="col-sm-3">#4</dt>
                                    <dd className="col-sm-9">
                                      Lorem ipsum dolor sit amet
                                    </dd>

                                    <dt className="col-sm-3">#5</dt>
                                    <dd className="col-sm-9">
                                      Lorem ipsum dolor sit amet
                                    </dd>
                                  </dl>
                                </aside>
                                <aside className="col-md-6">
                                  <h5>Beneficios</h5>
                                  <ul className="list-check">
                                    <li> Lorem ipsum dolor sit amet</li>
                                    <li> Lorem ipsum dolor sit amet</li>
                                    <li> Lorem ipsum dolor sit amet</li>
                                    <li> Lorem ipsum dolor sit amet</li>
                                    <li> Lorem ipsum dolor sit amet</li>
                                  </ul>
                                </aside>
                              </div>
                              <hr />
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </p>
                            </div>
                          </article>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Productdetails FALSE</div>
        )}
      </div>
    </div>
  );
};

export default Productdetails;
