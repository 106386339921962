import React from "react";
import Slider from "react-slick";
import { User as UserIcon } from "../../icons/User";
import { NavLink } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import PaymentIcon from "@mui/icons-material/Payment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MapIcon from "@mui/icons-material/Map";

let buttonsAdmin = [
  {
    title: "Empresa",
    icon: <BusinessIcon fontSize="medium" />,
    link: "/dashboard/companies",
  },

  {
    title: "Sucursales",
    icon: <StoreIcon fontSize="medium" />,
    link: "/dashboard/stores",
  },
  // {
  //   title: "Usuarios",
  //   icon: <PeopleIcon fontSize="medium" />,
  //   link: "/dashboard/users",
  // },
  // {
  //   title: "Invitar Usuario",
  //   icon: <DeliveryDiningIcon fontSize="medium" />,
  //   link: "/dashboard/usuarios",
  // },
  {
    title: "Envíos express",
    icon: <PersonAddAlt1Icon fontSize="medium" />,
    link: "/dashboard/envios-express",
  },
  {
    title: "Flota",
    icon: <LocalShippingIcon fontSize="medium" />,
    link: "/dashboard/fleet",
  },

  {
    title: "Despachos",
    icon: <ContentPasteGoIcon fontSize="medium" />,
    link: "/dashboard/guias",
  },
  {
    title: "Conductores",
    icon: <DirectionsBikeIcon fontSize="medium" />,
    link: "/dashboard/drivers",
  },
  {
    title: "Vehículos",
    icon: <AirportShuttleIcon fontSize="medium" />,
    link: "/dashboard/vehicles",
  },
  // {
  //   title: "Rutas",
  //   icon: <ForkLeftIcon fontSize="medium" />,
  //   link: "/dashboard/routes",
  // },
  {
    title: "Envío Express",
    icon: <DeliveryDiningIcon fontSize="medium" />,
    link: "/dashboard/mi-cuenta",
  },
  {
    title: "Mi Cuenta",
    icon: <UserIcon fontSize="medium" />,
    link: "/dashboard/mi-cuenta",
  },
  {
    title: "Dashboard",
    icon: <ListAltIcon fontSize="medium" />,
    link: "/dashboard/tablero",
  },
  {
    title: "Direcciones",
    icon: <AddLocationAltIcon fontSize="medium" />,
    link: "/dashboard/direcciones",
  },
  {
    title: "Soporte", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/dashboard/report-bugs",
  },
  {
    title: "Contactanos", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/contacto",
  },
];
let buttonsClient = [
  {
    title: "Empresa",
    icon: <BusinessIcon fontSize="medium" />,
    link: "/dashboard/companies",
  },
  {
    title: "Mi Cuenta",
    icon: <UserIcon fontSize="medium" />,
    link: "/dashboard/mi-cuenta",
  },
  {
    title: "Dashboard",
    icon: <ListAltIcon fontSize="medium" />,
    link: "/dashboard/tablero",
  },
  {
    title: "Direcciones",
    icon: <AddLocationAltIcon fontSize="medium" />,
    link: "/dashboard/direcciones",
  },
  {
    title: "Soporte", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/dashboard/report-bugs",
  },
  {
    title: "Contactanos", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/contacto",
  },
];
let buttonsAdminCompany = [
  {
    title: "Empresa",
    icon: <BusinessIcon fontSize="medium" />,
    link: "/dashboard/companies",
  },

  {
    title: "Sucursales",
    icon: <StoreIcon fontSize="medium" />,
    link: "/dashboard/stores",
  },
  // {
  //   title: "Invitar Usuario",
  //   icon: <PersonAddAlt1Icon fontSize="medium" />,
  //   link: "/dashboard/usuarios",
  // },
  {
    title: "Envíos express",
    icon: <PersonAddAlt1Icon fontSize="medium" />,
    link: "/dashboard/envios-express",
  },
  {
    title: "Flota",
    icon: <LocalShippingIcon fontSize="medium" />,
    link: "/dashboard/fleet",
  },

  {
    title: "Despachos",
    icon: <ContentPasteGoIcon fontSize="medium" />,
    link: "/dashboard/guias",
  },
  {
    title: "Vehículos",
    icon: <AirportShuttleIcon fontSize="medium" />,
    link: "/dashboard/vehicles",
  },
  {
    title: "Envío Express",
    icon: <DeliveryDiningIcon fontSize="medium" />,
    link: "/dashboard/envios-express",
  },
  {
    title: "Mi Cuenta",
    icon: <UserIcon fontSize="medium" />,
    link: "/dashboard/mi-cuenta",
  },
  {
    title: "Dashboard",
    icon: <ListAltIcon fontSize="medium" />,
    link: "/dashboard/tablero",
  },
  {
    title: "Direcciones",
    icon: <AddLocationAltIcon fontSize="medium" />,
    link: "/dashboard/direcciones",
  },
  {
    title: "Soporte", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/dashboard/report-bugs",
  },
  {
    title: "Contactanos", //
    icon: <EngineeringIcon fontSize="medium" />,
    link: "/contacto",
  },
];

let buttonsAdminFalabella = [
  {
    title: "Ops Falabella", // props.companyCollaborator === "falabella" ?
    icon: <ContentPasteGoIcon fontSize="medium" />,
    link: "/dashboard/guiasfalabella",
  },

  {
    title: "Monitor Falabella", // props.companyCollaborator === "falabella" ?
    icon: <AssessmentIcon fontSize="medium" />,
    link: "/dashboard/metricsfalabella",
  },

  {
    title: "Alerta OPS", // props.companyCollaborator === "falabella" ?
    icon: <AssessmentIcon fontSize="medium" />,
    link: "/dashboard/excepcionesfalabella",
  },
  {
    title: "Pagos", // props.companyCollaborator === "falabella" ?
    icon: <PaymentIcon fontSize="medium" />,
    link: "/dashboard/guias-facturacion",
  },
];

const Button = ({ title, icon, link }) => (
  <NavLink to={link} className="button-link">
    <div className="button">
      <div className="button-icon">{icon}</div>
      <div className="button-title">{title}</div>
    </div>
  </NavLink>
);

export default function CarouselItemsApp(user) {
  // console.log(user);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    touchMove: true,
    arrows: false, // Agregar este atributo
  };

  if (user.companyCollaborator === "falabella") {
    buttonsAdminCompany = buttonsAdminCompany.concat(buttonsAdminFalabella);
    buttonsClient = buttonsClient.concat(buttonsAdminFalabella);
    buttonsAdmin = buttonsAdmin.concat(buttonsAdminFalabella);
  }

  if (user.userRoll === process.env.REACT_APP_ADMIN) {
    return (
      <div className="carousel-container">
        <Slider className={"componente1"} {...settings}>
          {buttonsAdmin.map((button) => (
            <div key={button.title} className="carousel-item">
              <Button
                title={button.title}
                icon={button.icon}
                link={button.link}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  if (user.userRoll === process.env.REACT_APP_ADMIN_COMPANY) {
    return (
      <div className="carousel-container">
        <Slider className={"componente1"} {...settings}>
          {buttonsAdminCompany.map((button) => (
            <div key={button.title} className="carousel-item">
              <Button
                title={button.title}
                icon={button.icon}
                link={button.link}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
  if (user.userRoll === process.env.REACT_APP_CLIENT) {
    return (
      <div className="carousel-container">
        <Slider className={"componente1"} {...settings}>
          {buttonsClient.map((button) => (
            <div key={button.title} className="carousel-item">
              <Button
                title={button.title}
                icon={button.icon}
                link={button.link}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
