import React from "react";
import PropTypes from "prop-types";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const LocalizationContext = React.createContext();

function LocalizationProviderContext(props) {
  const { children } = props;

  return (
    <LocalizationContext.Provider
      value={
        {
          /* valores que desees proporcionar */
        }
      }
    >
      <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
        {children}
      </MuiLocalizationProvider>
    </LocalizationContext.Provider>
  );
}

LocalizationProviderContext.propTypes = {
  children: PropTypes.node,
};

export default LocalizationProviderContext;
