import React from "react";
import { Container } from "semantic-ui-react";
import FrmSoporte from "../../../components/formularios/FrmSoporte";
const ReportBug = () => {
  return (
    <div>
      <Container className="dashcontent">
        <div>
          <FrmSoporte></FrmSoporte>
        </div>
      </Container>
    </div>
  );
};

export default ReportBug;
