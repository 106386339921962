import { useEffect, useState } from "react";
import { db } from "../firebase";
import Moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";

function useGetDataRidersActive() {
  const [listRiderData, setListRiderData] = useState([]);
  const [loadRidersData, setLoadDataRider] = useState(false);
  const [errorRidersData, setErrorDataRider] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadDataRider(true);
    try {
      const allRidersRef = collection(
        db,
        process.env.REACT_APP_COL_USERS_DRIVERS
      );

      const q = query(
        allRidersRef,
        where("active", "==", true),
        where("online", "==", true)
      );
      const allData = await getDocs(q);

      var allDataRidersfind = allData.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setListRiderData(allDataRidersfind);
      setLoadDataRider(false);
      setErrorDataRider(null);
    } catch (error) {
      setLoadDataRider(false);
    }
  };

  return { listRiderData, loadRidersData, errorRidersData };
}

export default useGetDataRidersActive;
