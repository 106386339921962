import React, { useContext, useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  Box,
  Button,
  Card,
  Divider,
  CardHeader,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { OutlinedInput } from "@mui/material";
// import Map_marks from './Map_marks';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { deletePointADireccionForCompany } from "../../../redux/accionesCompanies";
import { updatePointADireccionForCompany } from "../../../redux/accionesCompanies";
import { crearPointADireccionForCompany } from "../../../redux/accionesCompanies";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
//import FadeInOut from "../../../components/animations/FadeInOut";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GlobeIcon from "@mui/icons-material/Public";
import MarkerIcon from "@mui/icons-material/Place";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import NumbersIcon from "@mui/icons-material/Numbers";
import UserIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import "@reach/combobox/styles.css";
import mapStyles from "../../../components/MapsGoogle/mapStyles";
import GooglePlacesAutocomplete from "../../../utils/objects/GooglePlacesAutocomplete";
import { UserContext } from "../../../context/CurrentUserProvider";
import FadeInOut from "../../../components/animations/FadeInOut";

const libraries = ["places", "drawing"];

const markerBytext = {
  lat: -33.444307504592445,
  lng: -70.64900995751954,
  time: new Date(),
};

const mapContainerStyle = {
  height: "50vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};
const center = {
  lat: -33.44488045476359,
  lng: -70.65930964013673,
};

const BranchOffc = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const { userDataContext } = useContext(UserContext);

  const dispatch = useDispatch();

  const [directions, setDirections] = React.useState([]);
  const [modoEdicion, setModoEdicion] = React.useState(false);
  const [itemediht, setItemEdith] = React.useState([]);

  //Variavles de formularios

  // States
  const resperror = useSelector((store) => store.usuario.error);
  const [error, setError] = React.useState([]);
  const [errorCritical, setErrorCritical] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [warning, setWarning] = React.useState("");

  // User Typing
  // const [adressName, setAdressName] = React.useState("Dirección#1");
  // const [userName, setName] = React.useState("");
  // const [adressPhone, setAdressPhone] = React.useState("+56");
  // const [adressAddress, setAdressAddress] = React.useState("");
  // const [adressNumAddress, setAdressNumAddress] = React.useState("");
  // const [adressRegion, setAdressRegion] = React.useState("");
  // const [adressCity, setAdressCity] = React.useState("");
  // const [adressComuna, setAdressComuna] = React.useState("");
  // const [adressCountry, setAdressCountry] = React.useState("CHILE");
  // const [adressComent, setAdressComent] = React.useState("");
  // const [companyUid, setCompanyUid] = React.useState("");
  const [logoMarket, setLogoMarkert] = React.useState(
    "https://firebasestorage.googleapis.com/v0/b/bicci-dev-c842f.appspot.com/o/expressDocuments%2Fcompanies%2F11111111-1%2Flogo-11111111-1?alt=media&token=0404274d-0e14-43f5-b102-b888c1a31f19"
  );

  //Maps
  const [adressGeoNumAddressLat, setAdressGeoNumAddressLat] =
    React.useState("");
  const [adressGeoNumAddressLng, setAdressGeoNumAddressLng] =
    React.useState("");

  //Animaciones
  const [showEdit, setShowEdit] = useState(false);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const [isEditNew, setEditNew] = useState(false);

  const [prefix, setPrefix] = useState(null);

  const [currentItemEdith, setCurrentItemEdith] = useState(false);

  const [markers, setMarkers] = React.useState([]);

  const [newSelected, setNewSelected] = React.useState(null);

  const [selected, setSelected] = React.useState(null);

  const [isDepartamento, setIsDepartamento] = useState(false);

  const [origenAdress, setOrigenAdress] = useState({
    adressName: "",
    clientNamesOrigin: "",
    clientRutOrigin: "",
    costCenter: "",
    userPhoneOrigin: "+56",
    toAddressOrigin: "",
    direccion1Origin: "",
    direccion2Origin: "",
    direccion3Origin: "N/A",
    communeOrigin: "",
    commune2Origin: "",
    regionOrigin: "",
    countryOrigin: "",
    adressOriginComent: "",
    company: "",
    companyID: "",
    commerce: "",
    int_warehouseName: "",
    int_warehouseCode: "",
    int_placeName: "",
    zonePoligon: "",
  });

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (userDataContext.companyData) {
      setPrefix(userDataContext.companyData.prefix);

      setOrigenAdress((prevState) => ({
        ...prevState,
        companyID: userDataContext.companyData.companyID,
        company: userDataContext.companyData.companyName,
        commerce: userDataContext.companyData.companyName,
        clientRutOrigin: userDataContext.companyData.companyRut,
      }));
    }
  }, [userDataContext.companyData]);

  React.useEffect(() => {
    if (!userDataContext.companyData) {
      setErrorCritical(
        "No se encuentra empresa asociada a su cuenta. Para crear nuevas sucursales primero debe crear la Empresa o ser asociado a una cuenta comercial."
      );
    } else {
      setErrorCritical(null);
      findAdressesCompany(userDataContext.companyData.companyID);
    }
  }, [userDataContext]);

  const findAdressesCompany = async (companyUid) => {
    console.log(companyUid);
    try {
      const direccionesRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${companyUid}/direcciones`
      );
      const direccionesSnapshot = await getDocs(direccionesRef);
      const adressses = direccionesSnapshot.docs.map((doc) => doc.data());

      //console.log(adressses.docs)
      // const directions = adressses.docs.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));
      const adreessFilter = adressses.filter((item) => item.delete !== true);
      console.log(adreessFilter);
      setMarkers(adreessFilter);
      setDirections(adreessFilter);
    } catch (error) {
      //console.log(error)
    }
  };

  const mapRef = React.useRef();

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const eliminarDireccion = (id) => {
    console.log("eliminarDireccion", id, origenAdress);
    const dirDelete = directions.filter((item) => item.id === id);
    const dirRest = directions.filter((item) => item.id !== id);
    setDirections(dirRest);
    setMarkers(dirRest);
    let data = {
      id: id,
      companyID: origenAdress.companyID
        ? origenAdress.companyID
        : dirDelete.companyUid,
    };
    dispatch(deletePointADireccionForCompany(data));
    function delay() {
      setSuccess(null);
    }
    setTimeout(delay, 1000);

    findAdressesCompany(userDataContext.companyData.companyID);
  };

  const cacelarEdicion = () => {
    //console.log('cacelarEdicion')
    dispatch(toggleShowEdit);
    setEditNew(false);
    setModoEdicion(false);
  };

  const toggleShowEditNew = () => {
    toggleShowEdit(true);
    setEditNew(true);
    setModoEdicion(true);
    setError([]);
    setSuccess(null);
    setWarning(null);
  };

  const editarDireccion = async (item) => {
    //console.log('editarDireccion item companyUid')
    console.log(item);
    //console.log(companyUid)
    if (item !== undefined) {
      try {
        setEditNew(false);
        setModoEdicion(true); // Alternar formulario  modoEdicion =   <Form_direcciones/> :  <Edit_direcciones itemData={data}/>
        setOrigenAdress((prevState) => ({
          ...prevState,
          id: item.id,
          adressName: item.adressName ? item.adressName : "",
          clientNamesOrigin: item.clientNamesOrigin
            ? item.clientNamesOrigin
            : "",
          userPhoneOrigin: item.userPhoneOrigin ? item.userPhoneOrigin : "",
          toAddressOrigin: item.toAddressOrigin ? item.toAddressOrigin : "",
          direccion1Origin: item.direccion1Origin ? item.direccion1Origin : "",
          direccion2Origin: item.direccion2Origin ? item.direccion2Origin : "",
          direccion3Origin: item.direccion3Origin
            ? item.direccion3Origin
            : "N/A",
          communeOrigin: item.communeOrigin ? item.communeOrigin : "",
          commune2Origin: item.commune2Origin ? item.commune2Origin : "",
          regionOrigin: item.regionOrigin ? item.regionOrigin : "",
          countryOrigin: item.countryOrigin ? item.countryOrigin : "",
          companyID: userDataContext.companyData.companyID,
          company: userDataContext.companyData.companyName,
          commerce: userDataContext.companyData.companyName,
          clientRutOrigin: userDataContext.companyData.companyRut,
          int_warehouseName: item.int_warehouseName
            ? item.int_warehouseName
            : "",
          int_warehouseCode: item.int_warehouseCode
            ? item.int_warehouseCode
            : "",
          int_placeName: item.int_placeName ? item.int_placeName : "",
          description: item.description ? item.description : "",
          zonePoligon: item.zonePoligon ? item.zonePoligon : "",
        }));
      } catch (error) {
        console.log(item);
        console.log(error);
      }
    }
    dispatch(toggleShowEdit);
    setError([]);
    setSuccess(null);
    setWarning(null);
  };

  const guardarDireccion = (e) => {
    e.preventDefault();
    let errores = [];
    console.log("Dirección a guardar:", origenAdress);
    if (!origenAdress.clientNamesOrigin) {
      errores.push("El nombre de la dirección es obligatorio.");
      setError(errores);
      return;
    }
    if (!origenAdress.adressName.trim()) {
      errores.push("El nombre de la dirección es obligatorio.");
    }
    if (!origenAdress.clientNamesOrigin.trim()) {
      errores.push("El nombre del cliente es obligatorio.");
    }
    // if (!origenAdress.clientRutOrigin.trim()) {
    //   errores.push("El RUT del cliente es obligatorio.");
    // }
    // Valida el formato del teléfono si es necesario
    if (
      !origenAdress.userPhoneOrigin.trim() ||
      origenAdress.userPhoneOrigin === "+56"
    ) {
      errores.push("El teléfono del usuario es obligatorio.");
    }
    if (!origenAdress.direccion1Origin.trim()) {
      errores.push("La dirección de destino es obligatoria.");
    }
    if (!origenAdress.direccion2Origin.trim()) {
      errores.push("La numeración de direccion.");
    }
    if (!origenAdress.direccion3Origin.trim()) {
      errores.push("La numeración de oficina o departamento es obligatoria.");
    }
    if (!origenAdress.toAddressOrigin.trim()) {
      errores.push("La dirección de destino es obligatoria.");
    }
    if (!origenAdress.communeOrigin.trim()) {
      errores.push("La comuna de origen es obligatoria.");
    }
    if (!origenAdress.regionOrigin.trim()) {
      errores.push("La región de origen es obligatoria.");
    }
    if (!origenAdress.countryOrigin.trim()) {
      errores.push("El país de origen es obligatorio.");
    }

    // Procesar errores o éxito
    if (errores.length > 0) {
      // Aquí podrías establecer los errores en el estado para mostrarlos en la UI
      // Por ejemplo: setError(errores.join("\n"));
      console.error("Errores:", errores);
      setError(errores);
      return; // Detener la ejecución si hay errores
    } else {
      setSuccess("Dirección guardada con éxito.");

      if (showEdit === true && isEditNew === true) {
        guardarDireccionUsuario();
        console.log("Es Nuevo");
      }

      if (showEdit === true && isEditNew === false) {
        guardarEdicionDireccionUsuario();
        console.log("Es Edicion");
      }
      console.log("Dirección a guardar:", origenAdress);
      console.log(isEditNew);
      console.log(modoEdicion);
      setError([]);
    }
  };

  const renderAdresses = async (companyUid) => {
    //console.log(userUid)
    try {
      const adressses = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(companyUid)
        .collection("direcciones")
        .get();
      //console.log(adressses.docs)
      const directions = adressses.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(directions);
      // setMarkers(directions);
      // setDirections(directions);
      // setNewSelected(null);
    } catch (error) {
      //console.log(error)
    }
  };

  const guardarEdicionDireccionUsuario = React.useCallback(async () => {
    dispatch(updatePointADireccionForCompany(origenAdress));
    // Limpiar
    setError(null);
    setSuccess(null);
    setWarning(null);
    setShowEdit(false);
    function delay() {
      dispatch(toggleShowEdit);
      setSuccess(null);
    }
    setTimeout(delay, 1000);

    findAdressesCompany(userDataContext.companyData.companyID);
    setEditNew(false);
    setModoEdicion(false);
  });

  const guardarDireccionUsuario = React.useCallback(async () => {
    setError(null);
    // Limpiar
    setError(null);
    setSuccess(null);
    setWarning(null);
    try {
      dispatch(crearPointADireccionForCompany(origenAdress));
      //console.log(data)
      function delay() {
        dispatch(toggleShowEdit);
        setSuccess(null);
      }
      setTimeout(delay, 1000);

      findAdressesCompany(userDataContext.companyData.companyID);
      setEditNew(false);
      setModoEdicion(false);
    } catch (error) {
      console.log(error);
    }
  });

  const limpiarFormulario = (e) => {
    e.preventDefault();
    setOrigenAdress((prevState) => ({
      ...prevState,
      adressName: "",
      clientNamesOrigin: "",
      clientRutOrigin: "",
      userPhoneOrigin: "",
      toAddressOrigin: "",
      direccion1Origin: "",
      direccion2Origin: "",
      direccion3Origin: "N/A",
      communeOrigin: "",
      commune2Origin: "",
      regionOrigin: "",
      countryOrigin: "",
      company: "",
      companyID: "",
      commerce: "",
      int_warehouseName: "",
      int_warehouseCode: "",
      int_placeName: "",
      description: "",
      zonePoligon: "",
    }));
    setError([]);
    setSuccess(null);
    setWarning(null);
    return;
  };

  const handleChangeDirection3 = (e) => {
    console.log(e);
    const value = e.target.checked ? "" : "N/A";
    setIsDepartamento(e.target.checked);
    setOrigenAdress((prevState) => ({
      ...prevState,
      direccion3Origin: value,
    }));
  };

  const handleSelections = (item) => {
    console.log(item);
    setOrigenAdress((prevState) => ({
      ...prevState,
      toAddressOrigin: item.address,
      addressGeoreft: item.addressGeoreft,
      direccion1Origin: item.direccion1,
      direccion2Origin: item.direccion2,
      communeOrigin: item.commune,
      commune2Origin: item.locality,
      regionOrigin: item.region,
      countryOrigin: item.country,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrigenAdress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <Grid
      celled="internally"
      item
      sx={{
        padding: "20px",
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
        },
      }}
    >
      <h2 className="h2-withe">Sucursales</h2>
      {errorCritical ? (
        <span className="alert alert-danger">{errorCritical}</span>
      ) : null}
      <Grid
        container
        sx={{
          padding: "20px",
          backgroundColor:
            "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
        }}
      >
        <Grid
          item
          sx={{
            padding: "20px",
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
            },
          }}
        >
          <Card
            item
            sx={{
              padding: "20px",
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
              height: "auto",
            }}
          >
            <CardHeader
              subheader={
                modoEdicion ? (
                  <div>
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "flex-center",
                        m: 1,
                      }}
                      color="error"
                      variant="contained"
                      onClick={cacelarEdicion}
                    >
                      <CancelIcon></CancelIcon>
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      sx={{
                        backgroundColor: "#591E8F",
                        border: "none",
                        borderRadius: "3px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "30px",
                        outline: "none",
                        padding: "5px",
                        minWidth: "50px",
                        fontWeight: 400,
                        margin: "0em 1em 0em 0em",
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                      variant="contained"
                      onClick={toggleShowEditNew}
                    >
                      <AddCircleIcon />
                      Nueva
                    </Button>
                  </div>
                )
              }
              title={
                modoEdicion ? (
                  <h3>Editando {itemediht.adressName}</h3>
                ) : (
                  <h3>Agregar Sucursal</h3>
                )
              }
            />
            <Divider />
            <FadeInOut show={showEdit} duration={500}>
              <Box id="micuentafromIni" sx={{ padding: 3 }}>
                <hr />
                {/* Mensajes de alerta */}
                {success && (
                  <Typography variant="body1" className="alert alert-success">
                    {success}
                  </Typography>
                )}
                {error.length > 0 && (
                  <div className="alert alert-danger">
                    {error.map((err, index) => (
                      <Typography key={index} variant="body1">
                        {err}
                      </Typography>
                    ))}
                  </div>
                )}
                {warning && (
                  <Typography variant="body1" className="alert alert-warning">
                    {warning}
                  </Typography>
                )}
                {resperror && (
                  <Typography variant="body1" className="alert alert-danger">
                    {resperror}
                  </Typography>
                )}
                <Box
                  component="form"
                  onSubmit={guardarDireccion}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Nombre"
                        placeholder="Nombre de Contacto"
                        name="clientNamesOrigin"
                        value={origenAdress.clientNamesOrigin}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UserIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Teléfono"
                        placeholder="Teléfono"
                        name="userPhoneOrigin"
                        value={origenAdress.userPhoneOrigin}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Nombre de sucursal"
                        placeholder="Nombre de sucursal"
                        variant="outlined"
                        name="adressName"
                        value={origenAdress.adressName}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Centro de Costo"
                        placeholder="Centro de Costo"
                        variant="outlined"
                        name="costCenter"
                        value={origenAdress.costCenter}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "33%",
                          sm: "33%",
                          md: "33%",
                          lg: "33%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Nombre referelcial (Opcional)"
                        placeholder="Nombre referelcial (Opcional)"
                        variant="outlined"
                        name="int_placeName"
                        value={origenAdress.int_placeName}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "33%",
                          sm: "33%",
                          md: "33%",
                          lg: "33%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Codígo de Bodega  (Opcional)"
                        placeholder="Codígo de Bodega  (Opcional)"
                        variant="outlined"
                        name="int_warehouseCode"
                        value={origenAdress.int_warehouseCode}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "33%",
                          sm: "33%",
                          md: "33%",
                          lg: "33%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Nombre de bodega  (Opcional)"
                        placeholder="Nombre de bodega  (Opcional)"
                        name="int_warehouseName"
                        value={origenAdress.int_warehouseName}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    {origenAdress.toAddressOrigin === "" ? (
                      <Grid
                        item
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          },
                        }}
                      >
                        <GooglePlacesAutocomplete
                          adress={origenAdress}
                          handleSelections={handleSelections}
                        ></GooglePlacesAutocomplete>
                      </Grid>
                    ) : (
                      <>
                        {" "}
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            placeholder="dirección"
                            variant="outlined"
                            name="toAddressOrigin"
                            value={origenAdress.toAddressOrigin}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            },
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              width: {
                                xs: "50%",
                                sm: "50%",
                                md: "50%",
                                lg: "50%",
                              },
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isDepartamento}
                                  onChange={handleChangeDirection3}
                                  name="seguro"
                                  color="primary"
                                />
                              }
                              label="Casa / Departamento"
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "40%",
                              sm: "40%",
                              md: "40%",
                              lg: "40%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Calle"
                            placeholder="Calle"
                            name="direccion1Origin"
                            value={origenAdress.direccion1Origin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MarkerIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "30%",
                              sm: "30%",
                              md: "30%",
                              lg: "30%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Número"
                            placeholder="Número"
                            name="direccion2Origin"
                            value={origenAdress.direccion2Origin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NumbersIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "30%",
                              sm: "30%",
                              md: "30%",
                              lg: "30%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Dpto"
                            placeholder="Dpto"
                            name="direccion3Origin"
                            value={origenAdress.direccion3Origin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NumbersIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "50%",
                              lg: "50%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Comuna"
                            placeholder="Comuna"
                            name="commune2Origin"
                            value={origenAdress.commune2Origin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HomeWorkIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "50%",
                              lg: "50%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Ciudad"
                            placeholder="Ciudad"
                            name="communeOrigin"
                            value={origenAdress.communeOrigin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MarkerIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "50%",
                              lg: "50%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Región"
                            placeholder="Región"
                            name="regionOrigin"
                            value={origenAdress.regionOrigin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MarkerIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "50%",
                              lg: "50%",
                            },
                          }}
                        >
                          <TextField
                            fullWidth
                            label="País"
                            placeholder="País"
                            name="countryOrigin"
                            value={origenAdress.countryOrigin}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <GlobeIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid
                      item
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Comentario (Opcional)"
                        placeholder="Comentario breve o referencia"
                        multiline
                        name={"adressOriginComent"}
                        rows={4}
                        onChange={(e) => handleChange(e)}
                        value={origenAdress.adressOriginComent}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#591E8F",
                        border: "none",
                        borderRadius: "3px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "30px",
                        outline: "none",
                        padding: "5px",
                        minWidth: "50px",
                        fontWeight: 400,
                        margin: "0em 1em 0em 0em",
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<CleaningServicesIcon />}
                      onClick={limpiarFormulario}
                      sx={{
                        backgroundColor: "#db2828",
                        border: "none",
                        borderRadius: "3px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "30px",
                        outline: "none",
                        padding: "5px",
                        minWidth: "50px",
                        fontWeight: 400,
                        margin: "0em 1em 0em 0em",
                        ":hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      Limpiar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </FadeInOut>
            <Divider />
          </Card>
        </Grid>

        <Grid
          item
          sx={{
            padding: "20px",
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
            },
          }}
        >
          <div>
            <GoogleMap
              id="mapControl"
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              center={center}
              options={options}
              //onClick={onMapClick}
              onLoad={onMapLoad}
            >
              {LoadMarket(
                selected,
                setSelected,
                setMarkers,
                newSelected,
                setNewSelected
              )}

              {markers && markers.length > 0 ? (
                <>
                  {" "}
                  {markers.map((marker, index) => {
                    if (marker.addressGeoreft) {
                      return (
                        <Marker
                          key={index}
                          position={{
                            lat: marker.addressGeoreft.lat,
                            lng: marker.addressGeoreft.lng,
                          }}
                          onClick={() => {
                            setSelected(marker);
                          }}
                          onLoad={(markerInstance) => {
                            const customIcon = {
                              url: "/static/images/casita.png", // URL de la imagen que quieres usar
                              scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                              anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                            };
                            markerInstance.setIcon(customIcon);
                          }}
                        />
                      );
                    }
                  })}
                </>
              ) : null}

              {selected ? (
                <InfoWindow
                  position={{
                    lat: selected.addressGeoreft.lat,
                    lng: selected.addressGeoreft.lng,
                  }}
                  onCloseClick={() => {
                    setSelected(null);
                  }}
                >
                  <div>
                    <h5>
                      <span role="img" aria-label="BICCI EXPRESS">
                        <img
                          className="markertsbransLogos"
                          alt=""
                          src={logoMarket}
                        />
                        {selected.companyPointName ? (
                          <>{selected.companyPointName}</>
                        ) : (
                          <>Nueva Sucursal</>
                        )}
                      </span>
                    </h5>
                    {selected.adressPhone ? (
                      <>
                        <div>
                          <a
                            className="nonstyle"
                            href={`tel:${selected.adressPhone}`}
                          >
                            Teléfono: {selected.adressPhone}
                          </a>
                        </div>
                        <div>
                          <a
                            className="nonstyle"
                            href={`https://api.whatsapp.com/send?phone=${selected.adressPhone}&text=Soporte%20BICCI%20:`}
                          >
                            WhatsApp: {selected.adressPhone}
                          </a>
                        </div>
                        <div>Nombre : {selected.companyPointName}</div>
                        <div>Dirección : {selected.adressAddress}</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </InfoWindow>
              ) : null}
            </GoogleMap>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Grid
          computer={15}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Box sx={{ display: "Flex" }}>
            {directions.map((item, index) => (
              // <Myaddresses data={item} key={item.id} />
              <div key={index}>
                <Card
                  sx={{
                    maxHeight: "150px",
                    height: "150px",
                    maxWidth: "320px",
                    width: "320px",
                    padding: "3px",
                    m: 1,
                  }}
                >
                  <CardHeader
                    subheader={item.toAddressOrigin}
                    title={item.adressName}
                    sx={{ padding: "3px", height: "60px" }}
                  />

                  <Divider />
                  {/* <Container className='formcontent' id="micuentafromIni">
        <span>Comentario</span>
        </Container> */}
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      p: 1,
                    }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "flex-center",
                        m: 1,
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "30px",
                        padding: "5px",
                        fontWeight: 400,
                        margin: "0em 1em 0em 0em",
                        borderRadius: "3px",
                      }}
                      color="error"
                      variant="contained"
                      onClick={() => eliminarDireccion(item.id)}
                    >
                      <DeleteForeverIcon></DeleteForeverIcon>
                    </Button>

                    {modoEdicion ? null : (
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          m: 1,
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                        }}
                        variant="contained"
                        onClick={() => editarDireccion(item)}
                      >
                        <EditIcon> </EditIcon>
                        Editar
                      </Button>
                    )}
                  </Box>
                </Card>
              </div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

function LoadMarket(
  selected,
  setSelected,
  setMarkers,
  newSelected,
  setNewSelected
) {
  return newSelected ? (
    <>
      <Marker
        onDragEnd={(e) => {
          markerBytext.lat = e.latLng.lat();
          markerBytext.lng = e.latLng.lng();
          var lat = e.latLng.lat();
          var lng = e.latLng.lng();
          //console.log(e.latLng)
          //console.log(e.latLng.lat())
          //console.log(e.latLng.lng())

          setNewSelected(markerBytext);
        }}
        draggable={true}
        position={{ lat: newSelected.lat, lng: newSelected.lng }}
        icon={{
          url: `https://images.vexels.com/media/users/3/128603/isolated/preview/c3baedd2e3624a2ed60434ab856cea3d-plantilla-de-venta-de-burbuja-roja.png`,
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(15, 15),
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        onClick={() => {
          setSelected({
            adressGeoNumAddressLat: markerBytext.lat,
            adressGeoNumAddressLng: markerBytext.lng,
          });
        }}
        //onChange={()=>{setSelected({ lat: markerBytext.lat, lng: markerBytext.lng });}}
      />
    </>
  ) : (
    <></>
  );
}

export default BranchOffc;
