import React, { useState } from "react";
import { Card, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { db } from "../../../../firebase";
import { doc, updateDoc, collection, getDocs } from "firebase/firestore";
import { updateOrdersBicciexpressPayments } from "../../../../redux/accionesOrders";
import { useDispatch } from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";

const ToolDashUpdatePayments = (props) => {
  const [mensajeLoadDataEstatusID, setMensajeLoadDataEstatusID] =
    useState(null); // Estado para el mensaje de error

  const [dataPayment, setDataPayment] = useState(null);
  const dispacth = useDispatch();

  const handleFileProforma = async (e) => {
    console.log("Aqui Prof");

    if (e.target.files.length > 0) {
      props.setAlertMensajes("seleccionado 1 Archivo");
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    //Validar Nombres
    try {
      const paymentProforma = jsonData[0]["PaymentProforma"];
      const paymentValor = jsonData[0]["PaymentValor"];
      const paymentTipoServicio = jsonData[0]["PaymentType_service"];
      const bicciId = jsonData[0]["BICCIID"];
      // PaymentProforma // PaymentValor // PaymentType_service // BICCIID
      if (paymentProforma && paymentValor && paymentTipoServicio && bicciId) {
        props.setAlertMensajes("un archivo seleccionado");
        setDataPayment(jsonData);
        props.setDocumentData(jsonData);
        props.setDataType("billing");
        props.setRender(!props.remder);
      } else {
        props.setAlertMensajes(
          <div>
            <div>
              Nonmbres clave requeridos revise el documento y ajustelo para
              procesarlo // PaymentProforma // PaymentValor //
              PaymentType_service // BICCIID
            </div>
            <div>
              PaymentProforma :{" "}
              {paymentProforma ? "Valido" : "No se encontro nombre"},
            </div>
            <div>
              PaymentValor: {paymentValor ? "Valido" : "No se encontro nombre"},
            </div>
            <div>
              PaymentType_service:{" "}
              {paymentTipoServicio ? "Valido" : "No se encontro nombre"},
            </div>
            <div>BICCIID: {bicciId ? "Valido" : "No se encontro nombre"},</div>
          </div>
        );

        // dispacth(updateOrdersBicciexpressPayments(jsonData));
      }

      // let arrayjsonData = Object.values(jsonData);
      // props.setDataPayment(arrayjsonData);
    } catch (error) {
      props.setAlertMensajes("No se encuentra la hoja con nombre DATA");
    }
  };

  const updatePaymentRegister = async (dataPayment) => {
    if (!dataPayment[0]) {
      props.setAlertMensajes("ERROR en nombre de columna BICCIID");
      props.setDataPayment(null);
      return;
    }

    if (dataPayment.length > 0 && dataPayment[0].BICCIID) {
      const CHUNK_SIZE = 1000; // Tamaño de cada trozo de datos
      const dataPaymentChunks = chunkArray(dataPayment, CHUNK_SIZE); // Dividir dataPayment en partes más pequeñas

      async function callAllData() {
        props.setLoadDataEstatus(true);
        props.setAlertMensajes(`Solicitando Data..`);
        const collectionRef = collection(db, process.env.REACT_APP_COL_ORDERS);
        const querySnapshot = await getDocs(collectionRef);

        const documents = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return documents;
      }

      async function callAllDataFalabella() {
        props.setAlertMensajes(`Solicitando Data Estatus..`);

        const collectionRef2 = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );
        const querySnapshot2 = await getDocs(collectionRef2);

        const documents2 = querySnapshot2.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return documents2;
      }

      const userOrdersData = await callAllData();

      const userDataOrdesFalabella = await callAllDataFalabella();

      const relationShips = {};

      for (let i = 0; i < dataPaymentChunks.length; i++) {
        const chunk = dataPaymentChunks[i];

        if (userOrdersData.length === 0) {
          props.setAlertMensajes("Error consulta userOrdersData");
        }
        props.setLoadDataEstatus(true);

        if (userDataOrdesFalabella.length === 0) {
          props.setAlertMensajes("Error consulta userDataOrdesFalabella");
        }

        for (let n = 0; n < userOrdersData.length; n++) {
          if (chunk.some((d) => d.BICCIID === userOrdersData[n].orderID)) {
            console.log("MACH ORDER");
            const filteredChunk = chunk.filter(
              (d) => d.BICCIID === userOrdersData[n].orderID
            );
            props.setAlertMensajes(
              `Actualizado datos de facturacion... ${userOrdersData[n].orderID}`
            );
            console.log(filteredChunk[0]);

            if (!filteredChunk[0].PaymentProforma) {
              console.log("ERROR en nombre de columna PaymentProforma");
            }
            if (!filteredChunk[0].PaymentType_service) {
              console.log("ERROR en nombre de columna PaymentType_service");
            }
            if (!filteredChunk[0].PaymentValor) {
              console.log("ERROR en nombre de columna PaymentValor");
            }
            if (
              filteredChunk[0].PaymentProforma &&
              filteredChunk[0].PaymentType_service &&
              filteredChunk[0].PaymentValor
            ) {
              props.setAlertMensajes(`Solicitando Data..`);
              relationShips[userOrdersData[n].orderID] = userOrdersData[n].key;
              await update(
                userOrdersData[n].key,
                filteredChunk[0].PaymentValor,
                filteredChunk[0].PaymentProforma,
                filteredChunk[0].PaymentType_service,
                userOrdersData[n].orderID,
                "Creado",
                userOrdersData[n].status,
                userOrdersData[n].driverName,
                userOrdersData[n].driverID,
                userOrdersData[n].createdAt
              );
              props.setAlertMensajes(
                `update Data.. ${userOrdersData[n].orderID}`
              );
            } else {
              props.setAlertMensajes(
                "ERROR verifique los nombres de columnas en documento PaymentProforma,PaymentType_service,PaymentValor"
              );
            }
            //props.setAlertMensajes(null);
          } else {
            //console.log("no macth");
          }
        }

        for (let n = 0; n < userDataOrdesFalabella.length; n++) {
          let orderID = `F${userDataOrdesFalabella[n].id}`;
          if (chunk.some((d) => d.BICCIID === orderID)) {
            console.log("MACH ORDER FAB");
            console.log(`Actualizado Estados de Orden... ${orderID}`);
            console.log(userDataOrdesFalabella[n].falabella_status);
            console.log(relationShips[orderID]);

            await updateStatusF(
              relationShips[orderID],
              userDataOrdesFalabella[n].falabella_status
            );
          }
        }

        props.setLoadDataEstatus(false);
      }

      function chunkArray(arr, chunkSize) {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
          chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
      }
    } else {
      props.setAlertMensajes("Error en archivo");
    }
  };

  async function update(
    id, // OrderID + sku
    PaymentValor, // columna Valor
    PaymentProforma, // ID Proforma
    PaymentType_service, // Tipo de Servicio
    orderID, // Onder BICCIID
    statusCreate,
    bicciStatus,
    driverName,
    driverID,
    createdAt
  ) {
    try {
      const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, id);
      await updateDoc(orderRef, {
        PaymentValor: PaymentValor,
        PaymentProforma: PaymentProforma,
        PaymentType_service: PaymentType_service,
        PaymentDate: Date.now(),
        isPayment: true,
        statusCreate: statusCreate,
        bicciStatus: bicciStatus,
        driverName: driverName,
        driverID: driverID,
        createdAt: createdAt,
      });
      setMensajeLoadDataEstatusID(true);
    } catch (error) {
      console.log("error no encontrado");
      console.log(error);
    }
  }

  async function updateStatusF(id, falabella_status) {
    try {
      const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, id);
      await updateDoc(orderRef, {
        falabella_status: falabella_status,
      });
    } catch (error) {
      console.log("error no encontrado");
      console.log(error);
    }
  }

  async function loadOrdesDataSdd(toolsData) {
    if (toolsData) {
      console.log("updateOrdersBicciexpressPayments");
      dispacth(updateOrdersBicciexpressPayments(toolsData));
    }
  }

  async function cancel() {
    setDataPayment(null);
    props.setRender(!props.remder);
  }
  async function pause() {
    setDataPayment([]);
    props.setRender(!props.remder);
  }

  return (
    <Card
      className="typeMiniformsBarsAccionsItemsMinimal"
      sx={{ borderRadius: "3px" }}
    >
      <div className="m-2">
        <div className="labelupload">
          <label htmlFor="file-upload1">
            Crear Registro Pagos - Formato Prof
          </label>
          <input
            id="file-upload1"
            name="file-upload1"
            type="file"
            onChange={(e) => handleFileProforma(e)}
          />
          <label htmlFor="file-upload1" className="choose-file">
            Proforma <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">Ningún archivo seleccionado.</div>
        </div>

        <div>
          {dataPayment ? (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadOrdesDataSdd(dataPayment)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon
                  className="btn-add-guide-small"
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => pause()}
                sx={{ color: "#591e8f" }}
              >
                <PauseIcon
                  className="btn-add-guide-small"
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => cancel()}
                sx={{ color: "#591e8f" }}
              >
                <CancelIcon
                  className="btn-add-guide-small-cancel "
                  sx={{ color: dataPayment ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
            </>
          ) : null}
        </div>

        <div>
          <span className="float-letf">{mensajeLoadDataEstatusID}</span>
        </div>
        <div>
          {props.LoadDataEstatus ? <LinearProgress></LinearProgress> : null}
        </div>
      </div>
    </Card>
  );
};

export default ToolDashUpdatePayments;
