import React from "react";
import { Container } from "semantic-ui-react";
import FrmSoporte from "../../../components/formularios/FrmSoporte";

const Soporte = (props) => {
  return (
    <div className="mt-5 text-center">
      <Container className="bodycontent">
        <h1>Soporte al cliente</h1>
        <div>
          <FrmSoporte></FrmSoporte>
        </div>
        <br></br>
      </Container>
    </div>
  );
};

export default Soporte;
