import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { Container } from "semantic-ui-react";
import Box from "@mui/material/Box";
import Moment from "moment";
import { db } from "../../../../firebase";
import { useParams } from "react-router-dom";
import useGetRoutesExpressById from "../../../../hooks/getRoutesExpressById";
import DragHandleIcon from "@mui/icons-material/DragHandle";
//import DashRoutesAddMap from './DashRoutesAddMap';
import FmdBadIcon from "@mui/icons-material/FmdBad";
import useObtenerDatosdeCliente from "../../../../hooks/obtenerDatosdeCliente";
import useGetDataDispachstGroup from "../../../../hooks/getDataDispachstGroup";

const DashPlannerAdd = (props) => {
  const { id: routeId } = useParams();
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const LocalRol = usuarioStorage.roll;
  const usuarioLocal = usuarioStorage.uid;

  const [companyId, setcompanyId] = useState("");

  const [ordersGroup, setOrdersGroup] = useState("");

  const [succesCreate, setSuccesCreate] = useState(false);

  const [storeDbRoute, setStoreDbRoute] = useState({
    durationValue: "",
    destination: { lat: -33.4592491, lng: -70.6010891 },
    routePoints: "",
    currentOrderList: "",
    dispacthPartials: "",
    date: "",
    wayPoints: "",
    createBy: "",
    distanceValue: "",
    travelMode: "",
    dateDeliveryText: "",
    companyId: "",
    driverName: "",
    dispacthDelyvery: "",
    fullCompliance: "",
    origin: { lat: -33.4592491, lng: -70.6010891 },
    optimizeWaypoints: "",
    vehicleID: "",
    waypoint_order: "",
    isDelete: "",
    dateDelivery: "",
    createDate: "",
    warnings: "",
    endTimeDelivery: "",
    id: "",
    dispacthNotDelyvery: "",
    driverID: "",
    startTimeDelivery: "",
    maxTimeDelivery: "",
    isActive: "",
    //setAllCharge(true)
  });

  const [newRoute, setNewRoute] = useState({
    durationValue: "",
    destination: { lat: -33.4592491, lng: -70.6010891 },
    routePoints: "",
    currentOrderList: "",
    dispacthPartials: "",
    date: "",
    wayPoints: "",
    createBy: "",
    distanceValue: "",
    travelMode: "",
    dateDeliveryText: "",
    companyId: "",
    driverName: "",
    dispacthDelyvery: "",
    fullCompliance: "",
    origin: { lat: -33.4592491, lng: -70.6010891 },
    optimizeWaypoints: "",
    vehicleID: "",
    waypoint_order: "",
    isDelete: "",
    dateDelivery: "",
    createDate: "",
    warnings: "",
    endTimeDelivery: "",
    id: "",
    dispacthNotDelyvery: "",
    driverID: "",
    startTimeDelivery: "",
    maxTimeDelivery: "",
    isActive: "",
    //setAllCharge(true)
  });

  const [editedBicciOrderExpressGroup, setEditableBicciOrderExpressGroup] =
    useState("");

  const [saveCurrentOrderList, setSaveCurrentOrderList] = useState("");

  const [allCharge, setAllCharge] = useState(false);
  const [routeLegs, setlegs] = useState("");

  const [visibleMaps, setVisibleMaps] = useState(false);

  // Data maps Api
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [travelMode, setTravelMode] = useState("");
  const [optimizeWaypoints, setOptimizeWaypoints] = useState(true);

  const [wayPoints, setWayPoints] = useState("");

  const [mapsType, setMapsType] = useState("storage");
  // handleresposeapi
  const [targetedOrdersRows, setTargetedOrdersRows] = useState("");

  const [targetedOrdersCancel, setTargetedOrdersCancel] = useState("");

  const [targetedOrdersDelivered, setTargetedOrdersDelivered] = useState("");

  const [markerpoint, setMarkerpoint] = useState("");

  const dragItem = useRef();

  const dragOverItem = useRef();

  var responseApiGoogle;

  const { userdata } = useObtenerDatosdeCliente(usuarioLocal);

  const { bicciRoutesExpressByID } = useGetRoutesExpressById(
    companyId,
    LocalRol,
    routeId
  );

  // const { bicciOrderExpressGroup, loadOrderExpressGroup} = useGetDataDispachstGroupByDriver(companyId,LocalRol,ordersGroup)

  let dataallOrderID = null;

  const { bicciOrderExpressGroup, loadOrderExpressGroup } =
    useGetDataDispachstGroup(dataallOrderID, LocalRol, ordersGroup);

  useEffect(() => {
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [userdata]);

  useEffect(() => {
    if (bicciRoutesExpressByID) {
      console.log(bicciRoutesExpressByID); // ruta almacenada
      console.log(bicciRoutesExpressByID.currentOrderList);
      setOrdersGroup(bicciRoutesExpressByID.currentOrderList);
      setStoreDbRoute(bicciRoutesExpressByID);
      setNewRoute(bicciRoutesExpressByID);
    }
  }, [bicciRoutesExpressByID]);

  useEffect(() => {
    if (bicciOrderExpressGroup) {
      //console.log(bicciOrderExpressGroup) // AQUI
      setEditableBicciOrderExpressGroup(bicciOrderExpressGroup);
      setAllCharge(true);
    }
  }, [bicciOrderExpressGroup]);

  useEffect(() => {
    //console.log(allCharge)
    if (allCharge === true) {
      //   console.log(storeDbRoute)

      UpdateArrayOrders();
    }
  }, [allCharge]);

  const validateChange = () => {
    //if(newRoute === storeDbRoute){console.log('COMENTALPHA sin cambios en RUTAS')}else{console.log('COMENTALPHA CAMBIOS SIN GUARDAR')}
  };
  /**
   * FILTRAR ORDENES PARA ASIGNACION INICIAL
   */
  const UpdateArrayOrders = () => {
    if (bicciOrderExpressGroup) {
      let opsList = [];
      var targetedOrdersRowsOnRoute = [];
      var targetedOrdersRowsComplete = [];
      var targetedOrdersRowsCancel = [];

      function getestimatedDeliveryTime(i) {
        //console.log(bicciRoutesExpressByID.legs[i])
        if (bicciRoutesExpressByID.legs) {
          try {
            return bicciRoutesExpressByID.legs[i].estimatedDeliveryTime;
          } catch (error) {
            return "no time";
          }
        }

        if (routeLegs) {
          try {
            return routeLegs[i].estimatedDeliveryTime;
          } catch (error) {
            return "no time";
          }
        }
      }

      for (let i = 0; i < bicciOrderExpressGroup.length; i++) {
        if (bicciOrderExpressGroup[i].status < 0) {
          targetedOrdersRowsCancel.push({
            id: bicciOrderExpressGroup[i].id,
            toAddress: bicciOrderExpressGroup[i].toAddress,
            toAddressLocation: bicciOrderExpressGroup[i].toAddressLocation,
            userName: bicciOrderExpressGroup[i].userName,
            userPhone: bicciOrderExpressGroup[i].userPhone,
            status: bicciOrderExpressGroup[i].status,
            express: bicciOrderExpressGroup[i].express,
          });
        }
        if (
          bicciOrderExpressGroup[i].status >= 0 &&
          bicciOrderExpressGroup[i].status <= 7
        ) {
          targetedOrdersRowsOnRoute.push({
            id: bicciOrderExpressGroup[i].id,
            toAddress: bicciOrderExpressGroup[i].toAddress,
            toAddressLocation: bicciOrderExpressGroup[i].toAddressLocation,
            userName: bicciOrderExpressGroup[i].userName,
            userPhone: bicciOrderExpressGroup[i].userPhone,
            status: bicciOrderExpressGroup[i].status,
            express: bicciOrderExpressGroup[i].express,
            estimatedDeliveryTime: getestimatedDeliveryTime(i),
          });
        }
        if (bicciOrderExpressGroup[i].status >= 8) {
          targetedOrdersRowsComplete.push({
            id: bicciOrderExpressGroup[i].id,
            toAddress: bicciOrderExpressGroup[i].toAddress,
            toAddressLocation: bicciOrderExpressGroup[i].toAddressLocation,
            userName: bicciOrderExpressGroup[i].userName,
            userPhone: bicciOrderExpressGroup[i].userPhone,
            status: bicciOrderExpressGroup[i].status,
            express: bicciOrderExpressGroup[i].express,
          });
        }
      }

      for (let i = 0; i < targetedOrdersRowsOnRoute.length; i++) {
        opsList.push(targetedOrdersRowsOnRoute[i].id);
      }

      for (let i = 0; i < targetedOrdersRowsComplete.length; i++) {
        opsList.push(targetedOrdersRowsComplete[i].id);
      }

      for (let i = 0; i < targetedOrdersRowsCancel.length; i++) {
        opsList.push(targetedOrdersRowsCancel[i].id);
      }

      setSaveCurrentOrderList(opsList);
      // console.log(opsList)
      newRoute.routePoints = opsList.length;
      let obj = Object.assign({}, opsList);
      // console.log(obj)
      setSaveCurrentOrderList(obj);

      setTargetedOrdersRows(targetedOrdersRowsOnRoute);
      setTargetedOrdersCancel(targetedOrdersRowsCancel);
      setTargetedOrdersDelivered(targetedOrdersRowsComplete);
      UpdateMapWaitPoints(targetedOrdersRowsOnRoute);

      // console.log(newRoute)
    }
  };

  const UpdateMapWaitPoints = (targetedOrdersRows) => {
    if (targetedOrdersRows) {
      console.log("ACTUALIZAR MAPA");
      console.log(targetedOrdersRows);
      console.log(bicciOrderExpressGroup);
      console.log(editedBicciOrderExpressGroup);
      let tempWayPoints = [];
      for (let i = 0; i < targetedOrdersRows.length; i++) {
        var location = new Object();
        location.lng = parseFloat(
          targetedOrdersRows[i].toAddressLocation.longitude
        );
        location.lat = parseFloat(
          targetedOrdersRows[i].toAddressLocation.latitude
        );
        let point = {
          location: location,
          stopover: true,
        };
        tempWayPoints.push(point);
      }
      setWayPoints(tempWayPoints);
      setNewRoute({ ...newRoute, wayPoints: tempWayPoints });
      console.log(tempWayPoints);
      UpdateArrayOrdersEdit(targetedOrdersRows);
      //updateMaps()
    } else console.log("SIN OBJETIVOS");
  };

  /**
  * PROCESAR OBJETO NEW ROUTE MAPA
  * currentOrderList
  * wayPoints
  * waypoint_order
  * optimizeWaypoints
  * setRoutePoints
  * routePoints - setRoutePoints
  * 
        setSaveCurrentOrderList(opsList)
        setTargetedOrdersRows(targetedOrdersRowsOnRoute)
        setTargetedOrdersCancel(targetedOrdersRowsCancel)
        setTargetedOrdersDelivered(targetedOrdersRowsComplete)
        UpdateMapWaitPoints(targetedOrdersRowsOnRoute)

        setWayPoints(tempWayPoints)
  */

  const UpdateArrayOrdersEdit = (targetedOrdersRows) => {
    validateChange();
    // console.log(newRoute)
    // console.log(targetedOrdersRows)
    // console.log(editedBicciOrderExpressGroup)

    if (targetedOrdersRows) {
      let opsListupdate = [];

      var targetedOrdersRowsOnRouteUpdate = [];
      var targetedOrdersRowsCompleteUpdate = [];
      var targetedOrdersRowsCancelUpdate = [];

      function getestimatedDeliveryTime(i) {
        if (bicciRoutesExpressByID.legs) {
          try {
            return bicciRoutesExpressByID.legs[i].estimatedDeliveryTime;
          } catch (error) {
            return "no time";
          }
        }

        if (routeLegs) {
          try {
            return routeLegs[i].estimatedDeliveryTime;
          } catch (error) {
            return "no time";
          }
        }
      }

      for (let i = 0; i < targetedOrdersRows.length; i++) {
        if (targetedOrdersRows[i].status < 0) {
          targetedOrdersRowsCancelUpdate.push({
            id: targetedOrdersRows[i].id,
            toAddress: targetedOrdersRows[i].toAddress,
            toAddressLocation: targetedOrdersRows[i].toAddressLocation,
            userName: targetedOrdersRows[i].userName,
            userPhone: targetedOrdersRows[i].userPhone,
            status: targetedOrdersRows[i].status,
            express: targetedOrdersRows[i].express,
          });
        }
        if (
          targetedOrdersRows[i].status >= 0 &&
          targetedOrdersRows[i].status <= 7
        ) {
          targetedOrdersRowsOnRouteUpdate.push({
            id: targetedOrdersRows[i].id,
            toAddress: targetedOrdersRows[i].toAddress,
            toAddressLocation: targetedOrdersRows[i].toAddressLocation,
            userName: targetedOrdersRows[i].userName,
            userPhone: targetedOrdersRows[i].userPhone,
            status: targetedOrdersRows[i].status,
            express: targetedOrdersRows[i].express,
            estimatedDeliveryTime: getestimatedDeliveryTime(i),
          });
        }
        if (targetedOrdersRows[i].status >= 8) {
          targetedOrdersRowsCompleteUpdate.push({
            id: targetedOrdersRows[i].id,
            toAddress: targetedOrdersRows[i].toAddress,
            toAddressLocation: targetedOrdersRows[i].toAddressLocation,
            userName: targetedOrdersRows[i].userName,
            userPhone: targetedOrdersRows[i].userPhone,
            status: targetedOrdersRows[i].status,
            express: targetedOrdersRows[i].express,
          });
        }
      }

      for (let i = 0; i < targetedOrdersRowsOnRouteUpdate.length; i++) {
        opsListupdate.push(targetedOrdersRowsOnRouteUpdate[i].id);
      }
      for (let i = 0; i < targetedOrdersRowsCompleteUpdate.length; i++) {
        opsListupdate.push(targetedOrdersRowsCompleteUpdate[i].id);
      }
      for (let i = 0; i < targetedOrdersRowsCancelUpdate.length; i++) {
        opsListupdate.push(targetedOrdersRowsCancelUpdate[i].id);
      }

      setSaveCurrentOrderList(opsListupdate);
      // console.log(opsListupdate)
      let obj = Object.assign({}, opsListupdate);
      // console.log(obj)

      // setNewRoute({ ...newRoute, waypoint_order:tempWayPoints})
    }
  };

  const handleVisibleMaps = () => {
    setVisibleMaps(!visibleMaps);
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...targetedOrdersRows];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setTargetedOrdersRows(copyListItems);
    UpdateMapWaitPoints(copyListItems);
    setOptimizeWaypoints(false);
  };

  const changeIndex = (e, index) => {
    dragItem.current = index;
    dragOverItem.current = e.target.value;
    drop(e.currentTarget.parentNode);
  };

  const handleresposeapi = (response) => {
    if (response.routes[0].legs && response.routes[0].legs.length > 0) {
      let inputsDate = [];
      let input;
      var estimateTemp;
      var estimateTotal = 0;
      var maxTimeDeliveryAwait = newRoute.maxTimeDelivery.split(":");
      inputsDate = storeDbRoute.startTimeDelivery.toString();
      input = inputsDate.split(":");
      let date = newRoute.dateDeliveryText.split("-");
      let mes = parseInt(date[1]) - 1;
      let dia = parseInt(date[0]) - 1;
      var startTime = new Date(date[2], mes, dia, 0, 0, 0);
      var distanceMtrsValue = 0;
      var distanceSegDuration = 0;
      startTime.setMinutes(parseInt(input[0]) * 60 + parseInt(input[1]));
      function getTimeSecons(duration) {
        console.log("duration.value : " + duration.value);
        estimateTotal += parseInt(duration.value / 60);
        console.log("estimateTotal : " + estimateTotal);
        estimateTemp = parseInt(estimateTotal + maxTimeDeliveryAwait[1]);
        console.log(" estimateTemp : " + estimateTemp / 60);
        let estimatedDeliveryTime = startTime.setMinutes(estimateTemp / 60);
        console.log(
          Moment(estimatedDeliveryTime).format("DD MMMM YYYY, HH:mm")
        );

        return Moment(estimatedDeliveryTime).format("DD MMMM YYYY, HH:mm");
      }

      responseApiGoogle = response.routes[0].legs.map((goLegs) => ({
        distance: goLegs.distance,
        duration: goLegs.duration,
        estimatedDeliveryTime: getTimeSecons(goLegs.duration),
        start_address: goLegs.start_address,
        start_location: goLegs.start_location.toJSON(),
        end_address: goLegs.end_address,
        end_location: goLegs.end_location.toJSON(),
      }));

      for (let i = 0; i < response.routes[0].legs.length; i++) {
        distanceMtrsValue += response.routes[0].legs[i].distance.value;
        distanceSegDuration += response.routes[0].legs[i].duration.value;
      }
      newRoute.distanceValue = distanceMtrsValue / 1000;
      newRoute.durationValue = distanceSegDuration / 60;
      function delay2() {
        newRoute.waypoint_order = response.routes[0].waypoint_order;
        setlegs(responseApiGoogle);
      }
      setTimeout(delay2, 3000);
    }
  };

  const updateMaps = () => {
    // if(mapsType === 'storage'){
    //   return  <DashRoutesAddMap
    //   origin={storeDbRoute.origin}
    //   destination={storeDbRoute.destination}
    //   travelmodel={storeDbRoute.travelMode}
    //   optimizewaypoints={optimizeWaypoints}
    //   waypoints={wayPoints}
    //   handleresposeapi={handleresposeapi}
    //   targetedordersrows={targetedOrdersRows}
    //   targetedorderscancel={targetedOrdersCancel}
    //   targetedordersdelivered={targetedOrdersDelivered}
    //   markerpoint={markerpoint}// entregada fuera de nueva ruta
    //   />
    // }
    // if(mapsType === 'modified'){
    //   return  <DashRoutesAddMap
    //   origin={newRoute.origin}
    //   destination={newRoute.destination}
    //   travelmodel={newRoute.travelMode}
    //   optimizewaypoints={optimizeWaypoints}
    //   waypoints={wayPoints}
    //   handleresposeapi={handleresposeapi}
    //   targetedordersrows={targetedOrdersRows}
    //   targetedorderscancel={targetedOrdersCancel}
    //   targetedordersdelivered={targetedOrdersDelivered}
    //   markerpoint={markerpoint}// entregada fuera de nueva ruta
    //   />
    // }
  };

  const handleOptimize = () => {
    UpdateArrayOrders();
    setOptimizeWaypoints(true);
    setSuccesCreate(null);
    updateMaps();
  };

  const handleRemoveItem = (e, index) => {
    e.preventDefault();
    UpdateArrayOrders();
    setSuccesCreate(null);
    updateMaps();
  };

  const handleClickPeriscope = (e, item) => {
    // setmarkerpoint(item)
    setVisibleMaps(null);
    function delay() {
      setVisibleMaps(true);
    }
    setTimeout(delay, 500);
  };

  const saveNewRoute = async () => {
    var saveData = {
      createBy: newRoute.createBy,
      createDate: newRoute.createDate,
      date: newRoute.date,
      id: newRoute.id,
      vehicleID: newRoute.vehicleID,
      driverID: newRoute.driverID,
      driverName: newRoute.driverName,
      companyId: newRoute.companyId,
      currentOrderList: saveCurrentOrderList,
      origin: newRoute.origin,
      destination: newRoute.destination,
      optimizeWaypoints: optimizeWaypoints,
      wayPoints: newRoute.wayPoints,
      waypoint_order: newRoute.waypoint_order,
      maxTimeDelivery: newRoute.maxTimeDelivery,
      dateDeliveryText: newRoute.dateDeliveryText,
      dateDelivery: newRoute.dateDelivery,
      startTimeDelivery: newRoute.startTimeDelivery,
      endTimeDelivery: newRoute.endTimeDelivery,
      distanceValue: newRoute.distanceValue,
      durationValue: newRoute.durationValue,
      routePoints: newRoute.routePoints,
      dispacthDelyvery: newRoute.dispacthDelyvery,
      dispacthPartials: newRoute.dispacthPartials,
      dispacthNotDelyvery: newRoute.dispacthNotDelyvery,
      fullCompliance: newRoute.fullCompliance,
      warnings: newRoute.warnings,
      isDelete: newRoute.isDelete,
      isActive: newRoute.isActive,
      lastModify: Date.now().toString(),
      legs: routeLegs,
      routeStates: 105,
    };

    console.log(saveData);
    await db
      .collection(`${process.env.REACT_APP_COL_ROUTES}`)
      .doc(saveData.id)
      .update(saveData);
    //histoy
    await db
      .collection(`${process.env.REACT_APP_COL_ROUTES}`)
      .doc(saveData.id)
      .collection("history")
      .doc(Date.now().toString())
      .set(saveData);

    setSuccesCreate("Nueva Ruta Optima Guardada");
  };

  return !loadOrderExpressGroup ? (
    <div>
      <div className="topflex">
        <div className="dashroutesadd_bar">
          <div className="dashroutesadd_bar_top">
            <div className="dashroutesadd_bar_top_left">
              <span>{`Planeacin > Ordenes Estimadas`}</span>
            </div>
            <div className="dashroutesadd_bar_top_right">
              {succesCreate ? (
                <div>
                  <div className="alert alert-success center">
                    {succesCreate}{" "}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="dashroutesadd_bar_right">
          <button className="btn btn-ebiex m-1">Agregar Guia</button>{" "}
          <button className="btn btn-ebiex m-1"> Editar Ruta</button>
        </div>
      </div>
      <div className="topflex">
        <div className="dashroutesadd_bar">
          <div className="dashroutesadd_bar_top">
            <div className="dashroutesadd_bar_top_left">
              <span className="route-name">{` VEHICULO3 `}</span>
            </div>
            <div className="dashroutesadd_bar_top_right">
              {bicciOrderExpressGroup ? (
                <span className="min-w-space-flag">
                  <i className="flag icon color-success"></i> -{" "}
                  {bicciOrderExpressGroup.length} Asignadas
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="dashroutesadd_bar_donw">
            <div className="dashroutesadd_bar_donw_left">
              <span>{` Adrian Medina `}</span> <br></br>
              <span>{` Ruta# ${routeId}`}</span>
            </div>
            <div className="dashroutesadd_bar_donw_right-alt">
              {targetedOrdersDelivered ? (
                <span className="min-w-space-flag">
                  <i className="flag checkered icon color-solid"></i> -{" "}
                  {targetedOrdersDelivered.length} Entregadas
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="dashroutesadd_bar_donw_right">
          <button onClick={handleOptimize}> Reordenar Ruta</button>
        </div>
      </div>
      <Container className="containerroutesdispachst">
        <div className="bar-topflex">
          <div className="bar-topflex-left"></div>
          <div className="bar-topflex-rigth">
            {visibleMaps ? (
              <button className="btn-maps-routes" onClick={handleVisibleMaps}>
                <i className="map icon font-size-big color-solid"></i>
              </button>
            ) : (
              <button
                className="btn-maps-routes-active"
                onClick={handleVisibleMaps}
              >
                <i className="map icon font-size-big color-solid"></i>
              </button>
            )}

            <button className="btn-routes-save" onClick={saveNewRoute}>
              Guardar Cambios
            </button>
          </div>
        </div>

        <div className="container-routes">
          <div className="container-routes-left">
            <Box
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fafafa",
                padding: "3px",
              }}
            >
              {targetedOrdersRows.length > 0 ? <h3>En Ruta</h3> : <></>}

              {targetedOrdersRows ? (
                <>
                  {targetedOrdersRows.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="dispachtelement"
                      draggable
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                    >
                      <span className="whight15">
                        <input
                          className="mininput"
                          type="number"
                          label=""
                          placeholder=""
                          onChange={(e) => changeIndex(e, index)}
                          value={index}
                        ></input>
                      </span>

                      <span className="whight65">{`${item.id}`}</span>

                      <button
                        className="btn-bicci-small btn-globalhover-calipso btn-floatright whight15"
                        variant="contained"
                        color="primary"
                        size="small"
                        id={targetedOrdersRows.id}
                        style={{
                          marginLeft: 1,
                          margin: "3px",
                          padding: "5px",
                          background: "#f8f8f8",
                          color: "#e0557a",
                          borderRadius: "50%",
                        }}
                        // onClick={(e)=>handleRemoveItem(e, index)}
                      >
                        <i className="trash alternate outline icon "></i>
                      </button>

                      <button
                        className="btn-bicci-small btn-globalhover-calipso btn-floatright whight15>"
                        variant="contained"
                        id={targetedOrdersRows.id}
                        style={{
                          marginLeft: 1,
                          margin: "3px",
                          padding: "5px",
                          background: "#f8f8f8",
                          color: "#7d0ee6",
                          borderRadius: "50%",
                        }}
                        onClick={(e) => handleClickPeriscope(e, item)}
                      >
                        <i className="periscope icon "></i>
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Box>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fafafa",
                padding: "3px",
              }}
            >
              {targetedOrdersDelivered.length > 0 ? (
                <h3>Finalizadas</h3>
              ) : (
                <></>
              )}

              {targetedOrdersDelivered ? (
                <>
                  {targetedOrdersDelivered.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="dispachtelement"
                    >
                      <span className="whight10">
                        <i className="flag checkered icon color-solid "></i>
                      </span>

                      <span className="whight30">{`${item.id}`}</span>
                      <span className="dispachtItem dispachtItemStatusSuccess ">{`Entregada `}</span>
                      <button
                        className="btn-bicci-small btn-globalhover-calipsobtn-floatright whight10"
                        variant="contained"
                        id={targetedOrdersDelivered.id}
                        style={{
                          marginLeft: 1,
                          margin: "3px",
                          padding: "5px",
                          background: "#f8f8f8",
                          color: "#7d0ee6",
                          borderRadius: "50%",
                        }}
                        onClick={(e) => handleClickPeriscope(e, item)}
                      >
                        <i className="periscope icon "></i>
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fafafa",
                padding: "3px",
              }}
            >
              {targetedOrdersCancel.length > 0 ? <h3>No Entregadas</h3> : <></>}

              {targetedOrdersCancel ? (
                <>
                  {targetedOrdersCancel.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="dispachtelement"
                    >
                      <span>
                        <FmdBadIcon className="FmdBadIconIsuress " />
                      </span>

                      <span>{`${item.id}`}</span>
                      <span className="dispachtItem dispachtItemStatusIsuress ">{`Incidencia `}</span>
                      <button
                        className="btn-bicci-small btn-globalhover-calipsobtn-floatright "
                        variant="contained"
                        id={targetedOrdersCancel.id}
                        style={{
                          marginLeft: 1,
                          margin: "3px",
                          padding: "5px",
                          background: "#f8f8f8",
                          color: "#7d0ee6",
                          borderRadius: "50%",
                        }}
                        onClick={(e) => handleClickPeriscope(e, item)}
                      >
                        <i className="periscope icon "></i>
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Box>
          </div>

          <div className="container-routes-right">
            {visibleMaps ? (
              <>
                <div className="container-routes-right-alt">
                  {wayPoints ? updateMaps() : <></>}
                </div>
              </>
            ) : (
              <>
                <div className="container-routes-right-alt">
                  <Box
                    sx={{
                      height: 100,
                      width: "100%",
                      backgroundColor: "#fafafa",
                      padding: "3px",
                    }}
                  >
                    <h2 className="subtitledispatches">Ordenes de ruta</h2>
                    <div className="dispachtelemenTitle">
                      <div className="dispachtItem ">{`Estatus Actual`}</div>
                      <div className="dispachtItem">Posición</div>
                      <div className="dispachtItem ">{`ID`}</div>
                      <div className="dispachtItem-name">
                        <div>{`Nombre `}</div>
                        <div>{`Telefono `}</div>
                      </div>
                      <div className="dispachtItem"></div>
                      <div className="dispachtItem">{`Destino `}</div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                      backgroundColor: "#fafafa",
                      padding: "3px",
                      overflowY: "scroll",
                    }}
                  >
                    {targetedOrdersRows.length > 0 ? (
                      <h3 className="subtitledispatches">
                        Pendientes por entregar
                      </h3>
                    ) : (
                      <></>
                    )}
                    {targetedOrdersRows ? (
                      <>
                        {targetedOrdersRows.map((item, index) => (
                          <div
                            key={index}
                            value={item.id}
                            className="dispachtelementrow"
                            draggable
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                          >
                            <div>
                              <div className="dispachtItem">
                                <DragHandleIcon></DragHandleIcon>{" "}
                              </div>

                              <div className="dispachtItem dispachtItemStatus">{`En trancito `}</div>

                              <div className="dispachtItem">
                                <input
                                  className="mininput"
                                  type="number"
                                  label=""
                                  placeholder=""
                                  onChange={(e) => changeIndex(e, index)}
                                  value={index}
                                ></input>
                              </div>

                              <div className="dispachtItem badge-info dispachtItem-id">{`${item.id}`}</div>
                              <div className="dispachtItem-name">
                                <div className="dispachtitemspan-name">{`${item.userName} `}</div>
                                <div className="dispachtitemspan-phone ">{`${item.userPhone} `}</div>
                              </div>

                              <div className="dispachtItem">{`${item.estimatedDeliveryTime} Hrs `}</div>

                              <div className="dispachtItem-addres">{`${item.toAddress} `}</div>

                              <div className="dispachtItem-actions">
                                <div className="btn-bicci-small btn-globalhover-calipso  ">
                                  {item.express ? (
                                    <span className="badge-success">
                                      Express
                                    </span>
                                  ) : (
                                    <>
                                      <i className="flag icon color-success padding5"></i>
                                    </>
                                  )}
                                </div>

                                <button
                                  className="btn-bicci-small btn-globalhover-calipso "
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  id={targetedOrdersRows.id}
                                  style={{
                                    marginLeft: 1,
                                    margin: "3px",
                                    padding: "5px",
                                    background: "#f8f8f8",
                                    color: "#e0557a",
                                    borderRadius: "50%",
                                  }}
                                  onClick={(e) => handleRemoveItem(e, index)}
                                >
                                  <i className="trash alternate outline icon "></i>
                                </button>

                                <button
                                  className="btn-bicci-small btn-globalhover-calipso "
                                  variant="contained"
                                  id={targetedOrdersRows.id}
                                  style={{
                                    marginLeft: 1,
                                    margin: "3px",
                                    padding: "5px",
                                    background: "#f8f8f8",
                                    color: "#7d0ee6",
                                    borderRadius: "50%",
                                  }}
                                  onClick={(e) => handleClickPeriscope(e, item)}
                                >
                                  <i className="periscope icon "></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#fafafa",
                      padding: "3px",
                    }}
                  >
                    {targetedOrdersDelivered.length > 0 ? (
                      <h3 className="subtitledispatches">Finalizadas</h3>
                    ) : (
                      <></>
                    )}

                    {targetedOrdersDelivered ? (
                      <>
                        {targetedOrdersDelivered.map((item, index) => (
                          <div
                            key={index}
                            value={item.id}
                            className="dispachtelementrow"
                          >
                            <span>{` `}</span>
                            <i className="flag checkered icon color-solid"></i>
                            <span className="dispachtItem dispachtItemStatusSuccess">{`Entregada `}</span>

                            <span className="dispachtItem"></span>

                            <div className="dispachtItem badge-info dispachtItem-id">{`${item.id}`}</div>
                            <div className="dispachtItem-name">
                              <div className="dispachtitemspan-name">{`${item.userName} `}</div>
                              <div className="dispachtitemspan-phone ">{`${item.userPhone} `}</div>
                            </div>
                            <div className="dispachtItem"></div>
                            <div className="dispachtItem">{`${item.toAddress} `}</div>
                            <div className="btn-bicci-small btn-globalhover-calipso  btn-floatright ">
                              {item.express ? (
                                <span className="badge-success">Express</span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <button
                              className="btn-bicci-small btn-globalhover-calipso  btn-floatright "
                              variant="contained"
                              id={targetedOrdersDelivered.id}
                              style={{
                                marginLeft: 1,
                                margin: "3px",
                                padding: "5px",
                                background: "#f8f8f8",
                                color: "#7d0ee6",
                                borderRadius: "50%",
                              }}
                              onClick={(e) => handleClickPeriscope(e, item)}
                            >
                              <i className="periscope icon "></i>
                            </button>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#fafafa",
                      padding: "3px",
                    }}
                  >
                    {targetedOrdersCancel.length > 0 ? (
                      <h3 className="subtitledispatches">No Entregadas</h3>
                    ) : (
                      <></>
                    )}

                    {targetedOrdersCancel ? (
                      <>
                        {targetedOrdersCancel.map((item, index) => (
                          <div
                            key={index}
                            value={item.id}
                            className="dispachtelementrow"
                          >
                            <span>{` `}</span>
                            <FmdBadIcon className="FmdBadIconIsuress" />
                            <span className="dispachtItem dispachtItemStatusIsuress">{`Incidencia `}</span>

                            <span className="dispachtItem"></span>

                            <div className="dispachtItem badge-info dispachtItem-id">{`${item.id}`}</div>
                            <div className="dispachtItem-name">
                              <div className="dispachtitemspan-name">{`${item.userName} `}</div>
                              <div className="dispachtitemspan-phone ">{`${item.userPhone} `}</div>
                            </div>
                            <div className="dispachtItem"></div>
                            <div className="dispachtItem">{`${item.toAddress} `}</div>
                            <div className="btn-bicci-small btn-globalhover-calipso  btn-floatright  ">
                              {item.express ? (
                                <span className="badge-success">Express</span>
                              ) : (
                                <></>
                              )}
                            </div>

                            <button
                              className="btn-bicci-small btn-globalhover-calipso btn-floatright "
                              variant="contained"
                              id={targetedOrdersCancel.id}
                              style={{
                                marginLeft: 1,
                                margin: "3px",
                                padding: "5px",
                                background: "#f8f8f8",
                                color: "#7d0ee6",
                                borderRadius: "50%",
                              }}
                              onClick={(e) => handleClickPeriscope(e, item)}
                            >
                              <i className="periscope icon "></i>
                            </button>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <>La ruta no posee ninguna orden, guía o envío asociado </>
  );
};

export default DashPlannerAdd;
