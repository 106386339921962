import JsBarcode from "jsbarcode";

export const generateBarcodeBase64 = async (orderID) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, orderID, {
      format: "CODE128",
      lineColor: "#000",
      width: 2,
      height: 30,
      displayValue: false,
    });
    const imageData = canvas.toDataURL("image/png");
    resolve(imageData);
  });
};
