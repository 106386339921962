async function getSimpleRoute(manifesOrders, origin, endPlaceDestination) {
  const google = window.google;
  const directionsService2 = new google.maps.DirectionsService();
  const relationsShipOrders = [];
  const routeZonesWaitPoints = [];
  const ordersIds = [];
  const startPoint = new google.maps.LatLng(origin);
  const endPoint = new google.maps.LatLng(endPlaceDestination); //{ lat: -33.4546334, lng: -70.700989 };
  const directionsDisplay = new google.maps.DirectionsRenderer({
    map: null,
    suppressMarkers: true,
    polylineOptions: {
      strokeOpacity: 2,
      strokeWeight: 1,
      strokeColor: "#081eeb",
    },
  });
  const bounds = new google.maps.LatLngBounds();

  class dataForCallBacks {
    constructor(
      response,
      type,
      startPointalgorithm,
      endPointalgorithm,
      reOrder_comunasWaintPoint,
      reOrder_ComunasGroupingNames,
      reOrder_responseHOOCKGrupos,
      reOrder_responseHOOCKManifest,
      boundsOne
    ) {
      this._response = response;
      this._type = type;
      this._startPointalgorithm = startPointalgorithm;
      this._endPointalgorithm = endPointalgorithm;
      this._reOrder_comunasWaintPoint = reOrder_comunasWaintPoint;
      this._reOrder_ComunasGroupingNames = reOrder_ComunasGroupingNames;
      this._reOrder_responseHOOCKGrupos = reOrder_responseHOOCKGrupos;
      this._reOrder_responseHOOCKManifest = reOrder_responseHOOCKManifest;
      this._boundsOne = boundsOne;
    }

    get response() {
      return this._response;
    }

    set response(newResponse) {
      this._response = newResponse;
    }

    get type() {
      return this._type;
    }

    set type(newType) {
      this._type = newType;
    }

    get startPointalgorithm() {
      return this._startPointalgorithm;
    }

    set startPointalgorithm(newStartPointalgorithm) {
      this._startPointalgorithm = newStartPointalgorithm;
    }

    get endPointalgorithm() {
      return this._endPointalgorithm;
    }

    set endPointalgorithm(newEndPointalgorithm) {
      this._endPointalgorithm = newEndPointalgorithm;
    }
    get reOrder_comunasWaintPoint() {
      return this._reOrder_comunasWaintPoint;
    }

    set reOrder_comunasWaintPoint(newReOrder_comunasWaintPoint) {
      this._reOrder_comunasWaintPoint = newReOrder_comunasWaintPoint;
    }
    get reOrder_ComunasGroupingNames() {
      return this._reOrder_ComunasGroupingNames;
    }

    set reOrder_ComunasGroupingNames(newReOrder_ComunasGroupingNames) {
      this._reOrder_ComunasGroupingNames = newReOrder_ComunasGroupingNames;
    }
    get reOrder_responseHOOCKGrupos() {
      return this._reOrder_responseHOOCKGrupos;
    }

    set reOrder_responseHOOCKGrupos(newReOrder_responseHOOCKGrupos) {
      this._reOrder_responseHOOCKGrupos = newReOrder_responseHOOCKGrupos;
    }
    get reOrder_responseHOOCKManifest() {
      return this._reOrder_responseHOOCKManifest;
    }

    set reOrder_responseHOOCKManifest(newReOrder_responseHOOCKManifest) {
      this._reOrder_responseHOOCKManifest = newReOrder_responseHOOCKManifest;
    }
  }

  const calculateData = async (manifesOrders, origin) => {
    for (let i = 0; i < manifesOrders.length; i++) {
      routeZonesWaitPoints.push({
        location: manifesOrders[i].adressGeoNumAddressLatLng,
        stopover: true,
      });
      ordersIds.push(manifesOrders[i].orderID);
      relationsShipOrders.push({
        stopover: true,
        location: manifesOrders[i].adressGeoNumAddressLatLng,
        position: i,
        id: manifesOrders[i].orderID,
      });
    }

    await calculateSimpleRoute(
      directionsService2,
      directionsDisplay,
      startPoint,
      endPoint,
      routeZonesWaitPoints,
      bounds,
      relationsShipOrders,
      ordersIds
    );

    async function calculateSimpleRoute(
      directionsService,
      directionsDisplay,
      startPoint,
      endPoint,
      wayPointsArray,
      bounds,
      relationsShipOrders,
      ordersIds
    ) {
      await directionsService.route(
        {
          origin: startPoint,
          destination: endPoint,
          travelMode: "BICYCLING",
          optimizeWaypoints: true,
          waypoints: wayPointsArray,
        },
        function (response, status) {
          if (status === "OK") {
            console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
            console.log("Evaluar si es necesario");
            console.log("destination");
            console.log(origin);
            console.log("Evaluar si es necesario ");
            console.log("OK directionsService");
            bounds.union(response.routes[0].bounds);
            //mapRef.current.fitBounds(bounds);
            dataForCallBacks.response = response;
            dataForCallBacks.type = "calculateSimpleRoute";
            dataForCallBacks.startPointalgorithm = startPoint;
            dataForCallBacks.endPointalgorithm = endPoint;
            dataForCallBacks.reOrder_comunasWaintPoint = null;
            dataForCallBacks.reOrder_ComunasGroupingNames = relationsShipOrders;
            dataForCallBacks.reOrder_responseHOOCKGrupos = ordersIds;
            dataForCallBacks.reOrder_responseHOOCKManifest = manifesOrders;
          } else {
            console.log(response);
            console.log(status);
          }
        }
      );
    }
  };
  await calculateData(manifesOrders, origin);

  console.log("OK calculateSimpleRoute");
  console.log(dataForCallBacks.response);
  return {
    response: dataForCallBacks.response,
    type: dataForCallBacks.type,
    startPointalgorithm: dataForCallBacks.startPointalgorithm,
    endPointalgorithm: dataForCallBacks.endPointalgorithm,
    reOrder_comunasWaintPoint: dataForCallBacks.reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames: dataForCallBacks.reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos: dataForCallBacks.reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest:
      dataForCallBacks.reOrder_responseHOOCKManifest,
  };
}

export default getSimpleRoute;
