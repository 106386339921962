import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const GooglePlacesAutocomplete = ({ handleSelections, defaultAddress }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(defaultAddress || ""); // Inicializar con la dirección predeterminada
  const [directionsData, setDirectionsData] = useState({
    addressGeoreft: "",
    address: "",
    direccion1: "",
    direccion2: "",
    direccion3: "",
    locality: "",
    commune: "",
    commune2: "",
    region: "",
    country: "",
    zipCode: "",
  });

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      types: ["address"],
      componentRestrictions: { country: "cl" },
    },
  });

  // Establecer dirección predeterminada cuando el componente se carga
  useEffect(() => {
    if (defaultAddress) {
      setInputValue(defaultAddress); // Mostrar dirección predeterminada en el campo
      setValue(defaultAddress, false); // También actualizar en usePlacesAutocomplete
    }
  }, [defaultAddress, setValue]);

  useEffect(() => {
    if (status === "OK") {
      const newOptions = data.map((suggestion) => ({
        label: suggestion.description,
        ...suggestion,
      }));
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [status, data]);

  useEffect(() => {
    if (directionsData) {
      handleSelections(directionsData);
    }
  }, [directionsData]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue); // Actualizar el valor del campo input
    setValue(newInputValue);
  };

  const handleSelect = async (event, newValue) => {
    const handleSelectApiGeoref = async (address) => {
      setValue(address, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address });
        const addressComponents = results[0].address_components;

        let newDirectionsData = {
          addressGeoreft: {
            lat: 0,
            lng: 0,
          },
          address: address,
          direccion1: "",
          direccion2: "",
          direccion3: "",
          locality: "",
          commune: "",
          commune2: "",
          region: "",
          country: "",
          zipCode: "",
        };

        // Mapeo de los componentes de la dirección a nuestro estado
        addressComponents.forEach((component) => {
          const componentType = component.types[0];
          switch (componentType) {
            case "street_number":
              newDirectionsData.direccion2 = component.long_name;
              break;
            case "route":
              newDirectionsData.direccion1 = component.long_name;
              break;
            case "sublocality_level_1":
            case "sublocality":
            case "locality":
              newDirectionsData.locality = component.long_name;
              newDirectionsData.commune2 = component.long_name;
              break;
            case "administrative_area_level_2":
              newDirectionsData.commune = component.long_name;
              break;
            case "administrative_area_level_1":
              newDirectionsData.region = component.long_name;
              break;
            case "country":
              newDirectionsData.country = component.long_name;
              break;
            case "postal_code":
              newDirectionsData.zipCode = component.long_name;
              break;
            default:
              break;
          }
        });

        const { lat, lng } = getLatLng(results[0]);
        newDirectionsData.addressGeoreft = { lat, lng };

        setDirectionsData(newDirectionsData);
      } catch (error) {
        console.log("Error al obtener detalles de la dirección: ", error);
      }
    };

    if (newValue) {
      const address = newValue.label;
      handleSelectApiGeoref(address);
    }
  };

  return (
    <Autocomplete
      id="google-map-autocomplete"
      style={{
        width: "100%",
        backgroundColor: "#FFF",
        borderRadius: "8px",
      }}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      disableOpenOnFocus
      onChange={handleSelect}
      onInputChange={handleInputChange}
      inputValue={inputValue} // Aquí vinculamos el valor del input
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar dirección"
          fullWidth
          style={{
            width: "100%",
            backgroundColor: "#FFF",
            borderRadius: "8px",
          }}
        />
      )}
    />
  );
};

export default GooglePlacesAutocomplete;
