import React from "react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { NavLink } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/AddLocationAlt";
import { collection, doc, getDocs } from "firebase/firestore";

const Boxdispach = (props) => {
  const [userOrders, setUserOrders] = React.useState([]);
  const [allOrders, setAllOrders] = React.useState([]);

  React.useEffect(() => {
    if (props && props.user) {
      const findOrdesrDispacht = async (userUid) => {
        try {
          const ordesrDispachtRef = collection(
            db,
            `${process.env.REACT_APP_COL_ORDERS}`
          );

          const ordesrDispacht = await getDocs(ordesrDispachtRef);

          const Ordenes = ordesrDispacht.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAllOrders(Ordenes);
          const ordersPropietary = Ordenes.filter(
            (item) => item.originPropietary === userUid
          );

          ordersPropietary.sort((a, b) =>
            a.dispachtDate < b.dispachtDate ? 1 : -1
          );
          var lats5ordersPropietary = ordersPropietary.slice(0, 4);

          setUserOrders(lats5ordersPropietary);
        } catch (error) {
          // console.log(error)
        }
      };
      //setOrigenEmail(userEmail)
      findOrdesrDispacht(props.user.uid);
    }
  }, [props]);

  return (
    <Card className="typeMiniforms">
      <CardHeader subheader="Ver despachos" title="Ultimos despachos" />
      <Divider />

      <Container className="formcontentmin" id="micuentafrominidash3">
        {userOrders ? (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Destino</th>
              </tr>
            </thead>
            <tbody>
              {userOrders.map((element) => (
                <tr key={element.id}>
                  <td>{element.dispachtCode}</td>
                  <td>{element.originUserName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          "No hay ordenes "
        )}
      </Container>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <NavLink to="/dashboard/tablero" className="btn-sidebarLink">
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            variant="contained"
          >
            Ver mas
          </Button>
        </NavLink>
      </Box>
    </Card>
  );
};

export default Boxdispach;
