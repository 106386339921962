import { Box, Stack } from "@mui/material";
import React from "react";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import { fDate } from "../../../../utils/objects/FormatTime";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../../components/CustomDateRangePicker";
import { CustomDateRangePickerStyle } from "../../../../components/CustomDateRangePicker/CustomDateRangePickerStyle";

export const GuiasToolsBars = ({ onSelectView, ...props }) => {
  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
    //console.log(createDate, FinalDate);
  };

  return (
    <Box sx={{ with: "100%", height: "100%" }}>
      <Button
        variant="contained"
        onClick={rangeInputPicker.onOpen}
        sx={CustomDateRangePickerStyle}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <div sx={{ typography: "body2" }}>
            <strong>Desde:</strong> {fDate(rangeInputPicker.startDate)}
            <EventIcon color="primary" /> {/* Ícono de calendario */}
          </div>
          <div sx={{ typography: "body2" }}>
            <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
            <EventIcon color="primary" /> {/* Ícono de calendario */}
          </div>
        </Stack>
      </Button>
      <CustomDateRangePicker
        variant="calendar" // input
        open={rangeInputPicker.open}
        startDate={rangeInputPicker.startDate}
        endDate={rangeInputPicker.endDate}
        onChangeStartDate={rangeInputPicker.onChangeStartDate}
        onChangeEndDate={rangeInputPicker.onChangeEndDate}
        onClose={rangeInputPicker.onClose}
        error={rangeInputPicker.error}
        setNewDate={setNewDate}
      />
      <Box>
        <Button
          size="small"
          color="primary"
          name="facturacion"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Facturacion
        </Button>
        <Button
          size="small"
          color="primary"
          name="crearregistro"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Carga SDD
        </Button>
        <Button
          size="small"
          color="primary"
          name="devoluciones"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Registrar Devoluciones
        </Button>
        <Button
          size="small"
          color="primary"
          name="apelaciones"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Registrar Apelaciones
        </Button>
        <Button
          size="small"
          color="primary"
          name="perdidas"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Registrar Perdidas
        </Button>
        <Button
          size="small"
          color="primary"
          name="all"
          onClick={(e) => onSelectView(e.target.name)}
        >
          Ver todos los modulos
        </Button>
      </Box>
    </Box>
  );
};

export default GuiasToolsBars;
