import React from "react";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Moment from "moment";
import useObtenerDatosdeCliente from "../../../../hooks/obtenerDatosdeCliente";
import useGetRoutesExpressById from "../../../../hooks/getRoutesExpressById";
import useGetDataPlaningGroupById from "../../../../hooks/useGetDataPlaningGroupById";
import useGetDataRiderByCompany from "../../../../hooks/getDataRiderByCompany";
import useGetDataVehiclesByCompany from "../../../../hooks/getOrdersDataContextByCompany";
import Button from "react-bootstrap/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../../components/animations/FadeInOut";
//import PlanerDocPDF from './PlanerDocPDF';
//import DashPlannerEditMapRoute from './DashPlannerEditMapRoute';
import { db } from "../../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";

import { Divider, CardHeader, Avatar, LinearProgress } from "@mui/material";
import { Tab } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Tooltip, IconButton } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { useEffect } from "react";
import {
  Container,
  Form,
  Input,
  Card,
  CardContent,
  Grid,
} from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";

var dispachtList = "lista de depachos";
var mapaDeRuta = " Mapa de ruta";
var documentPDF = "";
const libraries = ["drawing"];

const DashRoutesEdit = (props) => {
  const navigate = useNavigate();
  const { id: routeId } = useParams();
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const usuarioLocal = usuarioStorage.uid;
  const LocalRol = usuarioStorage.roll;
  const [userRider, setUserRiderr] = React.useState("");
  const [userVehicle, setUserVehicle] = React.useState("");
  const [currentOrderList, setCurrentOrderList] = React.useState([]);
  const [routeDate, setRouteDate] = React.useState("");
  const [routeStartTimeDelivery, setRouteStartTimeDelivery] =
    React.useState("08:30");
  const [routeStayTimeDelivery, setRouteStayTimeDelivery] =
    React.useState("00:10");
  const [routeEndTimeDelivery, setRouteEndTimeDelivery] =
    React.useState("20:30");
  const [optimizeWaypoints, setOptimizeWaypoints] = React.useState(false);

  const [companyId, setcompanyId] = React.useState(null);

  const [showEdit, setShowEdit] = React.useState(false);
  const [showEditDataPlaner, setShowEditDataPlaner] = React.useState(false);
  const [showEditDriver, setShowEditDriver] = React.useState(false);
  const [showEditVehicle, setShowEditVehicle] = React.useState(false);

  const [directions, setDirections] = React.useState(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const [ridersRows, setRidersRows] = React.useState("");

  const [vehiclesRows, setVehiclesRows] = React.useState("");

  const [OrdersRows, setOrdersRows] = React.useState("");

  const [targetedOrdersRows, setTargetedOrdersRows] = React.useState("");

  const [render, setRender] = React.useState(false);

  const [errorData, setError] = React.useState(null);

  const [succesCreate, setSuccesCreate] = React.useState(null);

  const [manifesOrders, setManifesOrders] = React.useState(null);

  const { userdata } = useObtenerDatosdeCliente(usuarioLocal);

  const { ridersDataByCompany } = useGetDataRiderByCompany(
    companyId,
    userRider
  );

  const { VehiclesDataByCompany, listVehiclesDataByCompany } =
    useGetDataVehiclesByCompany(companyId, userVehicle);

  const { bicciRoutesExpressByID } = useGetRoutesExpressById(
    companyId,
    LocalRol,
    routeId
  );

  const { bicciOrdesDataManifesPlaning } = useGetDataPlaningGroupById(
    bicciRoutesExpressByID
  );

  const google = window.google;

  const columnsRiders = [
    {
      field: "selector",
      headerName: "",
      width: 20,
      renderCell: (ridersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={ridersRows.id}
                value={ridersRows.row.name}
                data={ridersRows.row.avatar}
                onClick={handleGoPropsRidersRows2}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                {/* <ArrowForwardIosIcon   onClick={handleGoPropsRidersRows3} ></ArrowForwardIosIcon> */}

                <Icon name="angle right" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Seleccione un conductor",
      width: 250,
      minHeight: 70,
      height: 70,
      renderCell: (ridersRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            <CardHeader
              avatar={
                <Avatar
                  src={ridersRows.row.avatar}
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsRidersRows3}
                  aria-label="recipe"
                ></Avatar>
              }
              title={ridersRows.row.name}
              subheader={ridersRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const columnsVehicle = [
    {
      field: "actions",
      headerName: "Seleccione un vehículo",
      width: 250,
      renderCell: (vehicleRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={vehicleRows.row.id}
                value={vehicleRows.row.name}
                onClick={handleGoPropsVehicleRows}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                <Icon name="angle right" />
              </IconButton>
            </Tooltip>

            <CardHeader
              avatar={
                <Avatar
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecobicci-1.png?alt=media&token=25fc24ac-26e1-43a0-8bc0-889c4afec5cf"
                  }
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsVehicleRows}
                  aria-label="recipe"
                ></Avatar>
              }
              title={vehicleRows.row.id}
              subheader={vehicleRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const columsOrders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (OrdersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={OrdersRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleGoPropsOrdersRows}
            >
              Agregar
            </button>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: true,
    },
    {
      field: "toAddress",
      headerName: "Destino",
      width: 120,
      editable: true,
    },
    {
      field: "userName",
      headerName: "Destinatario",
      width: 120,
      editable: true,
    },
  ];

  const targetColumsOrders = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (targetedOrdersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-bicci-small btn-globalhover-calipso"
              variant="contained"
              color="primary"
              size="small"
              id={targetedOrdersRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleRemoveItem}
            >
              Remover
            </button>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: true,
    },
    {
      field: "toAddress",
      headerName: "Destino",
      width: 120,
      editable: true,
    },
    {
      field: "userName",
      headerName: "Destinatario",
      width: 120,
      editable: true,
    },
  ];

  var [newRoute, setNewRoute] = React.useState({
    createBy: usuarioLocal,
    createDate: Moment(Date.now()).format("DD-MM-YYYY"),
    date: Date.now(),
    id: "",
    vehicleID: "",
    driverID: "",
    driverName: "",
    companyId: "",
    travelMode: "",
    currentOrderList: {},
    origin: { lat: -33.41856676168833, lng: -70.6027176809204 },
    destination: { lat: -33.41856676168833, lng: -70.6027176809204 },
    optimizeWaypoints: true, // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    wayPoints: [],
    waypoint_order: [],
    maxTimeDelivery: routeStayTimeDelivery,
    dateDeliveryText: "",
    dateDelivery: "",
    startTimeDelivery: routeStartTimeDelivery,
    endTimeDelivery: routeEndTimeDelivery,
    distanceValue: 0,
    durationValue: 0,
    setRoutePoints: 0,
    dispacthDelyvery: 0,
    dispacthPartials: 0,
    dispacthNotDelyvery: 0,
    fullCompliance: 0,
    warnings: "",
    routeStates: 0,
    routePoints: 0,
    totalPack: 0,
  });

  const panes = [
    {
      menuItem: "GUIA DE DESPACHOS",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {dispachtList}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "RUTA DE ENTREGAS",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {mapaDeRuta}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "DOCUMENTO PDF",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {documentPDF}
        </Tab.Pane>
      ),
    },
  ];

  useEffect(() => {
    if (VehiclesDataByCompany) {
      getBicciVehiclesData();
    }
  }, [VehiclesDataByCompany && listVehiclesDataByCompany]);

  useEffect(() => {
    getBicciDriversData();

    getBicciUserData();
    if (bicciRoutesExpressByID) {
      getAwaitEstimateTime();
    }
  }, [
    VehiclesDataByCompany,
    ridersDataByCompany,
    userdata,
    bicciRoutesExpressByID,
  ]); //, targetedOrdersRows

  useEffect(() => {
    // console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
      findAdressesCompany();
    }
  }, [userdata]);

  useEffect(() => {}, [render]);

  useEffect(() => {
    if (bicciRoutesExpressByID) {
      setObjRoute(bicciRoutesExpressByID);
    }
  }, [bicciRoutesExpressByID]);

  useEffect(() => {
    if (bicciOrdesDataManifesPlaning) {
      setManifesOrders(bicciOrdesDataManifesPlaning);
    }
  }, [bicciOrdesDataManifesPlaning]);

  const findAdressesCompany = async (companyUid) => {
    //console.log(companyUid)
    try {
      const adressses = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(userdata.userCompanyID)
        .collection("direcciones")
        .get();
      //console.log(adressses.docs)
      const directions = adressses.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log(directions)
      setDirections(directions);
    } catch (error) {
      //console.log(error)
    }
  };

  const setObjRoute = (bicciRoutesExpressByID) => {
    newRoute.id = bicciRoutesExpressByID.id;
    newRoute.createBy = usuarioLocal;
    newRoute.createDate = bicciRoutesExpressByID.createDate;
    newRoute.date = bicciRoutesExpressByID.date;
    newRoute.vehicleID = bicciRoutesExpressByID.vehicleID;
    newRoute.driverID = bicciRoutesExpressByID.driverID;
    newRoute.driverName = bicciRoutesExpressByID.driverName;
    newRoute.companyId = bicciRoutesExpressByID.companyId;
    newRoute.travelMode = bicciRoutesExpressByID.travelMode;
    newRoute.currentOrderList = bicciRoutesExpressByID.currentOrderList;
    newRoute.origin = bicciRoutesExpressByID.origin;
    newRoute.destination = bicciRoutesExpressByID.destination;
    newRoute.optimizeWaypoints = bicciRoutesExpressByID.optimizeWaypoints; // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    newRoute.wayPoints = bicciRoutesExpressByID.wayPoints;
    newRoute.waypoint_order = bicciRoutesExpressByID.waypoint_order;
    newRoute.maxTimeDelivery = bicciRoutesExpressByID.maxTimeDelivery;
    newRoute.dateDeliveryText = bicciRoutesExpressByID.dateDeliveryText;
    newRoute.dateDelivery = bicciRoutesExpressByID.dateDelivery;
    newRoute.startTimeDelivery = bicciRoutesExpressByID.startTimeDelivery;
    newRoute.endTimeDelivery = bicciRoutesExpressByID.endTimeDelivery;
    newRoute.distanceValue = bicciRoutesExpressByID.distanceValue;
    newRoute.durationValue = bicciRoutesExpressByID.durationValue;
    newRoute.setRoutePoints = bicciRoutesExpressByID.wayPoints.length;
    newRoute.dispacthDelyvery = bicciRoutesExpressByID.dispacthDelyvery;
    newRoute.dispacthPartials = bicciRoutesExpressByID.dispacthPartials;
    newRoute.dispacthNotDelyvery = bicciRoutesExpressByID.dispacthNotDelyvery;
    newRoute.fullCompliance = bicciRoutesExpressByID.fullCompliance;
    newRoute.warnings = bicciRoutesExpressByID.warnings;
    newRoute.routePoints = bicciRoutesExpressByID.routePoints;
    newRoute.totalPack = bicciRoutesExpressByID.totalPack;
    newRoute.estimateAwaitTime = bicciRoutesExpressByID.estimateAwaitTime;
    newRoute.estimateTotalTime = bicciRoutesExpressByID.estimateTotalTime;
    setOptimizeWaypoints(newRoute.optimizeWaypoints);
    setCurrentOrderList(newRoute.currentOrderList);
    setRouteStartTimeDelivery(newRoute.startTimeDelivery);
    setRouteStayTimeDelivery(newRoute.maxTimeDelivery);
    setRouteEndTimeDelivery(newRoute.endTimeDelivery);

    setRender(!render);
  };

  const getBicciUserData = () => {
    if (userdata) {
      newRoute.companyId = userdata.userCompanyID;
    }
  };

  const handleGoPropsRidersRows2 = (e) => {
    console.log(e.target.parentNode);
    console.log(e.target.parentNode.id);
    console.log(e.target.parentNode.attributes.getNamedItem("data").value);
    newRoute.driverID = e.target.parentNode.id;
    newRoute.driverName = e.target.parentNode.value;
    newRoute.driverAvatar =
      e.target.parentNode.attributes.getNamedItem("data").value;

    setRender(!render);
    //  newRoute.driverID = e.target.parentNode.id
    //  newRoute.driverName =  e.target.parentNode.value
  };

  const getAwaitEstimateTime = () => {
    var maxTimeDeliveryAwait = newRoute.maxTimeDelivery.split(":");
    console.log(newRoute.routePoints);
    console.log(parseInt(maxTimeDeliveryAwait[1]));
    let timeTemp = parseInt(maxTimeDeliveryAwait[1]);
    newRoute.estimateAwaitTime = newRoute.routePoints * timeTemp;

    //setRouteStayTimeDelivery(totalEstimateAwaitTime)
  };

  const UpdateAviableOrders = () => {
    // REVISAR
    // console.log(newRoute.currentOrderList)
    if (bicciRoutesExpressByID) {
      var OrdersRowsTemp = [];
      var targetedOrdersRowsTemp = [];
      for (let i = 0; i < bicciRoutesExpressByID.length; i++) {
        let opsList = Object.values(newRoute.currentOrderList);
        if (opsList.includes(bicciRoutesExpressByID[i].id)) {
          // console.log('encontrando ya asignados: ' + bicciOrderExpressGroupRider[i].id)
          targetedOrdersRowsTemp.push({
            id: bicciRoutesExpressByID[i].id,
            toAddress: bicciRoutesExpressByID[i].toAddress,
            userName: bicciRoutesExpressByID[i].userName,
          });
        } else {
          OrdersRowsTemp.push({
            id: bicciRoutesExpressByID[i].id,
            toAddress: bicciRoutesExpressByID[i].toAddress,
            userName: bicciRoutesExpressByID[i].userName,
          });
        }
      }
      newRoute.setRoutePoints = targetedOrdersRowsTemp.length;
      setOrdersRows(OrdersRowsTemp);
      setTargetedOrdersRows(targetedOrdersRowsTemp);
    }
  };

  const getBicciDriversData = () => {
    if (ridersDataByCompany) {
      var ridersRows = [];
      for (let i = 0; i < ridersDataByCompany.length; i++) {
        ridersRows.push({
          id: ridersDataByCompany[i].id,
          name: ridersDataByCompany[i].name,
          bicciNumber: ridersDataByCompany[i].bicciNumber,
          avatar: ridersDataByCompany[i].avatar,
        });
      }
      setRidersRows(ridersRows);
    } else {
    }
  };

  const getBicciVehiclesData = () => {
    if (VehiclesDataByCompany) {
      var vehicleRows = [];
      for (let i = 0; i < VehiclesDataByCompany.length; i++) {
        vehicleRows.push({
          id: VehiclesDataByCompany[i].id,
          travelMode: VehiclesDataByCompany[i].travelMode,
          VehiclesTypes: VehiclesDataByCompany[i].VehiclesTypes,
        });
      }
      setVehiclesRows(vehicleRows);
    } else {
      // // console.log('Sin data')
    }
  };

  const clearObjRoute = () => {
    if (newRoute.createBy !== bicciRoutesExpressByID.createBy) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.createDate !== bicciRoutesExpressByID.createDate) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.date !== bicciRoutesExpressByID.date) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.vehicleID !== bicciRoutesExpressByID.vehicleID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverID !== bicciRoutesExpressByID.driverID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverName !== bicciRoutesExpressByID.driverName) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.companyId !== bicciRoutesExpressByID.companyId) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.travelMode !== bicciRoutesExpressByID.travelMode) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.currentOrderList !== bicciRoutesExpressByID.currentOrderList) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.origin !== bicciRoutesExpressByID.origin) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.destination !== bicciRoutesExpressByID.destination) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.optimizeWaypoints !== bicciRoutesExpressByID.optimizeWaypoints
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.wayPoints !== bicciRoutesExpressByID.wayPoints) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.waypoint_order !== bicciRoutesExpressByID.waypoint_order) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.maxTimeDelivery !== bicciRoutesExpressByID.maxTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDeliveryText !== bicciRoutesExpressByID.dateDeliveryText) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDelivery !== bicciRoutesExpressByID.dateDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.startTimeDelivery !== bicciRoutesExpressByID.startTimeDelivery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.endTimeDelivery !== bicciRoutesExpressByID.endTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.distanceValue !== bicciRoutesExpressByID.distanceValue) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.durationValue !== bicciRoutesExpressByID.durationValue) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.setRoutePoints !== bicciRoutesExpressByID.setRoutePoints) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dispacthDelyvery !== bicciRoutesExpressByID.dispacthDelyvery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dispacthPartials !== bicciRoutesExpressByID.dispacthPartials) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.dispacthNotDelyvery !==
      bicciRoutesExpressByID.dispacthNotDelyvery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.fullCompliance !== bicciRoutesExpressByID.fullCompliance) {
      setError("Faltan cambios por guardar");
      return;
    }
    navigate(`/dashboard/planner/`);
    setSuccesCreate(true);
    setRender(!render);
  };

  const handleChange = (e) => {
    // console.log(e.target.name)
    if (e.target.name === "endHour") {
      setRouteEndTimeDelivery(e.target.value);
      newRoute.endTimeDelivery = e.target.value;
    }
    if (e.target.name === "awaitTime") {
      setRouteStayTimeDelivery(e.target.value);
      newRoute.maxTimeDelivery = e.target.value;
    }
    if (e.target.name === "initHour") {
      setRouteStartTimeDelivery(e.target.value);
      newRoute.startTimeDelivery = e.target.value;
    }
    if (e.target.name === "dispchDate") {
      setRouteDate(e.target.value);
      newRoute.dateDelivery = e.target.value;
      newRoute.dateDeliveryText = e.target.value;
    }
    if (e.target.name === "activeOptimize")
      if (e.target.checked === true) {
        //console.log('seleccion')
        setNewRoute({ ...newRoute, optimizeWaypoints: true });
        setOptimizeWaypoints(true);
      }
    if (e.target.checked === false) {
      console.log("Quit seleccion");
      setNewRoute({ ...newRoute, optimizeWaypoints: false });
      setOptimizeWaypoints(false);
    }
    setRender(!render);
  };

  const onOptimize = () => {
    setNewRoute({ ...newRoute, optimizeWaypoints: true });
    setOptimizeWaypoints(true);
    setRender(!render);
  };
  const offOptimize = () => {
    setNewRoute({ ...newRoute, optimizeWaypoints: false });
    setOptimizeWaypoints(false);
    setRender(!render);
  };

  const handleGoPropsOrdersRows = (e) => {
    // REVISAR
    // console.log('un click')
    e.preventDefault();
    // console.log(e.target.id)
    setCurrentOrderList(newRoute.currentOrderList);
    let opsList = Object.values(currentOrderList);
    if (!opsList.includes(e.target.id)) {
      opsList.push(e.target.id);
    }
    newRoute.currentOrderList = Object.assign({}, opsList);
    setCurrentOrderList(newRoute.currentOrderList);
    UpdateAviableOrders();
    //console.log(newRoute)
    setRender(!render);
  };

  const handleRemoveItem = (e) => {
    // REVISAR
    // console.log('accion remove')
    e.preventDefault();
    // console.log(e.target.id)
    setCurrentOrderList(newRoute.currentOrderList);
    let removeItem = Object.values(currentOrderList);

    var newArray = removeItem.filter((item) => item !== e.target.id);
    // console.log(newArray);

    newRoute.currentOrderList = Object.assign({}, newArray);
    setCurrentOrderList(newRoute.currentOrderList);
    UpdateAviableOrders();
    // console.log(newRoute)
    setRender(!render);
  };

  const handleGoPropsRidersRows = (e) => {
    e.preventDefault();
    // console.log('un click')
    // console.log(e.target.value)
    // console.log(e.target.id)
    newRoute.driverID = e.target.id;
    newRoute.driverName = e.target.value;

    setRender(!render);
  };

  const handleGoPropsVehicleRows = (e) => {
    e.preventDefault();
    console.log(e.target.parentNode);
    newRoute.vehicleID = e.target.parentNode.id;
    let tempData = VehiclesDataByCompany.filter(
      (item) => item.id === e.target.parentNode.id
    );
    console.log(tempData);
    newRoute.travelMode = tempData[0].travelMode;
    newRoute.origin = tempData[0].origen;
    newRoute.destination = tempData[0].destiny;
    setRender(!render);
  };

  async function handleClickUpdate(e) {
    e.preventDefault();

    // console.log(newRoute)
    // console.log(newRoute.currentOrderList)
    if (!newRoute.companyId.trim()) {
      setError("Error companyId");
      return;
    }
    if (!newRoute.createBy.trim()) {
      setError("Error createBy");
      return;
    }
    // if(!newRoute.createDate.length()>0){setError('Error createDate')}
    // if(!newRoute.date.length()>0){setError('Error date')}
    //if(!newRoute.id.trim()){setError('Error ID');return}
    if (!newRoute.driverID.trim()) {
      setError("Error driverID");
      return;
    }
    if (!newRoute.vehicleID.trim()) {
      setError("Error vehicleID");
      return;
    }
    if (!newRoute.driverName.trim()) {
      setError("Error driverName");
      return;
    }
    if (!newRoute.travelMode.trim()) {
      setError("Error travelMode");
      return;
    }
    let currentOrderList = Object.entries(newRoute.currentOrderList);
    //console.log(currentOrderList)
    if (!currentOrderList.length > 0) {
      setError("No exiten guias o paquetes a la ruta ");
      return;
    } else {
      newRoute.routeStates = 104;
      //console.log('routeStates')
    }
    if (!Object.entries(newRoute.origin).length === true) {
      setError("Error origin");
      return;
    }
    if (!Object.entries(newRoute.destination).length === true) {
      setError("Error destination");
      return;
    }
    if (!newRoute.distanceValue === 0) {
      setError("Error distanceValue");
      return;
    }
    if (!newRoute.durationValue === 0) {
      setError("Error durationValue");
      return;
    }
    if (!newRoute.setRoutePoints === 0) {
      setError("Error setRoutePoints");
      return;
    }
    if (!newRoute.dispacthDelyvery === 0) {
      setError("Error dispacthDelyvery");
      return;
    }
    if (!newRoute.dispacthPartials === 0) {
      setError("Error dispacthPartials");
      return;
    }
    if (!newRoute.dispacthNotDelyvery === 0) {
      setError("Error dispacthNotDelyvery");
      return;
    }
    if (!newRoute.fullCompliance === 0) {
      setError("Error fullCompliance");
      return;
    }
    setError(null);
    // console.log('Update ruta')

    try {
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .collection("history")
        .doc(Date.now().toString())
        .set(newRoute);
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .update(newRoute);
      setSuccesCreate("true");
      clearObjRoute();
      navigate(`/dashboard/planner/`);
    } catch (error) {
      // console.log(error)
    }
  }

  function handleCancel() {
    clearObjRoute();
  }

  const confirmCancel = (e) => {
    e.preventDefault();
    navigate(`/dashboard/planner/`);
  };

  const handleChangeDirectionsOrigen = (e) => {
    console.log(e);
    let id = e;
    let dirSelect = directions.filter((item) => item.id === id);
    console.log(dirSelect);
    let selectOrigen = {
      lat: dirSelect[0].adressGeoNumAddressLat,
      lng: dirSelect[0].adressGeoNumAddressLng,
    };

    newRoute.origin = selectOrigen;
    newRoute.originAdressName = dirSelect[0].adressName;
  };
  const handleChangeDirectionsDestiny = (e) => {
    console.log(e);
    let id = e;
    let dirSelect = directions.filter((item) => item.id === id);
    console.log(dirSelect);
    let destination = {
      lat: dirSelect[0].adressGeoNumAddressLat,
      lng: dirSelect[0].adressGeoNumAddressLng,
    };
    newRoute.destination = destination;
  };

  const validateSave = () => {
    var isComplete;
    if (
      newRoute.driverName &&
      newRoute.vehicleID &&
      newRoute.dateDeliveryText &&
      routeStartTimeDelivery &&
      routeEndTimeDelivery &&
      routeStayTimeDelivery
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    return isComplete === true ? (
      <>
        <Tooltip title="Cancelar edición">
          <IconButton
            onClick={handleCancel}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#ff0000",
              borderRadius: "3px",
              margintop: "5px",
              marginLeft: "0px",
              "&:focus": {
                outline: "none!important",
                margintop: "5px",
                marginLeft: "0px",
              },
              "&:hover": {
                opacity: 0.4,
                ml: 2,
                color: "#f8f8f8",
                background: "#ff0000",
                borderRadius: "3px",
                margintop: "5px",
                marginLeft: "0px",
              },
            }}
          >
            {`x Cancelar`}
          </IconButton>
        </Tooltip>
        <Tooltip title="Guardar">
          <IconButton
            onClick={handleClickUpdate}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#008000",
              borderRadius: "3px",
              margin: "5px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#008000",
                borderRadius: "3px",
                margin: "5px",
              },
            }}
          >
            {`Guardar`}
          </IconButton>
        </Tooltip>
        <Tooltip title="Guardar">
          <IconButton
            onClick={() => console.log("Activar")}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#008000",
              borderRadius: "3px",
              margin: "5px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#008000",
                borderRadius: "3px",
                margin: "5px",
              },
            }}
          >
            {`Activar Ruta`}
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <>
        {" "}
        <Tooltip title="Cancelar edición">
          <IconButton
            onClick={handleCancel}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#ff0000",
              borderRadius: "3px",
              margintop: "5px",
              marginLeft: "0px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#ff0000",
                borderRadius: "3px",
                margintop: "5px",
                marginLeft: "0px",
              },
            }}
          >
            {`x Cancelar`}
          </IconButton>
        </Tooltip>
        <Tooltip title="Guardar">
          <IconButton
            onClick={handleClickUpdate}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#008000",
              borderRadius: "3px",
              margin: "5px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#008000",
                borderRadius: "3px",
                margin: "5px",
              },
            }}
          >
            {`Guardar`}
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const setTriger = () => {
    function delay2() {
      setRender(!render);
    }
    setTimeout(delay2, 3000);
  };

  const updatRdition = () => {
    console.log("guardarCambios");
  };

  const updateDataRouteOrderPiont = async (newOrederList) => {
    //console.log(newOrederList)
    //setCurrentOrderList(newOrederList)
    //console.log(newRoute)
    //currentOrderList OK

    //console.log(bicciOrdesDataManifesPlaning)

    //wayPoints

    //waypoint_order

    //optimizeWaypoints

    //manifest
    async function createNewRoute() {
      var Local_wayPoints = [];
      var Local_waypoint_order = [];
      var Local_ManifestOrders = [];

      const reOrderDataByCurrentList = async () => {
        for (let i = 0; i < newOrederList.length; i++) {
          for (let n = 0; n < bicciOrdesDataManifesPlaning.length; n++) {
            if (newOrederList[i] === bicciOrdesDataManifesPlaning[n].orderID) {
              let wayPoint = {
                stopover: true,
                location: {
                  lat: bicciOrdesDataManifesPlaning[n].adressGeoNumAddressLatLng
                    .lat,
                  lng: bicciOrdesDataManifesPlaning[n].adressGeoNumAddressLatLng
                    .lng,
                },
              };
              Local_wayPoints.push(wayPoint);
              Local_waypoint_order.push(i);

              Local_ManifestOrders.push(bicciOrdesDataManifesPlaning[n]);
            }
          }
        }

        return { Local_wayPoints, Local_waypoint_order, Local_ManifestOrders };
      };

      return new Promise(async (resolve, reject) => {
        let resp = await reOrderDataByCurrentList();
        newRoute.wayPoints = resp.Local_wayPoints;
        newRoute.waypoint_order = resp.Local_waypoint_order;
        setManifesOrders(resp.Local_ManifestOrders);

        //newRoute.legs  =  []
        setTimeout(() => {
          let proceso2 = "proceso 3";
          resolve(proceso2);
        }, 1000);
      });
    }

    await createNewRoute();
  };

  // const upDateDataRouteDetailsInfo = async(DataRouteDetailsInfo)=>{
  //   console.log('DataRouteDetailsInfo')
  //   console.log(DataRouteDetailsInfo)
  //   setNewRoute({...newRoute,
  //     travelMode: DataRouteDetailsInfo.travelMode,
  //     durationValue : DataRouteDetailsInfo.durationValue,
  //     distanceValue : DataRouteDetailsInfo.distanceValue,
  //     routePoints : DataRouteDetailsInfo.waypoint_orderCant,
  //   })
  //     // newRoute.travelMode= DataRouteDetailsInfo.travelMode
  //     // newRoute.durationValue = DataRouteDetailsInfo.durationValue
  //     // newRoute.distanceValue = DataRouteDetailsInfo.distanceValue
  //     // newRoute.routePoints = DataRouteDetailsInfo.waypoint_orderCant
  //     setRender(!render)
  // }

  dispachtList = (
    <div>
      {" "}
      <Card className="dispachtListManifest">
        <div className="manifesContainer">
          <img
            className="logoManifest"
            src={
              "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00"
            }
            alt=""
          ></img>
        </div>
        <CardHeader
          title={
            <>
              <h1 className="manifesColorsFonts">
                Guía de Despachos Asignados Para Entrega en Ruta
              </h1>
              <h2 className="manifesColorsFonts">DOC#{routeId} hoja 2 de 2</h2>
            </>
          }
        />
        <div>
          <CardHeader
            avatar={
              <Avatar
                src={newRoute.driverAvatar}
                sx={{ bgcolor: "#F2F2F2", color: "#050505" }}
                aria-label="recipe"
              ></Avatar>
            }
            title={
              newRoute.driverName ? (
                <span className="manifesFontsite20">{newRoute.driverName}</span>
              ) : (
                <>Seleccione un conductor</>
              )
            }
            subheader={
              <div className="manifesFontsite">
                {" "}
                Se ha asignado las siguientes órdenes del día{" "}
                {newRoute.dateDeliveryText} {", "}
                para completar las entregas al cliente final, por favor recuerda
                tomar todas la evidencias correctamente, que se solicitan el app
                BICCI RIDER
                <br></br> Retira en {newRoute.originAdressName}
                <br></br>
                <div className="manifesFontsite">
                  Total de Paquetes: {newRoute.totalPack}
                </div>
                <br></br>
                <div className="manifesFontsite">
                  Total de Puntos de entrega: {newRoute.routePoints}
                </div>
              </div>
            }
          />
        </div>
        <Box sx={{ width: "100%" }}>
          {manifesOrders ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Código</th>
                  <th scope="col">TIM</th>
                  <th scope="col">Cliente Destino</th>
                  <th scope="col">Fecha de envío</th>
                  <th scope="col">SOC</th>
                  <th scope="col">SKU</th>
                  <th scope="col">CANT</th>
                  <th scope="col">Conductor</th>
                  <th scope="col">Estatus</th>
                </tr>
              </thead>
              <tbody>
                {manifesOrders.map((element, index) => (
                  <tr key={index}>
                    {/* <td>    
                                    <input 
                                      type="checkbox"  
                                      defaultChecked={false} 
                                      id={element.id} 
                                      name={element.id}  
                                      onChange={handleSelecRiders}
                                      /> </td> */}
                    <td>{index + 1}</td>
                    <td>{element.orderID}</td>
                    <td>{element.F12}</td>
                    <td>{element.CLIENTENOMBRES}</td>
                    <td>{format(element.timeStamp * 1000, "dd/MM/yy")}</td>
                    <td>{element.SOC}</td>
                    <td>{element.SKU}</td>
                    <td>{element.UNIDADES}</td>
                    <td>nombre de conductor</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LinearProgress></LinearProgress>
          )}
        </Box>
      </Card>
    </div>
  );
  /*
  mapaDeRuta = (<DashPlannerEditMapRoute  
    setTriger={ setTriger} 
    companyId={companyId} 
    userUid={userdata} 
    setActiveIndex={setActiveIndex} 
    updateDataRouteOrderPiont={updateDataRouteOrderPiont} 
    newRoute={newRoute} 
    updatRdition={updatRdition} 
    manifesOrders={manifesOrders} 
    updateDataRouteOrderPiontByApi={updateDataRouteOrderPiontByApi}
    offOptimize={offOptimize}
    ></DashPlannerEditMapRoute>)

  documentPDF =(<>{newRoute && manifesOrders? (<PDFViewer style={{width:'100%', height:"90vh"}}> <PlanerDocPDF  newRoute={newRoute} routeDate={routeDate} manifesOrders={manifesOrders}></PlanerDocPDF></PDFViewer>): (null)}</>)
*/
  return (
    <>
      <Grid celled="internally" id="expresscuentaContent">
        {succesCreate ? (
          <div>
            <div className="alert alert-success m-3"> Nueva ruta creada </div>
          </div>
        ) : (
          <></>
        )}
        <Grid.Row>
          <Grid.Column
            computer={4}
            mobile={16}
            tablet={7}
            className="dash-container-colmun"
          >
            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Datos de Ruta" />
              <Divider />
              <Divider />
              <Card className="routeResumen">
                <CardContent className="routeResumen">
                  <table className="Vertical-table-width100">
                    <tbody>
                      <tr>
                        <th>Usuario conductor:</th>
                        <td>{newRoute.driverName}</td>
                      </tr>
                      <tr>
                        <th>Vehículo:</th>
                        <td>{newRoute.vehicleID}</td>
                      </tr>
                      <tr>
                        <th>Fecha de despacho:</th>
                        <td>{newRoute.dateDeliveryText}</td>
                      </tr>
                      <tr>
                        <th>Hora de inicio</th>
                        <td>{routeStartTimeDelivery}</td>
                      </tr>
                      <tr>
                        <th>Hora de final</th>
                        <td>{routeEndTimeDelivery}</td>
                      </tr>
                      <tr>
                        <th>Tiempo de espera</th>
                        <td>{routeStayTimeDelivery}</td>
                      </tr>
                    </tbody>
                  </table>
                  {validateSave()}
                  {errorData ? (
                    <div>
                      <div className="alert danger-alert">{errorData}</div>
                      <div>
                        {" "}
                        <button
                          className="resimanconfirmCancel"
                          onClick={confirmCancel}
                        >
                          {" "}
                          Salir sin guardar{" "}
                        </button>
                      </div>{" "}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardContent>
              </Card>
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Datos de Planeación" />
              {showEdit === true ? (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEdit(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEdit(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Editar
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  <table className="Vertical-table-width100">
                    <tbody>
                      <tr>
                        <th>Optimizar:</th>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={true}
                              name="activeOptimize"
                              onChange={handleChange}
                              //checked={optimizeWaypoints}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <th>Fecha de ruta:</th>
                        <td>
                          {" "}
                          <Form.Field
                            id="Form-Field-Edit-Route"
                            control={Input}
                            type="date"
                            iconPosition="left"
                            min={Moment(Date.now()).format("YYYY-MM-DD")}
                            // max="2022-08-10"
                            name="dispchDate"
                            onChange={(e) => handleChange(e)}
                            value={routeDate}
                            // blur={// console.log('blur accion')}
                            onClick={(e) => console.log(e.view)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Hora de inicio:</th>
                        <td>
                          {" "}
                          <Form.Field
                            id="Form-Field-Edit-Route-initHour"
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="initHour"
                            onChange={(e) => handleChange(e)}
                            value={routeStartTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Hora de final:</th>
                        <td>
                          {" "}
                          <Form.Field
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="endHour"
                            onChange={(e) => handleChange(e)}
                            value={routeEndTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Tiempo de espera</th>
                        <td>
                          {" "}
                          <Form.Field
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="awaitTime"
                            onChange={(e) => handleChange(e)}
                            value={routeStayTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Origen</th>
                        <td>
                          {directions ? (
                            <select
                              id="custom-selectFrom-comuna"
                              control="select"
                              onChange={(e) =>
                                handleChangeDirectionsOrigen(e.target.value)
                              }
                            >
                              <option> Seleccione una Sucursal... </option>

                              {directions.map((item, index) => (
                                <option key={item.id + index} value={item.id}>
                                  {item.adressName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            "No existen Direccciones"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Destino</th>
                        <td>
                          {directions ? (
                            <select
                              id="custom-selectFrom-comuna"
                              control="select"
                              onChange={(e) =>
                                handleChangeDirectionsDestiny(e.target.value)
                              }
                            >
                              <option> Seleccione una Sucursal... </option>

                              {directions.map((item, index) => (
                                <option key={item.id + index} value={item.id}>
                                  {item.adressName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            "No existen Direccciones"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Conductor" />
              <CardHeader
                avatar={
                  <Avatar
                    src={newRoute.driverAvatar}
                    sx={{ bgcolor: "#F2F2F2" }}
                    aria-label="recipe"
                  ></Avatar>
                }
                title={
                  newRoute.driverName ? (
                    <>{newRoute.driverName}</>
                  ) : (
                    <>Seleccione un conductor</>
                  )
                }
                subheader=""
              />
              {showEditDriver === true ? (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEditDriver(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEditDriver(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Selecciona un conductor
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEditDriver} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  {/* <Form.Group widths='equal' className='FormGroupRiders' >
                      <Form.Field
                          control={Input}
                          type = "text"
                          placeholder='Buscar ID Rider o Nombre'
                          iconPosition='left'
                          icon = 'user'
                          name="usuarioRider"
                          onChange={e => setUserRiderr(e.target.value)}
                          value ={userRider}
                          />
                        </Form.Group> */}

                  <Box
                    sx={{
                      width: "100%",
                      height: 350,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRows ? (
                      <DataGrid
                        className="DataGridStyle"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRows}
                        columns={columnsRiders}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Vehículo" />
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecobicci-1.png?alt=media&token=25fc24ac-26e1-43a0-8bc0-889c4afec5cf"
                    }
                    sx={{ bgcolor: "#F2F2F2" }}
                    aria-label="recipe"
                  ></Avatar>
                }
                title={
                  newRoute.vehicleID ? (
                    <>{newRoute.vehicleID}</>
                  ) : (
                    <>Seleccione un vehículo</>
                  )
                }
                subheader=""
              />
              {showEditVehicle === true ? (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEditVehicle(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      border: "none",
                      borderRadius: "3px",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "30px",
                      outline: "none",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      margin: "0em 1em 0em 0em",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowEditVehicle(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Selecciona un Vehículo
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEditVehicle} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  {/* <Form.Group widths='equal' className='FormGroupVehicle' >
                      <Form.Field
                                    control={Input}
                                    type = "text"
                                    placeholder='Patente/Placa'
                                    iconPosition='left'
                                    icon = 'truck'
                                    name="vehicle"
                                    onChange={e => setUserVehicle(e.target.value)}
                                    value ={userVehicle}
                                    />  </Form.Group> */}
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {vehiclesRows ? (
                      <DataGrid
                        sx={{
                          width: "100%",
                        }}
                        rows={vehiclesRows}
                        columns={columnsVehicle}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>
          </Grid.Column>
          <Grid.Column
            computer={12}
            mobile={16}
            tablet={9}
            className="dash-container-colmun"
          >
            <Tab
              className="tabular menu"
              menu={{ pointing: true }}
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={(e, { activeIndex }) => {
                setActiveIndex(activeIndex);
              }}
            ></Tab>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default DashRoutesEdit;
