import React from "react";
import { Card } from "@mui/material";
const ToolDashOrdersFilterDriver = (props) => {
  return (
    <Card className="typeMiniformsBars">
      <div className="m-2">
        <div> Agrupar por conductor</div>
      </div>
      {props.optionDriverFilter ? (
        <select
          id="custom-selectFrom-comuna"
          control="select"
          name="currentDriverFilter"
          onChange={(e) => props.onChageSubFilter(e)}
        >
          <option value={"itemClear"}> Seleccione una Conductor... </option>
          {props.optionDriverFilter.map((item, index) => (
            <option key={item + index} value={item}>
              {item}
            </option>
          ))}
        </select>
      ) : (
        "No data"
      )}
    </Card>
  );
};

export default ToolDashOrdersFilterDriver;
