import { db } from "../firebase";
import axios from "axios";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
} from "firebase/firestore";
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
};

const ORDER_LOADING_MOMENT = "ORDER_LOADING_MOMENT";
const ORDER_EDIT_USER_EBIEX = "ORDER_EDIT_USER_EBIEX";
const ORDER_EDIT_USER_ERROR = "ORDER_EDIT_USER_ERROR";
const ORDER_EDIT_USER_EBIEX_EXITO = "ORDER_EDIT_USER_EBIEX_EXITO";
const ORDER_FIND_INFORMATION_ERROR = "ORDER_FIND_INFORMATION_ERROR";

export default function orderClienteEbiex(state = dataInicial, action) {
  switch (action.type) {
    case ORDER_LOADING_MOMENT:
      return { ...state, loading: true };
    case ORDER_EDIT_USER_EBIEX:
      return { ...state, user: action.payload };
    case ORDER_EDIT_USER_EBIEX_EXITO:
      return { ...state };
    case ORDER_EDIT_USER_ERROR:
      return { loading: false };
    case ORDER_FIND_INFORMATION_ERROR:
      return { error: action.mjsstatus };
    default:
      return state;
  }
}

export const crearSolicitudDeOrden = (dataEbiex, data) => async (dispatch) => {
  // console.log('creando Orden bicci express')
  // // console.log(data.uid)
  let ebixeOrder = {
    ebixeOrderID: "",
    get bixeOrderID() {
      return this.ebixeOrderID;
    },
    set bixeOrderID(id) {
      this.ebixeOrderID = id;
    },
  };
  dispatch({
    type: ORDER_LOADING_MOMENT,
  });
  const companyID = data.companyID;
  const userName = data.userName;
  const userEmail = data.userEmail;
  const userPhone = data.userPhone;
  const groceriesNum = data.groceriesNum;
  const groceries_id = data.groceries_id;
  const groceries_name = data.groceries_name;
  const groceries_description = data.groceries_description;
  const groceries_quantity = data.groceries_quantity;
  const groceries_price = parseInt(data.groceries_price);
  const modifiersCant = data.modifiersCant;
  const modifiers_Name = data.modifiers_Name;
  const modifier_optionID = data.modifier_optionID;
  const modifier_optionName = data.modifier_optionName;
  const modifier_optionPrice = data.modifier_optionPrice;
  const modifier_optionQuantity = data.modifier_optionQuantity;
  const fromLocationlatitude = data.fromLocationlatitude;
  const fromLocationlongitude = data.fromLocationlongitude;
  const toLocationlatitude = data.toLocationlatitude;
  const toLocationlongitude = data.toLocationlongitude;
  const fromAddress = data.fromAddress;
  const toAddress = data.toAddress;

  try {
    //Consultar Contador
    const accountantsRef = doc(
      db,
      `${process.env.REACT_APP_COL_ACCOUNTANS}`,
      "ordersCounter"
    );
    const accountantsSnapshot = await getDoc(accountantsRef);
    const accountants = accountantsSnapshot.data();

    const documenCont = accountants + 1;
    ebixeOrder.ebixeOrderID = String(documenCont);
    const orderId = String(documenCont);

    const orderRef = doc(db, `${process.env.REACT_APP_COL_ORDERS}`, orderId);
    await setDoc(orderRef, {
      dispachtStatusChange: dataEbiex.dispachtStatusChange,
      dispachtStatusChangeDate: dataEbiex.dispachtStatusChangeDate,
      dispachtStatusNotes: dataEbiex.dispachtStatusNotes,
      dispachtCurrentStatus: dataEbiex.dispachtCurrentStatus,
      dispachtCode: dataEbiex.dispachtCode,
      packageCant: dataEbiex.packageCant,
      dispachtDeliveryDate: dataEbiex.dispachtDeliveryDate,
      dispachtRouteDate: dataEbiex.dispachtRouteDate,
      dispachtRetirementDate: dataEbiex.dispachtRetirementDate,
      originGeoNumAddressLat: dataEbiex.originGeoNumAddressLat,
      originGeoNumAddressLng: dataEbiex.originGeoNumAddressLng,
      toAddress: dataEbiex.toAddress,
      toAdressPhone: dataEbiex.toAdressPhone,
      toUserName: dataEbiex.toUserName,
      toUserEmail: dataEbiex.toUserEmail,
      toAdressName: dataEbiex.toAdressName,
      toAdressNumAddress: dataEbiex.toAdressNumAddress,
      toAdressNumAddressTemp: dataEbiex.toAdressNumAddressTemp,
      toAdressCity: dataEbiex.toAdressCity,
      toAdressComuna: dataEbiex.toAdressComuna,
      toAdressRegion: dataEbiex.toAdressRegion,
      toAdressCountry: dataEbiex.toAdressCountry,
      toCompanyAsignament: dataEbiex.toCompanyAsignament,
      toPropietary: dataEbiex.toPropietary,
      toAdressComent: dataEbiex.toAdressComent,
      dispachtDate: dataEbiex.dispachtDate,
      originAddress: dataEbiex.originAddress,
      originAdressCity: dataEbiex.originAdressCity,
      originAdressComuna: dataEbiex.originAdressComuna,
      originAdressName: dataEbiex.originAdressName,
      originAdressNumAddress: dataEbiex.originAdressNumAddress,
      originAdressPhone: dataEbiex.originAdressPhone,
      originAdressRegion: dataEbiex.originAdressRegion,
      originCompanyAsignament: dataEbiex.originCompanyAsignament,
      originPropietary: dataEbiex.originPropietary,
      originUserName: dataEbiex.originUserName,
      originAdressComent: dataEbiex.originAdressComent,
      packageContent: dataEbiex.packageContent,
      packageContentID: dataEbiex.packageContentID,
      packageContentnName: dataEbiex.packageContentnName,
      packageContentDescripcion: dataEbiex.packageContentDescripcion,
      packageContentQuantity: dataEbiex.packageContentQuantity,
      packageContentPrice: dataEbiex.packageContentPrice,
      packageModifiers: dataEbiex.packageModifiers,
      locations: dataEbiex.locations,
      toGeoNumAddressLat: dataEbiex.toGeoNumAddressLat,
      toGeoNumAddressLng: dataEbiex.toGeoNumAddressLng,
      packageCode: orderId,
      serviceCourier: "BICCI",
      courierID: "",
      courierStatus: "",
      courierDriverName: "",
      courierDriverLocationLat: "",
      courierDriverLocationLng: "",
      courierDriverAvatar: "",
    });
    // console.log('order Creada')
    const counterRef = doc(
      db,
      `${process.env.REACT_APP_COL_ACCOUNTANS}`,
      "ordersCounter"
    );
    await setDoc(counterRef, { count: documenCont });
  } catch (error) {
    // console.log(error)
  }
  // Desarrollo 4DgQJjhiFOVQ9wHVcjOL
  // Produccion FroY2JbeirD7B1MRYEXo companyID
  try {
    const data = JSON.stringify({
      data: {
        companyID: "bcY5lLGXfgyPOcdWJahe",
        userName: userName,
        userEmail: userEmail,
        userPhone: userPhone,
        groceries: [
          {
            id: groceries_id,
            name: groceries_name, //NO SOLO NUMEROS AL MENOS 2 CARACTERES ALFANUMERICOS
            description: groceries_description, //NO SOLO NUMEROS 2 CARACTERES ALFANUMERICOS
            quantity: groceries_quantity,
            price: groceries_price,
            modifiers: [
              {
                modifierName: modifiers_Name,
                optionID: modifier_optionID,
                optionName: modifier_optionName,
                optionPrice: modifier_optionPrice,
                optionQuantity: modifier_optionQuantity,
              },
            ],
          },
        ],
        fromLocation: {
          latitude: fromLocationlatitude,
          longitude: fromLocationlongitude,
        },
        toLocation: {
          latitude: toLocationlatitude,
          longitude: toLocationlongitude,
        },
        fromAddress: fromAddress,
        toAddress: toAddress,
      },
    });
    // console.log(data)
    const response = await axios.post(
      process.env.REACT_APP_BICCI_API_PRO,
      data,
      {
        // Produccion
        //const response = await axios.post(process.env.REACT_APP_BICCI_API_DEV, data, {      // Desarrollo
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // // console.log(response)
    // // console.log('Flujo Actualizados')
    // console.log(response.data.result)
    // // console.log(response.data.result.code)
    // // console.log(response.data.result.message)
    //  // console.log(response.data.result.orderID)
    if (response.data.result.code === 200) {
      var dispachtCode = response.data.result.orderID;
      try {
        let orderUpdate = ebixeOrder.ebixeOrderID;
        // console.log('id: ' +orderUpdate)
        const orderRef = doc(
          db,
          `${process.env.REACT_APP_COL_ORDERS}`,
          orderUpdate
        );
        await updateDoc(orderRef, { dispachtCode: dispachtCode });

        const historialRef = collection(orderRef, "historial");
        await addDoc(historialRef, {
          historialOrder: 1,
          dispachtID: response.data.result.orderID,
          dispachtStatusChangeDate: Date.now(),
          dispachtCurrentStatus: "C0E",
          issues: "Orden Creada en Bicci",
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No se recibe respuesta del servicio");
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: ORDER_EDIT_USER_ERROR,
    });
  }
};
