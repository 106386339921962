import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Card, CardContent, Button } from "@mui/material";
import FromShippingOrigin from "./FromShippingOrigin";
import FromShippingTo from "./FromShippingTo";
import FromShippingCurriers from "./FromShippingCurriers";
import FromShippingContainer from "./FromShippingContainer";
import FromShippingPaiments from "./FromShippingPaiments";
import FromShippingVehicle from "./FromShippingVehicle";
import FromShippingCarga from "./FromShippingCarga";
import FromShippingAccions from "./FromShippingAccions";
import {
  emptyOrder,
  orderValidationSchema,
} from "../../utils/objects/odersBody";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import FromShippingDescriptions from "./FromShippingDescriptions";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DebugObjectChanges from "../../utils/DebugObjectChanges/DebugObjectChanges";
import FromShippingInt from "./FromShippingInt";

const FromShippingWiew = ({
  userDataContext,
  prefix,
  companyData,
  dataOrigin,
}) => {
  const formatDeliveryDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const [recentCreated, setRecentCreated] = useState(false);

  const [dataDirectory, setDataDirectory] = useState([]);
  const [order, setOrder] = useState(emptyOrder);

  const [payments, setPayments] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [carga, setCarga] = useState(null);
  const curriers = [{ a: 1 }, { b: 2 }];

  useEffect(() => {
    if (order) {
      findAdressesCompany(userDataContext.companyID);
    }
  }, [order]);
  useEffect(() => {
    if (companyData) {
      //  console.log(companyData);
      setOrder((prevState) => ({
        ...prevState,
        dataOrigin: "FROM",
        company: companyData.companyName,
        companyId: companyData.companyID,
        commerce: companyData.companyName,
      }));
    }
  }, [companyData]);

  const findAdressesCompany = async (companyUid) => {
    try {
      const direccionesRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${companyUid}/direcciones`
      );
      const direccionesSnapshot = await getDocs(direccionesRef);
      const adressses = direccionesSnapshot.docs.map((doc) => doc.data());
      const adreessFilter = adressses.filter((item) => item.delete !== true);
      setDataDirectory(adreessFilter);
    } catch (error) {}
  };

  const handleOriginChange = (data) => {
    // console.log(data);
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleDestinationChange = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleCurrirer = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleContainer = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handlePaimets = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleVehicle = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleCarga = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleIntegrationsRequirement = (data) => {
    setOrder((prevState) => ({
      ...prevState,
      data,
    }));
  };

  function getToAdressDirection(adress1, adress2, adress3, adress4, adress5) {
    let a1 = adress1 ? adress1.toString() : null;
    a1 += adress2 ? " " + adress2.toString() : "";
    a1 += adress3 ? ", " + adress3.toString() : "";
    a1 += adress4 ? ", " + adress4.toString() : "";
    a1 += adress5 ? ", " + adress5.toString() : "";

    return { a1 };
  }

  function ExcelDateToJSDate(serial, id, typeDate) {
    try {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      //let fecha  = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      let fechaMasUno = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
      fechaMasUno.setDate(fechaMasUno.getDate() + 1);

      let fechaMasUnoFormat = format(fechaMasUno, "dd/MM/yy HH:mm:ss", {
        locale: es,
      });

      //let fecha  = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      let fechaActual = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
      fechaActual.setDate(fechaActual.getDate());

      return { fechaMasUnoFormat, fechaMasUno, fechaActual };
    } catch (error) {
      let fechaMasUnoFormat = "";
      let fechaMasUno = "";
      let fechaActual = "";
      return { fechaMasUnoFormat, fechaMasUno, fechaActual };
    }
  }

  const handleSave = async (formData) => {
    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    const upsertDocument = async (docData) => {
      let id = docData.id || generarCadenaAleatoria(10);
      let order = {};
      order.key = prefix + docData.SOC;

      let time = ExcelDateToJSDate(docData.RANGOFECHAPACTADA);
      let timeTravelDate = ExcelDateToJSDate(docData.FECHAVIAJE);

      let toAddress = getToAdressDirection(
        docData.direccion1,
        docData.direccion2,
        docData.direccion3,
        docData.commune,
        docData.commune2
      );

      order.dataOrigin = dataOrigin ? dataOrigin : "DATA";
      order.key = prefix + docData.orderID;
      order.orderID = prefix + docData.orderID.toString();
      order.id = docData.orderID.toString();
      order.company = companyData.companyName;
      order.companyId = companyData.companyID;
      order.commerce = companyData.companyName;
      order.int_warehouseCode = docData.int_warehouseCode;
      order.int_warehouseName = docData.int_warehouseName;
      order.int_placeName = docData.int_placeName; // Validar codido
      order.int_travelDate = timeTravelDate.fechaMasUnoFormat; // Validar codido
      order.int_f12 = docData.int_f12;
      order.int_client_soc = docData.int_client_soc;
      order.int_lpn = docData.int_lpn;
      order.int_serviceLevel = docData.int_serviceLevel;
      order.int_serviceType = docData.int_serviceType
        ? docData.int_serviceType
        : "All";
      order.userPhone = docData.userPhone ? docData.userPhone : 0;
      order.clientNames = docData.clientNames;
      order.clientRut = docData.clientRut;
      order.clientEmail = docData.clientEmail
        ? docData.clientEmail
        : "NO INFORMA";
      order.toAddress = toAddress.a1;
      order.direccion1 = docData.direccion1;
      order.direccion2 = docData.direccion2 ? docData.direccion2 : "";
      order.direccion3 = docData.direccion3 ? docData.direccion3 : "";
      order.commune = docData.commune;
      order.commune2 = docData.commune2 ? docData.commune2 : "";
      order.region = docData.region ? docData.region : "";
      order.serviceType = docData.serviceType;
      order.deliveryDate = timeTravelDate.fechaMasUnoFormat; // Validar codido
      order.timeStamp = time.fechaMasUno; // Validar codido
      order.createdAt = new Date();
      order.description = docData.description
        ? docData.description
        : "NO INFORMA";
      order.zonePoligon = "";
      order.pickedUp = docData.pickedUp;
      order.productLength = docData.productLength;
      order.productHeight = docData.productHeight;
      order.productWidth = docData.productWidth;
      order.productWeight = docData.productWeight;
      order.productName = docData.productName
        ? docData.productName
        : "NO INFORMA";
      order.productSku = docData.productSku ? docData.productSku : "NO INFORMA";
      order.productSize = docData.productSize;
      order.productUnits = docData.productUnits ? docData.productUnits : 1;
      order.productValue = docData.productValue;
      order.productVolume = docData.productVolume;
      order.productMultiDelivery = docData.productMultiDelivery;
      order.transportOrderSize = docData.transportOrderSize;
      order.routeEstimatedDate = time.fechaMasUnoFormat;
      order.routeStartDate = time.routeStartDate;
      order.routeStartTime = docData.routeStartTime;
      order.routeEndTime = docData.routeEndTime;
      order.routeLocality = docData.routeLocality;
      const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
      const docRef = doc(collRef, order.key);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, order);
        //console.log("Documento actualizado con éxito.", order);
      } else {
        await setDoc(docRef, order);
        //console.log("Documento creado con éxito.", order);
      }
    };

    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }
  };

  const handleNew = async () => {
    setRecentCreated(false);
  };
  const navigateOredes = () => {};

  return (
    <Box
      sx={{
        padding: 3,
        with: "100%",
        height: "100%",
        background:
          "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
        color: "#f8f8f8",
      }}
    >
      {!recentCreated ? (
        <>
          <Grid sx={{ with: "100%" }}>Envío Express</Grid>
          <DebugObjectChanges objectValidate={order}></DebugObjectChanges>
          <Grid container sx={{ with: "100%" }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "30%",
                  lg: "30%",
                },
              }}
            >
              <FromShippingOrigin
                onChange={handleOriginChange}
                dataOrigin={dataOrigin}
                dataDirectory={dataDirectory}
                handleDataOrder={handleOriginChange}
                companyData={companyData}
              ></FromShippingOrigin>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "30%",
                  lg: "30%",
                },
              }}
            >
              <FromShippingTo
                onChange={handleOriginChange}
                dataOrigin={dataOrigin}
                dataDirectory={dataDirectory}
                handleDataOrder={handleOriginChange}
                companyData={companyData}
              ></FromShippingTo>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "20%",
                  lg: "20%",
                },
              }}
            >
              <FromShippingDescriptions
                onChange={handleOriginChange}
                dataOrigin={dataOrigin}
                dataDirectory={dataDirectory}
                handleDataOrder={handleOriginChange}
                companyData={companyData}
              ></FromShippingDescriptions>
              {/* <FromShippingInt // DESARROLLO
            onChange={handleContainer}
            order={order}
          ></FromShippingInt> */}
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "20%",
                  lg: "20%",
                },
              }}
            >
              <FromShippingContainer
                onChange={handleContainer}
                order={order}
              ></FromShippingContainer>
            </Grid>
          </Grid>
          <Divider
            sx={{ marginTop: 1, marginBottom: 1, backgroundColor: "#f8f8f8" }}
          ></Divider>
          <Grid container sx={{ with: "100%" }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "30%",
                  lg: "30%",
                },
              }}
            >
              <FromShippingAccions
                order={order}
                companyData={companyData}
              ></FromShippingAccions>
            </Grid>

            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "15%",
                  lg: "15%",
                },
              }}
            >
              <FromShippingCarga
                onChange={handleCarga}
                data={carga}
              ></FromShippingCarga>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "15%",
                  lg: "15%",
                },
              }}
            >
              {" "}
              <FromShippingVehicle
                onChange={handleVehicle}
                data={vehicle}
              ></FromShippingVehicle>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "15%",
                  lg: "15%",
                },
              }}
            >
              {" "}
              <FromShippingPaiments
                onChange={handlePaimets}
                data={vehicle}
              ></FromShippingPaiments>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "15%",
                  lg: "15%",
                },
              }}
            >
              <FromShippingCurriers
                onChange={handleCurrirer}
                data={curriers}
              ></FromShippingCurriers>
            </Grid>
            <hr></hr>
            <Divider></Divider>
          </Grid>
        </>
      ) : (
        <>
          {" "}
          <Card>
            <CardContent>
              Has creado un envio exitosamente, deseas crear otro ?
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {" "}
                <Button onClick={handleNew}> Nuevo Envío</Button>
                <Button onClick={navigateOredes}> Ir a mís envíos</Button>
              </Box>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default FromShippingWiew;
