import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

const RutaProtegida = ({
  component: Component,
  usuarioStorage,
  firebaseUser,
  ...rest
}) => {
  if (!firebaseUser || !usuarioStorage) {
    return <Navigate to="/login" />;
  }

  if (firebaseUser.uid === usuarioStorage.uid) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
};

export default RutaProtegida;
