import React, {
  useState,
  useContext,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  Suspense,
  lazy,
} from "react";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { db } from "../../../firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { PDFViewer } from "@react-pdf/renderer";
import Moment from "moment";
import CierreOpsPdf from "./CierreOpsPdf";
import useGlobalConfig from "../../../hooks/globalConfig";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import * as XLSX from "xlsx";
import ToolDashResumenOps from "../../../components/Dashboard/dashboard/ToolDashResumenOps/ToolDashResumenOps";
import ModalAccionsDispacthDirectionsResolve from "../../../components/ModalAccionsDispacth/ModalAccionsDispacthDirectionsResolve";
import ToolDashAppBar from "../../../components/Dashboard/dashboard/ToolDashAppBar/ToolDashAppBar";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import FadeInOut from "../../../components/animations/FadeInOut";
import { retiredPodArray } from "../../../utils/objects/retiredPodArray";
import generarReporteExcepciones from "../../../utils/Reports/generarReporteExcepciones";
import generarReporteDiscrepanciasPiking from "../../../utils/Reports/generarReporteDiscrepanciasPiking";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament";
import CreateManiFestByCurrentListPdf from "../../../components/CreateManiFestByCurrentListPdf/CreateManiFestByCurrentListPdf";
import { pdf } from "@react-pdf/renderer";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";
import { translateStatesfalabbela } from "../../../utils/objects/translateStates";
import DataGridoOders from "../../../components/DataGridoOders/DataGridoOders";
import * as utils from "../../../utils/objects/BicciExpressOrderStatuses";

var listadeItemsNoMach = [];
var popUpAccion = "-";

const UserListViewOrdersIntFalabella = lazy(() =>
  import(
    "../../../components/UserListViewOrdersIntFalabella/UserListViewOrdersIntFalabella"
  )
);

const GuiasByFalabella = (props) => {
  const location = useLocation();
  const isGuiasFalabellaList = useMemo(
    () => location.pathname.includes("/guiasfalabella/list"),
    [location.pathname]
  );

  const listItems = utils.listItems;

  const { rosettaVehicles, translateStateBicciFalabella, falabellaExcepcions } =
    useGlobalConfig();

  const { widthHeight, movilActive } = useContext(ScreenSizeContext);

  const userUid = useSelector((store) => store.usuario.user.uid);

  const companyId = useMemo(() => {
    if (props.userdata) {
      if (props.userdata.companyCollaborator.includes("falabella")) {
        return `${process.env.REACT_APP_BXTOKETN}`;
      }
      return props.userdata.userCompanyID;
    }
    return "";
  }, [props.userdata]);

  const { VehiclesNames } = useGetVehiclesNames();

  const [falabellaOrders, setFalabellaOrders] = useState([]);

  const [BicciOrders, setBicciOrders] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [datailInsures, setDetailInsures] = useState([]);

  const [userOrders, setUserOrders] = useState(null);

  const [progressValue, setProgressValue] = useState(0);

  const [wiewAssignmentIndIcon, setWiewAssignment] = useState(true);

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);

  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);

  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );

  const [cantListItems, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const [menssajeAdmin, setMenssajeAdmin] = useState(null);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [staticDateBillingFilter, setStaticDateBillingFilter] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  const [render, setRender] = useState(true);

  const [realTimeisActive, setRealTimeisActive] = useState(false);

  const [containerHistory, setContainerHistory] = useState({});

  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);

  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [showPopUp, setShowPopUp] = useState(false);
  const [selected, setSelected] = useState(null);
  const [origenGeoCode, setOrigenGeoCode] = useState(null);
  const [adressOrigin, setAdressOrigin] = useState(null);
  const [adressDestiny, setAdressDestiny] = useState(null);
  const [destinyGeoCode, setDestinyGeoCode] = useState(null);
  const [listIdstoSelct, setListIdstoSelct] = useState("");
  const [SelecteDestiny, setSelecteDestiny] = useState(null);
  const [CheckedChangeOE, setCheckedChangeOE] = useState(false);
  const [vertical, setVertical] = useState("");
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);
  const [ordersHistoryGetProgres, setOrdersHistoryGetProgres] = useState(0);
  const [typeRequets, setTypeRequets] = useState({
    typeRequetsCreate: false,
    typeRequetsUpdate: true,
  });

  const [resetCicle, setResetCicle] = useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [bicciOrdersExcepData, setBicciOrdersExcepData] = useState([]);

  const [wiewPrePdf, setWiewPrePdf] = useState(false);

  const [Buttondisable, setButtondisable] = useState(true);

  const [typeRquest, setTypeRquest] = useState(false);

  const isMounted = useRef(true);

  const toggleShowRefres = () => setRender(!render);

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  // const { executeBicciMerge, bicciMerge } = useBicciFalabellaMerge("HD");

  // console.log("bicciMerge", bicciMerge);

  useEffect(() => {
    setVertical(props.vertical);
  }, [props && props.vertical]);

  useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispachtFalabella(
        initDate,
        endDate,
        typeRequets,
        companyId,
        props.vertical
      );
      findOrdesrDispachtBicci(
        initDate,
        endDate,
        typeRequets,
        companyId,
        props.vertical
      );
    }
  }, [companyId, initDate, endDate, props && props.vertical]);

  useEffect(() => {}, [itemsDataOrdersForAccions]);

  useEffect(() => {
    if (
      BicciOrders.length > 0 ||
      falabellaOrders.length > 0 ||
      bicciOrdersExcepData.length > 0
    ) {
      setMenssajeAdmin(<span className="alert alert-info">Buscando... </span>);

      BiccidataMerge();
    }
  }, [BicciOrders, falabellaOrders, bicciOrdersExcepData]);

  useEffect(() => {
    isMounted.current = true;
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando Ordenes... </span>
    );

    findOrdesrDispachtFalabella(
      initDate,
      endDate,
      typeRquest,
      companyId,
      props.vertical
    );
    findOrdesrDispachtBicci(
      initDate,
      endDate,
      typeRequets,
      companyId,
      props.vertical
    );
    return () => {
      isMounted.current = false;
    };
  }, [CheckedChangeOE, typeRequets, props.vertical]);

  useEffect(() => {
    if (dataMerge) {
      setMenssajeAdmin(
        <span className="alert alert-info">Buscando Ordenes... </span>
      );
      getResumenOps();
      getResumenRoutes();
      getInsuresMetrics();
    }
  }, [dataMerge]);

  useEffect(() => {
    let interval;
    if (realTimeisActive === true) {
      interval = setInterval(() => {
        resetCicle.currentRequest += 1;
        setCheckedChangeOE(false);
        findOrdesrDispachtFalabella(
          initDate,
          endDate,
          typeRquest,
          companyId,
          props.vertical
        );
        findOrdesrDispachtBicci(
          initDate,
          endDate,
          typeRequets,
          companyId,
          props.vertical
        );

        if (resetCicle.maxRequest === resetCicle.currentRequest) {
          setRender(!render);
        }
        if (resetCicle.desactiveRealtime === resetCicle.currentRequest) {
          setRealTimeisActive(!realTimeisActive);
          setRender(!render);
        }
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [realTimeisActive]);

  useEffect(() => {
    if (listIdstoSelct) {
      selecListItames(listIdstoSelct);
    }
  }, [listIdstoSelct]);

  useEffect(() => {
    // console.log(dataMerge);
  }, [render]);

  const findOrdesrDispachtFalabella = async (
    initDate,
    endDate,
    typeRquest,
    companyId
  ) => {
    //console.log("findOrdesrDispacht Falabella");
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Falabella </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "updatedAt";
    }
    setProgressValue(55);

    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );
    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }
    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate());
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    function formatDate(fecha) {
      var res = new Date(fecha);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangFinal = sumarDos(e);
    var rangInitial = formatDate(i);
    const dataOrder = {
      data: "",
      get dataEbiex() {
        return this.data;
      },
      set dataEbiex(data) {
        this.data = data;
      },
    };
    dataOrder.data = [];
    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    async function callExceptions() {
      //console.log("findOrdesrDispacht Exceptions");
      setProgressValue(45);
      try {
        const allOrdersRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
        const querySnapshot = await getDocs(q);

        const dataOrdesHasExcepciones = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dataOrdesHasExcepciones.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );
        let filtrarSolucionados = dataOrdesHasExcepciones.filter(
          (item) =>
            (item.falabella_status !== "1029") &
            (item.falabella_status !== "1046") &
            (item.falabella_status !== "1023") &
            (item.falabella_status !== "1081") &
            (item.falabella_status !== "1007") &
            (item.falabella_status !== "99")
        );

        let dataOrdesfilterHasExcepciones = filtrarSolucionados.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        let clearRang = dataOrder.data;
        const arrayId = clearRang.map((obj) => obj.id);

        let clearDataOrdesfilterHasExcepciones =
          dataOrdesfilterHasExcepciones.filter(
            (item) => !arrayId.includes(item.id.toString())
          );

        if (clearDataOrdesfilterHasExcepciones.length > 0) {
          //console.log(clearDataOrdesfilterHasExcepciones);
          let arryExcepcion = [];
          for (let i = 0; i < clearDataOrdesfilterHasExcepciones.length; i++) {
            arryExcepcion.push("F" + clearDataOrdesfilterHasExcepciones[i].id);
          }
          // console.log(arryExcepcion);
          try {
            const ordersCollectionRef = collection(
              db,
              process.env.REACT_APP_COL_USERS_ORDERS
            );
            const querySnapshot = await getDocs(
              query(ordersCollectionRef, where("id", "in", arryExcepcion))
            );
            const orders = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            //console.log("orders");
            //console.log(orders);

            setBicciOrdersExcepData(orders); // En test
          } catch (error) {}
          if (CheckedChangeOE === false) {
            // console.log("Mostrar incidencias activas");
            const resultoFind = dataOrdesfilterHasExcepciones.concat(
              dataOrder.data
            );

            //setResumenTable(resultoFind);
            setFalabellaOrders(resultoFind);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }

          if (CheckedChangeOE !== false) {
            //console.log("Ocultar incidencias activas");
            //setResumenTable(dataOrder.data);
            setFalabellaOrders(dataOrder.data);
            setMenssajeAdmin(null);

            setMenssajeAdmin(null);
          }
        } else {
          //setResumenTable(dataOrder.data);
          setFalabellaOrders(dataOrder.data);
          setProgressValue(100);
          setDataMerge(dataOrder.data); // QA
        }
        //await BiccidataMerge();
      } catch (error) {}

      toggleShowRefres();
    }

    async function callNormal() {
      try {
        const q = query(
          allOrdersRef,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", endRang)
        );
        const querySnapshot = await getDocs(q);
        var dataOrdes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        //   //////console.log(dataOrdes)

        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //console.log(error);
      }
    }

    async function callIgual() {
      try {
        const dataallOrdersfind5 = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(
          dataallOrdersfind5,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", rangFinal)
        );
        const querySnapshot5 = await getDocs(q);
        var dataOrdes = querySnapshot5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        ////console.log(dataOrdes)
        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );
        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const findOrdesrDispachtBicci = async (
    initDate,
    endDate,
    typeRequets,
    companyId
  ) => {
    //console.log("findOrdesrDispacht Bicci");
    setProgressValue(65);
    setMenssajeAdmin(
      <span className="alert alert-info">Buscando... Ordenes Bicci </span>
    );
    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "createdAt";
    }

    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate() - 5);
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(i);
    var rangFinal = sumarDos(e);

    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    setMenssajeAdmin(
      <span className="alert alert-info">
        Creando resumenes Riders, Vehiculos y Ordenes{" "}
      </span>
    );

    async function callNormal() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", i),
          where(temptypeRquestCall, "<=", endRang)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (bicciOrdersExcepData.length > 0) {
          let mergedate = bicciOrdersExcepData.concat(dataOrdesBicci);
          setBicciOrders(mergedate);
        } else {
          setBicciOrders(dataOrdesBicci);
        }
      } catch (error) {
        //console.log(error);
      }
    }

    async function callIgual() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", rangInit),
          where(temptypeRquestCall, "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const BiccidataMerge = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Uniendo data... </span>
    );
    var newdataMere = [];
    var DataOrders = BicciOrders;
    var DataOrdersExcep = bicciOrdersExcepData;

    async function initMerge(newdataMere) {
      const uniqueMap = new Map();

      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        }

        return Array.from(uniqueMap.values());
      }

      var DataMerge = await removeDuplicatesById(falabellaOrders);

      for (let i = 0; i < DataMerge.length; i++) {
        for (let n = 0; n < newdataMere.length; n++) {
          let socf = "F" + DataMerge[i].id;

          if (socf === newdataMere[n].id) {
            DataMerge[i].isPickedUp = newdataMere[n].isPickedUp
              ? newdataMere[n].isPickedUp
              : false;
            DataMerge[i].placeName = newdataMere[n].placeName;
            DataMerge[i].driverName = newdataMere[n].driverName;
            DataMerge[i].driverAvatar = newdataMere[n].driverAvatar;
            DataMerge[i].driverID = newdataMere[n].driverID;
            DataMerge[i].userNameReceptor = newdataMere[n].userNameReceptor;
            DataMerge[i].userDniReceptor = newdataMere[n].userDniReceptor;
            DataMerge[i].pics = newdataMere[n].pics;
            DataMerge[i].toAddressLocation = newdataMere[n].toAddressLocation;
            DataMerge[i].observationReceptor =
              newdataMere[n].observationReceptor;
            DataMerge[i].observationAdmin = newdataMere[n].observationAdmin;
            DataMerge[i].exceptionGeoRef = newdataMere[n].exceptionGeoRef;
            DataMerge[i].driverLicensePlate = newdataMere[n].driverLicensePlate;
            DataMerge[i].orderNumber = newdataMere[n].orderNumber;
            DataMerge[i].upDatedAtText = format(
              DataMerge[i].updatedAt.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
            DataMerge[i].dateCreateText = format(
              DataMerge[i].date.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
          }
        }
      }
      /*
      const elements = [];
      for (let n = 0; n < DataMerge.length; n++) {
        // elements.push({
        //   latitud: DataMerge[n].toAddressLocation.latitude,
        //   longitud: DataMerge[n].toAddressLocation.longitude,
        // });

        elements.push({
          id: DataMerge[n].id,
          geoRef: {
            latitud: DataMerge[n].toAddressLocation.latitude,
            longitud: DataMerge[n].toAddressLocation.longitude,
          },
        });
      }
      console.log(elements);
      */

      const filteredDataOrdersExcepVertical = DataMerge.filter((item) => {
        if (vertical === "all") {
          return true; // Incluye todos los tipos
        } else if (vertical === "HD") {
          return item.delivery_type === "HD" || item.delivery_type === "HDF";
        } else {
          return item.delivery_type === vertical; // Para "SD" o cualquier otro tipo específico
        }
      });
      //    console.log(filteredDataOrdersExcepVertical);
      setDataMerge(filteredDataOrdersExcepVertical);
      setResumenTable(filteredDataOrdersExcepVertical);

      // console.log(DataMerge);

      // console.log(DataMerge);
      // setDataMerge(DataMerge);
      // setResumenTable(DataMerge);
    }
    //console.log("newdataMere");
    //console.log(falabellaOrders);
    //console.log(BicciOrders);
    if (DataOrders.length > 0 || DataOrdersExcep.length > 0) {
      const dataOrdersIdSet = new Set(DataOrders.map((item) => item.id));
      const filteredDataOrdersExcep = DataOrdersExcep.filter(
        (item) => !dataOrdersIdSet.has(item.id)
      );

      newdataMere = DataOrders.concat(filteredDataOrdersExcep);
    }
    //console.log(newdataMere);
    await initMerge(newdataMere);
  };

  const getResumenOps = async () => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const comunasDelivery = [
        ...new Set(dataMerge.map((order) => order.commune2)),
      ];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.local_provider)),
      ];

      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.local_provider;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === "99") {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = await getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID;

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName,
            driverID: order.driverID,
            email: order.email,
            driverAvatar: order.driverAvatar,
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [],
            countersListOrders: [],
            countersIssuresListOrders: [],
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);

        if (order.falabella_status === "99") {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
        }

        const comunaIndex = acc[driverID].locality.findIndex(
          (comuna) => Object.keys(comuna)[0] === order.commune2
        );

        if (comunaIndex >= 0) {
          acc[driverID].locality[comunaIndex][order.commune2]++;
        } else {
          const newComuna = { [order.commune2]: 1 };
          acc[driverID].locality.push(newComuna);
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));

      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const getResumenRoutes = async () => {
    if (!dataMerge || dataMerge.length === 0) {
      setMetricsOpsRoutes([]);
      return;
    }

    try {
      const getOrdersByRoute = (data) => {
        return data.reduce((acc, order) => {
          const route = order.driverLicensePlate || "noRoute";

          if (!acc[route]) {
            acc[route] = {
              route,
              delivered: 0,
              withIssues: 0,
              assigned: 0,
              pending: 0,
              finalized: 0,
              NS: 0,
              comunas: {},
              countersListOrders: [],
              countersIssuresListOrders: [],
            };
          }

          if (order.status !== "99") {
            acc[route].withIssues++;
          } else {
            acc[route].delivered++;
          }
          acc[route].assigned++;

          if (order.falabella_status === "some_pending_status_code") {
            acc[route].pending++;
          } else if (order.falabella_status === "some_finalized_status_code") {
            acc[route].finalized++;
          } else if (order.falabella_status === "some_ns_status_code") {
            acc[route].NS++;
          }

          const comuna = order.commune2;
          if (!acc[route].comunas[comuna]) {
            acc[route].comunas[comuna] = 1;
          } else {
            acc[route].comunas[comuna]++;
          }

          acc[route].countersListOrders.push(order.id);
          if (order.falabella_status !== "99") {
            acc[route].countersIssuresListOrders.push(order.id);
          }

          return acc;
        }, {});
      };

      const listRoutesData = getOrdersByRoute(dataMerge);

      setMetricsOpsRoutes(Object.values(listRoutesData));
    } catch (error) {
      console.log(error);
    }
  };

  const getInsuresMetrics = async () => {
    // Crear el objeto detailInsures
    const detailInsuresReq = dataMerge.reduce((acc, order) => {
      const statusKey = String(order.falabella_status);
      if (!translateStatesfalabbela[statusKey]) {
        console.log(
          `"estado desconocido :", ${statusKey}, "Folio : ", ${order.id}`
        );
      }
      if (!acc[statusKey]) {
        acc[statusKey] = {
          id: statusKey,
          name: translateStatesfalabbela[statusKey] || "Desconocido",
          cant: 0,
        };
      }
      acc[statusKey].cant++;
      return acc;
    }, {});

    setDetailInsures(Object.values(detailInsuresReq));
  };

  const onChageRange = (e) => {
    console.log(e);
    // setProgressValue(20);
    // if (e.target.name === "rangoInicio") {
    //   setInitDate(e.target.value);
    // }
    // if (e.target.name === "rangoFinal") {
    //   setEndDate(e.target.value);
    // }
    // if (e.target.name === "staticDateBillingFilter") {
    //   setInitDate(e.target.value);
    //   setEndDate(e.target.value);
    //   setStaticDateBillingFilter(e.target.value);
    // }
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    // setProgressValue(20);
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
    // console.log(createDate);
    // console.log(FinalDate);
  };

  const setResumenTable = (dataOrdes) => {
    if (dataOrdes && dataOrdes.length === 0) {
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
    } else {
      setMenssajeAdmin(null);
    }

    let Creadas = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1058" || item.falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.falabella_status === "99"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1060" || item.falabella_status === "1061"
    );
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.falabella_status)
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const UpdateOrderForAcctions = (items) => {
    console.log(items);
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === "F" + tempuserOrders[n].id) {
          //  console.log("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  async function selecListItames(listIdstoSelct) {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    listIdstoSelct = listIdstoSelct.trim();
    var listadeItems2 = listIdstoSelct.split(/[,\s]+/);

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems2.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems2[i] === "F" + tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems2.length; index++) {
      if (!ordersForAccions.includes(listadeItems2[index])) {
        listadeItemsNoMach.push(listadeItems2[index]);
      }
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  }
  // Optimizado
  const handleClickSelectRiderCurrentList = useCallback(
    (e, item) => {
      e.preventDefault();

      listadeItemsNoMach = [];
      let ordersForAccions = new Set();
      let dataOrdersForAccions = [];
      let tempuserOrders = [...dataMerge]; // Usar spread operator para crear una copia del array
      let listadeItems = new Set(item.countersListOrders);

      const tempuserOrdersMap = new Map();
      tempuserOrders.forEach((order) => {
        order.setectedItem = false;
        tempuserOrdersMap.set(order.id, order);
      });

      listadeItems.forEach((id) => {
        const order = tempuserOrdersMap.get(id);
        if (order) {
          order.setectedItem = true;
          ordersForAccions.add("F" + id);
          dataOrdersForAccions.push(order);
        }
      });

      listadeItems.forEach((id) => {
        if (!ordersForAccions.has("F" + id)) {
          listadeItemsNoMach.push(id);
        }
      });

      setUserOrders(tempuserOrders);
      setDataMerge(tempuserOrders);

      if (ordersForAccions.size > 0) {
        setItemsDataOrdersForAccions(true);
        setRoutesDataOrdersForAccions(dataOrdersForAccions);
        setRoutesOrdersForAccions(Array.from(ordersForAccions));
      } else {
        setItemsDataOrdersForAccions(false);
      }

      setRender((prevRender) => !prevRender);
    },
    [dataMerge]
  );

  // Optimizado
  const handleClickSelectRiderPendintList = useCallback(
    (e, item) => {
      e.preventDefault();

      listadeItemsNoMach = [];
      let ordersForAccions = new Set();
      let dataOrdersForAccions = [];
      let tempuserOrders = [...dataMerge]; // Usar spread operator para crear una copia del array
      let listadeItems = new Set(item.countersListOrders);

      try {
        const tempuserOrdersMap = new Map();
        tempuserOrders.forEach((order) => {
          order.setectedItem = false;
          tempuserOrdersMap.set(order.id, order);
        });

        listadeItems.forEach((id) => {
          const order = tempuserOrdersMap.get(id);
          if (
            order &&
            (order.falabella_status === "1060" ||
              order.falabella_status === "1061")
          ) {
            order.setectedItem = true;
            ordersForAccions.add("F" + id);
            dataOrdersForAccions.push(order);
          }
        });

        listadeItems.forEach((id) => {
          if (!ordersForAccions.has("F" + id)) {
            listadeItemsNoMach.push(id);
          }
        });

        setUserOrders(tempuserOrders);
        setDataMerge(tempuserOrders);

        if (ordersForAccions.size > 0) {
          setItemsDataOrdersForAccions(true);
          setRoutesDataOrdersForAccions(dataOrdersForAccions);
          setRoutesOrdersForAccions(Array.from(ordersForAccions));
        } else {
          setItemsDataOrdersForAccions(false);
        }

        setRender((prevRender) => !prevRender);
      } catch (error) {
        console.log(error);
      }
    },
    [dataMerge]
  );

  // Optimizado
  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    console.log("handleCopySelectRiderCurrentList init");
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = dataMerge.slice();
    var listadeItems = new Set(item.countersListOrders);

    try {
      const tempuserOrdersMap = new Map();
      tempuserOrders.forEach((order) => {
        tempuserOrdersMap.set(order.id, order);
      });

      listadeItems.forEach((id) => {
        const order = tempuserOrdersMap.get(id);
        if (order) {
          copyOrdersForAccions.push("F" + order.id);
        }
      });

      let copyData = copyOrdersForAccions.join("\n"); // Convertir a string con saltos de línea
      navigator.clipboard.writeText(copyData);
      setRender(!render);
    } catch (error) {
      console.log(error);
    }
    console.log("handleCopySelectRiderCurrentList fin");
  };

  const createManiFestByCurrentList = async (e, item) => {
    e.preventDefault();
    console.log("createManiFestByCurrentList init");
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    const blob = await pdf(
      <CreateManiFestByCurrentListPdf orders={copyOrdersForAccions} />
    ).toBlob();

    console.log("createManiFestByCurrentList init");
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleCopySelectRiderCurrentListErrors = (e, item) => {
    e.preventDefault();
    navigator.clipboard.writeText(item);
  };

  const handleChangeOE = () => {
    setCheckedChangeOE(!CheckedChangeOE);
  };

  const handleChangetypeRequets = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "typeRequetsCreate" && checked) {
      setTypeRequets({ typeRequetsCreate: true, typeRequetsUpdate: false });
    } else {
      setTypeRequets({ typeRequetsCreate: false, typeRequetsUpdate: true });
    }
  };

  const generarReporteTurno = (data) => {
    generarReporteTurnoCierrediario(data, ridersDataByCompany, initDate);
  };

  const generarReporteDiscrepancias = (data) => {
    generarReporteDiscrepanciasPiking(data);
  };

  const generarReporteExcepcionesPendientes = (data) => {
    generarReporteExcepciones(data, ridersDataByCompany, initDate);
  };

  const generarReporteEstados = (dataOrdersForAccions) => {
    // console.log(dataOrdersForAccions);
    const matrizReporte = [
      [
        "Suborden",
        "Creado",
        "Estatus",
        "Descripción",
        "Última Actualización",
        "Resumen de entrega",
      ],
      ...dataOrdersForAccions.map((dato) => [
        dato.soc,
        dato.dateCreateText,
        dato.falabella_status,
        translateStateBicciFalabella[dato.falabella_status],
        dato.upDatedAtText,
        `${dato.upDatedAtText},  ${dato.userNameReceptor}, ${dato.userDniReceptor}`,
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `ESTADOS ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const generarReporteEvidencias = (dataOrdersForAccions) => {
    const matrizReporte = [
      [
        "SOC",
        "Fecha de envío",
        "Zonas",
        "Ultimo Registro",
        "Estatus",
        "Detalle",
        "Conductor",
        "Fotos",
        "Fotos",
        "Fotos",
        "Fotos",
      ],
      ...dataOrdersForAccions.map((dato) => [
        dato.soc,
        dato.dateCreateText,
        dato.commune2,
        format(dato.updatedAt.seconds * 1000, "dd/MM/yy hh:mm:ss"),
        dato.falabella_status,
        translateStateBicciFalabella[dato.falabella_status],
        dato.driverName,
        dato.pics[0],
        dato.pics[1],
        dato.pics[2],
        dato.pics[3],
        dato.pics[4],
        dato.pics[5],
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `Evidencias entregas ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const findHistorials = async () => {
    let cantGet = falabellaOrders.length;

    let dataDocsContainer = {};

    for (let i = 0; i < cantGet; i++) {
      let porcentajeDeAvance = (i / cantGet) * 100;
      setOrdersHistoryGetProgres(porcentajeDeAvance);

      try {
        const clearID = falabellaOrders[i].id; //.replace(/F/g, "");
        const falabellaOrderRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const statusRef = doc(falabellaOrderRef, clearID.toString());

        const statusDocs = collection(statusRef, "status");

        const dataallOrdersfind5 = await getDocs(statusDocs);
        const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        datadocuments.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );

        dataDocsContainer[falabellaOrders[i].id] = datadocuments;
      } catch (error) {}
    }
    setOrdersHistoryGetProgres(100);
    setContainerHistory(dataDocsContainer);
  };

  //  console.log(dataMerge);

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid>
        <Grid
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            {movilActive === true ? (
              <>
                <CardHeader
                  title="Ordenes Falabella"
                  subheader={
                    <ToolDashAppBar
                      movil={widthHeight.width < 950 ? true : false}
                      datailInsures={datailInsures}
                      setSelected={setSelected}
                      setOrigenGeoCode={setOrigenGeoCode}
                      setAdressOrigin={setAdressOrigin}
                      adressOrigin={adressOrigin}
                      setSelecteDestiny={setSelecteDestiny}
                      setDestinyGeoCode={setDestinyGeoCode}
                      setAdressDestiny={setAdressDestiny}
                      adressDestiny={adressDestiny}
                      Buttondisable={Buttondisable}
                      isShowPopUp={isShowPopUp}
                      setButtondisable={setButtondisable}
                      CheckedChangeOE={CheckedChangeOE}
                      handleChangeOE={handleChangeOE}
                      handleChangetypeRequets={handleChangetypeRequets}
                      typeRequets={typeRequets}
                      dataMerge={dataMerge}
                      dataOrdersForAccions={routesDataOrdersForAccions}
                      generarReporteEstados={generarReporteEstados}
                      generarReporteEvidencias={generarReporteEvidencias}
                      setWiewPrePdf={setWiewPrePdf}
                      findHistorials={findHistorials}
                      wiewPrePdf={wiewPrePdf}
                      initDate={initDate}
                      endDate={endDate}
                      onChageRangePiker={onChageRangePiker}
                      onChageRange={onChageRange}
                      staticDateBillingFilter={staticDateBillingFilter}
                      setListIdstoSelct={setListIdstoSelct}
                      listadeItemsNoMach={listadeItemsNoMach}
                      companyId={companyId}
                      realTimeisActive={realTimeisActive}
                      setRealTimeisActive={setRealTimeisActive}
                      listItems={listItems}
                      cantListItems={cantListItems}
                      handleCopySelectRiderCurrentListErrors={
                        handleCopySelectRiderCurrentListErrors
                      }
                      setWiewAssignment={setWiewAssignment}
                      wiewAssignmentIndIcon={wiewAssignmentIndIcon}
                      setOpemPopUpAsignament={setOpemPopUpAsignament}
                    ></ToolDashAppBar>
                  }
                  sx={{
                    padding: "16px",
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "flex-start",
                    "& .Routes-wrapper": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                    "& .realtime-label": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-info": {
                      marginTop: "0.5rem",
                    },
                    "& .realtime-pause": {
                      marginTop: "0.5rem",
                      textAlign: "center",
                    },
                    "& .searcher-wrapper": {
                      marginTop: "0.5rem",
                    },
                    "@media (min-width: 950px)": {
                      padding: "16px 24px",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      "& .Routes-wrapper": {
                        flexDirection: "row",
                        alignItems: "center",
                      },
                      "& .realtime-label": {
                        marginTop: 0,
                        marginRight: "1rem",
                      },
                      "& .realtime-pause": {
                        marginTop: 0,
                      },
                      "& .searcher-wrapper": {
                        marginTop: 0,
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                {" "}
                <CardHeader
                  title=""
                  subheader={
                    <div>
                      <ToolDashAppBar
                        movil={widthHeight.width < 950 ? true : false}
                        setSelected={setSelected}
                        setOrigenGeoCode={setOrigenGeoCode}
                        setAdressOrigin={setAdressOrigin}
                        adressOrigin={adressOrigin}
                        setSelecteDestiny={setSelecteDestiny}
                        setDestinyGeoCode={setDestinyGeoCode}
                        setAdressDestiny={setAdressDestiny}
                        adressDestiny={adressDestiny}
                        Buttondisable={Buttondisable}
                        isShowPopUp={isShowPopUp}
                        setButtondisable={setButtondisable}
                        CheckedChangeOE={CheckedChangeOE}
                        handleChangeOE={handleChangeOE}
                        handleChangetypeRequets={handleChangetypeRequets}
                        typeRequets={typeRequets}
                        dataMerge={dataMerge}
                        dataOrdersForAccions={routesDataOrdersForAccions}
                        generarReporteEstados={generarReporteEstados}
                        generarReporteEvidencias={generarReporteEvidencias}
                        generarReporteTurno={generarReporteTurno}
                        generarReporteDiscrepancias={
                          generarReporteDiscrepancias
                        }
                        generarReporteExcepcionesPendientes={
                          generarReporteExcepcionesPendientes
                        }
                        setWiewPrePdf={setWiewPrePdf}
                        findHistorials={findHistorials}
                        wiewPrePdf={wiewPrePdf}
                        initDate={initDate}
                        endDate={endDate}
                        onChageRangePiker={onChageRangePiker}
                        onChageRange={onChageRange}
                        staticDateBillingFilter={staticDateBillingFilter}
                        setListIdstoSelct={setListIdstoSelct}
                        listadeItemsNoMach={listadeItemsNoMach}
                        companyId={companyId}
                        realTimeisActive={realTimeisActive}
                        setRealTimeisActive={setRealTimeisActive}
                        listItems={listItems}
                        cantListItems={cantListItems}
                        datailInsures={datailInsures}
                        handleCopySelectRiderCurrentListErrors={
                          handleCopySelectRiderCurrentListErrors
                        }
                        setWiewAssignment={setWiewAssignment}
                        wiewAssignmentIndIcon={wiewAssignmentIndIcon}
                        setOpemPopUpAsignament={setOpemPopUpAsignament}
                      ></ToolDashAppBar>

                      <CardHeader subheader={""} />
                      <div className="Routes-container"></div>
                    </div>
                  }
                />
              </>
            )}

            <Divider />

            <Box className="formcontent">
              <div className="m-2">
                {menssajeAdmin ? (
                  <>{menssajeAdmin}</>
                ) : (
                  <>
                    {progressValue !== 100 ? (
                      <LinearProgress
                        sx={{ position: "fixed" }}
                      ></LinearProgress>
                    ) : null}
                  </>
                )}
              </div>
              <div className="frontMetricBar">
                <div className="content-small-screm">
                  <FadeInOut
                    sx={{ padding: "0px" }}
                    show={wiewAssignmentIndIcon}
                    duration={500}
                  >
                    <ToolDashResumenOps
                      movil={widthHeight.width < 1234 ? true : false}
                      metricsOps={metricsOps}
                      handleClickSelectRiderCurrentList={
                        handleClickSelectRiderCurrentList
                      }
                      handleClickSelectRiderPendintList={
                        handleClickSelectRiderPendintList
                      }
                      handleCopySelectRiderCurrentList={
                        handleCopySelectRiderCurrentList
                      }
                      rosettaVehicles={rosettaVehicles}
                      createManiFestByCurrentList={createManiFestByCurrentList}
                      metricsOpsRoutes={metricsOpsRoutes}
                    ></ToolDashResumenOps>
                  </FadeInOut>
                </div>
              </div>{" "}
              <div></div>
              <Grid>
                <Grid className="dash-container-colmun ">
                  <div>
                    {/* <button onClick={() => isShowPopUp("Asignacion")}>
                      {" "}
                      prueba de asinacion{" "}
                    </button>{" "} */}
                    {wiewPrePdf && metricsOps && metricsOpsStores ? (
                      <PDFViewer style={{ width: "100%", height: "90vh" }}>
                        <CierreOpsPdf
                          items={listItems}
                          cant={cantListItems}
                          metricsOps={metricsOps}
                          metricsOpsStores={metricsOpsStores}
                          rosettaVehicles={rosettaVehicles}
                          userUid={userUid}
                          initDate={initDate}
                          endDate={endDate}
                          typeReport={props.vertical}
                        ></CierreOpsPdf>
                      </PDFViewer>
                    ) : null}
                  </div>
                  <div>
                    {isGuiasFalabellaList ? (
                      <UserListViewOrdersIntFalabella
                        movil={widthHeight.width < 1234 ? true : false}
                        dataOrdesRequets={dataMerge}
                        visibleBilling={false}
                        ordersForAccions={routesOrdersForAccions}
                        UpdateOrderForAcctions={UpdateOrderForAcctions}
                        companyId={companyId}
                      />
                    ) : (
                      <div>
                        {dataMerge.length < 50 ? (
                          <Suspense fallback={<div>Loading...</div>}>
                            <UserListViewOrdersIntFalabella
                              movil={widthHeight.width < 1234 ? true : false}
                              dataOrdesRequets={dataMerge}
                              visibleBilling={false}
                              ordersForAccions={routesOrdersForAccions}
                              UpdateOrderForAcctions={UpdateOrderForAcctions}
                              companyId={companyId}
                            />
                          </Suspense>
                        ) : (
                          <Box>
                            <Box
                              sx={{
                                backgroundColor: "#ff7f00",
                                color: "#F8F8F8",
                                padding: "20px",
                              }}
                            >
                              {" "}
                              <NavLink
                                to={{
                                  pathname: `/dashboard/guiasfalabella/list`,
                                }}
                                color="primary"
                                variant="contained"
                              >
                                {" "}
                                <Typography sx={{ color: "#F8F8F8" }}>
                                  {" "}
                                  Alto volumen en la consulta. Modo Solo de
                                  ruteo activado, si deseas ver el listado de
                                  órdenes con más detalles, Has clic aquí. Ver
                                  Listado de Órdenes
                                </Typography>{" "}
                              </NavLink>
                            </Box>
                            <Box>
                              <DataGridoOders
                                dataOrdesRequets={dataMerge}
                                UpdateOrderForAcctions={UpdateOrderForAcctions}
                              ></DataGridoOders>
                            </Box>
                          </Box>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun"
                >
                  {/* <LatestProducts  />  */}
                </Grid>
              </Grid>
            </Box>

            <Divider />
          </Card>
          <div></div>
          {showPopUp ? (
            <ModalAccionsDispacthDirectionsResolve
              opened={showPopUp}
              handleClosePopUp={handleClosePopUp}
              popUpAccion={popUpAccion}
              interfaceOrigen={"liveOrders"}
              ordersForAccions={routesOrdersForAccions}
              dataOrdersForAccions={routesDataOrdersForAccions}
              companyId={companyId}
              ridersDataByCompany={ridersDataByCompany}
              adressOrigin={adressOrigin}
              origenGeoCode={origenGeoCode}
              adressDestiny={adressDestiny}
              destinyGeoCode={destinyGeoCode}
            >
              {" "}
            </ModalAccionsDispacthDirectionsResolve>
          ) : null}
          {ridersDataByCompany ? (
            <PopUpAsignament
              opemPopUpAsignament={opemPopUpAsignament}
              setOpemPopUpAsignament={setOpemPopUpAsignament}
              ordersForAccions={routesOrdersForAccions}
              ridersDataByCompany={ridersDataByCompany}
            ></PopUpAsignament>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuiasByFalabella;
