import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import {
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx"; // Importamos la biblioteca para manejar Excel
import { format, parseISO } from "date-fns";

const libraries = ["places", "geometry"];

const Trayectos = ({ dailyTrips, driverOperationData }) => {
  console.log(dailyTrips);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [path, setPath] = useState([]);
  const [selectedTripIndex, setSelectedTripIndex] = useState(0); // Estado para seleccionar el trayecto
  const [showStops, setShowStops] = useState(false); // Estado para mostrar/ocultar las paradas
  const [showDeliveryPoints, setShowDeliveryPoints] = useState(false); // Estado para mostrar puntos de entrega
  const [showPath, setShowPath] = useState(false); // Estado para mostrar/ocultar el trayecto
  const [showOriginDestination, setShowOriginDestination] = useState(false); // Estado para mostrar/ocultar origen y destino
  const [stops, setStops] = useState([]); // Guardar las paradas
  const [deliveryPoints, setDeliveryPoints] = useState([]); // Puntos de entrega
  const mapRef = useRef(null);
  const polylineRef = useRef(null); // Usamos una referencia para la Polyline

  // Función para crear la ruta y paradas
  const createPathAndStops = (trip) => {
    const locationData = trip.locations || trip.location; // Usar 'locations' si está disponible, de lo contrario 'location'

    // Manejar tanto la estructura nueva como la vieja
    const path = locationData.map((loc) => {
      if (loc.location) {
        // Nueva estructura
        return {
          lat: loc.location._lat,
          lng: loc.location._long,
          state: loc.state,
        };
      } else {
        // Antigua estructura
        return {
          lat: loc._lat,
          lng: loc._long,
          state: loc.state,
        };
      }
    });

    setPath(path);

    // Manejar paradas con el estado "DETENIDO"
    const stops = locationData.filter(
      (loc) =>
        loc.state === "DETENIDO" && // Asegurarnos de que el estado es "DETENIDO"
        ((loc.location && loc.location._lat && loc.location._long) || // Nueva estructura
          (loc._lat && loc._long)) // Antigua estructura
    );

    setStops(stops);
  };

  // Función para filtrar los puntos de entrega por la fecha de la ruta seleccionada
  const filterDeliveryPoints = (tripDate) => {
    const formattedTripDate = format(parseISO(tripDate), "yyyy-MM-dd");

    const filteredPoints = driverOperationData.filter((data) => {
      if (!data.updatedAt || !data.updatedAt.seconds) {
        return false; // Si updatedAt es undefined o no tiene seconds, excluir el punto
      }

      const deliveryDate = format(
        new Date(data.updatedAt.seconds * 1000),
        "yyyy-MM-dd"
      ); // Formatear la fecha de `updatedAt`

      // Comparar solo el formato de fecha (sin horas)
      return deliveryDate === formattedTripDate;
    });

    setDeliveryPoints(filteredPoints);
  };

  useEffect(() => {
    if (dailyTrips && dailyTrips.length > 0) {
      const trip = dailyTrips[selectedTripIndex];
      createPathAndStops(trip);
      filterDeliveryPoints(trip.date);
    }
  }, [dailyTrips, selectedTripIndex]);

  // Mostrar/ocultar paradas
  const handleShowStopsToggle = (event) => {
    setShowStops(event.target.checked);
  };

  // Mostrar/ocultar puntos de entrega
  const handleShowDeliveryPointsToggle = (event) => {
    setShowDeliveryPoints(event.target.checked);
  };

  // Mostrar/ocultar trayecto (Polyline)
  const handleShowPathToggle = (event) => {
    setShowPath(event.target.checked);
  };

  // Mostrar/ocultar origen y destino
  const handleShowOriginDestinationToggle = (event) => {
    setShowOriginDestination(event.target.checked);
  };

  const downloadExcel = () => {
    const trip = dailyTrips[selectedTripIndex];
    const locationData = trip.locations || trip.location;

    const worksheetData = locationData.map((point, index) => ({
      Hora: point.timestamp
        ? format(
            new Date(point.timestamp.seconds * 1000), // Convertir segundos a milisegundos
            "dd/MM/yyyy HH:mm:ss"
          ).toString()
        : "N/A", // Si no hay timestamp
      Conductor: point.driver || "N/A", // Conductor asociado al trayecto
      Vehículo: point.vehicle || "N/A", // Vehículo asociado al trayecto
      "#": index + 1,
      Latitud: point._lat || (point.location ? point.location._lat : "N/A"), // Verificar si location existe
      Longitud: point._long || (point.location ? point.location._long : "N/A"), // Verificar si location existe
      Estado: point.state || "N/A", // Agregar estado si está disponible
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Trayecto");

    XLSX.writeFile(workbook, `Trayecto_${new Date().toISOString()}.xlsx`);
  };

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const center = path.length > 0 ? path[0] : { lat: -33.45694, lng: -70.64827 };

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const createFinalLine = (codeLine) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps library not loaded");
      return;
    }

    if (polylineRef.current) {
      polylineRef.current.setMap(null);
    }

    polylineRef.current = new window.google.maps.Polyline({
      path: codeLine,
      strokeColor: "#0035ff",
      strokeWeight: 3,
      strokeOpacity: 1,
      fillColor: "#591e8f",
      map: mapRef.current,
    });
  };

  useEffect(() => {
    if (isLoaded && path.length > 0 && showPath) {
      createFinalLine(path);
    }
  }, [isLoaded, path, showPath]);

  if (loadError) {
    return "Error al cargar el mapa";
  }
  if (!isLoaded) {
    return "Cargando el mapa...";
  }

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">
          Origen: {path.length > 0 && `${path[0].lat}, ${path[0].lng}`}
        </Typography>
        <Typography variant="h6">
          Destino:{" "}
          {path.length > 0 &&
            `${path[path.length - 1].lat}, ${path[path.length - 1].lng}`}
        </Typography>
      </Box>

      <Select
        value={selectedTripIndex}
        onChange={(e) => setSelectedTripIndex(Number(e.target.value))}
        fullWidth
      >
        {dailyTrips.map((trip, index) => (
          <MenuItem key={trip.id} value={index}>
            Ruta del {trip.date} - Vehículo: {trip.id}
          </MenuItem>
        ))}
      </Select>

      <FormControlLabel
        control={
          <Switch checked={showStops} onChange={handleShowStopsToggle} />
        }
        label="Mostrar paradas"
      />

      <FormControlLabel
        control={
          <Switch
            checked={showDeliveryPoints}
            onChange={handleShowDeliveryPointsToggle}
          />
        }
        label="Mostrar puntos de entrega"
      />

      <FormControlLabel
        control={<Switch checked={showPath} onChange={handleShowPathToggle} />}
        label="Mostrar trayecto"
      />

      <FormControlLabel
        control={
          <Switch
            checked={showOriginDestination}
            onChange={handleShowOriginDestinationToggle}
          />
        }
        label="Mostrar origen y destino"
      />

      <Button variant="contained" color="primary" onClick={downloadExcel}>
        Descargar Excel
      </Button>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={(map) => (mapRef.current = map)}
      >
        {showStops &&
          stops.map((stop, index) => (
            <Marker
              key={index}
              position={
                stop.location
                  ? { lat: stop.location._lat, lng: stop.location._long }
                  : { lat: stop._lat, lng: stop._long }
              }
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
              }}
              title={`Parada en ${
                stop.location
                  ? `${stop.location._lat}, ${stop.location._long}`
                  : `${stop._lat}, ${stop._long}`
              }`}
            />
          ))}

        {showDeliveryPoints &&
          deliveryPoints.map((point, index) => (
            <Marker
              key={index}
              position={{
                lat: point.toAddressLocation._lat,
                lng: point.toAddressLocation._long,
              }}
              onLoad={(markerInstance) => {
                const customIcon = {
                  url: "/static/images/casita.png",
                  scaledSize: new window.google.maps.Size(35, 35),
                  anchor: new window.google.maps.Point(17.5, 17.5),
                };
                markerInstance.setIcon(customIcon);
              }}
            />
          ))}

        {path.length > 0 && showOriginDestination && (
          <>
            <Marker
              position={{ lat: path[0].lat, lng: path[0].lng }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              }}
              label="O"
              title="Origen"
            />
            <Marker
              position={{
                lat: path[path.length - 1].lat,
                lng: path[path.length - 1].lng,
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
              }}
              label="D"
              title="Destino"
            />
          </>
        )}
      </GoogleMap>
    </div>
  );
};

export default Trayectos;
