import React from "react";
import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import RegistroEmail from "../LoginEmail/RegistroEmail";
import { useParams } from "react-router-dom";

const Login = () => {
  const activo = useSelector((store) => store.usuario.activo);
  const { id } = useParams();

  return (
    <Container className="bodyFroms" id="bodyFroms">
      <div className="mt-5 text-center"></div>
      <RegistroEmail />
    </Container>
  );
};

export default Login;
