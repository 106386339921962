import { useEffect, useState } from "react";
import { db } from "../firebase";
import Moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";

function useGetRoutesExpress(
  companyId,
  userRoll,
  routeDate,
  userRider,
  userVehicle
) {
  // //console.log(companyId)
  // //console.log(routeDate)
  // //console.log(userRider)
  // //console.log(userVehicle)

  const [compayRoutesExpress, setCompayRoutesExpress] = useState(null);
  const [bicciRoutesExpress, setBicciRoutesExpress] = useState(null);
  const [loadRoutesExpress, setLoadRoutesExpress] = useState(false);
  const [errorRoutesExpress, setErrorRoutesExpress] = useState(null);

  useEffect(() => {
    //console.log(userRoll)
    fetchData(companyId);
  }, [companyId, routeDate, userRider, userVehicle, userRoll]);

  const fetchData = async (companyId) => {
    //var dataallRoutes = allRoutes.docs.map(doc => ({id: doc.id, ...doc.data()}))

    const AplyFilters = (routeDate, userRider, userVehicle, dataallRoutes) => {
      if (routeDate) {
        let formatDate = Moment(routeDate).format("DD-MM-YYYY");
        dataallRoutes = dataallRoutes.filter((userRider) =>
          Moment(userRider.dateDeliveryText)
            .format("DD-MM-YYYY")
            .includes(formatDate)
        );
      }

      if (userRider) {
        let userRiderClear = userRider.trim().toLowerCase();
        dataallRoutes = dataallRoutes.filter((userRider) =>
          userRider.driverName.toLowerCase().includes(userRiderClear)
        );
      }

      if (userVehicle) {
        let userVehicleClear = userVehicle.trim().toLowerCase();
        dataallRoutes = dataallRoutes.filter((userRider) =>
          userRider.vehicleID.toLowerCase().includes(userVehicleClear)
        );
      }
      //console.log(dataallRoutes)
      setBicciRoutesExpress(dataallRoutes);
    };

    const AplyFiltersByAdmin = (
      routeDate,
      userRider,
      userVehicle,
      dataallRoutes
    ) => {
      if (routeDate) {
        let formatDate = Moment(routeDate).format("DD-MM-YYYY");
        dataallRoutes = dataallRoutes.filter((userRider) =>
          Moment(userRider.dateDeliveryText)
            .format("DD-MM-YYYY")
            .includes(formatDate)
        );
      }

      if (userRider) {
        let userRiderClear = userRider.trim().toLowerCase();
        dataallRoutes = dataallRoutes.filter((userRider) =>
          userRider.driverName.toLowerCase().includes(userRiderClear)
        );
      }

      if (userVehicle) {
        let userVehicleClear = userVehicle.trim().toLowerCase();
        dataallRoutes = dataallRoutes.filter((userRider) =>
          userRider.vehicleID.toLowerCase().includes(userVehicleClear)
        );
      }
      //console.log(dataallRoutes)
      setBicciRoutesExpress(dataallRoutes);
    };

    try {
      setLoadRoutesExpress(true);
      if (userRoll === process.env.REACT_APP_ADMIN) {
        if (companyId) {
          const allRoutesRef = collection(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`
          );
          const querySnapshot = await getDocs(allRoutesRef);
          const dataallRoutes = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          AplyFiltersByAdmin(routeDate, userRider, userVehicle, dataallRoutes);
        } else {
          const allRoutesRef = collection(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`
          );
          const querySnapshot = await getDocs(allRoutesRef);
          const dataallRoutes = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          AplyFilters(routeDate, userRider, userVehicle, dataallRoutes);
        }
      } else {
        if (companyId) {
          const allRoutesRef = collection(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`
          );
          const querySnapshot = await getDocs(allRoutesRef);
          const dataallRoutes = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const companyUid = companyId.trim().toLowerCase();
          const q = query(allRoutesRef, where("companyId", "==", companyUid));
          const filteredQuerySnapshot = await getDocs(q);
          const filteredDataallRoutes = filteredQuerySnapshot.docs.map(
            (doc) => ({
              id: doc.id,
              ...doc.data(),
            })
          );
          AplyFilters(routeDate, userRider, userVehicle, dataallRoutes);
        } else {
          setBicciRoutesExpress(null);
        }
      }

      setLoadRoutesExpress(false);
    } catch (error) {
      //console.log(error)
      setErrorRoutesExpress(true);
      setBicciRoutesExpress(null);
    }
  };
  return {
    compayRoutesExpress,
    bicciRoutesExpress,
    loadRoutesExpress,
    errorRoutesExpress,
  };
}

export default useGetRoutesExpress;
