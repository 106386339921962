import { useState, useEffect } from "react";
import { requestOrdersStatusBicciCollection } from "../utils/UtilsOrders/requestOrdersStatusBicciCollection";
import { requestOrdersStatusFalabellaCollection } from "../utils/UtilsOrders/requestOrdersStatusFalabellaCollection";
import updateOrdersBicciexpressEstatusBilling from "../utils/UtilsOrders/updateOrdersBicciexpressEstatusBilling";

export const useDataProcessing = (data, isActive) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isActive || !data) {
      return;
    }

    let isCancelled = false;

    const processData = async () => {
      for (let i = 0; i < data.length; i++) {
        if (isCancelled) break;

        let element = data[i];

        // console.log(element.id);
        //console.log(element.int_serviceType);
        // console.log(element.int_lpn);
        // console.log(element.int_bicciStatus);
        let resp;
        let resp2;

        try {
          resp = await requestOrdersStatusBicciCollection(element);
          // console.log("resp.bicciStatus", resp);
        } catch (error) {
          console.error(error);
        }

        try {
          resp2 = await requestOrdersStatusFalabellaCollection(resp.newReq);
          // console.log("resp2", resp2);
        } catch (error) {
          console.error(error);
        }

        if (resp.bicciStatus !== false) {
          let dataUpdate = {
            statusCreate: resp.statusCreate,
            bicciStatus: resp.bicciStatus,
            driverName: resp.driverName,
            driverID: resp.driverID,
            createdAt: resp2.createdAtFalabella,
            falabella_status: resp2.falabella_status,
            observationReceptor: resp.observationReceptor,
            observationAdmin: resp.observationAdmin,
            userDniReceptor: resp.userDniReceptor,
            userNameReceptor: resp.userNameReceptor,
            updatedAt: resp2.updatedAt,
          };

          try {
            updateOrdersBicciexpressEstatusBilling(dataUpdate, element.id);
          } catch (error) {
            console.log(error);
          }
        }

        // Actualizar el progreso cada 10 elementos o al final del array
        if (i % 10 === 9 || i === data.length - 1) {
          const progresoActual = ((i + 1) / data.length) * 100;
          setProgress(progresoActual);
        }

        // Espera corta para evitar bloquear la UI completamente
        await new Promise((resolve) => setTimeout(resolve, 1));
      }
    };

    processData().catch(console.error);

    return () => {
      isCancelled = true;
    };
  }, [data, isActive]);

  return progress;
};
