import React, { createRef, useState } from "react";
import { db } from "../../../firebase";
import {
  setDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Card, Divider, CardHeader, CardContent, Box } from "@mui/material";
import { Input, Container, Form } from "semantic-ui-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../components/animations/FadeInOut";
import { Grid } from "semantic-ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import Chip from "@mui/material/Chip";
import DashIconsResumenVehicles from "../../../components/Dashboard/DashIconsResumenVehicles/DashIconsResumenVehicles";
import { DataGrid } from "@mui/x-data-grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import { LinearProgress } from "@mui/material";
import Popup from "../../../components/Popup";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetVehiclesTypes from "../../../hooks/getVehiclesTypes";
import useGetTravelModels from "../../../hooks/getTravelModels";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";

const libraries = ["places"];

const Vehicles = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const userUid = useSelector((store) => store.usuario);
  // console.log(userUid)
  //var companyId=null
  //const {userBalance  } = useObtenerBalance(userCompanyID)
  const [companyId, setcompanyId] = useState("");
  const [isvalid, setIsValid] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState(null);

  const [vehiclesRows, setVehiclesRows] = React.useState("");

  const [directions, setDirectionsByVehicle] = React.useState("");

  const [showPopup, setshowPopup] = useState(false);
  const [typePop, setTypePop] = useState(null);
  const [cantListItems, setcantListItems] = React.useState({
    Disponibles: 0,
    Conectados: 0,
    Bloqueados: 0,
  });

  const [objRider, setobjRider] = React.useState({
    title: "showPopupWiewVehicles",
    texto: "Lorem ipsum dolor.",
  });

  const [newVehicle, setNewVehicle] = useState({
    createBy: userUid.user.uid,
    createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    date: Date.now(),
    id: "",
    vehiclesTypes: "",
    travelMode: "",
    maxSpeed: "",
    maxTransportCapacityVol: "",
    minTransportCapacityVol: "",
    maximumCoverage: "",
    packetCapacity: "",
    requireMinPacketCapacity: "",
    cluster: companyId,
    origenAdrress: "",
    destinyAdrress: "",
    origen: "",
    destiny: "",
    workingDayMinHour: "08:00",
    workingDayMaxHour: "10:00",
    active: true,
    inRoute: false,
    isDelete: false,
    isBlocked: false,
    typePop: "vehicle",
  });
  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);

  const { vehiclesTypes } = useGetVehiclesTypes();
  const { travelModels } = useGetTravelModels();
  const { VehiclesDataByCompany, listVehiclesDataByCompany } =
    useGetDataVehiclesByCompany(companyId);
  const { ridersDataByCompanyActive } = useGetDataRiderByCompany(companyId);

  const listItems = [
    { id: "Conectados" },
    { id: "Bloqueados" },
    { id: "Disponibles" },
  ];

  useEffect(() => {
    //console.log(companyId)
    findAdressesCompany(companyId);
  }, [companyId]);

  useEffect(() => {
    //console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
      setNewVehicle({ ...newVehicle, cluster: userdata.userCompanyID });
    }
  }, [data, userdata]);

  useEffect(() => {
    if (VehiclesDataByCompany) {
      getCompanyVehicles();
      // console.log(VehiclesDataByCompany);
      setVehiclesRows(VehiclesDataByCompany);
      setcantListItems(listVehiclesDataByCompany);
    }
  }, [VehiclesDataByCompany && listVehiclesDataByCompany]);

  const findAdressesCompany = async (companyUid) => {
    //console.log(companyUid)
    try {
      const direccionCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${companyUid}/direcciones`
      );
      const addressesQuerySnapshot = await getDocs(direccionCollectionRef);
      // console.log(adressses.docs)
      const addresses = [];
      addressesQuerySnapshot.forEach((doc) => {
        // Agregamos los datos del documento a un array.
        addresses.push({ id: doc.id, ...doc.data() });
      });
      //console.log(directions)
      setDirectionsByVehicle(addresses);
    } catch (error) {
      // console.log(error)
    }
  };

  const togglePopup = () => {
    setshowPopup(!showPopup);
  };

  const toggleShowEditNew = () => {
    setShowEdit(!showEdit);
    //console.log('Abrir inviatcion')
  };

  const clearFrom = () => {
    setNewVehicle({
      createBy: userUid.user.uid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      date: Date.now(),
      id: "",
      vehiclesTypes: "",
      travelMode: "",
      maxSpeed: "",
      maxTransportCapacityVol: "",
      minTransportCapacityVol: "",
      maximumCoverage: "",
      packetCapacity: "",
      requireMinPacketCapacity: "",
      cluster: companyId,
      origenAdrress: "",
      destinyAdrress: "",
      origen: "",
      destiny: "",
      workingDayMinHour: "08:00",
      workingDayMaxHour: "10:00",
      active: true,
      inRoute: false,
      typePop: "vehicle",
      driverAssing: "",
    });
  };
  const saveNewVehicle = async (e) => {
    e.preventDefault();
    if (!newVehicle.id.trim()) {
      setError("Indique un identificador del vehiculo");
      setIsValid(false);
      return;
    }
    if (!newVehicle.vehiclesTypes.trim()) {
      setError("Seleccione un tipo valido de vehiculo");
      setIsValid(false);
      return;
    }
    if (!newVehicle.travelMode.trim()) {
      setError("Indique modo de viaje");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maxSpeed.length > 0) {
      setError("Indique la maxima velocidad");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maximumCoverage.length > 0) {
      setError("Indique la maximo de covertura");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maxTransportCapacityVol.length > 0) {
      setError("Indique la maximo de volumen de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.minTransportCapacityVol.length > 0) {
      setError("Indique la minimo de volumen de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.packetCapacity.length > 0) {
      setError("Indique capacidad de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.requireMinPacketCapacity.length > 0) {
      setError("Indique minimo de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.origen) {
      setError("Seleccione un origen");
      setIsValid(false);
      return;
    }
    if (!newVehicle.destiny) {
      setError("Seleccione un destino");
      setIsValid(false);
      return;
    }
    if (!newVehicle.workingDayMaxHour.length > 0) {
      setError("Indique hora de retorno");
      setIsValid(false);
      return;
    }
    if (!newVehicle.workingDayMinHour.length > 0) {
      setError("Indique hora de inicio");
      setIsValid(false);
      return;
    }
    setIsValid(true);
    try {
      const companyVehiclesCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
      );
      const vehicleDocRef = doc(companyVehiclesCollectionRef, newVehicle.id);
      await setDoc(vehicleDocRef, newVehicle);
    } catch (error) {
      //console.log(error)
    }
    updateRows();

    clearFrom();
    setShowEdit(false);
  };

  const updateRows = async () => {
    try {
      const companyVehiclesCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
      );
      const companyVehiclesQuerySnapshot = await getDocs(
        companyVehiclesCollectionRef
      );
      const dataRows = [];
      companyVehiclesQuerySnapshot.forEach((doc) => {
        dataRows.push({ id: doc.id, ...doc.data() });
      });
      //console.log(dataRows)

      let dataRowsBeforeDelete = dataRows.filter(
        (item) => item.isDelete === false
      );

      let vehicleNoActive = dataRowsBeforeDelete.filter(
        (item) => item.isBlocked === true
      );
      let vehicleActive = dataRowsBeforeDelete.filter(
        (item) => item.isBlocked === false
      );
      let vehicleInRoute = dataRowsBeforeDelete.filter(
        (item) => item.inRoute === true
      );

      setcantListItems({
        Disponibles: vehicleActive.length,
        Conectados: vehicleInRoute.length,
        Bloqueados: vehicleNoActive.length,
      });

      setVehiclesRows(dataRowsBeforeDelete);
    } catch (error) {
      console.log(error);
    }
  };

  const columnsVehicles = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      renderCell: (vehiclesRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            {vehiclesRows.row.isBlocked === true ? (
              <Chip
                label={vehiclesRows.row.id}
                onClick={showPopupWiewVehicles}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                variant="outlined"
                icon={
                  <LocalShippingIcon
                    style={{
                      color: "#fff",
                      textAling: "left",
                    }}
                  />
                }
                style={{
                  color: "#fff",
                  backgroundColor: "grey",
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              />
            ) : (
              <Chip
                label={vehiclesRows.row.id}
                onClick={showPopupWiewVehicles}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                variant="outlined"
                icon={
                  <LocalShippingIcon
                    style={{
                      color: "#fff",
                      textAling: "left",
                    }}
                  />
                }
                style={{
                  color: "#fff",
                  backgroundColor: "#591e8f",
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              />
            )}

            {vehiclesRows.row.isBlocked === false ? (
              <Chip
                label="Desactivar"
                onClick={handleBloqPropsVehicleRows}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                icon={<BlockIcon />}
                variant="outlined"
                style={{
                  color: "#010101",
                  backgroundColor: "#f7e908",
                }}
              />
            ) : (
              <Chip
                label="Activar"
                onClick={handleUnBloqPropsVehicleRows}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                icon={
                  <BlockIcon
                    style={{
                      color: "#fff",
                    }}
                  />
                }
                variant="outlined"
                style={{
                  color: "#fff",
                  backgroundColor: "#0400ef",
                }}
              />
            )}

            <Chip
              label="Eliminar"
              onClick={handleDeletedPropsVehicleRows}
              id={vehiclesRows.row.id}
              value={vehiclesRows.row.id}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#d5182b",
              }}
            />
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Estado",
      sortable: false,
      width: 250,
      renderCell: (vehiclesRows) => {
        // console.log(ridersRows.row.active)
        return (
          <>
            <Chip
              label={
                vehiclesRows.row.inRoute === true ? "On Route" : "OF Route"
              }
              icon={
                <AccountCircleIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#010101",
                backgroundColor:
                  vehiclesRows.row.inRoute === true ? "#1ef708" : "No Activado",
              }}
            />
          </>
        );
      },
    },
  ];

  const showPopupWiewVehicles = (e) => {
    //console.log(e.currentTarget.id)
    var searchData = vehiclesRows.find(
      (item) => item.id === e.currentTarget.id
    );
    //  var searchData = ridersDataByCompany.find (item => item.email === search.email)

    setTypePop("vehicle");
    setobjRider({
      title: searchData.id,
      texto: "",
      data: searchData,
    });
    togglePopup();
  };

  const handleBloqPropsVehicleRows = async (e) => {
    // console.log('BLOQ ACTIVE ' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isBlocked: true,
      active: false,
    });
    updateRows();
  };

  const handleUnBloqPropsVehicleRows = async (e) => {
    // console.log('BLOQ ACTIVE ' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isBlocked: false,
      active: true,
    });
    updateRows();
  };

  const handleDeletedPropsVehicleRows = async (e) => {
    // console.log('DELETE ACTIVE' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isDelete: true,
      active: false,
    });
    updateRows();
  };

  const handleUnDeletedPropsRidersRows = async (e) => {
    //console.log('DELETE ACTIVE' + e.currentTarget.id )
    await db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(newVehicle.cluster)
      .collection("companyvehicles")
      .doc(e.currentTarget.id)
      .update({
        isDelete: false,
        active: true,
      });
  };

  const getCompanyVehicles = () => {
    // console.log(vehiclesTypes)
    //console.log(VehiclesDataByCompany)
  };

  const habdleOnChange = (e) => {
    // console.log(e.target.value)
    // console.log(e.target.name)
    setNewVehicle({ ...newVehicle, [e.target.name]: e.target.value });
  };

  const handleSelectDirection = (e) => {
    const name = e.target.name;
    const getpoint = async (address) => {
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        let latlng = {
          lat: "",
          lng: "",
        };
        latlng.lat = lat;
        latlng.lng = lng;
        //console.log(latlng)
        setNewVehicle({ ...newVehicle, [name]: latlng });
      } catch (error) {}
    };
    getpoint(e.target.value);
  };
  //console.log(newVehicle);

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <>
      <Grid celled="internally" id="expresscuentaContent">
        <h2 className="h2-withe">Mis Vehículos</h2>
        {error ? <span className="alert alert-danger m-2">{error}</span> : null}
        <Grid.Row>
          <Grid.Column
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms typeMiniformsdrivers typeMiniformsCard">
              <CardHeader title="Resumen" />
              <DashIconsResumenVehicles
                items={listItems}
                cant={cantListItems}
              ></DashIconsResumenVehicles>
            </Card>
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Registra tus vehiculos. Es necesario completar los datos"
                title="Nuevo Vehículo"
              />
              {userdata && userdata.userCompanyID ? (
                <>
                  {showEdit === true ? (
                    <div>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          outline: "none",
                          m: 1,
                        }}
                        color="primary"
                        variant="contained"
                        onClick={toggleShowEditNew}
                      >
                        <HighlightOffIcon className="dashIcon-red" />
                        Cancelar
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        color="primary"
                        variant="contained"
                        onClick={toggleShowEditNew}
                      >
                        <AddCircleIcon className="dashIcon-green" />
                        Agregar
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <> Debe registrar su empresa para agregar vehículos</>
              )}

              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  <hr></hr>
                  <Box
                    sx={{
                      display: "contents",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#eeeeee",
                      p: 2,
                    }}
                  >
                    <form onSubmit={saveNewVehicle}>
                      <Form.Group widths="equal" className="displayFlex">
                        <div> Conductor Responsable </div>

                        {ridersDataByCompanyActive ? (
                          <>
                            <select
                              id="custom-selectFrom1"
                              label="Tipo de usuario"
                              name="driverAssing"
                              control="select"
                              onChange={(e) => habdleOnChange(e)}
                              value={newVehicle.driverAssing}
                            >
                              <option>
                                {" "}
                                Seleccione conductor de su empresa.{" "}
                              </option>
                              {ridersDataByCompanyActive.map((item) => (
                                <option
                                  key={Math.random().toString()}
                                  value={item.id}
                                >
                                  {" "}
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <>no data</>
                        )}
                      </Form.Group>

                      <Form.Group widths="equal" className="displayFlex">
                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="Company"
                          placeholder="Nombre"
                          iconPosition="left"
                          type="text"
                          onChange={(e) => habdleOnChange(e)}
                          disabled={true}
                          value={newVehicle.cluster}
                          icon="building"
                        />

                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="Placa / Matricula"
                          placeholder="Placa / Matricula"
                          iconPosition="left"
                          type="text"
                          name="id"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.id}
                          icon="user"
                        />
                      </Form.Group>

                      <Form.Group widths="equal" className="displayFlex">
                        {vehiclesTypes ? (
                          <>
                            <select
                              id="custom-selectFrom1"
                              label="Tipo de usuario"
                              name="vehiclesTypes"
                              control="select"
                              onChange={(e) => habdleOnChange(e)}
                              value={newVehicle.vehiclesTypes}
                            >
                              <option> Seleccione tipo vehículo. </option>
                              {vehiclesTypes.map((item) => (
                                <option
                                  key={Math.random().toString()}
                                  value={item.id}
                                >
                                  {" "}
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <>no data</>
                        )}
                        {travelModels ? (
                          <select
                            id="custom-selectFrom2"
                            label="Tipo de usuario"
                            name="travelMode"
                            control="select"
                            onChange={(e) => habdleOnChange(e)}
                            value={newVehicle.travelMode}
                          >
                            <option> Seleccione tipo viaje. </option>
                            {travelModels.map((item) => (
                              <option key={item.id} value={item.value}>
                                {" "}
                                {item.id}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>no data</>
                        )}
                      </Form.Group>

                      <Form.Group widths="equal" className="displayFlex">
                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="Velocidad Maxima"
                          placeholder="Km"
                          iconPosition="left"
                          type="number"
                          name="maxSpeed"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.maxSpeed}
                          icon="tachometer alternate"
                        />

                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="Cobertura"
                          placeholder="maximumCoverage"
                          iconPosition="left"
                          type="number"
                          name="maximumCoverage"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.maximumCoverage}
                          icon="tachometer alternate"
                        />
                      </Form.Group>
                      <Form.Group widths="equal" className="displayFlex">
                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="maxTransportCapacityVol"
                          placeholder="maxTransportCapacityVol"
                          iconPosition="left"
                          type="number"
                          name="maxTransportCapacityVol"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.maxTransportCapacityVol}
                          icon="boxes"
                        />

                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="minTransportCapacityVol"
                          placeholder="minTransportCapacityVol"
                          iconPosition="left"
                          type="number"
                          name="minTransportCapacityVol"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.minTransportCapacityVol}
                          icon="boxes"
                        />
                      </Form.Group>
                      <Form.Group widths="equal" className="displayFlex">
                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="packetCapacity"
                          placeholder="packetCapacity"
                          iconPosition="left"
                          type="number"
                          name="packetCapacity"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.packetCapacity}
                          icon="boxes"
                        />

                        <Form.Field
                          className="displayGrid m-2"
                          control={Input}
                          label="requireMinPacketCapacity"
                          placeholder="requireMinPacketCapacity"
                          iconPosition="left"
                          type="number"
                          name="requireMinPacketCapacity"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.requireMinPacketCapacity}
                          icon="boxes"
                        />
                      </Form.Group>
                      <hr></hr>
                      <span> Seleccione una dirección de sucursal.</span>
                      <br></br>

                      <Form.Group widths="equal" className="displayFlex">
                        {directions ? (
                          <>
                            <select
                              id="custom-selectFrom1"
                              label="Tipo de usuario"
                              name="origen"
                              control="select"
                              onChange={(e) => handleSelectDirection(e)}
                              value={newVehicle.adressName}
                            >
                              <option>Origen. </option>
                              {directions.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.adressAddress}
                                >
                                  {" "}
                                  {item.adressName}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <>no data</>
                        )}
                        {directions ? (
                          <select
                            id="custom-selectFrom2"
                            label="Tipo de usuario"
                            name="destiny"
                            control="select"
                            onChange={(e) => handleSelectDirection(e)}
                            value={newVehicle.adressName}
                          >
                            <option> Destino. </option>
                            {directions.map((item) => (
                              <option key={item.id} value={item.adressAddress}>
                                {" "}
                                {item.adressName}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>no data</>
                        )}
                      </Form.Group>
                      <hr></hr>
                      <Form.Group widths="equal">
                        <span>Hora de inicio</span>
                        <Form.Field
                          control={Input}
                          type="time"
                          format="hh:mm"
                          name="workingDayMinHour"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.workingDayMinHour}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <hr></hr>
                        <span>Hora de final</span>
                        <Form.Field
                          control={Input}
                          type="time"
                          format="hh:mm"
                          name="workingDayMaxHour"
                          onChange={(e) => habdleOnChange(e)}
                          value={newVehicle.workingDayMaxHour}
                        />
                      </Form.Group>
                      <hr></hr>

                      <button className="btn btn-ebiex">Guardar</button>
                    </form>
                  </Box>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>
          </Grid.Column>

          <Grid.Column
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Ver detalles, pausar actividad y eliminar vehículos."
                title="Vehículos registrados"
              />

              <Divider />
              <Container className="formcontent" id="micuentafromIni">
                <hr></hr>

                <div>
                  <div>
                    {" "}
                    <h3 className="m-1">Vehículos</h3>
                  </div>
                  <Box
                    sx={{
                      height: "500px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {vehiclesRows ? (
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "bicciNumber", sort: "desc" }],
                          },
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={vehiclesRows}
                        columns={columnsVehicles}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        {!vehiclesRows.length > 0 ? (
                          "no hay registros"
                        ) : (
                          <LinearProgress />
                        )}
                      </>
                    )}
                  </Box>
                </div>
              </Container>

              <Divider />
            </Card>
            <div></div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {showPopup ? (
        <Popup
          contenido={objRider}
          togglePopup={togglePopup}
          type={typePop}
        ></Popup>
      ) : null}
    </>
  );
};

export default Vehicles;
