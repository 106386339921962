import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
    },
  },
});

const GuiasViewOrders = ({
  viewOrder,
  setViewOrder,
  exportartReport,
  ...props
}) => {
  const classes = useStyles();

  // console.log(viewOrder);
  var groupedByServiceType = viewOrder.reduce((accumulator, item) => {
    if (!accumulator[item.int_serviceType]) {
      accumulator[item.int_serviceType] = [];
    }
    accumulator[item.int_serviceType].push(item);

    return accumulator;
  }, {});

  var groupedByState = viewOrder.reduce((accumulator, item) => {
    if (item.dataOrigin !== "GSDATA") {
      if (!accumulator[item.status]) {
        accumulator[item.status] = [];
      }
      accumulator[item.status].push(item);
    }
    return accumulator;
  }, {});

  var groupedByGeosort = viewOrder.reduce((accumulator, item) => {
    if (!accumulator[item.int_falabella_status]) {
      accumulator[item.int_falabella_status] = [];
    }
    accumulator[item.int_falabella_status].push(item);
    return accumulator;
  }, {});

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      editable: true,
    },
    {
      field: "int_lpn",
      headerName: "LPN",
      width: 150,
      editable: true,
    },
    { field: "int_serviceType", headerName: "int_serviceType", width: 100 },
    {
      field: "dataOrigin",
      headerName: "dataOrigin",
      width: 120,
      editable: true,
    },
    {
      field: "int_placeName",
      headerName: "int_placeName",
      width: 150,
      editable: true,
    },
    {
      field: "deliveryDate",
      headerName: "deliveryDate",
      width: 150,
      editable: true,
    },
    {
      field: "deliveryComment",
      headerName: "deliveryComment",
      width: 250,
      editable: true,
    },
  ];

  var rows = [];

  for (var i = 0; i < viewOrder.length; i++) {
    rows.push({
      id: viewOrder[i].id,
      deliveryDate: viewOrder[i].deliveryDate,
      int_lpn: viewOrder[i].int_lpn,
      int_serviceType: viewOrder[i].int_serviceType,
      dataOrigin: viewOrder[i].dataOrigin,
      int_placeName: viewOrder[i].int_placeName,
      deliveryComment: viewOrder[i].deliveryComment,
    });
  }
  console.log(viewOrder);

  return (
    <div>
      <div>
        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
          Total : {viewOrder.length}
        </Typography>
        <Button
          size="small"
          color="secondary"
          onClick={() => exportartReport(viewOrder)}
        >
          Exportar vista actual
        </Button>
      </div>
      <Button
        size="small"
        color="primary"
        onClick={() => setViewOrder(groupedByServiceType.SD)}
      >
        <div>total SD: {groupedByServiceType?.SD?.length}</div>
      </Button>
      <Button
        size="small"
        color="primary"
        onClick={() => setViewOrder(groupedByServiceType.HD)}
      >
        {" "}
        <div>total HD: {groupedByServiceType?.HD?.length}</div>
      </Button>
      <Button
        size="small"
        color="primary"
        onClick={() => setViewOrder(groupedByServiceType.HDF)}
      >
        <div>total HDF: {groupedByServiceType?.HDF?.length}</div>
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} sx={{ p: 1 }}>
            Gestionado en Bicci{" "}
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByState[8])}
            >
              <div>
                Entrega Confirmada 8 (Actualizar): {groupedByState[8]?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByState.isRequire)}
            >
              <div>
                Sin Confirmacion de entrega ( Estados Bicci ):{" "}
                {groupedByState?.isRequire?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByState[7])}
            >
              <div>
                Estado Pendiente 7 (Actualizar En Ruta):{" "}
                {groupedByState[7]?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByState[6])}
            >
              <div>
                Estado Pendiente 6 (Actualizar En Ruta):{" "}
                {groupedByState[6]?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByState[1])}
            >
              <div>
                Estado Pendiente 1 (Actualizar En Ruta):{" "}
                {groupedByState[1]?.length}
              </div>
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} sx={{ p: 1 }}>
            Gestionado en Geosort
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByGeosort.Terminado)}
            >
              <div>
                Estado Terminado (Actualizar Proforma):{" "}
                {groupedByGeosort.Terminado?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByGeosort.Pendiente)}
            >
              <div>
                Estado Pendiente (Actualizar Estado):{" "}
                {groupedByGeosort.Pendiente?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByGeosort.Incidencia)}
            >
              <div>
                Estado Incidencia (Actualizar Estado):{" "}
                {groupedByGeosort.Incidencia?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByGeosort.isRequire)}
            >
              <div>
                Estado IsRequire (Actualizar Estado):{" "}
                {groupedByGeosort.isRequire?.length}
              </div>
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setViewOrder(groupedByGeosort["En ruta"])}
            >
              <div>
                Estado En Ruta (Actualizar Estado):{" "}
                {groupedByGeosort["En ruta"]?.length}
              </div>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        className={classes.root}
        groupingModel={["int_serviceType"]}
      ></DataGrid>
    </div>
  );
};

export default GuiasViewOrders;
