import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

const IntegrationEnvironment = () => {
  return (
    <div id="integration-environment">
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Ambiente de Integración
        </Typography>
        <Typography variant="body1" paragraph>
          Este documento proporciona las instrucciones necesarias para
          configurar y validar el ambiente de integración. El objetivo es
          realizar todas las pruebas necesarias en el entorno de integración y,
          una vez confirmadas, proceder con la transición a producción cambiando
          el token y las URLs correspondientes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Instrucciones
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1. Configuración del Ambiente"
              secondary="Asegúrate de que todos los endpoints de la API apuntan al ambiente de integración. Reemplaza las URLs de producción con las URLs de integración proporcionadas."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. Autenticación"
              secondary="Utiliza el token de integración para todas las solicitudes. Este token es diferente del token de producción y debe ser utilizado únicamente en el ambiente de integración."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. Preparación de Datos de Prueba"
              secondary="Crea y utiliza datos de prueba realistas para validar todas las funcionalidades de la API. Asegúrate de que los datos cubren todos los escenarios posibles."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="4. Realización de Pruebas"
              secondary="Ejecuta todas las pruebas necesarias para validar la creación de órdenes y la subida masiva de registros. Asegúrate de que todas las respuestas sean correctas y que los datos se procesen adecuadamente."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="5. Validación de Resultados"
              secondary="Revisa los resultados de las pruebas para verificar que las órdenes se crean y procesan correctamente. Verifica también que los mensajes de error se manejan apropiadamente."
            />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Validaciones Necesarias
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Validación de Autenticación"
              secondary="Confirma que el token de integración funciona correctamente y que todas las solicitudes son autenticadas con éxito."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Validación de Creación de Órdenes"
              secondary="Verifica que las órdenes se crean correctamente con todos los campos requeridos y que el sistema retorna el código orderID."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Validación de Creación Masiva"
              secondary="Asegúrate de que el proceso de subida masiva de registros funciona correctamente y que el sistema puede manejar grandes volúmenes de datos sin errores."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Validación de Respuestas de la API"
              secondary="Revisa que todas las respuestas de la API, tanto de éxito como de error, sean manejadas y presentadas correctamente en el sistema."
            />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Objetivo
        </Typography>
        <Typography variant="body1" paragraph>
          El objetivo es completar todas las pruebas en el ambiente de
          integración. Una vez que todas las funcionalidades han sido validadas
          y confirmadas, se debe proceder a cambiar el token y las URLs a
          producción. Esto garantiza que el sistema esté completamente probado y
          listo para operar en el ambiente de producción sin problemas.
        </Typography>
      </Box>
    </div>
  );
};

export default IntegrationEnvironment;
