import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import PinpassFrom from "../components/formularios/PinpassFrom/PinpassFrom";
import { collection, getDoc, doc } from "firebase/firestore";
import { LinearProgress } from "@mui/material";
var CryptoJS = require("crypto-js");

const PinPassAdminBilling = (
  props,
  { component: Component, usuarioStorage, firebaseUser, ...rest }
) => {
  const [infoFrom, setInfoFrom] = useState(null);
  const [notFound, setNotFound] = useState(true);
  const [pin, setPin] = useState(null);
  const [userAdmin, setuserAdmin] = useState(null);
  const [autorize, setAutorize] = useState(null);
  const [render, setRender] = useState(false);

  const [tokenUserBicci, settokenUserBicci] = useState(
    JSON.parse(localStorage.getItem("tokenUserBicciAdmin"))
  );
  useEffect(() => {}, [autorize, render]);

  useEffect(() => {
    if (props.usuarioStorage) {
      setuserAdmin(props.usuarioStorage);
      if (pin === null || pin === "") {
        setInfoFrom(null);
      }
    }
  }, [props && props.usuarioStorage]);

  useEffect(() => {
    if (pin && userAdmin) {
      if (pin === null || pin === "") {
        setInfoFrom(null);
      }
      if (autorize === false || pin !== null) {
        setInfoFrom("Erro en PIN");
      }

      if (pin === process.env.REACT_APP_ADMIN_BILLING_PASS) {
        setAutorize(true);
        let newToken = {
          user: userAdmin.uid,
          userPin: pin,
          date: Date.now(),
        };

        var toket = JSON.stringify(newToken);
        var encryptData = CryptoJS.AES.encrypt(
          toket,
          `${process.env.REACT_APP_SECRETECRIPT_WEB}`
        ).toString();

        localStorage.setItem(
          "tokenUserBicciAdmin",
          JSON.stringify(encryptData)
        );
      }
    } else {
    }
  }, [pin]);

  useEffect(() => {
    if (!tokenUserBicci) {
      setAutorize(false);
    } else {
      if (tokenUserBicci) {
        try {
          var bytes = CryptoJS.AES.decrypt(
            tokenUserBicci,
            `${process.env.REACT_APP_SECRETECRIPT_WEB}`
          );
          var sessionData = bytes.toString(CryptoJS.enc.Utf8);
          let sessionDataObject = JSON.parse(sessionData);
          if (sessionDataObject) {
            let fechaActual = Date.now();

            let diferencia = fechaActual - sessionDataObject.date;

            let diferenciaEnHoras = diferencia / (1000 * 60 * 60);

            if (diferenciaEnHoras <= 6) {
              if (
                sessionDataObject.userPin ===
                process.env.REACT_APP_ADMIN_BILLING_PASS
              ) {
                setAutorize(true);
              } else {
                localStorage.removeItem("tokenUserBicciAdmin");
                setAutorize(false);
                setRender(!render);
              }
            } else {
              localStorage.removeItem("tokenUserBicciAdmin");
              setAutorize(false);
              setRender(!render);
            }
          }
        } catch (error) {
          console.log(error);
          setInfoFrom("Error de tokent");
        }
      }
    }
  }, [tokenUserBicci]);

  if (autorize !== null) {
    if (autorize === true) {
      return <Outlet />;
    } else {
      return (
        <PinpassFrom
          title={"Ingresa el PIN de administrador."}
          setPin={setPin}
          info={infoFrom}
          notFound={notFound}
        ></PinpassFrom>
      );
    }
  } else {
    return <LinearProgress></LinearProgress>;
  }
};

export default PinPassAdminBilling;
