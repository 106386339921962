import React from 'react';
import {Image} from 'semantic-ui-react';
import useGlobalConfig from '../../hooks/globalConfig';
const Bannerfooter = (props) => {
  const {imgbannerfooter } = useGlobalConfig();


  const FloatMensaje = (footermensaje)=>{
    if(footermensaje === null){
      return ''
    }else{
      return(
      <div className={ props.footermensaje.className}>
        <h1>{props.footermensaje.title}</h1>
        <p>{props.footermensaje.paragramh}</p>
      </div>
        )
    }


  }


  return (
        <div className='containerBannerfooter'>
          <FloatMensaje footermensaje={props.footermensaje}/>
          <Image src={imgbannerfooter} />    
        </div>
        );
};

export default Bannerfooter;
