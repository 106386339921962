import React, { useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

// Registrar los componentes de Chart.js
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

// Registrar la escala radial
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const Rendimiento = () => {
  // Estado para almacenar las evaluaciones
  const [evaluations, setEvaluations] = useState([]);

  // Estado para los valores actuales del formulario
  const [formValues, setFormValues] = useState({
    puntualidad: 0,
    velocidad: 0,
    uniforme: 0,
    entregasCompletadas: 0,
    productosRotos: 0,
    extravios: 0,
    robos: 0,
  });

  // Función para manejar los cambios en el formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: parseInt(value),
    });
  };

  // Función para calcular los promedios
  const calculateAverages = () => {
    const totalEvaluations = evaluations.length;
    if (totalEvaluations === 0) return formValues;

    const sums = evaluations.reduce(
      (acc, evaluation) => {
        acc.puntualidad += evaluation.puntualidad;
        acc.velocidad += evaluation.velocidad;
        acc.uniforme += evaluation.uniforme;
        acc.entregasCompletadas += evaluation.entregasCompletadas;
        acc.productosRotos += evaluation.productosRotos;
        acc.extravios += evaluation.extravios;
        acc.robos += evaluation.robos;
        return acc;
      },
      {
        puntualidad: 0,
        velocidad: 0,
        uniforme: 0,
        entregasCompletadas: 0,
        productosRotos: 0,
        extravios: 0,
        robos: 0,
      }
    );

    return {
      puntualidad: sums.puntualidad / totalEvaluations,
      velocidad: sums.velocidad / totalEvaluations,
      uniforme: sums.uniforme / totalEvaluations,
      entregasCompletadas: sums.entregasCompletadas / totalEvaluations,
      productosRotos: sums.productosRotos / totalEvaluations,
      extravios: sums.extravios / totalEvaluations,
      robos: sums.robos / totalEvaluations,
    };
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    setEvaluations([...evaluations, formValues]);
    setFormValues({
      puntualidad: 0,
      velocidad: 0,
      uniforme: 0,
      entregasCompletadas: 0,
      productosRotos: 0,
      extravios: 0,
      robos: 0,
    });
  };

  // Calcular los promedios actuales
  const averages = calculateAverages();

  // Configuración del gráfico radial
  const radarData = {
    labels: [
      "Puntualidad",
      "Velocidad",
      "Uniforme",
      "Entregas Completadas",
      "Productos Rotos",
      "Extravíos",
      "Robos en Ruta",
    ],
    datasets: [
      {
        label: "Promedio de Evaluación",
        data: [
          averages.puntualidad,
          averages.velocidad,
          averages.uniforme,
          averages.entregasCompletadas,
          averages.productosRotos,
          averages.extravios,
          averages.robos,
        ],
        backgroundColor: "rgba(50, 216, 72, 0.2)",
        borderColor: "rgba(50, 216, 72, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Rendimiento del Conductor
      </Typography>

      <Grid container spacing={4}>
        {/* Gráfico Radial */}
        <Grid item xs={12} md={6}>
          <Radar data={radarData} />
        </Grid>

        {/* Formulario de Evaluación */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Ingresar Evaluación
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Puntualidad"
                  name="puntualidad"
                  type="number"
                  value={formValues.puntualidad}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Velocidad"
                  name="velocidad"
                  type="number"
                  value={formValues.velocidad}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Uniforme"
                  name="uniforme"
                  type="number"
                  value={formValues.uniforme}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Entregas Completadas"
                  name="entregasCompletadas"
                  type="number"
                  value={formValues.entregasCompletadas}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Productos Rotos"
                  name="productosRotos"
                  type="number"
                  value={formValues.productosRotos}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Extravíos"
                  name="extravios"
                  type="number"
                  value={formValues.extravios}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Robos en Ruta"
                  name="robos"
                  type="number"
                  value={formValues.robos}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <Button type="submit" variant="contained" color="primary">
                Guardar Evaluación
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Rendimiento;
