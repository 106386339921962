import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import FileUploadRut from "../../../components/formularios/FrmsUpFiles/FileUploadRut.jsx";
import FileUploadDocComercio from "../../../components/formularios/FrmsUpFiles/FileUploadDocComercio.jsx";
import FileUploadDocSii from "../../../components/formularios/FrmsUpFiles/FileUploadDocSii.jsx";
import FileUploadDocEbiexComercio from "../../../components/formularios/FrmsUpFiles/FileUploadDocEbiexComercio.jsx";
import FileUploadPatente from "../../../components/formularios/FrmsUpFiles/FileUploadPatente.jsx";
import FileUploadLogo from "../../../components/formularios/FrmsUpFiles/FileUploadLogo.jsx";
import Fromcompanynew from "../../../components/formularios/Fromcompany/Fromcompanynew.jsx";
import Fromcompanywiew from "../../../components/formularios/Fromcompany/Fromcompanywiew.jsx";
import useGlobalConfig from "../../../hooks/globalConfig.js";
import { useLoadScript } from "@react-google-maps/api";
import { UserContext } from "../../../context/CurrentUserProvider.jsx";
import FromFindCompany from "./FromFindCompany/FromFindCompany.jsx";
import { getRequestAccessToCompany } from "../../../utils/requestAccessToCompany.jsx";

const libraries = ["places"];

const Companies = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const { userDataContext } = useContext(UserContext);
  const { userData, companyData } = userDataContext;
  const { siteName } = useGlobalConfig();
  const [authorized, setAuthorized] = useState(false);
  const [viewRegister, setViewRegister] = useState(false);
  const [ViewFindCompany, setViewFindCompany] = useState(false);
  const [requestDocs, setRequestDocs] = useState([]);

  useEffect(() => {
    if (userData) {
      const rolesAllowed = [
        process.env.REACT_APP_ADMIN,
        process.env.REACT_APP_ADMIN_COMPANY,
        process.env.REACT_APP_ADMIN_XPRESS,
      ];
      if (rolesAllowed.includes(userData.userCompanyRoll)) {
        setAuthorized(true);
      }
      getRequest(userData.uid);
      getUserPerfiles();
    }
  }, [userData]);

  useEffect(() => {}, [requestDocs]);

  const getRequest = async (userId) => {
    const documents = await getRequestAccessToCompany(userId);
    setRequestDocs(documents);
  };

  const getUserPerfiles = async () => {
    console.log(userData);
  };

  const handleRequestAccess = () => {
    setTimeout(() => {
      setViewRegister(false);
    }, 2000);
    setTimeout(() => {
      setViewFindCompany(true);
    }, 1000);
    // Logic to request access to a company
  };

  const toggleChildTrigger = () => {
    setTimeout(() => {
      setViewRegister(true);
    }, 2000);
    setTimeout(() => {
      setViewFindCompany(false);
    }, 1000);
  };
  console.log(userData);
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return userData ? (
    <Grid sx={{ margin: "3px", display: "flex-colum", padding: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h4" className="h2-withe">
          Mi Empresa
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {companyData ? (
          <Box sx={{ display: "flex", mb: 1 }}>
            <Grid
              sx={{
                width: {
                  xs: "100%", // pantalla extra pequeña (mobile)
                  sm: "100%", // pantalla pequeña (tablet)
                  md: "100%", // pantalla mediana (desktop)
                  lg: "30%", // pantalla grande (larger desktop)
                  xl: "30%", // pantalla extra grande
                },
                padding: "10px", // padding común para todos los tamaños
              }}
            >
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      src={companyData.companyImgLogo}
                      sx={{ height: 64, mb: 2, width: 64 }}
                    />
                    <Typography variant="h5">
                      {companyData.companyName || "Sin registro"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {companyData.companyCity || "Sin registro"}
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
              </Card>

              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6">Datos de facturación</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    {companyData.isActive ? (
                      <span>Activo</span>
                    ) : (
                      <span>
                        <i className="exclamation circle icon alert-danger"></i>{" "}
                        Activación en espera.. Puede solicitar revisión en
                        soporte
                      </span>
                    )}
                  </Typography>
                  {/* Continue the rest of the status checks similarly */}
                  <Divider sx={{ my: 1 }} />
                </CardContent>
                <Divider />
                <CardActions>{/* Optionally add actions here */}</CardActions>
              </Card>
            </Grid>

            <Grid
              sx={{
                width: {
                  xs: "100%", // pantalla extra pequeña (mobile)
                  sm: "100%", // pantalla pequeña (tablet)
                  md: "100%", // pantalla mediana (desktop)
                  lg: "70%", // pantalla grande (larger desktop)
                  xl: "70%", // pantalla extra grande
                },
                padding: "10px", // padding común para todos los tamaños
              }}
            >
              <Fromcompanywiew company={companyData} userUid={userData.uid} />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={12} lg={4} xl={4}>
              <FileUploadRut
                userUid={userData.uid}
                authorized={authorized}
                prefix={"rut"}
                prefixf={"companies"}
                title={"Rut"}
                descripcion={
                  "Rut del representante legal de la empresa (Pdf, Jpg)"
                }
                objRef="imagen"
                companyUid={userData.userCompanyID}
                defaultImg={companyData.companyImgRut}
                status={companyData.statusDocsRut}
              />
          
            </Grid> */}
          </Box>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h6" color="textSecondary">
              No estás registrado en una empresa.
            </Typography>
            <Box
              sx={{ display: "flex", mb: 1, justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleRequestAccess}
              >
                Solicitar acceso a una empresa
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ ml: 2 }}
                onClick={toggleChildTrigger}
              >
                Registrar nueva empresa
              </Button>
            </Box>
            {!viewRegister && !ViewFindCompany && requestDocs?.length > 0 ? (
              <Box sx={{ m: 1 }}>
                <Typography variant="h6" color="#f8f8f8">
                  Solicitudes enviadas
                </Typography>
                <List>
                  {requestDocs.map((request, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        backgroundColor: "#f8f8f8",
                        p: 1,
                        borderRadius: "8px",
                        m: 1,
                      }}
                    >
                      <ListItemText
                        primary={request.companyName}
                        sx={{ m: 1 }}
                      />
                      <Chip
                        label={request.status}
                        color={
                          request.status === "Pendiente"
                            ? "warning"
                            : request.status === "Aceptado"
                            ? "success"
                            : "error"
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : null}
            {viewRegister ? (
              <Fromcompanynew
                company={companyData}
                userUid={userData.uid}
                togglechildtrigres={toggleChildTrigger}
              />
            ) : null}
            {ViewFindCompany ? (
              <FromFindCompany
                userId={userData.uid}
                userName={userData.userName}
                email={userData.email}
              ></FromFindCompany>
            ) : null}
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <LinearProgress />
  );
};

export default Companies;
