import * as yup from "yup";

export const order = {
  id: "isRequire",
  companyId: "isRequire",
  company: "isRequire",
  commerce: "isRequire",
  int_warehouseCode: "isRequire",
  int_warehouseName: "isRequire",
  int_placeName: "isRequire",
  int_travelDate: "isRequire",
  int_windowStart: "isRequire",
  int_windowEnd: "isRequire",
  int_serviceLevel: "isRequire",
  int_f12: "isRequire",
  int_travelNumber: "isRequire",
  int_client_soc: "isRequire",
  int_serviceType: "isRequire",
  int_clientDescriptor: "isRequire",
  int_simpliRouteId: "isRequire",
  int_bicciStatus: "isRequire",
  int_falabella_status: "isRequire",
  int_falabella_status_descriptor: "isRequire",
  int_scheduledDateRange: "isRequire",
  int_lpn: "isRequire",
  fromAddress: "isRequire",
  fromAddressLocation: "isRequire",
  userEmail: "isRequire",
  userID: "isRequire",
  userName: "isRequire",
  userPhone: "isRequire",
  clientNames: "isRequire",
  clientRut: "isRequire",
  clientEmail: "isRequire",
  toAddress: "isRequire",
  toAddressLocation: "isRequire",
  direccion1: "isRequire",
  direccion2: "isRequire",
  direccion3: "isRequire",
  commune: "isRequire",
  commune2: "isRequire",
  region: "isRequire",
  serviceType: "isRequire",
  deliveryDate: "isRequire",
  paymentRequestId: "isRequire",
  origin: "isRequire",
  createdAt: "isRequire",
  statusCreate: "isRequire",
  status: "isRequire",
  description: "isRequire",
  zonePoligon: "isRequire",
  pickedUp: "isRequire",
  serviceLevel: "isRequire",
  polygonID: "isRequire",
  productLength: "isRequire",
  productHeight: "isRequire",
  productWidth: "isRequire",
  productWeight: "isRequire",
  productName: "isRequire",
  productSku: "isRequire",
  productSize: "isRequire",
  productUnits: "isRequire",
  productValue: "isRequire",
  productVolume: "isRequire",
  productMultiDelivery: "isRequire",
  driverTruckDescription: "isRequire",
  driverAvatar: "isRequire",
  driverEmail: "isRequire",
  driverName: "isRequire",
  driverRut: "isRequire",
  driverID: "isRequire",
  driverPhone: "isRequire",
  driverLocation: "isRequire",
  driverLicensePlate: "isRequire",
  driverAmountDriver: "isRequire",
  driverAmountPromotions: "isRequire",
  driverAmountOtherEarnings: "isRequire",
  driverVehicle: "isRequire",
  routeRanceTotalization: "isRequire",
  routeEstimatedDate: "isRequire",
  routeStartDate: "isRequire",
  routeScheduledDate: "isRequire",
  routeEndTime: "isRequire",
  routeStartTime: "isRequire",
  routeId: "isRequire",
  routePosition: "isRequire",
  routeLocality: "isRequire",
  deliveryMethod: "isRequire",
  deliveryFailureReason: "isRequire",
  deliveryPictures: "isRequire",
  deliveryRealDeliveryDate: "isRequire",
  deliveryComment: "isRequire",
  deliveryReceptor: "isRequire",
  deliveryRut: "isRequire",
  deliveryGeoref: "isRequire",
  billingCurrencyCode: "isRequire",
  billingCurrencySymbol: "isRequire",
  billingPaymentRequestType: "isRequire",
  billingPaymentProforma: "isRequire",
  billingPaymentValor: "isRequire",
  billingPaymentType_service: "isRequire",
};

export const newOrderValue = [
  {
    variable: "id",
    label: "Id",
    type: "string",
    require: true,
  },
  {
    variable: "companyId",
    label: "Company Id",
    type: "string",
    require: true,
  },
  {
    variable: "company",
    label: "Company",
    type: "string",
    require: true,
  },
  {
    variable: "commerce",
    label: "Commerce",
    type: "string",
    require: true,
  },
  {
    variable: "int_warehouseCode",
    label: "Int_warehouse Code",
    type: "string",
    require: true,
  },
  {
    variable: "int_warehouseName",
    label: "Int_warehouse Name",
    type: "string",
    require: true,
  },
  {
    variable: "int_placeName",
    label: "Int_place Name",
    type: "string",
    require: true,
  },
  {
    variable: "int_travelDate",
    label: "Int_travel Date",
    type: "string",
    require: true,
  },
  {
    variable: "int_windowStart",
    label: "Int_window Start",
    type: "string",
    require: true,
  },
  {
    variable: "int_windowEnd",
    label: "Int_window End",
    type: "string",
    require: true,
  },
  {
    variable: "int_serviceLevel",
    label: "Int_service Level",
    type: "string",
    require: true,
  },
  {
    variable: "int_f12",
    label: "Int_f12",
    type: "string",
    require: true,
  },
  {
    variable: "int_travelNumber",
    label: "Int_travel Number",
    type: "string",
    require: true,
  },
  {
    variable: "int_client_soc",
    label: "Int_client_soc",
    type: "string",
    require: true,
  },
  {
    variable: "int_serviceType",
    label: "Int_service Type",
    type: "string",
    require: true,
  },
  {
    variable: "int_clientDescriptor",
    label: "Int_client Descriptor",
    type: "string",
    require: true,
  },
  {
    variable: "int_simpliRouteId",
    label: "Int_simpli Route Id",
    type: "string",
    require: true,
  },
  {
    variable: "int_bicciStatus",
    label: "Int_bicci Status",
    type: "string",
    require: true,
  },
  {
    variable: "int_falabella_status",
    label: "Int_falabella_status",
    type: "string",
    require: true,
  },
  {
    variable: "int_falabella_status_descriptor",
    label: "Int_falabella_status_descriptor",
    type: "string",
    require: true,
  },
  {
    variable: "int_scheduledDateRange",
    label: "Int_scheduled Date Range",
    type: "string",
    require: true,
  },
  {
    variable: "int_lpn",
    label: "Int_lpn",
    type: "string",
    require: true,
  },
  {
    variable: "fromAddress",
    label: "From Address",
    type: "string",
    require: true,
  },
  {
    variable: "fromAddressLocation",
    label: "From Address Location",
    type: "string",
    require: true,
  },
  {
    variable: "userEmail",
    label: "User Email",
    type: "string",
    require: true,
  },
  {
    variable: "userID",
    label: "User ID",
    type: "string",
    require: true,
  },
  {
    variable: "userName",
    label: "User Name",
    type: "string",
    require: true,
  },
  {
    variable: "userPhone",
    label: "User Phone",
    type: "string",
    require: true,
  },
  {
    variable: "clientNames",
    label: "Client Names",
    type: "string",
    require: true,
  },
  {
    variable: "clientRut",
    label: "Client Rut",
    type: "string",
    require: true,
  },
  {
    variable: "clientEmail",
    label: "Client Email",
    type: "string",
    require: true,
  },
  {
    variable: "toAddress",
    label: "To Address",
    type: "string",
    require: true,
  },
  {
    variable: "toAddressLocation",
    label: "To Address Location",
    type: "string",
    require: true,
  },
  {
    variable: "direccion1",
    label: "Direccion1",
    type: "string",
    require: true,
  },
  {
    variable: "direccion2",
    label: "Direccion2",
    type: "string",
    require: true,
  },
  {
    variable: "direccion3",
    label: "Direccion3",
    type: "string",
    require: true,
  },
  {
    variable: "commune",
    label: "Commune",
    type: "string",
    require: true,
  },
  {
    variable: "commune2",
    label: "Commune2",
    type: "string",
    require: true,
  },
  {
    variable: "region",
    label: "Region",
    type: "string",
    require: true,
  },
  {
    variable: "serviceType",
    label: "Service Type",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryDate",
    label: "Delivery Date",
    type: "string",
    require: true,
  },
  {
    variable: "paymentRequestId",
    label: "Payment Request Id",
    type: "string",
    require: true,
  },
  {
    variable: "origin",
    label: "Origin",
    type: "string",
    require: true,
  },
  {
    variable: "createdAt",
    label: "Created At",
    type: "string",
    require: true,
  },
  {
    variable: "statusCreate",
    label: "Status Create",
    type: "string",
    require: true,
  },
  {
    variable: "status",
    label: "Status",
    type: "string",
    require: true,
  },
  {
    variable: "description",
    label: "Description",
    type: "string",
    require: true,
  },
  {
    variable: "zonePoligon",
    label: "Zone Poligon",
    type: "string",
    require: true,
  },
  {
    variable: "pickedUp",
    label: "Picked Up",
    type: "string",
    require: true,
  },
  {
    variable: "serviceLevel",
    label: "Service Level",
    type: "string",
    require: true,
  },
  {
    variable: "polygonID",
    label: "Polygon ID",
    type: "string",
    require: true,
  },
  {
    variable: "productLength",
    label: "Product Length",
    type: "string",
    require: true,
  },
  {
    variable: "productHeight",
    label: "Product Height",
    type: "string",
    require: true,
  },
  {
    variable: "productWidth",
    label: "Product Width",
    type: "string",
    require: true,
  },
  {
    variable: "productWeight",
    label: "Product Weight",
    type: "string",
    require: true,
  },
  {
    variable: "productName",
    label: "Product Name",
    type: "string",
    require: true,
  },
  {
    variable: "productSku",
    label: "Product Sku",
    type: "string",
    require: true,
  },
  {
    variable: "productSize",
    label: "Product Size",
    type: "string",
    require: true,
  },
  {
    variable: "productUnits",
    label: "Product Units",
    type: "string",
    require: true,
  },
  {
    variable: "productValue",
    label: "Product Value",
    type: "string",
    require: true,
  },
  {
    variable: "productVolume",
    label: "Product Volume",
    type: "string",
    require: true,
  },
  {
    variable: "productMultiDelivery",
    label: "Product Multi Delivery",
    type: "string",
    require: true,
  },
  {
    variable: "driverTruckDescription",
    label: "Driver Truck Description",
    type: "string",
    require: true,
  },
  {
    variable: "driverAvatar",
    label: "Driver Avatar",
    type: "string",
    require: true,
  },
  {
    variable: "driverEmail",
    label: "Driver Email",
    type: "string",
    require: true,
  },
  {
    variable: "driverName",
    label: "Driver Name",
    type: "string",
    require: true,
  },
  {
    variable: "driverRut",
    label: "Driver Rut",
    type: "string",
    require: true,
  },
  {
    variable: "driverID",
    label: "Driver ID",
    type: "string",
    require: true,
  },
  {
    variable: "driverPhone",
    label: "Driver Phone",
    type: "string",
    require: true,
  },
  {
    variable: "driverLocation",
    label: "Driver Location",
    type: "string",
    require: true,
  },
  {
    variable: "driverLicensePlate",
    label: "Driver License Plate",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountDriver",
    label: "Driver Amount Driver",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountPromotions",
    label: "Driver Amount Promotions",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountOtherEarnings",
    label: "Driver Amount Other Earnings",
    type: "string",
    require: true,
  },
  {
    variable: "driverVehicle",
    label: "Driver Vehicle",
    type: "string",
    require: true,
  },
  {
    variable: "routeRanceTotalization",
    label: "Route Rance Totalization",
    type: "string",
    require: true,
  },
  {
    variable: "routeEstimatedDate",
    label: "Route Estimated Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeStartDate",
    label: "Route Start Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeScheduledDate",
    label: "Route Scheduled Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeEndTime",
    label: "Route End Time",
    type: "string",
    require: true,
  },
  {
    variable: "routeStartTime",
    label: "Route Start Time",
    type: "string",
    require: true,
  },
  {
    variable: "routeId",
    label: "Route Id",
    type: "string",
    require: true,
  },
  {
    variable: "routePosition",
    label: "Route Position",
    type: "string",
    require: true,
  },
  {
    variable: "routeLocality",
    label: "Route Locality",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryMethod",
    label: "Delivery Method",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryFailureReason",
    label: "Delivery Failure Reason",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryPictures",
    label: "Delivery Pictures",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryRealDeliveryDate",
    label: "Delivery Real Delivery Date",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryComment",
    label: "Delivery Comment",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryReceptor",
    label: "Delivery Receptor",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryRut",
    label: "Delivery Rut",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryGeoref",
    label: "Delivery Georef",
    type: "string",
    require: true,
  },
  {
    variable: "billingCurrencyCode",
    label: "Billing Currency Code",
    type: "string",
    require: true,
  },
  {
    variable: "billingCurrencySymbol",
    label: "Billing Currency Symbol",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentRequestType",
    label: "Billing Payment Request Type",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentProforma",
    label: "Billing Payment Proforma",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentValor",
    label: "Billing Payment Valor",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentType_service",
    label: "Billing Payment Type_service",
    type: "string",
    require: true,
  },
];

export const orderValidationSchema = yup.object().shape({
  data: yup.object().shape({
    companyID: yup.string().required("El ID de la empresa es obligatorio"),
    orders: yup.array().of(
      yup.object().shape({
        id: yup.number().required("El ID de la orden es obligatorio"),
        date: yup.string().required("La fecha es obligatoria"),
        deliveryDate: yup
          .string()
          .required("La fecha de entrega es obligatoria"),
        deliveryType: yup
          .string()
          .oneOf(["HD", "SD"], "Tipo de entrega no válido")
          .required("El tipo de entrega es obligatorio"),
        whcode: yup.string().required("El código de almacén es obligatorio"),
        fromAddress: yup
          .string()
          .required("La dirección de origen es obligatoria"),
        direcction1: yup.string().nullable(),
        direcction2: yup.string().nullable(),
        direcction3: yup.string().nullable(),
        comuna: yup.string().required("La comuna es obligatoria"),
        region: yup.string().required("La región es obligatoria"),
        country: yup.string().required("El país es obligatorio"),
        fromLocation: yup.object().shape({
          lat: yup.number().required("La latitud de origen es obligatoria"),
          lng: yup.number().required("La longitud de origen es obligatoria"),
        }),
        toAddress: yup
          .string()
          .required("La dirección de destino es obligatoria"),
        toLocation: yup.object().shape({
          lat: yup.number().required("La latitud de destino es obligatoria"),
          lng: yup.number().required("La longitud de destino es obligatoria"),
        }),
        toAddressComment: yup.string().nullable(),
        userPhone: yup
          .string()
          .required("El teléfono del usuario es obligatorio"),
        userEmail: yup
          .string()
          .email("Debe ser un correo válido")
          .required("El correo electrónico del usuario es obligatorio"),
        userName: yup.string().required("El nombre del usuario es obligatorio"),
        product: yup.string().required("El nombre del producto es obligatorio"),
        groceries: yup.array().of(
          yup.object().shape({
            description: yup.string().nullable(),
            id: yup.string().required("El ID del producto es obligatorio"),
            name: yup
              .string()
              .required("El nombre del producto es obligatorio"),
            instructions: yup.string().nullable(),
            quantity: yup.number().required("La cantidad es obligatoria"),
            price: yup.number().required("El precio es obligatorio"),
            height: yup.number().required("La altura es obligatoria"),
            width: yup.number().required("El ancho es obligatorio"),
            length: yup.number().required("La longitud es obligatoria"),
            weight: yup.number().required("El peso es obligatorio"),
            volume: yup.number().required("El volumen es obligatorio"),
          })
        ),
      })
    ),
  }),
});

export const emptyOrder = {
  id: "",
  date: "",
  deliveryDate: "",
  deliveryType: "HD",
  whcode: "",
  fromAddress: "",
  direcction1: "",
  direcction2: "",
  direcction3: "",
  comuna: "",
  region: "",
  country: "",
  fromLocation: { lat: null, lng: null },
  toAddress: "",
  toLocation: { lat: null, lng: null },
  toAddressComment: "",
  userPhone: "",
  userEmail: "",
  userName: "",
  product: "",
  fragile: false,
  multipack: false,
  insurance: false,
  groceries: [
    {
      description: "",
      id: "",
      name: "",
      instructions: "",
      quantity: 0,
      price: 0,
      height: 0,
      width: 0,
      length: 0,
      weight: 0,
      volume: 0,
    },
  ],
};

export const emptyReq = {
  companyID: "",
  data: emptyOrder,
};
