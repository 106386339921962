import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

const dataInicial = {
  loading: true,
};

// types
const LOADING_DELIVERY_ROUTE = "LOAD_DELIVERY_ROUTE";
const DELIVERY_ROUTE_SUCCESS = "DELIVERY_ROUTE_SUCCESS";
const DELIVERY_ROUTE_ERROR = "DELIVERY_ROUTE_ERROR";

// reducer
export default function routesClientTraking(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_DELIVERY_ROUTE:
      return { ...state };
    case DELIVERY_ROUTE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case DELIVERY_ROUTE_ERROR:
      return { ...state, loading: false, ...action.payload };
    default:
      return state;
  }
}

// acciones
export const getDataDispachst = (orderId) => async (dispatch) => {
  dispatch({ type: LOADING_DELIVERY_ROUTE });

  const findRiderOrders = async (orderId) => {
    const clients = [
      {
        key: "F",
        collectionData: process.env.REACT_APP_COL_FALABELLA_ORDERS,
      },
      {
        key: "BICCI",
        collectionData: process.env.REACT_APP_COL_USERS_ORDERS,
      },
      // {
      //   caracterer: "S",
      //   collectionData: process.env.REACT_APP_COL_SENDU_ORDERS,
      // },
      // {
      //   caracterer: "E",
      //   collectionData: process.env.REACT_APP_COL_ENVIAME_ORDERS,
      // },
    ];
    try {
      const ordesBicciexpres = await getDocs(
        query(
          collection(db, process.env.REACT_APP_COL_USERS_ORDERS),
          where("id", "==", orderId)
        )
      );
      var dataOrdes = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const dataOrdesBicciExpress = dataOrdes;
      console.log(dataOrdesBicciExpress);
      if (dataOrdesBicciExpress.length === 0) {
        dispatch({
          type: DELIVERY_ROUTE_ERROR,
          payload: { error: "Pedido no encontrado" },
        });
      } else {
        dispatch({
          type: DELIVERY_ROUTE_SUCCESS,
          payload: { bicciorder: dataOrdesBicciExpress },
        });
      }
    } catch (error) {
      console.log({ error: "no found" });
    }

    try {
      const clearID = orderId.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const falabellaOrderData = await getDoc(doc(falabellaOrderRef, clearID));

      if (falabellaOrderData.exists()) {
        const orderDataSoc = falabellaOrderData.data();
        console.log(orderDataSoc);
        dispatch({
          type: DELIVERY_ROUTE_SUCCESS,
          payload: { bicciClient: orderDataSoc },
        });

        try {
          const clearID = orderId.replace(/F/g, "");
          const falabellaOrderRef = collection(
            db,
            process.env.REACT_APP_COL_FALABELLA_ORDERS
          );

          const statusRef = doc(falabellaOrderRef, clearID.toString());

          const statusDocs = collection(statusRef, "status");

          const dataallOrdersfind5 = await getDocs(statusDocs);
          const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          datadocuments.sort((a, b) =>
            a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
          );

          dispatch({
            type: DELIVERY_ROUTE_SUCCESS,
            payload: { bicciClientHistory: datadocuments },
          });
        } catch (error) {
          dispatch({
            type: DELIVERY_ROUTE_SUCCESS,
            payload: { bicciClientHistory: [] },
          });
        }
      } else {
        dispatch({
          type: DELIVERY_ROUTE_ERROR,
          payload: { error: "Pedido no encontrado" },
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_ROUTE_ERROR,
        payload: { error: "Pedido no encontrado" },
      });
    }
  };
  findRiderOrders(orderId);
};
