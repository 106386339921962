import React from 'react';
import {Container} from 'semantic-ui-react';
import Pos2 from '../postcontent/inicio/Pos2/Pos2';
import { animateScroll } from 'react-scroll';
import useTitle from '../../../components/Seo/useTitle';
import useSeo from '../../../components/Seo/useSeo';

const Nosotros = () => {
useTitle({title:'Acerca de BICCI EXPRESS'})
useSeo({description:'Empresa de logística y transporte comprometida con el desarrollo social en chile, que busca ayudar al medio ambiente por medio de la tecnología y la electromovilidad de nuestros vehículos, reduciendo la huella de carbono en la ciudad'})


  return (  
    <div>
    <Container className='bodycontent'>
     <div>
     <Pos2/>
    </div>
    </Container> 
   
    </div>
    ); 
  };
export default Nosotros;
