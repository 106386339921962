import React, { useEffect } from "react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import { Container, Item } from "semantic-ui-react";
import useObtenerServiciosAcivos from "../../hooks/obtenerServiciosAcivos";
import { CircularProgress } from "@mui/material";

const Boxinfoservices = (props) => {
  //console.log(props.serviciosAct)
  const { serviciosActivos, serviciosContratados, loading, error } =
    useObtenerServiciosAcivos(props.serviciosAct);
  const [servicios, setservicios] = React.useState("");

  React.useEffect(() => {}, [serviciosContratados]);
  // console.log(loading)
  // console.log(serviciosActivos)
  // console.log(serviciosContratados)
  return (
    <Card className="typeMiniforms">
      <CardHeader subheader="Tus servicios" title="Información" />
      <Divider />
      <Container className="formcontentmin" id="micuentafrominidash1">
        <span>Servicios: </span>

        {serviciosContratados ? (
          <>
            {serviciosContratados.map((item) => (
              <div key={item.id}>
                <span> {item.name} </span>
              </div>
            ))}
          </>
        ) : (
          <>
            {loading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>{error ? <>No contratados</> : <>2</>}</>
            )}
          </>
        )}
      </Container>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          sx={{
            backgroundColor: "#591E8F",
            border: "none",
            borderRadius: "3px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "30px",
            outline: "none",
            padding: "5px",
            minWidth: "50px",
            fontWeight: 400,
            margin: "0em 1em 0em 0em",
            ":hover": {
              backgroundColor: "#25b2f4 !important",
              color: "#591e8f",
            },
          }}
        >
          Ver mas...
        </Button>
      </Box>
    </Card>
  );
};

export default Boxinfoservices;
