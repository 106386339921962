import React from 'react'
import {
  Form,
  Input,
  Container,} from 'semantic-ui-react'
import InfoIcon from '@mui/icons-material/Info';
import BtnAceceder from '../../Login/buttons/BtnAceceder';
import { ingresoUsuarioLogin} from '../../../redux/usuarioDucks'


class Fromloging extends React.Component{
constructor(props) {
super(props);
//console.log(this.props);
this.state = {
  email:"",
  pass:"",
  success:"",
  error:"",
  resperror:"",
 }
 this.procesarDatos = this.procesarDatos.bind(this);
 this.hadlechage = this.hadlechage.bind(this) 

}
//const Fromloging = (props) => {
//console.log(props)

render(){
  return (<>
    {this.props.state === true ? (
        <div className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
              <h5 className="title">
                Estas conectado con la cuenta:  {this.props.user.email}
              </h5>
              <div className="ui-seller-info"></div>
        </div>
      ) : (  <div className="alert alert-danger ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
      <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
        <div className="ui-box-component ui-box-component-pdp__visible--desktop">
          <h3 className="title">
          <p className="ui-pdp-color--GRAY ui-pdp-size--XSMALL ui-pdp-family--REGULAR ui-pdp-seller__header__subtitle">
           <InfoIcon color="success"></InfoIcon>
           Se requiere inicio de sesión:<br></br></p>
          <Form onSubmit={this.procesarDatos}>
                        {
                            this.state.success ? (
                                <div className="alert alert-success">
                                {this.state.success}
                            </div>
                            ):null
                        }
                         {
                            this.state.error ? (
                                <div className="alert alert-danger">
                                {this.state.error}
                            </div>
                            ):null
                        }
                         {
                            this.props.resperror ? (
                                <div className="alert alert-danger">
                                { this.props.resperror }
                            </div>
                            ):null
                        }



                        <Form.Group widths='equal'>
                        <Form.Field 
                          iconPosition='left' placeholder='Email'
                          control={Input}
                          type="email" 
                          label='Email'
                          name='email'
                          icon='at'
                          onChange={this.hadlechage}
                          value ={this.state.email}
                          /> 
                      </Form.Group>
                      <Form.Group widths='equal'>
                      <Form.Field 
                          control={Input}
                          type = "password"
                          label='Contraseña'
                          placeholder='Contraseña'
                          iconPosition='left'
                          icon = 'lock' 
                          name={'pass'}
                          onChange={this.hadlechage}
                          value ={this.state.pass}
                        />
                      </Form.Group>
                      <button 
                        className="btn btn-ebiex "
                        type="sutmit">
                        Entrar
                      </button>
                      <div>
                      <BtnAceceder></BtnAceceder>
                      </div>
                      

           </Form>
          </h3>
          {/* <div className="ui-seller-info"></div>  <button onClick={this.props.accion}> Change</button> */}
        </div>
      </div>
    </div>)}</>)
    }

async procesarDatos(e) {
      e.preventDefault();
      //console.log('proces')
      
      if(!this.state.email.trim()){
            this.setState({ error: "Ingrese Email" });
            return
        }
      if(!this.state.pass.trim()){
          this.setState({ error: "Ingrese un Password" });
          return
      }
      try{
        this.props.dispatch(ingresoUsuarioLogin(this.state.email, this.state.pass))
        this.props.state(true)
      }catch(error){
        //console.log(error)
      }
   }



async hadlechage(e){
  e.preventDefault();
  const target = e.target;
  const name = target.name;
  const value = target.value;
  this.setState({ [name]: value });
 // console.log('chage')
  }
}

export default Fromloging