import { db } from "../firebase";
var CryptoJS = require("crypto-js");
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
  itemid: "",
};

const LOADING_CART = "LOADING_CART";
const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_ALL_FROM_CART = "REMOVE_ALL_FROM_CART";
const REMOVE_ONE_FROM_CART = "REMOVE_ONE_FROM_CART";
const CLEAR_CART = "CLEAR_CART";
const CART_CONTENT = "CART_CONTENT";
const ERROR_CART = "ERROR_CART";

export default function cartAccions(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_CART:
      return { ...state, loading: true };
    case ADD_TO_CART:
      return { ...state, cart: action.cart, loading: false };
    case REMOVE_ALL_FROM_CART:
      return { ...state };
    case REMOVE_ONE_FROM_CART:
      return { ...state };
    case CART_CONTENT:
      return {
        ...state,
        cart: action.cart,
        cartContent: action.cartContent,
        loading: action.loading,
      };
    case CLEAR_CART:
      return { ...state };
    case ERROR_CART:
      return { ...state, cartErr: action.cartErr };
    default:
      return state;
  }
}

export const clientAddCartProduct = (product, uid) => async (dispatch) => {
  let itemCard = {
    article: [],
  };

  let cardItem = {
    cartArticleId: "",
    articleType: "", // tipo de articulo pack de Servico Express  ARTYPE
    ServicExpressType: "", //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,
    rateService: "",
    productName: "",
    cartArticlePrice: 0,
    cartArticlCuality: 1,
    subTotalItem: 0,
    shippimgMethod: "",
    shippimgAmount: 0,
    ShippingAddress: "",
    discount: 0,
    discountType: "",
    ChargeShipments: 0,
  };

  let car = [];
  itemCard.product = product;
  //console.log(product);
  //console.log(uid);

  dispatch({
    type: LOADING_CART,
  });

  try {
    const productos = await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .doc(product)
      .get();
    if (productos.data()) {
      itemCard.article.push(productos.data());
    }
  } catch (error) {
    console.log(error);
  }
  try {
    const bolsas = await db
      .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .doc(product)
      .get();
    if (bolsas.data()) {
      itemCard.article.push(bolsas.data());
    }
  } catch (error) {
    console.log(error);
  }

  //console.log(itemCard.article[0])

  let transfer = itemCard.article[0];

  cardItem.cartArticleId = transfer.id;

  if (transfer.articleType) {
    cardItem.articleType = transfer.articleType;
  } else {
    cardItem.articleType = transfer.type;
  }

  if (transfer.ServicExpressType) {
    cardItem.ServicExpressType = transfer.ServicExpressType;
  } else {
    cardItem.ServicExpressType = "planServicExpress";
  } //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,

  if (transfer.rateService) {
    cardItem.rateService = transfer.rateService;
  } else {
    cardItem.rateService = transfer.rateEbiex;
  }

  if (transfer.planName) {
    cardItem.productName = transfer.planName;
  } else {
    cardItem.productName = transfer.productName;
  }

  if (transfer.articlePrice) {
    cardItem.cartArticlePrice = parseInt(transfer.articlePrice);
  } else {
    cardItem.cartArticlePrice = parseInt(transfer.salePrice);
  } // call}

  if (transfer.discount) {
    cardItem.discount = parseInt(transfer.discount);
  } else {
    cardItem.discount = parseInt(transfer.discountAmount);
  } // call}
  if (!isNaN(transfer.discount)) {
    cardItem.discount = 0;
  }

  cardItem.cartArticlCuality = 1; // call

  if (transfer.subTotal) {
    cardItem.subTotalItem = parseInt(transfer.subTotal);
  } else {
    cardItem.subTotalItem = parseInt(transfer.totalAmount);
  }

  if (transfer.ChargeShipments) {
    cardItem.ChargeShipments = parseInt(transfer.ChargeShipments);
  }

  if (transfer.totalAmount) {
    cardItem.totalAmount =
      parseInt(cardItem.cartArticlePrice) - parseInt(cardItem.discount);
  } else {
    cardItem.totalAmount =
      parseInt(cardItem.cartArticlePrice) - parseInt(cardItem.discount);
  } // call}

  cardItem.shippimgMethod = "";
  cardItem.shippimgAmount = "";
  cardItem.ShippingAddress = "";
  cardItem.discountType = "";

  car = JSON.parse(localStorage.getItem("itemCard"));
  if (car) {
    localStorage.removeItem("itemCard");
  }
  //descomentar
  if (!(car instanceof Array)) car = []; // if not, create one
  car.push(cardItem); // push a new student inside of it
  dispatch(computeCart(car));
};

export const clientRemoveCartProduct = (product, uid) => async (dispatch) => {
  let itemCard = {
    article: [],
  };

  let cardItem = {
    cartArticleId: "",
    articleType: "", // tipo de articulo pack de Servico Express  ARTYPE
    ServicExpressType: "", //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,
    rateService: "",
    productName: "",
    cartArticlePrice: 0,
    cartArticlCuality: 1,
    subTotalItem: 0,
    shippimgMethod: "",
    shippimgAmount: 0,
    ShippingAddress: "",
    ChargeShipments: 0,
    discount: 0,
    discountType: "",
  };

  let car = [];
  itemCard.product = product;

  dispatch({
    type: LOADING_CART,
  });

  try {
    const productos = await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .doc(product)
      .get();
    if (productos.data()) {
      itemCard.article.push(productos.data());
    }
  } catch (error) {
    console.log(error);
  }
  try {
    const bolsas = await db
      .collection(`${process.env.REACT_APP_COL_PRODUCTS}`)
      .doc(product)
      .get();
    if (bolsas.data()) {
      itemCard.article.push(bolsas.data());
    }
  } catch (error) {
    console.log(error);
  }

  //console.log(itemCard.article[0])

  let transfer = itemCard.article[0];

  cardItem.cartArticleId = transfer.id;

  if (transfer.articleType) {
    cardItem.articleType = transfer.articleType;
  } else {
    cardItem.articleType = transfer.type;
  }

  if (transfer.ServicExpressType) {
    cardItem.ServicExpressType = transfer.ServicExpressType;
  } else {
    cardItem.ServicExpressType = "planServicExpress";
  } //  servicExpress => bolsa bagServicExpress  -o- plan planServicExpress,

  if (transfer.rateService) {
    cardItem.rateService = transfer.rateService;
  } else {
    cardItem.rateService = transfer.rateEbiex;
  }

  if (transfer.planName) {
    cardItem.productName = transfer.planName;
  } else {
    cardItem.productName = transfer.productName;
  }

  if (transfer.articlePrice) {
    cardItem.cartArticlePrice = parseInt(transfer.articlePrice);
  } else {
    cardItem.cartArticlePrice = parseInt(transfer.salePrice);
  } // call}

  if (transfer.discount) {
    cardItem.discount = parseInt(transfer.discount);
  } else {
    cardItem.discount = parseInt(transfer.discountAmount);
  } // call}
  if (!isNaN(transfer.discount)) {
    cardItem.discount = 0;
  }

  // cardItem.cartArticlCuality = 1 // call

  if (transfer.subTotal) {
    cardItem.subTotalItem = parseInt(transfer.subTotal);
  } else {
    cardItem.subTotalItem = parseInt(transfer.totalAmount);
  }

  if (transfer.totalAmount) {
    cardItem.totalAmount =
      parseInt(cardItem.cartArticlePrice) - parseInt(cardItem.discount);
  } else {
    cardItem.totalAmount =
      parseInt(cardItem.cartArticlePrice) - parseInt(cardItem.discount);
  } // call}

  cardItem.shippimgMethod = "";
  cardItem.shippimgAmount = "";
  cardItem.ShippingAddress = "";
  cardItem.discountType = "";

  car = JSON.parse(localStorage.getItem("itemCard"));

  if (car) {
    localStorage.removeItem("itemCard");
  }
  //console.log(car)
  //descomentar
  const found = car.find(
    (element) => element.cartArticleId === cardItem.cartArticleId
  );

  if (found) {
    if (found.cartArticlCuality <= 1) {
      //console.log('Acción Delete for car');
      let elementIndex;
      let elemenCall;
      elementIndex = car.findIndex(
        (obj) => obj.cartArticleId === cardItem.cartArticleId
      );
      //console.log("Antes de Actualizar: ", car[elementIndex].cartArticlCuality);
      elemenCall = car[elementIndex].cartArticlCuality - 1;
      car[elementIndex].cartArticlCuality = elemenCall;
      //console.log(elemenCall)
      //console.log("Despues de Actualizar: ", car[elementIndex].cartArticlCuality);
    }

    if (found.cartArticlCuality > 1) {
      let elementIndex;
      let elemenCall;
      elementIndex = car.findIndex(
        (obj) => obj.cartArticleId === cardItem.cartArticleId
      );
      //console.log("Before update: ", car[elementIndex].cartArticlCuality);
      elemenCall = car[elementIndex].cartArticlCuality - 1;
      car[elementIndex].cartArticlCuality = elemenCall;
      //console.log(elemenCall)
      //console.log("After update: ", car[elementIndex].cartArticlCuality);
    }
  }

  if (!(car instanceof Array)) car = []; // if not, create one
  car.push(cardItem); // push a new student inside of it

  dispatch(computeCart(car));
};

export const getCart = () => async (dispatch) => {
  let car = [];
  car = JSON.parse(localStorage.getItem("itemCard"));

  if (car) {
    localStorage.removeItem("itemCard");
  }
  dispatch(computeCart(car));
};

export const setCart = (car) => async (dispatch) => {};

export const computeCart = (car) => async (dispatch) => {
  let itemCard = {
    idCart: "",
    article: [],
    totalDiscount: 0,
    totalShippimgAmount: 0,
    subTotalItem: 0,
    TotalChargeShipments: 0,
    totalAmount: 0,
    cualitys: 0,
  };

  let articles = {
    id: "",
    productName: "",
    cartArticlCuality: 0,
    cartArticlePrice: 0,
    ChargeShipments: 0,
    discount: 0,
    subTotalItem: 0,
    totalAmount: 0,
  };

  const sumarCart = () => {
    //Calculos Totales
    itemCard.cartArticlePrice = 0;
    itemCard.totalDiscount = 0;
    itemCard.subTotalItem = 0;
    itemCard.totalAmount = 0;
    itemCard.cualitys = 0;
    itemCard.TotalChargeShipments = 0;
    for (let i = 0; i < itemCard.article.length; i++) {
      //console.log(itemCard.article[i].totalAmount)
      itemCard.totalDiscount += itemCard.article[i].discount;
      itemCard.cartArticlePrice += itemCard.article[i].cartArticlePrice;
      itemCard.subTotalItem += itemCard.article[i].subTotalItem;
      itemCard.totalAmount += itemCard.article[i].totalAmount;
      itemCard.cualitys += itemCard.article[i].cartArticlCuality;
      itemCard.TotalChargeShipments += itemCard.article[i].ChargeShipments;
    }
    itemCard.idCart = "dumyCart";
  };

  const agregarNuevo = (articles) => {
    itemCard.article.push(articles);
    sumarCart();
  };

  const actualizarExtistente = (articles, exd) => {
    let OneMore =
      itemCard.article[exd].cartArticlCuality + articles.cartArticlCuality;
    var articleUpdates = {
      id: articles.id,
      productName: articles.productName,
      cartArticlePrice: articles.cartArticlePrice,
      cartArticlCuality: OneMore,
      subTotalItem: articles.subTotalItem * OneMore,
      discount: articles.discount * OneMore,
      totalAmount: articles.totalAmount,
      articleType: articles.articleType,
      ChargeShipments: articles.ChargeShipments,
    };
    //update
    itemCard.article[exd] = articleUpdates;
    sumarCart();
  };

  const removeOneLocal = (article) => {
    car.splice(-1, 1);
    sumarCart();
  };

  if (car) {
    if (car.length > 0) {
      let n = 1;
      //console.log('Creando itemCard en carro biccixpress cart')
      for (let i = 0; i < car.length; i++) {
        var busqueda = car.find(
          (articulo) => articulo.cartArticleId === car[i].cartArticleId
        );
        if (busqueda) {
          //console.log(busqueda)
          if (itemCard.article.length === 0) {
            // console.log('Agregar primer item inicio = ' + busqueda.cartArticleId )
            // console.log('Buscar si existe itemCard = '+busqueda.cartArticleId)
            articles = {
              id: busqueda.cartArticleId,
              productName: busqueda.productName,
              cartArticlePrice: busqueda.cartArticlePrice,
              discount: busqueda.discount,
              cartArticlCuality: busqueda.cartArticlCuality,
              subTotalItem: busqueda.subTotalItem,
              totalAmount: busqueda.totalAmount,
              articleType: busqueda.articleType,
              ChargeShipments: busqueda.ChargeShipments,
            };
            agregarNuevo(articles);
          } else {
            //console.log('Buscar si existe itemCard = ' + busqueda.cartArticleId )
            // for (let i = 0; i < itemCard.article.length; i++) {
            // console.log(itemCard.article)
            //console.log(busqueda.cartArticleId)

            var busquedaitemCard = itemCard.article.find(
              (element) => element.id === busqueda.cartArticleId
            );

            if (busquedaitemCard) {
              if (busquedaitemCard.cualitys <= 0) {
                // console.log('Encontrado valor MENOR 0')
              } else {
                // console.log('Encontrado algo')
              }

              if (busquedaitemCard.articleType === "packServicExpress") {
                // console.log('BLOCK  AND DELETE => RESTAURAR itemCard A UNO => RESPONSE ERROR')
                dispatch({
                  type: ERROR_CART,
                  cartErr:
                    "El artículo seleccionado solo se puede agregar una vez.",
                });
                removeOneLocal(busquedaitemCard);
                //car =  itemCard.article
              } else {
                const productId = itemCard.article.findIndex(
                  (i) => i.id === busqueda.cartArticleId
                );

                articles = {
                  id: busqueda.cartArticleId,
                  productName: busqueda.productName,
                  cartArticlePrice: busqueda.cartArticlePrice,
                  discount: busqueda.discount,
                  cartArticlCuality: busqueda.cartArticlCuality,
                  subTotalItem: busqueda.subTotalItem,
                  totalAmount: busqueda.totalAmount,
                  articleType: busqueda.articleType,
                  ChargeShipments: busqueda.ChargeShipments,
                };
                actualizarExtistente(articles, productId);
              }
            } else {
              articles = {
                id: busqueda.cartArticleId,
                productName: busqueda.productName,
                cartArticlePrice: busqueda.cartArticlePrice,
                discount: busqueda.discount,
                cartArticlCuality: busqueda.cartArticlCuality,
                subTotalItem: busqueda.subTotalItem,
                totalAmount: busqueda.totalAmount,
                articleType: busqueda.articleType,
                ChargeShipments: busqueda.ChargeShipments,
              };
              agregarNuevo(articles);
              dispatch({
                type: ERROR_CART,
                cartErr: "",
              });
            }
          }
        }
      }
    }
  }

  if (car) {
    // console.log(car)

    // console.log('ELIMINAR ITEMS cero')
    if (car.length > 0) {
      for (let i = 0; i < car.length; i++) {
        const Newcar = car.filter((item) => item.cartArticlCuality > 0);
        // console.log(Newcar)
        car = Newcar;
      }
    }
  }
  sumarCart();
  // console.log('ARRAY localStorage')
  // console.log(itemCard)

  /*
  localStorage.setItem("itemCard", JSON.stringify(car));
  localStorage.setItem("bicciexpress", JSON.stringify(itemCard));
*/
  //  // Encriptado de cookiss
  //   var testCrpipt = JSON.stringify(itemCard)
  //  // Encrypt
  //  var ciphertext = CryptoJS.AES.encrypt(
  //   testCrpipt,
  //   "secret key 123"
  //   ).toString();
  //   // Decrypt
  //   var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key 123");
  //   var originalText = bytes.toString(CryptoJS.enc.Utf8);

  //   console.log(originalText); // 'my message'
  //   console.log(bytes.toString());
  //   sessionStorage.setItem('bicciexpress', JSON.stringify(bytes.toString()));

  dispatch({
    type: CART_CONTENT,
    cart: car,
    cartContent: itemCard,
    loading: false,
  });
};
