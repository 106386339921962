import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Box,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Paper,
  TableContainer,
  Grid,
} from "@mui/material";
import { db } from "../../../../firebase";
import { Theme } from "../../../../Theme/Theme";
import { ScreenSizeContext } from "../../../../context/ScreenSizeProvider";
import useGetVehiclesNames from "../../../../hooks/getVehiclesNames";
import { collection, getDocs, query, where } from "firebase/firestore";

const ToolDashResumenOpsAutoCreate = ({
  mergedOrders,
  handleClickSelectRiderCurrentList,
  handleClickSelectRiderPendintList,
  handleCopySelectRiderCurrentList,
  createManiFestByCurrentList,
}) => {
  const { widthHeight } = useContext(ScreenSizeContext);
  const { VehiclesNames } = useGetVehiclesNames();
  const navigate = useNavigate();
  const [movil, setMovil] = useState(false);
  const [viewBy, setViewBy] = useState("drivers");
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [progressValue, setProgressValue] = useState(0);
  const [messajeAdmin, setMenssajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);

  useEffect(() => {
    if (widthHeight.width < 950) {
      setMovil(true);
    } else {
      setMovil(false);
    }
  }, []);

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Asegúrate de que los driverID sean únicos
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];

        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );

          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            // Agregar solo si no está ya presente
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }

        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    // console.log(mergedOrders);
    // console.log(ridersDataByCompany);
    // console.log(VehiclesNames);
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
      getResumenRoutes(mergedOrders);
    }
  }, [ridersDataByCompany]);

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      // Extraer ridersDataByCompany sin duplicados y sin driverID vacío o indefinido
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      // Esta función agrupa las órdenes por tienda y calcula métricas
      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [], // Puedes omitir esto si `commune2` no existe
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);
        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        // Omitir lógica de `commune2` si no existe
        if (order.commune2) {
          const comunaIndex = acc[driverID].locality.findIndex(
            (comuna) => Object.keys(comuna)[0] === order.commune2
          );

          if (comunaIndex >= 0) {
            acc[driverID].locality[comunaIndex][order.commune2]++;
          } else {
            const newComuna = { [order.commune2]: 1 };
            acc[driverID].locality.push(newComuna);
          }
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const getResumenRoutes = async (dataMerge) => {
    if (!dataMerge || dataMerge.length === 0) {
      setMetricsOpsRoutes([]);
      return;
    }

    try {
      const getOrdersByRoute = (data) => {
        return data.reduce((acc, order) => {
          const route = order.driverLicensePlate || "noRoute";

          if (!acc[route]) {
            acc[route] = {
              route,
              delivered: 0,
              withIssues: 0,
              assigned: 0,
              pending: 0,
              finalized: 0,
              NS: 0,
              comunas: {},
              countersListOrders: [],
              countersIssuresListOrders: [],
            };
          }

          if (order.status !== 8) {
            acc[route].withIssues++;
          } else {
            acc[route].delivered++;
          }
          acc[route].assigned++;

          if (order.falabella_status === "some_pending_status_code") {
            acc[route].pending++;
          } else if (order.falabella_status === "some_finalized_status_code") {
            acc[route].finalized++;
          } else if (order.falabella_status === "some_ns_status_code") {
            acc[route].NS++;
          }

          // const comuna = order.commune2;
          // if (!acc[route].comunas[comuna]) {
          //   acc[route].comunas[comuna] = 1;
          // } else {
          //   acc[route].comunas[comuna]++;
          // }

          acc[route].countersListOrders.push(order.id);
          if (order.falabella_status !== 8) {
            acc[route].countersIssuresListOrders.push(order.id);
          }

          return acc;
        }, {});
      };

      const listRoutesData = getOrdersByRoute(dataMerge);

      setMetricsOpsRoutes(Object.values(listRoutesData));
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewChange = (view) => {
    setViewBy(view);
  };

  const viewDriverPerfil = (dataRow) => {
    navigate(`/dashboard/drivers/${dataRow.driverID}`);
  };

  const getViewByGridCardsDrivers = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        {/* <Typography variant="h6">Resumen de Operación</Typography> */}
        <Grid container spacing={2}>
          {metricsOps.map((item, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardHeader
                  avatar={
                    <Tooltip title="Ver perfil ...">
                      <Avatar
                        src={item.driverAvatar}
                        sx={{
                          bgcolor: "#f2f2f2",
                          cursor: "pointer",
                          "&:hover": {
                            opacity: 0.7,
                          },
                        }}
                        aria-label="recipe"
                        onClick={() => viewDriverPerfil(item)}
                      />
                    </Tooltip>
                  }
                  title={item.driverName ? item.driverName : "SIN ASIGNACIÓN"}
                  subheader={item.driverName ? item.email : ""}
                  action={
                    <Box>
                      <Button
                        onClick={(e) =>
                          handleClickSelectRiderCurrentList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Todos Asignados
                      </Button>
                      <Button
                        onClick={(e) =>
                          handleClickSelectRiderPendintList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Solo En ruta
                      </Button>
                      <Button
                        onClick={(e) =>
                          handleCopySelectRiderCurrentList(e, item)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="body2">
                    {item.asignados} Asignados
                  </Typography>
                  <Typography variant="body2">
                    {item.delivery} Finalizados
                  </Typography>
                  <Typography variant="body2">
                    {item.issures} Pendientes
                  </Typography>
                  <Typography variant="body2">
                    {((item.delivery * 100) / item.asignados).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2">
                    {item.locality.map((i, index) => (
                      <span key={index}>
                        {i[Object.keys(i)]} {Object.keys(i)}{" "}
                      </span>
                    ))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };
  const getViewByTableDrivers = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        {/* <Typography variant="h6">Resumen de Operación</Typography> */}
        <TableContainer component={Paper} sx={{ maxHeight: "750px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Rider</TableCell>
                <TableCell>Vehículo</TableCell>
                <TableCell>Asignados</TableCell>
                <TableCell>Finalizados</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell>% NS</TableCell>
                <TableCell>Comunas</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metricsOps.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box>
                      <Typography>Seleccionar Lista</Typography>
                      <Grid container spacing={1} sx={{ maxWidth: "300px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              handleClickSelectRiderCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Todos Asignados
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              handleClickSelectRiderPendintList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Solo pendientes en ruta
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              handleCopySelectRiderCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Copiar todos los id
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            onClick={(e) =>
                              createManiFestByCurrentList(e, item)
                            }
                            variant="contained"
                            fullWidth
                            sx={Theme.smallButtons}
                          >
                            Imprimir hoja de Ruta
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={item.driverAvatar}
                        sx={{ bgcolor: "#f2f2f2", mr: 2 }}
                        onClick={() => viewDriverPerfil(item)}
                        aria-label="recipe"
                      ></Avatar>
                      <Box>
                        {item.driverName ? (
                          <>
                            <Typography variant="body2">
                              {item.driverName}
                            </Typography>
                            <Typography variant="body2">
                              {item.email}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body2" color="error">
                            SIN ASIGNACIÓN
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{item.vehicleDetailsType}</TableCell>
                  <TableCell>{item.asignados}</TableCell>
                  <TableCell>{item.delivery}</TableCell>
                  <TableCell>{item.issures}</TableCell>
                  <TableCell>
                    {((item.delivery * 100) / item.asignados).toFixed(2)}%
                  </TableCell>
                  <TableCell>
                    {item.locality.map((i, index) => (
                      <Typography key={index}>
                        {i[Object.keys(i)]} {Object.keys(i)}
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const getViewByGridCardsRoutes = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <Typography variant="h6">Resumen de Rutas</Typography>
        <Grid container spacing={2}>
          {metricsOpsRoutes.map((route, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardHeader
                  title={
                    route.route !== "noRoute"
                      ? `Ruta: ${route.route}`
                      : "Ruta: SIN ASIGNACIÓN"
                  }
                  subheader={
                    <Box>
                      <Button
                        onClick={(e) =>
                          handleClickSelectRiderCurrentList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Todos Asignados
                      </Button>
                      <Button
                        onClick={(e) =>
                          handleClickSelectRiderPendintList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Solo En ruta
                      </Button>
                      <Button
                        onClick={(e) =>
                          handleCopySelectRiderCurrentList(e, route)
                        }
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          background: "#591e8f",
                          borderRadius: "4px",
                          color: "#f8f8f8",
                          fontSize: "12px",
                          fontWeight: "300",
                          margin: "1px",
                          lineHeight: "1",
                          "&:hover": {
                            background: "#4e1778",
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="body2">
                    {route.assigned} Asignados
                  </Typography>
                  <Typography variant="body2">
                    {route.delivered} Finalizados
                  </Typography>
                  <Typography variant="body2">
                    {route.withIssues} Pendientes
                  </Typography>
                  <Typography variant="body2">
                    {((route.delivered * 100) / route.assigned).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2">
                    {Object.entries(route.comunas).map(
                      ([comuna, count], index) => (
                        <span key={index}>
                          {count} {comuna}{" "}
                        </span>
                      )
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const getViewByGridTablesRoutes = () => {
    return (
      <div className="typeMiniformsBarsMetrics">
        <p>Resumen</p>
        <Table sx={{ maxWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Ruta</TableCell>
              <TableCell>Asignados</TableCell>
              <TableCell>Finalizados</TableCell>
              <TableCell>Pendientes</TableCell>
              <TableCell>% NS</TableCell>
              <TableCell>Comunas</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metricsOpsRoutes.map((route, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box>
                    <Typography>Seleccionar Lista</Typography>
                    <Grid container spacing={1} sx={{ maxWidth: "300px" }}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            handleClickSelectRiderCurrentList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Todos Asignados
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            handleClickSelectRiderPendintList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Solo pendientes en ruta
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) =>
                            handleCopySelectRiderCurrentList(e, route)
                          }
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Copiar todos los id
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button
                          onClick={(e) => createManiFestByCurrentList(e, route)}
                          variant="contained"
                          fullWidth
                          sx={Theme.smallButtons}
                        >
                          Imprimir hoja de Ruta
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell>
                  {route.route !== "noRoute" ? route.route : "SIN ASIGNACIÓN"}
                </TableCell>
                <TableCell>{route.assigned}</TableCell>
                <TableCell>{route.delivered}</TableCell>
                <TableCell>{route.withIssues}</TableCell>
                <TableCell>
                  {((route.delivered * 100) / route.assigned).toFixed(2)}%
                </TableCell>
                <TableCell>
                  {Object.entries(route.comunas).map(
                    ([comuna, count], index) => (
                      <Typography key={index}>
                        {count} {comuna}
                      </Typography>
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        padding: "0px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          m: 2,
          backgroundColor: "#fff",
          p: 2,
          borderRadius: "5px",
        }}
      >
        <Button
          onClick={() => handleViewChange("drivers")}
          sx={{
            backgroundColor: viewBy === "drivers" ? "#591e8f" : "default",
            color: viewBy === "drivers" ? "white" : "#591e8f",
            "&:hover": {
              backgroundColor: viewBy === "drivers" ? "#591e8f" : "default",
            },
            border: viewBy === "drivers" ? "#591e8f" : "1px Solid #591e8f",
          }}
        >
          Ver por Conductores
        </Button>
        {metricsOpsRoutes ? (
          <Button
            onClick={() => handleViewChange("routes")}
            sx={{
              backgroundColor: viewBy === "routes" ? "#591e8f" : "default",
              color: viewBy === "routes" ? "white" : "#591e8f",
              "&:hover": {
                backgroundColor: viewBy === "routes" ? "#591e8f" : "default",
              },
              border: viewBy === "routes" ? "#591e8f" : "1px Solid #591e8f",
            }}
          >
            Ver por Rutas
          </Button>
        ) : null}
      </Box>

      {viewBy === "drivers" ? (
        <>
          {movil === true ? getViewByGridCardsDrivers() : null}
          {movil === false ? getViewByTableDrivers() : null}
        </>
      ) : (
        <>
          {movil === true ? getViewByGridCardsRoutes() : null}
          {movil === false ? getViewByGridTablesRoutes() : null}
        </>
      )}
    </Box>
  );
};

export default ToolDashResumenOpsAutoCreate;
