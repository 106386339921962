import { useEffect, useState } from "react";
import { db } from "../firebase";

function useGetOrderIDExpress(companyId, userRoll, ordersStatus, OrderID) {
  const [bicciOrderIDExpress, setBicciOrderIDExpress] = useState(null);
  const [loadOrderIDExpress, setLoadOrderIDExpress] = useState(false);
  const [errorOrderIDExpress, setErrorOrderIDExpress] = useState(null);

  useEffect(() => {
    //console.log(userRoll)
    fetchData(companyId);
  }, [companyId, userRoll, OrderID]);

  const fetchData = async (companyId) => {
    const AplyFiltersByAdmin = (dataallOrderID, OrderID) => {
      // console.log(dataallOrderID)
      // console.log(OrderID)
      if (OrderID) {
        let order = OrderID.trim().toLowerCase();
        dataallOrderID = dataallOrderID.filter((item) =>
          item.id.toLowerCase().includes(order)
        );
        dataallOrderID = dataallOrderID.filter(
          (item) => item.id.isActive === true
        );
        dataallOrderID = dataallOrderID.filter(
          (item) => item.id.isDelete === false
        );

        setBicciOrderIDExpress(dataallOrderID);
      } else {
        setBicciOrderIDExpress(dataallOrderID);
      }
      //console.log(dataallOrderID)
    };

    try {
      setLoadOrderIDExpress(true);
      if (userRoll === process.env.REACT_APP_ADMIN) {
        if (companyId) {
          const allOrdersRef = db.collection(
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const dataallOrdersfind = await allOrdersRef
            .where("status", ">=", 0)
            .where("status", "<", 8)
            .where("isActive", "==", true)
            .where("isDelete", "==", false)
            .get();
          var dataOrdes = dataallOrdersfind.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          AplyFiltersByAdmin(dataOrdes, OrderID);
        } else {
          const allOrdersRef = db.collection(
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const dataallOrdersfind3 = await allOrdersRef
            .where("status", ">=", 0)
            .where("status", "<", 8)
            .get();
          var dataOrdes = dataallOrdersfind3.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          //console.log(dataOrdes)
          AplyFiltersByAdmin(dataOrdes, OrderID);
        }
      } else {
        if (companyId) {
          const allOrdersRef = db.collection(
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const dataallOrdersfind5 = await allOrdersRef
            .where("status", ">=", 0)
            .where("status", "<", 8)
            .get();
          var dataOrdes = dataallOrdersfind5.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          AplyFiltersByAdmin(dataOrdes, OrderID);
        } else {
          setBicciOrderIDExpress(null);
        }
      }

      setLoadOrderIDExpress(false);
    } catch (error) {
      //console.log(error)
      setErrorOrderIDExpress(true);
      setBicciOrderIDExpress(null);
    }
  };

  return { bicciOrderIDExpress, loadOrderIDExpress, errorOrderIDExpress };
}

export default useGetOrderIDExpress;
