import  { useEffect, useState } from 'react'
import {db} from '../firebase'
import Moment from 'moment';

function useGetDataRider (companyId,userRider){
    const [riderData, setRiderDataRider ] = useState(null)
    const [loadRiderData, setLoadDataRider] = useState(false)
    const [errorRiderData, setErrorDataRider] = useState(null)    
  
    useEffect(()=>{
      fetchData(companyId)
    },[companyId,userRider])
  
    const fetchData = async (companyId) => {
      try {
        if(companyId){
          setLoadDataRider(true)
          // const routes = await db.collection(`${process.env.REACT_APP_COL_ROUTES}`)
         // .doc(companyId)
         // .get();
         // var dataCompanyResult = routes.data();
        setRiderDataRider('Error')
        setLoadDataRider(false)
        }else{
          setLoadDataRider(true) 
          
          if(userRider){
           const allRidersRef = db.collection(`${process.env.REACT_APP_COL_USERS_DRIVERS}`)
           const dataallRidersfind = await allRidersRef.where('active', '==', true)
           .get();
           var dataallRiders = dataallRidersfind.docs.map(doc => ({id: doc.id, ...doc.data()}))
          }else{
           const allRidersRef = db.collection(`${process.env.REACT_APP_COL_USERS_DRIVERS}`)
           const dataallRidersfind = await allRidersRef.where('online', '==', true)
           .get();
           var dataallRiders = dataallRidersfind.docs.map(doc => ({id: doc.id, ...doc.data()}))
          }
          
        
          // dataallRiders = dataallRiders.filter(userRider => userRider.driverName.toLowerCase().includes(userRiderClear));
          if(userRider){

          function findRidersName (userRider){
            let userRiderClear = userRider.trim().toLowerCase() 
            let tempDataNames = dataallRiders.filter(userRider => userRider.name.toLowerCase().includes(userRiderClear));
            if(tempDataNames.length > 0){setRiderDataRider(tempDataNames)}
            }
            findRidersName(userRider) 

          function findRidersUid (userRider){
            let userRiderClear = userRider.trim().toLowerCase() 
            let tempDataid = dataallRiders.filter(userRider => userRider.id.toLowerCase().includes(userRiderClear));
            if(tempDataid.length > 0){setRiderDataRider(tempDataid)}
            }
            findRidersUid(userRider)
              
          }else{ 
          setRiderDataRider(dataallRiders)}
           //console.log(dataallRiders)
        }
        setLoadDataRider(false)
        setErrorDataRider(false)
      } catch (error) {
        //console.log(error)
        setErrorDataRider(true)
      }
    }
  
    return {riderData, loadRiderData, errorRiderData}
}

export default useGetDataRider