import React, { createContext, useState, useEffect, useContext } from "react";
import useGetOrdersLocalDataContextByCompany from "./getOrdersLocalDataContextByCompany";

export const OrdersContext = createContext();

export const OrdersProvider = ({
  children,
  initDateInitial,
  endDateInitial,
}) => {
  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
    excludePendings,
    setExcludePendings,
  } = useGetOrdersLocalDataContextByCompany({
    initDateInitial,
    endDateInitial,
  });

  // console.log(
  //   `useGetOrdersLocalDataContextByCompany ${
  //     loadingContext ? "Actualizando" : "En Pausa"
  //   }`
  // );
  // console.log(loadingContext);
  //console.log(mergedOrders);
  //   console.log(loadingContext);
  //   console.log(setInitDate);
  //   console.log(setEndDate);
  // console.log(initDate);
  // console.log(endDate);

  return (
    <OrdersContext.Provider
      value={{
        mergedOrders,
        loadingContext,
        initDate,
        endDate,
        setInitDate,
        setEndDate,
        companiesData,
        listenStart,
        listenStop,
        setShouldSubscribe,
        excludePendings,
        setExcludePendings,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);
