import React from 'react'
import {Container} from 'semantic-ui-react';
import Pos4 from '../postcontent/inicio/Pos4/Pos4';
import useTitle from '../../../components/Seo/useTitle';
import useSeo from '../../../components/Seo/useSeo';
const Ecobicci = () => {
  useTitle({title:'Eco Bicci'})
  useSeo({description:'EcoBicci posee diversas habilidades y entre ellas, en primer lugar nos enorgullece ayudar al medio ambiente y además es capaz de transportar tus productos de forma eficiente ya que cuenta con un marco de aluminio que la hace liviana para su uso y una autonomía que le permite llegar hasta 100 kms de recorrido, lo cual la hace estupenda para poder cubrir todas tus necesidades.'})

  return (
    <div>
    <Container className='bodycontent'>
     <div>
     <Pos4/>
    </div>
    </Container> 
   
    </div>
    ); 
  
}

export default Ecobicci