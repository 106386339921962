import React from "react";
import BusinessIcon from "@mui/icons-material/Business";

const containsOfficeKeywords = (address) => {
  const keywords = [
    "oficina",
    "ofic",
    "piso",
    "local",
    // "depto",
    // "dep",
    "Oficina",
    "Ofic",
    "Piso",
    "Local",
    //"Depto",
    "Farmacia",
    //"Dpto",
    //"Dep",
  ];
  return keywords.some((keyword) => address.includes(keyword));
};

const containsDoubleNumbering = (address) => {
  const numberPattern = /\d+/g;
  const matches = address.match(numberPattern);
  return matches && matches.length > 1;
};

const isValidAddress = (address) => {
  return containsOfficeKeywords(address) || containsDoubleNumbering(address);
};

const ValidationDirections = (props) => {
  const { address } = props;
  const validAddress = isValidAddress(address);

  return validAddress ? (
    <div>
      <span>
        <BusinessIcon style={{ verticalAlign: "middle", color: "blue" }} />
        {address}{" "}
      </span>
    </div>
  ) : (
    <span> {address} </span>
  );
};

export default ValidationDirections;
