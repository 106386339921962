const getMatrixDistance = async (manifest, origin, endPlaceDestination) => {
  //const { ObjeResponseMatrixDistanceDev, ObjeResponseMatrixDistanceDev2rfec } = useGlobalConfig();

  let ObjeResponseMatrixDistanceDev2rfec = []; // DELETED
  class reponseMatrix {
    constructor(
      pointGroups,
      manifetsReOrderPoints,
      loadService,
      errorEvents,
      progressValue
    ) {
      this._pointGroups = pointGroups;
      this._manifetsReOrderPoints = manifetsReOrderPoints;
      this._loadService = loadService;
      this._errorEvents = errorEvents;
      this._progressValue = progressValue;
    }

    get pointGroups() {
      return this._pointGroups;
    }

    set pointGroups(newPointGroups) {
      this._pointGroups = newPointGroups;
    }

    get manifetsReOrderPoints() {
      return this.manifetsReOrderPoints;
    }

    set manifetsReOrderPoints(newManifetsReOrderPoints) {
      this._manifetsReOrderPoints = newManifetsReOrderPoints;
    }

    get loadService() {
      return this._loadService;
    }

    set loadService(newLoadService) {
      this._loadService = newLoadService;
    }
    get errorEvents() {
      return this._errorEvents;
    }

    set errorEvents(newErrorEvents) {
      this._errorEvents = newErrorEvents;
    }
    get progressValue() {
      return this._progressValue;
    }

    set progressValue(newProgressValue) {
      this._progressValue = newProgressValue;
    }
  }

  let manifestMatrix;
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();
  manifestMatrix = manifest;

  const calculateData = async (manifestMatrix, origin, endPlaceDestination) => {
    reponseMatrix.loadService = false;
    var destination = [];
    var destinationMultigrups25 = [];
    var matrizResponse = [];

    var matrizResponseDistance = [];
    var matrizResponseDurations = [];

    var dataManifestTemp = [];
    var origenMasLejano;
    var origenMasCercano;

    async function promiseCalculateMatrixDistance() {
      function espera() {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
      }

      //
      const openrouteservice = require("openrouteservice-js");

      const calculateDistance = async (destination, origin) => {
        console.log(`%c CONSUMO OPEN MAPS $$`, "color: green; font-size: 18px");
        console.log("Evaluar si es necesario");
        console.log(destination);
        console.log(origin);
        console.log("Evaluar si es necesario ");

        const matrix = new openrouteservice.Matrix({
          api_key: "5b3ce3597851110001cf6248154b4c0170a942948158b975c046c7dd", // Reemplace con su clave de API de OpenRouteService
        });

        const coordinates = [
          [origin.lng, origin.lat],
          ...destination.map((dest) => [dest.lng, dest.lat]),
        ];

        try {
          const response = await matrix.calculate({
            locations: coordinates,
            profile: "driving-car",
            sources: [0], // Usar solo la primera coordenada (origin) como fuente
            metrics: ["distance", "duration"],
          });

          console.log(response);

          if (response && response.distances && response.durations) {
            matrizResponseDistance = response.distances[0]
              .slice(1)
              .map((distance) => {
                return {
                  distance: {
                    text: `${distance.toFixed(2)} m`,
                    value: parseInt(distance),
                  },
                  status: "OK",
                };
              });

            matrizResponseDurations = response.durations[0]
              .slice(1)
              .map((duration) => {
                return {
                  duration: {
                    text: `${duration.toFixed(2)} s`,
                    value: parseInt(duration),
                  },
                  status: "OK",
                };
              });

            let localMatrizResponse = matrizResponseDistance.map(
              (element, index) => {
                return {
                  distance: element.distance,
                  duration: matrizResponseDurations[index].duration,
                  status: "OK",
                };
              }
            );
            matrizResponse = matrizResponse.concat(localMatrizResponse);
          } else {
            matrizResponse.push([{ status: "ZERO_RESULTS" }]);
          }

          await espera();
          console.log(matrizResponse);
          return;
        } catch (error) {
          console.log(error);
          reponseMatrix.errorEvents = "Error en Cordenedas";

          return "error";
        }
      };

      return new Promise(async (resolve, reject) => {
        let proceso1 = "proceso 1";
        reponseMatrix.progressValue = 15;
        let num = 0;
        for (let i = 0; i < manifestMatrix.length; i++) {
          destination.push(manifestMatrix[i].adressGeoNumAddressLatLng);
          if (i === 0) {
            destinationMultigrups25[num] = destination;
            destination = [];
          }
          if (i > 0 && (i + 1) % 25 === 0) {
            num++;
            destinationMultigrups25[num] = destination;
            destination = [];
          }
          if (i === manifestMatrix.length - 1) {
            num++;
            destinationMultigrups25[num] = destination;
          }
        }
        for (let n = 0; n < destinationMultigrups25.length; n++) {
          await calculateDistance(destinationMultigrups25[n], origin);
        }
        resolve(proceso1);
      });
    }

    // async function awaitMergedata(matrizResponseData) {
    //   console.log(matrizResponseData);
    //   MergematrizResponse = [];

    //   async function merge(matrizResponseData, MergematrizResponse) {
    //     for (let y = 0; y < matrizResponseData.length; y++) {
    //       for (let i = 0; i < matrizResponse[y].length; i++) {
    //         MergematrizResponse.push(matrizResponseData[y][i]);
    //       }
    //     }
    //   }
    //   await merge(matrizResponseData, MergematrizResponse);
    //   console.log(MergematrizResponse);
    // }
    async function insertIncialData(MergematrizResponse) {
      console.log(MergematrizResponse);
      for (let n = 0; n < MergematrizResponse.length; n++) {
        manifestMatrix[n].matrixRefs = MergematrizResponse[n];
        manifestMatrix[n].position = n;
      }
    }
    async function awaitReorderMergedata(manifestMatrix) {
      console.log("REORDER");
      console.log(manifestMatrix);
      let tempmMnifestMatrix = manifestMatrix.sort((a, b) =>
        a.matrixRefs.distance.value > b.matrixRefs.distance.value ? 1 : -1
      );
      manifestMatrix = tempmMnifestMatrix;
    }
    async function insertNewPosition(manifesOrders) {
      reponseMatrix.progressValue = 30;
      for (let n = 0; n < manifesOrders.length; n++) {
        manifesOrders[n].position = n;
        if (n === 0) {
          //console.log("Nodo mas cercana");
          //console.log(manifesOrders[n]);
        }
        //farthestFirst
        if (n === manifesOrders.length - 1) {
          //console.log("Nodo mas lejano");
          //console.log(manifesOrders[n]);
        }
      }
    }

    async function forceDevModeDumyData(ObjeResponseMatrixDistanceDev2rfec) {
      console.log(ObjeResponseMatrixDistanceDev2rfec);
      return ObjeResponseMatrixDistanceDev2rfec;
    }
    async function selectNextLastOrigin(orders) {
      origenMasLejano = orders[orders.length - 1];
      return origenMasLejano;
    }
    async function selectNextFirttOrigin(manifesOrders) {
      origenMasCercano = manifesOrders[manifesOrders[0]];
      return origenMasCercano;
    }
    async function crearDataManifestTemp(manifestMatrix) {
      dataManifestTemp = manifestMatrix;
    }

    async function agrupDataByComuna(data) {
      console.log("REST DATA");
      const groupByComuna = (data) => {
        return data.reduce((acc, obj) => {
          const key = obj.COMUNA2;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      };

      const groupedArray = groupByComuna(data);
      console.log(groupedArray);

      const sorted = {};
      Object.keys(groupedArray).forEach((key) => {
        sorted[key] = groupedArray[key].sort((a, b) => {
          return a.matrixRefs.distance.value - b.matrixRefs.distance.value;
        });
      });
      console.log(sorted);

      let items = [];

      for (let key in sorted) {
        items = items.concat(sorted[key]);
      }

      console.log(items);
      //const data = [objeto1, objeto2, ..., objetoN]; // donde objetoN es el último objeto del arreglo
      const resultado = items.reduce((acumulador, objeto) => {
        const comuna = objeto.COMUNA2;
        if (!acumulador[comuna]) {
          acumulador[comuna] = [];
        }
        acumulador[comuna].push(objeto);
        return acumulador;
      }, {});
      const arrayAgrupado = Object.values(resultado);

      console.log(arrayAgrupado);
      var comunasWaintPoint1 = [];
      for (let n = 0; n < arrayAgrupado.length; n++) {
        let obj = {
          stopover: true,
          location: {
            lat: arrayAgrupado[n][0].adressGeoNumAddressLatLng.lat,
            lng: arrayAgrupado[n][0].adressGeoNumAddressLatLng.lng,
          },
        };
        comunasWaintPoint1.push(obj);
      }

      //ordenar comunas

      console.log(origin);
      console.log(destination);
      console.log(" await directionsService.route(");
      let arrayAgrupadoOrder = [];
      await directionsService.route(
        {
          origin: origin,
          destination: endPlaceDestination,
          travelMode: "BICYCLING",
          optimizeWaypoints: true,
          waypoints: comunasWaintPoint1,
        },
        async function async(response, status) {
          if (status === "OK") {
            console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
            console.log("Evaluar si es necesario");
            console.log(destination);
            console.log(origin);
            console.log("Evaluar si es necesario ");
            console.log(response.routes[0].waypoint_order);
            let newOrder = response.routes[0].waypoint_order;
            for (let x = 0; x < newOrder.length; x++) {
              console.log(arrayAgrupado[newOrder[x]]);
              arrayAgrupadoOrder.push(arrayAgrupado[newOrder[x]]);
            }
          } else {
            console.log("error Puntos Guia");
          }
        }
      );

      //unir manifest
      console.log(arrayAgrupadoOrder); // ok
      var arrayAgrupadoConcat = [];
      for (let u = 0; u < arrayAgrupadoOrder.length; u++) {
        arrayAgrupadoConcat = arrayAgrupadoConcat.concat(arrayAgrupadoOrder[u]);
      }

      console.log(arrayAgrupadoConcat); // ORDEN PINCIPAL

      dataManifestTemp = arrayAgrupadoConcat;
    }

    async function sortClusterByProximity(dataOrders) {
      let tempdataOrders = [];

      console.log("sortClusterByProximity");

      for (let i = 0; i < dataOrders.length; i++) {
        let searchInRadio = false;
        let ordersInRadius = [];

        function buscarCoincidenciaId(idBuscado) {
          return !tempdataOrders.some((order) => order.orderID === idBuscado);
        }

        searchInRadio = buscarCoincidenciaId(dataOrders[i].orderID);

        if (searchInRadio === true) {
          let radioOrders = new google.maps.Circle({
            strokeColor: "#42f581",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#42f581",
            fillOpacity: 0.12,
            map: null,
            center: dataOrders[i].adressGeoNumAddressLatLng,
            radius: 700,
          });

          ordersInRadius = dataOrders.filter((order) => {
            let location = order.adressGeoNumAddressLatLng;
            let isInsideRadius =
              google.maps.geometry.spherical.computeDistanceBetween(
                location,
                radioOrders.getCenter()
              ) <= radioOrders.getRadius();

            return (
              isInsideRadius &&
              buscarCoincidenciaId(order.orderID) &&
              order.COMUNA2 === dataOrders[i].COMUNA2
            );
          });

          // Ordenar pedidos dentro del radio por proximidad al centro
          ordersInRadius.sort((a, b) => {
            let distanceA =
              google.maps.geometry.spherical.computeDistanceBetween(
                a.adressGeoNumAddressLatLng,
                radioOrders.getCenter()
              );
            let distanceB =
              google.maps.geometry.spherical.computeDistanceBetween(
                b.adressGeoNumAddressLatLng,
                radioOrders.getCenter()
              );

            return distanceA - distanceB;
          });

          // Agregar pedidos ordenados al array tempdataOrders sin duplicados
          tempdataOrders.push(...ordersInRadius);
        } else {
          if (buscarCoincidenciaId(dataOrders[i].orderID)) {
            tempdataOrders.push(dataOrders[i]);
          }
        }
      }

      dataManifestTemp = tempdataOrders;
    }

    //LLAMADA MATRIX DISTANCE

    await promiseCalculateMatrixDistance();

    //await awaitMergedata(matrizResponse);

    await insertIncialData(matrizResponse);

    await awaitReorderMergedata(manifestMatrix);

    await insertNewPosition(manifestMatrix);

    manifestMatrix = await forceDevModeDumyData(
      ObjeResponseMatrixDistanceDev2rfec
    );
    // ObjeResponseMatrixDistanceDev2rfec ORIGEN : Open Kennedy , DESTINO : Puente
    await selectNextLastOrigin(manifestMatrix);

    await selectNextFirttOrigin(manifestMatrix);

    await crearDataManifestTemp(manifestMatrix);

    await agrupDataByComuna(dataManifestTemp);

    await sortClusterByProximity(dataManifestTemp);

    destination = [];
    destinationMultigrups25 = [];

    let manifestInOrderPosition = [];
    let agrupationsOrders = {};
    let waitPoint_parentNodeIDContent = [];
    var updatedObj_agrupationsOrders = {};

    const setDataManifet = async (nuevoArray, newLastponint, conter) => {
      let parentNodeIDContent = {
        geoPonitRef: "",
        orderIds: [],
        position: conter,
      };
      let parentNodeID;
      let orderIds = [];
      if (!newLastponint) {
        console.log("%c ALERT! ", "color: red; font-size: 18px");
        reponseMatrix.errorEvents = "Error en Direcciones";
        return;
      }
      parentNodeID = newLastponint.orderID;
      parentNodeIDContent.geoPonitRef = newLastponint.adressGeoNumAddressLatLng;

      for (let index in nuevoArray) {
        orderIds.push(nuevoArray[index].orderID);
        manifestInOrderPosition.push(nuevoArray[index]);
      }
      parentNodeIDContent.orderIds = orderIds;

      agrupationsOrders[parentNodeID] = parentNodeIDContent;
    };

    async function selectNexttOriginResponse(orders) {
      origenMasLejano = orders[0];
      if (!origenMasLejano) {
        console.log("%c ALERT UNDEFINE! ", "color: red; font-size: 18px");
        reponseMatrix.errorEvents = "Error en Cordenedas";
        // console.log(orders);
        // console.log(orders.length - 1);
      }
      return origenMasLejano;
    }

    let cuonter = 0;

    let whileDataManifets = [];
    for (let i = 0; i < dataManifestTemp.length; i += 7) {
      const grupo = dataManifestTemp.slice(i, i + 7);
      whileDataManifets.push(grupo);
    }

    for (let conter = 0; conter < whileDataManifets.length; conter++) {
      let newLastponint;
      let nuevoArray = whileDataManifets[conter];
      // if (conter === 0) {
      //   newLastponint = await selectNextLastOriginResponse(dataManifestTemp);
      // } else {
      // }
      newLastponint = await selectNexttOriginResponse(nuevoArray);

      await setDataManifet(nuevoArray, newLastponint, cuonter);
      cuonter++;
    }

    const reOrder_agrupationsOrders = async (agrupationsOrders) => {
      const array_agrupationsOrders = Object.keys(agrupationsOrders).map(
        (key) => ({ id: key, ...agrupationsOrders[key] })
      );
      array_agrupationsOrders.sort((a, b) => a.position - b.position);
      console.log(array_agrupationsOrders);
      for (let item in array_agrupationsOrders) {
        let obj = {
          stopover: true,
          location: {
            lat: array_agrupationsOrders[item].geoPonitRef.lat,
            lng: array_agrupationsOrders[item].geoPonitRef.lng,
          },
        };
        waitPoint_parentNodeIDContent.push(obj);
      }

      var directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: waitPoint_parentNodeIDContent[0].location,
          destination:
            waitPoint_parentNodeIDContent[
              waitPoint_parentNodeIDContent.length - 1
            ].location,
          travelMode: "BICYCLING",
          optimizeWaypoints: true,
          waypoints: waitPoint_parentNodeIDContent,
        },
        function (response, status) {
          if (status === "OK") {
            //console.log(response.routes[0].waypoint_order);
            let arr = response.routes[0].waypoint_order;

            arr.forEach((index, i) => {
              const key = Object.keys(agrupationsOrders)[index];
              updatedObj_agrupationsOrders[key] = {
                ...agrupationsOrders[key],
                position: i,
              };
            });
          } else {
            console.log(status);
            console.log(
              "No de puedes mostrar este Tramo de ruta valide la Data "
            );
          }
        }
      );
    };
    await reOrder_agrupationsOrders(agrupationsOrders);
    console.log(agrupationsOrders);
    reponseMatrix.pointGroups = agrupationsOrders;
    reponseMatrix.manifesOrders = manifestInOrderPosition;
    reponseMatrix.loadService = true;
  };

  await calculateData(manifestMatrix, origin, endPlaceDestination);

  return {
    pointGroups: reponseMatrix.pointGroups,
    manifetsReOrderPoints: reponseMatrix.manifetsReOrderPoints,
    loadService: reponseMatrix.loadService,
    errorEvents: reponseMatrix.errorEvents,
    progressValue: reponseMatrix.progressValue,
  };
};

export default getMatrixDistance;
