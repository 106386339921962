import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
  Avatar,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReusableVewData = ({ open, handleClose, formFields, initialValues }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  const navigate = useNavigate();

  const handleChange = (variable, value) => {
    setFormData({ ...formData, [variable]: value });
  };

  const handleNavigate = () => {
    navigate(`/dashboard/support-ticket/${formData.id}`);
  };

  const renderField = (field, index) => {
    switch (field.type) {
      case "string":
        return (
          <Grid item xs={12} key={index}>
            <Typography variant="body1" color="textSecondary">
              {field.label || field.variable}:
            </Typography>
            <Typography variant="body2">{formData[field.variable]}</Typography>
          </Grid>
        );
      case "boolean":
        return (
          <Grid item xs={12} key={index}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData[field.variable] || false}
                  onChange={(e) =>
                    handleChange(field.variable, e.target.checked)
                  }
                  name={field.variable}
                  color="primary"
                  disabled
                />
              }
              label={field.label || field.variable}
            />
          </Grid>
        );
      case "array":
        return (
          <Grid item xs={12} key={index}>
            <Typography variant="body1" color="textSecondary">
              {field.label}:
            </Typography>
            {formData[field.variable] &&
            Array.isArray(formData[field.variable]) ? (
              formData[field.variable].map((item, itemIndex) => (
                <Typography key={itemIndex} style={{ marginLeft: 20 }}>
                  - {item}
                </Typography>
              ))
            ) : (
              <Typography style={{ marginLeft: 20 }}>
                No hay datos disponibles.
              </Typography>
            )}
          </Grid>
        );
      case "Timestamp":
        return (
          <Grid item xs={12} key={index}>
            <Typography variant="body1" color="textSecondary">
              {field.label || field.variable}:
            </Typography>
            <Typography variant="body2">
              {new Date(formData[field.variable]).toLocaleString()}
            </Typography>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Ver información:</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Avatar
                  alt={formData.displayName}
                  src={formData.avatar}
                  sx={{ width: 96, height: 96, margin: "0 auto" }}
                />
                <Typography variant="h6">{formData.userName}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {formData.email}
                </Typography>
              </Grid>
              {formFields.map(renderField)}
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleNavigate} color="primary">
          Ver detalles
        </Button> */}
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableVewData;
