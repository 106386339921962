import React, { useState, useContext } from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Card, Divider, CardHeader } from "@mui/material";
import { Grid } from "semantic-ui-react";
import { format } from "date-fns";
import { db } from "../../../firebase";
import Moment from "moment";
import DashIconsResumenDispachs from "../../../components/Dashboard/DashIconsResumenDispachs/DashIconsResumenDispachs";
import { collection, query, where, getDocs } from "firebase/firestore";
//import ExportExcel from "react-export-excel";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import useGlobalConfig from "../../../hooks/globalConfig";
import { PDFViewer } from "@react-pdf/renderer";
import CierreOpsPdf from "./CierreOpsPdf";
import ToolDashOrdersContainer from "../../../components/Dashboard/dashboard/ToolDashOrdersContainer/ToolDashOrdersContainer";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { CardContent } from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
// const libraries = ["places"];
// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
// const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

const GuiasByFalabellaMonitorExcepcions = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  //////console.log(props)

  const {
    rosettaVehicles,
    translateStateBicciFalabella,
    styleStateBicciFalabella,
    falabellaExcepcions,
    retiredTestsArray,
  } = useGlobalConfig();

  const { widthHeight } = useContext(ScreenSizeContext);

  const userUid = useSelector((store) => store.usuario.user.uid);

  const { userdata } = useObtenerDatosdeCliente(userUid);
  const [companyId, setcompanyId] = useState("");

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  const [falabellaOrders, setFalabellaOrders] = useState([]);

  const [BicciOrders, setBicciOrders] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [showEdit, setShowEdit] = useState(true);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [render, setRender] = useState(true);

  const [path, setPath] = React.useState([]);

  const [realTimeisActive, setRealTimeisActive] = React.useState(false);

  const [metricsOps, setMetricsOps] = React.useState([]);

  const [resetCicle, setResetCicle] = React.useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [Microzaonas, setMicrozonas] = useState([]);

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const listItems = [
    { id: "Creadas" },
    { id: "Ruta" },
    { id: "Incidencias" },
    { id: "Finalizadas" },
    { id: "Total" },
  ];

  const toggleShowRefres = () => setRender(!render);

  const [cantListItems, setcantListItems] = React.useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
      if (userdata.companyCollaborator === "falabella") {
        setcompanyId(`${process.env.REACT_APP_BXTOKETN}`);
      }
    }
  }, [userdata]);

  React.useEffect(() => {
    findOrdesrDispachtFalabella();
  }, []);

  React.useEffect(() => {
    //addBicciData();
  }, [listItems]);

  React.useEffect(() => {
    BiccidataMerge();
  }, [falabellaOrders, BicciOrders]);

  React.useEffect(() => {
    findOrdesrDispachtFalabellaByDate();
  }, [initDate, endDate]);

  React.useEffect(() => {
    getResumenOps();
  }, [dataMerge]);

  React.useEffect(() => {
    if (realTimeisActive === true) {
      const interval = setInterval(() => {
        ////////console.log('This will run every cicle');
        resetCicle.currentRequest += 1;
        findOrdesrDispachtFalabellaByDate();

        if (resetCicle.maxRequest === resetCicle.currentRequest) {
          setRender(!render);
        }
        if (resetCicle.desactiveRealtime === resetCicle.currentRequest) {
          ////////console.log('Desactivar real time')
          setRealTimeisActive(!realTimeisActive);
          setRender(!render);
        }
      }, 60000);

      return () => clearInterval(interval);
    } else {
      clearInterval();
    }
  }, [realTimeisActive]);

  React.useEffect(() => {}, [render]);

  const BiccidataMerge = async () => {
    // ////console.log(falabellaOrders)
    ////console.log(BicciOrders)

    //  let datatest1 = BicciOrders.filter(item  => item.status === 1)
    //  let datatest3 = BicciOrders.filter(item  => item.status === 3)
    //  let datatest4 = BicciOrders.filter(item  => item.status === 4)
    //  let datatest5 = BicciOrders.filter(item  => item.status === 5)
    //  let datatest6 = BicciOrders.filter(item  => item.status === 6)
    //  let datatest7 = BicciOrders.filter(item  => item.status === 7)
    //  ////console.log(datatest1)
    //  ////console.log(datatest3)
    //  ////console.log(datatest4)
    //  ////console.log(datatest5)
    //  ////console.log(datatest6)
    //  ////console.log(datatest7)
    async function initMerge() {
      const uniqueMap = new Map();

      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        }

        return Array.from(uniqueMap.values());
      }

      var DataMerge = await removeDuplicatesById(falabellaOrders);

      for (let i = 0; i < DataMerge.length; i++) {
        for (let n = 0; n < BicciOrders.length; n++) {
          let socf = "F" + DataMerge[i].id;
          // ////console.log(BicciOrders[n].id)
          if (socf === BicciOrders[n].id) {
            // ////console.log(falabellaOrders[i])

            DataMerge[i].placeName = BicciOrders[n].placeName;
            DataMerge[i].driverName = BicciOrders[n].driverName;
            DataMerge[i].driverAvatar = BicciOrders[n].driverAvatar;
            DataMerge[i].driverID = BicciOrders[n].driverID;
            DataMerge[i].bicciStatus = BicciOrders[n].status;
            DataMerge[i].userNameReceptor = BicciOrders[n].userNameReceptor;
            DataMerge[i].userDniReceptor = BicciOrders[n].userDniReceptor;
            DataMerge[i].pics = BicciOrders[n].pics;
            DataMerge[i].upDatedAtText = format(
              DataMerge[i].updatedAt.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
          }
        }
      }
      setDataMerge(DataMerge);
    }

    await initMerge();
  };

  const addBicciData = async () => {
    try {
      const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
      const querySnapshot = await getDocs(orderRef);
      const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBicciOrders(dataOrdesBicci);
    } catch (error) {}
  };

  const findOrdesrDispachtFalabella = async () => {
    const d = new Date(initDate);
    try {
      const allOrdersRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
      const querySnapshot = await getDocs(q);
      const dataOrdes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataOrdes.sort((a, b) =>
        a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
      );
      let dataOrdesfilter = dataOrdes.filter(
        (item) => !retiredTestsArray.includes("F" + item.id.toString())
      );
      ////console.log(dataOrdesfilter)
      setResumenTable(dataOrdesfilter);
      setFalabellaOrders(dataOrdesfilter);
    } catch (error) {
      ////console.log(error)
    }
  };

  const getResumenOps = () => {
    ////console.log(VehiclesDataByCompany)
    ////console.log(listVehiclesDataByCompany)
    var listRiders = [];
    var comunasDelivery = [];
    if (dataMerge) {
      for (let n = 0; n < dataMerge.length; n++) {
        if (!listRiders.includes(dataMerge[n].driverID)) {
          listRiders.push(dataMerge[n].driverID);
        }
        if (!comunasDelivery.includes(dataMerge[n].commune2)) {
          comunasDelivery.push(dataMerge[n].commune2);
        }
      }
      ////console.log(comunasDelivery)
      var listRidersJsonString = {};
      var conuters = {};
      var conutersDelivery = {};
      var conutersIssures = {};

      var countersListOrders = {};
      // let countersListOrdersContent = []

      var countersIssuresListOrders = {};

      var conterComunes = {};
      var vehicleDetailsID = {};
      var vehicleDetailsType = {};

      //llenado de data Resumen
      if (listRiders.length > 0 && VehiclesDataByCompany) {
        for (let n = 0; n < dataMerge.length; n++) {
          for (let m = 0; m < listRiders.length; m++) {
            if (dataMerge[n].driverID === listRiders[m]) {
              if (listRidersJsonString[dataMerge[n].driverID]) {
                conuters[dataMerge[n].driverID] = {
                  value:
                    listRidersJsonString[dataMerge[n].driverID].asignados + 1,
                };

                var countersListOrdersContent = [];

                if (
                  listRidersJsonString[dataMerge[n].driverID].countersListOrders
                ) {
                  countersListOrdersContent =
                    listRidersJsonString[dataMerge[n].driverID]
                      .countersListOrders.data;
                  countersListOrdersContent.push(dataMerge[n].id);

                  countersListOrders[dataMerge[n].driverID] = {
                    data: countersListOrdersContent,
                  };
                } else {
                  countersListOrdersContent.push(dataMerge[n].id);
                  countersListOrders[dataMerge[n].driverID] = {
                    data: countersListOrdersContent,
                  };
                }

                var countersListIssuresOrdersContent = [];
                if (dataMerge[n].falabella_status === "99") {
                  conutersDelivery[dataMerge[n].driverID] = {
                    value:
                      listRidersJsonString[dataMerge[n].driverID].delivery + 1,
                  };
                } else {
                  conutersIssures[dataMerge[n].driverID] = {
                    value:
                      listRidersJsonString[dataMerge[n].driverID].issures + 1,
                  };

                  if (
                    listRidersJsonString[dataMerge[n].driverID]
                      .countersIssuresListOrders
                  ) {
                    ////console.log('EXISTE countersIssuresListOrders')
                    countersListIssuresOrdersContent =
                      listRidersJsonString[dataMerge[n].driverID]
                        .countersIssuresListOrders.data;
                    countersListIssuresOrdersContent.push(dataMerge[n].id);
                    countersIssuresListOrders[dataMerge[n].driverID] = {
                      data: countersListIssuresOrdersContent,
                    };
                  } else {
                    countersListIssuresOrdersContent.push(dataMerge[n].id);
                    countersIssuresListOrders[dataMerge[n].driverID] = {
                      data: countersListIssuresOrdersContent,
                    };
                  }
                }
              } else {
                conuters[dataMerge[n].driverID] = { value: 1 };

                if (dataMerge[n].falabella_status === "99") {
                  conutersDelivery[dataMerge[n].driverID] = { value: 1 };
                  conutersIssures[dataMerge[n].driverID] = { value: 0 };
                  countersListOrdersContent = [];
                  countersListOrdersContent.push(dataMerge[n].id);
                  countersListOrders[dataMerge[n].driverID] = {
                    data: countersListOrdersContent,
                  };
                } else {
                  conutersDelivery[dataMerge[n].driverID] = { value: 0 };
                  conutersIssures[dataMerge[n].driverID] = { value: 1 };

                  countersListOrdersContent = [];
                  countersListOrdersContent.push(dataMerge[n].id);
                  countersListOrders[dataMerge[n].driverID] = {
                    data: countersListOrdersContent,
                  };

                  countersListIssuresOrdersContent = [];
                  countersListIssuresOrdersContent.push(dataMerge[n].id);
                  countersIssuresListOrders[dataMerge[n].driverID] = {
                    data: countersListIssuresOrdersContent,
                  };
                }
              }

              if (listRidersJsonString[dataMerge[n].driverID]) {
                for (let x = 0; x < comunasDelivery.length; x++) {
                  if (comunasDelivery[x] === dataMerge[n].commune2) {
                    if (
                      listRidersJsonString[dataMerge[n].driverID].locality
                        .length > 0
                    ) {
                      let arrayComunes =
                        listRidersJsonString[dataMerge[n].driverID].locality;
                      let comunaContenidas = [];
                      for (let a = 0; a < arrayComunes.length; a++) {
                        let nombreComuna = Object.keys(arrayComunes[a]);
                        if (!comunaContenidas.includes(nombreComuna[0])) {
                          comunaContenidas.push(nombreComuna[0]);
                        }
                      }
                      if (!comunaContenidas.includes(dataMerge[n].commune2)) {
                        arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                      } else {
                        arrayComunes[
                          comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                        ][dataMerge[n].commune2] =
                          arrayComunes[
                            comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                          ][dataMerge[n].commune2] + 1;
                      }
                      conterComunes[dataMerge[n].driverID] = arrayComunes;
                    } else {
                      let arrayComunes = [];
                      arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                      conterComunes[dataMerge[n].driverID] = arrayComunes;
                    }
                  }
                }
              } else {
                for (let x = 0; x < comunasDelivery.length; x++) {
                  if (comunasDelivery[x] === dataMerge[n].commune2) {
                    let arrayComunes = [];
                    arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                    conterComunes[dataMerge[n].driverID] = arrayComunes;
                  }
                }
              }

              for (let q = 0; q < VehiclesDataByCompany.length; q++) {
                if (
                  dataMerge[n].driverID ===
                  VehiclesDataByCompany[q].driverAssing
                ) {
                  vehicleDetailsID[dataMerge[n].driverID] = {
                    value: VehiclesDataByCompany[q].id,
                  };
                  vehicleDetailsType[dataMerge[n].driverID] = {
                    value: VehiclesDataByCompany[q].vehiclesTypes,
                  };
                } else {
                  if (!vehicleDetailsID[dataMerge[n].driverID]) {
                    vehicleDetailsID[dataMerge[n].driverID] = {
                      value: "No resgistrado",
                    };
                    vehicleDetailsType[dataMerge[n].driverID] = {
                      value: "No resgistrado",
                    };
                  }
                }
              }

              listRidersJsonString[dataMerge[n].driverID] = {
                driverName: dataMerge[n].driverName,
                driverAvatar: dataMerge[n].driverAvatar,
                asignados: conuters[dataMerge[n].driverID].value,
                delivery: conutersDelivery[dataMerge[n].driverID].value,
                issures: conutersIssures[dataMerge[n].driverID].value,
                vehicleDetailsType:
                  vehicleDetailsType[dataMerge[n].driverID].value,
                vehicleDetailsID: vehicleDetailsID[dataMerge[n].driverID].value,
                locality: conterComunes[dataMerge[n].driverID],
                countersListOrders: countersListOrders[dataMerge[n].driverID],
                countersIssuresListOrders:
                  countersIssuresListOrders[dataMerge[n].driverID],
              };
            }
          }
        }
        let arraylistRidersJsonString = Object.values(listRidersJsonString);
        console.log(arraylistRidersJsonString);
        setMetricsOps(arraylistRidersJsonString);
      }
    }
  };

  const onChageRange = (e) => {
    if (e.target.name === "rangoInicio") {
      setInitDate(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDate(e.target.value);
    }
  };

  const findOrdesrDispachtFalabellaByDate = async () => {
    const i = new Date(initDate);
    const e = new Date(endDate);
    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );
    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }
    var endRang = sumarUno(e);
    try {
      const allOrdersRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
      const querySnapshot = await getDocs(q);
      const dataOrdes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dataOrdes.sort((a, b) =>
        a.falabella_status > b.falabella_status ? 1 : -1
      );
      //   //////console.log(dataOrdes)

      let dataOrdesfilter = dataOrdes.filter(
        (item) => !retiredTestsArray.includes("F" + item.id.toString())
      );

      //////console.log(dataOrdesfilter)
      setResumenTable(dataOrdesfilter);
      setFalabellaOrders(dataOrdesfilter);
    } catch (error) {
      //     //////console.log(error)
    }

    if (initDate === endDate) {
      callIgual();
    }

    async function callIgual() {
      function restaUno(fecha2) {
        fecha2.setDate(fecha2.getDate());
        return fecha2;
      }
      var rangInit = restaUno(i);

      function sumarUno(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        return res;
      }
      var rangFinal = sumarUno(i);

      try {
        const dataallOrdersfind5 = await allOrdersRef
          // .where("createdAt", ">=", rangInit)
          // .where("createdAt", "<=", rangFinal)
          .where("falabella_status", "!=", "99")
          .get();
        var dataOrdes = dataallOrdersfind5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        ////console.log(dataOrdes)  a.updatedAt.seconds < b.updatedAt.seconds
        dataOrdes.sort((a, b) =>
          a.falabella_status > b.falabella_status ? 1 : -1
        );
        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredTestsArray.includes("F" + item.id.toString())
        );
        setFalabellaOrders(dataOrdesfilter);
      } catch (error) {
        //////console.log(error)
      }
    }
    toggleShowRefres();
  };

  const setResumenTable = (dataOrdes) => {
    let Creadas = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1058" || item.falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.falabella_status === "99"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1060" || item.falabella_status === "1061"
    );
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.falabella_status)
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const handleSelecRiders = () => {
    //////console.log(e.currentTarget.id)
    //////console.log(e.currentTarget.checked)
  };

  const changerealTimeOption = (e) => {
    setRealTimeisActive(e.target.checked);
  };

  const handleOrdersForAccions = () => {};

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <h3 className="h2-withe">
            ALERTA ORDENES INCIDENCIAS PENDIENTES EN SISTEMA{" "}
          </h3>
          <Card className="typeMiniforms">
            <CardHeader title="" subheader={<div></div>} />
            <Divider />
            <Container className="formcontent" id="micuentafromIni">
              <CardContent className="dashIconsresumencardcontent">
                <LocalPostOfficeIcon
                  className={`dashIconDispachs dashIcon-red
                  }`}
                />
                <DirectionsBikeIcon
                  className={`dashIconDispachs dashIcon-red
                  }`}
                />
                <div>
                  {cantListItems && cantListItems.Incidencias ? (
                    <div>
                      <span className="positionCants">{`${cantListItems.Incidencias}`}</span>
                      <span className="dashicontspansmalltext">Fallidas</span>
                    </div>
                  ) : (
                    0
                  )}
                </div>

                <Divider />
                <h3 className="dashicontitle">
                  {listItems.Incidencias === "Creadas" ? (
                    <>Sin Asignar</>
                  ) : (
                    <>{listItems.Incidencias}</>
                  )}
                </h3>
              </CardContent>
              {/* <DashIconsResumenDispachs
                items={listItems}
                cant={cantListItems}
              ></DashIconsResumenDispachs> */}
              <div>
                {dataMerge.length > 0 ? (
                  // <ExcelFile
                  //   element={
                  //     <button
                  //       onClick={() => exporExcelFile()}
                  //       className="btn btn-ebiex m-2"
                  //     >
                  //       {" "}
                  //       Respuestas de Raspado
                  //     </button>
                  //   }
                  //   filename={
                  //     "Respuesta Raspado Bicci Ordenes" +
                  //     Moment(Date.now()).format("YYYY-MM-DD")
                  //   }
                  // >
                  //   <ExcelSheet data={dataMerge} name="DATA">
                  //     <ExcelColumn label="ID" value="soc"></ExcelColumn>
                  //     <ExcelColumn
                  //       label="Receptor"
                  //       value="userNameReceptor"
                  //     ></ExcelColumn>
                  //     <ExcelColumn
                  //       label="RUT"
                  //       value="userDniReceptor"
                  //     ></ExcelColumn>
                  //     <ExcelColumn
                  //       label="status"
                  //       value="falabella_status"
                  //     ></ExcelColumn>
                  //     <ExcelColumn
                  //       label="Actulizado"
                  //       value="upDatedAtText"
                  //     ></ExcelColumn>

                  //     <ExcelColumn
                  //       label="Conductor"
                  //       value="driverName"
                  //     ></ExcelColumn>
                  //     <ExcelColumn
                  //       label="Comuna"
                  //       value="commune2"
                  //     ></ExcelColumn>
                  //   </ExcelSheet>
                  // </ExcelFile>
                  <>ExcelFile</>
                ) : null}
              </div>

              <div></div>

              <Grid.Row>
                <Grid.Column
                  computer={8}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun "
                >
                  {wiewPrePdf && metricsOps ? (
                    <PDFViewer style={{ width: "100%", height: "90vh" }}>
                      <CierreOpsPdf
                        items={listItems}
                        cant={cantListItems}
                        metricsOps={metricsOps}
                        rosettaVehicles={rosettaVehicles}
                      ></CierreOpsPdf>
                    </PDFViewer>
                  ) : null}

                  <ToolDashOrdersContainer
                    movil={widthHeight.width < 950 ? true : false}
                    dataMerge={dataMerge}
                    handleOrdersForAccions={handleOrdersForAccions}
                    styleStateBicciFalabella={styleStateBicciFalabella}
                    translateStateBicciFalabella={translateStateBicciFalabella}
                  ></ToolDashOrdersContainer>
                </Grid.Column>

                <Grid.Column
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun"
                >
                  {/* <LatestProducts  />  */}
                </Grid.Column>
              </Grid.Row>
            </Container>

            <Divider />
          </Card>

          <div></div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GuiasByFalabellaMonitorExcepcions;
