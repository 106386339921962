import { useEffect, useState } from "react";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

function useObtenerBalance(companyuid) {
  const [userBalance, setUserBalance] = useState(null);
  const [loadingBalance, setLoading] = useState(false);
  const [errorBalance, setError] = useState(null);

  useEffect(() => {
    if (companyuid) {
      fetchData(companyuid.trim());
    }

    // El return es opcional, en caso de querer cancelar el efecto en algún momento
    return () => {
      // Código para cancelar el efecto
    };
  }, []);

  const fetchData = async (companyuid) => {
    try {
      setLoading(true);
      try {
        const accountantsDoc = await getDoc(
          doc(db, `${process.env.REACT_APP_COL_BALANCE}/${companyuid}`)
        );
        const dataResult = accountantsDoc.data();
        setUserBalance(dataResult);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  return { userBalance, loadingBalance, errorBalance };
}

export default useObtenerBalance;
