import * as XLSX from "xlsx";
import moment from "moment";
import { differenceInCalendarDays, format, fromUnixTime } from "date-fns";
import { translateStatesBicci } from "../objects/translateStates";

const generarReporteTurnoRutasUtils = async (
  metricsOpsRoutes,
  ridersDataByCompany
) => {
  console.log(metricsOpsRoutes);
  if (metricsOpsRoutes) {
    const bicciNumberMap = {};
    ridersDataByCompany.forEach((rider) => {
      bicciNumberMap[rider.id] = rider.bicciNumber;
    });

    function formatDate(element) {
      try {
        return element.date
          ? format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : element.createdAt
          ? format(element.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : "No Date";
      } catch (error) {
        console.log("Error al formatear la fecha:", error);
        return "Formato de fecha inválido";
      }
    }

    function getDays(element) {
      try {
        const targetDate = element.date
          ? new Date(element.date.seconds * 1000)
          : element.createdAt
          ? new Date(element.createdAt.seconds * 1000)
          : null;

        if (!targetDate) {
          return 0;
        }

        let endDate;
        if (
          (element.int_falabella_status === "99" ||
            element.int_falabella_status === "Terminado" ||
            element.int_bicciStatus === 8) &&
          element.upDatedAtText
        ) {
          endDate = fromUnixTime(element.upDatedAtText.seconds);
        } else {
          endDate = new Date();
        }

        const delayDays = differenceInCalendarDays(endDate, targetDate);

        return delayDays < 0 ? 0 : delayDays;
      } catch (error) {
        console.log("Error al calcular los días de retraso:", error);
        return "Error al calcular los días";
      }
    }

    function formatDateUpdatedAt(element) {
      try {
        return element.updatedAt
          ? format(element.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
          : "No date updatedAt";
      } catch (error) {
        return "Formato de fecha inválido";
      }
    }

    const updateTableDocuments = async (metricsOpsRoutes) => {
      // Procesar la data de los conductores
      const rowsTable = metricsOpsRoutes.map((op, index) => {
        let sortedOrders;
        try {
          sortedOrders = op.listOrdersData.data
            .filter((order) => order.updatedAt) // Filtrar órdenes sin `updatedAt`
            .sort((a, b) => a.updatedAt.seconds - b.updatedAt.seconds);
        } catch (error) {
          console.log("Error al ordenar las órdenes: ", error);
          sortedOrders = [];
        }

        const startDay =
          sortedOrders.length > 0
            ? moment(sortedOrders[0].updatedAt.seconds * 1000).format(
                "DD/MM/YYYY"
              )
            : "N/A";

        const endDay =
          sortedOrders.length > 0
            ? moment(
                sortedOrders[sortedOrders.length - 1].updatedAt.seconds * 1000
              ).format("DD/MM/YYYY")
            : "N/A";

        const start =
          sortedOrders.length > 0
            ? new Date(sortedOrders[0].updatedAt.seconds * 1000)
            : null;

        const end =
          sortedOrders.length > 0
            ? new Date(
                sortedOrders[sortedOrders.length - 1].updatedAt.seconds * 1000
              )
            : null;

        const lastActivity =
          sortedOrders.length > 0
            ? new Date(
                Math.max(
                  ...sortedOrders.map((order) =>
                    order.updatedAt ? order.updatedAt.seconds * 1000 : 0
                  )
                )
              )
            : null;

        const routeTime = start && end ? Math.round((end - start) / 60000) : 0;
        const routeTimeInHours = routeTime / 60;

        const deliveriesPerHour =
          routeTimeInHours > 0
            ? (op.delivered / routeTimeInHours).toFixed(2)
            : "N/A";

        const deliveryTime =
          op.delivery > 0 ? (routeTime / op.delivery).toFixed(0) : 0;
        const estimateTime =
          op.asignados > 0
            ? ((op.asignados * 10) / op.asignados).toFixed(0)
            : 0;

        let speedLabel = "N/A";
        if (!op.delivered || op.delivered < 1) {
          speedLabel = "Detenido";
        } else if (deliveryTime > estimateTime) {
          speedLabel = "Lento";
        } else {
          speedLabel = "Rápido";
        }

        return {
          id: index + 1,
          driverName: "",
          vehicleDetailsID: op.route,
          day: startDay,
          endDay: endDay,
          start: start ? start.toLocaleTimeString() : "N/A",
          end: end ? end.toLocaleTimeString() : "N/A",
          LastActivity: lastActivity
            ? lastActivity.toLocaleTimeString()
            : "N/A",
          routeTime: routeTime,
          asignados: op.assigned,
          delivery: op.delivered,
          deliveriesPerHour: deliveriesPerHour,
          speedLabel: speedLabel,
          nSDriver: ((op.delivery / op.asignados) * 100).toFixed(2),
        };
      });

      const processedData = rowsTable.map((dato) => ({
        id: dato.id,
        driverName: dato.driverName,
        vehicleDetailsID: dato.vehicleDetailsID,
        day: dato.day,
        endDay: dato.endDay,
        start: dato.start,
        end: dato.end,
        LastActivity: dato.LastActivity,
        routeTime: (dato.routeTime / 60).toFixed(2),
        asignados: dato.asignados,
        delivery: dato.delivery,
        deliveriesPerHour: dato.deliveriesPerHour,
        speedLabel: dato.speedLabel,
        nSDriver: ((dato.delivery / dato.asignados) * 100).toFixed(2),
      }));

      // Crear la matriz de reporte con los datos procesados
      const matrizReporte = [
        [
          "ITEM",
          "CONDUCTOR",
          "VEHICULO",
          "DIA INICIO",
          "HORA INICIO",
          "DIA FIN",
          "HORA FIN",
          "ULTIMA ACT",
          "TIEMPO EN RUTA",
          "TOTAL ASIGNADOS",
          "ENTREGADOS",
          "ENTREGADO/HORA",
          "NS",
          "VELOCIDAD",
        ],
        ...processedData.map((dato) => [
          dato.id,
          dato.driverName,
          dato.vehicleDetailsID,
          dato.day,
          dato.endDay,
          dato.start,
          dato.end,
          dato.LastActivity,
          dato.routeTime,
          dato.asignados,
          dato.delivery,
          dato.deliveriesPerHour,
          dato.nSDriver,
          dato.speedLabel,
        ]),
      ];

      // Matriz para las incidencias
      const matrizIncidencias = [
        [
          "BICCIID",
          "SUBORDEN",
          "LPN",
          "F12",
          "PPU",
          "RIDER",
          "Origen de Carga",
          "Destino",
          "Comuna",
          "Tipo de Servicio",
          "ID Ruta/Viaje/Shipment",
          "Fecha de recepción",
          "Fecha de Actualización",
          "Fecha de entrega",
          "Estado",
          "Descripción",
          "Estado Integración",
          "Motivo de no entrega",
          "Receptor",
          "RUT Receptor",
          "Observación",
          "Días desde creacion",
          "fotos",
          "Exception GeoRef",
        ],
      ];

      metricsOpsRoutes.forEach((op) => {
        if (op.listOrdersIssuresData && op.listOrdersIssuresData.data) {
          op.listOrdersIssuresData.data.forEach((issue) => {
            matrizIncidencias.push([
              issue.id || "N/A",
              issue.soc || issue.int_soc || "N/A",
              issue.lpn || issue.int_lpn || "N/A",
              issue.do || issue.int_f12 || "N/A",
              bicciNumberMap[issue.driverID] || "N/A",
              issue.driverName || "",
              issue.local_provider || issue.placeName || "N/A",
              issue.toAddress || "N/A",
              issue.commune2 || "N/A",
              issue.deliveryType || "N/A",
              issue.driverLicensePlate || "N/A",
              formatDate(issue),
              formatDateUpdatedAt(issue),
              issue.status === 8 ? formatDateUpdatedAt(issue) : "",
              issue.status || "N/A",
              issue.status
                ? translateStatesBicci[issue.status] || "N/A"
                : "N/A",
              issue.intState || "N/A",
              issue.lostDelivery === true
                ? "Devolucion"
                : issue.intState || "N/A",
              issue.userNameReceptor || issue.deliveryReceptor || "",
              issue.userDniReceptor || issue.deliveryRut || "N/A",
              issue.observationReceptor ||
              issue.observationAdmin ||
              issue.deliveryComment
                ? `${
                    issue.observationAdmin
                      ? `- Operador:  ${issue.observationAdmin[0]} `
                      : ""
                  }${
                    issue.observationReceptor
                      ? `- Conductor: ${issue.observationReceptor}`
                      : ""
                  }${issue.deliveryComment ? `- ${issue.deliveryComment}` : ""}`
                : "N/A",
              getDays(issue),
              issue.pics && issue.pics.length > 0
                ? issue.pics.join("\n")
                : issue.deliveryPictures || "N/A",
              issue.exceptionGeoRef
                ? `${issue.exceptionGeoRef._lat} , ${issue.exceptionGeoRef._long}`
                : "N/A",
            ]);
          });
        }
      });

      const libro = XLSX.utils.book_new();
      // Crear la primera hoja con los datos de conductores
      const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
      // Añadir la hoja de conductores
      XLSX.utils.book_append_sheet(libro, hoja, "CONDUCTORES");

      // Crear la segunda hoja con las incidencias (si tiene más de un dato)
      if (matrizIncidencias.length > 1) {
        const hoja2 = XLSX.utils.aoa_to_sheet(matrizIncidencias);
        XLSX.utils.book_append_sheet(libro, hoja2, "PENDIENTES");
      }

      XLSX.writeFile(
        libro,
        `Reporte de Rutas ( BICCI )  ${moment(Date.now()).format(
          "YYYY-MM-DD hh:mm:ss"
        )}.xlsx`
      );
    };

    await updateTableDocuments(metricsOpsRoutes);
  }
};

export default generarReporteTurnoRutasUtils;
