import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GraficasChartLineDaysMonths = (props) => {
  let colors = [
    "#28cd71",
    "#41c5ed",
    "#591e8f",
    "#0219EB",
    "#02F5B8",
    "#87DE0D",
    "#F5A802",
    "#EB867A",
    "#BC5DEB",
    "#E302EB",
  ];

  const labels = [];
  const dataValues = [];

  for (let i = 0; i < props.dataHistorial.length; i++) {
    const mesNombre = props.dataHistorial[i].id;
    const cant = props.dataHistorial[i].cant;
    const mesLabel = `Label ${i + 1}`;

    labels.push(mesNombre);
    dataValues.push(cant);
  }

  const data = {
    labels: labels,

    datasets: [
      {
        label: props.name,
        data: dataValues,
        fill: false,
        borderColor: props.color,
        tension: 0.2,
      },
    ],
  };
  // var misoptions = {
  //   scales: {
  //     y: {
  //       min: 0,
  //       height: 100,
  //     },
  //     x: {
  //       ticks: { color: "rgba(30, 33, 88)", min: 0, max: 10 },
  //     },
  //   },
  //};
  // options={misoptions}
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Para que pueda ajustarse en su contenedor
    // Otras opciones
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default GraficasChartLineDaysMonths;
