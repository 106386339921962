import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import * as XLSX from "xlsx";
import { updateDoc } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";

const useHandleUpdateLostDelivery = () => {
  const [loadingUpdateLost, setLoading] = useState(false);
  const [dataUpdateLost, setData] = useState(null);
  const [errorUpdateLost, setError] = useState(null);

  const uploadDocumentUpdateLost = async (document) => {
    setLoading(true);
    setError(null);

    try {
      const data = await document.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets["lostDelivery"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData && jsonData.length > 0) {
        for (let n = 0; n < jsonData.length; n++) {
          const element = jsonData[n];
          let companyIdOrder = element.BICCIID.toString();
          let lostDelivery = element.lostDelivery;
          const orderRef = doc(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS,
            companyIdOrder
          );
          const docSnapshot = await getDoc(orderRef);

          if (docSnapshot.exists()) {
            // Actualiza el documento con el array modificado
            let updateData = {
              lostDelivery: lostDelivery,
            };

            // Actualiza el documento con los datos modificados
            await updateDoc(orderRef, updateData);
            console.log("ACTUALIZANDO lostDelivery DE RUTAS");
          } else {
            console.log("NO SE ENCONTRO EL ID:", element.BICCIID);
          }
        }

        // Simula una llamada a una función asincrónica para subir el documento
        //  await new Promise((resolve) => setTimeout(resolve, 5000));

        const result = {
          response: "Update lostDelivery OK",
        };
        console.log(jsonData);

        setData(result);
      } else {
        throw new Error("El archivo no contiene datos válidos.");
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataUpdateLost,
    loadingUpdateLost,
    errorUpdateLost,
    uploadDocumentUpdateLost,
  };
};

export default useHandleUpdateLostDelivery;
