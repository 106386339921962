import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export const getDocumentAndHistory = async (id, prefixes, collections) => {
  // console.log(id, prefixes, collections);
  let foundDocumentData = null;
  let documentHistory = [];
  let detectedPrefix = "";

  try {
    for (let collectionName of collections) {
      const matchedPrefix = prefixes.find((prefix) => id.startsWith(prefix));

      if (matchedPrefix) {
        // console.log(matchedPrefix); // id=R4067385 prefixes = ['R', 'BX', 'F'] collections = ['orders'], Si existe la orden pero no la encuentra
        detectedPrefix = matchedPrefix;
        console.log(id);
        let documentData = await tryGetDoc(collectionName, id);
        console.log(documentData);
        if (documentData) {
          documentHistory = await tryGetDocHistory(
            collectionName,
            documentData.id
          );
          foundDocumentData = documentData;

          if (matchedPrefix === "F") {
            const idWithoutPrefix = documentData.id.replace(/^F/, "");

            const alternativeData = await tryGetAlternativeDoc(idWithoutPrefix);
            foundDocumentData = mergeDocuments(
              foundDocumentData,
              alternativeData
            );
            const alternativeHistory = await tryGetAlternativeHistory(
              idWithoutPrefix
            );
            documentHistory = mergeHistories(
              documentHistory,
              alternativeHistory
            );
          }

          break;
        }
      } else {
        for (let currentPrefix of prefixes) {
          let idWithPrefix = currentPrefix + id;
          // console.log(idWithPrefix);
          let documentData = await tryGetDoc(collectionName, idWithPrefix);
          if (documentData) {
            documentHistory = await tryGetDocHistory(
              collectionName,
              documentData.id
            );
            foundDocumentData = documentData;
            detectedPrefix = currentPrefix;
            if (currentPrefix === "F") {
              const idWithoutPrefix = documentData.id.replace(/^F/, "");

              const alternativeData = await tryGetAlternativeDoc(
                idWithoutPrefix
              );

              foundDocumentData = mergeDocuments(
                foundDocumentData,
                alternativeData
              );
              const alternativeHistory = await tryGetAlternativeHistory(
                idWithoutPrefix
              );
              documentHistory = mergeHistories(
                documentHistory,
                alternativeHistory
              );
            }

            break;
          }
        }
      }
    }
  } catch (error) {
    console.log("Error fetching document or history:", error);
  }

  // console.log(foundDocumentData);
  // console.log(documentHistory);
  // console.log(detectedPrefix);

  return { foundDocumentData, documentHistory, prefix: detectedPrefix };
};
const tryGetAlternativeDoc = async (id) => {
  const alternativeCollection = process.env.REACT_APP_COL_FALABELLA_ORDERS;
  const orderRef = doc(db, alternativeCollection, id);
  const docSnapshot = await getDoc(orderRef);
  return docSnapshot.exists() ? docSnapshot.data() : null;
};
const tryGetAlternativeHistory = async (id) => {
  const alternativeCollection = process.env.REACT_APP_COL_FALABELLA_ORDERS;
  const orderRef = doc(db, alternativeCollection, id);
  const statusDocs = collection(orderRef, "status");
  const dataAllOrders = await getDocs(statusDocs);
  const dataDocuments = dataAllOrders.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  dataDocuments.sort((a, b) =>
    a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
  );

  return dataDocuments.length > 0 ? dataDocuments : [];
};

const mergeDocuments = (mainDoc, alternativeDoc) => {
  return { ...alternativeDoc, ...mainDoc };
};

const mergeHistories = (history1, history2) => {
  const mergedHistory = [...history1, ...history2];
  mergedHistory.sort((a, b) =>
    a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
  );
  return mergedHistory;
};

const tryGetDoc = async (collectionName, id) => {
  const orderRef = doc(db, collectionName, id);
  const docSnapshot = await getDoc(orderRef);
  return docSnapshot.exists() ? docSnapshot.data() : null;
};

const tryGetDocHistory = async (collectionSearch, id) => {
  const orderRef = doc(db, collectionSearch, id);
  const statusDocs = collection(orderRef, "statuses");
  const dataAllOrders = await getDocs(statusDocs);
  const dataDocuments = dataAllOrders.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  dataDocuments.sort((a, b) =>
    a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
  );

  return dataDocuments.length > 0 ? dataDocuments : [];
};
