import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

const ProductionEnvironment = () => {
  return (
    <div id="production-environment">
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Ambiente de Producción
        </Typography>
        <Typography variant="body1" paragraph>
          Antes de pasar al ambiente de producción, asegúrate de seguir estas
          instrucciones para garantizar una transición suave y sin problemas.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Instrucciones
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Verificar el Company ID"
              secondary="Asegúrate de que el Company ID utilizado en el ambiente de integración es el mismo que el utilizado en producción, ya que este puede variar entre los dos ambientes."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Cambiar las URLs a Producción"
              secondary="Actualiza todas las URLs en tu código para que apunten al ambiente de producción. Esto incluye todas las peticiones API que realizas."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Generar y Actualizar el Token de Producción"
              secondary="Puedes generar un nuevo token cuando lo consideres necesario, pero es importante que actualices este token en todas las peticiones API. El token está directamente asociado con tu compañía y debe estar vigente para asegurar el correcto funcionamiento de las integraciones."
            />
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default ProductionEnvironment;
