import React, { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  getDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BasicListElements from "../../../../components/BasicListElements/BasicListElements";
import { Box, Typography } from "@mui/material";
import {
  fetcherPermision,
  fetcherRoles,
} from "../../../../utils/FectcherData/FectcherUser";
import { DataGrid } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableForm from "../../../../components/ReusableForm/ReusableForm";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

const Permissions = () => {
  const [bodyItems, setBodyItems] = useState([]);
  const [render, setRender] = useState(false);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [statusActive, setStatusActive] = useState(false);

  // estados formualrios

  const classes = useStyles();
  useEffect(() => {
    getData();
  }, [render]);

  const getData = async () => {
    const items = await fetcherPermision();
    // const sortedItems = items.sort((a, b) => {
    //   return new Date(b.createdAt) - new Date(a.createdAt);
    // });
    console.log(items);
    setBodyItems(items);
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = (row) => {
    console.log("Ver", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleEdit = (row) => {
    console.log("Editar", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleActivate = (row) => {
    console.log("Activar", row);
    row.isActive = true;
    setStatusActive(true);
  };
  const handleDeactivate = (row) => {
    console.log("Desactivar", row);
    row.isActive = false;
    setStatusActive(false);
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        {/* Botón Ver */}
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        {/* Botón Editar */}
        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {/* Botón Activar/Desactivar */}
        {params.row.isActive ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "name",
        headerName: "Nombre",
        width: 150,
        editable: true,
      },
      {
        field: "permiso_id",
        headerName: "permiso_id",
        width: 100,
      },
      // {
      //   field: "actions",
      //   headerName: "Acciones",
      //   width: 300,
      //   renderCell: renderActionButtons,
      //   sortable: false,
      //   filterable: false,
      // },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < bodyItems.length; i++) {
      rows.push({
        id: bodyItems[i].id,
        name: bodyItems[i].name,
        permiso_id: bodyItems[i].permiso_id,
      });
    }
    setRows(rows);
  };

  // Formolario Nuevo Item
  const formFields = [
    { variable: "id", label: "id" },
    { variable: "name", label: "name" },
    { variable: "permiso_id", label: "permiso_id" },
  ];
  const handleAddItem = (formData) => {
    const newItem = {
      id: formData.id ? formData.id : rows.length + 1,
      name: formData.name,
      permiso_id: formData.permiso_id,
    };
    setRows([...rows, newItem]);
  };

  const handleSave = (formData) => {
    console.log(formData);
    handleAddItem(formData);
    setOpen(false);
  };
  // Formolario edit

  // Formolario ver edit
  const formFieldsEdit = [
    { variable: "id", label: "id" },
    { variable: "name", label: "name" },
    { variable: "permiso_id", label: "permiso_id" },
  ];

  const handleViewItem = (formData) => {
    const newItem = {
      id: rows.length + 1,
      userName: formData.userName,
      userLastName: formData.userLastName,
    };
    setRows([...rows, newItem]);
  };

  // Formolario ver edit

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestionar Permissions
      </Typography>
      {/* // Formolario Nuevo Item */}
      {/* <Button
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={() => setOpen(true)}
        sx={{ mb: 2 }} // margen inferior para separar del DataGrid
      >
        Agregar ítem
      </Button> */}
      <ReusableForm
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        handleSave={handleSave}
      />
      {/* // Formolario Nuevo Item */}
      {/* // Formolario view Item */}
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      {/* // Formolario View Item */}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        className={classes.root}
        groupingModel={["roll"]}
      ></DataGrid>
    </div>
  );
};

export default Permissions;
