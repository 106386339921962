import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { XCircle as XCircleIcon } from "../../../icons/XCircle";

const DashboardSidebarContact = (props) => {
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <NavLink
        to="/dashboard/report-bugs"
        className="btn-sidebarLink"
        onClick={props.onNavigate}
      >
        <div
          className={
            splitLocation[2] === "report-bugs"
              ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
              : "btn btn-lg btn-block btn-sidebar"
          }
        >
          <XCircleIcon fontSize="medium" />
          <span className="btn-title">Reportar error</span>
        </div>
      </NavLink>

      <hr className="sidebar-hr"></hr>
      <Box
        sx={{
          px: 2,
          py: 3,
        }}
      >
        <Typography
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="neutral.100"
          variant="subtitle2"
        >
          Necesitas más Soluciones?
        </Typography>
        <Typography
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="neutral.500"
          variant="body2"
        >
          Consulta con nuestro equipo de integraciones.
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: 2,
            mx: "auto",
            width: "160px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img alt="Go to pro" src="/static/images/sidebar_pro.png" />
        </Box>
        <NavLink to="/contacto">
          <Button
            color="secondary"
            endIcon={<OpenInNewIcon />}
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
          >
            Contactanos
          </Button>
        </NavLink>
      </Box>
    </>
  );
};

export default DashboardSidebarContact;
