import React from "react";
import { Grid, Image } from "semantic-ui-react";
import useGlobalConfig from "../../../../../hooks/globalConfig";

const Pos3 = () => {
  const { img4, img15, img2, img19, img16 } = useGlobalConfig();
  return (
    <Grid celled="internally">
      <Image
        id="bannerServicios"
        src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F001Bannerhome.jpg?alt=media&token=f25440e1-4912-4f84-9a4d-53cad593054d"
      />

      <div>
        <span>
          <h2 className="resaltadoCurvo">
            Somos una empresa comprometida con el desarrollo social y con la
            reducción de la huella de carbono en la ciudad.{" "}
          </h2>
        </span>
      </div>
      {/*       
      <NavLink  className="btn btn-ebiex m-1" to="/productos" variant="contained" >
     Nuestros Productos</NavLink>
 */}

      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={6}>
          <div className="textAlingCenter">
            <Image className="botonImage" src={img4} />
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={10} id="paragraph1">
          <div>
            <h2>Despacho Punto a Punto</h2>
            <span>
              Se realizan despachos de mercadería entre dos puntos dentro de la
              ciudad. Retiro de punto “A” con destino punto “B” .
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph2">
          <div>
            <h2>Next Day Delivery</h2>
            <span>Despachos en 24 horas desde la compra de tu cliente. </span>
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={6}>
          <div className="textAlingCenter">
            <Image className="botonImage" src={img15} />
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={6}>
          <div className="textAlingCenter">
            <Image className="botonImage" src={img2} />
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph3">
          <div>
            <h2>Same Day Delivery</h2>
            <span>
              Se realizan despachos de mercadería entre dos puntos dentro de la
              ciudad. Retiro de punto “A” con destino punto “B” .
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph4">
          <div>
            <h2>Logistica Reversa</h2>
            <span>
              Retiramos las devoluciones y las llevamos a la tienda o centro de
              distribución.{" "}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={6}>
          <div className="textAlingCenter">
            <Image className="botonImage" src={img19} />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={6}>
          <div className="textAlingCenter">
            <Image className="botonImage" src={img16} />
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={6} id="paragraph4">
          <div>
            <h2>Soluciones a Medida</h2>
            <span>
              Cuéntanos tus necesidades y buscamos una solución en conjunto.{" "}
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Pos3;
