import * as XLSX from "xlsx";
import Moment from "moment";
import { format } from "date-fns";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarReporteProformas = async (userOrders) => {
  console.log(userOrders);
  const matrizReporte = [
    [
      "Código",
      "F12",
      "Conductor",
      "Tienda Origen",
      "Cliente Destino",
      "Dirección",
      "Comuna",
      "Fecha Pactada",
      "Ultima Actualizacion",
      "Bicci Estatus",
      "Falabella Estatus",
      "Facturación",
      "Doc",
      "Tipo de servicio",
      "Tamaño producto",
      "Valor",
    ],
    ...userOrders.map((dato) => [
      dato.orderID,
      dato.F12 ? dato.F12 : dato.int_f12,
      dato.driverName && dato.driverName !== "isRequire"
        ? dato.driverName
        : "No registra",
      dato.BODEGANOMBRE ? dato.BODEGANOMBRE : dato.int_placeName,
      dato.CLIENTENOMBRES ? dato.CLIENTENOMBRES : dato.clientNames,
      dato.toAddress
        ? dato.toAddress
        : `${dato.DIRECCION1}, ${dato.DIRECCION2}`,
      dato.COMUNA2 ? dato.COMUNA2 : dato.commune2,
      dato.routeEstimatedDate
        ? dato.routeEstimatedDate
        : dato.timeStamp
        ? format(dato.timeStamp.seconds * 1000, "dd/MM/yy")
        : "err",
      dato.updatedAt
        ? format(dato.updatedAt.seconds * 1000, "dd/MM/yy")
        : "err formato",
      dato.int_bicciStatus
        ? dato.int_bicciStatus !== "isRequire"
          ? dato.int_bicciStatus
          : ""
        : dato.int_bicciStatus,
      dato.int_falabella_status
        ? dato.int_falabella_status === "isRequire"
          ? ""
          : dato.int_falabella_status
        : dato.falabella_status === "isRequire"
        ? ""
        : dato.falabella_status,
      dato.PaymentProforma || dato.billingPaymentProforma !== "isRequire"
        ? "ok"
        : "Pendiente",
      dato.PaymentProforma
        ? dato.PaymentProforma
        : dato.billingPaymentProforma === "isRequire"
        ? ""
        : dato.billingPaymentProforma,
      dato.billingPaymentType_service !== "isRequire"
        ? dato.billingPaymentType_service
        : "",
      dato.TAMANOPRODUCTO ? dato.TAMANOPRODUCTO : dato.transportOrderSize,
      dato.PaymentValor
        ? dato.PaymentValor
        : dato.billingPaymentValor !== "isRequire"
        ? dato.billingPaymentValor
        : "",
    ]),
  ];
  const libro = XLSX.utils.book_new();
  const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
  XLSX.utils.book_append_sheet(libro, hoja, "DATA");

  XLSX.writeFile(
    libro,
    `Reporte de Registros de Pagos ${Moment(Date.now()).format(
      "YYYY-MM-DD"
    )}.xlsx`
  );
};

export default generarReporteProformas;
