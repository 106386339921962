import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { format } from "date-fns";
// hooks
import { useBoolean } from "../../../../hooks/use-boolean";
// components
import Label from "../../../../components/Label/Label";
import Iconify from "../../../../components/Iconify/Iconify";
import CustomPopover, {
  usePopover,
} from "../../../../components/CustomPopover";
import { ConfirmDialog } from "../../../../components/CustomDialog";
import UserDriversQuickEditForm from "./OrderQuickEditForm";
import { NavLink, useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import {
  createBicciexpressOrderIssures,
  updateSateBicciexpressOrdes,
} from "../../../../redux/accionesOrders";
import { Box, OutlinedInput, Select } from "@mui/material";
import { styleState } from "../../../../utils/objects/stylesStates";
import {
  translateStatesSelectorBasic,
  translateStatesSelectorOptionesBasic,
  translateStatesfalabbela,
} from "../../../../utils/objects/translateStates";
import ValidationsSize from "../../../../utils/ValidationsSize";
import AutoUpdate from "./AutoUpdate";

//import UserDriversQuickEditForm from "./UserDriversQuickEditForm";

// ----------------------------------------------------------------------

export default function OrdersTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  visibleBilling,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userUid = useSelector((store) => store.usuario.user.uid);
  const companyId = useSelector((store) => store.usuario.user.company);
  const [selectedEstatus, setSelectedEstatus] = React.useState([]);

  const handleSelectedEstatus = (event) => {
    setSelectedEstatus(event.target.value);
  };

  const handleNavigateRows = (dataRow) => {
    console.log(dataRow);
    navigate(`/dashboard/dispatch/details/${dataRow.id}`);
  };

  const {
    id,
    int_lpn,
    int_placeName,
    driverName,
    driverLicensePlate,
    toAddress,
    userPhone,
    region,
    clientNames,
    clientEmail,
    routeId,
    int_f12,
    driverAvatar,
    int_falabella_status,
    int_bicciStatus,
    billingPaymentProforma,
    billingPaymentType_service,
    billingPaymentValor,
    isPickedUp,
    productValue,
    deliveryComment,
    updatedAt,
    createdAt,
    commune2,
    productWeight,
    productLength,
    productHeight,
    productWidth,
    productUnits,
    timeStamp,
    size,
    productSize,
    int_travelDate,
    routeEstimatedDate,
    orderID,
  } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const handleSaveState = () => {
    dispatch(updateSateBicciexpressOrdes({ id, selectedEstatus }));
    confirm.onFalse();
  };

  const handleSaveTicket = (row, userUid, type, companyId) => {
    dispatch(
      createBicciexpressOrderIssures({
        row,
        userUid,
        type,
        companyId,
      })
    );
    confirm.onFalse();
  };

  function formatDate(dateSeconds) {
    try {
      return format(dateSeconds * 1000, "dd/MM/yy hh:mm:ss").toString();
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return null;
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
          {/* <div>{formatDate(timeStamp.seconds)}</div> */}
          <ValidationsSize
            id={id}
            weight={productWeight}
            // multibulk={element.MULTIBULTO}
            length={productHeight}
            height={productLength}
            width={productWidth}
            units={productUnits}
            // volume={element.VOLUMEN}
            packeSize={size ? size : productSize}
          ></ValidationsSize>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {" "}
          <ListItemText
            primary={id}
            secondary={
              <div>
                <div>F12: {int_lpn}</div>
                <div>LPN: {int_f12}</div>
                <div>Origen: {int_placeName}</div>
                <div style={{ position: "relative" }}>
                  {productValue > 200000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "0",
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}

                  {productValue > 400000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "1em", // Establece la distancia horizontal entre las monedas
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}

                  {productValue > 900000 && (
                    <AttachMoneyIcon
                      sx={{
                        color: "#ff8f00",
                        borderColor: "1px solid #ff8f00",
                        borderRadius: "50%",
                        backgroundColor: "#feff00",
                        fontSize: "1em",
                        fontWeight: "bold",
                        width: "1.5em", // Aumenta el tamaño de la moneda
                        height: "1.5em", // Aumenta el tamaño de la moneda
                        position: "absolute",
                        top: "0",
                        left: "2em", // Establece la distancia horizontal entre las monedas
                        boxShadow: "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                      }}
                    ></AttachMoneyIcon>
                  )}
                </div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <ListItemText
            primary={clientNames}
            secondary={
              <div>
                <div>Telefono: {userPhone}</div>
                <div>Email: {clientEmail}</div>
                <div>Destino: {toAddress}</div>
                <div>{region}</div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Avatar alt={""} src={driverAvatar} sx={{ mr: 2 }} />

          <ListItemText
            primary={driverName}
            secondary={
              <div>
                <div>vehículo: {driverLicensePlate}</div>
                <div>ruta: {routeId}</div>
              </div>
            }
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: grey[900],
            }}
          />
        </TableCell>
        <TableCell>
          <Label
            variant="soft"
            color={
              (int_falabella_status === "Terminado" && "success") ||
              (int_falabella_status !== "Terminado" && "warning") ||
              "default"
            }
          >
            {int_falabella_status}
          </Label>
          <Label variant="soft" color={isPickedUp ? "success" : "error"}>
            {isPickedUp || isPickedUp == true ? "OK Picking" : "No Picking"}
          </Label>
          <div>
            <Label variant="soft" sx={{ mt: "2px" }}>
              F.Pactada: {routeEstimatedDate}
            </Label>
          </div>
          <div>
            <Label variant="soft" sx={{ mt: "2px" }}>
              F.Creado: {int_travelDate}
            </Label>
          </div>
        </TableCell>
        {visibleBilling ? (
          <TableCell sx={{ whiteSpace: "nowrap" }}>
            <ListItemText
              primary={
                <div>
                  {int_falabella_status &&
                  int_falabella_status !== "isRequire" ? (
                    <div>{int_falabella_status}</div>
                  ) : null}

                  <div>{int_bicciStatus}</div>

                  {/* {int_bicciStatus !== 8 ? (
                    <div>
                      {console.log(int_bicciStatus)}
                      {
                        <AutoUpdate
                          status={int_bicciStatus}
                          id={id}
                          orderID={orderID}
                        ></AutoUpdate>
                      }
                    </div>
                  ) : (
                    console.log("error de estatus : ", int_bicciStatus)
                  )} */}
                </div>
              }
              secondary={
                <div>
                  <div>Doc: {billingPaymentProforma}</div>
                  <div>Servicio: {billingPaymentType_service}</div>
                  <div>Monto: {billingPaymentValor}</div>
                </div>
              }
              primaryTypographyProps={{ typography: "body2" }}
              secondaryTypographyProps={{
                component: "span",
                color: grey[900],
              }}
            />
          </TableCell>
        ) : null}

        <TableCell align="right" sx={{ px: 1, whiteSpace: "nowrap" }}>
          <Tooltip title="Ver detalle" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? "inherit" : "default"}
              //onClick={quickEdit.onTrue}
              onClick={() => handleNavigateRows(row)}
            >
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>

          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <NavLink
              to={{
                pathname: `/dashboard/dispatch/details/F${id}`,
              }}
              element={row}
              color="primary"
              variant="contained"
              sx={{ backgroundColor: "#030303", width: "20px", height: "20px" }}
            >
              {" "}
              ver{" "}
            </NavLink>
          </IconButton>
        </TableCell>
      </TableRow>

      <UserDriversQuickEditForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={`TICKET # ${id}`}
        content={
          <div>
            <div>
              <strong>Conductor: {driverName} </strong>
              <div>
                <strong>Estado de la Orden:</strong>{" "}
                <span className={styleState[int_falabella_status]}>
                  {translateStatesfalabbela[int_falabella_status]}
                </span>
              </div>
              <div>
                <strong>Comentario de Estado:</strong>{" "}
                <span>
                  {deliveryComment &&
                  deliveryComment !== "" &&
                  deliveryComment !== "isRequire" ? (
                    <div>{deliveryComment}</div>
                  ) : null}
                </span>
              </div>
              <div>COD: {id}</div>
              <ListItemText
                primary={`Cliente: ${clientNames}`}
                secondary={
                  <div>
                    <div>Teléfono: {userPhone}</div>
                    <div>Destino: {toAddress}</div>
                    <div>{commune2}</div>
                  </div>
                }
                primaryTypographyProps={{ typography: "body2" }}
                secondaryTypographyProps={{
                  component: "span",
                  color: grey[900],
                }}
              />
            </div>
            <div>
              <strong>ACTUALIZACIONES DE LA ORDEN</strong>
            </div>
            <div>#</div>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => handleSaveState()}
              >
                Agregar Comentario
              </Button>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Select
                value={selectedEstatus}
                onChange={handleSelectedEstatus}
                input={<OutlinedInput label="Estado" />}
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: 240 },
                  },
                }}
                renderValue={(selected) => `Estatus: ${selected}`}
                sx={{ width: "240px" }}
              >
                {translateStatesSelectorOptionesBasic.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="IMPORTANTE: No son estados de integraciones">
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={() => handleSaveState()}
                >
                  Guardar estado para BICCIEXPRESS
                </Button>
              </Tooltip>
            </Box>
          </div>
        }
        action={
          <div>
            <Button
              sx={{ marginLeft: "3px", marginRight: "3px" }}
              variant="contained"
              color="success"
              onClick={() =>
                handleSaveTicket(row, userUid, "supportTicket", companyId)
              }
            >
              Crear Nuevo Seguimiento
            </Button>
          </div>
        }
      />
    </>
  );
}

OrdersTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  visibleBilling: PropTypes.bool,
  companyId: PropTypes.string,
};
