import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const ReusableFormImportOpcionalElements = ({
  open,
  handleClose,
  handleSave,
  formFieldsEdit,
}) => {
  const [openDropzone, setOpenDropzone] = useState(false);
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
    setOpenDropzone(false);
  };

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          // Verifica si el campo es obligatorio y si falta o está vacío.
          if (
            field.required &&
            (!item.hasOwnProperty(field.variable) ||
              item[field.variable] === "")
          ) {
            setErroMensaje(
              `Falta un valor para la columna obligatoria ${field.variable} o está vacío en uno de los registros.`
            );
            return false;
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      console.log("Todos los datos son válidos.");
      console.log(jsonData);
      handleSave(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Import document</DialogTitle>
      <DialogContent>
        <Button onClick={() => setOpenDropzone(true)} color="primary">
          Upload File
        </Button>
        {openDropzone && (
          <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
            <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
              <input {...getInputProps()} />
              <Typography>
                Drag 'n' drop some files here, or click to select files
              </Typography>
            </Box>
          </Paper>
        )}
        {file && <p>File ready to be saved: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOpcionalElements;
