import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { Box, Container, Card, CardHeader, Divider } from "@mui/material";

const Postulaciones = () => {
  return (
    <div className="bodypost">
      <section className="pattern" id="toppattern">
        <section className="pattern2">
          <div className="goostyle">
            <Grid>
              <Grid.Row>
                <Grid.Column computer={6} mobile={16} tablet={6}>
                  <div className="toptitlecontainer">
                    <h2 className="titleflota">
                      ¿TE QUIERES UNIR A NUESTRO EQUIPO?
                    </h2>
                    <h5>Postula como BICCI-RIDER</h5>
                    {/* <NavLink className="btn btn-ebiex-dark" to="/#optionsfleet">
                      {" "}
                      ¡Inscríbite ahora!
                    </NavLink> */}
                  </div>
                </Grid.Column>
                <Grid.Column computer={10} mobile={16} tablet={10}>
                  <img
                    className="imgLogoFlota"
                    src="/static/images/asset/004_1.png"
                    alt=""
                  ></img>{" "}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </section>
      </section>
      <div className="bodypostcontentsecion" id="optionsfleet">
        <Container sx={{ width: "950px" }}>
          <div className="bodypostsecion ">
            <Grid>
              <Grid.Row>
                <Grid.Column computer={4} mobile={16} tablet={4}></Grid.Column>
                <Grid.Column
                  computer={4}
                  mobile={16}
                  tablet={4}
                  className="stylestypeMiniformsRider"
                >
                  <Card className="typeMiniformsRider">
                    <CardHeader
                      subheader={
                        <>
                          <div className="typeMiniformsRidertext1">
                            Lleva productos desde supermercado y tiendas al
                            cliente final
                          </div>
                        </>
                      }
                      title={
                        <>
                          <div>
                            <div className="typeMiniformsRidertitle1">
                              RIDER
                            </div>
                            <div className="typeMiniformsRidertitle2">
                              LAST MILLE
                            </div>
                          </div>
                        </>
                      }
                    />

                    <Divider />
                    <Container className="" id="">
                      <img
                        width={150}
                        src="/static/images/asset/entrega-01.svg"
                        alt=""
                      />
                    </Container>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    >
                      <div className="cardUploaps">
                        <div>
                          <NavLink
                            to={"/flota/last-mille"}
                            className="btn btn-ebiex m-1"
                          >
                            {" "}
                            POSTULATE
                          </NavLink>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid.Column>
                <Grid.Column
                  computer={4}
                  mobile={16}
                  tablet={4}
                  className="stylestypeMiniformsRider"
                >
                  <Card className="typeMiniformsRider">
                    <CardHeader
                      subheader={
                        <>
                          <div className="typeMiniformsRidertext1">
                            Lleva paquetes desde bodegas o tiendas retail al
                            cliente final
                          </div>
                        </>
                      }
                      title={
                        <>
                          <div>
                            <div className="typeMiniformsRidertitle1">
                              RIDER
                            </div>
                            <div className="typeMiniformsRidertitle2">
                              RETAIL
                            </div>
                          </div>
                        </>
                      }
                    />

                    <Divider />
                    <Container className="" id="">
                      <img
                        width={150}
                        src="/static/images/asset/entrega-02.svg"
                        alt=""
                      />
                    </Container>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    >
                      <div className="cardUploaps">
                        <div>
                          <NavLink
                            to={"/flota/retail"}
                            className="btn btn-ebiex m-1"
                          >
                            {" "}
                            POSTULATE
                          </NavLink>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid.Column>
                <Grid.Column computer={4} mobile={16} tablet={4}></Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
        {/* <div className="bodypostsecion ">Postulaciones</div> */}
      </div>
      {/* <div className="bodypostcontentsecion">
        <div className="bodypostsecion">Postulaciones</div>
      </div>
      <div className="bodypostcontentsecion">
        <div className="bodypostsecion">Postulaciones</div>
      </div> */}
    </div>
  );
};

export default Postulaciones;
