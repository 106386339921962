import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  GeoPoint,
} from "firebase/firestore";
import getMatrixDistanceBicci from "../utils/getMatrixDistanceBicci";

const dataInicial = {
  source: null,
};

let agroupLines = {};

// types
const REQ_DOCUMENT_ROUTE = "REQ_DOCUMENT_ROUTE";
const LOAD_DOCUMENT_ROUTE = "OBTENER_DOCUMENT_ROUTE";
const LOAD_DOCUMENT_ROUTE_EXITO = "OBTENER_DOCUMENT_ROUTE_EXITO";
const SAVE_NEW_DOCUMENT_ROUTE_EXITO = "SAVE_NEW_DOCUMENT_ROUTE_EXITO";
const UPDATE_DOCUMENT_ROUTE_EXITO = "UPDATE_DOCUMENT_ROUTE_EXITO";
const REQ_MATRIXDISTANCE_ROUTE = "OBTENER_MATRIXDISTANCE_ROUTE";
const REQ_MATRIXDISTANCE_ROUTE_EXITO = "OBTENER_MATRIXDISTANCE_ROUTE_EXITO";
const UPDATE_VIEW_ROUTE_EXITO = "UPDATE_VIEW_ROUTE_EXITO";
const LOAD_DOCUMENT_ONFIG_ROUTE_EXITO = "LOAD_DOCUMENT_ONFIG_ROUTE_EXITO";

// reducer
export default function routesLiveAccionsReducer(state = dataInicial, action) {
  switch (action.type) {
    case REQ_DOCUMENT_ROUTE:
      return { ...state, ...action.payload };
    case LOAD_DOCUMENT_ROUTE:
      return { ...state, source: "remote", ...action.payload };
    case LOAD_DOCUMENT_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    case SAVE_NEW_DOCUMENT_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    case UPDATE_DOCUMENT_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    case REQ_MATRIXDISTANCE_ROUTE:
      return { ...state, source: "remote", ...action.payload };
    case REQ_MATRIXDISTANCE_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    case UPDATE_VIEW_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    case LOAD_DOCUMENT_ONFIG_ROUTE_EXITO:
      return { ...state, source: "remote", ...action.payload };
    default:
      return state;
  }
}

// acciones
export const getDataDispachstGroupByDriverId =
  (driverID, type, origin, destination) => async (dispatch) => {
    const findRiderOrders = async (idRider, fecha) => {
      const i = new Date(fecha);

      function lastTimer(fecha) {
        var res = new Date(fecha);

        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      function iniTimer(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangFinal = lastTimer(i);
      var rangInitial = iniTimer(i);
      const rangInitialDate = new Date(rangInitial);
      const rangFinalDate = new Date(rangFinal);

      const driveOrdersCollectionRef = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );
      const q = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("status", ">", 2),
        where("status", "<=", 8) // desarrollo => where("status", "<=", 8)
      );
      const querySnapshot = await getDocs(q);
      const dataOrdesBiccifilter = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const dataOrdesBicci = dataOrdesBiccifilter.filter((item) => {
        const createdAtDate = new Date(
          item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000
        );
        return (
          createdAtDate > rangInitialDate && createdAtDate <= rangFinalDate
        );
      });

      const qu = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("status", ">", 2),
        where("status", "<=", 8) // desarrollo => where("status", "<=", 8)
      );
      const querySnapshotPending = await getDocs(qu);
      const querySnapshotPendingFilterday = querySnapshotPending.docs.map(
        (doc) => ({
          id: doc.id,
          ...doc.data(),
        })
      );

      const dataOrdesBicciPendint = querySnapshotPendingFilterday.filter(
        (item) => {
          const createdAtDate = new Date(
            item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000
          );
          return (
            createdAtDate > rangInitialDate && createdAtDate <= rangFinalDate
          );
        }
      );

      let dataOrderdata = [];
      const dataOrder = {
        data: "",
        get dataEbiex() {
          return this.data;
        },
        set dataEbiex(data) {
          this.data = data;
        },
      };

      const clients = [
        {
          key: "F",
          collectionData: process.env.REACT_APP_COL_FALABELLA_ORDERS,
        },
        {
          key: "BICCI",
          collectionData: process.env.REACT_APP_COL_USERS_ORDERS,
        },
        // {
        //   caracterer: "S",
        //   collectionData: process.env.REACT_APP_COL_SENDU_ORDERS,
        // },
        // {
        //   caracterer: "E",
        //   collectionData: process.env.REACT_APP_COL_ENVIAME_ORDERS,
        // },
      ];

      const caracteres = [...new Set(clients.map((client) => client.key))];

      // const nombresColecciones = [
      //   ...new Set(clients.map((client) => client.collectionData)),
      // ];

      const gruposIDs = {};

      caracteres.forEach((caracter) => {
        gruposIDs[caracter] = [];
      });

      for (let n = 0; n < dataOrdesBicciPendint.length; n++) {
        const id = dataOrdesBicciPendint[n].id;
        const caracterEnID = caracteres.find((caracter) =>
          id.includes(caracter)
        );

        if (caracterEnID) {
          gruposIDs[caracterEnID].push(id);
        } else {
          gruposIDs["BICCI"].push(id);
        }
      }
      // for (let n = 0; n < dataOrdesBicci.length; n++) {
      //   const id = dataOrdesBicci[n].id;
      //   const caracterEnID = caracteres.find((caracter) =>
      //     id.includes(caracter)
      //   );

      //   if (caracterEnID) {
      //     gruposIDs[caracterEnID].push(id);
      //   } else {
      //     gruposIDs["BICCI"].push(id);
      //   }
      // }

      for (const grupo in gruposIDs) {
        try {
          let elementosConsultados = await consultarElementosPorIds(
            grupo,
            gruposIDs[grupo]
          );
          dataOrderdata = dataOrderdata.concat(elementosConsultados); // Concatenamos los elementos consultados a dataOrderdata
        } catch (error) {
          console.error("Error al consultar elementos:", error);
        }
      }

      async function consultarElementosPorIds(grupo, ids) {
        let includeInList = [];
        if (grupo !== "BICCI") {
          for (let n = 0; n < ids.length; n++) {
            const idSinF = ids[n].replace(grupo, "");
            includeInList.push(idSinF);
          }
        } else {
          includeInList = ids;
        }

        const nombresColeccionCliente = clients.find(
          (client) => client.key === grupo
        );

        try {
          const elementosConsultados = [];

          const nombreColeccion = nombresColeccionCliente.collectionData;
          const ids = includeInList; //= i === 0 ? includeInList : includeInList;

          let nombreColeccionRef = collection(db, nombreColeccion);

          await Promise.all(
            ids.map(async (id) => {
              const docRef = doc(nombreColeccionRef, id);
              const docSnapshot = await getDoc(docRef);
              if (docSnapshot.exists()) {
                elementosConsultados.push(docSnapshot.data());
              } else {
              }
            })
          );

          return elementosConsultados;
        } catch (error) {
          console.error("Error al consultar elementos:", error);
        }
      }

      try {
        var baseBicciData = dataOrdesBicci;

        async function initMerge(newdataMere) {
          const uniqueMap = new Map();

          async function removeDuplicatesById(array) {
            for (const item of array) {
              if (!uniqueMap.has(item.id)) {
                uniqueMap.set(item.id, item);
              }
            }

            return Array.from(uniqueMap.values());
          }

          var DataMerge = await removeDuplicatesById(baseBicciData);
          var requestWaitPonint = [];
          var currentOrderList = [];

          for (let i = 0; i < DataMerge.length; i++) {
            for (let n = 0; n < newdataMere.length; n++) {
              let socf = "F" + newdataMere[n].id;

              if (socf === DataMerge[i].id) {
                currentOrderList.push(DataMerge[i].id);
                DataMerge[i].address = newdataMere[n].address;
                DataMerge[i].agreed_date = newdataMere[n].agreed_date;
                DataMerge[i].commune1 = newdataMere[n].commune1;
                DataMerge[i].commune2 = newdataMere[n].commune2;
                DataMerge[i].createdAt = newdataMere[n].createdAt;
                DataMerge[i].customer_email = newdataMere[n].customer_email;
                DataMerge[i].customer_name = newdataMere[n].customer_name;
                DataMerge[i].customer_phone = newdataMere[n].customer_phone;
                DataMerge[i].date = newdataMere[n].date;
                DataMerge[i].do = newdataMere[n].do;
                DataMerge[i].falabella_id = newdataMere[n].falabella_id;
                DataMerge[i].falabella_status = newdataMere[n].falabella_status;
                DataMerge[i].height = newdataMere[n].height;
                DataMerge[i].length = newdataMere[n].length;
                DataMerge[i].local_provider = newdataMere[n].local_provider;
                DataMerge[i].lpn = newdataMere[n].lpn;
                DataMerge[i].max_delivery_date =
                  newdataMere[n].max_delivery_date;
                DataMerge[i].number_trip = newdataMere[n].number_trip;
                DataMerge[i].price = newdataMere[n].price;
                DataMerge[i].quantity = newdataMere[n].quantity;
                DataMerge[i].quantity_packages =
                  newdataMere[n].quantity_packages;
                DataMerge[i].region = newdataMere[n].region;
                DataMerge[i].schedule_range = newdataMere[n].schedule_range;
                DataMerge[i].shipper_id = newdataMere[n].shipper_id;
                DataMerge[i].size = newdataMere[n].size;
                DataMerge[i].soc = newdataMere[n].soc;
                DataMerge[i].status = newdataMere[n].status;
                DataMerge[i].updatedAt = newdataMere[n].updatedAt;
                DataMerge[i].weight = newdataMere[n].weight;
                DataMerge[i].width = newdataMere[n].width;
              }
            }
          }

          let odrdesDelivery = DataMerge.filter(
            (item) => item.falabella_status === "99"
          );

          let SinExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1058" ||
              item.falabella_status === "1059" ||
              item.falabella_status === "1060" ||
              item.falabella_status === "1061" || // eliminar de 99 al 1046 solo desarrollo 13/08/2023
              item.falabella_status === "99"
            // item.falabella_status === "1031" ||
            // item.falabella_status === "1030" ||
            // item.falabella_status === "1032" ||
            // item.falabella_status === "1034" ||
            // item.falabella_status === "1036" ||
            // item.falabella_status === "1041" ||
            // item.falabella_status === "1031" ||
            // item.falabella_status === "1081" ||
            // item.falabella_status === "1037" ||
            // item.falabella_status === "1038" ||
            // item.falabella_status === "1029" ||
            // item.falabella_status === "1046" ||
            // item.falabella_status === "1081" ||
            // item.falabella_status === "1023" ||
            // item.falabella_status === "1" ||
            // item.falabella_status === "1002" ||
            // item.falabella_status === "1005" ||
            // item.falabella_status === "1006" ||
            // item.falabella_status === "1007" ||
            // item.falabella_status === "1008" ||
            // item.falabella_status === "1009" ||
            // item.falabella_status === "1017" ||
            // item.falabella_status === "1019" ||
            // item.falabella_status === "1022" ||
            // item.falabella_status === "1023" ||
            // item.falabella_status === "1027" ||
            // item.falabella_status === "1046"
          );

          let filtrarExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1031" ||
              item.falabella_status === "1030" ||
              item.falabella_status === "1032" ||
              item.falabella_status === "1034" ||
              item.falabella_status === "1036" ||
              item.falabella_status === "1041" ||
              item.falabella_status === "1031" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1037" ||
              item.falabella_status === "1038" ||
              item.falabella_status === "1029" ||
              item.falabella_status === "1046" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1" ||
              item.falabella_status === "1002" ||
              item.falabella_status === "1005" ||
              item.falabella_status === "1006" ||
              item.falabella_status === "1007" ||
              item.falabella_status === "1008" ||
              item.falabella_status === "1009" ||
              item.falabella_status === "1017" ||
              item.falabella_status === "1019" ||
              item.falabella_status === "1022" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1027" ||
              item.falabella_status === "1046"
          );

          let objRequestOrigin = {
            id: "origin",
            geoRef: {
              latitud: origin.lat,
              longitud: origin.lng,
            },
          };

          let objRequestDestination = {
            id: "destination",
            geoRef: {
              latitud: destination.lat,
              longitud: destination.lng,
            },
          };

          for (let i = 0; i < SinExcepciones.length; i++) {
            let objRequest = {
              id: SinExcepciones[i].id,
              geoRef: {
                latitud: SinExcepciones[i].toAddressLocation.latitude,
                longitud: SinExcepciones[i].toAddressLocation.longitude,
              },
            };
            requestWaitPonint.push(objRequest);
          }

          let responseMatrixDistances = await getMatrixDistanceBicci(
            requestWaitPonint,
            objRequestOrigin,
            objRequestDestination
          );

          if (
            responseMatrixDistances.pointOrdersData &&
            SinExcepciones &&
            responseMatrixDistances.pointOrdersData[0].hasOwnProperty(
              "position"
            )
          ) {
            for (
              let n = 0;
              n < responseMatrixDistances.pointOrdersData.length;
              n++
            ) {
              let socf = responseMatrixDistances.pointOrdersData[n].id;
              for (let i = 0; i < SinExcepciones.length; i++) {
                if (socf === SinExcepciones[i].id) {
                  SinExcepciones[i].position =
                    responseMatrixDistances.pointOrdersData[n].position;
                  break;
                }
              }
            }

            SinExcepciones.sort((a, b) => a.position - b.position);

            const groupsOrderedStops = [];
            const tamanoGrupo = 23;
            let indiceInicio = 0;

            while (indiceInicio < SinExcepciones.length) {
              const routeSeccion = SinExcepciones.slice(
                indiceInicio,
                indiceInicio + tamanoGrupo
              );
              groupsOrderedStops.push(routeSeccion);
              indiceInicio += tamanoGrupo;
            }

            let arrayCurrentListGroups = [];
            let waypointsContGroups = [];
            for (let i = 0; i < groupsOrderedStops.length; i++) {
              let waypointsCont = [];
              let arrayCurrentList = [];
              let dataForRoute = groupsOrderedStops[i];
              dataForRoute.sort((a, b) => a.position - b.position);
              for (let n = 0; n < dataForRoute.length; n++) {
                const wayPoint = {
                  stopover: true,
                  location: {
                    lat: dataForRoute[n].toAddressLocation.latitude,
                    lng: dataForRoute[n].toAddressLocation.longitude,
                  },
                };
                arrayCurrentList.push(dataForRoute[n].id);
                waypointsCont.push(wayPoint);
              }

              arrayCurrentListGroups.push(arrayCurrentList);
              waypointsContGroups.push(waypointsCont);
            }
            function espera() {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 2000);
              });
            }

            await espera();

            dispatch({
              type: REQ_MATRIXDISTANCE_ROUTE,
              payload: {
                dataOrdesBicciByRider: SinExcepciones,
                dataOrdersExcepcions: filtrarExcepciones,
                isPointGroups: responseMatrixDistances.isPointGroups,
                dataOrderDeliverySusses: odrdesDelivery,
                dataOrdesBicciByRiderGroups: groupsOrderedStops,
                dataOrdesForRouteGoogleServicesRoutes: waypointsContGroups,
                dataOrdesForRouteIds: arrayCurrentListGroups,
                currentOrderList: currentOrderList,
              },
            });
          } else {
          }
        }

        await initMerge(dataOrderdata);
      } catch (error) {}

      //vieja Logica
    };

    if (driverID && type && origin && destination) {
      let ultimosSeis = type.slice(-6);

      let year = "20" + ultimosSeis.slice(0, 2); // Asume que el año es 2000+
      let month = ultimosSeis.slice(2, 4) - 1; // Los meses en JavaScript van de 0 a 11
      let day = ultimosSeis.slice(4, 6);

      let fecha = new Date(year, month, day);
      function esFechaValida(date) {
        return date instanceof Date && !isNaN(date);
      }

      if (esFechaValida(fecha)) {
        findRiderOrders(driverID, fecha);
      } else {
      }
    }
  };

export const getDataDispachstGroupByDriverIdToReports =
  (driverID, type, origin, destination) => async (dispatch) => {
    const findRiderOrders = async (idRider, fecha) => {
      const i = new Date(fecha);

      function lastTimer(fecha) {
        var res = new Date(fecha);

        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      function iniTimer(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangFinal = lastTimer(i);
      var rangInitial = iniTimer(i);

      const driveOrdersCollectionRef = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      const q = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("createdAt", ">", rangInitial),
        where("createdAt", "<=", rangFinal)
        // where("status", ">", 2),
        // where("status", "<=", 8) // desarrollo => where("status", "<=", 8)
      );

      const querySnapshot = await getDocs(q);

      const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const qu = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("createdAt", ">=", rangInitial),
        where("createdAt", "<=", rangFinal)
      );

      const querySnapshotPending = await getDocs(qu);

      const dataOrdesBicciPendint = querySnapshotPending.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let dataOrderdata = [];

      const clients = [
        {
          key: "F",
          collectionData: process.env.REACT_APP_COL_FALABELLA_ORDERS,
        },
        {
          key: "BICCI",
          collectionData: process.env.REACT_APP_COL_USERS_ORDERS,
        },
        // {
        //   caracterer: "S",
        //   collectionData: process.env.REACT_APP_COL_SENDU_ORDERS,
        // },
        // {
        //   caracterer: "E",
        //   collectionData: process.env.REACT_APP_COL_ENVIAME_ORDERS,
        // },
      ];

      const caracteres = [...new Set(clients.map((client) => client.key))];

      // const nombresColecciones = [
      //   ...new Set(clients.map((client) => client.collectionData)),
      // ];

      const gruposIDs = {};

      caracteres.forEach((caracter) => {
        gruposIDs[caracter] = [];
      });

      for (let n = 0; n < dataOrdesBicciPendint.length; n++) {
        const id = dataOrdesBicciPendint[n].id;
        const caracterEnID = caracteres.find((caracter) =>
          id.includes(caracter)
        );

        if (caracterEnID) {
          gruposIDs[caracterEnID].push(id);
        } else {
          gruposIDs["BICCI"].push(id);
        }
      }

      for (const grupo in gruposIDs) {
        try {
          let elementosConsultados = await consultarElementosPorIds(
            grupo,
            gruposIDs[grupo]
          );
          dataOrderdata = dataOrderdata.concat(elementosConsultados); // Concatenamos los elementos consultados a dataOrderdata
        } catch (error) {
          console.error("Error al consultar elementos:", error);
        }
      }

      async function consultarElementosPorIds(grupo, ids) {
        let includeInList = [];
        if (grupo !== "BICCI") {
          for (let n = 0; n < ids.length; n++) {
            const idSinF = ids[n].replace(grupo, "");
            includeInList.push(idSinF);
          }
        } else {
          includeInList = ids;
        }

        const nombresColeccionCliente = clients.find(
          (client) => client.key === grupo
        );

        try {
          const elementosConsultados = [];

          const nombreColeccion = nombresColeccionCliente.collectionData;
          const ids = includeInList; //= i === 0 ? includeInList : includeInList;

          let nombreColeccionRef = collection(db, nombreColeccion);

          await Promise.all(
            ids.map(async (id) => {
              const docRef = doc(nombreColeccionRef, id);
              const docSnapshot = await getDoc(docRef);
              if (docSnapshot.exists()) {
                elementosConsultados.push(docSnapshot.data());
              } else {
              }
            })
          );
          //  }

          return elementosConsultados;
        } catch (error) {
          console.error("Error al consultar elementos:", error);
        }
      }

      try {
        var baseBicciData = dataOrdesBicci;

        async function initMerge(newdataMere) {
          const uniqueMap = new Map();

          async function removeDuplicatesById(array) {
            for (const item of array) {
              if (!uniqueMap.has(item.id)) {
                uniqueMap.set(item.id, item);
              }
            }

            return Array.from(uniqueMap.values());
          }

          var DataMerge = await removeDuplicatesById(baseBicciData);
          var requestWaitPonint = [];
          var currentOrderList = [];

          for (let i = 0; i < DataMerge.length; i++) {
            for (let n = 0; n < newdataMere.length; n++) {
              let socf = "F" + newdataMere[n].id;

              if (socf === DataMerge[i].id) {
                currentOrderList.push(DataMerge[i].id);
                DataMerge[i].address = newdataMere[n].address;
                DataMerge[i].agreed_date = newdataMere[n].agreed_date;
                DataMerge[i].commune1 = newdataMere[n].commune1;
                DataMerge[i].commune2 = newdataMere[n].commune2;
                DataMerge[i].createdAt = newdataMere[n].createdAt;
                DataMerge[i].customer_email = newdataMere[n].customer_email;
                DataMerge[i].customer_name = newdataMere[n].customer_name;
                DataMerge[i].customer_phone = newdataMere[n].customer_phone;
                DataMerge[i].date = newdataMere[n].date;
                DataMerge[i].do = newdataMere[n].do;
                DataMerge[i].falabella_id = newdataMere[n].falabella_id;
                DataMerge[i].falabella_status = newdataMere[n].falabella_status;
                DataMerge[i].height = newdataMere[n].height;
                DataMerge[i].length = newdataMere[n].length;
                DataMerge[i].local_provider = newdataMere[n].local_provider;
                DataMerge[i].lpn = newdataMere[n].lpn;
                DataMerge[i].max_delivery_date =
                  newdataMere[n].max_delivery_date;
                DataMerge[i].number_trip = newdataMere[n].number_trip;
                DataMerge[i].price = newdataMere[n].price;
                DataMerge[i].quantity = newdataMere[n].quantity;
                DataMerge[i].quantity_packages =
                  newdataMere[n].quantity_packages;
                DataMerge[i].region = newdataMere[n].region;
                DataMerge[i].schedule_range = newdataMere[n].schedule_range;
                DataMerge[i].shipper_id = newdataMere[n].shipper_id;
                DataMerge[i].size = newdataMere[n].size;
                DataMerge[i].soc = newdataMere[n].soc;
                DataMerge[i].status = newdataMere[n].status;
                DataMerge[i].updatedAt = newdataMere[n].updatedAt;
                DataMerge[i].weight = newdataMere[n].weight;
                DataMerge[i].width = newdataMere[n].width;
              }
            }
          }

          let odrdesDelivery = DataMerge.filter(
            (item) => item.falabella_status === "99"
          );

          let SinExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1058" ||
              item.falabella_status === "1059" ||
              item.falabella_status === "1060" ||
              item.falabella_status === "1061" || // eliminar de 1061 al 1046 solo desarrollo
              item.falabella_status === "99" ||
              item.falabella_status === "1031" ||
              item.falabella_status === "1030" ||
              item.falabella_status === "1032" ||
              item.falabella_status === "1034" ||
              item.falabella_status === "1036" ||
              item.falabella_status === "1041" ||
              item.falabella_status === "1031" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1037" ||
              item.falabella_status === "1038" ||
              item.falabella_status === "1029" ||
              item.falabella_status === "1046" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1" ||
              item.falabella_status === "1002" ||
              item.falabella_status === "1005" ||
              item.falabella_status === "1006" ||
              item.falabella_status === "1007" ||
              item.falabella_status === "1008" ||
              item.falabella_status === "1009" ||
              item.falabella_status === "1017" ||
              item.falabella_status === "1019" ||
              item.falabella_status === "1022" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1027" ||
              item.falabella_status === "1046"
          );

          let filtrarExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1031" ||
              item.falabella_status === "1030" ||
              item.falabella_status === "1032" ||
              item.falabella_status === "1034" ||
              item.falabella_status === "1036" ||
              item.falabella_status === "1041" ||
              item.falabella_status === "1031" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1037" ||
              item.falabella_status === "1038" ||
              item.falabella_status === "1029" ||
              item.falabella_status === "1046" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1" ||
              item.falabella_status === "1002" ||
              item.falabella_status === "1005" ||
              item.falabella_status === "1006" ||
              item.falabella_status === "1007" ||
              item.falabella_status === "1008" ||
              item.falabella_status === "1009" ||
              item.falabella_status === "1017" ||
              item.falabella_status === "1019" ||
              item.falabella_status === "1022" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1027" ||
              item.falabella_status === "1046"
          );

          let objRequestOrigin = {
            id: "origin",
            geoRef: {
              latitud: origin.lat,
              longitud: origin.lng,
            },
          };

          let objRequestDestination = {
            id: "destination",
            geoRef: {
              latitud: destination.lat,
              longitud: destination.lng,
            },
          };

          for (let i = 0; i < SinExcepciones.length; i++) {
            let objRequest = {
              id: SinExcepciones[i].id,
              geoRef: {
                latitud: SinExcepciones[i].toAddressLocation.latitude,
                longitud: SinExcepciones[i].toAddressLocation.longitude,
              },
            };
            requestWaitPonint.push(objRequest);
          }

          let responseMatrixDistances = await getMatrixDistanceBicci(
            requestWaitPonint,
            objRequestOrigin,
            objRequestDestination
          );

          if (
            responseMatrixDistances.pointOrdersData &&
            SinExcepciones &&
            responseMatrixDistances.pointOrdersData[0].hasOwnProperty(
              "position"
            )
          ) {
            for (
              let n = 0;
              n < responseMatrixDistances.pointOrdersData.length;
              n++
            ) {
              let socf = responseMatrixDistances.pointOrdersData[n].id;
              for (let i = 0; i < SinExcepciones.length; i++) {
                if (socf === SinExcepciones[i].id) {
                  SinExcepciones[i].position =
                    responseMatrixDistances.pointOrdersData[n].position;
                  break;
                }
              }
            }

            SinExcepciones.sort((a, b) => a.position - b.position);

            const groupsOrderedStops = [];
            const tamanoGrupo = 23;
            let indiceInicio = 0;

            while (indiceInicio < SinExcepciones.length) {
              const routeSeccion = SinExcepciones.slice(
                indiceInicio,
                indiceInicio + tamanoGrupo
              );
              groupsOrderedStops.push(routeSeccion);
              indiceInicio += tamanoGrupo;
            }

            let arrayCurrentListGroups = [];
            let waypointsContGroups = [];
            for (let i = 0; i < groupsOrderedStops.length; i++) {
              let waypointsCont = [];
              let arrayCurrentList = [];
              let dataForRoute = groupsOrderedStops[i];
              dataForRoute.sort((a, b) => a.position - b.position);
              for (let n = 0; n < dataForRoute.length; n++) {
                const wayPoint = {
                  stopover: true,
                  location: {
                    lat: dataForRoute[n].toAddressLocation.latitude,
                    lng: dataForRoute[n].toAddressLocation.longitude,
                  },
                };
                arrayCurrentList.push(dataForRoute[n].id);
                waypointsCont.push(wayPoint);
              }

              arrayCurrentListGroups.push(arrayCurrentList);
              waypointsContGroups.push(waypointsCont);
            }
            function espera() {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 2000);
              });
            }

            await espera();

            dispatch({
              type: REQ_MATRIXDISTANCE_ROUTE,
              payload: {
                dataOrdesBicciByRider: SinExcepciones,
                dataOrdersExcepcions: filtrarExcepciones,
                isPointGroups: responseMatrixDistances.isPointGroups,
                dataOrderDeliverySusses: odrdesDelivery,
                dataOrdesBicciByRiderGroups: groupsOrderedStops,
                dataOrdesForRouteGoogleServicesRoutes: waypointsContGroups,
                dataOrdesForRouteIds: arrayCurrentListGroups,
                currentOrderList: currentOrderList,
              },
            });
          } else {
          }
        }

        await initMerge(dataOrderdata);
      } catch (error) {}

      //vieja Logica
    };

    if (driverID && type && origin && destination) {
      let ultimosSeis = type.slice(-6);

      let year = "20" + ultimosSeis.slice(0, 2); // Asume que el año es 2000+
      let month = ultimosSeis.slice(2, 4) - 1; // Los meses en JavaScript van de 0 a 11
      let day = ultimosSeis.slice(4, 6);

      let fecha = new Date(year, month, day);
      function esFechaValida(date) {
        return date instanceof Date && !isNaN(date);
      }

      if (esFechaValida(fecha)) {
        findRiderOrders(driverID, fecha);
      } else {
      }
    }
  };

export const getDataDispachstGroupByDriverIdToManifes =
  (driverID, type, origin, destination) => async (dispatch) => {
    const findRiderOrders = async (idRider, fecha) => {
      const i = new Date(fecha);

      function lastTimer(fecha) {
        var res = new Date(fecha);

        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      function iniTimer(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangFinal = lastTimer(i);
      var rangInitial = iniTimer(i);

      const driveOrdersCollectionRef = collection(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS
      );

      const q = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("status", ">", 0),
        where("status", "<", 8) // desarrollo => where("status", "<=", 8)
        // where("status", ">", 2),
        // where("status", "<=", 8) // desarrollo => where("status", "<=", 8)
      );

      const querySnapshot = await getDocs(q);

      const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const qu = query(
        driveOrdersCollectionRef,
        where("driverID", "==", idRider),
        where("createdAt", ">=", rangInitial),
        where("createdAt", "<=", rangFinal)
      );

      const querySnapshotPending = await getDocs(qu);

      const dataOrdesBicciPendint = querySnapshotPending.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const uniqueMap = new Map();
      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        }

        return Array.from(uniqueMap.values());
      }

      let dataOrderdata = [];

      const clients = [
        {
          key: "F",
          collectionData: process.env.REACT_APP_COL_FALABELLA_ORDERS,
        },
        {
          key: "BICCI",
          collectionData: process.env.REACT_APP_COL_USERS_ORDERS,
        },
        // {
        //   caracterer: "S",
        //   collectionData: process.env.REACT_APP_COL_SENDU_ORDERS,
        // },
        // {
        //   caracterer: "E",
        //   collectionData: process.env.REACT_APP_COL_ENVIAME_ORDERS,
        // },
      ];

      const caracteres = [...new Set(clients.map((client) => client.key))];

      // const nombresColecciones = [
      //   ...new Set(clients.map((client) => client.collectionData)),
      // ];
      let totaldataOrdesBicci = dataOrdesBicci.concat(dataOrdesBicciPendint);

      var allDataForMerge = await removeDuplicatesById(totaldataOrdesBicci);

      const gruposIDs = {};

      caracteres.forEach((caracter) => {
        gruposIDs[caracter] = [];
      });

      for (let n = 0; n < allDataForMerge.length; n++) {
        const id = allDataForMerge[n].id;
        const caracterEnID = caracteres.find((caracter) =>
          id.includes(caracter)
        );

        if (caracterEnID) {
          gruposIDs[caracterEnID].push(id);
        } else {
          gruposIDs["BICCI"].push(id);
        }
      }

      for (const grupo in gruposIDs) {
        try {
          let elementosConsultados = await consultarElementosPorIds(
            grupo,
            gruposIDs[grupo]
          );
          dataOrderdata = dataOrderdata.concat(elementosConsultados); // Concatenamos los elementos consultados a dataOrderdata
        } catch (error) {}
      }

      async function consultarElementosPorIds(grupo, ids) {
        let includeInList = [];
        if (grupo !== "BICCI") {
          for (let n = 0; n < ids.length; n++) {
            const idSinF = ids[n].replace(grupo, "");
            includeInList.push(idSinF);
          }
        } else {
          includeInList = ids;
        }

        const nombresColeccionCliente = clients.find(
          (client) => client.key === grupo
        );

        try {
          const elementosConsultados = [];

          const nombreColeccion = nombresColeccionCliente.collectionData;
          const ids = includeInList; //= i === 0 ? includeInList : includeInList;

          let nombreColeccionRef = collection(db, nombreColeccion);

          await Promise.all(
            ids.map(async (id) => {
              const docRef = doc(nombreColeccionRef, id);
              const docSnapshot = await getDoc(docRef);
              if (docSnapshot.exists()) {
                elementosConsultados.push(docSnapshot.data());
              } else {
              }
            })
          );

          return elementosConsultados;
        } catch (error) {
          console.error("Error al consultar elementos:", error);
        }
      }

      try {
        var baseBicciData = allDataForMerge;

        async function initMerge(newdataMere) {
          var DataMerge = await removeDuplicatesById(baseBicciData);
          var requestWaitPonint = [];
          var currentOrderList = [];

          for (let i = 0; i < DataMerge.length; i++) {
            for (let n = 0; n < newdataMere.length; n++) {
              let socf = "F" + newdataMere[n].id;

              if (socf === DataMerge[i].id) {
                currentOrderList.push(DataMerge[i].id);
                DataMerge[i].address = newdataMere[n].address;
                DataMerge[i].agreed_date = newdataMere[n].agreed_date;
                DataMerge[i].commune1 = newdataMere[n].commune1;
                DataMerge[i].commune2 = newdataMere[n].commune2;
                DataMerge[i].createdAt = newdataMere[n].createdAt;
                DataMerge[i].customer_email = newdataMere[n].customer_email;
                DataMerge[i].customer_name = newdataMere[n].customer_name;
                DataMerge[i].customer_phone = newdataMere[n].customer_phone;
                DataMerge[i].date = newdataMere[n].date;
                DataMerge[i].do = newdataMere[n].do;
                DataMerge[i].falabella_id = newdataMere[n].falabella_id;
                DataMerge[i].falabella_status = newdataMere[n].falabella_status;
                DataMerge[i].height = newdataMere[n].height;
                DataMerge[i].length = newdataMere[n].length;
                DataMerge[i].local_provider = newdataMere[n].local_provider;
                DataMerge[i].lpn = newdataMere[n].lpn;
                DataMerge[i].max_delivery_date =
                  newdataMere[n].max_delivery_date;
                DataMerge[i].number_trip = newdataMere[n].number_trip;
                DataMerge[i].price = newdataMere[n].price;
                DataMerge[i].quantity = newdataMere[n].quantity;
                DataMerge[i].quantity_packages =
                  newdataMere[n].quantity_packages;
                DataMerge[i].region = newdataMere[n].region;
                DataMerge[i].schedule_range = newdataMere[n].schedule_range;
                DataMerge[i].shipper_id = newdataMere[n].shipper_id;
                DataMerge[i].size = newdataMere[n].size;
                DataMerge[i].soc = newdataMere[n].soc;
                DataMerge[i].status = newdataMere[n].status;
                DataMerge[i].updatedAt = newdataMere[n].updatedAt;
                DataMerge[i].weight = newdataMere[n].weight;
                DataMerge[i].width = newdataMere[n].width;
              }
            }
          }

          let odrdesDelivery = DataMerge.filter(
            (item) => item.falabella_status === "99"
          );

          let SinExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1058" ||
              item.falabella_status === "1059" ||
              item.falabella_status === "1060" ||
              item.falabella_status === "1061" // eliminar de 1061 al 1046 solo desarrollo
            // item.falabella_status === "99" ||
            // item.falabella_status === "1031" ||
            // item.falabella_status === "1030" ||
            // item.falabella_status === "1032" ||
            // item.falabella_status === "1034" ||
            // item.falabella_status === "1036" ||
            // item.falabella_status === "1041" ||
            // item.falabella_status === "1031" ||
            // item.falabella_status === "1081" ||
            // item.falabella_status === "1037" ||
            // item.falabella_status === "1038" ||
            // item.falabella_status === "1029" ||
            // item.falabella_status === "1046" ||
            // item.falabella_status === "1081" ||
            // item.falabella_status === "1023" ||
            // item.falabella_status === "1" ||
            // item.falabella_status === "1002" ||
            // item.falabella_status === "1005" ||
            // item.falabella_status === "1006" ||
            // item.falabella_status === "1007" ||
            // item.falabella_status === "1008" ||
            // item.falabella_status === "1009" ||
            // item.falabella_status === "1017" ||
            // item.falabella_status === "1019" ||
            // item.falabella_status === "1022" ||
            // item.falabella_status === "1023" ||
            // item.falabella_status === "1027" ||
            // item.falabella_status === "1046"
          );

          let filtrarExcepciones = DataMerge.filter(
            (item) =>
              item.falabella_status === "1031" ||
              item.falabella_status === "1030" ||
              item.falabella_status === "1032" ||
              item.falabella_status === "1034" ||
              item.falabella_status === "1036" ||
              item.falabella_status === "1041" ||
              item.falabella_status === "1031" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1037" ||
              item.falabella_status === "1038" ||
              item.falabella_status === "1029" ||
              item.falabella_status === "1046" ||
              item.falabella_status === "1081" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1" ||
              item.falabella_status === "1002" ||
              item.falabella_status === "1005" ||
              item.falabella_status === "1006" ||
              item.falabella_status === "1007" ||
              item.falabella_status === "1008" ||
              item.falabella_status === "1009" ||
              item.falabella_status === "1017" ||
              item.falabella_status === "1019" ||
              item.falabella_status === "1022" ||
              item.falabella_status === "1023" ||
              item.falabella_status === "1027" ||
              item.falabella_status === "1046"
          );

          let objRequestOrigin = {
            id: "origin",
            geoRef: {
              latitud: origin.lat,
              longitud: origin.lng,
            },
          };

          let objRequestDestination = {
            id: "destination",
            geoRef: {
              latitud: destination.lat,
              longitud: destination.lng,
            },
          };

          for (let i = 0; i < SinExcepciones.length; i++) {
            let objRequest = {
              id: SinExcepciones[i].id,
              geoRef: {
                latitud: SinExcepciones[i].toAddressLocation.latitude,
                longitud: SinExcepciones[i].toAddressLocation.longitude,
              },
            };
            requestWaitPonint.push(objRequest);
          }

          let responseMatrixDistances = await getMatrixDistanceBicci(
            requestWaitPonint,
            objRequestOrigin,
            objRequestDestination
          );

          if (
            responseMatrixDistances.pointOrdersData &&
            SinExcepciones &&
            responseMatrixDistances.pointOrdersData[0].hasOwnProperty(
              "position"
            )
          ) {
            for (
              let n = 0;
              n < responseMatrixDistances.pointOrdersData.length;
              n++
            ) {
              let socf = responseMatrixDistances.pointOrdersData[n].id;
              for (let i = 0; i < SinExcepciones.length; i++) {
                if (socf === SinExcepciones[i].id) {
                  SinExcepciones[i].position =
                    responseMatrixDistances.pointOrdersData[n].position;
                  break;
                }
              }
            }

            SinExcepciones.sort((a, b) => a.position - b.position);

            const groupsOrderedStops = [];
            const tamanoGrupo = 23;
            let indiceInicio = 0;

            while (indiceInicio < SinExcepciones.length) {
              const routeSeccion = SinExcepciones.slice(
                indiceInicio,
                indiceInicio + tamanoGrupo
              );
              groupsOrderedStops.push(routeSeccion);
              indiceInicio += tamanoGrupo;
            }

            let arrayCurrentListGroups = [];
            let waypointsContGroups = [];
            for (let i = 0; i < groupsOrderedStops.length; i++) {
              let waypointsCont = [];
              let arrayCurrentList = [];
              let dataForRoute = groupsOrderedStops[i];
              dataForRoute.sort((a, b) => a.position - b.position);
              for (let n = 0; n < dataForRoute.length; n++) {
                const wayPoint = {
                  stopover: true,
                  location: {
                    lat: dataForRoute[n].toAddressLocation.latitude,
                    lng: dataForRoute[n].toAddressLocation.longitude,
                  },
                };
                arrayCurrentList.push(dataForRoute[n].id);
                waypointsCont.push(wayPoint);
              }

              arrayCurrentListGroups.push(arrayCurrentList);
              waypointsContGroups.push(waypointsCont);
            }
            function espera() {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 2000);
              });
            }

            await espera();

            dispatch({
              type: REQ_MATRIXDISTANCE_ROUTE,
              payload: {
                dataOrdesBicciByRider: SinExcepciones,
                dataOrdersExcepcions: filtrarExcepciones,
                isPointGroups: responseMatrixDistances.isPointGroups,
                dataOrderDeliverySusses: odrdesDelivery,
                dataOrdesBicciByRiderGroups: filtrarExcepciones,
                dataOrdesForRouteGoogleServicesRoutes: waypointsContGroups,
                dataOrdesForRouteIds: arrayCurrentListGroups,
                currentOrderList: currentOrderList,
              },
            });
          } else {
          }
        }

        await initMerge(dataOrderdata);
      } catch (error) {}

      //vieja Logica
    };

    if (driverID && type && origin && destination) {
      let ultimosSeis = type.slice(-6);

      let year = "20" + ultimosSeis.slice(0, 2); // Asume que el año es 2000+
      let month = ultimosSeis.slice(2, 4) - 1; // Los meses en JavaScript van de 0 a 11
      let day = ultimosSeis.slice(4, 6);

      let fecha = new Date(year, month, day);
      function esFechaValida(date) {
        return date instanceof Date && !isNaN(date);
      }

      if (esFechaValida(fecha)) {
        findRiderOrders(driverID, fecha);
      } else {
      }
    }
  };

export const obtenerDocumentRoute = (type) => async (dispatch) => {
  try {
    let collectionRef = collection(db, process.env.REACT_APP_COL_ROUTES);
    let documentReft = doc(collectionRef, type);
    let route = await getDoc(documentReft);
    const dataRoute = route.data();

    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { documentRoute: dataRoute },
    });
  } catch (error) {
    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { documentRoute: null },
    });
  }
};

export const createDocumentRoute = (idRoute, data) => async (dispatch) => {
  const planningData = {
    companyId: data.companyId ? data.companyId : "isRequire",
    createBy: data.createBy ? data.createBy : "isRequire",
    createDate: data.createDate ? data.createDate : "isRequire",
    currentOrderList: data.currentOrderList
      ? data.currentOrderList
      : "isRequire",
    date: data.date ? data.date : "isRequire",
    dateDelivery: data.dateDelivery ? data.dateDelivery : "isRequire",
    dateDeliveryText: data.dateDeliveryText
      ? data.dateDeliveryText
      : "isRequire",
    distanceValue: data.distanceValue ? data.distanceValue : "isRequire",
    driverID: data.driverID ? data.driverID : "isRequire",
    driverName: data.driverName ? data.driverName : "isRequire",
    id: data.id ? data.id : "isRequire",
    routePoints: data.routePoints ? data.routePoints : "isRequire",
    startTimeDelivery: data.startTimeDelivery
      ? data.startTimeDelivery
      : "isRequire",
    vehicleID: data.vehicleID ? data.vehicleID : "isRequire",
    destination: data.destination ? data.destination : "isRequire",
    destinationAdress: data.destinationAdress
      ? data.destinationAdress
      : "isRequire",
    optimizeWaypoints: data.optimizeWaypoints
      ? data.optimizeWaypoints
      : "isRequire",
    origin: data.origin ? data.origin : "isRequire",
    originAdress: data.originAdress ? data.originAdress : "isRequire",
    travelMode: data.travelMode ? data.travelMode : "isRequire",
    wayPoints: data.wayPoints ? data.wayPoints : "isRequire",
    warnings: data.warnings ? data.warnings : "isRequire",
    waypoint_order: data.waypoint_order ? data.waypoint_order : "isRequire",
    polylines: data.polylines ? data.polylines : "isRequire",
    indications: data.indications ? data.indications : "isRequire",
    overview_path: data.overview_path ? data.overview_path : "isRequire",
  };

  let routeRef = doc(db, process.env.REACT_APP_COL_ROUTES, idRoute);
  await setDoc(routeRef, planningData);
  try {
    dispatch(obtenerDocumentRoute(idRoute));
  } catch (error) {}
};

export const updateDocumentRoute = (idRoute, data) => async (dispatch) => {
  console.log(data);
  try {
    let routeRef = doc(db, process.env.REACT_APP_COL_ROUTES, idRoute);
    await updateDoc(routeRef, data);
    dispatch(obtenerDocumentRoute(idRoute));
  } catch (error) {
    console.log(error);
  }
};

const getWaypoints = (dataForRoute) => {
  return dataForRoute.map((entry) => ({
    stopover: true,
    location: {
      lat: entry.location.lat,
      lng: entry.location.lng,
    },
  }));
};

const prepareDirectionsServiceOptions = async (
  dataOrdesForRouteGoogleServicesRoutes,
  currentState
) => {
  const directionsServiceOptionaAgrupations = [];

  if (!dataOrdesForRouteGoogleServicesRoutes || !currentState) {
    return directionsServiceOptionaAgrupations;
  }

  for (let n = 0; n < dataOrdesForRouteGoogleServicesRoutes.length; n++) {
    let dataForRoute = dataOrdesForRouteGoogleServicesRoutes[n];

    let groupOrigenLat, groupOrigenLng, groupDestiniLat, groupDestiniLng;

    const waypointsElements = getWaypoints(dataForRoute);

    if (n === 0) {
      groupOrigenLat = currentState.origin.lat;
      groupOrigenLng = currentState.origin.lng;

      groupDestiniLat =
        dataOrdesForRouteGoogleServicesRoutes.length !== 1
          ? dataForRoute[dataForRoute.length - 1].location.lat
          : currentState.destination.lat;
      groupDestiniLng =
        dataOrdesForRouteGoogleServicesRoutes.length !== 1
          ? dataForRoute[dataForRoute.length - 1].location.lng
          : currentState.destination.lng;
    } else {
      groupOrigenLat =
        dataOrdesForRouteGoogleServicesRoutes[0][22]?.location.lat;
      groupOrigenLng =
        dataOrdesForRouteGoogleServicesRoutes[0][22]?.location.lng;

      groupDestiniLat =
        n === dataOrdesForRouteGoogleServicesRoutes.length - 1
          ? currentState.destination.lat
          : dataForRoute[dataForRoute.length - 1].location.lat;
      groupDestiniLng =
        n === dataOrdesForRouteGoogleServicesRoutes.length - 1
          ? currentState.destination.lng
          : dataForRoute[dataForRoute.length - 1].location.lng;
    }

    const DirectionsServiceOption = {
      origin: {
        lat: groupOrigenLat,
        lng: groupOrigenLng,
      },
      destination: {
        lat: groupDestiniLat,
        lng: groupDestiniLng,
      },
      travelMode: currentState.travelMode,
      optimizeWaypoints: true,
      waypoints: waypointsElements,
    };

    directionsServiceOptionaAgrupations.push(DirectionsServiceOption);
  }

  return directionsServiceOptionaAgrupations;
};

export const defineViewsRouteMapsReducer =
  (
    idRoute,
    dataOrdesForRouteGoogleServicesRoutes,
    currentState,
    currentOrderList
  ) =>
  async (dispatch) => {
    const directionsServiceOptionaAgrupations =
      await prepareDirectionsServiceOptions(
        dataOrdesForRouteGoogleServicesRoutes,
        currentState
      );

    dispatch({
      type: UPDATE_VIEW_ROUTE_EXITO,
      payload: {
        directionsServiceOptionaAgrupations:
          directionsServiceOptionaAgrupations,
      },
    });
    dispatch(
      controlServicesRoutesRequest(
        idRoute,
        directionsServiceOptionaAgrupations,
        "google",
        currentOrderList
      )
    );
  };

export const createLocalData = (data) => async (dispatch) => {
  try {
    localStorage.setItem("localDataRoute", JSON.stringify(data));
  } catch (error) {}
};

export const printInterface = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { estatus: "ok" },
    });
  } catch (error) {}
};

export const updateInterface = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { estatus: "ok" },
    });
  } catch (error) {}
};

export const reCreateDocumentRoute = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { estatus: "ok" },
    });
  } catch (error) {}
};

export const handleResponseApi = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_DOCUMENT_ROUTE_EXITO,
      payload: { estatus: "ok" },
    });
  } catch (error) {}
};

export const validateDriverConfig = (data, method) => async (dispatch) => {
  function validateBooleanProperties(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] !== "boolean") {
          return false;
        }
      }
    }
    return true;
  }
  const METHOD = {
    get: getDriverConfig,
    create: createDriverConfig,
    update: uptateDriverConfig,
  };

  const isValid = validateBooleanProperties(data);
  if (isValid) {
    // Aquí puedes invocar la función según el método que recibas como argumento
    switch (method) {
      case "get":
        METHOD.get(data);
        break;
      case "create":
        METHOD.create(data);
        break;
      case "update":
        METHOD.update(data);
        break;
      default:
    }
  } else {
  }
};

export const getDriverConfig = (dataId) => async (dispatch) => {
  try {
    let collectionRef = collection(
      db,
      process.env.REACT_APP_COL_ROUTES_CUSTON_CONFIG
    );
    let documentReft = doc(collectionRef, dataId);
    let routeConfig = await getDoc(documentReft);
    const dataRouteConfig = routeConfig.data();

    if (!dataRouteConfig) {
      dispatch(createDriverConfig(dataId));
    } else {
      dispatch({
        type: LOAD_DOCUMENT_ONFIG_ROUTE_EXITO,
        payload: { viewConfig: dataRouteConfig },
      });
    }
  } catch (error) {}
};

export const createDriverConfig = (dataId) => async (dispatch) => {
  let basicData = {
    upDateByRiderPosition: false,
    viewPositionsLabels: false,
    viewIds: false,
    nextStops: false,
    alertsEmojis: false,
    alertProxmitiPoints: false,
    maspStyleUserPreferent: false,
  };
  let routeRef = doc(
    db,
    process.env.REACT_APP_COL_ROUTES_CUSTON_CONFIG,
    dataId
  );
  await setDoc(routeRef, basicData);
  try {
    dispatch(getDriverConfig(dataId));
  } catch (error) {}
};

export const uptateDriverConfig = (dataId, data) => async (dispatch) => {
  let updateData = {
    upDateByRiderPosition: data.upDateByRiderPosition,
    viewPositionsLabels: data.viewPositionsLabels,
    viewIds: data.viewIds,
    nextStops: data.nextStops,
    alertsEmojis: data.alertsEmojis,
    alertProxmitiPoints: data.alertProxmitiPoints,
    maspStyleUserPreferent: data.maspStyleUserPreferent,
  };
  try {
    let routeRef = doc(
      db,
      process.env.REACT_APP_COL_ROUTES_CUSTON_CONFIG,
      dataId
    );
    await updateDoc(routeRef, updateData);
    dispatch({
      type: LOAD_DOCUMENT_ONFIG_ROUTE_EXITO,
      payload: { viewConfig: updateData },
    });
  } catch (error) {}
};

export const controlServicesRoutesRequest =
  (routeId, dataDirectionsOptions, type, currentOrderList) =>
  async (dispatch) => {
    try {
      if (routeId && routeId !== "") {
        const routeDocRef = doc(db, "xpress_DriverRoutes", routeId);
        const docSnap = await getDoc(routeDocRef);

        if (!docSnap.exists()) {
        } else {
          const documenRoute = docSnap.data();

          const existingStops = docSnap.data().currentOrderList;
          const newStops = currentOrderList.map((stop) => stop);

          let sortedExisting = [...existingStops].sort();
          let sortedNew = [...newStops].sort();

          let hasChanges = !(
            sortedExisting.length === sortedNew.length &&
            sortedExisting.every((id, index) => id === sortedNew[index])
          );

          if (hasChanges) {
            if (newStops.length <= 23) {
              console.log("Opcion Individual");
              await createNewRoutesMaps(
                routeId,
                dataDirectionsOptions[0]
              )(dispatch);
            } else {
              console.log("Opcion Multiple");
              await createMultiplesRoutesMaps(
                routeId,
                dataDirectionsOptions
              )(dispatch);
            }
          } else {
            //     console.count("hasChanges");
          }

          // Existe pero es nueva no posee lineas

          if (
            documenRoute.overview_path === "isRequire" ||
            documenRoute.waypoint_order === "isRequire" ||
            documenRoute.editable === true
          ) {
            if (existingStops.length <= 23) {
              console.log("Opcion Individual");
              await createNewRoutesMaps(
                routeId,
                dataDirectionsOptions[0]
              )(dispatch);
            } else {
              console.log("Opcion Multiple");
              await createMultiplesRoutesMaps(
                routeId,
                dataDirectionsOptions
              )(dispatch);
            }
          } else {
            // console.log("Archivo completado");
          }

          dispatch({
            type: UPDATE_VIEW_ROUTE_EXITO,
            payload: {
              directionsServiceOptionaAgrupations: dataDirectionsOptions,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error en controlServicesRoutesRequest: ", error);
      // Aquí, puedes enviar un dispatch para manejar errores, si lo deseas.
    }
  };

export const createNewRoutesMaps =
  (idRoute, directionsOptions) => async (dispacth) => {
    if (directionsOptions) {
      if (!directionsOptions.origin.lat || !directionsOptions.origin.lng) {
        return;
      }
      try {
        if (window.google && window.google.maps) {
          var directionsService = new window.google.maps.DirectionsService();
          let origin = {
            lat: parseFloat(directionsOptions.origin.lat),
            lng: parseFloat(directionsOptions.origin.lng),
          };
          let destination = {
            lat: parseFloat(directionsOptions.destination.lat),
            lng: parseFloat(directionsOptions.destination.lng),
          };

          directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: directionsOptions.travelMode,
              optimizeWaypoints: true, //direcctionOptions.optimizeWaypoints,
              waypoints: directionsOptions.waypoints,
            },
            function (response, status) {
              console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              console.log("Evaluar si es necesario");
              console.log("destination");
              console.log(origin);
              console.log("Evaluar si es necesario ");
              if (status === window.google.maps.DirectionsStatus.OK) {
                try {
                  if (response.routes[0].waypoint_order.length > 0) {
                    const validResponses = response.routes[0];
                    //setResponseApi(validResponses);

                    dispacth(
                      createFinalLine(validResponses.overview_polyline, idRoute)
                    );

                    let saveLegs = [];

                    for (let n = 0; n < validResponses.legs.length; n++) {
                      let leg = validResponses.legs[n];
                      let locationEnd = {
                        lat: leg.end_location.lat(),
                        lng: leg.end_location.lng(),
                      };
                      let LocationStar = {
                        lat: leg.start_location.lat(),
                        lng: leg.start_location.lng(),
                      };
                      saveLegs.push({
                        distance: leg.distance,
                        duration: leg.duration,
                        end_address: leg.end_address,
                        end_location: locationEnd,
                        start_address: leg.start_address,
                        start_location: LocationStar,
                      });
                    }

                    try {
                      const planningData = {
                        //  overview_path: latLngPositions,
                        indications: { 0: saveLegs },
                        warnings: validResponses.warnings,
                        waypoint_order: { 0: validResponses.waypoint_order },
                      };
                      dispacth(updateDocumentRoute(idRoute, planningData));
                    } catch (error) {}
                  } else {
                  }
                } catch (error) {}
              }
            }
          );
        } else {
        }
      } catch (error) {}
    }
  };

export const createMultiplesRoutesMaps =
  (idRoute, directionsOptions) => async (dispacth) => {
    try {
      if (window.google && window.google.maps) {
        const directionsService = new window.google.maps.DirectionsService();

        const routePromises = directionsOptions.map((directionsOption) => {
          return new Promise((resolve) => {
            directionsService.route(
              {
                origin: directionsOption.origin,
                destination: directionsOption.destination,
                travelMode: "BICYCLING", //.directionsOption.travelMode,
                optimizeWaypoints: true,
                waypoints: directionsOption.waypoints,
              },
              (response, status) => {
                console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
                console.log("Evaluar si es necesario");
                console.log("destination");
                console.log(origin);
                console.log("Evaluar si es necesario ");
                if (status === window.google.maps.DirectionsStatus.OK) {
                  resolve(response);
                } else {
                  resolve(null);
                }
              }
            );
          });
        });

        const responses = await Promise.all(routePromises);

        const validResponses = responses.filter(
          (response) => response !== null
        );

        console.log(validResponses); //trae array  respuestas de google DirectionsService
        let allSaveLegs = {};
        let allWarning = {};
        let allWaypoint_order = {};
        for (let n = 0; n < validResponses.length; n++) {
          let responseIterable = validResponses[n].routes[0];
          let saveLegs = [];
          for (let y = 0; y < responseIterable.legs.length; y++) {
            let leg = responseIterable.legs[y];
            let locationEnd = {
              lat: leg.end_location.lat(),
              lng: leg.end_location.lng(),
            };
            let locationStar = {
              lat: leg.start_location.lat(),
              lng: leg.start_location.lng(),
            };
            saveLegs.push({
              distance: leg.distance,
              duration: leg.duration,
              end_address: leg.end_address,
              end_location: locationEnd,
              start_address: leg.start_address,
              start_location: locationStar,
            });
          }
          allSaveLegs = Object.assign(allSaveLegs, { [n]: saveLegs });
          allWaypoint_order = Object.assign(allWaypoint_order, {
            [n]: responseIterable.waypoint_order,
          });
          allWarning = Object.assign(allWarning, {
            [n]: responseIterable.warnings,
          });
          dispacth(
            createMulipleLines(
              responseIterable.overview_polyline,
              idRoute,
              n,
              validResponses.length - 1
            )
          );
        }

        try {
          const planningData = {
            //  overview_path: latLngPositions,
            indications: allSaveLegs,
            warnings: allWarning,
            waypoint_order: allWaypoint_order,
          };

          dispacth(updateDocumentRoute(idRoute, planningData));
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {}
  };

export const createMulipleLines =
  (codeLine, idRoute, conter, accion) => async (dispacth) => {
    var points = await decodePolyline(codeLine);
    let latLngPositions = points.map(({ lat, lng }) => ({
      lat,
      lng,
    }));

    agroupLines = Object.assign(agroupLines, {
      [conter]: latLngPositions,
    });

    if (conter === accion) {
      try {
        const planningData = {
          overview_path: agroupLines,
          editable: false,
        };
        dispacth(updateDocumentRoute(idRoute, planningData));

        agroupLines = {};
      } catch (error) {}
    }
  };

export const createFinalLine = (codeLine, idRoute) => async (dispacth) => {
  var points = await decodePolyline(codeLine);
  let latLngPositions = points.map(({ lat, lng }) => ({
    lat,
    lng,
  }));

  try {
    const planningData = {
      overview_path: { 0: latLngPositions },
      editable: false,
    };
    dispacth(updateDocumentRoute(idRoute, planningData));
  } catch (error) {}
};

async function decodePolyline(encoded) {
  let index = 0;
  let lat = 0;
  let lng = 0;
  let coordinates = [];
  let shift = 0;
  let result = 0;
  let byte = null;
  let latitude_change;
  let longitude_change;
  let precision = Math.pow(10, -5);

  while (index < encoded.length) {
    byte = null;
    shift = 0;
    result = 0;
    do {
      byte = encoded.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);
    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    shift = result = 0;
    do {
      byte = encoded.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);
    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    lat += latitude_change;
    lng += longitude_change;
    coordinates.push({ lat: lat * precision, lng: lng * precision });
  }
  return coordinates;
}
