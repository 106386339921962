import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import Moment from "moment";
import { db } from "../../../firebase";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { pdf } from "@react-pdf/renderer";
import CreateManiFestByCurrentListPdf from "../../../components/CreateManiFestByCurrentListPdf/CreateManiFestByCurrentListPdf";
import ToolDashResumenOpsAutoCreate from "../../../components/Dashboard/dashboard/ToolDashResumenOpsAutoCreate";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent";
import { useDateRangePicker } from "../../../components/CustomDateRangePicker";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys";

var dataOrdersForAccions = [];
var listadeItemsNoMach = [];
var popUpAccion = "-";
const PlannerManagerRoutes = (props) => {
  const [rows, setRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);

  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );
  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  //-- Nuevas Variables
  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [messajeAdmin, setMenssajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
  } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Asegúrate de que los driverID sean únicos
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];

        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );

          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            // Agregar solo si no está ya presente
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }

        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      // Extraer ridersDataByCompany sin duplicados y sin driverID vacío o indefinido
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      // Esta función agrupa las órdenes por tienda y calcula métricas
      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [], // Puedes omitir esto si `commune2` no existe
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);
        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        // Omitir lógica de `commune2` si no existe
        if (order.commune2) {
          const comunaIndex = acc[driverID].locality.findIndex(
            (comuna) => Object.keys(comuna)[0] === order.commune2
          );

          if (comunaIndex >= 0) {
            acc[driverID].locality[comunaIndex][order.commune2]++;
          } else {
            const newComuna = { [order.commune2]: 1 };
            acc[driverID].locality.push(newComuna);
          }
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const UpdateOrderForAcctions = (items) => {
    console.log(items);
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          //  console.log("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const memoizedData = useMemo(() => {
    if (mergedOrders && metricsOps && ridersDataByCompany) {
      return { dataMerge: mergedOrders, metricsOps, ridersDataByCompany };
    }
  }, [mergedOrders, metricsOps, ridersDataByCompany]);

  const google = window.google;

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    const selectedRowsData = item.countersListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );
    setSelectedData(selectedRowsData);
    setSelectedIDs(item.countersListOrders);
    UpdateOrderForAcctions(item.countersListOrders);
  };

  const handleClickSelectRiderPendintList = (e, item) => {
    e.preventDefault();
    const selectedRowsDataPending = item.countersIssuresListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );

    const pendingIDs = selectedRowsDataPending.map((row) => row.id);
    setSelectedData(selectedRowsDataPending);
    setSelectedIDs(item.countersIssuresListOrders);
    UpdateOrderForAcctions(pendingIDs);
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders.slice();
    var listadeItems = new Set(item.countersListOrders);

    try {
      const tempuserOrdersMap = new Map();
      tempuserOrders.forEach((order) => {
        tempuserOrdersMap.set(order.id, order);
      });

      listadeItems.forEach((id) => {
        const order = tempuserOrdersMap.get(id);
        if (order) {
          copyOrdersForAccions.push(order.id);
        }
      });

      let copyData = copyOrdersForAccions.join("\n"); // Convertir a string con saltos de línea
      navigator.clipboard.writeText(copyData);
    } catch (error) {
      console.log(error);
    }
  };

  const createManiFestByCurrentList = async (e, item) => {
    e.preventDefault();
    console.log("createManiFestByCurrentList init");
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    const blob = await pdf(
      <CreateManiFestByCurrentListPdf orders={copyOrdersForAccions} />
    ).toBlob();

    console.log("createManiFestByCurrentList init");
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  return (
    <>
      <ToolFiltersComponent
        selectedCompanies={selectedCompanies}
        selectedOrigins={selectedOrigins}
        filteredCompanies={filteredCompanies}
        filteredOrigins={filteredOrigins}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
      ></ToolFiltersComponent>

      <ToolDashResumenOpsAutoCreate
        mergedOrders={viewMergedOrders}
        handleClickSelectRiderCurrentList={handleClickSelectRiderCurrentList}
        handleClickSelectRiderPendintList={handleClickSelectRiderPendintList}
        handleCopySelectRiderCurrentList={handleCopySelectRiderCurrentList}
        createManiFestByCurrentList={createManiFestByCurrentList}
      ></ToolDashResumenOpsAutoCreate>
    </>
  );
};

export default PlannerManagerRoutes;
