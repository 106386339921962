import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  LinearProgress,
  Select,
} from "@mui/material";
import { FormControl, MenuItem } from "@mui/material";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../firebase";

const LiberarOrden = ({ orderID, userID }) => {
  const orderRef = doc(db, process.env.REACT_APP_COL_USERS_ORDERS, orderID);
  const [open, setOpen] = useState(false);
  const [accion, setAccion] = useState("returne"); // Estado para la ruta
  const userRiderHub = {
    driverID: "zfaUMopBDldTOnHJmfqowzvrRbe2",
    driverAvatar:
      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/thumbnails%2FzfaUMopBDldTOnHJmfqowzvrRbe2%2FzfaUMopBDldTOnHJmfqowzvrRbe2.jpg?alt=media&token=2c7f8cdb-6ab0-43f3-a2e1-8eeec34dc848",
    driverName: "HUB BICCI ( BODEGA )",
    driverPhone: "950001234",
  };
  const selector = [
    {
      key: "returne",
      value: "returne",
      label: "Folios retornado al Hub, Salida adelantada",
    },
    {
      key: "LossManagement",
      value: "LossManagement",
      label: "Folio extraviado en ruta ( Gestíon Cociliaciónes )",
    },
    {
      key: "ClaimManagementIsure",
      value: "ClaimManagementIsure",
      label: "Folio en Perdida / Accidente ( Gestíon de Seguros)",
    },
    {
      key: "ClaimManagement",
      value: "ClaimManagement",
      label: "Folio en Siniestro / Robo ( Gestíon de Seguros)",
    },
  ];

  useEffect(() => {}, [accion]);
  // Función para abrir el popup
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSelect = (event) => {
    setAccion(event.target.value);
  };

  // Función para cerrar el popup
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Aquí puedes agregar lógica para guardar
    switch (accion) {
      case "returne":
        asignarAlHubSinActivarEventos();
        break;
      default:
        realiseRiderForPack();
        break;
    }
  };

  const realiseRiderForPack = () => {
    //Aciones a registrar en facturaciones
    // Marcar en historial evento del sistema No asociado al flujo operativo -> Flujo Administrativo

    updateOrder(null);
    setHistory(null);
  };

  const asignarAlHubSinActivarEventos = () => {
    //Aciones sin activar apis, retornos con demoras Salidas adelantadas
    // Marcar en historial evento del sistema No asociado al flujo operativo -> Flujo Administrativo

    updateOrder(userRiderHub);
    setHistory(userRiderHub);
  };

  const updateOrder = async (hubRider) => {
    try {
      await updateDoc(orderRef, {
        driverID: hubRider?.driverID ? hubRider.driverID : "",
        driverAvatar: hubRider?.driverAvatar ? hubRider.driverAvatar : "",
        //       driverName: hubRider?.driverName ? hubRider.driverName : "",
        driverPhone: hubRider?.driverPhone ? hubRider.driverPhone : "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setHistory = async (hubRider) => {
    let updateExtraHistory = {
      createdAt: Timestamp.now(),
      driverID: hubRider?.driverID ? hubRider.driverID : "",
      status: accion,
      userID: userID,
    };

    try {
      const statusDocs = collection(orderRef, "statuses");
      await addDoc(statusDocs, updateExtraHistory);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return orderID ? (
    <div>
      <Button
        sx={{
          backgroundColor: "#591E8F",
          border: "none",
          borderRadius: "3px",
          color: "#fff",
          cursor: "pointer",
          fontSize: "14px",
          marginTop: "30px",
          outline: "none",
          padding: "5px",
          minWidth: "50px",
          fontWeight: 400,
          margin: "0em 1em 0em 0em",
          ":hover": {
            backgroundColor: "#25b2f4 !important",
            color: "#591e8f",
          },
        }}
        onClick={handleClickOpen}
        color="primary"
        variant="contained"
        type="button"
      >
        Liberar Rider
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: 500,
            height: 400,
          },
        }}
      >
        <DialogTitle sx={{ color: "#030303", fontSize: "25px" }}>
          Motivo de Liberación de folio al conductor
        </DialogTitle>
        <DialogTitle>Estados administrativos, No de integracion</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="selectLabelRelase">Seleccione Opción</InputLabel>
            <Select
              value={accion}
              onChange={handleSelect}
              displayEmpty
              sx={{ width: "100%", m: 0 }}
            >
              {selector.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default LiberarOrden;
