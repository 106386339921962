import React from "react";
import { Grid } from "semantic-ui-react";
import { db } from "../../../firebase";
import { Form, Input, Container } from "semantic-ui-react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
var estados;
var optionOfBilling;
class RatesEbiex extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props)
    this.state = {
      ratetId: "",
      createBy: props.props.userUid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      date: Date.now(),
      typeOfBilling: {},
      optionOfBilling: {},
      isActive: false,
      isPublic: false,
      rateName: "",
    };
    this.changepaymentOption = this.changepaymentOption.bind(this);
    this.changepaymentMethods = this.changepaymentMethods.bind(this);
    this.handleChangeisPublic = this.handleChangeisPublic.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTarifario = this.handleChangeTarifario.bind(this);
    this.handleSubmitTarifario = this.handleSubmitTarifario.bind(this);
  }

  // const crearServico=()=>{}
  // const limpiarFormularioa=()=>{}
  render() {
    return (
      <Container className="dashcontent">
        <Grid celled="internally" id="mi-cuentaContent">
          <div>
            <h2> Crear tarifarios</h2>
          </div>
          <Grid.Row>
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo tarifario personalizado"
                title={
                  <>
                    <h3>Agregar tarifario</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form onSubmit={this.handleSubmitTarifario}>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Nombre del Tarifario"
                      type="text"
                      name="rateName"
                      onChange={this.handleChange}
                      value={this.state.rateName}
                    />
                  </Form.Group>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Metodos de pago</th>
                        <th scope="col">Estatus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.paymentMethods.map((element) => (
                        <tr key={element.id}>
                          <td>{element.name}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.changepaymentMethods}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Opciones de pago</th>
                        <th scope="col">Estatus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.paymentOptions.map((element) => (
                        <tr key={element.id}>
                          <td>{element.name}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.changepaymentOption}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Estatus</th>
                        <th scope="col">Nombre de tipo de pakete</th>
                        <th scope="col">Código in APP</th>
                        <th scope="col">Precio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.paqRates.map((element) => (
                        <tr key={element.id}>
                          <td>
                            {element.isActive ? (
                              <CheckCircleIcon color="success"></CheckCircleIcon>
                            ) : (
                              <IndeterminateCheckBoxIcon color="disabled"></IndeterminateCheckBoxIcon>
                            )}
                          </td>
                          <td>{element.name}</td>
                          <td>{element.id}</td>
                          <td>
                            {" "}
                            <input
                              onChange={this.handleChangeTarifario}
                              value={this.state[element.id]}
                              className="maxw"
                              type="number"
                              name={element.id}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>

            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo tarifario personalizado"
                title={
                  <>
                    <h3>Activa o desactiva tarifarios</h3>
                  </>
                }
              />
              <Divider />

              <Container className="formcontent" id="micuentafromIni">
                <Form>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Nombre de Tarifario</th>
                        <th scope="col">Código in APP</th>
                        <th scope="col">Status</th>
                        <th scope="col">Web</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.tarifariosData.map((element) => (
                        <tr key={element.id}>
                          <td>
                            {(estados = element.isActive)}
                            {element.isActive ? (
                              <CheckCircleIcon color="success"></CheckCircleIcon>
                            ) : (
                              <IndeterminateCheckBoxIcon color="disabled"></IndeterminateCheckBoxIcon>
                            )}
                          </td>
                          <td>{element.rateName}</td>
                          <td>{element.id}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItem}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isPublic}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeisPublic}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>Editar</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  ></Box>
                </Form>
              </Container>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  async handleSubmitTarifario(e) {
    e.preventDefault();

    try {
      await db
        .collection(`${process.env.REACT_APP_COL_RATES}`)
        .doc(this.state.ratetId)
        .set(this.state);
    } catch (error) {
      console.log(error);
    }
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
    if (name === "rateName") {
      this.setState({ rateName: value });
      this.setState({ ratetId: value.toLowerCase().replace(/ /g, "_") });
    }
  }

  handleChangeTarifario(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: parseInt(value) });
  }

  changepaymentMethods(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var typeOfBilling = this.props.paymentMethods;
    var elementType = [];
    for (let i = 0; i < typeOfBilling.length; i++) {
      if (typeOfBilling[i].id === id) {
        elementType.push((typeOfBilling[i].isActive = checked));
      }
    }

    this.setState({ typeOfBilling: typeOfBilling });
  }

  changepaymentOption(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var optionOfBilling = this.props.paymentOptions;
    var elementOpt = [];
    for (let i = 0; i < optionOfBilling.length; i++) {
      if (optionOfBilling[i].id === id) {
        elementOpt.push((optionOfBilling[i].isActive = checked));
      }
    }
    this.setState({ optionOfBilling: optionOfBilling });
  }

  autollenado(e) {
    var elemento;
    var valor;
    for (let i = 0; i < e.length; i++) {
      var element = e[i];
      elemento = element.id;
      valor = 2000;
      this.setState({ [elemento]: valor });
    }
  }

  async handleChangeItem(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    await db
      .collection(`${process.env.REACT_APP_COL_RATES}`)
      .doc(id)
      .update({ isActive: checked });
  }

  async handleChangeisPublic(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    await db
      .collection(`${process.env.REACT_APP_COL_RATES}`)
      .doc(id)
      .update({ isPublic: checked });
  }
}

export default RatesEbiex;
