import React from 'react';
import {Container} from 'semantic-ui-react';
const Configuraciones = () => {
  return (  
    <div>
 
    <Container className='dashcontent'>
     <div>
     Estamos mejorando el sitio en este momento.
     <h1>Managenement Configuraciones </h1>
    </div>
    </Container> 
    </div>
    ); 
  };

export default Configuraciones;
