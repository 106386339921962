import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

const Cuposenvios = (props) => {

    const data = {
        labels: [`Balance: ${props.balance}`, `Disponibles: ${props.shippingQuotas}`, ],
        datasets: [
          {
            label: '# of Votes',
            data: [`${props.balance}`, `${props.shippingQuotas}`],
            backgroundColor: [
                '#28cd71',
                '#41c5ed',
      
            ],
            borderColor: [
              '#28cd71',
              '#41c5ed',
            ],
            borderWidth: 2,
          },
        ],
      };

  return (
   <Doughnut data={data} />
  )
}

export default Cuposenvios