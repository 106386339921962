import Carousel from "react-material-ui-carousel";
import { Button, Box, CardHeader, CardContent } from "@mui/material";

import { useEffect, useState } from "react";
import { StyledCard } from "./FromStyles";

const FromShippingCurriers = ({ onChange, data }) => {
  const [selectedCurrier, setSelectedCurrier] = useState("");

  useEffect(() => {
    if (data && data.currier) {
      setSelectedCurrier(data.currier);
    }
  }, [data]);

  const handleChangeCurrier = (currier) => {
    setSelectedCurrier(currier);
    onChange({ currier });
  };

  const carouselItems = [
    {
      name: "BICCI",
      value: "BICCI",
      url: "https://bicci.cl/wp-content/uploads/2021/11/cropped-cropped-Logo-Bicci_Mesa-de-trabajo-1-1-300x117-1.png",
    },
    // ...otros elementos
  ];

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardContent
        sx={{ padding: "1px", backgroundColor: "#FFF", borderRadius: "15px" }}
      >
        <Box sx={{ height: "120px" }}>
          <Carousel autoPlay={false} animation="slide">
            {carouselItems.map((item) => (
              <Button
                key={item.value}
                onClick={() => handleChangeCurrier(item.value)}
                sx={{
                  padding: 0, // Eliminar el padding para que la imagen ocupe todo el botón
                  minWidth: "150px", // Eliminar el ancho mínimo
                  Width: "150px",
                  minHeight: "150px",
                  backgroundColor:
                    selectedCurrier === item.value ? "#FFF" : "transparent",
                  "&:hover": {
                    backgroundColor:
                      selectedCurrier === item.value ? "secondary.light" : "",
                  },
                }}
              >
                <img
                  src={item.url}
                  alt={item.name}
                  style={{ width: "150px", display: "block" }} // Asegurarse de que la imagen sea bloque y ocupe todo el ancho posible dentro del botón
                />
              </Button>
            ))}
          </Carousel>
        </Box>
        {/* Aquí puedes incluir más contenido que cambie según el currier seleccionado */}
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingCurriers;
