import { Grid } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="footerScss">
        <Grid>
          <Grid>
            <div className="copyright">
              {" "}
              ©bicci 2022 / Todos los derechos reservados
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
