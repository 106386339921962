import React, { useState, useEffect } from "react";
import { customLocaleText } from "../../../utils/objects/CustomLocaleTextTables";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { Box, Button } from "@mui/material";
import moment from "moment/moment";

const FleetResumenTable = ({ metricsOps }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (metricsOps && metricsOps.length > 0) {
      updateTable(metricsOps);
    }
  }, [metricsOps]);

  const updateTable = (metricsOps) => {
    const columnsTable = [
      {
        field: "driverName",
        headerName: "Conductor",
        width: 136,
      },
      {
        field: "vehicleDetailsID",
        headerName: "Vehículo",
        width: 136,
      },

      {
        field: "day",
        headerName: "day",
        width: 70,
      },
      {
        field: "start",
        headerName: "Hora de inicio",
        width: 100,
      },
      {
        field: "endDay",
        headerName: "endDay",
        width: 70,
      },
      {
        field: "end",
        headerName: "Hora Fin",
        width: 100,
      },
      {
        field: "LastActivity",
        headerName: "Última Actualización",
        width: 100,
      },
      {
        field: "routeTime",
        headerName: "Tiempo en ruta",
        width: 100,
        renderCell: (params) => `${(params.row.routeTime / 60).toFixed(1)} Hrs`,
      },
      {
        field: "routeTimeEstimate",
        headerName: "Duración ruta estimada",
        width: 150,
        renderCell: (params) =>
          `${((params.row.asignados * 10) / 60).toFixed(1)} Hrs`,
      },
      {
        field: "asignados",
        headerName: "Asignados",
        width: 90,
      },
      {
        field: "delivery",
        headerName: "Finalizados",
        width: 90,
      },
      {
        field: "pendientes",
        headerName: "Pendientes",
        width: 90,
        renderCell: (params) => params.row.asignados - params.row.delivery,
      },
      {
        field: "completionRate",
        headerName: "Nivel de %",
        width: 90,
        renderCell: (params) =>
          params.row.delivery > 0
            ? Math.round(
                (params.row.delivery / params.row.asignados) * 100
              ).toFixed(2)
            : 0,
      },
      {
        field: "deliveryForMinTimes",
        headerName: "Entrega/Min",
        width: 150,
        renderCell: (params) =>
          params.row.delivery > 0
            ? `${(params.row.routeTime / params.row.delivery).toFixed(2)} min`
            : 0,
      },
      {
        field: "deliveriesPerHour",
        headerName: "Entregas por Hora",
        width: 150,
        renderCell: (params) =>
          params.row.deliveriesPerHour ? params.row.deliveriesPerHour : "N/A",
      },
      {
        field: "speedLabel",
        headerName: "Velocidad",
        width: 150,
        renderCell: (params) => {
          const deliveryTime = parseInt(
            (params.row.routeTime / params.row.delivery).toFixed(0)
          );
          const estimateTime = parseInt(
            (params.row.asignados * 10) / params.row.asignados
          ).toFixed(0);

          let speedLabel = "N/A";
          let backgroundColor = "";

          if (params.row.delivery === 0) {
            speedLabel = "Detenido";
            backgroundColor = "red";
          } else if (deliveryTime > estimateTime) {
            speedLabel = "Lento";
            backgroundColor = "orange";
          } else {
            speedLabel = "Rápido";
            backgroundColor = "green";
          }

          return (
            <div
              style={{
                backgroundColor: backgroundColor,
                color: "white",
                padding: "4px",
                borderRadius: "4px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {speedLabel}
            </div>
          );
        },
        sortComparator: (v1, v2, param1, param2) => {
          // Definir valores numéricos para cada etiqueta
          const getSpeedRank = (speedLabel) => {
            if (speedLabel === "Rápido") return 1;
            if (speedLabel === "Lento") return 2;
            if (speedLabel === "Detenido") return 3;
            return 4; // N/A u otros valores
          };

          // Asignar rangos a las etiquetas de velocidad
          const rank1 = getSpeedRank(param1.row.speedLabel);
          const rank2 = getSpeedRank(param2.row.speedLabel);
          return rank1 - rank2; // Orden ascendente: Rápido, Lento, Detenido
        },
      },
    ];

    const rowsTable = metricsOps.map((op, index) => {
      let sortedOrders;
      try {
        sortedOrders = op.listOrdersData.data
          .filter((order) => order.updatedAt) // Filtrar órdenes sin `updatedAt`
          .sort((a, b) => a.updatedAt.seconds - b.updatedAt.seconds);
      } catch (error) {
        console.log("Error al ordenar las órdenes: ", error);
        sortedOrders = []; // Asegurarse de que `sortedOrders` no esté indefinido
      }

      const startDay =
        sortedOrders.length > 0
          ? moment(sortedOrders[0].updatedAt.seconds * 1000).format(
              "DD/MM/YYYY"
            )
          : null;

      // Determinar el día de fin
      const endDay =
        sortedOrders.length > 0
          ? moment(
              sortedOrders[sortedOrders.length - 1].updatedAt.seconds * 1000
            ).format("DD/MM/YYYY")
          : null;

      const start =
        sortedOrders.length > 0
          ? new Date(sortedOrders[0].updatedAt.seconds * 1000)
          : null;

      const end =
        sortedOrders.length > 0
          ? new Date(
              sortedOrders[sortedOrders.length - 1].updatedAt.seconds * 1000
            )
          : null;

      const lastActivity =
        sortedOrders.length > 0
          ? new Date(
              Math.max(
                ...sortedOrders.map((order) =>
                  order.updatedAt ? order.updatedAt.seconds * 1000 : 0
                )
              )
            )
          : null;

      const routeTime = start && end ? Math.round((end - start) / 60000) : 0; // Duración en minutos
      const routeTimeInHours = routeTime / 60; // Convertir el tiempo de ruta a horas

      // Cálculo del promedio de entregas por hora
      const deliveriesPerHour =
        routeTimeInHours > 0
          ? (op.delivery / routeTimeInHours).toFixed(2)
          : "N/A";

      // Determinar la etiqueta de velocidad (Rápido, Lento, Detenido)
      const deliveryTime = parseInt((routeTime / op.delivery).toFixed(0));
      const estimateTime = parseInt((op.asignados * 10) / op.asignados).toFixed(
        0
      );
      let speedLabel = "N/A";
      if (op.delivery === 0) {
        speedLabel = "Detenido";
      } else if (deliveryTime > estimateTime) {
        speedLabel = "Lento";
      } else {
        speedLabel = "Rápido";
      }

      return {
        id: index, // Se puede usar el índice como ID único para la fila
        driverName: op.driverName,
        vehicleDetailsID: op.vehicleDetailsID,
        day: startDay,
        endDay: endDay,
        start: start ? start.toLocaleTimeString() : "N/A",
        end: end ? end.toLocaleTimeString() : "N/A",
        LastActivity: lastActivity ? lastActivity.toLocaleTimeString() : "N/A",
        routeTime: routeTime,
        asignados: op.asignados,
        delivery: op.delivery,
        deliveriesPerHour: deliveriesPerHour, // Promedio de entregas por hora
        speedLabel: speedLabel, // Para ordenar la velocidad
      };
    });

    setColumns(columnsTable);
    setRows(rowsTable);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fleet Data");

    // Generar archivo y descargarlo
    XLSX.writeFile(workbook, "FleetResumenData.xlsx");
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          mb: 1,
          textAlign: "right",
          textDecoration: "underline",
        }}
      >
        <Button onClick={handleExportToExcel}>Exportar a formato xls</Button>
      </Box>
      <DataGrid
        sx={{ backgroundColor: "#F8F8F8" }}
        rows={rows}
        columns={columns}
        pageSize={1000}
        rowsPerPage={[50, 100, 1000]}
        pagination
        localeText={customLocaleText}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "speed", sort: "asc" }],
          },
        }}
      />
    </>
  );
};

export default FleetResumenTable;
