import { db } from "../firebase";
import moment from "moment";
import axios from "axios";
import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
};

// types
const LOADING_MOMENT = "LOADING_MOMENT";
const USUARIO_LEADS = "USUARIO_LEADS";
const USUARIO_LEADS_ERROR = "USUARIO_LEADS_ERROR";

// reducer
export default function usuarioComentarios(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_MOMENT:
      return { ...state, loading: true };
    case USUARIO_LEADS:
      return { mjsstatus: true };
    case USUARIO_LEADS_ERROR:
      return { mjsstatus: false };
    default:
      return { ...state };
  }
}

export const registrarComentarios = (data) => async (dispatch) => {
  console.log("Inicio registrarComentarios");
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    //Consultar Contador
    const accountantsRef = doc(
      db,
      `${process.env.REACT_APP_COL_ACCOUNTANS}`,
      "commentsCounter"
    );
    const accountants = await getDoc(accountantsRef);
    //console.log(accountants.data().count);
    const documenCont = accountants.data().count + 1;
    const comentId = "coment" + documenCont;
    //Crear comentario
    //var date = Date.now()
    //var dateFormata = date.toLocaleString()
    //console.log(dateFormata)
    function nombrePropio(name) {
      let nombre = name;
      let cadena = nombre.toLowerCase().split(" ");
      for (var i = 0; i < cadena.length; i++) {
        cadena[i] = cadena[i].charAt(0).toUpperCase() + cadena[i].substring(1);
      }
      nombre = cadena.join(" ");
      return nombre;
    }

    const comentsCollection = collection(
      db,
      `${process.env.REACT_APP_COL_COMENTS}`
    );
    const commentDoc = doc(comentsCollection, comentId);

    await setDoc(commentDoc, {
      date: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      userName: nombrePropio(data.userName),
      userCompany: data.userCompany,
      userType: data.userType,
      email: data.email.toLowerCase(),
      userPhone: data.phone,
      mensaje: data.mensaje,
      roll: "lead",
      userActive: "false",
      userPreferens: data.userPreferenCont,
      propietary: "EBIEX",
      acepTerminos: data.checked,
      delete: false,
    });

    try {
      const accountants = await getDocs(
        collection(db, `${process.env.REACT_APP_COL_USERS}`)
      );

      const arrayData = accountants.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log(arrayData)
      const userExist = arrayData.filter(
        (item) => item.email === data.email.toLowerCase()
      );
      //console.log(userExist)
      if (userExist.length > 0) {
        //console.log('Existe en nuestra data')}else{
        //console.log('No Se Existe y se crean')

        const riderDocRef = doc(
          db,
          `${process.env.REACT_APP_COL_CONTACTS}`,
          data.email.toLowerCase()
        );
        await setDoc(riderDocRef, {
          date: Date.now(),
          userName: nombrePropio(data.userName),
          userCompany: data.userCompany,
          userType: data.userType,
          email: data.email.toLowerCase(),
          userPhone: data.phone,
          lead: true,
          userPreferens: data.userPreferenCont,
          propietary: "EBIEX",
          acepTerminos: true,
        });
      }
      //.doc(res.user.uid)
      //console.log(accountants.data().uid)
    } catch (error) {}

    const docRef = doc(
      db,
      `${process.env.REACT_APP_COL_ACCOUNTANS}/commentsCounter`
    );
    await setDoc(docRef, { count: documenCont });
    dispatch({
      type: USUARIO_LEADS,
      mjsstatus: true,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: USUARIO_LEADS_ERROR,
      mjsstatus: false,
    });
  }
};

export const enviarEmaildeFormularioCliente = (data) => async () => {
  console.log("Inicio enviarEmaildeFormularioCliente");

  function nombrePropio(name) {
    let nombre = name;
    let cadena = nombre.toLowerCase().split(" ");
    for (var i = 0; i < cadena.length; i++) {
      cadena[i] = cadena[i].charAt(0).toUpperCase() + cadena[i].substring(1);
    }
    nombre = cadena.join(" ");
    return nombre;
  }
  try {
    const DATA = {
      date: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      userName: nombrePropio(data.userName),
      userCompany: data.userCompany,
      userType: data.userType,
      email: data.email.toLowerCase(),
      userPhone: data.phone,
      mensaje: data.mensaje,
      roll: "lead",
      userActive: "false",
      userPreferens: data.userPreferenCont,
      propietary: "EBIEX",
      acepTerminos: data.checked,
      delete: false,
    };

    const response = await axios.post(
      process.env.REACT_APP_URL_APIBICCICL,
      DATA,
      {
        // Desarrollo
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${process.env.REACT_APP_TOKEN_APIBICCICL}`,
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      console.log(response.status);
      console.log(response.statusText);
    }
  } catch (error) {
    console.log(error);
  }
};
