import React from "react";
import { useNavigate } from "react-router-dom";
import FrmContacto from "../../../components/formularios/FrmContacto/FrmContacto";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import useTitle from "../../../components/Seo/useTitle";
import useSeo from "../../../components/Seo/useSeo";

const Contacto = (props) => {
  const navigate = useNavigate();
  useTitle({ title: "Contacto BICCI EXPRESS" });
  useSeo({ description: "Contactanos!" });

  const activo = useSelector((store) => store.usuario.activo);

  //  console.log(activo)

  React.useEffect(() => {
    // console.log(activo)
    if (activo) {
      navigate("/dashboard/soporte");
    } else {
      navigate("/contacto");
    }
  }, [activo]);

  return (
    <div>
      <Container className="bodycontent">
        <br></br>
        <div>
          <FrmContacto />
        </div>
        <br></br>
      </Container>
    </div>
  );
};

export default Contacto;
