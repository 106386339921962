import React from "react";

import { Form, Container } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FadeInOut from "../animations/FadeInOut";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import SwipeableDrawerControlOrders from "../SwipeableDrawerControlOrders/SwipeableDrawerControlOrders";
import { useEffect } from "react";

const FormRouteInfo = (props) => {
  const dispatch = useDispatch();
  const mapRef = React.useRef();

  const [sortedArray, setSortedArray] = React.useState(null);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const [showEditO, setShowEditO] = React.useState(false);
  const toggleShowEditO = () => setShowEditO(!showEditO);

  const [reiniciandoStateServicie, setReiniciandoStateServicie] =
    React.useState(false);

  const [showEditD, setShowEditD] = React.useState(false);
  const toggleShowEditD = () => setShowEditD(!showEditD);

  useEffect(() => {
    if (props.dataordesbiccibyrider && props.orderslist) {
      let orders = props.dataordesbiccibyrider;
      const sortedArray = props.orderslist.map((index) => orders[index]);
      //console.log(sortedArray);

      setSortedArray(sortedArray); // return sortedArray[0];
    }
  }, [props && props.dataordesbiccibyrider && props.orderslist]);

  const handleSubmitFrom = () => {
    dispatch(props.updatedocumintroute);
  };

  const Cargando = () => {
    return (
      <div>
        {/* <CircularProgress /> */}

        <Container id="smallspiner" className="row">
          <div className="center-smallspiner">
            <img
              alt=""
              src="/ebiexlogosvg_animated.svg"
              className="smallspiner rotating"
            />
            <h3>Enviando...</h3>
          </div>
        </Container>
      </div>
    );
  };

  return reiniciandoStateServicie !== true ? (
    <Container>
      <Card
        className="typeMiniforms-routes"
        sx={{
          margintop: "5px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <Form>
          <div id="topControlRoutes">
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"} //success
              variant="contained"
              onClick={toggleShowEditO}
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
              }}
            >
              {props.originadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Origen
            </Button>
            <Button
              className="btn-control-route"
              color={props.destinationadress ? "success" : "secondary"}
              onClick={toggleShowEditD}
              variant="contained"
              sx={{
                width: "33.3%",
                height: "100%",
                margin: "0px",
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#1e2158",
              }}
            >
              {props.destinationadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Destino
            </Button>
            <SwipeableDrawerControlOrders
              dataordesbiccibyrider={sortedArray}
              orderslist={props.orderslist}
              originadress={props.originadress}
              destinationadress={props.destinationadress}
              dataordersexcepcions={props.dataordersexcepcions}
              dataOrderDeliverySusses={props.dataOrderDeliverySusses}
            />
          </div>
        </Form>

        <FadeInOut show={showEditO} duration={500}>
          <Card>
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Origen actual.</>}
              title={<div>{props.originadress} </div>}
            />
            <Divider />

            <Container
              className="formcontent routesfromIni"
              id="containerroutesfromIni"
            >
              <hr></hr>

              <Form onSubmit={handleSubmitFrom}>
                <Form.Group widths="equal" sx={{ padding: "5px" }}>
                  <SearchOrigin
                    panTo={panTo}
                    dispatch={dispatch}
                    props={props}
                    // toUserName={toUserName}
                    // setToAddress={setToAddress}
                    // setToAdressPhone={setToAdressPhone}
                    // setToUserEmail={setToUserEmail}
                    // setToAdressName={setToAdressName}
                    // setToAdressNumAddress={setToAdressNumAddress}
                    // setToAdressCity={setToAdressCity}
                    // setToAdressCountry={setToAdressCountry}
                    // setToAdressComuna={setToAdressComuna}
                    // setToAdressRegion={setToAdressRegion}
                    // setToCompanyAsignament={setToCompanyAsignament}
                    // setToPropietary={setToPropietary}
                    // setToAdressComent={setToAdressComent}
                    // setToGeoNumAddressLat={setToGeoNumAddressLat}
                    // setToGeoNumAddressLng={setToGeoNumAddressLng}
                    // setToAdressNumAddressTemp={setToAdressNumAddressTemp}
                    id="imputSearchFromOrigen"
                  />
                  <Button
                    sx={{
                      margin: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#591e8f",
                      color: "#fff",
                    }}
                    type="sutmit"
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditO(false)}
                  >
                    Confirmar Origen!
                  </Button>
                  <Button
                    sx={{ margin: "2px", color: "#F33107" }}
                    onClick={() => setShowEditO(false)}
                  >
                    Cancelar
                  </Button>
                </Form.Group>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                ></Box>
              </Form>
            </Container>

            <Divider />
          </Card>
        </FadeInOut>
        <FadeInOut show={showEditD} duration={500}>
          <Card
            className="typeMiniforms-routes"
            sx={{
              margintop: "5px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Destino actual.</>}
              title={<div>{props.destinationadress} </div>}
            />
            <Divider />

            <Container
              className="formcontent  routesfromIni"
              id="containerroutesfromIorig"
            >
              <hr></hr>

              <Form onSubmit={handleSubmitFrom}>
                <Form.Group widths="equal" sx={{ padding: "5px" }}>
                  <Search
                    panTo={panTo}
                    dispatch={dispatch}
                    props={props}
                    id="imputSearchFromOrigen"
                  />
                  <Button
                    sx={{
                      margin: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#591e8f",
                      color: "#fff",
                    }}
                    type="sutmit"
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditD(false)}
                  >
                    Confirmar destino!
                  </Button>
                  <Button
                    sx={{ margin: "2px", color: "#F33107" }}
                    onClick={() => setShowEditD(false)}
                  >
                    Cancelar
                  </Button>
                </Form.Group>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                ></Box>
              </Form>
            </Container>

            <Divider />
          </Card>
        </FadeInOut>
        <Card
          className="typeMiniforms-routes"
          sx={{
            margintop: "5px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <CardHeader
            sx={{
              padding: "5px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }}
            subheader={<>Sugerencia de proxima parada.</>}
            title={
              <div>
                {" "}
                {sortedArray && sortedArray.length > 0 ? (
                  <div>
                    <div>{sortedArray[0].id}</div>
                    <div>{sortedArray[0].toAddress}</div>
                  </div>
                ) : null}
              </div>
            }
          />
          <Divider />

          <Container sx={{ maxHeight: "50px" }}>
            <hr></hr>
          </Container>

          <Divider />
        </Card>
      </Card>
    </Container>
  ) : (
    <div>
      <Cargando />
    </div>
  );
};

function Search({ panTo, dispatch, props }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      props.setdestinationadress(address);

      const { lat, lng } = await getLatLng(results[0]);
      props.setdestination({ lat: lat, lng: lng });
      panTo({ lat: lat, lng: lng });
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de destino"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
function SearchOrigin({ panTo, dispatch, props }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      props.setoriginadress(address);
      const { lat, lng } = await getLatLng(results[0]);
      props.setorigin({ lat: lat, lng: lng });
      panTo({ lat: lat, lng: lng });
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de origen"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default FormRouteInfo;
