import { ContactSupportOutlined } from "@mui/icons-material";
import { useState, useCallback, useEffect } from "react";

// ----------------------------------------------------------------------

export default function UseTableOrdersF(props) {
  //console.log(props);
  const [dense, setDense] = useState(!!props?.defaultDense);

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || "name");

  const [ordersForAccions, setOrdersForAccions] = useState(
    props?.ordersForAccions || []
  );

  const [selectedData, setSelectedData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(
    props?.defaultRowsPerPage || 5
  );

  const [order, setOrder] = useState(props?.defaultOrder || "asc");

  const [selected, setSelected] = useState(props?.defaultSelected || []);

  const onSort = useCallback(
    (timeStamp) => {
      const isAsc = orderBy === timeStamp && order === "asc";
      if (timeStamp !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(timeStamp);
      }
    },
    [order, orderBy]
  );

  const onSelectRow = useCallback(
    (inputRow) => {
      const rowId = "F" + inputRow.id; // Asegurándonos de tener el prefijo "F"
      const exists = selected.includes(rowId);

      const newSelected = exists
        ? selected.filter((id) => id !== rowId)
        : [...selected, rowId];

      const newSelectedData = exists
        ? selectedData.filter((row) => row.id !== rowId)
        : [...selectedData, inputRow];

      setSelected(newSelected);
      setSelectedData(newSelectedData);
      if (!arraysAreEqual(ordersForAccions, newSelected)) {
        setOrdersForAccions(newSelected);
      }
      //props.UpdateOrderForAcctions(newSelected);
    },
    [selected, selectedData]
  );
  useEffect(() => {
    // console.log("selected", selected);
    // console.log("selectedData", selectedData);
    // console.log("ordersForAccions", ordersForAccions);
    // console.log("------- fin -------");
  }, [selected]);

  useEffect(() => {
    if (!arraysAreEqual(ordersForAccions, selected)) {
      setSelected(ordersForAccions);
    }
  }, [ordersForAccions]);

  useEffect(() => {
    if (props.ordersForAccions) {
      setOrdersForAccions(props?.ordersForAccions);
    }
  }, [props?.ordersForAccions]);

  const onChangeRowsPerPage = useCallback((event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  const onChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  const onSelectAllRows = useCallback((checked, inputValue) => {
    if (checked) {
      setSelected(inputValue);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const onUpdatePageDeleteRow = useCallback(
    (totalRowsInPage) => {
      setSelected([]);
      if (page) {
        if (totalRowsInPage < 2) {
          setPage(page - 1);
        }
      }
    },
    [page]
  );

  const onUpdatePageDeleteRows = useCallback(
    ({ totalRows, totalRowsInPage, totalRowsFiltered }) => {
      const totalSelected = selected.length;

      setSelected([]);

      if (page) {
        if (totalSelected === totalRowsInPage) {
          setPage(page - 1);
        } else if (totalSelected === totalRowsFiltered) {
          setPage(0);
        } else if (totalSelected > totalRowsInPage) {
          const newPage =
            Math.ceil((totalRows - totalSelected) / rowsPerPage) - 1;
          setPage(newPage);
        }
      }
    },
    [page, rowsPerPage, selected.length]
  );

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  return {
    dense,
    order,
    page,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onResetPage,
    onChangeRowsPerPage,
    onUpdatePageDeleteRow,
    onUpdatePageDeleteRows,
    //
    setPage,
    setDense,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,
  };
}
