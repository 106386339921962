import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DirectionsService,
  DirectionsRenderer,
  Polygon,
} from "@react-google-maps/api";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { formatRelative } from "date-fns";

import "@reach/combobox/styles.css";
import mapStyles from "../../../components/MapsGoogle/mapStyles";
import { Button } from "@mui/material";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import HomeIcon from "@mui/icons-material/Home";

const libraries = ["drawing"];
const mapContainerStyle = {
  height: "50vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 8,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};
const searchOptions = {
  componentRestrictions: {
    country: "Cl",
  },
};
const markerBytext = {
  lat: -33.448891,
  lng: -70.669266,
  time: new Date(),
};

export default function MapsRoutes(props) {
  //// console.log(props)

  const [markers, setMarkers] = React.useState([]);

  const [renderPoint, setRenderPoint] = React.useState(true);
  const toggleShowEdit = () => setRenderPoint(!renderPoint);

  const [responseService, setResponse] = React.useState(null);

  const [distancia, setDistancia] = React.useState(null);
  const [duracion, setdDuracion] = React.useState(null);
  const [routeOrder, setRouteOrder] = React.useState(null);
  const [routeItems, setRouteItems] = React.useState(null);
  const [travelMode, setTravelMode] = React.useState(null);
  //const markerRef = useRef(null)

  const google = window.google;

  const mapRef = React.useRef();

  const polygonRef = React.useRef(null);
  const listenersRef = React.useRef([]);

  // Bind refs to current Polygon and listeners

  const [path, setPath] = React.useState([
    { lat: -33.34453276257303, lng: -70.64897031502636 },
    { lat: -33.36231286263612, lng: -70.62287778572949 },
    { lat: -33.38646790289626, lng: -70.59026212410839 },
    { lat: -33.392272713724175, lng: -70.54528684334667 },
    { lat: -33.39585573679032, lng: -70.5226275415889 },
    { lat: -33.449869810665106, lng: -70.53155393319042 },
    { lat: -33.436262004463075, lng: -70.63249082283886 },
  ]);

  const eventHandlers = (latLng) => {
    //.onDragend{// console.log(latLng)}
  };

  const getDataRoute = (routes, travelMode) => {
    // // console.log(routes);
    var n = routes[0].legs.length;
    var distanceMtrsValue = 0;
    var distanceSegDuration = 0;
    for (let i = 0; i < n; i++) {
      distanceMtrsValue += routes[0].legs[i].distance.value;
      distanceSegDuration += routes[0].legs[i].duration.value;
    }
    // console.log(distanceMtrsValue/1000)
    // console.log(distanceSegDuration/60)
    setDistancia(distanceMtrsValue / 1000);
    setdDuracion(distanceSegDuration / 60);
    setRouteOrder(routes[0].waypoint_order);
    setRouteItems(routes[0].waypoint_order.length);
    setTravelMode(travelMode);
  };

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        setResponse(response);
        // console.log(response)
        getDataRoute(response.routes, response.request.travelMode);
      } else {
        // console.log("response: ", response);
      }
    } else {
      // console.log("response: OK ");
    }
  };

  const onMapClick = React.useCallback((e) => {
    // console.log(e.latLng.lat())
    // console.log(e.latLng.lng())
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const ClearMap = React.useCallback((e) => {
    setMarkers(() => []);
  }, []);

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onMapLoadRute = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const newMarker = React.useCallback(({ lat, lng }) => {
    // console.log('Marca por Imput')
    //mapRef.current.panTo({ lat, lng });
    //mapRef.current.setZoom(14);
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const onEdit = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  const onLoad = React.useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = React.useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  console.log("The path state is", path);

  const pathCoordinates = [
    { lat: -33.4171429, lng: -70.6069663 },
    { lat: -33.4171429, lng: -70.6051214 },
    { lat: -33.4172862, lng: -70.6035769 },
    { lat: -33.4173936, lng: -70.6027617 },
    { lat: -33.4178235, lng: -70.6019036 },
    { lat: -33.4181817, lng: -70.6013887 },
    { lat: -33.4177877, lng: -70.5997155 },
    { lat: -33.417322, lng: -70.5981709 },
    { lat: -33.4168205, lng: -70.596326 },
    { lat: -33.4166056, lng: -70.5945669 },
    { lat: -33.4160539, lng: -70.5923101 },
    { lat: -33.4156241, lng: -70.5915808 },
    { lat: -33.4148718, lng: -70.5920527 },
    { lat: -33.4140837, lng: -70.5924389 },
    { lat: -33.413833, lng: -70.5927821 },
  ];

  const testOrigen = { lat: -33.41856676168833, lng: -70.6027176809204 };
  const testDestino = { lat: -70.6027176809204, lng: -70.6027176809204 };
  const wayPoints = [
    {
      location: { lat: -33.41936375583752, lng: -70.59555081843261 }, // Jardín Botánico
      stopover: true,
    },
    {
      location: { lat: -33.41404435300238, lng: -70.60082940577392 }, // Parque la 93
      stopover: true,
    },
    {
      location: { lat: -33.41440257217985, lng: -70.59752492426757 }, // Maloka
      stopover: true,
    },
    /**
    {
      location: { lat:-33.415477220846874, lng: -70.59426335810547}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.41800259286872, lng: -70.59224633692627}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.418217514756016, lng: -70.58877019404297}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.418217514756016, lng: -70.58877019404297}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.421208455806195, lng: -70.58789042948608}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.42342920765859, lng: -70.59153823374633}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.42500519064097, lng: -70.59737472056274}, // Parque la 93
      stopover: true,
    },
    {
      location: { lat:-33.42267702387671, lng: -70.60273913859253}, // Parque la 93
      stopover: true,
    },
 */
  ];

  const DirectionsServiceOption = {
    origin: testOrigen,
    destination: testDestino,
    travelMode: "DRIVING",
    optimizeWaypoints: false,
    waypoints: wayPoints,
    // travelMode: "DRIVING",
    //  travelMode: "WALKING",
    travelMode: "BICYCLING",
  };

  const testOrigen2 = { lat: -33.413833, lng: -70.5927821 };
  const testDestino3 = { lat: -33.41871899709103, lng: -70.59110908030395 };

  const DirectionsServiceOption2 = {
    origin: testOrigen2,
    destination: testDestino3,
  };

  // // console.log(DirectionsServiceOption)

  const Addrutes = () => {
    return (
      <DirectionsService
        options={DirectionsServiceOption}
        callback={directionsCallback}
      />
    );
  };

  const chekPoinyt = async () => {
    const polygonBicci = new google.maps.Polygon({ paths: path });
    if (
      google.maps.geometry.poly.containsLocation(
        { lat: -33.413833, lng: -70.5927821 },
        polygonBicci
      )
    ) {
      console.log(
        google.maps.geometry.poly.containsLocation(
          { lat: -33.413833, lng: -70.5927821 },
          polygonBicci
        )
      );
      console.log("el Geopunto esta dentro del poligono");
    } else {
      console.log(
        google.maps.geometry.poly.containsLocation(
          { lat: -33.413833, lng: -70.5927821 },
          polygonBicci
        )
      );
      console.log("el Geopunto esta fuera de perimetro del poligono");
    }
  };

  const donwLoadPolygons = () => {
    console.log("donwLoadPolygons");
  };

  const showTools = () => {
    console.log("showTools");
  };

  return (
    <div>
      <hr></hr>

      <div id="mapsControl">
        <h3>
          <span role="img" aria-label="tent">
            <img id="logomaps" src="/PointMark.svg" />
          </span>
        </h3>
        <Button className="btn btn-secundary sm" onClick={toggleShowEdit}>
          <HomeIcon />
          Marcar Punto
        </Button>
        <Button className="btn btn-secundary sm" onClick={ClearMap}>
          <CleaningServicesIcon />
          Limpiar marcadores
        </Button>
        <div className="DisplayBtnMaps">
          <Locate panTo={panTo} id="imputLocale" />
          <Search panTo={panTo} id="imputSearch" />
        </div>
        <div className="metricscontainer">
          <div className="boxmetrics">
            {travelMode ? (
              <span>
                Tipo de vehículo: <h1>{travelMode}</h1>
              </span>
            ) : (
              <></>
            )}

            {distancia ? <h2>{distancia} KM</h2> : <></>}
            {duracion ? <h2>{duracion.toFixed(2)} Minutos</h2> : <></>}

            {routeItems ? <h2>{routeItems} Paquetes</h2> : <></>}
          </div>
          <div className="boxmetrics">
            <h2>Orden Óptimo de Ruta</h2>
            {routeOrder ? (
              <>
                {routeOrder.map((item) => (
                  <div>Paquete {item} </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div>
        <GoogleMap
          id="mapControl"
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          options={options}
          onLoad={onMapLoadRute}
        >
          <Marker
            draggable={true}
            position={{ lat: -33.4171429, lng: -70.6069663 }}
            onDragEnd={(e) => {
              // console.log(e.latLng)
              // console.log(e.latLng.lat())
              // console.log(e.latLng.lng())
            }}
          />
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            //  onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            //  onDragEnd={onEdit}
            //  onLoad={onLoad}
            //  onUnmount={onUnmount}
          />

          {responseService !== null && (
            <DirectionsRenderer
              options={{
                directions: responseService,
              }}
            />
          )}
          <Addrutes />
        </GoogleMap>
      </div>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button
      className="locate btn-primary m-1"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <ShareLocationIcon></ShareLocationIcon>Encuentrame
    </button>
  );
}

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      searchOptions: {
        componentRestrictions: {
          country: "Cl",
        },
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    // console.log(address)
    setValue(address, false);
    clearSuggestions();
    let element = [];
    try {
      const results = await getGeocode({ address });
      // // console.log(results[0].address_components[0].long_name);
      // // console.log(results[0].address_components[1].long_name);
      // // console.log(results[0].address_components[2].long_name);
      // // console.log(results[0].address_components[3].long_name);
      // // console.log(results[0].address_components[4].long_name);
      // // console.log(results[0].address_components[5].long_name);
      // // console.log(results[0].address_components[6].long_name);
      // console.log(results[0])
      // console.log(results[0].address_components.length)

      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      //  si funcion
      markerBytext.lat = lat;
      markerBytext.lng = lng;
      // console.log(lat);
      // console.log(lng);
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element)
    }
  };

  return (
    <div className="search">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-3"
          id="input-ebiex-maps"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Busca tu ubicación"
        />
        <ComboboxPopover className="comboboxList-ebiex">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={id}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

function LoadMarket(selected, setSelected, setMarkers) {
  return (
    <>
      <Marker
        onDragEnd={(e) => {
          markerBytext.lat = e.latLng.lat();
          markerBytext.lng = e.latLng.lng();
          // console.log(e.latLng)
          // console.log(e.latLng.lat())
          // console.log(e.latLng.lat())
        }}
        draggable={true}
        key={`${markerBytext.lat}-${markerBytext.lng}`}
        position={{ lat: markerBytext.lat, lng: markerBytext.lng }}
        icon={{
          url: `/PointMark.svg`,
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(15, 15),
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        onClick={() => {
          setSelected(markerBytext);
        }}
        onChange={() => {
          setSelected({ lat: markerBytext.lat, lng: markerBytext.lng });
          // setMarkers({ lat: markerBytext.lat, lng: markerBytext.lng })
        }}
        // console.log(selected)
      />
      {selected ? (
        <InfoWindow
          position={{ lat: selected.lat, lng: selected.lng }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div>
            <h2>
              <span role="img" aria-label="BICCI EXPRESS">
                <img src="/PointMark.svg" />
              </span>{" "}
              Punto de Retiro
            </h2>
            <p>Marcado {formatRelative(selected.time, new Date())}</p>
          </div>
        </InfoWindow>
      ) : null}
    </>
  );
}
