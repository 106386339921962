function useGlobalObject() {
  const legs = [
    // Sample
    {
      duration: {
        value: 1360,
        text: "23 min",
      },
      distance: {
        text: "14,3 km",
        value: 14277,
      },
      start_address:
        "Av. Manuel Montt 195, Santiago, Providencia, Región Metropolitana, Chile",
      start_location: {
        lng: -70.61920429999999,
        lat: -33.4304996,
      },
      end_address:
        "Av. Sta. Rosa 4237, San Miguel, San Joaquín, Región Metropolitana, Chile",
      end_location: {
        lat: -33.4953112,
        lng: -70.6399852,
      },
      estimatedDeliveryTime: "11 September 2022, 09:36",
    },
    {
      end_location: {
        lng: -70.61920429999999,
        lat: -33.4304996,
      },
      start_location: {
        lng: -70.6399852,
        lat: -33.4953112,
      },
      estimatedDeliveryTime: "11 September 2022, 10:16",
      start_address:
        "Av. Sta. Rosa 4237, San Miguel, San Joaquín, Región Metropolitana, Chile",
      duration: {
        text: "24 min",
        value: 1466,
      },
      end_address:
        "Av. Manuel Montt 195, Santiago, Providencia, Región Metropolitana, Chile",
      distance: {
        text: "18,5 km",
        value: 18480,
      },
    },
  ];

  const wayPoints = [
    // Sample
    {
      stopover: true,
      location: {
        lat: -33.4953089,
        lng: -70.6399938,
      },
    },
  ];

  const currentOrderList = {
    0: "6718488",
    1: "190614218",
  };
  const waypoint_order = [0, 1, 2, 3];
  // origin:{lat: -33.4305982, lng: -70.6194124}

  const newRoute = {
    driverName: "", //1
    currentOrderList: {}, //2
    durationValue: 0, //3
    endTimeDelivery: "", //4
    legs: [], //5
    driverID: "", //6
    startTimeDelivery: "", //7
    origin: { lat: -33.4592491, lng: -70.6010891 }, //8
    id: "", //9
    lastModify: "", //10
    dispacthDelyvery: 0, //11
    waypoint_order: [], //12
    date: 0, //13
    travelMode: "", //14
    dateDelivery: "", //15
    routeStates: 0, //16
    dateDeliveryText: "", //17
    isDelete: false, //18
    isActive: true, //19
    routePoints: 0, //20
    dispacthPartials: 0, //21
    destination: { lat: -33.4592491, lng: -70.6010891 }, //22
    wayPoints: [], //23
    maxTimeDelivery: "00:10", //24
    vehicleID: "", //25
    warnings: "", //26
    distanceValue: 0, //27
    optimizeWaypoints: false, //28
    companyId: "", //29
    createBy: "", //30
    dispacthNotDelyvery: 0, //31
    setRoutePoints: 0, //32
    createDate: "0", //33
    fullCompliance: 0, //34
  };
  const stringRequiere = "isRequire";
  const string = "";
  const number = 0;
  const boolean = false;

  const orderObject = {
    //Origen
    companyId: stringRequiere, // ID de Compañía
    company: stringRequiere, // Compañía
    commerce: stringRequiere, // Comercio nombre de empresa
    warehouseCode: string, // Código de Bodega
    warehouseName: string, // Nombre de Bodega
    placeName: string,
    travelDate: string, // Fecha de Viaje
    windowStart: string, // Hora de Inicio de la Ventana de Entrega
    windowEnd: string, // Hora de Finalización de la Ventana de Entrega
    po: string, // Purchase Order
    f12: string, // F12 codigo de bodega
    lpn: string, // LPN (Número de Identificación)
    travelNumber: string, // Número de Viaje
    paperless: string, // Paperless (Digitalización de Documentos)
    scheduledDateRange: string, // Rango de Fechas Programadas
    soc: number, // SOC (Solicitud de
    paperlessCode: string, // Código Paperless
    paperlessRecipient: string, // Destinatario Paperless
    paperlessRut: string, // RUT Paperless
    parentOrder: string, // Orden Padre
    ct: string, // CT (Certified Trainer)
    do: string, // DO (Documento de Origen)
    lpnContainer: string, // Contenedor LPN
    quantity: number,
    quantity_packages: number,
    max_delivery_date: string,

    //Origen
    fromAddress: string,
    fromAddressLocation: {
      lng: number,
      lat: number,
    },
    userEmail: string,
    userID: string,
    userName: string,
    userPhone: string,

    //Destino
    clientNames: string, // Nombres del Cliente
    clientRut: string, // RUT del Cliente
    clientEmail: string, // Correo Electrónico del Cliente
    toAddress: string, // Dirección
    direccion1: string, // Dirección 1
    direccion2: number, // Dirección 2
    direccion3: string, // Dirección 3
    commune: string, // Comuna
    commune2: string, // Comuna 2
    region: string, // Región
    addressGeoNumAddressLatLng: {
      lng: number,
      lat: number,
    }, // Coordenadas Geográficas de la Dirección
    // Detalles Solicitud de Viaje
    clientStatus: string, // Estado Falabella falabellaStatus
    clientDescriptor: string, // Descricion deñ Estado ejemplo Falabella falabellaStatus
    simpliRouteId: string, // ID de SimpliRoute
    documentType: string, // Tipo de Documento
    serviceType: string, // Tipo de Viaje
    deliveryDate: string, // Fecha de Entrega
    paymentRequestId: string, // ID de Solicitud de Pago
    origin: string, // Origen
    createdAt: {
      seconds: number,
      nanoseconds: number,
    }, // Fecha de Creación
    polygonID: string, // ID de Polígono
    bicciStatus: number, // Estado Bicci
    statusCreate: boolean, // Estado de Creación
    timeStamp: {
      seconds: number,
      nanoseconds: number,
    }, // Marca de Tiempo
    status: number, // Estado
    tamanoProducto: string, // Tamaño del Producto
    description: string, // Descripción
    zonePoligon: string, // Polígono de Zona
    //Accion
    pickedUp: string, // Recogido
    // Detalles del producto
    length: string, // Largo
    height: string, // Altura
    width: string, // Ancho
    weight: string, // Peso
    product: string, // Producto
    serviceLevel: string, // Nivel de Servicio
    sku: string, // SKU (Unidad de Control de Stock)
    productSize: string, // Tamaño del Producto
    units: string, // Unidades
    value: string, // Valor
    volume: string, // Volumen
    deliveryComment: string, // Comentario de Entrega
    multiDelivery: boolean, // Multi Parcel
    truckDescription: string, // Descripción del Camión
    // detalles del conductor
    driverAvatar: string,
    driverEmail: string, // Correo Electrónico del Conductor
    driverName: string, // Nombre del Conductor
    driverRut: string, // RUT del Conductor
    driverID: string, // ID del Conductor
    driverPhone: string,
    driverLocation: {
      lng: number,
      lat: number,
    },
    licensePlate: string, // Patente del Vehículo
    // Detalles de Ruta
    distanceTotalization: string, // Totalización de Distancia
    estimatedDate: string, // Fecha Estimada
    routeStartDate: string, // Fecha de Inicio de la Ruta
    scheduledDate: string, // Fecha Programada
    endTime: string, // Hora de Finalización
    startTime: string, // Hora de Inicio
    routeId: string, // ID de la Ruta
    routePosition: string, // Posición en la Ruta
    locality: string, // Localidad
    deliveryMethod: string, // Método de Entrega
    deliveryFailureReason: string, // Motivo de Fallo de Entrega
    //Detalles de entrega
    deliveryLatitude: string, // Latitud de Entrega
    deliveryLongitude: string, // Longitud de Entrega
    pictures: string,
    actualDeliveryDate: string, // Fecha de Entrega Real
    aMountRate: number,
    amountPromotions: number,
    mountOtherEarnings: number,
    currencyCode: "CLP",
    currencySimbol: "$",
    // Detalles de Pagos
    proforma: string, // Proforma
    transportOrderSize: string, // Tamaño de Orden de Transporte
    paymentRequestType: string, // Tipo de Solicitud de Pago
  };

  return { newRoute };
}

export default useGlobalObject;
