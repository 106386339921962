import React from "react";
import { Image } from "semantic-ui-react";
import FormRegistro from "./FormRegistro";

function formularioDeRegistro() {
  return <FormRegistro />;
}
function LoginEmail() {
  return (
    <div className="mt-5 text-center formcontainerr">
      <div id="brandsLogo">
        <Image
          className="brands"
          src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(24).svg?alt=media&token=ef6abc30-ba25-4422-bd45-5821ae5dc2cf"
        />
      </div>
      <h2>REGISTRARSE</h2>
      {formularioDeRegistro()}
    </div>
  );
}

export default LoginEmail;
