import React from "react";
import Emailmodel1 from "./Emailmodel1";
import Emailmodel2 from "./Emailmodel2";
import Emailmodel3 from "./Emailmodel3";
import EmailmodelConfirm1 from "./EmailmodelConfirm1";
import EmailmodelConfirm2 from "./EmailmodelConfirm2";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export const EmailMokups = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const emailData = location.state;
  const { id } = useParams();
  if (!emailData) {
    navigate(`/dashboard/dispatch/details/${id}`);
  }

  return (
    <div>
      <div className="h2-withe">EmailMokups Selector</div>
      {emailData && emailData.acction === "primerintento" ? (
        <Emailmodel1 emailData={emailData}></Emailmodel1>
      ) : null}
      {emailData && emailData.acction === "segundointento" ? (
        <Emailmodel2 emailData={emailData}></Emailmodel2>
      ) : null}
      {emailData && emailData.acction === "tercerintento" ? (
        <Emailmodel3 emailData={emailData}></Emailmodel3>
      ) : null}
      {emailData && emailData.acction === "confirmacion1" ? (
        <EmailmodelConfirm1 emailData={emailData}></EmailmodelConfirm1>
      ) : null}
      {emailData && emailData.acction === "confirmacion2" ? (
        <EmailmodelConfirm2 emailData={emailData}></EmailmodelConfirm2>
      ) : null}
    </div>
  );
};
