import React, { createContext, useState, useEffect, useMemo } from "react";
import { db } from "../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";

export const UserContext = createContext();

const CurrentUserProvider = ({ children }) => {
  const initialState = {
    uid: "notuid",
    userRole: "norole",
    companyRole: "nocompanyrole",
    companyID: "nocompanyid",
    companyData: null,
    rolePermissions: [],
    servicesStatus: [],
    isLoading: true,
    userdata: [],
  };

  const [userDataContext, setUser] = useState(initialState);
  const store = useSelector((store) => store.usuario.user);
  const userUid = store?.uid || "notuid";

  useEffect(() => {
    const fetchUserData = async (uid) => {
      const userDoc = await getDoc(
        doc(db, process.env.REACT_APP_COL_USERS, uid)
      );
      if (!userDoc.exists()) {
        setUser((current) => ({ ...current, isLoading: false }));
        return;
      }
      const userData = userDoc.data();

      const [companyData, rolePermissions, servicesQuerySnapshot] =
        await Promise.all([
          userData.userCompanyID
            ? getDoc(
                doc(
                  db,
                  process.env.REACT_APP_COL_COMPANIES,
                  userData.userCompanyID
                )
              )
            : Promise.resolve({ exists: () => false }), // Retorna un objeto simulado si no hay companyID
          getDoc(
            doc(db, process.env.REACT_APP_COL_ROLES_PERMISOS, userData.roll)
          ),
          getDocs(collection(db, process.env.REACT_APP_COL_SERVICIOS)), // Ajustado para usar collection en lugar de db directamente
        ]);

      //console.log(companyData.data(), rolePermissions.data(), servicesStatus);

      setUser({
        uid,
        roll: userData.roll,
        companyRole: userData.userCompanyRoll,
        companyCollaborator: userData.companyCollaborator,
        companyID: userData.userCompanyID,
        userData: userData ? userData : null,
        companyData: companyData.exists() ? companyData.data() : null,
        rolePermissions: rolePermissions.exists() ? rolePermissions.data() : [],
        servicesStatus: servicesQuerySnapshot
          ? servicesQuerySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          : [],
        isLoading: false,
      });
    };

    if (userUid !== "notuid") {
      fetchUserData(userUid);
    }
  }, [userUid]);

  const contextValue = useMemo(() => ({ userDataContext }), [userDataContext]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default CurrentUserProvider;
