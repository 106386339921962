import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { StyledCard, StyledTextField, StyledSelect } from "./FromStyles";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { Divider } from "semantic-ui-react";

const FromShippingDescriptions = ({
  onChange,
  dataOrigin,
  dataDirectory,
  handleDataOrder,
  companyData,
}) => {
  const [viewAddItemDelivery, setViewAddItemDelivery] = useState(true);
  const [producList, setProducList] = useState([]);
  const [multipack, setMultipack] = useState(false);
  const [fragile, setFragile] = useState(false);
  const [producItem, setProducItem] = useState({
    productLength: "",
    productHeight: "",
    productWidth: "",
    productWeight: "",
    productName: "",
    productSku: "",
    productSize: "",
    productUnits: "",
    productValue: "",
    productVolume: "",
  });

  useEffect(() => {
    handleDataOrder({ products: producList });
  }, [producItem]);

  const handleSelections = (item) => {
    if (item) {
      setProducItem((prevState) => ({
        ...prevState,
        productLength: item.productLength,
        productHeight: item.productHeight,
        productWidth: item.productWidth,
        productWeight: item.productWeight,
        productName: item.productName,
        productSku: item.productSku,
        productSize: item.productSize,
        productUnits: item.productUnits,
        productValue: item.productValue,
        productVolume: item.productVolume,
      }));
    }
  };

  const handleLadProduct = () => {
    if (!viewAddItemDelivery) {
      setProducItem({
        productLength: "",
        productHeight: "",
        productWidth: "",
        productWeight: "",
        productName: "",
        productSku: "",
        productSize: "",
        productUnits: "",
        productValue: "",
        productVolume: "",
      });
      setViewAddItemDelivery(!viewAddItemDelivery);
    } else {
      console.log(
        "Validar si los campos están completos Agregar o detener carga"
      );
      console.log(producItem);
      const allFieldsFilled = Object.values(producItem).every(
        (value) => value !== ""
      );
      if (allFieldsFilled) {
        setViewAddItemDelivery(!viewAddItemDelivery);
        let arrayObj = producList;
        arrayObj.push(producItem);
        setProducList(arrayObj);
      } else {
        console.log(
          "Todos los campos deben estar llenos antes de agregar el producto."
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeFragile = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setFragile(e.target.checked);
    setProducItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeMultipack = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setMultipack(e.target.checked);
    setProducItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        sx={{
          color: "#fff", // Texto blanco
          borderRadius: "10px 10px 0 0",
          padding: "1px",
          paddingTop: "1px",
        }}
      />
      <CardContent sx={{ padding: "1px" }}>
        <Typography sx={{ color: "#fff" }}>
          {" "}
          {"Seleccionar de Inventarios"}
        </Typography>
        <FormControl fullWidth margin="dense">
          <Select
            sx={{
              backgroundColor: "#fff", // Aplica fondo blanco al select
              ".MuiOutlinedInput-input": {
                borderRadius: "10px",
                padding: "10px",
                fontSize: "1rem",
                color: "#333",
              },
            }}
            labelId="Seleccione Tipo de sevico"
            id="medidas"
            value="ption.value"
            onChange={(e) => console.log("Data selector")}
            label=""
            input={
              <OutlinedInput
                notched
                // startAdornment={<LocationOnIcon color="secondary" />}
              />
            }
          >
            <MenuItem key="SD" value="SD">
              {"Inventory Opcions"}
            </MenuItem>
          </Select>
        </FormControl>
        <Divider></Divider>
        {/* <StyledTextField
          name="Descripción del producto"
          value={""}
          onChange={handleChange}
          placeholder="Descripción del producto"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />{" "}
        <StyledTextField
          name="Cantidad"
          value={""}
          onChange={handleChange}
          placeholder="Cantidad del producto"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />{" "}
        <StyledTextField
          name="Valor"
          value={""}
          onChange={handleChange}
          placeholder="Valor del producto"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />{" "}
        <Typography sx={{ color: "#fff" }}></Typography> */}

        <IconButton
          onClick={() => handleLadProduct()}
          sx={{
            padding: "10px",
            backgroundColor: "#fff",
            color: "primary.main", // Color del ícono
            "&:hover": {
              backgroundColor: "primary.light", // Color en hover
            },
            marginBottom: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          {viewAddItemDelivery ? (
            <PublishIcon fontSize="large" sx={{ color: "green" }} />
          ) : (
            <AddCircleOutlineIcon fontSize="large" />
          )}
        </IconButton>
        <Typography sx={{ color: "#fff" }}> {"Ingrese Producto"}</Typography>
      </CardContent>
      {!viewAddItemDelivery ? (
        <CardContent
          sx={{
            padding: "1px",
            backgroundColor: "#fff",
            padding: "15px",
            borderRadius: "15px",
          }}
        >
          {producList.map((item, index) => (
            <Box key={index}>
              <Typography>Nombre: {item.productName} </Typography>
              <Typography>SKU: {item.productSku}</Typography>
            </Box>
          ))}
        </CardContent>
      ) : (
        <CardContent>
          <FormControlLabel
            control={
              <Switch
                checked={fragile}
                onChange={(e) => handleChangeFragile(e)}
                name={"fragile"}
                color="primary"
              />
            }
            label={"Frágil"}
          />
          <FormControlLabel
            control={
              <Switch
                checked={multipack}
                onChange={(e) => handleChangeMultipack(e)}
                name={"multipack"}
                color="primary"
              />
            }
            label={"Mutibulto"}
          />
          <StyledTextField
            name="productName"
            value={producItem.productName}
            onChange={handleChange}
            placeholder="Nombre Producto"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="description"
            value={producItem.description}
            onChange={handleChange}
            placeholder="Descripcion Producto"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="productSku"
            value={producItem.productSku}
            onChange={handleChange}
            placeholder="SKU"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="productValue"
            value={producItem.productValue}
            onChange={handleChange}
            placeholder="Valor"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="productSize"
            value={producItem.productSize}
            onChange={handleChange}
            placeholder="Tamaño de empaque"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1%" }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "33%",
                  lg: "33%",
                },
              }}
            >
              {" "}
              <StyledTextField
                name="productHeight"
                value={producItem.productHeight}
                onChange={handleChange}
                placeholder="Alto"
                fullWidth
                margin="normal"
                sx={{ flex: 1, marginTop: "1px", marginBottom: "1px" }}
              />
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "33%",
                  lg: "33%",
                },
              }}
            >
              <StyledTextField
                name="productLength"
                value={producItem.productLength}
                onChange={handleChange}
                placeholder="Largo"
                fullWidth
                margin="normal"
                sx={{ flex: 1, marginTop: "1px", marginBottom: "1px" }}
              />
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "33%",
                  lg: "33%",
                },
              }}
            >
              <StyledTextField
                name="productWidth"
                value={producItem.productWidth}
                onChange={handleChange}
                placeholder="Ancho"
                fullWidth
                margin="normal"
                sx={{ flex: 1, marginTop: "1px", marginBottom: "1px" }}
              />
            </Grid>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1%" }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                },
              }}
            >
              <StyledTextField
                name="productVolume"
                value={producItem.productVolume}
                onChange={handleChange}
                placeholder="Volumen"
                fullWidth
                margin="normal"
                sx={{ marginTop: "1px", marginBottom: "1px" }}
              />
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                },
              }}
            >
              <StyledTextField
                name="productWeight"
                value={producItem.productWeight}
                onChange={handleChange}
                placeholder="Peso del producto"
                fullWidth
                margin="normal"
                sx={{ marginTop: "1px", marginBottom: "1px" }}
              />
            </Grid>
          </Box>
          <StyledTextField
            name="productUnits"
            value={producItem.productUnits}
            onChange={handleChange}
            placeholder="Unidades"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
        </CardContent>
      )}
    </StyledCard>
  );
};

export default FromShippingDescriptions;
