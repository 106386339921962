import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/CurrentUserProvider"; // Asegúrate de importar correctamente tu contexto
import { Box, Typography } from "@mui/material";
import LoadingSpiner from "../components/LoadingSpinner/LoadingSpiner";

const RutaUnBlock = ({ children, serviceId }) => {
  const location = useLocation();
  const { userDataContext } = useContext(UserContext);

  // Verificar el estado del servicio y los permisos
  const serviceStatus = userDataContext.servicesStatus.find(
    (service) => service.id === serviceId
  );
  const userPermission = userDataContext.rolePermissions[serviceId];

  // Comprobar si el objeto serviceStatus es válido
  if (!serviceStatus) {
    return (
      <Box sx={{ textAlign: "center", padding: 3 }}>
        <LoadingSpiner transparent></LoadingSpiner>
      </Box>
    );
  }

  // Comprobar si el acceso está permitido
  const isAccessAllowed = userPermission?.state !== false; // Si userPermission no existe, se permite el acceso por defecto

  if (serviceStatus.state === false) {
    // Mostrar mensaje de servicio deshabilitado
    return (
      <Box sx={{ textAlign: "center", padding: 3 }}>
        <Typography variant="h4" gutterBottom color="error">
          Servicio Deshabilitado Temporalmente
        </Typography>
        <Typography variant="body1" color="error">
          Está en mantenimiento o cambió su URL.
        </Typography>
      </Box>
    );
  }

  if (!isAccessAllowed) {
    // Mostrar mensaje de acceso denegado
    return (
      <Box sx={{ textAlign: "center", padding: 3 }}>
        <Typography variant="h4" gutterBottom color="error">
          Acceso Restringido
        </Typography>
        <Typography variant="body1" color="error">
          No tienes permisos suficientes para esta sección.
        </Typography>
      </Box>
    );
  }

  return children;
};

export default RutaUnBlock;
