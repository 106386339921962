import React from "react";
import { db } from "../../../../firebase";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Grid } from "semantic-ui-react";
import "@reach/combobox/styles.css";
import mapStyles from "../../../../components/MapsGoogle/mapStyles";
import { useEffect, useRef } from "react";
import { useState } from "react";
import DashPlannerEditMap from "./DashPlannerEditMapRouteComponents/DashPlannerEditMap";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import useGlobalObject from "../../../../hooks/getObjetcs";
import useGlobalConfig from "../../../../hooks/globalConfig";

const mapContainerStyle = {
  height: "90vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 11,
};
const center = {
  lat: -33.448891,
  lng: -70.669266,
};

export default function DashPlannerEditMapRoute(props) {
  // console.log(props)

  //const { newRoute } = useGlobalObject();

  const { rosettaVehicles } = useGlobalConfig();

  const [expamMap, setExpamMap] = useState(false);
  const [wayPoints, setWayPoints] = useState("");
  const [currentOrderList, setCurrentOrderList] = useState([]);
  const [PacketAnalisys, setPacketAnalisys] = useState(null);
  const [render, setRender] = useState(false);
  const [selectedMarker, setSelectedMarker] = React.useState("");
  const [wiewButonCreateRoutes, setWiewButonCreateRoutes] = useState(false);
  const [itemsToRotute, setItemsToRoutes] = useState([]);
  const [validandoDocumento, setValidandoDocumento] = useState(false);
  const [newRouteoptimizeWaypoints, setNewRouteOptimizeWaypoints] =
    useState(false);
  const [optimizeWaypointsNodos, setOptimizeWaypointsNodos] = useState(false);
  const [haveAllDataRequire, setHaveAllDataRequire] = useState(false);

  const dragItem = useRef();

  const dragOverItem = useRef();

  useEffect(() => {
    if (itemsToRotute.length <= 0) {
      setWiewButonCreateRoutes(false);
    } else {
      setWiewButonCreateRoutes(true);
    }
  }, [itemsToRotute]);

  useEffect(() => {}, [newRouteoptimizeWaypoints]);

  useEffect(() => {
    // console.log(props.newRoute);
    //setNewRouteOptimizeWaypoints(props.newRoute.optimizeWaypoints)
  }, [props.newRoute]);

  useEffect(() => {
    setNewRouteOptimizeWaypoints(props.newRoute.optimizeWaypoints);
    setOptimizeWaypointsNodos(props.newRoute.optimizeWaypointsNodos);
    setHaveAllDataRequire(haveAllDataRequire);
  }, [
    props.newRoute.optimizeWaypoints && props.newRoute.optimizeWaypointsNodos,
    props.haveAllDataRequire,
  ]);

  useEffect(() => {
    setCurrentOrderList(props.newRoute.currentOrderList);
  }, [props.newRoute.currentOrderList]);

  useEffect(() => {
    //updatepacketAnalysis()
    setWayPoints(props.newRoute.wayPoints);
  }, [props && props.newRoute && props.newRoute.wayPoints]);

  useEffect(() => {}, [render]);

  useEffect(() => {
    if (props.companyId) {
    }
  }, [props.companyId]);

  //console.log(newRouteoptimizeWaypoints);

  // Evaluar que mapa se pinta - Nuevo mapa o consultar nuevamente la data ya cargada

  const updateMaps = () => {
    //console.log("NOTA: Evaluar si requiere nuevo mapa o solo pintar ");

    if (
      props.newRoute.destination === null ||
      props.newRoute.destination === undefined ||
      props.newRoute.destination.lat === 0 ||
      props.newRoute.origin.lat === undefined
    ) {
      props.newRoute.destination = { lat: -33.4592491, lng: -70.6010891 };
    }

    if (
      props.newRoute.origin === null ||
      props.newRoute.origin === undefined ||
      props.newRoute.origin.lat === 0 ||
      props.newRoute.origin.lat === undefined
    ) {
      props.newRoute.origin = { lat: -33.4592491, lng: -70.6010891 };
    } else {
      // console.log("props.newRoute.origin paso undefine");
      // console.log(props.newRoute.origin);
    }

    return (
      <DashPlannerEditMap
        visiblePoligon={true}
        packetAnalisys={PacketAnalisys}
        markerpoint={wayPoints}
        manifesOrders={props.manifesOrders}
        destination={{
          lat: props.newRoute.destination.lat, // - 33.444821110629384,
          lng: props.newRoute.destination.lng, //- 70.56838870839468,
        }}
        origin={{
          lat: props.newRoute.origin.lat, //-33.444821110629384,
          lng: props.newRoute.origin.lng, //-70.56838870839468,
        }} //props.newRoute.origin}
        travelMode={props.newRoute.travelMode}
        selectedMarker={selectedMarker}
        setSelectionedMarkerPing={setSelectionedMarkerPing}
        handleresposeapi={handleresposeapi}
        haveAllDataRequire={haveAllDataRequire}
        optimizeWaypointsNodos={optimizeWaypointsNodos}
        optimizeWaypointsComuna={true}
        optimizeWaypointsPolygons={true}
        optimizeWaypoints={newRouteoptimizeWaypoints}
        updateDataRouteOrderPiontByApi={props.updateDataRouteOrderPiontByApi}
      />
    );
    //manifesOrders
  };

  const handleresposeapi = (response) => {
    //console.log(response)

    var n = response.routes[0].legs.length;
    var distanceMtrsValue = 0;
    var distanceSegDuration = 0;
    for (let i = 0; i < n; i++) {
      distanceMtrsValue += response.routes[0].legs[i].distance.value;
      distanceSegDuration += response.routes[0].legs[i].duration.value;
    }
    // setDistancia(distanceMtrsValue/1000);
    // setdDuracion(distanceSegDuration/60);
    // setRouteOrder(routes[0].waypointorder)
    // setRouteItems(routes[0].waypoint_order.length)
    // setTravelMode(travelMode)
    // props.upDateDataRouteDetailsInfo(DataRouteDetailsInfo)
    let DataRouteDetailsInfo = {
      distanceMtrsValue: distanceMtrsValue / 1000,
      distanceSegDuration: distanceSegDuration / 60,
      waypoint_orderCant: response.routes[0].waypoint_order.length,
      travelMode: response.request.travelMode,
    };

    props.updateDataRouteOrderPiontByApi(
      response.routes[0].waypoint_order,
      DataRouteDetailsInfo
    );
  };

  const setSelectionedMarkerPing = (selectedMarker) => {
    setSelectedMarker(selectedMarker);
  };

  const dragStartItem = (e, position) => {
    dragItem.current = position;
  };

  const dragEnterItem = (e, position) => {
    dragOverItem.current = position;
  };

  const dropItem = (e, position) => {
    e.preventDefault();
    const copyListItems = currentOrderList;
    const dragItemContent = currentOrderList[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setCurrentOrderList(copyListItems);
    props.updateDataRouteOrderPiont(copyListItems);
    props.offOptimize();
  };

  const selectmarket = (element, index) => {
    setSelectedMarker({ ...wayPoints[index], positionList: index });
    setRender(!render);
  };

  return (
    <div>
      <Grid celled="internally" id="expresscuentaContent">
        {/*  // descomenta  en produccion
        <div id="mapsControlPlanRoutes">
          <div className="metricscontainer">
            <table>
              <tbody>
                <tr>
                  <th>Distancia estimada (Km):</th>
                  <td>{props.newRoute.distanceValue} Km</td>
                </tr>
                <tr>
                  <th>Duración de ruta (minutos):</th>
                  <td>{props.newRoute.durationValue} min</td>
                </tr>
                <tr>
                  <th>Tiempo de espera (minutos):</th>
                  <td>{props.newRoute.estimateAwaitTime} min</td>
                </tr>
                <tr>
                  <th>Total estimado de ruta</th>
                  <td>
                    {parseInt(props.newRoute.estimateAwaitTime) +
                      parseInt(props.newRoute.durationValue)}{" "}
                    min
                  </td>
                </tr>
                <tr>
                  <th>Total de paradas</th>
                  <td>{props.newRoute.routePoints}</td>
                </tr>
                <tr>
                  <th>Vehículo</th>
                  <td>{rosettaVehicles[props.newRoute.travelMode]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
         */}
        <Grid.Row>
          <Grid.Column
            computer={16}
            mobile={16}
            tablet={16}
            className={
              expamMap === true
                ? "dash-container-colmun expam-maps"
                : "dash-container-colmun"
            }
          >
            <div className="expamContainer">
              {expamMap === true ? (
                <button
                  className="btn-float-expam-back"
                  onClick={() => setExpamMap(false)}
                >
                  {" "}
                  <SettingsOverscanIcon></SettingsOverscanIcon>{" "}
                </button>
              ) : (
                <button
                  className="btn-float-expam"
                  onClick={() => setExpamMap(true)}
                >
                  {" "}
                  <SettingsOverscanIcon></SettingsOverscanIcon>{" "}
                </button>
              )}
            </div>
            {/*  // descomenta  en produccion
            <div className="panel-vertical">
              <div>
                <div className="m-2">
                  <div className="m-2"></div>
                  {validandoDocumento === true ? (
                    <div className="m-2">
                      <LinearProgress></LinearProgress>
                    </div>
                  ) : null}
                </div>
              
                <div className="featurelist-scrollable-container">
                  <div className="featurelist-scrollable-container-div">
                    <div className="listContentitems">
                      {currentOrderList.length > 0 ? (
                        <>
                          {currentOrderList.map((item, index) => (
                            <div
                              key={index}
                              onDragStart={(e) => dragStartItem(e, index)}
                              onDragEnter={(e) => dragEnterItem(item, index)}
                              onDragEnd={dropItem}
                              id={item}
                              onClick={(e) => selectmarket(item, index)}
                              draggable
                              className="listContentitemsgroup-disable dispachtelementGroup"
                            >
                              <div className="zoneName btn-edition-planner">
                                <span className="zoneNameh5">
                                  {" "}
                                  <span className="positionmarker">
                                    {index + 1}
                                  </span>
                                  {"  "}
                                  {item}
                                </span>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
 */}
            {wayPoints ? (
              updateMaps()
            ) : (
              <>
                <GoogleMap
                  id="mapControl"
                  mapContainerStyle={mapContainerStyle}
                  zoom={13}
                  center={center}
                  options={options}
                ></GoogleMap>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
