import React from "react";
import {
  Box,
  Container,
  CardActions,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import { Grid } from "semantic-ui-react";
import useGlobalConfig from "../../../hooks/globalConfig";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { getCart } from "../../../redux/acccionesCart";
import { useSelector, useDispatch } from "react-redux";
import {
  clientAddCartProduct,
  clientRemoveCartProduct,
} from "../../../redux/acccionesCart";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

var userUid = "noregister";
const Minicart = (props) => {
  const navigate = useNavigate();

  //console.log(props)
  const dispatch = useDispatch();
  const { imgPlaceOlderCart } = useGlobalConfig();
  const [lastItem, setlastItem] = React.useState(null);

  const cart = useSelector((store) => store.cartAccion.cartContent);
  const itemsCart = useSelector((store) => store.cartAccion.cart);
  const loading = useSelector((store) => store.cartAccion.loading);
  // console.log(loading)
  React.useEffect(() => {
    dispatch(getCart());
    //  LastItemsCart(itemsCart)
  }, [loading]);
  React.useEffect(() => {
    LastItemsCart(itemsCart);

    // console.log('SE ACTIVO')
  }, [loading]);

  const LastItemsCart = (itemsCart) => {
    if (itemsCart) {
      let cant = itemsCart.length - 1;
      let ultimo = itemsCart[cant];
      setlastItem(ultimo);
    } else {
      setlastItem(null);
    }
  };

  const handleCarmin = (e) => {
    e.preventDefault();
    // console.log('restar')
    // console.log(lastItem.cartArticleId)
    dispatch(clientRemoveCartProduct(lastItem.cartArticleId, userUid));
  };
  const handleCarplus = (e) => {
    e.preventDefault();
    // console.log('Sumar')
    // console.log(lastItem.cartArticleId)
    dispatch(clientAddCartProduct(lastItem.cartArticleId, userUid));
  };

  const handlechange = (e) => {
    e.preventDefault();
    // console.log('Revisar inventario o metodos')
    // console.log(lastItem.cartArticleId)
  };
  const handleCarPay = (e) => {
    // e.preventDefault();
    // console.log('Push to checkout')
    props.handleclosedminicart();
    //navigate(`/checkout/`)
  };

  const handleCarView = (e) => {
    // e.preventDefault();
    // console.log('Push to pagina carrito')
    props.handleclosedminicart();
    //navigate(`/cart/`)
  };
  const setAutoClosed = () => {
    function delay() {
      props.handleclosedminicart();
    }
    setTimeout(delay, 2000);
  };
  return lastItem !== null && lastItem ? (
    <>
      {cart ? (
        <>
          <div className="minicartBody">
            <Container className="cartproduct" id="cartproduct" key={"item.id"}>
              <CardContent className="CardContentminicard">
                <Box
                  sx={{
                    alignItems: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid>
                    <div className="btn btn-cart-closed">
                      <span
                        onClick={() => props.handleclosedminicart()}
                        className="closedBtn"
                      >
                        <CloseIcon className="colorRed f-size16"></CloseIcon>
                      </span>
                    </div>
                    <Grid.Row className="cart-Grid-Row">
                      <Grid.Column computer={7} mobile={16} tablet={16}>
                        <div className="boxcart image-information">
                          <div>
                            {" "}
                            <img
                              className="image-minicart"
                              src={`${imgPlaceOlderCart}`}
                              alt={""}
                            />
                            <CheckCircleIcon
                              color="success"
                              id="pinfloatInto"
                            />
                          </div>
                          <div className="product-cart-info">
                            <div className="text-h1-minicart">
                              Agregaste a tu carrito
                            </div>
                            <div className="text-p-minicart">
                              {lastItem.productName}
                            </div>
                          </div>
                          <div className="btn-cart-control">
                            <form className="form-cart-control">
                              <button
                                onClick={handleCarmin}
                                className="btn-min scrollto bicci-btn-hover"
                              >
                                -
                              </button>
                              <input
                                id="cartImputId"
                                type="text"
                                value={1}
                                onChange={handlechange}
                              />
                              <button
                                onClick={handleCarplus}
                                className="btn-plus scrollto bicci-btn-hover"
                              >
                                +
                              </button>
                            </form>
                          </div>
                        </div>
                      </Grid.Column>

                      <Grid.Column
                        computer={5}
                        mobile={16}
                        tablet={16}
                        className="cart-Grid-Column"
                      >
                        <div className="boxcart">
                          <div className="boxcart image-information">
                            <div className="product-cart-info">
                              <div className="text-p-minicart-cant">
                                {cart.cualitys} productos en tu carrito
                              </div>
                              <div className="text-p-minicart-amount">
                                $ {cart.totalAmount}
                              </div>
                              {cart.totalDiscount ? (
                                <div className="text-p-minicart-notice">
                                  ¡Oferta temporal!
                                </div>
                              ) : (
                                <div className="text-p-minicart-notice">
                                  ¡Mejor precio!
                                </div>
                              )}
                            </div>
                            <div>
                              {cart.cualitys === 2 ? (
                                <>
                                  <img
                                    className="image-minicart floatIn"
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                  <img
                                    className="image-minicart floatIn floatInto "
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              {cart.cualitys >= 3 ? (
                                <>
                                  <img
                                    className="image-minicart floatIn"
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                  <img
                                    className="image-minicart floatIn floatInto "
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                  <img
                                    className="image-minicart floatIn floatInto"
                                    src={`${imgPlaceOlderCart}`}
                                    alt={""}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={4} mobile={16} tablet={16}>
                        <div className="boxcart">
                          <NavLink
                            to={"/cart"}
                            className="btn-buy btn-buy-minicart scrollto m-1"
                            onClick={handleCarView}
                          >
                            {" "}
                            Ver carrito
                          </NavLink>
                          {/* <Button onClick={handleCarView}className="btn-buy btn-buy-minicart scrollto m-1">Ver carrito</Button> */}
                          <NavLink
                            to={"/checkout"}
                            className="btn-buy btn-buy-minicart scrollto m-1"
                            onClick={handleCarPay}
                          >
                            {" "}
                            Comprar carrito
                          </NavLink>
                          {/* <Button onClick={handleCarPay} className="btn-buy btn-buy-minicart scrollto m-1">Comprar carrito</Button>
                           */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Box>
              </CardContent>
            </Container>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <div className="minicartBody">
      {" "}
      <div className="section-title">
        <h2>Carrito esta Vacio</h2>
      </div>
      {lastItem !== null ? setAutoClosed() : ""}{" "}
    </div>
  );
};

export default Minicart;
