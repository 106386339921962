import { useEffect, useState } from "react";
import { db } from "../firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";

function useGetVehiclesTypes() {
  const [vehiclesTypes, setVehiclesTypes] = useState(null);
  const [loadVehicleTypeData, setLoadVehicleTypeData] = useState(null);
  const [errorVehicleTypeData, setErrorVehicleTypeData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const vehiclesTypesCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_VEHICLES_TYPES}`
      );

      const vehiclesTypesQuerySnapshot = await getDocs(
        vehiclesTypesCollectionRef
      );

      const vehiclesTypesData = [];
      vehiclesTypesQuerySnapshot.forEach((doc) => {
        vehiclesTypesData.push(doc.data());
      });

      setVehiclesTypes(vehiclesTypesData);
    } catch (error) {
      setVehiclesTypes(null);
    }
  };

  return { vehiclesTypes, loadVehicleTypeData, errorVehicleTypeData };
}

export default useGetVehiclesTypes;
