import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

function useGetDataPlaningGroupById(currentOrderList, type) {
  // type
  const [bicciOrdesDataManifesPlaning, setBicciOrdesDataManifesPlaning] =
    useState(null);
  const [loadPlanningDataGroup, setLoadPlannings] = useState(false);
  const [errorPlanningDataGroup, setErrorPlannings] = useState(null);
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    if (currentOrderList) {
      switch (type) {
        case "planing":
          findEstimates(currentOrderList);
          break;
        case "route":
          fetchData(currentOrderList);
          break;

        default:
          break;
      }
    }
  }, [currentOrderList, type]);

  const findEstimates = async (currentOrderList) => {
    //console.log(currentOrderList);
    var estimateOrdes = [];
    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );

    for (let i = 0; i < currentOrderList.length; i++) {
      try {
        const q = query(
          allOrdersRef,
          where("orderID", "==", currentOrderList[i])
        );
        const querySnapshot = await getDocs(q);
        const dataallOrdersfind5 = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        var datadocument = dataallOrdersfind5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (!datadocument) {
          setErrorPlannings("no se encuenntra orden:" + currentOrderList[i]);
        }
        //  datadocument[0].packCounter = datadocument.length;
        estimateOrdes.push(datadocument[0]);
      } catch (error) {
        setErrorPlannings("no se encuenntra orden:" + currentOrderList[i]);
        console.log("no se encuenntra orden:" + currentOrderList[i]);
        console.log(error);
      }
    }
    setBicciOrdesDataManifesPlaning(estimateOrdes);
  };

  const fetchData = async (currentOrderList) => {
    var estimateOrdes = [];
    const allOrdersRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);

    for (let i = 0; i < currentOrderList.length; i++) {
      try {
        const datadocument = await getDoc(
          doc(allOrdersRef, currentOrderList[i])
        );

        await updateDoc(doc(allOrdersRef, currentOrderList[i]), {
          packCounter: datadocument.data().quantity,
        });

        estimateOrdes.push(datadocument.data());
      } catch (error) {
        setErrorPlannings("no se encuenntra orden:" + currentOrderList[i]);
        console.log("no se encuenntra orden:" + currentOrderList[i]);
        console.log(error);
      }
    }
    setBicciOrdesDataManifesPlaning(estimateOrdes);
  };
  //console.log(bicciOrdesDataManifesPlaning);
  return {
    bicciOrdesDataManifesPlaning,
    loadPlanningDataGroup,
    errorPlanningDataGroup,
  };
}

export default useGetDataPlaningGroupById;
