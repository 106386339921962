import axios from "axios";

const getMatrixDistanceBicci = async (waitPonints, origin, destination) => {
  // console.log(waitPonints, origin, destination);
  //const { ObjeResponseMatrixDistanceDev, ObjeResponseMatrixDistanceDev2rfec } = useGlobalConfig();

  class reponseMatrix {
    constructor(
      pointOrdersData,
      pointGroupsData,
      isPointGroups,
      manifetsReOrderPoints,
      loadService,
      errorEvents,
      progressValue
    ) {
      this._pointOrdersData = pointOrdersData;
      this._pointGroupsData = pointGroupsData;
      this._isPointGroups = isPointGroups;
      this._manifetsReOrderPoints = manifetsReOrderPoints;
      this._loadService = loadService;
      this._errorEvents = errorEvents;
      this._progressValue = progressValue;
    }

    get pointOrdersData() {
      return this._pointGroupsData;
    }

    set pointOrdersData(newpointOrdersData) {
      this._pointOrdersData = newpointOrdersData;
    }

    get pointGroupsData() {
      return this._pointGroupsData;
    }

    set pointGroupsData(newPointGroupsData) {
      this._pointGroupsData = newPointGroupsData;
    }

    get isPointGroups() {
      return this._isPointGroups;
    }

    set isPointGroups(newPointGroups) {
      this._isPointGroups = newPointGroups;
    }

    get manifetsReOrderPoints() {
      return this.manifetsReOrderPoints;
    }

    set manifetsReOrderPoints(newManifetsReOrderPoints) {
      this._manifetsReOrderPoints = newManifetsReOrderPoints;
    }

    get loadService() {
      return this._loadService;
    }

    set loadService(newLoadService) {
      this._loadService = newLoadService;
    }
    get errorEvents() {
      return this._errorEvents;
    }

    set errorEvents(newErrorEvents) {
      this._errorEvents = newErrorEvents;
    }
    get progressValue() {
      return this._progressValue;
    }

    set progressValue(newProgressValue) {
      this._progressValue = newProgressValue;
    }
  }

  const calculateData = async (waitPonints, origin, destination) => {
    reponseMatrix.loadService = true;
    reponseMatrix.errorEvents = false;
    reponseMatrix.progressValue = 0;
    let newObjects = [origin, destination];
    waitPonints.unshift(...newObjects);

    const data = {
      data: waitPonints,
    };

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_MATRIXDISTACEN_API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_BICCIEXPREE_ENCODE}`,
        },
        data: data,
      };

      await axios
        .request(config)
        .then((response) => {
          reponseMatrix.pointOrdersData = response.data.data.orderedStops;
          reponseMatrix.pointGroupsData = response.data.data.groupsOrderedStops;
          if (response.data.data.orderedStops.length > 0) {
            reponseMatrix.isPointGroups = true;
          } else {
            reponseMatrix.isPointGroups = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    reponseMatrix.loadService = false;
  };

  if (waitPonints && origin && destination) {
    await calculateData(waitPonints, origin, destination);
  }

  return {
    pointOrdersData: reponseMatrix.pointOrdersData,
    pointGroupsData: reponseMatrix.pointGroupsData,
    isPointGroups: reponseMatrix.isPointGroups,
    loadService: reponseMatrix.loadService,
    errorEvents: reponseMatrix.errorEvents,
    progressValue: reponseMatrix.progressValue,
  };
};

export default getMatrixDistanceBicci;
