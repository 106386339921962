import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import React, { useEffect, useState } from "react";

const useFindOrderBicciexpressPendingPayment = (companyId) => {
  const [dataOrdersPending, setOrdersPendingPayment] = useState(null);
  const [loadingPendints, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      findPaymentPendingOrderBicciexpress(companyId);
    }
  }, [companyId]);

  useEffect(() => {}, [loadingPendints]);

  const findPaymentPendingOrderBicciexpress = async (companyId) => {
    const startDate = new Date("2023-10-01");

    try {
      const ordersDispachtRef = collection(
        db,
        process.env.REACT_APP_COL_ORDERS
      );
      const q = query(
        ordersDispachtRef,
        where("timeStamp", ">=", startDate),
        where("billingPaymentProforma", "==", "isRequire")
      );

      const querySnapshot = await getDocs(q);

      const ordersPendingPayment = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const ordersPropietaryDevolution = ordersPendingPayment.filter(
        (item) =>
          item.int_bicciStatus !== "Devolucion" &&
          item.int_falabella_status !== "Devolucion" &&
          item.int_falabella_status !== "1029" &&
          item.int_falabella_status !== "1023" &&
          item.int_falabella_status !== "1046" &&
          item.int_falabella_status !== 1046 &&
          item.int_falabella_status !== 1029 &&
          item.int_falabella_status !== 1023 &&
          item.status !== "Perdida" &&
          item.status !== "Devolucion"
      );

      ordersPropietaryDevolution.sort((a, b) =>
        a.timeStamp < b.timeStamp ? 1 : -1
      );

      setOrdersPendingPayment(ordersPropietaryDevolution);
      setLoading(false);
    } catch (error) {
      console.log("error 345", error);
    }
  };
  return { dataOrdersPending, loadingPendints };
};

export default useFindOrderBicciexpressPendingPayment;
