import { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { defineFinalState } from "../utils/objects/translateStates";
import { arrayUnion } from "firebase/firestore";

export const useDataProcessingStates = (data, isActiveUpdateState) => {
  const [progressState, setProgress] = useState(0);

  useEffect(() => {
    if (!isActiveUpdateState || !data) {
      return;
    }

    let isCancelled = false;

    const processData = async () => {
      for (let n = 0; n < data.length; n++) {
        if (isCancelled) break;

        //  console.log(data[n]);
        const orderRef = doc(
          db,
          process.env.REACT_APP_COL_ORDERS,
          data[n].ID.toString()
        );
        try {
          const docSnapRef = await getDoc(orderRef);
          //  console.log(docSnapRef.exists());
          if (docSnapRef.exists()) {
            let event = {
              id: data[n].BAJA ? data[n].BAJA : "",
              comment: data[n].COMENTARIOBAJA ? data[n].COMENTARIOBAJA : "",
              currentState: data[n].ESTADO ? data[n].ESTADO : "",
              date: new Date(),
              refid: data[n].ID ? data[n].ID : "",
            };
            await updateDoc(orderRef, {
              status: defineFinalState[data[n].ESTADO],
              laterEvents: arrayUnion(event),
            });
            //  console.log("Documento Actualizado", data[n].ID.toString());
          } else {
            //  console.log("Manejar nueva solicitud:", data[n].ID.toString());

            let queries = [];

            const collectionOrdersRef = collection(
              db,
              process.env.REACT_APP_COL_ORDERS
            );

            queries.push(
              query(collectionOrdersRef, where("orderID", "==", data[n].ID))
            );
            queries.push(
              query(collectionOrdersRef, where("key", "==", data[n].ID))
            );
            queries.push(
              query(collectionOrdersRef, where("int_lpn", "==", data[n].ID))
            );
            queries.push(
              query(collectionOrdersRef, where("soc", "==", data[n].ID))
            );
            queries.push(
              query(
                collectionOrdersRef,
                where("orderID", "==", "F" + data[n].ID)
              )
            );
            queries.push(
              query(collectionOrdersRef, where("key", "==", "F" + data[n].ID))
            );
            queries.push(
              query(
                collectionOrdersRef,
                where("int_lpn", "==", "F" + data[n].ID)
              )
            );
            queries.push(
              query(collectionOrdersRef, where("soc", "==", "F" + data[n].ID))
            );

            let combinedResults = [];

            for (let q of queries) {
              try {
                let ordesBicciexpres = await getDocs(q);
                let dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                //    console.log(dataOrdesBicciexpres);

                if (dataOrdesBicciexpres.length > 0) {
                  combinedResults =
                    combinedResults.concat(dataOrdesBicciexpres);
                }
              } catch (error) {
                console.error("Error al ejecutar la consulta:", error);
                // Manejar el error o continuar con la siguiente consulta
              }
            }

            if (combinedResults.length > 0) {
              const uniqueResults = [
                ...new Map(
                  combinedResults.map((item) => [item.id, item])
                ).values(),
              ];
              //  console.log(uniqueResults);

              try {
                const orderRefq = doc(
                  db,
                  process.env.REACT_APP_COL_ORDERS,
                  uniqueResults[0].key
                );
                const docSnapRefq = await getDoc(orderRefq);
                //  console.log(docSnapRefq.exists());
                if (docSnapRefq.exists()) {
                  let event = {
                    id: data[n].BAJA ? data[n].BAJA : "",
                    comment: data[n].COMENTARIOBAJA
                      ? data[n].COMENTARIOBAJA
                      : "",
                    currentState: data[n].ESTADO ? data[n].ESTADO : "",
                    date: new Date(),
                    refid: data[n].ID ? data[n].ID : "",
                  };
                  await updateDoc(orderRefq, {
                    status: defineFinalState[data[n].ESTADO],
                    laterEvents: arrayUnion(event),
                  });
                  //  console.log("Documento Actualizado :", uniqueResults[0].key);
                }
              } catch (error) {
                console.log(error);
                console.log("No se encuentra ID");
              }
            }
          }
        } catch (error) {
          console.log(error);
        }

        // Actualizar el progreso cada 10 elementos o al final del array
        if (n % 10 === 9 || n === data.length - 1) {
          const progresoActual = ((n + 1) / data.length) * 100;
          setProgress(progresoActual);
        }

        // Espera corta para evitar bloquear la UI completamente
        await new Promise((resolve) => setTimeout(resolve, 1));
      }
    };

    processData().catch(console.error);

    return () => {
      isCancelled = true;
    };
  }, [data, isActiveUpdateState]);

  return progressState;
};
