import React from 'react';  
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    Divider,
  } from '@mui/material';

class Popup extends React.Component {  
    constructor(props){  
        console.log(props)
        super(props);  
        this.state = { 
            showPopup: false 
        };  
        this.togglePopup = this.togglePopup.bind(this);
        }
     togglePopup() {  
         //this.props.setshowPopup(false)
          this.setState({  
                   showPopup: !this.state.showPopup  
              });  
     }  

  render() {  
return (  
<div className='popup'>  
<div className='popup_open'>  
<h2 className='popup_open_title'>{this.props.contenido.title}</h2>  

<button className='closePopup' onClick={this.props.togglePopup}>X</button>  
<p>{this.props.contenido.texto}</p>
<div>
    <div>
    <div>Consulta Online</div>
{
this.props.type === 'rider'? (  
        <Card >
        <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
           <Card className="carnetRider" >
                 <CardContent>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                 <Avatar
                    src={this.props.contenido.data.avatar}
                    sx={{
                      height: 64,
                      mb: 2,
                      width: 64
                    }} />

                      <strong>{this.props.contenido.data.name}</strong>
                      <strong>{this.props.contenido.data.dni}</strong>
                      <br></br>       
                      <div className='alignItemscenter'>{this.props.contenido.data.active===true?(<span className="activo">Activo</span>):(<span className="inactivo">Inactivo</span>)} </div>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
            <strong>ID: {this.props.contenido.data.bicciNumber}</strong>
            </div>
            <CardActions > 
              <div className='centercontent'>
              <div className='borderCode'>
                      {/* <QRCode value={`https://get-bicci.web.app/consulta/${this.props.contenido.data.id}`} size={150} level='H' fgColor='#741ab0'/> */}
                      </div>
              </div>  
            </CardActions>
            <div className="centercontent contac-data">
            
            <br></br>
            CONTACTO <br></br>
            {this.props.contenido.data.phone}
            <br></br>
            </div>
            </Card>
    </Box>
  </CardContent>
  <Divider />
  <CardActions>
  </CardActions>
   </Card>
   ):null
}
{
this.props.type === 'riderInactive'?(<>
<Card >
        <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
           <Card className="carnetRider" >
                 <CardContent>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                 <Avatar
                    src={'https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FPortrait_Placeholder.png?alt=media&token=4522d651-b87a-4813-baa9-daad47e985a4'}
                    sx={{
                      height: 64,
                      mb: 2,
                      width: 64
                    }} />

                      <strong>Esperando Instalacion de App movil.</strong>
                     
                      <br></br>       
                      <div className='alignItemscenter'>{this.props.contenido.data.active===true?(<span className="activo">Activo</span>):(<span className="inactivo">Inactivo</span>)} </div>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
            {/* <strong>ID: {this.props.contenido.data.bicciNumber}</strong> */}
            </div>
            <CardActions > 
              <div className='centercontent'>
              <div className='borderCode'>
                      {/* <QRCode value={`https://get-bicci.web.app/consulta/${this.props.contenido.data.id}`} size={150} level='H' fgColor='#741ab0'/> */}
                      </div>
              </div>  
            </CardActions>
            <div className="centercontent contac-data">
            
            <br></br>
            CONTACTO <br></br>
            {this.props.contenido.data.email}
            <br></br>
            </div>
            </Card>
    </Box>
  </CardContent>
  <Divider />
  <CardActions>
  </CardActions>
   </Card>
</>):null
}
{
this.props.type === 'vehicle'?(<>

<Card >
      <CardContent>
       <Box
        sx={{
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column'
        }}
        >
          
        <strong>Detalles del Vehiculo.</strong>     
        <div className='alignItemscenter'>{this.props.contenido.data.isActive===true?(<span className="activo">Activo EN PLATAFORMA</span>):(<span className="inactivo">Inactivo</span>)} </div> 
        </Box>
        </CardContent>
        <Divider />
            <div className="centercontent contac-data">
            
          <div>
            Empresa: {this.props.contenido.data.cluster} 
            </div>
            <div>
            Fecha de registro: {this.props.contenido.data.createDate}
            </div>
            <div>
            Matrícula: {this.props.contenido.data.id}
            </div>
            <div>
            Tipo de viajes: {this.props.contenido.data.vehiclesTypes}
            </div>
            <div>
            Carga vol. maxima: {this.props.contenido.data.maxTransportCapacityVol}
            </div>
            <div>
            Carga vol. minima: {this.props.contenido.data.minTransportCapacityVol}
            </div>
            <div>
            Hora de inicio: {this.props.contenido.data.workingDayMinHour}
            </div>
            <div>
            Hora de cierre: {this.props.contenido.data.workingDayMaxHour}
            </div>
            </div> 
     <Divider />
   </Card>

</>):null
}  
{
this.props.type === 'usercompany'?(<>
{
  this.props.contenido.data?(<>
  <Card >
      <CardContent>
       <Box
        sx={{
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column'
        }}
        >
          
        <strong>Detalles de Usuario Operador.</strong>     
        <div className='alignItemscenter'>{this.props.contenido.data.isActive===true?(<span className="activo">ACTIVO EN PLATAFORMA</span>):(<span className="inactivo">NO ACTIVO EN PLATAFORMA</span>)} </div> 
        </Box>
        </CardContent>
        <Divider />
          <div className="centercontent contac-data">

          <div className="popcontainer"> Empresa : {this.props.contenido.data.companyName} </div> 
          <div className="popcontainer"> Fecha de registro : {this.props.contenido.data.createdateAdd}</div> 
          <div className="popcontainer"> Email : {this.props.contenido.data.email} </div> 
          <div className="popcontainer"> Telefono : {this.props.contenido.data.phone}</div> 
          <div className="popcontainer"> Nombre : {this.props.contenido.data.username}</div> 
          <div className="popcontainer"> Apellido : {this.props.contenido.data.userLastName}</div> 
          <div className="popcontainer"> Agregado como operador: {this.props.contenido.data.isblockedAgreement===true?(<span className="activo-small">Habilitado por la compañia</span>):(<span className="inactivo-small">Inactivo por la compañia</span>)} </div> 
          <div className="popcontainer"> Rol de usuario: {this.props.contenido.data.company_roll} </div> 

          </div> 
     <Divider />
   </Card>

  </>):(<></>)
}
</>):null
}  

</div>



</div>
</div>  
</div>  
);  
}  
}  
export default Popup;