import React from "react";
import { format } from "date-fns";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { minWidth } from "@mui/system";

const ReporteRutaPDF = (props) => {
  const CANT = {
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  };
  const styles = StyleSheet.create({
    page: { padding: "3%" },
    section: { textAlign: "letf", margin: 10 },
    img: { color: "#050505", width: "120px" },
    imgAvatar: { width: "70px", height: "70px", borderRadius: "100%" },
    text: { fontSize: "16px", marginTop: "3px" },
    text2: { fontSize: "12px", marginBottom: 1 },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      marginTop: "10px",
      marginBottom: "30px",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableCol: {
      width: "14%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColCant: {
      width: "4%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
    },
    tableColDescipcion: {
      width: "23%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontWeight: 700,
      textAlign: "letf",
    },
    tableCell: {
      marginTop: 5,
      fontSize: 10,
      margin: "auto",
      flexDirection: "row",
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableRowItem: {
      margin: "auto",
      flexDirection: "row",

      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingBottom: "3px",
    },
    tableCellItem: {
      margin: "auto",
      marginTop: 5,
      fontSize: 8,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      minHeight: "25px",
    },
  });

  return (
    <Document fileName="mi-archivo.pdf">
      <Page
        size="A4"
        style={styles.page}
        title={format(Date.now(), "yy/MM/dd")}
        author={"UjajsksiAJSJNSLKAKAHYAls5d46d65s"}
        creator={"BICCIEXPRESS"}
        subject={"Reporte de ruta"}
      >
        <Text style={styles.text2}>{Date.now().toString()}</Text>
        <View style={styles.section}>
          <Image
            src={"/static/images/bicciexpress.png"}
            alt=""
            style={styles.img}
          ></Image>
          <Text style={styles.text1}>Reporte de ruta</Text>
          <Text style={styles.text3Right}>
            Día / hora de emisión: {format(Date.now(), "dd/MM/yyyy hh:mm:ss")}
          </Text>
          <Text style={styles.text3}>Periodo de consultado:</Text>
          <Text style={styles.text12}>Desde: </Text>
          <Text style={styles.text12}>Hasta: </Text>

          <Text style={styles.tableCell}>
            ya la data esta aqui. solo Diseño y Maquetar
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReporteRutaPDF;
