import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Avatar,
  Grid,
  LinearProgress,
  Switch,
  Typography,
  SvgIcon,
  Select,
  MenuItem,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MapIcon from "@mui/icons-material/Map";
import RouteIcon from "@mui/icons-material/Route";
import { MapVectoriales } from "../../utils/Polygons/VectorialSatelite";
import DownloadIcon from "@mui/icons-material/Download";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { registerPickerActions } from "../../redux/accionesOrders";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import Moment from "moment";
import {
  globalsStatesCompanys,
  translateStatesBicciFilter,
} from "../../utils/objects/translateStates";
import { format } from "date-fns";
import useGetDataOrdersListPicking from "../../hooks/getDataOrdersListPicking";

const isTestMode = false;

const ScannerDistributionV2 = (props) => {
  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const { companiesData, mergedOrders } = useOrders({
    initDateInitial,
    endDateInitial,
  });

  const dispacth = useDispatch();

  const [barcodeDataImput, setBarcodeDataInput] = useState("");
  const [serahrCodeDataImput, setSearchBarcodeDataInput] = useState("");
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [inputDataMethod, setInputDataMethod] = useState(true);
  const [mute, setMute] = useState(true);
  const [muteDriver, setMuteDriver] = useState(true);
  const [muteRoute, setMuteRoute] = useState(true);
  const [muteZone, setMuteZone] = useState(true);
  const synth = window.speechSynthesis;
  const [progressValue, setProgressValue] = useState(0);
  const [prefixes, setPrefixes] = useState([]);
  const [selectedCompanyPrefix, setSelectedCompanyPrefix] = useState(null);

  // const [orderList, setOrderList] = useState([]);

  const [listId, setListId] = useState(null);

  const { listPickingData, internetSpeed } = useGetDataOrdersListPicking({
    listId,
  });

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexados
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setListId(formattedDate);
  }, []);

  const collRegidterRef = collection(
    db,
    process.env.REACT_APP_COL_PICKER_REGISTER
  );

  const input1Ref = useRef(null);

  useEffect(() => {
    if (companiesData) {
      const prefixes = companiesData
        .filter((item) => item.prefix)
        .map((item) => item.prefix);
      setPrefixes(prefixes);
    }
  }, [companiesData]);

  useEffect(() => {
    if (serahrCodeDataImput.length > 3 && listId && prefixes.length > 0) {
      findOrdesrDispachtScanner(serahrCodeDataImput, listId, prefixes);
    }
  }, [serahrCodeDataImput]);

  useEffect(() => {
    if (dataSearch) {
      if (!mute) {
        if (!muteDriver) {
          dataSearch.driverName && dataSearch.driverName !== "isRequire"
            ? speakText(dataSearch.driverName)
            : speakText("No Asignado");
        }

        if (!muteRoute) {
          dataSearch.driverLicensePlate &&
          dataSearch.driverLicensePlate !== "isRequire"
            ? speakText(`Vehiculo ${dataSearch.driverLicensePlate}`)
            : speakText("Sin Vehiculo");
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log(dataSearch);
    function isPointInPolygon(point, polygon) {
      var x = point[0],
        y = point[1];
      var inside = false;
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }

    function findPolygonForPoint(point, geoJson) {
      for (var feature of geoJson.features) {
        if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
          return feature.properties.Name;
        }
      }
      return null; // No contiene ningún polígono
    }

    var geoJson = MapVectoriales;

    if (dataSearch) {
      if (props.usuarioStorage) {
        if (!isTestMode) {
          dispacth(registerPickerActions(dataSearch, props.usuarioStorage));
        } else {
          console.log("Simulando registerPickerActions ok");
        }
      }
    }
    if (dataSearch) {
      if (dataSearch.toAddressLocation) {
        var point = [
          dataSearch.toAddressLocation.longitude
            ? dataSearch.toAddressLocation.longitude
            : dataSearch.toAddressLocation._long,
          dataSearch.toAddressLocation.latitude
            ? dataSearch.toAddressLocation.latitude
            : dataSearch.toAddressLocation._lat,
        ];
        var polygonName = findPolygonForPoint(point, geoJson);

        if (!muteZone) {
          speakText(`ruta ${polygonName}`);
        }

        dataSearch.BicciZone = polygonName;

        let updateDocData = {
          //   id: idWithF,
          orderID: dataSearch.id,
          sobrante: false,
          listId: listId,
          deleted: false,
          BicciZone: dataSearch.BicciZone ? dataSearch.BicciZone : "",
          date: new Date(),
          driverLicensePlate: dataSearch.driverLicensePlate
            ? dataSearch.driverLicensePlate
            : "",
          internalReference: dataSearch.internalReference
            ? dataSearch.internalReference
            : "",
        };
        // llamar a funcion que no retrase el flujo de la interfaz

        if (!isTestMode) {
          updateTodayPicking(updateDocData);
        } else {
          console.log("Simulando updateTodayPicking ok");
        }

        if (props.usuarioStorage) {
          let data = { id: serahrCodeDataImput };
        }
      } else {
        console.log("No posee la propiedad registrar");
        let updateDocData = {
          orderID: serahrCodeDataImput,
          sobrante: true,
          listId: listId,
          deleted: false,
          BicciZone: "Data no registrada", // Se puede actualizar más tarde
          date: new Date(),
          driverLicensePlate: "",
          internalReference: "",
        };

        // Registrar el faltante usando updateTodayPicking
        if (!isTestMode) {
          updateTodayPicking(updateDocData);
        } else {
          console.log("Simulando updateTodayPicking ok", updateDocData);
        }
      }
    }
  }, [dataSearch]);

  useEffect(() => {
    // Enfocar el campo de entrada al cargar la página
    setWaitingForScan(true);
    input1Ref.current.focus();
    // Agregar un manejador de eventos para hacer clic en el cuerpo
    document.body.addEventListener("click", handleBodyClick);

    // Limpia el manejador de eventos al desmontar el componente
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleFocus = () => {
    setWaitingForScan(true);
  };

  const handleBlur = () => {
    setWaitingForScan(false);
  };

  const findOrdesrDispachtScanner = async (
    serahrCodeDataImput,
    listId,
    prefixes
  ) => {
    setDataSearch(null);

    console.log(serahrCodeDataImput);

    const collections = [
      process.env.REACT_APP_COL_USERS_ORDERS,
      // process.env.REACT_APP_COL_ORDERS,
      // process.env.REACT_APP_COL_FALABELLA_ORDERS,
    ];

    //let idWithF = "R" + serahrCodeDataImput;

    const tryGetDoc = async (collection, id) => {
      const orderRef = doc(db, collection, id);
      const docSnapshot = await getDoc(orderRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    };

    const tryQueryByAttributes = async (collectionName, attribute, value) => {
      const colRef = collection(db, collectionName);
      const q = query(colRef, where(attribute, "==", value));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    };

    try {
      setProgressValue(10);
      let foundDocumentData = null;
      let orderId = serahrCodeDataImput;

      // Si hay un prefijo seleccionado, lo agregamos al ID de búsqueda
      if (selectedCompanyPrefix) {
        orderId = selectedCompanyPrefix + serahrCodeDataImput;
      }

      // Buscar en mergedOrders con el ID (posiblemente con prefijo)
      foundDocumentData = mergedOrders.find((order) => order.id === orderId);

      // Si no se encontró y hay prefijos, intentar con cada uno
      if (!foundDocumentData) {
        for (let prefix of prefixes) {
          orderId = prefix + serahrCodeDataImput;

          // Intentar encontrar por order.id
          foundDocumentData = mergedOrders.find(
            (order) => order.id === orderId
          );

          if (foundDocumentData) {
            setSelectedCompanyPrefix(prefix);
            break;
          }

          // Si no se encontró por order.id, intentar por internalReference
          if (!foundDocumentData) {
            foundDocumentData = mergedOrders.find((order) => {
              if (order.internalReference) {
                // Obtener la primera parte de internalReference antes del guión
                let internalRefFirstPart =
                  order.internalReference.split("-")[0];
                return (
                  internalRefFirstPart === serahrCodeDataImput ||
                  internalRefFirstPart === prefix + serahrCodeDataImput
                );
              }
              return false;
            });

            if (foundDocumentData) {
              setSelectedCompanyPrefix(prefix);
              break;
            }
          }
        }
      }

      // Si aún no se encuentra, buscar sin prefijo en internalReference
      if (!foundDocumentData) {
        foundDocumentData = mergedOrders.find((order) => {
          console.log(serahrCodeDataImput);

          // Primero, buscar si el valor completo de 'internalReference' coincide con 'serahrCodeDataImput'
          if (
            order.internalReference &&
            order.internalReference === serahrCodeDataImput
          ) {
            return true;
          }

          if (order.internalReference) {
            let parts = order.internalReference.split("-");

            // Comparamos la primera parte
            let internalRefFirstPart = parts[0];
            console.log(internalRefFirstPart);
            if (internalRefFirstPart === serahrCodeDataImput) {
              return true;
            }

            // Si existe la segunda parte, comparamos la segunda parte
            if (parts.length > 1) {
              let internalRefSecondPart = parts[1];
              console.log(internalRefSecondPart);
              if (internalRefSecondPart === serahrCodeDataImput) {
                return true;
              }
            }
          }

          return false;
        });
      }

      // Si se encontró en el contexto, actualizamos y salimos de la función
      if (foundDocumentData) {
        setDataSearch(foundDocumentData);
        setProgressValue(100);
        return;
      }

      // Primero intentar con el prefijo seleccionado por el usuario
      if (selectedCompanyPrefix) {
        let idWithPrefix = selectedCompanyPrefix + serahrCodeDataImput;

        for (let collection of collections) {
          foundDocumentData = await tryGetDoc(collection, idWithPrefix);
          if (foundDocumentData) {
            break;
          }
          setProgressValue(20);
        }

        if (foundDocumentData) {
          setDataSearch(foundDocumentData);

          setProgressValue(100);
          return;
        }
      }

      for (let collection of collections) {
        for (let prefix of prefixes) {
          let idWithPrefix = prefix + serahrCodeDataImput;

          // Busca el documento con el prefijo correspondiente
          let documentData = await tryGetDoc(collection, idWithPrefix);
          if (documentData) {
            setSelectedCompanyPrefix(prefix);
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(20);

          // Si no se encuentra, busca sin el prefijo
          documentData = await tryGetDoc(collection, serahrCodeDataImput);
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(30);

          documentData = await tryQueryByAttributes(
            collection,
            "id",
            serahrCodeDataImput
          );
          if (documentData) {
            setSelectedCompanyPrefix(prefix);
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(40);

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_client_soc",
          //   serahrCodeDataImput
          // );
          // if (documentData) {
          //   foundDocumentData = documentData;
          //   break;
          // }

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_f12",
          //   serahrCodeDataImput
          // );
          // if (documentData) {
          //   foundDocumentData = documentData;
          //   break;
          // }

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_lpn",
          //   serahrCodeDataImput
          // );
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }
        }

        if (foundDocumentData) {
          setDataSearch(foundDocumentData);

          setProgressValue(100);
          break;
        }
      }

      if (!foundDocumentData) {
        // No se encontró el documento en ninguna colección ni por los atributos específicos.
        let updateDocData = {
          orderID: serahrCodeDataImput,
          sobrante: true,
          listId: listId,
          deleted: false,
          BicciZone: "Data no registrada",
          date: new Date(),
          driverLicensePlate: "",
          internalReference: "",
        };
        setDataSearch(updateDocData);

        setProgressValue(100);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateTodayPicking = async (updateDocData) => {
    console.log("updateTodayPicking");
    console.log(updateDocData);
    try {
      let docRef;
      if (!isTestMode) {
        docRef = await addDoc(collRegidterRef, updateDocData);
        await setDoc(
          docRef,
          {
            ...updateDocData,
            id: docRef.id,
            BicciZone: updateDocData.BicciZone,
          },
          { merge: true }
        );
      } else {
        console.log("Simulando updateTodayPicking", updateDocData);
      }
      if (!isTestMode) {
        // setOrderList((prevOrderList) => {
        //   const updatedOrderList = [
        //     { id: docRef.id, ...updateDocData },
        //     ...prevOrderList,
        //   ];
        //   const sortedOrderList = updatedOrderList.sort(
        //     (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        //   );
        //   return sortedOrderList;
        // });
      }
      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS,
        updateDocData.orderID
      );
      const docSnapshot = await getDoc(orderRef);

      if (docSnapshot.exists()) {
        try {
          if (!isTestMode) {
            await updateDoc(orderRef, { isPickedUp: true });
          } else {
            console.log("Simulando updateTodayPicking updateDocData");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const loadInitialOrders = async (listId) => {
  //   const q = query(
  //     collRegidterRef,
  //     where("listId", "==", listId),
  //     where("deleted", "==", false)
  //   );
  //   const querySnapshot = await getDocs(q);
  //   const orders = querySnapshot.docs.map((doc) => {
  //     const data = doc.data();
  //     return {
  //       id: doc.id, // Añadiendo la propiedad key con el valor de documentName
  //       ...data,
  //     };
  //   });

  //   const sortedOrders = orders.sort((a, b) => b.date - a.date);

  //   setOrderList(sortedOrders);
  // };

  // const handleDeleteOrder = async (id) => {
  //   if (!isTestMode) {
  //     try {
  //       let docReft = doc(collRegidterRef, id);
  //       await updateDoc(docReft, { deleted: true });
  //       setOrderList((prevOrderList) =>
  //         prevOrderList.filter((order) => order.id !== id)
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     console.log("No se borran item en modo  desarrollo");
  //   }
  // };

  // const listenscanner = () => {
  //   input1Ref.current.focus();
  // };

  const handleBodyClick = (e) => {
    // Verifica si el clic se realizó fuera del campo de entrada
    if (!input1Ref.current.contains(e.target)) {
      // Si el clic fue fuera del campo, vuelve a enfocar el campo de entrada
      input1Ref.current.focus();
    }
  };
  const typingTimer = useRef(null);
  const doneTypingInterval = 700; // tiempo en milisegundos para esperar después de la última entrada

  const handleBarcodeScan = (data) => {
    //console.log(data);
    setBarcodeDataInput(data);

    clearTimeout(typingTimer.current); // Reinicia el temporizador cada vez que se detecta una nueva entrada
    typingTimer.current = setTimeout(() => {
      setSearchBarcodeDataInput(data); // Solo se ejecuta si no hay nuevas entradas después de 500ms
    }, doneTypingInterval);

    if (inputDataMethod === true) {
      setTimeout(() => {
        //console.log("Procesando...");
        setBarcodeDataInput("");
        input1Ref.current.focus();
      }, 500);
    }
  };

  const handleChange = (event) => {
    setInputDataMethod(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const speakText = (text) => {
    if (synth) {
      const utterance = new SpeechSynthesisUtterance(text);
      synth.speak(utterance);
    }
  };

  const handleClikDowLoad = () => {
    console.log("descargar reporte");
    console.log(listPickingData);

    const matrizReporte = [
      ["Código", "sobrante", "Lista", "Fecha", "Vehiculo", "ID Interno"],
      ...listPickingData.map((dato) => [
        dato.orderID,
        dato.sobrante,
        dato.listId,
        format(dato.date.seconds * 1000, "dd-MM-yyyy hh:mm:ss"),
        dato.driverLicensePlate,
        dato.internalReference,
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(libro, `Picking ${listId}.xlsx`);
  };

  const handleClearCacheAndReload = () => {
    window.location.reload();
  };

  const styleBox = {
    border: "1px solid #591e8f",
    p: 1,
    borderRadius: "3px",
  };

  return (
    <Grid sx={{ width: "100%", flex: "row" }}>
      <Grid sx={{ width: "100%", backgroundColor: "#f8f8f8", display: "flex" }}>
        <Box
          alt="bicciexpress"
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            //backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
            //backgroundSize: "100% auto", // Escala horizontal completa
            //backgroundRepeat: "no-repeat", // No se repite
            // backgroundColor: "#e58888", //"#aef3c3",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Card
            sx={{
              height: "90%",
              maxHeight: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2px",
              marginBottom: "5%",
            }}
          >
            <CardHeader
              title={<Box></Box>}
              subheader={
                <div>
                  <Switch
                    checked={inputDataMethod}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: waitingForScan ? "#aef3c3" : "#e58888",
                    }}
                  />
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    color="textPrimary"
                    variant="body"
                  >
                    {inputDataMethod ? (
                      <span>Escaner seleccionado</span>
                    ) : (
                      <span>Teclado seleccionado</span>
                    )}
                  </Typography>
                </div>
              }
              sx={{ padding: "1px", margin: "0px" }}
            />
            {/* <CardMedia
          component="img"
          height="100"
          image="/static/images/asset/entradateclado.png"
          alt="bicciexpress"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "50%",
            backgroundColor: "#aef3c3",
          }}
        /> */}
            <CardContent sx={{ padding: "1px" }}>
              <Box
                sx={{
                  objectFit: "contain",
                  width: "100%",
                  height: "99%",
                  //   backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
                  //   backgroundSize: "100% auto", // Escala horizontal completa
                  //   backgroundRepeat: "no-repeat", // No se repite
                  backgroundColor: waitingForScan ? "#aef3c3" : "#f1eeee", //,
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    padding: "1px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "30%",
                      },

                      overflowY: "auto",
                      maxHeight: "100vh",
                      padding: "2px",
                      maxWidth: "100%",
                    }}
                  >
                    <AppBar
                      position="static"
                      sx={{
                        color: "#8F8F8",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        },
                        backgroundColor: "#e3e3e3",
                        overflowY: "auto",
                        maxHeight: "100vh",
                      }}
                    >
                      <Toolbar variant="dense" disableGutters>
                        {/* Título */}
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          variant="body"
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1rem",
                            },
                            flexGrow: 1,
                            color: "#591e8f",
                          }}
                        >
                          Escáner de distribución
                        </Typography>

                        {/* Avatar e ícono de estado */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={
                              inputDataMethod
                                ? "/static/images/asset/escanner.png"
                                : "/static/images/asset/teclado.png"
                            }
                            sx={{
                              backgroundColor: waitingForScan
                                ? "#aef3c3"
                                : "#f1eeee",
                              height: 50,
                              width: 50,
                              marginRight: 1,
                            }}
                          />
                          {waitingForScan ? (
                            <ChangeCircleIcon
                              sx={{ color: green[500], marginRight: 1 }}
                            />
                          ) : (
                            <CachedIcon
                              sx={{ color: "#591e8f", marginRight: 1 }}
                            />
                          )}
                          <Typography variant="body2" sx={{ color: "#591e8f" }}>
                            {waitingForScan ? "On" : "Off"}
                          </Typography>
                        </Box>

                        {/* Íconos de sonido y configuración */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => setMute(!mute)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            {!mute ? (
                              <VolumeUpIcon sx={{ color: green[500] }} />
                            ) : (
                              <VolumeOffIcon sx={{ color: "#591e8f" }} />
                            )}
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute driver"
                            onClick={() => setMuteDriver(!muteDriver)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            <DirectionsBikeIcon
                              sx={{
                                color: !muteDriver ? green[500] : "#4f4f4f",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute zone"
                            onClick={() => setMuteZone(!muteZone)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            <MapIcon
                              sx={{ color: !muteZone ? green[500] : "#4f4f4f" }}
                            />
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute route"
                            onClick={() => setMuteRoute(!muteRoute)}
                            sx={{ color: "#591e8f" }}
                          >
                            <RouteIcon
                              sx={{
                                color: !muteRoute ? green[500] : "#4f4f4f",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Toolbar>
                    </AppBar>
                    {dataSearch ? (
                      <>
                        <Box>
                          <Card
                            sx={{
                              margin: "0px",
                            }}
                          >
                            <CardHeader
                              title={
                                <Box
                                  sx={{
                                    display: "flex",
                                    backgroundColor: "#F8F8F8",
                                    p: 0,
                                  }}
                                >
                                  {companiesData ? (
                                    <>
                                      <Select
                                        value={selectedCompanyPrefix}
                                        onChange={(e) =>
                                          setSelectedCompanyPrefix(
                                            e.target.value
                                          )
                                        }
                                        displayEmpty
                                        sx={{ width: "100%", m: 0 }}
                                      >
                                        <MenuItem value="">
                                          <em>Seleccionar Empresa</em>
                                        </MenuItem>
                                        {companiesData.map((company) => {
                                          if (company.name) {
                                            return (
                                              <MenuItem
                                                key={company.prefix}
                                                value={company.prefix}
                                              >
                                                {company.name}{" "}
                                                {
                                                  <strong>
                                                    {" "}
                                                    - {company.prefix}
                                                  </strong>
                                                }
                                              </MenuItem>
                                            );
                                          }
                                        })}
                                      </Select>
                                      <IconButton
                                        onClick={handleClearCacheAndReload}
                                      >
                                        <RefreshIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              }
                            />
                            <CardContent sx={{ m: 0 }}>
                              <Box sx={styleBox}>
                                <Typography
                                  sx={{
                                    fontSize: "1em",
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  Escáner de Distribución V2{" "}
                                </Typography>
                              </Box>
                              <Box sx={styleBox}>
                                <Typography
                                  sx={{ fontSize: "1em", fontWeight: 600 }}
                                >
                                  {" "}
                                  Folios escaneados hoy :{" "}
                                  {listPickingData.length}
                                </Typography>
                              </Box>
                              <Box sx={styleBox}>
                                <IconButton
                                  onClick={() => handleClikDowLoad()}
                                  sx={{
                                    fontSize: "1em",
                                    borderRadius: "3px",
                                    m: 0,
                                  }}
                                >
                                  <SvgIcon style={{ fontSize: "1em", m: 0 }}>
                                    <DownloadIcon />
                                  </SvgIcon>

                                  <Typography variant="body">
                                    Descargar reporte
                                  </Typography>
                                </IconButton>
                              </Box>
                              <Box sx={styleBox}>
                                <Typography
                                  sx={{ fontSize: "1em", fontWeight: 600 }}
                                >
                                  Informacion Red / Internet
                                </Typography>
                                <br></br>
                                <Typography
                                  sx={{ fontSize: "1em", fontWeight: 600 }}
                                >
                                  <div>
                                    {" "}
                                    <p>
                                      Velocidad de Conexión:{" "}
                                      {internetSpeed
                                        ? `${internetSpeed} Mbps`
                                        : "Midiendo..."}
                                    </p>
                                  </div>
                                </Typography>
                              </Box>
                              {dataSearch.status ? (
                                <Box sx={styleBox}>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Estatus:
                                    <br></br>
                                    {dataSearch.status}
                                    <br></br>{" "}
                                    {
                                      translateStatesBicciFilter[
                                        dataSearch.status
                                      ]
                                    }{" "}
                                  </Typography>
                                </Box>
                              ) : null}
                              {dataSearch.intStatus ? (
                                <Box sx={styleBox}>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Estatus:
                                    {dataSearch.intStatus}
                                    <br></br>{" "}
                                    {globalsStatesCompanys[
                                      selectedCompanyPrefix
                                    ][dataSearch.intStatus]
                                      ? globalsStatesCompanys[
                                          selectedCompanyPrefix
                                        ][dataSearch.intStatus]
                                      : "Desconocido"}
                                  </Typography>
                                </Box>
                              ) : null}

                              {dataSearch.observationAdmin ? (
                                <Box sx={styleBox}>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Observaciones:
                                    <br></br> {dataSearch.observationAdmin[0]}
                                  </Typography>
                                </Box>
                              ) : null}
                              {dataSearch.instucctions ? (
                                <Box sx={styleBox}>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Instrucciones:
                                    <br></br> {dataSearch.instucctions}
                                  </Typography>
                                </Box>
                              ) : null}
                              {dataSearch.placeName ? (
                                <Box sx={styleBox}>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Empresa:
                                    <br></br>
                                    {dataSearch.placeName}
                                  </Typography>
                                </Box>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Box>
                      </>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "70%",
                        padding: "1px",
                      },
                      height: "100%",
                      bottom: "0px",
                    }}
                  >
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textPrimary"
                      variant="body"
                    >
                      {waitingForScan ? (
                        <div style={{ padding: "5px" }}>
                          Esperando{" "}
                          {inputDataMethod
                            ? "lecturas por escanner..."
                            : "digitación de codigo..."}{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "5px",
                            backgroundColor: "red", //"#e58888",
                            color: "#fff",
                          }}
                        >
                          {inputDataMethod
                            ? "Mantenga la pagina activa para escanear..."
                            : "Mantenga la pagina activa para buscar..."}
                        </div>
                      )}
                    </Typography>
                    <LinearProgress
                      value={progressValue}
                      variant="determinate"
                    ></LinearProgress>
                    <div className="searchtiketcontainers">
                      <Card
                        sx={{
                          maxWidth: "450px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        <div className="inputcode">
                          <input
                            type="text"
                            id="codigoBarras"
                            className={
                              inputDataMethod
                                ? "barcode-input"
                                : "barcode-input-keyboar"
                            }
                            ref={input1Ref}
                            autoFocus
                            placeholder={
                              inputDataMethod
                                ? `${
                                    selectedCompanyPrefix
                                      ? selectedCompanyPrefix
                                      : "|"
                                  }|||||||| ||| |||| |||||`
                                : "Digite el codígo abuscar"
                            }
                            value={barcodeDataImput}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={(e) => handleBarcodeScan(e.target.value)}

                            //   sx={{
                            //     ...styles.inputBase,
                            //     "::before": {
                            //       ...styles.beforeContent,
                            //     },
                            //   }}
                          />

                          {/* Resto de tu interfaz y lógica aquí */}
                        </div>
                        {serahrCodeDataImput ? (
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            variant="h2"
                          >
                            {serahrCodeDataImput}
                          </Typography>
                        ) : null}
                      </Card>

                      {dataSearch ? (
                        <div className="tiketcontainers">
                          <div>
                            {dataSearch &&
                            dataSearch.length !== 0 &&
                            !dataSearch.sobrante ? (
                              <div className="tiketcontainers">
                                <Card
                                  className="m-4 b-1"
                                  key={dataSearch.id}
                                  sx={{
                                    boxShadow: "#F8F8F8",
                                  }}
                                >
                                  <CardHeader
                                    title={
                                      <div>
                                        <div>
                                          {dataSearch.dataOrigin === "SDDATA"
                                            ? "Same Day ( SD )"
                                            : "Home Delivery ( HD )"}
                                        </div>
                                        <div>{dataSearch.id} </div>
                                        {dataSearch.internalReference ? (
                                          <div>
                                            QR - Codígo Interno :
                                            {dataSearch.internalReference}{" "}
                                          </div>
                                        ) : null}{" "}
                                      </div>
                                    }
                                  />
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={3}
                                      >
                                        <Grid item xs>
                                          <Box>
                                            <Typography sx={{ m: 1 }}>
                                              VEHÍCULO
                                            </Typography>
                                            <Typography
                                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                                              color="textPrimary"
                                              sx={{
                                                padding: "2px",
                                                fontSize: "3em",
                                              }}
                                            >
                                              <div>
                                                {!dataSearch.driverLicensePlate ||
                                                dataSearch.driverLicensePlate ===
                                                  "isRequire" ||
                                                dataSearch.driverLicensePlate ===
                                                  "null" ? (
                                                  <span className="alertRed bigText">
                                                    -
                                                  </span>
                                                ) : (
                                                  <span className="alertcolor bigText">
                                                    {
                                                      dataSearch.driverLicensePlate
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs>
                                          <Card
                                            variant="solid"
                                            sx={{
                                              backgroundColor: "#F8F8F8",
                                              m: 1,
                                            }}
                                          >
                                            <CardContent
                                              sx={{
                                                backgroundColor: "#F8F8F8",
                                                padding: "2px",
                                              }}
                                            >
                                              <Typography>
                                                PUNTO RUTA
                                              </Typography>
                                              <Typography
                                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                                color="textPrimary"
                                                sx={{
                                                  padding: "2px",
                                                  fontSize: "3em",
                                                }}
                                              >
                                                <div>
                                                  {" "}
                                                  {dataSearch.orderNumber}
                                                </div>
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Typography>DESTINO:</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {dataSearch.routeLocality
                                            ? dataSearch.routeLocality
                                            : dataSearch.toAddress}
                                        </div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      {dataSearch.int_alert ? (
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <span className="alertRed bigText">
                                            DISCREPANCIA REGISTRADA
                                          </span>
                                        </Typography>
                                      ) : null}
                                      <Box>
                                        <Typography>RIDER</Typography>
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="body"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <div>
                                            {!dataSearch.driverName ||
                                            dataSearch.driverName ===
                                              "isRequire" ||
                                            dataSearch.driverName === "" ? (
                                              <span className="alertRed bigText">
                                                No asignado
                                              </span>
                                            ) : (
                                              <span className="alertcolor ">
                                                {dataSearch.driverName}
                                              </span>
                                            )}
                                          </div>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          Polygono / ZONA BICCI
                                        </Typography>
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <div>
                                            {dataSearch.BicciZone
                                              ? dataSearch.BicciZone
                                              : "No se Encuentra georeferencia"}
                                          </div>
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Card>
                              </div>
                            ) : (
                              <Card
                                sx={{
                                  marginTop: "50px",
                                  padding: "20px",
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                }}
                              >
                                <div>
                                  <span className="bigText">
                                    Sin resultados, ingrese nuevos datos para
                                    iniciar la busqueda.
                                  </span>
                                </div>
                                {dataSearch.sobrante ? (
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "red" }}
                                  >
                                    "SOBRANTE REGISTRADO"
                                  </Typography>
                                ) : null}
                              </Card>
                            )}{" "}
                          </div>
                        </div>
                      ) : (
                        <Card
                          sx={{
                            marginTop: "50px",
                            padding: "20px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <div className="bigText"> "buscando..."</div>
                        </Card>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>{" "}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScannerDistributionV2;
