import React from "react";
import { Card } from "@mui/material";
const ToolDashOrdersFilterState = (props) => {
  return (
    <Card className="typeMiniformsBars">
      <div className="m-2">
        <div> Estado de la Orden </div>
        {props.optionOrderStatusFilter ? (
          <select
            id="custom-selectFrom-comuna"
            control="select"
            name="currentOrderStatusFilter"
            onChange={(e) => props.onChageSubFilter(e)}
          >
            <option value={"itemClear"}> Seleccione una Estado... </option>
            {props.optionOrderStatusFilter.map((item, index) => (
              <option key={item + index.toString()} value={item}>
                {item < 2 ? "Cancelados" : null}
                {item >= 2 && item < 8 ? "En  curso" : null}
                {item >= 8 ? "Finalizados" : null}
                {item >= 10 ? " Reintentos" : null}
              </option>
            ))}
          </select>
        ) : (
          "No data"
        )}
      </div>

      <div className="m-2">
        <div> Estado de la Facturación </div>
        {props.optionStatusBillingFilter ? (
          <select
            id="custom-selectFrom-comuna"
            control="select"
            name="currentStatusBillingFilter"
            onChange={(e) => props.onChageSubFilter(e)}
          >
            <option value={"itemClear"}> Seleccione una Estado... </option>
            {props.optionStatusBillingFilter.map((item, index) => (
              <option key={item + index} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : (
          "No data"
        )}
      </div>
    </Card>
  );
};

export default ToolDashOrdersFilterState;
