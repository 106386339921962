import React from 'react';
import {Image} from 'semantic-ui-react';
import useGlobalConfig from '../../hooks/globalConfig';
const Bannerhome = () => {
const {logoOficialUrl} = useGlobalConfig 
  return (
        <div className='containerBanner'>
          <Image src={logoOficialUrl} />    
        </div>
        );
};

export default Bannerhome;
