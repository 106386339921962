import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Logo from "../header/logo/Logo";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { cerrarSesionAccion } from "../../redux/usuarioDucks";
import { useDispatch } from "react-redux";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";

import { purple, green } from "@mui/material/colors";

export default function DashboardNavbarMui(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const cerrarSesion = (props) => {
    dispatch(cerrarSesionAccion());
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenu2Open = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <div className="navlinkbar">
          <NavLink
            className="btns-AppBar mr-2"
            to="/dashboard/mi-cuenta"
            exact="true"
          >
            <i className="user circle icon"></i>Perfil
          </NavLink>
        </div>
        <div className="navlinkbar">
          <NavLink
            className="btns-AppBar mr-2"
            to="/dashboard/tablero"
            exact="true"
          >
            <ListAltIcon fontSize="medium" />
            Dashboard
          </NavLink>
        </div>
      </MenuItem>

      <MenuItem>
        <div className="navlinkbar">
          <NavLink
            className="btns.AppBarmr-2 "
            onClick={() => cerrarSesion(props)}
            to="/login"
          >
            <i className="sign out alternate icon koFoOQ"></i>cerrar sesion
          </NavLink>
        </div>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton>
          <Avatar alt="Remy Sharp" src={props.props.firebaseUser.photoURL} />
        </IconButton>
        <p>Mi cuenta</p>
      </MenuItem>
      <MenuItem>
        <NavLink
          className="btns-AppBar"
          // onClick={handleClick}
          to="/inicio"
          exact="true"
        >
          Sitio web
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink
          className="btns-AppBar"
          // onClick={handleClick}
          to="/inicio"
          exact="true"
        >
          Contacto
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink
          className="btns-AppBar"
          // onClick={handleClick}
          to="/Tracking"
          exact="true"
        >
          Tracking
        </NavLink>
      </MenuItem>

      {/*       
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="#1e2158">
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="#1e2158"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "transparent" }}>
      <AppBar position="static" sx={{ backgroundColor: "#F9F9F9" }}>
        <Toolbar>
          <Logo width={100}></Logo>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="#1e2158"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="#1e2158"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

            <ListItem onClick={handleProfileMenuOpen}>
              <ListItemAvatar>
                <Avatar
                  alt="Profile Picture"
                  src={props.props.firebaseUser.photoURL}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ color: "#333" }}
                primary={"Hola !"}
                secondary={props.props.firebaseUser.displayName}
              />
            </ListItem>

            <IconButton
              size="large"
              edge="start"
              color="#1e2158"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={handleMobileMenu2Open}
            >
              <MenuIcon />
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={() => props.setLandScren(!props.landScren)}
              color="#1e2158"
            >
              <ViewSidebarIcon
                sx={{ color: props.landScren ? purple[500] : green[500] }}
              />
            </IconButton> */}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="#1e2158"
            >
              <MoreIcon />
            </IconButton>

            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={() => props.handleDrawerToggle()}
              color="#1e2158"
            >
              <MenuIcon
                sx={{ color: props.landScren ? purple[500] : green[500] }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
