import React from "react";
import { Container } from "semantic-ui-react";
import Bannerhome from "../../../components/Bannerhome/Bannerhome";
import Tyc from "../postcontent/Tyc/Tyc";

const Terminosycondiciones = () => {
  return (
    <div>
      <Bannerhome />
      <Container className="bodycontent">
        <div>
          <Tyc />
        </div>
      </Container>
    </div>
  );
};

export default Terminosycondiciones;
