import React, { useEffect, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledCard, StyledTextField } from "./FromStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { Divider } from "semantic-ui-react";
import GooglePlacesAutocomplete from "../../utils/objects/GooglePlacesAutocomplete";
import { Grid } from "@mui/material";

const FromShippingTo = ({ onChange, order, dataDirectory, companyData }) => {
  const [showEdit, setShowEdit] = useState(false); // Estado para mostrar o no el formulario de edición
  const [method, setMethod] = useState(false); // Para cambiar entre selección de dirección o entrada manual
  const [selectedAddress, setSelectedAddress] = useState("");

  // Estado para almacenar los campos necesarios
  const [destinyAddress, setDestinyAddress] = useState(
    order.toAddress || {
      toAddress: "",
      toLocation: { lat: null, lng: null },
      direcction1: "",
      direcction2: "",
      direcction3: "",
      comuna: "",
      region: "",
      country: "",
      toAddressComment: "",
      userPhone: "",
      userEmail: "",
      userName: "",
    }
  );

  // Sincronizar los datos del pedido cuando el pedido cambia
  useEffect(() => {
    if (order.toAddress) {
      const odlDataAddress = {
        toAddress: order.toAddress,
        toLocation: order.toLocation || { lat: "", lng: "" },
        direcction1: order.direcction1,
        direcction2: order.direcction2,
        direcction3: order.direcction3,
        comuna: order.comuna,
        region: order.region,
        country: order.country,
        toAddressComment: order.toAddressComment,
        userPhone: order.userPhone,
        userEmail: order.userEmail,
        userName: order.userName,
      };

      setDestinyAddress(odlDataAddress);
    }
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedAddress = {
      ...destinyAddress,
      [name]: value,
    };
    setDestinyAddress(updatedAddress);
    onChange(updatedAddress); // Propagar los cambios inmediatamente
  };

  const handleSelections = (item) => {
    if (item && item.address !== "" && item.addressGeoreft !== "") {
      const update = {
        toAddress: item.address,
        toLocation: item.addressGeoreft,
        direcction1: item.direccion1 || "",
        direcction2: item.direccion2 || "",
        direcction3: item.direccion3 || "",
        comuna: item.locality || "",
        region: item.region || "",
        country: item.country || "",
      };
      setDestinyAddress(update);
      onChange(update);
    }
  };

  const handleMethodChange = (e) => {
    setMethod(e.target.checked);
    if (!e.target.checked) {
      setSelectedAddress(""); // Reiniciar la dirección seleccionada cuando se vuelva al ingreso manual
    }
  };

  const handleSelect = (event) => {
    const value = event.target.value;

    if (value === companyData.id) {
      const newAddress = {
        toAddress: companyData.companyAddress,
        toLocation: {
          lat: companyData.addressGeoreft.lat,
          lng: companyData.addressGeoreft.lng,
        },
        direcction1: companyData.direcction1,
        direcction2: companyData.companyNumAddress,
        direcction3: companyData.companyDptoAddress,
        comuna: companyData.companyCity,
        region: companyData.companyRegion,
        country: companyData.companyCountry,
        userPhone: companyData.companyAdressPhone,
        userEmail: companyData.companyContacEmail,
        userName: companyData.companyName,
      };

      if (JSON.stringify(newAddress) !== JSON.stringify(destinyAddress)) {
        setDestinyAddress(newAddress);
        setSelectedAddress(companyData.id);
        onChange(newAddress);
      }
    } else {
      const selectedData = dataDirectory.find((item) => item.id === value);
      if (selectedData) {
        const newAddress = {
          toAddress: selectedData.toAddressOrigin,
          toLocation: selectedData.addressGeoreft,
          direcction1: selectedData.direcction1Origin,
          direcction2: selectedData.direcction2Origin,
          direcction3: selectedData.direcction3Origin,
          comuna: selectedData.communeOrigin,
          region: selectedData.regionOrigin,
          country: selectedData.countryOrigin,
          userPhone: selectedData.userPhoneOrigin,
          userEmail: selectedData.userEmailOrigin,
          userName: selectedData.userNameOrigin,
        };

        if (JSON.stringify(newAddress) !== JSON.stringify(destinyAddress)) {
          setDestinyAddress(newAddress);
          setSelectedAddress(value);
          onChange(newAddress);
        }
      }
    }
  };

  const toggleEdit = () => {
    setShowEdit((prev) => !prev);
  };

  const saveEditedAddress = () => {
    // Concatenar los campos para construir el toAddress completo
    const concatenatedAddress = `${destinyAddress.direcction1 || ""} ${
      destinyAddress.direcction2 || ""
    }, ${destinyAddress.direcction3 || ""}${
      destinyAddress.direcction3 ? ", " : ""
    } ${destinyAddress.comuna || ""}, ${destinyAddress.region || ""}, ${
      destinyAddress.country || ""
    }`.trim();

    // Actualizar el campo toAddress concatenado
    const updatedAddress = {
      ...destinyAddress,
      toAddress: concatenatedAddress, // Seteamos la nueva dirección completa
    };

    // Guardar los cambios
    onChange(updatedAddress); // Propagar los cambios manuales al componente padre
    setShowEdit(false); // Cerrar el modo de edición
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        title="Destino ( Punto B )"
        sx={{
          color: "#fff",
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent sx={{ padding: "1px" }}>
        <Typography sx={{ color: "#fff" }}>{"Dirección de entrega"}</Typography>

        <Grid item sx={{ width: "100%" }}>
          <FormControlLabel
            control={
              <Switch
                checked={method}
                onChange={handleMethodChange}
                name="seguro"
                color="primary"
              />
            }
            label={
              !method ? (
                <Typography sx={{ color: "#fff" }}>
                  {"Ingrese una dirección"}
                </Typography>
              ) : (
                <Typography sx={{ color: "#fff" }}>
                  {"Seleccione una Dirección"}
                </Typography>
              )
            }
          />
        </Grid>
        <StyledTextField
          name="userName"
          value={destinyAddress.userName}
          onChange={handleChange}
          placeholder="Nombre del destinatario"
          fullWidth
        />
        <StyledTextField
          name="userPhone"
          value={destinyAddress.userPhone}
          onChange={handleChange}
          placeholder="Teléfono"
          fullWidth
        />
        <StyledTextField
          name="userEmail"
          value={destinyAddress.userEmail}
          onChange={handleChange}
          placeholder="Correo Electrónico"
          fullWidth
        />

        {method ? (
          <FormControl fullWidth margin="dense">
            <Select
              sx={{
                backgroundColor: "#fff",
                ".MuiOutlinedInput-input": {
                  borderRadius: "10px",
                  padding: "10px",
                  fontSize: "1rem",
                  color: "#333",
                },
              }}
              value={selectedAddress}
              onChange={handleSelect}
              input={
                <OutlinedInput
                  notched
                  startAdornment={<LocationOnIcon color="secondary" />}
                />
              }
            >
              {dataDirectory
                .filter(
                  (item) =>
                    item.addressGeoreft && // Verifica que addressGeoreft exista
                    item.addressGeoreft.lat &&
                    item.addressGeoreft.lng // Verifica que lat y lng contengan valores válidos
                )
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.adressName}
                  </MenuItem>
                ))}

              {/* Validación para companyData */}
              {companyData.addressGeoreft &&
              companyData.addressGeoreft.lat &&
              companyData.addressGeoreft.lng ? (
                <MenuItem key={companyData.id} value={companyData.id}>
                  {companyData.companyName} (Default)
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
        ) : (
          <Box sx={{ p: 1 }}>
            <GooglePlacesAutocomplete
              adress={destinyAddress}
              handleSelections={handleSelections}
              defaultAddress={destinyAddress.toAddress}
            />
          </Box>
        )}

        <Divider />

        {showEdit && (
          <>
            <StyledTextField
              name="direcction1"
              value={destinyAddress.direcction1}
              onChange={handleChange}
              placeholder="Calle"
              fullWidth
            />
            <StyledTextField
              name="direcction2"
              value={destinyAddress.direcction2}
              onChange={handleChange}
              placeholder="Número de calle"
              fullWidth
            />
            <StyledTextField
              name="direcction3"
              value={destinyAddress.direcction3}
              onChange={handleChange}
              placeholder="Oficina/Dpto o N/A"
              fullWidth
            />
            <StyledTextField
              name="comuna"
              value={destinyAddress.comuna}
              onChange={handleChange}
              placeholder="Comuna"
              fullWidth
            />
            <StyledTextField
              name="region"
              value={destinyAddress.region}
              onChange={handleChange}
              placeholder="Región"
              fullWidth
            />
            <StyledTextField
              name="country"
              value={destinyAddress.country}
              onChange={handleChange}
              placeholder="País"
              fullWidth
            />
            <StyledTextField
              name="toAddressComment"
              value={destinyAddress.toAddressComment}
              onChange={handleChange}
              placeholder="Comentarios de la dirección"
              fullWidth
            />
            <Button onClick={saveEditedAddress} sx={{ color: "#FFF", mt: 2 }}>
              Guardar cambios
            </Button>
          </>
        )}

        <Button onClick={toggleEdit} sx={{ color: "#FFF", mt: 2 }}>
          <EditOutlinedIcon />{" "}
          {!showEdit ? "Editar dirección" : "Cerrar edición"}
        </Button>
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingTo;
