import React from "react";
import {
  LinearProgress,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registrar componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const processDriverData = (data) => {
  let ordersByDay = {};
  let comunas = {};
  let origins = {};
  let totalEntregas = 0;
  let totalNoEntregas = 0;
  let totalPendientes = 0;
  let ordersByMonthYear = {};

  data.forEach((order) => {
    // Procesar fechas para agrupar por día
    const date = new Date(order.createdAt.seconds * 1000); // Convertir timestamp de Firebase a fecha
    const day = date.toISOString().split("T")[0]; // Solo la parte YYYY-MM-DD
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;

    // Agrupar órdenes por día
    if (!ordersByDay[day]) {
      ordersByDay[day] = 0;
    }
    ordersByDay[day]++;

    // Agrupar comunas visitadas
    if (!comunas[order.comuna]) {
      comunas[order.comuna] = 0;
    }
    comunas[order.comuna]++;

    // Agrupar orígenes
    if (!origins[order.placeName]) {
      origins[order.placeName] = 0;
    }
    origins[order.placeName]++;

    // Contar total de entregas, no entregas y pendientes
    if (order.status === 8) {
      totalEntregas++;
    } else if (order.status < 8) {
      totalNoEntregas++;
    } else if (order.status < 8) {
      totalPendientes++;
    }

    // Agrupar por mes y año
    if (!ordersByMonthYear[monthYear]) {
      ordersByMonthYear[monthYear] = 0;
    }
    ordersByMonthYear[monthYear]++;
  });

  return {
    ordersByDay,
    comunas,
    origins,
    totalEntregas,
    totalNoEntregas,
    totalPendientes,
    ordersByMonthYear,
  };
};

const Historial = ({ driverOperationData }) => {
  if (!driverOperationData) {
    return <LinearProgress />;
  }

  // Procesar los datos
  const {
    ordersByDay,
    comunas,
    origins,
    totalEntregas,
    totalNoEntregas,
    totalPendientes,
    ordersByMonthYear,
  } = processDriverData(driverOperationData);

  // Preparar los datos para el gráfico de órdenes por día
  const ordersByDayLabels = Object.keys(ordersByDay);
  const ordersByDayData = Object.values(ordersByDay);

  const ordersByDayChartData = {
    labels: ordersByDayLabels,
    datasets: [
      {
        label: "Órdenes por día",
        data: ordersByDayData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  // Preparar los datos para las comunas
  const comunasLabels = Object.keys(comunas);
  const comunasData = Object.values(comunas);

  const comunasChartData = {
    labels: comunasLabels,
    datasets: [
      {
        label: "Comunas visitadas",
        data: comunasData,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  // Preparar los datos para las comunas
  const originsLabels = Object.keys(origins);
  const originsData = Object.values(origins);

  const originsChartData = {
    labels: originsLabels,
    datasets: [
      {
        label: "Origenes",
        data: originsData,
        backgroundColor: "rgba(85, 179, 209, 0.6)",
      },
    ],
  };

  const ordersByMonthYearLabels = Object.keys(ordersByMonthYear);
  const ordersByMonthYearData = Object.values(ordersByMonthYear);

  const ordersByMonthYearChartData = {
    labels: ordersByMonthYearLabels,
    datasets: [
      {
        label: "Mes - Año",
        data: ordersByMonthYearData,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  return (
    <Box>
      {/* Gráfico de órdenes por día */}
      <Typography variant="h6">Órdenes por día</Typography>
      <Bar data={ordersByDayChartData} />
      {/* Gráfico de comunas visitadas */}
      <Typography variant="h6" mt={4}>
        Origenes
      </Typography>
      <Bar data={originsChartData} />
      <Typography variant="h6" mt={4}>
        Origenes por més y año
      </Typography>
      <Bar data={ordersByMonthYearChartData} />

      {/* Listas de totales */}
      <Box mt={4}>
        <Typography variant="h6">Totales</Typography>
        <List>
          <ListItem>
            <ListItemText primary={`Total entregas: ${totalEntregas}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Total no entregas: ${totalNoEntregas}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Total pendientes: ${totalPendientes}`} />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Historial;
