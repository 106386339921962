import React, { useState } from "react";
import { Card, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { db } from "../../../../firebase";
import { doc, updateDoc, collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { createOrderBicciexpressByGS } from "../../../../redux/accionesOrders";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";

const ToolDashUpdateReportGeosort = (props) => {
  const [mensajeLoadDataEstatusID, setMensajeLoadDataEstatusID] =
    useState(null); // Estado para el mensaje de error
  const [toolsData, setToolsData] = useState(null);

  const dispacth = useDispatch();

  const handleFileUpdateReportGeosort = async (e) => {
    console.log("Aqui GS");

    const files = e.target.files;
    if (files.length > 0) {
      props.setAlertMensajes(null);
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets[woskbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);

    try {
      const int_client_soc = jsonData[0]["Suborden"];
      const nouse = jsonData[0]["Do"];
      const int_falabellaStatusDescription = jsonData[0]["Estado"];
      const routeId = jsonData[0]["Idruta"];
      const routePosition = jsonData[0]["Posicionruta"];
      const toAddressLocation = jsonData[0]["Direccion"];
      const commune = jsonData[0]["Localidad"];
      const region = jsonData[0]["Region"];
      const productVolume = jsonData[0]["Volumen"];
      const routeStartTime = jsonData[0]["Horainicio"];
      const routeEndTime = jsonData[0]["Horafin"];
      const createdAt = jsonData[0]["Fechainicioruta"];
      const deliveryRealDeliveryDate = jsonData[0]["Fechapactada"];
      const updatedAt = jsonData[0]["Fechaentregareal"];
      const routeEstimatedDate = jsonData[0]["Fechaestimada"];
      const serviceType = jsonData[0]["Tipoviaje"];
      // const = jsonData[0]["Tipogeo"];
      // const = jsonData[0]["Tipodocumento"];
      const deliveryReceptor = jsonData[0]["Paperlessreceptor"];
      const deliveryRut = jsonData[0]["Paperlessrut"];
      const deliveryMethod = jsonData[0]["Metodoentrega"];

      const driverEmail = jsonData[0]["Driveremail"];
      const driverName = jsonData[0]["Drivername"];
      const driverRut = jsonData[0]["Driverrut"];
      // const toAddress = jsonData[0]["Pointlatitude"];
      // const toAddress = jsonData[0]["Pointlongitude"];
      // const deliveryGeoref = jsonData[0]["Deliverylatitude"];
      // const deliveryGeoref = jsonData[0]["Deliverylongitude"];
      const routeRanceTotalization = jsonData[0]["Distance_totalization"];
      const int_simpliRouteId = jsonData[0]["Simpliroute_id"];
      const deliveryPictures = jsonData[0]["Imageurl"];
      const int_lpn = jsonData[0]["LPN"];
      // const int_lpn = jsonData[0]["LPN_Container"];
      const int_placeName = jsonData[0]["Commerce"];
      // const = jsonData[0]["ParentOrder"];

      if (
        int_client_soc &&
        nouse &&
        int_falabellaStatusDescription &&
        routeId &&
        // routePosition &&
        toAddressLocation &&
        commune &&
        region &&
        productVolume &&
        routeStartTime &&
        routeEndTime &&
        createdAt &&
        deliveryRealDeliveryDate &&
        updatedAt &&
        routeEstimatedDate &&
        serviceType &&
        deliveryMethod &&
        driverEmail &&
        driverName &&
        driverRut &&
        routeRanceTotalization &&
        int_simpliRouteId &&
        int_lpn &&
        int_placeName
      ) {
        setToolsData(jsonData);
        props.setDocumentData(jsonData);
        props.setDataType("geosorrepot");
        props.setRender(!props.remder);
      } else {
        props.setAlertMensajes(
          <div>
            <div>
              Nonmbres clave requeridos revise el documento y ajustelo para
              procesarlo.
            </div>
            <div>
              {int_client_soc ? <div></div> : "Error Suborde " + int_client_soc}
            </div>
            <div>
              {int_falabellaStatusDescription ? (
                <div></div>
              ) : (
                "Error Estado " + int_falabellaStatusDescription
              )}
            </div>
            <div>{routeId ? <div></div> : "Error Idruta " + routeId}</div>
            <div>
              {routePosition ? (
                <div></div>
              ) : (
                "Alerta Posicionruta " + routePosition
              )}
            </div>
            <div>
              {toAddressLocation ? (
                <div></div>
              ) : (
                "Error Dirección " + toAddressLocation
              )}
            </div>
            <div>{commune ? <div></div> : "Error Localidad " + commune}</div>
            <div>{region ? <div></div> : "Error region " + region}</div>
            <div>
              {productVolume ? <div></div> : "Error Volumen " + productVolume}
            </div>
            <div>
              {routeStartTime ? (
                <div></div>
              ) : (
                "Error Horainicio " + routeStartTime
              )}
            </div>
            <div>
              {routeEndTime ? <div></div> : "Error Horafin " + routeEndTime}
            </div>
            <div>
              {createdAt ? <div></div> : "Error Fechainicioruta " + createdAt}
            </div>
            <div>
              {deliveryRealDeliveryDate ? (
                <div></div>
              ) : (
                "Error Fechapactada " + deliveryRealDeliveryDate
              )}
            </div>
            <div>
              {updatedAt ? <div></div> : "Error Fechaentregareal " + updatedAt}
            </div>
            <div>
              {routeEstimatedDate ? (
                <div></div>
              ) : (
                "Error Fechaestimada " + routeEstimatedDate
              )}
            </div>
            <div>
              {serviceType ? <div></div> : "Error Fechaestimada " + serviceType}
            </div>
            <div>
              {deliveryReceptor ? (
                <div></div>
              ) : (
                "Alerta Paperlessreceptor " + deliveryReceptor
              )}
            </div>
            <div>
              {deliveryRut ? <div></div> : "Alerta Paperlessrut " + deliveryRut}
            </div>
            <div>
              {deliveryMethod ? (
                <div></div>
              ) : (
                "Error Metodoentrega " + deliveryMethod
              )}
            </div>
            <div>
              {driverEmail ? <div></div> : "Error Driveremail " + driverEmail}
            </div>
            <div>
              {driverName ? <div></div> : "Error Drivername " + driverName}
            </div>
            <div>
              {driverRut ? <div></div> : "Error Driverrut " + driverRut}
            </div>
            <div>
              {routeRanceTotalization ? (
                <div></div>
              ) : (
                "Error Distance_totalization " + routeRanceTotalization
              )}
            </div>
            <div>
              {int_simpliRouteId ? (
                <div></div>
              ) : (
                "Error Simpliroute_id " + int_simpliRouteId
              )}
            </div>
            <div>
              {deliveryPictures ? (
                <div></div>
              ) : (
                "Alerta  Imageurl " + deliveryPictures
              )}
            </div>
            <div>{int_lpn ? <div></div> : "Error LPN " + int_lpn}</div>
            <div>
              {int_placeName ? <div></div> : "Error Commerce " + int_placeName}{" "}
            </div>
          </div>
        );
      }
    } catch (error) {
      props.setAlertMensajes("No se encuentra la hoja con nombre DATA");
    }
  };
  async function cancel() {
    setToolsData(null);
    props.setRender(!props.remder);
  }
  async function pause() {
    setToolsData([]);
    props.setRender(!props.remder);
  }
  async function loadOrdesDataSddByGS(toolsData) {
    if (toolsData) {
      dispacth(createOrderBicciexpressByGS(toolsData, props.usuarioStorage));
    }
  }

  return (
    <Card
      className="typeMiniformsBarsAccionsItemsMinimal"
      sx={{ borderRadius: "3px" }}
    >
      <div className="m-2">
        <div className="labelupload">
          <label htmlFor="file-upload1">
            Complemento Actualizar Registro Ordenes - Formato GS
          </label>
          <input
            id="file-uploadGs"
            name="file-uploadGs"
            type="file"
            onChange={(e) => handleFileUpdateReportGeosort(e)}
          />
          <label htmlFor="file-uploadGs" className="choose-file">
            GeoSort<UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">Ningún archivo seleccionado.</div>
        </div>

        <div>
          {toolsData ? (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadOrdesDataSddByGS(toolsData)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon
                  className="btn-add-guide-small"
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => pause()}
                sx={{ color: "#591e8f" }}
              >
                <PauseIcon
                  className="btn-add-guide-small"
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => cancel()}
                sx={{ color: "#591e8f" }}
              >
                <CancelIcon
                  className="btn-add-guide-small-cancel "
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
            </>
          ) : null}
        </div>

        <div>
          <span className="float-letf">{mensajeLoadDataEstatusID}</span>
        </div>
        <div>
          {props.LoadDataEstatus ? <LinearProgress></LinearProgress> : null}
        </div>
      </div>
    </Card>
  );
};

export default ToolDashUpdateReportGeosort;
