import React from "react";
import { Slideshow, Slide, TextoSlide } from "./Slideshow";
import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
const Carouselhomeauto = () => {
  return (
    <main>
      <Slideshow
        className="Slideshow"
        controles={true}
        autoplay={true}
        velocidad="300"
        intervalo="10000"
      >
        <Slide>
          <img src={img1} alt="" />

          <TextoSlide colorFondo="navy">
            <a href="https://linktr.ee/bicci">
              {" "}
              <h3>Ver más contenidos </h3>
            </a>
          </TextoSlide>
        </Slide>
        <Slide>
          <img src={img2} alt="" />

          <TextoSlide>
            <a href="https://linktr.ee/bicci">
              {" "}
              <h3>Ver más contenidos </h3>
            </a>
          </TextoSlide>
        </Slide>
        <Slide>
          <img src={img3} alt="" />

          <TextoSlide>
            <a href="https://linktr.ee/bicci">
              {" "}
              <h3>Ver más contenidos </h3>
            </a>
          </TextoSlide>
        </Slide>
        <Slide>
          <img src={img4} alt="" />

          <TextoSlide>
            <a href="https://linktr.ee/bicci">
              {" "}
              <h3>Ver más contenidos </h3>
            </a>
          </TextoSlide>
        </Slide>
      </Slideshow>
    </main>
  );
};

// const Titulo = styled.p`
// 	font-size: 18px;
// 	font-weight: 700;
// 	text-transform: uppercase;
// 	margin-bottom: 10px;
// `;

export default Carouselhomeauto;
