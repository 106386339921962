import React, { useState } from "react";
import { Card, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { db } from "../../../../firebase";
import { doc, updateDoc, collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import {
  createOrderBicciexpressBySDD,
  updateOriginalOrderBicciexpressBySDD,
} from "../../../../redux/accionesOrders";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// Carga de ordernes en sistema para regitros operativos y financieros, las ordenes no son gestionadas desde la aplicacion bicci solo son subidas a tablas de Bicciexpress
// - El Objetivo es crear registos, con dos objetivos registrar envios con app externas para evaluaciones, realizar cuadratura de pagos recibidos y pagos realizados a los conductores.
const ToolDashUpdatePaymentsByDocAntsMethod = (props) => {
  const dispacth = useDispatch();
  const [toolsData, setToolsData] = useState(null);

  const handleFilePaymentPrecioUnoaUno = async (e) => {
    console.log("Aqui SDD");

    const files = e.target.files;
    if (files.length > 0) {
      props.setAlertMensajes(null);
    } else {
      props.setAlertMensajes("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);

    try {
      const int_serviceType = jsonData[0]["TIPODELIVERY"];
      const productHeight = jsonData[0]["ALTO"];
      const productWidth = jsonData[0]["ANCHO"];
      const int_warehouseCode = jsonData[0]["BODEGACODIGO"];
      const int_warehouseName = jsonData[0]["BODEGANOMBRE"];
      // const driverVehicle = jsonData[0]["CAMION"];
      const clientEmail = jsonData[0]["CLIENTECORREO"];
      const clientNames = jsonData[0]["CLIENTENOMBRES"];
      const clientRut = jsonData[0]["CLIENTERUT"];
      const clientPhone = jsonData[0]["CLIENTETELEFONO"];
      const commune = jsonData[0]["COMUNA"];
      const commune2 = jsonData[0]["COMUNA2"];
      //const description = jsonData[0]["DESCRIPTION"];
      const direccion1 = jsonData[0]["DIRECCION1"];
      const direccion2 = jsonData[0]["DIRECCION2"];
      const direccion3 = jsonData[0]["DIRECCION3"];
      const int_f12 = jsonData[0]["F12"];
      const deliveryDate = jsonData[0]["FECHAVIAJE"];
      const productLength = jsonData[0]["LARGO"];
      const int_lpn = jsonData[0]["LPN"];
      const productMultiDelivery = jsonData[0]["MULTIBULTO"];
      // const int_travelNumber = jsonData[0]["NUMEROVIAJE"];
      const productWeight = jsonData[0]["PESO"];
      const productName = jsonData[0]["PRODUCTO"];
      const int_rangeDate = jsonData[0]["RANGOFECHAPACTADA"];
      const pickedUp = jsonData[0]["RECOGIDO"];
      const int_serviceLevel = jsonData[0]["SERVICELEVEL"];
      //const productSku = jsonData[0]["SKU"];
      const int_soc = jsonData[0]["SOC"];
      const int_productSize = jsonData[0]["TAMANOPRODUCTO"];
      //const productQuantity_packages = jsonData[0]["UNIDADES"];
      const productValue = jsonData[0]["VALOR"];
      const routeStartTime = jsonData[0]["VENTANAINICIO"];
      const routeEndTime = jsonData[0]["VENTANATERMINO"];

      setToolsData(jsonData);
      props.setAlertMensajes(
        <div>
          <div className="alert alert-info m-2"></div>
          {/* <div>
            {driverVehicle ? "" : "Faltann datos en CAMION " + driverVehicle}
          </div> */}
          {/* <div>
            {int_travelNumber
              ? ""
              : "Faltann datos en NUMERO DE VIAJE " + int_travelNumber}
          </div> */}
          <div>{int_lpn ? "" : "Faltann datos en LPN " + int_lpn}</div>
          <div>
            {deliveryDate ? "" : "Faltann datos en FECHAVIAJE " + deliveryDate}
          </div>
        </div>
      );
      props.setDocumentData(jsonData);
      props.setDataType("bicciSdd");
      props.setRender(!props.remder);

      if (
        productHeight &&
        productWidth &&
        int_warehouseCode &&
        int_warehouseName &&
        clientEmail &&
        clientNames &&
        clientRut &&
        clientPhone &&
        commune &&
        commune2 &&
        direccion1 &&
        direccion2 &&
        int_f12 &&
        productLength &&
        productMultiDelivery &&
        productName &&
        int_rangeDate &&
        pickedUp &&
        int_serviceLevel &&
        //productSku &&
        int_soc &&
        int_productSize &&
        //productQuantity_packages &&
        productValue &&
        routeStartTime
      ) {
        setToolsData(jsonData);
        props.setAlertMensajes(
          <div>
            <div className="alert alert-info m-2">
              Si es un archivo de estimacion puede continuar, pero la ausencuas
              de datos puede afectar la creacion de rutas
            </div>
            <div>{int_lpn ? "" : "Faltann datos en LPN " + int_lpn}</div>
            <div>
              {deliveryDate
                ? ""
                : "Faltann datos en FECHAVIAJE " + deliveryDate}
            </div>
          </div>
        );
        props.setDocumentData(jsonData);
        props.setDataType("bicciSdd");
        props.setRender(!props.remder);
      } else {
        props.setAlertMensajes(
          <div>
            <div>
              Nonmbres clave requeridos revise el documento y ajustelo para
              procesarlo.
            </div>
            <div>{productHeight ? "" : "Error en ALTO " + productHeight}</div>
            <div>{productWidth ? "" : "Error en ANCHO " + productWidth}</div>
            <div>
              {int_warehouseCode
                ? ""
                : "Error en BODEGACODIGO " + int_warehouseCode}
            </div>
            <div>
              {int_warehouseName
                ? ""
                : "Error en BODEGANOMBRE " + int_warehouseName}
            </div>

            <div>
              {clientEmail ? "" : "Error en CLIENTECORREO " + clientEmail}
            </div>
            <div>
              {clientNames ? "" : "Error en CLIENTENOMBRES " + clientNames}
            </div>
            <div>{clientRut ? "" : "Error en CLIENTERUT " + clientRut}</div>
            <div>
              {clientPhone ? "" : "Error en CLIENTETELEFONO " + clientPhone}
            </div>
            <div>{commune ? "" : "Error en COMUNA1 " + commune}</div>
            <div>{commune2 ? "" : "Error en COMUNA2 " + commune2}</div>
            {/* <div>
              {description ? "" : "Error en DESCRIPTION " + description}
            </div> */}
            <div>{direccion1 ? "" : "Error en DIRECCION1 " + direccion1}</div>
            <div>{direccion2 ? "" : "Error en DIRECCION2 " + direccion2}</div>
            <div>{direccion3 ? "" : "Error en DIRECCION3 " + direccion3}</div>
            <div>{int_f12 ? "" : "Error en F12 " + int_f12}</div>

            <div>
              {int_rangeDate
                ? ""
                : "Error en RANGOFECHAPACTADA " + int_rangeDate}
            </div>

            <div>
              {productMultiDelivery
                ? ""
                : "Error en MULTBULTO " + productMultiDelivery}
            </div>

            <div>
              {productWeight ? "" : "Error en MEDIDAS " + productWeight}
            </div>
            <div>
              {productName ? "" : "Error en NOMBRE PRODUCTO " + productName}
            </div>
            <div>
              {int_rangeDate ? "" : "Error en RANGO DE FECHA " + int_rangeDate}
            </div>
            <div>{pickedUp ? "" : "Error en RECOGIDO " + pickedUp}</div>
            <div>
              {int_serviceLevel
                ? ""
                : "Error en SERVICELEVEL" + int_serviceLevel}
            </div>
            {/* <div>{productSku ? "" : "Error en SKU " + productSku}</div> */}
            <div>{int_soc ? "" : "Error en SOC " + int_soc}</div>
            <div>
              {int_productSize
                ? ""
                : "Error en TAMANOPRODUCTO " + int_productSize}
            </div>
            {/* <div>
              {productQuantity_packages
                ? ""
                : "Error en UNIDADES " + productQuantity_packages}
            </div> */}
            <div>{productValue ? "" : "Error en VALOR " + productValue}</div>
            <div>
              {routeStartTime ? "" : "Error en VENTANAINICIO " + routeStartTime}
            </div>
          </div>
        );
      }
    } catch (error) {
      props.setAlertMensajes(<div>No se encuentra la hoja DATA</div>);
    }
  };

  async function cancel() {
    setToolsData(null);
    props.setRender(!props.remder);
  }
  async function pause() {
    setToolsData([]);
    props.setRender(!props.remder);
  }
  async function loadOrdesDataSdd(toolsData) {
    if (toolsData) {
      dispacth(createOrderBicciexpressBySDD(toolsData, props.usuarioStorage));
      // dispacth(updateOriginalOrderBicciexpressBySDD(toolsData, props.usuarioStorage))
    }
  }
  async function UpdateDataSdd(toolsData) {
    if (toolsData) {
      //dispacth(createOrderBicciexpressBySDD(toolsData, props.usuarioStorage));
      dispacth(
        updateOriginalOrderBicciexpressBySDD(toolsData, props.usuarioStorage)
      );
    }
  }

  return (
    <Card
      className="typeMiniformsBarsAccionsItemsMinimal"
      sx={{ borderRadius: "3px" }}
    >
      <div className="m-2">
        <div className="labelupload">
          <label htmlFor="file-upload1">
            Crear registros Ordenes - Formato SDD
          </label>
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            onChange={(e) => handleFilePaymentPrecioUnoaUno(e)}
          />
          <label htmlFor="file-upload" className="choose-file">
            <UploadFileIcon></UploadFileIcon>
          </label>
          <div id="file-upload-message">Ningún archivo seleccionado.</div>
        </div>

        <div>
          {toolsData ? (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadOrdesDataSdd(toolsData)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon
                  className="btn-add-guide-small"
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
                Crear
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => UpdateDataSdd(toolsData)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon
                  className="btn-add-guide-small"
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
                Actualizar
              </IconButton>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => pause()}
                sx={{ color: "#591e8f" }}
              >
                <PauseIcon
                  className="btn-add-guide-small"
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => cancel()}
                sx={{ color: "#591e8f" }}
              >
                <CancelIcon
                  className="btn-add-guide-small-cancel "
                  sx={{ color: toolsData ? "#F8F8F8" : "#591e8f" }}
                />
              </IconButton>
            </>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default ToolDashUpdatePaymentsByDocAntsMethod;
