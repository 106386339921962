import React from "react";
import { Container, Divider } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { Tab } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { db } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { clientAddCartProduct } from "../../../redux/acccionesCart";

var cuenta = "Sin contenido";
var facturacion = "No se muestran datos";
var productos = "No se muestran datos";

//asignar ruta Dashboard en APP.JSX oculto elemento seleccione_plan

const panes = [
  {
    menuItem: "Planes Disponibles",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {productos}
      </Tab.Pane>
    ),
  },
];
var userUid = "noregister"
var  userState = false
const Seleccioneplan = (props) => {
  
  const dispatch = useDispatch()
  React.useEffect(() => {
    obtenerTodoslosservicios();
 
  }, []);

  const [productosActivios, setProductosActivios] = React.useState([]);
  
  const obtenerTodoslosservicios = async () => {
    const producServices = await db.collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`).get();
    const servicios = producServices.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    var dataBolsas1 = servicios.filter((item) => item.isDelete === false);
    var dataBolsas2 = dataBolsas1.filter((item) => item.isActive === true);
    var dataBolsas3 = dataBolsas2.filter((item) => item.type !== 'custom');
    setProductosActivios(dataBolsas3);
    console.log(servicios);
  };
  try {
    const user = useSelector((store) => store.usuario.user.uid);
    userUid = user 
    userState = true
   } catch (error) {
    userState = false
   }
  
   const addToCard = (e) => {
    let obj = e.target.value
    console.log(obj, userUid)
     dispatch(clientAddCartProduct(obj,userUid));
   // props.history.push(`/checkout/`)
  }

  productos = (
    <Container className='dashcontent'>
      {productosActivios.map((element) => (
        <Grid.Row key={element.id} className="divmap">
          <Grid.Column
            computer={6}
            mobile={6}
            tablet={6}
            className="dash-container-colmun colmunPd30"
          >
            <Grid.Row className="distproductos">
              <div>
                <h3>{element.planName}</h3>
              </div>
            </Grid.Row>
            <Grid.Row className="distproductos">
        
              <button onClick={addToCard} value={element.id} className="btncambiar">Seleccionar
                                    </button>
              {/* <Modalhome  /> */}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            computer={6}
            mobile={6}
            tablet={6}
            className="dash-container-colmun colmunPd70"
          >
            <Grid.Row className=" distproductosdescrip">
              <Grid.Column className="dash-container-colmun-equal">
                Plan
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal ">
                <h5>{element.description}</h5>
                {
                element.descriptorsWeb.length>0?(<> cuenta con : </>):(<></>)
                }
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal textalignend">
              <div>Precio   ${element.salePrice}</div>
               <div>Descuento ${element.discountAmount}</div>
               <div>Total a pagar <strong>${element.totalAmount}</strong></div>
              </Grid.Column>
              
            </Grid.Row>
            <Grid.Row className="distproductosdescrip">
              <Grid.Column className="dash-container-colmun-equal ">
                Uso
              </Grid.Column>
              <Grid.Column className="dash-container-colmun-equal ">
               se agregan mensualmente {element.ChargeShipments} envíos a su cuenta, asociados a un tarifario de tipo <h5>{element.rateEbiex}</h5>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Container>
  );

  return (
    <Tab
      className="tabular menu"
      menu={{ secondary: true }}
      panes={panes}
    ></Tab>
  );
};

export default Seleccioneplan;
