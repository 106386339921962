import React from "react";
import { useEffect } from "react";
import { Card, Divider, CardContent, Box } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import GroupsIcon from "@mui/icons-material/Groups";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { PowerInputSharp } from "@mui/icons-material";
const DashIconsResumenDash = (props) => {
  // console.log(props)
  useEffect(() => {}, [props.cantD && props.cantV]);

  const COLORS = {
    Invitados: "dashIcon-blue",
    Inactivos: "dashIcon-grey",
    Activos: "dashIcon-green",
    Conectados: "dashIcon-on-line",
    Bloqueados: "dashIcon-grey",
    Disponibles: "dashIcon-on-line",
  };
  const CANT = {
    Invitados: props.cantD ? props.cantD.Invitados : 0,
    Inactivos: props.cantD.Inactivos ? props.cantD.Inactivos : 0,
    Activos: props.cantD.Activos ? props.cantD.Activos : 0,
    Conectados: props.cantD.Conectados ? props.cantD.Conectados : 0,
  };

  const CANTV = {
    Disponibles: props.cantV.Disponibles ? props.cantV.Disponibles : 0,
    Conectados: props.cantV.Conectados ? props.cantV.Conectados : 0,
    Bloqueados: props.cantV.Bloqueados ? props.cantV.Bloqueados : 0,
  };

  const CANTU = {
    Disponibles: props.cantU.Disponibles ? props.cantU.Disponibles : 0,
    Invitados: props.cantU.Invitados ? props.cantU.Invitados : 0,
    Bloqueados: props.cantU.Bloqueados ? props.cantU.Bloqueados : 0,
  };

  return (
    <div>
      <Box className="DashIconsResumenBoxCard">
        <div className="DashIconsResumenBoxHeader">
          <DirectionsBikeIcon className={`dashIconTable `} />
          <h3>Riders</h3>
        </div>
        <div className="DashIconsResumenBoxrowContent">
          {props.itemsD.map((item) => (
            <span key={item.id}>
              <Card
                className="DashIconsResumencard02"
                sx={{
                  display: "inline-block",
                }}
              >
                <CardContent className="dashIconsresumencardcontent06">
                  <Divider />
                  <div>
                    <span className={`dashicontitled ${COLORS[item.id]}`}>
                      {item.id}
                    </span>
                  </div>

                  <Box>
                    <span className="dashicontspancustontext">{`${
                      CANT[item.id]
                    }`}</span>
                    <span className="dashicontspansmalltext">{`User(s)`}</span>
                  </Box>
                </CardContent>
              </Card>
            </span>
          ))}
        </div>
      </Box>

      <Box className="DashIconsResumenBoxCard">
        <div className="DashIconsResumenBoxHeader">
          <LocalShippingIcon className={`dashIconTable truckDash`} />
          <h3>Vehículos</h3>
        </div>
        <div className="DashIconsResumenBoxrowContent">
          {props.itemsV.map((item) => (
            <span key={item.id}>
              <Card
                className="DashIconsResumencard02"
                sx={{
                  display: "inline-block",
                }}
              >
                <CardContent className="dashIconsresumencardcontent06">
                  <Divider />
                  <div>
                    <span className={`dashicontitled ${COLORS[item.id]}`}>
                      {item.id}
                    </span>
                  </div>

                  <Box>
                    <span className="dashicontspancustontext">{`${
                      CANTV[item.id]
                    }`}</span>
                    <span className="dashicontspansmalltext">{`Unit(s)`}</span>
                  </Box>
                </CardContent>
              </Card>
            </span>
          ))}
        </div>
      </Box>

      <Box className="DashIconsResumenBoxCard">
        <div className="DashIconsResumenBoxHeader">
          <GroupsIcon className={`dashIcon groupUser`} />
          <h3>Usuarios</h3>
        </div>
        <div className="DashIconsResumenBoxrowContent">
          {props.itemsU.map((item) => (
            <span key={item.id}>
              <Card
                className="DashIconsResumencard02"
                sx={{
                  display: "inline-block",
                }}
              >
                <CardContent className="dashIconsresumencardcontent06">
                  <Divider />
                  <div>
                    <span className={`dashicontitled ${COLORS[item.id]}`}>
                      {item.id}
                    </span>
                  </div>

                  <Box>
                    <span className="dashicontspancustontext">{`${
                      CANTU[item.id]
                    }`}</span>
                    <span className="dashicontspansmalltext">{`User(s)`}</span>
                  </Box>
                </CardContent>
              </Card>
            </span>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default DashIconsResumenDash;
