import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from "@mui/material";

const ReusableFormEdit = ({
  open,
  handleClose,
  formFields,
  handleSave,
  initialValues,
}) => {
  const [formData, setFormData] = useState(initialValues || {});

  useEffect(() => {
    setFormData(initialValues || {});
  }, [initialValues]);

  const handleChange = (variable, value) => {
    setFormData({ ...formData, [variable]: value });
  };

  const renderField = (field, index) => {
    switch (field.type) {
      case "string":
        return (
          <TextField
            key={index}
            autoFocus={index === 0}
            margin="dense"
            id={field.variable}
            label={field.label}
            value={formData[field.variable] || ""}
            type="text"
            fullWidth
            disabled={field.disabled || false} // Deshabilitar si la propiedad disabled es true
            onChange={(e) => handleChange(field.variable, e.target.value)}
          />
        );
      case "boolean":
        return (
          <FormControlLabel
            key={index}
            control={
              <Switch
                checked={formData[field.variable] || false}
                onChange={(e) => handleChange(field.variable, e.target.checked)}
                name={field.variable}
                color="primary"
              />
            }
            label={field.label}
          />
        );
      case "select":
        return (
          <FormControl fullWidth key={index} margin="dense">
            <InputLabel id={`${field.variable}-label`}>
              {field.label}
            </InputLabel>
            <Select
              labelId={`${field.variable}-label`}
              id={field.variable}
              value={formData[field.variable] || ""}
              onChange={(e) => handleChange(field.variable, e.target.value)}
              label={field.label}
            >
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "multi-select":
        return (
          <FormControl fullWidth key={index} margin="dense">
            <InputLabel id={`${field.variable}-label`}>
              {field.label}
            </InputLabel>
            <Select
              labelId={`${field.variable}-label`}
              id={field.variable}
              multiple
              value={
                typeof formData[field.variable] === "string"
                  ? [formData[field.variable]] // Convertir string a array
                  : formData[field.variable] || [] // Asegura que siempre sea un array
              }
              onChange={(e) =>
                handleChange(
                  field.variable,
                  Array.isArray(e.target.value) ? e.target.value : []
                )
              }
              renderValue={(selected) => {
                return selected
                  .map((value) => {
                    const selectedOption = field.options.find(
                      (option) => option.value === value
                    );
                    return selectedOption ? selectedOption.label : value;
                  })
                  .join(", ");
              }}
            >
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.value}>
                  <Checkbox
                    checked={
                      Array.isArray(formData[field.variable])
                        ? formData[field.variable].includes(option.value)
                        : formData[field.variable] === option.value // Verificar si era un string anteriormente
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Editar Información</DialogTitle>
      <DialogContent>{formFields.map(renderField)}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleSave(formData)} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormEdit;
