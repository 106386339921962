import React, { useEffect } from "react";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { useDispatch } from "react-redux";
import mapStylesClear from "../../../../../components/MapsGoogle/mapStylesClear";
import { LinearProgress } from "@mui/material";
import { decode, encode } from "@googlemaps/polyline-codec";
import getMatrixDistance from "../../../../../utils/getMatrixDistance";
import getSimpleRoute from "../../../../../utils/getSimpleRoute";
import getLowConcentrations from "../../../../../utils/getLowConcentrations";

const mapContainerStyle = {
  height: "90vh",
  width: "100%",
};
const options = {
  styles: mapStylesClear,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 11,
};

const rosettaVehicles = {
  BICYCLING: "Bicicleta",
  VEHICLE: "Bicicleta",
  TWO_WHEELER: "Motorizado",
  DRIVING: "Vehículo",
  WALKING: "Caminante",
};

const centerMaps = { lat: -33.448891, lng: -70.669266 };
// testing //
//http://localhost:3000/dashboard/planner/115/edit
//http://localhost:3000/dashboard/planner/113/edit
//http://localhost:3000/dashboard/planner/112/edit
export default function DashPlannerEditMap(props) {
  var DataPoligons = JSON.parse(localStorage.getItem("BicciToolsClientZones"));
  const [polygonsPath, setPolygons] = React.useState(null);
  const [visiblePoligon, setVisiblePoligon] = React.useState(false);

  const [markersPending, setMarkersPending] = React.useState(props.markerpoint);
  const [testMarkersPending, setTestMarkersPending] = React.useState(null);

  const [origin, setOrigin] = React.useState(null);
  const [destination, setDestinations] = React.useState(null);
  const [manifesOrders, setManifesOrders] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = React.useState("");

  const [optimizeWaypointsNodos, setOptimizeWaypointsNodos] =
    React.useState(false);
  const [havePointGroups, setHavePointGroups] = React.useState(false);
  const [hasConcentrationGreater25, setHasConcentrationGreater25] =
    React.useState(false);

  const [nodeMarker, setNodeMarker] = React.useState(null);

  const [serviceErr, setServiceErr] = React.useState("Cargando");

  const mapRef = React.useRef();
  const google = window.google;
  var bounds = new google.maps.LatLngBounds();
  var aryyaNodes = [];

  var directionsService2 = new google.maps.DirectionsService();

  const [stateReserveAllRelationsShip, setStateReserveAllRelationsShip] =
    React.useState(null);
  var reserveAllRelationsShip = {};
  var newReserveAllRelationsShip = {};
  var GruposDe21 = {};
  var GruposDe21OD = {};
  var initialLocation = {};
  var finalLocation = {};
  let canonicalOrder = {};
  let cononicalPositions = {};

  const colorIndex = {
    0: "blue",
    1: "#337AFF",
    2: "#ba6e09",
    3: "#7D33FF",
    4: "#64FF33",
    5: "#FF8A26",
    6: "#red",
    7: "#6AF525",
    9: "#C5F539",
    10: "red",
    11: "green",
    12: "#C5F539",
  };

  const onMapLoadRute = React.useCallback(
    (map) => {
      mapRef.current = map;
      if (manifesOrders !== null) {
        inicialMap(DataPoligons);
      }
    },
    [manifesOrders]
  );

  useEffect(() => {}, [testMarkersPending]);

  useEffect(() => {
    setManifesOrders(props.manifesOrders);
  }, [props && props.manifesOrders]);

  useEffect(() => {
    if (polygonsPath === null) {
      setPolygons(DataPoligons.polygonType);
    }
  }, [DataPoligons]);

  useEffect(() => {
    setManifesOrders(props.manifesOrders);
    setOptimizeWaypointsNodos(props.optimizeWaypointsNodos);
  }, [props && props.manifesOrders]);

  useEffect(() => {
    console.log(props.origin);
    setOrigin(props.origin);
  }, [props && props.origin]);

  useEffect(() => {
    console.log("props.destination");
    console.log(props.destination);
    if (
      props.destination !== null &&
      props.destination !== undefined &&
      props.destination.lat !== undefined &&
      props.destination.lat !== 0
    ) {
      setDestinations(props.destination);
    } else {
      setDestinations(props.origin);
    }
  }, [props && props.destination && props.origin]);

  // useEffect(() => {
  //   if (manifesOrders !== null) {
  //     initMap(DataPoligons);
  //   }
  // }, [manifesOrders && optimizeWaypointsNodos && origin && destination]);

  // useEffect(() => {
  //   if (
  //     pointGroups &&
  //     manifetsReOrderPoints &&
  //     manifetsReOrderPoints.length > 0 &&
  //     loadService
  //   ) {
  //     setManifesOrders(manifetsReOrderPoints);
  //     //evaluePointGroupsGeoref(pointGroups);
  //     initMap(DataPoligons);
  //   }
  // }, [pointGroups, manifetsReOrderPoints, loadService, errorEvents]);

  const evaluePointGroupsGeoref = (pointGroups) => {
    for (const key in pointGroups) {
      if (pointGroups[key].geoPonitRef) {
        setHavePointGroups(true);
      } else {
        setHavePointGroups(false);
      }
      if (pointGroups[key].orderIds.length > 25) {
        setHasConcentrationGreater25(true);
      } else {
        setHasConcentrationGreater25(false);
      }
    }
  };

  //carga inicial en map.

  async function inicialMap(polygonsPath) {
    const resultType = await reviewData(manifesOrders);
    console.log(manifesOrders.length, resultType, origin, destination);
    if (resultType && origin && destination) {
      // METODO AGRUPADO mas de 23 + Auto + ALTA CONCETRACION GGOGLEMAPS
      if (manifesOrders.length > 23 && resultType === "highDensity") {
        //dumny variables de hoock
        const { pointGroups, manifetsReOrderPoints, loadService, errorEvents } =
          await getMatrixDistance(
            manifesOrders,
            origin,
            destination,
            optimizeWaypointsNodos
          );
        console.log(" await getMatrixDistance");
        console.log(pointGroups);
        console.log(manifetsReOrderPoints);
        console.log(loadService);
        console.log(errorEvents);

        var responseHOOCKGrupos = pointGroups;
        var responseHOOCKManifest = manifesOrders;

        var reordercomunasGrouping = [];
        var comunasWaintPoint = [];

        if (responseHOOCKGrupos) {
          const { goupsWaitPoints, OrderComunasGroupingNames } =
            await procesarResponseHOOCKGrupos(responseHOOCKGrupos);
          comunasWaintPoint = goupsWaitPoints;
          console.log(comunasWaintPoint);
          var directionsDisplay = new google.maps.DirectionsRenderer({
            map: mapRef.current,
            preserveViewport: true,
            suppressMarkers: true,
            polylineOptions: {
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeColor: "#000",
            },
          });
          var boundsOne = new google.maps.LatLngBounds();
          var directionsService = new google.maps.DirectionsService();

          await calculateRouteByZones(
            directionsService,
            directionsDisplay,
            origin,
            destination,
            goupsWaitPoints, //comunasWaintPoint,
            responseHOOCKGrupos,
            boundsOne
          );

          async function calculateRouteByZones(
            directionsService,
            directionsDisplay,
            startPointalgorithm,
            endPointalgorithm,
            comunasWaintPoint,
            responseHOOCKGrupos,
            bounds
          ) {
            // Ruta General direcciona o ordena los grupos de ordenes.
            const response = await directionsService.route({
              origin: startPointalgorithm,
              destination: endPointalgorithm,
              travelMode: "BICYCLING",
              optimizeWaypoints: true,
              waypoints: comunasWaintPoint,
            });

            if (response.status === "OK" && responseHOOCKGrupos) {
              try {
                if (response.routes[0].waypoint_order.length > 0) {
                  //directionsDisplay.setDirections(response);
                  bounds.union(response.routes[0].bounds);
                  mapRef.current.fitBounds(bounds);
                  console.log(response.routes[0].waypoint_order);

                  const dataArray = Object.entries(responseHOOCKGrupos);
                  const newOrder = response.routes[0].waypoint_order;

                  const reOrder_comunasWaintPoint =
                    await reorderLocalityByResponse(
                      dataArray,
                      response,
                      OrderComunasGroupingNames
                    );

                  const reOrder_ComunasGroupingNames = newOrder.map(
                    (index) => OrderComunasGroupingNames[newOrder[index]]
                  );

                  const temp_reOrder_responseHOOCKGrupos =
                    reOrder_ComunasGroupingNames.map((item, index) => {
                      let obj = responseHOOCKGrupos[item];
                      obj.position = index;
                      return {
                        [item]: obj,
                      };
                    });

                  const {
                    reOrder_responseHOOCKGrupos,
                    reOrder_responseHOOCKManifest,
                    updateOrder_ComunasGroupingNames,
                  } = await reorderData(
                    temp_reOrder_responseHOOCKGrupos,
                    responseHOOCKManifest,
                    reOrder_ComunasGroupingNames
                  );

                  espera(3000);

                  if (
                    startPointalgorithm !== null &&
                    endPointalgorithm !== null &&
                    reOrder_comunasWaintPoint &&
                    updateOrder_ComunasGroupingNames.length > 0 &&
                    reOrder_responseHOOCKGrupos !== null &&
                    reOrder_responseHOOCKManifest
                  ) {
                    directionsCallback(
                      response,
                      "calculateMarix",
                      startPointalgorithm,
                      endPointalgorithm,
                      reOrder_comunasWaintPoint,
                      reOrder_ComunasGroupingNames,
                      reOrder_responseHOOCKGrupos,
                      reOrder_responseHOOCKManifest,
                      "boundsOne"
                    );
                  }
                } else {
                  console.log("precondicion error");
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        } else {
          console.log("Esperando Agrupaciones en la respuesta");
        }
      }

      // METODO AGRUPADO mas de 23 + MENOS DE 23 PUNTOS POR COMUNA  + BAJA CONCETRACION

      if (manifesOrders.length > 23 && resultType === "lowDensity") {
        const {
          response,
          type,
          startPointalgorithm,
          endPointalgorithm,
          reOrder_comunasWaintPoint,
          reOrder_ComunasGroupingNames,
          reOrder_responseHOOCKGrupos,
          reOrder_responseHOOCKManifest,
        } = await getLowConcentrations(
          manifesOrders,
          origin,
          destination,
          true
        );

        // console.log("response lowDensity");
        // console.log(response);
        // console.log(type);
        // console.log(startPointalgorithm);
        // console.log(endPointalgorithm);
        // console.log(reOrder_comunasWaintPoint);
        // console.log(reOrder_ComunasGroupingNames);
        // console.log(reOrder_responseHOOCKGrupos);
        // console.log(reOrder_responseHOOCKManifest);
        if (
          response &&
          type &&
          startPointalgorithm &&
          endPointalgorithm &&
          reOrder_ComunasGroupingNames &&
          reOrder_responseHOOCKManifest
        ) {
          directionsCallback(
            response,
            type,
            startPointalgorithm,
            endPointalgorithm,
            reOrder_comunasWaintPoint,
            reOrder_ComunasGroupingNames,
            reOrder_responseHOOCKGrupos,
            reOrder_responseHOOCKManifest
          );
        }
      }

      // METODO Menor a 23 + MENOS DE 23 PUNTOS
      if (manifesOrders.length <= 23) {
        const {
          response,
          type,
          startPointalgorithm,
          endPointalgorithm,
          reOrder_comunasWaintPoint,
          reOrder_ComunasGroupingNames,
          reOrder_responseHOOCKGrupos,
          reOrder_responseHOOCKManifest,
        } = await getSimpleRoute(manifesOrders, origin, destination, true);
        if (
          response &&
          type &&
          startPointalgorithm &&
          endPointalgorithm &&
          reOrder_ComunasGroupingNames &&
          reOrder_responseHOOCKGrupos &&
          reOrder_responseHOOCKManifest
        ) {
          directionsCallback(
            response,
            type,
            startPointalgorithm,
            endPointalgorithm,
            reOrder_comunasWaintPoint,
            reOrder_ComunasGroupingNames,
            reOrder_responseHOOCKGrupos,
            reOrder_responseHOOCKManifest
          );
        }
      }
    } else {
      console.log();
    }
  }

  const directionsCallback = async (
    response,
    type,
    startPointalgorithm,
    endPointalgorithm,
    reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest,
    boundsOne
  ) => {
    async function finRoutesMaps() {
      if (response !== null) {
        setServiceErr(false);
        if (type === "calculateSimpleRoute") {
          console.log("response calculateSimpleRoute");
          calculeRouteOneGroup(
            response,
            startPointalgorithm,
            endPointalgorithm,
            null,
            reOrder_ComunasGroupingNames,
            reOrder_responseHOOCKGrupos,
            reOrder_responseHOOCKManifest
          );
        }
        if (type === "calculateByMultizonesLowDensity") {
          console.log("calculateByMultizonesLowDensity");
          calculeRouteByMultiGroupsLowDensity(
            response,
            startPointalgorithm,
            endPointalgorithm,
            reOrder_comunasWaintPoint,
            reOrder_ComunasGroupingNames,
            reOrder_responseHOOCKGrupos,
            reOrder_responseHOOCKManifest
          );
        }

        if (type === "calculateMarix") {
          console.log("calculateMarix");
          calculeRouteByMultiGroups(
            response,
            startPointalgorithm,
            endPointalgorithm,
            reOrder_comunasWaintPoint,
            reOrder_ComunasGroupingNames,
            reOrder_responseHOOCKGrupos,
            reOrder_responseHOOCKManifest
          );
        }
      } else {
        console.log("response: NOT OK ");
        console.log(response.status);
      }
    }

    await finRoutesMaps();
  };

  function espera(tiempo) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, tiempo);
    });
  }

  async function calculeRouteOneGroup(
    response,
    startPointalgorithm,
    endPointalgorithm,
    reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest
  ) {
    let newOrder = response.routes[0].waypoint_order;
    let menos21Ids = reOrder_responseHOOCKGrupos;

    const intervalGroup = newOrder.map((index) => menos21Ids[index]);

    let i = 0;
    let numCalls = 1;
    let arrayOrdenadoGrupos21 = [];
    let intervalWaintPoint = [];

    for (let element in intervalGroup) {
      reOrder_responseHOOCKManifest.forEach((elemet, index) => {
        if (elemet.orderID === intervalGroup[element]) {
          var newObj = {
            stopover: true,
            location: elemet.adressGeoNumAddressLatLng,
          };
          var newObjRelations = {
            stopover: true,
            location: elemet.adressGeoNumAddressLatLng,
            position: parseInt(index),
            id: elemet.orderID,
          };
          intervalWaintPoint.push(newObj);

          arrayOrdenadoGrupos21.push(newObjRelations);
        }
      });
    }

    const valuesArray21 = Object.values(arrayOrdenadoGrupos21);
    valuesArray21.sort((a, b) => a.position - b.position);

    reOrder_21(
      valuesArray21,
      response.routes[0].waypoint_order,
      response.routes[0].overview_polyline,
      i,
      numCalls,
      startPointalgorithm,
      endPointalgorithm
    );
  }

  async function calculeRouteByMultiGroupsLowDensity(
    response,
    startPointalgorithm,
    endPointalgorithm,
    reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest
  ) {
    console.log("constructor Multiple Rutas Baja densidad por comunas");
    console.log(startPointalgorithm);
    let temp = new google.maps.LatLng(startPointalgorithm);
    console.log(temp);
    console.log(temp.lat);
    console.log(endPointalgorithm);
    reOrder_comunasWaintPoint.unshift({
      stopover: true,
      location: {
        lat: startPointalgorithm.lat,
        lng: startPointalgorithm.lng,
      },
    });

    var newObj = {
      stopover: true,
      location: {
        lat: endPointalgorithm.lat,
        lng: endPointalgorithm.lng,
      },
    };
    reOrder_comunasWaintPoint.push(newObj);

    await createFragmentsByComunas(
      response,
      startPointalgorithm,
      endPointalgorithm,
      reOrder_comunasWaintPoint,
      reOrder_ComunasGroupingNames,
      reOrder_responseHOOCKGrupos,
      reOrder_responseHOOCKManifest
    );

    async function createFragmentsByComunas(
      response,
      startPointalgorithm,
      endPointalgorithm,
      reOrder_comunasWaintPoint,
      reOrder_ComunasGroupingNames,
      reOrder_responseHOOCKGrupos,
      reOrder_responseHOOCKManifest
    ) {
      // Array para almacenar las ubicaciones de cada comuna
      console.log("response :", response);
      console.log("startPointalgorithm :", startPointalgorithm);
      console.log("endPointalgorithm :", endPointalgorithm);
      console.log("reOrder_comunasWaintPoint :", reOrder_comunasWaintPoint);
      console.log(
        "reOrder_ComunasGroupingNames :",
        reOrder_ComunasGroupingNames
      );
      console.log("reOrder_responseHOOCKGrupos :", reOrder_responseHOOCKGrupos);
      console.log(
        "reOrder_responseHOOCKManifest: ",
        reOrder_responseHOOCKManifest
      );

      let array = [];
      let subArray = [];

      let agrupacionDe21_ReserveOD = {};
      let agrupacionDe21_elements = {};

      const sorted = Object.values(reOrder_responseHOOCKGrupos).sort(
        (a, b) => a.position - b.position
      );
      for (let i = 0; i < sorted.length; i++) {
        let intervalGroup = sorted[i].orderIds;
        let intervalWaintPoint = [];
        let reserveRelationsShip = {};
        for (let element in intervalGroup) {
          reOrder_responseHOOCKManifest.forEach((elemet, index) => {
            if (elemet.orderID === intervalGroup[element]) {
              //    console.log("elemet", elemet);
              var newObj = {
                stopover: true,
                location: elemet.adressGeoNumAddressLatLng,
              };
              var newObjRelations = {
                stopover: true,
                location: elemet.adressGeoNumAddressLatLng,
                position: parseInt(element),
                id: elemet.orderID,
              };
              intervalWaintPoint.push(newObj);

              reserveRelationsShip[intervalGroup[element]] = newObjRelations;
            }
          });
        }
        // agrupacionDe21_origenDestino[index] = intervalWaintPoint;
        agrupacionDe21_ReserveOD[i] = intervalWaintPoint;
        agrupacionDe21_elements[i] = reserveRelationsShip;
      }

      console.log("agrupacionDe21_ReserveOD", agrupacionDe21_ReserveOD);

      console.log("agrupacionDe21_elements", agrupacionDe21_elements);

      const grupos = Object.values(reOrder_responseHOOCKGrupos).sort(
        (a, b) => a.position - b.position
      );
      const reserveOD = Object.values(agrupacionDe21_ReserveOD);

      console.log(grupos);
      console.log(reserveOD);

      // GruposDe21OD agrupacion  de origen - destino  agrupacionDe21_ReserveOD

      // CREAR GruposDe21OD
      // consuta agrupacionDe21_ReserveOD [incial][waipoint][siguiente] => reOrder_responseHOOCKGrupos => reOrder_comunasWaintPoint
      // Ordenar elementos GruposDe21OD y agrupacionDe21_ReserveOD
      let agrupacionDeComunas_origenDestino = [];

      for (let e = 0; e < reOrder_comunasWaintPoint.length; e++) {
        let total = reOrder_comunasWaintPoint.length;
        let origen;
        let destino;

        if (e === 1) {
          origen = {
            lat: reOrder_comunasWaintPoint[0].location.lat,
            lng: reOrder_comunasWaintPoint[0].location.lng,
          };

          destino = {
            lat: reOrder_comunasWaintPoint[e + 1].location.lat,
            lng: reOrder_comunasWaintPoint[e + 1].location.lng,
          };

          agrupacionDeComunas_origenDestino.push({
            origin: origen,
            destination: destino,
          });
        }
        if (e > 1 && e < total - 2) {
          origen = {
            lat: reOrder_comunasWaintPoint[e - 1].location.lat,
            lng: reOrder_comunasWaintPoint[e - 1].location.lng,
          };

          destino = {
            lat: reOrder_comunasWaintPoint[e + 1].location.lat,
            lng: reOrder_comunasWaintPoint[e + 1].location.lng,
          };

          agrupacionDeComunas_origenDestino.push({
            origin: origen,
            destination: destino,
          });
        }
        if (e === total - 2) {
          origen = {
            lat: reOrder_comunasWaintPoint[e - 1].location.lat,
            lng: reOrder_comunasWaintPoint[e - 1].location.lng,
          };

          destino = {
            lat: reOrder_comunasWaintPoint[total - 2].location.lat,
            lng: reOrder_comunasWaintPoint[total - 2].location.lng,
          };

          agrupacionDeComunas_origenDestino.push({
            origin: origen,
            destination: destino,
          });
        }
      }

      GruposDe21 = agrupacionDe21_ReserveOD;
      initialLocation = startPointalgorithm;
      console.log(GruposDe21);
      console.log(initialLocation);
      console.log(agrupacionDeComunas_origenDestino);

      async function groupNewOrder(
        GruposDe21,
        initialLocation,
        agrupacionDeComunas_origenDestino
      ) {
        let groupNewOrder = {};

        async function resolveGroups() {
          let reordergroupNewOrder = [];

          async function reorderIntervals(neOrder, index) {
            reordergroupNewOrder[index] = neOrder;
          }

          console.count("test");
          console.log(reordergroupNewOrder);

          return new Promise((resolve, reject) => {
            let promises = [];

            (async function () {
              for (let [i, od] of reserveOD.entries()) {
                await espera(1500);
                promises.push(
                  new Promise((resolve, reject) => {
                    console.log("for directionsService2");
                    console.log(reserveOD);
                    console.log(agrupacionDeComunas_origenDestino[i].origin);
                    console.log(
                      agrupacionDeComunas_origenDestino[i].destination
                    );
                    let funcOrigen = new google.maps.LatLng(
                      agrupacionDeComunas_origenDestino[i].origin
                    );
                    let funcDestiny = new google.maps.LatLng(
                      agrupacionDeComunas_origenDestino[i].destination
                    );
                    directionsService2.route(
                      {
                        origin: funcOrigen,
                        destination: funcDestiny,
                        travelMode: "BICYCLING",
                        optimizeWaypoints: true,
                        waypoints: od,
                      },
                      function (response, status) {
                        if (status === "OK") {
                          reorderIntervals(response.routes[0].waypoint_order, i)
                            .then(() => {
                              resolve();
                            })
                            .catch(() => {
                              console.log("Error en reorderIntervals");
                              reject();
                            });
                        } else {
                          console.log("error directionsService2");
                          reject();
                        }
                      }
                    );
                  })
                );
              }

              Promise.all(promises)
                .then(() => {
                  console.log(reordergroupNewOrder);
                  resolve(reordergroupNewOrder);
                })
                .catch(() => {
                  console.log("Error en alguna solicitud de ruta");
                  reject();
                });
            })();
          });
        }

        async function sortedIdsok(sorted, test) {
          let respSortedId = sorted;
          if (test.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
              const elements = sorted[i].orderIds;
              const elementGroupNewOrders = test[i];
              var sortedArr = [];
              for (var n = 0; n < elementGroupNewOrders.length; n++) {
                sortedArr.push(elements[elementGroupNewOrders[n]]);
              }
              respSortedId[i].orderIds = sortedArr;
            }
            return respSortedId;
          } else {
            console.log("error test");
          }
        }

        let test = await resolveGroups();
        let sortedIds = await sortedIdsok(sorted, test);

        return sortedIds;
      }

      let sortedIds = await groupNewOrder(
        GruposDe21,
        initialLocation,
        agrupacionDeComunas_origenDestino
      );

      console.log(sorted);
      console.log(sortedIds);

      let itemsAllIdsOrders = [];
      for (let i = 0; i < sortedIds.length; i++) {
        itemsAllIdsOrders = itemsAllIdsOrders.concat(sortedIds[i].orderIds);
      }
      console.log(itemsAllIdsOrders);

      let allManifesOrders = [];
      for (let n = 0; n < itemsAllIdsOrders.length; n++) {
        reOrder_responseHOOCKManifest.forEach((element, index) => {
          if (element.orderID === itemsAllIdsOrders[n]) {
            element.position = n;
            allManifesOrders.push(element);
          }
        });
      }

      let groupOrders21 = [];
      let container = [];
      let containerRelations = [];
      let conter = 0;
      GruposDe21 = {};

      for (let n = 0; n < allManifesOrders.length; n++) {
        if ((n + 1) % 22 === 0) {
          conter++;
          container = [];
          containerRelations = [];
        }
        const obj = {
          stopover: true,
          location: {
            lng: allManifesOrders[n].adressGeoNumAddressLatLng.lng,
            lat: allManifesOrders[n].adressGeoNumAddressLatLng.lat,
          },
          position: n,
          id: allManifesOrders[n].orderID,
        };

        containerRelations.push(obj);
        container.push(allManifesOrders[n]);

        groupOrders21[conter] = container;
        GruposDe21[conter] = containerRelations;
        // groupOrders21.push(container);
      }

      async function createOD(
        groupOrders21,
        startPointalgorithm,
        endPointalgorithm
      ) {
        let groupOrdersArray21 = Object.values(groupOrders21);
        let agrupacionDe21_origenDestino2 = [];
        // console.log(startPointalgorithm);
        // console.log(endPointalgorithm);
        for (let i = 0; i < groupOrdersArray21.length; i++) {
          const groupOrdersFragment21 = groupOrdersArray21[i];

          let total = groupOrdersArray21.length;
          let origen;
          let destino;
          if (i === 0) {
            origen = startPointalgorithm;

            destino = {
              lat: groupOrdersArray21[i + 1][0].adressGeoNumAddressLatLng.lat,
              lng: groupOrdersArray21[i + 1][0].adressGeoNumAddressLatLng.lng,
            };

            agrupacionDe21_origenDestino2.push({
              origin: origen,
              destination: destino,
            });
          }

          if (i > 0 && i < total - 1) {
            origen = {
              lat: groupOrdersArray21[i - 1][
                groupOrdersArray21[i - 1].length - 1
              ].adressGeoNumAddressLatLng.lat,
              lng: groupOrdersArray21[i - 1][
                groupOrdersArray21[i - 1].length - 1
              ].adressGeoNumAddressLatLng.lng,
            };

            destino = {
              lat: groupOrdersArray21[i + 1][0].adressGeoNumAddressLatLng.lat,
              lng: groupOrdersArray21[i + 1][0].adressGeoNumAddressLatLng.lng,
            };

            agrupacionDe21_origenDestino2.push({
              origin: origen,
              destination: destino,
            });
          }
          if (i === total - 1) {
            origen = {
              lat: groupOrdersArray21[i - 1][
                groupOrdersArray21[i - 1].length - 1
              ].adressGeoNumAddressLatLng.lat,
              lng: groupOrdersArray21[i - 1][
                groupOrdersArray21[i - 1].length - 1
              ].adressGeoNumAddressLatLng.lng,
            };

            destino = endPointalgorithm;

            agrupacionDe21_origenDestino2.push({
              origin: origen,
              destination: destino,
            });
          }
        }

        return agrupacionDe21_origenDestino2;
      }
      let agrupacionOD = await createOD(
        groupOrders21,
        startPointalgorithm,
        endPointalgorithm
      );

      GruposDe21OD = agrupacionOD;
      initialLocation = startPointalgorithm;
      getLinesAndPointsOrdersByComuna(
        GruposDe21,
        GruposDe21OD,
        initialLocation,
        startPointalgorithm,
        endPointalgorithm
      );
    }
  }

  async function calculeRouteByMultiGroups(
    response,
    startPointalgorithm,
    endPointalgorithm,
    reOrder_comunasWaintPoint,
    reOrder_ComunasGroupingNames,
    reOrder_responseHOOCKGrupos,
    reOrder_responseHOOCKManifest
  ) {
    console.log("constructor Multiple Rutas Alta densidad por Comuna");
    console.log("response :", response);
    console.log("startPointalgorithm :", startPointalgorithm);
    console.log("endPointalgorithm :", endPointalgorithm);
    console.log("reOrder_comunasWaintPoint :", reOrder_comunasWaintPoint);
    console.log("reOrder_ComunasGroupingNames :", reOrder_ComunasGroupingNames);
    console.log("reOrder_responseHOOCKGrupos :", reOrder_responseHOOCKGrupos);

    console.log(
      "reOrder_responseHOOCKManifest: ",
      reOrder_responseHOOCKManifest
    );
    await devViewNodes(reOrder_responseHOOCKGrupos);

    await resolveAlgorit();

    await testPromisesetTimeout();

    await createFragments();

    await getLinesAndPointsOrders(
      GruposDe21,
      GruposDe21OD,
      initialLocation,
      startPointalgorithm,
      endPointalgorithm
    );

    async function resolveAlgorit() {
      let temoMovePositions = {};
      let conter = 0;
      async function constructorRouteByResponse(
        response,
        intervalWaintPoint,
        reserveRelationsShip,
        item,
        intervalGroup
      ) {
        //Actualizando valores del grupo
        // console.log(nameGroup);

        if (item.position === 0) {
          console.log("reserve Origen", response.request.origin.location);
        }
        let responseRequestOriginLocation = response.request.origin.location;
        if (item.position === 0) {
          initialLocation = responseRequestOriginLocation;
        }

        let responseRequestDestinationLocation =
          response.request.destination.location;

        const reorderedArray_intervalGroup =
          response.routes[0].waypoint_order.map(
            (index) => intervalGroup[index]
          );

        item.orderIds = reorderedArray_intervalGroup;

        for (let n = 0; n < reorderedArray_intervalGroup.length; n++) {
          reserveRelationsShip[reorderedArray_intervalGroup[n]].position = n;
        }

        console.count("setElementsToCrearte");

        const esperaCarga = async (reserveRelationsShip) => {
          for (let key in reserveRelationsShip) {
            reserveRelationsShip[key]["position"] = conter++;
            newReserveAllRelationsShip[key] = reserveRelationsShip[key];
          }
          return newReserveAllRelationsShip;
        };

        let data = await esperaCarga(reserveRelationsShip);
        reserveAllRelationsShip = {
          ...reserveAllRelationsShip,
          ...data,
        };

        temoMovePositions[item.position] = item;

        finalLocation = responseRequestDestinationLocation;

        setStateReserveAllRelationsShip(reserveAllRelationsShip);
      }

      return new Promise(async (resolve, reject) => {
        let proceso3 = "proceso finalizado";

        reOrder_comunasWaintPoint.unshift({
          stopover: true,
          location: {
            lat: startPointalgorithm.lat,
            lng: startPointalgorithm.lng,
          },
        });

        var newObj = {
          stopover: true,
          location: {
            lat: endPointalgorithm.lat,
            lng: endPointalgorithm.lng,
          },
        };

        reOrder_comunasWaintPoint.push(newObj);

        const OrigenDestinyPoints = [];
        for (let i = 0; i < reOrder_comunasWaintPoint.length; i++) {
          if (reOrder_comunasWaintPoint[i + 2] !== undefined) {
            OrigenDestinyPoints.push({
              origen: reOrder_comunasWaintPoint[i],
              destiny: reOrder_comunasWaintPoint[i + 2],
            });
          }
        }

        const sorted = Object.values(reOrder_responseHOOCKGrupos).sort(
          (a, b) => a.position - b.position
        );
        console.log(sorted);
        for (let item of sorted) {
          let intervalGroup = item.orderIds;
          let intervalWaintPoint = [];
          let reserveRelationsShip = {};

          for (let element in intervalGroup) {
            reOrder_responseHOOCKManifest.forEach((elemet, index) => {
              if (elemet.orderID === intervalGroup[element]) {
                var newObj = {
                  stopover: true,
                  location: elemet.adressGeoNumAddressLatLng,
                };
                var newObjRelations = {
                  stopover: true,
                  location: elemet.adressGeoNumAddressLatLng,
                  position: parseInt(element),
                  id: elemet.orderID,
                };
                intervalWaintPoint.push(newObj);

                reserveRelationsShip[intervalGroup[element]] = newObjRelations;
              }
            });
          }

          console.count("directionsService2.route");

          directionsService2.route(
            {
              origin: OrigenDestinyPoints[item.position].origen.location,
              destination: OrigenDestinyPoints[item.position].destiny.location,
              travelMode: "BICYCLING",
              optimizeWaypoints: true,
              waypoints: intervalWaintPoint, // geopuntos de los ids contenidos
            },
            async function (response, status) {
              if (status === "OK") {
                await constructorRouteByResponse(
                  response,
                  intervalWaintPoint,
                  reserveRelationsShip,
                  item,
                  intervalGroup
                );
              } else {
                console.log(status);
              }
            }
          );

          /*
          var circle = new google.maps.Circle({
            strokeColor: "#232deb",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#232deb",
            fillOpacity: 0.12,
            map: mapRef.current,
            center: OrigenDestinyPoints[item.position].origen.location,
            radius: 500,
          });

          var circle2 = new google.maps.Circle({
            strokeColor: "#42f581",
            strokeOpacity: 0.12,
            strokeWeight: 2,
            fillColor: "#42f581",
            fillOpacity: 0.12,
            map: mapRef.current,
            center: OrigenDestinyPoints[item.position].destiny.location,
            radius: 500,
          });
          */
          await espera(2000);
        }

        resolve(reserveAllRelationsShip);
      });
    }

    async function testPromisesetTimeout() {
      const ordersStatus = async (msj) => {
        try {
          console.log(msj);
        } catch (error) {}
      };

      return new Promise(async (resolve, reject) => {
        await ordersStatus("PROCESANDO...");
        setTimeout(() => {
          let proceso2 = "proceso 3";

          resolve(proceso2);
        }, 5000);
      });
    }

    async function createFragments() {
      // Array para almacenar las ubicaciones cada 21 elementos
      let array = [];
      let subArray = [];
      let agrupacionDe21_origenDestino;
      console.log("reserveAllRelationsShip :", reserveAllRelationsShip);
      for (let key in reserveAllRelationsShip) {
        let element = reserveAllRelationsShip[key];
        subArray.push(element);
        if (
          (element.position + 1) % 21 === 0 ||
          Object.keys(reserveAllRelationsShip).length === subArray.length
        ) {
          array.push(subArray);
          subArray = [];
        }
      }
      array.push(subArray);
      console.log("reserveAllRelationsShip :", array);

      for (let e = 0; e < array.length; e++) {
        GruposDe21[e] = array[e];
        console.log(array[e]);

        let orderObj = array[e];
        let LastOrderJ = [];
        let NexOrderJ = [];

        if (array[e - 1]) {
          LastOrderJ = array[e - 1];
        }
        if (array[e + 1]) {
          NexOrderJ = array[e + 1];
        }

        console.count("INICIALIZADO");

        console.log("orderObj :", orderObj);

        if (e === 0) {
          let lat = NexOrderJ[0]["location"].lat;
          let lng = NexOrderJ[0]["location"].lng,
            agrupacionDe21_origenDestino = {
              origin: initialLocation,
              destination: {
                lat: lat,
                lng: lng,
              },
            };
          GruposDe21OD[e] = agrupacionDe21_origenDestino;
        }
        if (e !== 0 && e !== array.length - 1 && LastOrderJ.length > 0) {
          agrupacionDe21_origenDestino = {
            origin: {
              lat: LastOrderJ[LastOrderJ.length - 1]["location"].lat,
              lng: LastOrderJ[LastOrderJ.length - 1]["location"].lng,
            },
            destination: {
              lat: NexOrderJ[0]["location"].lat,
              lng: NexOrderJ[0]["location"].lng,
            },
          };
          GruposDe21OD[e] = agrupacionDe21_origenDestino;
        }

        if (e === array.length - 1) {
          let lat = LastOrderJ[LastOrderJ.length - 1].location.lat;
          let lng = LastOrderJ[LastOrderJ.length - 1].location.lng,
            agrupacionDe21_origenDestino = {
              origin: {
                lat: lat,
                lng: lng,
              },
              destination: finalLocation,
            };
          GruposDe21OD[e] = agrupacionDe21_origenDestino;
        }
      }
    }
  }

  //Utils

  async function devViewNodes(reOrder_responseHOOCKGrupos) {
    console.log("devViewNodes");
    let temViewData = Object.values(reOrder_responseHOOCKGrupos);
    let temViewDataName = Object.keys(reOrder_responseHOOCKGrupos);
    console.log(temViewData);
    let nodeMarkert = [];
    for (let i = 0; i < temViewData.length; i++) {
      console.log(temViewData[i]);
      // var circle = new google.maps.Circle({
      //   strokeColor: "#232deb",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: "#232deb",
      //   fillOpacity: 0.12,
      //   map: mapRef.current,
      //   center: temViewData[i].geoPonitRef,
      //   radius: 700,
      // });
      let geoReft = temViewData[i].geoPonitRef;
      let position = temViewData[i].position;
      let keyID = temViewDataName[i];
      nodeMarkert.push({
        adressGeoNumAddressLatLng: geoReft,
        position: position,
        keyID: keyID,
      });
    }
    setNodeMarker(nodeMarkert);
  }

  async function reviewData(manifesOrders) {
    const counter = {};
    console.log(manifesOrders);
    if (manifesOrders) {
      for (const { COMUNA2 } of manifesOrders) {
        if (counter[COMUNA2]) {
          counter[COMUNA2]++;
        } else {
          counter[COMUNA2] = 1;
        }
      }
      console.log(counter);
      const densities = Object.values(counter);
      return Math.max(...densities) > 23 ? "highDensity" : "lowDensity";
    } else {
      return null;
    }
  }

  async function procesarResponseHOOCKGrupos(responseHOOCKGrupos) {
    const arrayOrdenado = Object.entries(responseHOOCKGrupos);
    console.log("ver arrayOrdenado");
    console.log(arrayOrdenado);
    var goupsWaitPoints = [];
    var OrderComunasGroupingNames = [];
    if (responseHOOCKGrupos) {
      for (let i = 0; i < arrayOrdenado.length; i++) {
        for (let n = 0; n < arrayOrdenado.length; n++) {
          if (i === arrayOrdenado[n][1].position) {
            goupsWaitPoints.push({
              stopover: true,
              location: arrayOrdenado[n][1].geoPonitRef,
            });
            console.log(arrayOrdenado[n][0]);
            console.log(arrayOrdenado[n][1]);
            OrderComunasGroupingNames.push(arrayOrdenado[n][0]);
          }
        }
      }
    }
    console.log("result arrayOrdenado");
    console.log(goupsWaitPoints, OrderComunasGroupingNames);
    return { goupsWaitPoints, OrderComunasGroupingNames };
  }

  async function reorderLocalityByResponse(
    dataArray,
    response,
    OrderComunasGroupingNames
  ) {
    console.log("reorderLocalityByResponse");
    console.log(dataArray);
    console.log(response);
    console.log(response.routes[0].waypoint_order);
    console.log(OrderComunasGroupingNames);
    return new Promise(async (resolve, reject) => {
      const updatedDataArray = dataArray.slice(); // Crear una copia de dataArray para no modificar el original

      for (let i = 0; i < response.routes[0].waypoint_order.length; i++) {
        const originalIndex = response.routes[0].waypoint_order[i];
        updatedDataArray[originalIndex][1].position = i;
      }

      let newObjetOrder = Object.fromEntries(updatedDataArray);
      dataArray = newObjetOrder;
      let resultWPnewObjetOrder = [];

      for (const key in newObjetOrder) {
        // OrderComunasGroupingNames.push(key);
        resultWPnewObjetOrder.push({
          stopover: true,
          location: {
            lat: newObjetOrder[key].geoPonitRef.lat,
            lng: newObjetOrder[key].geoPonitRef.lng,
          },
        });
      }

      console.log(dataArray);
      console.log(response);
      console.log(OrderComunasGroupingNames);
      console.log(resultWPnewObjetOrder);
      resolve(resultWPnewObjetOrder);
    });
  }

  async function reorderData(
    temp_reOrder_responseHOOCKGrupos,
    responseHOOCKManifest,
    reOrder_ComunasGroupingNames
  ) {
    const reOrder_responseHOOCKGrupos = {};
    for (const item of temp_reOrder_responseHOOCKGrupos) {
      const key = Object.keys(item)[0];
      reOrder_responseHOOCKGrupos[key] = item[key];
    }

    const id_reOrder_responseHOOCKManifest = temp_reOrder_responseHOOCKGrupos
      .sort((a, b) => {
        const keyA = Object.keys(a)[0];
        const keyB = Object.keys(b)[0];
        return a[keyA].position - b[keyB].position;
      })
      .map((item) => {
        const key = Object.keys(item)[0];
        return item[key].orderIds;
      })
      .flat();

    const reOrder_responseHOOCKManifest = responseHOOCKManifest.sort((a, b) => {
      const aIndex = id_reOrder_responseHOOCKManifest.indexOf(a.orderID);
      const bIndex = id_reOrder_responseHOOCKManifest.indexOf(b.orderID);
      return aIndex - bIndex;
    });

    const updateOrder_ComunasGroupingNames = reOrder_ComunasGroupingNames;

    for (const item of temp_reOrder_responseHOOCKGrupos) {
      const key = Object.keys(item)[0];
      const position = item[key].position;
      const geoPonitRef = item[key].geoPonitRef;
      const index = reOrder_responseHOOCKManifest.findIndex(
        (item) => item.geoPonitRef === geoPonitRef
      );

      if (index !== -1) {
        updateOrder_ComunasGroupingNames[position] =
          reOrder_responseHOOCKManifest[index].Comuna;

        reOrder_responseHOOCKManifest.splice(index, 1);
      }
    }

    return {
      reOrder_responseHOOCKGrupos,
      reOrder_responseHOOCKManifest,
      updateOrder_ComunasGroupingNames,
    };
  }

  async function getLinesAndPointsOrders(
    GruposDe21,
    GruposDe21OD,
    initialLocation,
    startPointalgorithm,
    endPointalgorithm
  ) {
    console.log("getLinesAndPointsOrders");
    console.log(GruposDe21);
    console.log(GruposDe21OD);
    console.log(initialLocation);
    console.log(startPointalgorithm);
    console.log(endPointalgorithm);
    let num = 0;

    function ordenarPorPosition(obj) {
      const valuesArray = Object.values(obj);
      valuesArray.sort((a, b) => a.position - b.position);
      return valuesArray;
    }

    const arrayOrdenadoGrupos21 = ordenarPorPosition(GruposDe21);

    function ordenarPorPositionOD(obj) {
      const valuesArray = Object.values(obj);
      return valuesArray;
    }

    const arrayGruposDe21OD = ordenarPorPositionOD(GruposDe21OD);
    const numCalls = arrayOrdenadoGrupos21.length;
    //console.log(arrayGruposDe21OD);

    for (let i = 0; i < arrayOrdenadoGrupos21.length; i++) {
      let callOrigen;
      let callDesteny;

      const valuesArray21 = Object.values(arrayOrdenadoGrupos21[i]);
      valuesArray21.sort((a, b) => a.position - b.position);

      let valuesArray21_intervalWaintPoint = [];

      for (const item in valuesArray21) {
        var newObjWaitPoints = {
          stopover: true,
          location: valuesArray21[item].location,
        };
        valuesArray21_intervalWaintPoint.push(newObjWaitPoints);
      }

      if (i === 0) {
        callOrigen = initialLocation;
        callDesteny = arrayGruposDe21OD[i].destination;
      } else {
        callOrigen = arrayGruposDe21OD[i].origin;
        callDesteny = arrayGruposDe21OD[i].destination;
      }

      directionsService2.route(
        {
          origin: callOrigen,
          destination: callDesteny, //valuesArray21[valuesArray21.length - 1].location,
          travelMode: "BICYCLING",
          optimizeWaypoints: true,
          waypoints: valuesArray21_intervalWaintPoint, // geopuntos de los ids contenidos
        },

        function (response, status) {
          if (status === "OK") {
            console.log("constructor 1769");
            console.log(valuesArray21);
            console.log(response.routes[0].waypoint_order);
            console.log(response.routes[0].overview_polyline);
            console.log(i);
            console.log(numCalls);
            console.log(startPointalgorithm);
            console.log(endPointalgorithm);
            reOrder_21(
              valuesArray21,
              response.routes[0].waypoint_order,
              response.routes[0].overview_polyline,
              i,
              numCalls,
              startPointalgorithm,
              endPointalgorithm
            );
          } else {
            console.log(status);
          }
        }
      );
      await espera(2000);
    }
  }

  async function getLinesAndPointsOrdersByComuna(
    GruposDe21,
    GruposDe21OD,
    initialLocation,
    startPointalgorithm,
    endPointalgorithm
  ) {
    console.log("getLinesAndPointsOrdersByComuna");
    console.log(GruposDe21);
    console.log(GruposDe21OD);
    console.log(initialLocation);
    console.log(startPointalgorithm);
    console.log(endPointalgorithm);
    let num = 0;

    function ordenarPorPosition(obj) {
      const valuesArray = Object.values(obj);
      valuesArray.sort((a, b) => a.position - b.position);
      return valuesArray;
    }

    const arrayOrdenadoGrupos21 = ordenarPorPosition(GruposDe21);

    function ordenarPorPositionOD(obj) {
      const valuesArray = Object.values(obj).sort(
        (a, b) => a.position - b.position
      );
      return valuesArray;
    }

    const arrayGruposDe21OD = ordenarPorPositionOD(GruposDe21OD);
    const numCalls = arrayOrdenadoGrupos21.length;

    console.log(arrayOrdenadoGrupos21);
    console.log(arrayGruposDe21OD);
    console.log(numCalls);

    let canonicalIntervalOrder = {};

    if (arrayOrdenadoGrupos21 && arrayGruposDe21OD) {
      console.log("canonicalOrder :", arrayGruposDe21OD);
      console.log("cononicalPositions :", arrayOrdenadoGrupos21);

      async function reOrderIntervals(canonicalOrder, canonicalPositions) {
        console.log(canonicalOrder, canonicalPositions);

        let res_canonicalIntervalOrder = {};

        for (let i = 0; i < arrayOrdenadoGrupos21.length; i++) {
          let grupos21 = arrayOrdenadoGrupos21[i];
          let valuesArray21_intervalWaintPoint = [];

          for (let i = 0; i < grupos21.length; i++) {
            console.log(grupos21[i].id);

            let obj = {
              stopover: true,
              location: grupos21[i].location,
            };
            valuesArray21_intervalWaintPoint.push(obj);
          }

          await new Promise((resolve, reject) => {
            directionsService2.route(
              {
                origin: arrayGruposDe21OD[i].origin,
                destination: arrayGruposDe21OD[i].destination, //valuesArray21[valuesArray21.length - 1].location,
                travelMode: "BICYCLING",
                optimizeWaypoints: true,
                waypoints: valuesArray21_intervalWaintPoint, // geopuntos de los ids contenidos
              },

              async function (response, status) {
                if (status === "OK") {
                  console.log("constructor 1854");

                  // console.log(response.routes[0].overview_polyline);
                  // console.log(i);
                  // console.log(startPointalgorithm);
                  // console.log(endPointalgorithm);
                  let newOrder = response.routes[0].waypoint_order;
                  createFinalLine(response.routes[0].overview_polyline, i);
                  for (let n = 0; n < newOrder.length; n++) {
                    console.log(
                      "ID =>",
                      grupos21[n].id,
                      "Valor en Objeto =>",
                      grupos21[n].position,
                      " =>",
                      "Valor Anterior =>",
                      newOrder[n],
                      "Nueva Posicion =>",
                      n
                    );
                  }

                  // Crear una copia superficial de grupos21
                  async function updateGroups(grupos21, newOrder) {
                    const sortedData = grupos21.sort((a, b) => {
                      return (
                        newOrder.indexOf(a.position) -
                        newOrder.indexOf(b.position)
                      );
                    });
                    let copy = {};
                    for (let i = 0; i < sortedData.length; i++) {
                      //console.log(newOrder[i]);
                      let Obj = {
                        stopover: true,
                        location: sortedData[i].location,
                        position: i,
                        id: sortedData[i].id,
                      };
                      copy[i] = Obj;
                    }

                    const arr = Object.entries(copy);

                    console.log(arr);

                    return arr;
                  }
                  let copyUpdate = await updateGroups(grupos21, newOrder);

                  res_canonicalIntervalOrder[i] = Object.values(copyUpdate);
                  resolve();
                } else {
                  console.log(status);
                  reject();
                }
              }
            );
          });
        }

        return res_canonicalIntervalOrder;
      }

      canonicalIntervalOrder = await reOrderIntervals(
        arrayOrdenadoGrupos21,
        arrayGruposDe21OD
      );

      if (!canonicalIntervalOrder) {
        console.log("canonicalIntervalOrder NO EXITS");
        return; // Salir de la función si no existe canonicalIntervalOrder
      }

      const arrayCanonicalIntervalOrder = Object.values(canonicalIntervalOrder);

      async function findNewOrdersId(arrayCanonicalIntervalOrder) {
        let newOrdersId = [];

        for (let i = 0; i < arrayCanonicalIntervalOrder.length; i++) {
          let elements = arrayCanonicalIntervalOrder[i]; // Se corrige la variable "elemetns" a "elements" y se accede al índice "i" en lugar de "elements[i]"

          for (let n = 0; n < elements.length; n++) {
            newOrdersId.push(elements[n][1].id);
          }
        }

        return newOrdersId;
      }

      (async function () {
        let newOrdersId = await findNewOrdersId(arrayCanonicalIntervalOrder);
        if (newOrdersId && newOrdersId.length > 0) {
          let allManifesOrders = [];

          for (let n = 0; n < newOrdersId.length; n++) {
            manifesOrders.forEach((element, index) => {
              if (element.orderID === newOrdersId[n]) {
                element.position = n;
                allManifesOrders.push(element);
              }
            });
          }
          console.log(allManifesOrders);
          setTestMarkersPending(allManifesOrders);
        } else {
          console.log("newOrdersId NO EXISTE"); // Se corrige el mensaje de salida
        }
      })();
    } else {
      console.log("canonicalOrder NO EXITS");
    }
  }

  async function reOrder_21(
    arrays21,
    newOrder,
    line,
    index,
    numCalls,
    startPointalgorithm,
    endPointalgorithm
  ) {
    for (let n = 0; n < arrays21.length; n++) {
      arrays21[newOrder[n]].position = n;
      //console.log(arrays21[newOrder[n]]);
    }

    canonicalOrder[index] = arrays21;

    cononicalPositions[index] = newOrder;

    if (numCalls === index + 1) {
      console.log("LEGO AL FINAL");
      // createFinalLine(response.routes[0].overview_polyline, i);
      console.log(canonicalOrder);

      await createCanonicalPolyLines(
        canonicalOrder,
        startPointalgorithm,
        endPointalgorithm
      );
      await applyCanonicalOrder(canonicalOrder);
    }
  }

  async function applyCanonicalOrder(canonicalOrder) {
    console.log(canonicalOrder);
    const valuesArraycanonicalOrder = Object.values(canonicalOrder);

    // Use `flatMap` to flatten the array of orders, and `map` to extract the IDs
    const newOrdersId = valuesArraycanonicalOrder
      .flatMap((orderGroup) =>
        orderGroup.sort((a, b) => a.position - b.position)
      )
      .map((order) => order.id);

    console.log(newOrdersId);

    // Use `props.updateDataRouteOrderPiontByApi` with the `newOrdersId` array directly
    await props.updateDataRouteOrderPiontByApi(newOrdersId);

    // Use `map` to create a new array of orders sorted by their position in `newOrdersId`
    const sortedArray = newOrdersId.map((id) =>
      manifesOrders.find((order) => order.orderID === id)
    );

    console.log(sortedArray);
    setTestMarkersPending(sortedArray);
  }

  async function createCanonicalPolyLines(
    canonicalOrder,
    startPointalgorithm,
    endPointalgorithm
  ) {
    // console.log(cononicalPositions);
    console.log("canonicalOrder");
    console.log(canonicalOrder);
    var count = 2;
    for (let key in cononicalPositions) {
      console.log("Orden final");
      console.log(canonicalOrder[key]);

      let wayPoints = [];
      let orderWaipoints = [];

      for (let i = 0; i < canonicalOrder[key].length; i++) {
        let obj = {
          stopover: true,
          location: canonicalOrder[key][i].location,
        };
        wayPoints.push(obj);
      }

      //Crearte Origen => Destino
      let startPoint;
      let endPoint;
      if (parseInt(key) === 0) {
        startPoint = startPointalgorithm;
      } else {
        startPoint =
          canonicalOrder[parseInt(key) - 1][
            [canonicalOrder[parseInt(key) - 1].length - 1]
          ].location;
      }

      console.log(Object.keys(cononicalPositions).length);

      if (
        parseInt(key) ===
        parseInt(Object.keys(cononicalPositions).length) - 1
      ) {
        console.log("Validado ultimo elemento");
        endPoint = endPointalgorithm;
      } else {
        endPoint = canonicalOrder[key][canonicalOrder[key].length - 1].location;
      }

      console.log(wayPoints.length);
      await callPolyLines(wayPoints, count++, startPoint, endPoint);
      await espera(2000);
    }
  }

  async function callPolyLines(wayPoints, count, startPoint, endPoint) {
    console.log(startPoint);
    console.log(endPoint);

    var directionsService = new google.maps.DirectionsService();

    await directionsService.route(
      {
        origin: startPoint,
        destination: endPoint,
        travelMode: "BICYCLING",
        optimizeWaypoints: true,
        waypoints: wayPoints,
      },
      async function async(response, status) {
        if (status === "OK") {
          console.log("OK api directionsrvice");
          console.log(response);
          createFinalLine(response.routes[0].overview_polyline, count);
          count++;
          console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
          console.log("Evaluar si es necesario");
          console.log("destination");
          console.log(origin);
          console.log("Evaluar si es necesario ");
        } else {
          console.log(status);
          console.log("error api directionsrvice");
        }
      }
    );
  }

  var polyLine;
  var circle;
  async function createFinalLine(codeLine, i) {
    var points = decode(codeLine);

    console.log(i);
    console.log(points);
    let latLngPositions = points.map(([lat, lng]) => ({
      lat,
      lng,
    }));
    //console.log(latLngPositions);
    // create the polyline
    polyLine = new google.maps.Polyline({
      path: latLngPositions,
      strokeColor: colorIndex[i + 2],
      strokeWeight: 2,
      strokeOpacity: 1,
      fillColor: "#810FCB",
      map: mapRef.current,
    });
    alert("Print OK");
  }

  return (
    <div>
      <div>
        <div>
          {serviceErr ? (
            <>
              <div>{serviceErr}</div>
            </>
          ) : (
            <>
              {/* {loadService ? (
                <></>
              ) : (
                <>
                  {" "}
                  <LinearProgress
                    value={progressValue}
                    valueBuffer={100}
                    variant="buffer"
                  ></LinearProgress>
                  <LinearProgress></LinearProgress>
                </>
              )} */}
            </>
          )}
          {polygonsPath ? (
            <div>
              <GoogleMap
                id="mapControl"
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={centerMaps}
                options={options}
                onLoad={onMapLoadRute}
              >
                {polygonsPath &&
                polygonsPath.length > 0 &&
                visiblePoligon === true ? (
                  <>
                    {polygonsPath.map((Poligon) => (
                      <div key={Poligon.id}>
                        <Polygon
                          path={Poligon.coordinates}
                          editable={false}
                          draggable={false}
                          // onMouseUp={onEdit}
                          // onDragEnd={onEdit}
                          // onLoad={onLoad}
                          // onUnmount={onUnmount}

                          options={{
                            fillColor: Poligon.fill,
                            fillOpacity: 0.5, //item.fillOpacity,
                            strokeColor: Poligon.strokeColor,
                            strokeOpacity: 0.8, //item.strokeOpacity,
                            strokeWeight: 1,
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : null}

                {origin ? (
                  <>
                    <Marker
                      key={"origenId"}
                      position={origin}
                      // position={props.origin}
                      //   onClick={() => {setSelectedMarker ({
                      //     "id": "Punto BICCI",
                      //     "toAddress": "Destino",
                      //     "toAddressLocation": props.destination,
                      //     "userName": "",
                      //     "userPhone": "+56987654321",
                      //     "status": '',
                      //     "express": "",
                      // });}}
                      icon={{
                        url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(10, 10),
                        scaledSize: new window.google.maps.Size(35, 35),
                      }}
                    />
                  </>
                ) : null}

                {destination ? (
                  <>
                    <Marker
                      key={"destinationId"}
                      position={destination}
                      // position={props.origin}
                      //   onClick={() => {setSelectedMarker ({
                      //     "id": "Punto BICCI",
                      //     "toAddress": "Destino",
                      //     "toAddressLocation": props.destination,
                      //     "userName": "",
                      //     "userPhone": "+56987654321",
                      //     "status": '',
                      //     "express": "",
                      // });}}
                      icon={{
                        url: `https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2FPines-01.svg?alt=media&token=80c371be-15ab-4fc6-92d1-e526dc1e4e69`,
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(10, 10),
                        scaledSize: new window.google.maps.Size(35, 35),
                      }}
                    />
                  </>
                ) : null}

                {markersPending ? (
                  <>
                    {markersPending.map((marker, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: marker.location.lat,
                          lng: marker.location.lng,
                        }}
                        //label={`${marker.position}`}
                        //onClick={() => {setSelectedMarker ({...marker, positionList :index})}}
                        onClick={() => {
                          console.log(marker.id);
                          console.log(marker.position);
                          console.log(marker);
                        }}
                        //animation= {window.google.maps.Animation.DROP}
                        onLoad={(marker) => {
                          const customIcon = (opts) =>
                            Object.assign(
                              {
                                //path: 'M 40.84679,0.77854062 C 37.001512,1.4840956 34.038179,2.2954846 30.616235,3.6360406 16.39929,9.1040956 5.8159574,21.169097 2.2176244,36.021041 c -3.316112,13.617222 -1.164167,26.387778 6.314722,37.606111 3.1044436,4.621389 7.2319436,8.784167 13.0880546,13.088063 5.820834,4.30389 12.065,10.44222 15.275278,14.993045 4.656671,6.56167 8.149171,15.06362 10.195281,24.69445 l 0.9525,4.58611 0.3175,-1.94028 c 1.37583,-9.27805 4.09222,-16.96861 8.74889,-24.58861 3.35139,-5.503325 9.03111,-11.818055 13.40555,-14.957775 8.07861,-5.75028 10.61861,-7.97278 14.2875,-12.488336 2.25778,-2.786944 3.56306,-4.868333 5.3975,-8.537222 3.38667,-6.773334 4.7625,-12.770556 4.7625,-20.919722 0,-6.067778 -0.52916,-9.454445 -2.29305,-14.9225 C 87.62513,17.182708 74.57235,5.2588186 58.48568,1.4488186 53.86429,0.35520762 45.04485,0.03770762 40.84679,0.77854062 Z M 56.33374,16.159652 c 11.25361,3.351389 19.47333,11.606389 22.61305,22.648334 1.09361,3.739444 1.30528,10.124722 0.52917,14.216944 -1.19945,6.455833 -3.98639,11.782778 -8.60778,16.439445 -3.63361,3.633611 -6.56167,5.679722 -10.54806,7.3025 -4.72722,1.905 -8.96055,2.645833 -14.00527,2.398888 -5.00945,-0.211666 -7.33778,-0.776111 -11.782782,-2.892777 -9.066389,-4.268611 -15.134167,-11.465278 -17.78,-21.096111 -0.635,-2.363612 -0.776111,-3.774723 -0.776111,-8.149167 0.03528,-6.0325 0.635,-8.784167 2.963333,-13.758333 4.1275,-8.643056 12.558889,-15.416389 21.80167,-17.4625 4.26861,-0.9525 11.78278,-0.776112 15.59278,0.352777 z',
                                path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                                //type:'Icon',
                                //icon: pinImage,
                                color: colorIndex[1],
                                fillColor: colorIndex[1],
                                fillOpacity: 1,
                                strokeColor: colorIndex[1],
                                strokeWeight: 0.3,
                                scale: 1.5,

                                //anchor: new window.google.maps.Point(5, 10),
                                //     //translate:180
                                // animation: window.google.maps.Animation.DROP,
                              },
                              opts
                            );
                          // path: 'm 322.43335,187.59519 c -0.07,72.31 -59.84,131.05 -133.32,131 -73.48,-0.05 -133.929995,-59.46 -133.519995,-131.54 0.42,-72.6 60.419995,-131.160003 134.069995,-130.940003 73.44,0.28 132.85,59.060003 132.77,131.480003 z',
                          marker.setIcon(
                            customIcon({
                              fillColor: colorIndex[1],
                              strokeColor: "white",
                              color: colorIndex[1],
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(15, 15),
                              scaledSize: new window.google.maps.Size(50, 50),
                            })
                          );
                        }}
                      />
                    ))}
                  </>
                ) : null}

                {testMarkersPending ? (
                  <>
                    {testMarkersPending.map((marker, index) => (
                      <Marker
                        key={index}
                        // label={{
                        //   text: marker.position
                        //     ? ` ${marker.position} => ${marker.orderID}`
                        //     : `Index: ${index} => ${marker.orderID}`,
                        //   className: "marker-label-ebiex",
                        // }}
                        position={{
                          lat: marker.adressGeoNumAddressLatLng.lat,
                          lng: marker.adressGeoNumAddressLatLng.lng,
                        }}
                        //onClick={() => {setSelectedMarker ({...marker, positionList :index})}}
                        onClick={() => {
                          console.log(marker);
                          console.log(marker.position);
                          console.log(index);
                        }}
                        //animation= {window.google.maps.Animation.DROP}
                        onLoad={(marker) => {
                          const customIcon = (opts) =>
                            Object.assign(
                              {
                                //path: 'M 40.84679,0.77854062 C 37.001512,1.4840956 34.038179,2.2954846 30.616235,3.6360406 16.39929,9.1040956 5.8159574,21.169097 2.2176244,36.021041 c -3.316112,13.617222 -1.164167,26.387778 6.314722,37.606111 3.1044436,4.621389 7.2319436,8.784167 13.0880546,13.088063 5.820834,4.30389 12.065,10.44222 15.275278,14.993045 4.656671,6.56167 8.149171,15.06362 10.195281,24.69445 l 0.9525,4.58611 0.3175,-1.94028 c 1.37583,-9.27805 4.09222,-16.96861 8.74889,-24.58861 3.35139,-5.503325 9.03111,-11.818055 13.40555,-14.957775 8.07861,-5.75028 10.61861,-7.97278 14.2875,-12.488336 2.25778,-2.786944 3.56306,-4.868333 5.3975,-8.537222 3.38667,-6.773334 4.7625,-12.770556 4.7625,-20.919722 0,-6.067778 -0.52916,-9.454445 -2.29305,-14.9225 C 87.62513,17.182708 74.57235,5.2588186 58.48568,1.4488186 53.86429,0.35520762 45.04485,0.03770762 40.84679,0.77854062 Z M 56.33374,16.159652 c 11.25361,3.351389 19.47333,11.606389 22.61305,22.648334 1.09361,3.739444 1.30528,10.124722 0.52917,14.216944 -1.19945,6.455833 -3.98639,11.782778 -8.60778,16.439445 -3.63361,3.633611 -6.56167,5.679722 -10.54806,7.3025 -4.72722,1.905 -8.96055,2.645833 -14.00527,2.398888 -5.00945,-0.211666 -7.33778,-0.776111 -11.782782,-2.892777 -9.066389,-4.268611 -15.134167,-11.465278 -17.78,-21.096111 -0.635,-2.363612 -0.776111,-3.774723 -0.776111,-8.149167 0.03528,-6.0325 0.635,-8.784167 2.963333,-13.758333 4.1275,-8.643056 12.558889,-15.416389 21.80167,-17.4625 4.26861,-0.9525 11.78278,-0.776112 15.59278,0.352777 z',
                                path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                                //type:'Icon',
                                //icon: pinImage,
                                color: "red",
                                fillColor: "red",
                                fillOpacity: 0.5,
                                strokeColor: "red",
                                strokeWeight: 0.3,
                                scale: 1,

                                //anchor: new window.google.maps.Point(5, 10),
                                //     //translate:180
                                // animation: window.google.maps.Animation.DROP,
                              },
                              opts
                            );
                          // path: 'm 322.43335,187.59519 c -0.07,72.31 -59.84,131.05 -133.32,131 -73.48,-0.05 -133.929995,-59.46 -133.519995,-131.54 0.42,-72.6 60.419995,-131.160003 134.069995,-130.940003 73.44,0.28 132.85,59.060003 132.77,131.480003 z',
                          marker.setIcon(
                            customIcon({
                              fillColor: "red",
                              strokeColor: "red",
                              color: "red",
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(15, 15),
                              scaledSize: new window.google.maps.Size(50, 50),
                            })
                          );
                        }}
                      />
                    ))}
                  </>
                ) : null}

                {nodeMarker ? (
                  <>
                    {nodeMarker.map((marker, index) => (
                      <Marker
                        key={index}
                        label={{
                          text: `Index: ${index} <=\nvar position : ${marker.position}\n=> keyID : ${marker.keyID}`,
                          className: "marker-label-ebiex",
                        }}
                        position={{
                          lat: marker.adressGeoNumAddressLatLng.lat,
                          lng: marker.adressGeoNumAddressLatLng.lng,
                        }}
                        //onClick={() => {setSelectedMarker ({...marker, positionList :index})}}
                        onClick={() => {
                          console.log(marker);
                          console.log(index);
                        }}
                        //animation= {window.google.maps.Animation.DROP}
                        onLoad={(marker) => {
                          const customIcon = (opts) =>
                            Object.assign(
                              {
                                //path: 'M 40.84679,0.77854062 C 37.001512,1.4840956 34.038179,2.2954846 30.616235,3.6360406 16.39929,9.1040956 5.8159574,21.169097 2.2176244,36.021041 c -3.316112,13.617222 -1.164167,26.387778 6.314722,37.606111 3.1044436,4.621389 7.2319436,8.784167 13.0880546,13.088063 5.820834,4.30389 12.065,10.44222 15.275278,14.993045 4.656671,6.56167 8.149171,15.06362 10.195281,24.69445 l 0.9525,4.58611 0.3175,-1.94028 c 1.37583,-9.27805 4.09222,-16.96861 8.74889,-24.58861 3.35139,-5.503325 9.03111,-11.818055 13.40555,-14.957775 8.07861,-5.75028 10.61861,-7.97278 14.2875,-12.488336 2.25778,-2.786944 3.56306,-4.868333 5.3975,-8.537222 3.38667,-6.773334 4.7625,-12.770556 4.7625,-20.919722 0,-6.067778 -0.52916,-9.454445 -2.29305,-14.9225 C 87.62513,17.182708 74.57235,5.2588186 58.48568,1.4488186 53.86429,0.35520762 45.04485,0.03770762 40.84679,0.77854062 Z M 56.33374,16.159652 c 11.25361,3.351389 19.47333,11.606389 22.61305,22.648334 1.09361,3.739444 1.30528,10.124722 0.52917,14.216944 -1.19945,6.455833 -3.98639,11.782778 -8.60778,16.439445 -3.63361,3.633611 -6.56167,5.679722 -10.54806,7.3025 -4.72722,1.905 -8.96055,2.645833 -14.00527,2.398888 -5.00945,-0.211666 -7.33778,-0.776111 -11.782782,-2.892777 -9.066389,-4.268611 -15.134167,-11.465278 -17.78,-21.096111 -0.635,-2.363612 -0.776111,-3.774723 -0.776111,-8.149167 0.03528,-6.0325 0.635,-8.784167 2.963333,-13.758333 4.1275,-8.643056 12.558889,-15.416389 21.80167,-17.4625 4.26861,-0.9525 11.78278,-0.776112 15.59278,0.352777 z',
                                path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                                //type:'Icon',
                                //icon: pinImage,
                                color: "red",
                                fillColor: "red",
                                fillOpacity: 1,
                                strokeColor: "red",
                                strokeWeight: 0.3,
                                scale: 1.8,

                                //anchor: new window.google.maps.Point(5, 10),
                                //     //translate:180
                                // animation: window.google.maps.Animation.DROP,
                              },
                              opts
                            );
                          // path: 'm 322.43335,187.59519 c -0.07,72.31 -59.84,131.05 -133.32,131 -73.48,-0.05 -133.929995,-59.46 -133.519995,-131.54 0.42,-72.6 60.419995,-131.160003 134.069995,-130.940003 73.44,0.28 132.85,59.060003 132.77,131.480003 z',
                          marker.setIcon(
                            customIcon({
                              fillColor: "red",
                              strokeColor: "white",
                              color: "red",
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(15, 15),
                              scaledSize: new window.google.maps.Size(50, 50),
                            })
                          );
                        }}
                      />
                    ))}
                  </>
                ) : null}

                {selectedMarker ? (
                  <>
                    {
                      <InfoWindow
                        position={{
                          lat: selectedMarker.location.lat,
                          lng: selectedMarker.location.lng,
                        }}
                        onCloseClick={() => {
                          setSelectedMarker(null);
                        }}
                      >
                        <div>
                          <span
                            onClick={() => setSelectedMarker(null)}
                            className="posditonCloseInfo"
                          >
                            x
                          </span>
                          <h3 className="positionmarker">
                            {selectedMarker.positionList + 1}
                          </h3>
                          <h5>
                            ID:{" "}
                            {manifesOrders[selectedMarker.positionList].orderID}
                          </h5>
                          <div>
                            <a
                              className="nonstyle"
                              href={`tel:${
                                manifesOrders[selectedMarker.positionList]
                                  .CLIENTETELEFONO
                              }`}
                            >
                              Teléfono:{" "}
                              {
                                manifesOrders[selectedMarker.positionList]
                                  .CLIENTETELEFONO
                              }
                            </a>
                          </div>
                          <div>
                            <a
                              className="nonstyle"
                              href={`https://api.whatsapp.com/send?phone=${
                                manifesOrders[selectedMarker.positionList]
                                  .userPhone
                              }&text=Soporte%20BICCI%20:`}
                            >
                              WhatsApp:{" "}
                              {
                                manifesOrders[selectedMarker.positionList]
                                  .CLIENTETELEFONO
                              }
                            </a>
                          </div>
                          <div>
                            Nombre :{" "}
                            {manifesOrders[selectedMarker.positionList]
                              .CLIENTENOMBRES + " "}
                          </div>
                          <div>
                            Destino :{" "}
                            {manifesOrders[selectedMarker.positionList]
                              .DIRECCION1 +
                              " " +
                              manifesOrders[selectedMarker.positionList]
                                .DIRECCION2}
                          </div>
                        </div>
                      </InfoWindow>
                    }
                  </>
                ) : null}
              </GoogleMap>
            </div>
          ) : (
            <LinearProgress></LinearProgress>
          )}
        </div>
      </div>
    </div>
  );
}
