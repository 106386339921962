import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

import usuarioReducer, { leerUsuarioActivoAccion } from "./usuarioDucks";
import usuarioComentarios from "./accionesCRM";
import usuarioClienteEbiex from "./acccionesClientes";
import orderClienteEbiex from "./accionesUsuarioDispach";
import usuarioClienteCompanyEbiex from "./accionesCompanies";
import usuarioClienteDriverEbiex from "./accionesDrivers";
import adminServicesEbiex from "./adminServicesEbiex";
import cartAccions from "./acccionesCart";
import updatePolygonLocalData from "./getUpdatePolygons";
import routesLiveAccionsReducer from "./routesLiveAccions";
import usuarioRegistrosDeCambios from "./accionsRegister";
import routesClientTraking from "./routesClientTraking";
import createOrdersBicciexpress from "./accionesOrders";

const rootReducer = combineReducers({
  usuario: usuarioReducer,
  mensajes: usuarioComentarios,
  clienteEbiex: usuarioClienteEbiex,
  dispachOrder: orderClienteEbiex,
  clienteCompany: usuarioClienteCompanyEbiex,
  usuarioDriver: usuarioClienteDriverEbiex,
  adminServicesEbiex: adminServicesEbiex,
  cartAccion: cartAccions,
  updatePolygons: updatePolygonLocalData,
  accionsLiveRoutes: routesLiveAccionsReducer,
  accionsRegisterLogs: usuarioRegistrosDeCambios,
  accionExternal: routesClientTraking,
  acctionsCreateOrdersBicciexpress: createOrdersBicciexpress,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  leerUsuarioActivoAccion()(store.dispatch);
  return store;
}
