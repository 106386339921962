import React from "react";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Moment from "moment";
import useObtenerDatosdeCliente from "../../../../hooks/obtenerDatosdeCliente";
import useGetRoutesExpressById from "../../../../hooks/getRoutesExpressById";
import useGetDataPlaningGroupById from "../../../../hooks/useGetDataPlaningGroupById";
import useGetDataRiderByCompany from "../../../../hooks/getDataRiderByCompany";
import useGetDataVehiclesByCompany from "../../../../hooks/getOrdersDataContextByCompany";
import Button from "react-bootstrap/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../../components/animations/FadeInOut";
import { db } from "../../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";

import { Divider, CardHeader, Avatar, LinearProgress } from "@mui/material";
import { Tab } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Tooltip, IconButton } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { useEffect, useReducer, useRef } from "react";
import {
  Container,
  Form,
  Input,
  Card,
  CardContent,
  Grid,
} from "semantic-ui-react";
import PlanerDocPDF from "./PlanerDocPDF";
import DashPlannerEditMapRoute from "./DashPlannerEditMapRoute";
import PlanerDocExcell from "./PlanerDocExcell";
import { useParams } from "react-router-dom";
var dispachtList = "lista de depachos";
var mapaDeRuta = " Mapa de ruta";
var documentPDF = "";
var listOrders = "Editar Ordenes";

const DashPlannerEdit = (props) => {
  const { id: documentId } = useParams();
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const dragRow = useRef();
  const dragOverRow = useRef();
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "userRider":
        return { ...state, userRider: action.payLoad };
      case "userVehicle":
        return { ...state, userVehicle: action.payLoad };
      default:
        return state;
    }
  }, {});

  const [documentID, setdocumentID] = React.useState(documentId);

  const usuarioLocal = usuarioStorage.uid;
  const LocalRol = usuarioStorage.roll;
  const [userRider, setUserRiderr] = React.useState("");
  const [userVehicle, setUserVehicle] = React.useState("");
  const [currentOrderList, setCurrentOrderList] = React.useState([]);
  const [routeDate, setRouteDate] = React.useState("");
  const [routeStartTimeDelivery, setRouteStartTimeDelivery] =
    React.useState("08:30");
  const [routeStayTimeDelivery, setRouteStayTimeDelivery] =
    React.useState("00:10");
  const [routeEndTimeDelivery, setRouteEndTimeDelivery] =
    React.useState("20:30");
  const [optimizeWaypoints, setOptimizeWaypoints] = React.useState(false);

  const [optimizeWaypointsComune, setOptimizeWaypointsComune] =
    React.useState(false);
  const [optimizeWaypointsNodos, setOptimizeWaypointsNodos] =
    React.useState(true);
  const [optimizeWaypointsPolygons, setOptimizeWaypointsPolygons] =
    React.useState(false);

  const [companyId, setcompanyId] = React.useState(null);

  const [origin, setOrigin] = React.useState(null);

  const [showEdit, setShowEdit] = React.useState(true);
  const [showEditDataPlaner, setShowEditDataPlaner] = React.useState(true);
  const [showEditDriver, setShowEditDriver] = React.useState(true);
  const [showEditVehicle, setShowEditVehicle] = React.useState(true);

  const [orderIdExist, setOrderIdExist] = React.useState(false);

  const [directions, setDirectionsDPlanner] = React.useState(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const [ridersRows, setRidersRows] = React.useState("");

  const [vehiclesRows, setVehiclesRows] = React.useState("");

  const [render, setRender] = React.useState(false);

  const [errorData, setError] = React.useState(null);

  const [succesCreate, setSuccesCreate] = React.useState(null);

  const [manifesOrders, setManifesOrders] = React.useState(null);

  const [waitPointManifest, setWayPointsManifets] = React.useState(null);

  const [haveAllDataRequire, setHaveAllDataRequire] = React.useState(false);

  const [errorConfiCompany, setErrorConfiCompany] = React.useState(null);

  const { userdata } = useObtenerDatosdeCliente(usuarioLocal);

  const { ridersDataByCompany } = useGetDataRiderByCompany(
    companyId,
    userRider
  );

  const { VehiclesDataByCompany, listVehiclesDataByCompany } =
    useGetDataVehiclesByCompany(companyId, userVehicle);

  const { bicciRoutesExpressByID } = useGetRoutesExpressById(
    companyId,
    LocalRol,
    documentId
  );

  const { bicciOrdesDataManifesPlaning, errorPlanningDataGroup } =
    useGetDataPlaningGroupById(currentOrderList, "planing");

  const columnsRiders = [
    {
      field: "selector",
      headerName: "",
      width: 20,
      renderCell: (ridersRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={ridersRows.id}
                value={ridersRows.row.name}
                data={ridersRows.row.avatar}
                onClick={handleGoPropsRidersRows2}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                {/* <ArrowForwardIosIcon   onClick={handleGoPropsRidersRows3} ></ArrowForwardIosIcon> */}

                <Icon name="angle right" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Seleccione un conductor",
      width: 250,
      minHeight: 70,
      height: 70,
      renderCell: (ridersRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            <CardHeader
              avatar={
                <Avatar
                  src={ridersRows.row.avatar}
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsRidersRows3}
                  aria-label="recipe"
                ></Avatar>
              }
              title={ridersRows.row.name}
              subheader={ridersRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const columnsVehicle = [
    {
      field: "actions",
      headerName: "Seleccione un vehículo",
      width: 250,
      renderCell: (vehicleRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={vehicleRows.row.id}
                value={vehicleRows.row.name}
                onClick={handleGoPropsVehicleRows}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                <Icon name="angle right" />
              </IconButton>
            </Tooltip>

            <CardHeader
              avatar={
                <Avatar
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecobicci-1.png?alt=media&token=25fc24ac-26e1-43a0-8bc0-889c4afec5cf"
                  }
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsVehicleRows}
                  aria-label="recipe"
                ></Avatar>
              }
              title={vehicleRows.row.id}
              subheader={vehicleRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const [newRoute, setNewRoute] = React.useState({
    createBy: usuarioLocal,
    createDate: Moment(Date.now()).format("DD-MM-YYYY"),
    date: Date.now(),
    id: documentId,
    vehicleID: "",
    driverID: "",
    driverName: "",
    companyId: "",
    travelMode: "",
    currentOrderList: {},
    origin: { lat: -33.4592491, lng: -70.6010891 },
    destination: { lat: -33.4592491, lng: -70.6010891 },
    optimizeWaypoints: true, // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    optimizeWaypointsNodos: true,
    wayPoints: [],
    waypoint_order: [],
    maxTimeDelivery: routeStayTimeDelivery,
    dateDeliveryText: "",
    dateDelivery: "",
    startTimeDelivery: routeStartTimeDelivery,
    endTimeDelivery: routeEndTimeDelivery,
    distanceValue: 0,
    durationValue: 0,
    dispacthDelyvery: 0,
    dispacthPartials: 0,
    dispacthNotDelyvery: 0,
    fullCompliance: 0,
    warnings: "",
    routeStates: 0,
    routePoints: 0,
    totalPack: 0,
  });

  const panes = [
    {
      menuItem: "DESPACHOS",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {listOrders}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "RUTA DE ENTREGAS",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {mapaDeRuta}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "GUIA DE DESPACHOS",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {dispachtList}
        </Tab.Pane>
      ),
    },

    {
      menuItem: "DOCUMENTO PDF",
      render: () => (
        <Tab.Pane className="item" attached={false}>
          {documentPDF}
        </Tab.Pane>
      ),
    },
  ];

  useEffect(() => {
    if (userdata) {
      // console.count(
      //   "carga Basica A: Otener Objeto ruta bicciRoutesExpressByID "
      // );
      setcompanyId(userdata.userCompanyID);
    }
    setdocumentID(documentId);
  }, [userdata && userdata.userCompanyID && documentId]);

  useEffect(() => {
    if (VehiclesDataByCompany && VehiclesDataByCompany) {
      // console.count("carga Actualizacion A");
      getBicciDriversData();
      getBicciUserData();
      findAdressesCompany();
      getBicciVehiclesData();
    }
  }, [
    VehiclesDataByCompany && ridersDataByCompany && listVehiclesDataByCompany,
  ]); // targetedOrdersRows

  useEffect(() => {
    if (bicciRoutesExpressByID) {
      // console.count("carga Basica B:  setObjRoute(bicciRoutesExpressByID);");
      console.log(bicciRoutesExpressByID);
      setObjRoute(bicciRoutesExpressByID);
    }
  }, [bicciRoutesExpressByID]);

  useEffect(() => {
    if (
      bicciOrdesDataManifesPlaning &&
      bicciOrdesDataManifesPlaning.length === currentOrderList.length
    ) {
      updateDataRouteOrderPiont(currentOrderList);
      getAwaitEstimateTime();
    } else {
      // console.log("No coicide las datas ");
      // console.log(bicciOrdesDataManifesPlaning);
      // console.log(currentOrderList);
    }
  }, [currentOrderList && bicciOrdesDataManifesPlaning]);

  function espera() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }
  //OPCIONES DISPONIBLE A SELECCION
  const getBicciDriversData = () => {
    // console.log("Secundaria  A: Riders de empresa");
    if (ridersDataByCompany) {
      var ridersRows = [];
      for (let i = 0; i < ridersDataByCompany.length; i++) {
        ridersRows.push({
          id: ridersDataByCompany[i].id,
          name: ridersDataByCompany[i].name,
          bicciNumber: ridersDataByCompany[i].bicciNumber,
          avatar: ridersDataByCompany[i].avatar,
        });
      }
      setRidersRows(ridersRows);
    } else {
    }
  };

  const getBicciUserData = () => {
    // console.log("Secundaria  B: Asignar ID de Ruta a Empresa");
    if (userdata) {
      newRoute.companyId = userdata.userCompanyID;
    }
  };

  const findAdressesCompany = async (companyUid) => {
    // console.log("Secundaria  C: Buscar direcciones de empresa");
    try {
      const adressses = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(userdata.userCompanyID)
        .collection("direcciones")
        .get();
      const directions = adressses.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log(directions)
      setDirectionsDPlanner(directions);
    } catch (error) {
      //console.log(error)
    }
  };

  const getBicciVehiclesData = () => {
    // console.log("Secundaria  D: Buscar Vehiculos de empresa");
    if (VehiclesDataByCompany) {
      var vehicleRows = [];
      for (let i = 0; i < VehiclesDataByCompany.length; i++) {
        vehicleRows.push({
          id: VehiclesDataByCompany[i].id,
          travelMode: VehiclesDataByCompany[i].travelMode,
          VehiclesTypes: VehiclesDataByCompany[i].VehiclesTypes,
        });
      }
      setVehiclesRows(vehicleRows);
    } else {
    }
  };

  const getAwaitEstimateTime = () => {
    // console.log("Secundaria  F: Calcular tiempos de espera");
    var maxTimeDeliveryAwait = newRoute.maxTimeDelivery.split(":");
    let timeTemp = parseInt(maxTimeDeliveryAwait[1]);
    newRoute.estimateAwaitTime = newRoute.routePoints * timeTemp;
  };

  // SELECCIONES DE USUARIO

  const handleGoPropsRidersRows2 = (e) => {
    // console.log("SELECCIONES DE USUARIO  A");
    newRoute.driverID = e.target.parentNode.id;
    newRoute.driverName = e.target.parentNode.value;
    newRoute.driverAvatar =
      e.target.parentNode.attributes.getNamedItem("data").value;

    setRender(!render);
    //  newRoute.driverID = e.target.parentNode.id
    //  newRoute.driverName =  e.target.parentNode.value
  };

  const handleChangeMode = (e) => {
    //checkManifestOrders(manifesOrders, 456);
    // console.log("SELECCIONES DE USUARIO  B: Optimizar por comuna  y por nodos");
    if (e.target.name === "activeOptimizeComune") {
      let currentValue = !optimizeWaypointsComune;
      setOptimizeWaypointsComune(currentValue);
      setNewRoute({
        ...newRoute,
        optimizeWaypointsComune: currentValue,
      });
    }
    if (e.target.name === "activeOptimizeNodos") {
      let currentValue = !optimizeWaypointsNodos;
      setOptimizeWaypointsNodos(currentValue);
      if (manifesOrders.length > 21) {
        setOptimizeWaypointsNodos(true);
      }

      setNewRoute({
        ...newRoute,
        optimizeWaypointsNodos: currentValue,
      });
    }
    if (e.target.name === "activeOptimizePolygons") {
      let currentValue = !optimizeWaypointsPolygons;
      setOptimizeWaypointsPolygons(currentValue);
      setNewRoute({
        ...newRoute,
        optimizeWaypointsPolygons: currentValue,
      });
    }
    if (e.target.name === "activeOptimize") {
      if (e.target.checked === true) {
        setNewRoute({ ...newRoute, optimizeWaypoints: true });
        setOptimizeWaypoints(true);
      }
      if (e.target.checked === false) {
        setNewRoute({ ...newRoute, optimizeWaypoints: false });
        setOptimizeWaypoints(false);
      }
    }
  };

  //REVIZAR
  const offOptimize = () => {
    checkManifestOrders(manifesOrders, 517);

    setNewRoute({ ...newRoute, optimizeWaypoints: false });
    setOptimizeWaypoints(false);
    setRender(!render);
  };

  const onOptimize = () => {
    console.log("SELECCIONES DE USUARIO  E");
    setNewRoute({ ...newRoute, optimizeWaypoints: true });
    setOptimizeWaypoints(true);
    setRender(!render);
  };
  //REVIZAR Arriba

  const handleChange = (e) => {
    checkManifestOrders(manifesOrders, 485);
    // console.log("SELECCIONES DE USUARIO  C: seleccion tiempos, fecha");
    if (e.target.name === "endHour") {
      setRouteEndTimeDelivery(e.target.value);
      newRoute.endTimeDelivery = e.target.value;
    }
    if (e.target.name === "awaitTime") {
      setRouteStayTimeDelivery(e.target.value);
      newRoute.maxTimeDelivery = e.target.value;
    }
    if (e.target.name === "initHour") {
      setRouteStartTimeDelivery(e.target.value);
      newRoute.startTimeDelivery = e.target.value;
    }
    if (e.target.name === "dispchDate") {
      setRouteDate(e.target.value);
      newRoute.dateDelivery = e.target.value;
      newRoute.dateDeliveryText = e.target.value;
    }
  };

  const handleChangeDirectionsOrigen = (e) => {
    checkManifestOrders(manifesOrders, 531);
    // console.log("SELECCIONES DE USUARIO  H: seleccion Origen");
    let id = e;
    let dirSelect = directions.filter((item) => item.id === id);
    let selectOrigen = {
      lat: dirSelect[0].adressGeoNumAddressLat,
      lng: dirSelect[0].adressGeoNumAddressLng,
    };
    setOrigin(selectOrigen);
    newRoute.origin = selectOrigen;
    newRoute.originAdressName = dirSelect[0].adressName;
    setRender(!render);
  };

  const handleChangeDirectionsDestiny = (e) => {
    checkManifestOrders(manifesOrders, 548);
    // console.log("SELECCIONES DE USUARIO  I: seleccion de destino");
    let id = e;
    let dirSelect = directions.filter((item) => item.id === id);
    let destination = {
      lat: dirSelect[0].adressGeoNumAddressLat,
      lng: dirSelect[0].adressGeoNumAddressLng,
    };
    newRoute.destination = destination;
    setRender(!render);
  };

  //DATA OBJETO RUTA DB

  const setObjRoute = (bicciRoutesExpressByID) => {
    //checkManifestOrders(manifesOrders, 565);
    // console.log(bicciRoutesExpressByID);
    // console.log("DOWNLOAD DATA");
    newRoute.id = bicciRoutesExpressByID.id;
    newRoute.createBy = usuarioLocal;
    newRoute.createDate = bicciRoutesExpressByID.createDate;
    newRoute.date = bicciRoutesExpressByID.date;
    newRoute.vehicleID = bicciRoutesExpressByID.vehicleID;
    newRoute.driverID = bicciRoutesExpressByID.driverID;
    newRoute.driverName = bicciRoutesExpressByID.driverName;
    newRoute.companyId = userdata.userCompanyID;
    newRoute.travelMode = bicciRoutesExpressByID.travelMode;
    newRoute.origin = bicciRoutesExpressByID.origin;
    newRoute.destination = bicciRoutesExpressByID.destination;
    newRoute.optimizeWaypoints = bicciRoutesExpressByID.optimizeWaypoints; // si el falso se debe reodenar wayPoints segun las prioridades manualmente
    newRoute.wayPoints = bicciRoutesExpressByID.wayPoints;
    newRoute.waypoint_order = bicciRoutesExpressByID.waypoint_order;
    newRoute.maxTimeDelivery = bicciRoutesExpressByID.maxTimeDelivery;
    newRoute.dateDeliveryText = bicciRoutesExpressByID.dateDeliveryText;
    newRoute.dateDelivery = bicciRoutesExpressByID.dateDelivery;
    newRoute.startTimeDelivery = bicciRoutesExpressByID.startTimeDelivery;
    newRoute.endTimeDelivery = bicciRoutesExpressByID.endTimeDelivery;
    newRoute.distanceValue = bicciRoutesExpressByID.distanceValue;
    newRoute.durationValue = bicciRoutesExpressByID.durationValue;
    newRoute.dispacthDelyvery = bicciRoutesExpressByID.dispacthDelyvery;
    newRoute.dispacthPartials = bicciRoutesExpressByID.dispacthPartials;
    newRoute.dispacthNotDelyvery = bicciRoutesExpressByID.dispacthNotDelyvery;
    newRoute.fullCompliance = bicciRoutesExpressByID.fullCompliance;
    newRoute.warnings = bicciRoutesExpressByID.warnings;
    newRoute.routePoints = bicciRoutesExpressByID.routePoints;
    newRoute.totalPack = bicciRoutesExpressByID.totalPack;
    newRoute.estimateAwaitTime = bicciRoutesExpressByID.estimateAwaitTime;
    newRoute.estimateTotalTime = bicciRoutesExpressByID.estimateTotalTime;
    setOptimizeWaypoints(newRoute.optimizeWaypoints);
    setRouteStartTimeDelivery(newRoute.startTimeDelivery);
    setRouteStayTimeDelivery(newRoute.maxTimeDelivery);
    setRouteEndTimeDelivery(newRoute.endTimeDelivery);
    var clearID = [];
    for (let i = 0; i < bicciRoutesExpressByID.currentOrderList.length; i++) {
      if (!clearID.includes(bicciRoutesExpressByID.currentOrderList[i])) {
        clearID.push(bicciRoutesExpressByID.currentOrderList[i]);
      }
    }
    newRoute.currentOrderList = clearID;
    setCurrentOrderList(clearID);
    // setRender(!render);
  };

  const handleGoPropsVehicleRows = (e) => {
    // console.log("AUTOEJECUCIONES  B: Al seleccionar vehiculo");
    e.preventDefault();
    newRoute.vehicleID = e.target.parentNode.id;
    let tempData = VehiclesDataByCompany.filter(
      (item) => item.id === e.target.parentNode.id
    );
    newRoute.travelMode = tempData[0].travelMode;
    newRoute.origin = tempData[0].origen;
    newRoute.destination = tempData[0].destiny;

    setRender(!render);
  };

  const updateDataRouteOrderPiont = async (newOrederList) => {
    //checkManifestOrders(manifesOrders, 680);
    // console.log(
    //   "AUTOEJECUCIONES  C: Al Cambiar la lista de Ordenes en NewRoute"
    // );
    // console.log(newOrederList);
    async function createNewRoute() {
      var Local_wayPoints = [];
      var Local_waypoint_order = [];
      var Local_ManifestOrders = [];

      const reOrderDataByCurrentList = async () => {
        //adaptar el orden actual al orden de agrupacion
        var conuterTotalPack = 0;
        for (let i = 0; i < newOrederList.length; i++) {
          for (let n = 0; n < bicciOrdesDataManifesPlaning.length; n++) {
            try {
              if (
                newOrederList[i] === bicciOrdesDataManifesPlaning[n].orderID
              ) {
                conuterTotalPack += bicciOrdesDataManifesPlaning[i].UNIDADES;
                let wayPoint = {
                  stopover: true,
                  location: {
                    lat: bicciOrdesDataManifesPlaning[n]
                      .adressGeoNumAddressLatLng.lat,
                    lng: bicciOrdesDataManifesPlaning[n]
                      .adressGeoNumAddressLatLng.lng,
                  },
                };
                Local_wayPoints.push(wayPoint);
                Local_waypoint_order.push(i);
                Local_ManifestOrders.push(bicciOrdesDataManifesPlaning[n]);
              }
            } catch (error) {
              console.log("no se encuenntra data de orden:" + newOrederList[i]);
              // console.log(error);
            }
          }
          newRoute.totalPack = conuterTotalPack;
        }

        return {
          Local_wayPoints,
          Local_waypoint_order,
          Local_ManifestOrders,
        };
      };

      return new Promise(async (resolve, reject) => {
        let resp = await reOrderDataByCurrentList();
        // checkManifestOrders(resp.Local_ManifestOrders, 734);
        var clearID = [];
        var clearOrdersRepeat = [];
        async function reorder() {
          for (let i = 0; i < newOrederList.length; i++) {
            if (!clearID.includes(newOrederList[i])) {
              clearID.push(newOrederList);
            }
          }
        }

        await reorder();
        setNewRoute({
          ...newRoute,
          wayPoints: resp.Local_wayPoints,
          waypoint_order: resp.Local_waypoint_order,
        });
        setWayPointsManifets(clearID.length);
        setManifesOrders(resp.Local_ManifestOrders);
        espera();
      });
    }

    await createNewRoute();
  };

  const updateDataRouteOrderPiontByApi = async (newOrederList) => {
    newRoute.currentOrderList = newOrederList;
    setCurrentOrderList(newOrederList);
  };

  const setTriger = () => {
    function delay2() {
      setRender(!render);
    }
    setTimeout(delay2, 3000);
  };

  // GUARDADO DE EDICION

  const validateSave = () => {
    console.log("GUARDADO  A: Guardar cambios o cancelar edicion");
    var isComplete;
    if (
      newRoute.driverName &&
      newRoute.vehicleID &&
      newRoute.dateDeliveryText &&
      routeStartTimeDelivery &&
      routeEndTimeDelivery &&
      routeStayTimeDelivery
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    return isComplete === true ? (
      <>
        <Tooltip title="Cancelar edición">
          <IconButton
            onClick={handleCancel}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#ff0000",
              borderRadius: "3px",
              margintop: "5px",
              marginLeft: "0px",
              "&:focus": {
                outline: "none!important",
                margintop: "5px",
                marginLeft: "0px",
              },
              "&:hover": {
                opacity: 0.4,
                ml: 2,
                color: "#f8f8f8",
                background: "#ff0000",
                borderRadius: "3px",
                margintop: "5px",
                marginLeft: "0px",
              },
            }}
          >
            {`x Cancelar`}
          </IconButton>
        </Tooltip>
        <Tooltip title="Guardar">
          <IconButton
            onClick={handleClickUpdate}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#008000",
              borderRadius: "3px",
              margin: "5px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#008000",
                borderRadius: "3px",
                margin: "5px",
              },
            }}
          >
            {`Guardar`}
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <>
        {" "}
        <Tooltip title="Cancelar edición">
          <IconButton
            onClick={handleCancel}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#ff0000",
              borderRadius: "3px",
              margintop: "5px",
              marginLeft: "0px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#ff0000",
                borderRadius: "3px",
                margintop: "5px",
                marginLeft: "0px",
              },
            }}
          >
            {`x Cancelar`}
          </IconButton>
        </Tooltip>
        <Tooltip title="Guardar">
          <IconButton
            onClick={handleClickUpdate}
            size="small"
            sx={{
              ml: 2,
              color: "#f8f8f8",
              background: "#008000",
              borderRadius: "3px",
              margin: "5px",
              "&:focus": {
                outline: "none!important",
              },
              "&:hover": {
                opacity: 0.8,
                ml: 2,
                color: "#f8f8f8",
                background: "#008000",
                borderRadius: "3px",
                margin: "5px",
              },
            }}
          >
            {`Guardar`}
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const clearObjRoute = () => {
    console.log("GUARDADO  B: Validacion de datos correctos");
    if (newRoute.createBy !== bicciRoutesExpressByID.createBy) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.createDate !== bicciRoutesExpressByID.createDate) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.date !== bicciRoutesExpressByID.date) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.vehicleID !== bicciRoutesExpressByID.vehicleID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverID !== bicciRoutesExpressByID.driverID) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.driverName !== bicciRoutesExpressByID.driverName) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.companyId !== bicciRoutesExpressByID.companyId) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.travelMode !== bicciRoutesExpressByID.travelMode) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.currentOrderList !== bicciRoutesExpressByID.currentOrderList) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.origin !== bicciRoutesExpressByID.origin) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.destination !== bicciRoutesExpressByID.destination) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.optimizeWaypoints !== bicciRoutesExpressByID.optimizeWaypoints
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.wayPoints !== bicciRoutesExpressByID.wayPoints) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.waypoint_order !== bicciRoutesExpressByID.waypoint_order) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.maxTimeDelivery !== bicciRoutesExpressByID.maxTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDeliveryText !== bicciRoutesExpressByID.dateDeliveryText) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dateDelivery !== bicciRoutesExpressByID.dateDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.startTimeDelivery !== bicciRoutesExpressByID.startTimeDelivery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.endTimeDelivery !== bicciRoutesExpressByID.endTimeDelivery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.distanceValue !== bicciRoutesExpressByID.distanceValue) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.durationValue !== bicciRoutesExpressByID.durationValue) {
      setError("Faltan cambios por guardar");
      return;
    }

    if (newRoute.dispacthDelyvery !== bicciRoutesExpressByID.dispacthDelyvery) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.dispacthPartials !== bicciRoutesExpressByID.dispacthPartials) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (
      newRoute.dispacthNotDelyvery !==
      bicciRoutesExpressByID.dispacthNotDelyvery
    ) {
      setError("Faltan cambios por guardar");
      return;
    }
    if (newRoute.fullCompliance !== bicciRoutesExpressByID.fullCompliance) {
      setError("Faltan cambios por guardar");
      return;
    }
    props.history.push(`/dashboard/planner/`);
    setSuccesCreate(true);
    setRender(!render);
  };
  function handleCancel() {
    console.log("GUARDADO  C: Guardado confirmado");
    clearObjRoute();
  }
  const confirmCancel = (e) => {
    console.log("GUARDADO  D: Salir sin guardar");
    e.preventDefault();
    props.history.push(`/dashboard/planner/`);
  };

  // ACCIONES DE USUARIO

  async function handleClickUpdate(e) {
    console.log("GUARDADO  C: Validando entradas de formulario");
    e.preventDefault();
    if (!newRoute.companyId.trim()) {
      setError("Error companyId");
      return;
    }
    if (!newRoute.createBy.trim()) {
      setError("Error createBy");
      return;
    }
    // if(!newRoute.createDate.length()>0){setError('Error createDate')}
    // if(!newRoute.date.length()>0){setError('Error date')}
    //if(!newRoute.id.trim()){setError('Error ID');return}
    if (!newRoute.driverID.trim()) {
      setError("Error driverID");
      return;
    }
    if (!newRoute.vehicleID.trim()) {
      setError("Error vehicleID");
      return;
    }
    if (!newRoute.driverName.trim()) {
      setError("Error driverName");
      return;
    }
    if (!newRoute.travelMode.trim()) {
      setError("Error travelMode");
      return;
    }
    let currentOrderList = Object.entries(newRoute.currentOrderList);

    if (!currentOrderList.length > 0) {
      setError("No exiten guias o paquetes a la ruta ");
      return;
    } else {
      newRoute.routeStates = 104;
    }
    if (!Object.entries(newRoute.origin).length === true) {
      setError("Error origin");
      return;
    }
    if (!Object.entries(newRoute.destination).length === true) {
      setError("Error destination");
      return;
    }
    if (!newRoute.distanceValue === 0) {
      setError("Error distanceValue");
      return;
    }
    if (!newRoute.durationValue === 0) {
      setError("Error durationValue");
      return;
    }
    if (!newRoute.dispacthDelyvery === 0) {
      setError("Error dispacthDelyvery");
      return;
    }
    if (!newRoute.dispacthPartials === 0) {
      setError("Error dispacthPartials");
      return;
    }
    if (!newRoute.dispacthNotDelyvery === 0) {
      setError("Error dispacthNotDelyvery");
      return;
    }
    if (!newRoute.fullCompliance === 0) {
      setError("Error fullCompliance");
      return;
    }
    setError(null);

    try {
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .collection("history")
        .doc(Date.now().toString())
        .set(newRoute);
      await db
        .collection(`${process.env.REACT_APP_COL_ROUTES}`)
        .doc(newRoute.id)
        .update(newRoute);
      setSuccesCreate("true");
      clearObjRoute();
      props.history.push(`/dashboard/planner/`);
    } catch (error) {}
  }
  const updatRdition = () => {
    console.log("ACCIONES  F");
    console.log("guardarCambios");
  };
  const handleDeleted = async (orderID) => {
    console.log("ACCIONES  G");
    console.log("Deleted Order of manifets");
    console.log(orderID);
    let clearCurrentOrderList = currentOrderList.filter(
      (item) => item !== orderID
    );
    newRoute.currentOrderList = clearCurrentOrderList;
    newRoute.routePoints = clearCurrentOrderList.length;

    setCurrentOrderList(clearCurrentOrderList);
    getAwaitEstimateTime();
  };

  const selectAddOrders = async (bicciOrdersExpressByID) => {
    console.log("ACCIONES  H");
    console.log("selectAddOrders");
    console.log(bicciOrdersExpressByID.id);
    setOrderIdExist(false);

    if (currentOrderList.includes(bicciOrdersExpressByID.id)) {
      console.log("YA EXISTE");
      setOrderIdExist(true);
      return;
    }
    console.log("AGREGARNDO NUEVO");
    console.log(bicciOrdersExpressByID);

    setCurrentOrderList(currentOrderList);
    var tempManifesOrders = manifesOrders;
    var tempCurrentOrderList = currentOrderList;
    try {
      const allOrders = await db
        .collection(`${process.env.REACT_APP_COL_ORDERS}`) //buscar en data Bicci para routes
        .where("orderID", "==", bicciOrdersExpressByID.id)
        .get();
      var dataOrdes = allOrders.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      if (dataOrdes.length > 0) {
        for (let index = 0; index < dataOrdes.length; index++) {
          tempManifesOrders.push(dataOrdes[index]);
          bicciOrdesDataManifesPlaning.push(dataOrdes[index]);
        }
        tempCurrentOrderList.push(bicciOrdersExpressByID.id);
        // Ordenar Por currentOrderList
        tempManifesOrders.sort(function (a, b) {
          return (
            tempCurrentOrderList.indexOf(a.id) -
            tempCurrentOrderList.indexOf(b.id)
          );
        });

        newRoute.currentOrderList = tempCurrentOrderList;
        newRoute.routePoints = tempCurrentOrderList.length;
        setCurrentOrderList(tempCurrentOrderList);
        setManifesOrders(tempManifesOrders);
        getAwaitEstimateTime();
      } else {
        console.log("NO SE ENCONTRO");
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }

    //Validar si esta entregado
    //Validar si esta asigmado a otro conductor. y eviar alerta

    setRender(!render);
  };

  listOrders = (
    <PlanerDocExcell
      manifesOrders={manifesOrders}
      companyId={companyId}
      LocalRol={LocalRol}
      handleDeleted={handleDeleted}
      selectAddOrders={selectAddOrders}
      updateDataRouteOrderPiont={updateDataRouteOrderPiont}
      orderIdExist={orderIdExist}
      currentOrderList={currentOrderList}
      dragRow={dragRow}
      dragOverRow={dragOverRow}
      offOptimize={offOptimize}
    ></PlanerDocExcell>
  );
  mapaDeRuta = (
    <DashPlannerEditMapRoute
      setTriger={setTriger}
      companyId={companyId}
      userUid={userdata}
      setActiveIndex={setActiveIndex}
      updateDataRouteOrderPiontByApi={updateDataRouteOrderPiontByApi}
      newRoute={newRoute}
      updatRdition={updatRdition}
      manifesOrders={manifesOrders}
      optimizeWaypointsNodos={optimizeWaypointsNodos}
      haveAllDataRequire={haveAllDataRequire}
      offOptimize={offOptimize}
    ></DashPlannerEditMapRoute>
  );
  dispachtList = (
    <div>
      {" "}
      <Card className="dispachtListManifest">
        <div className="manifesContainer">
          <img
            className="logoManifest"
            src={
              "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00"
            }
            alt=""
          ></img>
        </div>
        <CardHeader
          title={
            <>
              <h1 className="manifesColorsFonts">
                Guía de Despachos a Retirar en Bodega
              </h1>
              <h2 className="manifesColorsFonts">
                DOC#{documentId} hoja 1 de 2
              </h2>
            </>
          }
        />
        <div>
          <CardHeader
            avatar={
              <Avatar
                src={newRoute.driverAvatar}
                sx={{ bgcolor: "#F2F2F2", color: "#050505" }}
                aria-label="recipe"
              ></Avatar>
            }
            title={
              newRoute.driverName ? (
                <span className="manifesFontsite20">{newRoute.driverName}</span>
              ) : (
                <>Seleccione un conductor</>
              )
            }
            subheader={
              <div className="manifesFontsite">
                {" "}
                Se ha asignado las siguientes órdenes del día{" "}
                {newRoute.dateDeliveryText} {", "}
                para completar las entregas al cliente final, por favor recuerda
                tomar todas la evidencias correctamente, que se solicitan el app
                BICCI RIDER
                <br></br> Retira en {newRoute.originAdressName}
                <br></br>
                <div className="manifesFontsite">
                  Total de Paquetes: {newRoute.totalPack}
                </div>
                <br></br>
                <div className="manifesFontsite">
                  Total de Puntos de entrega: {waitPointManifest}
                </div>
              </div>
            }
          />
        </div>

        <Box sx={{ width: "100%" }}>
          {manifesOrders ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">TIM</th>
                  <th scope="col">Cliente Destino</th>
                  <th scope="col">Fecha de envío</th>
                  <th scope="col">SOC</th>
                  <th scope="col">SKU</th>
                  <th scope="col">CANT</th>
                  <th scope="col">Descripción</th>
                </tr>
              </thead>
              <tbody>
                {manifesOrders.map((element, index) => (
                  <tr key={index}>
                    {/* <td>    
                                    <input 
                                      type="checkbox"  
                                      defaultChecked={false} 
                                      id={element.id} 
                                      name={element.id}  
                                      onChange={handleSelecRiders}
                                      /> </td> */}
                    <td>{element.orderID}</td>
                    <td>{element.F12}</td>
                    <td>{element.CLIENTENOMBRES}</td>
                    <td>{format(element.timeStamp * 1000, "dd/MM/yy")}</td>
                    <td>{element.SOC}</td>
                    <td>{element.SKU}</td>
                    <td>{element.packCounter}</td>
                    <td>{element.PRODUCTO}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LinearProgress></LinearProgress>
          )}
        </Box>
      </Card>
    </div>
  );
  documentPDF = (
    <>
      {newRoute && manifesOrders ? (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          {" "}
          <PlanerDocPDF
            newRoute={newRoute}
            routeDate={routeDate}
            manifesOrders={manifesOrders}
            documentID={documentID}
            waitPointManifest={waitPointManifest}
          ></PlanerDocPDF>
        </PDFViewer>
      ) : null}
    </>
  );

  // console.log("GLOBAL STATE");
  // console.log(newRoute);
  // console.log(currentOrderList);
  // console.log(manifesOrders);
  console.count("render :");
  if (newRoute && manifesOrders && manifesOrders.length > 0) {
    if (
      newRoute.wayPoints[3].location.lat ===
      manifesOrders[3].adressGeoNumAddressLatLng.lat
    ) {
      // console.log("GeoPuntos Concuerdan");
    } else {
      // console.log("ERROR ORDEN: GeoPuntos");
    }
  }
  if (newRoute && manifesOrders && manifesOrders.length > 0) {
    if (
      newRoute.currentOrderList[3] === currentOrderList[3] &&
      newRoute.currentOrderList[3] === manifesOrders[3].orderID
    ) {
      // console.log("IDs Concuerdan");
    } else {
      // console.log("ERROR IDs: newRoute.currentOrderList");
      // console.log(newRoute.currentOrderList[3]);
      // console.log("total :" + newRoute.currentOrderList.length);
      // console.log("ERROR IDs: manifesOrders");
      // console.log(manifesOrders[3].orderID);
      // console.log("total :" + manifesOrders.length);
      // console.log("ERROR currentOrderList");
      // console.log(currentOrderList[3]);
      // console.log("total :" + currentOrderList.length);
    }
  }

  // // ORDEN DE DATOS NOTAS
  //
  /* Variables de control
        newRoute.currentOrderList = tempCurrentOrderList;   // Array de ordenes en newRoute
        newRoute.routePoints = tempCurrentOrderList.length; // Array de ordenes catidad de Items en newRoute
        setCurrentOrderList(tempCurrentOrderList);          // variable currentOrderList 
        setManifesOrders(tempManifesOrders);                // manifestOrders
        getAwaitEstimateTime();                             // recalcular tiempos
  */
  //_> currentOrderList
  //_> manifesOrders
  //_> newRoute.waypoint_order = optimizeWaypoints:bolean
  //_> resposeAlgorit matrixDistance && destinationServe
  // folio de prueba F149079179533 - F149079184610
  // AUTOEJECUCIONES - INTERFAZ DE USUARIO - EDICIONES

  // ->newRoute
  // currentOrderList => Informacion Ordenes contenidas en la Ruta
  // wayPoints        => array de geoPuntos
  // waypoint_order   => Orden de Puntos de ruta
  // manifesOrders    => INFORMACION DEL PRODUCTOS

  // AGRUPACION DE RUTA
  // () => Ejecucion Maps Matrix
  // pointGroups,
  // manifetsReOrderPoints  => Actualiza data (manifesOrders, waypoint_order, wayPoints)

  // CALCULO DE RUTA
  // () => Ejecucion Maps Distance

  // MUESTRA DE RESULTADOS DE A LOS USUARIOS
  // geocoded_waypoints => Dibujado de puntos
  // waypoint_order *** => Orden de entregas
  // legs   => Instrucciones de dirección
  // warnings => Advertencias de API
  // overview_polyline => Dibujado de linea

  const checkManifestOrders = (manifesOrders1, line) => {
    if (manifesOrders1 && manifesOrders1.length === 85) {
      alert("CAMBIO :" + line);
    }
    if (
      manifesOrders1 &&
      manifesOrders1.length < 9 &&
      manifesOrders1 !== null &&
      manifesOrders1 !== undefined &&
      manifesOrders1.length !== 0
    ) {
      console.log(`%c ALERT! LINE   ${line}`, "color: red; font-size: 18px");
      console.log(manifesOrders1);
    }
  };

  return errorConfiCompany === null ? (
    <>
      <Grid celled="internally" id="expresscuentaContent">
        {/* {console.count("RENDER-S:")} */}
        {succesCreate ? (
          <div>
            <div className="alert alert-success m-3"> Nueva ruta creada </div>
          </div>
        ) : (
          <></>
        )}
        {errorPlanningDataGroup ? (
          <div>
            <div className="alert alert-danger m-3">
              {" "}
              {errorPlanningDataGroup}{" "}
            </div>
          </div>
        ) : null}
        <Grid.Row>
          <Grid.Column
            computer={4}
            mobile={16}
            tablet={7}
            className="dash-container-colmun"
          >
            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Datos de Planeacion  de Ruta" />
              <div className="m-2">
                <div>Tipo de ruta: </div>
                <div>Estado de ruta: </div>
              </div>
              <Divider />
              <div className="m-2">
                <table className="Vertical-table-width100">
                  <tbody>
                    {/* <tr>
                      <th>Agrupar Comunas:</th>
                      <td>
                        {" "}
                        <label className="switch">
                          <input
                            type="checkbox"
                            //defaultChecked={optimizeWaypoints}
                            name="activeOptimizeComune"
                            onChange={handleChangeMode}
                            checked={optimizeWaypointsComune}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                     */}
                    <tr>
                      <th>Agrupar Nodos:</th>
                      <td>
                        {" "}
                        <label className="switch">
                          <input
                            type="checkbox"
                            //defaultChecked={optimizeWaypoints}
                            name="activeOptimizeNodos"
                            onChange={handleChangeMode}
                            checked={optimizeWaypointsNodos}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    {/*
                    <tr>
                      <th>Agrupar Polygons:</th>
                      <td>
                        {" "}
                        <label className="switch">
                          <input
                            type="checkbox"
                            //defaultChecked={optimizeWaypoints}
                            name="activeOptimizePolygons"
                            onChange={handleChangeMode}
                            checked={optimizeWaypointsPolygons}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>{" "}
                    */}
                    <tr>
                      <th>Optimizar:</th>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            //defaultChecked={optimizeWaypoints}
                            name="activeOptimize"
                            onChange={handleChangeMode}
                            checked={optimizeWaypoints}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Divider />
              <Card className="routeResumen">
                <CardContent className="routeResumen">
                  <table className="Vertical-table-width100">
                    <tbody>
                      <tr>
                        <th>Usuario conductor:</th>
                        <td>{newRoute.driverName}</td>
                      </tr>
                      <tr>
                        <th>Vehículo:</th>
                        <td>{newRoute.vehicleID}</td>
                      </tr>
                      <tr>
                        <th>Fecha de despacho:</th>
                        <td>{newRoute.dateDeliveryText}</td>
                      </tr>
                      <tr>
                        <th>Hora de inicio</th>
                        <td>{routeStartTimeDelivery}</td>
                      </tr>
                      <tr>
                        <th>Hora de final</th>
                        <td>{routeEndTimeDelivery}</td>
                      </tr>
                      <tr>
                        <th>Tiempo de espera</th>
                        <td>{routeStayTimeDelivery}</td>
                      </tr>
                    </tbody>
                  </table>

                  {errorData ? (
                    <div>
                      <div className="alert danger-alert">{errorData}</div>
                      <div>
                        {" "}
                        <button
                          className="resimanconfirmCancel"
                          onClick={confirmCancel}
                        >
                          {" "}
                          Salir sin guardar{" "}
                        </button>
                      </div>{" "}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardContent>
              </Card>
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Datos de Planeación" />
              {showEdit === true ? (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEdit(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEdit(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Editar
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  <table className="Vertical-table-width100">
                    <tbody>
                      <tr>
                        <th>Oden:</th>
                        <td>
                          <div className="m-2">
                            {manifesOrders && manifesOrders.length > 25 ? (
                              <span className="alert alert-danger">
                                {/* <button
                                  onClick={() =>
                                    updateDataRouteOrderPointByZones()
                                  }
                                >
                                  {" "}
                                  Requiere agrupar zonas{" "}
                                </button> */}
                              </span>
                            ) : (
                              <span className="alert m-2">
                                {" "}
                                Optimización normal
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>Fecha de ruta:</th>
                        <td>
                          {" "}
                          <Form.Field
                            id="Form-Field-Edit-Route"
                            control={Input}
                            type="date"
                            iconPosition="left"
                            min={Moment(Date.now()).format("YYYY-MM-DD")}
                            // max="2022-08-10"
                            name="dispchDate"
                            onChange={(e) => handleChange(e)}
                            value={routeDate}
                            // blur={// console.log('blur accion')}
                            onClick={(e) => console.log(e.view)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Hora de inicio:</th>
                        <td>
                          {" "}
                          <Form.Field
                            id="Form-Field-Edit-Route-initHour"
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="initHour"
                            onChange={(e) => handleChange(e)}
                            value={routeStartTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Hora de final:</th>
                        <td>
                          {" "}
                          <Form.Field
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="endHour"
                            onChange={(e) => handleChange(e)}
                            value={routeEndTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Tiempo de espera</th>
                        <td>
                          {" "}
                          <Form.Field
                            control={Input}
                            type="time"
                            iconPosition="left"
                            name="awaitTime"
                            onChange={(e) => handleChange(e)}
                            value={routeStayTimeDelivery}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Origen</th>
                        <td>
                          {directions ? (
                            <select
                              id="custom-selectFrom-comuna"
                              control="select"
                              onChange={(e) =>
                                handleChangeDirectionsOrigen(e.target.value)
                              }
                            >
                              <option> Seleccione una Sucursal... </option>

                              {directions.map((item, index) => (
                                <option key={item.id + index} value={item.id}>
                                  {item.adressName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            "No existen Direccciones"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Destino</th>
                        <td>
                          {directions ? (
                            <select
                              id="custom-selectFrom-comuna"
                              control="select"
                              onChange={(e) =>
                                handleChangeDirectionsDestiny(e.target.value)
                              }
                            >
                              <option> Seleccione una Sucursal... </option>

                              {directions.map((item, index) => (
                                <option key={item.id + index} value={item.id}>
                                  {item.adressName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            "No existen Direccciones"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Conductor" />
              <CardHeader
                avatar={
                  <Avatar
                    src={newRoute.driverAvatar}
                    sx={{ bgcolor: "#F2F2F2" }}
                    aria-label="recipe"
                  ></Avatar>
                }
                title={
                  newRoute.driverName ? (
                    <>{newRoute.driverName}</>
                  ) : (
                    <>Seleccione un conductor</>
                  )
                }
                subheader=""
              />
              {showEditDriver === true ? (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditDriver(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditDriver(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Selecciona un conductor
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEditDriver} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  {/* <Form.Group widths='equal' className='FormGroupRiders' >
                      <Form.Field
                          control={Input}
                          type = "text"
                          placeholder='Buscar ID Rider o Nombre'
                          iconPosition='left'
                          icon = 'user'
                          name="usuarioRider"
                          onChange={e => setUserRiderr(e.target.value)}
                          value ={userRider}
                          />
                        </Form.Group> */}

                  <Box
                    sx={{
                      width: "100%",
                      height: 350,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRows ? (
                      <DataGrid
                        className="DataGridStyle"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRows}
                        columns={columnsRiders}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>

            <Card className="typeMiniformsPlanner">
              <CardHeader subheader="" title="Vehículo" />
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fecobicci-1.png?alt=media&token=25fc24ac-26e1-43a0-8bc0-889c4afec5cf"
                    }
                    sx={{ bgcolor: "#F2F2F2" }}
                    aria-label="recipe"
                  ></Avatar>
                }
                title={
                  newRoute.vehicleID ? (
                    <>{newRoute.vehicleID}</>
                  ) : (
                    <>Seleccione un vehículo</>
                  )
                }
                subheader=""
              />
              {showEditVehicle === true ? (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditVehicle(false)}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cerrar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowEditVehicle(true)}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Selecciona un Vehículo
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEditVehicle} duration={500}>
                <Container className="formcontent" id="micuentafromIni">
                  {/* <Form.Group widths='equal' className='FormGroupVehicle' >
                      <Form.Field
                                    control={Input}
                                    type = "text"
                                    placeholder='Patente/Placa'
                                    iconPosition='left'
                                    icon = 'truck'
                                    name="vehicle"
                                    onChange={e => setUserVehicle(e.target.value)}
                                    value ={userVehicle}
                                    />  </Form.Group> */}
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {vehiclesRows ? (
                      <DataGrid
                        sx={{
                          width: "100%",
                        }}
                        rows={vehiclesRows}
                        columns={columnsVehicle}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Container>
              </FadeInOut>

              <Divider />
            </Card>

            {/* <Card className='typeMiniformsPlanner'>
                    <CardHeader
                      subheader=''
                      title='Editar lista de entregas'
                    />
                        {
                          showEdit===true?
                          (<div>
                          <Button sx={{
                          display: 'flex',
                          justifyContent: 'flex-center',
                          outline: 'none',
                          m:1,
                          }}
                          color="primary"
                          variant="contained"
                          onClick={()=>setShowEdit(false)}
                          >
                          <HighlightOffIcon className="dashIcon-red" />
                          Cerrar
                          </Button></div>
                          ):(<div>

                            <Button sx={{
                            display: 'flex',
                            justifyContent: 'flex-center',
                            outline: 'none',
                            m:1,
                            }}
                            color="primary"
                            variant="contained"
                            onClick={()=>setShowEdit(true)}

                            >
                            <AddCircleIcon className="dashIcon-green"/>
                            Datos de Ruta
                            </Button></div>)
                        }
                    <Divider />
                    <FadeInOut show={showEdit} duration={500}>

                    <Container className='formcontent' id="micuentafromIni">
                    <Box
                      sx={{
                        width: '100%',
                        height: 50,
                        backgroundColor: '#2f2f2',
                        textAlign:'center',
                        p:1,

                      }}
                      >
                      <div> <h3 className="m-1" >Agregar despacho</h3></div>
                    </Box>
                    <Form.Group widths='equal' className='FormGroupVehicle' >
                    <Form.Field
                          control={Input}
                          type = "text"
                          placeholder='#Código ID'
                          iconPosition='left'
                          icon = 'truck'
                          name="orderid"
                          onChange={e => setOrderID(e.target.value)}
                          value ={OrderID}
                          />  </Form.Group>
                        <Box sx={{
                              height: '100%',
                              width: 550,
                              backgroundColor: '#fafafa',
                          }}>
                            {
                              OrdersRows?(<DataGrid
                                sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',}}
                                  rows={OrdersRows}
                                  columns={columsOrders}
                                  pageSize={5}
                                  rowsPerPageOptions={[10]}
                                  disableSelectionOnClick

                                />):(<></>)
                            }
                        </Box>

                    </Container>
                    </FadeInOut>

                    <Divider />

      </Card> */}
          </Grid.Column>
          <Grid.Column
            computer={12}
            mobile={16}
            tablet={9}
            className="dash-container-colmun"
          >
            <Tab
              className="tabular menu"
              menu={{ pointing: true }}
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={(e, { activeIndex }) => {
                setActiveIndex(activeIndex);
              }}
            ></Tab>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  ) : (
    <>{"errorConfiCompany"}</>
  );
};

export default DashPlannerEdit;
