import React, { useState, useEffect } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import BulkUpdateComponent from "./BulkUpdateComponent";

// Configuración de campos

const EditableOrderGrid = ({ data, errors, mode, onSave }) => {
  const fieldConfig = {
    ID: { required: true },
    FECHACARGA: { required: true },
    IDEINTERNAL: { required: true },
    FECHAENTREGA: { required: true },
    TIPODELIVERY: { required: true },
    CODIGOSUCRUSAL: { required: false },
    ORIGEN: { required: true },
    ORIGENLAT: { required: false },
    ORIGENLOG: { required: false },
    DESTINO: { required: true },
    DIR1: { required: true },
    DIR2: { required: false },
    DIR3: { required: false },
    COMUNA: { required: true },
    REGION: { required: true },
    PAIS: { required: true },
    LAT: { required: false },
    LOG: { required: false },
    COMENTARIO: { required: false },
    TELEFONO: { required: true },
    EMAIL: { required: true },
    NOMBRECLIENTE: { required: true },
    TIPOPRODUCTO: { required: false },
    CONTENIDO: { required: true },
    SKU: { required: false },
    DESCRPCION: { required: false },
    INSTRUCCIONES: { required: false },
    UNIDADES: { required: true },
    VALOR: { required: true },
    ALTO: { required: true },
    ANCHO: { required: true },
    LARGO: { required: true },
    PESO: { required: true },
    VOLUMEN: { required: true },
  };
  const [rows, setRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState(errors || []);
  const [applyToEmptyOnly, setApplyToEmptyOnly] = useState(false); // Estado para aplicar solo a vacíos

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (data && data.length > 0) {
      const initializedRows = data.map((row) => {
        let newRow = { ...row };

        // Convertir FECHACARGA y FECHAENTREGA a fechas legibles
        newRow.TELEFONO = newRow.TELEFONO ? newRow.TELEFONO.toString() : "";

        newRow.FECHACARGA = newRow.FECHACARGA
          ? excelSerialDateToJSDate(newRow.FECHACARGA)
          : "";
        newRow.FECHAENTREGA = newRow.FECHAENTREGA
          ? excelSerialDateToJSDate(newRow.FECHAENTREGA)
          : "";
        Object.keys(fieldConfig).forEach((field) => {
          if (newRow[field] === undefined) {
            newRow[field] = ""; // Si el campo no existe, inicializar como cadena vacía
          }
        });
        return newRow;
      });
      setRows(initializedRows);
    }
  }, [data]);

  useEffect(() => {
    handleValidate();
  }, [rows]);

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRows = rows.map((row) =>
      row.ID === newRow.ID ? newRow : row
    );
    setRows(updatedRows); // Actualizamos las filas en el estado

    return newRow; // Retornamos el nuevo valor actualizado
  };

  const handleBulkUpdate = (selectedColumn, value, applyToEmptyOnly) => {
    const updatedRows = rows.map((row) => {
      const columnValue = row[selectedColumn];

      if (
        applyToEmptyOnly &&
        (columnValue === undefined || columnValue === "")
      ) {
        return { ...row, [selectedColumn]: value }; // Aplicar solo a los vacíos
      } else if (!applyToEmptyOnly) {
        return { ...row, [selectedColumn]: value }; // Aplicar a todos
      }
      return row;
    });

    setRows(updatedRows); // Actualizar las filas
  };

  const handleSave = () => {
    const rowsToSave = rows.map((row) => {
      let newRow = { ...row };
      // Convertir FECHACARGA y FECHAENTREGA de vuelta al serial de Excel
      if (newRow.FECHACARGA) {
        newRow.FECHACARGA = jsDateToExcelSerial(newRow.FECHACARGA);
      }
      if (newRow.FECHAENTREGA) {
        newRow.FECHAENTREGA = jsDateToExcelSerial(newRow.FECHAENTREGA);
      }
      return newRow;
    });

    onSave(rowsToSave, validationErrors); // Envía los datos procesados
  };

  const ValidarDatos = (rows) => {
    let validationErrors = [];
    rows.forEach((row, rowIndex) => {
      Object.keys(fieldConfig).forEach((field) => {
        const isRequired = fieldConfig[field].required;
        const fieldValue = row[field];

        if (isRequired && (!fieldValue || fieldValue === "")) {
          validationErrors.push({
            index: rowIndex + 1,
            field,
            message: `El campo ${field} es obligatorio.`,
          });
        }
      });
    });

    return {
      isValid: validationErrors.length === 0,
      errors: validationErrors,
    };
  };

  const handleValidate = () => {
    const validation = ValidarDatos(rows);
    if (validation.isValid) {
      setValidationErrors([]);
    } else {
      setValidationErrors(validation.errors);
    }
  };
  // Convierte serial de Excel a fecha legible
  const excelSerialDateToJSDate = (serial) => {
    const days = Math.floor(serial);
    const fraction = serial - days;
    const excelEpoch = new Date(1899, 11, 30);
    const date = new Date(excelEpoch.getTime() + days * 86400 * 1000);
    const millisecondsInDay = 86400 * 1000;
    const milliseconds = Math.round(fraction * millisecondsInDay);
    date.setMilliseconds(date.getMilliseconds() + milliseconds);
    return date.toISOString().slice(0, 10); // Formato legible YYYY-MM-DD
  };

  // Convierte fecha legible a serial de Excel
  const jsDateToExcelSerial = (dateStr) => {
    const date = new Date(dateStr);
    const excelEpoch = new Date(1899, 11, 30);
    const diff = date.getTime() - excelEpoch.getTime();
    return diff / (86400 * 1000);
  };

  const splitAddress = (address, comuna) => {
    const regex = /^(.*?)(\d+)\s*(.*)?$/;
    const match = address.match(regex);

    if (!match) {
      return { DIR1: cleanString(address), DIR2: "", DIR3: "" }; // Si no coincide, retorna la dirección completa en DIR1
    }

    let DIR1 = cleanString(match[1].trim());
    let DIR2 = cleanString(match[2].trim());
    let DIR3 = match[3] ? cleanString(match[3].trim()) : "";

    // Nueva regla: Si DIR3 termina con la comuna, quitarla y eliminar la coma antes de la comuna
    const comunaLower = comuna.toLowerCase().trim();
    const dir3Lower = DIR3.toLowerCase().trim();

    if (dir3Lower.endsWith(comunaLower)) {
      // Eliminar la coma si precede a la comuna
      DIR3 = DIR3.replace(new RegExp(`,?\\s*${comuna}$`, "i"), "").trim();
    }

    console.log(`Direccion ideal: ${DIR1} ${DIR2}, ${comuna}, Chile`);

    let upateDirection = `${DIR1} ${DIR2}, ${comuna}, Chile`;
    return { DIR1, DIR2, DIR3, upateDirection };
  };

  // Función para limpiar caracteres especiales de los campos
  const cleanString = (str) => {
    // Eliminar caracteres especiales al principio y final (# , ; .)
    return str.replace(/^(\s|[#.,;])+/g, "").replace(/(\s|[#.,;])+$/g, "");
  };

  const handleApplyAddressTransformation = (applyToEmptyOnly) => {
    const updatedRows = rows.map((row) => {
      console.log(row);
      let direction = `${row.DESTINO}, ${row.DIR1}, ${row.DIR2}, ${row.DIR3}`;
      const { DIR1, DIR2, DIR3, upateDirection } = splitAddress(
        direction,
        row.COMUNA
      );
      console.log(DIR1, DIR2, DIR3, upateDirection);
      row.DESTINO = upateDirection;
      if (applyToEmptyOnly) {
        if (!row.DIR1 && !row.DIR2 && !row.DIR3) {
          return { ...row, DIR1, DIR2, DIR3 };
        }
        return row;
      } else {
        return { ...row, DIR1, DIR2, DIR3 };
      }
    });

    setRows(updatedRows);
  };

  const columns = [
    { field: "ID", headerName: "ID", width: 150, editable: true },
    {
      field: "IDEINTERNAL",
      headerName: "Eiqueta/QR",
      width: 150,
      editable: true,
    },

    {
      field: "FECHACARGA",
      headerName: "Fecha Carga",
      width: 150,
      editable: true,
    },
    {
      field: "FECHAENTREGA",
      headerName: "Fecha Entrega",
      width: 150,
      editable: true,
    },
    {
      field: "TIPODELIVERY",
      headerName: "Tipo Delivery",
      width: 150,
      editable: true,
    },
    {
      field: "CODIGOSUCRUSAL",
      headerName: "Código Sucursal",
      width: 150,
      editable: true,
    },
    { field: "ORIGEN", headerName: "Origen", width: 150, editable: true },
    { field: "DESTINO", headerName: "Destino", width: 150, editable: true },
    { field: "DIR1", headerName: "Calle", width: 150, editable: true },
    { field: "DIR2", headerName: "Numero", width: 150, editable: true },
    { field: "DIR3", headerName: "Dpto/Oficina", width: 150, editable: true },
    { field: "COMUNA", headerName: "Comuna", width: 150, editable: true },
    { field: "REGION", headerName: "Región", width: 150, editable: true },
    { field: "PAIS", headerName: "País", width: 150, editable: true },
    { field: "LAT", headerName: "Latitud", width: 150, editable: true },
    { field: "LOG", headerName: "Longitud", width: 150, editable: true },
    { field: "TELEFONO", headerName: "Teléfono", width: 150, editable: true },
    { field: "EMAIL", headerName: "Email", width: 150, editable: true },
    {
      field: "NOMBRECLIENTE",
      headerName: "NOMBRECLIENTE",
      width: 150,
      editable: true,
    },
    { field: "CONTENIDO", headerName: "Contenido", width: 150, editable: true },
    { field: "SKU", headerName: "SKU", width: 150, editable: true },
    {
      field: "DESCRPCION",
      headerName: "Descripción",
      width: 150,
      editable: true,
    },
    {
      field: "INSTRUCCIONES",
      headerName: "Instrucciones",
      width: 150,
      editable: true,
    },
    { field: "UNIDADES", headerName: "Unidades", width: 150, editable: true },
    { field: "VALOR", headerName: "Valor", width: 150, editable: true },
    { field: "ALTO", headerName: "Alto", width: 150, editable: true },
    { field: "ANCHO", headerName: "Ancho", width: 150, editable: true },
    { field: "LARGO", headerName: "Largo", width: 150, editable: true },
    { field: "PESO", headerName: "Peso", width: 150, editable: true },
    { field: "VOLUMEN", headerName: "Volumen", width: 150, editable: true },
  ];
  const columnNames = {
    ID: "ID",
    IDEINTERNAL: "Etiqueta/QR",
    FECHACARGA: "Fecha Carga",
    FECHAENTREGA: "Fecha Entrega",
    TIPODELIVERY: "Tipo Delivery",
    CODIGOSUCRUSAL: "Código Sucursal",
    ORIGEN: "Origen",
    DESTINO: "Destino",
    DIR1: "Calle",
    DIR2: "Numero",
    DIR3: "Dpto/Oficina",
    COMUNA: "Comuna",
    REGION: "Región",
    PAIS: "País",
    LAT: "Latitud",
    LOG: "Longitud",
    TELEFONO: "Teléfono",
    EMAIL: "Email",
    NOMBRECLIENTE: "NOMBRECLIENTE",
    CONTENIDO: "Contenido",
    SKU: "SKU",
    DESCRPCION: "Descripción",
    INSTRUCCIONES: "Instrucciones",
    UNIDADES: "Unidades",
    VALOR: "Valor",
    ALTO: "Alto",
    ANCHO: "Ancho",
    LARGO: "Largo",
    PESO: "Peso",
    VOLUMEN: "Volumen",
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Editar Data de Órdenes
      </Typography>

      <BulkUpdateComponent
        columns={columns}
        handleBulkUpdate={handleBulkUpdate}
      ></BulkUpdateComponent>
      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={handleSave}
      >
        Guardar cambios
      </Button>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={handleValidate}
      >
        Revisar datos
      </Button>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2 }}
        onClick={() => handleApplyAddressTransformation(applyToEmptyOnly)}
      >
        Aplicar reglas de dirección
      </Button>

      <FormControlLabel
        control={
          <Checkbox
            checked={applyToEmptyOnly}
            onChange={(e) => setApplyToEmptyOnly(e.target.checked)} // Cambiar el estado de la opción
          />
        }
        label="Aplicar reglas de dirección solo a campos vacíos"
      />

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={1500}
        getRowId={(row) => row.ID}
        //onCellEditCommit={handleCellEditCommit}
        //onCellEditCommit={handleCellEditCommit}
        processRowUpdate={processRowUpdate}
        apiRef={apiRef}
      />

      {validationErrors.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" color="error">
            Errores encontrados: <strong>{validationErrors.length}</strong>
          </Typography>
          <Box
            sx={{ maxWidth: "350px", maxHeight: "230px", overflow: "scroll" }}
          >
            {validationErrors.map((error, index) => (
              <Typography key={index} variant="body2" color="error">
                {`Línea ${error.index} - Campo: ${
                  columnNames[error.field] || error.field
                } - ${error.message}`}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditableOrderGrid;
