import { Button } from "@mui/material";
import React from "react";

const CodeExample = ({ examples, selectedExample, onSelectExample }) => {
  return (
    <div>
      <div>
        {Object.keys(examples).map((key) => (
          <Button key={key} onClick={() => onSelectExample(key)}>
            {key}
          </Button>
        ))}
      </div>
      <pre className="code-example">
        <code>{examples[selectedExample]}</code>
      </pre>
    </div>
  );
};

export default CodeExample;
