import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import { StyledCard, StyledTextField } from "./FromStyles";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

const FromShippingAccions = ({
  order,
  handleChangeDate,
  handleChangeAccions,
  validateFaltantes,
}) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});
  const [deliveryDate, setDeliveryDate] = useState(new Date()); // Set today as the default date

  useEffect(() => {
    if (order) {
      setFormState(order);
      // Evitar ciclo infinito: solo cambiar deliveryDate si es diferente del actual
    }
  }, [order]);

  // Controlar el cambio de deliveryDate y notificar al padre solo si cambia el valor
  useEffect(() => {
    const formattedDate = format(deliveryDate, "dd-MM-yy");
    if (order?.deliveryDate !== formattedDate) {
      handleChangeDate(formattedDate); // Notificar al padre solo si la fecha cambia
    }
  }, [deliveryDate, order, handleChangeDate]);

  const newOrderCreate = () => {
    handleChangeAccions("create");
    // Dispatch your order creation logic here
  };

  const ClearOrder = () => {
    handleChangeAccions("clear");
    // Dispatch your order creation logic here
  };

  const CancelCreate = () => {
    handleChangeAccions("canceled");
    // Dispatch your order creation logic here
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* DatePicker for deliveryDate */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledCard
          elevation={0}
          variant="outlined"
          sx={{ background: "transparent", border: "none", boxShadow: "none" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              paddingBottom: "5px!important",
              color: "#5e72b9",
              borderRadius: "10px 10px 10px 10px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <DatePicker
              label="Fecha de entrega"
              value={deliveryDate}
              onChange={(newValue) => setDeliveryDate(newValue)}
              disablePast // Disable dates before today
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  sx={{
                    input: { color: "#fff" },
                    svg: { color: "#fff" },
                    "& label": { color: "#fff" },
                  }}
                />
              )}
            />
            <Typography variant="button" display="block" gutterBottom>
              Fecha de entrega
            </Typography>
          </CardContent>
        </StyledCard>
      </LocalizationProvider>

      {/* Button actions */}
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            backgroundColor: "#5e72b9",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            onClick={newOrderCreate}
            disabled={validateFaltantes?.length > 0 ? true : false}
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "green",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <SendIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Enviar
          </Typography>
        </CardContent>
      </StyledCard>

      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            onClick={ClearOrder}
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <CleaningServicesIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Limpiar
          </Typography>
        </CardContent>
      </StyledCard>
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            onClick={CancelCreate}
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "red", //"primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <CancelScheduleSendIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Cancelar
          </Typography>
        </CardContent>
      </StyledCard>
      {/* <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <DirectionsBikeIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            ECO - BICCI
          </Typography>
        </CardContent>
      </StyledCard>
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <PaidIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            conciliación
          </Typography>
        </CardContent>
      </StyledCard> */}
    </Box>
  );
};

export default FromShippingAccions;
