import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import FadeInOut from "../../../animations/FadeInOut";
import BallotIcon from "@mui/icons-material/Ballot";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { GridSearchIcon } from "@mui/x-data-grid";
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import PrintIcon from "@mui/icons-material/Print";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const MenuGeneralOperacion = ({ onNavigate }) => {
  const [showOps, setShowshowOps] = useState(false);

  const opctionOps = ["guias", "eagleeye", "search", "fleet", "routes"];
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const getClassName = (pathSegment) =>
    splitLocation[2] === pathSegment
      ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
      : "btn btn-lg btn-block btn-sidebar";

  return (
    <>
      <Chip
        icon={<BallotIcon />}
        label="Gestión de Operación"
        variant="outlined"
        clickable
        color="primary"
        onClick={() => setShowshowOps(!showOps)}
        sx={{
          color: "#F8F8F8",
          width: "100%",
          fontSize: "1em",
          padding: "2px",
          marginBottom: "5px",
          backgroundColor: opctionOps.includes(splitLocation[2])
            ? "#07b107"
            : "transparent",
        }}
      />
      <FadeInOut show={showOps} duration={500}>
        <NavLink
          to="/dashboard/guias"
          className="btn-sidebarLink"
          onClick={onNavigate}
        >
          <div className={getClassName("guias")}>
            <ContentPasteGoIcon fontSize="medium" />
            <span className="btn-title">Despachos</span>
          </div>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard/search"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("search")}>
            <GridSearchIcon fontSize="medium" />
            <span className="btn-title">Buscar Datos</span>
          </div>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard/impresion-de-etiquetas"
          className="btn-sidebarLink"
        >
          <div className={getClassName("impresion-de-etiquetas")}>
            <PrintIcon fontSize="medium" />
            <span className="btn-title">Impresión de Etiquetas</span>
          </div>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard/distribucion"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("distribucion")}>
            <TravelExploreIcon fontSize="medium" />
            <span className="btn-title">Distribución</span>
          </div>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard/asignación-y-ruteo"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("asignación-y-ruteo")}>
            <AssignmentIcon fontSize="medium" />
            <span className="btn-title">Asignación</span>
          </div>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard/company-fleet"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("company-fleet")}>
            <LocalShippingIcon fontSize="medium" />
            <span className="btn-title">Flota Activa</span>
          </div>
        </NavLink>
        {/* <NavLink
          to="/dashboard/Monitor"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("Monitor")}>
            <AssignmentIcon fontSize="medium" />
            <span className="btn-title">Monitor</span>
          </div>
        </NavLink> */}
        <hr />
        <NavLink
          to="/dashboard/reportes"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div className={getClassName("company-report")}>
            <FileCopyIcon fontSize="medium" />
            <span className="btn-title">Reportes</span>
          </div>
        </NavLink>
      </FadeInOut>
    </>
  );
};

export default MenuGeneralOperacion;
