import React, { useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const ReusableFormImportOrders = ({
  open,
  handleClose,
  handleAddItem,
  setOpen,
  companyData,
}) => {
  const [openDropzone, setOpenDropzone] = useState(false);
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
    setOpenDropzone(false);
  };

  const formFieldsEdit = [
    {
      variable: "int_serviceType",
      label: "TIPODELIVERY",
      type: "string",
      require: true,
    },
    { variable: "productHeight", label: "ALTO", type: "string", require: true },
    { variable: "productWidth", label: "ANCHO", type: "string", require: true },
    {
      variable: "int_warehouseCode",
      label: "BODEGACODIGO",
      type: "string",
      require: true,
    },
    {
      variable: "int_warehouseName",
      label: "BODEGANOMBRE",
      type: "boolean",
      require: true,
    },
    {
      variable: "clientEmail",
      label: "CLIENTECORREO",
      type: "string",
      require: true,
    },
    {
      variable: "clientNames",
      label: "CLIENTENOMBRES",
      type: "string",
      require: true,
    },
    {
      variable: "clientRut",
      label: "CLIENTERUT",
      type: "string",
      require: true,
    },
    {
      variable: "clientPhone",
      label: "CLIENTETELEFONO",
      type: "string",
      require: true,
    },
    { variable: "commune", label: "COMUNA", type: "string", require: true },
    { variable: "commune2", label: "COMUNA2", type: "string", require: true },
    {
      variable: "direccion1",
      label: "DIRECCION1",
      type: "string",
      require: true,
    },
    {
      variable: "direccion2",
      label: "DIRECCION2",
      type: "string",
      require: false,
    },
    {
      variable: "direccion3",
      label: "DIRECCION3",
      type: "string",
      require: false,
    },
    { variable: "int_f12", label: "F12", type: "string", require: false },
    {
      variable: "deliveryDate",
      label: "FECHAVIAJE",
      type: "string",
      require: true,
    },
    {
      variable: "productLength",
      label: "LARGO",
      type: "string",
      require: true,
    },
    { variable: "int_lpn", label: "LPN", type: "string", require: true },
    {
      variable: "productMultiDelivery",
      label: "MULTIBULTO",
      type: "string",
      require: false,
    },
    { variable: "productWeight", label: "PESO", type: "string", require: true },
    {
      variable: "productName",
      label: "PRODUCTO",
      type: "string",
      require: true,
    },
    {
      variable: "int_rangeDate",
      label: "RANGOFECHAPACTADA",
      type: "string",
      require: true,
    },
    { variable: "pickedUp", label: "RECOGIDO", type: "string", require: true },
    {
      variable: "int_serviceLevel",
      label: "SERVICELEVEL",
      type: "string",
      require: true,
    },
    { variable: "int_soc", label: "SOC", type: "string", require: true },
    {
      variable: "int_productSize",
      label: "MULTIBULTO",
      type: "string",
      require: true,
    },
    { variable: "productValue", label: "VALOR", type: "string", require: true },
    {
      variable: "routeStartTime",
      label: "VENTANAINICIO",
      type: "string",
      require: true,
    },
    {
      variable: "routeEndTime",
      label: "VENTANATERMINO",
      type: "string",
      require: true,
    },
  ];

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    console.log(jsonData);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          if (field.require === true) {
            if (
              field.variable !== "id" &&
              (!item.hasOwnProperty(field.label) || item[field.label] === "")
            ) {
              setErroMensaje(
                `Falta un valor para la columna ${field.label} o está vacío en uno de los registros.`
              );
              return false;
            }
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      console.log("Todos los datos son válidos.");
      console.log(jsonData);
      await handleSaveData(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  const handleSaveData = async (formData) => {
    console.log(formData);
    console.log(companyData.prefix);
    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    // Función para crear o actualizar un único documento
    const upsertDocument = async (docData) => {
      /// CAMBIO :  DOCUMENTO BICCI O DOCUMENTO FALABELLA , O DOCUMENTO DE OTRA EMPRESA
      let id = docData.id || generarCadenaAleatoria(10);
      if (!docData.id || docData.id === "") {
        docData.id = id;
        docData.servicio_id = id; // Asumiendo que quieres asignar el mismo ID generado a servicio_id
      }

      const collRef = collection(db, process.env.REACT_APP_COL_SERVICIOS);
      const docRef = doc(collRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, docData);
        console.log("Documento actualizado con éxito.");
      } else {
        await setDoc(docRef, docData);
        console.log("Documento creado con éxito.");
      }

      handleAddItem(docData); // Asumiendo que esto actualiza la UI adecuadamente
    };

    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Crear viajes</DialogTitle>
      <DialogContent>
        <Button onClick={() => setOpenDropzone(true)} color="primary">
          Subir archivo
        </Button>
        {openDropzone && (
          <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
            <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
              <input {...getInputProps()} />
              <Typography>
                Arrastra y suelta algunos archivos aquí, o haz clic para
                seleccionar archivos
              </Typography>
            </Box>
          </Paper>
        )}
        {file && <p>File ready to be saved: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrders;
