// ReusableForm.js

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";

const ReusableForm = ({ open, handleClose, formFields, handleSave }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (variable, value) => {
    setFormData({ ...formData, [variable]: value });
  };

  const renderField = (field, index) => {
    switch (field.type) {
      case "string":
        return (
          <TextField
            key={index}
            autoFocus={index === 0}
            margin="dense"
            id={field.variable}
            label={field.label}
            value={formData[field.variable] || ""}
            type="text"
            fullWidth
            onChange={(e) => handleChange(field.variable, e.target.value)}
          />
        );
      case "boolean":
        return (
          <FormControlLabel
            key={index}
            control={
              <Switch
                checked={formData[field.variable] || false}
                onChange={(e) => handleChange(field.variable, e.target.checked)}
                name={field.variable}
                color="primary"
              />
            }
            label={field.label}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Agregar Nuevo Ítem</DialogTitle>
      <DialogContent>
        <DialogContent>{formFields.map(renderField)}</DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleSave(formData)} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableForm;
