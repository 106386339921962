import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { StyledCard, StyledTextField } from "./FromStyles";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useDispatch } from "react-redux";
import { createOrderBicciexpressClientCompany } from "../../redux/accionesOrders";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { updateDoc } from "firebase/firestore";

const FromShippingAccions = ({ order, companyData }) => {
  const dispacth = useDispatch();
  const [formState, setFormState] = useState({});
  const [isIDCreated, setIsIDCreated] = useState(false);

  useEffect(() => {
    if (order) {
      setFormState(order);
    }
  }, [order]);

  useEffect(() => {
    if (isIDCreated) {
      // Llama a newOrderCretate solo cuando formState ha sido actualizado.
      newOrderCretate();
      setIsIDCreated(false); // Restablecer para el próximo uso.
    }
  }, [isIDCreated]);

  const handleSendDefaultBicci = async () => {
    try {
      const createID = async () => {
        try {
          const accountantsCollRef = collection(
            db,
            `${process.env.REACT_APP_COL_ACCOUNTANS}`
          );
          const accuantatntOrder = doc(accountantsCollRef, "ordersCounter");
          const accountantsSnapshot = await getDoc(accuantatntOrder);
          const accountants = accountantsSnapshot.data();
          const documenCont = accountants.count + 1;
          const orderId = `${companyData.prefix}${documenCont}`;
          console.log(orderId);
          await updateDoc(accuantatntOrder, { count: documenCont });
          const orderRef = doc(
            db,
            `${process.env.REACT_APP_COL_ORDERS}`,
            orderId
          );

          setFormState((prevState) => ({
            ...prevState,
            id: orderId,
            key: orderId,
            createdAt: new Date(),
            timeStamp: new Date(),
          }));

          return true;
        } catch (error) {
          return false;
        }
      };

      let resultt = await createID();
      if (resultt) {
        console.log("creado el id");
        setIsIDCreated(true);
      } else {
        console.log("error al crear el id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const newOrderCretate = () => {
    // Crearte Ordes Methods.
    //dispacth(createOrderBicciexpressClientCompany(data));
    // Transferir gestion de entrega a bicci (Sin ID)
    // generar en store => dispacth(createOrderBicciClientCompanyBicciexpress(data));
    // Al respnder con el ID Actualizar el documento key -> Integracíon
    dispacth(createOrderBicciexpressClientCompany(formState));
  };

  const handleSaveOrderReferent = () => {
    console.log("handleSaveOrderReferent;", formState);
  };

  const handleClearOrderForm = () => {
    console.log("handleClearOrderForm:", formState);
  };

  const handleClearOrderFormAndBack = () => {
    console.log("handleClearOrderFormAndBack", formState);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardHeader
          title="Enviar"
          sx={{
            color: "#fff", // Texto blanco
            borderRadius: "10px 10px 0 0",
            padding: "16px",
          }}
        />
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            onClick={() => handleSendDefaultBicci()}
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <SendIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Enviar
          </Typography>
        </CardContent>
      </StyledCard>
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardHeader
          title="Guardar"
          sx={{
            color: "#fff", // Texto blanco
            borderRadius: "10px 10px 0 0",
            padding: "16px",
          }}
        />
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <SaveIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Guardar
          </Typography>
        </CardContent>
      </StyledCard>
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardHeader
          title="Limpiar"
          sx={{
            color: "#fff", // Texto blanco
            borderRadius: "10px 10px 0 0",
            padding: "16px",
          }}
        />
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <CleaningServicesIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Reset
          </Typography>
        </CardContent>
      </StyledCard>
      <StyledCard
        elevation={0}
        variant="outlined"
        sx={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <CardHeader
          title="Cancelar"
          sx={{
            color: "#fff", // Texto blanco
            borderRadius: "10px 10px 0 0",
            padding: "16px",
          }}
        />
        <CardContent
          sx={{
            display: "flex", // Habilita Flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center", // Centra los hijos horizontalmente
            padding: "5px",
            paddingBottom: "5px!important",
            color: "#fff",
            borderRadius: "10px 10px 10px 10px",
            background: "#26b1e9", // Fondo azul
            backgroundColor: "#5e72b9", // Fondo azul oscuro
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
          }}
        >
          <IconButton
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              color: "primary.main", // Color del ícono
              "&:hover": {
                backgroundColor: "primary.light", // Color en hover
              },
              marginBottom: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.35)",
            }}
          >
            <CancelScheduleSendIcon fontSize="large" />
          </IconButton>
          <Typography variant="button" display="block" gutterBottom>
            Cancelar
          </Typography>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default FromShippingAccions;
