//OLD FLEET

import React, { useEffect, useState } from "react";
import useGetDataDispachstByDate from "../../../hooks/getDataDispachstByDate";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import ToolDashSeachByDaRang from "../../../components/Dashboard/dashboard/ToolDashSeachByRang/ToolDashSeachByDaRang";
import ToolDashSeachByDay from "../../../components/Dashboard/dashboard/ToolDashSeachByDay/ToolDashSeachByDay";
import Moment from "moment";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import useGlobalConfig from "../../../hooks/globalConfig";
import LinearProgress from "@mui/material/LinearProgress";
import { useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import PlannerMapDistribucionAssing from "../Planner/PlannerTools/PlannerMapDistribucionAssing";
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { format } from "date-fns";
import PlannerMapDistribucionAssing2 from "../Planner/PlannerTools/PlannerMapDistribucionAssing2";

import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker";
import { fDate } from "../../../utils/objects/FormatTime";
import EventIcon from "@mui/icons-material/Event";
import GetRouteGoogleMaps from "../../../utils/GetRouteGoogleMaps";
import { CardContent, CardHeader } from "semantic-ui-react";
import FleetResumenTable from "./FleetResumenTable";
import { CustomDateRangePickerStyle } from "../../../components/CustomDateRangePicker/CustomDateRangePickerStyle";

const libraries = ["places", "drawing", "geometry"];
const Fleet = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [realTimeisActive, setRealTimeisActive] = React.useState(false);

  const [typeRquestReq, setTypeRquestReq] = useState(null);

  const [companyIdReq, setCompanyIdReq] = useState(null);

  const [localMetrcis, setLocalMetrics] = React.useState(null);

  const [totalArrayVehicles, setTotalArrayVehicles] = React.useState([]);

  const [totalArrayComunas, setTotalArrayComunas] = React.useState([]);

  const { userdata } = useObtenerDatosdeCliente(props.usuarioStorage.uid);

  const { rosettaVehicles } = useGlobalConfig();

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const { dataMerge, metricsOps } = useGetDataDispachstByDate(
    initDate,
    endDate,
    typeRquestReq,
    companyIdReq,
    userdata,
    realTimeisActive,
    setRealTimeisActive
  );

  console.log("metricsOps", metricsOps);
  // console.count("RENDER");

  useEffect(() => {}, [rangeInputPicker]);

  useEffect(() => {
    if (metricsOps && metricsOps.length > 0) {
      // console.log(metricsOps);
      callMetrics(metricsOps);
    }
  }, [metricsOps]);

  const onChageRange = (e) => {
    if (e.target.name === "rangoInicio") {
      setInitDate(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDate(e.target.value);
    }
    if (e.target.name === "staticDateBillingFilter") {
      setStaticDateBillingFilter(e.target.value);
      setInitDate(e.target.value);
      setEndDate(e.target.value);
    }
  };

  const changerealTimeOption = (e) => {
    setRealTimeisActive(e.target.checked);
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    let listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push("F" + tempuserOrders[n].id);
        }
      }
    }

    let copyData = copyOrdersForAccions;
    navigator.clipboard.writeText(copyData);
  };

  const callMetrics = (data) => {
    var totalRider = 0;
    var totalVehicles = [];
    var totalAssing = 0;
    var totalDelivery = 0;
    var totalPending = 0;
    var ntsPerformanse = 0;
    var totalZones = [];
    var listOfRiders = [];

    // Itera sobre los datos
    for (var i = 0; i < data.length; i++) {
      if (data[i].driverName !== "") {
        totalRider++;
        totalAssing += data[i].asignados;
        totalDelivery += data[i].delivery;
        totalPending += data[i].issures;
        listOfRiders.push(data[i].driverName);
      } else {
        totalPending += data[i].issures;
      }

      totalVehicles.push(data[i].vehicleDetailsType);

      // Asegúrate de que data[i].locality esté definido antes de empujarlo a totalZones
      if (data[i].locality) {
        totalZones.push(data[i].locality);
      }
    }

    ntsPerformanse = ((totalDelivery * 100) / totalAssing).toFixed(2);

    var allVehiclesNames = [];
    var allVehiclesConter = {};

    // Procesa los vehículos
    for (let a = 0; a < totalVehicles.length; a++) {
      if (!allVehiclesNames.includes(totalVehicles[a])) {
        allVehiclesNames.push(totalVehicles[a]);
        allVehiclesConter[totalVehicles[a]] = { cant: 0 };
      }
    }

    for (let a = 0; a < allVehiclesNames.length; a++) {
      for (let q = 0; q < totalVehicles.length; q++) {
        if (allVehiclesNames[a] === totalVehicles[q]) {
          allVehiclesConter[totalVehicles[q]].cant += 1;
        }
      }
    }

    var allZonesNames = [];
    var conterAllZonesGrousp = {};

    // Procesa las zonas
    for (let x = 0; x < totalZones.length; x++) {
      // Asegúrate de que totalZones[x] es un array antes de iterar sobre él
      if (Array.isArray(totalZones[x])) {
        for (let n = 0; n < totalZones[x].length; n++) {
          var tempData = Object.keys(totalZones[x][n]);

          if (!allZonesNames.includes(tempData[0])) {
            allZonesNames.push(tempData[0]);
            let zoneValue = Object.values(totalZones[x][n]);
            conterAllZonesGrousp[Object.keys(totalZones[x][n])] = parseInt(
              zoneValue[0]
            );
          } else {
            if (conterAllZonesGrousp[tempData[0]]) {
              let subTotalZoneValue = conterAllZonesGrousp[tempData[0]];
              let zoneValue = Object.values(totalZones[x][n]);
              conterAllZonesGrousp[tempData[0]] =
                parseInt(subTotalZoneValue) + parseInt(zoneValue[0]);
            }
          }
        }
      }
    }

    let cantTotalVehicles = Object.values(allVehiclesConter);
    let cantTotalZones = allZonesNames.length;

    let tempNames = Object.keys(allVehiclesConter);
    let tempValues = Object.values(allVehiclesConter);
    let tempArry = [];
    for (let y = 0; y < tempNames.length; y++) {
      let obj = { name: tempNames[y], value: tempValues[y] };
      tempArry.push(obj);
    }

    setTotalArrayVehicles(tempArry);

    let tempNamesComunas = Object.keys(conterAllZonesGrousp);
    let tempValuesComunas = Object.values(conterAllZonesGrousp);
    let tempArrayZones = [];
    for (let y = 0; y < tempNamesComunas.length; y++) {
      let obj = { name: tempNamesComunas[y], value: tempValuesComunas[y] };
      tempArrayZones.push(obj);
    }

    setTotalArrayComunas(tempArrayZones);

    let objeMetric = {
      totalRider: totalRider,
      totalVehicles: tempArry,
      cantTotalVehicles: cantTotalVehicles.length,
      totalAssing: totalAssing,
      totalDelivery: totalDelivery,
      totalPending: totalPending,
      ntsPerformanse: ntsPerformanse,
      totalZones: conterAllZonesGrousp,
      cantTotalZones: cantTotalZones,
      listOfRiders: listOfRiders,
    };

    var renderData = Object.entries(objeMetric);
    setLocalMetrics(objeMetric);
  };

  const setSeleccionByMapDataWaitPoints = () => {};

  const navigateToMapRider = async (e, item) => {
    // // console.log(e);
    // // console.log(item);
    //navigate(`/dashboard/planner/${eID}/edit`);
    let fecha = new Date(); // Fecha actual

    let year = fecha.getFullYear().toString().slice(-2); // Obtiene el año y lo convierte a cadena, luego toma los últimos dos dígitos
    let month = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1. Luego se convierte a cadena, y se le agrega un 0 al inicio si solo tiene un dígito
    let day = fecha.getDate().toString().padStart(2, "0"); // Obtiene el día y lo convierte a cadena, luego se le agrega un 0 al inicio si solo tiene un dígito

    let fechaStr = year + month + day;

    // Debería imprimir la fecha actual en formato YYMMDD
    const riderRef = collection(db, process.env.REACT_APP_COL_USERS_DRIVERS);

    let riderData = await getDoc(doc(riderRef, item.driverId));

    navigate(
      `/route/live/${item.driverId}/${riderData.data().bicciNumber}${fechaStr}`
    );
  };

  const navigateToMapRiderManifest = async (e, item) => {
    // // console.log(e);
    // // console.log(item);
    //navigate(`/dashboard/planner/${eID}/edit`);
    let fecha = new Date(staticDateBillingFilter); // Fecha actual

    let year = fecha.getFullYear().toString().slice(-2); // Obtiene el año y lo convierte a cadena, luego toma los últimos dos dígitos
    let month = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1. Luego se convierte a cadena, y se le agrega un 0 al inicio si solo tiene un dígito
    let day = fecha.getDate().toString().padStart(2, "0"); // Obtiene el día y lo convierte a cadena, luego se le agrega un 0 al inicio si solo tiene un dígito

    let fechaStr = year + month + day;

    // Debería imprimir la fecha actual en formato YYMMDD
    const riderRef = collection(db, process.env.REACT_APP_COL_USERS_DRIVERS);

    let riderData = await getDoc(doc(riderRef, item.driverId));

    navigate(
      `/dashboard/route/manifest/${item.driverId}/${
        riderData.data().bicciNumber
      }${fechaStr}`
    );
  };

  const copyToMapRider = async (e, item) => {
    //navigate(`/dashboard/planner/${eID}/edit`);
    let fecha = new Date(); // Fecha actual

    let year = fecha.getFullYear().toString().slice(-2); // Obtiene el año y lo convierte a cadena, luego toma los últimos dos dígitos
    let month = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1. Luego se convierte a cadena, y se le agrega un 0 al inicio si solo tiene un dígito
    let day = fecha.getDate().toString().padStart(2, "0"); // Obtiene el día y lo convierte a cadena, luego se le agrega un 0 al inicio si solo tiene un dígito

    let fechaStr = year + month + day;

    // Debería imprimir la fecha actual en formato YYMMDD
    const riderRef = collection(db, process.env.REACT_APP_COL_USERS_DRIVERS);

    let riderData = await getDoc(doc(riderRef, item.driverId));
    navigator.clipboard.writeText(
      `https://bicciexpress.cl/route/live/${item.driverId}/${
        riderData.data().bicciNumber
      }${fechaStr}`
    );
  };

  const navigateToOpcionsMapRider = (e, item) => {
    // // console.log(e);
    // // console.log(item);
  };

  function getAnalitycs(data, initDate, endDate) {
    var productValue = 0;
    var producstPeso = 0;
    var productsVolumen = 0;

    for (let n = 0; n < data.length; n++) {
      productValue += data[n].price;
      producstPeso += data[n].weight;
      productsVolumen +=
        data[n].height *
        data[n].weight *
        data[n].width *
        data[n].quantity_packages; // Asegúrate de que 'volume' es el campo correcto
    }

    const objetosFiltrados = data.filter((objeto) => objeto.status === 8);

    let horaMenor = Infinity;
    let horaMayor = -Infinity;

    objetosFiltrados.forEach((objeto) => {
      const updatedAt = objeto.updatedAt.seconds;

      if (updatedAt < horaMenor) {
        horaMenor = updatedAt;
      }
      if (updatedAt > horaMayor) {
        horaMayor = updatedAt;
      }
    });

    // console.log(objetosFiltrados);

    const convertirAHoraMinutos = (segundos) => {
      if (segundos === Infinity || segundos === -Infinity)
        return "Sin registro";
      const fecha = new Date(segundos * 1000);
      var horas = fecha.getHours().toString().padStart(2, "0");
      var minutos = fecha.getMinutes().toString().padStart(2, "0");
      return `${horas}:${minutos}`;
    };

    var horaMenorFormateada = convertirAHoraMinutos(horaMenor);
    var horaMayorFormateada = convertirAHoraMinutos(horaMayor);

    const diferenciaSegundos = horaMayor - horaMenor;

    var diferenciaHoras = Math.floor(diferenciaSegundos / 3600);
    var diferenciaMinutos = Math.floor((diferenciaSegundos % 3600) / 60);

    return (
      <table>
        <tbody>
          <tr>
            <th colSpan="2">Información de Carga</th>
          </tr>
          <tr>
            <td>Valor de Carga</td>
            <td>${productValue}</td>
          </tr>
          <tr>
            <td>Peso de Carga</td>
            <td>{producstPeso.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Volumen de Carga</td>
            <td>{productsVolumen.toFixed(3)} M3</td>
          </tr>
          <tr>
            <td>Hora de Inicio</td>
            <td>{horaMenorFormateada}</td>
          </tr>
          <tr>
            <td>Hora de Termino</td>
            <td>{horaMayorFormateada}</td>
          </tr>
          <tr>
            <td>Duración estimada</td>
            <td>
              {diferenciaHoras} Hrs, {diferenciaMinutos} min
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const calculateRoute = async (e, item) => {
    // console.log(item);
    let responde = await GetRouteGoogleMaps();
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  if (loadError) {
    // // console.log("Maps Error");
    return "Error";
  }

  if (!isLoaded) {
    // // console.log("Loading OK");
    return "Loading...";
  }

  return (
    <div>
      <h2 className="h2-withe">Flota</h2>
      {/* <Grid>
        <Button
          variant="contained"
          onClick={rangeInputPicker.onOpen}
           sx={CustomDateRangePickerStyle}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <div sx={{ typography: "body2" }}>
              <strong>Desde:</strong> {fDate(rangeInputPicker.startDate)}
              <EventIcon color="primary" />
            </div>
            <div sx={{ typography: "body2" }}>
              <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
              <EventIcon color="primary" />
            </div>
          </Stack>
        </Button>

        <CustomDateRangePicker
          variant="calendar" // input
          open={rangeInputPicker.open}
          startDate={rangeInputPicker.startDate}
          endDate={rangeInputPicker.endDate}
          onChangeStartDate={rangeInputPicker.onChangeStartDate}
          onChangeEndDate={rangeInputPicker.onChangeEndDate}
          onClose={rangeInputPicker.onClose}
          error={rangeInputPicker.error}
          setNewDate={setNewDate}
        />
      </Grid> */}
      <Button
        variant="contained"
        onClick={rangeInputPicker.onOpen}
        sx={CustomDateRangePickerStyle}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <div sx={{ typography: "body2" }}>
            <strong>Desde:</strong> {fDate(rangeInputPicker.startDate)}
            <EventIcon color="primary" />
          </div>
          <div sx={{ typography: "body2" }}>
            <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
            <EventIcon color="primary" />
          </div>
        </Stack>
      </Button>
      <CustomDateRangePicker
        variant="calendar" // input
        open={rangeInputPicker.open}
        startDate={rangeInputPicker.startDate}
        endDate={rangeInputPicker.endDate}
        onChangeStartDate={rangeInputPicker.onChangeStartDate}
        onChangeEndDate={rangeInputPicker.onChangeEndDate}
        onClose={rangeInputPicker.onClose}
        error={rangeInputPicker.error}
        setNewDate={setNewDate}
      />
      {/* <div> */}
      {/* <ToolDashSeachByDaRang
          initDate={initDate}
          endDate={endDate}
          onChageRange={onChageRange}
        ></ToolDashSeachByDaRang>
        <ToolDashSeachByDay
          staticDateBillingFilter={staticDateBillingFilter}
          onChageRange={onChageRange}
        ></ToolDashSeachByDay>
      </div> */}
      <div className="Routes-wrapper">
        {/* <label className="switch">
          <input
            type="checkbox"
            id="realTimeisActive"
            name="realTimeisActive"
            checked={realTimeisActive}
            onChange={changerealTimeOption}
          />
          <span className="slider round"></span>
        </label> */}
        {/* <span className="realtime-label h2-withe">
          Actualizar en tiempo real:
        </span>
        {realTimeisActive ? (
          <span className="realtime-info h2-withe">{initDate}</span>
        ) : (
          <div className="realtime-pause m-2">
            <span className="alert alert-info">En Pausa</span>
          </div>
        )} */}
      </div>
      <div>
        <FleetResumenTable metricsOps={metricsOps}></FleetResumenTable>
      </div>

      <div>
        <h3 className="m-3 h2-withe">Resumen de la Operación</h3>

        {metricsOps ? (
          <>
            {metricsOps.map((item, index) => {
              const progress = ((item.delivery * 100) / item.asignados).toFixed(
                0
              );

              let progressColor = "error";
              if (progress >= 98) {
                progressColor = "success";
              } else if (progress >= 96 && progress < 98) {
                progressColor = "info";
              }
              if (progress >= 5 && progress < 96) {
                progressColor = "warning";
              }
              if (item.driverName) {
                return (
                  <Card key={index} sx={{ marginBottom: "3px" }}>
                    <CardHeader
                      title={
                        <>
                          {" "}
                          <span className="progressTextPorcent ">
                            {" "}
                            {((item.delivery * 100) / item.asignados).toFixed(
                              2
                            )}
                            %
                          </span>{" "}
                        </>
                      }
                    ></CardHeader>
                    <CardContent>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ flex: 1 }}>
                          <div className="card-image">
                            <Avatar
                              src={item.driverAvatar}
                              sx={{
                                bgcolor: "#f2f2f2",
                                width: "100px",
                                height: "100px",
                              }}
                              aria-label="recipe"
                            ></Avatar>
                          </div>
                        </Box>

                        <Box sx={{ flex: 3, minWidth: "30%" }}>
                          <>
                            {item.listOrdersData && item.listOrdersData.data ? (
                              getAnalitycs(
                                item.listOrdersData.data,
                                initDate,
                                endDate
                              )
                            ) : (
                              <></>
                            )}
                          </>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <table className="table table-card-fullw">
                            <thead>
                              <tr>
                                <th scope="col">RUTA:</th>
                                <th scope="col"> Nombre </th>
                                <th scope="col"> Asígnados </th>
                                <th scope="col"> Finalizados </th>
                                <th scope="col"> Pendientes </th>
                                <th scope="col"> % </th>
                                <th scope="col"> Comunas </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>
                                  <div className="card-content">
                                    <Typography sx={{ fontSize: 18 }}>
                                      {item.driverName ? (
                                        <>{item.driverName}</>
                                      ) : (
                                        <span className="alert alert-danger">
                                          SIN ASIGNACIÓN
                                        </span>
                                      )}
                                    </Typography>
                                  </div>
                                </td>

                                <td>
                                  <p>{item.asignados} Asignados</p>
                                </td>
                                <td>
                                  <p>{item.delivery} Finalizados</p>
                                </td>
                                <td>
                                  <p>{item.issures} Pendientes</p>
                                </td>
                                <td>
                                  <p>{progress}%</p>
                                </td>
                                <td>
                                  <div>
                                    {item.locality.map((i, index) => (
                                      <div key={index + index}>
                                        {i[Object.keys(i)]} {Object.keys(i)}{" "}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      </Box>

                      {progress ? (
                        <>
                          <span className="progressTextPorcent ">
                            {" "}
                            {((item.delivery * 100) / item.asignados).toFixed(
                              2
                            )}
                            %
                          </span>
                          <LinearProgress
                            value={parseInt(progress)}
                            variant="determinate"
                            color={progressColor}
                            sx={{ height: "50px" }}
                          ></LinearProgress>
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                );
              }
            })}
          </>
        ) : null}
      </div>

      {dataMerge ? (
        <div>
          <h3 className="m-3 h2-withe">Vista general de la Operación</h3>
          <h3 className="m-3 progressTextPorcentBig h2-withe">
            {" "}
            Total: {dataMerge.length}
          </h3>
          <div className="card">
            <div className="card-header">
              <button className="btn-add-guide-small m-2">
                Ver distibucion en Mapa
              </button>
              {localMetrcis ? (
                <span className="progressTextPorcentBig color-calipso ">
                  {" "}
                  {parseInt(localMetrcis.ntsPerformanse).toFixed(1)}%
                </span>
              ) : null}
              <span className="progressTextresalte ">Avance general</span>
            </div>
            <div className="card-body-flex">
              <table className="table-card-fullw-50">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"> Conductores en ruta </th>
                    <th scope="col"> Vehículos </th>
                  </tr>
                </thead>
                {localMetrcis ? (
                  <tbody>
                    <tr>
                      <td>
                        <div className="card-image">
                          <PlannerMapDistribucionAssing
                            markerpoint={dataMerge}
                            confHeight={"400px"}
                            confWidth={"400px"}
                            setSeleccionByMapDataWaitPoints={
                              setSeleccionByMapDataWaitPoints
                            }
                          ></PlannerMapDistribucionAssing>
                        </div>
                      </td>
                      <td className="vertical-align-initial">
                        <div className="card-content"></div>
                        <div>
                          {localMetrcis.listOfRiders.map((item, index) => {
                            return (
                              <div key={index} className="progressTextresalte">
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      <td className="vertical-align-initial">
                        {localMetrcis.totalVehicles.map((item, index) => {
                          return (
                            <div key={index} className="progressTextresalte">
                              {item.value.cant} {rosettaVehicles[item.name]}
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
              {localMetrcis ? (
                <table className="table-card-fullw-20">
                  <tbody>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item"> Asígnados</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalAssing}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Finalizados</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalDelivery}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Pendientes</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalPending}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Avance</div>
                      </td>
                      <td>
                        {" "}
                        <p className="progressTextresalte">
                          {parseInt(localMetrcis.ntsPerformanse).toFixed(0)}%
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Comunas</div>
                      </td>

                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.cantTotalZones}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
            <hr></hr>
          </div>
        </div>
      ) : null}
      <div>
        <h3 className="m-3 color-white">Vista por conductor</h3>
        {metricsOps && metricsOps.length > 0 ? (
          <div className="table-card">
            {metricsOps.map((item, index) => {
              const progress = ((item.delivery * 100) / item.asignados).toFixed(
                0
              );

              let progressColor = "error";
              if (progress >= 98) {
                progressColor = "success";
              } else if (progress >= 96 && progress < 98) {
                progressColor = "info";
              }
              if (progress >= 5 && progress < 96) {
                progressColor = "warning";
              }
              // if (!item.driverName) {
              //   return (
              //     <div key={index} className="card card-route">
              //       <div className="card-header">
              //          <button
              //         onClick={(e) => copyToMapRider(e, item)}
              //         className="btn-add-guide-small m-2"
              //       >
              //         Copy Link
              //       </button>
              //          <button
              //         onClick={(e) => navigateToMapRider(e, item)}
              //         className="btn-add-guide-small m-2"
              //       >
              //         Ver Mapa
              //       </button> *
              //         <button
              //           onClick={(e) =>
              //             handleCopySelectRiderCurrentList(e, item)
              //           }
              //           className="btn-add-guide-small m-2"
              //         >
              //           Copiar lista
              //         </button>

              //         {/* <button
              //         onClick={(e) => navigateToMapRiderManifest(e, item)}
              //         className="btn-add-guide-small m-2"
              //       >
              //         Ver hoja de ruta
              //       </button> *
              //       </div>
              //       <div className="card-body">
              //         <table className="table table-card-fullw">
              //           <thead>
              //             <tr>
              //               <th scope="col"></th>
              //               <th scope="col"> Estatus </th>
              //               <th scope="col"> Vehículo </th>
              //               <th scope="col"> Asígnados </th>
              //               <th scope="col"> Finalizados </th>
              //               <th scope="col"> Pendientes </th>
              //               <th scope="col"> % </th>
              //               <th scope="col"> Comunas </th>
              //               <th scope="col"> Pentientes por zona</th>
              //               <th scope="col"> </th>
              //             </tr>
              //           </thead>
              //           <tbody>
              //             <tr>
              //               <td></td>
              //               <td>
              //                 <div className="card-content">
              //                   <h3>
              //                     {item.driverName ? (
              //                       <>{item.driverName}</>
              //                     ) : (
              //                       <span className="alert alert-warrning">
              //                         POSIBLES RETRASOS, O PENDIENTES POR
              //                         ASIGNACION
              //                       </span>
              //                     )}
              //                   </h3>
              //                 </div>
              //               </td>
              //               <td>
              //                 {rosettaVehicles[item.vehicleDetailsType]
              //                   ? rosettaVehicles[item.vehicleDetailsType]
              //                   : "No registrado"}
              //               </td>
              //               <td>
              //                 <p>{item.asignados} Asignados</p>
              //               </td>
              //               <td>
              //                 <p>{item.delivery} Finalizados</p>
              //               </td>
              //               <td>
              //                 <p>{item.issures} Pendientes</p>
              //               </td>
              //               <td>
              //                 <p>{progress}%</p>
              //               </td>
              //               <td>
              //                 <div>
              //                   {item.locality.map((i, index) => (
              //                     <div key={index + index}>
              //                       {i[Object.keys(i)]} {Object.keys(i)}{" "}
              //                     </div>
              //                   ))}
              //                 </div>
              //               </td>
              //               <td>
              //                 <div>
              //                   {item.localityIsures ? (
              //                     <div>
              //                       {" "}
              //                       {item.localityIsures.map((i, index) => (
              //                         <div key={index + index}>
              //                           {i[Object.keys(i)]} {Object.keys(i)}{" "}
              //                         </div>
              //                       ))}
              //                     </div>
              //                   ) : (
              //                     <>Sin Pendientes</>
              //                   )}
              //                 </div>
              //               </td>
              //             </tr>
              //           </tbody>
              //         </table>
              //       </div>
              //     </div>
              //   );
              //}
              return (
                <div key={index} className="card card-route">
                  <div className="card-header">
                    {/* <button
                      onClick={(e) => copyToMapRider(e, item)}
                      className="btn-add-guide-small m-2"
                    >
                      Copy Link
                    </button> 
                    {/* <button
                      onClick={(e) => navigateToMapRider(e, item)}
                      className="btn-add-guide-small m-2"
                    >
                      Ver Mapa
                    </button> */}
                    <button
                      onClick={(e) => handleCopySelectRiderCurrentList(e, item)}
                      className="btn-add-guide-small m-2"
                    >
                      Copiar lista
                    </button>
                    <button
                      onClick={(e) => calculateRoute(e, item)}
                      className="btn-add-guide-small m-2"
                    >
                      Obtener calculo de rutas
                    </button>
                    {/* <button
                      onClick={(e) => navigateToMapRiderManifest(e, item)}
                      className="btn-add-guide-small m-2"
                    >
                      Ver hoja de ruta
                    </button> */}
                    {item.asignados < 13 ? (
                      <span className="alert alert-danger relevanText">
                        {" "}
                        Alerta de costos. Ruta posee menos de 13 Paquetes
                        asignados.
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="card-body">
                    <table className="table table-card-fullw">
                      <thead>
                        <tr>
                          <th scope="col">RUTA:</th>
                          <th scope="col"> Nombre </th>
                          <th scope="col"> Vehículo </th>
                          <th scope="col"> Asígnados </th>
                          <th scope="col"> Finalizados </th>
                          <th scope="col"> Pendientes </th>
                          <th scope="col"> % </th>
                          <th scope="col"> Comunas </th>
                          <th scope="col"> Pentientes por zona</th>
                          <th scope="col"> Detalles</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="card-image">
                              <Avatar
                                src={item.driverAvatar}
                                sx={{
                                  bgcolor: "#f2f2f2",
                                  width: "100px",
                                  height: "100px",
                                }}
                                aria-label="recipe"
                              ></Avatar>
                            </div>
                            <div className="minimap">
                              {item.listOrdersData.data ? (
                                <PlannerMapDistribucionAssing2
                                  markerpoint={item.listOrdersData.data}
                                  confHeight={"150px"}
                                  confWidth={"400px"}
                                  setSeleccionByMapDataWaitPoints={
                                    setSeleccionByMapDataWaitPoints
                                  }
                                ></PlannerMapDistribucionAssing2>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <div className="card-content">
                              <h3>
                                {item.driverName ? (
                                  <>{item.driverName}</>
                                ) : (
                                  <span className="alert alert-danger">
                                    SIN ASIGNACIÓN
                                  </span>
                                )}
                              </h3>
                            </div>
                          </td>
                          <td>
                            {rosettaVehicles[item.vehicleDetailsType]
                              ? rosettaVehicles[item.vehicleDetailsType]
                              : "No registrado"}
                          </td>
                          <td>
                            <p>{item.asignados} Asignados</p>
                          </td>
                          <td>
                            <p>{item.delivery} Finalizados</p>
                          </td>
                          <td>
                            <p>{item.issures} Pendientes</p>
                          </td>
                          <td>
                            <p>{progress}%</p>
                          </td>
                          <td>
                            <div>
                              {item.localit ? (
                                <>
                                  {item.locality.map((i, index) => (
                                    <div key={index + index}>
                                      {i[Object.keys(i)]} {Object.keys(i)}{" "}
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <div>
                              {item.localityIsures ? (
                                <div>
                                  {" "}
                                  {item.localityIsures.map((i, index) => (
                                    <div key={index + index}>
                                      {i[Object.keys(i)]} {Object.keys(i)}{" "}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <>Sin Pendientes</>
                              )}
                            </div>
                          </td>
                          <td>
                            {item.listOrdersData && item.listOrdersData.data ? (
                              getAnalitycs(
                                item.listOrdersData.data,
                                initDate,
                                endDate
                              )
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span className="progressTextPorcent ">
                      {" "}
                      {((item.delivery * 100) / item.asignados).toFixed(2)}%
                    </span>
                    {progress ? (
                      <LinearProgress
                        value={parseInt(progress)}
                        variant="determinate"
                        color={progressColor}
                        sx={{ height: "33px" }}
                      ></LinearProgress>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Fleet;
