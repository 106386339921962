import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { db } from "../../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Simula un conjunto de permisos posibles
const defaultPermissions = ["READ", "CREATE", "UPDATE", "DELETE"];

export const RolesPermissionsConfigurator = ({
  roles,
  servicesInfo,
  FormInitialConfig,
  currentconfig,
}) => {
  // Configuración inicial que combina todos los roles con todos los servicios y permisos vacíos
  const [config, setConfig] = useState({});

  useEffect(() => {
    // Inicializa la configuración para incluir todos los roles y servicios, sin permisos asignados
    if (roles && servicesInfo) {
      //   console.log(roles);
      //   console.log(servicesInfo);

      //   console.log(currentconfig);

      const initialConfigRoles = roles.reduce((acc, roleId) => {
        acc[roleId] = {};
        Object.keys(servicesInfo).forEach((serviceId) => {
          acc[roleId][serviceId] = { permisos: [] };
        });
        return acc;
      }, {});

      setConfig(initialConfigRoles);
    }
  }, [roles, servicesInfo]);

  //   useEffect(() => {
  //     const initializeRolesPermissions = async (FormInitialConfig) => {
  //       const promises = [];

  //       // Itera sobre cada rol
  //       Object.entries(FormInitialConfig).forEach(([role, permissions]) => {
  //         const roleDocRef = doc(
  //           db,
  //           process.env.REACT_APP_COL_ROLES_PERMISOS,
  //           role
  //         );

  //         // Aquí, 'permissions' ya contiene la estructura correcta que queremos en Firestore
  //         promises.push(setDoc(roleDocRef, permissions, { merge: true }));
  //       });

  //       // Espera a que todas las promesas se resuelvan
  //       try {
  //         await Promise.all(promises);
  //         console.log(
  //           "Todos los roles y permisos han sido inicializados correctamente."
  //         );
  //       } catch (error) {
  //         console.error("Error al inicializar los roles y permisos:", error);
  //       }
  //     };

  //     if (FormInitialConfig && Object.keys(FormInitialConfig).length > 0) {
  //       initializeRolesPermissions(FormInitialConfig);
  //     }
  //   }, [FormInitialConfig]);

  const updatePermission = (roleId, serviceId, permission, isChecked) => {
    setConfig((prevConfig) => {
      // Clona el objeto prevConfig para no modificar el estado directamente
      const updatedConfig = { ...prevConfig };

      // Asegúrate de que el rol y el servicio están inicializados correctamente
      if (!updatedConfig[roleId]) {
        updatedConfig[roleId] = {};
      }

      // Clona el array de permisos o inicialízalo si no existe
      const updatedPermissions =
        updatedConfig[roleId][serviceId] &&
        updatedConfig[roleId][serviceId].permission
          ? [...updatedConfig[roleId][serviceId].permission]
          : [];

      // Actualiza el array de permisos basándote en isChecked
      if (isChecked) {
        // Solo agrega el permiso si aún no está presente
        if (!updatedPermissions.includes(permission)) {
          updatedPermissions.push(permission);
        }
      } else {
        // Crea un nuevo array sin el permiso especificado
        const index = updatedPermissions.indexOf(permission);
        if (index > -1) {
          updatedPermissions.splice(index, 1);
        }
      }

      // Asigna el nuevo array de permisos al servicio actualizado
      const updatedService = {
        ...updatedConfig[roleId][serviceId],
        permission: updatedPermissions,
      };
      updatedConfig[roleId][serviceId] = updatedService;

      // Retorna el objeto de configuración actualizado para actualizar el estado
      return updatedConfig;
    });

    // Llama a la función que actualiza la base de datos
    updateRolePermissions(roleId, serviceId, permission, isChecked);
  };

  const updateRolePermissions = async (
    roleId,
    serviceId,
    permission,
    isChecked
  ) => {
    const roleDocRef = doc(
      db,
      process.env.REACT_APP_COL_ROLES_PERMISOS,
      roleId
    );

    try {
      const docSnap = await getDoc(roleDocRef);

      // Estructura base para un servicio si no existe previamente.
      const defaultServiceData = {
        permission: [],
        active: "default",
      };

      let roleData = docSnap.exists() ? docSnap.data() : {};
      // Si el servicio no existe en el documento, inicialízalo con la estructura base.
      roleData[serviceId] = roleData[serviceId] || { ...defaultServiceData };

      // Actualiza los permisos según la acción del switch.
      if (isChecked) {
        if (!roleData[serviceId].permission.includes(permission)) {
          roleData[serviceId].permission.push(permission);
        }
      } else {
        roleData[serviceId].permission = roleData[serviceId].permission.filter(
          (perm) => perm !== permission
        );
      }

      // Guarda los cambios en la base de datos.
      await setDoc(roleDocRef, roleData, { merge: true });
    } catch (error) {
      console.error("Error updating permissions: ", error);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#f8f8f8", padding: 2 }}>
        {roles.map((item) => (
          <Card key={item.id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {item.id}
              </Typography>
              {servicesInfo.map((service) => (
                <Grid container key={service.id} spacing={2} sx={{ margin: 1 }}>
                  <Grid item xs={4}>
                    <Typography variant="h6">{service.name}</Typography>
                    <Typography variant="body2">
                      {service.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormGroup row>
                      {defaultPermissions.map((permission) => {
                        let roleConfig = currentconfig.find(
                          (config) => config.id === item.id
                        );
                        // Determina si el permiso está activo para el servicio actual en la configuración del rol.
                        const isPermissionActive =
                          roleConfig &&
                          roleConfig[service.id] &&
                          roleConfig[service.id].permissions &&
                          roleConfig[service.id].permission.includes(
                            permission
                          );

                        return (
                          <FormControlLabel
                            key={`${item.id}-${service.id}-${permission}`}
                            control={
                              <Switch
                                checked={isPermissionActive || false}
                                onChange={(e) =>
                                  updatePermission(
                                    item.id,
                                    service.id,
                                    permission,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={permission}
                          />
                        );
                      })}
                    </FormGroup>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};
