import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function useGetRoutesExpressById(companyId, userRoll, routeID) {
  const [bicciRoutesExpressByID, setBicciRoutesExpressByID] = useState(null);
  const [loadRoutesExpressByID, setLoadRoutesExpressByID] = useState(false);
  const [errorRoutesExpressByID, setErrorRoutesExpressByID] = useState(null);

  useEffect(() => {
    fetchData(companyId);
  }, [companyId, routeID]);

  const fetchData = async (companyId) => {
    try {
      setLoadRoutesExpressByID(true);
      if (userRoll === process.env.REACT_APP_ADMIN) {
        if (companyId) {
          const allRoutesRef = doc(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`,
            routeID
          );
          const allRoutes = await getDoc(allRoutesRef);
          const dataRoute = allRoutes.data();
          //// console.log(allRoutes.data())
          setBicciRoutesExpressByID(dataRoute);
        } else {
          const allRoutesRef = doc(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`,
            routeID
          );
          const allRoutes = await getDoc(allRoutesRef);
          const dataRoute = allRoutes.data();
          //// console.log(allRoutes.data())
          setBicciRoutesExpressByID(dataRoute);
        }
      } else {
        if (companyId) {
          const allRoutesRef = doc(
            db,
            `${process.env.REACT_APP_COL_ROUTES}`,
            routeID
          );
          const allRoutes = await getDoc(allRoutesRef);
          const dataRoute = allRoutes.data();
          // console.log(allRoutes.data())
          setBicciRoutesExpressByID(dataRoute);
        } else {
          setBicciRoutesExpressByID(null);
        }
      }

      setLoadRoutesExpressByID(false);
    } catch (error) {
      // console.log(error)
      setErrorRoutesExpressByID(true);
      setBicciRoutesExpressByID(null);
    }
  };
  return {
    bicciRoutesExpressByID,
    loadRoutesExpressByID,
    errorRoutesExpressByID,
  };
}

export default useGetRoutesExpressById;
