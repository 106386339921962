import React from "react";
import { Form, Input, Container } from "semantic-ui-react";
import { registrarComentarios } from "../../../redux/accionesCRM";
import { useDispatch, useSelector } from "react-redux";

const FrmSoporte = () => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);
  const usuarioActual = useSelector((store) => store.usuario);
  const [success, setSuccess] = React.useState(null);
  const mensjaEnviado = useSelector((store) => store.mensajes.mjsstatus);
  const [username, setName] = React.useState();
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState(usuarioActual.user.email);
  const [phone, setPhone] = React.useState("+56");
  const [userPreferenCont, setUserPreferenCont] = React.useState("email");
  const [textComents, setTextComents] = React.useState("");
  const [error, setError] = React.useState(null);
  const [userType, setUserType] = React.useState("empresa");
  const [checked, setChecked] = React.useState(false);
  const registrarComentario = (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!companyName.trim()) {
      setError("El campo nombre de **Empresa** es obligatorio");
      return;
    }
    if (!email.trim()) {
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!textComents.trim()) {
      setError("Ingrese Mensaje");
      return;
    }
    if (textComents.length < 8) {
      setError("Mensaje es muy corto");
      return;
    }
    if (!userPreferenCont.trim()) {
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (!resperror === null) {
      setError("Error de acceso");
      return;
    }
    setError(null);
    cometar();
  };

  const handleChange = () => {
    setChecked(!checked);
    //   console.log (checked)
  };
  if (checked === true) {
    //    console.log ('Acepta terminos y condiciones')
  }

  const cometar = React.useCallback(async () => {
    //console.log('Esta Comentando')
    try {
      const userData = {
        userName: username,
        userCompany: companyName,
        userType: userType,
        email: email,
        phone: phone,
        mensaje: textComents,
        userPreferenCont: userPreferenCont,
        checked: checked,
      };
      dispatch(registrarComentarios(userData));
      setSuccess("Gracias. Mensaje enviado...");
    } catch (error) {
      //console.log(error)
    }
  });

  return (
    <Container className="formcontent" id="">
      <hr></hr>

      <Form onSubmit={registrarComentario}>
        {mensjaEnviado ? (
          <div className="alert alert-success">{success}</div>
        ) : null}
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {resperror ? (
          <div className="alert alert-danger">{resperror}</div>
        ) : null}
        <Form.Group widths="equal">
          <Form.Field></Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <h3>{email}</h3>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            // label='Nombre'
            placeholder="Nombre de contacto"
            iconPosition="left"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={username}
            icon="user"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            placeholder="Teléfono"
            iconPosition="left"
            icon="phone"
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            placeholder="Compañia"
            type="text"
            onChange={(e) => setCompanyName(e.target.value)} // Cambio
            value={companyName}
          />
        </Form.Group>
        <Form.Field
          label="Preferencia de contacto"
          control="select"
          onChange={(e) => setUserPreferenCont(e.target.value)}
        >
          <option value="telefono">Teléfono</option>
          <option value="email">Email</option>
          <option value="presencial">Presencial</option>
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field
            label="Tipo de usuario"
            control="select"
            onChange={(e) => setUserType(e.target.value)}
          >
            <option value="empresa">Empresa</option>
            <option value="repartidor">Repartidor</option>
            <option value="integracion">Integracion</option>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Mensaje"
            placeholder="Como podemos ayudarte?"
            onChange={(e) => setTextComents(e.target.value)}
            value={textComents}
          />
        </Form.Group>

        <hr></hr>
        <button className="btn btn-ebiex " type="sutmit">
          Enviar
        </button>
      </Form>
      <hr></hr>
    </Container>
  );
};

export default FrmSoporte;
