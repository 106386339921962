import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import { Timestamp, getDoc } from "firebase/firestore";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { Container } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, CardHeader, LinearProgress, Box } from "@mui/material";
import { Grid } from "semantic-ui-react";
import { format } from "date-fns";
import { db } from "../../../firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { PDFViewer } from "@react-pdf/renderer";
import Moment from "moment";
import CierreOpsPdf from "./CierreOpsPdf";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import useGlobalConfig from "../../../hooks/globalConfig";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import * as XLSX from "xlsx";
import ToolDashResumenOps from "../../../components/Dashboard/dashboard/ToolDashResumenOps/ToolDashResumenOps";
import ToolDashOrdersContainer from "../../../components/Dashboard/dashboard/ToolDashOrdersContainer/ToolDashOrdersContainer";
import ModalAccionsDispacthDirectionsResolve from "../../../components/ModalAccionsDispacth/ModalAccionsDispacthDirectionsResolve";
import ToolDashAppBar from "../../../components/Dashboard/dashboard/ToolDashAppBar/ToolDashAppBar";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import ToolDashOrdersContainerFGeosrot from "../../../components/Dashboard/dashboard/ToolDashOrdersContainerFGeosrot/ToolDashOrdersContainerFGeosort";
import UserListViewOrders from "../../../components/UserListViewOrders/UserListViewOrders";
import DashIconsResumenDispachsMinimal from "../../../components/Dashboard/DashIconsResumenDispachs/DashIconsResumenDispachsMinimal";
import FadeInOut from "../../../components/animations/FadeInOut";
import UserListViewOrdersIntFalabella from "../../../components/UserListViewOrdersIntFalabella/UserListViewOrdersIntFalabella";
import generarReporteExcepciones from "../../../utils/Reports/generarReporteExcepciones";
import generarReporteDiscrepanciasPiking from "../../../utils/Reports/generarReporteDiscrepanciasPiking";
import ToolDashAppBarIntFalabellaGeosort from "../../../components/Dashboard/dashboard/ToolDashAppBarIntFalabellaGeosort/ToolDashAppBarIntFalabellaGeosort";
import { updateOrdersBicciexpressEstatus } from "../../../redux/accionesOrders";
import { UserContext } from "../../../context/CurrentUserProvider";

// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
// const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

var ordersForAccions = [];
var dataOrdersForAccions = [];
var listadeItemsNoMach = [];
var popUpAccion = "-";

const GuiasFalabellaGeosort = (props) => {
  const { userDataContext } = useContext(UserContext);

  const dispacth = useDispatch();
  const {
    rosettaVehicles,
    translateStateBicciFalabella,
    styleStateBicciFalabella,
    falabellaExcepcions,
    retiredTestsArray,
  } = useGlobalConfig();

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const { widthHeight, movilActive } = useContext(ScreenSizeContext);

  const userUid = userDataContext.uid;

  const companyId = userDataContext.companyID;

  const VehiclesDataByCompany = ["bicycle"];

  const [pendingGesort, setPendingGesort] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [userOrders, setUserOrders] = useState(null);

  const [dataOrdersFilter, setDataOrdersFilter] = React.useState(null);

  const [companyData, sertCompanyData] = React.useState(null);

  const [progressValue, setProgressValue] = useState(0);

  const [prefix, setPrefix] = useState("");

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);

  const [menssajeAdmin, setMenssajeAdmin] = useState(null);

  const [alertMensajes, setAlertMensajes] = useState(null);

  const [initDate, setInitDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [staticDateBillingFilter, setStaticDateBillingFilter] = useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [render, setRender] = useState(true);

  const [realTimeisActive, setRealTimeisActive] = useState(false);

  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [showPopUp, setShowPopUp] = useState(false);
  const [selected, setSelected] = useState(null);
  const [origenGeoCode, setOrigenGeoCode] = useState(null);
  const [adressOrigin, setAdressOrigin] = useState(null);
  const [listIdstoSelct, setListIdstoSelct] = useState("");
  const [SelecteDestiny, setSelecteDestiny] = useState(null);
  const [destinyGeoCode, setDestinyGeoCode] = useState(null);
  const [adressDestiny, setAdressDestiny] = useState(null);
  const [CheckedChangeOE, setCheckedChangeOE] = useState(false);
  const [ordersHistoryGetProgres, setOrdersHistoryGetProgres] = useState(0);
  const [typeRequets, setTypeRequets] = useState({
    typeRequetsCreate: true,
    typeRequetsUpdate: false,
  });

  const [itemsOrdersSelected, setItems] = useState([]);

  const [resetCicle, setResetCicle] = useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);

  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );

  const [progress, setProgres] = useState([]);

  const [bicciOrdersExcepData, setBicciOrdersExcepData] = useState([]);

  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);

  const [wiewPrePdf, setWiewPrePdf] = useState(false);

  const [wiewAssignmentIndIcon, setWiewAssignment] = useState(true);

  const [Buttondisable, setButtondisable] = useState(true);

  const [dataType, setDataType] = React.useState("request");

  const [documentData, setDocumentData] = React.useState(null);

  const [typeRquest, setTypeRquest] = useState(false);

  const isMounted = useRef(true);

  const listItems = [
    { id: "Creadas" },
    { id: "Ruta" },
    { id: "Incidencias" },
    { id: "Finalizadas" },
    { id: "Total" },
  ];

  const [cantListItems, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispachtGeosrt(companyId, initDate, endDate);
      findOrdesrDispachtGeosrtPendint(companyId);
    }
  }, [companyId, initDate, endDate]);

  useEffect(() => {}, [itemsDataOrdersForAccions]);

  useEffect(() => {
    if (userDataContext.companyData) {
      setPrefix(userDataContext.companyData.prefix);
      sertCompanyData(userDataContext.companyData);
    }
  }, [userDataContext.companyData]);

  useEffect(() => {
    isMounted.current = true;

    setMenssajeAdmin(
      <span className="alert alert-info">Buscando Ordenes... </span>
    );
    findOrdesrDispachtGeosrt(companyId, initDate, endDate);
    findOrdesrDispachtGeosrtPendint();
    return () => {
      isMounted.current = false;
    };
  }, [initDate, endDate, CheckedChangeOE, typeRequets]);

  useEffect(() => {
    // //console.log(dataMerge);
    if (dataMerge) {
      setMenssajeAdmin(
        <span className="alert alert-info">Buscando Ordenes... </span>
      );
    }
    getResumenOps();
  }, [dataMerge]);

  useEffect(() => {
    if (listIdstoSelct) {
      selecListItames(listIdstoSelct);
    }
  }, [listIdstoSelct]);

  useEffect(() => {}, [render]);

  const findOrdesrDispachtGeosrt = async (companyId, initDate, endDate) => {
    // console.log("BUSCANDO");
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(59);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("dataOrigin", "==", "GSDATA"),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.id]) {
              uniqueObjects[obj.id] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
          console.log(filteredArray);
          setDataOrdersFilter(filteredArray);
          setDataMerge(filteredArray);
          setResumenTable(filteredArray);
          getResumenOps();
        } catch (error) {
          //console.log("error 345");
          console.error(error);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("dataOrigin", "==", "GSDATA"),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log(ordersPropietary);
          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.id]) {
              uniqueObjects[obj.id] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
          console.log(filteredArray);
          setDataOrdersFilter(filteredArray);
          setDataMerge(filteredArray);
          setResumenTable(filteredArray);
          getResumenOps();
        } catch (error) {
          //console.log("error 346");
          ////console.log(error);
        }
      }
      // setDataType("request");
    } catch (error) {
      ////console.log(error);
    }
  };
  const findOrdesrDispachtGeosrtPendint = async (companyId) => {
    // console.log("BUSCANDO PENDIENTES");
    try {
      async function callExceptions() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("dataOrigin", "==", "GSDATA"),
            where("int_falabella_status", "!=", "Terminado")
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.orderID]) {
              uniqueObjects[obj.orderID] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setPendingGesort(filteredArray);
        } catch (error) {
          //console.log("error 345");
          console.error(error);
        }
      }

      callExceptions();
    } catch (error) {
      ////console.log(error);
    }
  };

  const HandlePendints = () => {
    setDataOrdersFilter(pendingGesort);
    setDataMerge(pendingGesort);
    setResumenTable(pendingGesort);
    getResumenOps();
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  function generateUniqueDriverID(driverName) {
    // Convertir el nombre del conductor en una cadena codificada en base64
    const base64Name = btoa(unescape(encodeURIComponent(driverName)));
    return `tempID_${base64Name}`;
  }

  const getResumenOps = async () => {
    // //console.log("getResumenOps");

    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación...</span>
    );

    const driverOrdersMap = {};
    const storeOrdersMap = {};

    function processOrder(order) {
      // Si el driver no tiene ID, generarlo
      if (!order.driverID || order.driverID === "isRequire") {
        order.driverID = generateUniqueDriverID(order.driverName);
      }

      // Procesar orden por conductor
      if (!driverOrdersMap[order.driverID]) {
        driverOrdersMap[order.driverID] = {
          driverName: order.driverName,
          email: order.email,
          driverAvatar: order.driverAvatar,
          asignados: 0,
          delivery: 0,
          issures: 0,
          locality: [],
          countersListOrders: [],
          countersIssuresListOrders: [],
          vehicleDetailsType: "No resgistrado",
          vehicleDetailsID: "No resgistrado",
        };
      }
      const driverData = driverOrdersMap[order.driverID];
      driverData.asignados++;
      driverData.countersListOrders.push(order.id);

      if (order.int_falabella_status === "Terminado") {
        driverData.delivery++;
      } else {
        driverData.issures++;
        driverData.countersIssuresListOrders.push(order.id);
      }

      // Localidad
      if (!driverData.locality.some((loc) => loc[order.commune2])) {
        driverData.locality.push({ [order.commune2]: 1 });
      } else {
        driverData.locality.find((loc) => loc[order.commune2])[
          order.commune2
        ]++;
      }

      // Procesar orden por tienda
      const storeName = order.int_warehouseName;
      if (!storeOrdersMap[storeName]) {
        storeOrdersMap[storeName] = {
          delivered: 0,
          withIssues: 0,
          localName: storeName,
        };
      }
      const storeData = storeOrdersMap[storeName];
      if (order.status === "Pendiente") {
        storeData.withIssues++;
      } else {
        storeData.delivered++;
      }
    }

    try {
      if (dataMerge && dataMerge.length > 0) {
        dataMerge.forEach(processOrder);

        // Actualizar details de vehículos
        VehiclesDataByCompany.forEach((vehicleData) => {
          const driverData = driverOrdersMap[vehicleData.driverAssing];
          if (driverData) {
            driverData.vehicleDetailsType = vehicleData.vehiclesTypes;
            driverData.vehicleDetailsID = vehicleData.id;
          }
        });

        // //console.log(storeOrdersMap);
        // //console.log(driverOrdersMap);

        const driversArray = Object.values(driverOrdersMap);
        const storesArray = Object.values(storeOrdersMap);

        setRidersDataByCompany(driversArray);
        setMetricsOps(driversArray);
        setMetricsOpsStores(storesArray);
        setProgressValue(100);
        setMenssajeAdmin(null);
      } else {
        setProgressValue(100);
        setMetricsOps([]);
        setMetricsOpsStores([]);
        setMenssajeAdmin(
          <span className="alert alert-danger">
            "NO se encuentran envíos para la fecha Solicitada"
          </span>
        );
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const onChageRange = (e) => {
    setProgressValue(20);
    if (e.target.name === "rangoInicio") {
      setInitDate(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDate(e.target.value);
    }
    if (e.target.name === "staticDateBillingFilter") {
      setInitDate(e.target.value);
      setEndDate(e.target.value);
      setStaticDateBillingFilter(e.target.value);
    }
  };

  const setResumenTable = (dataOrdes) => {
    if (dataOrdes && dataOrdes.length === 0) {
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
    } else {
      setMenssajeAdmin(null);
    }

    let Creadas = dataOrdes.filter(
      (item) =>
        item.int_falabella_status === "1058" ||
        item.int_falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.int_falabella_status === "Terminado"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.int_falabella_status === "1060" ||
        item.int_falabella_status === "1061" ||
        item.int_falabella_status === "En ruta"
    );
    //cantListItems.Ruta = Ruta.length

    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.int_falabella_status)
    );

    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const handleOrdersForAccions = (e) => {
    var fIF = "F" + e.currentTarget.id;
    if (e.currentTarget.checked) {
      ordersForAccions.push(fIF);
      const order = dataMerge.filter(
        (item) => item.id.toString() === e.currentTarget.id
      );

      dataOrdersForAccions.push(order[0]);
    }

    if (!e.currentTarget.checked) {
      let newOrders = ordersForAccions.filter(
        (item) => item !== "F" + e.currentTarget.id
      ); //push(e.target.id)
      ordersForAccions = newOrders;
    }

    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const UpdateOrderForAcctions = (items) => {
    //console.log(items);
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === "F" + tempuserOrders[n].id) {
          //  //console.log("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
    // //console.log(routesDataOrdersForAccions);
    // //console.log(routesOrdersForAccions);
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  async function selecListItames(listIdstoSelct) {
    listadeItemsNoMach = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    listIdstoSelct = listIdstoSelct.trim();
    var listadeItems2 = listIdstoSelct.split(/[,\s]+/);

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems2.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems2[i] === "F" + tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems2.length; index++) {
      if (!ordersForAccions.includes(listadeItems2[index])) {
        listadeItemsNoMach.push(listadeItems2[index]);
      }
    }
    setUserOrders(tempuserOrders);
    //setDataMerge(tempuserOrders);
    setRender(!render);
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  }

  const handleUpdateItems = (newItems) => {
    setItems(newItems);
  };

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
  };
  const handleClickSelectRiderPendintList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          if (
            tempuserOrders[n].falabella_status === "1060" ||
            tempuserOrders[n].falabella_status === "1061"
          ) {
            console.log(tempuserOrders[n]);
            tempuserOrders[n].setectedItem = true;
            ordersForAccions.push("F" + tempuserOrders[n].id);
            dataOrdersForAccions.push(tempuserOrders[n]);
          }
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
    setUserOrders(tempuserOrders);
    setDataMerge(tempuserOrders);
    setRender(!render);
  };
  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    ordersForAccions = [];
    dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push("F" + tempuserOrders[n].id);
        }
      }
    }

    let copyData = copyOrdersForAccions;
    //console.log(copyData);
    navigator.clipboard.writeText(copyData);
    setRender(!render);
  };

  const generarReporteExcepcionesPendientes = (data) => {
    generarReporteExcepciones(data, ridersDataByCompany, initDate);
  };

  const handleCopySelectRiderCurrentListErrors = (e, item) => {
    e.preventDefault();
    navigator.clipboard.writeText(item);
  };

  const handleChangeOE = () => {
    setCheckedChangeOE(!CheckedChangeOE);
  };

  const handleChangetypeRequets = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "typeRequetsCreate" && checked) {
      setTypeRequets({ typeRequetsCreate: true, typeRequetsUpdate: false });
    } else {
      setTypeRequets({ typeRequetsCreate: false, typeRequetsUpdate: true });
    }
  };

  const generarReporteTurno = (data) => {
    generarReporteTurnoCierrediario(data, ridersDataByCompany, initDate);
  };

  const generarReporteDiscrepancias = (data) => {
    generarReporteDiscrepanciasPiking(data);
  };

  const generarReporteEstados = (dataOrdersForAccions) => {
    //console.log(dataOrdersForAccions);
    const matrizReporte = [
      [
        "Suborden",
        "Creado",
        "Estatus",
        "Descripción",
        "Última Actualización",
        "Resumen de entrega",
      ],
      ...dataOrdersForAccions.map((dato) => [
        dato.id,
        dato.int_travelDate,
        dato.int_falabella_status,
        dato.int_falabella_status_descriptor,
        dato.deliveryRealDeliveryDate,
        `${dato.deliveryRealDeliveryDate},  ${dato.deliveryReceptor}, ${dato.deliveryRut}`,
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `ESTADOS ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const generarReporteEvidencias = (dataOrdersForAccions) => {
    const matrizReporte = [
      [
        "SOC",
        "Fecha de envío",
        "Zonas",
        "Ultimo Registro",
        "Estatus",
        "Detalle",
        "Conductor",
        "Fotos",
        "Fotos",
        "Fotos",
        "Fotos",
      ],
      ...dataOrdersForAccions.map((dato) => [
        dato.soc,
        dato.dateCreateText,
        dato.commune2,
        format(dato.updatedAt.seconds * 1000, "dd/MM/yy hh:mm:ss"),
        dato.falabella_status,
        translateStateBicciFalabella[dato.falabella_status],
        dato.driverName,
        dato.pics[0],
        dato.pics[1],
        dato.pics[2],
        dato.pics[3],
        dato.pics[4],
        dato.pics[5],
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `Evidencias entregas ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  const reporteSegmentadoPorRutas = () => {
    function formatDate(dateSeconds) {
      try {
        return format(dateSeconds * 1000, "dd/MM/yy hh:mm:ss").toString();
      } catch (error) {
        console.error("Error al formatear la fecha:", error);
        return null;
      }
    }

    dataMerge.forEach((objeto) => {
      let fecha = objeto["deliveryDate"].replace(/\//g, ""); // Asumiendo que deliveryDate está en el formato adecuado
      let ruta_id = objeto["routeId"];
      let id_unico = fecha + "_" + ruta_id;
      objeto["idUnicoPorDiaYRuta"] = id_unico;
      objeto["newDate"] = objeto["deliveryRealDeliveryDate"]
        ? objeto["deliveryRealDeliveryDate"]
        : "";
      objeto["fechaPactada"] = objeto["deliveryDate"].replace(/\//g, "");
    });

    const matrizReporte = [
      [
        "Identiificador de Ruta",
        "driverLicensePlate",
        "Suborden",
        "Creado",
        "Estatus",
        "Conductor",
        "Descripción",
        "Última Actualización",
        "Resumen de entrega",
        "Tipo de sevicio",
        "Proforma",
      ],
      ...dataMerge.map((dato) => [
        dato.idUnicoPorDiaYRuta,
        dato.driverLicensePlate,
        dato.id,
        dato.int_travelDate,
        dato.int_falabella_status,
        dato.driverName,
        dato.int_falabella_status_descriptor,
        dato.fechaPactada,
        `${dato.newDate},  ${dato.deliveryReceptor}, ${dato.deliveryRut}`,
        dato.int_serviceType,
        dato.billingPaymentProforma,
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `REPORTE DE RUTAS GEOSORT ${Moment(Date.now()).format(
        "YYYY-MM-DD  HH:mm:ss"
      )}.xlsx`
    );
  };

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <ToolDashAppBarIntFalabellaGeosort
              movil={widthHeight.width < 950 ? true : false}
              cantDataOrdersFilter={
                dataOrdersFilter ? dataOrdersFilter.length : 0
              }
              Buttondisable={Buttondisable}
              setButtondisable={setButtondisable}
              CheckedChangeOE={CheckedChangeOE}
              typeRequets={typeRequets}
              dataOrdersForAccions={dataOrdersForAccions}
              setWiewPrePdf={setWiewPrePdf}
              wiewPrePdf={wiewPrePdf}
              listadeItemsNoMach={listadeItemsNoMach}
              companyId={companyId}
              onChageRange={onChageRange}
              staticDateBillingFilter={staticDateBillingFilter}
              dataCurrentMohstDelivery={[]}
              documentData={documentData}
              setDocumentData={setDocumentData}
              dataType={dataType}
              setDataType={setDataType}
              render={render}
              setRender={setRender}
              dataOrdersFilter={dataOrdersFilter}
              onChageRangePiker={onChageRangePiker}
              usuarioStorage={props.usuarioStorage}
              companyData={companyData}
              setAlertMensajes={setAlertMensajes}
              setSelected={setSelected}
              setOrigenGeoCode={setOrigenGeoCode}
              setAdressOrigin={setAdressOrigin}
              adressOrigin={adressOrigin}
              setSelecteDestiny={setSelecteDestiny}
              setDestinyGeoCode={setDestinyGeoCode}
              setAdressDestiny={setAdressDestiny}
              adressDestiny={adressDestiny}
              isShowPopUp={isShowPopUp}
              handleChangeOE={handleChangeOE}
              handleChangetypeRequets={handleChangetypeRequets}
              dataMerge={dataMerge}
              generarReporteEstados={generarReporteEstados}
              generarReporteEvidencias={generarReporteEvidencias}
              generarReporteTurno={generarReporteTurno}
              generarReporteDiscrepancias={generarReporteDiscrepancias}
              generarReporteExcepcionesPendientes={
                generarReporteExcepcionesPendientes
              }
              initDate={initDate}
              endDate={endDate}
              setListIdstoSelct={setListIdstoSelct}
              realTimeisActive={realTimeisActive}
              setRealTimeisActive={setRealTimeisActive}
              listItems={listItems}
              cantListItems={cantListItems}
              handleCopySelectRiderCurrentListErrors={
                handleCopySelectRiderCurrentListErrors
              }
              setWiewAssignment={setWiewAssignment}
              wiewAssignmentIndIcon={wiewAssignmentIndIcon}
              HandlePendints={HandlePendints}
              reporteSegmentadoPorRutas={reporteSegmentadoPorRutas}
              userDataContext={userDataContext}
              prefix={prefix}
            ></ToolDashAppBarIntFalabellaGeosort>
            <Divider />

            <Container className="formcontent" id="micuentafromIni">
              <Grid.Row>
                <Grid.Column
                  computer={8}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun "
                >
                  <FadeInOut show={wiewAssignmentIndIcon} duration={500}>
                    <ToolDashResumenOps
                      movil={widthHeight.width < 950 ? true : false}
                      metricsOps={metricsOps}
                      handleClickSelectRiderCurrentList={
                        handleClickSelectRiderCurrentList
                      }
                      handleCopySelectRiderCurrentList={
                        handleCopySelectRiderCurrentList
                      }
                      handleClickSelectRiderPendintList={
                        handleClickSelectRiderPendintList
                      }
                      rosettaVehicles={rosettaVehicles}
                    ></ToolDashResumenOps>
                  </FadeInOut>
                  <div>
                    {" "}
                    {wiewPrePdf && metricsOps && metricsOpsStores ? (
                      <PDFViewer style={{ width: "100%", height: "90vh" }}>
                        <CierreOpsPdf
                          items={listItems}
                          cant={cantListItems}
                          metricsOps={metricsOps}
                          metricsOpsStores={metricsOpsStores}
                          rosettaVehicles={rosettaVehicles}
                          userUid={userUid}
                          initDate={initDate}
                          endDate={endDate}
                          typeReport={"geoSort"}
                        ></CierreOpsPdf>
                      </PDFViewer>
                    ) : null}
                  </div>

                  <div className="m-2">
                    {menssajeAdmin ? (
                      <>{menssajeAdmin}</>
                    ) : (
                      <>
                        {progressValue !== 100 ? (
                          <LinearProgress></LinearProgress>
                        ) : null}
                      </>
                    )}
                  </div>
                  {!dataMerge ? <div>no resultados de busqueda</div> : null}
                  {dataMerge && dataOrdersFilter && widthHeight.width > 950 ? (
                    <UserListViewOrders
                      dataOrdesRequets={dataMerge}
                      visibleBilling={false}
                      ordersForAccions={routesOrdersForAccions}
                      UpdateOrderForAcctions={UpdateOrderForAcctions}
                    ></UserListViewOrders>
                  ) : null}
                </Grid.Column>

                <Grid.Column
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="dash-container-colmun"
                ></Grid.Column>
              </Grid.Row>
            </Container>

            <Divider />
          </Card>

          <div></div>
          {showPopUp ? (
            <ModalAccionsDispacthDirectionsResolve
              opened={showPopUp}
              popUpAccion={popUpAccion}
              interfaceOrigen={"liveOrders"}
              ordersForAccions={ordersForAccions}
              dataOrdersForAccions={dataOrdersForAccions}
              companyId={companyId}
              ridersDataByCompany={ridersDataByCompany}
              adressOrigin={adressOrigin}
              origenGeoCode={origenGeoCode}
              adressDestiny={adressDestiny}
              destinyGeoCode={destinyGeoCode}
            >
              {" "}
            </ModalAccionsDispacthDirectionsResolve>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GuiasFalabellaGeosort;
