import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import Moment from "moment";
import { db } from "../../../firebase";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { pdf } from "@react-pdf/renderer";
import CreateManiFestByCurrentListPdf from "../../../components/CreateManiFestByCurrentListPdf/CreateManiFestByCurrentListPdf";
import FleetResumenTable from "../Fleet/FleetResumenTable";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker";
import EventIcon from "@mui/icons-material/Event";
import { fDate } from "../../../utils/objects/FormatTime";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys";
import { CustomDateRangePickerStyle } from "../../../components/CustomDateRangePicker/CustomDateRangePickerStyle";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent";

var listadeItemsNoMach = [];
const FleetCompany = (props) => {
  const [rows, setRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);

  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );
  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);
  const [localMetrcis, setLocalMetrics] = React.useState(null);

  //-- Nuevas Variables
  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [messajeAdmin, setMenssajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
    excludePendings,
    setExcludePendings,
  } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {}, [excludePendings]);

  useEffect(() => {
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Asegúrate de que los driverID sean únicos
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];

        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );

          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            // Agregar solo si no está ya presente
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }

        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (viewMergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    console.log(metricsOps);
    if (metricsOps) {
      callMetrics(metricsOps);
    }
  }, [metricsOps]);

  useEffect(() => {
    setShouldSubscribe(true);
  }, []);

  const handleExcludePendings = async (state) => {
    console.log(state);
    setExcludePendings(state);
  };

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      // Extraer ridersDataByCompany sin duplicados y sin driverID vacío o indefinido
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      // Esta función agrupa las órdenes por tienda y calcula métricas
      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [], // Puedes omitir esto si `commune2` no existe
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);
        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        // Omitir lógica de `commune2` si no existe
        if (order.commune2) {
          const comunaIndex = acc[driverID].locality.findIndex(
            (comuna) => Object.keys(comuna)[0] === order.commune2
          );

          if (comunaIndex >= 0) {
            acc[driverID].locality[comunaIndex][order.commune2]++;
          } else {
            const newComuna = { [order.commune2]: 1 };
            acc[driverID].locality.push(newComuna);
          }
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const setViewFilterMergedOrdersOrigins = (selectedOrigin) => {
    applyFilters(selectedCompanies, selectedOrigin);
  };

  const UpdateOrderForAcctions = (items) => {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          //  console.log("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  const memoizedData = useMemo(() => {
    if (viewMergedOrders && metricsOps && ridersDataByCompany) {
      return { dataMerge: viewMergedOrders, metricsOps, ridersDataByCompany };
    }
  }, [viewMergedOrders, metricsOps, ridersDataByCompany]);

  const google = window.google;

  const callMetrics = (data) => {
    var totalRider = 0;
    var totalVehicles = [];
    var totalAssing = 0;
    var totalDelivery = 0;
    var totalPending = 0;
    var ntsPerformanse = 0;
    var totalZones = [];
    var listOfRiders = [];

    // Itera sobre los datos
    for (var i = 0; i < data.length; i++) {
      if (data[i].driverName !== "") {
        totalRider++;
        totalAssing += data[i].asignados;
        totalDelivery += data[i].delivery;
        totalPending += data[i].issures;
        listOfRiders.push(data[i].driverName);
      } else {
        totalPending += data[i].issures;
      }

      totalVehicles.push(data[i].vehicleDetailsType);

      // Asegúrate de que data[i].locality esté definido antes de empujarlo a totalZones
      if (data[i].locality) {
        totalZones.push(data[i].locality);
      }
    }

    ntsPerformanse = ((totalDelivery * 100) / totalAssing).toFixed(2);

    var allVehiclesNames = [];
    var allVehiclesConter = {};

    // Procesa los vehículos
    for (let a = 0; a < totalVehicles.length; a++) {
      if (!allVehiclesNames.includes(totalVehicles[a])) {
        allVehiclesNames.push(totalVehicles[a]);
        allVehiclesConter[totalVehicles[a]] = { cant: 0 };
      }
    }

    for (let a = 0; a < allVehiclesNames.length; a++) {
      for (let q = 0; q < totalVehicles.length; q++) {
        if (allVehiclesNames[a] === totalVehicles[q]) {
          allVehiclesConter[totalVehicles[q]].cant += 1;
        }
      }
    }

    var allZonesNames = [];
    var conterAllZonesGrousp = {};

    // Procesa las zonas
    for (let x = 0; x < totalZones.length; x++) {
      // Asegúrate de que totalZones[x] es un array antes de iterar sobre él
      if (Array.isArray(totalZones[x])) {
        for (let n = 0; n < totalZones[x].length; n++) {
          var tempData = Object.keys(totalZones[x][n]);

          if (!allZonesNames.includes(tempData[0])) {
            allZonesNames.push(tempData[0]);
            let zoneValue = Object.values(totalZones[x][n]);
            conterAllZonesGrousp[Object.keys(totalZones[x][n])] = parseInt(
              zoneValue[0]
            );
          } else {
            if (conterAllZonesGrousp[tempData[0]]) {
              let subTotalZoneValue = conterAllZonesGrousp[tempData[0]];
              let zoneValue = Object.values(totalZones[x][n]);
              conterAllZonesGrousp[tempData[0]] =
                parseInt(subTotalZoneValue) + parseInt(zoneValue[0]);
            }
          }
        }
      }
    }

    let cantTotalVehicles = Object.values(allVehiclesConter);
    let cantTotalZones = allZonesNames.length;

    let tempNames = Object.keys(allVehiclesConter);
    let tempValues = Object.values(allVehiclesConter);
    let tempArry = [];
    for (let y = 0; y < tempNames.length; y++) {
      let obj = { name: tempNames[y], value: tempValues[y] };
      tempArry.push(obj);
    }

    //setTotalArrayVehicles(tempArry);

    let tempNamesComunas = Object.keys(conterAllZonesGrousp);
    let tempValuesComunas = Object.values(conterAllZonesGrousp);
    let tempArrayZones = [];
    for (let y = 0; y < tempNamesComunas.length; y++) {
      let obj = { name: tempNamesComunas[y], value: tempValuesComunas[y] };
      tempArrayZones.push(obj);
    }

    //setTotalArrayComunas(tempArrayZones);

    let objeMetric = {
      totalRider: totalRider,
      totalVehicles: tempArry,
      cantTotalVehicles: cantTotalVehicles.length,
      totalAssing: totalAssing,
      totalDelivery: totalDelivery,
      totalPending: totalPending,
      ntsPerformanse: ntsPerformanse,
      totalZones: conterAllZonesGrousp,
      cantTotalZones: cantTotalZones,
      listOfRiders: listOfRiders,
    };

    let renderData = Object.entries(objeMetric);

    setLocalMetrics(objeMetric);
  };

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    const selectedRowsData = item.countersListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );
    setSelectedData(selectedRowsData);
    setSelectedIDs(item.countersListOrders);
    UpdateOrderForAcctions(item.countersListOrders);
  };

  const handleClickSelectRiderPendintList = (e, item) => {
    e.preventDefault();
    const selectedRowsDataPending = item.countersIssuresListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );

    const pendingIDs = selectedRowsDataPending.map((row) => row.id);
    setSelectedData(selectedRowsDataPending);
    setSelectedIDs(item.countersIssuresListOrders);
    UpdateOrderForAcctions(pendingIDs);
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders.slice();
    var listadeItems = new Set(item.countersListOrders);

    try {
      const tempuserOrdersMap = new Map();
      tempuserOrders.forEach((order) => {
        tempuserOrdersMap.set(order.id, order);
      });

      listadeItems.forEach((id) => {
        const order = tempuserOrdersMap.get(id);
        if (order) {
          copyOrdersForAccions.push(order.id);
        }
      });

      let copyData = copyOrdersForAccions.join("\n"); // Convertir a string con saltos de línea
      navigator.clipboard.writeText(copyData);
    } catch (error) {
      console.log(error);
    }
  };

  const createManiFestByCurrentList = async (e, item) => {
    e.preventDefault();
    console.log("createManiFestByCurrentList init");
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    const blob = await pdf(
      <CreateManiFestByCurrentListPdf orders={copyOrdersForAccions} />
    ).toBlob();

    console.log("createManiFestByCurrentList init");
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  return (
    <>
      <Grid>
        {" "}
        <ToolFiltersComponent
          selectedCompanies={selectedCompanies}
          selectedOrigins={selectedOrigins}
          filteredCompanies={filteredCompanies}
          filteredOrigins={filteredOrigins}
          handleChange={handleChange}
          handleOrigins={handleOrigins}
          rangeInputPicker={rangeInputPicker}
          setNewDate={setNewDate}
          excludePendings={excludePendings}
          handleExcludePendings={handleExcludePendings}
        ></ToolFiltersComponent>
      </Grid>

      {mergedOrders ? (
        <Box sx={{ backgroundColor: "#fff" }}>
          <Typography sx={{ fontSize: "2em", color: "#030303", m: 2 }}>
            Flota
          </Typography>
          <Box
            sx={{
              backgroundColor: "#f8f8f8",
              m: 2,
              p: 2,
              display: "flex",
              borderRadius: "8px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "4em", color: "#591e8f", mr: 2 }}>
              Total: {mergedOrders.length}
            </Typography>
            {localMetrcis ? (
              <Typography sx={{ fontSize: "4em", color: "#3bcddc" }}>
                {!isNaN(localMetrcis.ntsPerformanse) ? (
                  <>{parseInt(localMetrcis.ntsPerformanse).toFixed(1)}%</>
                ) : null}
              </Typography>
            ) : null}
          </Box>
          {/* <h3 className="m-3 progressTextPorcentBig h2-withe">
            {" "}
            Total: {mergedOrders.length}
          </h3>
          <div className="card">
            <div className="card-header">
               <button className="btn-add-guide-small m-2">
                Ver distibucion en Mapa
              </button> 
              {localMetrcis ? (
                <span className="progressTextPorcentBig color-calipso ">
                  {" "}
                  {!isNaN(localMetrcis.ntsPerformanse) ? (
                    <>{parseInt(localMetrcis.ntsPerformanse).toFixed(1)}%</>
                  ) : null}
                </span>
              ) : null}
              <span className="progressTextresalte ">Avance general</span>
            </div>

            <div className="card-body-flex">
              <table className="table-card-fullw-50">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"> Conductores en ruta </th>
                    <th scope="col"> Vehículos </th>
                  </tr>
                </thead>
                {localMetrcis ? (
                  <tbody>
                    <tr>
                      <td></td>
                      <td className="vertical-align-initial">
                        <div className="card-content"></div>
                        <div>
                          {localMetrcis.listOfRiders.map((item, index) => {
                            return (
                              <div key={index} className="progressTextresalte">
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      <td className="vertical-align-initial">
                        {localMetrcis.totalVehicles.map((item, index) => {
                          return (
                            <div key={index} className="progressTextresalte">
                              {item.value.cant} {item.name}
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
              {localMetrcis ? (
                <table className="table-card-fullw-20">
                  <tbody>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item"> Asígnados</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalAssing}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Finalizados</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalDelivery}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Pendientes</div>
                      </td>
                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.totalPending}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Avance</div>
                      </td>
                      <td>
                        {" "}
                        <p className="progressTextresalte">
                          {!isNaN(localMetrcis.ntsPerformanse) ? (
                            <>
                              {parseInt(localMetrcis.ntsPerformanse).toFixed(1)}
                              %
                            </>
                          ) : null}
                        </p>
                      </td>
                    </tr>
                    <tr className="tr-boder-solid">
                      <td>
                        <div className="card-content-item">Comunas</div>
                      </td>

                      <td>
                        <p className="progressTextresalte">
                          {localMetrcis.cantTotalZones}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
            <hr></hr>
          </div> */}
        </Box>
      ) : null}
      {metricsOps ? (
        <div>
          <FleetResumenTable metricsOps={metricsOps}></FleetResumenTable>
        </div>
      ) : (
        <LinearProgress></LinearProgress>
      )}
    </>
  );
};

export default FleetCompany;
