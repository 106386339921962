import React, { useEffect, useState, useMemo } from "react";

export const ScreenSizeContext = React.createContext();

function ScreenSizeProvider(props) {
  const [widthHeight, setWidthHeight] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [movilActive, setMovilActive] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidthHeight({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (window.innerWidth > 900) {
        setMovilActive(false);
      } else {
        setMovilActive(true);
      }
    }

    window.addEventListener("resize", handleResize);

    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /iphone|ipod|ipad|android|iemobile|operamini|blackberry|bb10|windows phone|mobile safari|mobile/.test(
        userAgent
      );
    if (isMobile) {
      setMovilActive(true);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const memoizedContextValue = useMemo(
    () => ({ widthHeight, movilActive }),
    [widthHeight, movilActive]
  );

  return (
    <ScreenSizeContext.Provider value={memoizedContextValue}>
      {props.children}
    </ScreenSizeContext.Provider>
  );
}

export default ScreenSizeProvider;
