import React from "react";
import { Grid, Container } from "semantic-ui-react";
import { Card, CardHeader, CardContent, Box, Typography } from "@mui/material";
import Logo from "../header/logo/Logo";

import { format } from "date-fns";
const Emailmodel1 = (props) => {
  return (
    <div id="containerDetails">
      <div>
        <h2 className="h2-withe">Vista previa email Emailmodel1</h2>
      </div>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div className="contariner-view-email m-2">
              <div>
                Asunto : Tienes un envío desde el origen{" "}
                {props.emailData.placeName} paquete código{" "}
                {props.emailData.data.id}, Solicitamos nuevo número de contacto
                telefónico para completar la entrega.
              </div>
              <hr></hr>
              <Card
                sx={{
                  margin: "20px",
                  backgroundColor: "#F8E8FD",
                }}
                className="card-ultimasOrdenes typeMiniforms"
              >
                <CardHeader
                  title={
                    <div>
                      <div>
                        <Logo></Logo>
                      </div>
                      <h1>¡Hola {props.emailData.data.userName}! </h1>
                    </div>
                  }
                />

                <CardContent>
                  <Box
                    sx={{
                      alignItems: "left",
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "350",
                    }}
                  >
                    <div></div>
                    <Container className="formcontent-email">
                      <div className="emailbodycontainer">
                        <p>
                          Junto con saludarte te queremos informar, que hemos
                          realizado 1 intento de entrega, en la dirección,{" "}
                          {props.emailData.data.toAddress}, de su compra en
                          Falabella.com.
                        </p>
                        <h3>
                          Tienes un envío desde el origen Falabella
                          {"  "} paquete código{"  "}
                          {props.emailData.data.id}, Solicitamos nuevo número de
                          contacto telefónico para completar la entrega.
                        </h3>
                        <div className="emailbodycontainer-mensaje">
                          <p>
                            Número de orden {props.emailData.data.id} nos
                            comunicamos vía telefónica +569
                            {props.emailData.data.userPhone} la cual no se ha
                            logrado completar, Se estimó la entrega para el día{" "}
                            {format(
                              props.emailData.data.createdAt.seconds * 1000,
                              "dd/MM/yy"
                            )}{" "}
                          </p>
                          <p>
                            Enviamos el presente mensaje vía e-mail, con la
                            finalidad de encontrar un nuevo número de contacto
                            telefónico para realizar nuevo intento en la
                            dirección acordada, antes de llevar a cabo la
                            devolución de su compra.
                          </p>
                          <p>
                            Saludos cordiales, quedamos atentos a sus
                            comentarios.
                          </p>
                        </div>
                        <div>
                          <h3>Datos Destino</h3>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Nombre:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data ? (
                              <>{props.emailData.data.userName}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Destino:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data.toAddress}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Email Cliente:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data.userEmail}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Teléfono:
                          </Typography>{" "}
                          +569
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data.userPhone} |
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Localidad:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.commune1}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            SOC:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.soc}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          {props.emailData.data3.length > 0 ? (
                            <>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                F12:
                              </Typography>{" "}
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                {props.emailData.data3[0].F12}
                              </Typography>
                            </>
                          ) : (
                            "Sin F12 Registrado"
                          )}
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Tamaño de Paquete:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.size}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Medidas:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {props.emailData.data2 ? (
                              <>
                                {" "}
                                height: {props.emailData.data2.height}| weight:{" "}
                                {props.emailData.data2.weight} | width:{" "}
                                {props.emailData.data2.width}
                              </>
                            ) : null}
                          </Typography>
                        </div>
                        <div></div>
                      </div>
                    </Container>
                  </Box>
                </CardContent>
              </Card>
              -<hr></hr>
              <div>Botones Acciones = Enviar</div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Emailmodel1;
