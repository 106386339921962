import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import { Avatar, LinearProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { purple, grey, green } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SyncIcon from "@mui/icons-material/Sync";
import { Card } from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useEffect } from "react";
import SwipeableSearcherDrawer from "../../../../pages/Dashboard/Guias/SwipeableSearcherDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import GraficasChartLineDaysMonths from "../../../GraficasChartLineDaysMonths/GraficasChartLineDaysMonths";
import ToolDashUpdatePaymentsByDocAntsMethod from "../ToolDashUpdatePayments/ToolDashUpdatePaymentsByDocAntsMethod";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ToolDashUpdateReportGeosort from "../ToolDashUpdatePayments/ToolDashUpdateReportGeosort";
import generarReporte from "../../../../utils/Reports/generarReporte";
import ToolDashUpdateDocPreGrilla from "../ToolDashUpdatePayments/ToolDashUpdateDocPreGrilla";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../CustomDateRangePicker";
import { fDate } from "../../../../utils/objects/FormatTime";
import EventIcon from "@mui/icons-material/Event";
import ReusableFormImportOrders from "../../../ReusableFormImportOrders/ReusableFormImportOrders";
import ReusableFormImportOrdersByDataSDD from "../../../ReusableFormImportOrdersByDataSDD/ReusableFormImportOrdersByDataSDD";
import ReusableFormImportOrdersPregrillaGeosort from "../../../ReusableFormImportOrdersPregrillaGeosort/ReusableFormImportOrdersPregrillaGeosort";
import ReusableFormImportReportGeosort from "../../../ReusableFormImportReportGeosort/ReusableFormImportReportGeosort";
import { CustomDateRangePickerStyle } from "../../../CustomDateRangePicker/CustomDateRangePickerStyle";

export default function ToolDashAppBarIntFalabellaGeosort(props) {
  const [viewCreateRoutes, setViewCreateRoutes] = React.useState(false);
  const [viewSelectTimes, setViewSelectTimes] = React.useState(true);
  const [viewRegisterIems, setViewRegisterIems] = React.useState(true);
  const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =
    React.useState(false);

  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [listIDs, setListIDs] = React.useState("");

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);

  const [dataSearch, setDataSearch] = React.useState("");

  const [rows, setRows] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const [openImportDataSDD, setOpenImportDataSDD] = React.useState(false);

  const [openImportDataPregrilla, setOpenImportDataPregrilla] =
    React.useState(false);

  const [openImportByDataReport, setOpenImportByDataReport] =
    React.useState(false);

  useEffect(() => {
    if (props.dataOrdersFilter) {
    }
    setDataMergeLocalOrder(props.dataOrdersFilter);
  }, [viewCreateRoutes, props, viewFinFindOrdes, props.dataOrdersFilter]);

  const handleChangeListToSelect = (e) => {
    console.log(e.target.value);
    setListIDs(e.target.value);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dataSearch) {
      filterData(dataSearch);
    }
  }, [dataSearch]);

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      for (let key in obj) {
        const value = obj[key];
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = dataMergeLocalOrder.filter((item) =>
      filterFunction(item, dataSearch)
    );

    const rowsResult = filteredData.map((item) => {
      // Aquí puedes mapear los campos del objeto filtrado a la estructura que necesites
      return createData(
        item["orderID"],
        item["CLIENTENOMBRES"],
        item["CLIENTETELEFONO"],
        item["CLIENTECORREO"],
        item["driverName"],
        item["upDatedAtText"],
        item["address"],
        item["commune1"],
        item["orderID"],
        item["driverAvatar"],
        item["companyId"],
        item["estiatedDate"],
        item["COMUNA"]
      );
    });

    setRows(rowsResult);
  };

  function createData(
    OrderID,
    Cliente,
    ClienteTelefono,
    ClienteEmail,
    Conductor,
    Fecha,
    Adress,
    Zona,
    Detalles,
    driverAvatar
  ) {
    return {
      OrderID,
      Cliente,
      ClienteTelefono,
      ClienteEmail,
      Conductor,
      Fecha,
      Adress,
      Zona,
      Detalles,
      driverAvatar,
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleSelectBtnSearchInterfaz = async (e) => {
    await handleCloseBtnSearchInterfaz();
    switch (e.target.name) {
      case "viewFinFindOrdes":
        setViewFindOrdes(true);
        break;
      default:
        break;
    }
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  const handleCloseBtnSearchInterfaz = async () => {
    setViewFindOrdes(false);
    //setViewSearchOrder(false);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const setViewPendingstGeosort = () => {
    props.HandlePendints();
  };

  const UploadDocumentsByDataSDD = () => {
    console.log("Importador de documentos DataSDD");
    setOpenImportDataSDD(true);
  };

  const UploadDocumentsByDataPregrilla = () => {
    console.log("Importador de documentos DataPregrilla");
    setOpenImportDataPregrilla(true);
  };

  const UploadDocumentsByDataReport = () => {
    console.log("Importador de documentos ByDataReport");
    setOpenImportByDataReport(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "40%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "60%",
            },
          }}
        ></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          borderBlockColor: grey[200],
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewRegisterIems(!viewRegisterIems)}
                sx={{ color: "#591e8f" }}
              >
                <PostAddIcon
                  sx={{ color: viewRegisterIems ? green[500] : "#591e8f" }}
                />
              </IconButton>
            </div>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewSelectTimes(!viewSelectTimes)}
                sx={{ color: "#591e8f" }}
              >
                <CalendarMonthIcon
                  sx={{ color: viewSelectTimes ? green[500] : "#591e8f" }}
                />
              </IconButton>
            </div>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuReport}
                sx={{ color: "#591e8f" }}
              >
                <SaveAltIcon sx={{ color: "#591e8f" }} />
              </IconButton>
              Reportes
              <Menu
                id="menu-appbar"
                anchorEl={anchorElReport}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElReport)}
                onClose={handleCloseMenuReport}
              >
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() => generarReporte(props.dataOrdersFilter)}
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Pagos Riders
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteTurno(props.dataOrdersFilter)
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Cierre Turno Salida directas
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length &&
                  props.dataOrdersForAccions &&
                  props.dataOrdersForAccions.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteEstados(
                            props.dataOrdersForAccions
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Estados
                      </button>
                    </>
                  ) : (
                    <div>
                      Seleccione Órdenes para, exportar un reporte de Estados
                    </div>
                  )}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilte &&
                  props.dataOrdersFilter.length > 0 ? (
                    <button
                      onClick={() => props.findHistorials()}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Ver Historiales de Órdenes
                    </button>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  <button
                    onClick={() => props.reporteSegmentadoPorRutas()}
                    className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                  >
                    {" "}
                    Reporte segmentado por Días/Rutas
                  </button>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <button
                      onClick={() => props.setWiewPrePdf(!props.wiewPrePdf)}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {props.wiewPrePdf === true
                        ? "Cerrar vista previa de reporte"
                        : "Crear reporte Cierre Diario"}
                    </button>
                  ) : null}
                </MenuItem>
              </Menu>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewPendingstGeosort()}
                sx={{ color: "#591e8f" }}
              >
                <ReportProblemIcon
                  sx={{
                    color: "#ff3100",
                  }}
                />{" "}
              </IconButton>
              Pendientes Para validación
            </div>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        >
          <div>
            <h3>{props.cantDataOrdersFilter} Ordenes en la consulta actual</h3>
          </div>
        </Grid>
      </Grid>
      {viewSelectTimes === true ? (
        <>
          <Grid container spacing={2} columns={16}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "50%",
                },
              }}
            >
              <Toolbar
                sx={{
                  borderBlockColor: grey[200],
                }}
              >
                <Grid container spacing={2} columns={16}>
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                      },
                    }}
                  >
                    <Toolbar
                      sx={{
                        backgroundColor: grey[200],
                      }}
                    >
                      <Grid container columns={16}>
                        <Button
                          variant="contained"
                          onClick={rangeInputPicker.onOpen}
                          sx={CustomDateRangePickerStyle}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <div sx={{ typography: "body2" }}>
                              <strong>Desde:</strong>{" "}
                              {fDate(rangeInputPicker.startDate)}
                              <EventIcon color="primary" />{" "}
                              {/* Ícono de calendario */}
                            </div>
                            <div sx={{ typography: "body2" }}>
                              <strong>Hasta:</strong>{" "}
                              {fDate(rangeInputPicker.endDate)}
                              <EventIcon color="primary" />{" "}
                              {/* Ícono de calendario */}
                            </div>
                          </Stack>
                        </Button>

                        <CustomDateRangePicker
                          variant="calendar" // input
                          open={rangeInputPicker.open}
                          startDate={rangeInputPicker.startDate}
                          endDate={rangeInputPicker.endDate}
                          onChangeStartDate={rangeInputPicker.onChangeStartDate}
                          onChangeEndDate={rangeInputPicker.onChangeEndDate}
                          onClose={rangeInputPicker.onClose}
                          error={rangeInputPicker.error}
                          setNewDate={setNewDate}
                        />
                      </Grid>
                    </Toolbar>
                  </Grid>
                </Grid>
              </Toolbar>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "50%",
                },
              }}
            ></Grid>
          </Grid>

          <Grid
            item
            sx={{
              width: {
                xs: "70%",
                sm: "70%",
                md: "70%",
                lg: "70%",
              },
            }}
          ></Grid>
        </>
      ) : null}

      {viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              {/* <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                {props.dataType === "request" ? (
                  <Button
                    className="btn-tool-small "
                    name="viewFinFindOrdes"
                    onClick={(e) => handleSelectBtnSearchInterfaz(e)} //</ButtonGroup>setViewFindOrdes(!viewFinFindOrdes)}
                  >
                    Encontrar en el rango actual
                  </Button>
                ) : null}

                <SwipeableSearcherDrawer
                  companyId={props.companyId}
                  iconColor={"#FFF"}
                  styleClassName={"btn-tool-small"}
                ></SwipeableSearcherDrawer>
              </ButtonGroup> */}
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewFinFindOrdes === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <div>
                <Form.Field
                  sx={{
                    backgroundColor: "transparent",
                    border: "0.1",
                    borderRadius: "15px",
                    color: "#000",
                    fontSize: "inherit",
                    height: "auto",
                    width: "100px",
                    outline: "none",
                    paddingInlineEnd: "44px",
                    position: "relative",
                    textAlign: "left",
                    appearance: "menulist-button",
                    backgroundImage: "none",
                    maxWidth: "250px",
                    display: "inline-block",
                    margin: "3px",
                  }}
                  control={Input}
                  placeholder="Buscar..."
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setDataSearch(e.target.value)}
                  value={dataSearch}
                />
              </div>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewRegisterIems === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Button
              variant="outline"
              startIcon={<FileUploadIcon />}
              onClick={() => UploadDocumentsByDataSDD(true)}
              sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
            >
              1- Subir SDD - Crear Registra Ordenes.
            </Button>
            <ReusableFormImportOrdersByDataSDD
              open={openImportDataSDD}
              setOpen={setOpenImportDataSDD}
              handleClose={() => setOpenImportDataSDD(false)}
              companyData={props.companyData}
              userDataContext={props.userDataContext}
              prefix={props.prefix}
              dataOrigin={"GSDATA"}
            />

            <Button
              variant="outline"
              startIcon={<FileUploadIcon />}
              onClick={() => UploadDocumentsByDataPregrilla(true)}
              sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
            >
              2 - Planeación Pre grilla (Rutas Externa)
            </Button>
            <ReusableFormImportOrdersPregrillaGeosort
              open={openImportDataPregrilla}
              setOpen={setOpenImportDataPregrilla}
              handleClose={() => setOpenImportDataPregrilla(false)}
              companyData={props.companyData}
              userDataContext={props.userDataContext}
              prefix={props.prefix}
              dataOrigin={"GSDATA"}
            />
            <ReusableFormImportReportGeosort
              open={openImportByDataReport}
              setOpen={setOpenImportByDataReport}
              handleClose={() => setOpenImportByDataReport(false)}
              companyData={props.companyData}
              userDataContext={props.userDataContext}
              prefix={props.prefix}
              dataOrigin={"GSDATA"}
            />

            <Button
              variant="outline"
              startIcon={<FileUploadIcon />}
              onClick={() => UploadDocumentsByDataReport(true)}
              sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
            >
              3 - Complemento Actualizar Estados Geosort (Conductores y Estados)
            </Button>
            {/* 
            <ToolDashUpdatePaymentsByDocAntsMethod
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdatePaymentsByDocAntsMethod>

            <ToolDashUpdateDocPreGrilla
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdateDocPreGrilla>

            <ToolDashUpdateReportGeosort
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdateReportGeosort>
             */}
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
      </Grid>
      <div>
        <Box sx={{ width: "100%" }}>
          {dataSearch.length > 0 &&
          viewFinFindOrdes === true &&
          viewFindAnSelectorOrdes === true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell align="right">Conductor</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Zona</TableCell>
                    <TableCell align="right">Ver&nbsp;Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div>
                          <div>
                            <strong>{row.OrderID}</strong>
                          </div>
                          <div>{row.Cliente}</div>
                          <div>9{row.ClienteTelefono}</div>
                          <div>{row.ClienteEmail}</div>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div>
                          <div>
                            <Avatar
                              src={row.driverAvatar}
                              sx={{ bgcolor: "#f2f2f2" }}
                              aria-label="recipe"
                            />
                          </div>
                          <div>
                            {row.Conductor ? row.Conductor : "No Asignado"}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.Fecha}</TableCell>
                      <TableCell align="right">{row.Zona}</TableCell>
                      <TableCell align="right">
                        <NavLink
                          to={{
                            pathname: `/dashboard/dispatch/details/${row.OrderID}`,
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {" "}
                          ver{" "}
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      </div>
    </Box>
  );
}
