import useSWR from "swr";
import { useMemo } from "react";
import { findOrderByContext } from "./findOrderByContext";

export function useGetOrders(params) {
  console.log(params.companyIDs[0]);
  let url = "#";
  const fetcher = (...args) => findOrderByContext(...args);

  // Asignar valores predeterminados para los parámetros
  const defaultParams = {
    companyIDs: [],
    state: null,
    dateField: "createdAt",
    startDate: new Date(),
    endDate: new Date(),
    ...params, // Sobrescribir con los parámetros proporcionados
  };

  const { data, error, isValidating } = useSWR(
    ["/api/orders", defaultParams],
    fetcher
  );

  console.log("data:", data);
  console.log("error: ", error);
  console.log("isValidating:", isValidating);

  const isLoading = !data && !error;

  const memoizedValue = useMemo(
    () => ({
      orders: data?.orders || [],
      ordersLoading: isLoading,
      ordersError: error,
      ordersValidating: isValidating,
      ordersEmpty: !isLoading && !data?.orders.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
