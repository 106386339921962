import React from "react";
import { NavLink } from "react-router-dom";

const BtnRegistrarme = () => {
  return (
    <button className="btn btn-nostyle">
      <NavLink to="/registro" exact="true">
        Registrar una cuenta nueva
      </NavLink>
    </button>
  );
};

export default BtnRegistrarme;
